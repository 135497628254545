(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.hJ.j3 === region.i3.j3)
	{
		return 'on line ' + region.hJ.j3;
	}
	return 'on lines ' + region.hJ.j3 + ' through ' + region.i3.j3;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.nU,
		impl.p5,
		impl.pP,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		of: func(record.of),
		pN: record.pN,
		oX: record.oX
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.of;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.pN;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.oX) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.nU,
		impl.p5,
		impl.pP,
		function(sendToApp, initialModel) {
			var view = impl.p9;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.nU,
		impl.p5,
		impl.pP,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.hv && impl.hv(sendToApp)
			var view = impl.p9;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.mo);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.eE) && (_VirtualDom_doc.title = title = doc.eE);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.oF;
	var onUrlRequest = impl.oG;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		hv: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.k3 === next.k3
							&& curr.ju === next.ju
							&& curr.kG.a === next.kG.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		nU: function(flags)
		{
			return A3(impl.nU, flags, _Browser_getUrl(), key);
		},
		p9: impl.p9,
		p5: impl.p5,
		pP: impl.pP
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { nK: 'hidden', mv: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { nK: 'mozHidden', mv: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { nK: 'msHidden', mv: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { nK: 'webkitHidden', mv: 'webkitvisibilitychange' }
		: { nK: 'hidden', mv: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		lk: _Browser_getScene(),
		lX: {
			l2: _Browser_window.pageXOffset,
			h0: _Browser_window.pageYOffset,
			cR: _Browser_doc.documentElement.clientWidth,
			nI: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		cR: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		nI: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			lk: {
				cR: node.scrollWidth,
				nI: node.scrollHeight
			},
			lX: {
				l2: node.scrollLeft,
				h0: node.scrollTop,
				cR: node.clientWidth,
				nI: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			lk: _Browser_getScene(),
			lX: {
				l2: x,
				h0: y,
				cR: _Browser_doc.documentElement.clientWidth,
				nI: _Browser_doc.documentElement.clientHeight
			},
			i_: {
				l2: x + rect.left,
				h0: y + rect.top,
				cR: rect.width,
				nI: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.nk.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.nk.b, xhr)); });
		$elm$core$Maybe$isJust(request.p4) && _Http_track(router, xhr, request.p4.a);

		try {
			xhr.open(request.oi, request.lO, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.lO));
		}

		_Http_configureRequest(xhr, request);

		request.mo.a && xhr.setRequestHeader('Content-Type', request.mo.a);
		xhr.send(request.mo.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.nH; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.pW.a || 0;
	xhr.responseType = request.nk.d;
	xhr.withCredentials = request.mb;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		lO: xhr.responseURL,
		pL: xhr.status,
		pM: xhr.statusText,
		nH: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			pF: event.loaded,
			aH: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ph: event.loaded,
			aH: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.kh) { flags += 'm'; }
	if (options.iq) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}

// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $author$project$Model$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Model$UrlRequested = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.s) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.x),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.x);
		} else {
			var treeLen = builder.s * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.y) : builder.y;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.s);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.x) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.x);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{y: nodeList, s: (len / $elm$core$Array$branchFactor) | 0, x: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {jh: fragment, ju: host, kC: path, kG: port_, k3: protocol, k4: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Utils$Logger$loggerPort_ = _Platform_outgoingPort('loggerPort_', $elm$core$Basics$identity);
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Utils$Logger$devConsoleError = F2(
	function (place, args) {
		return $author$project$Utils$Logger$loggerPort_(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type_',
						$elm$json$Json$Encode$string('error')),
						_Utils_Tuple2(
						'codeLocation',
						$elm$json$Json$Encode$string(place)),
						_Utils_Tuple2('args', args)
					])));
	});
var $author$project$Utils$Logger$devConsoleErrorForJsonDecodeError = function (conf) {
	return A2(
		$author$project$Utils$Logger$devConsoleError,
		conf.bw,
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('Error log for Json Decoding error')),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(
						$elm$json$Json$Decode$errorToString(conf.b0)))
				])));
};
var $author$project$Types$Flags = F2(
	function (staticFlags, dynamicFlags) {
		return {iY: dynamicFlags, z: staticFlags};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $author$project$Types$DynamicFlags = function (isPrivacyDisclaimerVisible) {
	return {fM: isPrivacyDisclaimerVisible};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Types$expectedDynamicFlagsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'isPrivacyDisclaimerVisible',
	$elm$json$Json$Decode$bool,
	$elm$json$Json$Decode$succeed($author$project$Types$DynamicFlags));
var $author$project$Types$fallbackDynamicFlags = {fM: false};
var $author$project$Types$fallbackDynamicFlagsDecoder = $elm$json$Json$Decode$succeed($author$project$Types$fallbackDynamicFlags);
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $author$project$Types$dynamicFlagsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Types$expectedDynamicFlagsDecoder, $author$project$Types$fallbackDynamicFlagsDecoder]));
var $author$project$Types$StaticFlags = function (apiUrl) {
	return function (challengeProviderUrl) {
		return function (cookiePageUrl) {
			return function (downloadUrl) {
				return function (intermediariUrl) {
					return function (isMobile) {
						return function (liraUrl) {
							return function (loginPageUrl) {
								return function (mastrotaUrl) {
									return function (nonce) {
										return function (primaUrl) {
											return function (referral) {
												return function (seed) {
													return function (tokenConfig) {
														return {mc: apiUrl, mu: challengeProviderUrl, iE: cookiePageUrl, iX: downloadUrl, jG: intermediariUrl, _: isMobile, fS: liraUrl, j6: loginPageUrl, fV: mastrotaUrl, oz: nonce, kS: primaUrl, hj: referral, lm: seed, p2: tokenConfig};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Types$TokenConfig = F3(
	function (token, refreshToken, tokenRetrievedFromUrl) {
		return {pi: refreshToken, p1: token, lJ: tokenRetrievedFromUrl};
	});
var $author$project$Types$tokenConfigDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'tokenRetrievedFromUrl',
	$elm$json$Json$Decode$bool,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'refreshToken',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'token',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Types$TokenConfig))));
var $author$project$Types$staticFlagsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tokenConfig',
	$elm$json$Json$Decode$nullable($author$project$Types$tokenConfigDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'seed',
		$elm$json$Json$Decode$int,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'referral',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'primaUrl',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'nonce',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'mastrotaUrl',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'loginPageUrl',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'liraUrl',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'isMobile',
									$elm$json$Json$Decode$bool,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'intermediariUrl',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'downloadUrl',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'cookiePageUrl',
												$elm$json$Json$Decode$string,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'challengeProviderUrl',
													$elm$json$Json$Decode$string,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'apiUrl',
														$elm$json$Json$Decode$string,
														$elm$json$Json$Decode$succeed($author$project$Types$StaticFlags)))))))))))))));
var $author$project$Types$flagsTupleDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	A2($elm$json$Json$Decode$field, 'dynamicFlags', $author$project$Types$dynamicFlagsDecoder),
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		A2($elm$json$Json$Decode$field, 'staticFlags', $author$project$Types$staticFlagsDecoder),
		$elm$json$Json$Decode$succeed($author$project$Types$Flags)));
var $author$project$AppStatus$AppLoading = 0;
var $author$project$AppStatus$AppReady = 1;
var $author$project$Model$ChangeAppStatus = function (a) {
	return {$: 18, a: a};
};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Model$ReceivedTokenFromNonce = function (a) {
	return {$: 14, a: a};
};
var $author$project$Model$DelayedBootState = {$: 22};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Init$bootDelayedCmd = A2(
	$elm$core$Task$attempt,
	$elm$core$Basics$always($author$project$Model$DelayedBootState),
	$elm$core$Process$sleep(2000));
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return {
			bT: baseUrl,
			m4: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
			nk: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
			nH: _List_Nil,
			aY: $elm$core$Maybe$Nothing,
			a0: _List_Nil,
			pW: $elm$core$Maybe$Nothing,
			a7: false
		};
	});
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {k9: reqs, lB: subs};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.p4;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.k9));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.lB)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					mb: r.mb,
					mo: r.mo,
					nk: A2(_Http_mapExpect, func, r.nk),
					nH: r.nH,
					oi: r.oi,
					pW: r.pW,
					p4: r.p4,
					lO: r.lO
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{mb: false, mo: r.mo, nk: r.nk, nH: r.nH, oi: r.oi, pW: r.pW, p4: r.p4, lO: r.lO}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{mb: true, mo: r.mo, nk: r.nk, nH: r.nH, oi: r.oi, pW: r.pW, p4: r.p4, lO: r.lO}));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$convertResult = function (httpResult) {
	if (!httpResult.$) {
		var successOrError = httpResult.a;
		if (!successOrError.$) {
			var value = successOrError.a;
			return $elm$core$Result$Ok(value);
		} else {
			var _v2 = successOrError.a;
			var possiblyParsedData = _v2.a;
			var error = _v2.b;
			return $elm$core$Result$Err(
				A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, error));
		}
	} else {
		var httpError = httpResult.a;
		return $elm$core$Result$Err(
			$dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError));
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $dillonkearns$elm_graphql$Graphql$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
					case 1:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
					case 2:
						return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
					case 3:
						var metadata = response.a;
						var body = response.b;
						return $elm$core$Result$Err(
							A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
					default:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v1.$) {
							var value = _v1.a;
							return $elm$core$Result$Ok(value);
						} else {
							var err = _v1.a;
							return $elm$core$Result$Err(
								$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
						}
				}
			});
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$ge = _Utils_ge;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.ci;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{y: nodeList, s: nodeListSize, x: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {dG: index, ci: match, kp: number, lA: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{iq: false, kh: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {be: charsProcessed, bo: hash, lm: seed, bF: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.bo)) ? (data.lm ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.bo)))) : data.lm;
	var h0 = acc ^ data.be;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.bo | ((255 & $elm$core$Char$toCode(c)) << data.bF);
		var _v0 = data.bF;
		if (_v0 === 24) {
			return {
				be: data.be + 1,
				bo: 0,
				lm: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.lm, res),
				bF: 0
			};
		} else {
			return {be: data.be + 1, bo: res, lm: data.lm, bF: data.bF + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$List = function (a) {
	return {$: 2, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 3, a: a};
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	var serializeJson = function (json) {
		var decoder = $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$List,
						$elm$core$List$map($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json)),
					$elm$json$Json$Decode$list($elm$json$Json$Decode$value)),
					A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						$dillonkearns$elm_graphql$Graphql$Internal$Encode$Object,
						$elm$core$List$map(
							$elm$core$Tuple$mapSecond($dillonkearns$elm_graphql$Graphql$Internal$Encode$Json))),
					$elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$value))
				]));
		var _v2 = A2($elm$json$Json$Decode$decodeValue, decoder, json);
		if (!_v2.$) {
			var v = _v2.a;
			return $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(v);
		} else {
			return A2($elm$json$Json$Encode$encode, 0, json);
		}
	};
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return serializeJson(json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.lN;
				var fieldName = field.a.jb;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.lN;
		var fieldName = field.a.jb;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $j_maas$elm_ordered_containers$OrderedDict$empty = {o: $elm$core$Dict$empty, oK: _List_Nil};
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields = function (rawFields) {
	var compositeCount = $elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			function (field) {
				if (!field.$) {
					return $elm$core$Maybe$Just(0);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			rawFields));
	if (compositeCount <= 1) {
		return $elm$core$Set$empty;
	} else {
		var levelBelowNodes = A2(
			$elm$core$List$concatMap,
			function (field) {
				if (field.$ === 1) {
					return _List_Nil;
				} else {
					var children = field.c;
					return children;
				}
			},
			rawFields);
		var fieldTypes = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, acc) {
					var fieldName = _v1.a;
					var fieldType = _v1.b;
					return A3(
						$elm$core$Dict$update,
						fieldName,
						function (maybeFieldTypes) {
							if (maybeFieldTypes.$ === 1) {
								return $elm$core$Maybe$Just(
									$elm$core$Set$singleton(fieldType));
							} else {
								var fieldTypes_ = maybeFieldTypes.a;
								return $elm$core$Maybe$Just(
									A2($elm$core$Set$insert, fieldType, fieldTypes_));
							}
						},
						acc);
				}),
			$elm$core$Dict$empty,
			A2(
				$elm$core$List$filterMap,
				function (field) {
					if (field.$ === 1) {
						var typeString = field.a.lN;
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								$dillonkearns$elm_graphql$Graphql$RawField$name(field),
								typeString));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				},
				levelBelowNodes));
		return $elm$core$Set$fromList(
			$elm$core$Dict$keys(
				A2(
					$elm$core$Dict$filter,
					F2(
						function (fieldType, fields) {
							return function (size) {
								return size > 1;
							}(
								$elm$core$Set$size(fields));
						}),
					fieldTypes)));
	}
};
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return A2($elm$core$Dict$get, key, orderedDict.o);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.o) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.oK) : orderedDict.oK;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			o: A3($elm$core$Dict$insert, key, value, orderedDict.o),
			oK: newOrder
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			o: A2($elm$core$Dict$remove, key, orderedDict.o),
			oK: A2($elm$core$Dict$member, key, orderedDict.o) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.oK) : orderedDict.oK
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.o);
		if (!_v0.$) {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (!_v1.$) {
				var newItem = _v1.a;
				return {
					o: A3(
						$elm$core$Dict$update,
						key,
						$elm$core$Basics$always(
							$elm$core$Maybe$Just(newItem)),
						orderedDict.o),
					oK: orderedDict.oK
				};
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing = F2(
	function (forceHashing, rawFields) {
		var fieldCounts = A3(
			$elm$core$List$foldl,
			F2(
				function (fld, acc) {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						fld,
						function (val) {
							return $elm$core$Maybe$Just(
								function () {
									if (val.$ === 1) {
										return 0;
									} else {
										var count = val.a;
										return count + 1;
									}
								}());
						},
						acc);
				}),
			$j_maas$elm_ordered_containers$OrderedDict$empty,
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$RawField$name, rawFields));
		var conflictingTypeFields = $dillonkearns$elm_graphql$Graphql$Document$Field$findConflictingTypeFields(rawFields);
		return A2(
			$elm$core$List$map,
			function (field) {
				return _Utils_Tuple3(
					field,
					A2(
						$elm$core$Set$member,
						$dillonkearns$elm_graphql$Graphql$RawField$name(field),
						forceHashing) ? $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field) : ((!A2(
						$elm$core$Maybe$withDefault,
						0,
						A2(
							$j_maas$elm_ordered_containers$OrderedDict$get,
							$dillonkearns$elm_graphql$Graphql$RawField$name(field),
							fieldCounts))) ? $elm$core$Maybe$Nothing : $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field)),
					conflictingTypeFields);
			},
			rawFields);
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return A2($elm$core$String$repeat, indentationLevel, '  ');
};
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (field, mergedSoFar) {
				if (!field.$) {
					var newChildren = field.c;
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							if (maybeChildrenSoFar.$ === 1) {
								return $elm$core$Maybe$Just(field);
							} else {
								if (!maybeChildrenSoFar.a.$) {
									var _v2 = maybeChildrenSoFar.a;
									var existingFieldName = _v2.a;
									var existingArgs = _v2.b;
									var existingChildren = _v2.c;
									return $elm$core$Maybe$Just(
										A3(
											$dillonkearns$elm_graphql$Graphql$RawField$Composite,
											existingFieldName,
											existingArgs,
											_Utils_ap(existingChildren, newChildren)));
								} else {
									return $elm$core$Maybe$Just(field);
								}
							}
						},
						mergedSoFar);
				} else {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							return $elm$core$Maybe$Just(
								A2($elm$core$Maybe$withDefault, field, maybeChildrenSoFar));
						},
						mergedSoFar);
				}
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		rawFields);
};
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.o);
		},
		orderedDict.oK);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
	return $j_maas$elm_ordered_containers$OrderedDict$values(
		$dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
};
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{jb: '__typename', lN: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F4(
	function (forceHashing, aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A3($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp, forceHashing, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A3(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp,
								forceHashing,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.jb;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp = F3(
	function (forceHashing, indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (_v0) {
						var field = _v0.a;
						var maybeAlias = _v0.b;
						var conflictingTypeFields = _v0.c;
						return A4(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							conflictingTypeFields,
							maybeAlias,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing,
						forceHashing,
						$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(
							$dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children))))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A3($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildrenHelp, $elm$core$Set$empty, indentationLevel, children);
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('query', serializedQueryForGetRequest),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				mo: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedQuery)),
							operationNameParamForPostRequest))),
				oi: 1,
				lO: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, queryParams, url)
			};
		} else {
			return {mo: $elm$http$Http$emptyBody, oi: 0, lO: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {m4: details, oc: locations, of: message};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {iA: column, j3: line};
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.m4;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.bT,
			request.a0,
			request.aY,
			querySelectionSet);
		return {
			mo: queryRequestDetails.mo,
			m0: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.nk),
			nH: request.nH,
			oi: function () {
				var _v2 = queryRequestDetails.oi;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			pW: request.pW,
			lO: queryRequestDetails.lO
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v7 = request.aY;
			if (!_v7.$) {
				var operationName = _v7.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			mo: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedMutation))
							]),
						function () {
							var _v6 = request.aY;
							if (!_v6.$) {
								var operationName = _v6.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'operationName',
										$elm$json$Json$Encode$string(operationName))
									]);
							} else {
								return _List_Nil;
							}
						}()))),
			m0: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.nk),
			nH: request.nH,
			oi: 'POST',
			pW: request.pW,
			lO: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.a0, request.bT)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return function (readyRequest) {
			return {
				mo: readyRequest.mo,
				nk: A2(
					$dillonkearns$elm_graphql$Graphql$Http$expectJson,
					A2($elm$core$Basics$composeR, $dillonkearns$elm_graphql$Graphql$Http$convertResult, resultToMessage),
					readyRequest.m0),
				nH: readyRequest.nH,
				oi: readyRequest.oi,
				pW: readyRequest.pW,
				p4: $elm$core$Maybe$Nothing,
				lO: readyRequest.lO
			};
		}(
			$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Http$send = F2(
	function (resultToMessage, fullRequest) {
		var request = fullRequest;
		return (request.a7 ? $elm$http$Http$riskyRequest : $elm$http$Http$request)(
			A2($dillonkearns$elm_graphql$Graphql$Http$toHttpRequestRecord, resultToMessage, fullRequest));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$json$Json$Decode$field,
						fieldName,
						decoderTransform(decoder)),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
							A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
						decoderTransform(decoder))
					])));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Gql$Vianello$Mutation$tokenFromNonce = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'tokenFromNonce',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'nonce', requiredArgs____.oz, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$TokenFromNonceResult$Fragments = F2(
	function (onTokenFromNonceOk, onTokenFromNonceError) {
		return {g_: onTokenFromNonceError, g$: onTokenFromNonceOk};
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment = F2(
	function (fragmentTypeName, _v0) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet, fragmentTypeName, fields, decoder);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage = F2(
	function (typeSpecificSelections, typeName) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'Unhandled type `{0}` in exhaustive fragment handling. The following types had handlers registered to handle them: [{1}]. This happens if you are parsing either a Union or Interface. Do you need to rerun the `@dillonkearns/elm-graphql` command line tool?',
			_List_fromArray(
				[
					typeName,
					A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm$core$List$map,
						function (_v0) {
							var fragmentType = _v0.a;
							var fields = _v0.b;
							var decoder = _v0.c;
							return fragmentType;
						},
						typeSpecificSelections))
				]));
	});
var $elm$json$Json$Decode$fail = _Json_fail;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection = function (typeSpecificSelections) {
	var selections = A2(
		$elm$core$List$map,
		function (_v1) {
			var typeName = _v1.a;
			var fields = _v1.b;
			var decoder = _v1.c;
			return A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, '...on ' + typeName, _List_Nil, fields);
		},
		typeSpecificSelections);
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, selections),
		A2(
			$elm$json$Json$Decode$andThen,
			function (typeName) {
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Decode$fail(
						A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage, typeSpecificSelections, typeName)),
					A2(
						$elm$core$Dict$get,
						typeName,
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (_v0) {
									var thisTypeName = _v0.a;
									var fields = _v0.b;
									var decoder = _v0.c;
									return _Utils_Tuple2(thisTypeName, decoder);
								},
								typeSpecificSelections))));
			},
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName($dillonkearns$elm_graphql$Graphql$RawField$typename),
				$elm$json$Json$Decode$string)));
};
var $author$project$Gql$Vianello$Union$TokenFromNonceResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'TokenFromNonceOk', selections____.g$),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'TokenFromNonceError', selections____.g_)
			]));
};
var $author$project$Types$AnonymousTokenError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $author$project$Types$TokenError = function (message) {
	return {of: message};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{jb: fieldName, lN: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2($elm$json$Json$Decode$field, fieldName, decoder),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
						decoder)
					])));
	});
var $author$project$Gql$Vianello$Object$TokenFromNonceError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Api$Token$tokenFromNonceErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$TokenFromNonceError$message,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenError));
var $author$project$Api$Token$onTokenFromNonceErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$tokenFromNonceErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$AnonymousTokenError));
var $author$project$Types$AnonymousTokenOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$TokenOk = F2(
	function (token, refreshToken) {
		return {pi: refreshToken, p1: token};
	});
var $author$project$Gql$Vianello$Object$TokenFromNonceOk$refreshToken = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'refreshToken',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$TokenFromNonceOk$token = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'token', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Token$tokenFromNonceOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$TokenFromNonceOk$refreshToken,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$TokenFromNonceOk$token,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenOk)));
var $author$project$Api$Token$onTokenFromNonceOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$tokenFromNonceOkSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$AnonymousTokenOk));
var $author$project$Api$Token$tokenFromNonceSelection = $author$project$Gql$Vianello$Union$TokenFromNonceResult$fragments(
	A2($author$project$Gql$Vianello$Union$TokenFromNonceResult$Fragments, $author$project$Api$Token$onTokenFromNonceOkSelection, $author$project$Api$Token$onTokenFromNonceErrorSelection));
var $author$project$Api$Token$tokenFromNonceMutation = function (nonce) {
	return A2(
		$author$project$Gql$Vianello$Mutation$tokenFromNonce,
		{oz: nonce},
		$author$project$Api$Token$tokenFromNonceSelection);
};
var $author$project$Api$AuthClient$getTokenFromNonce = F2(
	function (apiUrl, msg) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Api$Token$tokenFromNonceMutation,
			A2(
				$elm$core$Basics$composeR,
				$dillonkearns$elm_graphql$Graphql$Http$mutationRequest(apiUrl),
				$dillonkearns$elm_graphql$Graphql$Http$send(msg)));
	});
var $author$project$Model$AuthenticatorMsg = function (a) {
	return {$: 21, a: a};
};
var $author$project$Model$CookieBotInnerMsg = function (a) {
	return {$: 19, a: a};
};
var $author$project$Model$Model = function (flags) {
	return function (route) {
		return function (preselectionModel) {
			return function (quotationOldModel) {
				return function (quotationModel) {
					return function (guaranteesModel) {
						return function (checkoutModel) {
							return function (thankYouModel) {
								return function (oneClickModel) {
									return function (notAvailableModel) {
										return function (key) {
											return function (url) {
												return function (isModalOpen) {
													return function (gqlClientModel) {
														return function (authenticator) {
															return function (tokenConfig) {
																return function (user) {
																	return function (regions) {
																		return function (provinces) {
																			return function (appStatus) {
																				return function (cookieBot) {
																					return {c0: appStatus, mf: authenticator, mw: checkoutModel, e5: cookieBot, nv: flags, dy: gqlClientModel, nG: guaranteesModel, n4: isModalOpen, fR: key, oA: notAvailableModel, oH: oneClickModel, oW: preselectionModel, o7: provinces, pa: quotationModel, pb: quotationOldModel, pk: regions, pw: route, pU: thankYouModel, p2: tokenConfig, lO: url, p6: user};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Routing$UnsetQuotationType = 2;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Model$GqlClientMsg = function (a) {
	return {$: 16, a: a};
};
var $author$project$PrimaElm$Lib$GqlClient$Model$Model = $elm$core$Basics$identity;
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PrimaElm$Lib$GqlClient$Model$defaultAuthenticationErrorIdentifier = function () {
	var errorDecoder = A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['missing_token']),
		$elm$json$Json$Decode$bool);
	return A2(
		$elm$core$Basics$composeR,
		A2(
			$elm$core$List$foldl,
			F2(
				function (_v0, acc) {
					var details = _v0.m4;
					return A2($elm$core$List$cons, details, acc);
				}),
			_List_Nil),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filterMap(
				$elm$core$Dict$get('extensions')),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$filterMap(
					A2(
						$elm$core$Basics$composeL,
						$elm$core$Result$toMaybe,
						$elm$json$Json$Decode$decodeValue(errorDecoder))),
				A2(
					$elm$core$Basics$composeR,
					$elm_community$list_extra$List$Extra$find($elm$core$Basics$identity),
					$elm_community$maybe_extra$Maybe$Extra$isJust))));
}();
var $author$project$PrimaElm$Lib$GqlClient$Config$Config = $elm$core$Basics$identity;
var $author$project$PrimaElm$Lib$GqlClient$Config$initialize = F2(
	function (url, auth) {
		return {eZ: auth, lO: url};
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$initialize = F3(
	function (url, auth, msgLifter) {
		return {
			e_: $author$project$PrimaElm$Lib$GqlClient$Model$defaultAuthenticationErrorIdentifier,
			iC: A2($author$project$PrimaElm$Lib$GqlClient$Config$initialize, url, auth),
			kg: msgLifter,
			bz: _List_Nil
		};
	});
var $author$project$Types$pickApiUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.mc;
	});
var $author$project$Types$pickReferral = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.hj;
	});
var $author$project$Model$withInitialReferral = function (flags) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$singleton,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$pair('X-Origin-Referral'),
				$author$project$Types$pickReferral(flags))));
};
var $author$project$Utils$withBearer = function (token) {
	return 'Bearer ' + token;
};
var $author$project$Utils$authHeader = function (token) {
	return A3(
		$elm$core$Basics$composeR,
		$author$project$Utils$withBearer,
		$elm$core$Tuple$pair('Authorization'),
		token);
};
var $author$project$Types$pickTokenConfig = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.p2;
	});
var $author$project$Model$withInitialToken = function (flags) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$singleton,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.p1;
					},
					$author$project$Utils$authHeader),
				$author$project$Types$pickTokenConfig(flags))));
};
var $author$project$Model$withInitialHeaders = function (flags) {
	return _Utils_ap(
		$author$project$Model$withInitialReferral(flags),
		$author$project$Model$withInitialToken(flags));
};
var $author$project$Model$gqlClientModelConfig = function (flags) {
	return A3(
		$author$project$PrimaElm$Lib$GqlClient$Model$initialize,
		$author$project$Types$pickApiUrl(flags),
		$author$project$Model$withInitialHeaders(flags),
		$author$project$Model$GqlClientMsg);
};
var $author$project$CookieBot$cookieBotPort_ = _Platform_outgoingPort('cookieBotPort_', $elm$core$Basics$identity);
var $author$project$CookieBot$encodeBootArgs = function (args) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					_Utils_Tuple2(
						'cookieBotId',
						$elm$json$Json$Encode$string(args.dc))),
					A2(
					$elm$core$Maybe$map,
					function (justId) {
						return _Utils_Tuple2(
							'dialogContainerId',
							$elm$json$Json$Encode$string(justId));
					},
					args.dk)
				])));
};
var $author$project$CookieBot$init = function (args) {
	return _Utils_Tuple2(
		{
			c9: {j8: false, kj: true, kO: false, lw: false},
			ey: args.ey
		},
		$author$project$CookieBot$cookieBotPort_(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type_',
						$elm$json$Json$Encode$string('Init')),
						_Utils_Tuple2(
						'args',
						$author$project$CookieBot$encodeBootArgs(
							{dc: args.dc, dk: args.dk}))
					]))));
};
var $author$project$Checkout$Model$BraintreeCreditcard = 1;
var $author$project$Types$EndUserToken = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$BraintreeModel$AppReady = 1;
var $author$project$Checkout$Model$BraintreeModel$BraintreeField = F6(
	function (id, label, labelId, error, isValid, isPristine) {
		return {b0: error, nM: id, jR: isPristine, jY: isValid, n6: label, n7: labelId};
	});
var $author$project$Checkout$Model$BraintreeModel$initialBraintreeFields = {
	io: A6($author$project$Checkout$Model$BraintreeModel$BraintreeField, 'card-number', 'Numero carta di credito', 'card-label', 'controlla il numero di carta di credito', false, true),
	iJ: A6($author$project$Checkout$Model$BraintreeModel$BraintreeField, 'cvv', 'CVV', 'cvv-label', 'controlla il cvv', false, true),
	i7: A6($author$project$Checkout$Model$BraintreeModel$BraintreeField, 'expiration-month', 'Data di scadenza', 'expiration-date-label', 'controlla la data di scadenza', false, true),
	i8: A6($author$project$Checkout$Model$BraintreeModel$BraintreeField, 'expiration-year', 'anno', '', '', false, true)
};
var $author$project$Checkout$Model$BraintreeModel$initModel = {c0: 1, e1: $elm$core$Maybe$Nothing, b0: $elm$core$Maybe$Nothing, jc: $author$project$Checkout$Model$BraintreeModel$initialBraintreeFields, jI: false, jQ: false, oz: $elm$core$Maybe$Nothing, ky: $elm$core$Maybe$Nothing, hK: $elm$core$Maybe$Nothing, p1: $elm$core$Maybe$Nothing};
var $author$project$Checkout$Model$initialOptions = {jW: false, jX: false, ln: $elm$core$Maybe$Nothing};
var $author$project$Checkout$Model$initModel = F2(
	function (flags, quotationType) {
		return {
			mp: $author$project$Checkout$Model$BraintreeModel$initModel,
			iv: $krisajenkins$remotedata$RemoteData$NotAsked,
			iH: $elm$core$Maybe$Nothing,
			de: 1,
			m8: $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate($elm$core$Maybe$Nothing),
			m9: $elm$core$Maybe$Nothing,
			nv: flags,
			nW: {na: false, oe: 3, po: 0},
			jB: $krisajenkins$remotedata$RemoteData$NotAsked,
			n1: false,
			ku: $author$project$Checkout$Model$initialOptions,
			oK: $elm$core$Maybe$Nothing,
			g4: $krisajenkins$remotedata$RemoteData$NotAsked,
			oS: $elm$core$Maybe$Just(1),
			pc: quotationType,
			k5: $elm$core$Maybe$Nothing,
			pz: $elm$core$Maybe$Nothing,
			hq: $krisajenkins$remotedata$RemoteData$NotAsked,
			lq: $krisajenkins$remotedata$RemoteData$NotAsked,
			lQ: $elm$core$Maybe$Nothing,
			lR: 0
		};
	});
var $author$project$RFQDTS$Http$Authenticator$AntiSpiderOnly = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AskItem = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$portValueEncoder = function (val) {
	if (!val.$) {
		var record = val.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type_',
					$elm$json$Json$Encode$string('askItem')),
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string(record.fR))
				]));
	} else {
		var record = val.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type_',
					$elm$json$Json$Encode$string('setItem')),
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string(record.fR)),
					_Utils_Tuple2('item', record.dS)
				]));
	}
};
var $author$project$RFQDTS$Http$Authenticator$rfqDtsHttpAuthenticatorPort_ = _Platform_outgoingPort('rfqDtsHttpAuthenticatorPort_', $elm$core$Basics$identity);
var $author$project$RFQDTS$Http$Authenticator$findItem = function (itemKey) {
	return $author$project$RFQDTS$Http$Authenticator$rfqDtsHttpAuthenticatorPort_(
		$author$project$RFQDTS$Http$Authenticator$portValueEncoder(
			$author$project$RFQDTS$Http$Authenticator$AskItem(
				{fR: itemKey})));
};
var $author$project$RFQDTS$Http$Authenticator$resolveClientId = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$sessionStorageKey = 'Authenticator.ProofOfWork';
var $primait$prima_elm_extra$PrimaUpdate$withCmds = F2(
	function (cmds, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(cmds));
	});
var $author$project$RFQDTS$Http$Authenticator$initWithAntiSpiderOnly = function (conf) {
	return A2(
		$primait$prima_elm_extra$PrimaUpdate$withCmds,
		_List_fromArray(
			[
				$author$project$RFQDTS$Http$Authenticator$findItem($author$project$RFQDTS$Http$Authenticator$ProofOfWork$sessionStorageKey)
			]),
		$author$project$RFQDTS$Http$Authenticator$AntiSpiderOnly(
			{
				mu: conf.mu,
				mD: $author$project$RFQDTS$Http$Authenticator$resolveClientId(conf.mD),
				aq: 3,
				op: conf.op,
				i: $elm$core$Maybe$Nothing
			}));
};
var $author$project$Types$Quote$NoSectionFilter = {$: 1};
var $author$project$Guarantees$Model$UnsetDependenciesDialog = {$: 1};
var $author$project$Guarantees$Model$initModalDetailsAccordionStates = $elm$core$Dict$empty;
var $author$project$Guarantees$Model$NavigableModalMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$NavigableModal$Close = {$: 2};
var $author$project$Components$NavigableModal$closeMsg = $author$project$Components$NavigableModal$Close;
var $author$project$Components$NavigableModal$GoBack = {$: 3};
var $author$project$Components$NavigableModal$goBackMsg = $author$project$Components$NavigableModal$GoBack;
var $author$project$Components$NavigableModal$NavigableModalModel = $elm$core$Basics$identity;
var $author$project$Components$NavigableModal$init = F2(
	function (closingMsg, backMsg) {
		return {e$: backMsg, e2: closingMsg, jP: false, aZ: _List_Nil};
	});
var $author$project$Guarantees$Model$initNavigableModalModel = function () {
	var closeMsg = $author$project$Guarantees$Model$NavigableModalMsg($author$project$Components$NavigableModal$closeMsg);
	var backMsg = $author$project$Guarantees$Model$NavigableModalMsg($author$project$Components$NavigableModal$goBackMsg);
	return A2($author$project$Components$NavigableModal$init, closeMsg, backMsg);
}();
var $author$project$Guarantees$Model$initialBottomBarModel = {ip: $elm$core$Set$empty, jH: false};
var $author$project$Components$ArrowAccordionGroup$init = {
	at: $elm$core$Dict$empty,
	db: $elm$core$Maybe$Nothing,
	cp: $elm$core$Maybe$Nothing,
	eO: $elm$core$Basics$always(0)
};
var $author$project$Guarantees$Model$initialDetailsModel = {iQ: $author$project$Components$ArrowAccordionGroup$init, j9: $elm$core$Maybe$Nothing, hx: false};
var $author$project$Guarantees$Model$initialOptions = {jW: false, jX: false, j_: true};
var $author$project$Guarantees$Model$initialModel = F2(
	function (flags, quotationType) {
		return {
			im: $author$project$Guarantees$Model$initialBottomBarModel,
			iH: $elm$core$Maybe$Nothing,
			iN: $author$project$Guarantees$Model$UnsetDependenciesDialog,
			fd: $author$project$Guarantees$Model$initialDetailsModel,
			nv: flags,
			fs: $krisajenkins$remotedata$RemoteData$NotAsked,
			ft: $krisajenkins$remotedata$RemoteData$NotAsked,
			jw: 0,
			jU: false,
			ku: $author$project$Guarantees$Model$initialOptions,
			w: $elm$core$Maybe$Nothing,
			pc: quotationType,
			cy: $elm$core$Maybe$Nothing,
			pz: $elm$core$Maybe$Nothing,
			hq: $krisajenkins$remotedata$RemoteData$NotAsked,
			bM: {ax: $elm$core$Dict$empty, fH: false, jV: false, aS: $author$project$Guarantees$Model$initModalDetailsAccordionStates, d3: $author$project$Guarantees$Model$initNavigableModalModel, hr: $author$project$Types$Quote$NoSectionFilter},
			lR: 0,
			h$: $elm$core$Maybe$Nothing
		};
	});
var $author$project$NotAvailable$Model$initialModel = function (flags) {
	return {nv: flags, k6: $elm$core$Maybe$Nothing, lK: 0};
};
var $author$project$OneClick$Model$ModelNotInitialized = {$: 0};
var $author$project$OneClick$Model$initialModel = function (flags) {
	return {nv: flags, cG: $author$project$OneClick$Model$ModelNotInitialized};
};
var $author$project$Preselection$Preselection$NotInitializedModel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Preselection$Preselection$initialModel = $author$project$Preselection$Preselection$NotInitializedModel;
var $author$project$Quotation$Model$ModelNotInitialized = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Model$initialModel = $author$project$Quotation$Model$ModelNotInitialized;
var $author$project$QuotationOld$Model$FormStep$Invalid = 1;
var $author$project$QuotationOld$Model$FormStep$PropertyAddressStep = function (a) {
	return {$: 0, a: a};
};
var $author$project$QuotationOld$Model$RadioField$ActiveMortgage = 1;
var $author$project$QuotationOld$Update$Validation$AgeInRange = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$QuotationOld$Model$AutocompleteField$BirthCity = 1;
var $author$project$QuotationOld$Model$AutocompleteField$BirthCountry = 2;
var $author$project$QuotationOld$Model$DatePickerField$Birthday = 0;
var $author$project$QuotationOld$Model$RadioField$CondominiumType = 2;
var $author$project$QuotationOld$Model$TextField$Email = 10;
var $author$project$QuotationOld$Model$SelectField$FamilyAdults = 4;
var $author$project$QuotationOld$Model$SelectField$FamilyUnderages = 5;
var $author$project$QuotationOld$Model$SelectField$FlatFloor = 1;
var $author$project$QuotationOld$Model$RadioField$Gender = 6;
var $author$project$QuotationOld$Model$RadioField$InsuranceOfInterest = 4;
var $author$project$QuotationOld$Model$NumberField$InsuredValue = 1;
var $author$project$QuotationOld$Update$Validation$Max = function (a) {
	return {$: 2, a: a};
};
var $author$project$QuotationOld$Update$Validation$Min = function (a) {
	return {$: 1, a: a};
};
var $author$project$QuotationOld$Model$TextField$Name = 8;
var $author$project$QuotationOld$Model$RadioField$OwnerOrTenant = 0;
var $author$project$QuotationOld$Model$TextField$Phone = 11;
var $author$project$QuotationOld$Model$SelectField$PropertyConstructionPeriod = 2;
var $author$project$QuotationOld$Model$SelectField$PropertyPurpose = 0;
var $author$project$QuotationOld$Model$NumberField$PropertySize = 0;
var $author$project$QuotationOld$Model$RadioField$PropertyType = 5;
var $author$project$QuotationOld$Model$SelectField$ReportedAccident = 3;
var $author$project$QuotationOld$Update$Validation$Required = {$: 0};
var $author$project$QuotationOld$Model$TextField$Surname = 9;
var $author$project$QuotationOld$Update$Validation$ValidEmail = {$: 4};
var $author$project$QuotationOld$Update$Validation$ValidPhone = {$: 5};
var $author$project$QuotationOld$Model$RadioField$VillaType = 3;
var $author$project$Utils$familyMembersValueToLabel = function (value) {
	return (!value) ? 'Nessuno' : ((value >= 5) ? '5 o più' : $elm$core$String$fromInt(value));
};
var $author$project$QuotationOld$Model$FormData$familyAdultsOptions = A2(
	$elm$core$List$map,
	function (n) {
		return _Utils_Tuple2(
			$elm$core$String$fromInt(n),
			$author$project$Utils$familyMembersValueToLabel(n));
	},
	_List_fromArray(
		[1, 2, 3, 4, 5]));
var $author$project$QuotationOld$Model$FormData$familyUnderagesOptions = A2(
	$elm$core$List$map,
	function (n) {
		return _Utils_Tuple2(
			$elm$core$String$fromInt(n),
			$author$project$Utils$familyMembersValueToLabel(n));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4, 5]));
var $author$project$QuotationOld$Model$FormData$flatFloorOptions = _List_fromArray(
	[
		_Utils_Tuple2('GROUND', 'Piano terra o rialzato'),
		_Utils_Tuple2('TOP', 'Ultimo piano'),
		_Utils_Tuple2('INTERMEDIATE', 'Tutti gli altri piani')
	]);
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.hJ, posixMinutes) < 0) {
					return posixMinutes + era.bx;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		iK: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		ke: month,
		l3: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).iK;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ke;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).l3;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$QuotationOld$Model$AutocompleteField$InputAutocomplete = F5(
	function (value, errorMessage, state, name, validations) {
		return {i4: errorMessage, oq: name, cG: state, lS: validations, p8: value};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Pristine = {$: 2};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$State = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$StateConfig = F7(
	function (focused, selected, filter, isMenuOpen, choices, debouncerState, threshold) {
		return {av: choices, fb: debouncerState, dr: filter, az: focused, Z: isMenuOpen, pC: selected, hT: threshold};
	});
var $elm$core$Basics$round = _Basics_round;
var $Gizra$elm_debouncer$Debouncer$Internal$fromSeconds = function (s) {
	return $elm$core$Basics$round(s * 1000);
};
var $Gizra$elm_debouncer$Debouncer$Basic$fromSeconds = $Gizra$elm_debouncer$Debouncer$Internal$fromSeconds;
var $Gizra$elm_debouncer$Debouncer$Internal$Config = $elm$core$Basics$identity;
var $Gizra$elm_debouncer$Debouncer$Internal$lastInput = F2(
	function (i, o) {
		return $elm$core$Maybe$Just(i);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$manual = {aL: $Gizra$elm_debouncer$Debouncer$Internal$lastInput, ag: $elm$core$Maybe$Nothing, ah: $elm$core$Maybe$Nothing, am: $elm$core$Maybe$Nothing};
var $Gizra$elm_debouncer$Debouncer$Basic$manual = $Gizra$elm_debouncer$Debouncer$Internal$manual;
var $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor = F2(
	function (time, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{am: time});
	});
var $Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor = $Gizra$elm_debouncer$Debouncer$Internal$settleWhenQuietFor;
var $Gizra$elm_debouncer$Debouncer$Internal$Debouncer = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$Settled = {$: 0};
var $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative = $elm$core$Maybe$andThen(
	function (num) {
		return (num < 0) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(num);
	});
var $Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig = function (_v0) {
	var config = _v0;
	return {
		aL: config.aL,
		ag: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.ag),
		ah: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.ah),
		am: $Gizra$elm_debouncer$Debouncer$Internal$nothingIfNegative(config.am)
	};
};
var $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer = function (config) {
	return A2(
		$Gizra$elm_debouncer$Debouncer$Internal$Debouncer,
		$Gizra$elm_debouncer$Debouncer$Internal$sanitizeConfig(config),
		$Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer = $Gizra$elm_debouncer$Debouncer$Internal$toDebouncer;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initDebouncer = function (secondsDebounce) {
	return $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer(
		A2(
			$Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor,
			$elm$core$Maybe$Just(
				$Gizra$elm_debouncer$Debouncer$Basic$fromSeconds(secondsDebounce)),
			$Gizra$elm_debouncer$Debouncer$Basic$manual));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$init = A7(
	$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$StateConfig,
	$elm$core$Maybe$Nothing,
	$elm$core$Maybe$Nothing,
	$elm$core$Maybe$Nothing,
	false,
	$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Pristine,
	$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initDebouncer(0),
	1);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateDebouncer = F2(
	function (debouncerState, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{fb: debouncerState});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$withDebouncer = F2(
	function (secondsDebounce, state) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateDebouncer,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initDebouncer(secondsDebounce),
			state);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$withThreshold = F2(
	function (threshold, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{hT: threshold});
	});
var $author$project$QuotationOld$Model$AutocompleteField$initAutocompleteState = A2(
	$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$withDebouncer,
	0.5,
	A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$withThreshold, 2, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$init));
var $author$project$QuotationOld$Model$AutocompleteField$initAutocompleteField = A3($author$project$QuotationOld$Model$AutocompleteField$InputAutocomplete, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $author$project$QuotationOld$Model$AutocompleteField$initAutocompleteState);
var $author$project$QuotationOld$Model$DatePickerField$InputDatePicker = F6(
	function (value, errorMessage, name, state, isOpen, validations) {
		return {i4: errorMessage, jP: isOpen, oq: name, cG: state, lS: validations, p8: value};
	});
var $author$project$QuotationOld$Model$DatePickerField$initDatePickerField = function (name) {
	return A5(
		$author$project$QuotationOld$Model$DatePickerField$InputDatePicker,
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate($elm$core$Maybe$Nothing),
		$elm$core$Maybe$Nothing,
		name,
		$elm$core$Maybe$Nothing,
		false);
};
var $author$project$QuotationOld$Model$NumberField$InputNumber = F4(
	function (value, errorMessage, name, validations) {
		return {i4: errorMessage, oq: name, lS: validations, p8: value};
	});
var $author$project$QuotationOld$Model$NumberField$initNumberField = A2($author$project$QuotationOld$Model$NumberField$InputNumber, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $author$project$QuotationOld$Model$CheckboxField$PrivacyCommercialPurposes = 2;
var $author$project$QuotationOld$Model$CheckboxField$PrivacyDataProcessingConsent = 1;
var $author$project$QuotationOld$Model$CheckboxField$PrivacyMarketResearch = 3;
var $author$project$QuotationOld$Model$CheckboxField$PrivacyReadPrivacyPolicy = 0;
var $author$project$QuotationOld$Model$CheckboxField$PrivacyThirdParties = 4;
var $author$project$QuotationOld$Model$CheckboxField$InputCheckbox = F4(
	function (value, errorMessage, name, validations) {
		return {i4: errorMessage, oq: name, lS: validations, p8: value};
	});
var $author$project$QuotationOld$Model$CheckboxField$initCheckboxField = A2($author$project$QuotationOld$Model$CheckboxField$InputCheckbox, false, $elm$core$Maybe$Nothing);
var $primait$pyxis_components$Prima$Pyxis$Accordion$State = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Accordion$state = function (isOpen) {
	return isOpen;
};
var $author$project$QuotationOld$Model$FormData$initPrivacyData = {
	h3: $primait$pyxis_components$Prima$Pyxis$Accordion$state(false),
	mL: A2($author$project$QuotationOld$Model$CheckboxField$initCheckboxField, 2, _List_Nil),
	mZ: A2($author$project$QuotationOld$Model$CheckboxField$initCheckboxField, 1, _List_Nil),
	od: A2($author$project$QuotationOld$Model$CheckboxField$initCheckboxField, 3, _List_Nil),
	pf: A2(
		$author$project$QuotationOld$Model$CheckboxField$initCheckboxField,
		0,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	bH: 'privacy-accordion',
	pV: A2($author$project$QuotationOld$Model$CheckboxField$initCheckboxField, 4, _List_Nil)
};
var $author$project$QuotationOld$Model$AutocompleteField$PropertyAddressCity = 0;
var $author$project$QuotationOld$Model$TextField$PropertyAddressInternal = 3;
var $author$project$QuotationOld$Model$TextField$PropertyAddressNumber = 1;
var $author$project$QuotationOld$Model$TextField$PropertyAddressStair = 2;
var $author$project$QuotationOld$Model$TextField$PropertyAddressStreet = 0;
var $author$project$QuotationOld$Model$SelectField$PropertyAddressZipCode = 6;
var $author$project$QuotationOld$Model$SelectField$InputSelect = F5(
	function (value, errorMessage, state, name, validations) {
		return {i4: errorMessage, oq: name, cG: state, lS: validations, p8: value};
	});
var $author$project$Utils$applyTupleAsParameters = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return A2(f, a, b);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$State = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Select$StateConfig = F4(
	function (focused, selected, isMenuOpen, choices) {
		return {av: choices, az: focused, Z: isMenuOpen, pC: selected};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$init = function (choices) {
	return A4($primait$pyxis_components$Prima$Pyxis$Form$Select$StateConfig, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, false, choices);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$SelectChoice = F2(
	function (value, label) {
		return {n6: label, p8: value};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$selectChoice = F2(
	function (value, label) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Select$SelectChoice, value, label);
	});
var $author$project$QuotationOld$Model$SelectField$initSelectState = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		$author$project$Utils$applyTupleAsParameters($primait$pyxis_components$Prima$Pyxis$Form$Select$selectChoice)),
	$primait$pyxis_components$Prima$Pyxis$Form$Select$init);
var $author$project$QuotationOld$Model$SelectField$initSelectField = function (options) {
	return A3(
		$author$project$QuotationOld$Model$SelectField$InputSelect,
		$elm$core$Maybe$Nothing,
		$elm$core$Maybe$Nothing,
		$author$project$QuotationOld$Model$SelectField$initSelectState(options));
};
var $author$project$QuotationOld$Model$TextField$InputText = F5(
	function (value, errorMessage, visible, name, validations) {
		return {i4: errorMessage, oq: name, lS: validations, p8: value, lZ: visible};
	});
var $author$project$QuotationOld$Model$TextField$initTextField = A3($author$project$QuotationOld$Model$TextField$InputText, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, true);
var $author$project$QuotationOld$Model$FormData$initPropertyAddress = {
	my: A2(
		$author$project$QuotationOld$Model$AutocompleteField$initAutocompleteField,
		0,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	nX: A2($author$project$QuotationOld$Model$TextField$initTextField, 3, _List_Nil),
	kp: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		1,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	pJ: A2($author$project$QuotationOld$Model$TextField$initTextField, 2, _List_Nil),
	lx: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		0,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	qg: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		_List_Nil,
		6,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required]))
};
var $author$project$QuotationOld$Model$RadioField$InputRadio = F4(
	function (value, errorMessage, name, validations) {
		return {i4: errorMessage, oq: name, lS: validations, p8: value};
	});
var $author$project$QuotationOld$Model$RadioField$initRadioField = A2($author$project$QuotationOld$Model$RadioField$InputRadio, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $author$project$QuotationOld$Model$AutocompleteField$ResidenceAddressCity = 3;
var $author$project$QuotationOld$Model$TextField$ResidenceAddressInternal = 7;
var $author$project$QuotationOld$Model$TextField$ResidenceAddressNumber = 5;
var $author$project$QuotationOld$Model$TextField$ResidenceAddressStair = 6;
var $author$project$QuotationOld$Model$TextField$ResidenceAddressStreet = 4;
var $author$project$QuotationOld$Model$SelectField$ResidenceAddressZipCode = 7;
var $author$project$QuotationOld$Model$FormData$initResidenceAddress = {
	my: A2(
		$author$project$QuotationOld$Model$AutocompleteField$initAutocompleteField,
		3,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	nX: A2($author$project$QuotationOld$Model$TextField$initTextField, 7, _List_Nil),
	kp: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		5,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	pJ: A2($author$project$QuotationOld$Model$TextField$initTextField, 6, _List_Nil),
	lx: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		4,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	qg: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		_List_Nil,
		7,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required]))
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$QuotationOld$Model$FormData$propertyConstructionPeriodOptions = _List_fromArray(
	[
		_Utils_Tuple2('BEFORE1940', 'Prima del 1940'),
		_Utils_Tuple2('DECADE1940', '1940-1949'),
		_Utils_Tuple2('DECADE1950', '1950-1959'),
		_Utils_Tuple2('DECADE1960', '1960-1969'),
		_Utils_Tuple2('DECADE1970', '1970-1979'),
		_Utils_Tuple2('DECADE1980', '1980-1989'),
		_Utils_Tuple2('DECADE1990', '1990-1999'),
		_Utils_Tuple2('DECADE2000', '2000-2009'),
		_Utils_Tuple2('DECADE2010', '2010-2019'),
		_Utils_Tuple2('DECADE2020', 'Dal 2020 in poi'),
		_Utils_Tuple2('UNKNOWN', 'Non ricordi')
	]);
var $author$project$QuotationOld$Model$FormData$propertyPurposeOptions = _List_fromArray(
	[
		_Utils_Tuple2('RESIDENTIAL', 'Uso abituale'),
		_Utils_Tuple2('SECOND_HOUSE', 'Uso saltuario'),
		_Utils_Tuple2('RENTED', 'Immobile concesso in locazione'),
		_Utils_Tuple2('UNINHABITED', 'Non abitato')
	]);
var $author$project$QuotationOld$Model$FormData$reportedAccidentSelectOptions = _List_fromArray(
	[
		_Utils_Tuple2('0', 'Nessun sinistro'),
		_Utils_Tuple2('1', '1'),
		_Utils_Tuple2('2', '2 o più')
	]);
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$QuotationOld$Model$FormData$initFormData = {
	cY: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		1,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	ih: A2(
		$author$project$QuotationOld$Model$AutocompleteField$initAutocompleteField,
		1,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	mj: A2(
		$author$project$QuotationOld$Model$AutocompleteField$initAutocompleteField,
		2,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	mn: A2(
		$author$project$QuotationOld$Model$DatePickerField$initDatePickerField,
		0,
		_List_fromArray(
			[
				$author$project$QuotationOld$Update$Validation$Required,
				A2($author$project$QuotationOld$Update$Validation$AgeInRange, 18, 100)
			])),
	iu: $elm$core$Dict$empty,
	mN: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		2,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	mP: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$propertyConstructionPeriodOptions,
		2,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	iH: A2(
		$justinmimbs$date$Date$fromPosix,
		$elm$time$Time$utc,
		$elm$time$Time$millisToPosix(0)),
	fi: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		10,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required, $author$project$QuotationOld$Update$Validation$ValidEmail])),
	np: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$familyAdultsOptions,
		4,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	nr: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$familyUnderagesOptions,
		5,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	nw: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$flatFloorOptions,
		1,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	ny: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		6,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	dI: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		4,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	dJ: A2(
		$author$project$QuotationOld$Model$NumberField$initNumberField,
		1,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	oq: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		8,
		_List_fromArray(
			[
				$author$project$QuotationOld$Update$Validation$Required,
				$author$project$QuotationOld$Update$Validation$Min(2)
			])),
	U: $elm$core$Maybe$Nothing,
	w: $elm$core$Maybe$Nothing,
	oL: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		0,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	oT: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		11,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required, $author$project$QuotationOld$Update$Validation$ValidPhone])),
	oZ: $author$project$QuotationOld$Model$FormData$initPrivacyData,
	kY: $author$project$QuotationOld$Model$FormData$initPropertyAddress,
	o1: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$propertyPurposeOptions,
		0,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	o2: A2(
		$author$project$QuotationOld$Model$NumberField$initNumberField,
		0,
		_List_fromArray(
			[
				$author$project$QuotationOld$Update$Validation$Required,
				$author$project$QuotationOld$Update$Validation$Min(20),
				$author$project$QuotationOld$Update$Validation$Max(500)
			])),
	o4: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		5,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	hf: $elm$core$Maybe$Nothing,
	pn: A3(
		$author$project$QuotationOld$Model$SelectField$initSelectField,
		$author$project$QuotationOld$Model$FormData$reportedAccidentSelectOptions,
		3,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required])),
	pq: $author$project$QuotationOld$Model$FormData$initResidenceAddress,
	hn: $elm$core$Maybe$Nothing,
	hM: _List_Nil,
	lC: A2(
		$author$project$QuotationOld$Model$TextField$initTextField,
		9,
		_List_fromArray(
			[
				$author$project$QuotationOld$Update$Validation$Required,
				$author$project$QuotationOld$Update$Validation$Min(2)
			])),
	qa: A2(
		$author$project$QuotationOld$Model$RadioField$initRadioField,
		3,
		_List_fromArray(
			[$author$project$QuotationOld$Update$Validation$Required]))
};
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $author$project$Types$pickSeed = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.lm;
	});
var $author$project$QuotationOld$Model$initialModel = function (flags) {
	return {
		iI: $elm$random$Random$initialSeed(
			$author$project$Types$pickSeed(flags)),
		nv: flags,
		dv: $author$project$QuotationOld$Model$FormData$initFormData,
		fp: $author$project$QuotationOld$Model$FormStep$PropertyAddressStep(1),
		je: $elm$core$Maybe$Nothing,
		fK: true,
		ks: $elm$core$Maybe$Nothing,
		U: $elm$core$Maybe$Nothing,
		w: $elm$core$Maybe$Nothing,
		lP: $elm$core$Maybe$Nothing,
		lR: 0
	};
};
var $author$project$ThankYou$Model$DownloadDone = 1;
var $author$project$ThankYou$Model$initialModel = function (flags) {
	return {iW: 1, fi: $elm$core$Maybe$Nothing, nv: flags, nV: $elm$core$Maybe$Nothing, jA: $elm$core$Maybe$Nothing, jB: $krisajenkins$remotedata$RemoteData$NotAsked, j4: $elm$core$Maybe$Nothing, kw: $elm$core$Maybe$Nothing, g4: $krisajenkins$remotedata$RemoteData$NotAsked, p2: $elm$core$Maybe$Nothing, lR: 0};
};
var $author$project$Types$pickChallengeProviderUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.mu;
	});
var $author$project$Types$pickLiraUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.fS;
	});
var $author$project$Types$pickMastrotaUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.fV;
	});
var $author$project$Types$mapStaticFlags = F2(
	function (mapper, flags) {
		return _Utils_update(
			flags,
			{
				z: mapper(flags.z)
			});
	});
var $author$project$Types$setApiUrl = function (value) {
	return $author$project$Types$mapStaticFlags(
		function (staticFlags) {
			return _Utils_update(
				staticFlags,
				{mc: value});
		});
};
var $author$project$Types$setLiraUrl = function (value) {
	return $author$project$Types$mapStaticFlags(
		function (staticFlags) {
			return _Utils_update(
				staticFlags,
				{fS: value});
		});
};
var $author$project$Types$setMastrotaUrl = function (value) {
	return $author$project$Types$mapStaticFlags(
		function (staticFlags) {
			return _Utils_update(
				staticFlags,
				{fV: value});
		});
};
var $author$project$Routing$NotFound = {$: 7};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aP: frag, a_: params, aK: unvisited, p8: value, a6: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aK;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.p8);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.p8);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.kC),
					$elm$url$Url$Parser$prepareQuery(url.k4),
					url.jh,
					$elm$core$Basics$identity)));
	});
var $author$project$Routing$FamilyForm = 1;
var $author$project$Routing$HomeForm = 0;
var $author$project$Routing$Homepage = {$: 0};
var $author$project$Routing$Quotation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Routing$Checkout = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $danyx23$elm_uuid$Uuid$Uuid = $elm$core$Basics$identity;
var $elm$regex$Regex$contains = _Regex_contains;
var $danyx23$elm_uuid$Uuid$Barebones$uuidRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[0-9A-Fa-f]{8,8}-[0-9A-Fa-f]{4,4}-[1-5][0-9A-Fa-f]{3,3}-[8-9A-Ba-b][0-9A-Fa-f]{3,3}-[0-9A-Fa-f]{12,12}$'));
var $danyx23$elm_uuid$Uuid$Barebones$isValidUuid = function (uuidAsString) {
	return A2($elm$regex$Regex$contains, $danyx23$elm_uuid$Uuid$Barebones$uuidRegex, uuidAsString);
};
var $elm$core$String$toLower = _String_toLower;
var $danyx23$elm_uuid$Uuid$fromString = function (text) {
	return $danyx23$elm_uuid$Uuid$Barebones$isValidUuid(text) ? $elm$core$Maybe$Just(
		$elm$core$String$toLower(text)) : $elm$core$Maybe$Nothing;
};
var $author$project$Routing$FamilyQuotation = 1;
var $author$project$Routing$HomeQuotation = 0;
var $author$project$Routing$quotationTypeToString = function (quotationType) {
	switch (quotationType) {
		case 0:
			return $elm$core$Maybe$Just('casaefamiglia');
		case 1:
			return $elm$core$Maybe$Just('famiglia');
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routing$quotationTypeQueryStringMapper = function (queryStringParameter) {
	return _Utils_eq(
		queryStringParameter,
		$author$project$Routing$quotationTypeToString(0)) ? 0 : (_Utils_eq(
		queryStringParameter,
		$author$project$Routing$quotationTypeToString(1)) ? 1 : 2);
};
var $author$project$Routing$checkoutUrlParserWithStep = F3(
	function (quoteUuid, step, maybeQuotationTypeString) {
		var quotationType = $author$project$Routing$quotationTypeQueryStringMapper(maybeQuotationTypeString);
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$NotFound,
			A2(
				$elm$core$Maybe$map,
				function (qUuid) {
					return A3($author$project$Routing$Checkout, quotationType, qUuid, step);
				},
				$danyx23$elm_uuid$Uuid$fromString(quoteUuid)));
	});
var $author$project$Routing$checkoutUrlParser = F2(
	function (quoteUuid, quotationType) {
		return A3($author$project$Routing$checkoutUrlParserWithStep, quoteUuid, 1, quotationType);
	});
var $author$project$Routing$Guarantees = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Routing$guaranteesQuoteUrlParser = F2(
	function (quoteUuid, maybeQuotationTypeString) {
		var quotationType = $author$project$Routing$quotationTypeQueryStringMapper(maybeQuotationTypeString);
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$NotFound,
			A2(
				$elm$core$Maybe$map,
				function (qUuid) {
					return A3($author$project$Routing$Guarantees, quotationType, qUuid, $elm$core$Maybe$Nothing);
				},
				$danyx23$elm_uuid$Uuid$fromString(quoteUuid)));
	});
var $author$project$Routing$guaranteesQuoteWithSaveUrlParser = F3(
	function (quoteUuid, saveId, maybeQuotationTypeString) {
		var quotationType = $author$project$Routing$quotationTypeQueryStringMapper(maybeQuotationTypeString);
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$NotFound,
			A2(
				$elm$core$Maybe$map,
				function (qUuid) {
					return A3(
						$author$project$Routing$Guarantees,
						quotationType,
						qUuid,
						$danyx23$elm_uuid$Uuid$fromString(saveId));
				},
				$danyx23$elm_uuid$Uuid$fromString(quoteUuid)));
	});
var $author$project$Routing$insuranceIdParameterName = 'insuranceId';
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.a6;
			var unvisited = _v0.aK;
			var params = _v0.a_;
			var frag = _v0.aP;
			var value = _v0.p8;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.a6;
		var unvisited = _v0.aK;
		var params = _v0.a_;
		var frag = _v0.aP;
		var value = _v0.p8;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.a6;
			var unvisited = _v1.aK;
			var params = _v1.a_;
			var frag = _v1.aP;
			var value = _v1.p8;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $author$project$Routing$Modify = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Routing$modifyUrlParser = F2(
	function (quoteId, saveId) {
		return A2(
			$author$project$Routing$Modify,
			A2($elm$core$Maybe$andThen, $danyx23$elm_uuid$Uuid$fromString, quoteId),
			A2($elm$core$Maybe$andThen, $danyx23$elm_uuid$Uuid$fromString, saveId));
	});
var $author$project$Routing$NotAvailable = function (a) {
	return {$: 6, a: a};
};
var $author$project$NotAvailable$Model$NotOwner = {$: 1};
var $author$project$NotAvailable$Model$OfferPageError = {$: 5};
var $author$project$NotAvailable$Model$OfferPageNotAvailable = {$: 6};
var $author$project$NotAvailable$Model$QuoteNotAvailable = {$: 7};
var $author$project$NotAvailable$Model$SaveExpired = function (a) {
	return {$: 2, a: a};
};
var $author$project$NotAvailable$Model$SaveNotPurchasable = {$: 3};
var $author$project$NotAvailable$Model$SavePurchased = {$: 4};
var $author$project$NotAvailable$Model$Uninhabited = {$: 0};
var $author$project$NotAvailable$Utils$stringToReason = F2(
	function (string, saveId) {
		switch (string) {
			case 'uninhabited':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$Uninhabited);
			case 'not-owner':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$NotOwner);
			case 'save-expired':
				return $elm$core$Maybe$Just(
					$author$project$NotAvailable$Model$SaveExpired(
						A2($elm$core$Maybe$andThen, $danyx23$elm_uuid$Uuid$fromString, saveId)));
			case 'save-purchased':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$SavePurchased);
			case 'save-not-purchasable':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$SaveNotPurchasable);
			case 'offer-error':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$OfferPageError);
			case 'offer-not-available':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$OfferPageNotAvailable);
			case 'quote-not-available':
				return $elm$core$Maybe$Just($author$project$NotAvailable$Model$QuoteNotAvailable);
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Routing$notAvailableUrlParser = F2(
	function (string, saveId) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Routing$NotFound,
			$author$project$Routing$NotAvailable,
			A2($author$project$NotAvailable$Utils$stringToReason, string, saveId));
	});
var $author$project$Routing$OneClick = function (a) {
	return {$: 8, a: a};
};
var $author$project$Routing$oneClickUrlParser = function (offerId) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$author$project$Routing$NotFound,
		$author$project$Routing$OneClick,
		$danyx23$elm_uuid$Uuid$fromString(offerId));
};
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.a6;
		var unvisited = _v1.aK;
		var params = _v1.a_;
		var frag = _v1.aP;
		var value = _v1.p8;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $author$project$Routing$quotationTypeParameterName = 'quotationType';
var $author$project$Routing$quoteIdParameterName = 'quoteId';
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.a6;
		var unvisited = _v0.aK;
		var params = _v0.a_;
		var frag = _v0.aP;
		var value = _v0.p8;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$Routing$saveIdParameterName = 'saveId';
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$Routing$ThankYou = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Routing$FamilyThankYouPage = 1;
var $author$project$Routing$HomeThankYouPage = 0;
var $author$project$Routing$OneClickThankYouPage = 2;
var $author$project$Routing$UnsetThankYouPageType = 3;
var $author$project$Routing$thankYouPageTypeToString = function (thankYouPageType) {
	switch (thankYouPageType) {
		case 0:
			return $elm$core$Maybe$Just('casaefamiglia');
		case 1:
			return $elm$core$Maybe$Just('famiglia');
		case 2:
			return $elm$core$Maybe$Just('oneclick');
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routing$thankYouPageTypeQueryStringMapper = function (queryStringParameter) {
	return _Utils_eq(
		queryStringParameter,
		$author$project$Routing$thankYouPageTypeToString(0)) ? 0 : (_Utils_eq(
		queryStringParameter,
		$author$project$Routing$thankYouPageTypeToString(1)) ? 1 : (_Utils_eq(
		queryStringParameter,
		$author$project$Routing$thankYouPageTypeToString(2)) ? 2 : 3));
};
var $author$project$Routing$thankYouUrlParser = F3(
	function (orderId, maybeInsuranceId, maybeThankYouPageTypeString) {
		var thankYouPageType = $author$project$Routing$thankYouPageTypeQueryStringMapper(maybeThankYouPageTypeString);
		var insuranceUuid = A2($elm$core$Maybe$andThen, $danyx23$elm_uuid$Uuid$fromString, maybeInsuranceId);
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$NotFound,
			A2(
				$elm$core$Maybe$map,
				function (orderUuid) {
					return A3($author$project$Routing$ThankYou, thankYouPageType, orderUuid, insuranceUuid);
				},
				$danyx23$elm_uuid$Uuid$fromString(orderId)));
	});
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routing$route = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Routing$Homepage, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$Quotation(0),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo-casa'),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$Quotation(1),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo-famiglia'),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$guaranteesQuoteUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('garanzie'),
						$elm$url$Url$Parser$Query$string($author$project$Routing$quotationTypeParameterName))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$guaranteesQuoteWithSaveUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('salvataggio'),
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$string,
							A2(
								$elm$url$Url$Parser$questionMark,
								$elm$url$Url$Parser$s('garanzie'),
								$elm$url$Url$Parser$Query$string($author$project$Routing$quotationTypeParameterName))))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$checkoutUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$questionMark,
						$elm$url$Url$Parser$s('pagamento'),
						$elm$url$Url$Parser$Query$string($author$project$Routing$quotationTypeParameterName))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$checkoutUrlParserWithStep,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$s('pagamento'),
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$int,
							$elm$url$Url$Parser$Query$string($author$project$Routing$quotationTypeParameterName)))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$thankYouUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('preventivo'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$questionMark,
						A2(
							$elm$url$Url$Parser$questionMark,
							$elm$url$Url$Parser$s('grazie'),
							$elm$url$Url$Parser$Query$string($author$project$Routing$insuranceIdParameterName)),
						$elm$url$Url$Parser$Query$string($author$project$Routing$quotationTypeParameterName))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$oneClickUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('offer'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$modifyUrlParser,
			A2(
				$elm$url$Url$Parser$questionMark,
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('modifica'),
					$elm$url$Url$Parser$Query$string($author$project$Routing$quoteIdParameterName)),
				$elm$url$Url$Parser$Query$string($author$project$Routing$saveIdParameterName))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$notAvailableUrlParser,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('not-available'),
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$Query$string($author$project$Routing$saveIdParameterName))))
		]));
var $author$project$Routing$toRoute = function (string) {
	var _v0 = $elm$url$Url$fromString(string);
	if (_v0.$ === 1) {
		return $author$project$Routing$NotFound;
	} else {
		var url = _v0.a;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$NotFound,
			A2($elm$url$Url$Parser$parse, $author$project$Routing$route, url));
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.k3;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.jh,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.k4,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.kG,
					_Utils_ap(http, url.ju)),
				url.kC)));
};
var $author$project$Model$allowedTokenFromParamUrl = function (route) {
	switch (route.$) {
		case 2:
			return true;
		case 3:
			return true;
		case 5:
			return true;
		case 8:
			return true;
		default:
			return false;
	}
};
var $elm$core$Basics$not = _Basics_not;
var $author$project$Model$tokenConfigByParamRouteGuard = F2(
	function (route, tokenConfig) {
		return (tokenConfig.lJ && (!$author$project$Model$allowedTokenFromParamUrl(route))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(tokenConfig);
	});
var $author$project$RFQDTS$Http$Authenticator$ClientId = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$websiteClientId = 'prima.website';
var $author$project$Model$initialModel = F6(
	function (flags, key, url, regions, provincesResponse, appStatus) {
		var updatedFlags = A2(
			$author$project$Types$setMastrotaUrl,
			$author$project$Types$pickMastrotaUrl(flags) + '/crossql',
			A2(
				$author$project$Types$setLiraUrl,
				$author$project$Types$pickLiraUrl(flags) + '/graphql',
				A2(
					$author$project$Types$setApiUrl,
					$author$project$Types$pickApiUrl(flags) + '/graphql',
					flags)));
		var currentRoute = $author$project$Routing$toRoute(
			$elm$url$Url$toString(url));
		var updatedTokenConfig = A2(
			$elm$core$Maybe$andThen,
			$author$project$Model$tokenConfigByParamRouteGuard(currentRoute),
			$author$project$Types$pickTokenConfig(updatedFlags));
		var _v0 = $author$project$CookieBot$init(
			{dc: 'f6d47d34-f1bc-40bc-bf15-624bb9b1febe', dk: $elm$core$Maybe$Nothing, ey: $author$project$Model$CookieBotInnerMsg});
		var cookieBot = _v0.a;
		var cookieBotCmd = _v0.b;
		var _v1 = $author$project$RFQDTS$Http$Authenticator$initWithAntiSpiderOnly(
			{
				mu: $author$project$Types$pickChallengeProviderUrl(flags),
				mD: $author$project$RFQDTS$Http$Authenticator$websiteClientId,
				op: $author$project$Model$AuthenticatorMsg
			});
		var authenticatorModel = _v1.a;
		var authenticatorMsg = _v1.b;
		return _Utils_Tuple2(
			$author$project$Model$Model(updatedFlags)(currentRoute)(
				$author$project$Preselection$Preselection$initialModel(updatedFlags))(
				$author$project$QuotationOld$Model$initialModel(updatedFlags))(
				$author$project$Quotation$Model$initialModel(updatedFlags))(
				A2($author$project$Guarantees$Model$initialModel, updatedFlags, 2))(
				A2($author$project$Checkout$Model$initModel, updatedFlags, 2))(
				$author$project$ThankYou$Model$initialModel(updatedFlags))(
				$author$project$OneClick$Model$initialModel(updatedFlags))(
				$author$project$NotAvailable$Model$initialModel(updatedFlags))(key)(url)(false)(
				$author$project$Model$gqlClientModelConfig(updatedFlags))(authenticatorModel)(updatedTokenConfig)($elm$core$Maybe$Nothing)(regions)(provincesResponse)(appStatus)(cookieBot),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[authenticatorMsg, cookieBotCmd])));
	});
var $author$project$Model$ReceivedToken = function (a) {
	return {$: 12, a: a};
};
var $author$project$Gql$Vianello$Mutation$anonymousToken = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'anonymousToken', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Union$AnonymousTokenResult$Fragments = F2(
	function (onAnonymousTokenOk, onAnonymousTokenError) {
		return {f4: onAnonymousTokenError, f5: onAnonymousTokenOk};
	});
var $author$project$Gql$Vianello$Union$AnonymousTokenResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'AnonymousTokenOk', selections____.f5),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'AnonymousTokenError', selections____.f4)
			]));
};
var $author$project$Gql$Vianello$Object$AnonymousTokenError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Token$tokenErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$AnonymousTokenError$message,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenError));
var $author$project$Api$Token$onAnonymousTokenErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$tokenErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$AnonymousTokenError));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $author$project$Gql$Vianello$Object$AnonymousTokenOk$refreshToken = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'refreshToken', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$AnonymousTokenOk$token = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'token', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Token$tokenOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $elm$core$Maybe$Just, $author$project$Gql$Vianello$Object$AnonymousTokenOk$refreshToken),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$AnonymousTokenOk$token,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenOk)));
var $author$project$Api$Token$onAnonymousTokenOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$tokenOkSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$AnonymousTokenOk));
var $author$project$Api$Token$anonymousTokenSelection = $author$project$Gql$Vianello$Union$AnonymousTokenResult$fragments(
	A2($author$project$Gql$Vianello$Union$AnonymousTokenResult$Fragments, $author$project$Api$Token$onAnonymousTokenOkSelection, $author$project$Api$Token$onAnonymousTokenErrorSelection));
var $author$project$Api$Token$anonymousTokenMutation = $author$project$Gql$Vianello$Mutation$anonymousToken($author$project$Api$Token$anonymousTokenSelection);
var $author$project$Api$AuthClient$getToken = F2(
	function (apiUrl, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			msg,
			A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, apiUrl, $author$project$Api$Token$anonymousTokenMutation));
	});
var $author$project$Update$maybeTokenCommand = F2(
	function (tokenConfig, apiUrl) {
		if (tokenConfig.$ === 1) {
			return $elm$core$Maybe$Just(
				A2($author$project$Api$AuthClient$getToken, apiUrl, $author$project$Model$ReceivedToken));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Api$GetUser = function (a) {
	return {$: 8, a: a};
};
var $author$project$Types$RootQueryMsg$GetUser = $elm$core$Basics$identity;
var $author$project$Api$RootSource = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$QueryOp = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$buildQuery = F2(
	function (source, selection) {
		return {
			hs: $author$project$Api$QueryOp(selection),
			lu: source
		};
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$PrimaElm$Lib$GqlClient$Model$Mutation = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$Transient = 0;
var $author$project$PrimaElm$Lib$GqlClient$Model$FirstAttemptFinished = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$SecondAttemptFinished = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$getElementsInPipeline = function (_v0) {
	var pipeline = _v0.bz;
	return pipeline;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$addElementToPipeline = F2(
	function (query, model) {
		var modelData = model;
		return _Utils_update(
			modelData,
			{
				bz: A2(
					$elm$core$List$cons,
					query,
					$author$project$PrimaElm$Lib$GqlClient$Model$getElementsInPipeline(model))
			});
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $author$project$PrimaElm$Lib$GqlClient$Config$getAuthentication = function (_v0) {
	var configData = _v0;
	return configData.eZ;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$getConfig = function (_v0) {
	var config = _v0.iC;
	return config;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$getAuthentication = A2($elm$core$Basics$composeL, $author$project$PrimaElm$Lib$GqlClient$Config$getAuthentication, $author$project$PrimaElm$Lib$GqlClient$Model$getConfig);
var $author$project$PrimaElm$Lib$GqlClient$Model$getPipelineElementName = function (pipelineElement) {
	if (!pipelineElement.$) {
		var name = pipelineElement.a;
		return name;
	} else {
		var name = pipelineElement.a;
		return name;
	}
};
var $author$project$PrimaElm$Lib$GqlClient$Config$getUrl = function (_v0) {
	var url = _v0.lO;
	return url;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$getUrl = A2($elm$core$Basics$composeL, $author$project$PrimaElm$Lib$GqlClient$Config$getUrl, $author$project$PrimaElm$Lib$GqlClient$Model$getConfig);
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			bT: baseUrl,
			m4: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			nk: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			nH: _List_Nil,
			aY: $elm$core$Maybe$Nothing,
			a0: _List_Nil,
			pW: $elm$core$Maybe$Nothing,
			a7: false
		};
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $dillonkearns$elm_graphql$Graphql$Http$withHeader = F3(
	function (key, value, _v0) {
		var request = _v0;
		return _Utils_update(
			request,
			{
				nH: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					request.nH)
			});
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$doRunQuery = F3(
	function (attempt, model, pipelineElement) {
		var request = function () {
			if (!pipelineElement.$) {
				var selectionSet = pipelineElement.b;
				return A2(
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$PrimaElm$Lib$GqlClient$Model$getUrl(model),
					selectionSet);
			} else {
				var selectionSet = pipelineElement.b;
				return A2(
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$PrimaElm$Lib$GqlClient$Model$getUrl(model),
					selectionSet);
			}
		}();
		var queryId = 1 + $elm$core$List$length(
			$author$project$PrimaElm$Lib$GqlClient$Model$getElementsInPipeline(model));
		var tagger = function () {
			if (!attempt) {
				return A2(
					$author$project$PrimaElm$Lib$GqlClient$Model$FirstAttemptFinished,
					queryId,
					$author$project$PrimaElm$Lib$GqlClient$Model$getPipelineElementName(pipelineElement));
			} else {
				return A2(
					$author$project$PrimaElm$Lib$GqlClient$Model$SecondAttemptFinished,
					queryId,
					$author$project$PrimaElm$Lib$GqlClient$Model$getPipelineElementName(pipelineElement));
			}
		}();
		var authenticatedQuery = A3(
			$elm$core$List$foldl,
			function (authHeader) {
				return A2($dillonkearns$elm_graphql$Graphql$Http$withHeader, authHeader.a, authHeader.b);
			},
			request,
			$author$project$PrimaElm$Lib$GqlClient$Model$getAuthentication(model));
		return _Utils_Tuple2(
			A2(
				$author$project$PrimaElm$Lib$GqlClient$Model$addElementToPipeline,
				_Utils_Tuple2(queryId, pipelineElement),
				model),
			A2(
				$dillonkearns$elm_graphql$Graphql$Http$send,
				A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, tagger),
				authenticatedQuery));
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$runMutation = F2(
	function (name, model) {
		return A2(
			$elm$core$Basics$composeL,
			A2($author$project$PrimaElm$Lib$GqlClient$GqlClient$doRunQuery, 0, model),
			$author$project$PrimaElm$Lib$GqlClient$Model$Mutation(name));
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$runQuery = F2(
	function (name, model) {
		return A2(
			$elm$core$Basics$composeL,
			A2($author$project$PrimaElm$Lib$GqlClient$GqlClient$doRunQuery, 0, model),
			$author$project$PrimaElm$Lib$GqlClient$Model$Query(name));
	});
var $author$project$Api$AuthClient$updateGqlClient = F2(
	function (gqModel, model) {
		return _Utils_update(
			model,
			{dy: gqModel});
	});
var $author$project$Utils$withCommands = F2(
	function (cmds, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(cmds));
	});
var $author$project$Api$AuthClient$mapOperationToCommand = F2(
	function (model, _v0) {
		var gqlClientModel = model.dy;
		var source = _v0.lu;
		var selection = _v0.hs;
		var updatedReturn = function () {
			if (!selection.$) {
				var query = selection.a;
				return A3($author$project$PrimaElm$Lib$GqlClient$GqlClient$runQuery, source, gqlClientModel, query);
			} else {
				var mutation = selection.a;
				return A3($author$project$PrimaElm$Lib$GqlClient$GqlClient$runMutation, source, gqlClientModel, mutation);
			}
		}();
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					A2($elm$core$Platform$Cmd$map, $author$project$Model$GqlClientMsg, updatedReturn.b)
				]),
			A2($author$project$Api$AuthClient$updateGqlClient, updatedReturn.a, model));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Types$AgentToken = 1;
var $simonh1000$elm_jwt$Jwt$TokenDecodeError = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $simonh1000$elm_jwt$Jwt$TokenHeaderError = {$: 2};
var $simonh1000$elm_jwt$Jwt$TokenProcessingError = function (a) {
	return {$: 0, a: a};
};
var $simonh1000$elm_jwt$Jwt$fixlength = function (s) {
	var _v0 = A2(
		$elm$core$Basics$modBy,
		4,
		$elm$core$String$length(s));
	switch (_v0) {
		case 0:
			return $elm$core$Result$Ok(s);
		case 2:
			return $elm$core$Result$Ok(
				$elm$core$String$concat(
					_List_fromArray(
						[s, '=='])));
		case 3:
			return $elm$core$Result$Ok(
				$elm$core$String$concat(
					_List_fromArray(
						[s, '='])));
		default:
			return $elm$core$Result$Err(
				$simonh1000$elm_jwt$Jwt$TokenProcessingError('Wrong length'));
	}
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$string = function (n) {
	return _Bytes_read_string(n);
};
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$BE = 1;
var $danfishgold$base64_bytes$Encode$isValidChar = function (c) {
	if ($elm$core$Char$isAlphaNum(c)) {
		return true;
	} else {
		switch (c) {
			case '+':
				return true;
			case '/':
				return true;
			default:
				return false;
		}
	}
};
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $danfishgold$base64_bytes$Encode$unsafeConvertChar = function (_char) {
	var key = $elm$core$Char$toCode(_char);
	if ((key >= 65) && (key <= 90)) {
		return key - 65;
	} else {
		if ((key >= 97) && (key <= 122)) {
			return (key - 97) + 26;
		} else {
			if ((key >= 48) && (key <= 57)) {
				return ((key - 48) + 26) + 26;
			} else {
				switch (_char) {
					case '+':
						return 62;
					case '/':
						return 63;
					default:
						return -1;
				}
			}
		}
	}
};
var $elm$bytes$Bytes$Encode$U16 = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt16 = $elm$bytes$Bytes$Encode$U16;
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $danfishgold$base64_bytes$Encode$encodeCharacters = F4(
	function (a, b, c, d) {
		if ($danfishgold$base64_bytes$Encode$isValidChar(a) && $danfishgold$base64_bytes$Encode$isValidChar(b)) {
			var n2 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(b);
			var n1 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(a);
			if ('=' === d) {
				if ('=' === c) {
					var n = (n1 << 18) | (n2 << 12);
					var b1 = n >> 16;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$unsignedInt8(b1));
				} else {
					if ($danfishgold$base64_bytes$Encode$isValidChar(c)) {
						var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
						var n = ((n1 << 18) | (n2 << 12)) | (n3 << 6);
						var combined = n >> 8;
						return $elm$core$Maybe$Just(
							A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			} else {
				if ($danfishgold$base64_bytes$Encode$isValidChar(c) && $danfishgold$base64_bytes$Encode$isValidChar(d)) {
					var n4 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(d);
					var n3 = $danfishgold$base64_bytes$Encode$unsafeConvertChar(c);
					var n = ((n1 << 18) | (n2 << 12)) | ((n3 << 6) | n4);
					var combined = n >> 8;
					var b3 = n;
					return $elm$core$Maybe$Just(
						$elm$bytes$Bytes$Encode$sequence(
							_List_fromArray(
								[
									A2($elm$bytes$Bytes$Encode$unsignedInt16, 1, combined),
									$elm$bytes$Bytes$Encode$unsignedInt8(b3)
								])));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $danfishgold$base64_bytes$Encode$encodeChunks = F2(
	function (input, accum) {
		encodeChunks:
		while (true) {
			var _v0 = $elm$core$String$toList(
				A2($elm$core$String$left, 4, input));
			_v0$4:
			while (true) {
				if (!_v0.b) {
					return $elm$core$Maybe$Just(accum);
				} else {
					if (_v0.b.b) {
						if (_v0.b.b.b) {
							if (_v0.b.b.b.b) {
								if (!_v0.b.b.b.b.b) {
									var a = _v0.a;
									var _v1 = _v0.b;
									var b = _v1.a;
									var _v2 = _v1.b;
									var c = _v2.a;
									var _v3 = _v2.b;
									var d = _v3.a;
									var _v4 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, d);
									if (!_v4.$) {
										var enc = _v4.a;
										var $temp$input = A2($elm$core$String$dropLeft, 4, input),
											$temp$accum = A2($elm$core$List$cons, enc, accum);
										input = $temp$input;
										accum = $temp$accum;
										continue encodeChunks;
									} else {
										return $elm$core$Maybe$Nothing;
									}
								} else {
									break _v0$4;
								}
							} else {
								var a = _v0.a;
								var _v5 = _v0.b;
								var b = _v5.a;
								var _v6 = _v5.b;
								var c = _v6.a;
								var _v7 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, c, '=');
								if (_v7.$ === 1) {
									return $elm$core$Maybe$Nothing;
								} else {
									var enc = _v7.a;
									return $elm$core$Maybe$Just(
										A2($elm$core$List$cons, enc, accum));
								}
							}
						} else {
							var a = _v0.a;
							var _v8 = _v0.b;
							var b = _v8.a;
							var _v9 = A4($danfishgold$base64_bytes$Encode$encodeCharacters, a, b, '=', '=');
							if (_v9.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var enc = _v9.a;
								return $elm$core$Maybe$Just(
									A2($elm$core$List$cons, enc, accum));
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
			return $elm$core$Maybe$Nothing;
		}
	});
var $danfishgold$base64_bytes$Encode$encoder = function (string) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeR, $elm$core$List$reverse, $elm$bytes$Bytes$Encode$sequence),
		A2($danfishgold$base64_bytes$Encode$encodeChunks, string, _List_Nil));
};
var $danfishgold$base64_bytes$Encode$toBytes = function (string) {
	return A2(
		$elm$core$Maybe$map,
		$elm$bytes$Bytes$Encode$encode,
		$danfishgold$base64_bytes$Encode$encoder(string));
};
var $danfishgold$base64_bytes$Base64$toBytes = $danfishgold$base64_bytes$Encode$toBytes;
var $elm$bytes$Bytes$width = _Bytes_width;
var $danfishgold$base64_bytes$Base64$toString = function (b64String) {
	var _v0 = $danfishgold$base64_bytes$Base64$toBytes(b64String);
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var b64Bytes = _v0.a;
		return A2(
			$elm$bytes$Bytes$Decode$decode,
			$elm$bytes$Bytes$Decode$string(
				$elm$bytes$Bytes$width(b64Bytes)),
			b64Bytes);
	}
};
var $elm$core$String$map = _String_map;
var $simonh1000$elm_jwt$Jwt$unurl = function () {
	var fix = function (c) {
		switch (c) {
			case '-':
				return '+';
			case '_':
				return '/';
			default:
				return c;
		}
	};
	return $elm$core$String$map(fix);
}();
var $simonh1000$elm_jwt$Jwt$getTokenParts = function (token) {
	var verifyJson = F2(
		function (errorHandler, str) {
			return A2(
				$elm$core$Result$mapError,
				errorHandler,
				A2(
					$elm$core$Result$map,
					function (_v8) {
						return str;
					},
					A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, str)));
		});
	var processor = A2(
		$elm$core$Basics$composeR,
		$simonh1000$elm_jwt$Jwt$unurl,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split('.'),
			$elm$core$List$map($simonh1000$elm_jwt$Jwt$fixlength)));
	var _v0 = processor(token);
	_v0$1:
	while (true) {
		if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.b.b)) {
			if (!_v0.a.$) {
				if (!_v0.b.a.$) {
					var header = _v0.a.a;
					var _v1 = _v0.b;
					var body = _v1.a.a;
					var _v2 = _v1.b;
					var header_ = A2(
						$elm$core$Result$andThen,
						verifyJson(
							function (_v3) {
								return $simonh1000$elm_jwt$Jwt$TokenHeaderError;
							}),
						A2(
							$elm$core$Result$fromMaybe,
							$simonh1000$elm_jwt$Jwt$TokenHeaderError,
							$danfishgold$base64_bytes$Base64$toString(header)));
					var body_ = A2(
						$elm$core$Result$andThen,
						verifyJson($simonh1000$elm_jwt$Jwt$TokenDecodeError),
						A2(
							$elm$core$Result$fromMaybe,
							$simonh1000$elm_jwt$Jwt$TokenProcessingError('Invalid UTF-16'),
							$danfishgold$base64_bytes$Base64$toString(body)));
					return A3(
						$elm$core$Result$map2,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						header_,
						body_);
				} else {
					break _v0$1;
				}
			} else {
				if (_v0.b.a.$ === 1) {
					break _v0$1;
				} else {
					var err = _v0.a.a;
					var _v6 = _v0.b;
					var _v7 = _v6.b;
					return $elm$core$Result$Err(err);
				}
			}
		} else {
			return $elm$core$Result$Err(
				$simonh1000$elm_jwt$Jwt$TokenProcessingError('Token has invalid shape'));
		}
	}
	var _v4 = _v0.b;
	var err = _v4.a.a;
	var _v5 = _v4.b;
	return $elm$core$Result$Err(err);
};
var $simonh1000$elm_jwt$Jwt$decodeToken = F2(
	function (dec, token) {
		return A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeString(dec),
				$elm$core$Result$mapError($simonh1000$elm_jwt$Jwt$TokenDecodeError)),
			A2(
				$elm$core$Result$map,
				$elm$core$Tuple$second,
				$simonh1000$elm_jwt$Jwt$getTokenParts(token)));
	});
var $author$project$Utils$Auth$JwtPayload = function (issuer) {
	return {j$: issuer};
};
var $author$project$Utils$Auth$payloadDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Utils$Auth$JwtPayload,
	A2($elm$json$Json$Decode$field, 'iss', $elm$json$Json$Decode$string));
var $author$project$Utils$Auth$getTokenType = function (jwtToken) {
	var _v0 = A2($simonh1000$elm_jwt$Jwt$decodeToken, $author$project$Utils$Auth$payloadDecoder, jwtToken);
	if (!_v0.$) {
		var issuer = _v0.a.j$;
		return (issuer === 'intermediario_service') ? 1 : 0;
	} else {
		return 0;
	}
};
var $author$project$Update$tokenType = function (tokenConfig) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$map,
			$author$project$Utils$Auth$getTokenType,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.p1;
				},
				tokenConfig)));
};
var $author$project$Api$GetUserResult = function (a) {
	return {$: 8, a: a};
};
var $author$project$Gql$Vianello$Query$me = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'me', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$User$User = F2(
	function (email, admin) {
		return {ma: admin, fi: email};
	});
var $author$project$Gql$Vianello$Object$User$admin = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'admin', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$User$email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'email', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$User$userQuerySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$User$admin,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$User$email,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$User$User)));
var $author$project$Api$User$userQueryWithRetry = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
	$author$project$Api$GetUserResult,
	$author$project$Gql$Vianello$Query$me($author$project$Api$User$userQuerySelection));
var $author$project$Update$maybeUserCommandWithModel = F2(
	function (tokenConfig, model) {
		var _v0 = $author$project$Update$tokenType(tokenConfig);
		if (_v0 === 1) {
			return A2(
				$author$project$Api$AuthClient$mapOperationToCommand,
				model,
				A2(
					$author$project$Api$buildQuery,
					$author$project$Api$RootSource(
						$author$project$Api$GetUser($elm$core$Basics$identity)),
					$author$project$Api$User$userQueryWithRetry));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var $author$project$Types$pickNonce = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.oz;
	});
var $author$project$Utils$send = A2(
	$elm$core$Basics$composeL,
	$elm$core$Task$perform($elm$core$Basics$identity),
	$elm$core$Task$succeed);
var $author$project$FlagshipIO$PageViewHit = function (a) {
	return {$: 1, a: a};
};
var $author$project$FlagshipIO$flagshipIOPort_ = _Platform_outgoingPort('flagshipIOPort_', $elm$core$Basics$identity);
var $author$project$FlagshipIO$eventCategoryEncoder = function (eC) {
	return $elm$json$Json$Encode$string('Action Tracking');
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$FlagshipIO$hitEncoder = function (hit) {
	if (!hit.$) {
		var args = hit.a;
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('EVENT'))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'action',
							$elm$json$Json$Encode$string(args.eP))),
						$elm$core$Maybe$Just(
						_Utils_Tuple2(
							'category',
							$author$project$FlagshipIO$eventCategoryEncoder(args.ir))),
						A2(
						$elm$core$Maybe$map,
						function (value) {
							return _Utils_Tuple2(
								'value',
								$elm$json$Json$Encode$int(value));
						},
						args.p8),
						A2(
						$elm$core$Maybe$map,
						function (label) {
							return _Utils_Tuple2(
								'label',
								$elm$json$Json$Encode$string(label));
						},
						args.n6)
					])));
	} else {
		var documentLocation = hit.a.iU;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('PAGEVIEW')),
					_Utils_Tuple2(
					'documentLocation',
					$elm$json$Json$Encode$string(
						$elm$url$Url$toString(documentLocation)))
				]));
	}
};
var $author$project$FlagshipIO$sendHit = function (hit) {
	return $author$project$FlagshipIO$flagshipIOPort_(
		$elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type_',
					$elm$json$Json$Encode$string('send_hit')),
					_Utils_Tuple2(
					'hit',
					$author$project$FlagshipIO$hitEncoder(hit))
				])));
};
var $author$project$FlagshipIO$sendPageViewHit = function (url) {
	return $author$project$FlagshipIO$sendHit(
		$author$project$FlagshipIO$PageViewHit(
			{iU: url}));
};
var $author$project$Init$initWithDecodedFlags = F3(
	function (flags, url, key) {
		var _v0 = A6($author$project$Model$initialModel, flags, key, url, _List_Nil, $krisajenkins$remotedata$RemoteData$NotAsked, 0);
		var initialModel = _v0.a;
		var initialCmd = _v0.b;
		var getTokenCmd = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Utils$send(
				$author$project$Model$ChangeAppStatus(1)),
			A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				A2(
					$author$project$Update$maybeTokenCommand,
					initialModel.p2,
					$author$project$Types$pickApiUrl(initialModel.nv)),
				A2(
					$elm$core$Maybe$map,
					A2(
						$author$project$Api$AuthClient$getTokenFromNonce,
						$author$project$Types$pickApiUrl(initialModel.nv),
						$author$project$Model$ReceivedTokenFromNonce),
					$author$project$Types$pickNonce(initialModel.nv))));
		var _v1 = A2($author$project$Update$maybeUserCommandWithModel, initialModel.p2, initialModel);
		var model = _v1.a;
		var cmd = _v1.b;
		return A2(
			$primait$prima_elm_extra$PrimaUpdate$withCmds,
			_List_fromArray(
				[
					initialCmd,
					cmd,
					getTokenCmd,
					$author$project$Init$bootDelayedCmd,
					$author$project$FlagshipIO$sendPageViewHit(url)
				]),
			model);
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $author$project$Init$init = F3(
	function (flagsValue, url, key) {
		var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Types$flagsTupleDecoder, flagsValue);
		if (!_v0.$) {
			var flags = _v0.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Result$Ok,
				A3($author$project$Init$initWithDecodedFlags, flags, url, key));
		} else {
			var error = _v0.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$Logger$devConsoleErrorForJsonDecodeError(
						{b0: error, bw: 'appStateInit'})
					]),
				$elm$core$Result$Err(error));
		}
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Model$CheckoutMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Guarantees$Model$CloseAllEditOptionSelects = {$: 17};
var $author$project$Checkout$Model$EffectiveDate = function (a) {
	return {$: 11, a: a};
};
var $author$project$Model$GuaranteesMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Checkout$Model$OnBlur = {$: 2};
var $author$project$Quotation$Model$OnBlurSelect = {$: 4};
var $author$project$QuotationOld$Model$OnDatePickerBlur = {$: 2};
var $author$project$QuotationOld$Model$OnDatePickerEvent = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Guarantees$Model$OnLocalStorageDataReceived = function (a) {
	return {$: 22, a: a};
};
var $author$project$Model$OneClickMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Model$QuotationMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Model$QuotationOldMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$OneClick$Model$ToggleCvvTooltip = function (a) {
	return {$: 13, a: a};
};
var $author$project$OneClick$Model$ToggleEffectiveDateTooltip = function (a) {
	return {$: 10, a: a};
};
var $author$project$QuotationOld$Model$ToggleToolTip = function (a) {
	return {$: 20, a: a};
};
var $author$project$OneClick$Model$isModelInitialized = function (_v0) {
	var state = _v0.cG;
	if (state.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Ports$localStorageItemResponse = _Platform_incomingPort(
	'localStorageItemResponse',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$Just,
				A2(
					$elm$json$Json$Decode$andThen,
					function (value) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (name) {
								return $elm$json$Json$Decode$succeed(
									{oq: name, p8: value});
							},
							A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string));
					},
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string)))
			])));
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {kF: pids, lB: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {fm: event, fR: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.kF,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.fR;
		var event = _v0.fm;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.lB);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onMouseDown = A2($elm$browser$Browser$Events$on, 0, 'mousedown');
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $author$project$Main$isOutsideElement = function (elementClass) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				function (classesString) {
					return A2($elm$core$String$contains, elementClass, classesString) ? $elm$json$Json$Decode$succeed(false) : $elm$json$Json$Decode$fail('check parent node');
				},
				A2($elm$json$Json$Decode$field, 'className', $elm$json$Json$Decode$string)),
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return A2(
						$elm$json$Json$Decode$field,
						'parentNode',
						$author$project$Main$isOutsideElement(elementClass));
				}),
				$elm$json$Json$Decode$succeed(true)
			]));
};
var $author$project$Main$outsideTarget = F2(
	function (elementClass, msg) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (isOutside) {
				return isOutside ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('inside ' + elementClass);
			},
			A2(
				$elm$json$Json$Decode$field,
				'target',
				$author$project$Main$isOutsideElement(elementClass)));
	});
var $author$project$Main$outsideMultipleTargets = F2(
	function (elementClassesList, msg) {
		if (!elementClassesList.b) {
			return $elm$json$Json$Decode$succeed(msg);
		} else {
			var firstClass = elementClassesList.a;
			var otherClasses = elementClassesList.b;
			return A2(
				$elm$json$Json$Decode$andThen,
				$author$project$Main$outsideMultipleTargets(otherClasses),
				A2($author$project$Main$outsideTarget, firstClass, msg));
		}
	});
var $author$project$Model$pickCookieBot = function (_v0) {
	var cookieBot = _v0.e5;
	return cookieBot;
};
var $author$project$Components$NavigableModal$pickIsOpen = function (_v0) {
	var isOpen = _v0.jP;
	return isOpen;
};
var $author$project$Main$subscribeIf = F2(
	function (isEnabled, subMsg) {
		return isEnabled ? subMsg : $elm$core$Platform$Sub$none;
	});
var $author$project$Checkout$Model$BrainTreeEvent = function (a) {
	return {$: 19, a: a};
};
var $author$project$Checkout$Model$BraintreePaypal = 2;
var $author$project$Checkout$Model$BraintreeModel$BraintreeReceivedNonce = function (a) {
	return {$: 3, a: a};
};
var $author$project$Checkout$Model$BraintreeModel$NotifyPaymentEvent = function (a) {
	return {$: 4, a: a};
};
var $author$project$Checkout$Model$PaymentMethodStatus = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Checkout$Model$BraintreeModel$PaypalPopupClosed = function (a) {
	return {$: 5, a: a};
};
var $author$project$Checkout$Model$BraintreeModel$ThreeDSReceivedPayload = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$BraintreeModel$ThreeDSVerified = function (a) {
	return {$: 2, a: a};
};
var $author$project$Checkout$Model$BraintreeModel$ValidateBraintreeData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Ports$braintreeReceivedNonce = _Platform_incomingPort('braintreeReceivedNonce', $elm$json$Json$Decode$string);
var $author$project$Checkout$Ports$creditCardReady = _Platform_incomingPort('creditCardReady', $elm$json$Json$Decode$bool);
var $author$project$Checkout$Model$GetInsurance = {$: 16};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {kW: processes, lE: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.kW;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.lE);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Checkout$Subscriptions$insuranceRequest = function (_v0) {
	var enabled = _v0.na;
	var requestCount = _v0.po;
	var maxRequests = _v0.oe;
	return (enabled && (_Utils_cmp(requestCount, maxRequests) < 1)) ? A2(
		$elm$time$Time$every,
		1000,
		$elm$core$Basics$always($author$project$Checkout$Model$GetInsurance)) : $elm$core$Platform$Sub$none;
};
var $author$project$Checkout$Ports$notifyErrorEvent = _Platform_incomingPort(
	'notifyErrorEvent',
	A2(
		$elm$json$Json$Decode$andThen,
		function (error) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (context) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (code) {
							return $elm$json$Json$Decode$succeed(
								{mH: code, mR: context, b0: error});
						},
						A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'context', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string)));
var $author$project$Checkout$Ports$paypalPopupClosed = _Platform_incomingPort('paypalPopupClosed', $elm$json$Json$Decode$bool);
var $author$project$Checkout$Ports$paypalReady = _Platform_incomingPort('paypalReady', $elm$json$Json$Decode$bool);
var $author$project$Checkout$Ports$threeDSReceivedPayload = _Platform_incomingPort(
	'threeDSReceivedPayload',
	A2(
		$elm$json$Json$Decode$andThen,
		function (nonce) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (bin) {
					return $elm$json$Json$Decode$succeed(
						{e1: bin, oz: nonce});
				},
				A2($elm$json$Json$Decode$field, 'bin', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'nonce', $elm$json$Json$Decode$string)));
var $author$project$Checkout$Ports$threeDSVerified = _Platform_incomingPort(
	'threeDSVerified',
	A2(
		$elm$json$Json$Decode$andThen,
		function (status) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (nonce) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (bin) {
							return $elm$json$Json$Decode$succeed(
								{e1: bin, oz: nonce, hK: status});
						},
						A2($elm$json$Json$Decode$field, 'bin', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'nonce', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'status', $elm$json$Json$Decode$string)));
var $author$project$Checkout$Ports$validateBraintreeData = _Platform_incomingPort(
	'validateBraintreeData',
	$elm$json$Json$Decode$list(
		A2(
			$elm$json$Json$Decode$andThen,
			function (isValid) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (isPristine) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (id) {
								return $elm$json$Json$Decode$succeed(
									{nM: id, jR: isPristine, jY: isValid});
							},
							A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
					},
					A2($elm$json$Json$Decode$field, 'isPristine', $elm$json$Json$Decode$bool));
			},
			A2($elm$json$Json$Decode$field, 'isValid', $elm$json$Json$Decode$bool))));
var $author$project$Checkout$Subscriptions$subscriptions = function (_v0) {
	var insurancePollingConfig = _v0.nW;
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Checkout$Ports$creditCardReady(
				$author$project$Checkout$Model$PaymentMethodStatus(1)),
				$author$project$Checkout$Ports$paypalReady(
				$author$project$Checkout$Model$PaymentMethodStatus(2)),
				$author$project$Checkout$Ports$validateBraintreeData(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$ValidateBraintreeData, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Ports$threeDSReceivedPayload(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$ThreeDSReceivedPayload, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Ports$threeDSVerified(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$ThreeDSVerified, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Ports$braintreeReceivedNonce(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$BraintreeReceivedNonce, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Ports$notifyErrorEvent(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$NotifyPaymentEvent, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Ports$paypalPopupClosed(
				A2($elm$core$Basics$composeR, $author$project$Checkout$Model$BraintreeModel$PaypalPopupClosed, $author$project$Checkout$Model$BrainTreeEvent)),
				$author$project$Checkout$Subscriptions$insuranceRequest(insurancePollingConfig)
			]));
};
var $author$project$CookieBot$ConsentUpdate = function (a) {
	return {$: 0, a: a};
};
var $author$project$CookieBot$ConsentUpdateError = function (a) {
	return {$: 1, a: a};
};
var $author$project$CookieBot$cookieBotSubscription_ = _Platform_incomingPort('cookieBotSubscription_', $elm$json$Json$Decode$value);
var $author$project$CookieBot$OnConsentUpdate = $elm$core$Basics$identity;
var $author$project$CookieBot$Consent = F4(
	function (marketing, necessary, preferences, statistics) {
		return {j8: marketing, kj: necessary, kO: preferences, lw: statistics};
	});
var $author$project$CookieBot$consentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'statistics',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'preferences',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'necessary',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'marketing',
				$elm$json$Json$Decode$bool,
				$elm$json$Json$Decode$succeed($author$project$CookieBot$Consent)))));
var $author$project$CookieBot$onConsentUpdateDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'consent',
	$author$project$CookieBot$consentDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'type_',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed(
			F2(
				function (_v0, consent) {
					return consent;
				}))));
var $author$project$CookieBot$subscriptions = function (cookieBot) {
	return A2(
		$elm$core$Platform$Sub$map,
		cookieBot.ey,
		$author$project$CookieBot$cookieBotSubscription_(
			function (v) {
				var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$CookieBot$onConsentUpdateDecoder, v);
				if (_v0.$ === 1) {
					var fail = _v0.a;
					return $author$project$CookieBot$ConsentUpdateError(fail);
				} else {
					var consent = _v0.a;
					return $author$project$CookieBot$ConsentUpdate(consent);
				}
			}));
};
var $author$project$OneClick$Model$BrainTreeEvent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Payment$Model$BraintreeReceivedNonce = function (a) {
	return {$: 3, a: a};
};
var $author$project$Payment$Model$CreditCard = 0;
var $author$project$Payment$Model$NotifyPaymentEvent = function (a) {
	return {$: 4, a: a};
};
var $author$project$Payment$Model$PayPal = 1;
var $author$project$OneClick$Model$PaymentMethodStatus = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Payment$Model$PaypalPopupClosed = function (a) {
	return {$: 5, a: a};
};
var $author$project$Payment$Model$ThreeDSReceivedPayload = function (a) {
	return {$: 1, a: a};
};
var $author$project$Payment$Model$ThreeDSVerified = function (a) {
	return {$: 2, a: a};
};
var $author$project$Payment$Model$ValidateBraintreeData = function (a) {
	return {$: 0, a: a};
};
var $author$project$OneClick$Subscriptions$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Checkout$Ports$creditCardReady(
			$author$project$OneClick$Model$PaymentMethodStatus(0)),
			$author$project$Checkout$Ports$paypalReady(
			$author$project$OneClick$Model$PaymentMethodStatus(1)),
			$author$project$Checkout$Ports$validateBraintreeData(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$ValidateBraintreeData, $author$project$OneClick$Model$BrainTreeEvent)),
			$author$project$Checkout$Ports$threeDSReceivedPayload(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$ThreeDSReceivedPayload, $author$project$OneClick$Model$BrainTreeEvent)),
			$author$project$Checkout$Ports$threeDSVerified(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$ThreeDSVerified, $author$project$OneClick$Model$BrainTreeEvent)),
			$author$project$Checkout$Ports$braintreeReceivedNonce(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$BraintreeReceivedNonce, $author$project$OneClick$Model$BrainTreeEvent)),
			$author$project$Checkout$Ports$notifyErrorEvent(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$NotifyPaymentEvent, $author$project$OneClick$Model$BrainTreeEvent)),
			$author$project$Checkout$Ports$paypalPopupClosed(
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$PaypalPopupClosed, $author$project$OneClick$Model$BrainTreeEvent))
		]));
var $author$project$RFQDTS$Http$Authenticator$NoOp = {$: 1};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$POWResult = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$ProofOfWork = F2(
	function (r, s) {
		return {ar: r, as: s};
	});
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$Secret = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$decoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$RFQDTS$Http$Authenticator$ProofOfWork$ProofOfWork,
	A2(
		$elm$json$Json$Decode$field,
		'r',
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		's',
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string)));
var $author$project$RFQDTS$Http$Authenticator$JwtToken$JwtToken = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$JwtToken$decoderForSessionStorage = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$RFQDTS$Http$Authenticator$JwtTokenFromSessionStorage = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$SessionStorageDecodeError = function (a) {
	return {$: 4, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$onGetJwtTokenFromSessionStorage = function (res) {
	if (res.$ === 1) {
		var error = res.a;
		return $author$project$RFQDTS$Http$Authenticator$SessionStorageDecodeError(error);
	} else {
		if (!res.a.$) {
			var jwtToken = res.a.a;
			return $author$project$RFQDTS$Http$Authenticator$JwtTokenFromSessionStorage(jwtToken);
		} else {
			var _v1 = res.a;
			return $author$project$RFQDTS$Http$Authenticator$NoOp;
		}
	}
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWorkFromSessionStorage = function (a) {
	return {$: 3, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$onGetProofOfWorkFromSessionStorage = function (res) {
	if (res.$ === 1) {
		var error = res.a;
		return $author$project$RFQDTS$Http$Authenticator$SessionStorageDecodeError(error);
	} else {
		if (!res.a.$) {
			var proofOfWork = res.a.a;
			return $author$project$RFQDTS$Http$Authenticator$ProofOfWorkFromSessionStorage(proofOfWork);
		} else {
			var _v1 = res.a;
			return $author$project$RFQDTS$Http$Authenticator$NoOp;
		}
	}
};
var $author$project$RFQDTS$Http$Authenticator$rfqDtsHttpAuthenticatorSubscription_ = _Platform_incomingPort('rfqDtsHttpAuthenticatorSubscription_', $elm$json$Json$Decode$value);
var $author$project$RFQDTS$Http$Authenticator$SubscriptionValue = F2(
	function (item, key) {
		return {dS: item, fR: key};
	});
var $author$project$RFQDTS$Http$Authenticator$subscriptionValueDecoder = function (storedTypeDecoder) {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$RFQDTS$Http$Authenticator$SubscriptionValue,
		A2(
			$elm$json$Json$Decode$field,
			'item',
			$elm$json$Json$Decode$maybe(storedTypeDecoder)),
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
};
var $author$project$RFQDTS$Http$Authenticator$receiveItem = function (conf) {
	return $author$project$RFQDTS$Http$Authenticator$rfqDtsHttpAuthenticatorSubscription_(
		function (v) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				$author$project$RFQDTS$Http$Authenticator$subscriptionValueDecoder(conf.dT),
				v);
			if (_v0.$ === 1) {
				var jsonError = _v0.a;
				return conf.co(
					$elm$core$Result$Err(jsonError));
			} else {
				var subscriptionVal = _v0.a;
				return _Utils_eq(subscriptionVal.fR, conf.fR) ? conf.co(
					$elm$core$Result$Ok(subscriptionVal.dS)) : conf.d8;
			}
		});
};
var $author$project$RFQDTS$Http$Authenticator$JwtToken$sessionStorageKey = 'Authenticator.JwtToken';
var $author$project$RFQDTS$Http$Authenticator$subscriptions = function (auth) {
	if (!auth.$) {
		var antiSpiderOnlyConfig = auth.a;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$RFQDTS$Http$Authenticator$receiveItem(
				{dT: $author$project$RFQDTS$Http$Authenticator$ProofOfWork$decoder, fR: $author$project$RFQDTS$Http$Authenticator$ProofOfWork$sessionStorageKey, co: $author$project$RFQDTS$Http$Authenticator$onGetProofOfWorkFromSessionStorage, d8: $author$project$RFQDTS$Http$Authenticator$NoOp}),
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$always($elm$core$Platform$Sub$none),
				antiSpiderOnlyConfig.i));
	} else {
		var fullConfig = auth.a;
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$RFQDTS$Http$Authenticator$receiveItem(
						{dT: $author$project$RFQDTS$Http$Authenticator$ProofOfWork$decoder, fR: $author$project$RFQDTS$Http$Authenticator$ProofOfWork$sessionStorageKey, co: $author$project$RFQDTS$Http$Authenticator$onGetProofOfWorkFromSessionStorage, d8: $author$project$RFQDTS$Http$Authenticator$NoOp}),
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always($elm$core$Platform$Sub$none),
						fullConfig.i)),
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$RFQDTS$Http$Authenticator$receiveItem(
						{dT: $author$project$RFQDTS$Http$Authenticator$JwtToken$decoderForSessionStorage, fR: $author$project$RFQDTS$Http$Authenticator$JwtToken$sessionStorageKey, co: $author$project$RFQDTS$Http$Authenticator$onGetJwtTokenFromSessionStorage, d8: $author$project$RFQDTS$Http$Authenticator$NoOp}),
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always($elm$core$Platform$Sub$none),
						fullConfig.I))
				]));
	}
};
var $author$project$Main$subscriptionsOk = function (model) {
	var checkoutModel = model.mw;
	var quotationOldModel = model.pb;
	var guaranteesModel = model.nG;
	var oneClickModel = model.oH;
	var authenticator = model.mf;
	var subscriptionHandler = function (_v0) {
		var condition = _v0.a;
		var handler = _v0.b;
		return A2(
			$author$project$Main$subscribeIf,
			condition,
			$elm$browser$Browser$Events$onMouseDown(handler));
	};
	return $elm$core$Platform$Sub$batch(
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$core$Platform$Sub$map,
					$author$project$Model$CheckoutMsg,
					$author$project$Checkout$Subscriptions$subscriptions(checkoutModel)),
					A2($elm$core$Platform$Sub$map, $author$project$Model$OneClickMsg, $author$project$OneClick$Subscriptions$subscriptions),
					$author$project$Ports$localStorageItemResponse(
					A2($elm$core$Basics$composeR, $author$project$Guarantees$Model$OnLocalStorageDataReceived, $author$project$Model$GuaranteesMsg)),
					$author$project$CookieBot$subscriptions(
					$author$project$Model$pickCookieBot(model)),
					A2(
					$elm$core$Platform$Sub$map,
					$author$project$Model$AuthenticatorMsg,
					$author$project$RFQDTS$Http$Authenticator$subscriptions(authenticator))
				]),
			A2(
				$elm$core$List$map,
				subscriptionHandler,
				_List_fromArray(
					[
						_Utils_Tuple2(
						quotationOldModel.dv.mn.jP,
						A2(
							$author$project$Main$outsideTarget,
							'datepicker',
							$author$project$Model$QuotationOldMsg(
								A2($author$project$QuotationOld$Model$OnDatePickerEvent, 0, $author$project$QuotationOld$Model$OnDatePickerBlur)))),
						_Utils_Tuple2(
						checkoutModel.n1,
						A2(
							$author$project$Main$outsideTarget,
							'datepicker',
							$author$project$Model$CheckoutMsg(
								$author$project$Checkout$Model$EffectiveDate($author$project$Checkout$Model$OnBlur)))),
						_Utils_Tuple2(
						true,
						A2(
							$author$project$Main$outsideTarget,
							'form-row__item__append',
							$author$project$Model$QuotationOldMsg(
								$author$project$QuotationOld$Model$ToggleToolTip($elm$core$Maybe$Nothing)))),
						_Utils_Tuple2(
						$author$project$Components$NavigableModal$pickIsOpen(guaranteesModel.bM.d3),
						A2(
							$author$project$Main$outsideMultipleTargets,
							_List_fromArray(
								['form-select__status', 'form-select__list']),
							$author$project$Model$GuaranteesMsg($author$project$Guarantees$Model$CloseAllEditOptionSelects))),
						_Utils_Tuple2(
						$author$project$OneClick$Model$isModelInitialized(oneClickModel),
						A2(
							$author$project$Main$outsideMultipleTargets,
							_List_fromArray(
								['one-click__payment__cvv-icon-wrapper', 'one-click__payment__cvv-tooltip-mobile']),
							$author$project$Model$OneClickMsg(
								$author$project$OneClick$Model$ToggleCvvTooltip(false)))),
						_Utils_Tuple2(
						$author$project$OneClick$Model$isModelInitialized(oneClickModel),
						A2(
							$author$project$Main$outsideMultipleTargets,
							_List_fromArray(
								['one-click__payment__effective-date-action-wrapper', 'one-click__payment__effective-date-tooltip-mobile']),
							$author$project$Model$OneClickMsg(
								$author$project$OneClick$Model$ToggleEffectiveDateTooltip(false)))),
						_Utils_Tuple2(
						true,
						A2(
							$author$project$Main$outsideMultipleTargets,
							_List_fromArray(
								['form-field--with-opened-dropdown']),
							$author$project$Model$QuotationMsg($author$project$Quotation$Model$OnBlurSelect)))
					]))));
};
var $author$project$Main$subscriptions = function (appState) {
	if (!appState.$) {
		var model = appState.a;
		return $author$project$Main$subscriptionsOk(model);
	} else {
		return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Quotation$Model$FillFormMsg = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$Checkout$Model$Initialize = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Guarantees$Model$Initialize = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$NotAvailable$Model$Initialize = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$OneClick$Model$Initialize = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Preselection$Preselection$Initialize = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Quotation$Model$Initialize = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$ThankYou$Model$Initialize = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Model$NoOp = {$: 11};
var $author$project$Model$NotAvailableMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Model$PreselectionMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$QuotationOld$Model$PushFormDataToDataLayer = {$: 25};
var $author$project$Model$ThankYouMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Utils$UpdateReturn$Update = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Utils$addCommand = F2(
	function (newCmd, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[newCmd, cmd])));
	});
var $author$project$Update$changeAppStatus = function (appStatus) {
	return $author$project$Utils$addCommand(
		$author$project$Utils$send(
			$author$project$Model$ChangeAppStatus(appStatus)));
};
var $author$project$Utils$referralHeader = function (referral) {
	return A2($elm$core$Tuple$pair, 'X-Origin-Referral', referral);
};
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$Definitive = 1;
var $author$project$PrimaElm$Lib$Utils$FP$flip = F3(
	function (fn, b, a) {
		return A2(fn, a, b);
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$getMsgLifter = function (_v0) {
	var msgLifter = _v0.kg;
	return msgLifter;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$resetPipelines = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{bz: _List_Nil});
};
var $author$project$PrimaElm$Lib$GqlClient$Config$setNewAuthentication = F2(
	function (authentication, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{eZ: authentication});
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$setNewAuthentication = F2(
	function (headers, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				iC: A2($author$project$PrimaElm$Lib$GqlClient$Config$setNewAuthentication, headers, modelData.iC)
			});
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$setNewAuthentication = F4(
	function (model, headers, mainModelUpdater, mainModel) {
		var queryFold = F2(
			function (_v0, _v1) {
				var query = _v0.b;
				var accModel = _v1.a;
				var commands = _v1.b;
				return A2(
					$elm$core$Tuple$mapSecond,
					A2($author$project$PrimaElm$Lib$Utils$FP$flip, $elm$core$List$cons, commands),
					A3($author$project$PrimaElm$Lib$GqlClient$GqlClient$doRunQuery, 1, accModel, query));
			});
		var newModel = A2($author$project$PrimaElm$Lib$GqlClient$Model$setNewAuthentication, headers, model);
		var queries = $author$project$PrimaElm$Lib$GqlClient$Model$getElementsInPipeline(newModel);
		return A2(
			$elm$core$Tuple$mapSecond,
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Platform$Cmd$map(
					$author$project$PrimaElm$Lib$GqlClient$Model$getMsgLifter(model)),
				$elm$core$Platform$Cmd$batch),
			A2(
				$elm$core$Tuple$mapFirst,
				A2(
					$elm$core$Basics$composeL,
					A2($author$project$PrimaElm$Lib$Utils$FP$flip, mainModelUpdater, mainModel),
					$author$project$PrimaElm$Lib$GqlClient$Model$resetPipelines),
				A3(
					$elm$core$List$foldl,
					queryFold,
					_Utils_Tuple2(newModel, _List_Nil),
					queries)));
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$storeTokenConfig = _Platform_outgoingPort(
	'storeTokenConfig',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'refreshToken',
					function ($) {
						return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
					}($.pi)),
					_Utils_Tuple2(
					'token',
					$elm$json$Json$Encode$string($.p1)),
					_Utils_Tuple2(
					'tokenRetrievedFromUrl',
					$elm$json$Json$Encode$bool($.lJ))
				]));
	});
var $author$project$Api$AuthClient$storeTokenConfig = F2(
	function (tokenConfig, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					$author$project$Ports$storeTokenConfig(tokenConfig)
				]),
			model);
	});
var $author$project$Api$AuthClient$updateTokenConfig = F2(
	function (tokenConfig, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{p2: tokenConfig}),
			cmd);
	});
var $author$project$Api$AuthClient$changeAuthentication = F2(
	function (model, tokenConfig) {
		var flags = model.nv;
		var token = tokenConfig.p1;
		var headers = A2(
			$elm$core$List$cons,
			$author$project$Utils$authHeader(token),
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$singleton,
					A2(
						$elm$core$Maybe$map,
						$author$project$Utils$referralHeader,
						$author$project$Types$pickReferral(flags)))));
		return A2(
			$author$project$Api$AuthClient$storeTokenConfig,
			tokenConfig,
			A2(
				$author$project$Api$AuthClient$updateTokenConfig,
				$elm$core$Maybe$Just(tokenConfig),
				A4($author$project$PrimaElm$Lib$GqlClient$GqlClient$setNewAuthentication, model.dy, headers, $author$project$Api$AuthClient$updateGqlClient, model)));
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Model$RootQueryMsg = function (a) {
	return {$: 20, a: a};
};
var $author$project$Api$AuthClient$errorMapper = F2(
	function (error, mapper) {
		if (error.$ === 1) {
			var err = error.a;
			return $elm$core$Maybe$Just(
				$dillonkearns$elm_graphql$Graphql$Http$HttpError(err));
		} else {
			if (!error.a.$) {
				var parsedData = error.a.a;
				var graphqlErrors = error.b;
				return A2(
					$elm$core$Maybe$map,
					function (mapped) {
						return A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, mapped, graphqlErrors);
					},
					A2(
						$elm$core$Maybe$map,
						$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData,
						mapper(parsedData)));
			} else {
				var unparsedData = error.a.a;
				var graphqlErrors = error.b;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Http$GraphqlError,
						$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(unparsedData),
						graphqlErrors));
			}
		}
	});
var $author$project$Api$AuthClient$getInsuranceErrorMapper = function (result) {
	if (result.$ === 6) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$getOrderErrorMapper = function (result) {
	if (result.$ === 7) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$getQuoteErrorMapper = function (result) {
	if (result.$ === 2) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$getSaveErrorMapper = function (result) {
	if (!result.$) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$getUserErrorMapper = function (result) {
	if (result.$ === 8) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$postEffectiveDateErrorMapper = function (result) {
	if (result.$ === 5) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$postOrderErrorMapper = function (result) {
	if (result.$ === 4) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$postQuoteErrorMapper = function (result) {
	if (result.$ === 3) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$postSaveErrorMapper = function (result) {
	if (result.$ === 1) {
		var response = result.a;
		return $elm$core$Maybe$Just(response);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Api$AuthClient$queryResultErrorMessageMapper = F2(
	function (result, queryKind) {
		switch (queryKind.$) {
			case 0:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$getSaveErrorMapper)));
			case 1:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$postSaveErrorMapper)));
			case 2:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$getQuoteErrorMapper)));
			case 3:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$postQuoteErrorMapper)));
			case 4:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$postOrderErrorMapper)));
			case 5:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$postEffectiveDateErrorMapper)));
			case 6:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$getInsuranceErrorMapper)));
			case 7:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$getOrderErrorMapper)));
			default:
				var mapper = queryKind.a;
				return A2(
					$elm$core$Maybe$map,
					mapper,
					A2(
						$elm$core$Maybe$map,
						$krisajenkins$remotedata$RemoteData$Failure,
						A2($author$project$Api$AuthClient$errorMapper, result, $author$project$Api$AuthClient$getUserErrorMapper)));
		}
	});
var $author$project$Api$AuthClient$handleGenericQueryError = F3(
	function (model, queryTagger, error) {
		var msg = function () {
			switch (queryTagger.$) {
				case 0:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$QuotationOldMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
				case 4:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$QuotationMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
				case 1:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$CheckoutMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
				case 2:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$ThankYouMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
				case 3:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$GuaranteesMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
				default:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$RootQueryMsg,
						A2($author$project$Api$AuthClient$queryResultErrorMessageMapper, error, queryKind));
			}
		}();
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$Maybe$map, $author$project$Utils$send, msg))
				]),
			model);
	});
var $krisajenkins$remotedata$RemoteData$succeed = $krisajenkins$remotedata$RemoteData$Success;
var $author$project$Api$AuthClient$queryResultMessageMapper = F2(
	function (result, translator) {
		var _v0 = _Utils_Tuple2(result, translator);
		_v0$9:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 3:
					if (_v0.b.$ === 3) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 4:
					if (_v0.b.$ === 4) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 5:
					if (_v0.b.$ === 5) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 6:
					if (_v0.b.$ === 6) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				case 7:
					if (_v0.b.$ === 7) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
				default:
					if (_v0.b.$ === 8) {
						var response = _v0.a.a;
						var mapper = _v0.b.a;
						return $elm$core$Maybe$Just(
							mapper(
								$krisajenkins$remotedata$RemoteData$succeed(response)));
					} else {
						break _v0$9;
					}
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $author$project$Api$AuthClient$handleGenericQueryResponse = F3(
	function (model, queryTagger, queryResult) {
		var msg = function () {
			switch (queryTagger.$) {
				case 0:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$QuotationOldMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
				case 1:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$CheckoutMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
				case 2:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$ThankYouMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
				case 3:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$GuaranteesMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
				case 4:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$QuotationMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
				default:
					var queryKind = queryTagger.a;
					return A2(
						$elm$core$Maybe$map,
						$author$project$Model$RootQueryMsg,
						A2($author$project$Api$AuthClient$queryResultMessageMapper, queryResult, queryKind));
			}
		}();
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$Maybe$map, $author$project$Utils$send, msg))
				]),
			model);
	});
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$PrimaElm$Lib$GqlClient$Response$DefinitiveError = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$PrimaElm$Lib$GqlClient$Response$NotAsked = {$: 0};
var $author$project$PrimaElm$Lib$GqlClient$Response$RefreshTokenExpired = {$: 2};
var $author$project$PrimaElm$Lib$GqlClient$Response$ResponseOk = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$getAuthenticationErrorIdentifier = function (_v0) {
	var authenticationErrorIdentifier = _v0.e_;
	return authenticationErrorIdentifier;
};
var $author$project$PrimaElm$Lib$GqlClient$Model$setElementsInPipeline = F2(
	function (pipeline, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{bz: pipeline});
	});
var $author$project$PrimaElm$Lib$GqlClient$Model$removeQueryFromPipeline = F2(
	function (queryId, model) {
		var pipeline = model.bz;
		return A3(
			$author$project$PrimaElm$Lib$Utils$FP$flip,
			$author$project$PrimaElm$Lib$GqlClient$Model$setElementsInPipeline,
			model,
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$Basics$neq(queryId),
					$elm$core$Tuple$first),
				pipeline));
	});
var $author$project$PrimaElm$Lib$GqlClient$Update$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var queryId = msg.a;
			var queryName = msg.b;
			var remoteData = msg.c;
			var isTokenError = function () {
				_v3$2:
				while (true) {
					if (remoteData.$ === 2) {
						if (remoteData.a.$ === 1) {
							if (remoteData.a.a.$ === 3) {
								var _v4 = remoteData.a.a;
								var metadata = _v4.a;
								return metadata.pL === 401;
							} else {
								break _v3$2;
							}
						} else {
							var _v5 = remoteData.a;
							var graphqlErrors = _v5.b;
							return A2($author$project$PrimaElm$Lib$GqlClient$Model$getAuthenticationErrorIdentifier, model, graphqlErrors);
						}
					} else {
						break _v3$2;
					}
				}
				return false;
			}();
			var newModel = function () {
				switch (remoteData.$) {
					case 0:
						return model;
					case 1:
						return model;
					case 2:
						return isTokenError ? model : A2($author$project$PrimaElm$Lib$GqlClient$Model$removeQueryFromPipeline, queryId, model);
					default:
						return A2($author$project$PrimaElm$Lib$GqlClient$Model$removeQueryFromPipeline, queryId, model);
				}
			}();
			var response = function () {
				switch (remoteData.$) {
					case 0:
						return $author$project$PrimaElm$Lib$GqlClient$Response$NotAsked;
					case 1:
						return $author$project$PrimaElm$Lib$GqlClient$Response$NotAsked;
					case 2:
						var error = remoteData.a;
						return isTokenError ? $author$project$PrimaElm$Lib$GqlClient$Response$RefreshTokenExpired : A2($author$project$PrimaElm$Lib$GqlClient$Response$DefinitiveError, queryName, error);
					default:
						return A2($author$project$PrimaElm$Lib$GqlClient$Response$ResponseOk, queryName, remoteData);
				}
			}();
			return _Utils_Tuple3(newModel, $elm$core$Platform$Cmd$none, response);
		} else {
			var queryName = msg.b;
			var remoteData = msg.c;
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				function () {
					switch (remoteData.$) {
						case 0:
							return $author$project$PrimaElm$Lib$GqlClient$Response$NotAsked;
						case 1:
							return $author$project$PrimaElm$Lib$GqlClient$Response$NotAsked;
						case 2:
							var error = remoteData.a;
							return A2($author$project$PrimaElm$Lib$GqlClient$Response$DefinitiveError, queryName, error);
						default:
							return A2($author$project$PrimaElm$Lib$GqlClient$Response$ResponseOk, queryName, remoteData);
					}
				}());
		}
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$PrimaElm$Lib$GqlClient$GqlClient$manageUpdate = F7(
	function (msg, model, tokenUpdater, queryResultHandler, queryErrorHandler, mainModelUpdater, mainModel) {
		var _v0 = A2($author$project$PrimaElm$Lib$GqlClient$Update$update, msg, model);
		var gqlClientModel = _v0.a;
		var gqlClientCmd = _v0.b;
		var response = _v0.c;
		var command = function () {
			switch (response.$) {
				case 0:
					return $elm$core$Platform$Cmd$none;
				case 1:
					return $elm$core$Platform$Cmd$none;
				case 2:
					return tokenUpdater;
				default:
					return $elm$core$Platform$Cmd$none;
			}
		}();
		var _v1 = function () {
			switch (response.$) {
				case 0:
					return _Utils_Tuple2(mainModel, $elm$core$Platform$Cmd$none);
				case 1:
					var name = response.a;
					var remoteData = response.b;
					return A2(
						$krisajenkins$remotedata$RemoteData$withDefault,
						_Utils_Tuple2(mainModel, $elm$core$Platform$Cmd$none),
						A2(
							$krisajenkins$remotedata$RemoteData$map,
							A2(queryResultHandler, mainModel, name),
							remoteData));
				case 2:
					return _Utils_Tuple2(mainModel, $elm$core$Platform$Cmd$none);
				default:
					var name = response.a;
					var error = response.b;
					return A3(queryErrorHandler, mainModel, name, error);
			}
		}();
		var newMainModel = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			A2(mainModelUpdater, gqlClientModel, newMainModel),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						command,
						A2(
						$elm$core$Platform$Cmd$map,
						$author$project$PrimaElm$Lib$GqlClient$Model$getMsgLifter(model),
						gqlClientCmd),
						cmd
					])));
	});
var $author$project$Model$ReceivedRefreshToken = function (a) {
	return {$: 15, a: a};
};
var $author$project$Gql$Vianello$Mutation$refreshToken = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'refreshToken',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'refreshToken', requiredArgs____.pi, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$RefreshTokenResult$Fragments = F2(
	function (onRefreshTokenOk, onRefreshTokenError) {
		return {gJ: onRefreshTokenError, gK: onRefreshTokenOk};
	});
var $author$project$Gql$Vianello$Union$RefreshTokenResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'RefreshTokenOk', selections____.gK),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'RefreshTokenError', selections____.gJ)
			]));
};
var $author$project$Types$RefreshTokenError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$RefreshTokenError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Token$refreshTokenErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$RefreshTokenError$message,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenError));
var $author$project$Api$Token$onRefreshTokenErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$refreshTokenErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$RefreshTokenError));
var $author$project$Types$RefreshTokenOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Object$RefreshTokenOk$refreshToken = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'refreshToken', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$RefreshTokenOk$token = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'token', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Api$Token$refreshTokenOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $elm$core$Maybe$Just, $author$project$Gql$Vianello$Object$RefreshTokenOk$refreshToken),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$RefreshTokenOk$token,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$TokenOk)));
var $author$project$Api$Token$onRefreshTokenOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Api$Token$refreshTokenOkSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$RefreshTokenOk));
var $author$project$Api$Token$refreshTokenSelection = $author$project$Gql$Vianello$Union$RefreshTokenResult$fragments(
	A2($author$project$Gql$Vianello$Union$RefreshTokenResult$Fragments, $author$project$Api$Token$onRefreshTokenOkSelection, $author$project$Api$Token$onRefreshTokenErrorSelection));
var $author$project$Api$Token$refreshTokenMutation = function (refreshToken) {
	return A2(
		$author$project$Gql$Vianello$Mutation$refreshToken,
		{pi: refreshToken},
		$author$project$Api$Token$refreshTokenSelection);
};
var $author$project$Api$AuthClient$refreshTokenCommand = F2(
	function (apiUrl, token) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Api$Token$refreshTokenMutation,
			A2(
				$elm$core$Basics$composeR,
				$dillonkearns$elm_graphql$Graphql$Http$mutationRequest(apiUrl),
				A2(
					$elm$core$Basics$composeR,
					A2(
						$dillonkearns$elm_graphql$Graphql$Http$withHeader,
						'Authorization',
						$author$project$Utils$withBearer(token)),
					$dillonkearns$elm_graphql$Graphql$Http$send($author$project$Model$ReceivedRefreshToken))));
	});
var $author$project$Api$AuthClient$maybeRefreshTokenCommand = F2(
	function (apiUrl, tokenConfig) {
		return A2(
			$elm$core$Maybe$map,
			A2($author$project$Api$AuthClient$refreshTokenCommand, apiUrl, tokenConfig.p1),
			tokenConfig.pi);
	});
var $author$project$Api$AuthClient$refreshTokenRequest = function (_v0) {
	var tokenConfig = _v0.p2;
	var flags = _v0.nv;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$andThen,
			$author$project$Api$AuthClient$maybeRefreshTokenCommand(
				$author$project$Types$pickApiUrl(flags)),
			tokenConfig));
};
var $author$project$Api$AuthClient$manageUpdate = F2(
	function (model, gqlClientMsg) {
		return A7(
			$author$project$PrimaElm$Lib$GqlClient$GqlClient$manageUpdate,
			gqlClientMsg,
			model.dy,
			$author$project$Api$AuthClient$refreshTokenRequest(model),
			$author$project$Api$AuthClient$handleGenericQueryResponse,
			$author$project$Api$AuthClient$handleGenericQueryError,
			$author$project$Api$AuthClient$updateGqlClient,
			model);
	});
var $author$project$Model$ReceivedUser = function (a) {
	return {$: 13, a: a};
};
var $author$project$Api$User$userQuery = $author$project$Gql$Vianello$Query$me($author$project$Api$User$userQuerySelection);
var $dillonkearns$elm_graphql$Graphql$Http$withCredentials = function (_v0) {
	var request = _v0;
	return _Utils_update(
		request,
		{a7: true});
};
var $author$project$Api$AuthClient$getUser = F3(
	function (apiUrl, token, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			msg,
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'Authorization',
				$author$project$Utils$withBearer(token),
				$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
					A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, apiUrl, $author$project$Api$User$userQuery))));
	});
var $author$project$Update$maybeUserCommand = F2(
	function (apiUrl, tokenConfig) {
		var _v0 = $author$project$Update$tokenType(tokenConfig);
		if (_v0 === 1) {
			return A2(
				$elm$core$Maybe$map,
				function (token) {
					return A3($author$project$Api$AuthClient$getUser, apiUrl, token, $author$project$Model$ReceivedUser);
				},
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.p1;
					},
					tokenConfig));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Update$maybeAskForUser = function (_v0) {
	var model = _v0.a;
	var flags = model.nv;
	var msg = _v0.b;
	return A2(
		$elm$core$Maybe$withDefault,
		A2(
			$author$project$Update$changeAppStatus,
			1,
			_Utils_Tuple2(model, msg)),
		A2(
			$elm$core$Maybe$map,
			function (cmd) {
				return A2(
					$author$project$Utils$addCommand,
					cmd,
					_Utils_Tuple2(model, msg));
			},
			A2(
				$author$project$Update$maybeUserCommand,
				$author$project$Types$pickApiUrl(flags),
				model.p2)));
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Api$Token$refreshTokenResultToMaybeToken = A2(
	$elm$core$Basics$composeR,
	$elm$core$Result$toMaybe,
	$elm$core$Maybe$andThen(
		function (tr) {
			if (!tr.$) {
				var token = tr.a.p1;
				var refreshToken = tr.a.pi;
				return $elm$core$Maybe$Just(
					{pi: refreshToken, p1: token, lJ: false});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}));
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$Utils$scrollTo = F3(
	function (uselessMsg, x, y) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return uselessMsg;
			},
			A2($elm$browser$Browser$Dom$setViewport, x, y));
	});
var $author$project$FlagshipIO$ActionTracking = 0;
var $author$project$FlagshipIO$EventHit = function (a) {
	return {$: 0, a: a};
};
var $author$project$FlagshipIO$sendActionTrackingEventHit = function (args) {
	return $author$project$FlagshipIO$sendHit(
		$author$project$FlagshipIO$EventHit(
			{eP: args.eP, ir: 0, n6: args.n6, p8: args.p8}));
};
var $author$project$Model$setCookieBot = F2(
	function (cookieBot, m) {
		return _Utils_update(
			m,
			{e5: cookieBot});
	});
var $author$project$Api$Token$tokenResultToMaybeToken = A2(
	$elm$core$Basics$composeR,
	$elm$core$Result$toMaybe,
	$elm$core$Maybe$andThen(
		function (tr) {
			if (!tr.$) {
				var token = tr.a.p1;
				var refreshToken = tr.a.pi;
				return $elm$core$Maybe$Just(
					{pi: refreshToken, p1: token, lJ: false});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}));
var $author$project$Checkout$Model$BraintreeModel$AppChallenging = 3;
var $author$project$Checkout$Model$BraintreeModel$AppError = 4;
var $author$project$Checkout$Model$LazyInit = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Checkout$Model$PayWithCurrentDate = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Checkout$Model$BraintreeModel$ServerSideError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$Summary = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$Toggle = function (a) {
	return {$: 13, a: a};
};
var $author$project$Utils$UpdateReturn$UpdateReturn = $elm$core$Basics$identity;
var $author$project$Utils$UpdateReturn$initUpdateReturn = {c$: _List_Nil, fT: $elm$core$Maybe$Nothing, ec: _List_Nil, hg: $elm$core$Maybe$Nothing, hl: $elm$core$Maybe$Nothing, hK: $elm$core$Maybe$Nothing, hW: $elm$core$Maybe$Nothing};
var $author$project$Utils$UpdateReturn$addDefaultUpdateReturn = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	return _Utils_Tuple3(model, cmd, $author$project$Utils$UpdateReturn$initUpdateReturn);
};
var $author$project$Api$CheckoutSource = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$GotOrder = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$PostOrder = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$MutationOp = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$buildMutation = F2(
	function (source, selection) {
		return {
			hs: $author$project$Api$MutationOp(selection),
			lu: source
		};
	});
var $author$project$Api$CheckoutCreateOrderResult = function (a) {
	return {$: 4, a: a};
};
var $author$project$Gql$Vianello$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Gql$Vianello$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {iz: col, mS: contextStack, kU: problem, ld: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.ld, s.iz, x, s.mR));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.bx, s.b);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{iz: 1, mR: s.mR, g: s.g, bx: s.bx + 1, ld: s.ld + 1, b: s.b}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{iz: s.iz + 1, mR: s.mR, g: s.g, bx: newOffset, ld: s.ld, b: s.b}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.kU;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.b),
			s.bx) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.bx, s1.bx, s0.b),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.bx, s.ld, s.iz, s.b);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{iz: newCol, mR: s.mR, g: s.g, bx: newOffset, ld: newRow, b: s.b});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {iz: col, kU: problem, ld: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.ld, p.iz, p.kU);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{iz: 1, mR: _List_Nil, g: 1, bx: 0, ld: 1, b: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $prikhi$decimal$Decimal$Decimal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$emptyZero = function (_v0) {
	var xs = _v0;
	var _v1 = A2(
		$elm_community$list_extra$List$Extra$dropWhile,
		$elm$core$Basics$eq(0),
		xs);
	if (!_v1.b) {
		return _List_Nil;
	} else {
		return xs;
	}
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $cmditch$elm_bigint$BigInt$fromString_ = function (x) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeL, $cmditch$elm_bigint$BigInt$emptyZero, $elm$core$Basics$identity),
		$elm_community$maybe_extra$Maybe$Extra$combine(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2($elm$core$Basics$composeR, $elm$core$String$fromList, $elm$core$String$toInt)),
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					$cmditch$elm_bigint$Constants$maxDigitMagnitude,
					$elm$core$List$reverse(x)))));
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$fromString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		switch (_v0.a) {
			case '-':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(1),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			case '+':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(0),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			default:
				var xs = _v0;
				return A2(
					$elm$core$Maybe$map,
					$cmditch$elm_bigint$BigInt$mkBigInt(0),
					$cmditch$elm_bigint$BigInt$fromString_(xs));
		}
	}
};
var $prikhi$decimal$Decimal$fromString = function (str) {
	var stringToDecimal = function (s) {
		var stringIntToDecimal = F2(
			function (s_, e) {
				var _v8 = $cmditch$elm_bigint$BigInt$fromString(s_);
				if (_v8.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var a = _v8.a;
					return $elm$core$Maybe$Just(
						A2($prikhi$decimal$Decimal$Decimal, a, e));
				}
			});
		var _v6 = A2($elm$core$String$split, '.', s);
		_v6$2:
		while (true) {
			if (_v6.b) {
				if (_v6.b.b) {
					if (!_v6.b.b.b) {
						var a = _v6.a;
						var _v7 = _v6.b;
						var b = _v7.a;
						return A2(
							stringIntToDecimal,
							_Utils_ap(a, b),
							-$elm$core$String$length(b));
					} else {
						break _v6$2;
					}
				} else {
					var a = _v6.a;
					return A2(stringIntToDecimal, a, 0);
				}
			} else {
				break _v6$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var splitMantissaExponent = function (s) {
		var _v4 = A2(
			$elm$core$String$split,
			'e',
			$elm$core$String$toLower(s));
		_v4$2:
		while (true) {
			if (_v4.b) {
				if (!_v4.b.b) {
					var s1 = _v4.a;
					return _Utils_Tuple2(
						stringToDecimal(s1),
						$elm$core$Maybe$Just(0));
				} else {
					if (!_v4.b.b.b) {
						var s1 = _v4.a;
						var _v5 = _v4.b;
						var s2 = _v5.a;
						return _Utils_Tuple2(
							stringToDecimal(s1),
							$elm$core$String$toInt(s2));
					} else {
						break _v4$2;
					}
				}
			} else {
				break _v4$2;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
	};
	var makeMantissa = function (s) {
		var _v2 = A2($elm$core$String$split, '.', s);
		_v2$2:
		while (true) {
			if (_v2.b) {
				if (!_v2.b.b) {
					var s1 = _v2.a;
					return stringToDecimal(s1);
				} else {
					if (!_v2.b.b.b) {
						var s1 = _v2.a;
						var _v3 = _v2.b;
						var s2 = _v3.a;
						return stringToDecimal(
							A2(
								$elm$core$String$join,
								'',
								_List_fromArray(
									[s1, s2])));
					} else {
						break _v2$2;
					}
				}
			} else {
				break _v2$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var _v0 = splitMantissaExponent(str);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a.a;
		var m = _v1.a;
		var a = _v1.b;
		var e = _v0.b.a;
		return $elm$core$Maybe$Just(
			A2($prikhi$decimal$Decimal$Decimal, m, e + a));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {iK: d, ke: m, l3: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		g5: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		l3: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.l3, 0, date.g5);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.iK;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.ke;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.g5;
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		l_: 1 + (((rd - week1Day1) / 7) | 0),
		l$: wy,
		qb: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.l_;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.l$;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.oo(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.on(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.oo(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.m_(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.qd(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.qd(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.qd(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.qc(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.qd(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.qd(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.b);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.bx, offset) < 0,
					0,
					{iz: col, mR: s0.mR, g: s0.g, bx: offset, ld: row, b: s0.b});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.bx, s.ld, s.iz, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.bx, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	m_: $justinmimbs$date$Date$withOrdinalSuffix,
	on: $justinmimbs$date$Date$monthToName,
	oo: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	qc: $justinmimbs$date$Date$weekdayToName,
	qd: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$lt = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$compare, x, y);
	});
var $cmditch$elm_bigint$BigInt$gte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$lt, x, y);
	});
var $prikhi$decimal$Decimal$insert_decimal_period = F2(
	function (pos, s) {
		var extra_zeros = pos - $elm$core$String$length(s);
		var padded_s = (extra_zeros >= 0) ? _Utils_ap(
			A2($elm$core$String$repeat, extra_zeros + 1, '0'),
			s) : s;
		var before = A2($elm$core$String$dropRight, pos, padded_s);
		var after = A2($elm$core$String$right, pos, padded_s);
		return before + ('.' + after);
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $prikhi$decimal$Decimal$toString = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var sign = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? '' : '-';
	var add_zeros = function (n) {
		return A2($elm$core$String$repeat, n, '0');
	};
	var abs_m = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? m : $cmditch$elm_bigint$BigInt$negate(m);
	var s = $cmditch$elm_bigint$BigInt$toString(abs_m);
	var _v1 = A2($elm$core$Basics$compare, e, 0);
	switch (_v1) {
		case 1:
			return _Utils_ap(sign, s);
		case 2:
			return _Utils_ap(
				sign,
				_Utils_ap(
					s,
					add_zeros(e)));
		default:
			return _Utils_ap(
				sign,
				A2($prikhi$decimal$Decimal$insert_decimal_period, 0 - e, s));
	}
};
var $danyx23$elm_uuid$Uuid$toString = function (_v0) {
	var internalString = _v0;
	return internalString;
};
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$VianelloScalarCodecs$codecs = $author$project$Gql$Vianello$Scalar$defineCodecs(
	{
		ix: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedTime) {
					if (!maybeParsedTime.$) {
						var parsedTime = maybeParsedTime.a;
						return $elm$json$Json$Decode$succeed(parsedTime);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse Datetime.');
					}
				},
				A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime, $elm$core$Result$toMaybe),
					$elm$json$Json$Decode$string)),
			fk: function (dateTime) {
				return $elm$json$Json$Encode$string(
					$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(dateTime));
			}
		},
		mJ: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeDecimal) {
					return A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Decode$fail('impossibile convertire il valore in decimal'),
						A2($elm$core$Maybe$map, $elm$json$Json$Decode$succeed, maybeDecimal));
				},
				A2($elm$json$Json$Decode$map, $prikhi$decimal$Decimal$fromString, $elm$json$Json$Decode$string)),
			fk: function (decimal) {
				return $elm$json$Json$Encode$string(
					$prikhi$decimal$Decimal$toString(decimal));
			}
		},
		mK: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedTime) {
					if (!maybeParsedTime.$) {
						var parsedTime = maybeParsedTime.a;
						return $elm$json$Json$Decode$succeed(parsedTime);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse Time as an Posix.');
					}
				},
				A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromIsoString, $elm$core$Result$toMaybe),
					$elm$json$Json$Decode$string)),
			fk: function (dateTime) {
				return $elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(dateTime));
			}
		},
		t: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedId) {
					if (!maybeParsedId.$) {
						var parsedId = maybeParsedId.a;
						return $elm$json$Json$Decode$succeed(parsedId);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse ID as an Uuid.');
					}
				},
				A2($elm$json$Json$Decode$map, $danyx23$elm_uuid$Uuid$fromString, $elm$json$Json$Decode$string)),
			fk: function (uuid) {
				return $elm$json$Json$Encode$string(
					$danyx23$elm_uuid$Uuid$toString(uuid));
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Gql$Vianello$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).fk,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Gql$Vianello$Mutation$createOrder = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'createOrder',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'saveId',
					requiredArgs____.pz,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$CreateOrderResult$Fragments = F2(
	function (onOrder, onCreateOrderErrors) {
		return {gg: onCreateOrderErrors, gv: onOrder};
	});
var $author$project$Checkout$Model$CreateOrderErrors = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$OrderError = F2(
	function (field, description) {
		return {m2: description, ns: field};
	});
var $author$project$Gql$Vianello$Object$CreateOrderError$field = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'field',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$CreateOrderError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Order$createOrderErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$CreateOrderError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$CreateOrderError$field,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$OrderError)));
var $author$project$Gql$Vianello$Object$CreateOrderErrors$errors = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'errors',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Checkout$Api$Order$createOrderErrorsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$CreateOrderErrors$errors($author$project$Checkout$Api$Order$createOrderErrorSelection),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$CreateOrderErrors));
var $author$project$Checkout$Model$CreateOrderOK = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$Order = F6(
	function (orderId, amount, saveExpiration, effectiveDate, save, billingAddress) {
		return {bS: amount, e0: billingAddress, m8: effectiveDate, kw: orderId, py: save, lh: saveExpiration};
	});
var $author$project$Gql$Vianello$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Gql$Vianello$Object$Order$amount = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Decimal',
	'amount',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mJ.m0);
var $author$project$Gql$Vianello$Object$Order$billingAddress = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'billingAddress', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Checkout$Model$BillingAddress = F9(
	function (firstName, lastName, phoneNumber, streetAddress, streetNumber, city, zipCode, countryCode, region) {
		return {my: city, e6: countryCode, nu: firstName, n8: lastName, cu: phoneNumber, hk: region, hN: streetAddress, pO: streetNumber, qg: zipCode};
	});
var $author$project$Gql$Vianello$Object$OrderBillingAddress$city = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'city', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$countryCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'countryCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$phoneNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'phoneNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$region = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'region', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$streetAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetAddress', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$streetNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$OrderBillingAddress$zipCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'zipCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Order$billingAddressSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$OrderBillingAddress$region,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$OrderBillingAddress$countryCode,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$OrderBillingAddress$zipCode,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$OrderBillingAddress$city,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$OrderBillingAddress$streetNumber,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$OrderBillingAddress$streetAddress,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$OrderBillingAddress$phoneNumber,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$OrderBillingAddress$lastName,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Vianello$Object$OrderBillingAddress$firstName,
									$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$BillingAddress))))))))));
var $author$project$Gql$Vianello$Object$Order$effectiveDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe VianelloScalarCodecs.DateTimeUtc)',
	'effectiveDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).ix.m0));
var $author$project$Gql$Vianello$Object$Order$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Gql$Vianello$Object$Order$save = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'save', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Order$saveExpiration = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.DateTimeUtc',
	'saveExpiration',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).ix.m0);
var $author$project$Types$Save$Save = function (id) {
	return function (saveCode) {
		return function (productType) {
			return function (configuration) {
				return function (quote) {
					return function (status) {
						return function (sourceInfo) {
							return function (selection) {
								return function (actions) {
									return function (parentInsuranceId) {
										return {h4: actions, mO: configuration, nM: id, kB: parentInsuranceId, kX: productType, pd: quote, lg: saveCode, hs: selection, pI: sourceInfo, hK: status};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Vianello$Object$Save$actions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'actions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Save$configuration = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'configuration', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Save$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Gql$Vianello$Object$Save$parentInsuranceId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe VianelloScalarCodecs.Uuid)',
	'parentInsuranceId',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0));
var $author$project$Gql$Vianello$Object$Save$productType = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'productType', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Save$quote = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'quote', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Quote$Quote = F8(
	function (id, prices, formType, productType, information, sourceInfo, version, informationPackageLink) {
		return {nx: formType, nM: id, nT: information, dH: informationPackageLink, kR: prices, kX: productType, pI: sourceInfo, lU: version};
	});
var $author$project$Gql$Vianello$Enum$FormType$FamilyOnly = 0;
var $author$project$Gql$Vianello$Enum$FormType$Full = 1;
var $author$project$Gql$Vianello$Enum$FormType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'FAMILY_ONLY':
				return $elm$json$Json$Decode$succeed(0);
			case 'FULL':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid FormType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Quote$formType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.FormType.FormType)',
	'formType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$FormType$decoder));
var $author$project$Gql$Vianello$Object$Quote$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Gql$Vianello$Object$Quote$information = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'information', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Quote$informationPackageLink = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'informationPackageLink', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$FamilyOnly = 1;
var $author$project$Types$Quote$Full = 0;
var $author$project$Guarantees$Api$Quote$mapFormType = function (formType) {
	if (!formType) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Types$Quote$QuoteV1 = 0;
var $author$project$Types$Quote$QuoteV2 = 1;
var $author$project$Guarantees$Api$Quote$mapQuoteVersion = function (quoteVersion) {
	if (!quoteVersion) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Object$Quote$prices = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'prices',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Quote$productType = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'productType', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Quote$QuoteInformation = function (name) {
	return function (surname) {
		return function (gender) {
			return function (birthday) {
				return function (birthCity) {
					return function (birthLandRegister) {
						return function (birthCountry) {
							return function (email) {
								return function (phone) {
									return function (residenceAddress) {
										return function (propertyAddress) {
											return function (ownerOrTenant) {
												return function (propertyPurpose) {
													return function (propertyType) {
														return function (condominiumType) {
															return function (villaType) {
																return function (flatFloor) {
																	return function (totalAreaSqm) {
																		return function (constructionPeriod) {
																			return function (reportedAccidents) {
																				return function (familyAdults) {
																					return function (familyUnderages) {
																						return {ih: birthCity, mj: birthCountry, mm: birthLandRegister, mn: birthday, mN: condominiumType, mP: constructionPeriod, fi: email, np: familyAdults, nr: familyUnderages, nw: flatFloor, ny: gender, oq: name, oL: ownerOrTenant, oT: phone, kY: propertyAddress, o1: propertyPurpose, o4: propertyType, pn: reportedAccidents, pq: residenceAddress, lC: surname, p3: totalAreaSqm, qa: villaType};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Types$Quote$Address = F7(
	function (street, number, city, zipCode, staircase, entrance, province) {
		return {my: city, fl: entrance, kp: number, o6: province, hH: staircase, lx: street, qg: zipCode};
	});
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$city = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'city', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$entrance = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'entrance',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$provinceCode = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'provinceCode',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$staircase = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'staircase',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$streetAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetAddress', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$streetNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInfoAddress$zipCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'zipCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$addressSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuoteInfoAddress$provinceCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuoteInfoAddress$entrance,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$QuoteInfoAddress$staircase,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$QuoteInfoAddress$zipCode,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$QuoteInfoAddress$city,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$QuoteInfoAddress$streetNumber,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$QuoteInfoAddress$streetAddress,
							$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Address))))))));
var $author$project$Types$Quote$ForeignCountry = {$: 0};
var $author$project$Types$Quote$ItalianCity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Guarantees$Api$Quote$cityFromString = function (city) {
	var _v0 = $elm$core$String$toLower(city);
	if (_v0 === 'stato estero') {
		return $author$project$Types$Quote$ForeignCountry;
	} else {
		return $author$project$Types$Quote$ItalianCity(city);
	}
};
var $author$project$Gql$Vianello$Object$QuoteInformation$cityOfBirth = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cityOfBirth', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$cityOfBirthLandRegister = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'cityOfBirthLandRegister',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$QuoteInformation$colivingAdults = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'colivingAdults',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Vianello$Object$QuoteInformation$colivingMinors = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'colivingMinors',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Vianello$Enum$CondominiumType$MoreThanEightApartment = 1;
var $author$project$Gql$Vianello$Enum$CondominiumType$UpToEightApartment = 0;
var $author$project$Gql$Vianello$Enum$CondominiumType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'UP_TO_EIGHT_APARTMENT':
				return $elm$json$Json$Decode$succeed(0);
			case 'MORE_THAN_EIGHT_APARTMENT':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid CondominiumType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$condominiumType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.CondominiumType.CondominiumType)',
	'condominiumType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$CondominiumType$decoder));
var $author$project$Types$Quote$MoreThanEight = 1;
var $author$project$Types$Quote$UpToEight = 0;
var $author$project$Guarantees$Api$Quote$condominiumTypeMap = function (condominiumType) {
	if (!condominiumType) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Before1940 = 2;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Before1970 = 1;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1940 = 3;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1950 = 4;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1960 = 5;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1970 = 6;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1980 = 7;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade1990 = 8;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade2000 = 9;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade2010 = 10;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Decade2020 = 11;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$Unknown = 0;
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'UNKNOWN':
				return $elm$json$Json$Decode$succeed(0);
			case 'BEFORE1970':
				return $elm$json$Json$Decode$succeed(1);
			case 'BEFORE1940':
				return $elm$json$Json$Decode$succeed(2);
			case 'DECADE1940':
				return $elm$json$Json$Decode$succeed(3);
			case 'DECADE1950':
				return $elm$json$Json$Decode$succeed(4);
			case 'DECADE1960':
				return $elm$json$Json$Decode$succeed(5);
			case 'DECADE1970':
				return $elm$json$Json$Decode$succeed(6);
			case 'DECADE1980':
				return $elm$json$Json$Decode$succeed(7);
			case 'DECADE1990':
				return $elm$json$Json$Decode$succeed(8);
			case 'DECADE2000':
				return $elm$json$Json$Decode$succeed(9);
			case 'DECADE2010':
				return $elm$json$Json$Decode$succeed(10);
			case 'DECADE2020':
				return $elm$json$Json$Decode$succeed(11);
			default:
				return $elm$json$Json$Decode$fail('Invalid ConstructionPeriod type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$constructionPeriod = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.ConstructionPeriod.ConstructionPeriod)',
	'constructionPeriod',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$ConstructionPeriod$decoder));
var $author$project$Types$Quote$Before1940 = 2;
var $author$project$Types$Quote$Before1970 = 1;
var $author$project$Types$Quote$Decade1940 = 3;
var $author$project$Types$Quote$Decade1950 = 4;
var $author$project$Types$Quote$Decade1960 = 5;
var $author$project$Types$Quote$Decade1970 = 6;
var $author$project$Types$Quote$Decade1980 = 7;
var $author$project$Types$Quote$Decade1990 = 8;
var $author$project$Types$Quote$Decade2000 = 9;
var $author$project$Types$Quote$Decade2010 = 10;
var $author$project$Types$Quote$Decade2020 = 11;
var $author$project$Types$Quote$Unknown = 0;
var $author$project$Guarantees$Api$Quote$constructionPeriodMap = function (constructionPeriod) {
	switch (constructionPeriod) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		default:
			return 11;
	}
};
var $author$project$Gql$Vianello$Object$QuoteInformation$countryOfBirth = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'countryOfBirth', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$customerAddress = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'customerAddress',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Vianello$Object$QuoteInformation$dateOfBirth = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.NaiveDate',
	'dateOfBirth',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mK.m0);
var $author$project$Gql$Vianello$Object$QuoteInformation$email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'email', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Enum$FloorType$Ground = 0;
var $author$project$Gql$Vianello$Enum$FloorType$Intermediate = 1;
var $author$project$Gql$Vianello$Enum$FloorType$Top = 2;
var $author$project$Gql$Vianello$Enum$FloorType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'GROUND':
				return $elm$json$Json$Decode$succeed(0);
			case 'INTERMEDIATE':
				return $elm$json$Json$Decode$succeed(1);
			case 'TOP':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid FloorType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$floorType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.FloorType.FloorType)',
	'floorType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$FloorType$decoder));
var $author$project$Types$Quote$Low = 0;
var $author$project$Types$Quote$Medium = 1;
var $author$project$Types$Quote$Top = 2;
var $author$project$Guarantees$Api$Quote$floorTypeMap = function (floorType) {
	switch (floorType) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Gql$Vianello$Enum$Gender$F = 1;
var $author$project$Gql$Vianello$Enum$Gender$M = 0;
var $author$project$Gql$Vianello$Enum$Gender$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'M':
				return $elm$json$Json$Decode$succeed(0);
			case 'F':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid Gender type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$gender = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Gender.Gender', 'gender', _List_Nil, $author$project$Gql$Vianello$Enum$Gender$decoder);
var $author$project$Types$Quote$F = 1;
var $author$project$Types$Quote$M = 0;
var $author$project$Guarantees$Api$Quote$genderMap = function (gender) {
	if (gender === 1) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Gql$Vianello$Enum$HouseType$SingleFamily = 0;
var $author$project$Gql$Vianello$Enum$HouseType$TerracedHouse = 1;
var $author$project$Gql$Vianello$Enum$HouseType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'SINGLE_FAMILY':
				return $elm$json$Json$Decode$succeed(0);
			case 'TERRACED_HOUSE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid HouseType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$houseType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.HouseType.HouseType)',
	'houseType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$HouseType$decoder));
var $author$project$Types$Quote$SingleFamily = 0;
var $author$project$Types$Quote$TerracedHouse = 1;
var $author$project$Guarantees$Api$Quote$houseTypeMap = function (houseType) {
	if (!houseType) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Object$QuoteInformation$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Enum$Ownership$NoProperty = 2;
var $author$project$Gql$Vianello$Enum$Ownership$Owner = 0;
var $author$project$Gql$Vianello$Enum$Ownership$Tenant = 1;
var $author$project$Gql$Vianello$Enum$Ownership$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'OWNER':
				return $elm$json$Json$Decode$succeed(0);
			case 'TENANT':
				return $elm$json$Json$Decode$succeed(1);
			case 'NO_PROPERTY':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid Ownership type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$ownership = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Ownership.Ownership', 'ownership', _List_Nil, $author$project$Gql$Vianello$Enum$Ownership$decoder);
var $author$project$Types$Quote$NoPropertyOwnership = 2;
var $author$project$Types$Quote$Owner = 0;
var $author$project$Types$Quote$Tenant = 1;
var $author$project$Guarantees$Api$Quote$ownershipMap = function (ownership) {
	switch (ownership) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Gql$Vianello$Object$QuoteInformation$pastIncidents = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'pastIncidents',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Vianello$Object$QuoteInformation$phoneMobile = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'phoneMobile', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$propertyAddress = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'propertyAddress',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Vianello$Enum$PropertyType$Apartment = 0;
var $author$project$Gql$Vianello$Enum$PropertyType$House = 1;
var $author$project$Gql$Vianello$Enum$PropertyType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'APARTMENT':
				return $elm$json$Json$Decode$succeed(0);
			case 'HOUSE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid PropertyType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$propertyType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.PropertyType.PropertyType)',
	'propertyType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Vianello$Enum$PropertyType$decoder));
var $author$project$Types$Quote$Apartment = 0;
var $author$project$Types$Quote$House = 1;
var $author$project$Guarantees$Api$Quote$propertyTypeMap = function (propertyType) {
	if (!propertyType) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Enum$PropertyUsage$NoProperty = 4;
var $author$project$Gql$Vianello$Enum$PropertyUsage$Rented = 2;
var $author$project$Gql$Vianello$Enum$PropertyUsage$Residential = 0;
var $author$project$Gql$Vianello$Enum$PropertyUsage$SecondHouse = 1;
var $author$project$Gql$Vianello$Enum$PropertyUsage$Uninhabited = 3;
var $author$project$Gql$Vianello$Enum$PropertyUsage$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'RESIDENTIAL':
				return $elm$json$Json$Decode$succeed(0);
			case 'SECOND_HOUSE':
				return $elm$json$Json$Decode$succeed(1);
			case 'RENTED':
				return $elm$json$Json$Decode$succeed(2);
			case 'UNINHABITED':
				return $elm$json$Json$Decode$succeed(3);
			case 'NO_PROPERTY':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('Invalid PropertyUsage type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuoteInformation$propertyUsageType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PropertyUsage.PropertyUsage', 'propertyUsageType', _List_Nil, $author$project$Gql$Vianello$Enum$PropertyUsage$decoder);
var $author$project$Types$Quote$NoPropertyUsageType = 4;
var $author$project$Types$Quote$Occasional = 1;
var $author$project$Types$Quote$Rented = 2;
var $author$project$Types$Quote$Residential = 0;
var $author$project$Types$Quote$Uninhabited = 3;
var $author$project$Guarantees$Api$Quote$propertyUsageTypeMap = function (propertyUsageType) {
	switch (propertyUsageType) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		default:
			return 4;
	}
};
var $author$project$Gql$Vianello$Object$QuoteInformation$totalAreaSqm = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'totalAreaSqm',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Guarantees$Api$Quote$quoteInformationSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuoteInformation$colivingMinors,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuoteInformation$colivingAdults,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$QuoteInformation$pastIncidents,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
					$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$constructionPeriodMap),
					$author$project$Gql$Vianello$Object$QuoteInformation$constructionPeriod),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$QuoteInformation$totalAreaSqm,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
							$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$floorTypeMap),
							$author$project$Gql$Vianello$Object$QuoteInformation$floorType),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
								$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$houseTypeMap),
								$author$project$Gql$Vianello$Object$QuoteInformation$houseType),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
									$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$condominiumTypeMap),
									$author$project$Gql$Vianello$Object$QuoteInformation$condominiumType),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
										$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$propertyTypeMap),
										$author$project$Gql$Vianello$Object$QuoteInformation$propertyType),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$propertyUsageTypeMap, $author$project$Gql$Vianello$Object$QuoteInformation$propertyUsageType),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$ownershipMap, $author$project$Gql$Vianello$Object$QuoteInformation$ownership),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Vianello$Object$QuoteInformation$propertyAddress($author$project$Guarantees$Api$Quote$addressSelection),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Vianello$Object$QuoteInformation$customerAddress($author$project$Guarantees$Api$Quote$addressSelection),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Vianello$Object$QuoteInformation$phoneMobile,
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															$author$project$Gql$Vianello$Object$QuoteInformation$email,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																$author$project$Gql$Vianello$Object$QuoteInformation$countryOfBirth,
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Vianello$Object$QuoteInformation$cityOfBirthLandRegister,
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$cityFromString, $author$project$Gql$Vianello$Object$QuoteInformation$cityOfBirth),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Vianello$Object$QuoteInformation$dateOfBirth,
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$genderMap, $author$project$Gql$Vianello$Object$QuoteInformation$gender),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Vianello$Object$QuoteInformation$lastName,
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Vianello$Object$QuoteInformation$firstName,
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$QuoteInformation)))))))))))))))))))))));
var $author$project$Types$Quote$QuotePrice = F5(
	function (issuingCompany, sections, inclusionPackagesRules, inclusionClustersRules, messages) {
		return {nP: inclusionClustersRules, nQ: inclusionPackagesRules, b9: issuingCompany, oh: messages, pB: sections};
	});
var $author$project$Types$Quote$ClustersRule = F2(
	function (clusters, rule) {
		return {mG: clusters, le: rule};
	});
var $author$project$Gql$Vianello$Object$ClustersRule$clusters = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'clusters',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Enum$Rule$AlwaysIncluded = 1;
var $author$project$Gql$Vianello$Enum$Rule$AnyOf = 0;
var $author$project$Gql$Vianello$Enum$Rule$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'ANY_OF':
				return $elm$json$Json$Decode$succeed(0);
			case 'ALWAYS_INCLUDED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid Rule type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ClustersRule$rule = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Rule.Rule', 'rule', _List_Nil, $author$project$Gql$Vianello$Enum$Rule$decoder);
var $author$project$Types$Quote$AlwaysIncluded = 1;
var $author$project$Types$Quote$AnyOf = 0;
var $author$project$Guarantees$Api$Quote$ruleMap = function (rule) {
	if (!rule) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Guarantees$Api$Quote$clustersRuleSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$ruleMap, $author$project$Gql$Vianello$Object$ClustersRule$rule),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ClustersRule$clusters,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ClustersRule)));
var $author$project$Gql$Vianello$Object$Price$inclusionClustersRules = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'inclusionClustersRules',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Price$inclusionPackagesRules = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'inclusionPackagesRules',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Price$issuingCompany = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'issuingCompany', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Quote$IssuingCompany = F2(
	function (companyName, label) {
		return {mM: companyName, n6: label};
	});
var $author$project$Gql$Vianello$Object$IssuingCompany$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$IssuingCompany$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$issuingCompanySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$IssuingCompany$name,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$IssuingCompany$slug,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$IssuingCompany)));
var $author$project$Gql$Vianello$Object$Price$messages = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'messages',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Quote$PackagesRule = F2(
	function (packages, rule) {
		return {oN: packages, le: rule};
	});
var $author$project$Gql$Vianello$Object$PackagesRule$packages = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'packages',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$PackagesRule$rule = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Rule.Rule', 'rule', _List_Nil, $author$project$Gql$Vianello$Enum$Rule$decoder);
var $author$project$Guarantees$Api$Quote$packagesRuleSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$ruleMap, $author$project$Gql$Vianello$Object$PackagesRule$rule),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PackagesRule$packages,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PackagesRule)));
var $author$project$Gql$Vianello$Interface$PriceMessage$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PriceMessageDiscount', selections____.gC)
			]));
};
var $author$project$Types$Quote$PriceMessage = F2(
	function (content, messageType) {
		return {mQ: content, og: messageType};
	});
var $author$project$Gql$Vianello$Object$PriceMessageDiscount$content = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'content', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Enum$PriceMessageDiscountType$ExistingMotorCustomer = 0;
var $author$project$Gql$Vianello$Enum$PriceMessageDiscountType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (string === 'EXISTING_MOTOR_CUSTOMER') {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			return $elm$json$Json$Decode$fail('Invalid PriceMessageDiscountType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$PriceMessageDiscount$discountType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PriceMessageDiscountType.PriceMessageDiscountType', 'discountType', _List_Nil, $author$project$Gql$Vianello$Enum$PriceMessageDiscountType$decoder);
var $author$project$Types$Quote$PriceMessageContent = F3(
	function (title, body, badge) {
		return {mi: badge, mo: body, eE: title};
	});
var $author$project$Gql$Vianello$Object$PriceMessageContent$badge = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'badge', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$PriceMessageContent$body = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'body',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$PriceMessageContent$title = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'title',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Guarantees$Api$Quote$priceMessageContentSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$PriceMessageContent$badge,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PriceMessageContent$body,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$PriceMessageContent$title,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PriceMessageContent))));
var $author$project$Types$Quote$ExistingMotorCustomer = 0;
var $author$project$Types$Quote$PriceMessageTypeDiscount = $elm$core$Basics$identity;
var $author$project$Guarantees$Api$Quote$priceMessageDiscountTypeMap = function (discountType) {
	return 0;
};
var $author$project$Guarantees$Api$Quote$priceMessageDiscountSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$priceMessageDiscountTypeMap, $author$project$Gql$Vianello$Object$PriceMessageDiscount$discountType),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PriceMessageDiscount$content($author$project$Guarantees$Api$Quote$priceMessageContentSelection),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PriceMessage)));
var $author$project$Guarantees$Api$Quote$priceMessageSelection = $author$project$Gql$Vianello$Interface$PriceMessage$fragments(
	{gC: $author$project$Guarantees$Api$Quote$priceMessageDiscountSelection});
var $author$project$Types$Quote$Section = F7(
	function (label, slug, weight, filterable, packageGroups, description, isSpecial) {
		return {m2: description, nt: filterable, n5: isSpecial, n6: label, oM: packageGroups, bH: slug, l0: weight};
	});
var $author$project$Gql$Vianello$Object$Section$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$Section$filterable = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'filterable', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$Section$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$PackageGroup = F4(
	function (label, packages, weight, description) {
		return {m2: description, n6: label, oN: packages, l0: weight};
	});
var $author$project$Gql$Vianello$Object$PackageGroup$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$PackageGroup$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$Package = F7(
	function (slug, label, weight, claim, selected, clusters, description) {
		return {mz: claim, mG: clusters, m2: description, n6: label, pC: selected, bH: slug, l0: weight};
	});
var $author$project$Gql$Vianello$Object$Package$claim = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'claim',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Types$Quote$GuaranteeCluster = F9(
	function (slug, label, weight, mandatory, guarantees, optionGroups, description, selected, clusterDetails) {
		return {mF: clusterDetails, m2: description, nF: guarantees, n6: label, dX: mandatory, g2: optionGroups, pC: selected, bH: slug, l0: weight};
	});
var $author$project$Gql$Vianello$Object$GuaranteeCluster$clusterDetails = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'clusterDetails',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Types$Quote$ClusterDetails = F3(
	function (title, shortDescription, reasons) {
		return {pg: reasons, pH: shortDescription, eE: title};
	});
var $author$project$Gql$Vianello$Object$ClusterDetailReason$description = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'description', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ClusterDetails$reasons = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'reasons',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$ClusterDetails$shortDescription = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'shortDescription', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ClusterDetails$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$clusterDetailsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ClusterDetails$reasons($author$project$Gql$Vianello$Object$ClusterDetailReason$description),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ClusterDetails$shortDescription,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$ClusterDetails$title,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ClusterDetails))));
var $author$project$Gql$Vianello$Object$GuaranteeCluster$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Interface$Guarantee$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'GuaranteeYearly', selections____.gm),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'GuaranteeMonthly', selections____.gl)
			]));
};
var $author$project$Types$Quote$Monthly = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Quote$Guarantee = function (slug) {
	return function (label) {
		return function (weight) {
			return function (clusterDependencies) {
				return function (guaranteeDependencies) {
					return function (priceBlocks) {
						return function (guaranteeType) {
							return function (optionsGroup) {
								return function (descriptionCoverage) {
									return function (descriptionDetails) {
										return function (details) {
											return function (guaranteeClaim) {
												return function (selected) {
													return {mE: clusterDependencies, iO: descriptionCoverage, iP: descriptionDetails, m4: details, nC: guaranteeClaim, nD: guaranteeDependencies, js: guaranteeType, n6: label, g3: optionsGroup, kQ: priceBlocks, pC: selected, bH: slug, l0: weight};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$clusterDependencies = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'clusterDependencies',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$descriptionCoverage = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'descriptionCoverage',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$descriptionDetails = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'descriptionDetails',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeClaim = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'guaranteeClaim',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeDependencies = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeDependencies',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Quote$GuaranteeDependency = F2(
	function (guarantees, rules) {
		return {nF: guarantees, px: rules};
	});
var $author$project$Gql$Vianello$Object$GuaranteeDependency$guarantees = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'guarantees',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded = F2(
	function (constant, _v0) {
		var objectFields = _v0.a;
		var objectDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			objectFields,
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Basics$apR,
				$elm$json$Json$Decode$succeed(constant),
				objectDecoder));
	});
var $author$project$Guarantees$Api$Quote$guaranteeDependencySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeDependency$guarantees,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$GuaranteeDependency)));
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeDetails = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeDetails',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Types$Quote$Details = F6(
	function (title, shortDescription, coverageDetails, coverageExclusions, coverageInclusions, reasons) {
		return {mU: coverageDetails, mV: coverageExclusions, mW: coverageInclusions, pg: reasons, pH: shortDescription, eE: title};
	});
var $author$project$Types$Quote$CoverageDetail = F2(
	function (title, description) {
		return {m2: description, eE: title};
	});
var $author$project$Gql$Vianello$Object$GuaranteeDetailCoverageDetail$description = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'description', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeDetailCoverageDetail$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$coverageDetailSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeDetailCoverageDetail$description,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeDetailCoverageDetail$title,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$CoverageDetail)));
var $author$project$Gql$Vianello$Object$GuaranteeDetails$coverageDetails = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'coverageDetails',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeDetails$coverageExclusions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'coverageExclusions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeDetails$coverageInclusions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'coverageInclusions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeDetailCoverageExclusion$description = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'description', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeDetailCoverageInclusion$description = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'description', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeDetailReason$description = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'description', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeDetails$reasons = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'reasons',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeDetails$shortDescription = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'shortDescription', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeDetails$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$guaranteeDetailsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeDetails$reasons($author$project$Gql$Vianello$Object$GuaranteeDetailReason$description),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeDetails$coverageInclusions($author$project$Gql$Vianello$Object$GuaranteeDetailCoverageInclusion$description),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$GuaranteeDetails$coverageExclusions($author$project$Gql$Vianello$Object$GuaranteeDetailCoverageExclusion$description),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$GuaranteeDetails$coverageDetails($author$project$Guarantees$Api$Quote$coverageDetailSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$GuaranteeDetails$shortDescription,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$GuaranteeDetails$title,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Details)))))));
var $author$project$Gql$Vianello$Enum$GuaranteeType$Excluded = 2;
var $author$project$Gql$Vianello$Enum$GuaranteeType$Included = 0;
var $author$project$Gql$Vianello$Enum$GuaranteeType$Optional = 1;
var $author$project$Gql$Vianello$Enum$GuaranteeType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'INCLUDED':
				return $elm$json$Json$Decode$succeed(0);
			case 'OPTIONAL':
				return $elm$json$Json$Decode$succeed(1);
			case 'EXCLUDED':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid GuaranteeType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.GuaranteeType.GuaranteeType', 'guaranteeType', _List_Nil, $author$project$Gql$Vianello$Enum$GuaranteeType$decoder);
var $author$project$Types$Quote$Excluded = 2;
var $author$project$Types$Quote$Included = 0;
var $author$project$Types$Quote$Optional = 1;
var $author$project$Guarantees$Api$Quote$guaranteeTypeMap = function (guaranteeType) {
	switch (guaranteeType) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$PriceBlock = F6(
	function (full, relatedOptions, price, fullPrice, selected, guaranteeBundle) {
		return {jj: full, jk: fullPrice, nB: guaranteeBundle, oY: price, pl: relatedOptions, pC: selected};
	});
var $author$project$Gql$Vianello$Object$MonthlyPriceBlock$full = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'full', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$MonthlyPriceBlock$fullPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$MonthlyPriceBlock$guaranteeBundle = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeBundle',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Types$Quote$GuaranteeBundle = F2(
	function (discount, requiredGuarantees) {
		return {m5: discount, pp: requiredGuarantees};
	});
var $author$project$Gql$Vianello$Object$GuaranteeBundle$discount = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Decimal',
	'discount',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mJ.m0);
var $author$project$Gql$Vianello$Object$GuaranteeBundle$requiredGuarantees = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'requiredGuarantees',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Guarantees$Api$Quote$guaranteeBundleSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeBundle$requiredGuarantees,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeBundle$discount,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$GuaranteeBundle)));
var $author$project$Gql$Vianello$Object$MonthlyPriceBlock$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Quote$PriceValue = F2(
	function (net, taxes) {
		return {kk: net, lF: taxes};
	});
var $author$project$Gql$Vianello$Object$PriceValue$net = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Decimal',
	'net',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mJ.m0);
var $author$project$Gql$Vianello$Object$PriceValue$taxes = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Decimal',
	'taxes',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mJ.m0);
var $author$project$Guarantees$Api$Quote$priceValueSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$PriceValue$taxes,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PriceValue$net,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PriceValue)));
var $author$project$Types$Quote$RelatedOption = F3(
	function (slug, optionType, target) {
		return {oJ: optionType, bH: slug, pT: target};
	});
var $author$project$Gql$Vianello$Object$RelatedOption$optionType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'optionType', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$ClusterTarget = 0;
var $author$project$Types$Quote$GuaranteeTarget = 1;
var $author$project$Guarantees$Api$Quote$relatedOptionTargetMap = function (optionTarget) {
	if (!optionTarget) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Object$RelatedOption$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Enum$RelatedOptionTarget$Cluster = 0;
var $author$project$Gql$Vianello$Enum$RelatedOptionTarget$Guarantee = 1;
var $author$project$Gql$Vianello$Enum$RelatedOptionTarget$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CLUSTER':
				return $elm$json$Json$Decode$succeed(0);
			case 'GUARANTEE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid RelatedOptionTarget type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$RelatedOption$target = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.RelatedOptionTarget.RelatedOptionTarget', 'target', _List_Nil, $author$project$Gql$Vianello$Enum$RelatedOptionTarget$decoder);
var $author$project$Guarantees$Api$Quote$relatedOptionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$relatedOptionTargetMap, $author$project$Gql$Vianello$Object$RelatedOption$target),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$RelatedOption$optionType,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$RelatedOption$slug,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$RelatedOption))));
var $author$project$Gql$Vianello$Object$MonthlyPriceBlock$relatedOptions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'relatedOptions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Guarantees$Api$Quote$monthlyPriceBlockSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$MonthlyPriceBlock$guaranteeBundle($author$project$Guarantees$Api$Quote$guaranteeBundleSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		false,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$MonthlyPriceBlock$fullPrice($author$project$Guarantees$Api$Quote$priceValueSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$MonthlyPriceBlock$price($author$project$Guarantees$Api$Quote$priceValueSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$MonthlyPriceBlock$relatedOptions($author$project$Guarantees$Api$Quote$relatedOptionSelection),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$MonthlyPriceBlock$full($author$project$Guarantees$Api$Quote$priceValueSelection),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PriceBlock)))))));
var $author$project$Gql$Vianello$Interface$OptionGroup$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BooleanOptionGroup', selections____.f7),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ListOptionGroup', selections____.gs)
			]));
};
var $author$project$Types$Quote$BooleanGroup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Quote$BooleanOptionGroup = F5(
	function (label, coverage, type_, mandatory, option) {
		return {ap: coverage, n6: label, dX: mandatory, g1: option, ao: type_};
	});
var $author$project$Gql$Vianello$Object$BooleanOptionGroup$coverage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'coverage', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Quote$GuaranteeOption = F3(
	function (slug, values, selected) {
		return {pC: selected, bH: slug, hZ: values};
	});
var $author$project$Types$Quote$GuaranteeOptionValue = F2(
	function (key, value) {
		return {fR: key, p8: value};
	});
var $author$project$Gql$Vianello$Object$GuaranteeOptionValue$key = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'key', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeOptionValue$value = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'value', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$guaranteeOptionValueSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeOptionValue$value,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeOptionValue$key,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$GuaranteeOptionValue)));
var $author$project$Gql$Vianello$Object$GuaranteeOption$selected = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'selected', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$GuaranteeOption$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeOption$values = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'values',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Guarantees$Api$Quote$guaranteeOptionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeOption$selected,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeOption$values($author$project$Guarantees$Api$Quote$guaranteeOptionValueSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$GuaranteeOption$slug,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$GuaranteeOption))));
var $author$project$Gql$Vianello$Object$BooleanOptionGroup$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$BooleanOptionGroup$mandatory = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'mandatory', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$BooleanOptionGroup$option = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'option', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$BooleanOptionGroup$type_ = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'type', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$booleanOptionGroupSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$BooleanOptionGroup$option($author$project$Guarantees$Api$Quote$guaranteeOptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$BooleanOptionGroup$mandatory,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$BooleanOptionGroup$type_,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$BooleanOptionGroup$coverage,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$BooleanOptionGroup$label,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$BooleanOptionGroup))))));
var $author$project$Guarantees$Api$Quote$onBooleanOptionGroupSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$booleanOptionGroupSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$BooleanGroup));
var $author$project$Types$Quote$ListGroup = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Quote$ListOptionGroup = F5(
	function (label, coverage, type_, mandatory, options) {
		return {ap: coverage, n6: label, dX: mandatory, ku: options, ao: type_};
	});
var $author$project$Gql$Vianello$Object$ListOptionGroup$coverage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'coverage', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ListOptionGroup$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ListOptionGroup$mandatory = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'mandatory', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$ListOptionGroup$options = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'options',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$ListOptionGroup$type_ = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'type', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$listOptionGroupSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ListOptionGroup$options($author$project$Guarantees$Api$Quote$guaranteeOptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ListOptionGroup$mandatory,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$ListOptionGroup$type_,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$ListOptionGroup$coverage,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$ListOptionGroup$label,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ListOptionGroup))))));
var $author$project$Guarantees$Api$Quote$onListOptionGroupSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$listOptionGroupSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ListGroup));
var $author$project$Guarantees$Api$Quote$optionGroupSelection = $author$project$Gql$Vianello$Interface$OptionGroup$fragments(
	{f7: $author$project$Guarantees$Api$Quote$onBooleanOptionGroupSelection, gs: $author$project$Guarantees$Api$Quote$onListOptionGroupSelection});
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$optionGroups = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'optionGroups',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$priceBlocks = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'priceBlocks',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$selected = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'selected', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Gql$Vianello$Object$GuaranteeMonthly$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$guaranteeMonthlySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeMonthly$selected,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeClaim,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeDetails($author$project$Guarantees$Api$Quote$guaranteeDetailsSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$GuaranteeMonthly$descriptionDetails,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$GuaranteeMonthly$descriptionCoverage,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$GuaranteeMonthly$optionGroups($author$project$Guarantees$Api$Quote$optionGroupSelection),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$guaranteeTypeMap, $author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeType),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$GuaranteeMonthly$priceBlocks($author$project$Guarantees$Api$Quote$monthlyPriceBlockSelection),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Vianello$Object$GuaranteeMonthly$guaranteeDependencies($author$project$Guarantees$Api$Quote$guaranteeDependencySelection),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Vianello$Object$GuaranteeMonthly$clusterDependencies,
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Vianello$Object$GuaranteeMonthly$weight,
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Vianello$Object$GuaranteeMonthly$label,
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Vianello$Object$GuaranteeMonthly$slug,
													$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Guarantee))))))))))))));
var $author$project$Guarantees$Api$Quote$onGuaranteeMonthlySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$guaranteeMonthlySelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Monthly));
var $author$project$Types$Quote$Yearly = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Object$GuaranteeYearly$clusterDependencies = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'clusterDependencies',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeYearly$descriptionCoverage = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'descriptionCoverage',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeYearly$descriptionDetails = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'descriptionDetails',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeClaim = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'guaranteeClaim',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeDependencies = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeDependencies',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeDetails = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeDetails',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.GuaranteeType.GuaranteeType', 'guaranteeType', _List_Nil, $author$project$Gql$Vianello$Enum$GuaranteeType$decoder);
var $author$project$Gql$Vianello$Object$GuaranteeYearly$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeYearly$optionGroups = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'optionGroups',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$YearlyPriceBlock$full = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'full', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$YearlyPriceBlock$fullPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$YearlyPriceBlock$guaranteeBundle = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guaranteeBundle',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Vianello$Object$YearlyPriceBlock$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$YearlyPriceBlock$relatedOptions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'relatedOptions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Guarantees$Api$Quote$priceBlockSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$YearlyPriceBlock$guaranteeBundle($author$project$Guarantees$Api$Quote$guaranteeBundleSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		false,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$YearlyPriceBlock$fullPrice($author$project$Guarantees$Api$Quote$priceValueSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$YearlyPriceBlock$price($author$project$Guarantees$Api$Quote$priceValueSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$YearlyPriceBlock$relatedOptions($author$project$Guarantees$Api$Quote$relatedOptionSelection),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$YearlyPriceBlock$full($author$project$Guarantees$Api$Quote$priceValueSelection),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PriceBlock)))))));
var $author$project$Gql$Vianello$Object$GuaranteeYearly$priceBlocks = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'priceBlocks',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeYearly$selected = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'selected', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$GuaranteeYearly$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeYearly$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$guaranteeYearlySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeYearly$selected,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeClaim,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeDetails($author$project$Guarantees$Api$Quote$guaranteeDetailsSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$GuaranteeYearly$descriptionDetails,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$GuaranteeYearly$descriptionCoverage,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$GuaranteeYearly$optionGroups($author$project$Guarantees$Api$Quote$optionGroupSelection),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$guaranteeTypeMap, $author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeType),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$GuaranteeYearly$priceBlocks($author$project$Guarantees$Api$Quote$priceBlockSelection),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Vianello$Object$GuaranteeYearly$guaranteeDependencies($author$project$Guarantees$Api$Quote$guaranteeDependencySelection),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Vianello$Object$GuaranteeYearly$clusterDependencies,
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Vianello$Object$GuaranteeYearly$weight,
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Vianello$Object$GuaranteeYearly$label,
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Vianello$Object$GuaranteeYearly$slug,
													$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Guarantee))))))))))))));
var $author$project$Guarantees$Api$Quote$onGuaranteeYearlySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$guaranteeYearlySelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Yearly));
var $author$project$Guarantees$Api$Quote$guaranteeSelection = $author$project$Gql$Vianello$Interface$Guarantee$fragments(
	{gl: $author$project$Guarantees$Api$Quote$onGuaranteeMonthlySelection, gm: $author$project$Guarantees$Api$Quote$onGuaranteeYearlySelection});
var $author$project$Gql$Vianello$Object$GuaranteeCluster$guarantees = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guarantees',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeCluster$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeCluster$mandatory = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'mandatory', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$GuaranteeCluster$optionGroups = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'optionGroups',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$GuaranteeCluster$selected = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'selected', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$GuaranteeCluster$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$GuaranteeCluster$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$clusterSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeCluster$clusterDetails($author$project$Guarantees$Api$Quote$clusterDetailsSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeCluster$selected,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$GuaranteeCluster$description,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$GuaranteeCluster$optionGroups($author$project$Guarantees$Api$Quote$optionGroupSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$GuaranteeCluster$guarantees($author$project$Guarantees$Api$Quote$guaranteeSelection),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$GuaranteeCluster$mandatory,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$GuaranteeCluster$weight,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$GuaranteeCluster$label,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Vianello$Object$GuaranteeCluster$slug,
									$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$GuaranteeCluster))))))))));
var $author$project$Gql$Vianello$Object$Package$clusters = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'clusters',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Package$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$Package$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Package$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Package$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$packageSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Package$description,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Package$clusters($author$project$Guarantees$Api$Quote$clusterSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			false,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Package$claim,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Package$weight,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$Package$label,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$Package$slug,
							$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Package))))))));
var $author$project$Gql$Vianello$Object$PackageGroup$packages = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'packages',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$PackageGroup$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$packageGroupSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$PackageGroup$description,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PackageGroup$weight,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$PackageGroup$packages($author$project$Guarantees$Api$Quote$packageSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$PackageGroup$label,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$PackageGroup)))));
var $author$project$Gql$Vianello$Object$Section$packageGroups = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'packageGroups',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Section$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Section$specialField = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'specialField', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Vianello$Object$Section$weight = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Float', 'weight', _List_Nil, $elm$json$Json$Decode$float);
var $author$project$Guarantees$Api$Quote$sectionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Section$specialField,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Section$description,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Section$packageGroups($author$project$Guarantees$Api$Quote$packageGroupSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Section$filterable,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Section$weight,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$Section$slug,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$Section$label,
							$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Section))))))));
var $author$project$Gql$Vianello$Object$Price$sections = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'sections',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Guarantees$Api$Quote$quotePriceSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Price$messages($author$project$Guarantees$Api$Quote$priceMessageSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Price$inclusionClustersRules($author$project$Guarantees$Api$Quote$clustersRuleSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Price$inclusionPackagesRules($author$project$Guarantees$Api$Quote$packagesRuleSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Price$sections($author$project$Guarantees$Api$Quote$sectionSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Price$issuingCompany($author$project$Guarantees$Api$Quote$issuingCompanySelection),
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$QuotePrice))))));
var $author$project$Gql$Vianello$Union$ProductType$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ProductHomeAndFamily', selections____.gE),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ProductFamily', selections____.gD)
			]));
};
var $author$project$Types$Quote$ProductTypeFamily = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$ProductFamily$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$onProductFamily = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ProductFamily$name,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ProductTypeFamily));
var $author$project$Types$Quote$ProductTypeHomeAndFamily = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Object$ProductHomeAndFamily$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$onProductHomeAndFamily = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ProductHomeAndFamily$name,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ProductTypeHomeAndFamily));
var $author$project$Guarantees$Api$Quote$quoteProductType = $author$project$Gql$Vianello$Union$ProductType$fragments(
	{gD: $author$project$Guarantees$Api$Quote$onProductFamily, gE: $author$project$Guarantees$Api$Quote$onProductHomeAndFamily});
var $author$project$Gql$Vianello$Enum$QuoteVersion$V1 = 0;
var $author$project$Gql$Vianello$Enum$QuoteVersion$V2 = 1;
var $author$project$Gql$Vianello$Enum$QuoteVersion$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'V1':
				return $elm$json$Json$Decode$succeed(0);
			case 'V2':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid QuoteVersion type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Quote$quoteVersion = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.QuoteVersion.QuoteVersion', 'quoteVersion', _List_Nil, $author$project$Gql$Vianello$Enum$QuoteVersion$decoder);
var $author$project$Gql$Vianello$Object$Quote$sourceInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'sourceInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$SourceInfo = F4(
	function (ip, platform, operator, referral) {
		return {nZ: ip, g0: operator, ha: platform, hj: referral};
	});
var $author$project$Gql$Vianello$Object$SourceInfo$ip = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'ip',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$SourceInfo$operator = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'operator',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$SourceInfo$platform = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'platform',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$SourceInfo$referral = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'referral',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Api$sourceInfoSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SourceInfo$referral,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SourceInfo$platform,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SourceInfo$operator,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$SourceInfo$ip,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$SourceInfo)))));
var $author$project$Guarantees$Api$Quote$quoteSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Quote$informationPackageLink,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$mapQuoteVersion, $author$project$Gql$Vianello$Object$Quote$quoteVersion),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Quote$sourceInfo($author$project$Api$sourceInfoSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Quote$information($author$project$Guarantees$Api$Quote$quoteInformationSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Quote$productType($author$project$Guarantees$Api$Quote$quoteProductType),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
							$elm$core$Maybe$map($author$project$Guarantees$Api$Quote$mapFormType),
							$author$project$Gql$Vianello$Object$Quote$formType),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$Quote$prices($author$project$Guarantees$Api$Quote$quotePriceSelection),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$Quote$id,
								$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$Quote)))))))));
var $author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$endDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.DateTimeUtc',
	'endDate',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).ix.m0);
var $author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$startDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.DateTimeUtc',
	'startDate',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).ix.m0);
var $author$project$Types$Save$ChangeEffectiveDateSaveAction = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$Save$ChangeEffectiveDateSaveData = F4(
	function (name, websiteLink, startDate, endDate) {
		return {nb: endDate, oq: name, pK: startDate, eN: websiteLink};
	});
var $author$project$Types$Save$toChangeEffectiveDate = F4(
	function (name, websiteAccessLink, startDate, endDate) {
		return $author$project$Types$Save$ChangeEffectiveDateSaveAction(
			A4($author$project$Types$Save$ChangeEffectiveDateSaveData, name, websiteAccessLink, startDate, endDate));
	});
var $author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$websiteLink = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'websiteLink', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Save$WebsiteAccessLink = F2(
	function (slug, url) {
		return {bH: slug, lO: url};
	});
var $author$project$Types$Save$ChangeEffectiveDateSave = 3;
var $author$project$Types$Save$ChangeGuaranteesSave = 2;
var $author$project$Types$Save$ChangeQuote = 0;
var $author$project$Types$Save$PaySave = 1;
var $author$project$Guarantees$Api$Save$mapWebsiteAccessLinkSlug = function (websiteAccessLinkSlug) {
	switch (websiteAccessLinkSlug) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$ChangeEffectiveDateSave = 3;
var $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$ChangeGuaranteesSave = 2;
var $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$ChangeQuote = 0;
var $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$PaySave = 1;
var $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CHANGE_QUOTE':
				return $elm$json$Json$Decode$succeed(0);
			case 'PAY_SAVE':
				return $elm$json$Json$Decode$succeed(1);
			case 'CHANGE_GUARANTEES_SAVE':
				return $elm$json$Json$Decode$succeed(2);
			case 'CHANGE_EFFECTIVE_DATE_SAVE':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Invalid WebsiteAccessLinkSlug type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$WebsiteAccessLink$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.WebsiteAccessLinkSlug.WebsiteAccessLinkSlug', 'slug', _List_Nil, $author$project$Gql$Vianello$Enum$WebsiteAccessLinkSlug$decoder);
var $author$project$Gql$Vianello$Object$WebsiteAccessLink$url = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'url', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$websiteLinkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$WebsiteAccessLink$url,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Save$mapWebsiteAccessLinkSlug, $author$project$Gql$Vianello$Object$WebsiteAccessLink$slug),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$WebsiteAccessLink)));
var $author$project$Guarantees$Api$Save$changeEffectiveDateSaveActionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$endDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$startDate,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$websiteLink($author$project$Guarantees$Api$Save$websiteLinkSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$ChangeEffectiveDateSave$name,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$toChangeEffectiveDate)))));
var $author$project$Gql$Vianello$Object$ChangeGuaranteesSave$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Save$ChangeGuaranteesSaveAction = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$Save$ChangeGuaranteesSaveData = F2(
	function (name, websiteLink) {
		return {oq: name, eN: websiteLink};
	});
var $author$project$Types$Save$toGuaranteesSave = F2(
	function (name, websiteLink) {
		return $author$project$Types$Save$ChangeGuaranteesSaveAction(
			A2($author$project$Types$Save$ChangeGuaranteesSaveData, name, websiteLink));
	});
var $author$project$Gql$Vianello$Object$ChangeGuaranteesSave$websiteLink = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'websiteLink', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$changeGuaranteesSaveActionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ChangeGuaranteesSave$websiteLink($author$project$Guarantees$Api$Save$websiteLinkSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ChangeGuaranteesSave$name,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$toGuaranteesSave)));
var $author$project$Gql$Vianello$Object$ChangeQuote$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Save$ChangeQuoteAction = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Save$ChangeQuoteData = F2(
	function (name, websiteLink) {
		return {oq: name, eN: websiteLink};
	});
var $author$project$Types$Save$toChangeQuote = F2(
	function (name, websiteLink) {
		return $author$project$Types$Save$ChangeQuoteAction(
			A2($author$project$Types$Save$ChangeQuoteData, name, websiteLink));
	});
var $author$project$Gql$Vianello$Object$ChangeQuote$websiteLink = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'websiteLink', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$changeQuoteActionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ChangeQuote$websiteLink($author$project$Guarantees$Api$Save$websiteLinkSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$ChangeQuote$name,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$toChangeQuote)));
var $author$project$Gql$Vianello$Union$SaveAction$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PaySave', selections____.gy),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ChangeQuote', selections____.ga),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ChangeGuaranteesSave', selections____.f9),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'ChangeEffectiveDateSave', selections____.f8)
			]));
};
var $author$project$Gql$Vianello$Object$PaySave$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Save$PaySaveAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Save$PaySaveData = F2(
	function (name, websiteLink) {
		return {oq: name, eN: websiteLink};
	});
var $author$project$Types$Save$toPaySave = F2(
	function (name, websiteLink) {
		return $author$project$Types$Save$PaySaveAction(
			A2($author$project$Types$Save$PaySaveData, name, websiteLink));
	});
var $author$project$Gql$Vianello$Object$PaySave$websiteLink = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'websiteLink', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$paySaveActionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$PaySave$websiteLink($author$project$Guarantees$Api$Save$websiteLinkSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PaySave$name,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$toPaySave)));
var $author$project$Guarantees$Api$Save$saveActionsSelection = $author$project$Gql$Vianello$Union$SaveAction$fragments(
	{f8: $author$project$Guarantees$Api$Save$changeEffectiveDateSaveActionSelection, f9: $author$project$Guarantees$Api$Save$changeGuaranteesSaveActionSelection, ga: $author$project$Guarantees$Api$Save$changeQuoteActionSelection, gy: $author$project$Guarantees$Api$Save$paySaveActionSelection});
var $author$project$Gql$Vianello$Object$Save$saveCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'saveCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Types$Save$SaveConfiguration = F3(
	function (paymentFrequency, issuingCompany, packages) {
		return {b9: issuingCompany, oN: packages, ct: paymentFrequency};
	});
var $author$project$Gql$Vianello$Object$SaveConfiguration$issuingCompany = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'issuingCompany', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Model$PaymentFrequency$Monthly = 1;
var $author$project$Guarantees$Model$PaymentFrequency$Yearly = 0;
var $author$project$Guarantees$Api$Save$mapPaymentFrequency = function (paymentFrequency) {
	if (paymentFrequency === 1) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Object$SaveConfiguration$packages = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'packages',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Enum$PaymentFrequency$Monthly = 0;
var $author$project$Gql$Vianello$Enum$PaymentFrequency$Yearly = 1;
var $author$project$Gql$Vianello$Enum$PaymentFrequency$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'MONTHLY':
				return $elm$json$Json$Decode$succeed(0);
			case 'YEARLY':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid PaymentFrequency type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SaveConfiguration$paymentFrequency = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PaymentFrequency.PaymentFrequency', 'paymentFrequency', _List_Nil, $author$project$Gql$Vianello$Enum$PaymentFrequency$decoder);
var $author$project$Types$Save$SaveConfigurationPackage = F2(
	function (slug, clusters) {
		return {mG: clusters, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SaveConfigurationPackage$clusters = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'clusters',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Save$SaveConfigurationCluster = F3(
	function (slug, guarantees, options) {
		return {nF: guarantees, ku: options, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SaveConfigurationCluster$guarantees = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guarantees',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$SaveConfigurationCluster$options = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'options',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Save$SaveConfigurationGuarantee = F2(
	function (slug, options) {
		return {ku: options, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SaveConfigurationGuarantee$options = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'options',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Save$SaveConfigurationOption = F2(
	function (slug, type_) {
		return {bH: slug, ao: type_};
	});
var $author$project$Gql$Vianello$Object$SaveConfigurationOption$optionConfigurationType = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'optionConfigurationType', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SaveConfigurationOption$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$saveConfigurationOptionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveConfigurationOption$optionConfigurationType,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveConfigurationOption$slug,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveConfigurationOption)));
var $author$project$Gql$Vianello$Object$SaveConfigurationGuarantee$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$saveConfigurationGuaranteeSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveConfigurationGuarantee$options($author$project$Guarantees$Api$Save$saveConfigurationOptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveConfigurationGuarantee$slug,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveConfigurationGuarantee)));
var $author$project$Gql$Vianello$Object$SaveConfigurationCluster$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$saveConfigurationClusterSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveConfigurationCluster$options($author$project$Guarantees$Api$Save$saveConfigurationOptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveConfigurationCluster$guarantees($author$project$Guarantees$Api$Save$saveConfigurationGuaranteeSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SaveConfigurationCluster$slug,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveConfigurationCluster))));
var $author$project$Gql$Vianello$Object$SaveConfigurationPackage$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$saveConfigurationPackageSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveConfigurationPackage$clusters($author$project$Guarantees$Api$Save$saveConfigurationClusterSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveConfigurationPackage$slug,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveConfigurationPackage)));
var $author$project$Guarantees$Api$Save$saveConfigurationSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveConfiguration$packages($author$project$Guarantees$Api$Save$saveConfigurationPackageSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveConfiguration$issuingCompany,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Save$mapPaymentFrequency, $author$project$Gql$Vianello$Object$SaveConfiguration$paymentFrequency),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveConfiguration))));
var $author$project$Guarantees$Api$Save$onProductFamily = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ProductFamily$name,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ProductTypeFamily));
var $author$project$Guarantees$Api$Save$onProductHomeAndFamily = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ProductHomeAndFamily$name,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$ProductTypeHomeAndFamily));
var $author$project$Guarantees$Api$Save$saveProductType = $author$project$Gql$Vianello$Union$ProductType$fragments(
	{gD: $author$project$Guarantees$Api$Save$onProductFamily, gE: $author$project$Guarantees$Api$Save$onProductHomeAndFamily});
var $author$project$Types$Save$SaveSelection = F3(
	function (price, fullPrice, packages) {
		return {jk: fullPrice, oN: packages, oY: price};
	});
var $author$project$Gql$Vianello$Object$Selection$fullPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Selection$packages = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'packages',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Selection$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Save$PriceValue = F3(
	function (amount, net, taxes) {
		return {bS: amount, kk: net, lF: taxes};
	});
var $author$project$Gql$Vianello$Object$PriceValue$amount = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Decimal',
	'amount',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).mJ.m0);
var $author$project$Guarantees$Api$Save$priceSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$PriceValue$taxes,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$PriceValue$net,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$PriceValue$amount,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$PriceValue))));
var $author$project$Types$Save$SelectedPackage = F5(
	function (slug, label, price, fullPrice, clusters) {
		return {mG: clusters, jk: fullPrice, n6: label, oY: price, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SelectedPackage$clusters = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'clusters',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$SelectedPackage$fullPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$SelectedPackage$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SelectedPackage$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Save$SelectedCluster = F5(
	function (slug, label, price, fullPrice, guarantees) {
		return {jk: fullPrice, nF: guarantees, n6: label, oY: price, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SelectedCluster$fullprice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullprice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$SelectedCluster$guarantees = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'guarantees',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$SelectedCluster$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SelectedCluster$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Save$SelectedGuarantee = F5(
	function (slug, label, price, fullPrice, options) {
		return {jk: fullPrice, n6: label, ku: options, oY: price, bH: slug};
	});
var $author$project$Gql$Vianello$Object$SelectedGuarantee$fullPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'fullPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Types$Save$GuaranteeOption = F2(
	function (slug, label) {
		return {n6: label, bH: slug};
	});
var $author$project$Gql$Vianello$Object$GuaranteeOption$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$guaranteeOptionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$GuaranteeOption$label,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$GuaranteeOption$slug,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$GuaranteeOption)));
var $author$project$Gql$Vianello$Object$SelectedGuarantee$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SelectedGuarantee$options = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'options',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$SelectedGuarantee$price = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'price', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$SelectedGuarantee$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$selectedGuaranteeSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SelectedGuarantee$options($author$project$Guarantees$Api$Save$guaranteeOptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SelectedGuarantee$fullPrice($author$project$Guarantees$Api$Save$priceSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SelectedGuarantee$price($author$project$Guarantees$Api$Save$priceSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$SelectedGuarantee$label,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$SelectedGuarantee$slug,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SelectedGuarantee))))));
var $author$project$Gql$Vianello$Object$SelectedCluster$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$selectedClusterSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SelectedCluster$guarantees($author$project$Guarantees$Api$Save$selectedGuaranteeSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SelectedCluster$fullprice($author$project$Guarantees$Api$Save$priceSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SelectedCluster$price($author$project$Guarantees$Api$Save$priceSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$SelectedCluster$label,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$SelectedCluster$slug,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SelectedCluster))))));
var $author$project$Gql$Vianello$Object$SelectedPackage$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$selectedPackageSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SelectedPackage$clusters($author$project$Guarantees$Api$Save$selectedClusterSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SelectedPackage$fullPrice($author$project$Guarantees$Api$Save$priceSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SelectedPackage$price($author$project$Guarantees$Api$Save$priceSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$SelectedPackage$label,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$SelectedPackage$slug,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SelectedPackage))))));
var $author$project$Guarantees$Api$Save$saveSelectionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Selection$packages($author$project$Guarantees$Api$Save$selectedPackageSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Selection$fullPrice($author$project$Guarantees$Api$Save$priceSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Selection$price($author$project$Guarantees$Api$Save$priceSelection),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveSelection))));
var $author$project$Gql$Vianello$Union$SaveStatus$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SavePurchased', selections____.gU),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SavePurchasable', selections____.gT),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveNonPurchasable', selections____.gP),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveDocumentsRequired', selections____.gN)
			]));
};
var $author$project$Types$Save$SaveStatusDocumentsRequired = {$: 3};
var $author$project$Guarantees$Api$Save$onSaveDocumentsRequired = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusDocumentsRequired);
var $author$project$Types$Save$SaveStatusNonPurchasable = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$Save$SaveNonPurchasable = F2(
	function (name, substatus) {
		return {oq: name, pQ: substatus};
	});
var $author$project$Gql$Vianello$Object$SaveNonPurchasable$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Union$SaveNonPurchasableSubstatus$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveNonPurchasableSubstatusExpired', selections____.gQ),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveNonPurchasableSubstatusQuotePurchased', selections____.gS),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveNonPurchasableSubstatusInvalidatedBackOffice', selections____.gR)
			]));
};
var $author$project$Types$Save$SaveStatusNonPurchasableSubstatusExpired = 0;
var $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusExpired = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(0);
var $author$project$Types$Save$SaveStatusNonPurchasableSubstatusInvalidatedBackOffice = 2;
var $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusInvalidatedBackOffice = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(2);
var $author$project$Types$Save$SaveStatusNonPurchasableSubstatusQuotePurchased = 1;
var $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusQuotePurchased = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(1);
var $author$project$Guarantees$Api$Save$saveNonPurchasableSubstatus = $author$project$Gql$Vianello$Union$SaveNonPurchasableSubstatus$fragments(
	{gQ: $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusExpired, gR: $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusInvalidatedBackOffice, gS: $author$project$Guarantees$Api$Save$onSaveNonPurchasableSubstatusQuotePurchased});
var $author$project$Gql$Vianello$Object$SaveNonPurchasable$substatus = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'substatus', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$saveNonPurchasableSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveNonPurchasable$substatus($author$project$Guarantees$Api$Save$saveNonPurchasableSubstatus),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveNonPurchasable$name,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveNonPurchasable)));
var $author$project$Guarantees$Api$Save$onSaveNonPurchasable = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Save$saveNonPurchasableSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusNonPurchasable));
var $author$project$Types$Save$SaveStatusPurchasable = {$: 1};
var $author$project$Guarantees$Api$Save$onSavePurchasable = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusPurchasable);
var $author$project$Types$Save$SaveStatusPurchased = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Save$SavePurchased = F2(
	function (name, substatus) {
		return {oq: name, pQ: substatus};
	});
var $author$project$Gql$Vianello$Object$SavePurchased$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Union$SavePurchasedSubstatus$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SavePurchasedSubstatusIssued', selections____.gV),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SavePurchasedSubstatusWaitingForIssuing', selections____.gW)
			]));
};
var $author$project$Types$Save$SaveStatusPurhcasedSubstatusIssued = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Save$SavePurchasedSubstatusIssued = F3(
	function (purchasedAt, insuranceId, insuranceCode) {
		return {nV: insuranceCode, jA: insuranceId, o9: purchasedAt};
	});
var $author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$insuranceCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'insuranceCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$insuranceId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'insuranceId',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$purchasedAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.DateTimeUtc',
	'purchasedAt',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).ix.m0);
var $author$project$Guarantees$Api$Save$savePurchasedSubstatusIssued = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$insuranceCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$insuranceId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$SavePurchasedSubstatusIssued$purchasedAt,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SavePurchasedSubstatusIssued))));
var $author$project$Guarantees$Api$Save$onSavePurchasedSubstatusIssued = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Save$savePurchasedSubstatusIssued,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusPurhcasedSubstatusIssued));
var $author$project$Types$Save$SaveStatusPurhcasedSubstatusWaitingforIssuing = {$: 1};
var $author$project$Guarantees$Api$Save$onSavePurchasedSubstatusWaitingForIssuing = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusPurhcasedSubstatusWaitingforIssuing);
var $author$project$Guarantees$Api$Save$savePurchasedSubstatus = $author$project$Gql$Vianello$Union$SavePurchasedSubstatus$fragments(
	{gV: $author$project$Guarantees$Api$Save$onSavePurchasedSubstatusIssued, gW: $author$project$Guarantees$Api$Save$onSavePurchasedSubstatusWaitingForIssuing});
var $author$project$Gql$Vianello$Object$SavePurchased$substatus = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'substatus', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$savePurchasedSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SavePurchased$substatus($author$project$Guarantees$Api$Save$savePurchasedSubstatus),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SavePurchased$name,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SavePurchased)));
var $author$project$Guarantees$Api$Save$onSavePurchased = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Save$savePurchasedSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveStatusPurchased));
var $author$project$Guarantees$Api$Save$saveStatus = $author$project$Gql$Vianello$Union$SaveStatus$fragments(
	{gN: $author$project$Guarantees$Api$Save$onSaveDocumentsRequired, gP: $author$project$Guarantees$Api$Save$onSaveNonPurchasable, gT: $author$project$Guarantees$Api$Save$onSavePurchasable, gU: $author$project$Guarantees$Api$Save$onSavePurchased});
var $author$project$Gql$Vianello$Object$Save$selection = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'selection', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Save$sourceInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'sourceInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Vianello$Object$Save$status = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'status', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Guarantees$Api$Save$saveSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Save$parentInsuranceId,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Save$actions($author$project$Guarantees$Api$Save$saveActionsSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Save$selection($author$project$Guarantees$Api$Save$saveSelectionSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Save$sourceInfo($author$project$Api$sourceInfoSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Save$status($author$project$Guarantees$Api$Save$saveStatus),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$Save$quote($author$project$Guarantees$Api$Quote$quoteSelection),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Vianello$Object$Save$configuration($author$project$Guarantees$Api$Save$saveConfigurationSelection),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Vianello$Object$Save$productType($author$project$Guarantees$Api$Save$saveProductType),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Vianello$Object$Save$saveCode,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Vianello$Object$Save$id,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$Save)))))))))));
var $author$project$Checkout$Api$Order$orderSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Order$billingAddress($author$project$Checkout$Api$Order$billingAddressSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Order$save($author$project$Guarantees$Api$Save$saveSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Order$effectiveDate,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Order$saveExpiration,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Order$amount,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Vianello$Object$Order$id,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$Order)))))));
var $author$project$Checkout$Api$Order$createOrderOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$Order$orderSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$CreateOrderOK));
var $author$project$Gql$Vianello$Union$CreateOrderResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Order', selections____.gv),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'CreateOrderErrors', selections____.gg)
			]));
};
var $author$project$Checkout$Api$Order$createOrderResultSelection = $author$project$Gql$Vianello$Union$CreateOrderResult$fragments(
	A2($author$project$Gql$Vianello$Union$CreateOrderResult$Fragments, $author$project$Checkout$Api$Order$createOrderOkSelection, $author$project$Checkout$Api$Order$createOrderErrorsSelection));
var $author$project$Checkout$Api$Order$createOrderMutation = function (saveId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$CheckoutCreateOrderResult,
		A2(
			$author$project$Gql$Vianello$Mutation$createOrder,
			{pz: saveId},
			$author$project$Checkout$Api$Order$createOrderResultSelection));
};
var $author$project$Checkout$Api$createOrderMutation = function (saveId) {
	return A2(
		$author$project$Api$buildMutation,
		$author$project$Api$CheckoutSource(
			$author$project$Api$PostOrder($author$project$Checkout$Model$GotOrder)),
		$author$project$Checkout$Api$Order$createOrderMutation(saveId));
};
var $author$project$Utils$UpdateReturn$addQuery = F2(
	function (operation, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				ec: A2($elm$core$List$cons, operation, data.ec)
			});
	});
var $author$project$Utils$UpdateReturn$withQuery = F2(
	function (query, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$addQuery, query, ur));
	});
var $author$project$Checkout$Update$Helper$createOrderMutation = F2(
	function (saveId, updateReturn) {
		return A2(
			$author$project$Utils$UpdateReturn$withQuery,
			$author$project$Checkout$Api$createOrderMutation(saveId),
			updateReturn);
	});
var $author$project$Api$GetInsurance = function (a) {
	return {$: 6, a: a};
};
var $author$project$Checkout$Model$GotInsurance = function (a) {
	return {$: 15, a: a};
};
var $author$project$Api$InsuranceQueryResult = function (a) {
	return {$: 6, a: a};
};
var $author$project$Gql$Vianello$Query$insuranceByOrderId = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'insuranceByOrderId',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'orderId',
					requiredArgs____.kw,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$GetInsuranceResponse$Fragments = F2(
	function (onInsurance, onInsuranceError) {
		return {gp: onInsurance, gq: onInsuranceError};
	});
var $author$project$Gql$Vianello$Union$GetInsuranceResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Insurance', selections____.gp),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'InsuranceError', selections____.gq)
			]));
};
var $author$project$Checkout$Model$InsuranceErrorResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$InsuranceError$error = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'error', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Insurance$insuranceResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$InsuranceError$error,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$InsuranceErrorResponse));
var $author$project$Checkout$Model$InsuranceOkResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$Insurance = F5(
	function (id, insuranceCode, email, documents, sourceInfo) {
		return {iV: documents, fi: email, nM: id, nV: insuranceCode, pI: sourceInfo};
	});
var $author$project$Checkout$Model$Document = F3(
	function (docType, label, url) {
		return {iS: docType, n6: label, lO: url};
	});
var $author$project$Gql$Vianello$Object$InsuranceDocument$label = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'label', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Model$Contract = 0;
var $author$project$Checkout$Model$InformationPackage = 1;
var $author$project$Checkout$Api$Insurance$mapInsuranceDocumentType = function (insuranceDocumentType) {
	if (!insuranceDocumentType) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Vianello$Enum$InsuranceDocumentType$Contract = 0;
var $author$project$Gql$Vianello$Enum$InsuranceDocumentType$InformationPackage = 1;
var $author$project$Gql$Vianello$Enum$InsuranceDocumentType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CONTRACT':
				return $elm$json$Json$Decode$succeed(0);
			case 'INFORMATION_PACKAGE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid InsuranceDocumentType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$InsuranceDocument$type_ = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.InsuranceDocumentType.InsuranceDocumentType', 'type', _List_Nil, $author$project$Gql$Vianello$Enum$InsuranceDocumentType$decoder);
var $author$project$Gql$Vianello$Object$InsuranceDocument$url = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'url', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Insurance$documentSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$InsuranceDocument$url,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$InsuranceDocument$label,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Checkout$Api$Insurance$mapInsuranceDocumentType, $author$project$Gql$Vianello$Object$InsuranceDocument$type_),
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$Document))));
var $author$project$Gql$Vianello$Object$Insurance$documents = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'documents',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$Insurance$email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'email', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Insurance$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Gql$Vianello$Object$Insurance$insuranceCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'insuranceCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Insurance$sourceInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'sourceInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Checkout$Api$Insurance$insuranceSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Insurance$sourceInfo($author$project$Api$sourceInfoSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Insurance$documents($author$project$Checkout$Api$Insurance$documentSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Insurance$email,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Insurance$insuranceCode,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Insurance$id,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$Insurance))))));
var $author$project$Checkout$Api$Insurance$insuranceResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$Insurance$insuranceSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$InsuranceOkResponse));
var $author$project$Checkout$Api$Insurance$insuranceResponseSelection = $author$project$Gql$Vianello$Union$GetInsuranceResponse$fragments(
	A2($author$project$Gql$Vianello$Union$GetInsuranceResponse$Fragments, $author$project$Checkout$Api$Insurance$insuranceResponseOkSelection, $author$project$Checkout$Api$Insurance$insuranceResponseErrorSelection));
var $author$project$Checkout$Api$Insurance$insuranceByOrderIdQuery = function (orderId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$InsuranceQueryResult,
		A2(
			$author$project$Gql$Vianello$Query$insuranceByOrderId,
			{kw: orderId},
			$author$project$Checkout$Api$Insurance$insuranceResponseSelection));
};
var $author$project$Checkout$Api$insuranceByOrderIdQuery = F2(
	function (order, updateReturn) {
		return A2(
			$elm$core$Maybe$withDefault,
			updateReturn,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Utils$UpdateReturn$withQuery, updateReturn),
				A2(
					$elm$core$Maybe$map,
					$author$project$Api$buildQuery(
						$author$project$Api$CheckoutSource(
							$author$project$Api$GetInsurance($author$project$Checkout$Model$GotInsurance))),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kw;
							},
							$author$project$Checkout$Api$Insurance$insuranceByOrderIdQuery),
						order))));
	});
var $author$project$Routing$quotationToThankYouPageType = function (quotationType) {
	switch (quotationType) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 3;
	}
};
var $author$project$Utils$UpdateReturn$changeUrl = F2(
	function (route, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				hg: $elm$core$Maybe$Just(route)
			});
	});
var $author$project$Utils$UpdateReturn$withPushUrl = F2(
	function (route, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$changeUrl, route, ur));
	});
var $author$project$Utils$withoutCommands = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Checkout$Api$insuranceRequestHandler = function (model) {
	var order = model.oK;
	var insurancePollingConfig = model.nW;
	var quotationType = model.pc;
	var conditionalThankYouPageUrl = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Routing$NotFound,
		A2(
			$elm$core$Maybe$map,
			function (orderValue) {
				return A3(
					$author$project$Routing$ThankYou,
					$author$project$Routing$quotationToThankYouPageType(quotationType),
					orderValue.kw,
					$elm$core$Maybe$Nothing);
			},
			order));
	return insurancePollingConfig.na ? A2(
		$author$project$Checkout$Api$insuranceByOrderIdQuery,
		order,
		$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(model))) : A2(
		$author$project$Utils$UpdateReturn$withPushUrl,
		conditionalThankYouPageUrl,
		$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(model)));
};
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {aw: clock, iK: day, ke: month, eD: time, l3: year};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rules = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$WallClock = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {e7: current, Y: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {hI: standardOffset, h2: zoneRules};
	});
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.hJ <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.bx, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $elm$core$List$sortBy = _List_sortBy;
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.aI;
		var save = _v0.py;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.l3;
	var month = _v0.ke;
	var day = _v0.iK;
	var time = _v0.eD;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.aw, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.hJ;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								aw: rule.aw,
								py: rule.py,
								hJ: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.iK;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ke, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ke, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ke, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.ke));
										}
									}()) + rule.eD
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.ji, year) < 1) && (_Utils_cmp(year, rule.lH) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.l3 - 1, until.l3));
		var initialOffset = {py: 0, aI: standardOffset};
		var initialChange = {
			bx: standardOffset,
			hJ: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {py: transition.py, aI: standardOffset};
					if (_Utils_cmp(
						transition.hJ + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aw, previousOffset),
						initialChange.hJ) < 1) {
						var updatedInitialChange = {bx: standardOffset + transition.py, hJ: initialChange.hJ};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.hJ + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aw, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								bx: standardOffset + transition.py,
								hJ: transition.hJ + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.aw, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.hI;
		var zoneRules = _v0.h2;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						bx: standardOffset + save,
						hJ: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{py: save, aI: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.Y);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.e7, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.Y;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.e7;
			}
		}();
		var initialOffset = {
			py: function () {
				var _v4 = initialState.h2;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			aI: initialState.hI
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.bx;
			},
			initialOffset.aI + initialOffset.py,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.aI + initialOffset.py, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $justinmimbs$timezone_data$TimeZone$Specification$Day = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Last = function (a) {
	return {$: 3, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Next = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rule = F7(
	function (from, to, month, day, time, clock, save) {
		return {aw: clock, iK: day, ji: from, ke: month, py: save, eD: time, lH: to};
	});
var $justinmimbs$timezone_data$TimeZone$rules_EU = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$Specification$Standard = 1;
var $justinmimbs$timezone_data$TimeZone$rules_Italy = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1969,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1972,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1979,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__rome = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Italy)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $author$project$Utils$Time$convertPosixToDate = function (posix) {
	return A2(
		$justinmimbs$date$Date$fromPosix,
		$justinmimbs$timezone_data$TimeZone$europe__rome(0),
		posix);
};
var $justinmimbs$date$Date$isBetween = F3(
	function (_v0, _v1, _v2) {
		var a = _v0;
		var b = _v1;
		var x = _v2;
		return A3($justinmimbs$date$Date$isBetweenInt, a, b, x);
	});
var $author$project$Checkout$Update$isValidRange = F3(
	function (effectiveDate, saveExpireDate, today) {
		return A3($justinmimbs$date$Date$isBetween, today, saveExpireDate, effectiveDate);
	});
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$Checkout$Update$isEffectiveDateValid = function (_v0) {
	var order = _v0.oK;
	var currentDate = _v0.iH;
	var saveExpiration = A2(
		$elm$core$Maybe$map,
		$author$project$Utils$Time$convertPosixToDate,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.lh;
			},
			order));
	var effectiveDate = A2(
		$elm$core$Maybe$map,
		$author$project$Utils$Time$convertPosixToDate,
		A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.m8;
			},
			order));
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A4($elm$core$Maybe$map3, $author$project$Checkout$Update$isValidRange, effectiveDate, saveExpiration, currentDate));
};
var $author$project$Types$Save$isRenewalSave = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.kB;
	},
	$elm_community$maybe_extra$Maybe$Extra$isJust);
var $author$project$Utils$ternaryOperator = F3(
	function (condition, trueCase, falseCase) {
		return condition ? trueCase : falseCase;
	});
var $author$project$Utils$pipedTernaryOperator = F3(
	function (trueCase, falseCase, condition) {
		return A3($author$project$Utils$ternaryOperator, condition, trueCase, falseCase);
	});
var $author$project$Checkout$Update$isEffectiveDateValidOnRenewal = function (_v0) {
	var order = _v0.oK;
	var isNotEmptyEffectiveDate = $elm_community$maybe_extra$Maybe$Extra$isJust(
		A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.m8;
			},
			order));
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			A2($author$project$Utils$pipedTernaryOperator, isNotEmptyEffectiveDate, false),
			A2(
				$elm$core$Maybe$map,
				$author$project$Types$Save$isRenewalSave,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.py;
					},
					order))));
};
var $author$project$Checkout$Model$GotClientConfig = function (a) {
	return {$: 2, a: a};
};
var $author$project$Checkout$Model$ClientConfig = F2(
	function (braintreeClientToken, placeholder) {
		return {mq: braintreeClientToken, oU: placeholder};
	});
var $author$project$Gql$Lira$Object$ClientConfiguration$braintreeClientToken = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'braintreeClientToken', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$ClientConfig$clientConfigSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Lira$Object$ClientConfiguration$braintreeClientToken,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$ClientConfig)));
var $author$project$Gql$Lira$Query$clientConfiguration = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'clientConfiguration', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Checkout$Api$ClientConfig$clientConfigQuery = $author$project$Gql$Lira$Query$clientConfiguration($author$project$Checkout$Api$ClientConfig$clientConfigSelection);
var $author$project$Api$makeRequest = F3(
	function (apiUrl, msg, query) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
				A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, apiUrl, query)));
	});
var $author$project$Checkout$Api$ClientConfig$clientConfigRequest = function (liraUrl) {
	return A3($author$project$Api$makeRequest, liraUrl, $author$project$Checkout$Model$GotClientConfig, $author$project$Checkout$Api$ClientConfig$clientConfigQuery);
};
var $author$project$Checkout$Api$clientConfigRequest = $author$project$Checkout$Api$ClientConfig$clientConfigRequest;
var $author$project$Checkout$Update$Helper$maybeClientConfigRequest = function (_v0) {
	var model = _v0.a;
	var clientConfig = model.iv;
	var flags = model.nv;
	var cmd = _v0.b;
	if (!clientConfig.$) {
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					$author$project$Checkout$Api$clientConfigRequest(
					$author$project$Types$pickLiraUrl(flags))
				]),
			model);
	} else {
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[cmd]),
			model);
	}
};
var $author$project$Checkout$Ports$braintree3DSSetup = _Platform_outgoingPort('braintree3DSSetup', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Checkout$Ports$verify3DSCard = _Platform_outgoingPort(
	'verify3DSCard',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'amount',
					$elm$json$Json$Encode$float($.bS)),
					_Utils_Tuple2(
					'config',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'billingAddress',
									function ($) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'countryCode',
													$elm$json$Json$Encode$string($.e6)),
													_Utils_Tuple2(
													'givenName',
													$elm$json$Json$Encode$string($.jq)),
													_Utils_Tuple2(
													'locality',
													$elm$json$Json$Encode$string($.j5)),
													_Utils_Tuple2(
													'phoneNumber',
													$elm$json$Json$Encode$string($.cu)),
													_Utils_Tuple2(
													'postalCode',
													$elm$json$Json$Encode$string($.kJ)),
													_Utils_Tuple2(
													'region',
													$elm$json$Json$Encode$string($.hk)),
													_Utils_Tuple2(
													'streetAddress',
													$elm$json$Json$Encode$string($.hN)),
													_Utils_Tuple2(
													'surname',
													$elm$json$Json$Encode$string($.lC))
												]));
									}($.e0)),
									_Utils_Tuple2(
									'bin',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.e1)),
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string($.fi)),
									_Utils_Tuple2(
									'nonce',
									function ($) {
										return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
									}($.oz)),
									_Utils_Tuple2(
									'phoneNumber',
									$elm$json$Json$Encode$string($.cu)),
									_Utils_Tuple2(
									'token',
									$elm$json$Json$Encode$string($.p1))
								]));
					}($.iC))
				]));
	});
var $author$project$Checkout$InitBraintree$init = F2(
	function (token, _v0) {
		var paymentMethod = _v0.oS;
		var _v1 = $author$project$Checkout$Ports$verify3DSCard;
		return (!_Utils_eq(
			paymentMethod,
			$elm$core$Maybe$Just(2))) ? $author$project$Checkout$Ports$braintree3DSSetup(token) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Checkout$Update$Helper$updateBrainTreeModel = F2(
	function (updateFunction, model) {
		var brainTreeModel = model.mp;
		return _Utils_update(
			model,
			{
				mp: updateFunction(brainTreeModel)
			});
	});
var $author$project$Checkout$Update$BraintreeHelper$conditionallyInitBraintreeByResponse = F2(
	function (clientConfigResponse, defaultReturn) {
		var model = defaultReturn.a;
		var cmds = defaultReturn.b;
		if (clientConfigResponse.$ === 3) {
			var clientConfig = clientConfigResponse.a;
			return function (m) {
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							cmds,
							A2($author$project$Checkout$InitBraintree$init, clientConfig.mq, m)
						]),
					m);
			}(
				A2(
					$author$project$Checkout$Update$Helper$updateBrainTreeModel,
					function (btm) {
						return _Utils_update(
							btm,
							{
								p1: $elm$core$Maybe$Just(clientConfig.mq)
							});
					},
					model));
		} else {
			return defaultReturn;
		}
	});
var $author$project$Checkout$Model$PaymentStep = 1;
var $author$project$Checkout$Model$NotDefinedStep = 2;
var $author$project$Checkout$Model$NotDefinedSave = 2;
var $author$project$Checkout$Model$RenewalSave = 1;
var $author$project$Checkout$Model$StandardSave = 0;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Types$Save$isChangeEffectiveDateAction = function (action) {
	switch (action.$) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		default:
			return true;
	}
};
var $author$project$Types$Save$canChangeEffectiveDate = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.h4;
	},
	$elm$core$List$any($author$project$Types$Save$isChangeEffectiveDateAction));
var $author$project$Checkout$Model$isRenewalOrder = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.py;
	},
	A2($elm$core$Basics$composeR, $author$project$Types$Save$canChangeEffectiveDate, $elm$core$Basics$not));
var $author$project$Checkout$Model$getSaveType = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.oK;
	},
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Checkout$Model$isRenewalOrder,
				A2($author$project$Utils$pipedTernaryOperator, 1, 0))),
		$elm$core$Maybe$withDefault(2)));
var $author$project$Checkout$Model$renewalSaveStepMapper = function (_v0) {
	var currentStep = _v0.de;
	if (currentStep === 1) {
		return 1;
	} else {
		return 2;
	}
};
var $author$project$Checkout$Model$EffectiveDateSelectionStep = 0;
var $author$project$Checkout$Model$standardSaveStepMapper = function (_v0) {
	var currentStep = _v0.de;
	switch (currentStep) {
		case 1:
			return 0;
		case 2:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Checkout$Model$getCheckoutStepType = function (model) {
	var _v0 = $author$project$Checkout$Model$getSaveType(model);
	switch (_v0) {
		case 0:
			return $author$project$Checkout$Model$standardSaveStepMapper(model);
		case 1:
			return $author$project$Checkout$Model$renewalSaveStepMapper(model);
		default:
			return 2;
	}
};
var $author$project$Checkout$Model$isPaymentStep = A2(
	$elm$core$Basics$composeR,
	$author$project$Checkout$Model$getCheckoutStepType,
	$elm$core$Basics$eq(1));
var $author$project$Checkout$Update$BraintreeHelper$maybeInitBraintree = F2(
	function (clientConfigResponse, defaultReturn) {
		var model = defaultReturn.a;
		return A3(
			$author$project$Utils$ternaryOperator,
			$author$project$Checkout$Model$isPaymentStep(model),
			A2($author$project$Checkout$Update$BraintreeHelper$conditionallyInitBraintreeByResponse, clientConfigResponse, defaultReturn),
			defaultReturn);
	});
var $author$project$Checkout$Update$BraintreeHelper$maybeInitBraintreeFromOrderResponse = F2(
	function (clientConfigResponse, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		var _v1 = A2(
			$author$project$Checkout$Update$BraintreeHelper$maybeInitBraintree,
			clientConfigResponse,
			_Utils_Tuple2(model, cmd));
		var updatedModel = _v1.a;
		var updatedCmd = _v1.b;
		return _Utils_Tuple3(updatedModel, updatedCmd, ur);
	});
var $author$project$Types$disablePolling = function (pollingConfig) {
	return _Utils_update(
		pollingConfig,
		{na: false});
};
var $author$project$Checkout$Update$Helper$updateDisablePolling = function (model) {
	var insurancePollingConfig = model.nW;
	return _Utils_update(
		model,
		{
			nW: $author$project$Types$disablePolling(insurancePollingConfig)
		});
};
var $author$project$Checkout$Update$Helper$maybeToThankYouPage = function (_v0) {
	var model = _v0.a;
	var insuranceResponse = model.jB;
	var order = model.oK;
	var quotationType = model.pc;
	var cmd = _v0.b;
	var _v1 = _Utils_Tuple2(order, insuranceResponse);
	if (((!_v1.a.$) && (_v1.b.$ === 3)) && (!_v1.b.a.$)) {
		var orderId = _v1.a.a.kw;
		var id = _v1.b.a.a.nM;
		return A2(
			$author$project$Utils$UpdateReturn$withPushUrl,
			A3(
				$author$project$Routing$ThankYou,
				$author$project$Routing$quotationToThankYouPageType(quotationType),
				orderId,
				$elm$core$Maybe$Just(id)),
			$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[cmd]),
					$author$project$Checkout$Update$Helper$updateDisablePolling(model))));
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			_Utils_Tuple2(model, cmd));
	}
};
var $author$project$Checkout$Ports$payWithCreditCard = _Platform_outgoingPort('payWithCreditCard', $elm$json$Json$Encode$string);
var $author$project$Checkout$InitBraintree$payWithCard = A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Platform$Cmd$none, $author$project$Checkout$Ports$payWithCreditCard);
var $author$project$Checkout$Update$BraintreeHelper$payWithCard = function (model) {
	var clientConfig = model.iv;
	if (clientConfig.$ === 3) {
		var config = clientConfig.a;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Checkout$InitBraintree$payWithCard(
					$elm$core$Maybe$Just(config.mq))
				]),
			model);
	} else {
		return $author$project$Utils$withoutCommands(model);
	}
};
var $author$project$Checkout$Ports$payWithPayPal = _Platform_outgoingPort('payWithPayPal', $elm$json$Json$Encode$string);
var $author$project$Checkout$InitBraintree$payWithPaypal = A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Platform$Cmd$none, $author$project$Checkout$Ports$payWithPayPal);
var $author$project$Checkout$Update$BraintreeHelper$payWithPaypal = function (model) {
	var clientConfig = model.iv;
	if (clientConfig.$ === 3) {
		var config = clientConfig.a;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Checkout$InitBraintree$payWithPaypal(
					$elm$core$Maybe$Just(config.mq))
				]),
			model);
	} else {
		return $author$project$Utils$withoutCommands(model);
	}
};
var $author$project$Checkout$Model$BraintreeModel$Braintree = 1;
var $author$project$Checkout$Model$BraintreeModel$Unknown = 0;
var $author$project$Checkout$Update$Helper$paymentErrorToBackEndEvent = function (paymentError) {
	var eventCode = function () {
		var _v0 = paymentError.mH;
		if (!_v0) {
			return 0;
		} else {
			return 1;
		}
	}();
	return {mH: eventCode, of: paymentError.of};
};
var $author$project$Checkout$Model$pickQuotationType = function (_v0) {
	var quotationType = _v0.pc;
	return quotationType;
};
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$toMonths = function (rd) {
	var date = $justinmimbs$date$Date$toCalendarDate(rd);
	var wholeMonths = (12 * (date.l3 - 1)) + ($justinmimbs$date$Date$monthToNumber(date.ke) - 1);
	return wholeMonths + (date.iK / 100);
};
var $elm$core$Basics$truncate = _Basics_truncate;
var $justinmimbs$date$Date$diff = F3(
	function (unit, _v0, _v1) {
		var rd1 = _v0;
		var rd2 = _v1;
		switch (unit) {
			case 0:
				return ((($justinmimbs$date$Date$toMonths(rd2) - $justinmimbs$date$Date$toMonths(rd1)) | 0) / 12) | 0;
			case 1:
				return ($justinmimbs$date$Date$toMonths(rd2) - $justinmimbs$date$Date$toMonths(rd1)) | 0;
			case 2:
				return ((rd2 - rd1) / 7) | 0;
			default:
				return rd2 - rd1;
		}
	});
var $author$project$Utils$calculateAge = $justinmimbs$date$Date$diff(0);
var $author$project$Types$Quote$Price = 0;
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $prikhi$decimal$Decimal$addDecimals = F2(
	function (i, _v0) {
		addDecimals:
		while (true) {
			var m = _v0.a;
			var e = _v0.b;
			var mul10 = function (x) {
				return A2(
					$cmditch$elm_bigint$BigInt$mul,
					x,
					$cmditch$elm_bigint$BigInt$fromInt(10));
			};
			if (!i) {
				return A2($prikhi$decimal$Decimal$Decimal, m, e);
			} else {
				if (i > 0) {
					var $temp$i = i - 1,
						$temp$_v0 = A2(
						$prikhi$decimal$Decimal$Decimal,
						mul10(m),
						e - 1);
					i = $temp$i;
					_v0 = $temp$_v0;
					continue addDecimals;
				} else {
					return A2($prikhi$decimal$Decimal$Decimal, m, e);
				}
			}
		}
	});
var $prikhi$decimal$Decimal$toExponent = F2(
	function (e, _v0) {
		var md = _v0.a;
		var ed = _v0.b;
		return A2(
			$prikhi$decimal$Decimal$addDecimals,
			ed - e,
			A2($prikhi$decimal$Decimal$Decimal, md, ed));
	});
var $prikhi$decimal$Decimal$toCommonExponent = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var _v1 = b;
	var mb = _v1.a;
	var eb = _v1.b;
	var _v2 = a;
	var ma = _v2.a;
	var ea = _v2.b;
	var exponent = A2($elm$core$Basics$min, ea, eb);
	return _Utils_Tuple2(
		A2($prikhi$decimal$Decimal$toExponent, exponent, a),
		A2($prikhi$decimal$Decimal$toExponent, exponent, b));
};
var $prikhi$decimal$Decimal$add = F2(
	function (a, b) {
		var _v0 = $prikhi$decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var ra = _v0.a;
		var rb = _v0.b;
		var _v1 = rb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = ra;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$add, ma, mb),
			ea);
	});
var $author$project$Guarantees$Helpers$pickPriceBlocksByPaymentFrequency = F2(
	function (paymentFrequency, guarantee) {
		var _v0 = _Utils_Tuple2(paymentFrequency, guarantee);
		_v0$2:
		while (true) {
			if (!_v0.a) {
				if (!_v0.b.$) {
					var _v1 = _v0.a;
					var priceBlocks = _v0.b.a.kQ;
					return priceBlocks;
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 1) {
					var _v2 = _v0.a;
					var priceBlocks = _v0.b.a.kQ;
					return priceBlocks;
				} else {
					break _v0$2;
				}
			}
		}
		return _List_Nil;
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Guarantees$Helpers$matchOption = F2(
	function (option, _v0) {
		var slug = _v0.bH;
		var type_ = _v0.ao;
		return _Utils_eq(option.bH, slug) && _Utils_eq(option.oJ, type_);
	});
var $author$project$Guarantees$Helpers$matchesOption = F3(
	function (selectedClusterOptions, selectedGuaranteeOptions, option) {
		var _v0 = option.pT;
		if (!_v0) {
			return A2(
				$elm$core$List$any,
				$author$project$Guarantees$Helpers$matchOption(option),
				selectedClusterOptions);
		} else {
			return A2(
				$elm$core$List$any,
				$author$project$Guarantees$Helpers$matchOption(option),
				selectedGuaranteeOptions);
		}
	});
var $author$project$Guarantees$Helpers$matchesOptions = F3(
	function (relatedOptions, selectedClusterOptions, selectedGuaranteeOptions) {
		return A2(
			$elm$core$List$all,
			A2($author$project$Guarantees$Helpers$matchesOption, selectedClusterOptions, selectedGuaranteeOptions),
			relatedOptions);
	});
var $author$project$Guarantees$Model$UserSelection$Guarantee$Included = 0;
var $author$project$Guarantees$Model$UserSelection$Guarantee$getOptionList = function (group) {
	if (!group.$) {
		var options = group.a.ku;
		return options;
	} else {
		var option = group.a.g1;
		return _List_fromArray(
			[option]);
	}
};
var $author$project$Guarantees$Model$UserSelection$pickGuaranteeSelectedOptions = F4(
	function (packageSlug, clusterSlug, guaranteeSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$concatMap,
			$author$project$Guarantees$Model$UserSelection$Guarantee$getOptionList,
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.g2;
				},
				A2(
					$elm$core$List$filter,
					function (_v1) {
						var slug = _v1.bH;
						var selected = _v1.pC;
						var guaranteeType = _v1.js;
						return _Utils_eq(slug, guaranteeSlug) && (selected || (!guaranteeType));
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.nF;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								$elm$core$Basics$eq(clusterSlug)),
							A2(
								$elm$core$List$concatMap,
								function ($) {
									return $.mG;
								},
								A2(
									$elm$core$List$filter,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.bH;
										},
										$elm$core$Basics$eq(packageSlug)),
									packages)))))));
	});
var $author$project$Types$Quote$pickGuarantee = function (guaranteeFrequency) {
	if (!guaranteeFrequency.$) {
		var guarantee = guaranteeFrequency.a;
		return guarantee;
	} else {
		var guarantee = guaranteeFrequency.a;
		return guarantee;
	}
};
var $author$project$Guarantees$Helpers$pickGuaranteeSlug = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.bH;
	});
var $author$project$Guarantees$Model$UserSelection$pickSelectedClusterOptionsInPackage = F3(
	function (packageSlug, clusterSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$concatMap,
			$author$project$Guarantees$Model$UserSelection$Guarantee$getOptionList,
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.g2;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(clusterSlug)),
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.mG;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								$elm$core$Basics$eq(packageSlug)),
							packages)))));
	});
var $author$project$Guarantees$Helpers$filterOptions = F5(
	function (_package, cluster, guarantee, userSelection, _v0) {
		var relatedOptions = _v0.pl;
		return A3(
			$author$project$Guarantees$Helpers$matchesOptions,
			relatedOptions,
			A3($author$project$Guarantees$Model$UserSelection$pickSelectedClusterOptionsInPackage, _package.bH, cluster.bH, userSelection),
			A4(
				$author$project$Guarantees$Model$UserSelection$pickGuaranteeSelectedOptions,
				_package.bH,
				cluster.bH,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
				userSelection));
	});
var $author$project$Guarantees$Helpers$mapPriceBlock = F2(
	function (priceType, yearly) {
		if (!priceType) {
			return A2($prikhi$decimal$Decimal$add, yearly.jj.kk, yearly.jj.lF);
		} else {
			return A2($prikhi$decimal$Decimal$add, yearly.jk.kk, yearly.jk.lF);
		}
	});
var $prikhi$decimal$Decimal$fromIntWithExponent = F2(
	function (n, e) {
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			$cmditch$elm_bigint$BigInt$fromInt(n),
			e);
	});
var $prikhi$decimal$Decimal$fromInt = function (n) {
	return A2($prikhi$decimal$Decimal$fromIntWithExponent, n, 0);
};
var $prikhi$decimal$Decimal$zero = $prikhi$decimal$Decimal$fromInt(0);
var $author$project$Guarantees$Helpers$sumPriceBlocks = F6(
	function (blocks, userSelection, _package, cluster, guarantee, priceType) {
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				$author$project$Guarantees$Helpers$mapPriceBlock(priceType),
				A2(
					$elm$core$List$filter,
					A4($author$project$Guarantees$Helpers$filterOptions, _package, cluster, guarantee, userSelection),
					blocks)));
	});
var $author$project$Guarantees$Helpers$calculateGuaranteePrice = F6(
	function (paymentFrequency, userSelection, _package, cluster, priceType, guarantee) {
		return A6(
			$author$project$Guarantees$Helpers$sumPriceBlocks,
			A2($author$project$Guarantees$Helpers$pickPriceBlocksByPaymentFrequency, paymentFrequency, guarantee),
			userSelection,
			_package,
			cluster,
			guarantee,
			priceType);
	});
var $author$project$Guarantees$Model$UserSelection$isGuaranteeInPackageSelected = F4(
	function (clusterSlug, guaranteeSlug, packageSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$any,
			function (_v1) {
				var slug = _v1.bH;
				var selected = _v1.pC;
				var guaranteeType = _v1.js;
				return _Utils_eq(slug, guaranteeSlug) && (selected || (!guaranteeType));
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.nF;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(clusterSlug)),
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.mG;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								$elm$core$Basics$eq(packageSlug)),
							packages)))));
	});
var $author$project$Guarantees$Helpers$filterGuaranteeInPackage = F4(
	function (_package, userSelection, cluster, guarantee) {
		return A4(
			$author$project$Guarantees$Model$UserSelection$isGuaranteeInPackageSelected,
			cluster.bH,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
			_package.bH,
			userSelection);
	});
var $author$project$Guarantees$Model$UserSelection$pickPaymentFrequency = function (_v0) {
	var paymentFrequency = _v0.ct;
	return paymentFrequency;
};
var $author$project$Guarantees$Helpers$calculateSelectedClusterPrice = F4(
	function (_package, userSelection, priceType, cluster) {
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				A5(
					$author$project$Guarantees$Helpers$calculateGuaranteePrice,
					$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection),
					userSelection,
					_package,
					cluster,
					priceType),
				A2(
					$elm$core$List$filter,
					A3($author$project$Guarantees$Helpers$filterGuaranteeInPackage, _package, userSelection, cluster),
					cluster.nF)));
	});
var $author$project$Guarantees$Helpers$calculateSelectedPackagePrice = F3(
	function (_package, userSelection, priceType) {
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				A3($author$project$Guarantees$Helpers$calculateSelectedClusterPrice, _package, userSelection, priceType),
				_package.mG));
	});
var $author$project$Guarantees$Helpers$calculatePackagePrice = F2(
	function (_package, userSelection) {
		return A3($author$project$Guarantees$Helpers$calculateSelectedPackagePrice, _package, userSelection, 0);
	});
var $author$project$Types$Utils$constructionPeriodToLabel = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Non ricordi';
		case 1:
			return 'Prima del 1970';
		case 2:
			return 'Prima del 1940';
		case 3:
			return '1940-1949';
		case 4:
			return '1950-1959';
		case 5:
			return '1960-1969';
		case 6:
			return '1970-1979';
		case 7:
			return '1980-1989';
		case 8:
			return '1990-1999';
		case 9:
			return '2000-2009';
		case 10:
			return '2010-2019';
		default:
			return 'Dal 2020 in poi';
	}
};
var $elm_community$basics_extra$Basics$Extra$flip = F3(
	function (f, b, a) {
		return A2(f, a, b);
	});
var $author$project$Types$Utils$floorTypeToValue = function (_enum) {
	switch (_enum) {
		case 0:
			return $elm$core$Maybe$Just('GROUND');
		case 1:
			return $elm$core$Maybe$Just('INTERMEDIATE');
		default:
			return $elm$core$Maybe$Just('TOP');
	}
};
var $author$project$Guarantees$Model$genderGTMParser = function (gender) {
	if (!gender) {
		return 'a';
	} else {
		return 'b';
	}
};
var $elm_community$maybe_extra$Maybe$Extra$toList = function (m) {
	if (m.$ === 1) {
		return _List_Nil;
	} else {
		var x = m.a;
		return _List_fromArray(
			[x]);
	}
};
var $author$project$Utils$GTM$generateGTMTupleInList = function (key) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map($elm$core$String$toLower),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				$elm$core$Tuple$pair(key)),
			$elm_community$maybe_extra$Maybe$Extra$toList));
};
var $author$project$Utils$GTM$generateGTMTupleWithDefault = F2(
	function (key, _default) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(_default),
			$elm$core$Tuple$pair(key));
	});
var $author$project$Utils$GTM$generateGTMTupleInListWithDefault = F2(
	function (key, _default) {
		return A2(
			$elm$core$Basics$composeR,
			A2($author$project$Utils$GTM$generateGTMTupleWithDefault, key, _default),
			$elm$core$List$singleton);
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Ports$pushFormDataToDataLayer = _Platform_outgoingPort(
	'pushFormDataToDataLayer',
	$elm$json$Json$Encode$list(
		function ($) {
			var a = $.a;
			var b = $.b;
			return A2(
				$elm$json$Json$Encode$list,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						$elm$json$Json$Encode$string(a),
						$elm$json$Json$Encode$string(b)
					]));
		}));
var $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$cons(
		_Utils_Tuple2('event', 'dataLayerUpdate')),
	$author$project$Ports$pushFormDataToDataLayer);
var $elm_community$basics_extra$Basics$Extra$uncurry = F2(
	function (f, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		return A2(f, a, b);
	});
var $author$project$Utils$GTM$generateTupleAndPushWithDefaultEvent = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		$elm_community$basics_extra$Basics$Extra$uncurry($author$project$Utils$GTM$generateGTMTupleInList)),
	A2($elm$core$Basics$composeR, $elm$core$List$concat, $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent));
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Checkout$Update$Helper$getCasaInsuranceSelected = A2(
	$elm$core$Basics$composeR,
	$elm_community$list_extra$List$Extra$find(
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.bH;
			},
			A2(
				$elm_community$basics_extra$Basics$Extra$flip,
				$elm$core$List$member,
				_List_fromArray(
					['casa_protetta', 'casa_sicura', 'casa_super'])))),
	$elm$core$Maybe$map(
		function ($) {
			return $.bH;
		}));
var $author$project$Checkout$Model$getSaveConfigurationPackages = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.py;
	},
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.mO;
		},
		function ($) {
			return $.oN;
		}));
var $author$project$Checkout$Model$getSaveConfigurationGuarantees = A2(
	$elm$core$Basics$composeR,
	$author$project$Checkout$Model$getSaveConfigurationPackages,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$concatMap(
			function ($) {
				return $.mG;
			}),
		$elm$core$List$concatMap(
			function ($) {
				return $.nF;
			})));
var $author$project$Checkout$Update$Helper$getFamilyProtectionSelected = function () {
	var convertFamilyProtectionSlug = function (slug) {
		switch (slug) {
			case 'rc_vita_privata':
				return $elm$core$Maybe$Just('rc');
			case 'rc_animali_domestici':
				return $elm$core$Maybe$Just('animali');
			case 'rc_bici_elettriche':
				return $elm$core$Maybe$Just('bici');
			default:
				return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Checkout$Model$getSaveConfigurationGuarantees,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$sortBy(
				function ($) {
					return $.bH;
				}),
			A2(
				$elm$core$Basics$composeR,
				A2(
					$elm$core$List$foldl,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						A2(
							$elm$core$Basics$composeR,
							convertFamilyProtectionSlug,
							A2($elm$core$Basics$composeR, $elm_community$maybe_extra$Maybe$Extra$toList, $elm$core$Basics$append))),
					_List_Nil),
				$elm$core$String$join('+'))));
}();
var $author$project$Checkout$Update$Helper$getLevelCasaInsuranceSelected = function (packages) {
	var casaInsuranceSelected = A2(
		$elm$core$Maybe$withDefault,
		'',
		$author$project$Checkout$Update$Helper$getCasaInsuranceSelected(packages));
	switch (casaInsuranceSelected) {
		case 'casa_protetta':
			return 'Protetta';
		case 'casa_sicura':
			return 'Sicuro';
		case 'casa_super':
			return 'Super';
		default:
			return 'Niente';
	}
};
var $author$project$Checkout$Update$Helper$getPackage = function (slug) {
	return $elm_community$list_extra$List$Extra$find(
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.bH;
			},
			$elm$core$Basics$eq(slug)));
};
var $author$project$Checkout$Update$Helper$getRiskTypeDataLayerList = function (saveType) {
	switch (saveType) {
		case 0:
			return A2(
				$author$project$Utils$GTM$generateGTMTupleInList,
				'rt',
				$elm$core$Maybe$Just('nb'));
		case 1:
			return A2(
				$author$project$Utils$GTM$generateGTMTupleInList,
				'rt',
				$elm$core$Maybe$Just('rn'));
		default:
			return A2($author$project$Utils$GTM$generateGTMTupleInList, 'rt', $elm$core$Maybe$Nothing);
	}
};
var $author$project$Guarantees$Model$UserSelection$isPackageSelected = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$any,
			function (_v1) {
				var slug = _v1.bH;
				var selected = _v1.pC;
				return _Utils_eq(slug, packageSlug) && selected;
			},
			packages);
	});
var $author$project$Guarantees$Helpers$calculatePackagesPriceWithType = F3(
	function (packages, userSelection, priceType) {
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (flipped) {
					return flipped(priceType);
				},
				A2(
					$elm$core$List$map,
					A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Helpers$calculateSelectedPackagePrice, userSelection),
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Model$UserSelection$isPackageSelected, userSelection)),
						packages))));
	});
var $author$project$Guarantees$Helpers$calculatePackagesPrice = F2(
	function (packages, userSelection) {
		return A3($author$project$Guarantees$Helpers$calculatePackagesPriceWithType, packages, userSelection, 0);
	});
var $author$project$Guarantees$Helpers$pickPackagesFromSave = function (save) {
	var selectedPackages = save.mO.oN;
	return A2(
		$elm$core$List$filter,
		function (_package) {
			return A2(
				$elm$core$List$any,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					$elm$core$Basics$eq(_package.bH)),
				selectedPackages);
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.oN;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oM;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.pB;
					},
					A2(
						$elm$core$List$filter,
						function (price) {
							return _Utils_eq(price.b9.mM, save.mO.b9);
						},
						save.pd.kR)))));
};
var $author$project$Checkout$Update$Helper$getTotalPrice = F2(
	function (_v0, userSelection) {
		var save = _v0.py;
		var packages = $author$project$Guarantees$Helpers$pickPackagesFromSave(save);
		return $prikhi$decimal$Decimal$toString(
			A2($author$project$Guarantees$Helpers$calculatePackagesPrice, packages, userSelection));
	});
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Checkout$Update$Helper$amountToString = function (_v0) {
	var price = _v0.oY;
	return $prikhi$decimal$Decimal$toString(price.bS);
};
var $author$project$Checkout$Update$Helper$familyDictionary = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('rc_vita_privata', 'rc'),
			_Utils_Tuple2('rc_animali_domestici', 'animali'),
			_Utils_Tuple2('rc_bici_elettriche', 'bici')
		]));
var $author$project$Checkout$Update$Helper$houseDictionary = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('protezione_locali', 'L'),
			_Utils_Tuple2('protezione_contenuto', 'C'),
			_Utils_Tuple2('furto_in_casa', 'D'),
			_Utils_Tuple2('preziosi', 'P'),
			_Utils_Tuple2('furto_fuori_casa', 'S')
		]));
var $author$project$Checkout$Update$Helper$mapSingleSourceInfo = F3(
	function (picker, gtmKey, sourceInfo) {
		return A3(
			$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
			gtmKey,
			'',
			picker(sourceInfo));
	});
var $author$project$Checkout$Update$Helper$mapSourceInfo = function (sourceInfo) {
	return _Utils_ap(
		A3(
			$author$project$Checkout$Update$Helper$mapSingleSourceInfo,
			function ($) {
				return $.ha;
			},
			'Splatform',
			sourceInfo),
		_Utils_ap(
			A3(
				$author$project$Checkout$Update$Helper$mapSingleSourceInfo,
				function ($) {
					return $.g0;
				},
				'Soperator',
				sourceInfo),
			A3(
				$author$project$Checkout$Update$Helper$mapSingleSourceInfo,
				function ($) {
					return $.hj;
				},
				'Sreferral',
				sourceInfo)));
};
var $author$project$Types$Save$pickSelectedGuarantee = F2(
	function (guaranteeSlug, _v0) {
		var packages = _v0.oN;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					$elm$core$Basics$eq(guaranteeSlug)),
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.nF;
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.mG;
						},
						packages))));
	});
var $author$project$Checkout$Update$Helper$singleGuaranteeMapper = F3(
	function (guaranteeSlug, gtmKey, selection) {
		var selectedGuarantee = A2($author$project$Types$Save$pickSelectedGuarantee, guaranteeSlug, selection);
		return _Utils_ap(
			$elm$core$List$singleton(
				A2(
					$elm$core$Tuple$pair,
					gtmKey,
					A2(
						$elm$core$Maybe$withDefault,
						'niente',
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always('inclusa'),
							selectedGuarantee)))),
			A2(
				$author$project$Utils$GTM$generateGTMTupleInList,
				'P' + gtmKey,
				A2($elm$core$Maybe$map, $author$project$Checkout$Update$Helper$amountToString, selectedGuarantee)));
	});
var $author$project$Types$Save$pickSelectedPackage = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					$elm$core$Basics$eq(packageSlug)),
				packages));
	});
var $author$project$Checkout$Update$Helper$singleClusterMapper = F2(
	function (slugDictionary, _v0) {
		var slug = _v0.bH;
		var guarantees = _v0.nF;
		return _Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$singleton,
					A2($elm$core$Dict$get, slug, slugDictionary))),
			A2(
				$elm$core$List$filterMap,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					A2($elm_community$basics_extra$Basics$Extra$flip, $elm$core$Dict$get, slugDictionary)),
				guarantees));
	});
var $author$project$Checkout$Update$Helper$singlePackageMapper = F5(
	function (packageSlug, gtmKey, joiner, slugDictionary, selection) {
		var selectedPackage = A2($author$project$Types$Save$pickSelectedPackage, packageSlug, selection);
		return _Utils_ap(
			$elm$core$List$singleton(
				A2(
					$elm$core$Tuple$pair,
					gtmKey,
					A2(
						$elm$core$Maybe$withDefault,
						'niente',
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.mG;
								},
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$concatMap(
										$author$project$Checkout$Update$Helper$singleClusterMapper(slugDictionary)),
									$elm$core$String$join(joiner))),
							selectedPackage)))),
			A2(
				$author$project$Utils$GTM$generateGTMTupleInList,
				'P' + gtmKey,
				A2($elm$core$Maybe$map, $author$project$Checkout$Update$Helper$amountToString, selectedPackage)));
	});
var $author$project$Checkout$Update$Helper$singletonPair = F2(
	function (key, value) {
		return $elm$core$List$singleton(
			_Utils_Tuple2(key, value));
	});
var $author$project$Checkout$Update$Helper$dataLayerV2Cmd = F2(
	function (model, _v0) {
		var id = _v0.nM;
		var selection = _v0.hs;
		var sourceInfo = _v0.pI;
		return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
			_Utils_ap(
				A2(
					$author$project$Checkout$Update$Helper$singletonPair,
					'SID',
					$danyx23$elm_uuid$Uuid$toString(id)),
				_Utils_ap(
					A2(
						$author$project$Checkout$Update$Helper$singletonPair,
						'PS',
						$author$project$Checkout$Update$Helper$amountToString(selection)),
					_Utils_ap(
						A5($author$project$Checkout$Update$Helper$singlePackageMapper, 'rc_famiglia', 'F', '+', $author$project$Checkout$Update$Helper$familyDictionary, selection),
						_Utils_ap(
							A5($author$project$Checkout$Update$Helper$singlePackageMapper, 'protezione_locali', 'C', '', $author$project$Checkout$Update$Helper$houseDictionary, selection),
							_Utils_ap(
								A3($author$project$Checkout$Update$Helper$singleGuaranteeMapper, 'infortuni_domestici', 'I', selection),
								_Utils_ap(
									A3($author$project$Checkout$Update$Helper$singleGuaranteeMapper, 'tutela_legale', 'T', selection),
									_Utils_ap(
										$author$project$Checkout$Update$Helper$mapSourceInfo(sourceInfo),
										$author$project$Checkout$Update$Helper$getRiskTypeDataLayerList(
											$author$project$Checkout$Model$getSaveType(model))))))))));
	});
var $author$project$Checkout$Update$Helper$maybeDataLayerV2Cmd = F2(
	function (model, maybeSave) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Checkout$Update$Helper$dataLayerV2Cmd(model),
				maybeSave));
	});
var $author$project$Types$Utils$ownershipToLabel = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Proprietario';
		case 1:
			return 'Locatario';
		default:
			return 'Nessuna proprietà';
	}
};
var $author$project$Guarantees$Model$getSaveFromSaveResponse = function (saveResponse) {
	if (!saveResponse.$) {
		var save = saveResponse.a;
		return $elm$core$Maybe$Just(save);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $krisajenkins$remotedata$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map($elm$core$Maybe$Just),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$Checkout$Model$pickSave = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.hq;
	},
	A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		$elm$core$Maybe$andThen($author$project$Guarantees$Model$getSaveFromSaveResponse)));
var $author$project$Types$Utils$propertyTypeToLabel = function (_enum) {
	if (!_enum) {
		return 'Appartamento in condominio';
	} else {
		return 'Casa indipendente o villa';
	}
};
var $author$project$Types$Utils$propertyUsageTypeToLabel = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Abituale';
		case 1:
			return 'Saltuario';
		case 2:
			return 'Locato';
		case 3:
			return 'Non abitato';
		default:
			return 'Nessuna proprietà';
	}
};
var $author$project$Utils$stringWithDefault = F2(
	function (_default, value) {
		return $elm$core$String$isEmpty(value) ? _default : value;
	});
var $author$project$Checkout$Update$Helper$pushFormDataToDataLayer = function (_v0) {
	var model = _v0.a;
	var saveId = model.pz;
	var order = model.oK;
	var userSelection = model.lQ;
	var cmd = _v0.b;
	var updateReturn = _v0.c;
	var maybeSourceInfo = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.pI;
		},
		$author$project$Checkout$Model$pickSave(model));
	var maybeSave = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.py;
		},
		order);
	var selectedPackages = A2($elm$core$Maybe$map, $author$project$Guarantees$Helpers$pickPackagesFromSave, maybeSave);
	var maybeQuote = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.pd;
		},
		maybeSave);
	var maybeInformation = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.nT;
		},
		maybeQuote);
	var previousDataLayerCmd = $author$project$Utils$GTM$generateTupleAndPushWithDefaultEvent(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Location',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.my;
					},
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.kY;
						},
						maybeInformation))),
				_Utils_Tuple2(
				'Province',
				$elm_community$maybe_extra$Maybe$Extra$join(
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.o6;
						},
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.kY;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'Proprietario',
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.oL;
						},
						$author$project$Types$Utils$ownershipToLabel),
					maybeInformation)),
				_Utils_Tuple2(
				'Utilizzo',
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.o1;
						},
						$author$project$Types$Utils$propertyUsageTypeToLabel),
					maybeInformation)),
				_Utils_Tuple2(
				'Immobile',
				A2(
					$elm$core$Maybe$map,
					$author$project$Types$Utils$propertyTypeToLabel,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.o4;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'Dimensioni',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.p3;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'Costruzione',
				A2(
					$elm$core$Maybe$map,
					$author$project$Types$Utils$constructionPeriodToLabel,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.mP;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'S',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.pn;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'Maggiorenni',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.np;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'Minorenni',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					$elm_community$maybe_extra$Maybe$Extra$join(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.nr;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'G',
				A2(
					$elm$core$Maybe$map,
					$author$project$Guarantees$Model$genderGTMParser,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ny;
						},
						maybeInformation))),
				_Utils_Tuple2(
				'Età',
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					A3(
						$elm$core$Maybe$map2,
						$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$calculateAge),
						model.iH,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.mn;
							},
							maybeInformation)))),
				_Utils_Tuple2(
				'FloorType',
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Types$Utils$floorTypeToValue,
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.nw;
						},
						maybeInformation))),
				_Utils_Tuple2(
				'QID',
				$elm$core$Maybe$Just(
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						'',
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.nM;
							},
							$danyx23$elm_uuid$Uuid$toString),
						maybeQuote))),
				_Utils_Tuple2(
				'Qplatform',
				$elm$core$Maybe$Just(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.ha;
							},
							maybeSourceInfo)))),
				_Utils_Tuple2(
				'Qoperator',
				$elm$core$Maybe$Just(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.g0;
							},
							maybeSourceInfo)))),
				_Utils_Tuple2(
				'Qreferral',
				$elm$core$Maybe$Just(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.hj;
							},
							maybeSourceInfo))))
			]));
	var houseProtectionLevel = A2(
		$elm$core$Maybe$andThen,
		A2($elm$core$Basics$composeR, $author$project$Checkout$Model$getSaveConfigurationPackages, $author$project$Checkout$Update$Helper$getCasaInsuranceSelected),
		order);
	var dataLayerV1Cmd = $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'SID',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, '', $danyx23$elm_uuid$Uuid$toString, saveId)),
			_Utils_ap(
				A2(
					$author$project$Utils$GTM$generateGTMTupleInList,
					'PS',
					A3($elm$core$Maybe$map2, $author$project$Checkout$Update$Helper$getTotalPrice, order, userSelection)),
				_Utils_ap(
					A2(
						$author$project$Utils$GTM$generateGTMTupleInList,
						'PC',
						A2(
							$elm$core$Maybe$map,
							$prikhi$decimal$Decimal$toString,
							A3(
								$elm$core$Maybe$map2,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Guarantees$Helpers$calculatePackagePrice),
								userSelection,
								$elm_community$maybe_extra$Maybe$Extra$join(
									A3($elm$core$Maybe$map2, $author$project$Checkout$Update$Helper$getPackage, houseProtectionLevel, selectedPackages))))),
					_Utils_ap(
						A2(
							$author$project$Utils$GTM$generateGTMTupleInList,
							'C',
							A2(
								$elm$core$Maybe$map,
								$author$project$Checkout$Update$Helper$getLevelCasaInsuranceSelected,
								A2($elm$core$Maybe$map, $author$project$Checkout$Model$getSaveConfigurationPackages, order))),
						_Utils_ap(
							A2(
								$author$project$Utils$GTM$generateGTMTupleInList,
								'PF',
								A2(
									$elm$core$Maybe$map,
									$prikhi$decimal$Decimal$toString,
									A3(
										$elm$core$Maybe$map2,
										$elm_community$basics_extra$Basics$Extra$flip($author$project$Guarantees$Helpers$calculatePackagePrice),
										userSelection,
										$elm_community$maybe_extra$Maybe$Extra$join(
											A2(
												$elm$core$Maybe$map,
												$author$project$Checkout$Update$Helper$getPackage('rc_famiglia'),
												selectedPackages))))),
							_Utils_ap(
								A2(
									$author$project$Utils$GTM$generateGTMTupleInList,
									'F',
									A2(
										$elm$core$Maybe$map,
										$author$project$Utils$stringWithDefault('niente'),
										A2($elm$core$Maybe$map, $author$project$Checkout$Update$Helper$getFamilyProtectionSelected, order))),
								_Utils_ap(
									A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										_List_fromArray(
											[
												_Utils_Tuple2('I', 'niente')
											]),
										A2(
											$elm$core$Basics$composeR,
											$elm$core$List$singleton,
											$elm$core$List$cons(
												_Utils_Tuple2('I', 'inclusi'))),
										A2(
											$elm$core$Maybe$map,
											$elm$core$Tuple$pair('PI'),
											A2(
												$elm$core$Maybe$map,
												$prikhi$decimal$Decimal$toString,
												A3(
													$elm$core$Maybe$map2,
													$elm_community$basics_extra$Basics$Extra$flip($author$project$Guarantees$Helpers$calculatePackagePrice),
													userSelection,
													$elm_community$maybe_extra$Maybe$Extra$join(
														A2(
															$elm$core$Maybe$map,
															$author$project$Checkout$Update$Helper$getPackage('infortuni_domestici'),
															selectedPackages)))))),
									_Utils_ap(
										A3(
											$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
											'Splatform',
											'',
											A2(
												$elm$core$Maybe$andThen,
												function ($) {
													return $.ha;
												},
												maybeSourceInfo)),
										_Utils_ap(
											A3(
												$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
												'Soperator',
												'',
												A2(
													$elm$core$Maybe$andThen,
													function ($) {
														return $.g0;
													},
													maybeSourceInfo)),
											_Utils_ap(
												A3(
													$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
													'Sreferral',
													'',
													A2(
														$elm$core$Maybe$andThen,
														function ($) {
															return $.hj;
														},
														maybeSourceInfo)),
												$author$project$Checkout$Update$Helper$getRiskTypeDataLayerList(
													$author$project$Checkout$Model$getSaveType(model)))))))))))));
	var dataLayerForVersion = function (_v2) {
		var version = _v2.lU;
		if (!version) {
			return dataLayerV1Cmd;
		} else {
			return A2($author$project$Checkout$Update$Helper$maybeDataLayerV2Cmd, model, maybeSave);
		}
	};
	var dataLayerCmd = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2($elm$core$Maybe$map, dataLayerForVersion, maybeQuote));
	return _Utils_Tuple3(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[previousDataLayerCmd, dataLayerCmd, cmd])),
		updateReturn);
};
var $author$project$Ports$pushUserRole = _Platform_outgoingPort(
	'pushUserRole',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Checkout$Update$BraintreeHelper$removeError = function (brainTreeModel) {
	return _Utils_update(
		brainTreeModel,
		{c0: 1, b0: $elm$core$Maybe$Nothing, ky: $elm$core$Maybe$Nothing, hK: $elm$core$Maybe$Nothing});
};
var $author$project$Api$GetSave = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$GotSave = function (a) {
	return {$: 14, a: a};
};
var $author$project$Api$SaveQueryResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Union$GetSaveResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Save', selections____.gM),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SaveError', selections____.gO)
			]));
};
var $author$project$Types$Save$GetSaveResponseError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$GenericError = F2(
	function (error, code) {
		return {mH: code, b0: error};
	});
var $author$project$Gql$Vianello$Object$SaveError$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'code', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gql$Vianello$Object$SaveError$error = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'error', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$onError = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SaveError$code,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SaveError$error,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$GenericError)));
var $author$project$Guarantees$Api$Save$onSaveErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Save$onError,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$GetSaveResponseError));
var $author$project$Types$Save$GetSaveData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$Api$Save$onSaveSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Save$saveSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$GetSaveData));
var $author$project$Guarantees$Api$Save$getSaveResponseSelection = $author$project$Gql$Vianello$Union$GetSaveResponse$fragments(
	{gM: $author$project$Guarantees$Api$Save$onSaveSelection, gO: $author$project$Guarantees$Api$Save$onSaveErrorSelection});
var $author$project$Gql$Vianello$Query$save = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'save',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.nM,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Guarantees$Api$Save$saveQuery = function (saveId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$SaveQueryResult,
		A2(
			$author$project$Gql$Vianello$Query$save,
			{nM: saveId},
			$author$project$Guarantees$Api$Save$getSaveResponseSelection));
};
var $author$project$Checkout$Api$saveQuery = function (saveId) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$CheckoutSource(
			$author$project$Api$GetSave($author$project$Checkout$Model$GotSave)),
		$author$project$Guarantees$Api$Save$saveQuery(saveId));
};
var $author$project$Checkout$Update$Helper$saveQuery = F2(
	function (saveId, updateReturn) {
		return A2(
			$author$project$Utils$UpdateReturn$withQuery,
			$author$project$Checkout$Api$saveQuery(saveId),
			updateReturn);
	});
var $author$project$Checkout$Model$BraintreeModel$setAppStatus = F2(
	function (appStatus, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{c0: appStatus});
	});
var $author$project$Checkout$Model$BraintreeModel$setError = F2(
	function (maybePaymentError, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{b0: maybePaymentError});
	});
var $author$project$Checkout$Update$BraintreeHelper$setServerSideError = F2(
	function (errorDescription, brainTreeModel) {
		var serverErrorEvent = {mH: 0, of: errorDescription};
		return _Utils_update(
			brainTreeModel,
			{
				c0: 4,
				b0: $elm$core$Maybe$Just(
					$author$project$Checkout$Model$BraintreeModel$ServerSideError(serverErrorEvent))
			});
	});
var $elm$time$Time$here = _Time_here(0);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $author$project$Types$ToggleClassConfig = F3(
	function (isAdding, className, selector) {
		return {mA: className, n_: isAdding, pE: selector};
	});
var $author$project$Ports$toggleClass = _Platform_outgoingPort(
	'toggleClass',
	$elm$json$Json$Encode$list(
		function ($) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'className',
						$elm$json$Json$Encode$string($.mA)),
						_Utils_Tuple2(
						'isAdding',
						$elm$json$Json$Encode$bool($.n_)),
						_Utils_Tuple2(
						'selector',
						$elm$json$Json$Encode$string($.pE))
					]));
		}));
var $author$project$Utils$toggleBlockScrollingClass = function (isBlocked) {
	return $author$project$Ports$toggleClass(
		_List_fromArray(
			[
				A3($author$project$Types$ToggleClassConfig, isBlocked, 'scroll-locked', 'html')
			]));
};
var $author$project$Checkout$Model$BraintreeModel$AppLoading = 0;
var $author$project$Checkout$Api$BraintreePay$createConfigBillingAddress = function (_v0) {
	var firstName = _v0.nu;
	var lastName = _v0.n8;
	var phoneNumber = _v0.cu;
	var streetAddress = _v0.hN;
	var streetNumber = _v0.pO;
	var zipCode = _v0.qg;
	var city = _v0.my;
	var countryCode = _v0.e6;
	var region = _v0.hk;
	return {e6: countryCode, jq: firstName, j5: city, cu: phoneNumber, kJ: zipCode, hk: region, hN: streetAddress + (' ' + streetNumber), lC: lastName};
};
var $author$project$Checkout$Api$BraintreePay$createConfigWithOrderAndToken = F3(
	function (brainTreeModel, order, token) {
		var quoteInfo = order.py.pd.nT;
		return {
			e0: $author$project$Checkout$Api$BraintreePay$createConfigBillingAddress(order.e0),
			e1: brainTreeModel.e1,
			fi: quoteInfo.fi,
			oz: brainTreeModel.oz,
			cu: quoteInfo.oT,
			p1: token
		};
	});
var $author$project$Checkout$Api$BraintreePay$createBrainTreeConfig = function (_v0) {
	var brainTreeModel = _v0.mp;
	var order = _v0.oK;
	return A3(
		$elm$core$Maybe$map2,
		$author$project$Checkout$Api$BraintreePay$createConfigWithOrderAndToken(brainTreeModel),
		order,
		brainTreeModel.p1);
};
var $prikhi$decimal$Decimal$toFloat = function (d) {
	var _v0 = $elm$core$String$toFloat(
		$prikhi$decimal$Decimal$toString(d));
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 42.0;
	}
};
var $author$project$Checkout$Api$BraintreePay$createVerify3DSCard = function (model) {
	var order = model.oK;
	return A3(
		$elm$core$Maybe$map2,
		F2(
			function (o, c) {
				return {
					bS: $prikhi$decimal$Decimal$toFloat(o.bS),
					iC: c
				};
			}),
		order,
		$author$project$Checkout$Api$BraintreePay$createBrainTreeConfig(model));
};
var $author$project$Checkout$Model$GotPaymentResponse = function (a) {
	return {$: 7, a: a};
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$bool(value));
};
var $author$project$Gql$Lira$Scalar$Id = $elm$core$Basics$identity;
var $author$project$Gql$Lira$Scalar$Uuid = $elm$core$Basics$identity;
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				return bool ? 'true' : 'false';
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Gql$Lira$Scalar$defaultCodecs = {
	iy: {
		m0: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		fk: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	t: {
		m0: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		fk: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Gql$Lira$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Gql$Lira$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $author$project$LiraScalarCodecs$codecs = $author$project$Gql$Lira$Scalar$defineCodecs(
	{
		iy: $author$project$Gql$Lira$Scalar$defaultCodecs.iy,
		t: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedId) {
					if (!maybeParsedId.$) {
						var parsedId = maybeParsedId.a;
						return $elm$json$Json$Decode$succeed(parsedId);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse ID as an Uuid.');
					}
				},
				A2($elm$json$Json$Decode$map, $danyx23$elm_uuid$Uuid$fromString, $elm$json$Json$Decode$string)),
			fk: function (uuid) {
				return $elm$json$Json$Encode$string(
					$danyx23$elm_uuid$Uuid$toString(uuid));
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional = F3(
	function (fieldName, maybeValue, toValue) {
		switch (maybeValue.$) {
			case 0:
				var value = maybeValue.a;
				return $elm$core$Maybe$Just(
					A2(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
						fieldName,
						toValue(value)));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument, fieldName, $dillonkearns$elm_graphql$Graphql$Internal$Encode$null));
		}
	});
var $author$project$Gql$Lira$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).fk,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Gql$Lira$Mutation$braintreePay = F3(
	function (fillInOptionals____, requiredArgs____, object____) {
		var filledInOptionals____ = fillInOptionals____(
			{c: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, d: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, hu: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent, lu: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		var optionalArgs____ = A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'customer', filledInOptionals____.c, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'productReference', filledInOptionals____.d, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'setAsDefault', filledInOptionals____.hu, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$optional, 'source', filledInOptionals____.lu, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]));
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'braintreePay',
			_Utils_ap(
				optionalArgs____,
				_List_fromArray(
					[
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'nonce', requiredArgs____.oz, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'product', requiredArgs____.o0, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
						A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'amount', requiredArgs____.bS, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string),
						A3(
						$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
						'orderId',
						requiredArgs____.kw,
						A2(
							$author$project$Gql$Lira$Scalar$unwrapEncoder,
							function ($) {
								return $.t;
							},
							$author$project$LiraScalarCodecs$codecs))
					])),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Lira$Union$BraintreePayResponse$Fragments = F2(
	function (onPaymentResponse, onPaymentError) {
		return {gz: onPaymentError, gB: onPaymentResponse};
	});
var $author$project$Checkout$Model$BraintreePayResponseError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Model$PaymentError = F2(
	function (code, message) {
		return {mH: code, of: message};
	});
var $author$project$Gql$Lira$Enum$ErrorCode$BraintreePayError = 1;
var $author$project$Gql$Lira$Enum$ErrorCode$Unknown = 0;
var $author$project$Gql$Lira$Enum$ErrorCode$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'UNKNOWN':
				return $elm$json$Json$Decode$succeed(0);
			case 'BRAINTREE_PAY_ERROR':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid ErrorCode type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Lira$Object$PaymentError$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.ErrorCode.ErrorCode', 'code', _List_Nil, $author$project$Gql$Lira$Enum$ErrorCode$decoder);
var $author$project$Gql$Lira$Object$PaymentError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Model$BraintreePayError = 1;
var $author$project$Checkout$Model$Unknown = 0;
var $author$project$Checkout$Api$BraintreePay$paymentErrorCodeMap = function (errorCode) {
	if (!errorCode) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Checkout$Api$BraintreePay$paymentErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Lira$Object$PaymentError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Checkout$Api$BraintreePay$paymentErrorCodeMap, $author$project$Gql$Lira$Object$PaymentError$code),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$PaymentError)));
var $author$project$Checkout$Api$BraintreePay$braintreePayResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$BraintreePay$paymentErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$BraintreePayResponseError));
var $author$project$Checkout$Model$BraintreePayResponseOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$PaymentResponse = F2(
	function (id, legacyId) {
		return {nM: id, ob: legacyId};
	});
var $author$project$Gql$Lira$Object$PaymentResponse$id = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'id', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Lira$Object$PaymentResponse$legacyId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'legacyId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$BraintreePay$paymentResponseSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Lira$Object$PaymentResponse$legacyId,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Lira$Object$PaymentResponse$id,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$PaymentResponse)));
var $author$project$Checkout$Api$BraintreePay$braintreePayResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$BraintreePay$paymentResponseSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$BraintreePayResponseOk));
var $author$project$Gql$Lira$Union$BraintreePayResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PaymentResponse', selections____.gB),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PaymentError', selections____.gz)
			]));
};
var $author$project$Checkout$Api$BraintreePay$braintreePayResponseSelection = $author$project$Gql$Lira$Union$BraintreePayResponse$fragments(
	A2($author$project$Gql$Lira$Union$BraintreePayResponse$Fragments, $author$project$Checkout$Api$BraintreePay$braintreePayResponseOkSelection, $author$project$Checkout$Api$BraintreePay$braintreePayResponseErrorSelection));
var $author$project$Checkout$Api$BraintreePay$braintreePayMutation = F2(
	function (order, nonce) {
		return A3(
			$author$project$Gql$Lira$Mutation$braintreePay,
			$elm$core$Basics$identity,
			{
				bS: $prikhi$decimal$Decimal$toString(order.bS),
				oz: nonce,
				kw: order.kw,
				o0: 'home'
			},
			$author$project$Checkout$Api$BraintreePay$braintreePayResponseSelection);
	});
var $author$project$Api$makePost = F3(
	function (apiUrl, query, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
				A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, apiUrl, query)));
	});
var $author$project$Checkout$Api$BraintreePay$registerBraintreePayment = F3(
	function (liraUrl, order, nonce) {
		return A3(
			$author$project$Api$makePost,
			liraUrl,
			A2($author$project$Checkout$Api$BraintreePay$braintreePayMutation, order, nonce),
			$author$project$Checkout$Model$GotPaymentResponse);
	});
var $author$project$Checkout$Api$registerBraintreePayment = $author$project$Checkout$Api$BraintreePay$registerBraintreePayment;
var $author$project$Checkout$Update$BraintreeHelper$registerPayment = F2(
	function (nonce, model) {
		var order = model.oK;
		var flags = model.nv;
		return A2(
			$elm$core$Tuple$pair,
			A2(
				$author$project$Checkout$Update$Helper$updateBrainTreeModel,
				$author$project$Checkout$Model$BraintreeModel$setAppStatus(0),
				model),
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$elm$core$Platform$Cmd$none,
				function (order_) {
					return A3(
						$author$project$Checkout$Api$registerBraintreePayment,
						$author$project$Types$pickLiraUrl(flags),
						order_,
						nonce);
				},
				order));
	});
var $author$project$Checkout$Model$BraintreeModel$AppPaying = 2;
var $author$project$Checkout$Model$BraintreeModel$setAppChallenging = F2(
	function (isChallenging, model) {
		return _Utils_update(
			model,
			{
				c0: isChallenging ? 3 : 2
			});
	});
var $author$project$Checkout$Model$BraintreeModel$ClientSideError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Update$BraintreeHelper$setError = F2(
	function (errorEvent, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				c0: 4,
				b0: $elm$core$Maybe$Just(
					$author$project$Checkout$Model$BraintreeModel$ClientSideError(errorEvent))
			});
	});
var $author$project$Checkout$Update$BraintreeHelper$setPayPalError = $author$project$Checkout$Update$BraintreeHelper$setError(
	{mH: 'PAYPAL_POPUP_CLOSED', mR: 'braintreeTokenizeError', b0: 'Customer closed PayPal popup before authorizing.'});
var $author$project$Checkout$Update$BraintreeHelper$setPayloadInformation = F2(
	function (payload, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				e1: $elm$core$Maybe$Just(payload.e1),
				oz: $elm$core$Maybe$Just(payload.oz)
			});
	});
var $author$project$Checkout$Model$BraintreeModel$AuthenticateAttemptSuccessful = 2;
var $author$project$Checkout$Model$BraintreeModel$AuthenticateFailed = 1;
var $author$project$Checkout$Model$BraintreeModel$AuthenticateRejected = 4;
var $author$project$Checkout$Model$BraintreeModel$AuthenticateSuccessful = 0;
var $author$project$Checkout$Model$BraintreeModel$AuthenticateUnableToAuthenticate = 3;
var $author$project$Checkout$Model$BraintreeModel$AuthenticationUnavailable = 5;
var $author$project$Checkout$Model$BraintreeModel$ChallengeRequired = 8;
var $author$project$Checkout$Model$BraintreeModel$LookupBypassed = 7;
var $author$project$Checkout$Model$BraintreeModel$LookupError = 6;
var $author$project$Checkout$Update$BraintreeHelper$statusToOutcome = function (status) {
	switch (status) {
		case 'authenticate_successful':
			return $elm$core$Maybe$Just(0);
		case 'authenticate_failed':
			return $elm$core$Maybe$Just(1);
		case 'authenticate_attempt_successful':
			return $elm$core$Maybe$Just(2);
		case 'authenticate_unable_to_authenticate':
			return $elm$core$Maybe$Just(3);
		case 'authenticate_rejected':
			return $elm$core$Maybe$Just(4);
		case 'authentication_unavailable':
			return $elm$core$Maybe$Just(5);
		case 'lookup_error':
			return $elm$core$Maybe$Just(6);
		case 'lookup_bypassed':
			return $elm$core$Maybe$Just(7);
		case 'challenge_required':
			return $elm$core$Maybe$Just(8);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Checkout$Update$BraintreeHelper$setVerificationResult = F2(
	function (verificationResult, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				e1: $elm$core$Maybe$Just(verificationResult.e1),
				oz: $elm$core$Maybe$Just(verificationResult.oz),
				ky: $author$project$Checkout$Update$BraintreeHelper$statusToOutcome(verificationResult.hK),
				hK: $elm$core$Maybe$Just(verificationResult.hK)
			});
	});
var $author$project$Checkout$Update$BraintreeHelper$updateFieldValidation = F2(
	function (_v0, braintreeField) {
		var isValid = _v0.jY;
		var isPristine = _v0.jR;
		return _Utils_update(
			braintreeField,
			{jR: isPristine, jY: isValid});
	});
var $author$project$Checkout$Update$BraintreeHelper$updateFieldsValidationAndStatus = F2(
	function (braintreeFieldValidation, braintreeFields) {
		var id = braintreeFieldValidation.nM;
		var cardNumber = braintreeFields.io;
		var expirationMonth = braintreeFields.i7;
		var expirationYear = braintreeFields.i8;
		var cvv = braintreeFields.iJ;
		switch (id) {
			case 'card-number':
				return _Utils_update(
					braintreeFields,
					{
						io: A2($author$project$Checkout$Update$BraintreeHelper$updateFieldValidation, braintreeFieldValidation, cardNumber)
					});
			case 'expiration-month':
				return _Utils_update(
					braintreeFields,
					{
						i7: A2($author$project$Checkout$Update$BraintreeHelper$updateFieldValidation, braintreeFieldValidation, expirationMonth)
					});
			case 'expiration-year':
				return _Utils_update(
					braintreeFields,
					{
						i8: A2($author$project$Checkout$Update$BraintreeHelper$updateFieldValidation, braintreeFieldValidation, expirationYear)
					});
			case 'cvv':
				return _Utils_update(
					braintreeFields,
					{
						iJ: A2($author$project$Checkout$Update$BraintreeHelper$updateFieldValidation, braintreeFieldValidation, cvv)
					});
			default:
				return braintreeFields;
		}
	});
var $author$project$Checkout$Update$BraintreeHelper$updateFormFields = F2(
	function (fieldList, model) {
		var brainTreeModel = model.mp;
		return A2(
			$author$project$Checkout$Update$Helper$updateBrainTreeModel,
			function (btm) {
				return _Utils_update(
					btm,
					{
						jc: A3($elm$core$List$foldl, $author$project$Checkout$Update$BraintreeHelper$updateFieldsValidationAndStatus, brainTreeModel.jc, fieldList)
					});
			},
			model);
	});
var $author$project$Utils$withMapCommands = F2(
	function (mappers, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					$elm$core$Basics$apR(model),
					mappers)));
	});
var $author$project$Checkout$Update$BraintreeUpdate$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var braintreeFieldList = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Checkout$Update$BraintreeHelper$updateFormFields, braintreeFieldList, model)));
			case 1:
				var payload = msg.a;
				var command = A2(
					$elm$core$Basics$composeR,
					$author$project$Checkout$Api$BraintreePay$createVerify3DSCard,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($author$project$Checkout$Ports$verify3DSCard),
						$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none)));
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withMapCommands,
						_List_fromArray(
							[command]),
						A2(
							$author$project$Checkout$Update$Helper$updateBrainTreeModel,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Checkout$Update$BraintreeHelper$setPayloadInformation(payload),
								$author$project$Checkout$Model$BraintreeModel$setAppChallenging(true)),
							model)));
			case 2:
				var threeDSPayload = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Checkout$Update$BraintreeHelper$registerPayment,
						threeDSPayload.oz,
						A2(
							$author$project$Checkout$Update$Helper$updateBrainTreeModel,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Checkout$Update$BraintreeHelper$setVerificationResult(threeDSPayload),
								$author$project$Checkout$Model$BraintreeModel$setAppStatus(0)),
							model)));
			case 4:
				var error = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$Checkout$Update$Helper$updateBrainTreeModel,
							$author$project$Checkout$Update$BraintreeHelper$setError(error),
							model)));
			case 3:
				var nonce = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2($author$project$Checkout$Update$BraintreeHelper$registerPayment, nonce, model));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Checkout$Update$Helper$updateBrainTreeModel, $author$project$Checkout$Update$BraintreeHelper$setPayPalError, model)));
		}
	});
var $author$project$Checkout$Update$Helper$updateAppOptions = F2(
	function (mapper, model) {
		return _Utils_update(
			model,
			{
				ku: mapper(model.ku)
			});
	});
var $author$project$Checkout$Update$Helper$updateAppOptionsSetSelectedPackage = F2(
	function (maybeSelectedPackage, appOptions) {
		return _Utils_update(
			appOptions,
			{ln: maybeSelectedPackage});
	});
var $author$project$Checkout$Update$Helper$updateAppOptionsSetSummaryOpen = F2(
	function (isSummaryOpen, appOptions) {
		return _Utils_update(
			appOptions,
			{jW: isSummaryOpen});
	});
var $author$project$Checkout$Update$Helper$updateClientConfigResponse = F2(
	function (clientConfigResponse, model) {
		return _Utils_update(
			model,
			{iv: clientConfigResponse});
	});
var $author$project$Checkout$Update$Helper$updateCurrentDate = F2(
	function (today, model) {
		return _Utils_update(
			model,
			{
				iH: $elm$core$Maybe$Just(today)
			});
	});
var $author$project$Checkout$Update$Helper$updateCurrentStep = F2(
	function (step, model) {
		return _Utils_update(
			model,
			{de: step});
	});
var $author$project$Checkout$Update$Helper$updateEffectiveDate = F2(
	function (effectiveDate, model) {
		return _Utils_update(
			model,
			{m8: effectiveDate});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ParsedDate = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$selectedDate = function (model) {
	return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ParsedDate(model.j);
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ValidDay = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ValidMonth = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$isLeapYear = function (year) {
	return ((!(year % 4)) && (!(!(year % 100)))) || (!(year % 400));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$getDaysInMonth = F2(
	function (year, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$isLeapYear(year) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateToLastDayOfMonth = function (date) {
	return A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(date),
		$justinmimbs$date$Date$month(date),
		A2(
			$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$getDaysInMonth,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid = F3(
	function (validityCheck, newDate, model) {
		var _v0 = model.aN;
		var low_ = _v0.a;
		var high_ = _v0.b;
		var _v1 = function () {
			if (!validityCheck) {
				return _Utils_Tuple2(low_, high_);
			} else {
				return _Utils_Tuple2(
					A3(
						$justinmimbs$date$Date$fromCalendarDate,
						$justinmimbs$date$Date$year(low_),
						$justinmimbs$date$Date$month(low_),
						1),
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateToLastDayOfMonth(high_));
			}
		}();
		var low = _v1.a;
		var high = _v1.b;
		return A3($justinmimbs$date$Date$isBetween, low, high, newDate) ? _Utils_update(
			model,
			{j: newDate}) : model;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$setDate = F2(
	function (date, model) {
		return A3(
			$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid,
			1,
			date,
			A3($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid, 0, date, model));
	});
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$nextMonth = F2(
	function (month, year) {
		switch (month) {
			case 0:
				return _Utils_Tuple2(1, year);
			case 1:
				return _Utils_Tuple2(2, year);
			case 2:
				return _Utils_Tuple2(3, year);
			case 3:
				return _Utils_Tuple2(4, year);
			case 4:
				return _Utils_Tuple2(5, year);
			case 5:
				return _Utils_Tuple2(6, year);
			case 6:
				return _Utils_Tuple2(7, year);
			case 7:
				return _Utils_Tuple2(8, year);
			case 8:
				return _Utils_Tuple2(9, year);
			case 9:
				return _Utils_Tuple2(10, year);
			case 10:
				return _Utils_Tuple2(11, year);
			default:
				return _Utils_Tuple2(0, year + 1);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$shiftToNextMonth = function (model) {
	var _v0 = A2(
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$nextMonth,
		$justinmimbs$date$Date$month(model.j),
		$justinmimbs$date$Date$year(model.j));
	var newMonth = _v0.a;
	var newYear = _v0.b;
	var shiftedDate = A3(
		$justinmimbs$date$Date$fromCalendarDate,
		newYear,
		newMonth,
		$justinmimbs$date$Date$day(model.j));
	var _v1 = model.aN;
	var high_ = _v1.b;
	var newDate = (A2($justinmimbs$date$Date$compare, high_, shiftedDate) === 2) ? shiftedDate : high_;
	return A3($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid, 0, newDate, model);
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$prevMonth = F2(
	function (month, year) {
		switch (month) {
			case 0:
				return _Utils_Tuple2(11, year - 1);
			case 1:
				return _Utils_Tuple2(0, year);
			case 2:
				return _Utils_Tuple2(1, year);
			case 3:
				return _Utils_Tuple2(2, year);
			case 4:
				return _Utils_Tuple2(3, year);
			case 5:
				return _Utils_Tuple2(4, year);
			case 6:
				return _Utils_Tuple2(5, year);
			case 7:
				return _Utils_Tuple2(6, year);
			case 8:
				return _Utils_Tuple2(7, year);
			case 9:
				return _Utils_Tuple2(8, year);
			case 10:
				return _Utils_Tuple2(9, year);
			default:
				return _Utils_Tuple2(10, year);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$shiftToPreviousMonth = function (model) {
	var _v0 = A2(
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$prevMonth,
		$justinmimbs$date$Date$month(model.j),
		$justinmimbs$date$Date$year(model.j));
	var newMonth = _v0.a;
	var newYear = _v0.b;
	var shiftedDate = A3(
		$justinmimbs$date$Date$fromCalendarDate,
		newYear,
		newMonth,
		$justinmimbs$date$Date$day(model.j));
	var _v1 = model.aN;
	var low_ = _v1.a;
	var newDate = (!A2($justinmimbs$date$Date$compare, low_, shiftedDate)) ? shiftedDate : low_;
	return A3($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid, 0, newDate, model);
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateSelectedDay = F2(
	function (day, model) {
		var newDate = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(model.j),
			$justinmimbs$date$Date$month(model.j),
			day);
		return A3($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid, 0, newDate, model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateSelectedYear = F2(
	function (year, model) {
		var newDate = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			year,
			$justinmimbs$date$Date$month(model.j),
			$justinmimbs$date$Date$day(model.j));
		return A3($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateModelIfValid, 1, newDate, model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return model;
			case 1:
				return _Utils_update(
					model,
					{a3: true});
			case 2:
				return _Utils_update(
					model,
					{a3: false});
			case 3:
				return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$shiftToPreviousMonth(model);
			case 4:
				return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$shiftToNextMonth(model);
			case 5:
				var year = msg.a;
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateSelectedYear,
					year,
					_Utils_update(
						model,
						{a3: false}));
			case 6:
				var day = msg.a;
				return A2($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$updateSelectedDay, day, model);
			default:
				var date = msg.a;
				return A2($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$setDate, date, model);
		}
	});
var $author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpen = F2(
	function (datePickerMsg, model) {
		if (datePickerMsg.$ === 6) {
			return _Utils_update(
				model,
				{n1: false});
		} else {
			return model;
		}
	});
var $author$project$Checkout$Update$Helper$updateEffectiveDateDatePickerByMsg = F2(
	function (datePickerMsg, model) {
		var effectiveDateDatePicker = model.m9;
		return A2(
			$author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpen,
			datePickerMsg,
			function (m) {
				return _Utils_update(
					m,
					{
						m8: A3($elm_community$maybe_extra$Maybe$Extra$unwrap, m.m8, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$selectedDate, m.m9)
					});
			}(
				_Utils_update(
					model,
					{
						m9: A2(
							$elm$core$Maybe$map,
							$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$update(datePickerMsg),
							effectiveDateDatePicker)
					})));
	});
var $author$project$Checkout$Update$BraintreeHelper$updateError = F2(
	function (maybeErrorEvent, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_update(
				model,
				{ky: $elm$core$Maybe$Nothing, hK: $elm$core$Maybe$Nothing}),
			A2(
				$elm$core$Maybe$map,
				function (errorEvent) {
					return _Utils_update(
						model,
						{
							ky: $author$project$Checkout$Update$BraintreeHelper$statusToOutcome(errorEvent.b0),
							hK: $elm$core$Maybe$Just(errorEvent.b0)
						});
				},
				maybeErrorEvent));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$fromMaybeDate = function (date) {
	if (!date.$) {
		var a = date.a;
		return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ParsedDate(a);
	} else {
		return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate($elm$core$Maybe$Nothing);
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$adjustInitialDate = F2(
	function (day, _v0) {
		var low = _v0.a;
		var high = _v0.b;
		return A3($justinmimbs$date$Date$isBetween, low, high, day) ? day : low;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$init = F2(
	function (date, daysRange) {
		return {
			j: A2($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$adjustInitialDate, date, daysRange),
			aN: daysRange,
			a3: false
		};
	});
var $author$project$Checkout$Update$Helper$initEffectiveDateDatePicker = F3(
	function (saveExpiration, selectedDate, today) {
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$init,
			_Utils_Tuple2(today, saveExpiration),
			A2($elm$core$Maybe$withDefault, today, selectedDate));
	});
var $author$project$Types$Quote$Selection$mapGuaranteeOptionGroups = F2(
	function (mapper, guarantee) {
		if (!guarantee.$) {
			var yearly = guarantee.a;
			return $author$project$Types$Quote$Yearly(
				_Utils_update(
					yearly,
					{
						g3: mapper(yearly.g3)
					}));
		} else {
			var monthly = guarantee.a;
			return $author$project$Types$Quote$Monthly(
				_Utils_update(
					monthly,
					{
						g3: mapper(monthly.g3)
					}));
		}
	});
var $author$project$Types$Quote$Selection$mapGuarantess = F2(
	function (mapper, cluster) {
		return _Utils_update(
			cluster,
			{
				nF: mapper(cluster.nF)
			});
	});
var $author$project$Types$Quote$Selection$mapClusters = F2(
	function (mapper, _package) {
		return _Utils_update(
			_package,
			{
				mG: mapper(_package.mG)
			});
	});
var $author$project$Types$Quote$Selection$mapPackageGroups = F2(
	function (mapper, section) {
		return _Utils_update(
			section,
			{
				oM: mapper(section.oM)
			});
	});
var $author$project$Types$Quote$Selection$mapPackages = F2(
	function (mapper, group) {
		return _Utils_update(
			group,
			{
				oN: mapper(group.oN)
			});
	});
var $author$project$Types$Quote$Selection$mapSections = F2(
	function (mapper, price) {
		return _Utils_update(
			price,
			{
				pB: mapper(price.pB)
			});
	});
var $author$project$Types$Quote$Selection$updatePackage = function (mapper) {
	return $author$project$Types$Quote$Selection$mapSections(
		$elm$core$List$map(
			$author$project$Types$Quote$Selection$mapPackageGroups(
				$elm$core$List$map(
					$author$project$Types$Quote$Selection$mapPackages(
						$elm$core$List$map(mapper))))));
};
var $author$project$Types$Quote$Selection$updateCluster = function (mapper) {
	return $author$project$Types$Quote$Selection$updatePackage(
		$author$project$Types$Quote$Selection$mapClusters(
			$elm$core$List$map(mapper)));
};
var $author$project$Types$Quote$Selection$updateGuarantee = function (mapper) {
	return $author$project$Types$Quote$Selection$updateCluster(
		$author$project$Types$Quote$Selection$mapGuarantess(
			$elm$core$List$map(mapper)));
};
var $author$project$Guarantees$Helpers$pickOptionList = function (group) {
	if (!group.$) {
		var options = group.a.ku;
		return options;
	} else {
		var option = group.a.g1;
		return _List_fromArray(
			[option]);
	}
};
var $author$project$Types$Quote$Selection$isOptionInOptionsGroup = F2(
	function (optionSlug, group) {
		return $elm_community$maybe_extra$Maybe$Extra$isJust(
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(optionSlug)),
					$author$project$Guarantees$Helpers$pickOptionList(group))));
	});
var $author$project$Types$Quote$Selection$setOptionSelected = F2(
	function (selected, option) {
		return _Utils_update(
			option,
			{pC: selected});
	});
var $author$project$Types$Quote$Selection$multipleOptionMapper = F2(
	function (optionSlug, option) {
		return A2(
			$author$project$Types$Quote$Selection$setOptionSelected,
			_Utils_eq(optionSlug, option.bH),
			option);
	});
var $author$project$Types$Quote$Selection$updateOptionSelectedInGroup = F2(
	function (optionSlug, group) {
		if (A2($author$project$Types$Quote$Selection$isOptionInOptionsGroup, optionSlug, group)) {
			if (!group.$) {
				var list = group.a;
				return $author$project$Types$Quote$ListGroup(
					_Utils_update(
						list,
						{
							ku: A2(
								$elm$core$List$map,
								$author$project$Types$Quote$Selection$multipleOptionMapper(optionSlug),
								list.ku)
						}));
			} else {
				var _boolean = group.a;
				return $author$project$Types$Quote$BooleanGroup(
					_Utils_update(
						_boolean,
						{
							g1: A2($author$project$Types$Quote$Selection$multipleOptionMapper, optionSlug, _boolean.g1)
						}));
			}
		} else {
			return group;
		}
	});
var $author$project$Types$Quote$Selection$updateGuaranteeOptionSelectionPrice = function (optionSlug) {
	return $author$project$Types$Quote$Selection$updateGuarantee(
		$author$project$Types$Quote$Selection$mapGuaranteeOptionGroups(
			$elm$core$List$map(
				$author$project$Types$Quote$Selection$updateOptionSelectedInGroup(optionSlug))));
};
var $author$project$Types$Quote$Selection$mapPriceBlocks = F2(
	function (mapper, guarantee) {
		if (!guarantee.$) {
			var yearly = guarantee.a;
			return $author$project$Types$Quote$Yearly(
				_Utils_update(
					yearly,
					{
						kQ: mapper(yearly.kQ)
					}));
		} else {
			var monthly = guarantee.a;
			return $author$project$Types$Quote$Monthly(
				_Utils_update(
					monthly,
					{
						kQ: mapper(monthly.kQ)
					}));
		}
	});
var $author$project$Guarantees$Helpers$pickGuaranteeSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.pC;
	});
var $author$project$Types$Quote$Selection$pickSelectedGuarantees = function (_v0) {
	var sections = _v0.pB;
	return A2(
		$elm$core$List$filter,
		$author$project$Guarantees$Helpers$pickGuaranteeSelected,
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.nF;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.mG;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oN;
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.oM;
						},
						sections)))));
};
var $author$project$Guarantees$Helpers$pickOptionGroups = function (guarantee) {
	if (!guarantee.$) {
		var optionsGroup = guarantee.a.g3;
		return optionsGroup;
	} else {
		var optionsGroup = guarantee.a.g3;
		return optionsGroup;
	}
};
var $author$project$Types$Quote$Selection$pickSelectedOptionsInGroup = function (optionGroup) {
	return A2(
		$elm$core$List$filter,
		function ($) {
			return $.pC;
		},
		$author$project$Guarantees$Helpers$pickOptionList(optionGroup));
};
var $author$project$Types$Quote$Selection$pickSelectedGuaranteeOptions = function (guarantee) {
	return A2(
		$elm$core$List$concatMap,
		$author$project$Types$Quote$Selection$pickSelectedOptionsInGroup,
		$author$project$Guarantees$Helpers$pickOptionGroups(guarantee));
};
var $author$project$Types$Quote$Selection$pickSelectedGuaranteeOptionsInCluster = function (_v0) {
	var guarantees = _v0.nF;
	return A2($elm$core$List$concatMap, $author$project$Types$Quote$Selection$pickSelectedGuaranteeOptions, guarantees);
};
var $author$project$Types$Quote$Selection$pickSelectedClusterAndGuaranteeOptions = function (cluster) {
	var optionGroups = cluster.g2;
	return _Utils_ap(
		$author$project$Types$Quote$Selection$pickSelectedGuaranteeOptionsInCluster(cluster),
		A2($elm$core$List$concatMap, $author$project$Types$Quote$Selection$pickSelectedOptionsInGroup, optionGroups));
};
var $author$project$Types$Quote$Selection$pickSelectedOptions = function (_v0) {
	var sections = _v0.pB;
	return A2(
		$elm$core$List$concatMap,
		$author$project$Types$Quote$Selection$pickSelectedClusterAndGuaranteeOptions,
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.mG;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oN;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oM;
					},
					sections))));
};
var $author$project$Types$Quote$Selection$setBlockSelected = F2(
	function (condition, block) {
		return _Utils_update(
			block,
			{
				pC: condition(block)
			});
	});
var $author$project$Types$Quote$Selection$isBundleSelectable = F2(
	function (selectedGuarantees, _v0) {
		var requiredGuarantees = _v0.pp;
		return A2(
			$elm$core$List$all,
			function (requiredGuarantee) {
				return A2(
					$elm$core$List$any,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$Helpers$pickGuaranteeSlug,
						$elm$core$Basics$eq(requiredGuarantee)),
					selectedGuarantees);
			},
			requiredGuarantees);
	});
var $author$project$Types$Quote$pickPriceBlocks = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.kQ;
	});
var $author$project$Types$Quote$Selection$containsSelectableBundle = F2(
	function (guarantee, selectedGuarantee) {
		return A2(
			$elm$core$List$any,
			$author$project$Types$Quote$Selection$isBundleSelectable(selectedGuarantee),
			A2(
				$elm$core$List$filterMap,
				function ($) {
					return $.nB;
				},
				$author$project$Types$Quote$pickPriceBlocks(guarantee)));
	});
var $author$project$Types$Quote$Selection$shouldBlockBeSelected = F4(
	function (guarantee, selectedGuarantees, selectedOptions, _v0) {
		var relatedOptions = _v0.pl;
		var guaranteeBundle = _v0.nB;
		var matchOption = function (_v2) {
			var slug = _v2.bH;
			return A2(
				$elm$core$List$any,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					$elm$core$Basics$eq(slug)),
				selectedOptions);
		};
		var matchAllOptions = A2($elm$core$List$all, matchOption, relatedOptions);
		if (!guaranteeBundle.$) {
			var bundle = guaranteeBundle.a;
			return matchAllOptions && A2($author$project$Types$Quote$Selection$isBundleSelectable, selectedGuarantees, bundle);
		} else {
			return matchAllOptions && (!A2($author$project$Types$Quote$Selection$containsSelectableBundle, guarantee, selectedGuarantees));
		}
	});
var $author$project$Types$Quote$Selection$updateSelectedPriceBlocks = function (price) {
	var selectedOptions = $author$project$Types$Quote$Selection$pickSelectedOptions(price);
	var selectedGuarantees = $author$project$Types$Quote$Selection$pickSelectedGuarantees(price);
	var mapper = function (guarantee) {
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Types$Quote$Selection$mapPriceBlocks,
			guarantee,
			$elm$core$List$map(
				$author$project$Types$Quote$Selection$setBlockSelected(
					A3($author$project$Types$Quote$Selection$shouldBlockBeSelected, guarantee, selectedGuarantees, selectedOptions))));
	};
	return A2($author$project$Types$Quote$Selection$updateGuarantee, mapper, price);
};
var $author$project$Types$Quote$Selection$updateGuaranteeOptionSelection = function (optionSlug) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Types$Quote$Selection$updateGuaranteeOptionSelectionPrice(optionSlug),
		$author$project$Types$Quote$Selection$updateSelectedPriceBlocks);
};
var $author$project$Guarantees$Helpers$pickGuaranteeType = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.js;
	});
var $author$project$Guarantees$Helpers$isGuaranteeOptional = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickGuaranteeType,
	$elm$core$Basics$eq(1));
var $author$project$Types$Quote$Selection$setGuaranteeSelected = F2(
	function (selected, guarantee) {
		if (guarantee.$ === 1) {
			var monthly = guarantee.a;
			return $author$project$Types$Quote$Monthly(
				_Utils_update(
					monthly,
					{pC: selected}));
		} else {
			var yearly = guarantee.a;
			return $author$project$Types$Quote$Yearly(
				_Utils_update(
					yearly,
					{pC: selected}));
		}
	});
var $author$project$Types$Quote$Selection$updateGuaranteeSelection = F2(
	function (guaranteeSlug, selected) {
		var mapper = function (guarantee) {
			return A3(
				$author$project$Utils$pipedTernaryOperator,
				A2($author$project$Types$Quote$Selection$setGuaranteeSelected, selected, guarantee),
				guarantee,
				$author$project$Guarantees$Helpers$isGuaranteeOptional(guarantee) && _Utils_eq(
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					guaranteeSlug));
		};
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Types$Quote$Selection$updateGuarantee(mapper),
			$author$project$Types$Quote$Selection$updateSelectedPriceBlocks);
	});
var $author$project$Types$Quote$Selection$initGuaranteeSelected = F2(
	function (_v0, quotePrice) {
		var slug = _v0.bH;
		var options = _v0.ku;
		var newPrice = A3($author$project$Types$Quote$Selection$updateGuaranteeSelection, slug, true, quotePrice);
		return A3(
			$elm$core$List$foldr,
			$author$project$Types$Quote$Selection$updateGuaranteeOptionSelection,
			newPrice,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.bH;
				},
				options));
	});
var $author$project$Types$Quote$Selection$mapClusterOptionGroups = F2(
	function (mapper, cluster) {
		return _Utils_update(
			cluster,
			{
				g2: mapper(cluster.g2)
			});
	});
var $author$project$Types$Quote$Selection$updateClusterOptionSelectionPrice = F2(
	function (optionSlug, price) {
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Types$Quote$Selection$updateCluster,
			price,
			$author$project$Types$Quote$Selection$mapClusterOptionGroups(
				$elm$core$List$map(
					$author$project$Types$Quote$Selection$updateOptionSelectedInGroup(optionSlug))));
	});
var $author$project$Types$Quote$Selection$updateClusterOptionSelection = F2(
	function (optionSlug, price) {
		return $author$project$Types$Quote$Selection$updateSelectedPriceBlocks(
			A2($author$project$Types$Quote$Selection$updateClusterOptionSelectionPrice, optionSlug, price));
	});
var $author$project$Guarantees$Helpers$pickPackagesFromQuotePrice = function (_v0) {
	var sections = _v0.pB;
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.oN;
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.oM;
			},
			sections));
};
var $author$project$Guarantees$Helpers$pickClustersFromQuotePrice = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickPackagesFromQuotePrice,
	$elm$core$List$concatMap(
		function ($) {
			return $.mG;
		}));
var $author$project$Guarantees$Helpers$pickGuarantees = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickClustersFromQuotePrice,
	$elm$core$List$concatMap(
		function ($) {
			return $.nF;
		}));
var $author$project$Guarantees$Helpers$pickGuarantee = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Helpers$pickGuarantees,
		$elm_community$list_extra$List$Extra$find(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug,
				$elm$core$Basics$eq(guaranteeSlug))));
};
var $author$project$Types$Quote$Selection$checkIsGuaranteeSelected = F2(
	function (quotePrice, slug) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			false,
			$author$project$Guarantees$Helpers$pickGuaranteeSelected,
			A2($author$project$Guarantees$Helpers$pickGuarantee, slug, quotePrice));
	});
var $author$project$Utils$fromListToMaybe = function (list) {
	return $elm$core$List$isEmpty(list) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(list);
};
var $author$project$Guarantees$Helpers$pickGuaranteeDependencies = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.nD;
	});
var $author$project$Types$Quote$Selection$isGuaranteeDependenciesSelected = F3(
	function (quotePrice, isClusterSelected, guarantee) {
		return A2(
			$elm$core$Maybe$withDefault,
			isClusterSelected,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$any(
					$elm$core$List$all(
						$author$project$Types$Quote$Selection$checkIsGuaranteeSelected(quotePrice))),
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$map(
						function ($) {
							return $.nF;
						}),
					$author$project$Utils$fromListToMaybe(
						$author$project$Guarantees$Helpers$pickGuaranteeDependencies(guarantee)))));
	});
var $author$project$Guarantees$Helpers$isGuaranteeIncluded = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickGuaranteeType,
	$elm$core$Basics$eq(0));
var $author$project$Types$Quote$Selection$setClusterSelected = F2(
	function (selected, cluster) {
		return _Utils_update(
			cluster,
			{pC: selected});
	});
var $author$project$Utils$mapIf = F2(
	function (condition, mapper) {
		return function (g) {
			return condition(g) ? mapper(g) : g;
		};
	});
var $author$project$Types$Quote$Selection$setClusterGuarantees = F2(
	function (guarantees, cluster) {
		return _Utils_update(
			cluster,
			{nF: guarantees});
	});
var $author$project$Types$Quote$Selection$updateClusterIncludedGuaranteesSelection = F2(
	function (isSelected, cluster) {
		var guarantees = cluster.nF;
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Types$Quote$Selection$setClusterGuarantees,
			cluster,
			A2(
				$elm$core$List$map,
				A2(
					$author$project$Utils$mapIf,
					$author$project$Guarantees$Helpers$isGuaranteeIncluded,
					$author$project$Types$Quote$Selection$setGuaranteeSelected(isSelected)),
				guarantees));
	});
var $author$project$Types$Quote$Selection$updateClusterOptionalGuaranteesSelection = F2(
	function (isSelected, cluster) {
		var guarantees = cluster.nF;
		return A3(
			$author$project$Utils$ternaryOperator,
			isSelected,
			cluster,
			A3(
				$elm_community$basics_extra$Basics$Extra$flip,
				$author$project$Types$Quote$Selection$setClusterGuarantees,
				cluster,
				A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						$author$project$Guarantees$Helpers$isGuaranteeOptional,
						$author$project$Types$Quote$Selection$setGuaranteeSelected(false)),
					guarantees)));
	});
var $author$project$Types$Quote$Selection$updateSingleCluster = F2(
	function (isSelected, cluster) {
		return A2(
			$author$project$Types$Quote$Selection$updateClusterIncludedGuaranteesSelection,
			isSelected,
			A2(
				$author$project$Types$Quote$Selection$updateClusterOptionalGuaranteesSelection,
				isSelected,
				A2($author$project$Types$Quote$Selection$setClusterSelected, isSelected, cluster)));
	});
var $author$project$Types$Quote$Selection$mapClusterWithDependencies = F2(
	function (quotePrice, cluster) {
		var isClusterSelected = cluster.pC && A2(
			$elm$core$List$all,
			A2($author$project$Types$Quote$Selection$isGuaranteeDependenciesSelected, quotePrice, cluster.pC),
			A2($elm$core$List$filter, $author$project$Guarantees$Helpers$isGuaranteeIncluded, cluster.nF));
		return A2($author$project$Types$Quote$Selection$updateSingleCluster, isClusterSelected, cluster);
	});
var $author$project$Types$Quote$Selection$updateDependantCluster = function (quotePrice) {
	var mapper = $author$project$Types$Quote$Selection$mapClusterWithDependencies(quotePrice);
	return A2($author$project$Types$Quote$Selection$updateCluster, mapper, quotePrice);
};
var $author$project$Types$Quote$Selection$setPackageSelected = F2(
	function (selected, _package) {
		return _Utils_update(
			_package,
			{pC: selected});
	});
var $author$project$Types$Quote$Selection$updateSelectedPackages = function (price) {
	var mapper = function (_package) {
		var clusters = _package.mG;
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Types$Quote$Selection$setPackageSelected,
			_package,
			A2(
				$elm$core$List$any,
				function ($) {
					return $.pC;
				},
				clusters));
	};
	return A2($author$project$Types$Quote$Selection$updatePackage, mapper, price);
};
var $author$project$Types$Quote$Selection$updateClusterSelectionInternal = F3(
	function (clusterSlug, selected, checkDependantCluster) {
		var mapper = function (cluster) {
			return A3(
				$author$project$Utils$ternaryOperator,
				_Utils_eq(clusterSlug, cluster.bH),
				A2($author$project$Types$Quote$Selection$updateSingleCluster, selected, cluster),
				cluster);
		};
		var dependantMapper = A3($author$project$Utils$ternaryOperator, checkDependantCluster, $author$project$Types$Quote$Selection$updateDependantCluster, $elm$core$Basics$identity);
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Types$Quote$Selection$updateCluster(mapper),
			A2(
				$elm$core$Basics$composeR,
				dependantMapper,
				A2($elm$core$Basics$composeR, $author$project$Types$Quote$Selection$updateSelectedPackages, $author$project$Types$Quote$Selection$updateSelectedPriceBlocks)));
	});
var $author$project$Types$Quote$Selection$initClusterSelected = F2(
	function (_v0, quotePrice) {
		var slug = _v0.bH;
		var options = _v0.ku;
		var guarantees = _v0.nF;
		var newPrice = A4(
			$author$project$Types$Quote$Selection$updateClusterSelectionInternal,
			slug,
			true,
			false,
			A3(
				$elm$core$List$foldr,
				$author$project$Types$Quote$Selection$updateClusterOptionSelection,
				quotePrice,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.bH;
					},
					options)));
		return A3($elm$core$List$foldr, $author$project$Types$Quote$Selection$initGuaranteeSelected, newPrice, guarantees);
	});
var $author$project$Types$Quote$Selection$updatePackageGroup = function (mapper) {
	return $author$project$Types$Quote$Selection$mapSections(
		$elm$core$List$map(
			$author$project$Types$Quote$Selection$mapPackageGroups(
				$elm$core$List$map(mapper))));
};
var $author$project$Types$Quote$Selection$isPackageInPackageGroup = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return $elm_community$maybe_extra$Maybe$Extra$isJust(
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(packageSlug)),
					packages)));
	});
var $author$project$Types$Quote$Selection$multiPackageMapper = F3(
	function (packageSlug, selected, _package) {
		return _Utils_eq(_package.bH, packageSlug) ? A2($author$project$Types$Quote$Selection$setPackageSelected, selected, _package) : (selected ? A2($author$project$Types$Quote$Selection$setPackageSelected, false, _package) : _package);
	});
var $author$project$Types$Quote$Selection$updateMultiplePackages = F3(
	function (packageSlug, selected, packages) {
		return A2(
			$elm$core$List$map,
			A2($author$project$Types$Quote$Selection$multiPackageMapper, packageSlug, selected),
			packages);
	});
var $author$project$Types$Quote$Selection$updatePackageSelectedInGroup = F3(
	function (packageSlug, selected, group) {
		return A2($author$project$Types$Quote$Selection$isPackageInPackageGroup, packageSlug, group) ? _Utils_update(
			group,
			{
				oN: A3($author$project$Types$Quote$Selection$updateMultiplePackages, packageSlug, selected, group.oN)
			}) : group;
	});
var $author$project$Types$Quote$Selection$updatePackageSelection = F2(
	function (packageSlug, selected) {
		return $author$project$Types$Quote$Selection$updatePackageGroup(
			A2($author$project$Types$Quote$Selection$updatePackageSelectedInGroup, packageSlug, selected));
	});
var $author$project$Types$Quote$Selection$initPackageSelected = F2(
	function (_v0, quotePrice) {
		var slug = _v0.bH;
		var clusters = _v0.mG;
		var newPrice = A3($author$project$Types$Quote$Selection$updatePackageSelection, slug, true, quotePrice);
		return A3($elm$core$List$foldr, $author$project$Types$Quote$Selection$initClusterSelected, newPrice, clusters);
	});
var $author$project$Types$Quote$Selection$initPriceSelectionFromSaveConfiguration = F2(
	function (_v0, quotePrice) {
		var packages = _v0.oN;
		return $author$project$Types$Quote$Selection$updateSelectedPriceBlocks(
			A3($elm$core$List$foldr, $author$project$Types$Quote$Selection$initPackageSelected, quotePrice, packages));
	});
var $author$project$Guarantees$Model$UserSelection$UserSelection = $elm$core$Basics$identity;
var $author$project$Guarantees$Model$UserSelection$create = F3(
	function (issuingCompany, paymentFrequency, quoteId) {
		return {b9: issuingCompany, w: $elm$core$Maybe$Nothing, oN: _List_Nil, ct: paymentFrequency, cy: quoteId};
	});
var $author$project$Guarantees$Helpers$createPackageExclusion = F2(
	function (group, _v0) {
		var slug = _v0.bH;
		return _Utils_Tuple2(
			slug,
			A2(
				$elm$core$List$filter,
				$elm$core$Basics$neq(slug),
				group));
	});
var $author$project$Guarantees$Helpers$createExclusion = function (price) {
	var groups = A2(
		$elm$core$List$concatMap,
		function (packages) {
			var packagesSlug = A2(
				$elm$core$List$map,
				function ($) {
					return $.bH;
				},
				packages);
			return A2(
				$elm$core$List$map,
				$author$project$Guarantees$Helpers$createPackageExclusion(packagesSlug),
				packages);
		},
		A2(
			$elm$core$List$map,
			function ($) {
				return $.oN;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oM;
				},
				price.pB)));
	return $elm$core$Dict$fromList(groups);
};
var $author$project$Guarantees$Model$UserSelection$Package$AlwaysIncluded = 1;
var $author$project$Guarantees$Model$UserSelection$Package$AnyOf = 0;
var $author$project$Guarantees$Helpers$mapRule = function (rule) {
	if (!rule) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Guarantees$Helpers$mapPackageInclusionRule = F2(
	function (price, _package) {
		return A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.le;
				},
				$author$project$Guarantees$Helpers$mapRule),
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.oN;
					},
					$elm$core$List$member(_package.bH)),
				price.nQ));
	});
var $author$project$Guarantees$Model$UserSelection$Guarantee$BooleanGroup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Guarantees$Model$UserSelection$Guarantee$ListGroup = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$Helpers$mapToGuaranteeOptionValue = function (_v0) {
	var key = _v0.fR;
	var value = _v0.p8;
	return {fR: key, p8: value};
};
var $author$project$Guarantees$Helpers$mapToGuaranteeOption = F4(
	function (selected, type_, coverage, _v0) {
		var slug = _v0.bH;
		var values = _v0.hZ;
		return {
			ap: coverage,
			pC: selected,
			bH: slug,
			ao: type_,
			hZ: A2($elm$core$List$map, $author$project$Guarantees$Helpers$mapToGuaranteeOptionValue, values)
		};
	});
var $author$project$Guarantees$Helpers$mapToBooleanOptionGroup = function (booleanOption) {
	return {
		ap: booleanOption.ap,
		n6: booleanOption.n6,
		dX: booleanOption.dX,
		g1: A4($author$project$Guarantees$Helpers$mapToGuaranteeOption, true, booleanOption.ao, booleanOption.ap, booleanOption.g1),
		ao: booleanOption.ao
	};
};
var $author$project$Guarantees$Helpers$mapToListOptionsGroup = function (listOptions) {
	return {
		ap: listOptions.ap,
		n6: listOptions.n6,
		dX: listOptions.dX,
		ku: A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, option) {
					return A4($author$project$Guarantees$Helpers$mapToGuaranteeOption, !idx, listOptions.ao, listOptions.ap, option);
				}),
			listOptions.ku),
		ao: listOptions.ao
	};
};
var $author$project$Guarantees$Helpers$mapToOptionGroups = function (group) {
	if (!group.$) {
		var listOptions = group.a;
		return $author$project$Guarantees$Model$UserSelection$Guarantee$ListGroup(
			$author$project$Guarantees$Helpers$mapToListOptionsGroup(listOptions));
	} else {
		var booleanOption = group.a;
		return $author$project$Guarantees$Model$UserSelection$Guarantee$BooleanGroup(
			$author$project$Guarantees$Helpers$mapToBooleanOptionGroup(booleanOption));
	}
};
var $author$project$Guarantees$Model$UserSelection$Guarantee$Montly = 1;
var $author$project$Guarantees$Model$UserSelection$Guarantee$Yearly = 0;
var $author$project$Guarantees$Helpers$mapToUserSelectionGuaranteeFrequencyType = function (guarantee) {
	if (!guarantee.$) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Guarantees$Model$UserSelection$Guarantee$Excluded = 2;
var $author$project$Guarantees$Model$UserSelection$Guarantee$Optional = 1;
var $author$project$Guarantees$Helpers$mapToUserSelectionGuaranteeType = function (guaranteeType) {
	switch (guaranteeType) {
		case 0:
			return 0;
		case 2:
			return 2;
		default:
			return 1;
	}
};
var $author$project$Guarantees$Helpers$pickGuaranteeLabel = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.n6;
	});
var $author$project$Guarantees$Helpers$pickGuaranteeOptionGroups = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.g3;
	});
var $author$project$Guarantees$Helpers$mapToUserSelectionGuarantee = function (guarantee) {
	var guaranteeType = $author$project$Guarantees$Helpers$mapToUserSelectionGuaranteeType(
		$author$project$Guarantees$Helpers$pickGuaranteeType(guarantee));
	return {
		nE: $author$project$Guarantees$Helpers$mapToUserSelectionGuaranteeFrequencyType(guarantee),
		js: guaranteeType,
		n6: $author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee),
		g2: A2(
			$elm$core$List$map,
			$author$project$Guarantees$Helpers$mapToOptionGroups,
			$author$project$Guarantees$Helpers$pickGuaranteeOptionGroups(guarantee)),
		pC: !guaranteeType,
		bH: $author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)
	};
};
var $author$project$Guarantees$Helpers$mapToUserSelectionCluster = function (cluster) {
	return {
		nF: A2($elm$core$List$map, $author$project$Guarantees$Helpers$mapToUserSelectionGuarantee, cluster.nF),
		g2: A2($elm$core$List$map, $author$project$Guarantees$Helpers$mapToOptionGroups, cluster.g2),
		pC: false,
		bH: cluster.bH
	};
};
var $author$project$Guarantees$Helpers$mapToUserSelectionPackage = F3(
	function (price, exclusions, _package) {
		var inclusionRules = A2($author$project$Guarantees$Helpers$mapPackageInclusionRule, price, _package);
		var isPackageSelected = A2($elm$core$List$member, 1, inclusionRules);
		return {
			mG: A2($elm$core$List$map, $author$project$Guarantees$Helpers$mapToUserSelectionCluster, _package.mG),
			ni: A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Dict$get, _package.bH, exclusions)),
			nR: inclusionRules,
			pC: isPackageSelected,
			bH: _package.bH
		};
	});
var $author$project$Guarantees$Model$UserSelection$setPackages = F2(
	function (packages, _v0) {
		var userSelection = _v0;
		return _Utils_update(
			userSelection,
			{oN: packages});
	});
var $author$project$Guarantees$Helpers$initSelectionFromPrice = F2(
	function (price, quoteId) {
		var packages = A2(
			$elm$core$List$map,
			A2(
				$author$project$Guarantees$Helpers$mapToUserSelectionPackage,
				price,
				$author$project$Guarantees$Helpers$createExclusion(price)),
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oN;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oM;
					},
					price.pB)));
		var initialSelection = A3($author$project$Guarantees$Model$UserSelection$create, price.b9.mM, 0, quoteId);
		return A2($author$project$Guarantees$Model$UserSelection$setPackages, packages, initialSelection);
	});
var $author$project$Guarantees$Model$UserSelection$setPackageAsSelected = F2(
	function (isSelected, _package) {
		return _Utils_update(
			_package,
			{pC: isSelected});
	});
var $author$project$Guarantees$Model$UserSelection$setGuaranteeAsSelected = F2(
	function (isSelected, guarantee) {
		return _Utils_update(
			guarantee,
			{pC: isSelected});
	});
var $author$project$Guarantees$Model$UserSelection$toggleGuaranteeInCluster = F3(
	function (guaranteeSlug, selected, cluster) {
		return _Utils_update(
			cluster,
			{
				nF: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(guaranteeSlug)),
						$author$project$Guarantees$Model$UserSelection$setGuaranteeAsSelected(selected)),
					cluster.nF)
			});
	});
var $author$project$Guarantees$Model$UserSelection$toggleGuaranteeInPackage = F4(
	function (clusterSlug, guaranteeSlug, selected, _package) {
		return _Utils_update(
			_package,
			{
				mG: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(clusterSlug)),
						A2($author$project$Guarantees$Model$UserSelection$toggleGuaranteeInCluster, guaranteeSlug, selected)),
					_package.mG)
			});
	});
var $author$project$Guarantees$Model$UserSelection$selectGuaranteeInPackage = F4(
	function (clusterSlug, guaranteeSlug, packageSlug, _v0) {
		var selection = _v0;
		return _Utils_update(
			selection,
			{
				oN: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(packageSlug)),
						A2(
							$elm$core$Basics$composeL,
							A3($author$project$Guarantees$Model$UserSelection$toggleGuaranteeInPackage, clusterSlug, guaranteeSlug, true),
							$author$project$Guarantees$Model$UserSelection$setPackageAsSelected(true))),
					selection.oN)
			});
	});
var $author$project$Guarantees$Update$Helper$initSelectionFromGuaranteeSelected = F4(
	function (_package, cluster, guarantee, userSelection) {
		return A4($author$project$Guarantees$Model$UserSelection$selectGuaranteeInPackage, cluster.bH, guarantee.bH, _package.bH, userSelection);
	});
var $author$project$Guarantees$Update$Helper$initSelectionFromClusterSelected = F3(
	function (_package, cluster, userSelection) {
		return A3(
			$elm$core$List$foldl,
			A2($author$project$Guarantees$Update$Helper$initSelectionFromGuaranteeSelected, _package, cluster),
			userSelection,
			cluster.nF);
	});
var $author$project$Guarantees$Update$Helper$initSelectionFromPackageSelected = F2(
	function (_package, userSelection) {
		return A3(
			$elm$core$List$foldl,
			$author$project$Guarantees$Update$Helper$initSelectionFromClusterSelected(_package),
			userSelection,
			_package.mG);
	});
var $author$project$Guarantees$Model$UserSelection$setOriginalSaveId = F2(
	function (originalSaveId, _v0) {
		var userSelection = _v0;
		return _Utils_update(
			userSelection,
			{
				w: $elm$core$Maybe$Just(originalSaveId)
			});
	});
var $author$project$Guarantees$Update$Helper$initSingleSelectionFromSave = F2(
	function (save, userSelection) {
		var configuration = save.mO;
		return A2(
			$author$project$Guarantees$Model$UserSelection$setOriginalSaveId,
			save.nM,
			A3($elm$core$List$foldl, $author$project$Guarantees$Update$Helper$initSelectionFromPackageSelected, userSelection, configuration.oN));
	});
var $author$project$Guarantees$Update$Helper$initSelectionFromSave = function (save) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$map,
			$author$project$Guarantees$Model$UserSelection$setOriginalSaveId(save.nM),
			A2(
				$elm$core$List$map,
				$author$project$Guarantees$Update$Helper$initSingleSelectionFromSave(save),
				A2(
					$elm$core$List$map,
					A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Helpers$initSelectionFromPrice, save.pd.nM),
					A2(
						$elm$core$List$filter,
						function (price) {
							return _Utils_eq(price.b9.mM, save.mO.b9);
						},
						save.pd.kR)))));
};
var $author$project$Guarantees$Update$Helper$maybeRedirectForStatus = F2(
	function (_v0, model) {
		var status = _v0.hK;
		var id = _v0.nM;
		switch (status.$) {
			case 0:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SavePurchased),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
			case 2:
				var substatus = status.a.pQ;
				switch (substatus) {
					case 0:
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							$author$project$Routing$NotAvailable(
								$author$project$NotAvailable$Model$SaveExpired(
									$elm$core$Maybe$Just(id))),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
					case 2:
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SaveNotPurchasable),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
					default:
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SaveNotPurchasable),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
				}
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$Guarantees$Helpers$pickSelectedPrice = function (save) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (price) {
				return _Utils_eq(price.b9.mM, save.mO.b9);
			},
			save.pd.kR));
};
var $author$project$Types$pickIntermediariUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.jG;
	});
var $author$project$Types$pickPrimaUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.kS;
	});
var $author$project$Utils$UpdateReturn$newUrl = F2(
	function (url, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				fT: $elm$core$Maybe$Just(url)
			});
	});
var $author$project$Utils$UpdateReturn$withNewUrl = F2(
	function (url, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$newUrl, url, ur));
	});
var $author$project$Utils$Auth$redirectNotAuthenticated = F2(
	function (error, _v0) {
		var model = _v0.a;
		var flags = model.nv;
		var userType = model.lR;
		var cmd = _v0.b;
		var ur = _v0.c;
		return function () {
			if (!error.$) {
				var errors = error.b;
				var unauthorizedErrors = A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.of;
						},
						$elm$core$Basics$eq('unauthorized')),
					errors);
				if (!unauthorizedErrors.b) {
					return $elm$core$Basics$identity;
				} else {
					if (userType === 1) {
						return $author$project$Utils$UpdateReturn$withNewUrl(
							$author$project$Types$pickIntermediariUrl(flags));
					} else {
						return $author$project$Utils$UpdateReturn$withNewUrl(
							$author$project$Types$pickPrimaUrl(flags) + '/reserved/login');
					}
				}
			} else {
				return $elm$core$Basics$identity;
			}
		}()(
			_Utils_Tuple3(model, cmd, ur));
	});
var $author$project$Checkout$Model$isStandardSave = A2(
	$elm$core$Basics$composeR,
	$author$project$Checkout$Model$getSaveType,
	$elm$core$Basics$eq(0));
var $author$project$Checkout$Update$Helper$isValidRange = F3(
	function (effectiveDate, saveExpireDate, today) {
		return A3($justinmimbs$date$Date$isBetween, today, saveExpireDate, effectiveDate);
	});
var $author$project$Checkout$Update$Helper$redirectOnMissingEffectiveDate = function (_v0) {
	var model = _v0.a;
	var order = model.oK;
	var currentDate = model.iH;
	var quotationType = model.pc;
	var cmd = _v0.b;
	var updateReturn = _v0.c;
	var _v1 = _Utils_Tuple3(
		$author$project$Checkout$Model$isPaymentStep(model) && $author$project$Checkout$Model$isStandardSave(model),
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.m8;
			},
			order),
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.py;
			},
			order));
	_v1$2:
	while (true) {
		if (_v1.a && (!_v1.b.$)) {
			if (_v1.b.a.$ === 1) {
				if (!_v1.c.$) {
					var _v2 = _v1.b.a;
					var save = _v1.c.a;
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A3($author$project$Routing$Checkout, quotationType, save.nM, 1),
						_Utils_Tuple3(model, cmd, updateReturn));
				} else {
					break _v1$2;
				}
			} else {
				if (!_v1.c.$) {
					var effectiveDate = _v1.b.a.a;
					var save = _v1.c.a;
					var saveExpiration = A2(
						$elm$core$Maybe$map,
						$author$project$Utils$Time$convertPosixToDate,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.lh;
							},
							order));
					var valid = A2(
						$elm$core$Maybe$withDefault,
						false,
						A3(
							$elm$core$Maybe$map2,
							$author$project$Checkout$Update$Helper$isValidRange(
								$author$project$Utils$Time$convertPosixToDate(effectiveDate)),
							saveExpiration,
							currentDate));
					return valid ? _Utils_Tuple3(model, cmd, updateReturn) : A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A3($author$project$Routing$Checkout, quotationType, save.nM, 1),
						_Utils_Tuple3(model, cmd, updateReturn));
				} else {
					break _v1$2;
				}
			}
		} else {
			break _v1$2;
		}
	}
	return _Utils_Tuple3(model, cmd, updateReturn);
};
var $author$project$Checkout$Update$Helper$setServerSideError = F2(
	function (errorDescription, model) {
		var serverErrorEvent = {mH: 0, of: errorDescription};
		return _Utils_update(
			model,
			{
				b0: $elm$core$Maybe$Just(
					$author$project$Checkout$Model$BraintreeModel$ServerSideError(serverErrorEvent))
			});
	});
var $author$project$Checkout$Update$Helper$updateFromOrderResponse = F2(
	function (orderResponse, model) {
		var getSelectedDate = function (productType) {
			if (!productType.$) {
				return $elm$core$Basics$identity;
			} else {
				return $elm_community$maybe_extra$Maybe$Extra$orElse(model.iH);
			}
		};
		switch (orderResponse.$) {
			case 3:
				if (!orderResponse.a.$) {
					var order = orderResponse.a.a;
					var effectiveDate = order.m8;
					var save = order.py;
					var saveExpiration = order.lh;
					var selectedDate = A2(
						getSelectedDate,
						save.kX,
						A2($elm$core$Maybe$map, $author$project$Utils$Time$convertPosixToDate, effectiveDate));
					return $author$project$Checkout$Update$Helper$redirectOnMissingEffectiveDate(
						A2(
							$author$project$Guarantees$Update$Helper$maybeRedirectForStatus,
							save,
							_Utils_update(
								model,
								{
									m8: $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$fromMaybeDate(selectedDate),
									m9: A2(
										$elm$core$Maybe$map,
										A2(
											$author$project$Checkout$Update$Helper$initEffectiveDateDatePicker,
											$author$project$Utils$Time$convertPosixToDate(saveExpiration),
											selectedDate),
										model.iH),
									oK: $elm$core$Maybe$Just(order),
									g4: orderResponse,
									k5: A2(
										$elm$core$Maybe$map,
										$author$project$Types$Quote$Selection$initPriceSelectionFromSaveConfiguration(save.mO),
										$author$project$Guarantees$Helpers$pickSelectedPrice(save)),
									lQ: $author$project$Guarantees$Update$Helper$initSelectionFromSave(save)
								})));
				} else {
					var errors = orderResponse.a.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$Checkout$Update$Helper$updateBrainTreeModel,
								$author$project$Checkout$Update$Helper$setServerSideError(
									A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										'',
										function ($) {
											return $.m2;
										},
										$elm$core$List$head(errors))),
								model)));
				}
			case 2:
				var err = orderResponse.a;
				return A2(
					$author$project$Utils$Auth$redirectNotAuthenticated,
					err,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{g4: orderResponse}),
						$elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Checkout$Update$Helper$updateFromSaveResponse = F2(
	function (saveResponse, model) {
		switch (saveResponse.$) {
			case 3:
				if (!saveResponse.a.$) {
					var save = saveResponse.a.a;
					return A2(
						$author$project$Guarantees$Update$Helper$maybeRedirectForStatus,
						save,
						_Utils_update(
							model,
							{hq: saveResponse}));
				} else {
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SaveNotPurchasable),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
				}
			case 2:
				var err = saveResponse.a;
				return A2(
					$author$project$Utils$Auth$redirectNotAuthenticated,
					err,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{hq: saveResponse}),
						$elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Checkout$Update$Helper$updateFromSetEffectiveDateResponse = F2(
	function (setEffectiveDateResponse, model) {
		if ((setEffectiveDateResponse.$ === 3) && (!setEffectiveDateResponse.a.$)) {
			var order = setEffectiveDateResponse.a.a;
			return _Utils_update(
				model,
				{
					oK: $elm$core$Maybe$Just(order),
					lq: setEffectiveDateResponse
				});
		} else {
			return _Utils_update(
				model,
				{lq: setEffectiveDateResponse});
		}
	});
var $author$project$Types$enablePolling = function (pollingConfig) {
	return _Utils_update(
		pollingConfig,
		{na: true});
};
var $author$project$Types$incrementPollingCount = function (pollingConfig) {
	var requestCount = pollingConfig.po;
	return _Utils_update(
		pollingConfig,
		{po: requestCount + 1});
};
var $krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $krisajenkins$remotedata$RemoteData$isNotAsked = function (data) {
	if (!data.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Checkout$Update$Helper$updateInsurancePollingConfig = function (model) {
	var insurancePollingConfig = model.nW;
	var insuranceResponse = model.jB;
	var isSuccessfulResponse = function () {
		if ((insuranceResponse.$ === 3) && (!insuranceResponse.a.$)) {
			return true;
		} else {
			return false;
		}
	}();
	return $krisajenkins$remotedata$RemoteData$isNotAsked(insuranceResponse) ? _Utils_update(
		model,
		{
			nW: $author$project$Types$incrementPollingCount(
				$author$project$Types$enablePolling(insurancePollingConfig))
		}) : ((isSuccessfulResponse || (_Utils_cmp(insurancePollingConfig.po, insurancePollingConfig.oe) > -1)) ? _Utils_update(
		model,
		{
			nW: $author$project$Types$disablePolling(insurancePollingConfig)
		}) : ($krisajenkins$remotedata$RemoteData$isLoading(insuranceResponse) ? model : _Utils_update(
		model,
		{
			nW: $author$project$Types$incrementPollingCount(insurancePollingConfig)
		})));
};
var $author$project$Checkout$Update$Helper$updateInsuranceResponse = F2(
	function (insuranceQueryResponse, model) {
		return _Utils_update(
			model,
			{jB: insuranceQueryResponse});
	});
var $author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpenWithBool = F2(
	function (isOpen, model) {
		return _Utils_update(
			model,
			{n1: isOpen});
	});
var $author$project$Checkout$Update$Helper$updatePaymentMethod = F2(
	function (method, model) {
		return _Utils_update(
			model,
			{
				oS: $elm$core$Maybe$Just(method)
			});
	});
var $author$project$Checkout$Update$Helper$setIsCreditCardMethodReady = F2(
	function (isReady, btm) {
		return _Utils_update(
			btm,
			{jI: isReady});
	});
var $author$project$Checkout$Update$Helper$setIsPayPalMethodReady = F2(
	function (isReady, btm) {
		return _Utils_update(
			btm,
			{jQ: isReady});
	});
var $author$project$Checkout$Update$Helper$updatePaymentMethodsStatus = F2(
	function (paymentMethod, isReady) {
		switch (paymentMethod) {
			case 0:
				return $elm$core$Basics$identity;
			case 1:
				return $author$project$Checkout$Update$Helper$updateBrainTreeModel(
					$author$project$Checkout$Update$Helper$setIsCreditCardMethodReady(isReady));
			default:
				return $author$project$Checkout$Update$Helper$updateBrainTreeModel(
					$author$project$Checkout$Update$Helper$setIsPayPalMethodReady(isReady));
		}
	});
var $author$project$Checkout$Update$Helper$updateQuotationType = F2(
	function (quotationType, model) {
		return _Utils_update(
			model,
			{pc: quotationType});
	});
var $author$project$Checkout$Update$Helper$updateSaveId = F2(
	function (saveId, model) {
		return _Utils_update(
			model,
			{
				pz: $elm$core$Maybe$Just(saveId)
			});
	});
var $author$project$Checkout$Model$GotSetEffectiveDate = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$PostEffectiveDate = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$CheckoutSetEffectiveDateOrderResult = function (a) {
	return {$: 5, a: a};
};
var $author$project$Gql$Vianello$Union$SetOrderEffectiveDateResult$Fragments = F2(
	function (onOrder, onSetOrderEffectiveDateErrors) {
		return {gv: onOrder, gY: onSetOrderEffectiveDateErrors};
	});
var $author$project$Checkout$Model$SetEffectiveDateErrors = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$SetOrderError$field = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'field',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$SetOrderError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Order$effectiveDateErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SetOrderError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$SetOrderError$field,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$OrderError)));
var $author$project$Gql$Vianello$Object$SetOrderEffectiveDateErrors$errors = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'errors',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Checkout$Api$Order$effectiveDateErrorsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$SetOrderEffectiveDateErrors$errors($author$project$Checkout$Api$Order$effectiveDateErrorSelection),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$SetEffectiveDateErrors));
var $author$project$Checkout$Model$SetEffectiveDateOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Api$Order$effectiveFateOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$Order$orderSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$SetEffectiveDateOk));
var $author$project$Gql$Vianello$Union$SetOrderEffectiveDateResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Order', selections____.gv),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SetOrderEffectiveDateErrors', selections____.gY)
			]));
};
var $author$project$Checkout$Api$Order$effectiveDateSelection = $author$project$Gql$Vianello$Union$SetOrderEffectiveDateResult$fragments(
	A2($author$project$Gql$Vianello$Union$SetOrderEffectiveDateResult$Fragments, $author$project$Checkout$Api$Order$effectiveFateOkSelection, $author$project$Checkout$Api$Order$effectiveDateErrorsSelection));
var $author$project$Gql$Vianello$Mutation$setEffectiveDateOrder = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'setEffectiveDateOrder',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'orderId',
					requiredArgs____.kw,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'effectiveDate',
					requiredArgs____.m8,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.mK;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Checkout$Api$Order$setEffectiveDateMutation = F2(
	function (orderId, effectiveDate) {
		return A2(
			$author$project$Gql$Vianello$Mutation$setEffectiveDateOrder,
			{m8: effectiveDate, kw: orderId},
			$author$project$Checkout$Api$Order$effectiveDateSelection);
	});
var $author$project$Checkout$Api$Order$setEffectiveDateOrderMutation = F2(
	function (saveId, effectiveDate) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
			$author$project$Api$CheckoutSetEffectiveDateOrderResult,
			A2($author$project$Checkout$Api$Order$setEffectiveDateMutation, saveId, effectiveDate));
	});
var $author$project$Checkout$Api$setEffectiveDateOrderMutation = F2(
	function (saveId, effectiveDate) {
		return A2(
			$author$project$Api$buildMutation,
			$author$project$Api$CheckoutSource(
				$author$project$Api$PostEffectiveDate($author$project$Checkout$Model$GotSetEffectiveDate)),
			A2($author$project$Checkout$Api$Order$setEffectiveDateOrderMutation, saveId, effectiveDate));
	});
var $author$project$Checkout$Update$Helper$pickOrder = F3(
	function (_v0, updateReturn, parsedDate) {
		var orderResponse = _v0.g4;
		var _v1 = _Utils_Tuple2(orderResponse, parsedDate);
		if (((_v1.a.$ === 3) && (!_v1.a.a.$)) && (!_v1.b.$)) {
			var order_ = _v1.a.a.a;
			var date = _v1.b.a;
			return A2(
				$author$project$Utils$UpdateReturn$withQuery,
				A2($author$project$Checkout$Api$setEffectiveDateOrderMutation, order_.kw, date),
				updateReturn);
		} else {
			return updateReturn;
		}
	});
var $author$project$Checkout$Model$pickStringDateFromDatePickerDate = function (datePickerDate) {
	if (!datePickerDate.$) {
		var date = datePickerDate.a;
		return $elm$core$Maybe$Just(
			$justinmimbs$date$Date$toIsoString(date));
	} else {
		var maybeString = datePickerDate.a;
		return maybeString;
	}
};
var $author$project$Checkout$Update$Helper$withChangeEffectiveDateMutation = F2(
	function (model, updateReturn) {
		return A3(
			$author$project$Checkout$Update$Helper$pickOrder,
			model,
			updateReturn,
			A2(
				$elm$core$Maybe$andThen,
				A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromIsoString, $elm$core$Result$toMaybe),
				$author$project$Checkout$Model$pickStringDateFromDatePickerDate(model.m8)));
	});
var $author$project$Utils$UpdateReturn$changeModalState = F2(
	function (isModalOpen, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				hW: $elm$core$Maybe$Just(isModalOpen)
			});
	});
var $author$project$Utils$UpdateReturn$withChangeModalState = F2(
	function (isModalOpen, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$changeModalState, isModalOpen, ur));
	});
var $author$project$Checkout$Update$update = F2(
	function (msg, model) {
		var saveId = model.pz;
		var clientConfig = model.iv;
		switch (msg.$) {
			case 0:
				var quotationType = msg.a;
				var saveId_ = msg.b;
				var step = msg.c;
				return A2(
					$author$project$Checkout$Update$Helper$saveQuery,
					saveId_,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Checkout$Update$Helper$maybeClientConfigRequest(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										A2(
										$elm$core$Task$perform,
										$author$project$Checkout$Model$LazyInit(saveId_),
										$justinmimbs$date$Date$today),
										$author$project$Ports$pushUserRole(0)
									]),
								A2(
									$author$project$Checkout$Update$Helper$updateSaveId,
									saveId_,
									A2(
										$author$project$Checkout$Update$Helper$updateCurrentStep,
										step,
										A2($author$project$Checkout$Update$Helper$updateQuotationType, quotationType, model)))))));
			case 1:
				var saveId_ = msg.a;
				var today = msg.b;
				return A2(
					$author$project$Checkout$Update$Helper$createOrderMutation,
					saveId_,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$Checkout$Update$Helper$updateCurrentDate, today, model))));
			case 10:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 12:
				return A2(
					$author$project$Checkout$Update$Helper$withChangeEffectiveDateMutation,
					model,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 2:
				var config = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Checkout$Update$BraintreeHelper$maybeInitBraintree,
						config,
						$author$project$Utils$withoutCommands(
							A2($author$project$Checkout$Update$Helper$updateClientConfigResponse, config, model))));
			case 3:
				var maybePaymentMethod = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A4(
							$elm_community$maybe_extra$Maybe$Extra$unwrap,
							$elm$core$Basics$identity,
							$author$project$Checkout$Update$Helper$updatePaymentMethod,
							maybePaymentMethod,
							A2($author$project$Checkout$Update$Helper$updateBrainTreeModel, $author$project$Checkout$Update$BraintreeHelper$removeError, model))));
			case 4:
				var method = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2(
								$elm$core$Task$perform,
								$author$project$Checkout$Model$PayWithCurrentDate(method),
								$justinmimbs$date$Date$today)
							]),
						model));
			case 5:
				var method = msg.a;
				var currentDate = msg.b;
				var updatedModel = A2($author$project$Checkout$Update$Helper$updateCurrentDate, currentDate, model);
				var canPay = $author$project$Checkout$Update$isEffectiveDateValid(updatedModel) || $author$project$Checkout$Update$isEffectiveDateValidOnRenewal(updatedModel);
				if (canPay) {
					switch (method) {
						case 1:
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Checkout$Update$BraintreeHelper$payWithCard(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										$author$project$Checkout$Model$BraintreeModel$setAppStatus(3),
										updatedModel)));
						case 2:
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Checkout$Update$BraintreeHelper$payWithPaypal(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										$author$project$Checkout$Model$BraintreeModel$setAppStatus(3),
										updatedModel)));
						default:
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										A2(
											$elm$core$Basics$composeR,
											$author$project$Checkout$Update$BraintreeHelper$updateError($elm$core$Maybe$Nothing),
											$author$project$Checkout$Model$BraintreeModel$setAppStatus(3)),
										A2($author$project$Checkout$Update$Helper$updatePaymentMethod, method, updatedModel))));
					}
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(updatedModel));
				}
			case 6:
				var paymentMethod = msg.a;
				var isReady = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A3($author$project$Checkout$Update$Helper$updatePaymentMethodsStatus, paymentMethod, isReady, model)));
			case 7:
				var paymentResponse = msg.a;
				switch (paymentResponse.$) {
					case 0:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					case 2:
						var error = paymentResponse.a;
						if (!error.$) {
							var graphqlErrors = error.b;
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										$author$project$Checkout$Update$BraintreeHelper$setServerSideError(
											A3(
												$elm_community$maybe_extra$Maybe$Extra$unwrap,
												'',
												function ($) {
													return $.of;
												},
												$elm$core$List$head(graphqlErrors))),
										model)));
						} else {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										$author$project$Checkout$Update$BraintreeHelper$setServerSideError('HTTP Error'),
										model)));
						}
					default:
						var response = paymentResponse.a;
						if (!response.$) {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$send($author$project$Checkout$Model$GetInsurance)
										]),
									model));
						} else {
							var paymentError = response.a;
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$Checkout$Update$Helper$updateBrainTreeModel,
										A2(
											$elm$core$Basics$composeR,
											$author$project$Checkout$Model$BraintreeModel$setError(
												$elm$core$Maybe$Just(
													$author$project$Checkout$Model$BraintreeModel$ServerSideError(
														$author$project$Checkout$Update$Helper$paymentErrorToBackEndEvent(paymentError)))),
											$author$project$Checkout$Model$BraintreeModel$setAppStatus(4)),
										model)));
						}
				}
			case 8:
				var orderResponse = msg.a;
				return A2(
					$author$project$Checkout$Update$BraintreeHelper$maybeInitBraintreeFromOrderResponse,
					clientConfig,
					$author$project$Checkout$Update$Helper$pushFormDataToDataLayer(
						A2($author$project$Checkout$Update$Helper$updateFromOrderResponse, orderResponse, model)));
			case 14:
				var saveResponse = msg.a;
				return $author$project$Checkout$Update$Helper$pushFormDataToDataLayer(
					A2($author$project$Checkout$Update$Helper$updateFromSaveResponse, saveResponse, model));
			case 9:
				var setEffectiveDateResponse = msg.a;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						$author$project$Routing$NotFound,
						A2(
							$author$project$PrimaElm$Lib$Utils$FP$flip,
							$author$project$Routing$Checkout(
								$author$project$Checkout$Model$pickQuotationType(model)),
							2),
						saveId),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$Checkout$Update$Helper$updateFromSetEffectiveDateResponse, setEffectiveDateResponse, model))));
			case 13:
				if (!msg.a.$) {
					var isSummaryOpen = msg.a.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									$author$project$Utils$toggleBlockScrollingClass(isSummaryOpen)
								]),
							A2(
								$author$project$Checkout$Update$Helper$updateAppOptions,
								$author$project$Checkout$Update$Helper$updateAppOptionsSetSummaryOpen(isSummaryOpen),
								model)));
				} else {
					var _package = msg.a.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									$author$project$Utils$toggleBlockScrollingClass(
									$elm_community$maybe_extra$Maybe$Extra$isJust(_package))
								]),
							A2(
								$author$project$Checkout$Update$Helper$updateAppOptions,
								$author$project$Checkout$Update$Helper$updateAppOptionsSetSelectedPackage(_package),
								model)));
				}
			case 16:
				return $author$project$Checkout$Api$insuranceRequestHandler(
					$author$project$Checkout$Update$Helper$updateInsurancePollingConfig(model));
			case 15:
				var insuranceQueryResponse = msg.a;
				return $author$project$Checkout$Update$Helper$maybeToThankYouPage(
					$author$project$Utils$withoutCommands(
						A2($author$project$Checkout$Update$Helper$updateInsuranceResponse, insuranceQueryResponse, model)));
			case 17:
				var uuid = msg.a;
				var step = msg.b;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A3(
						$author$project$Routing$Checkout,
						$author$project$Checkout$Model$pickQuotationType(model),
						uuid,
						step),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 18:
				var maybeQuoteId = msg.a;
				var maybeSaveId = msg.b;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Modify, maybeQuoteId, maybeSaveId),
					A2(
						$author$project$Utils$UpdateReturn$withChangeModalState,
						false,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Checkout$Model$Toggle(
											$author$project$Checkout$Model$Summary(false)))
									]),
								model))));
			case 11:
				var datePickerEvent = msg.a;
				switch (datePickerEvent.$) {
					case 0:
						var date = datePickerEvent.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Checkout$Update$Helper$updateEffectiveDate, date, model)));
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpenWithBool, false, model)));
					case 3:
						var datePickerMsg = datePickerEvent.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Checkout$Update$Helper$updateEffectiveDateDatePickerByMsg, datePickerMsg, model)));
					case 2:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpenWithBool, false, model)));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Checkout$Update$Helper$updateIsEffectiveDateDatePickerOpenWithBool, true, model)));
				}
			case 19:
				var brainTreeMsg = msg.a;
				return A2($author$project$Checkout$Update$BraintreeUpdate$update, brainTreeMsg, model);
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$CookieBot$setConsent = F2(
	function (con, cookieBot) {
		return _Utils_update(
			cookieBot,
			{c9: con});
	});
var $primait$prima_elm_extra$PrimaUpdate$withoutCmds = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$CookieBot$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var cookieBotConsent = msg.a;
			return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
				A2($author$project$CookieBot$setConsent, cookieBotConsent, model));
		} else {
			return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(model);
		}
	});
var $author$project$Guarantees$Model$CurrentDate = function (a) {
	return {$: 10, a: a};
};
var $author$project$Guarantees$Model$OnToggle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Guarantees$Model$Others = 2;
var $author$project$Guarantees$Model$P = 0;
var $author$project$Guarantees$Model$Q = 1;
var $author$project$Guarantees$Model$Summary = function (a) {
	return {$: 0, a: a};
};
var $author$project$Utils$addCommandWithUpdateReturn = F2(
	function (newCmd, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var updateReturn = _v0.c;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[newCmd, cmd])),
			updateReturn);
	});
var $author$project$Guarantees$Model$UserSelection$changePaymentFrequency = F2(
	function (paymentFrequency, _v0) {
		var selection = _v0;
		return _Utils_update(
			selection,
			{ct: paymentFrequency});
	});
var $author$project$Guarantees$Update$Helper$getBottomBarModel = function (model) {
	return function ($) {
		return $.im;
	}(model);
};
var $author$project$Guarantees$Update$Helper$updateBottomBarModel = F2(
	function (bottomBarModel, model) {
		return _Utils_update(
			model,
			{im: bottomBarModel});
	});
var $author$project$Guarantees$Update$Helper$updateSelectedPackagesSlugs = F2(
	function (packagesSlugsSet, bottomBarModel) {
		return _Utils_update(
			bottomBarModel,
			{ip: packagesSlugsSet});
	});
var $author$project$Guarantees$Update$Helper$clearCartSummarySelectedPackagesSlugs = function (model) {
	return A2(
		$author$project$Guarantees$Update$Helper$updateBottomBarModel,
		A2(
			$author$project$Guarantees$Update$Helper$updateSelectedPackagesSlugs,
			$elm$core$Set$empty,
			$author$project$Guarantees$Update$Helper$getBottomBarModel(model)),
		model);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$close = function (_v0) {
	var state = _v0;
	return _Utils_update(
		state,
		{Z: false});
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Guarantees$Model$mapEditOptionsSelectState = F2(
	function (mapper, ui) {
		return _Utils_update(
			ui,
			{
				ax: mapper(ui.ax)
			});
	});
var $author$project$Guarantees$Model$mapUI = F2(
	function (mapper, model) {
		return _Utils_update(
			model,
			{
				bM: mapper(model.bM)
			});
	});
var $author$project$Guarantees$Model$closeAllEditOptionSelects = $author$project$Guarantees$Model$mapUI(
	$author$project$Guarantees$Model$mapEditOptionsSelectState(
		$elm$core$Dict$map(
			$elm$core$Basics$always($primait$pyxis_components$Prima$Pyxis$Form$Select$close))));
var $author$project$Guarantees$Update$Helper$closeDependenciesDialog = function (model) {
	return _Utils_update(
		model,
		{iN: $author$project$Guarantees$Model$UnsetDependenciesDialog});
};
var $author$project$Guarantees$Update$Helper$getHighlightedMessageFlagPrefix = 'guaranteesHighlightedMessage_';
var $author$project$Guarantees$Update$Helper$getHighlightedMessageFlagName = function (quoteId) {
	return $author$project$Guarantees$Update$Helper$getHighlightedMessageFlagPrefix + ('protezione_locali_' + $danyx23$elm_uuid$Uuid$toString(quoteId));
};
var $author$project$Ports$localStorageItemRequest = _Platform_outgoingPort('localStorageItemRequest', $elm$json$Json$Encode$string);
var $author$project$Guarantees$Update$Helper$getHighlightedMessageFlag = A2($elm$core$Basics$composeR, $author$project$Guarantees$Update$Helper$getHighlightedMessageFlagName, $author$project$Ports$localStorageItemRequest);
var $primait$pyxis_components$Prima$Pyxis$Form$Select$initWithDefault = F2(
	function (defaultSelectedValue, choices) {
		return A4(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$StateConfig,
			$elm$core$Maybe$Just(defaultSelectedValue),
			$elm$core$Maybe$Just(defaultSelectedValue),
			false,
			choices);
	});
var $author$project$Guarantees$Model$mapModalDetailsAccordionStateDictionary = F2(
	function (mapper, ui) {
		var modalDetailsAccordionStates = ui.aS;
		return _Utils_update(
			ui,
			{
				aS: mapper(modalDetailsAccordionStates)
			});
	});
var $author$project$Guarantees$Update$Helper$initializeModalDetailsAccordionState = function (guaranteeFrequency) {
	var guarantee = $author$project$Types$Quote$pickGuarantee(guaranteeFrequency);
	var newModalDetailsAccordionState = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Array$empty,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$map(
					$elm$core$Basics$always(true)),
				$elm$core$Array$fromList),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.mU;
				},
				guarantee.m4)));
	var guaranteeModalDetailsAccordionState = {
		eR: newModalDetailsAccordionState,
		fh: {eS: $elm$core$Array$empty, fI: true},
		jM: true,
		jN: true
	};
	return $author$project$Guarantees$Model$mapUI(
		$author$project$Guarantees$Model$mapModalDetailsAccordionStateDictionary(
			A2($elm$core$Dict$insert, guarantee.bH, guaranteeModalDetailsAccordionState)));
};
var $author$project$Guarantees$Update$Helper$initializeModalEditOptionsAccordionState = F2(
	function (_v0, accordionToOpen) {
		var slug = _v0.bH;
		var guarantees = _v0.nF;
		var isGuaranteeOpen = function (guarantee) {
			if (accordionToOpen.$ === 2) {
				var guaranteeFrequency = accordionToOpen.a;
				return _Utils_eq(
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency));
			} else {
				return false;
			}
		};
		var isClusterOpen = function () {
			switch (accordionToOpen.$) {
				case 0:
					return false;
				case 1:
					return true;
				default:
					var guaranteeFrequency = accordionToOpen.a;
					return !$author$project$Guarantees$Helpers$pickGuaranteeType(guaranteeFrequency);
			}
		}();
		var areGuaranteesOpen = $elm$core$Array$fromList(
			A2(
				$elm$core$List$map,
				isGuaranteeOpen,
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$Helpers$pickGuaranteeType,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Basics$eq(0),
							$elm$core$Basics$not)),
					guarantees)));
		var guaranteeModalDetailsAccordionState = {
			eR: $elm$core$Array$empty,
			fh: {eS: areGuaranteesOpen, fI: isClusterOpen},
			jM: true,
			jN: true
		};
		return $author$project$Guarantees$Model$mapUI(
			$author$project$Guarantees$Model$mapModalDetailsAccordionStateDictionary(
				A2($elm$core$Dict$insert, slug, guaranteeModalDetailsAccordionState)));
	});
var $author$project$Guarantees$Model$mapNavigableModalModel = F2(
	function (mapper, ui) {
		var navigableModalModel = ui.d3;
		return _Utils_update(
			ui,
			{
				d3: mapper(navigableModalModel)
			});
	});
var $author$project$Guarantees$Model$mapUserSelection = F2(
	function (mapper, guaranteeViewConfig) {
		return _Utils_update(
			guaranteeViewConfig,
			{
				lQ: mapper(guaranteeViewConfig.lQ)
			});
	});
var $author$project$Utils$maybeApply = F2(
	function (func, maybeParam) {
		if (!maybeParam.$) {
			var param = maybeParam.a;
			return func(param);
		} else {
			return $elm$core$Basics$identity;
		}
	});
var $author$project$Api$GetQuote = function (a) {
	return {$: 2, a: a};
};
var $author$project$Guarantees$Model$GotQuote = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$GuaranteeSource = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$QuoteQueryResult = function (a) {
	return {$: 2, a: a};
};
var $author$project$Gql$Vianello$Query$quote = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'quote',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.nM,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$GetQuoteResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Quote', selections____.gH),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'QuoteError', selections____.gI)
			]));
};
var $author$project$Types$Quote$QuoteResponseError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Quote$QuoteError = F2(
	function (error, code) {
		return {mH: code, b0: error};
	});
var $author$project$Gql$Vianello$Object$QuoteError$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'code', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gql$Vianello$Object$QuoteError$error = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'error', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Quote$onError = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuoteError$code,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuoteError$error,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$QuoteError)));
var $author$project$Guarantees$Api$Quote$onQuoteErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$onError,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$QuoteResponseError));
var $author$project$Types$Quote$QuoteData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$Api$Quote$onQuoteSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Guarantees$Api$Quote$quoteSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Quote$QuoteData));
var $author$project$Guarantees$Api$Quote$quoteResponseSelection = $author$project$Gql$Vianello$Union$GetQuoteResponse$fragments(
	{gH: $author$project$Guarantees$Api$Quote$onQuoteSelection, gI: $author$project$Guarantees$Api$Quote$onQuoteErrorSelection});
var $author$project$Guarantees$Api$Quote$quoteQuery = function (quoteId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$QuoteQueryResult,
		A2(
			$author$project$Gql$Vianello$Query$quote,
			{nM: quoteId},
			$author$project$Guarantees$Api$Quote$quoteResponseSelection));
};
var $author$project$Guarantees$Api$quoteQuery = function (id) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$GuaranteeSource(
			$author$project$Api$GetQuote($author$project$Guarantees$Model$GotQuote)),
		$author$project$Guarantees$Api$Quote$quoteQuery(id));
};
var $author$project$Guarantees$Model$FetchedSave = function (a) {
	return {$: 5, a: a};
};
var $author$project$Guarantees$Api$saveQuery = function (saveId) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$GuaranteeSource(
			$author$project$Api$GetSave($author$project$Guarantees$Model$FetchedSave)),
		$author$project$Guarantees$Api$Save$saveQuery(saveId));
};
var $author$project$Guarantees$Update$Helper$maybeQuoteOrGetSaveQuery = F2(
	function (quoteId, saveId) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$author$project$Guarantees$Api$quoteQuery(quoteId),
			$author$project$Guarantees$Api$saveQuery,
			saveId);
	});
var $author$project$Guarantees$Model$DependenciesDialog = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$Update$Helper$openDependenciesDialog = F2(
	function (dialogType, model) {
		return _Utils_update(
			model,
			{
				iN: $author$project$Guarantees$Model$DependenciesDialog(dialogType)
			});
	});
var $author$project$Guarantees$Model$pickQuotationType = function (_v0) {
	var quotationType = _v0.pc;
	return quotationType;
};
var $author$project$Guarantees$Model$ClusterDetailsPayload = F3(
	function (cluster, details, price) {
		return {e3: cluster, m4: details, oY: price};
	});
var $author$project$Guarantees$Model$ClusterDetailsPayloadType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$Quote$Selection$pickClusterByClusterSlug = F2(
	function (clusterSlug, _v0) {
		var sections = _v0.pB;
		return A2(
			$elm_community$list_extra$List$Extra$find,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bH;
				},
				$elm$core$Basics$eq(clusterSlug)),
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.mG;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oN;
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.oM;
						},
						sections))));
	});
var $author$project$Guarantees$Update$Helper$generateClusterDetailsPayload = F2(
	function (clusterSlug, model) {
		var maybeCluster = A2(
			$elm$core$Maybe$andThen,
			$author$project$Types$Quote$Selection$pickClusterByClusterSlug(clusterSlug),
			model.k5);
		var maybeDetails = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.mF;
			},
			maybeCluster);
		return A2(
			$elm$core$Maybe$map,
			$author$project$Guarantees$Model$ClusterDetailsPayloadType,
			A4($elm$core$Maybe$map3, $author$project$Guarantees$Model$ClusterDetailsPayload, maybeCluster, maybeDetails, model.k5));
	});
var $author$project$Guarantees$Model$ClusterCardSelection = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Guarantees$View$Helpers$getNotSelectedClusterActionBtnMsg = function (cluster) {
	return $author$project$Guarantees$Model$OnToggle(
		A2($author$project$Guarantees$Model$ClusterCardSelection, cluster, true));
};
var $author$project$Guarantees$Model$OpenDependenciesDialog = function (a) {
	return {$: 19, a: a};
};
var $author$project$Guarantees$Model$RemovingDependenciesDialog = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Guarantees$Helpers$pickGuaranteesDependenciesSlugs = function (guarantee) {
	return A2(
		$elm$core$List$map,
		function ($) {
			return $.nF;
		},
		$author$project$Guarantees$Helpers$pickGuaranteeDependencies(guarantee));
};
var $author$project$Guarantees$View$Helpers$getGuaranteeDependencies = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Helpers$pickGuarantees,
		A2(
			$elm$core$Basics$composeR,
			$elm_community$list_extra$List$Extra$find(
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug,
					$elm$core$Basics$eq(guaranteeSlug))),
			A2($elm_community$maybe_extra$Maybe$Extra$unwrap, _List_Nil, $author$project$Guarantees$Helpers$pickGuaranteesDependenciesSlugs)));
};
var $author$project$Guarantees$View$Helpers$isSelectable = F2(
	function (guaranteeSlug, quotePrice) {
		var dependencies = A2($author$project$Guarantees$View$Helpers$getGuaranteeDependencies, guaranteeSlug, quotePrice);
		var checkIsGuaranteeSelected = function (slug) {
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				false,
				$author$project$Guarantees$Helpers$pickGuaranteeSelected,
				A2($author$project$Guarantees$Helpers$pickGuarantee, slug, quotePrice));
		};
		return $elm$core$List$isEmpty(dependencies) ? true : A2(
			$elm$core$List$any,
			$elm$core$List$all(checkIsGuaranteeSelected),
			dependencies);
	});
var $author$project$Types$Quote$Selection$pickAllClusters = function (_v0) {
	var sections = _v0.pB;
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.mG;
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.oN;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oM;
				},
				sections)));
};
var $author$project$Types$Quote$Selection$pickAllGuarantees = function (price) {
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.nF;
		},
		$author$project$Types$Quote$Selection$pickAllClusters(price));
};
var $author$project$Guarantees$View$Helpers$getDirectInvalidSelectedGuarantees = function (price) {
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Guarantees$View$Helpers$isSelectable, price),
			$elm$core$Basics$not),
		A2(
			$elm$core$List$map,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug,
			A2(
				$elm$core$List$filter,
				$author$project$Guarantees$Helpers$pickGuaranteeSelected,
				$author$project$Types$Quote$Selection$pickAllGuarantees(price))));
};
var $author$project$Types$Quote$Selection$updateIncludedGuaranteeSelection = F2(
	function (guaranteeSlug, selected) {
		var mapper = function (guarantee) {
			return A3(
				$author$project$Utils$pipedTernaryOperator,
				A2($author$project$Types$Quote$Selection$setGuaranteeSelected, selected, guarantee),
				guarantee,
				$author$project$Guarantees$Helpers$isGuaranteeIncluded(guarantee) && _Utils_eq(
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					guaranteeSlug));
		};
		return $author$project$Types$Quote$Selection$updateGuarantee(mapper);
	});
var $author$project$Types$Quote$Selection$multiUpdateIncludedGuaranteeSelection = F3(
	function (guaranteesSlugs, isSelected, price) {
		return A3(
			$elm$core$List$foldl,
			A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Types$Quote$Selection$updateIncludedGuaranteeSelection, isSelected),
			price,
			guaranteesSlugs);
	});
var $author$project$Types$Quote$Selection$multiUpdateOptionalGuaranteeSelection = F3(
	function (guaranteesSlugs, isSelected, price) {
		return A3(
			$elm$core$List$foldl,
			A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Types$Quote$Selection$updateGuaranteeSelection, isSelected),
			price,
			guaranteesSlugs);
	});
var $author$project$Guarantees$View$Helpers$deselectAllInvalidSelectedGuarantees = function (price) {
	deselectAllInvalidSelectedGuarantees:
	while (true) {
		var _v0 = $author$project$Guarantees$View$Helpers$getDirectInvalidSelectedGuarantees(price);
		if (!_v0.b) {
			return price;
		} else {
			var list = _v0;
			var $temp$price = A3(
				$author$project$Types$Quote$Selection$multiUpdateIncludedGuaranteeSelection,
				list,
				false,
				A3($author$project$Types$Quote$Selection$multiUpdateOptionalGuaranteeSelection, list, false, price));
			price = $temp$price;
			continue deselectAllInvalidSelectedGuarantees;
		}
	}
};
var $author$project$Guarantees$View$Helpers$getSelectedGuaranteesDifference = F2(
	function (price1, price2) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug,
				A2(
					$elm$core$Basics$composeR,
					A2(
						$elm_community$basics_extra$Basics$Extra$flip,
						$elm$core$List$member,
						A2(
							$elm$core$List$map,
							$author$project$Guarantees$Helpers$pickGuaranteeSlug,
							$author$project$Types$Quote$Selection$pickSelectedGuarantees(price2))),
					$elm$core$Basics$not)),
			$author$project$Types$Quote$Selection$pickSelectedGuarantees(price1));
	});
var $author$project$Guarantees$View$Helpers$getAllInvalidSelectedGuarantees = function (price) {
	return A2(
		$author$project$Guarantees$View$Helpers$getSelectedGuaranteesDifference,
		price,
		$author$project$Guarantees$View$Helpers$deselectAllInvalidSelectedGuarantees(price));
};
var $author$project$Guarantees$View$Helpers$getGuaranteeDescendantDependencies = F3(
	function (price, guaranteeFrequency, isGuaranteeIncluded) {
		return $author$project$Guarantees$View$Helpers$getAllInvalidSelectedGuarantees(
			A3(
				$author$project$Utils$ternaryOperator,
				isGuaranteeIncluded,
				A3(
					$author$project$Types$Quote$Selection$updateIncludedGuaranteeSelection,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency),
					false,
					price),
				A3(
					$author$project$Types$Quote$Selection$updateGuaranteeSelection,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency),
					false,
					price)));
	});
var $author$project$Guarantees$View$Helpers$getClusterDescendantDependencies = F2(
	function (price, _v0) {
		var guarantees = _v0.nF;
		var firstIncludedGuaranteeFrequency = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$pickGuaranteeType,
					$elm$core$Basics$eq(0)),
				guarantees));
		if (!firstIncludedGuaranteeFrequency.$) {
			var guaranteeFrequency = firstIncludedGuaranteeFrequency.a;
			return A3($author$project$Guarantees$View$Helpers$getGuaranteeDescendantDependencies, price, guaranteeFrequency, true);
		} else {
			return _List_Nil;
		}
	});
var $author$project$Guarantees$View$Helpers$getSelectedClusterActionBtnMsg = F2(
	function (price, cluster) {
		var _v0 = A2($author$project$Guarantees$View$Helpers$getClusterDescendantDependencies, price, cluster);
		if (!_v0.b) {
			return $author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$ClusterCardSelection, cluster, false));
		} else {
			var descendantDependenciesList = _v0;
			return $author$project$Guarantees$Model$OpenDependenciesDialog(
				A3(
					$author$project$Guarantees$Model$RemovingDependenciesDialog,
					A2($author$project$Guarantees$Model$ClusterCardSelection, cluster, false),
					cluster.n6,
					descendantDependenciesList));
		}
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $primait$pyxis_components$Prima$Pyxis$Button$CallOut = 0;
var $primait$pyxis_components$Prima$Pyxis$Button$isCallOut = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Button$Loading = 7;
var $primait$pyxis_components$Prima$Pyxis$Button$isLoading = $elm$core$Basics$eq(7);
var $primait$pyxis_components$Prima$Pyxis$Button$Medium = 0;
var $primait$pyxis_components$Prima$Pyxis$Button$isMedium = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Button$Primary = 1;
var $primait$pyxis_components$Prima$Pyxis$Button$isPrimary = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Button$PrimaryAlt = 2;
var $primait$pyxis_components$Prima$Pyxis$Button$isPrimaryAlt = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Button$Secondary = 3;
var $primait$pyxis_components$Prima$Pyxis$Button$isSecondary = $elm$core$Basics$eq(3);
var $primait$pyxis_components$Prima$Pyxis$Button$SecondaryAlt = 4;
var $primait$pyxis_components$Prima$Pyxis$Button$isSecondaryAlt = $elm$core$Basics$eq(4);
var $primait$pyxis_components$Prima$Pyxis$Button$Small = 1;
var $primait$pyxis_components$Prima$Pyxis$Button$isSmall = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Button$Tertiary = 5;
var $primait$pyxis_components$Prima$Pyxis$Button$isTertiary = $elm$core$Basics$eq(5);
var $primait$pyxis_components$Prima$Pyxis$Button$TertiaryAlt = 6;
var $primait$pyxis_components$Prima$Pyxis$Button$isTertiaryAlt = $elm$core$Basics$eq(6);
var $primait$pyxis_components$Prima$Pyxis$Button$Tiny = 2;
var $primait$pyxis_components$Prima$Pyxis$Button$isTiny = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Button$buildClasses = F2(
	function (_v0, options) {
		var emphasis = _v0.i0;
		var size = _v0.aH;
		var isLoadingState = _v0.fL;
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2(
						'btn--callout',
						$primait$pyxis_components$Prima$Pyxis$Button$isCallOut(emphasis)),
						_Utils_Tuple2(
						'btn--primary',
						$primait$pyxis_components$Prima$Pyxis$Button$isPrimary(emphasis)),
						_Utils_Tuple2(
						'btn--primary-alt',
						$primait$pyxis_components$Prima$Pyxis$Button$isPrimaryAlt(emphasis)),
						_Utils_Tuple2(
						'btn--secondary',
						$primait$pyxis_components$Prima$Pyxis$Button$isSecondary(emphasis)),
						_Utils_Tuple2(
						'btn--secondary-alt',
						$primait$pyxis_components$Prima$Pyxis$Button$isSecondaryAlt(emphasis)),
						_Utils_Tuple2(
						'btn--tertiary',
						$primait$pyxis_components$Prima$Pyxis$Button$isTertiary(emphasis)),
						_Utils_Tuple2(
						'btn--tertiary-alt',
						$primait$pyxis_components$Prima$Pyxis$Button$isTertiaryAlt(emphasis)),
						_Utils_Tuple2(
						'btn--loading',
						$primait$pyxis_components$Prima$Pyxis$Button$isLoading(emphasis)),
						_Utils_Tuple2(
						'btn--small',
						$primait$pyxis_components$Prima$Pyxis$Button$isSmall(size)),
						_Utils_Tuple2(
						'btn--tiny',
						$primait$pyxis_components$Prima$Pyxis$Button$isTiny(size)),
						_Utils_Tuple2(
						'btn--medium',
						$primait$pyxis_components$Prima$Pyxis$Button$isMedium(size)),
						_Utils_Tuple2(
						'is-loading',
						(!$primait$pyxis_components$Prima$Pyxis$Button$isLoading(emphasis)) && isLoadingState)
					])),
				$elm$html$Html$Attributes$class(
				A2($elm$core$String$join, ' ', options.c6))
			]);
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $primait$pyxis_components$Prima$Pyxis$Button$buildFormTarget = function (options) {
	var _v0 = options.pT;
	switch (_v0) {
		case 1:
			return A2($elm$html$Html$Attributes$attribute, 'formtarget', '_self');
		case 0:
			return A2($elm$html$Html$Attributes$attribute, 'formtarget', '_blank');
		case 2:
			return A2($elm$html$Html$Attributes$attribute, 'formtarget', '_parent');
		default:
			return A2($elm$html$Html$Attributes$attribute, 'formtarget', '_top');
	}
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $primait$pyxis_components$Prima$Pyxis$Button$buildType = function (options) {
	var _v0 = options.ao;
	switch (_v0) {
		case 0:
			return $elm$html$Html$Attributes$type_('button');
		case 1:
			return $elm$html$Html$Attributes$type_('submit');
		default:
			return $elm$html$Html$Attributes$type_('reset');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Button$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 1:
				var action = modifier.a;
				return _Utils_update(
					options,
					{
						ng: A2($elm$core$List$cons, action, options.ng)
					});
			case 2:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 3:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 4:
				var index = modifier.a;
				return _Utils_update(
					options,
					{
						hQ: $elm$core$Maybe$Just(index)
					});
			case 5:
				var title = modifier.a;
				return _Utils_update(
					options,
					{
						eE: $elm$core$Maybe$Just(title)
					});
			case 6:
				var attr = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attr, options.eY)
					});
			case 7:
				var type_ = modifier.a;
				return _Utils_update(
					options,
					{ao: type_});
			default:
				var target = modifier.a;
				return _Utils_update(
					options,
					{pT: target});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Button$Button = 0;
var $primait$pyxis_components$Prima$Pyxis$Button$Self = 1;
var $primait$pyxis_components$Prima$Pyxis$Button$defaultOptions = {eY: _List_Nil, c6: _List_Nil, ff: $elm$core$Maybe$Nothing, ng: _List_Nil, nM: $elm$core$Maybe$Nothing, hQ: $elm$core$Maybe$Nothing, pT: 1, eE: $elm$core$Maybe$Nothing, ao: 0};
var $primait$pyxis_components$Prima$Pyxis$Button$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Button$applyOption, $primait$pyxis_components$Prima$Pyxis$Button$defaultOptions, config.ku);
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $primait$pyxis_components$Prima$Pyxis$Button$buildAttributes = function (buttonConfig) {
	var options = $primait$pyxis_components$Prima$Pyxis$Button$computeOptions(buttonConfig);
	return A2(
		$elm$core$List$append,
		options.ng,
		A2(
			$elm$core$List$append,
			options.eY,
			_Utils_ap(
				A2($primait$pyxis_components$Prima$Pyxis$Button$buildClasses, buttonConfig, options),
				A2(
					$elm$core$List$cons,
					$primait$pyxis_components$Prima$Pyxis$Button$buildFormTarget(options),
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Button$buildType(options),
						A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
									A2($elm$core$Maybe$map, $elm$html$Html$Attributes$tabindex, options.hQ),
									A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff)
								])))))));
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$i = _VirtualDom_node('i');
var $primait$pyxis_components$Prima$Pyxis$Button$renderIcon = function (icon) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('icon', true),
						_Utils_Tuple2('icon-' + icon, true)
					]))
			]),
		_List_Nil);
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $primait$pyxis_components$Prima$Pyxis$Button$render = function (config) {
	var label = config.n6;
	var icon = config.dE;
	return A2(
		$elm$html$Html$button,
		$primait$pyxis_components$Prima$Pyxis$Button$buildAttributes(config),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn__text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					])),
				A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$elm$html$Html$text(''),
				$primait$pyxis_components$Prima$Pyxis$Button$renderIcon,
				icon)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig = F6(
	function (emphasis, isLoadingState, size, label, icon, options) {
		return {i0: emphasis, dE: icon, fL: isLoadingState, n6: label, ku: options, aH: size};
	});
var $primait$pyxis_components$Prima$Pyxis$Button$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Button$tertiary = function (label) {
	return A6($primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig, 5, false, 0, label, $elm$core$Maybe$Nothing, _List_Nil);
};
var $primait$pyxis_components$Prima$Pyxis$Button$Class = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Button$addOption = F2(
	function (option, _v0) {
		var buttonConfig = _v0;
		return _Utils_update(
			buttonConfig,
			{
				ku: _Utils_ap(
					buttonConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Button$withClass = function (_class) {
	return $primait$pyxis_components$Prima$Pyxis$Button$addOption(
		$primait$pyxis_components$Prima$Pyxis$Button$Class(_class));
};
var $primait$pyxis_components$Prima$Pyxis$Button$Disabled = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Button$withDisabled = function (isDisabled) {
	return $primait$pyxis_components$Prima$Pyxis$Button$addOption(
		$primait$pyxis_components$Prima$Pyxis$Button$Disabled(isDisabled));
};
var $primait$pyxis_components$Prima$Pyxis$Button$Event = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $primait$pyxis_components$Prima$Pyxis$Button$withOnClick = function (tagger) {
	return $primait$pyxis_components$Prima$Pyxis$Button$addOption(
		$primait$pyxis_components$Prima$Pyxis$Button$Event(
			$elm$html$Html$Events$onClick(tagger)));
};
var $primait$pyxis_components$Prima$Pyxis$Button$withSmallSize = function (_v0) {
	var buttonConfig = _v0;
	return _Utils_update(
		buttonConfig,
		{aH: 1});
};
var $author$project$Guarantees$View$V2$DetailsCluster$addClusterButtonView = F3(
	function (price, cluster, isDisabled) {
		var selected = cluster.pC;
		var buttonLabel = A3($author$project$Utils$ternaryOperator, selected, 'rimuovi garanzia', 'aggiungi garanzia');
		return $primait$pyxis_components$Prima$Pyxis$Button$render(
			$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					isDisabled,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withClass,
						'v2-details-modal__action',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
							A3(
								$author$project$Utils$ternaryOperator,
								selected,
								A2($author$project$Guarantees$View$Helpers$getSelectedClusterActionBtnMsg, price, cluster),
								$author$project$Guarantees$View$Helpers$getNotSelectedClusterActionBtnMsg(cluster)),
							$primait$pyxis_components$Prima$Pyxis$Button$tertiary(buttonLabel))))));
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$View$Svg$svg = F3(
	function (attributes, path, name) {
		return A2(
			$elm$html$Html$img,
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('/svg/' + (path + (name + '.svg')))
					])),
			_List_Nil);
	});
var $author$project$Guarantees$View$V2$Details$coverageReasonsView = F2(
	function (slug, coverageReasons) {
		var reasonView = function (elem) {
			return A2(
				$elm$html$Html$li,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-details-modal__banner__item')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-details-modal__banner__item-content')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(elem)
							]))
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__banner')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-details-modal__banner__image')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$View$Svg$svg,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-details-modal__banner__icon')
								]),
							'illustrazioni/v2/',
							slug)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-details-modal__banner__content fw-base c-text-light')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fs-large')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Perché sceglierla:')
								])),
							A2(
							$elm$html$Html$ol,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-details-modal__banner__list')
								]),
							A2($elm$core$List$map, reasonView, coverageReasons))
						]))
				]));
	});
var $author$project$Guarantees$Model$ClusterAccordion = {$: 1};
var $author$project$Guarantees$Model$OpenEditOptionsDetailModal = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Guarantees$Helpers$pickOptionGroupCoverage = function (group) {
	if (!group.$) {
		var coverage = group.a.ap;
		return coverage;
	} else {
		var coverage = group.a.ap;
		return coverage;
	}
};
var $author$project$Guarantees$Helpers$isOptionGroupCoverageDefault = function (group) {
	return $author$project$Guarantees$Helpers$pickOptionGroupCoverage(group) === 'default';
};
var $author$project$PrimaElm$Lib$Utils$Render$renderIf = F2(
	function (condition, html) {
		return condition ? html : $elm$html$Html$text('');
	});
var $author$project$Guarantees$View$V2$DetailsCluster$editOptionsButtonView = function (cluster) {
	var optionGroups = cluster.g2;
	var guarantees = cluster.nF;
	var clusterAndGuaranteeOptionList = A2(
		$elm$core$List$filter,
		$author$project$Guarantees$Helpers$isOptionGroupCoverageDefault,
		A2(
			$elm$core$List$append,
			optionGroups,
			A2(
				$elm$core$List$concatMap,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Types$Quote$pickGuarantee,
					function ($) {
						return $.g3;
					}),
				guarantees)));
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		!$elm$core$List$isEmpty(clusterAndGuaranteeOptionList),
		$primait$pyxis_components$Prima$Pyxis$Button$render(
			$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withClass,
					'v2-details-modal__action',
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						A2($author$project$Guarantees$Model$OpenEditOptionsDetailModal, cluster, $author$project$Guarantees$Model$ClusterAccordion),
						$primait$pyxis_components$Prima$Pyxis$Button$tertiary('modifica massimali'))))));
};
var $hecrj$html_parser$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $hecrj$html_parser$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $hecrj$html_parser$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $hecrj$html_parser$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$hecrj$html_parser$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $hecrj$html_parser$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.bx, s.ld, s.iz, s.b);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.mR)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.bx, newOffset) < 0,
			0,
			{iz: newCol, mR: s.mR, g: s.g, bx: newOffset, ld: newRow, b: s.b});
	};
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $hecrj$html_parser$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $hecrj$html_parser$Html$Parser$comment = A2($elm$parser$Parser$map, $hecrj$html_parser$Html$Parser$Comment, $hecrj$html_parser$Html$Parser$commentString);
var $hecrj$html_parser$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $hecrj$html_parser$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $hecrj$html_parser$Html$Parser$voidElements);
};
var $hecrj$html_parser$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $hecrj$html_parser$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $hecrj$html_parser$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($hecrj$html_parser$Html$Parser$isTagAttributeCharacter)));
var $hecrj$html_parser$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $hecrj$html_parser$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $hecrj$html_parser$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$Char$fromCode = _Char_fromCode;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $hecrj$html_parser$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$hecrj$html_parser$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {iz: s.iz + (newOffset - s.bx), mR: s.mR, g: s.g, bx: newOffset, ld: s.ld, b: s.b};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.bx, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.b);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.ld, s.iz - (floatOffset + s.bx), invalid, s.mR));
		} else {
			if (_Utils_eq(s.bx, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.bx, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.bx, floatOffset, s.b));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.bx, s.b)) {
			var zeroOffset = s.bx + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.b) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.nY,
				c.jt,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.b),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.b) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.nY,
				c.kq,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.b),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.b) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.nY,
				c.ig,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.b),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.nY,
				c.i6,
				c.jE,
				c.jd,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.nY,
				c.i6,
				c.jE,
				c.jd,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.bx, s.b),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				ig: $elm$core$Result$Err(invalid),
				i6: expecting,
				jd: $elm$core$Result$Err(invalid),
				jt: $elm$core$Result$Err(invalid),
				jE: $elm$core$Result$Ok($elm$core$Basics$identity),
				nY: invalid,
				kq: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $hecrj$html_parser$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$hecrj$html_parser$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $hecrj$html_parser$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$namedCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($hecrj$html_parser$Html$Parser$numericCharacterReference),
				$hecrj$html_parser$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $hecrj$html_parser$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$hecrj$html_parser$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$hecrj$html_parser$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$hecrj$html_parser$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $hecrj$html_parser$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$hecrj$html_parser$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$hecrj$html_parser$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$hecrj$html_parser$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$hecrj$html_parser$Html$Parser$characterReference
					]))));
}();
var $hecrj$html_parser$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$hecrj$html_parser$Html$Parser$tagAttributeUnquotedValue,
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\"'),
						$hecrj$html_parser$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $hecrj$html_parser$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$hecrj$html_parser$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$hecrj$html_parser$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter)));
var $hecrj$html_parser$Html$Parser$tagAttributes = $hecrj$html_parser$Html$Parser$many($hecrj$html_parser$Html$Parser$tagAttribute);
var $hecrj$html_parser$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $hecrj$html_parser$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $hecrj$html_parser$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$hecrj$html_parser$Html$Parser$Text),
	A2(
		$hecrj$html_parser$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$hecrj$html_parser$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$hecrj$html_parser$Html$Parser$characterReference
				]))));
function $hecrj$html_parser$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$hecrj$html_parser$Html$Parser$text,
				$hecrj$html_parser$Html$Parser$comment,
				$hecrj$html_parser$Html$Parser$cyclic$element()
			]));
}
function $hecrj$html_parser$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $hecrj$html_parser$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($hecrj$html_parser$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($hecrj$html_parser$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$hecrj$html_parser$Html$Parser$cyclic$node())),
					$hecrj$html_parser$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$hecrj$html_parser$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($hecrj$html_parser$Html$Parser$isSpaceCharacter))),
			$hecrj$html_parser$Html$Parser$tagAttributes));
}
var $hecrj$html_parser$Html$Parser$node = $hecrj$html_parser$Html$Parser$cyclic$node();
$hecrj$html_parser$Html$Parser$cyclic$node = function () {
	return $hecrj$html_parser$Html$Parser$node;
};
var $hecrj$html_parser$Html$Parser$element = $hecrj$html_parser$Html$Parser$cyclic$element();
$hecrj$html_parser$Html$Parser$cyclic$element = function () {
	return $hecrj$html_parser$Html$Parser$element;
};
var $hecrj$html_parser$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($hecrj$html_parser$Html$Parser$oneOrMore, 'node', $hecrj$html_parser$Html$Parser$node),
		str);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $hecrj$html_parser$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $hecrj$html_parser$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $hecrj$html_parser$Html$Parser$Util$toAttribute, attrs),
				$hecrj$html_parser$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Utils$htmlRender = function (string) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		$hecrj$html_parser$Html$Parser$Util$toVirtualDom(
			A2(
				$elm$core$Result$withDefault,
				_List_Nil,
				$hecrj$html_parser$Html$Parser$run(string))));
};
var $author$project$Guarantees$Model$OpenGuaranteeDetailModal = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $primait$pyxis_components$Prima$Pyxis$Helpers$flip = F3(
	function (mapper, b, a) {
		return A2(mapper, a, b);
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$isJust = function (v) {
	if (!v.$) {
		return true;
	} else {
		return false;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Link$Standalone = 1;
var $primait$pyxis_components$Prima$Pyxis$Link$isStandalone = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Link$buildClassList = F2(
	function (_v0, options) {
		var type_ = _v0.ao;
		var icon = _v0.dE;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$append,
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Tuple$pair, true),
					options.c6),
				A2(
					$elm$core$List$append,
					options.c5,
					_List_fromArray(
						[
							_Utils_Tuple2('link', true),
							_Utils_Tuple2(
							'link--standalone',
							$primait$pyxis_components$Prima$Pyxis$Link$isStandalone(type_)),
							_Utils_Tuple2(
							'link--with-icon',
							$primait$pyxis_components$Prima$Pyxis$Helpers$isJust(icon))
						]))));
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute = A2(
	$elm$core$Basics$composeL,
	$elm$html$Html$Attributes$class,
	$elm$core$String$join(' '));
var $primait$pyxis_components$Prima$Pyxis$Link$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 2:
				var list = modifier.a;
				return _Utils_update(
					options,
					{
						c5: A2($elm$core$List$append, list, options.c5)
					});
			case 3:
				var action = modifier.a;
				return _Utils_update(
					options,
					{
						ng: A2($elm$core$List$cons, action, options.ng)
					});
			case 4:
				var attr = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attr, options.eY)
					});
			default:
				var target = modifier.a;
				return _Utils_update(
					options,
					{
						pT: $elm$core$Maybe$Just(target)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Link$defaultOptions = {eY: _List_Nil, c5: _List_Nil, c6: _List_Nil, ng: _List_Nil, nM: $elm$core$Maybe$Nothing, pT: $elm$core$Maybe$Nothing};
var $primait$pyxis_components$Prima$Pyxis$Link$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Link$applyOption, $primait$pyxis_components$Prima$Pyxis$Link$defaultOptions, config.ku);
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $primait$pyxis_components$Prima$Pyxis$Link$buildAttributes = function (linkModel) {
	var options = $primait$pyxis_components$Prima$Pyxis$Link$computeOptions(linkModel);
	return A2(
		$elm$core$List$cons,
		A2($primait$pyxis_components$Prima$Pyxis$Link$buildClassList, linkModel, options),
		A2(
			$elm$core$List$cons,
			$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
			A2(
				$elm$core$List$append,
				options.eY,
				A2(
					$elm$core$List$append,
					options.ng,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
								A2($elm$core$Maybe$map, $elm$html$Html$Attributes$target, options.pT)
							]))))));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $primait$pyxis_components$Prima$Pyxis$Link$renderIcon = function (icon) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('icon', true),
						_Utils_Tuple2('link__icon', true),
						_Utils_Tuple2('icon-' + icon, true)
					]))
			]),
		_List_Nil);
};
var $primait$pyxis_components$Prima$Pyxis$Link$render = function (config) {
	var label = config.n6;
	var icon = config.dE;
	var href = config.fz;
	var maybeHref = A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		$elm$core$List$singleton(
			A2($elm$core$Maybe$map, $elm$html$Html$Attributes$href, href)));
	return A2(
		A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$span,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$always($elm$html$Html$a),
				href)),
		A2(
			$elm$core$List$append,
			$primait$pyxis_components$Prima$Pyxis$Link$buildAttributes(config),
			maybeHref),
		_List_fromArray(
			[
				A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$elm$html$Html$text(''),
				$primait$pyxis_components$Prima$Pyxis$Link$renderIcon,
				icon),
				$elm$html$Html$text(label)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Link$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Link$LinkConfig = F5(
	function (type_, label, href, icon, options) {
		return {fz: href, dE: icon, n6: label, ku: options, ao: type_};
	});
var $primait$pyxis_components$Prima$Pyxis$Link$Simple = 0;
var $primait$pyxis_components$Prima$Pyxis$Link$simple = function (label) {
	return A5($primait$pyxis_components$Prima$Pyxis$Link$LinkConfig, 0, label, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, _List_Nil);
};
var $primait$pyxis_components$Prima$Pyxis$Link$Class = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Link$addOption = F2(
	function (option, _v0) {
		var linkConfig = _v0;
		return _Utils_update(
			linkConfig,
			{
				ku: _Utils_ap(
					linkConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Link$withClass = function (class_) {
	return $primait$pyxis_components$Prima$Pyxis$Link$addOption(
		$primait$pyxis_components$Prima$Pyxis$Link$Class(class_));
};
var $primait$pyxis_components$Prima$Pyxis$Link$Event = function (a) {
	return {$: 3, a: a};
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt = F2(
	function (eventType, msg) {
		return A2(
			$elm$html$Html$Events$custom,
			eventType,
			$elm$json$Json$Decode$succeed(
				{of: msg, oX: true, pN: true}));
	});
var $primait$pyxis_components$Prima$Pyxis$Link$withOnClick = function (tagger) {
	return $primait$pyxis_components$Prima$Pyxis$Link$addOption(
		$primait$pyxis_components$Prima$Pyxis$Link$Event(
			A2($primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt, 'click', tagger)));
};
var $author$project$Guarantees$View$V2$DetailsCluster$includedGuaranteeView = F2(
	function (cluster, guarantee) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__guarantee fw-base')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex align-items-center')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$View$Svg$svg,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-details-modal__guarantee__icon')
								]),
							'garanzie/v2/',
							$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex align-items-center')
						]),
					_List_fromArray(
						[
							$primait$pyxis_components$Prima$Pyxis$Link$render(
							A2(
								$primait$pyxis_components$Prima$Pyxis$Link$withOnClick,
								A2($author$project$Guarantees$Model$OpenGuaranteeDetailModal, guarantee, cluster),
								A2(
									$primait$pyxis_components$Prima$Pyxis$Link$withClass,
									'cursor-pointer',
									$primait$pyxis_components$Prima$Pyxis$Link$simple('Scopri >'))))
						]))
				]));
	});
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Guarantees$View$V2$DetailsCluster$includedGuaranteesView = F3(
	function (includedGuarantees, cluster, details) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__guarantee-list')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('margin-bottom--10')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(details.eE + ' include:')
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Guarantees$View$V2$DetailsCluster$includedGuaranteeView(cluster),
						includedGuarantees))
				]));
	});
var $author$project$Guarantees$Helpers$isClusterAlwaysIncluded = F2(
	function (_v0, _v1) {
		var inclusionClustersRules = _v0.nP;
		var slug = _v1.bH;
		return A2(
			$elm$core$List$member,
			slug,
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.mG;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.le;
						},
						$elm$core$Basics$eq(1)),
					inclusionClustersRules)));
	});
var $author$project$Types$Quote$FullPrice = 1;
var $author$project$Types$Quote$Selection$mapPriceBlock = F2(
	function (priceType, block) {
		if (!priceType) {
			return A2($prikhi$decimal$Decimal$add, block.oY.kk, block.oY.lF);
		} else {
			return A2($prikhi$decimal$Decimal$add, block.jk.kk, block.jk.lF);
		}
	});
var $author$project$Types$Quote$Selection$pickSelectedPriceBlocks = F2(
	function (paymentFrequency, guarantee) {
		var _v0 = _Utils_Tuple2(guarantee, paymentFrequency);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b) {
					var priceBlocks = _v0.a.a.kQ;
					var _v1 = _v0.b;
					return A2(
						$elm$core$List$map,
						function (_v2) {
							var price = _v2.oY;
							var fullPrice = _v2.jk;
							return {jk: fullPrice, oY: price};
						},
						A2(
							$elm$core$List$filter,
							function ($) {
								return $.pC;
							},
							priceBlocks));
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b === 1) {
					var priceBlocks = _v0.a.a.kQ;
					var _v3 = _v0.b;
					return A2(
						$elm$core$List$map,
						function (_v4) {
							var price = _v4.oY;
							var fullPrice = _v4.jk;
							return {jk: fullPrice, oY: price};
						},
						A2(
							$elm$core$List$filter,
							function ($) {
								return $.pC;
							},
							priceBlocks));
				} else {
					break _v0$2;
				}
			}
		}
		return _List_Nil;
	});
var $author$project$Types$Quote$Selection$calculateGuaranteePrice = F3(
	function (guarantee, priceType, paymentFrequency) {
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				$author$project$Types$Quote$Selection$mapPriceBlock(priceType),
				A2($author$project$Types$Quote$Selection$pickSelectedPriceBlocks, paymentFrequency, guarantee)));
	});
var $author$project$Types$Quote$Selection$calculateClusterPrice = F3(
	function (_v0, priceType, paymentFrequency) {
		var guarantees = _v0.nF;
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				function (guarantee) {
					return A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, priceType, paymentFrequency);
				},
				A2($elm$core$List$filter, $author$project$Guarantees$Helpers$isGuaranteeIncluded, guarantees)));
	});
var $prikhi$decimal$Decimal$compare = F2(
	function (a, b) {
		var _v0 = $prikhi$decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var fa = _v0.a;
		var fb = _v0.b;
		var _v1 = fb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = fa;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2($cmditch$elm_bigint$BigInt$compare, ma, mb);
	});
var $prikhi$decimal$Decimal$lt = F2(
	function (a, b) {
		var _v0 = A2($prikhi$decimal$Decimal$compare, a, b);
		if (!_v0) {
			return true;
		} else {
			return false;
		}
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {m$: decimals, jF: integers, kx: original, oV: prefix, ev: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.jF,
				$elm$core$List$singleton(formatted.m$))));
	return onlyZeros ? 1 : ((formatted.kx < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.m$;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.m$;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian = function (integers) {
	var thousand = ($elm$core$String$length(integers) > 3) ? A2($elm$core$String$right, 3, integers) : integers;
	var reversedSplitHundreds = function (value) {
		return ($elm$core$String$length(value) > 2) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 2, value),
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 2, value))) : ((!$elm$core$String$length(value)) ? _List_Nil : _List_fromArray(
			[value]));
	};
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$cons,
			thousand,
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 3, integers))));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitIntegers = F2(
	function (system, integers) {
		if (!system) {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		} else {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		}
	});
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = A2(
			$cuducos$elm_format_number$FormatNumber$Parser$splitIntegers,
			locale.ex,
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{oV: locale.f$, ev: locale.f0});
			case 0:
				return _Utils_update(
					partial,
					{oV: locale.kH, ev: locale.kI});
			default:
				return _Utils_update(
					partial,
					{oV: locale.l5, ev: locale.l6});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.aO, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.aJ, formatted.jF);
		var decimals = stringfyDecimals(formatted.m$);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.oV, integers, decimals, formatted.ev]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Western = 0;
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	aO: '.',
	m$: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	f$: '−',
	f0: '',
	kH: '',
	kI: '',
	ex: 0,
	aJ: '',
	l5: '',
	l6: ''
};
var $cuducos$elm_format_number$FormatNumber$Locales$spanishLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		aO: ',',
		m$: $cuducos$elm_format_number$FormatNumber$Locales$Exact(3),
		aJ: '.'
	});
var $author$project$Utils$priceDecimalsFormat = $cuducos$elm_format_number$FormatNumber$format(
	_Utils_update(
		$cuducos$elm_format_number$FormatNumber$Locales$spanishLocale,
		{
			m$: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2)
		}));
var $author$project$Utils$pricify = function (price) {
	return '€ ' + $author$project$Utils$priceDecimalsFormat(
		$prikhi$decimal$Decimal$toFloat(price));
};
var $author$project$Guarantees$View$V2$Details$priceView = F2(
	function (price, fullPrice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fw-base fs-medium c-text-base')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					A2($prikhi$decimal$Decimal$lt, price, fullPrice),
					A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-xsmall text-decoration-line-through margin-right--10')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Utils$pricify(fullPrice))
							]))),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-base')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Utils$pricify(price))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-small')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' /anno')
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsCluster$priceClusterView = function (cluster) {
	var price = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 1, 0);
	return A2($author$project$Guarantees$View$V2$Details$priceView, price, fullPrice);
};
var $author$project$Guarantees$View$V2$DetailsCluster$clusterDetailsContentView = function (_v0) {
	var cluster = _v0.e3;
	var details = _v0.m4;
	var price = _v0.oY;
	var includedGuarantees = A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Guarantees$Helpers$pickGuaranteeType,
			$elm$core$Basics$eq(0)),
		cluster.nF);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-details-modal')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$View$Svg$svg,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-details-modal__icon')
					]),
				'garanzie/v2/',
				cluster.bH),
				$author$project$Guarantees$View$V2$DetailsCluster$priceClusterView(cluster),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-base fs-large')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(details.eE)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-align-center fs-small c-text-base')
					]),
				_List_fromArray(
					[
						$author$project$Utils$htmlRender(details.pH)
					])),
				A2(
				$author$project$PrimaElm$Lib$Utils$Render$renderIf,
				!A2($author$project$Guarantees$Helpers$isClusterAlwaysIncluded, price, cluster),
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$Guarantees$View$V2$DetailsCluster$addClusterButtonView, price, cluster, false)
						]))),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Guarantees$View$V2$DetailsCluster$editOptionsButtonView(cluster)
					])),
				A3($author$project$Guarantees$View$V2$DetailsCluster$includedGuaranteesView, includedGuarantees, cluster, details),
				A2($author$project$Guarantees$View$V2$Details$coverageReasonsView, cluster.bH, details.pg),
				A2(
				$author$project$PrimaElm$Lib$Utils$Render$renderIf,
				!A2($author$project$Guarantees$Helpers$isClusterAlwaysIncluded, price, cluster),
				A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$Guarantees$View$V2$DetailsCluster$addClusterButtonView, price, cluster, false)
						])))
			]));
};
var $author$project$Guarantees$View$V2$DetailsCluster$clusterDetailsContents = function (payload) {
	return {
		mQ: _List_fromArray(
			[
				$author$project$Guarantees$View$V2$DetailsCluster$clusterDetailsContentView(payload)
			]),
		ds: $elm$core$Maybe$Nothing,
		eE: $elm$core$Maybe$Nothing
	};
};
var $author$project$Guarantees$Model$OptionAccordion = function (a) {
	return {$: 3, a: a};
};
var $author$project$Guarantees$Model$OptionAccordionTypeCluster = 0;
var $author$project$Guarantees$Model$ToggleModalAccordion = F5(
	function (a, b, c, d, e) {
		return {$: 15, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Guarantees$Helpers$pickOptionGroupType = function (group) {
	if (!group.$) {
		var type_ = group.a.ao;
		return type_;
	} else {
		var type_ = group.a.ao;
		return type_;
	}
};
var $author$project$Guarantees$View$V2$DetailsOptions$hasOptionGroupDefaultType = F2(
	function (type_, _v0) {
		var optionsGroup = _v0.g3;
		return A2(
			$elm$core$List$any,
			function (optionGroup) {
				return _Utils_eq(
					$author$project$Guarantees$Helpers$pickOptionGroupType(optionGroup),
					type_) && $author$project$Guarantees$Helpers$isOptionGroupCoverageDefault(optionGroup);
			},
			optionsGroup);
	});
var $author$project$Guarantees$View$V2$DetailsOptions$buildClusterOptionGroupLabel = F2(
	function (type_, guarantees) {
		return A2(
			$elm$core$String$join,
			' - ',
			A2(
				$elm$core$List$map,
				function ($) {
					return $.n6;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$View$V2$DetailsOptions$hasOptionGroupDefaultType(type_),
						$elm$core$Basics$not),
					A2($elm$core$List$map, $author$project$Types$Quote$pickGuarantee, guarantees))));
	});
var $author$project$Guarantees$Model$EditOptionSelectMsg = F4(
	function (a, b, c, d) {
		return {$: 16, a: a, b: b, c: c, d: d};
	});
var $author$project$Utils$applyIf = F2(
	function (condition, func) {
		return condition ? func : $elm$core$Basics$identity;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Form = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Grid = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$init = {jc: _List_Nil, cc: 0};
var $author$project$Guarantees$Helpers$pickOptionGroupLabel = function (group) {
	if (!group.$) {
		var label = group.a.n6;
		return label;
	} else {
		var label = group.a.n6;
		return label;
	}
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$Grid = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$addRow = F2(
	function (row_, _v0) {
		var rows = _v0;
		return _Utils_ap(
			rows,
			_List_fromArray(
				[row_]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$Row = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$WithoutColumns = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$emptyRow = $primait$pyxis_components$Prima$Pyxis$Form$Grid$WithoutColumns;
var $primait$pyxis_components$Prima$Pyxis$Form$pickLabel = function (formField) {
	switch (formField.$) {
		case 6:
			var label = formField.a.n6;
			return label;
		case 8:
			var label = formField.a.n6;
			return label;
		case 9:
			var label = formField.a.n6;
			return label;
		case 0:
			var label = formField.a.n6;
			return label;
		case 1:
			if (formField.a.b) {
				var _v1 = formField.a;
				var label = _v1.a.n6;
				return label;
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 2:
			var label = formField.a.n6;
			return label;
		case 3:
			var label = formField.a.n6;
			return label;
		case 10:
			var label = formField.a.n6;
			return label;
		case 4:
			var label = formField.a.n6;
			return label;
		case 11:
			var label = formField.a.n6;
			return label;
		case 5:
			var label = formField.a.n6;
			return label;
		default:
			var label = formField.a.n6;
			return label;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$hasLabel = A2($elm$core$Basics$composeL, $primait$pyxis_components$Prima$Pyxis$Helpers$isJust, $primait$pyxis_components$Prima$Pyxis$Form$pickLabel);
var $primait$pyxis_components$Prima$Pyxis$Form$isRadioFlagField = function (field) {
	if (field.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$InputField = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loading = {$: 1};
var $primait$pyxis_components$Prima$Pyxis$Helpers$addIf = F3(
	function (condition, element, list) {
		return condition ? A2($elm$core$List$cons, element, list) : list;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Value = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 9:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: _List_fromArray(
							[_class])
					});
			case 2:
				var value = modifier.a;
				return _Utils_update(
					options,
					{
						dj: $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Value(value)
					});
			case 3:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 4:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 5:
				var predicate = modifier.a;
				return _Utils_update(
					options,
					{b8: predicate});
			case 6:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 7:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 8:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 10:
				var placeholder = modifier.a;
				return _Utils_update(
					options,
					{
						oU: $elm$core$Maybe$Just(placeholder)
					});
			case 11:
				var size = modifier.a;
				return _Utils_update(
					options,
					{aH: size});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Indeterminate = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$defaultOptions = {
	eY: _List_Nil,
	c6: _List_fromArray(
		['form-input form-autocomplete__input']),
	dj: $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Indeterminate,
	ff: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	b8: $elm$core$Basics$always(true),
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	oU: $elm$core$Maybe$Nothing,
	aH: 1,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions = function (_v0) {
	var options = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$defaultOptions, options);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices = function (state) {
	var _v0 = state.av;
	if (!_v0.$) {
		var autocompleteChoices = _v0.a;
		return autocompleteChoices;
	} else {
		return _List_Nil;
	}
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$filterReaderAttribute = function (_v0) {
	var stateConfig = _v0;
	var _v1 = _Utils_Tuple2(stateConfig.pC, stateConfig.Z);
	if ((!_v1.a.$) && (!_v1.b)) {
		var currentValue = _v1.a.a;
		return $elm$html$Html$Attributes$value(
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$elm$core$List$head(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.n6;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeL,
								$elm$core$Basics$eq(currentValue),
								function ($) {
									return $.p8;
								}),
							$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices(stateConfig))))));
	} else {
		return $elm$html$Html$Attributes$value(
			A2($elm$core$Maybe$withDefault, '', stateConfig.dr));
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnInput = function (a) {
	return {$: 4, a: a};
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$filterTaggerAttribute = $elm$html$Html$Events$onInput($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnInput);
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isPristine = F2(
	function (_v0, inputModel) {
		var stateConfig = _v0;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(inputModel);
		var _v1 = _Utils_Tuple2(
			_Utils_eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Value(stateConfig.pC),
				options.dj),
			stateConfig.pC);
		if (_v1.a) {
			return true;
		} else {
			if (_v1.b.$ === 1) {
				var _v2 = _v1.b;
				return true;
			} else {
				return false;
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pristineAttribute = F2(
	function (state, inputModel) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isPristine, state, inputModel) ? $elm$html$Html$Attributes$class('is-pristine') : $elm$html$Html$Attributes$class('is-touched');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$shouldBeValidated = F4(
	function (model, autocompleteModel, stateModel, options) {
		return (!A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isPristine, stateModel, autocompleteModel)) || options.b8(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Validation$isError = function (type_) {
	switch (type_.$) {
		case 0:
			return true;
		case 1:
			return true;
		default:
			return false;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning = function (type_) {
	switch (type_.$) {
		case 2:
			return true;
		case 3:
			return true;
		default:
			return false;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$validationAttribute = F2(
	function (model, autocompleteModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$buildAttributes = F3(
	function (model, stateModel, autocompleteModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel);
		var hasValidations = A4($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$shouldBeValidated, model, autocompleteModel, stateModel, options);
		return A2(
			$elm$core$List$cons,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$filterTaggerAttribute,
			A2(
				$elm$core$List$cons,
				$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$filterReaderAttribute(stateModel),
				A2(
					$elm$core$List$cons,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pristineAttribute, stateModel, autocompleteModel),
					A3(
						$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
						hasValidations,
						A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$validationAttribute, model, autocompleteModel),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.oU),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj)
										]))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$hasReachedThreshold = function (_v0) {
	var filter = _v0.dr;
	var threshold = _v0.hT;
	return _Utils_cmp(
		threshold,
		A3($elm_community$maybe_extra$Maybe$Extra$unwrap, 0, $elm$core$String$length, filter)) < 1;
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isChoiceSelected = F2(
	function (_v0, choice) {
		var stateConfig = _v0;
		return _Utils_eq(
			$elm$core$Maybe$Just(choice.p8),
			stateConfig.pC);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Large = 2;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isLarge = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isMedium = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Small = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isSmall = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnSelect = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isChoiceFocused = F2(
	function (_v0, choice) {
		var stateConfig = _v0;
		return _Utils_eq(
			$elm$core$Maybe$Just(choice.p8),
			stateConfig.az);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderAutocompleteChoice = F2(
	function (stateModel, choice) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-autocomplete__list__item', true),
							_Utils_Tuple2(
							'is-selected',
							A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isChoiceSelected, stateModel, choice) || A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isChoiceFocused, stateModel, choice))
						])),
					A2($elm$core$Basics$composeL, $elm$html$Html$Events$onClick, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnSelect)(choice.p8)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(choice.n6)
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderAutocompleteNoResults = _List_fromArray(
	[
		A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-autocomplete__list--no-results')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Nessun risultato.')
			]))
	]);
var $primait$pyxis_components$Prima$Pyxis$Helpers$renderIf = F2(
	function (condition, html) {
		return condition ? html : $elm$html$Html$text('');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnReset = {$: 3};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderResetIcon = A2(
	$elm$html$Html$i,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-autocomplete__reset-icon'),
			$elm$html$Html$Events$onClick($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnReset)
		]),
	_List_Nil);
var $primait$pyxis_components$Prima$Pyxis$Form$Validation$render = function (type_) {
	switch (type_.$) {
		case 3:
			var message = type_.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-warning')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]));
		case 1:
			var message = type_.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-error')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]));
		default:
			return $elm$html$Html$text('');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderValidationMessages = F3(
	function (model, autocompleteModel, showValidation) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel));
		var _v0 = _Utils_Tuple3(showValidation, errors, warnings);
		if (_v0.a) {
			if (!_v0.b.b) {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
			} else {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
			}
		} else {
			return _List_Nil;
		}
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$render = F3(
	function (model, stateModel, autocompleteModel) {
		var stateConfig = stateModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$computeOptions(autocompleteModel);
		var isDisabled = _Utils_eq(
			options.ff,
			$elm$core$Maybe$Just(true));
		var hasValidations = A4($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$shouldBeValidated, model, autocompleteModel, stateModel, options);
		var hasSelectedAnyChoice = A2(
			$elm$core$List$any,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isChoiceSelected(stateModel),
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices(stateConfig));
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
					hasValidations,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$validationAttribute, model, autocompleteModel),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-autocomplete', true),
									_Utils_Tuple2(
									'is-open',
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$hasReachedThreshold(stateModel) && stateConfig.Z),
									_Utils_Tuple2('has-selected-choice', hasSelectedAnyChoice),
									_Utils_Tuple2(
									'is-small',
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isSmall(options.aH)),
									_Utils_Tuple2(
									'is-medium',
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isMedium(options.aH)),
									_Utils_Tuple2(
									'is-large',
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$isLarge(options.aH))
								])),
							A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pristineAttribute, stateModel, autocompleteModel)
						])),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						A3($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$buildAttributes, model, stateModel, autocompleteModel),
						_List_Nil),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'form-autocomplete__loader-icon',
										_Utils_eq(stateConfig.av, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loading)),
										_Utils_Tuple2(
										'form-autocomplete__search-icon',
										(!_Utils_eq(stateConfig.av, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loading)) && (!isDisabled))
									]))
							]),
						_List_Nil),
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('form-autocomplete__list')
							]),
						function () {
							var _v0 = _Utils_Tuple2(
								stateConfig.av,
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$hasReachedThreshold(stateModel));
							if ((!_v0.a.$) && _v0.b) {
								if (!_v0.a.a.b) {
									return $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderAutocompleteNoResults;
								} else {
									return A2(
										$elm$core$List$map,
										$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderAutocompleteChoice(stateModel),
										$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices(stateConfig));
								}
							} else {
								return _List_Nil;
							}
						}()),
						A2($primait$pyxis_components$Prima$Pyxis$Helpers$renderIf, hasSelectedAnyChoice && (!isDisabled), $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderResetIcon)
					])),
			A3($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$renderValidationMessages, model, autocompleteModel, hasValidations));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: A2($elm$core$List$cons, _class, options.bX)
					});
			case 2:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 3:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 4:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 6:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 5:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$defaultOptions = {
	eY: _List_Nil,
	bX: _List_fromArray(
		['form-checkbox__input']),
	ff: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Helpers$slugify = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$map(
		function (c) {
			return (c === ' ') ? '_' : c;
		}),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filter(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === ' ');
				}),
			$elm$core$String$fromList)));
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$toId = F2(
	function (label, id) {
		return id + ('_' + $primait$pyxis_components$Prima$Pyxis$Helpers$slugify(label));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$generateId = F2(
	function (_v0, checkboxItemLabel) {
		var id = _v0.nM;
		return A2(
			$elm$core$Maybe$map,
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$toId(checkboxItemLabel),
			id);
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$readerAttribute = F3(
	function (model, _v0, choice) {
		var config = _v0;
		return $elm$html$Html$Attributes$checked(
			A2(
				$elm$core$List$member,
				choice,
				config.hi(model)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$Disabled = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$isDisabled = function (_v0) {
	var config = _v0;
	return 1 <= $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			$elm$core$Basics$eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$Disabled(true)),
			config.ku));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$taggerAttribute = F2(
	function (_v0, choice) {
		var config = _v0;
		return $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$isDisabled(config) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			config.hS(choice));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$validationAttribute = F2(
	function (model, checkboxModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions(checkboxModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions(checkboxModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$buildAttributes = F3(
	function (model, checkboxModel, _v0) {
		var label = _v0.n6;
		var value = _v0.p8;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions(checkboxModel);
		return A2(
			$elm$core$List$cons,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$validationAttribute, model, checkboxModel),
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$value(value),
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$type_('checkbox'),
					A2(
						$elm$core$List$cons,
						A3($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$readerAttribute, model, checkboxModel, value),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.bX),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Attributes$id,
											A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$generateId, options, label)),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Events$onClick,
											A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$taggerAttribute, checkboxModel, value))
										]))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Label$Label = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Label$LabelConfig = F2(
	function (options, children) {
		return {ac: children, ku: options};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Label$label = function (str) {
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Form$Label$LabelConfig,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 2:
				var _for = modifier.a;
				return _Utils_update(
					options,
					{
						du: $elm$core$Maybe$Just(_for)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 3:
				var msg = modifier.a;
				return _Utils_update(
					options,
					{
						gc: $elm$core$Maybe$Just(msg)
					});
			case 4:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: _List_fromArray(
							[_class])
					});
			default:
				var lbl = modifier.a;
				return _Utils_update(
					options,
					{
						es: $elm$core$Maybe$Just(lbl)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Label$defaultOptions = {
	eY: _List_Nil,
	c6: _List_fromArray(
		['form-label']),
	du: $elm$core$Maybe$Nothing,
	gc: $elm$core$Maybe$Nothing,
	es: $elm$core$Maybe$Nothing
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Label$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Label$defaultOptions, config.ku);
};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $primait$pyxis_components$Prima$Pyxis$Form$Label$buildAttributes = function (labelModel) {
	var options = $primait$pyxis_components$Prima$Pyxis$Form$Label$computeOptions(labelModel);
	return A2(
		$elm$core$List$cons,
		$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
		_Utils_ap(
			options.eY,
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$for, options.du),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, options.gc)
					]))));
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $primait$pyxis_components$Prima$Pyxis$Form$Label$renderSubtitle = function (labelModel) {
	var options = $primait$pyxis_components$Prima$Pyxis$Form$Label$computeOptions(labelModel);
	var _v0 = options.es;
	if (_v0.$ === 1) {
		return $elm$html$Html$text('');
	} else {
		var lbl = _v0.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-label__subtitle')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(lbl)
				]));
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$render = function (labelModel) {
	var config = labelModel;
	return A2(
		$elm$html$Html$label,
		$primait$pyxis_components$Prima$Pyxis$Form$Label$buildAttributes(labelModel),
		_Utils_ap(
			config.ac,
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Form$Label$renderSubtitle(labelModel)
				])));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$For = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$addOption = F2(
	function (option, _v0) {
		var labelConfig = _v0;
		return _Utils_update(
			labelConfig,
			{
				ku: A2($elm$core$List$cons, option, labelConfig.ku)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Label$withFor = function (_for) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Label$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Label$For(_for));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$withConditionallyFor = function (maybeFor) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Basics$identity,
		A2($elm$core$Maybe$map, $primait$pyxis_components$Prima$Pyxis$Form$Label$withFor, maybeFor));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$OverridingClass = function (a) {
	return {$: 4, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Label$withOverridingClass = function (_class) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Label$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Label$OverridingClass(_class));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$renderCheckbox = F3(
	function (model, checkboxModel, checkboxItem) {
		var label = checkboxItem.n6;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions(checkboxModel);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-checkbox')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A3($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$buildAttributes, model, checkboxModel, checkboxItem),
					_List_Nil),
					$primait$pyxis_components$Prima$Pyxis$Form$Label$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Label$withOverridingClass,
						'form-checkbox__label',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Label$withConditionallyFor,
							A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$generateId, options, label),
							$primait$pyxis_components$Prima$Pyxis$Form$Label$label(label))))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Checkbox$render = F2(
	function (model, checkboxModel) {
		var config = checkboxModel;
		var id = A2(
			$elm$core$Maybe$withDefault,
			'',
			$primait$pyxis_components$Prima$Pyxis$Form$Checkbox$computeOptions(checkboxModel).nM);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-checkbox-options'),
						A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$validationAttribute, model, checkboxModel),
						$elm$html$Html$Attributes$id(id)
					]),
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$renderCheckbox, model, checkboxModel),
					config.av))
			]);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 3:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 2:
				var label = modifier.a;
				return _Utils_update(
					options,
					{
						n6: $elm$core$Maybe$Just(label)
					});
			case 5:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 4:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$defaultOptions = {
	eY: _List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-checkbox__input')
		]),
	ff: $elm$core$Maybe$Nothing,
	n6: $elm$core$Maybe$Nothing,
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$Flag = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$Disabled = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$isDisabled = function (_v0) {
	var config = _v0;
	return 1 <= $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			$elm$core$Basics$eq(
				$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$Disabled(true)),
			config.ku));
};
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$pickTagger = F2(
	function (model, _v0) {
		var config = _v0;
		return $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$isDisabled(config) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			config.hS(
				!A2(
					$elm$core$Maybe$withDefault,
					false,
					config.hi(model))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$readerAttribute = F2(
	function (model, _v0) {
		var config = _v0;
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Attributes$checked,
				$elm$core$Maybe$withDefault(false)),
			config.hi)(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$validationAttribute = F2(
	function (model, flagModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$buildAttributes = F2(
	function (model, flagModel) {
		var config = flagModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel);
		return A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$id(config.nM),
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$type_('checkbox'),
				A2(
					$elm$core$List$cons,
					A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$validationAttribute, model, flagModel),
					A2(
						$elm$core$List$cons,
						A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$readerAttribute, model, flagModel),
						_Utils_ap(
							options.eY,
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
										A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
										A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
										A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
										A2(
										$elm$core$Maybe$map,
										$elm$html$Html$Events$onClick,
										A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$pickTagger, model, flagModel))
									])))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Label$labelWithHtml = function (children) {
	return A2($primait$pyxis_components$Prima$Pyxis$Form$Label$LabelConfig, _List_Nil, children);
};
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$renderFlagChoice = F2(
	function (model, flagModel) {
		var config = flagModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-checkbox')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$buildAttributes, model, flagModel),
					_List_Nil),
					$primait$pyxis_components$Prima$Pyxis$Form$Label$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Label$withOverridingClass,
						'form-checkbox__label',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Label$withFor,
							config.nM,
							A2(
								$elm$core$Maybe$withDefault,
								$primait$pyxis_components$Prima$Pyxis$Form$Label$labelWithHtml(_List_Nil),
								options.n6))))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$renderValidationMessages = F2(
	function (model, flagModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$computeOptions(flagModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
		} else {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$render = F2(
	function (model, flagModel) {
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-checkbox-options')
					]),
				_List_fromArray(
					[
						A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$renderFlagChoice, model, flagModel)
					])),
			A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$renderValidationMessages, model, flagModel));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$InputDate = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$Date$InputText = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Value = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 2:
				var reader = modifier.a;
				var tagger = modifier.b;
				return _Utils_update(
					options,
					{
						bZ: reader,
						b_: $elm$core$Maybe$Just(tagger)
					});
			case 3:
				var reader = modifier.a;
				return _Utils_update(
					options,
					{di: reader});
			case 4:
				var value = modifier.a;
				return _Utils_update(
					options,
					{
						dj: $primait$pyxis_components$Prima$Pyxis$Form$Date$Value(value)
					});
			case 5:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 6:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 7:
				var predicate = modifier.a;
				return _Utils_update(
					options,
					{b8: predicate});
			case 8:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 9:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 10:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 12:
				var placeholder = modifier.a;
				return _Utils_update(
					options,
					{
						oU: $elm$core$Maybe$Just(placeholder)
					});
			case 13:
				var size = modifier.a;
				return _Utils_update(
					options,
					{aH: size});
			case 14:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
			default:
				var onIconClick = modifier.a;
				return _Utils_update(
					options,
					{
						d7: $elm$core$Maybe$Just(onIconClick)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Indeterminate = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$Date$defaultOptions = {
	id: $elm$core$Maybe$Nothing,
	eY: _List_Nil,
	c6: _List_fromArray(
		['form-input', 'form-datepicker']),
	bZ: $elm$core$Basics$always($elm$core$Maybe$Nothing),
	b_: $elm$core$Maybe$Nothing,
	di: $elm$core$Basics$always(false),
	dj: $primait$pyxis_components$Prima$Pyxis$Form$Date$Indeterminate,
	ff: $elm$core$Maybe$Nothing,
	fu: _List_Nil,
	nM: $elm$core$Maybe$Nothing,
	b8: $elm$core$Basics$always(true),
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	d7: $elm$core$Maybe$Nothing,
	oU: $elm$core$Maybe$Nothing,
	kP: $elm$core$Maybe$Nothing,
	aH: 1,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Date$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Date$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderAppendGroup = function (dateModel) {
	var _v0 = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
	var onIconClick = _v0.d7;
	var groupClasses = _v0.fu;
	var groupAttrs = _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-input-group__append'),
				$elm$html$Html$Attributes$class(
				A2($elm$core$String$join, ' ', groupClasses))
			]),
		A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			_List_Nil,
			A2($elm$core$Basics$composeR, $elm$html$Html$Events$onClick, $elm$core$List$singleton),
			onIconClick));
	return $elm$html$Html$div(groupAttrs);
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$DaySelection = {$: 2};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$YearSelection = {$: 1};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatDay = function (day) {
	switch (day) {
		case 0:
			return 'Lunedì';
		case 1:
			return 'Martedì';
		case 2:
			return 'Mercoledì';
		case 3:
			return 'Giovedì';
		case 4:
			return 'Venerdì';
		case 5:
			return 'Sabato';
		default:
			return 'Domenica';
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatMonth = function (month) {
	switch (month) {
		case 0:
			return 'Gennaio';
		case 1:
			return 'Febbraio';
		case 2:
			return 'Marzo';
		case 3:
			return 'Aprile';
		case 4:
			return 'Maggio';
		case 5:
			return 'Giugno';
		case 6:
			return 'Luglio';
		case 7:
			return 'Agosto';
		case 8:
			return 'Settembre';
		case 9:
			return 'Ottobre';
		case 10:
			return 'Novembre';
		default:
			return 'Dicembre';
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$italianLanguage = {
	m_: $elm$core$Basics$always(''),
	on: $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatMonth,
	oo: A2(
		$elm$core$Basics$composeL,
		$elm$core$String$left(3),
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatMonth),
	qc: $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatDay,
	qd: A2(
		$elm$core$Basics$composeL,
		$elm$core$String$left(3),
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formatDay)
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formattedDay = A2(
	$elm$core$Basics$composeL,
	A2($justinmimbs$date$Date$formatWithLanguage, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$italianLanguage, 'EEEE, d MMMM'),
	function ($) {
		return $.j;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$header = function (model) {
	var date = model.j;
	var selectingYear = model.a3;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('datepicker__header')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('datepicker__header__year', true),
								_Utils_Tuple2('is-selected', selectingYear)
							])),
						A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt,
						'click',
						selectingYear ? $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$DaySelection : $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$YearSelection)
					]),
				_List_fromArray(
					[
						A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$html$Html$text, $elm$core$String$fromInt),
						$justinmimbs$date$Date$year)(date)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('datepicker__header__day', true),
								_Utils_Tuple2('is-selected', !selectingYear)
							])),
						A2($primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt, 'click', $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$DaySelection)
					]),
				_List_fromArray(
					[
						A2($elm$core$Basics$composeL, $elm$html$Html$text, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formattedDay)(model)
					]))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$NextMonth = {$: 4};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PrevMonth = {$: 3};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formattedMonth = A2(
	$elm$core$Basics$composeL,
	A2($justinmimbs$date$Date$formatWithLanguage, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$italianLanguage, 'MMMM y'),
	function ($) {
		return $.j;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$chunks = F2(
	function (k, xs) {
		return (_Utils_cmp(
			$elm$core$List$length(xs),
			k) > 0) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, k, xs),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$chunks,
				k,
				A2($elm$core$List$drop, k, xs))) : _List_fromArray(
			[xs]);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$fromDateRangeToList = F2(
	function (dates, _v0) {
		fromDateRangeToList:
		while (true) {
			var low = _v0.a;
			var high = _v0.b;
			var newDate = A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(low),
				$justinmimbs$date$Date$month(low),
				$justinmimbs$date$Date$day(low) + 1);
			if (A2($justinmimbs$date$Date$compare, low, high) === 1) {
				return A2($elm$core$List$cons, low, dates);
			} else {
				var $temp$dates = A2($elm$core$List$cons, low, dates),
					$temp$_v0 = _Utils_Tuple2(newDate, high);
				dates = $temp$dates;
				_v0 = $temp$_v0;
				continue fromDateRangeToList;
			}
		}
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$SelectDay = function (a) {
	return {$: 6, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$dayCell = F3(
	function (dayNumber, currentDay, disabled) {
		return (dayNumber > 0) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('datepicker__picker__day', true),
							_Utils_Tuple2(
							'is-selected',
							_Utils_eq(dayNumber, currentDay)),
							_Utils_Tuple2('is-disabled', disabled)
						])),
					A2(
					$elm$core$Basics$composeL,
					$primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt('click'),
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$SelectDay)(dayNumber)
				]),
			_List_fromArray(
				[
					A2($elm$core$Basics$composeL, $elm$html$Html$text, $elm$core$String$fromInt)(dayNumber)
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('datepicker__picker__day is-empty')
				]),
			_List_Nil);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$weekRow = F3(
	function (days, currentDay, disabledDays) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('datepicker__picker__week')
				]),
			A2(
				$elm$core$List$map,
				function (day) {
					return A3(
						$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$dayCell,
						day,
						currentDay,
						A2($elm$core$List$member, day, disabledDays));
				},
				days));
	});
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$monthDays = function (model) {
	var date = model.j;
	var daysPickerRange = model.aN;
	var lastOfMonth = A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(model.j),
		$justinmimbs$date$Date$month(model.j),
		31);
	var firstOfMonth = A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(model.j),
		$justinmimbs$date$Date$month(model.j),
		1);
	var firstDayOfMonth = A3(
		$justinmimbs$date$Date$fromCalendarDate,
		$justinmimbs$date$Date$year(model.j),
		$justinmimbs$date$Date$month(model.j),
		1);
	var weekDay = $justinmimbs$date$Date$weekdayToNumber(
		$justinmimbs$date$Date$weekday(firstDayOfMonth));
	var leftPadding = weekDay - 1;
	var currentYear = $justinmimbs$date$Date$year(date);
	var currentMonth = $justinmimbs$date$Date$month(date);
	var daysCount = A2($primait$pyxis_components$Prima$Pyxis$Form$DatePicker$getDaysInMonth, currentYear, currentMonth);
	var rightPadding = (35 - daysCount) - leftPadding;
	var weeks = A2(
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$chunks,
		7,
		_Utils_ap(
			A2($elm$core$List$repeat, leftPadding, 0),
			_Utils_ap(
				A2($elm$core$List$range, 1, daysCount),
				A2($elm$core$List$repeat, rightPadding, 0))));
	var _v0 = daysPickerRange;
	var lowDate = _v0.a;
	var highDate = _v0.b;
	var highDayInMonth = (!A2($justinmimbs$date$Date$compare, highDate, lastOfMonth)) ? highDate : lastOfMonth;
	var lowDayInMonth = (!A2($justinmimbs$date$Date$compare, firstOfMonth, lowDate)) ? lowDate : firstOfMonth;
	var availableDays = A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$elm$core$List$map($justinmimbs$date$Date$day),
			$elm$core$List$reverse),
		$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$fromDateRangeToList(_List_Nil))(
		_Utils_Tuple2(lowDayInMonth, highDayInMonth));
	var disabledDaysInMonth = A2(
		$elm$core$Basics$composeL,
		$elm$core$List$filter(
			A2(
				$elm$core$Basics$composeL,
				$elm$core$Basics$not,
				function (a) {
					return A2($elm$core$List$member, a, availableDays);
				})),
		$elm$core$List$range(1))(daysCount);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('datepicker__picker__month')
			]),
		A2(
			$elm$core$List$map,
			function (week) {
				return A3(
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$weekRow,
					week,
					$justinmimbs$date$Date$day(date),
					disabledDaysInMonth);
			},
			weeks));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$weekDays = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('datepicker__picker__days-name')
		]),
	A2(
		$elm$core$List$map,
		function (day) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('datepicker__picker__days-name__item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(day)
					]));
		},
		_List_fromArray(
			['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'])));
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$picker = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('datepicker__picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('datepicker__picker__header')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('datepicker__picker__header__arrow datepicker__picker__header__arrow--prev'),
								A2($primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt, 'click', $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PrevMonth)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('datepicker__picker__header__current-month')
							]),
						_List_fromArray(
							[
								A2($elm$core$Basics$composeL, $elm$html$Html$text, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$formattedMonth)(model)
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('datepicker__picker__header__arrow datepicker__picker__header__arrow--next'),
								A2($primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt, 'click', $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$NextMonth)
							]),
						_List_Nil)
					])),
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$weekDays,
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$monthDays(model)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$SelectYear = function (a) {
	return {$: 5, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$yearButton = F2(
	function (year, currentYear) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('datepicker__year-picker__scroller__item', true),
							_Utils_Tuple2(
							'is-selected',
							_Utils_eq(year, currentYear))
						])),
					A2(
					$elm$core$Basics$composeL,
					$primait$pyxis_components$Prima$Pyxis$Helpers$stopEvt('click'),
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$SelectYear)(year)
				]),
			_List_fromArray(
				[
					A2($elm$core$Basics$composeL, $elm$html$Html$text, $elm$core$String$fromInt)(year)
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$yearPicker = function (model) {
	var daysPickerRange = model.aN;
	var _v0 = daysPickerRange;
	var lowerBound = _v0.a;
	var upperBound = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('datepicker__year-picker')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('datepicker__year-picker__scroller')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('datepicker__year-picker__scroller__list')
							]),
						A2(
							$elm$core$List$map,
							A2(
								$primait$pyxis_components$Prima$Pyxis$Helpers$flip,
								$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$yearButton,
								$justinmimbs$date$Date$year(model.j)),
							A2(
								$elm$core$List$range,
								$justinmimbs$date$Date$year(lowerBound),
								$justinmimbs$date$Date$year(upperBound))))
					]))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$render = function (model) {
	var selectingYear = model.a3;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('datepicker')
			]),
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$header(model),
				selectingYear ? $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$yearPicker(model) : $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$picker(model)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderDatePicker = F2(
	function (model, dateModel) {
		var _v0 = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
		var datePickerVisibility = _v0.di;
		var datePickerTagger = _v0.b_;
		var datePickerReader = _v0.bZ;
		var _v1 = _Utils_Tuple3(
			datePickerVisibility(model),
			datePickerReader(model),
			datePickerTagger);
		if ((_v1.a && (!_v1.b.$)) && (!_v1.c.$)) {
			var dpModel = _v1.b.a;
			var dpTagger = _v1.c.a;
			return A2(
				$elm$html$Html$map,
				dpTagger,
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$render(dpModel));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderDatePickerIcon = A2(
	$elm$html$Html$i,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-input-group--datepicker__icon')
		]),
	_List_Nil);
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderGroup = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-input-group form-input-group--datepicker')
		]));
var $primait$pyxis_components$Prima$Pyxis$Form$Date$isPristine = F2(
	function (model, dateModel) {
		var config = dateModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
		var _v0 = _Utils_Tuple2(
			_Utils_eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Date$Value(
					config.hi(model)),
				options.dj),
			config.hi(model));
		if (_v0.a) {
			return true;
		} else {
			if (_v0.b.$ === 1) {
				return true;
			} else {
				return false;
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$pristineAttribute = F2(
	function (model, dateModel) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Date$isPristine, model, dateModel) ? $elm$html$Html$Attributes$class('is-pristine') : $elm$html$Html$Attributes$class('is-touched');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$readerAttribute = F3(
	function (mode, model, _v0) {
		var config = _v0;
		var format = function () {
			if (!mode) {
				return 'yyyy-MM-dd';
			} else {
				return 'dd/MM/yyyy';
			}
		}();
		var _v1 = config.hi(model);
		if (!_v1.$) {
			var parsedDate = _v1.a;
			return $elm$html$Html$Attributes$value(
				A2($justinmimbs$date$Date$format, format, parsedDate));
		} else {
			var partialDate = _v1.a;
			return $elm$html$Html$Attributes$value(
				A2($elm$core$Maybe$withDefault, '', partialDate));
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$shouldBeValidated = F3(
	function (model, dateModel, options) {
		return (!A2($primait$pyxis_components$Prima$Pyxis$Form$Date$isPristine, model, dateModel)) || options.b8(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$sizeAttribute = function (size) {
	return $elm$html$Html$Attributes$class(
		function () {
			switch (size) {
				case 0:
					return 'is-small';
				case 1:
					return 'is-medium';
				default:
					return 'is-large';
			}
		}());
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$guessedYear = function (year) {
	return ($elm$core$String$length(year) === 2) ? A2(
		$elm$core$Maybe$withDefault,
		year,
		A2(
			$elm$core$Maybe$map,
			function (y) {
				return (y <= 25) ? ('20' + year) : ('19' + year);
			},
			$elm$core$String$toInt(year))) : year;
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$guess6Chars = F2(
	function (mapper, str) {
		var year = A3($elm$core$String$slice, 4, 6, str);
		var startsWithCentury = A2($elm$core$String$startsWith, '19', year) || A2($elm$core$String$startsWith, '20', year);
		return startsWithCentury ? mapper(str) : A2(
			$elm$core$String$join,
			'/',
			_List_fromArray(
				[
					A3($elm$core$String$slice, 0, 2, str),
					A3($elm$core$String$slice, 2, 4, str),
					$primait$pyxis_components$Prima$Pyxis$Form$Date$guessedYear(year)
				]));
	});
var $elm_community$string_extra$String$Extra$replaceSlice = F4(
	function (substitution, start, end, string) {
		return _Utils_ap(
			A3($elm$core$String$slice, 0, start, string),
			_Utils_ap(
				substitution,
				A3(
					$elm$core$String$slice,
					end,
					$elm$core$String$length(string),
					string)));
	});
var $elm_community$string_extra$String$Extra$insertAt = F3(
	function (insert, pos, string) {
		return A4($elm_community$string_extra$String$Extra$replaceSlice, insert, pos, pos, string);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$interpolateWithSlash = A2(
	$elm$core$Basics$composeR,
	A2($elm_community$string_extra$String$Extra$insertAt, '/', 2),
	A2($elm_community$string_extra$String$Extra$insertAt, '/', 5));
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$guess8Chars = function (str) {
	var _v0 = _Utils_Tuple2(
		A2($elm$core$String$contains, '/', str),
		$elm$core$String$length(str));
	_v0$2:
	while (true) {
		if (_v0.a) {
			if (_v0.b === 8) {
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Date$guess6Chars,
					$primait$pyxis_components$Prima$Pyxis$Form$Date$interpolateWithSlash,
					A3($elm$core$String$replace, '/', '', str));
			} else {
				break _v0$2;
			}
		} else {
			if (_v0.b === 8) {
				return A2(
					$elm$core$String$join,
					'/',
					_List_fromArray(
						[
							A3($elm$core$String$slice, 0, 2, str),
							A3($elm$core$String$slice, 2, 4, str),
							A3($elm$core$String$slice, 4, 8, str)
						]));
			} else {
				break _v0$2;
			}
		}
	}
	return str;
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$guessDate = function (str) {
	var _v0 = $elm$core$String$length(str);
	switch (_v0) {
		case 6:
			return A2($primait$pyxis_components$Prima$Pyxis$Form$Date$guess6Chars, $elm$core$Basics$identity, str);
		case 8:
			return $primait$pyxis_components$Prima$Pyxis$Form$Date$guess8Chars(str);
		default:
			return str;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$toIsoFormat = function (str) {
	return A2(
		$elm$core$String$join,
		'-',
		$elm$core$List$reverse(
			A2($elm$core$String$split, '/', str)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$stringToDate = function (str) {
	var guessedDate = $primait$pyxis_components$Prima$Pyxis$Form$Date$guessDate(str);
	var _v0 = _Utils_Tuple2(
		$elm$core$String$length(guessedDate),
		$justinmimbs$date$Date$fromIsoString(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$toIsoFormat(guessedDate)));
	_v0$2:
	while (true) {
		if (!_v0.b.$) {
			switch (_v0.a) {
				case 8:
					var parsedDate = _v0.b.a;
					return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ParsedDate(parsedDate);
				case 10:
					var parsedDate = _v0.b.a;
					return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$ParsedDate(parsedDate);
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return A2($elm$core$Basics$composeL, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate, $elm$core$Maybe$Just)(str);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$taggerAttribute = function (_v0) {
	var config = _v0;
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Tuple$pair, true),
			A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, config.hS, $primait$pyxis_components$Prima$Pyxis$Form$Date$stringToDate),
				$elm$html$Html$Events$targetValue)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$typeAttribute = function (mode) {
	if (!mode) {
		return A2($elm$html$Html$Attributes$attribute, 'type', 'date');
	} else {
		return A2($elm$html$Html$Attributes$attribute, 'type', 'text');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$validationAttribute = F2(
	function (model, dateModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$buildAttributes = F3(
	function (mode, model, dateModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
		return A2(
			$elm$core$List$cons,
			$primait$pyxis_components$Prima$Pyxis$Form$Date$typeAttribute(mode),
			A2(
				$elm$core$List$cons,
				A2($primait$pyxis_components$Prima$Pyxis$Form$Date$pristineAttribute, model, dateModel),
				A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
					A3($primait$pyxis_components$Prima$Pyxis$Form$Date$shouldBeValidated, model, dateModel, options),
					A2($primait$pyxis_components$Prima$Pyxis$Form$Date$validationAttribute, model, dateModel),
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Form$Date$sizeAttribute(options.aH),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Form$Date$taggerAttribute(dateModel),
							A2(
								$elm$core$List$cons,
								A3($primait$pyxis_components$Prima$Pyxis$Form$Date$readerAttribute, mode, model, dateModel),
								A2(
									$elm$core$List$cons,
									$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
									_Utils_ap(
										options.eY,
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.oU),
													A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
													A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj)
												]))))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderInput = F3(
	function (mode, model, dateModel) {
		return A2(
			$elm$html$Html$input,
			A3($primait$pyxis_components$Prima$Pyxis$Form$Date$buildAttributes, mode, model, dateModel),
			_List_Nil);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$renderValidationMessages = F3(
	function (model, dateModel, showValidation) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel));
		var _v0 = _Utils_Tuple3(showValidation, errors, warnings);
		if (_v0.a) {
			if (!_v0.b.b) {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
			} else {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
			}
		} else {
			return _List_Nil;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$shouldShowDatePicker = F2(
	function (model, dateModel) {
		var _v0 = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
		var datePickerTagger = _v0.b_;
		var datePickerReader = _v0.bZ;
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (_v1, _v2) {
						return true;
					}),
				datePickerTagger,
				datePickerReader(model)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$render = F2(
	function (model, dateModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Date$computeOptions(dateModel);
		var hasValidations = A3($primait$pyxis_components$Prima$Pyxis$Form$Date$shouldBeValidated, model, dateModel, options);
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Date$shouldShowDatePicker, model, dateModel) ? _List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Form$Date$renderGroup(
				A2(
					$elm$core$List$cons,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Date$renderAppendGroup,
						dateModel,
						_List_fromArray(
							[$primait$pyxis_components$Prima$Pyxis$Form$Date$renderDatePickerIcon])),
					A2(
						$elm$core$List$cons,
						A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderInput, 0, model, dateModel),
						A2(
							$elm$core$List$cons,
							A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderInput, 1, model, dateModel),
							A2(
								$elm$core$List$cons,
								A2($primait$pyxis_components$Prima$Pyxis$Form$Date$renderDatePicker, model, dateModel),
								A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderValidationMessages, model, dateModel, hasValidations))))))
			]) : A2(
			$elm$core$List$cons,
			A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderInput, 0, model, dateModel),
			A2(
				$elm$core$List$cons,
				A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderInput, 1, model, dateModel),
				A3($primait$pyxis_components$Prima$Pyxis$Form$Date$renderValidationMessages, model, dateModel, hasValidations)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$FilterableSelect$FilterableSelectMsg = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$FilterableSelect$render = F2(
	function (model, _v0) {
		var state = _v0;
		return A2(
			$elm$core$Basics$composeR,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$render, model, state.n),
			$elm$core$List$map(
				$elm$html$Html$map($elm$core$Basics$identity)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$Value = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var html = modifier.a;
				return _Utils_update(
					options,
					{
						id: $elm$core$Maybe$Just(html)
					});
			case 1:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 2:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 11:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: _List_fromArray(
							[_class])
					});
			case 3:
				var value = modifier.a;
				return _Utils_update(
					options,
					{
						dj: $primait$pyxis_components$Prima$Pyxis$Form$Input$Value(value)
					});
			case 4:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 5:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						fu: A2($elm$core$List$cons, _class, options.fu)
					});
			case 6:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 7:
				var predicate = modifier.a;
				return _Utils_update(
					options,
					{b8: predicate});
			case 8:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 9:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 10:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 12:
				var placeholder = modifier.a;
				return _Utils_update(
					options,
					{
						oU: $elm$core$Maybe$Just(placeholder)
					});
			case 13:
				var html = modifier.a;
				return _Utils_update(
					options,
					{
						kP: $elm$core$Maybe$Just(html)
					});
			case 14:
				var size = modifier.a;
				return _Utils_update(
					options,
					{aH: size});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$Indeterminate = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$Input$defaultOptions = {
	id: $elm$core$Maybe$Nothing,
	eY: _List_Nil,
	c6: _List_fromArray(
		['form-input']),
	dj: $primait$pyxis_components$Prima$Pyxis$Form$Input$Indeterminate,
	ff: $elm$core$Maybe$Nothing,
	fu: _List_Nil,
	nM: $elm$core$Maybe$Nothing,
	b8: $elm$core$Basics$always(true),
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	oU: $elm$core$Maybe$Nothing,
	kP: $elm$core$Maybe$Nothing,
	aH: 1,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Input$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Input$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$renderAppendGroup = function (inputModel) {
	var options = $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel);
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-input-group__append'),
				$elm$html$Html$Attributes$class(
				A2($elm$core$String$join, ' ', options.fu))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$renderGroup = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-input-group')
		]));
var $primait$pyxis_components$Prima$Pyxis$Form$Input$isPristine = F2(
	function (model, inputModel) {
		var config = inputModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel);
		var _v0 = _Utils_Tuple2(
			_Utils_eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Input$Value(
					config.hi(model)),
				options.dj),
			config.hi(model));
		if (_v0.a) {
			return true;
		} else {
			if (_v0.b.$ === 1) {
				var _v1 = _v0.b;
				return true;
			} else {
				return false;
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$pristineAttribute = F2(
	function (model, inputModel) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Input$isPristine, model, inputModel) ? $elm$html$Html$Attributes$class('is-pristine') : $elm$html$Html$Attributes$class('is-touched');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$readerAttribute = F2(
	function (model, _v0) {
		var config = _v0;
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Attributes$value,
				$elm$core$Maybe$withDefault('')),
			config.hi)(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$shouldBeValidated = F3(
	function (model, inputModel, options) {
		return (!A2($primait$pyxis_components$Prima$Pyxis$Form$Input$isPristine, model, inputModel)) || options.b8(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$sizeAttribute = function (size) {
	return $elm$html$Html$Attributes$class(
		function () {
			switch (size) {
				case 0:
					return 'is-small';
				case 1:
					return 'is-medium';
				default:
					return 'is-large';
			}
		}());
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$taggerAttribute = function (_v0) {
	var config = _v0;
	return $elm$html$Html$Events$onInput(config.hS);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$typeAttribute = function (type_) {
	return $elm$html$Html$Attributes$type_(
		function () {
			switch (type_) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 4:
					return 'email';
				case 2:
					return 'date';
				default:
					return 'number';
			}
		}());
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$validationAttribute = F2(
	function (model, inputModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Input$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Input$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$buildAttributes = F2(
	function (model, inputModel) {
		var config = inputModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel);
		return A2(
			$elm$core$List$cons,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Input$pristineAttribute, model, inputModel),
			A3(
				$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
				A3($primait$pyxis_components$Prima$Pyxis$Form$Input$shouldBeValidated, model, inputModel, options),
				A2($primait$pyxis_components$Prima$Pyxis$Form$Input$validationAttribute, model, inputModel),
				A2(
					$elm$core$List$cons,
					$primait$pyxis_components$Prima$Pyxis$Form$Input$sizeAttribute(options.aH),
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Form$Input$typeAttribute(config.ao),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Form$Input$taggerAttribute(inputModel),
							A2(
								$elm$core$List$cons,
								A2($primait$pyxis_components$Prima$Pyxis$Form$Input$readerAttribute, model, inputModel),
								A2(
									$elm$core$List$cons,
									$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
									_Utils_ap(
										options.eY,
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
													A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.oU),
													A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
													A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj)
												]))))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$renderInput = F2(
	function (model, inputModel) {
		return A2(
			$elm$html$Html$input,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Input$buildAttributes, model, inputModel),
			_List_Nil);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$renderPrependGroup = function (inputModel) {
	var options = $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel);
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-input-group__prepend'),
				$elm$html$Html$Attributes$class(
				A2($elm$core$String$join, ' ', options.fu))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Input$renderValidationMessages = F3(
	function (model, inputModel, showValidation) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Input$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Input$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel));
		var _v0 = _Utils_Tuple3(showValidation, errors, warnings);
		if (_v0.a) {
			if (!_v0.b.b) {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
			} else {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
			}
		} else {
			return _List_Nil;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Input$render = F2(
	function (model, inputModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Input$computeOptions(inputModel);
		var hasValidations = A3($primait$pyxis_components$Prima$Pyxis$Form$Input$shouldBeValidated, model, inputModel, options);
		var _v0 = _Utils_Tuple2(options.kP, options.id);
		if (!_v0.a.$) {
			var html = _v0.a.a;
			return _List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Form$Input$renderGroup(
					A2(
						$elm$core$List$cons,
						A2($primait$pyxis_components$Prima$Pyxis$Form$Input$renderPrependGroup, inputModel, html),
						A2(
							$elm$core$List$cons,
							A2($primait$pyxis_components$Prima$Pyxis$Form$Input$renderInput, model, inputModel),
							A3($primait$pyxis_components$Prima$Pyxis$Form$Input$renderValidationMessages, model, inputModel, hasValidations))))
				]);
		} else {
			if (!_v0.b.$) {
				var html = _v0.b.a;
				return _List_fromArray(
					[
						$primait$pyxis_components$Prima$Pyxis$Form$Input$renderGroup(
						A2(
							$elm$core$List$cons,
							A2($primait$pyxis_components$Prima$Pyxis$Form$Input$renderAppendGroup, inputModel, html),
							A2(
								$elm$core$List$cons,
								A2($primait$pyxis_components$Prima$Pyxis$Form$Input$renderInput, model, inputModel),
								A3($primait$pyxis_components$Prima$Pyxis$Form$Input$renderValidationMessages, model, inputModel, hasValidations))))
					]);
			} else {
				return A2(
					$elm$core$List$cons,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Input$renderInput, model, inputModel),
					A3($primait$pyxis_components$Prima$Pyxis$Form$Input$renderValidationMessages, model, inputModel, hasValidations));
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: A2($elm$core$List$cons, _class, options.bX)
					});
			case 2:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 3:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 4:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 6:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 5:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$defaultOptions = {
	eY: _List_Nil,
	bX: _List_fromArray(
		['form-radio__input']),
	ff: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions = function (_v0) {
	var options = _v0.ku;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Radio$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Radio$defaultOptions, options);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$toId = F2(
	function (label, id) {
		return id + ('_' + $primait$pyxis_components$Prima$Pyxis$Helpers$slugify(label));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$generateId = F2(
	function (_v0, radioChoiceLabel) {
		var id = _v0.nM;
		return A2(
			$elm$core$Maybe$map,
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$toId(radioChoiceLabel),
			id);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$isPristine = F2(
	function (model, _v0) {
		var config = _v0;
		var _v1 = config.hi(model);
		if (!_v1.$) {
			return false;
		} else {
			return true;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$Radio = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$Disabled = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$isDisabled = function (_v0) {
	var config = _v0;
	return 1 <= $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			$elm$core$Basics$eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Radio$Disabled(true)),
			config.ku));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$pickTagger = F2(
	function (_v0, choice) {
		var config = _v0;
		return $primait$pyxis_components$Prima$Pyxis$Form$Radio$isDisabled(config) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			config.hS(choice.p8));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$readerAttribute = F3(
	function (model, _v0, choice) {
		var config = _v0;
		return $elm$html$Html$Attributes$checked(
			_Utils_eq(
				$elm$core$Maybe$Just(choice.p8),
				config.hi(model)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$validationAttribute = F2(
	function (model, radioModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$buildAttributes = F3(
	function (model, radioModel, choice) {
		var label = choice.n6;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel);
		var hasValidations = (($elm$core$List$length(options.lS) > 0) && (!A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$isPristine, model, radioModel))) || (!A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$isPristine, model, radioModel));
		return A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$value(choice.p8),
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$type_('radio'),
				A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
					hasValidations,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$validationAttribute, model, radioModel),
					A2(
						$elm$core$List$cons,
						A3($primait$pyxis_components$Prima$Pyxis$Form$Radio$readerAttribute, model, radioModel, choice),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.bX),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Attributes$id,
											A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$generateId, options, label)),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Events$onClick,
											A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$pickTagger, radioModel, choice))
										]))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$renderRadioChoice = F3(
	function (model, radioModel, choice) {
		var label = choice.n6;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-radio')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A3($primait$pyxis_components$Prima$Pyxis$Form$Radio$buildAttributes, model, radioModel, choice),
					_List_Nil),
					$primait$pyxis_components$Prima$Pyxis$Form$Label$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Label$withOverridingClass,
						'form-radio__label',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Label$withConditionallyFor,
							A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$generateId, options, label),
							$primait$pyxis_components$Prima$Pyxis$Form$Label$label(label))))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$renderValidationMessages = F2(
	function (model, radioModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
		} else {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$render = F2(
	function (model, radioModel) {
		var radioChoices = radioModel.hh;
		var id = A2(
			$elm$core$Maybe$withDefault,
			'',
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$computeOptions(radioModel).nM);
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-radio-options'),
						$elm$html$Html$Attributes$id(id)
					]),
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$renderRadioChoice, model, radioModel),
					radioChoices)),
			A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$renderValidationMessages, model, radioModel));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: A2($elm$core$List$cons, _class, options.bX)
					});
			case 2:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 4:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 3:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
			default:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{ff: disabled});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$defaultOptions = {
	eY: _List_Nil,
	bX: _List_fromArray(
		['form-radio-button']),
	ff: false,
	nM: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$hasDisabledAttribute = function (disabled) {
	return disabled ? $elm$html$Html$Attributes$class('is-disabled') : $elm$html$Html$Attributes$class('');
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$hasSubtitleAttribute = function (choice) {
	return $primait$pyxis_components$Prima$Pyxis$Helpers$isJust(choice.eu) ? $elm$html$Html$Attributes$class('has-subtitle') : $elm$html$Html$Attributes$class('');
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$pickTagger = F3(
	function (_v0, choice, options) {
		var config = _v0;
		return options.ff ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			config.hS(choice.p8));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$readerAttribute = F3(
	function (model, _v0, choice) {
		var config = _v0;
		return _Utils_eq(
			$elm$core$Maybe$Just(choice.p8),
			config.hi(model)) ? $elm$html$Html$Attributes$class('is-selected') : $elm$html$Html$Attributes$class('');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$validationAttribute = F2(
	function (model, radioButtonModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(radioButtonModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(radioButtonModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$buildAttributes = F3(
	function (model, radioButtonModel, choice) {
		var title = choice.eE;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(radioButtonModel);
		var generateId = function (id) {
			return id + ('_' + $primait$pyxis_components$Prima$Pyxis$Helpers$slugify(title));
		};
		return A2(
			$elm$core$List$cons,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$hasSubtitleAttribute(choice),
			A2(
				$elm$core$List$cons,
				A2($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$validationAttribute, model, radioButtonModel),
				A2(
					$elm$core$List$cons,
					A3($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$readerAttribute, model, radioButtonModel, choice),
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$hasDisabledAttribute(options.ff),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.bX),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$map,
											A2($elm$core$Basics$composeR, generateId, $elm$html$Html$Attributes$id),
											options.nM),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Events$onClick,
											A3($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$pickTagger, radioButtonModel, choice, options))
										]))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$renderMaybe = function (theMaybe) {
	if (!theMaybe.$) {
		var html = theMaybe.a;
		return html;
	} else {
		return $elm$html$Html$text('');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderSubtitle = function (subtitle) {
	return A2(
		$elm$html$Html$p,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-radio-button__subtitle')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(subtitle)
			]));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderTitle = function (title) {
	return A2(
		$elm$html$Html$strong,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-radio-button__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderRadioButtonChoice = F3(
	function (model, radioButtonModel, choice) {
		var title = choice.eE;
		var subtitle = choice.eu;
		return A2(
			$elm$html$Html$div,
			A3($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$buildAttributes, model, radioButtonModel, choice),
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderTitle(title),
					$primait$pyxis_components$Prima$Pyxis$Helpers$renderMaybe(
					A2($elm$core$Maybe$map, $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderSubtitle, subtitle))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderValidationMessages = F2(
	function (model, inputModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(inputModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(inputModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
		} else {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioButton$render = F2(
	function (model, radioButtonModel) {
		var config = radioButtonModel;
		var id = A2(
			$elm$core$Maybe$withDefault,
			'',
			$primait$pyxis_components$Prima$Pyxis$Form$RadioButton$computeOptions(radioButtonModel).nM);
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-radio-button-options'),
						$elm$html$Html$Attributes$id(id)
					]),
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderRadioButtonChoice, model, radioButtonModel),
					config.av)),
			A2($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$renderValidationMessages, model, radioButtonModel));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: A2($elm$core$List$cons, _class, options.bX)
					});
			case 2:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 3:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 4:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 6:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 5:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$defaultOptions = {
	eY: _List_Nil,
	bX: _List_fromArray(
		['form-radio-flag__input']),
	ff: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions = function (_v0) {
	var options = _v0.ku;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$defaultOptions, options);
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$boolToValue = function (flag) {
	return flag ? 'si' : 'no';
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$toId = F2(
	function (label, id) {
		return id + ('_' + $primait$pyxis_components$Prima$Pyxis$Helpers$slugify(label));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$generateId = F2(
	function (_v0, radioChoiceLabel) {
		var id = _v0.nM;
		return A2(
			$elm$core$Maybe$map,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$toId(radioChoiceLabel),
			id);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$RadioFlag = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$Disabled = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isDisabled = function (_v0) {
	var config = _v0;
	return 1 <= $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			$elm$core$Basics$eq(
				$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$Disabled(true)),
			config.ku));
};
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$pickTagger = F2(
	function (_v0, choice) {
		var config = _v0;
		return $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isDisabled(config) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			config.hS(choice.p8));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$readerAttribute = F3(
	function (model, _v0, choice) {
		var config = _v0;
		return $elm$html$Html$Attributes$checked(
			_Utils_eq(
				$elm$core$Maybe$Just(choice.p8),
				config.hi(model)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$validationAttribute = F2(
	function (model, radioModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$buildAttributes = F3(
	function (model, radioModel, choice) {
		var label = choice.n6;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel);
		return A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$value(
				$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$boolToValue(choice.p8)),
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$type_('radio'),
				A2(
					$elm$core$List$cons,
					A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$validationAttribute, model, radioModel),
					A2(
						$elm$core$List$cons,
						A3($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$readerAttribute, model, radioModel, choice),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.bX),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Attributes$id,
											A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$generateId, options, label)),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
											A2(
											$elm$core$Maybe$map,
											$elm$html$Html$Events$onClick,
											A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$pickTagger, radioModel, choice))
										]))))))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$Dark = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isDark = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$Light = 0;
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isLight = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$renderRadioChoice = F3(
	function (model, radioModel, choice) {
		var skin = radioModel.ls;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-radio-flag', true),
							_Utils_Tuple2(
							'form-radio-flag--light',
							$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isLight(skin)),
							_Utils_Tuple2(
							'form-radio-flag--dark',
							$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$isDark(skin))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A3($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$buildAttributes, model, radioModel, choice),
					_List_Nil),
					$primait$pyxis_components$Prima$Pyxis$Form$Label$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Label$withOverridingClass,
						'form-radio-flag__label',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Label$withConditionallyFor,
							A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$generateId, options, choice.n6),
							$primait$pyxis_components$Prima$Pyxis$Form$Label$label(choice.n6))))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$renderValidationMessages = F2(
	function (model, radioModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
		} else {
			return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$render = F2(
	function (model, radioModel) {
		var radioChoices = radioModel.hh;
		var id = A2(
			$elm$core$Maybe$withDefault,
			'',
			$primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$computeOptions(radioModel).nM);
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-radio-flag-options'),
						$elm$html$Html$Attributes$id(id)
					]),
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$renderRadioChoice, model, radioModel),
					radioChoices)),
			A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$renderValidationMessages, model, radioModel));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Value = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: A2($elm$core$List$cons, _class, options.bX)
					});
			case 2:
				var value = modifier.a;
				return _Utils_update(
					options,
					{
						dj: $primait$pyxis_components$Prima$Pyxis$Form$Select$Value(value)
					});
			case 3:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 4:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 5:
				var predicate = modifier.a;
				return _Utils_update(
					options,
					{b8: predicate});
			case 6:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 7:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 8:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						bX: _List_fromArray(
							[_class])
					});
			case 9:
				var placeholder = modifier.a;
				return _Utils_update(
					options,
					{oU: placeholder});
			case 10:
				var size = modifier.a;
				return _Utils_update(
					options,
					{aH: size});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Indeterminate = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$Select$defaultOptions = {
	eY: _List_Nil,
	bX: _List_fromArray(
		['form-select form-select--native']),
	dj: $primait$pyxis_components$Prima$Pyxis$Form$Select$Indeterminate,
	ff: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	b8: $elm$core$Basics$always(true),
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	oU: 'Seleziona',
	aH: 1,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions = function (_v0) {
	var options = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$Select$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$Select$defaultOptions, options);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$OnSelect = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$isChoiceFocused = F2(
	function (_v0, choice) {
		var stateConfig = _v0;
		return _Utils_eq(
			$elm$core$Maybe$Just(choice.p8),
			stateConfig.az);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$isChoiceSelected = F2(
	function (_v0, choice) {
		var stateConfig = _v0;
		return _Utils_eq(
			$elm$core$Maybe$Just(choice.p8),
			stateConfig.pC);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectChoice = F2(
	function (stateModel, choice) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-select__list__item', true),
							_Utils_Tuple2(
							'is-selected',
							A2($primait$pyxis_components$Prima$Pyxis$Form$Select$isChoiceSelected, stateModel, choice) || A2($primait$pyxis_components$Prima$Pyxis$Form$Select$isChoiceFocused, stateModel, choice))
						])),
					A2($elm$core$Basics$composeL, $elm$html$Html$Events$onClick, $primait$pyxis_components$Prima$Pyxis$Form$Select$OnSelect)(choice.p8)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(choice.n6)
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectChoiceWrapper = $elm$html$Html$ul(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-select__list')
		]));
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectIcon = A2(
	$elm$html$Html$i,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-select__status-icon')
		]),
	_List_Nil);
var $primait$pyxis_components$Prima$Pyxis$Form$Select$OnToggleMenu = {$: 1};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectStatus = F2(
	function (_v0, selectModel) {
		var choices = _v0.av;
		var selected = _v0.pC;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-select__status'),
					$elm$html$Html$Events$onClick($primait$pyxis_components$Prima$Pyxis$Form$Select$OnToggleMenu)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2(
						$elm$core$Maybe$withDefault,
						options.oU,
						$elm$core$List$head(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.n6;
								},
								A2(
									$elm$core$List$filter,
									A2(
										$elm$core$Basics$composeL,
										A2(
											$elm$core$Basics$composeL,
											$elm$core$Basics$eq(selected),
											$elm$core$Maybe$Just),
										function ($) {
											return $.p8;
										}),
									choices)))))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$isPristine = F2(
	function (_v0, selectModel) {
		var stateConfig = _v0;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		var _v1 = _Utils_Tuple2(
			_Utils_eq(
				$primait$pyxis_components$Prima$Pyxis$Form$Select$Value(stateConfig.pC),
				options.dj),
			stateConfig.pC);
		if (_v1.a) {
			return true;
		} else {
			if (_v1.b.$ === 1) {
				var _v2 = _v1.b;
				return true;
			} else {
				return false;
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$shouldBeValidated = F4(
	function (model, selectModel, stateModel, options) {
		return (!A2($primait$pyxis_components$Prima$Pyxis$Form$Select$isPristine, stateModel, selectModel)) || options.b8(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$sizeAttribute = function (size) {
	return $elm$html$Html$Attributes$class(
		function () {
			switch (size) {
				case 0:
					return 'is-small';
				case 1:
					return 'is-medium';
				default:
					return 'is-large';
			}
		}());
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$validationAttribute = F2(
	function (model, selectModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelect = F3(
	function (model, stateModel, selectModel) {
		var choices = stateModel.av;
		var isMenuOpen = stateModel.Z;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		var hasValidations = A4($primait$pyxis_components$Prima$Pyxis$Form$Select$shouldBeValidated, model, selectModel, stateModel, options);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
					hasValidations,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Select$validationAttribute, model, selectModel),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-select', true),
									_Utils_Tuple2('is-open', isMenuOpen),
									_Utils_Tuple2(
									'is-disabled',
									A2($elm$core$Maybe$withDefault, false, options.ff))
								])),
							$primait$pyxis_components$Prima$Pyxis$Form$Select$sizeAttribute(options.aH)
						])),
				_Utils_ap(
					options.eY,
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
								A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
								A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
								A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6)
							])))),
			_List_fromArray(
				[
					A2($primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectStatus, stateModel, selectModel),
					$primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectIcon,
					$primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectChoiceWrapper(
					A2(
						$elm$core$List$map,
						$primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelectChoice(stateModel),
						choices))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$pristineAttribute = F2(
	function (state, selectModel) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Select$isPristine, state, selectModel) ? $elm$html$Html$Attributes$class('is-pristine') : $elm$html$Html$Attributes$class('is-touched');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$taggerAttribute = $elm$html$Html$Events$onInput($primait$pyxis_components$Prima$Pyxis$Form$Select$OnSelect);
var $primait$pyxis_components$Prima$Pyxis$Form$Select$buildAttributes = F3(
	function (model, stateModel, selectModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		var hasValidations = A4($primait$pyxis_components$Prima$Pyxis$Form$Select$shouldBeValidated, model, selectModel, stateModel, options);
		return A2(
			$elm$core$List$cons,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Select$pristineAttribute, stateModel, selectModel),
			A3(
				$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
				hasValidations,
				A2($primait$pyxis_components$Prima$Pyxis$Form$Select$validationAttribute, model, selectModel),
				A2(
					$elm$core$List$cons,
					$primait$pyxis_components$Prima$Pyxis$Form$Select$taggerAttribute,
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Form$Select$sizeAttribute(options.aH),
						A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.bX),
							_Utils_ap(
								options.eY,
								A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									_List_fromArray(
										[
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
											A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj),
											A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6)
										]))))))));
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderSelectChoice = F2(
	function (_v0, choice) {
		var selected = _v0.pC;
		return A2(
			$elm$html$Html$option,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(choice.p8),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(
						selected,
						$elm$core$Maybe$Just(choice.p8)))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(choice.n6)
				]));
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderSelect = F3(
	function (model, stateModel, selectModel) {
		var choices = stateModel.av;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		return A2(
			$elm$html$Html$select,
			A3($primait$pyxis_components$Prima$Pyxis$Form$Select$buildAttributes, model, stateModel, selectModel),
			A2(
				$elm$core$List$map,
				$primait$pyxis_components$Prima$Pyxis$Form$Select$renderSelectChoice(stateModel),
				A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$flip,
					$elm$core$List$cons,
					choices,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Select$SelectChoice, '', options.oU))));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$renderValidationMessages = F3(
	function (model, selectModel, showValidation) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel));
		var _v0 = _Utils_Tuple3(showValidation, errors, warnings);
		if (_v0.a) {
			if (!_v0.b.b) {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
			} else {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
			}
		} else {
			return _List_Nil;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$render = F3(
	function (model, stateModel, selectModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$Select$computeOptions(selectModel);
		var hasValidations = A4($primait$pyxis_components$Prima$Pyxis$Form$Select$shouldBeValidated, model, selectModel, stateModel, options);
		return _Utils_ap(
			_List_fromArray(
				[
					A3($primait$pyxis_components$Prima$Pyxis$Form$Select$renderSelect, model, stateModel, selectModel),
					A3($primait$pyxis_components$Prima$Pyxis$Form$Select$renderCustomSelect, model, stateModel, selectModel)
				]),
			A3($primait$pyxis_components$Prima$Pyxis$Form$Select$renderValidationMessages, model, selectModel, hasValidations));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$Value = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 2:
				var value = modifier.a;
				return _Utils_update(
					options,
					{
						dj: $primait$pyxis_components$Prima$Pyxis$Form$TextArea$Value(value)
					});
			case 3:
				var disabled = modifier.a;
				return _Utils_update(
					options,
					{
						ff: $elm$core$Maybe$Just(disabled)
					});
			case 4:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 5:
				var predicate = modifier.a;
				return _Utils_update(
					options,
					{b8: predicate});
			case 6:
				var name = modifier.a;
				return _Utils_update(
					options,
					{
						oq: $elm$core$Maybe$Just(name)
					});
			case 7:
				var onBlur = modifier.a;
				return _Utils_update(
					options,
					{
						f6: $elm$core$Maybe$Just(onBlur)
					});
			case 8:
				var onFocus = modifier.a;
				return _Utils_update(
					options,
					{
						gj: $elm$core$Maybe$Just(onFocus)
					});
			case 9:
				var placeholder = modifier.a;
				return _Utils_update(
					options,
					{
						oU: $elm$core$Maybe$Just(placeholder)
					});
			case 10:
				var size = modifier.a;
				return _Utils_update(
					options,
					{aH: size});
			default:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						lS: A2($elm$core$List$cons, validation, options.lS)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$Indeterminate = {$: 0};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$defaultOptions = {
	eY: _List_Nil,
	c6: _List_fromArray(
		['form-textarea']),
	dj: $primait$pyxis_components$Prima$Pyxis$Form$TextArea$Indeterminate,
	ff: $elm$core$Maybe$Nothing,
	fu: _List_Nil,
	nM: $elm$core$Maybe$Nothing,
	b8: $elm$core$Basics$always(true),
	oq: $elm$core$Maybe$Nothing,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	oU: $elm$core$Maybe$Nothing,
	aH: 1,
	lS: _List_Nil
};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Form$TextArea$applyOption, $primait$pyxis_components$Prima$Pyxis$Form$TextArea$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$isPristine = F2(
	function (model, textAreaModel) {
		var config = textAreaModel;
		var options = $primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel);
		var _v0 = _Utils_Tuple2(
			_Utils_eq(
				$primait$pyxis_components$Prima$Pyxis$Form$TextArea$Value(
					config.hi(model)),
				options.dj),
			config.hi(model));
		if (_v0.a) {
			return true;
		} else {
			if (_v0.b.$ === 1) {
				var _v1 = _v0.b;
				return true;
			} else {
				return false;
			}
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$pristineAttribute = F2(
	function (model, textAreaModel) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$isPristine, model, textAreaModel) ? $elm$html$Html$Attributes$class('is-pristine') : $elm$html$Html$Attributes$class('is-touched');
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$readerAttribute = F2(
	function (model, _v0) {
		var config = _v0;
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$Attributes$value,
				$elm$core$Maybe$withDefault('')),
			config.hi)(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$shouldBeValidated = F3(
	function (model, textAreaModel, options) {
		return (!A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$isPristine, model, textAreaModel)) || options.b8(model);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$sizeAttribute = function (size) {
	return $elm$html$Html$Attributes$class(
		function () {
			switch (size) {
				case 0:
					return 'is-small';
				case 1:
					return 'is-medium';
				default:
					return 'is-large';
			}
		}());
};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$taggerAttribute = function (_v0) {
	var config = _v0;
	return $elm$html$Html$Events$onInput(config.hS);
};
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$errorsValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isError,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$warningValidations = F2(
	function (model, options) {
		return A2(
			$elm$core$List$filter,
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$isWarning,
			A2(
				$elm$core$List$filterMap,
				A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Basics$identity, model),
				options.lS));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$validationAttribute = F2(
	function (model, textAreaModel) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel));
		var _v0 = _Utils_Tuple2(errors, warnings);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return $elm$html$Html$Attributes$class('is-valid');
			} else {
				return $elm$html$Html$Attributes$class('has-warning');
			}
		} else {
			return $elm$html$Html$Attributes$class('has-error');
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$buildAttributes = F2(
	function (model, textAreaModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel);
		return A3(
			$primait$pyxis_components$Prima$Pyxis$Helpers$addIf,
			A3($primait$pyxis_components$Prima$Pyxis$Form$TextArea$shouldBeValidated, model, textAreaModel, options),
			A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$validationAttribute, model, textAreaModel),
			A2(
				$elm$core$List$cons,
				$primait$pyxis_components$Prima$Pyxis$Form$TextArea$sizeAttribute(options.aH),
				A2(
					$elm$core$List$cons,
					$primait$pyxis_components$Prima$Pyxis$Form$TextArea$taggerAttribute(textAreaModel),
					A2(
						$elm$core$List$cons,
						A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$readerAttribute, model, textAreaModel),
						A2(
							$elm$core$List$cons,
							A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$pristineAttribute, model, textAreaModel),
							A2(
								$elm$core$List$cons,
								$primait$pyxis_components$Prima$Pyxis$Helpers$classesAttribute(options.c6),
								_Utils_ap(
									options.eY,
									A2(
										$elm$core$List$filterMap,
										$elm$core$Basics$identity,
										_List_fromArray(
											[
												A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM),
												A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.oq),
												A2($elm$core$Maybe$map, $elm$html$Html$Attributes$disabled, options.ff),
												A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.oU),
												A2($elm$core$Maybe$map, $elm$html$Html$Events$onBlur, options.f6),
												A2($elm$core$Maybe$map, $elm$html$Html$Events$onFocus, options.gj)
											])))))))));
	});
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$renderTextArea = F2(
	function (model, textAreaModel) {
		return A2(
			$elm$html$Html$textarea,
			A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$buildAttributes, model, textAreaModel),
			_List_Nil);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$renderValidationMessages = F3(
	function (model, textAreaModel, showValidation) {
		var warnings = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$warningValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel));
		var errors = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$errorsValidations,
			model,
			$primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel));
		var _v0 = _Utils_Tuple3(showValidation, errors, warnings);
		if (_v0.a) {
			if (!_v0.b.b) {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, warnings);
			} else {
				return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Validation$render, errors);
			}
		} else {
			return _List_Nil;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$TextArea$render = F2(
	function (model, textAreaModel) {
		var options = $primait$pyxis_components$Prima$Pyxis$Form$TextArea$computeOptions(textAreaModel);
		var hasValidations = A3($primait$pyxis_components$Prima$Pyxis$Form$TextArea$shouldBeValidated, model, textAreaModel, options);
		return A2(
			$elm$core$List$cons,
			A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$renderTextArea, model, textAreaModel),
			A3($primait$pyxis_components$Prima$Pyxis$Form$TextArea$renderValidationMessages, model, textAreaModel, hasValidations));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml = function (content) {
	return $elm$core$List$singleton(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
			$elm$core$List$length(content) > 0,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-row__item__append')
					]),
				content)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$renderField = F2(
	function (model, formField) {
		switch (formField.$) {
			case 0:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$Input$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 1:
				var list = formField.a;
				return $elm$core$List$concat(
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeL,
							$primait$pyxis_components$Prima$Pyxis$Form$renderField(model),
							$primait$pyxis_components$Prima$Pyxis$Form$InputField),
						list));
			case 8:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$Checkbox$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 9:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$CheckboxFlag$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 4:
				var msgMapper = formField.a.aD;
				var state = formField.a.cG;
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$flip,
					$elm$core$List$append,
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml),
					A2(
						$elm$core$List$map,
						$elm$html$Html$map(msgMapper),
						A3($primait$pyxis_components$Prima$Pyxis$Form$Select$render, model, state, config)));
			case 6:
				var msgMapper = formField.a.aD;
				var state = formField.a.cG;
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$flip,
					$elm$core$List$append,
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml),
					A2(
						$elm$core$List$map,
						$elm$html$Html$map(msgMapper),
						A3($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$render, model, state, config)));
			case 2:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$Radio$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 3:
				var config = formField.a.iC;
				return A2($primait$pyxis_components$Prima$Pyxis$Form$RadioFlag$render, model, config);
			case 10:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$RadioButton$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 11:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$TextArea$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			case 5:
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return _Utils_ap(
					A2($primait$pyxis_components$Prima$Pyxis$Form$Date$render, model, config),
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml));
			default:
				var msgMapper = formField.a.aD;
				var state = formField.a.cG;
				var config = formField.a.iC;
				var appendableHtml = formField.a.f;
				return A3(
					$primait$pyxis_components$Prima$Pyxis$Helpers$flip,
					$elm$core$List$append,
					$primait$pyxis_components$Prima$Pyxis$Form$renderAppendableHtml(appendableHtml),
					A2(
						$elm$core$List$map,
						$elm$html$Html$map(msgMapper),
						A3($primait$pyxis_components$Prima$Pyxis$Form$FilterableSelect$render, model, state, config)));
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$renderLabel = function (formField) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		_List_Nil,
		A2($elm$core$Basics$composeL, $elm$core$List$singleton, $primait$pyxis_components$Prima$Pyxis$Form$Label$render),
		$primait$pyxis_components$Prima$Pyxis$Form$pickLabel(formField));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$FourColumns = function (a) {
	return {$: 4, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalFourColumns = F4(
	function (first, second, third, fourth) {
		return {bj: first, jg: fourth, bE: second, eC: third};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$withFourColumns = F4(
	function (first, second, third, fourth) {
		return $primait$pyxis_components$Prima$Pyxis$Form$Grid$FourColumns(
			A4($primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalFourColumns, first, second, third, fourth));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$OneColumn = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$withOneColumn = function (childs) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Grid$OneColumn(childs);
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalThreeColumns = F3(
	function (first, second, third) {
		return {bj: first, bE: second, eC: third};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$ThreeColumns = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$withThreeColumns = F3(
	function (first, second, third) {
		return $primait$pyxis_components$Prima$Pyxis$Form$Grid$ThreeColumns(
			A3($primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalThreeColumns, first, second, third));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalTwoColumns = F2(
	function (first, second) {
		return {bj: first, bE: second};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$TwoColumns = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$withTwoColumns = F2(
	function (first, second) {
		return $primait$pyxis_components$Prima$Pyxis$Form$Grid$TwoColumns(
			A2($primait$pyxis_components$Prima$Pyxis$Form$Grid$InternalTwoColumns, first, second));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$buildGridRow = F3(
	function (model, kind, fields) {
		_v0$2:
		while (true) {
			if (fields.b) {
				if (fields.b.b) {
					if (!fields.b.b.b) {
						var first = fields.a;
						var _v1 = fields.b;
						var second = _v1.a;
						var _v2 = _Utils_Tuple2(
							$primait$pyxis_components$Prima$Pyxis$Form$hasLabel(first),
							$primait$pyxis_components$Prima$Pyxis$Form$hasLabel(second));
						if (_v2.b) {
							return A4(
								$primait$pyxis_components$Prima$Pyxis$Form$Grid$withFourColumns,
								$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(first),
								A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, first),
								$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(second),
								A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, second));
						} else {
							switch (kind) {
								case 0:
									return A3(
										$primait$pyxis_components$Prima$Pyxis$Form$Grid$withThreeColumns,
										$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(first),
										A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, first),
										A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, second));
								case 2:
									return $primait$pyxis_components$Prima$Pyxis$Form$Grid$emptyRow;
								default:
									return A2(
										$primait$pyxis_components$Prima$Pyxis$Form$Grid$withTwoColumns,
										$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(first),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-row__item__column')
													]),
												A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, first)),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('form-row__item__column')
													]),
												A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, second))
											]));
							}
						}
					} else {
						break _v0$2;
					}
				} else {
					var first = fields.a;
					return $primait$pyxis_components$Prima$Pyxis$Form$isRadioFlagField(first) ? $primait$pyxis_components$Prima$Pyxis$Form$Grid$withOneColumn(
						_Utils_ap(
							$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(first),
							A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, first))) : A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Grid$withTwoColumns,
						$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(first),
						A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, first));
				}
			} else {
				break _v0$2;
			}
		}
		return $primait$pyxis_components$Prima$Pyxis$Form$Grid$emptyRow;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$renderVerticalField = F2(
	function (model, field) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-row__item is-vertical')
				]),
			_Utils_ap(
				$primait$pyxis_components$Prima$Pyxis$Form$renderLabel(field),
				A2($primait$pyxis_components$Prima$Pyxis$Form$renderField, model, field)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$buildVerticalRow = F2(
	function (model, fields) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-row is-vertical')
				]),
			A2(
				$elm$core$List$map,
				$primait$pyxis_components$Prima$Pyxis$Form$renderVerticalField(model),
				fields));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$create = _List_Nil;
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$renderColumn = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('form-row__item')
		]));
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$rowStructToChildCount = function (rowStruct) {
	switch (rowStruct.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		default:
			return 4;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$toListOfChildren = function (rowStruct) {
	switch (rowStruct.$) {
		case 0:
			return _List_Nil;
		case 1:
			var child = rowStruct.a;
			return _List_fromArray(
				[child]);
		case 2:
			var first = rowStruct.a.bj;
			var second = rowStruct.a.bE;
			return _List_fromArray(
				[first, second]);
		case 3:
			var first = rowStruct.a.bj;
			var second = rowStruct.a.bE;
			var third = rowStruct.a.eC;
			return _List_fromArray(
				[first, second, third]);
		default:
			var first = rowStruct.a.bj;
			var second = rowStruct.a.bE;
			var third = rowStruct.a.eC;
			var fourth = rowStruct.a.jg;
			return _List_fromArray(
				[first, second, third, fourth]);
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$renderRow = function (_v0) {
	var rowStruct = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-row'),
				A2(
				$elm$html$Html$Attributes$attribute,
				'data-children-count',
				$elm$core$String$fromInt(
					$primait$pyxis_components$Prima$Pyxis$Form$Grid$rowStructToChildCount(rowStruct)))
			]),
		A2(
			$elm$core$List$map,
			$primait$pyxis_components$Prima$Pyxis$Form$Grid$renderColumn,
			$primait$pyxis_components$Prima$Pyxis$Form$Grid$toListOfChildren(rowStruct)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Grid$render = function (_v0) {
	var grid = _v0;
	return A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Form$Grid$renderRow, grid);
};
var $primait$pyxis_components$Prima$Pyxis$Form$renderFields = F3(
	function (model, kind, fields) {
		if (kind === 2) {
			return A2(
				$elm$core$List$map,
				$primait$pyxis_components$Prima$Pyxis$Form$buildVerticalRow(model),
				fields);
		} else {
			return $elm$core$List$concat(
				A2(
					$elm$core$List$map,
					$primait$pyxis_components$Prima$Pyxis$Form$Grid$render,
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeL,
							A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $primait$pyxis_components$Prima$Pyxis$Form$Grid$addRow, $primait$pyxis_components$Prima$Pyxis$Form$Grid$create),
							A2($primait$pyxis_components$Prima$Pyxis$Form$buildGridRow, model, kind)),
						fields)));
		}
	});
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $primait$pyxis_components$Prima$Pyxis$Form$renderLegend = function (_v0) {
	var title = _v0.a;
	var prependableHtml = _v0.b;
	var appendableHtml = _v0.c;
	return A2(
		$elm$html$Html$legend,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form__fieldset__legend')
			]),
		_List_fromArray(
			[
				A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
				$elm$core$List$length(prependableHtml) > 0,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form__fieldset__legend__prepend')
						]),
					prependableHtml)),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form__fieldset__legend__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					])),
				A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
				$elm$core$List$length(appendableHtml) > 0,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form__fieldset__legend__append')
						]),
					appendableHtml))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Form$renderFieldset = F3(
	function (model, kind, fieldset) {
		if (!fieldset.$) {
			var fields = fieldset.a;
			return A3(
				$primait$pyxis_components$Prima$Pyxis$Form$renderFields,
				model,
				kind,
				$elm$core$List$singleton(fields));
		} else {
			var legend_ = fieldset.a;
			var fields = fieldset.b;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$fieldset,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form__fieldset')
						]),
					A2(
						$elm$core$List$cons,
						$primait$pyxis_components$Prima$Pyxis$Form$renderLegend(legend_),
						A3($primait$pyxis_components$Prima$Pyxis$Form$renderFields, model, kind, fields)))
				]);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$render = F2(
	function (model, _v0) {
		var formConfig = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form')
				]),
			$elm$core$List$concat(
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Form$renderFieldset, model, formConfig.cc),
					formConfig.jc)));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$SelectField = function (a) {
	return {$: 4, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$SelectFieldConfig = F5(
	function (msgMapper, state, config, label, appendableHtml) {
		return {f: appendableHtml, iC: config, n6: label, aD: msgMapper, cG: state};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$select = F3(
	function (msgMapper, state, config) {
		return $primait$pyxis_components$Prima$Pyxis$Form$SelectField(
			A5($primait$pyxis_components$Prima$Pyxis$Form$SelectFieldConfig, msgMapper, state, config, $elm$core$Maybe$Nothing, _List_Nil));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Select = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Select$select = _List_Nil;
var $author$project$Guarantees$Helpers$pickValueOptionByKey = F2(
	function (key, guaranteeOption) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.p8;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.fR;
						},
						$elm$core$Basics$eq(key)),
					guaranteeOption.hZ)));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$selectChoiceFromGuaranteeOption = function (option) {
	var slug = option.bH;
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Form$Select$selectChoice,
		slug,
		A2(
			$elm$core$Maybe$withDefault,
			slug,
			A2($author$project$Guarantees$Helpers$pickValueOptionByKey, 'name', option)));
};
var $author$project$Guarantees$View$V2$DetailsOptions$selectChoiceListFromOptionGroup = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickOptionList,
	$elm$core$List$map($author$project$Guarantees$View$V2$DetailsOptions$selectChoiceFromGuaranteeOption));
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Disabled = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$addOption = F2(
	function (option, _v0) {
		var options = _v0;
		return _Utils_ap(
			options,
			_List_fromArray(
				[option]));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$withDisabled = function (disabled) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Select$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Select$Disabled(disabled));
};
var $primait$pyxis_components$Prima$Pyxis$Form$WithoutLegend = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$withFields = F2(
	function (fields, _v0) {
		var formConfig = _v0;
		return _Utils_update(
			formConfig,
			{
				jc: A2(
					$elm$core$List$append,
					formConfig.jc,
					$elm$core$List$singleton(
						$primait$pyxis_components$Prima$Pyxis$Form$WithoutLegend(fields)))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Large = 2;
var $primait$pyxis_components$Prima$Pyxis$Form$Select$Size = function (a) {
	return {$: 10, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$withLargeSize = $primait$pyxis_components$Prima$Pyxis$Form$Select$addOption(
	$primait$pyxis_components$Prima$Pyxis$Form$Select$Size(2));
var $author$project$Guarantees$View$V2$DetailsOptions$editOptionGroupSelectView = F4(
	function (selectSlug, disabled, editOptionsSelectState, group) {
		var selectedOptionValue = A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.bH;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function ($) {
						return $.pC;
					},
					$author$project$Guarantees$Helpers$pickOptionList(group))));
		var choicesList = $author$project$Guarantees$View$V2$DetailsOptions$selectChoiceListFromOptionGroup(group);
		var hasLongLabel = A2(
			$elm$core$List$any,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.n6;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$length,
					$elm$core$Basics$lt(20))),
			choicesList);
		var selectModel = A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Select$withDisabled,
			disabled,
			A3($author$project$Utils$applyIf, hasLongLabel, $primait$pyxis_components$Prima$Pyxis$Form$Select$withLargeSize, $primait$pyxis_components$Prima$Pyxis$Form$Select$select));
		var selectState = A2(
			$elm$core$Maybe$withDefault,
			A2($primait$pyxis_components$Prima$Pyxis$Form$Select$initWithDefault, selectedOptionValue, choicesList),
			A2($elm$core$Dict$get, selectSlug, editOptionsSelectState));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Guarantees$Helpers$pickOptionGroupLabel(group))
						])),
					A2(
					$primait$pyxis_components$Prima$Pyxis$Form$render,
					editOptionsSelectState,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$withFields,
						_List_fromArray(
							[
								A3(
								$primait$pyxis_components$Prima$Pyxis$Form$select,
								A3($author$project$Guarantees$Model$EditOptionSelectMsg, selectSlug, choicesList, selectedOptionValue),
								selectState,
								selectModel)
							]),
						$primait$pyxis_components$Prima$Pyxis$Form$init))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionsGroupView = F4(
	function (slug, label, editOptionsSelectState, group) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-align-left margin-top--10')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fw-base')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-options-modal__option-select')
						]),
					_List_fromArray(
						[
							A4($author$project$Guarantees$View$V2$DetailsOptions$editOptionGroupSelectView, slug, false, editOptionsSelectState, group)
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType = function (type_) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$filter($author$project$Guarantees$Helpers$isOptionGroupCoverageDefault),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filter(
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$pickOptionGroupType,
					$elm$core$Basics$eq(type_))),
			$elm$core$List$head));
};
var $author$project$PrimaElm$Lib$Utils$Render$maybeMapRender = F2(
	function (renderer, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return renderer(a);
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionDeductibleView = F2(
	function (_v0, editOptionsSelectState) {
		var slug = _v0.bH;
		var optionGroups = _v0.g2;
		var guarantees = _v0.nF;
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
			A3(
				$author$project$Guarantees$View$V2$DetailsOptions$clusterOptionsGroupView,
				slug + '-deductible',
				A2($author$project$Guarantees$View$V2$DetailsOptions$buildClusterOptionGroupLabel, 'deductible', guarantees),
				editOptionsSelectState),
			A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'deductible', optionGroups));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteeDeductibleView = F2(
	function (editOptionsSelectState, _v0) {
		var slug = _v0.bH;
		var label = _v0.n6;
		var optionsGroup = _v0.g3;
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
			A3($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionsGroupView, slug, label, editOptionsSelectState),
			A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'deductible', optionsGroup));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteesDeductibleView = F2(
	function (_v0, editOptionsSelectState) {
		var guarantees = _v0.nF;
		return A2(
			$elm$core$List$map,
			$author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteeDeductibleView(editOptionsSelectState),
			A2(
				$elm$core$List$filter,
				$author$project$Guarantees$View$V2$DetailsOptions$hasOptionGroupDefaultType('deductible'),
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.js;
						},
						$elm$core$Basics$eq(0)),
					A2($elm$core$List$map, $author$project$Types$Quote$pickGuarantee, guarantees))));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteeLimitView = F2(
	function (editOptionsSelectState, _v0) {
		var slug = _v0.bH;
		var label = _v0.n6;
		var optionsGroup = _v0.g3;
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
			A3($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionsGroupView, slug, label, editOptionsSelectState),
			A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'limit', optionsGroup));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteesLimitView = F2(
	function (_v0, editOptionsSelectState) {
		var guarantees = _v0.nF;
		return A2(
			$elm$core$List$map,
			$author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteeLimitView(editOptionsSelectState),
			A2(
				$elm$core$List$filter,
				$author$project$Guarantees$View$V2$DetailsOptions$hasOptionGroupDefaultType('limit'),
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.js;
						},
						$elm$core$Basics$eq(0)),
					A2($elm$core$List$map, $author$project$Types$Quote$pickGuarantee, guarantees))));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionLimitView = F2(
	function (_v0, editOptionsSelectState) {
		var slug = _v0.bH;
		var optionGroups = _v0.g2;
		var guarantees = _v0.nF;
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
			A3(
				$author$project$Guarantees$View$V2$DetailsOptions$clusterOptionsGroupView,
				slug + '-limit',
				A2($author$project$Guarantees$View$V2$DetailsOptions$buildClusterOptionGroupLabel, 'limit', guarantees),
				editOptionsSelectState),
			A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'limit', optionGroups));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterContentView = F2(
	function (cluster, editOptionsSelectState) {
		return _Utils_ap(
			_List_fromArray(
				[
					A2($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionDeductibleView, cluster, editOptionsSelectState),
					A2($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionLimitView, cluster, editOptionsSelectState)
				]),
			_Utils_ap(
				A2($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteesDeductibleView, cluster, editOptionsSelectState),
				A2($author$project$Guarantees$View$V2$DetailsOptions$clusterOptionGuaranteesLimitView, cluster, editOptionsSelectState)));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$priceView = function (price) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fw-base fs-medium c-text-base')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fs-base')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Utils$pricify(price))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fs-small')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(' /anno')
					]))
			]));
};
var $author$project$Guarantees$View$V2$DetailsOptions$optionTitleView = F3(
	function (price, slug, title) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-options-modal__option-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex text-align-left')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$View$Svg$svg,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-options-modal__option-icon')
								]),
							'garanzie/v2/',
							slug),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-options-modal__option-title')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fw-base fs-medium')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(title)
												])),
											$author$project$Guarantees$View$V2$DetailsOptions$priceView(price)
										]))
								]))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-options-modal__option-toggle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Modifica')
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterOptionTitleView = function (cluster) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
		A2(
			$author$project$Guarantees$View$V2$DetailsOptions$optionTitleView,
			A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0),
			cluster.bH),
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.eE;
			},
			cluster.mF));
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$AccordionConfig = F6(
	function (type_, slug, tagger, title, content, options) {
		return {mQ: content, ku: options, bH: slug, hS: tagger, eE: title, ao: type_};
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Accordion$Light = 2;
var $primait$pyxis_components$Prima$Pyxis$Accordion$light = F2(
	function (slug, tagger) {
		return A6($primait$pyxis_components$Prima$Pyxis$Accordion$AccordionConfig, 2, slug, tagger, $elm$core$Maybe$Nothing, _List_Nil, _List_Nil);
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var attribute = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attribute, options.eY)
					});
			case 1:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						cS: A2(
							$elm$core$List$cons,
							_Utils_Tuple2(_class, true),
							options.cS)
					});
			default:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						e4: A2($elm$core$List$cons, _class, options.e4)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$defaultOptions = {eY: _List_Nil, e4: _List_Nil, cS: _List_Nil};
var $primait$pyxis_components$Prima$Pyxis$Accordion$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Accordion$applyOption, $primait$pyxis_components$Prima$Pyxis$Accordion$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$buildContentClass = function (config) {
	var options = $primait$pyxis_components$Prima$Pyxis$Accordion$computeOptions(config);
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$append,
			'accordion__content',
			A2($elm$core$String$join, ' ', options.e4)));
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$buildWrapperClass = F2(
	function (config, classes) {
		var options = $primait$pyxis_components$Prima$Pyxis$Accordion$computeOptions(config);
		return $elm$html$Html$Attributes$classList(
			A2($elm$core$List$append, classes, options.cS));
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$Base = 0;
var $primait$pyxis_components$Prima$Pyxis$Accordion$isBase = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Accordion$Dark = 1;
var $primait$pyxis_components$Prima$Pyxis$Accordion$isDark = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Accordion$isLight = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Accordion$render = F2(
	function (_v0, config) {
		var isOpen = _v0;
		var type_ = config.ao;
		var tagger = config.hS;
		var slug = config.bH;
		var title = config.eE;
		var content = config.mQ;
		var options = $primait$pyxis_components$Prima$Pyxis$Accordion$computeOptions(config);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				options.eY,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(slug),
						A2(
						$primait$pyxis_components$Prima$Pyxis$Accordion$buildWrapperClass,
						config,
						_List_fromArray(
							[
								_Utils_Tuple2('accordion', true),
								_Utils_Tuple2(
								'accordion--base',
								$primait$pyxis_components$Prima$Pyxis$Accordion$isBase(type_)),
								_Utils_Tuple2(
								'accordion--light',
								$primait$pyxis_components$Prima$Pyxis$Accordion$isLight(type_)),
								_Utils_Tuple2(
								'accordion--dark',
								$primait$pyxis_components$Prima$Pyxis$Accordion$isDark(type_)),
								_Utils_Tuple2('is-open', isOpen)
							]))
					])),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('accordion__toggle'),
							$elm$html$Html$Events$onClick(
							A2(tagger, slug, isOpen))
						]),
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$withDefault,
							$elm$html$Html$text(''),
							title),
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('accordion__toggle__icon')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$primait$pyxis_components$Prima$Pyxis$Accordion$buildContentClass(config)
						]),
					content)
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$withContent = F2(
	function (content, _v0) {
		var accordionConfig = _v0;
		return _Utils_update(
			accordionConfig,
			{mQ: content});
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$ContentClass = function (a) {
	return {$: 2, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$addOption = F2(
	function (option, _v0) {
		var accordionConfig = _v0;
		return _Utils_update(
			accordionConfig,
			{
				ku: _Utils_ap(
					accordionConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Accordion$withContentClass = function (class_) {
	return $primait$pyxis_components$Prima$Pyxis$Accordion$addOption(
		$primait$pyxis_components$Prima$Pyxis$Accordion$ContentClass(class_));
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$withHtmlTitle = F2(
	function (title, _v0) {
		var accordionConfig = _v0;
		return _Utils_update(
			accordionConfig,
			{
				eE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Guarantees$View$V2$DetailsOptions$clusterAccordionView = F3(
	function (cluster, isOpen, editOptionsSelectState) {
		var slug = cluster.bH;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Accordion$render,
			$primait$pyxis_components$Prima$Pyxis$Accordion$state(isOpen),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Accordion$withContentClass,
				' v2-options-modal__accordion-content',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Accordion$withContent,
					A2($author$project$Guarantees$View$V2$DetailsOptions$clusterContentView, cluster, editOptionsSelectState),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Accordion$withHtmlTitle,
						$author$project$Guarantees$View$V2$DetailsOptions$clusterOptionTitleView(cluster),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Accordion$light,
							slug,
							A3(
								$author$project$Guarantees$Model$ToggleModalAccordion,
								$author$project$Guarantees$Model$OptionAccordion(0),
								slug,
								0))))));
	});
var $author$project$Guarantees$Model$OptionAccordionTypeGuarantee = 1;
var $author$project$Guarantees$View$V2$DetailsOptions$clusterEditDetailsInfo = function (clusterLabel) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-row align-items-left')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('icon icon-info v2-options-modal__accordion-info-icon')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Scegli franchigie e massimali di ' + (clusterLabel + ' per modificare anche quelli di questa garanzia.'))
					]))
			]));
};
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeDeductibleOptionsGroupView = F4(
	function (slug, disabled, groups, editOptionsSelectState) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-options-modal__option-select')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					A3($author$project$Guarantees$View$V2$DetailsOptions$editOptionGroupSelectView, slug + '-deductible', disabled, editOptionsSelectState),
					A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'deductible', groups))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeLimitOptionsGroupView = F4(
	function (slug, disabled, groups, editOptionsSelectState) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-options-modal__option-select')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					A3($author$project$Guarantees$View$V2$DetailsOptions$editOptionGroupSelectView, slug + '-limit', disabled, editOptionsSelectState),
					A2($author$project$Guarantees$View$V2$DetailsOptions$findDefaultOptionGroupByType, 'limit', groups))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeOptionsGroupView = F5(
	function (slug, clusterLabel, disabled, groups, editOptionsSelectState) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('display-flex flex-direction-row text-align-left')
					]),
				_List_fromArray(
					[
						A4($author$project$Guarantees$View$V2$DetailsOptions$guaranteeDeductibleOptionsGroupView, slug, disabled, groups, editOptionsSelectState),
						A4($author$project$Guarantees$View$V2$DetailsOptions$guaranteeLimitOptionsGroupView, slug, disabled, groups, editOptionsSelectState)
					])),
				A2(
				$author$project$PrimaElm$Lib$Utils$Render$renderIf,
				disabled,
				$author$project$Guarantees$View$V2$DetailsOptions$clusterEditDetailsInfo(clusterLabel))
			]);
	});
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeContentView = F3(
	function (_v0, editOptionsSelectState, guarantee) {
		var optionGroups = _v0.g2;
		var label = _v0.n6;
		var _v1 = $author$project$Guarantees$Helpers$pickOptionGroups(guarantee);
		if (!_v1.b) {
			return A5(
				$author$project$Guarantees$View$V2$DetailsOptions$guaranteeOptionsGroupView,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
				label,
				true,
				optionGroups,
				editOptionsSelectState);
		} else {
			var guaranteeOptionGroups = _v1;
			return A5(
				$author$project$Guarantees$View$V2$DetailsOptions$guaranteeOptionsGroupView,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
				label,
				false,
				guaranteeOptionGroups,
				editOptionsSelectState);
		}
	});
var $author$project$Guarantees$Helpers$pickDescriptionDetails = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.m4;
	});
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeOptionTitleView = function (guarantee) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
		A2(
			$author$project$Guarantees$View$V2$DetailsOptions$optionTitleView,
			A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 0, 0),
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)),
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.eE;
			},
			$author$project$Guarantees$Helpers$pickDescriptionDetails(guarantee)));
};
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteeAccordionView = F5(
	function (cluster, areOpen, editOptionsSelectState, index, guarantee) {
		var tagger = A3(
			$author$project$Guarantees$Model$ToggleModalAccordion,
			$author$project$Guarantees$Model$OptionAccordion(1),
			cluster.bH,
			index);
		var isOpen = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Array$get, index, areOpen));
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Accordion$render,
			$primait$pyxis_components$Prima$Pyxis$Accordion$state(isOpen),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Accordion$withContentClass,
				' v2-options-modal__accordion-content',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Accordion$withContent,
					A3($author$project$Guarantees$View$V2$DetailsOptions$guaranteeContentView, cluster, editOptionsSelectState, guarantee),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Accordion$withHtmlTitle,
						$author$project$Guarantees$View$V2$DetailsOptions$guaranteeOptionTitleView(guarantee),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Accordion$light,
							$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
							tagger)))));
	});
var $author$project$Guarantees$View$V2$DetailsOptions$guaranteesAccordionListView = F3(
	function (cluster, areOpen, editOptionsSelectState) {
		var notIncludedGuarantees = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Guarantees$Helpers$pickGuaranteeType,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Basics$eq(0),
					$elm$core$Basics$not)),
			cluster.nF);
		return A2(
			$elm$core$List$indexedMap,
			A3($author$project$Guarantees$View$V2$DetailsOptions$guaranteeAccordionView, cluster, areOpen, editOptionsSelectState),
			notIncludedGuarantees);
	});
var $author$project$Guarantees$View$V2$DetailsOptions$editOptionsContentView = function (_v0) {
	var cluster = _v0.e3;
	var accordionState = _v0.h3;
	var editOptionsSelectState = _v0.ax;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-details-modal')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-options-modal__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('display-flex fs-large fw-base')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Modifica franchigie e massimali')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('display-flex fs-small')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Personalizza franchigie e massimali delle garanzie che hai aggiunto')
								]))
						])),
					A3($author$project$Guarantees$View$V2$DetailsOptions$clusterAccordionView, cluster, accordionState.fh.fI, editOptionsSelectState)
				]),
			A3($author$project$Guarantees$View$V2$DetailsOptions$guaranteesAccordionListView, cluster, accordionState.fh.eS, editOptionsSelectState)));
};
var $author$project$Guarantees$View$V2$DetailsOptions$editOptionsContents = function (payload) {
	return {
		mQ: _List_fromArray(
			[
				$author$project$Guarantees$View$V2$DetailsOptions$editOptionsContentView(payload)
			]),
		ds: $elm$core$Maybe$Nothing,
		eE: $elm$core$Maybe$Nothing
	};
};
var $author$project$Guarantees$Model$ExcludedAccordion = {$: 1};
var $author$project$Guarantees$Model$IncludedAccordion = {$: 0};
var $author$project$Guarantees$Model$AddingDependenciesDialog = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Guarantees$Model$GuaranteeCardSelection = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Guarantees$View$Helpers$updatePriceWithSelectedAncestorDependencies = F2(
	function (guaranteeSlug, price) {
		var guaranteeDependencies = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$elm$core$List$head(
				A2($author$project$Guarantees$View$Helpers$getGuaranteeDependencies, guaranteeSlug, price)));
		return A2($author$project$Guarantees$View$Helpers$isSelectable, guaranteeSlug, price) ? price : A3(
			$elm$core$List$foldl,
			$author$project$Guarantees$View$Helpers$updatePriceWithSelectedAncestorDependencies,
			A3(
				$author$project$Types$Quote$Selection$multiUpdateIncludedGuaranteeSelection,
				guaranteeDependencies,
				true,
				A3($author$project$Types$Quote$Selection$multiUpdateOptionalGuaranteeSelection, guaranteeDependencies, true, price)),
			guaranteeDependencies);
	});
var $author$project$Guarantees$View$Helpers$getNotSelectedAncestorDependencies = F2(
	function (guaranteeSlug, price) {
		return A2(
			$author$project$Guarantees$View$Helpers$getSelectedGuaranteesDifference,
			A2($author$project$Guarantees$View$Helpers$updatePriceWithSelectedAncestorDependencies, guaranteeSlug, price),
			price);
	});
var $author$project$Guarantees$View$Helpers$getNotSelectedGuaranteeActionBtnMsg = F2(
	function (price, guaranteeFrequency) {
		return A2(
			$author$project$Guarantees$View$Helpers$isSelectable,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency),
			price) ? $author$project$Guarantees$Model$OnToggle(
			A2($author$project$Guarantees$Model$GuaranteeCardSelection, guaranteeFrequency, true)) : $author$project$Guarantees$Model$OpenDependenciesDialog(
			A3(
				$author$project$Guarantees$Model$AddingDependenciesDialog,
				A2($author$project$Guarantees$Model$GuaranteeCardSelection, guaranteeFrequency, true),
				$author$project$Guarantees$Helpers$pickGuaranteeLabel(guaranteeFrequency),
				A2(
					$author$project$Guarantees$View$Helpers$getNotSelectedAncestorDependencies,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency),
					price)));
	});
var $author$project$Guarantees$View$Helpers$getSelectedGuaranteeActionBtnMsg = F2(
	function (price, guaranteeFrequency) {
		var _v0 = A3($author$project$Guarantees$View$Helpers$getGuaranteeDescendantDependencies, price, guaranteeFrequency, false);
		if (!_v0.b) {
			return $author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$GuaranteeCardSelection, guaranteeFrequency, false));
		} else {
			var descendantDependenciesList = _v0;
			return $author$project$Guarantees$Model$OpenDependenciesDialog(
				A3(
					$author$project$Guarantees$Model$RemovingDependenciesDialog,
					A2($author$project$Guarantees$Model$GuaranteeCardSelection, guaranteeFrequency, false),
					$author$project$Guarantees$Helpers$pickGuaranteeLabel(guaranteeFrequency),
					descendantDependenciesList));
		}
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$addGuaranteeButtonView = F3(
	function (price, guaranteeFrequency, isDisabled) {
		var isSelected = $author$project$Guarantees$Helpers$pickGuaranteeSelected(guaranteeFrequency);
		var buttonLabel = A3($author$project$Utils$ternaryOperator, isSelected, 'rimuovi garanzia', 'aggiungi garanzia');
		return $primait$pyxis_components$Prima$Pyxis$Button$render(
			$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					isDisabled,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withClass,
						'v2-details-modal__action',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
							A3(
								$author$project$Utils$ternaryOperator,
								isSelected,
								A2($author$project$Guarantees$View$Helpers$getSelectedGuaranteeActionBtnMsg, price, guaranteeFrequency),
								A2($author$project$Guarantees$View$Helpers$getNotSelectedGuaranteeActionBtnMsg, price, guaranteeFrequency)),
							$primait$pyxis_components$Prima$Pyxis$Button$tertiary(buttonLabel))))));
	});
var $author$project$Guarantees$Helpers$calculateSingleGuaranteeDependencies = function (price) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Helpers$pickGuaranteeDependencies,
		$elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.nF;
				},
				$elm$core$List$filterMap(
					A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Helpers$pickGuarantee, price)))));
};
var $author$project$Guarantees$Helpers$guaranteeDependenciesFolder = F2(
	function (price, guarantee) {
		return A2(
			$elm$core$Dict$insert,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
			A2($author$project$Guarantees$Helpers$calculateSingleGuaranteeDependencies, price, guarantee));
	});
var $author$project$Guarantees$Helpers$calculateGuaranteesDependenciesRules = function (price) {
	return A3(
		$elm$core$List$foldl,
		$author$project$Guarantees$Helpers$guaranteeDependenciesFolder(price),
		$elm$core$Dict$empty,
		$author$project$Guarantees$Helpers$pickGuarantees(price));
};
var $author$project$Guarantees$Model$DetailsAccordion = {$: 2};
var $author$project$Guarantees$View$V2$DetailsGuarantee$coverageDetailAccordionView = F4(
	function (guaranteeSlug, areDetailsOpen, index, _v0) {
		var title = _v0.eE;
		var description = _v0.m2;
		var isAccordionOpen = A2(
			$elm$core$Maybe$withDefault,
			true,
			A2($elm$core$Array$get, index, areDetailsOpen));
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Accordion$render,
			$primait$pyxis_components$Prima$Pyxis$Accordion$state(isAccordionOpen),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Accordion$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Utils$htmlRender(description)
							]))
					]),
				A2(
					$primait$pyxis_components$Prima$Pyxis$Accordion$withHtmlTitle,
					$elm$html$Html$text(title),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Accordion$light,
						'coverage-detail-' + $elm$core$String$fromInt(index),
						A3($author$project$Guarantees$Model$ToggleModalAccordion, $author$project$Guarantees$Model$DetailsAccordion, guaranteeSlug, index)))));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$coverageDetailsView = F2(
	function (guaranteeSlug, areDetailsOpen) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$List$indexedMap(
				A2($author$project$Guarantees$View$V2$DetailsGuarantee$coverageDetailAccordionView, guaranteeSlug, areDetailsOpen)),
			A2(
				$elm$core$Basics$composeR,
				$elm$html$Html$div(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-details-modal__coverage-list')
						])),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$elm$html$Html$div(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-base fs-base')
							])))));
	});
var $author$project$Guarantees$Helpers$isGuaranteesGroupSelected = $elm$core$List$all($author$project$Guarantees$Helpers$pickGuaranteeSelected);
var $author$project$Guarantees$Helpers$isAnyOfGuaranteesGroupSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Utils$fromListToMaybe,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(
			$elm$core$List$any($author$project$Guarantees$Helpers$isGuaranteesGroupSelected)),
		$elm$core$Maybe$withDefault(true)));
var $author$project$Guarantees$Helpers$isGuaranteeDependenciesSelected = F2(
	function (dependencies, guarantee) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$author$project$Guarantees$Helpers$isAnyOfGuaranteesGroupSelected,
				A2(
					$elm$core$Dict$get,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					dependencies)));
	});
var $author$project$Utils$mapSingleton = F2(
	function (mapper, list) {
		if (list.b && (!list.b.b)) {
			var element = list.a;
			return $elm$core$Maybe$Just(
				mapper(element));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Guarantees$Helpers$getSelectedTypeCoverageOption = F2(
	function (type_, optionGroups) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.pC;
				},
				A2(
					$elm$core$List$concatMap,
					$author$project$Guarantees$Helpers$pickOptionList,
					A2(
						$elm$core$List$filter,
						$author$project$Guarantees$Helpers$isOptionGroupCoverageDefault,
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Guarantees$Helpers$pickOptionGroupType,
								$elm$core$Basics$eq(type_)),
							optionGroups)))));
	});
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $author$project$Guarantees$Helpers$findDefaultTypeOption = F3(
	function (type_, cluster, guarantee) {
		return A2(
			$elm_community$maybe_extra$Maybe$Extra$or,
			A2(
				$author$project$Guarantees$Helpers$getSelectedTypeCoverageOption,
				type_,
				$author$project$Guarantees$Helpers$pickOptionGroups(guarantee)),
			A3(
				$elm$core$Basics$composeR,
				function ($) {
					return $.g2;
				},
				$author$project$Guarantees$Helpers$getSelectedTypeCoverageOption(type_),
				cluster));
	});
var $author$project$Guarantees$Helpers$findDeductibleOption = $author$project$Guarantees$Helpers$findDefaultTypeOption('deductible');
var $author$project$Guarantees$Helpers$findLimitOption = $author$project$Guarantees$Helpers$findDefaultTypeOption('limit');
var $author$project$Guarantees$Model$GuaranteeAccordion = function (a) {
	return {$: 2, a: a};
};
var $author$project$Guarantees$View$V2$DetailsGuarantee$optionView = F4(
	function (title, cluster, guaranteeFrequency, option) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__option-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw-base')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
									$elm$html$Html$text,
									A2($author$project$Guarantees$Helpers$pickValueOptionByKey, 'name', option))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$primait$pyxis_components$Prima$Pyxis$Link$render(
							A2(
								$primait$pyxis_components$Prima$Pyxis$Link$withOnClick,
								A2(
									$author$project$Guarantees$Model$OpenEditOptionsDetailModal,
									cluster,
									$author$project$Guarantees$Model$GuaranteeAccordion(guaranteeFrequency)),
								A2(
									$primait$pyxis_components$Prima$Pyxis$Link$withClass,
									'cursor-pointer fs-small',
									$primait$pyxis_components$Prima$Pyxis$Link$simple('MODIFICA'))))
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$optionsView = F2(
	function (cluster, guaranteeFrequency) {
		var limitOption = A2($author$project$Guarantees$Helpers$findLimitOption, cluster, guaranteeFrequency);
		var deductibleOption = A2($author$project$Guarantees$Helpers$findDeductibleOption, cluster, guaranteeFrequency);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__option-list')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					A3($author$project$Guarantees$View$V2$DetailsGuarantee$optionView, 'Franchigia: ', cluster, guaranteeFrequency),
					deductibleOption),
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					A3($author$project$Guarantees$View$V2$DetailsGuarantee$optionView, 'Massimale: ', cluster, guaranteeFrequency),
					limitOption)
				]));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$priceGuaranteeView = function (guarantee) {
	var price = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 1, 0);
	return A2($author$project$Guarantees$View$V2$Details$priceView, price, fullPrice);
};
var $elm_community$list_extra$List$Extra$unconsLast = function (list) {
	var _v0 = $elm$core$List$reverse(list);
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var last_ = _v0.a;
		var rest = _v0.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(
				last_,
				$elm$core$List$reverse(rest)));
	}
};
var $author$project$Guarantees$View$V2$Details$getCardDependenciesText = function (dependenciesLabels) {
	var _v0 = $elm_community$list_extra$List$Extra$unconsLast(dependenciesLabels);
	if (!_v0.$) {
		if (!_v0.a.b.b) {
			var _v1 = _v0.a;
			var lastDependencyLabel = _v1.a;
			return 'Acquistabile con ' + lastDependencyLabel;
		} else {
			var _v2 = _v0.a;
			var lastDependencyLabel = _v2.a;
			var dependenciesLabelsBody = _v2.b;
			return 'Acquistabile con ' + (A2($elm$core$String$join, ', ', dependenciesLabelsBody) + (' o ' + lastDependencyLabel));
		}
	} else {
		return '';
	}
};
var $author$project$Guarantees$Helpers$isGuaranteeInCluster = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.nF;
		},
		$elm$core$List$any(
			A2(
				$elm$core$Basics$composeR,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug,
				$elm$core$Basics$eq(guaranteeSlug))));
};
var $author$project$Guarantees$Helpers$isGuaranteeInPackage = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.mG;
		},
		$elm$core$List$any(
			$author$project$Guarantees$Helpers$isGuaranteeInCluster(guaranteeSlug)));
};
var $author$project$Guarantees$Helpers$pickPackageByGuaranteeSlug = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Helpers$pickPackagesFromQuotePrice,
		$elm_community$list_extra$List$Extra$find(
			$author$project$Guarantees$Helpers$isGuaranteeInPackage(guaranteeSlug)));
};
var $author$project$Guarantees$Helpers$pickClusterByGuaranteeSlug = function (guaranteeSlug) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Helpers$pickPackageByGuaranteeSlug(guaranteeSlug),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				function ($) {
					return $.mG;
				}),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					$elm_community$list_extra$List$Extra$find(
						$author$project$Guarantees$Helpers$isGuaranteeInCluster(guaranteeSlug))),
				$elm_community$maybe_extra$Maybe$Extra$join)));
};
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$Guarantees$View$V2$Details$getCardDependenciesByGroupText = F2(
	function (price, dependenciesList) {
		var dependenciesListSlugs = A2($elm$core$List$map, $author$project$Guarantees$Helpers$pickGuaranteeSlug, dependenciesList);
		var containsAllIncludedGuarantees = function (_v0) {
			var guarantees = _v0.nF;
			return A2(
				$elm$core$List$all,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug,
					A2($elm_community$basics_extra$Basics$Extra$flip, $elm$core$List$member, dependenciesListSlugs)),
				A2($elm$core$List$filter, $author$project$Guarantees$Helpers$isGuaranteeIncluded, guarantees));
		};
		var dependantCluster = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				containsAllIncludedGuarantees,
				A2(
					$elm_community$list_extra$List$Extra$uniqueBy,
					function ($) {
						return $.bH;
					},
					A2(
						$elm$core$List$filterMap,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Guarantees$Helpers$pickGuaranteeSlug,
							A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Guarantees$Helpers$pickClusterByGuaranteeSlug, price)),
						dependenciesList))));
		var filteredDependenciesList = A2(
			$elm$core$Maybe$withDefault,
			A2($elm$core$List$map, $author$project$Guarantees$Helpers$pickGuaranteeLabel, dependenciesList),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.n6;
					},
					$elm$core$List$singleton),
				dependantCluster));
		return A2($elm$core$String$join, ', ', filteredDependenciesList);
	});
var $author$project$Guarantees$View$V2$Details$getDependenciesLabels = F2(
	function (price, dependenciesList) {
		return A2(
			$elm$core$List$map,
			$author$project$Guarantees$View$V2$Details$getCardDependenciesByGroupText(price),
			dependenciesList);
	});
var $author$project$Guarantees$View$V2$Details$guaranteeCardDependenciesView = F2(
	function (price, dependenciesList) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-label__dependency')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$View$Svg$svg,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__dependency-icon')
						]),
					'',
					'link'),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__dependency-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Guarantees$View$V2$Details$getCardDependenciesText(
								A2($author$project$Guarantees$View$V2$Details$getDependenciesLabels, price, dependenciesList)))
						]))
				]));
	});
var $author$project$PrimaElm$Lib$Utils$Render$renderMaybe = function (theMaybe) {
	if (!theMaybe.$) {
		var html = theMaybe.a;
		return html;
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Guarantees$View$V2$Details$singleGuaranteeCardDependenciesView = F3(
	function (price, dependencies, guarantee) {
		return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				$author$project$Guarantees$View$V2$Details$guaranteeCardDependenciesView(price),
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Utils$fromListToMaybe,
					A2(
						$elm$core$Maybe$map,
						A3(
							$author$project$Utils$ternaryOperator,
							A2(
								$author$project$Guarantees$View$Helpers$isSelectable,
								$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
								price),
							$elm$core$Basics$always(_List_Nil),
							$elm$core$Basics$identity),
						A2(
							$elm$core$Dict$get,
							$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
							dependencies)))));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$getDescriptionIconViewBySlug = function (slug) {
	var iconName = function () {
		switch (slug) {
			case 'coverage-inclusions':
				return $elm$core$Maybe$Just('guarantee_includes');
			case 'coverage-exclusions':
				return $elm$core$Maybe$Just('guarantee_not_includes');
			default:
				return $elm$core$Maybe$Nothing;
		}
	}();
	return A2(
		$elm$core$Maybe$map,
		A2(
			$author$project$View$Svg$svg,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-details-modal__accordion-coverage__icon')
				]),
			'garanzie/v2/'),
		iconName);
};
var $author$project$Guarantees$View$V2$DetailsGuarantee$accordionContentView = F2(
	function (slug, elements) {
		var iconDescriptionView = function (description) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-details-modal__accordion-coverage__item')
					]),
				_List_fromArray(
					[
						$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
						$author$project$Guarantees$View$V2$DetailsGuarantee$getDescriptionIconViewBySlug(slug)),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-small fw-light')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(description)
							]))
					]));
		};
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-align-left fw-base fs-medium')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-details-modal__accordion-coverage__content')
						]),
					A2($elm$core$List$map, iconDescriptionView, elements))
				]));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$getTitleViewBySlug = function (slug) {
	var titleText = function () {
		switch (slug) {
			case 'coverage-inclusions':
				return $elm$core$Maybe$Just('Questa garanzia include:');
			case 'coverage-exclusions':
				return $elm$core$Maybe$Just('Questa garanzia non include:');
			default:
				return $elm$core$Maybe$Nothing;
		}
	}();
	return A2(
		$elm$core$Maybe$map,
		$elm$html$Html$span(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fs-base')
				])),
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $elm$html$Html$text, $elm$core$List$singleton),
			titleText));
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$WrapperClass = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Accordion$withWrapperClass = function (class_) {
	return $primait$pyxis_components$Prima$Pyxis$Accordion$addOption(
		$primait$pyxis_components$Prima$Pyxis$Accordion$WrapperClass(class_));
};
var $author$project$Guarantees$View$V2$DetailsGuarantee$twoColumnAccordionView = F5(
	function (guaranteeSlug, accordionSlug, accordionType, isOpen, elements) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Accordion$render,
			$primait$pyxis_components$Prima$Pyxis$Accordion$state(isOpen),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Accordion$withContent,
				_List_fromArray(
					[
						A2($author$project$Guarantees$View$V2$DetailsGuarantee$accordionContentView, accordionSlug, elements)
					]),
				A2(
					$primait$pyxis_components$Prima$Pyxis$Accordion$withHtmlTitle,
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
						$author$project$Guarantees$View$V2$DetailsGuarantee$getTitleViewBySlug(accordionSlug)),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Accordion$withWrapperClass,
						'v2-details-modal__accordion-coverage',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Accordion$light,
							accordionSlug,
							A3($author$project$Guarantees$Model$ToggleModalAccordion, accordionType, guaranteeSlug, 0))))));
	});
var $author$project$Guarantees$View$V2$DetailsGuarantee$guaranteeDetailsContentView = function (_v0) {
	var accordionState = _v0.h3;
	var cluster = _v0.e3;
	var guaranteeFrequency = _v0.nE;
	var details = _v0.m4;
	var price = _v0.oY;
	var isSingleClusterGuarantee = A2(
		$elm$core$Maybe$withDefault,
		false,
		A2($author$project$Utils$mapSingleton, $author$project$Guarantees$Helpers$isGuaranteeIncluded, cluster.nF));
	var shouldBeVisible = $author$project$Guarantees$Helpers$isGuaranteeOptional(guaranteeFrequency) || (isSingleClusterGuarantee && $author$project$Guarantees$Helpers$isGuaranteeIncluded(guaranteeFrequency));
	var guaranteeSlug = $author$project$Types$Quote$pickGuarantee(guaranteeFrequency).bH;
	var dependencies = $author$project$Guarantees$Helpers$calculateGuaranteesDependenciesRules(price);
	var isSelectionDisabled = isSingleClusterGuarantee && (!A2($author$project$Guarantees$Helpers$isGuaranteeDependenciesSelected, dependencies, guaranteeFrequency));
	var addGuaranteeOrClusterButton = A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		shouldBeVisible,
		A3(
			$author$project$Utils$ternaryOperator,
			isSingleClusterGuarantee,
			A3($author$project$Guarantees$View$V2$DetailsCluster$addClusterButtonView, price, cluster, isSelectionDisabled),
			A3($author$project$Guarantees$View$V2$DetailsGuarantee$addGuaranteeButtonView, price, guaranteeFrequency, isSelectionDisabled)));
	var _v1 = accordionState;
	var isIncludedOpen = _v1.jN;
	var isExcludedOpen = _v1.jM;
	var areDetailsOpen = _v1.eR;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-details-modal__content')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$View$Svg$svg,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-details-modal__icon'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('v2-card-label__icon--gray', isSelectionDisabled)
							]))
					]),
				'garanzie/v2/',
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency)),
				$author$project$Guarantees$View$V2$DetailsGuarantee$priceGuaranteeView(guaranteeFrequency),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-base fs-large')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(details.eE)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-align-center fs-small c-text-base')
					]),
				_List_fromArray(
					[
						$author$project$Utils$htmlRender(details.pH)
					])),
				addGuaranteeOrClusterButton,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-details-modal__dependencies')
					]),
				_List_fromArray(
					[
						A3($author$project$Guarantees$View$V2$Details$singleGuaranteeCardDependenciesView, price, dependencies, guaranteeFrequency)
					])),
				A2($author$project$Guarantees$View$V2$DetailsGuarantee$optionsView, cluster, guaranteeFrequency),
				A5($author$project$Guarantees$View$V2$DetailsGuarantee$twoColumnAccordionView, guaranteeSlug, 'coverage-inclusions', $author$project$Guarantees$Model$IncludedAccordion, isIncludedOpen, details.mW),
				A5($author$project$Guarantees$View$V2$DetailsGuarantee$twoColumnAccordionView, guaranteeSlug, 'coverage-exclusions', $author$project$Guarantees$Model$ExcludedAccordion, isExcludedOpen, details.mV),
				A3($author$project$Guarantees$View$V2$DetailsGuarantee$coverageDetailsView, guaranteeSlug, areDetailsOpen, details.mU),
				A2($author$project$Guarantees$View$V2$Details$coverageReasonsView, guaranteeSlug, details.pg),
				addGuaranteeOrClusterButton
			]));
};
var $author$project$Guarantees$View$V2$DetailsGuarantee$guaranteeDetailsContents = function (payload) {
	return {
		mQ: _List_fromArray(
			[
				$author$project$Guarantees$View$V2$DetailsGuarantee$guaranteeDetailsContentView(payload)
			]),
		ds: $elm$core$Maybe$Nothing,
		eE: $elm$core$Maybe$Nothing
	};
};
var $author$project$Guarantees$Update$Helper$modalContentPayload = function (navigableModalPayloadType) {
	switch (navigableModalPayloadType.$) {
		case 0:
			var detailsPayload = navigableModalPayloadType.a;
			return $author$project$Guarantees$View$V2$DetailsGuarantee$guaranteeDetailsContents(detailsPayload);
		case 1:
			var detailsPayload = navigableModalPayloadType.a;
			return $author$project$Guarantees$View$V2$DetailsCluster$clusterDetailsContents(detailsPayload);
		default:
			var detailsPayload = navigableModalPayloadType.a;
			return $author$project$Guarantees$View$V2$DetailsOptions$editOptionsContents(detailsPayload);
	}
};
var $author$project$Components$NavigableModal$NavigateToDynamic = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Components$NavigableModal$DynamicPage = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$NavigableModal$StaticPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$NavigableModal$mapPages = F2(
	function (mapper, _v0) {
		var modelConfig = _v0;
		var pages = modelConfig.aZ;
		return _Utils_update(
			modelConfig,
			{
				aZ: mapper(pages)
			});
	});
var $author$project$Components$NavigableModal$goBack = $author$project$Components$NavigableModal$mapPages(
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$tail,
		$elm$core$Maybe$withDefault(_List_Nil)));
var $author$project$Components$NavigableModal$pushPage = function (page) {
	return $author$project$Components$NavigableModal$mapPages(
		$elm$core$List$append(
			_List_fromArray(
				[page])));
};
var $author$project$Components$NavigableModal$setIsOpen = F2(
	function (isOpen, _v0) {
		var modelConfig = _v0;
		return _Utils_update(
			modelConfig,
			{jP: isOpen});
	});
var $author$project$Components$NavigableModal$setPages = F2(
	function (pages, _v0) {
		var modelConfig = _v0;
		return _Utils_update(
			modelConfig,
			{aZ: pages});
	});
var $author$project$Components$NavigableModal$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				return A2(
					$author$project$Components$NavigableModal$setPages,
					_List_Nil,
					A2($author$project$Components$NavigableModal$setIsOpen, false, model));
			case 3:
				return $author$project$Components$NavigableModal$goBack(model);
			case 0:
				var pagePayloadMapper = msg.a;
				var pageRenderer = msg.b;
				return A2(
					$author$project$Components$NavigableModal$setIsOpen,
					true,
					A2(
						$author$project$Components$NavigableModal$pushPage,
						A2($author$project$Components$NavigableModal$DynamicPage, pagePayloadMapper, pageRenderer),
						model));
			default:
				var modalPayload = msg.a;
				return A2(
					$author$project$Components$NavigableModal$setIsOpen,
					true,
					A2(
						$author$project$Components$NavigableModal$pushPage,
						$author$project$Components$NavigableModal$StaticPage(modalPayload),
						model));
		}
	});
var $author$project$Components$NavigableModal$pushDynamicPage = function (pagePayloadMapper) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Components$NavigableModal$NavigateToDynamic(pagePayloadMapper),
		$author$project$Components$NavigableModal$update);
};
var $author$project$Guarantees$Update$Helper$pushClusterDetailsPageInNavigableModal = F2(
	function (cluster, model) {
		var pagePayloadMapper = $author$project$Guarantees$Update$Helper$generateClusterDetailsPayload(cluster.bH);
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Guarantees$Model$mapUI,
			model,
			$author$project$Guarantees$Model$mapNavigableModalModel(
				A2($author$project$Components$NavigableModal$pushDynamicPage, pagePayloadMapper, $author$project$Guarantees$Update$Helper$modalContentPayload)));
	});
var $author$project$Guarantees$Model$EditOptionsPayload = F3(
	function (editOptionsSelectState, cluster, accordionState) {
		return {h3: accordionState, e3: cluster, ax: editOptionsSelectState};
	});
var $author$project$Guarantees$Model$EditOptionsPayloadType = function (a) {
	return {$: 2, a: a};
};
var $author$project$Guarantees$Update$Helper$generateEditOptionsPayload = F2(
	function (clusterSlug, model) {
		var maybeModalDetailsAccordionState = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.aS;
			},
			$elm$core$Dict$get(clusterSlug));
		var maybeCluster = A2(
			$elm$core$Maybe$andThen,
			$author$project$Types$Quote$Selection$pickClusterByClusterSlug(clusterSlug),
			model.k5);
		return A2(
			$elm$core$Maybe$map,
			$author$project$Guarantees$Model$EditOptionsPayloadType,
			A3(
				$elm$core$Maybe$map2,
				$author$project$Guarantees$Model$EditOptionsPayload(model.ax),
				maybeCluster,
				maybeModalDetailsAccordionState(model)));
	});
var $author$project$Guarantees$Update$Helper$pushEditOptionsPageInNavigableModal = F2(
	function (cluster, model) {
		var pagePayloadMapper = $author$project$Guarantees$Update$Helper$generateEditOptionsPayload(cluster.bH);
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Guarantees$Model$mapUI,
			model,
			$author$project$Guarantees$Model$mapNavigableModalModel(
				A2($author$project$Components$NavigableModal$pushDynamicPage, pagePayloadMapper, $author$project$Guarantees$Update$Helper$modalContentPayload)));
	});
var $author$project$Guarantees$Model$getQuoteFromQuoteResponse = function (quoteResponse) {
	if (!quoteResponse.$) {
		var save = quoteResponse.a;
		return $elm$core$Maybe$Just(save);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Guarantees$Model$getQuote = function (_v0) {
	var getQuoteResponse = _v0.fs;
	var getSaveResponse = _v0.ft;
	var maybeQuoteInSave = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.pd;
		},
		A2(
			$elm$core$Maybe$andThen,
			$author$project$Guarantees$Model$getSaveFromSaveResponse,
			$krisajenkins$remotedata$RemoteData$toMaybe(getSaveResponse)));
	var maybeQuote = A2(
		$elm$core$Maybe$andThen,
		$author$project$Guarantees$Model$getQuoteFromQuoteResponse,
		$krisajenkins$remotedata$RemoteData$toMaybe(getQuoteResponse));
	return A2($elm_community$maybe_extra$Maybe$Extra$or, maybeQuoteInSave, maybeQuote);
};
var $author$project$Guarantees$Model$pickPackageBySlug = function (slug) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.pB;
		},
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$concatMap(
				function ($) {
					return $.oM;
				}),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$concatMap(
					function ($) {
						return $.oN;
					}),
				$elm_community$list_extra$List$Extra$find(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(slug))))));
};
var $author$project$Guarantees$Update$Helper$pushPackagePriceV1 = function (_v0) {
	var price = _v0.oY;
	var userSelection = _v0.lQ;
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$pair('P'),
				$elm$core$List$singleton),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Guarantees$Helpers$calculatePackagePrice, userSelection),
					$prikhi$decimal$Decimal$toString),
				A2($author$project$Guarantees$Model$pickPackageBySlug, 'casa_protetta', price))));
};
var $author$project$Guarantees$Update$Helper$pushPackagePriceV2 = function (_v0) {
	var price = _v0.oY;
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$pair('P'),
				$elm$core$List$singleton),
			A2(
				$elm$core$Maybe$map,
				function (cluster) {
					return $prikhi$decimal$Decimal$toString(
						A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0));
				},
				A2($author$project$Types$Quote$Selection$pickClusterByClusterSlug, 'protezione_locali', price))));
};
var $author$project$Guarantees$Update$Helper$pushPackagePrice = function (model) {
	var packageMapper = function (viewConfig) {
		var quote = viewConfig.pd;
		var _v0 = quote.lU;
		if (!_v0) {
			return $author$project$Guarantees$Update$Helper$pushPackagePriceV1(viewConfig);
		} else {
			return $author$project$Guarantees$Update$Helper$pushPackagePriceV2(viewConfig);
		}
	};
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2($elm$core$Maybe$map, packageMapper, model.h$));
};
var $author$project$Guarantees$Update$Helper$pushFormDataToDataLayer = F2(
	function (event, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var updateReturn = _v0.c;
		var maybeSourceInfo = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.pI;
			},
			$author$project$Guarantees$Model$getQuote(model));
		var maybeInformation = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.nT;
			},
			$author$project$Guarantees$Model$getQuote(model));
		var dlCmd = function () {
			switch (event) {
				case 0:
					return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
						$author$project$Guarantees$Update$Helper$pushPackagePrice(model));
				case 1:
					return $author$project$Utils$GTM$generateTupleAndPushWithDefaultEvent(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Location',
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.my;
										},
										$elm$core$String$toLower),
									A2(
										$elm$core$Maybe$andThen,
										function ($) {
											return $.kY;
										},
										maybeInformation))),
								_Utils_Tuple2(
								'Province',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$toLower,
									$elm_community$maybe_extra$Maybe$Extra$join(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.o6;
											},
											A2(
												$elm$core$Maybe$andThen,
												function ($) {
													return $.kY;
												},
												maybeInformation))))),
								_Utils_Tuple2(
								'Proprietario',
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.oL;
										},
										A2($elm$core$Basics$composeR, $author$project$Types$Utils$ownershipToLabel, $elm$core$String$toLower)),
									maybeInformation)),
								_Utils_Tuple2(
								'Utilizzo',
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.o1;
										},
										A2($elm$core$Basics$composeR, $author$project$Types$Utils$propertyUsageTypeToLabel, $elm$core$String$toLower)),
									maybeInformation)),
								_Utils_Tuple2(
								'Immobile',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$toLower,
									A2(
										$elm$core$Maybe$map,
										$author$project$Types$Utils$propertyTypeToLabel,
										$elm_community$maybe_extra$Maybe$Extra$join(
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.o4;
												},
												maybeInformation))))),
								_Utils_Tuple2(
								'Dimensioni',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$fromInt,
									$elm_community$maybe_extra$Maybe$Extra$join(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.p3;
											},
											maybeInformation)))),
								_Utils_Tuple2(
								'Costruzione',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$toLower,
									A2(
										$elm$core$Maybe$map,
										$author$project$Types$Utils$constructionPeriodToLabel,
										$elm_community$maybe_extra$Maybe$Extra$join(
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.mP;
												},
												maybeInformation))))),
								_Utils_Tuple2(
								'S',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$fromInt,
									$elm_community$maybe_extra$Maybe$Extra$join(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.pn;
											},
											maybeInformation)))),
								_Utils_Tuple2(
								'Maggiorenni',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$fromInt,
									$elm_community$maybe_extra$Maybe$Extra$join(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.np;
											},
											maybeInformation)))),
								_Utils_Tuple2(
								'Minorenni',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$fromInt,
									$elm_community$maybe_extra$Maybe$Extra$join(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.nr;
											},
											maybeInformation)))),
								_Utils_Tuple2(
								'G',
								A2(
									$elm$core$Maybe$map,
									$author$project$Guarantees$Model$genderGTMParser,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.ny;
										},
										maybeInformation))),
								_Utils_Tuple2(
								'Età',
								A2(
									$elm$core$Maybe$map,
									$elm$core$String$fromInt,
									A3(
										$elm$core$Maybe$map2,
										$author$project$PrimaElm$Lib$Utils$FP$flip($author$project$Utils$calculateAge),
										model.iH,
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.mn;
											},
											maybeInformation)))),
								_Utils_Tuple2(
								'FloorType',
								A2(
									$elm$core$Maybe$andThen,
									$author$project$Types$Utils$floorTypeToValue,
									A2(
										$elm$core$Maybe$andThen,
										function ($) {
											return $.nw;
										},
										maybeInformation)))
							]));
				default:
					return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'QID',
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, '', $danyx23$elm_uuid$Uuid$toString, model.cy)),
								_Utils_Tuple2(
								'Qplatform',
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										$elm$core$String$toLower,
										A2(
											$elm$core$Maybe$andThen,
											function ($) {
												return $.ha;
											},
											maybeSourceInfo)))),
								_Utils_Tuple2(
								'Qoperator',
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										$elm$core$String$toLower,
										A2(
											$elm$core$Maybe$andThen,
											function ($) {
												return $.g0;
											},
											maybeSourceInfo)))),
								_Utils_Tuple2(
								'Qreferral',
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										$elm$core$String$toLower,
										A2(
											$elm$core$Maybe$andThen,
											function ($) {
												return $.hj;
											},
											maybeSourceInfo)))),
								_Utils_Tuple2('rt', 'nb')
							]));
			}
		}();
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[dlCmd, cmd])),
			updateReturn);
	});
var $author$project$Guarantees$Model$GuaranteeDetailsPayload = F5(
	function (guaranteeFrequency, cluster, accordionState, details, price) {
		return {h3: accordionState, e3: cluster, m4: details, nE: guaranteeFrequency, oY: price};
	});
var $author$project$Guarantees$Model$GuaranteeDetailsPayloadType = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$map5 = F6(
	function (func, ma, mb, mc, md, me) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						if (me.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var e = me.a;
							return $elm$core$Maybe$Just(
								A5(func, a, b, c, d, e));
						}
					}
				}
			}
		}
	});
var $author$project$Guarantees$Update$Helper$generateGuaranteeDetailsPayload = F3(
	function (guaranteeSlug, clusterSlug, model) {
		var maybeModalDetailsAccordionState = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.aS;
			},
			$elm$core$Dict$get(guaranteeSlug));
		var maybeCluster = A2(
			$elm$core$Maybe$andThen,
			$author$project$Types$Quote$Selection$pickClusterByClusterSlug(clusterSlug),
			model.k5);
		var maybeGuarantee = A2(
			$elm$core$Maybe$andThen,
			$elm_community$list_extra$List$Extra$find(
				A2(
					$elm$core$Basics$composeR,
					$author$project$Types$Quote$pickGuarantee,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(guaranteeSlug)))),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.nF;
				},
				maybeCluster));
		var maybeDetails = A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.m4;
			},
			A2($elm$core$Maybe$map, $author$project$Types$Quote$pickGuarantee, maybeGuarantee));
		return A2(
			$elm$core$Maybe$map,
			$author$project$Guarantees$Model$GuaranteeDetailsPayloadType,
			A6(
				$elm$core$Maybe$map5,
				$author$project$Guarantees$Model$GuaranteeDetailsPayload,
				maybeGuarantee,
				maybeCluster,
				maybeModalDetailsAccordionState(model),
				maybeDetails,
				model.k5));
	});
var $author$project$Guarantees$Update$Helper$pushGuaranteeDetailsPageInNavigableModal = F3(
	function (guaranteeFrequency, cluster, model) {
		var pagePayloadMapper = A2(
			$author$project$Guarantees$Update$Helper$generateGuaranteeDetailsPayload,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency),
			cluster.bH);
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Guarantees$Model$mapUI,
			model,
			$author$project$Guarantees$Model$mapNavigableModalModel(
				A2($author$project$Components$NavigableModal$pushDynamicPage, pagePayloadMapper, $author$project$Guarantees$Update$Helper$modalContentPayload)));
	});
var $author$project$Guarantees$Model$setIsBundleDiscountMessageVisible = F2(
	function (isVisible, ui) {
		return _Utils_update(
			ui,
			{fH: isVisible});
	});
var $author$project$Guarantees$Update$Helper$readHighlightedMessageFlagResponse = function (maybeLocalStorageItem) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Guarantees$Model$mapUI(
			$author$project$Guarantees$Model$setIsBundleDiscountMessageVisible(true)),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always($elm$core$Basics$identity),
			maybeLocalStorageItem));
};
var $author$project$Guarantees$Model$DoRedirectToCheckout = function (a) {
	return {$: 8, a: a};
};
var $author$project$Utils$UpdateReturn$changeUrlWithoutStackHistory = F2(
	function (route, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				hl: $elm$core$Maybe$Just(route)
			});
	});
var $author$project$Utils$UpdateReturn$withChangeUrlWithoutStackHistory = F2(
	function (route, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$changeUrlWithoutStackHistory, route, $author$project$Utils$UpdateReturn$initUpdateReturn));
	});
var $author$project$Guarantees$Update$Helper$redirectToCheckout = function (_v0) {
	var model = _v0.a;
	var quoteId = model.cy;
	var flags = model.nv;
	var quotationType = model.pc;
	var cmd = _v0.b;
	var _v1 = _Utils_Tuple3(model.hq, quoteId, model.lR);
	if (((_v1.a.$ === 3) && (!_v1.a.a.$)) && (!_v1.b.$)) {
		if (!_v1.c) {
			var saveId_ = _v1.a.a.a;
			var quoteId_ = _v1.b.a;
			var _v2 = _v1.c;
			return A2(
				$author$project$Utils$UpdateReturn$withChangeUrlWithoutStackHistory,
				A3(
					$author$project$Routing$Guarantees,
					quotationType,
					quoteId_,
					$elm$core$Maybe$Just(saveId_)),
				A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							cmd,
							$author$project$Utils$send(
							$author$project$Guarantees$Model$DoRedirectToCheckout(saveId_))
						]),
					model));
		} else {
			var saveId_ = _v1.a.a.a;
			var quoteId_ = _v1.b.a;
			var _v3 = _v1.c;
			return A2(
				$author$project$Utils$UpdateReturn$withNewUrl,
				$author$project$Types$pickIntermediariUrl(flags) + ('/preventivi/casa/' + $danyx23$elm_uuid$Uuid$toString(saveId_)),
				A2(
					$author$project$Utils$UpdateReturn$withChangeUrlWithoutStackHistory,
					A3(
						$author$project$Routing$Guarantees,
						quotationType,
						quoteId_,
						$elm$core$Maybe$Just(saveId_)),
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[cmd]),
						model)));
		}
	} else {
		return _Utils_Tuple3(model, cmd, $author$project$Utils$UpdateReturn$initUpdateReturn);
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$reset = function (_v0) {
	var state = _v0;
	return $primait$pyxis_components$Prima$Pyxis$Form$Select$close(
		_Utils_update(
			state,
			{az: $elm$core$Maybe$Nothing, pC: $elm$core$Maybe$Nothing}));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$selectedValue = function (_v0) {
	var stateConfig = _v0;
	return stateConfig.pC;
};
var $author$project$Guarantees$Model$setSectionFilter = F2(
	function (sectionFilter, ui) {
		return _Utils_update(
			ui,
			{hr: sectionFilter});
	});
var $author$project$Guarantees$Update$Helper$setSubmitting = F2(
	function (isSubmitting, model) {
		var ui = model.bM;
		return _Utils_update(
			model,
			{
				bM: _Utils_update(
					ui,
					{jV: isSubmitting})
			});
	});
var $author$project$Guarantees$Model$mapQuotePrice = F2(
	function (mapper, guaranteeViewConfig) {
		return _Utils_update(
			guaranteeViewConfig,
			{
				oY: mapper(guaranteeViewConfig.oY)
			});
	});
var $author$project$Types$Quote$Selection$updateClusterSelection = F2(
	function (clusterSlug, selected) {
		return A3($author$project$Types$Quote$Selection$updateClusterSelectionInternal, clusterSlug, selected, true);
	});
var $author$project$Guarantees$Update$Helper$updateViewConfig = F2(
	function (mapper, model) {
		var viewConfig = model.h$;
		return _Utils_update(
			model,
			{
				h$: A2($elm$core$Maybe$map, mapper, viewConfig)
			});
	});
var $author$project$Guarantees$Update$Helper$toggleCluster = F2(
	function (_v0, isSelected) {
		var slug = _v0.bH;
		return $author$project$Guarantees$Update$Helper$updateViewConfig(
			$author$project$Guarantees$Model$mapQuotePrice(
				A2($author$project$Types$Quote$Selection$updateClusterSelection, slug, isSelected)));
	});
var $author$project$Types$Quote$Selection$updateClusterIncludedGuaranteesSelectionByGuarantee = F3(
	function (guaranteeSlug, selected, price) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			price,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.nF;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$map($author$project$Guarantees$Helpers$pickGuaranteeSlug),
					A2(
						$elm_community$basics_extra$Basics$Extra$flip,
						A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Types$Quote$Selection$multiUpdateIncludedGuaranteeSelection, selected),
						price))),
			A2($author$project$Guarantees$Helpers$pickClusterByGuaranteeSlug, guaranteeSlug, price));
	});
var $author$project$Types$Quote$Selection$updateClusterSelectionByGuarantee = F3(
	function (guaranteeSlug, selected, price) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			price,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bH;
				},
				A2(
					$elm_community$basics_extra$Basics$Extra$flip,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Types$Quote$Selection$updateClusterSelection, selected),
					price)),
			A2($author$project$Guarantees$Helpers$pickClusterByGuaranteeSlug, guaranteeSlug, price));
	});
var $author$project$Types$Quote$Selection$updatePackageSelectionByGuarantee = F3(
	function (guaranteeSlug, selected, price) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			price,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bH;
				},
				A2(
					$elm_community$basics_extra$Basics$Extra$flip,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Types$Quote$Selection$updatePackageSelection, selected),
					price)),
			A2($author$project$Guarantees$Helpers$pickPackageByGuaranteeSlug, guaranteeSlug, price));
	});
var $author$project$Guarantees$Update$Helper$handleGuaranteeSelectionWithDependencies = F2(
	function (guaranteeSlug, dependenciesSlugs) {
		return $author$project$Guarantees$Update$Helper$updateViewConfig(
			$author$project$Guarantees$Model$mapQuotePrice(
				A2(
					$elm$core$Basics$composeR,
					A2($author$project$Types$Quote$Selection$updatePackageSelectionByGuarantee, guaranteeSlug, true),
					A2(
						$elm$core$Basics$composeR,
						A2($author$project$Types$Quote$Selection$updateClusterSelectionByGuarantee, guaranteeSlug, true),
						A2(
							$elm$core$Basics$composeR,
							A2($author$project$Types$Quote$Selection$updateClusterIncludedGuaranteesSelectionByGuarantee, guaranteeSlug, true),
							A2(
								$author$project$Types$Quote$Selection$multiUpdateOptionalGuaranteeSelection,
								A2($elm$core$List$cons, guaranteeSlug, dependenciesSlugs),
								true))))));
	});
var $author$project$Guarantees$Update$Helper$handleGuaranteeSelection = F2(
	function (guaranteeSlug, model) {
		var viewConfig = model.h$;
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.oY;
					},
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$View$Helpers$getNotSelectedAncestorDependencies(guaranteeSlug),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$map($author$project$Guarantees$Helpers$pickGuaranteeSlug),
							A2(
								$elm_community$basics_extra$Basics$Extra$flip,
								$author$project$Guarantees$Update$Helper$handleGuaranteeSelectionWithDependencies(guaranteeSlug),
								model)))),
				viewConfig));
	});
var $author$project$Guarantees$Update$Helper$toggleGuarantee = F3(
	function (guarantee, isSelected, model) {
		return isSelected ? A2(
			$author$project$Guarantees$Update$Helper$handleGuaranteeSelection,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
			model) : A2(
			$author$project$Guarantees$Update$Helper$updateViewConfig,
			$author$project$Guarantees$Model$mapQuotePrice(
				A2(
					$elm$core$Basics$composeR,
					A2(
						$author$project$Types$Quote$Selection$updateGuaranteeSelection,
						$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
						false),
					$author$project$Guarantees$View$Helpers$deselectAllInvalidSelectedGuarantees)),
			model);
	});
var $author$project$Guarantees$Update$Helper$toggleSidebarCardListExpansion = function (model) {
	return _Utils_update(
		model,
		{jU: !model.jU});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$pickChoiceByIndex = F2(
	function (index, _v0) {
		var choices = _v0.av;
		return A2(
			$elm$core$Array$get,
			index,
			$elm$core$Array$fromList(choices));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$pickFocusedItemIndex = function (_v0) {
	var focused = _v0.az;
	var choices = _v0.av;
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		0,
		$elm$core$Tuple$first,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Basics$eq(focused),
							$elm$core$Maybe$Just),
						function ($) {
							return $.p8;
						}),
					$elm$core$Tuple$second),
				A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, choices))));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooLow = F2(
	function (index, list) {
		return _Utils_cmp(
			index,
			$elm$core$List$length(list) - 1) > -1;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnKeyDown = function (stateModel) {
	var state = stateModel;
	var thereIsNoFocusedItem = _Utils_eq($elm$core$Maybe$Nothing, state.az);
	var focusedItemIndex = $primait$pyxis_components$Prima$Pyxis$Form$Select$pickFocusedItemIndex(stateModel);
	var wentTooLow = A2($primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooLow, focusedItemIndex, state.av);
	var index = (thereIsNoFocusedItem || wentTooLow) ? 0 : (focusedItemIndex + 1);
	return _Utils_update(
		state,
		{
			az: A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.p8;
				},
				A2($primait$pyxis_components$Prima$Pyxis$Form$Select$pickChoiceByIndex, index, stateModel))
		});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooHigh = function (index) {
	return index <= 0;
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnKeyUp = function (stateModel) {
	var state = stateModel;
	var focusedItemIndex = $primait$pyxis_components$Prima$Pyxis$Form$Select$pickFocusedItemIndex(stateModel);
	return _Utils_update(
		state,
		{
			az: A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.p8;
				},
				A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Select$pickChoiceByIndex,
					$primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooHigh(focusedItemIndex) ? focusedItemIndex : (focusedItemIndex - 1),
					stateModel))
		});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnSelect = F2(
	function (value, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{Z: false, pC: value});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnToggleMenu = function (_v0) {
	var state = _v0;
	return _Utils_update(
		state,
		{Z: !state.Z});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Select$update = F2(
	function (msg, stateModel) {
		var state = stateModel;
		switch (msg.$) {
			case 0:
				var value = msg.a;
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnSelect,
					$elm$core$Maybe$Just(value),
					stateModel);
			case 1:
				return $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnToggleMenu(stateModel);
			default:
				if (!msg.a.$) {
					switch (msg.a.a) {
						case 0:
							var _v1 = msg.a.a;
							return $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnKeyUp(stateModel);
						case 1:
							var _v2 = msg.a.a;
							return $primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnKeyDown(stateModel);
						default:
							var _v3 = msg.a.a;
							return A2($primait$pyxis_components$Prima$Pyxis$Form$Select$updateOnSelect, state.az, stateModel);
					}
				} else {
					var _v4 = msg.a;
					return stateModel;
				}
		}
	});
var $author$project$Guarantees$Update$Helper$updateAppOptions = F2(
	function (mapper, model) {
		return _Utils_update(
			model,
			{
				ku: mapper(model.ku)
			});
	});
var $author$project$Guarantees$Update$Helper$updateAppOptionsSetSummaryOpen = F2(
	function (isSummaryOpen, appOptions) {
		return _Utils_update(
			appOptions,
			{jW: isSummaryOpen});
	});
var $author$project$Guarantees$Update$Helper$updateAppOptionsSetYearlySelected = F2(
	function (isYearlySelected, appOptions) {
		return _Utils_update(
			appOptions,
			{j_: isYearlySelected});
	});
var $author$project$Guarantees$Update$Helper$updateCartSummaryVisibility = F2(
	function (isSummaryVisible, bottomBarModel) {
		return _Utils_update(
			bottomBarModel,
			{jH: isSummaryVisible});
	});
var $author$project$Guarantees$Update$Helper$updateBottomBarCartSummaryVisibility = F2(
	function (isSummaryVisible, model) {
		return A2(
			$author$project$Guarantees$Update$Helper$updateBottomBarModel,
			A2(
				$author$project$Guarantees$Update$Helper$updateCartSummaryVisibility,
				isSummaryVisible,
				$author$project$Guarantees$Update$Helper$getBottomBarModel(model)),
			model);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Guarantees$Update$Helper$updateCartSummarySelectedPackagesSlugs = F2(
	function (packageSlug, model) {
		var removeOrInsert = A3(
			$author$project$Utils$ternaryOperator,
			A2(
				$elm$core$Set$member,
				packageSlug,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Update$Helper$getBottomBarModel,
					function ($) {
						return $.ip;
					})(model)),
			$elm$core$Set$remove,
			$elm$core$Set$insert);
		return A2(
			$author$project$Guarantees$Update$Helper$updateBottomBarModel,
			A2(
				$author$project$Guarantees$Update$Helper$updateSelectedPackagesSlugs,
				A2(
					removeOrInsert,
					packageSlug,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$Update$Helper$getBottomBarModel,
						function ($) {
							return $.ip;
						})(model)),
				$author$project$Guarantees$Update$Helper$getBottomBarModel(model)),
			model);
	});
var $author$project$Guarantees$Update$Helper$updateClusterOption = function (clusterOptionSlug) {
	return $author$project$Guarantees$Update$Helper$updateViewConfig(
		$author$project$Guarantees$Model$mapQuotePrice(
			$author$project$Types$Quote$Selection$updateClusterOptionSelection(clusterOptionSlug)));
};
var $author$project$Guarantees$Update$Helper$updateCurrentDate = F2(
	function (date, model) {
		return _Utils_update(
			model,
			{
				iH: $elm$core$Maybe$Just(date)
			});
	});
var $author$project$Guarantees$Update$Helper$updateDetailsAccordionGroup = F2(
	function (arrowAccordionGroupConfig, model) {
		var detailsModel = model.fd;
		var dm = _Utils_update(
			detailsModel,
			{iQ: arrowAccordionGroupConfig});
		return _Utils_update(
			model,
			{fd: dm});
	});
var $author$project$Guarantees$Model$updateDetailsModel = F2(
	function (detailsModel, model) {
		return _Utils_update(
			model,
			{fd: detailsModel});
	});
var $author$project$Guarantees$Model$updateShowDetails = F2(
	function (isDetailsModalVisible, detailsModel) {
		return _Utils_update(
			detailsModel,
			{hx: isDetailsModalVisible});
	});
var $author$project$Guarantees$Update$Helper$updateDetailsShowDetail = F2(
	function (isDetailsModalVisible, model) {
		var detailsModel = model.fd;
		return A2(
			$author$project$Guarantees$Model$updateDetailsModel,
			A2($author$project$Guarantees$Model$updateShowDetails, isDetailsModalVisible, detailsModel),
			model);
	});
var $author$project$Types$Quote$Selection$initPriceSelection = A2($elm$core$Basics$composeR, $author$project$Types$Quote$Selection$updateSelectedPriceBlocks, $author$project$Types$Quote$Selection$updateSelectedPackages);
var $author$project$Guarantees$Update$Helper$initGuaranteeViewData = F3(
	function (quote, pricePicker, mapper) {
		return A2(
			$elm$core$Maybe$map,
			function (price) {
				return {
					oY: $author$project$Types$Quote$Selection$initPriceSelection(price),
					pd: quote,
					lQ: mapper(
						A2($author$project$Guarantees$Helpers$initSelectionFromPrice, price, quote.nM))
				};
			},
			pricePicker(quote));
	});
var $author$project$Guarantees$Update$Helper$pickSelectedPrice = F2(
	function (save, _v0) {
		var prices = _v0.kR;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (price) {
					return _Utils_eq(price.b9.mM, save.mO.b9);
				},
				prices));
	});
var $author$project$Guarantees$Update$Helper$updateSetSaveResponse = F2(
	function (response, model) {
		return _Utils_update(
			model,
			{ft: response});
	});
var $author$project$Guarantees$Update$Helper$updateSetViewConfig = F2(
	function (viewConfig, model) {
		return _Utils_update(
			model,
			{h$: viewConfig});
	});
var $author$project$Guarantees$Update$Helper$updateFetchSaveResult = F2(
	function (getSaveResponse, model) {
		_v0$2:
		while (true) {
			switch (getSaveResponse.$) {
				case 3:
					if (!getSaveResponse.a.$) {
						var save = getSaveResponse.a.a;
						var quote = save.pd;
						return A2(
							$author$project$Guarantees$Update$Helper$maybeRedirectForStatus,
							save,
							A2(
								$author$project$Guarantees$Update$Helper$updateSetViewConfig,
								A3(
									$author$project$Guarantees$Update$Helper$initGuaranteeViewData,
									quote,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Guarantees$Update$Helper$pickSelectedPrice(save),
										$elm$core$Maybe$map(
											$author$project$Types$Quote$Selection$initPriceSelectionFromSaveConfiguration(save.mO))),
									$author$project$Guarantees$Update$Helper$initSingleSelectionFromSave(save)),
								A2($author$project$Guarantees$Update$Helper$updateSetSaveResponse, getSaveResponse, model)));
					} else {
						break _v0$2;
					}
				case 2:
					var err = getSaveResponse.a;
					return A2(
						$author$project$Utils$Auth$redirectNotAuthenticated,
						err,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Guarantees$Update$Helper$updateSetSaveResponse, getSaveResponse, model))));
				default:
					break _v0$2;
			}
		}
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				A2($author$project$Guarantees$Update$Helper$updateSetSaveResponse, getSaveResponse, model)));
	});
var $author$project$Guarantees$Update$Helper$pickFirstPrice = function (_v0) {
	var prices = _v0.kR;
	return $elm$core$List$head(prices);
};
var $author$project$Guarantees$Update$Helper$updateSetQuoteResponse = F2(
	function (response, model) {
		return _Utils_update(
			model,
			{fs: response});
	});
var $author$project$Guarantees$Update$Helper$updateFromQuoteResult = F2(
	function (response, model) {
		_v0$2:
		while (true) {
			switch (response.$) {
				case 3:
					if (!response.a.$) {
						var quote = response.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Guarantees$Update$Helper$updateSetViewConfig,
									A3($author$project$Guarantees$Update$Helper$initGuaranteeViewData, quote, $author$project$Guarantees$Update$Helper$pickFirstPrice, $elm$core$Basics$identity),
									A2($author$project$Guarantees$Update$Helper$updateSetQuoteResponse, response, model))));
					} else {
						break _v0$2;
					}
				case 2:
					var err = response.a;
					return A2(
						$author$project$Utils$Auth$redirectNotAuthenticated,
						err,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Guarantees$Update$Helper$updateSetQuoteResponse, response, model))));
				default:
					break _v0$2;
			}
		}
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				A2($author$project$Guarantees$Update$Helper$updateSetQuoteResponse, response, model)));
	});
var $author$project$Guarantees$Model$mapAccordionDetailsAreOpen = F2(
	function (mapper, accordionState) {
		var areDetailsOpen = accordionState.eR;
		return _Utils_update(
			accordionState,
			{
				eR: mapper(areDetailsOpen)
			});
	});
var $author$project$Guarantees$Model$mapAccordionEditOptionsClusterOpen = F2(
	function (mapper, editOptionAccordionState) {
		var isClusterOpen = editOptionAccordionState.fI;
		return _Utils_update(
			editOptionAccordionState,
			{
				fI: mapper(isClusterOpen)
			});
	});
var $author$project$Guarantees$Model$mapAccordionEditOptionsGuaranteesOpen = F2(
	function (mapper, editOptionAccordionState) {
		var areGuaranteesOpen = editOptionAccordionState.eS;
		return _Utils_update(
			editOptionAccordionState,
			{
				eS: mapper(areGuaranteesOpen)
			});
	});
var $author$project$Guarantees$Model$mapEditOptionsOpenAccordionState = F2(
	function (mapper, accordionState) {
		var editOptionsOpen = accordionState.fh;
		return _Utils_update(
			accordionState,
			{
				fh: mapper(editOptionsOpen)
			});
	});
var $author$project$Guarantees$Model$mapModalDetailsAccordionState = F2(
	function (key, mapper) {
		return A2($elm$core$Dict$update, key, mapper);
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $author$project$Guarantees$Model$setAccordionIsExcludedOpen = F2(
	function (isOpen, accordionState) {
		return _Utils_update(
			accordionState,
			{jM: isOpen});
	});
var $author$project$Guarantees$Model$setAccordionIsIncludedOpen = F2(
	function (isOpen, accordionState) {
		return _Utils_update(
			accordionState,
			{jN: isOpen});
	});
var $author$project$Guarantees$Update$Helper$updateGuaranteeModalAccordions = F4(
	function (modalId, accordionType, isOpen, indexToUpdate) {
		var mapDeep = A2(
			$elm$core$Basics$composeR,
			$author$project$Guarantees$Model$mapModalDetailsAccordionState(modalId),
			A2($elm$core$Basics$composeR, $author$project$Guarantees$Model$mapModalDetailsAccordionStateDictionary, $author$project$Guarantees$Model$mapUI));
		switch (accordionType.$) {
			case 0:
				return mapDeep(
					$elm$core$Maybe$map(
						$author$project$Guarantees$Model$setAccordionIsIncludedOpen(!isOpen)));
			case 1:
				return mapDeep(
					$elm$core$Maybe$map(
						$author$project$Guarantees$Model$setAccordionIsExcludedOpen(!isOpen)));
			case 2:
				return mapDeep(
					$elm$core$Maybe$map(
						$author$project$Guarantees$Model$mapAccordionDetailsAreOpen(
							A2($elm$core$Array$set, indexToUpdate, !isOpen))));
			default:
				var optionAccordionType = accordionType.a;
				if (!optionAccordionType) {
					return mapDeep(
						$elm$core$Maybe$map(
							$author$project$Guarantees$Model$mapEditOptionsOpenAccordionState(
								$author$project$Guarantees$Model$mapAccordionEditOptionsClusterOpen($elm$core$Basics$not))));
				} else {
					return mapDeep(
						$elm$core$Maybe$map(
							$author$project$Guarantees$Model$mapEditOptionsOpenAccordionState(
								$author$project$Guarantees$Model$mapAccordionEditOptionsGuaranteesOpen(
									A2($elm$core$Array$set, indexToUpdate, !isOpen)))));
				}
		}
	});
var $author$project$Guarantees$Update$Helper$updateGuaranteeOption = function (guaranteeOptionSlug) {
	return $author$project$Guarantees$Update$Helper$updateViewConfig(
		$author$project$Guarantees$Model$mapQuotePrice(
			$author$project$Types$Quote$Selection$updateGuaranteeOptionSelection(guaranteeOptionSlug)));
};
var $author$project$Types$LocalStorageItem = F2(
	function (name, value) {
		return {oq: name, p8: value};
	});
var $author$project$Ports$setLocalStorageItem = _Platform_outgoingPort(
	'setLocalStorageItem',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string($.oq)),
					_Utils_Tuple2(
					'value',
					$elm$json$Json$Encode$string($.p8))
				]));
	});
var $author$project$Guarantees$Update$Helper$updateHighlightedMessagesFlag = F2(
	function (messageSlug, quoteId) {
		return $author$project$Ports$setLocalStorageItem(
			A2($author$project$Types$LocalStorageItem, $author$project$Guarantees$Update$Helper$getHighlightedMessageFlagPrefix + (messageSlug + ('_' + quoteId)), ''));
	});
var $author$project$Components$ArrowAccordionGroup$setOpen = F2(
	function (accordionSlug, openAccordionSlug) {
		var getOpenAccordion = function (slug) {
			return _Utils_eq(slug, accordionSlug) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(accordionSlug);
		};
		if (!openAccordionSlug.$) {
			var slug = openAccordionSlug.a;
			return getOpenAccordion(slug);
		} else {
			return $elm$core$Maybe$Just(accordionSlug);
		}
	});
var $author$project$Components$ArrowAccordionGroup$updateOpenAccordion = F2(
	function (slug, groupConfig) {
		var openAccordion = groupConfig.cp;
		return _Utils_update(
			groupConfig,
			{
				cp: A2($author$project$Components$ArrowAccordionGroup$setOpen, slug, openAccordion)
			});
	});
var $author$project$Guarantees$Update$Helper$optionSvg = A2(
	$author$project$View$Svg$svg,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('option-icon')
		]),
	'garanzie/');
var $author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOptionsInPackage = F3(
	function (packageSlug, guaranteeSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$concatMap,
			$author$project$Guarantees$Model$UserSelection$Guarantee$getOptionList,
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.g2;
				},
				A2(
					$elm$core$List$filter,
					function (_v2) {
						var slug = _v2.bH;
						var selected = _v2.pC;
						var guaranteeType = _v2.js;
						return _Utils_eq(slug, guaranteeSlug) && (selected || (!guaranteeType));
					},
					A2(
						$elm$core$List$filter,
						function (_v1) {
							var selected = _v1.pC;
							var guaranteeType = _v1.js;
							return selected || (!guaranteeType);
						},
						A2(
							$elm$core$List$concatMap,
							function ($) {
								return $.nF;
							},
							A2(
								$elm$core$List$concatMap,
								function ($) {
									return $.mG;
								},
								A2(
									$elm$core$List$filter,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.bH;
										},
										$elm$core$Basics$eq(packageSlug)),
									packages)))))));
	});
var $author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackage = F4(
	function (packageSlug, clusterSlug, guaranteeSlug, userSelection) {
		return _Utils_ap(
			A3($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOptionsInPackage, packageSlug, guaranteeSlug, userSelection),
			A3($author$project$Guarantees$Model$UserSelection$pickSelectedClusterOptionsInPackage, packageSlug, clusterSlug, userSelection));
	});
var $author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackageWithTypeAndCoverage = F6(
	function (packageSlug, clusterSlug, guaranteeSlug, optionType, optionCoverage, userSelection) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.ap;
				},
				$elm$core$Basics$eq(optionCoverage)),
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ao;
					},
					$elm$core$Basics$eq(optionType)),
				A4($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackage, packageSlug, clusterSlug, guaranteeSlug, userSelection)));
	});
var $author$project$Guarantees$Model$UserSelection$pickValueOptionByKey = F2(
	function (key, option) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.p8;
				},
				A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.fR;
						},
						$elm$core$Basics$eq(key)),
					option.hZ)));
	});
var $author$project$Guarantees$Update$Helper$pickGuaranteeOptionValue = F6(
	function (userSelection, packageSlug, clusterSlug, guaranteeSlug, optionType, optionCoverage) {
		return A2(
			$elm$core$Maybe$withDefault,
			'nessuna',
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Guarantees$Model$UserSelection$pickValueOptionByKey('name'),
				$elm$core$List$head(
					A6($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackageWithTypeAndCoverage, packageSlug, clusterSlug, guaranteeSlug, optionType, optionCoverage, userSelection))));
	});
var $author$project$View$Helper$wrapInColWithClass = function (class_) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-column ' + class_)
			]));
};
var $author$project$View$Helper$wrapInCol = $author$project$View$Helper$wrapInColWithClass('');
var $author$project$View$Helper$wrapInRowWithClass = function (class_) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-row ' + class_)
			]));
};
var $author$project$Guarantees$Update$Helper$guaranteeOptionRender = F8(
	function (userSelection, _package, cluster, guarantee, optionName, optionType, optionCoverage, iconType) {
		return A2(
			$author$project$View$Helper$wrapInRowWithClass,
			'align-items-center',
			_List_fromArray(
				[
					$author$project$Guarantees$Update$Helper$optionSvg(iconType),
					$author$project$View$Helper$wrapInCol(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fs-xsmall fw-heavy text-transform-uppercase')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(optionName)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fs-xsmall fw-base c-text-base')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A6(
										$author$project$Guarantees$Update$Helper$pickGuaranteeOptionValue,
										userSelection,
										_package.bH,
										cluster.bH,
										$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
										optionType,
										optionCoverage))
								]))
						]))
				]));
	});
var $author$project$Guarantees$Update$Helper$guaranteeDeductAndLimitRender = F4(
	function (userSelection, _package, cluster, guarantee) {
		return A2(
			$author$project$View$Helper$wrapInRowWithClass,
			'guarantees-package-detail-options align-items-center',
			_List_fromArray(
				[
					A8($author$project$Guarantees$Update$Helper$guaranteeOptionRender, userSelection, _package, cluster, guarantee, 'Franchigia', 'deductible', 'default', 'franchise'),
					A8($author$project$Guarantees$Update$Helper$guaranteeOptionRender, userSelection, _package, cluster, guarantee, 'Massimale', 'limit', 'default', 'package')
				]));
	});
var $author$project$Guarantees$Update$Helper$guaranteeDescriptionRender = function (string) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('guarantees-package-detail-content')
			]),
		$hecrj$html_parser$Html$Parser$Util$toVirtualDom(
			A2(
				$elm$core$Result$withDefault,
				_List_Nil,
				$hecrj$html_parser$Html$Parser$run(string))));
};
var $author$project$Guarantees$Update$Helper$guaranteeContentRender = F3(
	function (userSelection, _package, _v0) {
		var cluster = _v0.a;
		var guarantee = _v0.b;
		var _v1 = function () {
			if (!guarantee.$) {
				var guaranteeYearly = guarantee.a;
				return _Utils_Tuple2(guaranteeYearly.iO, guaranteeYearly.iP);
			} else {
				var guaranteeMonthly = guarantee.a;
				return _Utils_Tuple2(guaranteeMonthly.iO, guaranteeMonthly.iP);
			}
		}();
		var maybeCoverage = _v1.a;
		var maybeDetails = _v1.b;
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (coverage, details) {
						return A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A4($author$project$Guarantees$Update$Helper$guaranteeDeductAndLimitRender, userSelection, _package, cluster, guarantee),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fs-xsmall fw-heavy')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('COSA COPRE?')
										])),
									$author$project$Guarantees$Update$Helper$guaranteeDescriptionRender(coverage),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fs-xsmall fw-heavy')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('DETTAGLI')
										])),
									$author$project$Guarantees$Update$Helper$guaranteeDescriptionRender(details)
								]));
					}),
				maybeCoverage,
				maybeDetails));
	});
var $author$project$Components$ArrowAccordionGroup$withConfig = F3(
	function (slug, accordion, config) {
		return _Utils_update(
			config,
			{
				at: A3($elm$core$Dict$insert, slug, accordion, config.at)
			});
	});
var $author$project$Components$ArrowAccordionGroup$withContentRender = F2(
	function (contentRender, config) {
		return _Utils_update(
			config,
			{
				db: $elm$core$Maybe$Just(contentRender)
			});
	});
var $author$project$Guarantees$Update$Helper$accordionsToGroup = F3(
	function (userSelection, _package, arrowAccordionConfigs) {
		var initialGroup = A2(
			$author$project$Components$ArrowAccordionGroup$withContentRender,
			A2($author$project$Guarantees$Update$Helper$guaranteeContentRender, userSelection, _package),
			$author$project$Components$ArrowAccordionGroup$init);
		return A3(
			$elm$core$List$foldl,
			F2(
				function (x, y) {
					return A3($author$project$Components$ArrowAccordionGroup$withConfig, x.bH, x, y);
				}),
			initialGroup,
			arrowAccordionConfigs);
	});
var $author$project$Guarantees$Model$DetailAccordion = function (a) {
	return {$: 8, a: a};
};
var $author$project$Guarantees$Update$Helper$guaranteeSvg = A2($author$project$View$Svg$svg, _List_Nil, 'garanzie/');
var $author$project$Guarantees$Model$Guarantee = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $author$project$Components$CheckboxSwitch$CheckBoxSwitch = $elm$core$Basics$identity;
var $author$project$Components$CheckboxSwitch$checkboxSwitch = F2(
	function (tagger, ischecked) {
		return {dL: ischecked, gb: tagger};
	});
var $author$project$Guarantees$View$Helpers$isGuaranteeInPackageSelected = F4(
	function (cluster, guarantee, _package, userSelection) {
		return A4(
			$author$project$Guarantees$Model$UserSelection$isGuaranteeInPackageSelected,
			cluster.bH,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
			_package.bH,
			userSelection);
	});
var $author$project$Guarantees$View$Helpers$guaranteeSwitch = F4(
	function (_package, cluster, guarantee, userSelection) {
		return A2(
			$author$project$Components$CheckboxSwitch$checkboxSwitch,
			A2(
				$elm$core$Basics$composeL,
				$author$project$Guarantees$Model$OnToggle,
				A3($author$project$Guarantees$Model$Guarantee, _package, cluster, guarantee)),
			A4($author$project$Guarantees$View$Helpers$isGuaranteeInPackageSelected, cluster, guarantee, _package, userSelection));
	});
var $author$project$Components$ArrowAccordion$init = F2(
	function (slug_, tagger) {
		return {
			bf: $elm$core$Maybe$Nothing,
			dP: $elm$core$Basics$always(false),
			cj: $elm$core$Maybe$Nothing,
			bH: slug_,
			hS: tagger,
			eE: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Components$ArrowAccordion$withContent = F2(
	function (content, config) {
		return _Utils_update(
			config,
			{
				cj: $elm$core$Maybe$Just(content)
			});
	});
var $author$project$Components$ArrowAccordion$withSwitch = F3(
	function (maybeSwitch, isRender, config) {
		return _Utils_update(
			config,
			{bf: maybeSwitch, dP: isRender});
	});
var $author$project$Components$ArrowAccordion$withTitle = F2(
	function (title, config) {
		return _Utils_update(
			config,
			{eE: title});
	});
var $author$project$Guarantees$Update$Helper$fromGuranteeToAccordion = F4(
	function (_package, cluster, guarantee, _v0) {
		var viewConfig = _v0.h$;
		var _v1 = function () {
			if (!guarantee.$) {
				var guaranteeYearly = guarantee.a;
				return guaranteeYearly;
			} else {
				var guaranteeMonthly = guarantee.a;
				return guaranteeMonthly;
			}
		}();
		var slug = _v1.bH;
		var label = _v1.n6;
		var guaranteeClaim = _v1.nC;
		var title = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex align-items-center')
				]),
			_List_fromArray(
				[
					$author$project$Guarantees$Update$Helper$guaranteeSvg(slug),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('guarantees-accordion-details-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$elm$html$Html$text,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$List$singleton,
								$elm$html$Html$span(
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fw-base fs-xsmall ml-5-px mt-3-px')
										])))),
						guaranteeClaim))
				]));
		return A3(
			$author$project$Components$ArrowAccordion$withSwitch,
			A2(
				$elm$core$Maybe$map,
				A3($author$project$Guarantees$View$Helpers$guaranteeSwitch, _package, cluster, guarantee),
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.lQ;
					},
					viewConfig)),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$second,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$pickGuaranteeType,
					$elm$core$Basics$eq(1))),
			A2(
				$author$project$Components$ArrowAccordion$withContent,
				_Utils_Tuple2(cluster, guarantee),
				A2(
					$author$project$Components$ArrowAccordion$withTitle,
					$elm$core$Maybe$Just(title),
					A2(
						$author$project$Components$ArrowAccordion$init,
						slug,
						$elm$core$Basics$always(
							$author$project$Guarantees$Model$OnToggle(
								$author$project$Guarantees$Model$DetailAccordion(slug)))))));
	});
var $author$project$Utils$listPair = function (_v0) {
	var a = _v0.a;
	var l = _v0.b;
	return A2(
		$elm$core$List$map,
		$elm$core$Tuple$pair(a),
		l);
};
var $author$project$Guarantees$Helpers$isGuaranteeExcluded = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickGuaranteeType,
	$elm$core$Basics$eq(2));
var $author$project$Guarantees$Helpers$notExcludedGuarantees = $elm$core$List$filter(
	A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Guarantees$Helpers$isGuaranteeExcluded));
var $author$project$Guarantees$Update$Helper$fromPackageToAccordionGroup = F3(
	function (userSelection, _package, model) {
		return A3(
			$author$project$Guarantees$Update$Helper$accordionsToGroup,
			userSelection,
			_package,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var cluster = _v0.a;
					var guarantee = _v0.b;
					return A4($author$project$Guarantees$Update$Helper$fromGuranteeToAccordion, _package, cluster, guarantee, model);
				},
				A2(
					$elm$core$List$concatMap,
					$author$project$Utils$listPair,
					A2(
						$elm$core$List$map,
						function (p) {
							return _Utils_Tuple2(
								p,
								$author$project$Guarantees$Helpers$notExcludedGuarantees(p.nF));
						},
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.l0;
							},
							_package.mG)))));
	});
var $author$project$Guarantees$Update$Helper$updatePackage = F2(
	function (_package, model) {
		var detailsModel = model.fd;
		var viewConfig = model.h$;
		var dm = A2(
			$elm$core$Maybe$withDefault,
			detailsModel,
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var userSelection = _v0.lQ;
					return _Utils_update(
						detailsModel,
						{
							iQ: A3($author$project$Guarantees$Update$Helper$fromPackageToAccordionGroup, userSelection, _package, model),
							j9: $elm$core$Maybe$Just(_package)
						});
				},
				viewConfig));
		return _Utils_update(
			model,
			{fd: dm});
	});
var $author$project$Guarantees$Update$Helper$updateQuotationType = F2(
	function (quotationType, model) {
		return _Utils_update(
			model,
			{pc: quotationType});
	});
var $author$project$Guarantees$Update$Helper$updateQuoteId = F2(
	function (quoteId, model) {
		return _Utils_update(
			model,
			{
				cy: $elm$core$Maybe$Just(quoteId)
			});
	});
var $author$project$Guarantees$Update$Helper$updateSaveId = F2(
	function (maybeSaveId, model) {
		return _Utils_update(
			model,
			{pz: maybeSaveId});
	});
var $author$project$Guarantees$Update$Helper$updateSaveSubmissionResult = F2(
	function (response, model) {
		return _Utils_update(
			model,
			{hq: response});
	});
var $author$project$Components$CheckboxSwitch$setChecked = F2(
	function (bool, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{dL: bool});
	});
var $author$project$Components$ArrowAccordion$setSwitchState = F2(
	function (bool, config) {
		return _Utils_update(
			config,
			{
				bf: A2(
					$elm$core$Maybe$map,
					$author$project$Components$CheckboxSwitch$setChecked(bool),
					config.bf)
			});
	});
var $author$project$Components$ArrowAccordionGroup$setSwitchState = F3(
	function (slug, bool, groupConfig) {
		return _Utils_update(
			groupConfig,
			{
				at: function (arrowAccordion) {
					return A3(
						$elm$core$Dict$update,
						slug,
						$elm$core$Basics$always(arrowAccordion),
						groupConfig.at);
				}(
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$ArrowAccordion$setSwitchState(bool),
						A2($elm$core$Dict$get, slug, groupConfig.at)))
			});
	});
var $author$project$Guarantees$Update$Helper$updateSwitchState = F3(
	function (guarantee, isOpen, model) {
		var detailsModel = model.fd;
		var dm = _Utils_update(
			detailsModel,
			{
				iQ: A3(
					$author$project$Components$ArrowAccordionGroup$setSwitchState,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					isOpen,
					detailsModel.iQ)
			});
		return _Utils_update(
			model,
			{fd: dm});
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionAddGuaranteeToPackage = F4(
	function (cluster, guarantee, _package, model) {
		return A2(
			$author$project$Guarantees$Update$Helper$updateViewConfig,
			$author$project$Guarantees$Model$mapUserSelection(
				A3(
					$author$project$Guarantees$Model$UserSelection$selectGuaranteeInPackage,
					cluster.bH,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					_package.bH)),
			model);
	});
var $author$project$Guarantees$Model$UserSelection$deselectGuaranteeInPackage = F4(
	function (clusterSlug, guaranteeSlug, packageSlug, _v0) {
		var selection = _v0;
		return _Utils_update(
			selection,
			{
				oN: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(packageSlug)),
						A3($author$project$Guarantees$Model$UserSelection$toggleGuaranteeInPackage, clusterSlug, guaranteeSlug, false)),
					selection.oN)
			});
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionRemoveGuaranteeFromPackage = F4(
	function (cluster, guarantee, _package, model) {
		return A2(
			$author$project$Guarantees$Update$Helper$updateViewConfig,
			$author$project$Guarantees$Model$mapUserSelection(
				A3(
					$author$project$Guarantees$Model$UserSelection$deselectGuaranteeInPackage,
					cluster.bH,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					_package.bH)),
			model);
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionToggleGuaranteeInPackage = F5(
	function (cluster, guarantee, _package, isSelected, model) {
		return isSelected ? A4($author$project$Guarantees$Update$Helper$updateUserSelectionAddGuaranteeToPackage, cluster, guarantee, _package, model) : A4($author$project$Guarantees$Update$Helper$updateUserSelectionRemoveGuaranteeFromPackage, cluster, guarantee, _package, model);
	});
var $author$project$Guarantees$Model$UserSelection$pickPackageExclusion = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.ni;
			},
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bH;
					},
					$elm$core$Basics$eq(packageSlug)),
				packages));
	});
var $author$project$Guarantees$Model$UserSelection$selectPackage = F2(
	function (packageSlug, userSelection) {
		var selection = userSelection;
		var packages = A2(
			$elm$core$List$map,
			A2(
				$author$project$Utils$mapIf,
				function (_v0) {
					var slug = _v0.bH;
					return A2(
						$elm$core$List$member,
						slug,
						A2($author$project$Guarantees$Model$UserSelection$pickPackageExclusion, packageSlug, userSelection));
				},
				$author$project$Guarantees$Model$UserSelection$setPackageAsSelected(false)),
			selection.oN);
		return _Utils_update(
			selection,
			{
				oN: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(packageSlug)),
						$author$project$Guarantees$Model$UserSelection$setPackageAsSelected(true)),
					packages)
			});
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionAddPackage = F2(
	function (_package, model) {
		return A2(
			$author$project$Guarantees$Update$Helper$updateViewConfig,
			$author$project$Guarantees$Model$mapUserSelection(
				$author$project$Guarantees$Model$UserSelection$selectPackage(_package.bH)),
			model);
	});
var $author$project$Guarantees$Model$UserSelection$deselectPackage = F2(
	function (packageSlug, _v0) {
		var selection = _v0;
		return _Utils_update(
			selection,
			{
				oN: A2(
					$elm$core$List$map,
					A2(
						$author$project$Utils$mapIf,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(packageSlug)),
						$author$project$Guarantees$Model$UserSelection$setPackageAsSelected(false)),
					selection.oN)
			});
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionRemovePackage = F2(
	function (_package, model) {
		return A2(
			$author$project$Guarantees$Update$Helper$updateViewConfig,
			$author$project$Guarantees$Model$mapUserSelection(
				$author$project$Guarantees$Model$UserSelection$deselectPackage(_package.bH)),
			model);
	});
var $author$project$Guarantees$Update$Helper$updateUserSelectionTogglePackage = F3(
	function (_package, isSelected, model) {
		return isSelected ? A2($author$project$Guarantees$Update$Helper$updateUserSelectionAddPackage, _package, model) : A2($author$project$Guarantees$Update$Helper$updateUserSelectionRemovePackage, _package, model);
	});
var $author$project$Guarantees$Update$Helper$updateUserType = F2(
	function (userType, model) {
		return _Utils_update(
			model,
			{lR: userType});
	});
var $author$project$Guarantees$Update$Helper$updateVisiblePackage = F2(
	function (index, model) {
		return _Utils_update(
			model,
			{jw: index});
	});
var $author$project$Guarantees$Model$GotSave = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$PostSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$SaveMutationResult = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$InputObject$buildQuoteSaveConfiguration = F2(
	function (required____, fillOptionals____) {
		var optionals____ = fillOptionals____(
			{w: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent});
		return {b9: required____.b9, w: optionals____.w, oN: required____.oN, ct: required____.ct, cy: required____.cy};
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe = function (maybeValue) {
	if (!maybeValue.$) {
		var value = maybeValue.a;
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
	} else {
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Gql$Vianello$InputObject$buildQuoteSaveConfigurationPackage = function (required____) {
	return {mG: required____.mG, bH: required____.bH};
};
var $author$project$Guarantees$Model$UserSelection$Guarantee$getOptionGroupType = function (group) {
	if (!group.$) {
		var type_ = group.a.ao;
		return type_;
	} else {
		var type_ = group.a.ao;
		return type_;
	}
};
var $author$project$Guarantees$Api$Save$mapOptionGroupToOptionGroupConfiguration = function (group) {
	return {
		cq: $author$project$Guarantees$Model$UserSelection$Guarantee$getOptionGroupType(group),
		bH: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.bH;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function ($) {
						return $.pC;
					},
					$author$project$Guarantees$Model$UserSelection$Guarantee$getOptionList(group))))
	};
};
var $author$project$Guarantees$Api$Save$mapGuaranteeToGuaranteeConfiguration = function (_v0) {
	var slug = _v0.bH;
	var optionGroups = _v0.g2;
	return {
		ku: A2($elm$core$List$map, $author$project$Guarantees$Api$Save$mapOptionGroupToOptionGroupConfiguration, optionGroups),
		bH: slug
	};
};
var $author$project$Guarantees$Api$Save$mapClusterToClusterConfiguration = function (_v0) {
	var slug = _v0.bH;
	var guarantees = _v0.nF;
	var optionGroups = _v0.g2;
	return {
		nF: A2(
			$elm$core$List$map,
			$author$project$Guarantees$Api$Save$mapGuaranteeToGuaranteeConfiguration,
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.pC;
				},
				guarantees)),
		ku: A2($elm$core$List$map, $author$project$Guarantees$Api$Save$mapOptionGroupToOptionGroupConfiguration, optionGroups),
		bH: slug
	};
};
var $author$project$Guarantees$Api$Save$mapPackageToPackageConfiguration = function (_v0) {
	var slug = _v0.bH;
	var clusters = _v0.mG;
	return $author$project$Gql$Vianello$InputObject$buildQuoteSaveConfigurationPackage(
		{
			mG: A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.nF;
					},
					A2($elm$core$Basics$composeR, $elm$core$List$isEmpty, $elm$core$Basics$not)),
				A2($elm$core$List$map, $author$project$Guarantees$Api$Save$mapClusterToClusterConfiguration, clusters)),
			bH: slug
		});
};
var $author$project$Guarantees$Model$UserSelection$pickUserSelectionPayload = function (_v0) {
	var userSelection = _v0;
	return userSelection;
};
var $author$project$Guarantees$Api$Save$mapUserSelectionToQuoteSaveConfiguration = function (userSelection) {
	var _v0 = $author$project$Guarantees$Model$UserSelection$pickUserSelectionPayload(userSelection);
	var quoteId = _v0.cy;
	var originalSaveId = _v0.w;
	var issuingCompany = _v0.b9;
	var packages = _v0.oN;
	return A2(
		$author$project$Gql$Vianello$InputObject$buildQuoteSaveConfiguration,
		{
			b9: issuingCompany,
			oN: A2(
				$elm$core$List$map,
				$author$project$Guarantees$Api$Save$mapPackageToPackageConfiguration,
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.pC;
					},
					packages)),
			ct: 1,
			cy: quoteId
		},
		function (optionals) {
			return _Utils_update(
				optionals,
				{
					w: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(originalSaveId)
				});
		});
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return A2(
					$elm$core$Maybe$andThen,
					function (actualValue) {
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(key, actualValue));
					},
					value);
			},
			maybeValues));
};
var $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationOption = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'slug',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.bH))),
				_Utils_Tuple2(
				'optionConfigurationType',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.cq)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$list = F2(
	function (toValue, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$List(
			A2($elm$core$List$map, toValue, value));
	});
var $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationGuarantee = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'options',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationOption)(input____.ku))),
				_Utils_Tuple2(
				'slug',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.bH)))
			]));
};
var $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationCluster = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'slug',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.bH))),
				_Utils_Tuple2(
				'guarantees',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationGuarantee)(input____.nF))),
				_Utils_Tuple2(
				'options',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationOption)(input____.ku)))
			]));
};
var $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationPackage = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'slug',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.bH))),
				_Utils_Tuple2(
				'clusters',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationCluster)(input____.mG)))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 0:
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $author$project$Gql$Vianello$Enum$PaymentFrequency$toString = function (enum____) {
	if (!enum____) {
		return 'MONTHLY';
	} else {
		return 'YEARLY';
	}
};
var $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfiguration = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quoteId',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)(input____.cy))),
				_Utils_Tuple2(
				'originalSaveId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))),
				_Utils_Tuple2(
				'issuingCompany',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.b9))),
				_Utils_Tuple2(
				'paymentFrequency',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$PaymentFrequency$toString, input____.ct))),
				_Utils_Tuple2(
				'packages',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$list($author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfigurationPackage)(input____.oN)))
			]));
};
var $author$project$Gql$Vianello$Mutation$createSave = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'createSave',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'configuration', requiredArgs____.mO, $author$project$Gql$Vianello$InputObject$encodeQuoteSaveConfiguration)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Vianello$Union$CreateSaveResult$Fragments = F2(
	function (onCreateSaveOk, onCreateSaveErrors) {
		return {gh: onCreateSaveErrors, gi: onCreateSaveOk};
	});
var $author$project$Gql$Vianello$Union$CreateSaveResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'CreateSaveOk', selections____.gi),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'CreateSaveErrors', selections____.gh)
			]));
};
var $author$project$Types$Save$SaveResponseError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$CreateSaveErrors$errors = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'errors',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Types$Save$SaveError = F2(
	function (field, message) {
		return {ns: field, of: message};
	});
var $author$project$Gql$Vianello$Object$CreateSaveError$field = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'field',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$CreateSaveError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Guarantees$Api$Save$saveErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$CreateSaveError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$CreateSaveError$field,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveError)));
var $author$project$Guarantees$Api$Save$saveResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$CreateSaveErrors$errors($author$project$Guarantees$Api$Save$saveErrorSelection),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveResponseError));
var $author$project$Types$Save$SaveData = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Object$CreateSaveOk$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Guarantees$Api$Save$saveResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$CreateSaveOk$id,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$Save$SaveData));
var $author$project$Guarantees$Api$Save$saveResponseSelection = $author$project$Gql$Vianello$Union$CreateSaveResult$fragments(
	A2($author$project$Gql$Vianello$Union$CreateSaveResult$Fragments, $author$project$Guarantees$Api$Save$saveResponseOkSelection, $author$project$Guarantees$Api$Save$saveResponseErrorSelection));
var $author$project$Guarantees$Api$Save$saveMutation = function (quoteSaveConfiguration) {
	return A2(
		$author$project$Gql$Vianello$Mutation$createSave,
		{mO: quoteSaveConfiguration},
		$author$project$Guarantees$Api$Save$saveResponseSelection);
};
var $author$project$Guarantees$Api$Save$createSaveMutation = function (userSelection) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$SaveMutationResult,
		$author$project$Guarantees$Api$Save$saveMutation(
			$author$project$Guarantees$Api$Save$mapUserSelectionToQuoteSaveConfiguration(userSelection)));
};
var $author$project$Guarantees$Api$saveMutation = function (selection) {
	return A2(
		$author$project$Api$buildMutation,
		$author$project$Api$GuaranteeSource(
			$author$project$Api$PostSave($author$project$Guarantees$Model$GotSave)),
		$author$project$Guarantees$Api$Save$createSaveMutation(selection));
};
var $author$project$Guarantees$Helpers$isGuaranteeSelected = function (guarantee) {
	if (!guarantee.$) {
		var selected = guarantee.a.pC;
		return selected;
	} else {
		var selected = guarantee.a.pC;
		return selected;
	}
};
var $author$project$Guarantees$Api$Save$mapQuoteOptionToQuoteSaveOption = function (group) {
	return {
		cq: $author$project$Guarantees$Helpers$pickOptionGroupType(group),
		bH: A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.bH;
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function ($) {
						return $.pC;
					},
					$author$project$Guarantees$Helpers$pickOptionList(group))))
	};
};
var $author$project$Guarantees$Api$Save$mapQuoteGuaranteeToQuoteSaveGuarantee = function (guarantee) {
	return {
		ku: A2(
			$elm$core$List$map,
			$author$project$Guarantees$Api$Save$mapQuoteOptionToQuoteSaveOption,
			$author$project$Guarantees$Helpers$pickOptionGroups(guarantee)),
		bH: $author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)
	};
};
var $author$project$Guarantees$Api$Save$mapQuoteClusterToQuoteSaveCluster = function (_v0) {
	var slug = _v0.bH;
	var guarantees = _v0.nF;
	var optionGroups = _v0.g2;
	return {
		nF: A2(
			$elm$core$List$map,
			$author$project$Guarantees$Api$Save$mapQuoteGuaranteeToQuoteSaveGuarantee,
			A2($elm$core$List$filter, $author$project$Guarantees$Helpers$isGuaranteeSelected, guarantees)),
		ku: A2($elm$core$List$map, $author$project$Guarantees$Api$Save$mapQuoteOptionToQuoteSaveOption, optionGroups),
		bH: slug
	};
};
var $author$project$Guarantees$Api$Save$mapQuotePackageToQuoteSavePackage = function (_v0) {
	var slug = _v0.bH;
	var clusters = _v0.mG;
	return $author$project$Gql$Vianello$InputObject$buildQuoteSaveConfigurationPackage(
		{
			mG: A2(
				$elm$core$List$map,
				$author$project$Guarantees$Api$Save$mapQuoteClusterToQuoteSaveCluster,
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.pC;
					},
					clusters)),
			bH: slug
		});
};
var $author$project$Guarantees$Api$Save$mapQuotePriceToQuoteSavePackages = function (_v0) {
	var sections = _v0.pB;
	return A2(
		$elm$core$List$map,
		$author$project$Guarantees$Api$Save$mapQuotePackageToQuoteSavePackage,
		A2(
			$elm$core$List$filter,
			function ($) {
				return $.pC;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oN;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oM;
					},
					sections))));
};
var $author$project$Guarantees$Api$Save$mapQuotePriceToQuoteSaveConfiguration = F3(
	function (quoteId, originalSaveId, price) {
		var issuingCompany = price.b9;
		var optionalFields = function (optionals) {
			return _Utils_update(
				optionals,
				{
					w: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(originalSaveId)
				});
		};
		return A2(
			$author$project$Gql$Vianello$InputObject$buildQuoteSaveConfiguration,
			{
				b9: issuingCompany.mM,
				oN: $author$project$Guarantees$Api$Save$mapQuotePriceToQuoteSavePackages(price),
				ct: 1,
				cy: quoteId
			},
			optionalFields);
	});
var $author$project$Guarantees$Api$Save$createSaveMutationForPrice = F2(
	function (quoteId, originalSaveId) {
		return A2(
			$elm$core$Basics$composeR,
			A2($author$project$Guarantees$Api$Save$mapQuotePriceToQuoteSaveConfiguration, quoteId, originalSaveId),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Guarantees$Api$Save$saveMutation,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$map($author$project$Api$SaveMutationResult)));
	});
var $author$project$Guarantees$Api$saveWithPriceMutation = F3(
	function (quoteId, originalSaveId, price) {
		return A2(
			$author$project$Api$buildMutation,
			$author$project$Api$GuaranteeSource(
				$author$project$Api$PostSave($author$project$Guarantees$Model$GotSave)),
			A3($author$project$Guarantees$Api$Save$createSaveMutationForPrice, quoteId, originalSaveId, price));
	});
var $author$project$Guarantees$Update$Helper$withCreateSaveMutationForVersion = F2(
	function (_v0, _return) {
		var quote = _v0.pd;
		var price = _v0.oY;
		var userSelection = _v0.lQ;
		var originalSaveId = _return.a.w;
		var _v1 = quote.lU;
		if (!_v1) {
			return A2(
				$author$project$Utils$UpdateReturn$withQuery,
				$author$project$Guarantees$Api$saveMutation(userSelection),
				_return);
		} else {
			return A2(
				$author$project$Utils$UpdateReturn$withQuery,
				A3($author$project$Guarantees$Api$saveWithPriceMutation, quote.nM, originalSaveId, price),
				_return);
		}
	});
var $author$project$Guarantees$Update$Helper$withCreateSaveMutation = function (_return) {
	var viewConfig = _return.a.h$;
	if (!viewConfig.$) {
		var viewConfig_ = viewConfig.a;
		return A2($author$project$Guarantees$Update$Helper$withCreateSaveMutationForVersion, viewConfig_, _return);
	} else {
		return _return;
	}
};
var $author$project$Guarantees$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var quotationType = msg.a;
				var quoteId = msg.b;
				var maybeSaveId = msg.c;
				var tokenType = msg.d;
				return A2(
					$author$project$Utils$UpdateReturn$withQuery,
					A2($author$project$Guarantees$Update$Helper$maybeQuoteOrGetSaveQuery, quoteId, maybeSaveId),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									A2($elm$core$Task$perform, $author$project$Guarantees$Model$CurrentDate, $justinmimbs$date$Date$today),
									$author$project$Guarantees$Update$Helper$getHighlightedMessageFlag(quoteId),
									$author$project$Ports$pushUserRole(0)
								]),
							A2(
								$author$project$Guarantees$Model$mapUI,
								$author$project$Guarantees$Model$setSectionFilter($author$project$Types$Quote$NoSectionFilter),
								A2(
									$author$project$Guarantees$Update$Helper$updateUserType,
									tokenType,
									A2(
										$author$project$Guarantees$Update$Helper$updateSaveId,
										maybeSaveId,
										A2(
											$author$project$Guarantees$Update$Helper$updateQuoteId,
											quoteId,
											A2($author$project$Guarantees$Update$Helper$updateQuotationType, quotationType, model))))))));
			case 1:
				var quoteResponse = msg.a;
				return A2(
					$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
					2,
					A2(
						$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
						0,
						A2(
							$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
							1,
							A2($author$project$Guarantees$Update$Helper$updateFromQuoteResult, quoteResponse, model))));
			case 2:
				var field = msg.a;
				switch (field.$) {
					case 0:
						var isSummaryOpen = field.a;
						return A2(
							$author$project$Utils$UpdateReturn$withChangeModalState,
							isSummaryOpen,
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$toggleBlockScrollingClass(isSummaryOpen)
										]),
									A2(
										$author$project$Guarantees$Update$Helper$updateAppOptions,
										$author$project$Guarantees$Update$Helper$updateAppOptionsSetSummaryOpen(isSummaryOpen),
										model))));
					case 2:
						var _package = field.a;
						var isSelected = field.b;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A3($author$project$Guarantees$Update$Helper$updateUserSelectionTogglePackage, _package, isSelected, model)));
					case 3:
						var isYearlySelected = field.a;
						var paymentFrequency = isYearlySelected ? 0 : 1;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Guarantees$Update$Helper$updateViewConfig,
									$author$project$Guarantees$Model$mapUserSelection(
										$author$project$Guarantees$Model$UserSelection$changePaymentFrequency(paymentFrequency)),
									A2(
										$author$project$Guarantees$Update$Helper$updateAppOptions,
										$author$project$Guarantees$Update$Helper$updateAppOptionsSetYearlySelected(isYearlySelected),
										model))));
					case 4:
						var _package = field.a;
						var cluster = field.b;
						var guarantee = field.c;
						var isSelected = field.d;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A3(
									$author$project$Guarantees$Update$Helper$updateSwitchState,
									guarantee,
									isSelected,
									A5($author$project$Guarantees$Update$Helper$updateUserSelectionToggleGuaranteeInPackage, cluster, guarantee, _package, isSelected, model))));
					case 5:
						var guarantee = field.a;
						var isSelected = field.b;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A3($author$project$Guarantees$Update$Helper$toggleGuarantee, guarantee, isSelected, model)));
					case 6:
						var cluster = field.a;
						var isSelected = field.b;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A3($author$project$Guarantees$Update$Helper$toggleCluster, cluster, isSelected, model)));
					case 7:
						var bool = field.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Guarantees$Update$Helper$updateDetailsShowDetail, bool, model)));
					case 8:
						var slug = field.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Guarantees$Update$Helper$updateDetailsAccordionGroup,
									A2($author$project$Components$ArrowAccordionGroup$updateOpenAccordion, slug, model.fd.iQ),
									model)));
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					case 9:
						var isCartSummaryVisible = field.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$toggleBlockScrollingClass(isCartSummaryVisible)
									]),
								$author$project$Guarantees$Update$Helper$clearCartSummarySelectedPackagesSlugs(
									A2($author$project$Guarantees$Update$Helper$updateBottomBarCartSummaryVisibility, isCartSummaryVisible, model))));
					case 10:
						var packageSlug = field.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Guarantees$Update$Helper$updateCartSummarySelectedPackagesSlugs, packageSlug, model)));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								$author$project$Guarantees$Update$Helper$toggleSidebarCardListExpansion(model)));
				}
			case 3:
				return $author$project$Guarantees$Update$Helper$withCreateSaveMutation(
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$Guarantees$Update$Helper$setSubmitting, true, model))));
			case 4:
				var saveResult = msg.a;
				return A2(
					$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
					2,
					A2(
						$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
						0,
						A2(
							$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
							1,
							$author$project$Guarantees$Update$Helper$redirectToCheckout(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$Guarantees$Update$Helper$updateSaveSubmissionResult,
										saveResult,
										A2($author$project$Guarantees$Update$Helper$setSubmitting, false, model)))))));
			case 5:
				var getSaveResult = msg.a;
				return A2(
					$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
					2,
					A2(
						$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
						0,
						A2(
							$author$project$Guarantees$Update$Helper$pushFormDataToDataLayer,
							1,
							A2($author$project$Guarantees$Update$Helper$updateFetchSaveResult, getSaveResult, model))));
			case 6:
				var _package = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$Guarantees$Update$Helper$updateDetailsShowDetail,
							true,
							A2($author$project$Guarantees$Update$Helper$updatePackage, _package, model))));
			case 7:
				var maybeQuoteId = msg.a;
				var maybeSaveId = msg.b;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Modify, maybeQuoteId, maybeSaveId),
					A2(
						$author$project$Utils$UpdateReturn$withChangeModalState,
						false,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Guarantees$Model$OnToggle(
											$author$project$Guarantees$Model$Summary(false)))
									]),
								model))));
			case 8:
				var saveId = msg.a;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A3(
						$author$project$Routing$Checkout,
						$author$project$Guarantees$Model$pickQuotationType(model),
						saveId,
						1),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 9:
				var index = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Guarantees$Update$Helper$updateVisiblePackage, index, model)));
			case 10:
				var date = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Guarantees$Update$Helper$updateCurrentDate, date, model)));
			case 12:
				var guarantee = msg.a;
				var cluster = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(true)
							]),
						A3(
							$author$project$Guarantees$Update$Helper$pushGuaranteeDetailsPageInNavigableModal,
							guarantee,
							cluster,
							A2(
								$author$project$Guarantees$Update$Helper$initializeModalDetailsAccordionState,
								guarantee,
								A2(
									$author$project$Guarantees$Model$mapUI,
									$author$project$Guarantees$Model$mapEditOptionsSelectState(
										$elm$core$Basics$always($elm$core$Dict$empty)),
									model)))));
			case 13:
				var cluster = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(true)
							]),
						A2(
							$author$project$Guarantees$Update$Helper$pushClusterDetailsPageInNavigableModal,
							cluster,
							A2(
								$author$project$Guarantees$Model$mapUI,
								$author$project$Guarantees$Model$mapEditOptionsSelectState(
									$elm$core$Basics$always($elm$core$Dict$empty)),
								model))));
			case 14:
				var cluster = msg.a;
				var accordionToOpen = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(true)
							]),
						A2(
							$author$project$Guarantees$Update$Helper$pushEditOptionsPageInNavigableModal,
							cluster,
							A3($author$project$Guarantees$Update$Helper$initializeModalEditOptionsAccordionState, cluster, accordionToOpen, model))));
			case 15:
				var accordionType = msg.a;
				var modalId = msg.b;
				var index = msg.c;
				var isOpen = msg.e;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A5($author$project$Guarantees$Update$Helper$updateGuaranteeModalAccordions, modalId, accordionType, isOpen, index, model)));
			case 11:
				var navigableMsg = msg.a;
				var withBlockScrollingCmd = function (m) {
					return A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(
								$author$project$Components$NavigableModal$pickIsOpen(m.bM.d3))
							]),
						m);
				};
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					withBlockScrollingCmd(
						A2(
							$author$project$Guarantees$Model$mapUI,
							$author$project$Guarantees$Model$mapNavigableModalModel(
								$author$project$Components$NavigableModal$update(navigableMsg)),
							model)));
			case 16:
				var selectSlug = msg.a;
				var choicesList = msg.b;
				var defaultValue = msg.c;
				var selectMsg = msg.d;
				var currentSelectState = A2(
					$elm$core$Maybe$withDefault,
					A2($primait$pyxis_components$Prima$Pyxis$Form$Select$initWithDefault, defaultValue, choicesList),
					A2($elm$core$Dict$get, selectSlug, model.bM.ax));
				var updatedState = function () {
					if (!selectMsg.$) {
						return A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Select$update,
							selectMsg,
							$primait$pyxis_components$Prima$Pyxis$Form$Select$reset(currentSelectState));
					} else {
						return A2($primait$pyxis_components$Prima$Pyxis$Form$Select$update, selectMsg, currentSelectState);
					}
				}();
				var updatedSelection = $primait$pyxis_components$Prima$Pyxis$Form$Select$selectedValue(updatedState);
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A3(
							$author$project$Utils$maybeApply,
							$author$project$Guarantees$Update$Helper$updateGuaranteeOption,
							updatedSelection,
							A3(
								$author$project$Utils$maybeApply,
								$author$project$Guarantees$Update$Helper$updateClusterOption,
								updatedSelection,
								A2(
									$author$project$Guarantees$Model$mapUI,
									$author$project$Guarantees$Model$mapEditOptionsSelectState(
										A2($elm$core$Dict$insert, selectSlug, updatedState)),
									$author$project$Guarantees$Model$closeAllEditOptionSelects(model))))));
			case 17:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Guarantees$Model$closeAllEditOptionSelects(model)));
			case 18:
				var sectionFilter = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$Guarantees$Model$mapUI,
							$author$project$Guarantees$Model$setSectionFilter(sectionFilter),
							model)));
			case 19:
				var dialogType = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(true)
							]),
						A2($author$project$Guarantees$Update$Helper$openDependenciesDialog, dialogType, model)));
			case 20:
				var dialogMsg = msg.a;
				var isScrollingBlocked = $author$project$Components$NavigableModal$pickIsOpen(model.bM.d3);
				if (!dialogMsg.$) {
					var toggleElement = dialogMsg.a;
					return A2(
						$author$project$Utils$addCommandWithUpdateReturn,
						$author$project$Utils$toggleBlockScrollingClass(isScrollingBlocked),
						A2(
							$author$project$Guarantees$Update$update,
							$author$project$Guarantees$Model$OnToggle(toggleElement),
							$author$project$Guarantees$Update$Helper$closeDependenciesDialog(model)));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									$author$project$Utils$toggleBlockScrollingClass(isScrollingBlocked)
								]),
							$author$project$Guarantees$Update$Helper$closeDependenciesDialog(model)));
				}
			case 21:
				var messageSlug = msg.a;
				var quoteId = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($author$project$Guarantees$Update$Helper$updateHighlightedMessagesFlag, messageSlug, quoteId)
							]),
						A2(
							$author$project$Guarantees$Model$mapUI,
							$author$project$Guarantees$Model$setIsBundleDiscountMessageVisible(false),
							model)));
			case 22:
				var localStorageItemJson = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Guarantees$Update$Helper$readHighlightedMessageFlagResponse, localStorageItemJson, model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$NotAvailable$Update$Helper$updateReason = F2(
	function (reason, model) {
		return _Utils_update(
			model,
			{
				k6: $elm$core$Maybe$Just(reason)
			});
	});
var $author$project$NotAvailable$Update$Helper$updateTokenType = F2(
	function (tokenType, model) {
		return _Utils_update(
			model,
			{lK: tokenType});
	});
var $author$project$NotAvailable$Update$update = F2(
	function (msg, model) {
		var flags = model.nv;
		switch (msg.$) {
			case 0:
				var reason = msg.a;
				var token = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Ports$pushUserRole(0)
							]),
						A2(
							$author$project$NotAvailable$Update$Helper$updateTokenType,
							token,
							A2($author$project$NotAvailable$Update$Helper$updateReason, reason, model))));
			case 1:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/reserved/login',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 2:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/assicurazioni-online',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 3:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/supporto-online',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 4:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/home',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 5:
				var saveId = msg.a;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Modify, $elm$core$Maybe$Nothing, saveId),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 6:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/reserved/login',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 7:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/home',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickIntermediariUrl(flags),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
		}
	});
var $author$project$OneClick$Model$Hidden = {$: 1};
var $author$project$OneClick$Model$SetEffectiveDateTooltipState = function (a) {
	return {$: 11, a: a};
};
var $author$project$OneClick$Model$ModelError = function (a) {
	return {$: 1, a: a};
};
var $author$project$OneClick$Model$GotClientConfiguration = function (a) {
	return {$: 2, a: a};
};
var $author$project$Gql$Mastrota$Query$braintreeClientConfiguration = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'braintreeClientConfiguration', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$OneClick$Model$ClientConfiguration = function (token) {
	return {p1: token};
};
var $author$project$Gql$Mastrota$Object$ClientConfiguration$braintreeClientToken = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'braintreeClientToken', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$clientConfigurationSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$ClientConfiguration$braintreeClientToken,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$ClientConfiguration));
var $author$project$OneClick$Api$Offer$clientConfigurationQuery = $author$project$Gql$Mastrota$Query$braintreeClientConfiguration($author$project$OneClick$Api$Offer$clientConfigurationSelection);
var $author$project$OneClick$Api$Offer$getClientConfiguration = function (apiUrl) {
	return A3($author$project$Api$makeRequest, apiUrl, $author$project$OneClick$Model$GotClientConfiguration, $author$project$OneClick$Api$Offer$clientConfigurationQuery);
};
var $author$project$OneClick$Model$GotOffer = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$makeAuthRequest = F4(
	function (apiUrl, token, msg, query) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'Authorization',
				$author$project$Utils$withBearer(token),
				$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
					A2($dillonkearns$elm_graphql$Graphql$Http$queryRequest, apiUrl, query))));
	});
var $author$project$Gql$Mastrota$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Gql$Mastrota$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $author$project$MastrotaScalarCodecs$codecs = $author$project$Gql$Mastrota$Scalar$defineCodecs(
	{
		mI: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedTime) {
					if (!maybeParsedTime.$) {
						var parsedTime = maybeParsedTime.a;
						return $elm$json$Json$Decode$succeed(parsedTime);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse Datetime.');
					}
				},
				A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime, $elm$core$Result$toMaybe),
					$elm$json$Json$Decode$string)),
			fk: function (dateTime) {
				return $elm$json$Json$Encode$string(
					$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(dateTime));
			}
		},
		mJ: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeDecimal) {
					return A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Decode$fail('impossibile convertire il valore in decimal'),
						A2($elm$core$Maybe$map, $elm$json$Json$Decode$succeed, maybeDecimal));
				},
				A2($elm$json$Json$Decode$map, $prikhi$decimal$Decimal$fromString, $elm$json$Json$Decode$string)),
			fk: function (decimal) {
				return $elm$json$Json$Encode$string(
					$prikhi$decimal$Decimal$toString(decimal));
			}
		},
		iy: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedId) {
					if (!maybeParsedId.$) {
						var parsedId = maybeParsedId.a;
						return $elm$json$Json$Decode$succeed(parsedId);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse ID as an Uuid.');
					}
				},
				A2($elm$json$Json$Decode$map, $danyx23$elm_uuid$Uuid$fromString, $elm$json$Json$Decode$string)),
			fk: function (uuid) {
				return $elm$json$Json$Encode$string(
					$danyx23$elm_uuid$Uuid$toString(uuid));
			}
		},
		mK: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedTime) {
					if (!maybeParsedTime.$) {
						var parsedTime = maybeParsedTime.a;
						return $elm$json$Json$Decode$succeed(parsedTime);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse Time as an Posix.');
					}
				},
				A2(
					$elm$json$Json$Decode$map,
					A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromIsoString, $elm$core$Result$toMaybe),
					$elm$json$Json$Decode$string)),
			fk: function (dateTime) {
				return $elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(dateTime));
			}
		},
		t: {
			m0: A2(
				$elm$json$Json$Decode$andThen,
				function (maybeParsedId) {
					if (!maybeParsedId.$) {
						var parsedId = maybeParsedId.a;
						return $elm$json$Json$Decode$succeed(parsedId);
					} else {
						return $elm$json$Json$Decode$fail('Could not parse ID as an Uuid.');
					}
				},
				A2($elm$json$Json$Decode$map, $danyx23$elm_uuid$Uuid$fromString, $elm$json$Json$Decode$string)),
			fk: function (uuid) {
				return $elm$json$Json$Encode$string(
					$danyx23$elm_uuid$Uuid$toString(uuid));
			}
		}
	});
var $author$project$Gql$Mastrota$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).fk,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Gql$Mastrota$Query$offer = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'offer',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.nM,
					A2(
						$author$project$Gql$Mastrota$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$MastrotaScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Mastrota$Union$GetOfferResponse$Fragments = F2(
	function (onOffer, onGetOfferError) {
		return {gk: onGetOfferError, gu: onOffer};
	});
var $author$project$Gql$Mastrota$Union$GetOfferResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Offer', selections____.gu),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'GetOfferError', selections____.gk)
			]));
};
var $author$project$OneClick$Model$OfferErr = function (a) {
	return {$: 1, a: a};
};
var $author$project$OneClick$Model$OfferError = F2(
	function (reason, placeholder) {
		return {oU: placeholder, k6: reason};
	});
var $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$AuthExpired = 0;
var $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$OfferExpired = 2;
var $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$OfferNotPurchasable = 3;
var $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$OfferPurchased = 1;
var $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AUTH_EXPIRED':
				return $elm$json$Json$Decode$succeed(0);
			case 'OFFER_PURCHASED':
				return $elm$json$Json$Decode$succeed(1);
			case 'OFFER_EXPIRED':
				return $elm$json$Json$Decode$succeed(2);
			case 'OFFER_NOT_PURCHASABLE':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Invalid GetOfferErrorKind type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$GetOfferError$kind = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.GetOfferErrorKind.GetOfferErrorKind', 'kind', _List_Nil, $author$project$Gql$Mastrota$Enum$GetOfferErrorKind$decoder);
var $author$project$OneClick$Model$OfferAuthExpired = 1;
var $author$project$OneClick$Model$OfferExpired = 2;
var $author$project$OneClick$Model$OfferNotPurchasable = 3;
var $author$project$OneClick$Model$OfferPurchased = 0;
var $author$project$OneClick$Api$Offer$mapOfferResponseErrorKind = function (kind) {
	switch (kind) {
		case 1:
			return 0;
		case 0:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$OneClick$Api$Offer$offerErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$OneClick$Api$Offer$mapOfferResponseErrorKind, $author$project$Gql$Mastrota$Object$GetOfferError$kind),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferError)));
var $author$project$OneClick$Api$Offer$offerResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$offerErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferErr));
var $author$project$OneClick$Model$OfferOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$OneClick$Model$Offer = function (id) {
	return function (price) {
		return function (status) {
			return function (saveCode) {
				return function (name) {
					return function (effectiveDate) {
						return function (details) {
							return function (contractor) {
								return function (limit) {
									return function (deductible) {
										return {mT: contractor, m1: deductible, m4: details, m8: effectiveDate, nM: id, T: limit, oq: name, oY: price, lg: saveCode, hK: status};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Mastrota$Object$Offer$contractor = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'contractor', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$Offer$deductible = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'deductible', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Offer$details = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'details', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Gql$Mastrota$Object$Offer$effectiveDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.DateTime',
	'effectiveDate',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).mI.m0);
var $author$project$Gql$Mastrota$Object$Offer$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).t.m0);
var $author$project$Gql$Mastrota$Object$Offer$limit = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'limit', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Offer$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Model$Contractor = F9(
	function (firstName, lastName, email, phoneNumber, birthDate, birthCity, birthCountry, gender, address) {
		return {bP: address, ih: birthCity, mj: birthCountry, mk: birthDate, fi: email, nu: firstName, ny: gender, n8: lastName, cu: phoneNumber};
	});
var $author$project$Gql$Mastrota$Object$Contractor$address = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'address', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$Contractor$cityOfBirth = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cityOfBirth', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Contractor$countryOfBirth = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'countryOfBirth', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Contractor$dateOfBirth = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.NaiveDate',
	'dateOfBirth',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).mK.m0);
var $author$project$Gql$Mastrota$Object$Contractor$email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'email', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Contractor$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Enum$Gender$F = 1;
var $author$project$Gql$Mastrota$Enum$Gender$M = 0;
var $author$project$Gql$Mastrota$Enum$Gender$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'M':
				return $elm$json$Json$Decode$succeed(0);
			case 'F':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid Gender type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Contractor$gender = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.Gender.Gender', 'gender', _List_Nil, $author$project$Gql$Mastrota$Enum$Gender$decoder);
var $author$project$Gql$Mastrota$Object$Contractor$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Model$F = 1;
var $author$project$OneClick$Model$M = 0;
var $author$project$OneClick$Api$Offer$mapGender = function (gender) {
	if (!gender) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$OneClick$Model$ContractorAddress = F6(
	function (city, countryCode, region, streetAddress, streetNumber, zipCode) {
		return {my: city, e6: countryCode, hk: region, hN: streetAddress, pO: streetNumber, qg: zipCode};
	});
var $author$project$Gql$Mastrota$Object$ContractorAddress$city = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'city', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$ContractorAddress$countryCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'countryCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$ContractorAddress$region = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'region', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$ContractorAddress$streetAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetAddress', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$ContractorAddress$streetNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$ContractorAddress$zipCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'zipCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerContractorAddressSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$ContractorAddress$zipCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$ContractorAddress$streetNumber,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$ContractorAddress$streetAddress,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Mastrota$Object$ContractorAddress$region,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Mastrota$Object$ContractorAddress$countryCode,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Mastrota$Object$ContractorAddress$city,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$ContractorAddress)))))));
var $author$project$Gql$Mastrota$Object$Contractor$phoneNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'phoneNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerContractorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$Contractor$address($author$project$OneClick$Api$Offer$offerContractorAddressSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$OneClick$Api$Offer$mapGender, $author$project$Gql$Mastrota$Object$Contractor$gender),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$Contractor$countryOfBirth,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Guarantees$Api$Quote$cityFromString, $author$project$Gql$Mastrota$Object$Contractor$cityOfBirth),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Mastrota$Object$Contractor$dateOfBirth,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Mastrota$Object$Contractor$phoneNumber,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Mastrota$Object$Contractor$email,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Mastrota$Object$Contractor$lastName,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Mastrota$Object$Contractor$firstName,
									$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$Contractor))))))))));
var $author$project$OneClick$Model$OfferDetail = F8(
	function (title, subtitle, cta, offerBox, offerCoverage, heroImage, infobox, purchaseSection) {
		return {iG: cta, nJ: heroImage, nS: infobox, oC: offerBox, oD: offerCoverage, o8: purchaseSection, eu: subtitle, eE: title};
	});
var $author$project$Gql$Mastrota$Object$OfferDetail$coverages = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'coverages', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$OfferDetail$cta = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cta', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$OfferDetail$heroImage = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'heroImage',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Mastrota$Object$OfferDetail$infoBox = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'infoBox',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Mastrota$Object$OfferDetail$offerBox = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerBox', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$OneClick$Model$OfferCoverage = F3(
	function (title, includes, excludes) {
		return {nh: excludes, nO: includes, eE: title};
	});
var $author$project$Gql$Mastrota$Object$OfferCoverage$excludes = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'excludes',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Mastrota$Object$OfferCoverage$includes = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'includes',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Gql$Mastrota$Object$OfferCoverage$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerCoverageSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$OfferCoverage$excludes,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$OfferCoverage$includes,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$OfferCoverage$title,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferCoverage))));
var $author$project$OneClick$Model$HeroImage = F2(
	function (desktop, mobile) {
		return {m3: desktop, om: mobile};
	});
var $author$project$Gql$Mastrota$Object$HeroImage$desktop = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'desktop', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$HeroImage$mobile = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'mobile', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerHeroImageSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$HeroImage$mobile,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$HeroImage$desktop,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$HeroImage)));
var $author$project$OneClick$Model$InfoBox = F3(
	function (slug, message, description) {
		return {m2: description, of: message, bH: slug};
	});
var $author$project$Gql$Mastrota$Object$InfoBox$description = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'description', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$InfoBox$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Model$InfoBoxDescription = F2(
	function (title, message) {
		return {of: message, eE: title};
	});
var $author$project$Gql$Mastrota$Object$InfoBoxDescription$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$InfoBoxDescription$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerInfoBoxDescriptionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$InfoBoxDescription$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$InfoBoxDescription$title,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$InfoBoxDescription)));
var $author$project$Gql$Mastrota$Object$InfoBox$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerInfoBoxSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$InfoBox$description($author$project$OneClick$Api$Offer$offerInfoBoxDescriptionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$InfoBox$message,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$InfoBox$slug,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$InfoBox))));
var $author$project$OneClick$Model$PurchaseSection = F2(
	function (title, subtitle) {
		return {eu: subtitle, eE: title};
	});
var $author$project$Gql$Mastrota$Object$PurchaseSection$subtitle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'subtitle', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$PurchaseSection$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerPurchaseSectionSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$PurchaseSection$subtitle,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$PurchaseSection$title,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$PurchaseSection)));
var $author$project$OneClick$Model$OfferSummaryBox = F4(
	function (title, subtitle, cta, cards) {
		return {mt: cards, iG: cta, eu: subtitle, eE: title};
	});
var $author$project$Gql$Mastrota$Object$OfferSummaryBox$cards = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'cards',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Mastrota$Object$OfferSummaryBox$cta = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cta', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$OfferSummaryBox$subtitle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'subtitle', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Model$SummaryCard = F3(
	function (slug, title, subtitle) {
		return {bH: slug, eu: subtitle, eE: title};
	});
var $author$project$Gql$Mastrota$Object$SummaryCard$slug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'slug', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$SummaryCard$subTitle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'subTitle', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$SummaryCard$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$summaryCardSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$SummaryCard$subTitle,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$SummaryCard$title,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$SummaryCard$slug,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$SummaryCard))));
var $author$project$Gql$Mastrota$Object$OfferSummaryBox$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerSummaryBoxSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$OfferSummaryBox$cards($author$project$OneClick$Api$Offer$summaryCardSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$OfferSummaryBox$cta,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$OfferSummaryBox$subtitle,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Mastrota$Object$OfferSummaryBox$title,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferSummaryBox)))));
var $author$project$Gql$Mastrota$Object$OfferDetail$purchaseSection = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'purchaseSection', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$OfferDetail$subTitle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'subTitle', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$OfferDetail$title = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'title', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$OneClick$Api$Offer$offerDetailsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$OfferDetail$purchaseSection($author$project$OneClick$Api$Offer$offerPurchaseSectionSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$OfferDetail$infoBox($author$project$OneClick$Api$Offer$offerInfoBoxSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$OfferDetail$heroImage($author$project$OneClick$Api$Offer$offerHeroImageSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Mastrota$Object$OfferDetail$coverages($author$project$OneClick$Api$Offer$offerCoverageSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Mastrota$Object$OfferDetail$offerBox($author$project$OneClick$Api$Offer$offerSummaryBoxSelection),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Mastrota$Object$OfferDetail$cta,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Mastrota$Object$OfferDetail$subTitle,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Mastrota$Object$OfferDetail$title,
								$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferDetail)))))))));
var $author$project$OneClick$Model$Active = 0;
var $author$project$OneClick$Model$Expired = 3;
var $author$project$OneClick$Model$NotPurchasable = 2;
var $author$project$OneClick$Model$Purchased = 1;
var $author$project$OneClick$Api$Offer$offerStatusMapper = function (status) {
	switch (status) {
		case 0:
			return 0;
		case 2:
			return 1;
		case 1:
			return 3;
		default:
			return 2;
	}
};
var $author$project$Gql$Mastrota$Object$Offer$price = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.Decimal',
	'price',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).mJ.m0);
var $author$project$Gql$Mastrota$Object$Offer$saveCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'saveCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Enum$OfferStatus$Active = 0;
var $author$project$Gql$Mastrota$Enum$OfferStatus$Expired = 1;
var $author$project$Gql$Mastrota$Enum$OfferStatus$NotPurchasable = 3;
var $author$project$Gql$Mastrota$Enum$OfferStatus$Purchased = 2;
var $author$project$Gql$Mastrota$Enum$OfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'Active':
				return $elm$json$Json$Decode$succeed(0);
			case 'Expired':
				return $elm$json$Json$Decode$succeed(1);
			case 'Purchased':
				return $elm$json$Json$Decode$succeed(2);
			case 'NotPurchasable':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Invalid OfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Offer$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.OfferStatus.OfferStatus', 'status', _List_Nil, $author$project$Gql$Mastrota$Enum$OfferStatus$decoder);
var $author$project$OneClick$Api$Offer$offerSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$Offer$deductible,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$Offer$limit,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$Offer$contractor($author$project$OneClick$Api$Offer$offerContractorSelection),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Mastrota$Object$Offer$details($author$project$OneClick$Api$Offer$offerDetailsSelection),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Mastrota$Object$Offer$effectiveDate,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Mastrota$Object$Offer$name,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Mastrota$Object$Offer$saveCode,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$OneClick$Api$Offer$offerStatusMapper, $author$project$Gql$Mastrota$Object$Offer$status),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Mastrota$Object$Offer$price,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Mastrota$Object$Offer$id,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$Offer)))))))))));
var $author$project$OneClick$Api$Offer$offerResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$offerSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$OfferOk));
var $author$project$OneClick$Api$Offer$offerResponseSelection = $author$project$Gql$Mastrota$Union$GetOfferResponse$fragments(
	A2($author$project$Gql$Mastrota$Union$GetOfferResponse$Fragments, $author$project$OneClick$Api$Offer$offerResponseOkSelection, $author$project$OneClick$Api$Offer$offerResponseErrorSelection));
var $author$project$OneClick$Api$Offer$offerQuery = function (id) {
	return A2(
		$author$project$Gql$Mastrota$Query$offer,
		{nM: id},
		$author$project$OneClick$Api$Offer$offerResponseSelection);
};
var $author$project$OneClick$Api$Offer$getOffer = F3(
	function (apiUrl, token, id) {
		return A4(
			$author$project$Api$makeAuthRequest,
			apiUrl,
			token,
			$author$project$OneClick$Model$GotOffer,
			$author$project$OneClick$Api$Offer$offerQuery(id));
	});
var $author$project$OneClick$Model$ModelLoading = function (a) {
	return {$: 2, a: a};
};
var $author$project$OneClick$Update$setLoading = F2(
	function (tokenConfig, model) {
		return _Utils_update(
			model,
			{
				cG: $author$project$OneClick$Model$ModelLoading(
					{mC: $krisajenkins$remotedata$RemoteData$Loading, oE: $krisajenkins$remotedata$RemoteData$Loading, p2: tokenConfig})
			});
	});
var $author$project$OneClick$Update$initialState = F3(
	function (id, tokenConfig, model) {
		if (!tokenConfig.$) {
			var token = tokenConfig.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						A3(
						$author$project$OneClick$Api$Offer$getOffer,
						$author$project$Types$pickMastrotaUrl(model.nv),
						token.p1,
						id),
						$author$project$OneClick$Api$Offer$getClientConfiguration(
						$author$project$Types$pickMastrotaUrl(model.nv))
					]),
				A2($author$project$OneClick$Update$setLoading, token, model));
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cG: $author$project$OneClick$Model$ModelError(
							{})
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$OneClick$InitBraintree$init = F2(
	function (token, paymentMethod) {
		return (!paymentMethod) ? $author$project$Checkout$Ports$braintree3DSSetup(token) : $elm$core$Platform$Cmd$none;
	});
var $author$project$OneClick$Update$maybeInitBraintree = function (_v0) {
	var model = _v0.a;
	var state = model.cG;
	var msg = _v0.b;
	var updateReturn = _v0.c;
	if (state.$ === 3) {
		var clientConfiguration = state.a.mB;
		var paymentMethod = state.a.oS;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						msg,
						A2($author$project$OneClick$InitBraintree$init, clientConfiguration.p1, paymentMethod)
					])),
			updateReturn);
	} else {
		return _Utils_Tuple3(model, msg, updateReturn);
	}
};
var $author$project$OneClick$InitBraintree$payWithCard = A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Platform$Cmd$none, $author$project$Checkout$Ports$payWithCreditCard);
var $author$project$OneClick$InitBraintree$payWithPaypal = A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$core$Platform$Cmd$none, $author$project$Checkout$Ports$payWithPayPal);
var $author$project$OneClick$Update$payWithPaymentMethod = F2(
	function (paymentMethod, model) {
		var state = model.cG;
		if (state.$ === 3) {
			var clientConfiguration = state.a.mB;
			switch (paymentMethod) {
				case 0:
					return _Utils_Tuple2(
						model,
						$author$project$OneClick$InitBraintree$payWithCard(
							$elm$core$Maybe$Just(clientConfiguration.p1)));
				case 1:
					return _Utils_Tuple2(
						model,
						$author$project$OneClick$InitBraintree$payWithPaypal(
							$elm$core$Maybe$Just(clientConfiguration.p1)));
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$OneClick$Model$NoOp = {$: 15};
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $author$project$OneClick$Update$scrollToSection = function (sectionId) {
	return A2(
		$elm$core$Task$attempt,
		$elm$core$Basics$always($author$project$OneClick$Model$NoOp),
		A2(
			$elm$core$Task$andThen,
			function (info) {
				return A2($elm$browser$Browser$Dom$setViewport, 0, info.i_.h0 - 20);
			},
			$elm$browser$Browser$Dom$getElement(sectionId)));
};
var $author$project$OneClick$Model$Bottom = 1;
var $author$project$OneClick$Model$Top = 0;
var $author$project$OneClick$Model$Visible = function (a) {
	return {$: 0, a: a};
};
var $author$project$OneClick$Update$showEffectiveDateTooltip = function () {
	var resultHandler = function (result) {
		if (!result.$) {
			var info = result.a;
			return (((info.lX.h0 + info.lX.nI) - info.i_.h0) < 200) ? $author$project$OneClick$Model$SetEffectiveDateTooltipState(
				$author$project$OneClick$Model$Visible(0)) : $author$project$OneClick$Model$SetEffectiveDateTooltipState(
				$author$project$OneClick$Model$Visible(1));
		} else {
			return $author$project$OneClick$Model$NoOp;
		}
	};
	return A2(
		$elm$core$Task$attempt,
		resultHandler,
		$elm$browser$Browser$Dom$getElement('one-click__payment__effective-date-action-wrapper'));
}();
var $author$project$OneClick$Update$createConfigBillingAddress = function (_v0) {
	var firstName = _v0.nu;
	var lastName = _v0.n8;
	var phoneNumber = _v0.cu;
	var address = _v0.bP;
	return {e6: address.e6, jq: firstName, j5: address.my, cu: phoneNumber, kJ: address.qg, hk: address.hk, hN: address.hN + (' ' + address.pO), lC: lastName};
};
var $author$project$OneClick$Update$createConfigWithOrderAndToken = F3(
	function (brainTreeModel, _v0, token) {
		var contractor = _v0.mT;
		return {
			e0: $author$project$OneClick$Update$createConfigBillingAddress(contractor),
			e1: brainTreeModel.e1,
			fi: contractor.fi,
			oz: brainTreeModel.oz,
			cu: contractor.cu,
			p1: token
		};
	});
var $author$project$OneClick$Update$createBrainTreeConfig = function (_v0) {
	var brainTreeModel = _v0.mp;
	var offer = _v0.oB;
	return A3($author$project$OneClick$Update$createConfigWithOrderAndToken, brainTreeModel, offer, brainTreeModel.p1);
};
var $author$project$OneClick$Update$createVerificationConfig = function (model) {
	return {
		bS: $prikhi$decimal$Decimal$toFloat(model.oB.oY),
		iC: $author$project$OneClick$Update$createBrainTreeConfig(model)
	};
};
var $author$project$OneClick$Model$GotPaymentResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$makeAuthPost = F4(
	function (apiUrl, token, query, msg) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Http$send,
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, msg),
			A3(
				$dillonkearns$elm_graphql$Graphql$Http$withHeader,
				'Authorization',
				$author$project$Utils$withBearer(token),
				$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
					A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, apiUrl, query))));
	});
var $author$project$Gql$Mastrota$Mutation$payOffer = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'payOffer',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'offerId',
					requiredArgs____.kr,
					A2(
						$author$project$Gql$Mastrota$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$MastrotaScalarCodecs$codecs)),
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'nonce', requiredArgs____.oz, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Mastrota$Union$PayOfferResponse$Fragments = F3(
	function (onPayOfferOk, onInsurance, onPaymentError) {
		return {gp: onInsurance, gx: onPayOfferOk, gz: onPaymentError};
	});
var $author$project$Gql$Mastrota$Union$PayOfferResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PayOfferOk', selections____.gx),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Insurance', selections____.gp),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PaymentError', selections____.gz)
			]));
};
var $author$project$OneClick$Model$PayOfferError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Payment$Model$Error$PaymentError = F2(
	function (message, details) {
		return {m4: details, of: message};
	});
var $author$project$Gql$Mastrota$Object$PaymentError$details = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'details', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Mastrota$Object$PaymentError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Union$PaymentErrorDetails$Fragments = F2(
	function (onLiraError, onPaymentFailed) {
		return {gr: onLiraError, gA: onPaymentFailed};
	});
var $author$project$Gql$Mastrota$Union$PaymentErrorDetails$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'LiraError', selections____.gr),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PaymentFailed', selections____.gA)
			]));
};
var $author$project$Payment$Model$Error$PaymentFailedError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Payment$Model$Failed$PaymentFailed = function (status) {
	return {hK: status};
};
var $author$project$Payment$Model$Failed$AuthorizationExpired = 0;
var $author$project$Payment$Model$Failed$Authorized = 1;
var $author$project$Payment$Model$Failed$Failed = 2;
var $author$project$Payment$Model$Failed$GatewayRejected = 3;
var $author$project$Payment$Model$Failed$ProcessorDeclined = 4;
var $author$project$Payment$Model$Failed$Settled = 5;
var $author$project$Payment$Model$Failed$SettlementDeclined = 6;
var $author$project$Payment$Model$Failed$SettlementPending = 7;
var $author$project$Payment$Model$Failed$Settling = 8;
var $author$project$Payment$Model$Failed$SubmittedForSettlement = 9;
var $author$project$Payment$Model$Failed$Unknown = 11;
var $author$project$Payment$Model$Failed$Voided = 10;
var $author$project$OneClick$Api$Offer$mapPaymentStatus = function (_class) {
	switch (_class) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		default:
			return 11;
	}
};
var $author$project$Gql$Mastrota$Enum$PaymentStatus$AuthorizationExpired = 0;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Authorized = 1;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Failed = 2;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$GatewayRejected = 3;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$ProcessorDeclined = 4;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Settled = 5;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$SettlementDeclined = 6;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$SettlementPending = 7;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Settling = 8;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$SubmittedForSettlement = 9;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Unknown = 11;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$Voided = 10;
var $author$project$Gql$Mastrota$Enum$PaymentStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AUTHORIZATION_EXPIRED':
				return $elm$json$Json$Decode$succeed(0);
			case 'AUTHORIZED':
				return $elm$json$Json$Decode$succeed(1);
			case 'FAILED':
				return $elm$json$Json$Decode$succeed(2);
			case 'GATEWAY_REJECTED':
				return $elm$json$Json$Decode$succeed(3);
			case 'PROCESSOR_DECLINED':
				return $elm$json$Json$Decode$succeed(4);
			case 'SETTLED':
				return $elm$json$Json$Decode$succeed(5);
			case 'SETTLEMENT_DECLINED':
				return $elm$json$Json$Decode$succeed(6);
			case 'SETTLEMENT_PENDING':
				return $elm$json$Json$Decode$succeed(7);
			case 'SETTLING':
				return $elm$json$Json$Decode$succeed(8);
			case 'SUBMITTED_FOR_SETTLEMENT':
				return $elm$json$Json$Decode$succeed(9);
			case 'VOIDED':
				return $elm$json$Json$Decode$succeed(10);
			case 'UNKNOWN':
				return $elm$json$Json$Decode$succeed(11);
			default:
				return $elm$json$Json$Decode$fail('Invalid PaymentStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$PaymentFailed$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PaymentStatus.PaymentStatus', 'status', _List_Nil, $author$project$Gql$Mastrota$Enum$PaymentStatus$decoder);
var $author$project$OneClick$Api$Offer$paymentFailedSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$OneClick$Api$Offer$mapPaymentStatus, $author$project$Gql$Mastrota$Object$PaymentFailed$status),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Payment$Model$Failed$PaymentFailed));
var $author$project$OneClick$Api$Offer$paymentFailedDetailsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$paymentFailedSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Payment$Model$Error$PaymentFailedError));
var $author$project$Payment$Model$Error$PaymentLiraError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Payment$Model$LiraError$LiraError = function (_class) {
	return {bX: _class};
};
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$Authentication = 0;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$Authorization = 1;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$Internal = 2;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$NotFound = 4;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$NotImplemented = 5;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$ResourceLimit = 6;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$ServiceAvailability = 7;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$Unknown = 9;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$UnsupportedClient = 3;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$Validation = 8;
var $author$project$Gql$Mastrota$Enum$LiraErrorClass$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AUTHENTICATION':
				return $elm$json$Json$Decode$succeed(0);
			case 'AUTHORIZATION':
				return $elm$json$Json$Decode$succeed(1);
			case 'INTERNAL':
				return $elm$json$Json$Decode$succeed(2);
			case 'UNSUPPORTED_CLIENT':
				return $elm$json$Json$Decode$succeed(3);
			case 'NOT_FOUND':
				return $elm$json$Json$Decode$succeed(4);
			case 'NOT_IMPLEMENTED':
				return $elm$json$Json$Decode$succeed(5);
			case 'RESOURCE_LIMIT':
				return $elm$json$Json$Decode$succeed(6);
			case 'SERVICE_AVAILABILITY':
				return $elm$json$Json$Decode$succeed(7);
			case 'VALIDATION':
				return $elm$json$Json$Decode$succeed(8);
			case 'UNKNOWN':
				return $elm$json$Json$Decode$succeed(9);
			default:
				return $elm$json$Json$Decode$fail('Invalid LiraErrorClass type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$LiraError$class = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.LiraErrorClass.LiraErrorClass', 'class', _List_Nil, $author$project$Gql$Mastrota$Enum$LiraErrorClass$decoder);
var $author$project$Payment$Model$LiraError$Authentication = 0;
var $author$project$Payment$Model$LiraError$Authorization = 1;
var $author$project$Payment$Model$LiraError$Internal = 2;
var $author$project$Payment$Model$LiraError$NotFound = 4;
var $author$project$Payment$Model$LiraError$NotImplemented = 5;
var $author$project$Payment$Model$LiraError$ResourceLimit = 6;
var $author$project$Payment$Model$LiraError$ServiceAvailability = 7;
var $author$project$Payment$Model$LiraError$Unknown = 9;
var $author$project$Payment$Model$LiraError$UnsupportedClient = 3;
var $author$project$Payment$Model$LiraError$Validation = 8;
var $author$project$OneClick$Api$Offer$mapLiraErrorClass = function (_class) {
	switch (_class) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		default:
			return 9;
	}
};
var $author$project$OneClick$Api$Offer$paymentLiraErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$OneClick$Api$Offer$mapLiraErrorClass, $author$project$Gql$Mastrota$Object$LiraError$class),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Payment$Model$LiraError$LiraError));
var $author$project$OneClick$Api$Offer$paymentLiraErrorDetailsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$paymentLiraErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Payment$Model$Error$PaymentLiraError));
var $author$project$OneClick$Api$Offer$paymentErrorDetailsSelection = $author$project$Gql$Mastrota$Union$PaymentErrorDetails$fragments(
	A2($author$project$Gql$Mastrota$Union$PaymentErrorDetails$Fragments, $author$project$OneClick$Api$Offer$paymentLiraErrorDetailsSelection, $author$project$OneClick$Api$Offer$paymentFailedDetailsSelection));
var $author$project$OneClick$Api$Offer$paymentErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$PaymentError$details($author$project$OneClick$Api$Offer$paymentErrorDetailsSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$PaymentError$message,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Payment$Model$Error$PaymentError)));
var $author$project$OneClick$Api$Offer$payOfferErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$paymentErrorSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$PayOfferError));
var $author$project$OneClick$Model$PayOfferIssued = function (a) {
	return {$: 1, a: a};
};
var $author$project$OneClick$Model$Insurance = F3(
	function (id, code, orderId) {
		return {mH: code, nM: id, kw: orderId};
	});
var $author$project$Gql$Mastrota$Object$Insurance$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'code', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Mastrota$Object$Insurance$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).t.m0);
var $author$project$Gql$Mastrota$Object$Insurance$orderId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.Uuid',
	'orderId',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).t.m0);
var $author$project$OneClick$Api$Offer$insuranceSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$Insurance$orderId,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Mastrota$Object$Insurance$code,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Mastrota$Object$Insurance$id,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$Insurance))));
var $author$project$OneClick$Api$Offer$payOfferOkIssuedSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$OneClick$Api$Offer$insuranceSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$PayOfferIssued));
var $author$project$OneClick$Model$PayOfferOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Mastrota$Object$PayOfferOk$orderId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'MastrotaScalarCodecs.Uuid',
	'orderId',
	_List_Nil,
	$author$project$Gql$Mastrota$Scalar$unwrapCodecs($author$project$MastrotaScalarCodecs$codecs).t.m0);
var $author$project$OneClick$Api$Offer$payOfferOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Mastrota$Object$PayOfferOk$orderId,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$OneClick$Model$PayOfferOk));
var $author$project$OneClick$Api$Offer$payOfferResponseSelection = $author$project$Gql$Mastrota$Union$PayOfferResponse$fragments(
	A3($author$project$Gql$Mastrota$Union$PayOfferResponse$Fragments, $author$project$OneClick$Api$Offer$payOfferOkSelection, $author$project$OneClick$Api$Offer$payOfferOkIssuedSelection, $author$project$OneClick$Api$Offer$payOfferErrorSelection));
var $author$project$OneClick$Api$Offer$payOfferMutation = F2(
	function (offerId, nonce) {
		return A2(
			$author$project$Gql$Mastrota$Mutation$payOffer,
			{oz: nonce, kr: offerId},
			$author$project$OneClick$Api$Offer$payOfferResponseSelection);
	});
var $author$project$OneClick$Api$Offer$payOffer = F4(
	function (apiUrl, token, offerId, nonce) {
		return A4(
			$author$project$Api$makeAuthPost,
			apiUrl,
			token,
			A2($author$project$OneClick$Api$Offer$payOfferMutation, offerId, nonce),
			$author$project$OneClick$Model$GotPaymentResponse);
	});
var $author$project$OneClick$Update$payOffer = F3(
	function (apiUrl, _v0, nonce) {
		var offer = _v0.oB;
		var tokenConfig = _v0.p2;
		return A4($author$project$OneClick$Api$Offer$payOffer, apiUrl, tokenConfig.p1, offer.nM, nonce);
	});
var $author$project$OneClick$Model$setBrainTreeModel = F2(
	function (brainTreeModel, modelState) {
		return _Utils_update(
			modelState,
			{mp: brainTreeModel});
	});
var $author$project$Payment$Model$AppLoading = 0;
var $author$project$Payment$Model$AppChallenging = 3;
var $author$project$Payment$Model$AppPaying = 2;
var $author$project$Payment$Model$setAppChallenging = F2(
	function (isChallenging, model) {
		return _Utils_update(
			model,
			{
				c0: isChallenging ? 3 : 2
			});
	});
var $author$project$Payment$Model$setAppStatus = F2(
	function (appStatus, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{c0: appStatus});
	});
var $author$project$Payment$Model$AppError = 4;
var $author$project$Payment$Model$ClientSideError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Payment$Model$setClientError = F2(
	function (errorEvent, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				c0: 4,
				b0: $elm$core$Maybe$Just(
					$author$project$Payment$Model$ClientSideError(errorEvent))
			});
	});
var $author$project$Payment$Model$setPayPalError = $author$project$Payment$Model$setClientError(
	{mH: 'PAYPAL_POPUP_CLOSED', mR: 'braintreeTokenizeError', b0: 'Customer closed PayPal popup before authorizing.'});
var $author$project$Payment$Model$setPayloadInformation = F2(
	function (payload, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				e1: $elm$core$Maybe$Just(payload.e1),
				oz: $elm$core$Maybe$Just(payload.oz)
			});
	});
var $author$project$Payment$Model$AuthenticateAttemptSuccessful = 2;
var $author$project$Payment$Model$AuthenticateFailed = 1;
var $author$project$Payment$Model$AuthenticateRejected = 4;
var $author$project$Payment$Model$AuthenticateSuccessful = 0;
var $author$project$Payment$Model$AuthenticateUnableToAuthenticate = 3;
var $author$project$Payment$Model$AuthenticationUnavailable = 5;
var $author$project$Payment$Model$ChallengeRequired = 8;
var $author$project$Payment$Model$LookupBypassed = 7;
var $author$project$Payment$Model$LookupError = 6;
var $author$project$Payment$Model$statusToOutcome = function (status) {
	switch (status) {
		case 'authenticate_successful':
			return $elm$core$Maybe$Just(0);
		case 'authenticate_failed':
			return $elm$core$Maybe$Just(1);
		case 'authenticate_attempt_successful':
			return $elm$core$Maybe$Just(2);
		case 'authenticate_unable_to_authenticate':
			return $elm$core$Maybe$Just(3);
		case 'authenticate_rejected':
			return $elm$core$Maybe$Just(4);
		case 'authentication_unavailable':
			return $elm$core$Maybe$Just(5);
		case 'lookup_error':
			return $elm$core$Maybe$Just(6);
		case 'lookup_bypassed':
			return $elm$core$Maybe$Just(7);
		case 'challenge_required':
			return $elm$core$Maybe$Just(8);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Payment$Model$setVerificationResult = F2(
	function (verificationResult, brainTreeModel) {
		return _Utils_update(
			brainTreeModel,
			{
				e1: $elm$core$Maybe$Just(verificationResult.e1),
				oz: $elm$core$Maybe$Just(verificationResult.oz),
				ky: $author$project$Payment$Model$statusToOutcome(verificationResult.hK),
				hK: $elm$core$Maybe$Just(verificationResult.hK)
			});
	});
var $author$project$Payment$Model$updateFieldValidation = F2(
	function (_v0, braintreeField) {
		var isValid = _v0.jY;
		var isPristine = _v0.jR;
		return _Utils_update(
			braintreeField,
			{jR: isPristine, jY: isValid});
	});
var $author$project$Payment$Model$updateFieldsValidationAndStatus = F2(
	function (braintreeFieldValidation, braintreeFields) {
		var id = braintreeFieldValidation.nM;
		var cardNumber = braintreeFields.io;
		var expirationMonth = braintreeFields.i7;
		var expirationYear = braintreeFields.i8;
		var cvv = braintreeFields.iJ;
		switch (id) {
			case 'card-number':
				return _Utils_update(
					braintreeFields,
					{
						io: A2($author$project$Payment$Model$updateFieldValidation, braintreeFieldValidation, cardNumber)
					});
			case 'expiration-month':
				return _Utils_update(
					braintreeFields,
					{
						i7: A2($author$project$Payment$Model$updateFieldValidation, braintreeFieldValidation, expirationMonth)
					});
			case 'expiration-year':
				return _Utils_update(
					braintreeFields,
					{
						i8: A2($author$project$Payment$Model$updateFieldValidation, braintreeFieldValidation, expirationYear)
					});
			case 'cvv':
				return _Utils_update(
					braintreeFields,
					{
						iJ: A2($author$project$Payment$Model$updateFieldValidation, braintreeFieldValidation, cvv)
					});
			default:
				return braintreeFields;
		}
	});
var $author$project$Payment$Model$updateFormFields = F2(
	function (fieldList, model) {
		return _Utils_update(
			model,
			{
				jc: A3($elm$core$List$foldl, $author$project$Payment$Model$updateFieldsValidationAndStatus, model.jc, fieldList)
			});
	});
var $author$project$Payment$Update$updateNonce = F2(
	function (_v0, _v1) {
		var nonce = _v0.oz;
		var bin = _v0.e1;
		var amount = _v1.bS;
		var config = _v1.iC;
		return {
			bS: amount,
			iC: _Utils_update(
				config,
				{
					e1: $elm$core$Maybe$Just(bin),
					oz: $elm$core$Maybe$Just(nonce)
				})
		};
	});
var $author$project$Payment$Update$update = F5(
	function (msg, braintreeModel, model, mapper, onPayment) {
		switch (msg.$) {
			case 0:
				var braintreeFieldList = msg.a;
				return $author$project$Utils$withoutCommands(
					A2($author$project$Payment$Model$updateFormFields, braintreeFieldList, braintreeModel));
			case 1:
				var payload = msg.a;
				var command = $author$project$Checkout$Ports$verify3DSCard(
					A2(
						$author$project$Payment$Update$updateNonce,
						payload,
						mapper(model)));
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[command]),
					A3(
						$elm$core$Basics$composeR,
						$author$project$Payment$Model$setPayloadInformation(payload),
						$author$project$Payment$Model$setAppChallenging(true),
						braintreeModel));
			case 2:
				var threeDSPayload = msg.a;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							A2(onPayment, model, threeDSPayload.oz)
						]),
					A3(
						$elm$core$Basics$composeR,
						$author$project$Payment$Model$setVerificationResult(threeDSPayload),
						$author$project$Payment$Model$setAppStatus(0),
						braintreeModel));
			case 4:
				var error = msg.a;
				return $author$project$Utils$withoutCommands(
					A2($author$project$Payment$Model$setClientError, error, braintreeModel));
			case 3:
				var nonce = msg.a;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							A2(onPayment, model, nonce)
						]),
					braintreeModel);
			default:
				return $author$project$Utils$withoutCommands(
					$author$project$Payment$Model$setPayPalError(braintreeModel));
		}
	});
var $author$project$OneClick$Model$ModelInitialized = function (a) {
	return {$: 3, a: a};
};
var $author$project$OneClick$Model$updateState = F2(
	function (model, state) {
		return _Utils_update(
			model,
			{cG: state});
	});
var $author$project$OneClick$Model$updateInitializedState = F2(
	function (mapper, model) {
		var state = model.cG;
		if (state.$ === 3) {
			var modelState = state.a;
			return A2(
				$author$project$OneClick$Model$updateState,
				model,
				$author$project$OneClick$Model$ModelInitialized(
					mapper(modelState)));
		} else {
			return model;
		}
	});
var $author$project$OneClick$Update$updateBraintreeModel = F2(
	function (msg, model) {
		var state = model.cG;
		var flags = model.nv;
		if (state.$ === 3) {
			var modelState = state.a;
			var _v1 = A5(
				$author$project$Payment$Update$update,
				msg,
				modelState.mp,
				modelState,
				$author$project$OneClick$Update$createVerificationConfig,
				$author$project$OneClick$Update$payOffer(
					$author$project$Types$pickMastrotaUrl(flags)));
			var btModel = _v1.a;
			var cmd = _v1.b;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				A2(
					$author$project$OneClick$Model$updateInitializedState,
					$author$project$OneClick$Model$setBrainTreeModel(btModel),
					model));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$OneClick$Model$setEffectiveDateTooltipState = F2(
	function (messageState, modelState) {
		return _Utils_update(
			modelState,
			{iZ: messageState});
	});
var $author$project$OneClick$Model$updateEffectiveDateTooltipState = function (messageState) {
	return $author$project$OneClick$Model$updateInitializedState(
		$author$project$OneClick$Model$setEffectiveDateTooltipState(messageState));
};
var $author$project$Payment$Model$ServerSideError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Payment$Model$Unknown = function (a) {
	return {$: 0, a: a};
};
var $author$project$Payment$Model$setServerSideError = F2(
	function (errorDescription, brainTreeModel) {
		var serverErrorEvent = $author$project$Payment$Model$Unknown(errorDescription);
		return _Utils_update(
			brainTreeModel,
			{
				c0: 4,
				b0: $elm$core$Maybe$Just(
					$author$project$Payment$Model$ServerSideError(serverErrorEvent))
			});
	});
var $author$project$OneClick$Model$updateBrainTreeModel = function (updateFunction) {
	return $author$project$OneClick$Model$updateInitializedState(
		function (state) {
			return A2(
				$author$project$OneClick$Model$setBrainTreeModel,
				updateFunction(state.mp),
				state);
		});
};
var $author$project$OneClick$Update$updateFromPaymentResponse = F2(
	function (response, model) {
		var state = model.cG;
		var _v0 = _Utils_Tuple2(state, response);
		_v0$4:
		while (true) {
			if (_v0.a.$ === 3) {
				switch (_v0.b.$) {
					case 3:
						switch (_v0.b.a.$) {
							case 1:
								var id = _v0.b.a.a.nM;
								var orderId = _v0.b.a.a.kw;
								return A2(
									$author$project$Utils$UpdateReturn$withPushUrl,
									A3(
										$author$project$Routing$ThankYou,
										2,
										orderId,
										$elm$core$Maybe$Just(id)),
									$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
										_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
							case 0:
								var orderId = _v0.b.a.a;
								return A2(
									$author$project$Utils$UpdateReturn$withPushUrl,
									A3($author$project$Routing$ThankYou, 2, orderId, $elm$core$Maybe$Nothing),
									$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
										_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)));
							default:
								var paymentError = _v0.b.a.a;
								return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
									$author$project$Utils$withoutCommands(
										A2(
											$author$project$OneClick$Model$updateBrainTreeModel,
											$author$project$Payment$Model$setServerSideError(paymentError.of),
											model)));
						}
					case 2:
						var error = _v0.b.a;
						if (!error.$) {
							var graphqlErrors = error.b;
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$OneClick$Model$updateBrainTreeModel,
										$author$project$Payment$Model$setServerSideError(
											A3(
												$elm_community$maybe_extra$Maybe$Extra$unwrap,
												'',
												function ($) {
													return $.of;
												},
												$elm$core$List$head(graphqlErrors))),
										model)));
						} else {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$OneClick$Model$updateBrainTreeModel,
										$author$project$Payment$Model$setServerSideError('HTTP Error'),
										model)));
						}
					default:
						break _v0$4;
				}
			} else {
				break _v0$4;
			}
		}
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
	});
var $author$project$OneClick$Model$setIsInfoModalOpen = F2(
	function (isOpen, modelState) {
		return _Utils_update(
			modelState,
			{jO: isOpen});
	});
var $author$project$OneClick$Model$updateInfoBoxModal = function (isOpen) {
	return $author$project$OneClick$Model$updateInitializedState(
		$author$project$OneClick$Model$setIsInfoModalOpen(isOpen));
};
var $author$project$OneClick$Model$setIsCvvTooltipVisible = F2(
	function (isVisible, modelState) {
		return _Utils_update(
			modelState,
			{jJ: isVisible});
	});
var $author$project$OneClick$Model$updateIsCvvTooltipVisible = function (isVisible) {
	return $author$project$OneClick$Model$updateInitializedState(
		$author$project$OneClick$Model$setIsCvvTooltipVisible(isVisible));
};
var $author$project$OneClick$Model$setIsSummaryOpen = F2(
	function (isOpen, modelState) {
		return _Utils_update(
			modelState,
			{jW: isOpen});
	});
var $author$project$OneClick$Model$updateIsSummaryOpen = function (isOpen) {
	return $author$project$OneClick$Model$updateInitializedState(
		$author$project$OneClick$Model$setIsSummaryOpen(isOpen));
};
var $author$project$OneClick$Model$setPaymentMethod = F2(
	function (modelState, paymentMethod) {
		return _Utils_update(
			modelState,
			{oS: paymentMethod});
	});
var $author$project$OneClick$Model$updatePaymentMethod = function (maybePaymentMethod) {
	return $author$project$OneClick$Model$updateInitializedState(
		function (state) {
			return A2(
				$elm$core$Maybe$withDefault,
				state,
				A2(
					$elm$core$Maybe$map,
					$author$project$OneClick$Model$setPaymentMethod(state),
					maybePaymentMethod));
		});
};
var $author$project$Payment$Model$setIsCreditCardMethodReady = F2(
	function (isReady, btm) {
		return _Utils_update(
			btm,
			{jI: isReady});
	});
var $author$project$Payment$Model$setIsPayPalMethodReady = F2(
	function (isReady, btm) {
		return _Utils_update(
			btm,
			{jQ: isReady});
	});
var $author$project$OneClick$Model$paymentMethodToBrainTree = F3(
	function (paymentMethod, isReady, _v0) {
		var brainTreeModel = _v0.mp;
		switch (paymentMethod) {
			case 2:
				return brainTreeModel;
			case 0:
				return A2($author$project$Payment$Model$setIsCreditCardMethodReady, isReady, brainTreeModel);
			default:
				return A2($author$project$Payment$Model$setIsPayPalMethodReady, isReady, brainTreeModel);
		}
	});
var $author$project$OneClick$Model$updatePaymentMethodStatus = F2(
	function (paymentMethod, isReady) {
		return $author$project$OneClick$Model$updateInitializedState(
			function (state) {
				return A2(
					$author$project$OneClick$Model$setBrainTreeModel,
					A3($author$project$OneClick$Model$paymentMethodToBrainTree, paymentMethod, isReady, state),
					state);
			});
	});
var $author$project$Payment$Model$AppReady = 1;
var $author$project$Payment$Model$BraintreeField = F6(
	function (id, label, labelId, error, isValid, isPristine) {
		return {b0: error, nM: id, jR: isPristine, jY: isValid, n6: label, n7: labelId};
	});
var $author$project$Payment$Model$initialBraintreeFields = {
	io: A6($author$project$Payment$Model$BraintreeField, 'card-number', 'Numero carta di credito', 'card-label', 'controlla il numero di carta di credito', false, true),
	iJ: A6($author$project$Payment$Model$BraintreeField, 'cvv', 'CVV', 'cvv-label', 'controlla il cvv', false, true),
	i7: A6($author$project$Payment$Model$BraintreeField, 'expiration-month', 'Data di scadenza', 'expiration-date-label', 'controlla la data di scadenza', false, true),
	i8: A6($author$project$Payment$Model$BraintreeField, 'expiration-year', 'anno', '', '', false, true)
};
var $author$project$Payment$Model$initModel = function (token) {
	return {c0: 1, e1: $elm$core$Maybe$Nothing, b0: $elm$core$Maybe$Nothing, jc: $author$project$Payment$Model$initialBraintreeFields, jI: false, jQ: false, oz: $elm$core$Maybe$Nothing, ky: $elm$core$Maybe$Nothing, hK: $elm$core$Maybe$Nothing, p1: token};
};
var $author$project$OneClick$Update$maybeRedirectOnOfferStatus = F2(
	function (offer, _v0) {
		var model = _v0.a;
		var msg = _v0.b;
		var _v1 = offer.hK;
		switch (_v1) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					_Utils_Tuple2(model, msg));
			case 3:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotAvailable(
						$author$project$NotAvailable$Model$SaveExpired($elm$core$Maybe$Nothing)),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						_Utils_Tuple2(model, msg)));
			case 1:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SavePurchased),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						_Utils_Tuple2(model, msg)));
			default:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SaveNotPurchasable),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						_Utils_Tuple2(model, msg)));
		}
	});
var $author$project$OneClick$Update$redirectOnError = F2(
	function (error, _v0) {
		var model = _v0.a;
		var flags = model.nv;
		var cmd = _v0.b;
		var updateReturn = _v0.c;
		return function () {
			if (!error.$) {
				var errors = error.b;
				var unauthorizedErrors = A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.of;
						},
						$elm$core$Basics$eq('unauthorized')),
					errors);
				if (!unauthorizedErrors.b) {
					return $author$project$Utils$UpdateReturn$withPushUrl(
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$OfferPageError));
				} else {
					return $author$project$Utils$UpdateReturn$withNewUrl(
						$author$project$Types$pickPrimaUrl(flags) + '/reserved/login');
				}
			} else {
				return $author$project$Utils$UpdateReturn$withPushUrl(
					$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$OfferPageError));
			}
		}()(
			_Utils_Tuple3(model, cmd, updateReturn));
	});
var $author$project$OneClick$Update$redirectOnOfferError = F2(
	function (error, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var updateReturn = _v0.c;
		return function () {
			var _v1 = error.k6;
			switch (_v1) {
				case 0:
					return $author$project$Utils$UpdateReturn$withPushUrl(
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SavePurchased));
				case 1:
					return $author$project$Utils$UpdateReturn$withPushUrl(
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$OfferPageNotAvailable));
				case 2:
					return $author$project$Utils$UpdateReturn$withPushUrl(
						$author$project$Routing$NotAvailable(
							$author$project$NotAvailable$Model$SaveExpired($elm$core$Maybe$Nothing)));
				default:
					return $author$project$Utils$UpdateReturn$withPushUrl(
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$SaveNotPurchasable));
			}
		}()(
			_Utils_Tuple3(model, cmd, updateReturn));
	});
var $author$project$OneClick$Update$updateStateFromResponses = F4(
	function (offerResponse, clientConfigResponse, tokenConfig, model) {
		var _v0 = _Utils_Tuple2(offerResponse, clientConfigResponse);
		_v0$1:
		while (true) {
			_v0$7:
			while (true) {
				switch (_v0.a.$) {
					case 2:
						var error = _v0.a.a;
						return A2(
							$author$project$OneClick$Update$redirectOnError,
							error,
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(
									_Utils_update(
										model,
										{
											cG: $author$project$OneClick$Model$ModelError(
												{})
										}))));
					case 1:
						switch (_v0.b.$) {
							case 2:
								break _v0$1;
							case 3:
								var _v1 = _v0.a;
								return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
									$author$project$Utils$withoutCommands(
										_Utils_update(
											model,
											{
												cG: $author$project$OneClick$Model$ModelLoading(
													{mC: clientConfigResponse, oE: offerResponse, p2: tokenConfig})
											})));
							default:
								break _v0$7;
						}
					case 3:
						switch (_v0.b.$) {
							case 2:
								break _v0$1;
							case 3:
								if (!_v0.a.a.$) {
									var offer = _v0.a.a.a;
									var clientConfig = _v0.b.a;
									return A2(
										$author$project$OneClick$Update$maybeRedirectOnOfferStatus,
										offer,
										$author$project$Utils$withoutCommands(
											_Utils_update(
												model,
												{
													cG: $author$project$OneClick$Model$ModelInitialized(
														{
															mp: $author$project$Payment$Model$initModel(clientConfig.p1),
															mB: clientConfig,
															iZ: $author$project$OneClick$Model$Hidden,
															jJ: false,
															jO: false,
															jW: false,
															oB: offer,
															oS: 0,
															p2: tokenConfig
														})
												})));
								} else {
									var error = _v0.a.a.a;
									return A2(
										$author$project$OneClick$Update$redirectOnOfferError,
										error,
										$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
											$author$project$Utils$withoutCommands(
												_Utils_update(
													model,
													{
														cG: $author$project$OneClick$Model$ModelError(
															{})
													}))));
								}
							case 1:
								if (!_v0.a.a.$) {
									var offer = _v0.a.a.a;
									var _v2 = _v0.b;
									return A2(
										$author$project$OneClick$Update$maybeRedirectOnOfferStatus,
										offer,
										$author$project$Utils$withoutCommands(
											_Utils_update(
												model,
												{
													cG: $author$project$OneClick$Model$ModelLoading(
														{mC: clientConfigResponse, oE: offerResponse, p2: tokenConfig})
												})));
								} else {
									var error = _v0.a.a.a;
									var _v3 = _v0.b;
									return A2(
										$author$project$OneClick$Update$redirectOnOfferError,
										error,
										$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
											$author$project$Utils$withoutCommands(
												_Utils_update(
													model,
													{
														cG: $author$project$OneClick$Model$ModelLoading(
															{mC: clientConfigResponse, oE: offerResponse, p2: tokenConfig})
													}))));
								}
							default:
								break _v0$7;
						}
					default:
						if (_v0.b.$ === 2) {
							break _v0$1;
						} else {
							break _v0$7;
						}
				}
			}
			return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				$author$project$Utils$withoutCommands(model));
		}
		var error = _v0.b.a;
		return A2(
			$author$project$OneClick$Update$redirectOnError,
			error,
			$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				$author$project$Utils$withoutCommands(
					_Utils_update(
						model,
						{
							cG: $author$project$OneClick$Model$ModelError(
								{})
						}))));
	});
var $author$project$OneClick$Update$updateStateFromClientConfigurationResponse = F2(
	function (clientConfigResponse, model) {
		var state = model.cG;
		var _v0 = _Utils_Tuple2(state, clientConfigResponse);
		if (_v0.a.$ === 2) {
			var offerResponse = _v0.a.a.oE;
			var tokenConfig = _v0.a.a.p2;
			return A4($author$project$OneClick$Update$updateStateFromResponses, offerResponse, clientConfigResponse, tokenConfig, model);
		} else {
			return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$OneClick$Update$updateStateFromOfferResponse = F2(
	function (offerResponse, model) {
		var state = model.cG;
		var _v0 = _Utils_Tuple2(state, offerResponse);
		if (_v0.a.$ === 2) {
			var clientConfigurationResponse = _v0.a.a.mC;
			var tokenConfig = _v0.a.a.p2;
			return A4($author$project$OneClick$Update$updateStateFromResponses, offerResponse, clientConfigurationResponse, tokenConfig, model);
		} else {
			return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$OneClick$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var id = msg.a;
				var tokenConfig = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A3($author$project$OneClick$Update$initialState, id, tokenConfig, model));
			case 1:
				var response = msg.a;
				return $author$project$OneClick$Update$maybeInitBraintree(
					A2($author$project$OneClick$Update$updateStateFromOfferResponse, response, model));
			case 2:
				var response = msg.a;
				return $author$project$OneClick$Update$maybeInitBraintree(
					A2($author$project$OneClick$Update$updateStateFromClientConfigurationResponse, response, model));
			case 4:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$OneClick$Update$scrollToSection('one-click__payment')
							]),
						model));
			case 5:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$OneClick$Update$scrollToSection('one-click__offer-coverage')
							]),
						model));
			case 6:
				var isSummaryOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isSummaryOpen)
							]),
						A2($author$project$OneClick$Model$updateIsSummaryOpen, isSummaryOpen, model)));
			case 7:
				var maybePaymentMethod = msg.a;
				return $author$project$OneClick$Update$maybeInitBraintree(
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$OneClick$Model$updatePaymentMethod, maybePaymentMethod, model))));
			case 12:
				var method = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2($author$project$OneClick$Update$payWithPaymentMethod, method, model));
			case 3:
				var response = msg.a;
				return A2($author$project$OneClick$Update$updateFromPaymentResponse, response, model);
			case 8:
				var brainTreeMsg = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2($author$project$OneClick$Update$updateBraintreeModel, brainTreeMsg, model));
			case 9:
				var paymentMethod = msg.a;
				var isReady = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A3($author$project$OneClick$Model$updatePaymentMethodStatus, paymentMethod, isReady, model)));
			case 13:
				var isVisible = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$OneClick$Model$updateIsCvvTooltipVisible, isVisible, model)));
			case 10:
				var isVisible = msg.a;
				return isVisible ? $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[$author$project$OneClick$Update$showEffectiveDateTooltip]),
						model)) : $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$send(
								$author$project$OneClick$Model$SetEffectiveDateTooltipState($author$project$OneClick$Model$Hidden))
							]),
						model));
			case 11:
				var messageState = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$OneClick$Model$updateEffectiveDateTooltipState, messageState, model)));
			case 14:
				var isOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isOpen)
							]),
						A2($author$project$OneClick$Model$updateInfoBoxModal, isOpen, model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$Preselection$InitializedPreselection$HomeOption = 0;
var $author$project$Preselection$Preselection$InitializedModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Preselection$Preselection$InitializedPreselectionMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Preselection$Preselection$OnSubmitSucceed = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$FieldStatus$FieldStatus = F2(
	function (isBlurred, isDirty) {
		return {n$: isBlurred, n0: isDirty};
	});
var $author$project$Pyxis$Components$Field$FieldStatus$init = A2($author$project$Pyxis$Components$Field$FieldStatus$FieldStatus, false, false);
var $author$project$Pyxis$Components$Field$CheckboxGroup$init = {au: _List_Nil, dq: $author$project$Pyxis$Components$Field$FieldStatus$init, f6: $elm$core$Maybe$Nothing, d4: $elm$core$Maybe$Nothing, gj: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Tabs$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$init = {
	O: 0,
	eb: $elm$core$Maybe$Nothing,
	gZ: $elm$core$Maybe$Nothing,
	by: A3($elm$core$Dict$insert, 0, 1, $elm$core$Dict$empty)
};
var $author$project$Preselection$InitializedPreselection$init = function (_v0) {
	var flags = _v0.nv;
	var tokenType = _v0.lK;
	var user = _v0.p6;
	var onSubmitSuccess = _v0.ea;
	var parentMsg = _v0.ed;
	return $author$project$Utils$withoutCommands(
		{nv: flags, dw: $author$project$Pyxis$Components$Field$CheckboxGroup$init, dM: false, n4: false, d_: 0, d$: $author$project$Pyxis$Components$Tabs$init, ea: onSubmitSuccess, ed: parentMsg, lK: tokenType, p6: user});
};
var $author$project$Preselection$InitializedPreselection$setTokenType = F2(
	function (tokenType, modelData) {
		return _Utils_update(
			modelData,
			{lK: tokenType});
	});
var $author$project$Preselection$InitializedPreselection$setUser = F2(
	function (maybeUser, modelData) {
		return _Utils_update(
			modelData,
			{p6: maybeUser});
	});
var $author$project$Preselection$InitializedPreselection$resume = F2(
	function (_v0, oldModel) {
		var tokenType = _v0.lK;
		var user = _v0.p6;
		return $author$project$Utils$withoutCommands(
			A2(
				$author$project$Preselection$InitializedPreselection$setUser,
				user,
				A2($author$project$Preselection$InitializedPreselection$setTokenType, tokenType, oldModel)));
	});
var $author$project$Preselection$Update$initOrResumeInitializedPreselection = F3(
	function (tokenType, maybeUser, model) {
		if (!model.$) {
			var flags = model.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Preselection$Preselection$InitializedModel,
				$author$project$Preselection$InitializedPreselection$init(
					{nv: flags, ea: $author$project$Preselection$Preselection$OnSubmitSucceed, ed: $author$project$Preselection$Preselection$InitializedPreselectionMsg, lK: tokenType, p6: maybeUser}));
		} else {
			var initializedPreselection = model.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Preselection$Preselection$InitializedModel,
				A2(
					$author$project$Preselection$InitializedPreselection$resume,
					{lK: tokenType, p6: maybeUser},
					initializedPreselection));
		}
	});
var $author$project$Preselection$InitializedPreselection$SendGTMFormEvent = function (a) {
	return {$: 5, a: a};
};
var $author$project$Utils$GTM$EventTag = $elm$core$Basics$identity;
var $author$project$Utils$GTM$composeEventTag = function (_v0) {
	var eventName = _v0.ne;
	var parameterName = _v0.oO;
	var parameterPrefixValue = _v0.oP;
	var parameterValue = _v0.oQ;
	var eventId = _v0.ay;
	return {ay: eventId, ne: eventName, oO: parameterName, oP: parameterPrefixValue, oQ: parameterValue};
};
var $author$project$Utils$GTM$createDataLayerList = function (_v0) {
	var parameterPrefixValue = _v0.oP;
	var eventName = _v0.ne;
	var parameterName = _v0.oO;
	var parameterValue = _v0.oQ;
	var eventId = _v0.ay;
	return {
		mY: _List_fromArray(
			[
				_Utils_Tuple2('event', eventName),
				_Utils_Tuple2(parameterName, parameterPrefixValue + ('|' + parameterValue))
			]),
		nM: eventId
	};
};
var $author$project$Ports$simplePushToDataLayer = _Platform_outgoingPort(
	'simplePushToDataLayer',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'dataLayerList',
					$elm$json$Json$Encode$list(
						function ($) {
							var a = $.a;
							var b = $.b;
							return A2(
								$elm$json$Json$Encode$list,
								$elm$core$Basics$identity,
								_List_fromArray(
									[
										$elm$json$Json$Encode$string(a),
										$elm$json$Json$Encode$string(b)
									]));
						})($.mY)),
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string($.nM))
				]));
	});
var $author$project$Utils$GTM$simplePushToDataLayer = $author$project$Ports$simplePushToDataLayer;
var $author$project$Utils$GTM$conditionallySendGTMFormEvent = function (_v0) {
	var isFieldValid = _v0.n3;
	var eventTag = _v0.nf;
	return isFieldValid ? $author$project$Utils$GTM$simplePushToDataLayer(
		$author$project$Utils$GTM$createDataLayerList(eventTag)) : $elm$core$Platform$Cmd$none;
};
var $author$project$Utils$GTM$defaultEventName = 'form_completion';
var $author$project$Utils$GTM$defaultHomeAndFamilyParameterName = 'home_family_form';
var $author$project$Utils$GTM$eventIdWithPrefix = function (_v0) {
	var prefix = _v0.oV;
	var eventId = _v0.ay;
	return prefix + ('_' + eventId);
};
var $author$project$Preselection$InitializedPreselection$DOMElementIdNotFound = function (a) {
	return {$: 7, a: a};
};
var $author$project$Preselection$InitializedPreselection$SetModalContentMinHeight = function (a) {
	return {$: 1, a: a};
};
var $author$project$Preselection$InitializedPreselection$modalContentId = 'preselection__modal-content';
var $author$project$Preselection$InitializedPreselection$getModalContentMinHeightCmd = function (parentMsg) {
	return A2(
		$elm$core$Platform$Cmd$map,
		parentMsg,
		A2(
			$elm$core$Task$attempt,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Result$map(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.i_;
						},
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.nI;
							},
							$author$project$Preselection$InitializedPreselection$SetModalContentMinHeight))),
				$elm$core$Result$withDefault(
					$author$project$Preselection$InitializedPreselection$DOMElementIdNotFound($author$project$Preselection$InitializedPreselection$modalContentId))),
			A2(
				$elm$core$Task$andThen,
				$elm$core$Basics$always(
					$elm$browser$Browser$Dom$getElement($author$project$Preselection$InitializedPreselection$modalContentId)),
				$elm$core$Process$sleep(500))));
};
var $author$project$Preselection$InitializedPreselection$logDOMElementNotFoundError = function (id) {
	return A2(
		$author$project$Utils$Logger$devConsoleError,
		'InitializedPreselection:Update',
		$elm$json$Json$Encode$string('Modal content DOM element with id: ' + (id + ' not found')));
};
var $author$project$Preselection$InitializedPreselection$familyOptionPreselectionParameter = 'your_family';
var $author$project$Utils$ifThenCmd = F2(
	function (condition, cmd) {
		return condition ? cmd : $elm$core$Platform$Cmd$none;
	});
var $author$project$Preselection$InitializedPreselection$FamilyOption = 1;
var $author$project$Pyxis$Components$Field$CheckboxGroup$getValue = function (_v0) {
	var modelData = _v0;
	return modelData.au;
};
var $author$project$Preselection$InitializedPreselection$pickFormTypeField = function (_v0) {
	var formTypeField = _v0.dw;
	return formTypeField;
};
var $author$project$Preselection$InitializedPreselection$isFamilyOptionSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Preselection$InitializedPreselection$pickFormTypeField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$CheckboxGroup$getValue,
		$elm$core$List$member(1)));
var $author$project$Preselection$InitializedPreselection$pickParentMsg = function (_v0) {
	var parentMsg = _v0.ed;
	return parentMsg;
};
var $author$project$Preselection$InitializedPreselection$conditionalSelectedFamilyOptionCmd = function (modelData) {
	return A2(
		$author$project$Utils$ifThenCmd,
		$author$project$Preselection$InitializedPreselection$isFamilyOptionSelected(modelData),
		$author$project$Utils$send(
			A2(
				$author$project$Preselection$InitializedPreselection$pickParentMsg,
				modelData,
				$author$project$Preselection$InitializedPreselection$SendGTMFormEvent(
					{bb: $author$project$Preselection$InitializedPreselection$familyOptionPreselectionParameter, ay: $author$project$Preselection$InitializedPreselection$familyOptionPreselectionParameter}))));
};
var $author$project$Preselection$InitializedPreselection$homeOptionPreselectionParameter = 'your_home';
var $author$project$Preselection$InitializedPreselection$isHomeOptionSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Preselection$InitializedPreselection$pickFormTypeField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$CheckboxGroup$getValue,
		$elm$core$List$member(0)));
var $author$project$Preselection$InitializedPreselection$conditionalSelectedHomeOptionCmd = function (modelData) {
	return A2(
		$author$project$Utils$ifThenCmd,
		$author$project$Preselection$InitializedPreselection$isHomeOptionSelected(modelData),
		$author$project$Utils$send(
			A2(
				$author$project$Preselection$InitializedPreselection$pickParentMsg,
				modelData,
				$author$project$Preselection$InitializedPreselection$SendGTMFormEvent(
					{bb: $author$project$Preselection$InitializedPreselection$homeOptionPreselectionParameter, ay: $author$project$Preselection$InitializedPreselection$homeOptionPreselectionParameter}))));
};
var $author$project$Preselection$InitializedPreselection$pickOnSubmitSuccess = function (_v0) {
	var onSubmitSuccess = _v0.ea;
	return onSubmitSuccess;
};
var $author$project$Preselection$InitializedPreselection$proceedBtnParameter = 'click_on_proceed';
var $author$project$Preselection$InitializedPreselection$proceedCmd = function (modelData) {
	return $author$project$Utils$send(
		A2(
			$author$project$Preselection$InitializedPreselection$pickParentMsg,
			modelData,
			$author$project$Preselection$InitializedPreselection$SendGTMFormEvent(
				{bb: $author$project$Preselection$InitializedPreselection$proceedBtnParameter, ay: $author$project$Preselection$InitializedPreselection$proceedBtnParameter})));
};
var $author$project$Preselection$InitializedPreselection$setIsFormSubmitted = F2(
	function (fieldValue, modelData) {
		return _Utils_update(
			modelData,
			{dM: fieldValue});
	});
var $author$project$Preselection$InitializedPreselection$formTypeFieldValidation = function (selectedValues) {
	return $elm$core$List$isEmpty(selectedValues) ? $elm$core$Result$Err('Seleziona cosa vuoi assicurare per continuare') : $elm$core$Result$Ok(selectedValues);
};
var $author$project$Preselection$InitializedPreselection$validatedFormTypeSelection = function (modelData) {
	return $author$project$Preselection$InitializedPreselection$formTypeFieldValidation(
		$author$project$Pyxis$Components$Field$CheckboxGroup$getValue(
			$author$project$Preselection$InitializedPreselection$pickFormTypeField(modelData)));
};
var $author$project$Preselection$InitializedPreselection$onProceedMsg = function (modelData) {
	var _v0 = $author$project$Preselection$InitializedPreselection$validatedFormTypeSelection(modelData);
	if (!_v0.$) {
		var selectedOptions = _v0.a;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					A2($author$project$Preselection$InitializedPreselection$pickOnSubmitSuccess, modelData, selectedOptions)),
					$author$project$Preselection$InitializedPreselection$conditionalSelectedHomeOptionCmd(modelData),
					$author$project$Preselection$InitializedPreselection$conditionalSelectedFamilyOptionCmd(modelData),
					$author$project$Preselection$InitializedPreselection$proceedCmd(modelData)
				]),
			modelData);
	} else {
		return $author$project$Utils$withoutCommands(
			A2($author$project$Preselection$InitializedPreselection$setIsFormSubmitted, true, modelData));
	}
};
var $author$project$Preselection$InitializedPreselection$preselectionInfoModalParameter = 'click_on_what_cover';
var $author$project$Preselection$InitializedPreselection$preselectionPrefixValue = 'preselection_page';
var $author$project$Preselection$InitializedPreselection$setIsModalOpen = F2(
	function (isOpen, modelData) {
		return _Utils_update(
			modelData,
			{n4: isOpen});
	});
var $author$project$Preselection$InitializedPreselection$setModalContentMinHeight = F2(
	function (minHeight, modelData) {
		return _Utils_update(
			modelData,
			{d_: minHeight});
	});
var $author$project$Preselection$InitializedPreselection$setFormTypeField = F2(
	function (fieldValue, modelData) {
		return _Utils_update(
			modelData,
			{dw: fieldValue});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				au: mapper(modelData.au)
			});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$checkValue = function (value) {
	return $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues(
		$elm$core$List$cons(value));
};
var $author$project$Pyxis$Commons$Commands$dispatch = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(msg),
		$elm$core$Task$succeed(
			$elm$core$Basics$always(msg)));
};
var $author$project$Pyxis$Commons$Commands$dispatchFromMaybe = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Pyxis$Commons$Commands$dispatch),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $primait$prima_elm_extra$PrimaFunction$ifThenElseMap = F4(
	function (condition, mapper1, mapper2, m) {
		return condition(m) ? mapper1(m) : mapper2(m);
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dq: mapper(model.dq)
			});
	});
var $author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred = F2(
	function (isBlurred, fieldStatus) {
		return _Utils_update(
			fieldStatus,
			{n$: isBlurred});
	});
var $author$project$Pyxis$Components$Field$FieldStatus$setIsDirty = F2(
	function (isDirty, fieldStatus) {
		return _Utils_update(
			fieldStatus,
			{n0: isDirty});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$uncheckValue = function (value) {
	return $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues(
		$elm$core$List$filter(
			$elm$core$Basics$neq(value)));
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					model);
			default:
				var value = msg.a;
				var check = msg.b;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d4)
						]),
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A4(
							$primait$prima_elm_extra$PrimaFunction$ifThenElseMap,
							$elm$core$Basics$always(check),
							$author$project$Pyxis$Components$Field$CheckboxGroup$checkValue(value),
							$author$project$Pyxis$Components$Field$CheckboxGroup$uncheckValue(value),
							model)));
		}
	});
var $author$project$Preselection$InitializedPreselection$updateFormTypeFieldWithCmd = F2(
	function (fieldMsg, modelData) {
		return A2(
			$elm$core$Tuple$mapSecond,
			$elm$core$Platform$Cmd$map(
				$author$project$Preselection$InitializedPreselection$pickParentMsg(modelData)),
			A2(
				$elm$core$Tuple$mapFirst,
				function (updatedModelData) {
					return A2($author$project$Preselection$InitializedPreselection$setIsFormSubmitted, false, updatedModelData);
				},
				A2(
					$elm$core$Tuple$mapFirst,
					function (updatedField) {
						return A2($author$project$Preselection$InitializedPreselection$setFormTypeField, updatedField, modelData);
					},
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$update,
						fieldMsg,
						$author$project$Preselection$InitializedPreselection$pickFormTypeField(modelData)))));
	});
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$Preselection$InitializedPreselection$pickModalTabs = function (_v0) {
	var modalTabs = _v0.d$;
	return modalTabs;
};
var $author$project$Preselection$InitializedPreselection$setModalTabs = F2(
	function (modalTabs, modelData) {
		return _Utils_update(
			modelData,
			{d$: modalTabs});
	});
var $author$project$Pyxis$Components$Tabs$dispatchOnTabActivation = function (_v0) {
	var activeIndex = _v0.O;
	var onTabActivation = _v0.eb;
	return $author$project$Pyxis$Commons$Commands$dispatchFromMaybe(
		A2(
			$elm$core$Maybe$map,
			function (theMsg) {
				return theMsg(activeIndex);
			},
			onTabActivation));
};
var $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation = function (_v0) {
	var activeIndex = _v0.O;
	var onTabFirstActivation = _v0.gZ;
	return $author$project$Pyxis$Commons$Commands$dispatchFromMaybe(
		A2(
			$elm$core$Maybe$map,
			function (theMsg) {
				return theMsg(activeIndex);
			},
			onTabFirstActivation));
};
var $primait$prima_elm_extra$PrimaCmd$ifThenCmd = F2(
	function (condition, cmd) {
		return condition ? cmd : $elm$core$Platform$Cmd$none;
	});
var $primait$prima_elm_extra$PrimaCmd$ifThenCmdMap = F3(
	function (condition, cmd, a) {
		return A2(
			$primait$prima_elm_extra$PrimaCmd$ifThenCmd,
			condition(a),
			cmd(a));
	});
var $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation = function (_v0) {
	var activeIndex = _v0.O;
	var openedTabs = _v0.by;
	return 1 === A2(
		$elm$core$Maybe$withDefault,
		1,
		A2($elm$core$Dict$get, activeIndex, openedTabs));
};
var $author$project$Pyxis$Components$Tabs$updateIndex = F2(
	function (index, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				O: index,
				by: A3(
					$elm$core$Dict$insert,
					index,
					1 + A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Dict$get, index, modelData.by)),
					modelData.by)
			});
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $primait$prima_elm_extra$PrimaCmd$fromMaybeMap = F2(
	function (command, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return command(a);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Pyxis$Components$Tabs$getTabId = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		$elm$core$Basics$append(id + '-tab-'));
};
var $author$project$Pyxis$Components$Tabs$attemptTabFocus = F2(
	function (id, _v0) {
		var modelData = _v0;
		return A2(
			$primait$prima_elm_extra$PrimaCmd$fromMaybeMap,
			function (msg) {
				return A2(
					$elm$core$Task$attempt,
					$elm$core$Basics$always(msg),
					$elm$browser$Browser$Dom$focus(
						A2($author$project$Pyxis$Components$Tabs$getTabId, id, modelData.O)));
			},
			A2(
				$elm$core$Maybe$map,
				function (msg) {
					return msg(modelData.O);
				},
				modelData.eb));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode = F2(
	function (match, _v0) {
		var keyCode = _v0.j0;
		return _Utils_eq(keyCode, match);
	});
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(37);
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowRight = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(39);
var $author$project$Pyxis$Commons$Events$Keyboard$isEnd = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(35);
var $author$project$Pyxis$Commons$Events$Keyboard$isHome = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(36);
var $author$project$Pyxis$Components$Tabs$updateIndexToPrevious = F2(
	function (numberOfTabs, model) {
		var activeIndex = model.O;
		return ((activeIndex - 1) >= 0) ? A2($author$project$Pyxis$Components$Tabs$updateIndex, activeIndex - 1, model) : A2($author$project$Pyxis$Components$Tabs$updateIndex, numberOfTabs - 1, model);
	});
var $author$project$Pyxis$Components$Tabs$updateToNextIndex = F2(
	function (numberOfTabs, model) {
		var activeIndex = model.O;
		return (_Utils_cmp(activeIndex + 1, numberOfTabs - 1) < 1) ? A2($author$project$Pyxis$Components$Tabs$updateIndex, activeIndex + 1, model) : A2($author$project$Pyxis$Components$Tabs$updateIndex, 0, model);
	});
var $primait$prima_elm_extra$PrimaUpdate$withCmdsMap = F2(
	function (cmdFunctions, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					function (f) {
						return f(model);
					},
					cmdFunctions)));
	});
var $author$project$Pyxis$Components$Tabs$updateOnKeyEvent = F3(
	function (id, numberOfTabs, event) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft(event) ? $author$project$Pyxis$Components$Tabs$updateIndexToPrevious(numberOfTabs) : ($author$project$Pyxis$Commons$Events$Keyboard$isArrowRight(event) ? $author$project$Pyxis$Components$Tabs$updateToNextIndex(numberOfTabs) : ($author$project$Pyxis$Commons$Events$Keyboard$isHome(event) ? $author$project$Pyxis$Components$Tabs$updateIndex(0) : ($author$project$Pyxis$Commons$Events$Keyboard$isEnd(event) ? $author$project$Pyxis$Components$Tabs$updateIndex(numberOfTabs - 1) : $elm$core$Basics$identity))),
			$primait$prima_elm_extra$PrimaUpdate$withCmdsMap(
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Tabs$attemptTabFocus(id),
						$author$project$Pyxis$Components$Tabs$dispatchOnTabActivation,
						A2($primait$prima_elm_extra$PrimaCmd$ifThenCmdMap, $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation, $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation)
					])));
	});
var $author$project$Pyxis$Components$Tabs$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var index = msg.a;
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Tabs$dispatchOnTabActivation,
						A2($primait$prima_elm_extra$PrimaCmd$ifThenCmdMap, $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation, $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation)
					]),
				A2($author$project$Pyxis$Components$Tabs$updateIndex, index, model));
		} else {
			var numberOfTabs = msg.a;
			var id = msg.b;
			var event = msg.c;
			return A4($author$project$Pyxis$Components$Tabs$updateOnKeyEvent, id, numberOfTabs, event, model);
		}
	});
var $author$project$Preselection$InitializedPreselection$updateModalTabsWithCmd = F2(
	function (tabsMsg, modelData) {
		return A3(
			$elm$core$Tuple$mapBoth,
			function (updatedTabs) {
				return A2($author$project$Preselection$InitializedPreselection$setModalTabs, updatedTabs, modelData);
			},
			$elm$core$Platform$Cmd$map(
				$author$project$Preselection$InitializedPreselection$pickParentMsg(modelData)),
			A2(
				$author$project$Pyxis$Components$Tabs$update,
				tabsMsg,
				$author$project$Preselection$InitializedPreselection$pickModalTabs(modelData)));
	});
var $author$project$Preselection$InitializedPreselection$update = F2(
	function (msg, modelData) {
		switch (msg.$) {
			case 0:
				var radioCardMsg = msg.a;
				return A2($author$project$Preselection$InitializedPreselection$updateFormTypeFieldWithCmd, radioCardMsg, modelData);
			case 1:
				var minHeight = msg.a;
				return $author$project$Utils$withoutCommands(
					A2($author$project$Preselection$InitializedPreselection$setModalContentMinHeight, minHeight, modelData));
			case 2:
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$toggleBlockScrollingClass(true),
							$author$project$Preselection$InitializedPreselection$getModalContentMinHeightCmd(
							$author$project$Preselection$InitializedPreselection$pickParentMsg(modelData)),
							$author$project$Utils$send(
							A2(
								$author$project$Preselection$InitializedPreselection$pickParentMsg,
								modelData,
								$author$project$Preselection$InitializedPreselection$SendGTMFormEvent(
									{bb: $author$project$Preselection$InitializedPreselection$preselectionInfoModalParameter, ay: $author$project$Preselection$InitializedPreselection$preselectionInfoModalParameter})))
						]),
					A2($author$project$Preselection$InitializedPreselection$setIsModalOpen, true, modelData));
			case 3:
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$toggleBlockScrollingClass(false)
						]),
					A2($author$project$Preselection$InitializedPreselection$setIsModalOpen, false, modelData));
			case 4:
				var tabsMsg = msg.a;
				return A2($author$project$Preselection$InitializedPreselection$updateModalTabsWithCmd, tabsMsg, modelData);
			case 5:
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$GTM$conditionallySendGTMFormEvent(
							{
								nf: $author$project$Utils$GTM$composeEventTag(
									{
										ay: $author$project$Utils$GTM$eventIdWithPrefix(
											{ay: eventId, oV: $author$project$Preselection$InitializedPreselection$preselectionPrefixValue}),
										ne: $author$project$Utils$GTM$defaultEventName,
										oO: $author$project$Utils$GTM$defaultHomeAndFamilyParameterName,
										oP: $author$project$Preselection$InitializedPreselection$preselectionPrefixValue,
										oQ: analyticsParameter
									}),
								n3: true
							})
						]),
					modelData);
			case 6:
				return $author$project$Preselection$InitializedPreselection$onProceedMsg(modelData);
			default:
				var id = msg.a;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Preselection$InitializedPreselection$logDOMElementNotFoundError(id)
						]),
					modelData);
		}
	});
var $author$project$Preselection$Update$updateInitializedPreselection = F2(
	function (msg, preselection) {
		if (!preselection.$) {
			return $author$project$Utils$withoutCommands(preselection);
		} else {
			var initializedPreselection = preselection.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Preselection$Preselection$InitializedModel,
				A2($author$project$Preselection$InitializedPreselection$update, msg, initializedPreselection));
		}
	});
var $author$project$Preselection$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var tokenType = msg.a;
				var maybeUser = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A3($author$project$Preselection$Update$initOrResumeInitializedPreselection, tokenType, maybeUser, model));
			case 1:
				var initializedPreselectionMsg = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2($author$project$Preselection$Update$updateInitializedPreselection, initializedPreselectionMsg, model));
			default:
				var selectedOptions = msg.a;
				return A2($elm$core$List$member, 0, selectedOptions) ? A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Quotation, 0, 1),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model))) : A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Quotation, 1, 1),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
		}
	});
var $author$project$Quotation$Model$FamilyMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Model$HomeMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Family$Model$ModelNotInitialized = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Home$Model$ModelNotInitialized = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Family$Model$OnBlurSelect = {$: 4};
var $author$project$Quotation$Home$Model$OnBlurSelect = {$: 5};
var $author$project$Quotation$Family$Model$Initialize = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Quotation$Model$familyInitializeMsg = F3(
	function (step, tokenType, user) {
		return $author$project$Quotation$Model$FamilyMsg(
			A3($author$project$Quotation$Family$Model$Initialize, step, tokenType, user));
	});
var $author$project$Quotation$Family$Model$Fill = F5(
	function (a, b, c, d, e) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Quotation$Update$familyMsgMapper = F5(
	function (tokenType, user, flags, quote, maybeCity) {
		return $author$project$Quotation$Model$FamilyMsg(
			A5($author$project$Quotation$Family$Model$Fill, tokenType, user, flags, quote, maybeCity));
	});
var $author$project$Quotation$Home$Model$Initialize = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Quotation$Model$homeInitializeMsg = F3(
	function (step, tokenType, user) {
		return $author$project$Quotation$Model$HomeMsg(
			A3($author$project$Quotation$Home$Model$Initialize, step, tokenType, user));
	});
var $author$project$Quotation$Home$Model$Fill = F5(
	function (a, b, c, d, e) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Quotation$Update$homeMsgMapper = F5(
	function (tokenType, user, flags, quote, maybeCity) {
		return $author$project$Quotation$Model$HomeMsg(
			A5($author$project$Quotation$Home$Model$Fill, tokenType, user, flags, quote, maybeCity));
	});
var $author$project$Quotation$Model$NoOp = {$: 9};
var $author$project$Quotation$Update$hydrateSubmodel = F2(
	function (mapper, model) {
		if (model.$ === 1) {
			var modelState = model.a;
			var tokenType = modelState.lK;
			var user = modelState.p6;
			var flags = modelState.nv;
			var maybeSave = $krisajenkins$remotedata$RemoteData$toMaybe(modelState.pA);
			var maybeQuote = $krisajenkins$remotedata$RemoteData$toMaybe(modelState.pe);
			var maybeCity = A2(
				$elm$core$Maybe$andThen,
				$elm$core$List$head,
				$krisajenkins$remotedata$RemoteData$toMaybe(modelState.mx));
			var _v1 = _Utils_Tuple2(maybeQuote, maybeSave);
			_v1$2:
			while (true) {
				if (!_v1.a.$) {
					if ((!_v1.a.a.$) && (_v1.b.$ === 1)) {
						var quote = _v1.a.a.a;
						var _v2 = _v1.b;
						return A5(mapper, tokenType, user, flags, quote, maybeCity);
					} else {
						break _v1$2;
					}
				} else {
					if ((!_v1.b.$) && (!_v1.b.a.$)) {
						var _v3 = _v1.a;
						var save = _v1.b.a.a;
						return A5(mapper, tokenType, user, flags, save.pd, maybeCity);
					} else {
						break _v1$2;
					}
				}
			}
			return $author$project$Quotation$Model$NoOp;
		} else {
			return $author$project$Quotation$Model$NoOp;
		}
	});
var $author$project$Quotation$Model$Hydrated = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Model$ModelHydrating = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Update$formTypeFromModelState = function (modelState) {
	var maybeSave = $krisajenkins$remotedata$RemoteData$toMaybe(modelState.pA);
	var maybeQuote = $krisajenkins$remotedata$RemoteData$toMaybe(modelState.pe);
	var _v0 = _Utils_Tuple2(maybeQuote, maybeSave);
	_v0$2:
	while (true) {
		if (!_v0.a.$) {
			if ((!_v0.a.a.$) && (_v0.b.$ === 1)) {
				var quote = _v0.a.a.a;
				var _v1 = _v0.b;
				return quote.nx;
			} else {
				break _v0$2;
			}
		} else {
			if ((!_v0.b.$) && (!_v0.b.a.$)) {
				var _v2 = _v0.a;
				var save = _v0.b.a.a;
				return save.pd.nx;
			} else {
				break _v0$2;
			}
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Quotation$Update$hydrateWithCity = F2(
	function (response, model) {
		if (model.$ === 1) {
			var modelState = model.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Model$Hydrated(
							$author$project$Quotation$Update$formTypeFromModelState(modelState)))
					]),
				$author$project$Quotation$Model$ModelHydrating(
					_Utils_update(
						modelState,
						{mx: response})));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Quotation$Model$GotBirtCitiesResponse = function (a) {
	return {$: 7, a: a};
};
var $author$project$Gql$Vianello$InputObject$encodeCityFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.oq, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'zipCode',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.qg, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'active',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a8, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool)),
				_Utils_Tuple2(
				'landRegister',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.S, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Vianello$Query$cities = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'cities',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'filter', requiredArgs____.dr, $author$project$Gql$Vianello$InputObject$encodeCityFilter)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$QuotationOld$Model$ExtraTypes$City = F4(
	function (name, landRegister, zipCodes, province) {
		return {S: landRegister, oq: name, o6: province, qh: zipCodes};
	});
var $author$project$Gql$Vianello$Object$City$landRegister = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'landRegister', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$City$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$City$province = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'province',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$QuotationOld$Model$ExtraTypes$Province = function (code) {
	return {mH: code};
};
var $author$project$Gql$Vianello$Object$Province$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'code', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$QuotationOld$Api$Province$provinceSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Province$code,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$ExtraTypes$Province));
var $author$project$QuotationOld$Model$ExtraTypes$ZipCode = function (zip) {
	return {h1: zip};
};
var $author$project$Gql$Vianello$Object$ZipCode$zip = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'zip', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$QuotationOld$Api$ZipCode$zipCodeSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$ZipCode$zip,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$ExtraTypes$ZipCode));
var $author$project$Gql$Vianello$Object$City$zipCodes = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'zipCodes',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$QuotationOld$Api$City$citySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$City$province($author$project$QuotationOld$Api$Province$provinceSelection),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$City$zipCodes($author$project$QuotationOld$Api$ZipCode$zipCodeSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$City$landRegister,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$City$name,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$ExtraTypes$City)))));
var $author$project$QuotationOld$Api$City$citiesByLandRegisterQuery = F3(
	function (city, landRegister, active) {
		return A2(
			$author$project$Gql$Vianello$Query$cities,
			{
				dr: {
					a8: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(active),
					S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(landRegister),
					oq: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(city),
					qg: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
				}
			},
			$author$project$QuotationOld$Api$City$citySelection);
	});
var $author$project$Quotation$Common$Api$City$citiesByLandRegisterRequest = F5(
	function (city, landRegister, active, vianelloUrl, messageMapper) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			messageMapper,
			A3($author$project$QuotationOld$Api$City$citiesByLandRegisterQuery, city, landRegister, active));
	});
var $author$project$Quotation$Update$birthCityRequest = F3(
	function (apiUrl, cityName, landRegister) {
		return A5($author$project$Quotation$Common$Api$City$citiesByLandRegisterRequest, cityName, landRegister, false, apiUrl, $author$project$Quotation$Model$GotBirtCitiesResponse);
	});
var $author$project$Types$Quote$pickItalianCity = function (birthCity) {
	if (birthCity.$ === 1) {
		var city = birthCity.a;
		return $elm$core$Maybe$Just(city);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Update$pickBirthCityRequest = F2(
	function (flags, _v0) {
		var information = _v0.nT;
		return A3(
			$elm$core$Maybe$map2,
			$author$project$Quotation$Update$birthCityRequest(
				$author$project$Types$pickApiUrl(flags)),
			$author$project$Types$Quote$pickItalianCity(information.ih),
			information.mm);
	});
var $author$project$Quotation$Family$Model$pickFlags = function (model) {
	switch (model.$) {
		case 0:
			var flags = model.a;
			return flags;
		case 1:
			var state = model.a;
			return state.nv;
		default:
			var state = model.a;
			return state.nv;
	}
};
var $author$project$Quotation$Home$Model$pickFlags = function (model) {
	switch (model.$) {
		case 0:
			var flags = model.a;
			return flags;
		case 2:
			var state = model.a;
			return state.nv;
		default:
			var state = model.a;
			return state.nv;
	}
};
var $author$project$Quotation$Model$pickFlags = function (model) {
	switch (model.$) {
		case 0:
			var flags = model.a;
			return flags;
		case 1:
			var flags = model.a.nv;
			return flags;
		case 2:
			var homeModel = model.a;
			return $author$project$Quotation$Home$Model$pickFlags(homeModel);
		default:
			var familyModel = model.a;
			return $author$project$Quotation$Family$Model$pickFlags(familyModel);
	}
};
var $author$project$Quotation$Update$withGetCityCmd = F2(
	function (currentState, cityCommand) {
		if (!cityCommand.$) {
			var cmd = cityCommand.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				$author$project$Quotation$Model$ModelHydrating(currentState));
		} else {
			return $author$project$Utils$withoutCommands(
				$author$project$Quotation$Model$ModelHydrating(currentState));
		}
	});
var $author$project$Quotation$Update$initialModelFromHydratingState = function (model) {
	var flags = $author$project$Quotation$Model$pickFlags(model);
	if (model.$ === 1) {
		var modelState = model.a;
		var quoteRequest = modelState.pe;
		var saveRequest = modelState.pA;
		var _v1 = _Utils_Tuple2(quoteRequest, saveRequest);
		if ((_v1.a.$ === 3) && (!_v1.a.a.$)) {
			var quote = _v1.a.a.a;
			return A2(
				$author$project$Quotation$Update$withGetCityCmd,
				modelState,
				A2($author$project$Quotation$Update$pickBirthCityRequest, flags, quote));
		} else {
			if ((_v1.b.$ === 3) && (!_v1.b.a.$)) {
				var save = _v1.b.a.a;
				return A2(
					$author$project$Quotation$Update$withGetCityCmd,
					modelState,
					A2($author$project$Quotation$Update$pickBirthCityRequest, flags, save.pd));
			} else {
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Quotation$Update$hydrateWithQuote = F2(
	function (response, model) {
		if (model.$ === 1) {
			var modelState = model.a;
			return $author$project$Quotation$Update$initialModelFromHydratingState(
				$author$project$Quotation$Model$ModelHydrating(
					_Utils_update(
						modelState,
						{pe: response})));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Quotation$Update$hydrateWithSave = F2(
	function (response, model) {
		if (model.$ === 1) {
			var modelState = model.a;
			return $author$project$Quotation$Update$initialModelFromHydratingState(
				$author$project$Quotation$Model$ModelHydrating(
					_Utils_update(
						modelState,
						{pA: response})));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Quotation$Model$hydratingModel = F5(
	function (quoteId, saveId, tokenType, user, flags) {
		return $author$project$Quotation$Model$ModelHydrating(
			{
				mx: $krisajenkins$remotedata$RemoteData$NotAsked,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				nv: flags,
				cy: quoteId,
				pe: $krisajenkins$remotedata$RemoteData$Loading,
				pz: saveId,
				pA: $krisajenkins$remotedata$RemoteData$Loading,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Model$pickFamilyModel = function (model) {
	if (model.$ === 3) {
		var familyModel = model.a;
		return $elm$core$Maybe$Just(familyModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Model$pickHomeModel = function (model) {
	if (model.$ === 2) {
		var homeModel = model.a;
		return $elm$core$Maybe$Just(homeModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Model$Family = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Model$setFamilyModel = $author$project$Quotation$Model$Family;
var $author$project$Quotation$Model$Home = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Model$setHomeModel = $author$project$Quotation$Model$Home;
var $author$project$Quotation$Family$Model$CustomerStepMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Family$Model$FamilyInfoStepMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$Initialize = {$: 0};
var $author$project$Quotation$Family$FamilyInfoStep$Model$Initialize = {$: 0};
var $author$project$Quotation$Family$CustomerStep$Model$OnBlurSelect = {$: 16};
var $author$project$Quotation$Family$FamilyInfoStep$Model$OnBlurSelect = {$: 10};
var $toastal$either$Either$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Types$ItalianCity = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Family$Model$FamilyInfoStep = function (a) {
	return {$: 0, a: a};
};
var $toastal$either$Either$Left = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$StepResultData = function (nameField) {
	return function (surnameField) {
		return function (genderField) {
			return function (birthDayField) {
				return function (birthCityField) {
					return function (birthCountryField) {
						return function (residentialAddressField) {
							return function (emailField) {
								return function (phoneField) {
									return function (privacyField) {
										return {ii: birthCityField, ij: birthCountryField, ml: birthDayField, i$: emailField, jl: genderField, ki: nameField, kE: phoneField, o_: privacyField, lc: residentialAddressField, lD: surnameField};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm_community$maybe_extra$Maybe$Extra$andMap = $elm$core$Maybe$map2($elm$core$Basics$apR);
var $author$project$Types$Quote$formattedAddress = function (_v0) {
	var street = _v0.lx;
	var number = _v0.kp;
	var city = _v0.my;
	var zipCode = _v0.qg;
	return street + (', ' + (number + (', ' + (zipCode + (' ' + (city + ', Italia'))))));
};
var $author$project$Quotation$Common$Types$quoteAddressMapper = function (address) {
	var city = address.my;
	var street = address.lx;
	var number = address.kp;
	var zipCode = address.qg;
	return {
		bP: $elm$core$Maybe$Just(street),
		l8: $elm$core$Maybe$Just(number),
		my: $elm$core$Maybe$Just(city),
		jf: $author$project$Types$Quote$formattedAddress(address),
		qg: $elm$core$Maybe$Just(zipCode)
	};
};
var $author$project$Quotation$Common$Types$ForeignCity = {$: 0};
var $author$project$Quotation$Common$Fields$Model$quoteBirthCityMapperDefault = function (_v0) {
	var birthCity = _v0.ih;
	var birthLandRegister = _v0.mm;
	if (birthCity.$ === 1) {
		var city = birthCity.a;
		return $author$project$Quotation$Common$Types$ItalianCity(
			{
				S: A2($elm$core$Maybe$withDefault, '', birthLandRegister),
				oq: city,
				o6: $elm$core$Maybe$Nothing,
				qh: _List_Nil
			});
	} else {
		return $author$project$Quotation$Common$Types$ForeignCity;
	}
};
var $author$project$Quotation$Common$Fields$Model$quoteBirthCityMapper = F2(
	function (enrichedCity, quoteInfo) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Quotation$Common$Fields$Model$quoteBirthCityMapperDefault(quoteInfo),
			enrichedCity);
	});
var $author$project$Quotation$Common$Types$ForeignCountry = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Types$Italy = {$: 0};
var $author$project$Quotation$Common$Fields$Model$quoteBirthCountryMapper = function (birthCountry) {
	return ($elm$core$String$toLower(birthCountry) !== 'italia') ? $author$project$Quotation$Common$Types$ForeignCountry(
		{oq: birthCountry}) : $author$project$Quotation$Common$Types$Italy;
};
var $author$project$Quotation$Common$Fields$Model$Female = 1;
var $author$project$Quotation$Common$Fields$Model$Male = 0;
var $author$project$Quotation$Common$Fields$Model$quoteGenderMapper = function (gender) {
	if (!gender) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Quotation$Common$Fields$StepResult$StepResult = $elm$core$Basics$identity;
var $author$project$Quotation$Common$Fields$StepResult$toStepResult = function (data) {
	return data;
};
var $author$project$Quotation$Family$CustomerStep$Model$stepResultFromQuote = F2(
	function (birthCity, _v0) {
		var information = _v0.nT;
		return A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Fields$StepResult$toStepResult,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$andMap,
				$elm$core$Maybe$Just(_List_Nil),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$andMap,
					$elm$core$Maybe$Just(information.oT),
					A2(
						$elm_community$maybe_extra$Maybe$Extra$andMap,
						$elm$core$Maybe$Just(information.fi),
						A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							A2($elm$core$Maybe$map, $author$project$Quotation$Common$Types$quoteAddressMapper, information.pq),
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								$elm$core$Maybe$Just(
									$author$project$Quotation$Common$Fields$Model$quoteBirthCountryMapper(information.mj)),
								A2(
									$elm_community$maybe_extra$Maybe$Extra$andMap,
									$elm$core$Maybe$Just(
										A2($author$project$Quotation$Common$Fields$Model$quoteBirthCityMapper, birthCity, information)),
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$Maybe$Just(information.mn),
										A2(
											$elm_community$maybe_extra$Maybe$Extra$andMap,
											$elm$core$Maybe$Just(
												$author$project$Quotation$Common$Fields$Model$quoteGenderMapper(information.ny)),
											A2(
												$elm_community$maybe_extra$Maybe$Extra$andMap,
												$elm$core$Maybe$Just(information.lC),
												A2(
													$elm_community$maybe_extra$Maybe$Extra$andMap,
													$elm$core$Maybe$Just(information.oq),
													$elm$core$Maybe$Just($author$project$Quotation$Family$CustomerStep$Model$StepResultData))))))))))));
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$StepResultData = F3(
	function (adultsField, childrenField, reportedClaimsField) {
		return {h8: adultsField, it: childrenField, k8: reportedClaimsField};
	});
var $author$project$Quotation$Common$Fields$Model$FiveOrMoreAdults = 4;
var $author$project$Quotation$Common$Fields$Model$FourAdults = 3;
var $author$project$Quotation$Common$Fields$Model$OneAdult = 0;
var $author$project$Quotation$Common$Fields$Model$ThreeAdults = 2;
var $author$project$Quotation$Common$Fields$Model$TwoAdults = 1;
var $author$project$Quotation$Common$Fields$Model$quoteAdultsMapper = function (adults) {
	switch (adults) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		default:
			return 4;
	}
};
var $author$project$Quotation$Common$Fields$Model$FiveOrMoreChildren = 5;
var $author$project$Quotation$Common$Fields$Model$FourChildren = 4;
var $author$project$Quotation$Common$Fields$Model$OneChild = 1;
var $author$project$Quotation$Common$Fields$Model$ThreeChildren = 3;
var $author$project$Quotation$Common$Fields$Model$TwoChildren = 2;
var $author$project$Quotation$Common$Fields$Model$ZeroChildren = 0;
var $author$project$Quotation$Common$Fields$Model$quoteChildrenMapper = function (children) {
	switch (children) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Quotation$Common$Fields$Model$OneReportedClaim = 1;
var $author$project$Quotation$Common$Fields$Model$TwoOrMoreReportedClaims = 2;
var $author$project$Quotation$Common$Fields$Model$ZeroReportedClaims = 0;
var $author$project$Quotation$Common$Fields$Model$quoteClaimsMapper = function (claims) {
	switch (claims) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$stepResultFromQuote = function (_v0) {
	var information = _v0.nT;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$elm_community$maybe_extra$Maybe$Extra$andMap,
			A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteClaimsMapper, information.pn),
			A2(
				$elm_community$maybe_extra$Maybe$Extra$andMap,
				A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteChildrenMapper, information.nr),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$andMap,
					A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteAdultsMapper, information.np),
					$elm$core$Maybe$Just($author$project$Quotation$Family$FamilyInfoStep$Model$StepResultData)))));
};
var $author$project$Quotation$Family$Model$hydrateStateFromQuote = F2(
	function (quote, city) {
		return {
			X: A2(
				$elm$core$Maybe$map,
				$toastal$either$Either$Left,
				A2($author$project$Quotation$Family$CustomerStep$Model$stepResultFromQuote, city, quote)),
			b2: $author$project$Quotation$Family$FamilyInfoStep$Model$stepResultFromQuote(quote)
		};
	});
var $author$project$Utils$initialDate = A2(
	$justinmimbs$date$Date$fromPosix,
	$elm$time$Time$utc,
	$elm$time$Time$millisToPosix(0));
var $author$project$Quotation$Family$Model$ModelInitialized = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState = F3(
	function (flags, formState, stepState) {
		return $author$project$Quotation$Family$Model$ModelInitialized(
			{nv: flags, aA: formState, cH: stepState});
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal = {$: 2};
var $author$project$Quotation$Common$Fields$Model$EmptyField = {$: 0};
var $author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged = function (a) {
	return {$: 5, a: a};
};
var $author$project$Quotation$Common$Fields$Model$AdultsFieldChangedOnSelect = 11;
var $author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper = function (adultsType) {
	switch (adultsType) {
		case 0:
			return 'Uno';
		case 1:
			return 'Due';
		case 2:
			return 'Tre';
		case 3:
			return 'Quattro';
		default:
			return 'Cinque o più';
	}
};
var $author$project$Utils$duplicate = function (a) {
	return _Utils_Tuple2(a, a);
};
var $author$project$Quotation$Common$Fields$Model$getAdultsTypeVariants = _List_fromArray(
	[0, 1, 2, 3, 4]);
var $author$project$Pyxis$Components$Field$Select$Option = $elm$core$Basics$identity;
var $primait$prima_elm_extra$PrimaFunction$flip = F3(
	function (mapper, b, a) {
		return A2(mapper, a, b);
	});
var $author$project$Pyxis$Components$Field$Select$optionId = F2(
	function (label, index) {
		return A2(
			$elm$core$String$join,
			'-',
			A3(
				$primait$prima_elm_extra$PrimaFunction$flip,
				$elm$core$List$append,
				_List_fromArray(
					[
						$elm$core$String$fromInt(index)
					]),
				A2(
					$elm$core$String$split,
					' ',
					$elm$core$String$toLower(label))));
	});
var $author$project$Pyxis$Components$Field$Select$option = function (_v0) {
	var value = _v0.p8;
	var label = _v0.n6;
	return {
		nM: A2($author$project$Pyxis$Components$Field$Select$optionId, label, -1),
		dG: -1,
		n6: label,
		p8: value
	};
};
var $author$project$Quotation$Common$Fields$Model$toSelectOptionValue = function (_v0) {
	var value = _v0.a;
	var label = _v0.b;
	return $author$project$Pyxis$Components$Field$Select$option(
		{n6: label, p8: value});
};
var $author$project$Quotation$Common$Fields$Model$adultsFieldOptions = A2(
	$elm$core$List$map,
	$author$project$Quotation$Common$Fields$Model$toSelectOptionValue,
	A2(
		$elm$core$List$map,
		$author$project$Utils$duplicate,
		A2($elm$core$List$map, $author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper, $author$project$Quotation$Common$Fields$Model$getAdultsTypeVariants)));
var $author$project$Pyxis$Components$Field$Select$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Select$init = {G: $elm$core$Maybe$Nothing, dq: $author$project$Pyxis$Components$Field$FieldStatus$init, jP: false, f6: $elm$core$Maybe$Nothing, gj: $elm$core$Maybe$Nothing, gn: $elm$core$Maybe$Nothing, go: $elm$core$Maybe$Nothing, gL: $elm$core$Maybe$Nothing, d9: $elm$core$Maybe$Nothing, ku: _List_Nil, a2: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Field$Select$setOnSelect = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d9: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$Select$setOptions = F2(
	function (options, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				ku: A2(
					$elm$core$List$indexedMap,
					F2(
						function (index, _v1) {
							var value = _v1.p8;
							var label = _v1.n6;
							return {
								nM: A2($author$project$Pyxis$Components$Field$Select$optionId, label, index),
								dG: index,
								n6: label,
								p8: value
							};
						}),
					options)
			});
	});
var $author$project$Pyxis$Components$Field$Select$setValue = F2(
	function (selectedValue, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				a2: $elm$core$Maybe$Just(selectedValue)
			});
	});
var $author$project$Quotation$Common$Fields$Model$initSelectField = F4(
	function (onFieldChangedMsg, options, msgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Pyxis$Components$Field$Select$setOnSelect,
			msgMapper(onFieldChangedMsg),
			A2($author$project$Pyxis$Components$Field$Select$setOptions, options, $author$project$Pyxis$Components$Field$Select$init));
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var value = fieldValue.a;
			return A2($author$project$Pyxis$Components$Field$Select$setValue, value, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initAdultsField = A2($author$project$Quotation$Common$Fields$Model$initSelectField, 11, $author$project$Quotation$Common$Fields$Model$adultsFieldOptions);
var $author$project$Quotation$Common$Fields$Model$ChildrenFieldChangedOnSelect = 12;
var $author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper = function (childrenType) {
	switch (childrenType) {
		case 0:
			return 'Nessuno';
		case 1:
			return 'Uno';
		case 2:
			return 'Due';
		case 3:
			return 'Tre';
		case 4:
			return 'Quattro';
		default:
			return 'Cinque o più';
	}
};
var $author$project$Quotation$Common$Fields$Model$getChildrenTypeVariants = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $author$project$Quotation$Common$Fields$Model$childrenFieldOptions = A2(
	$elm$core$List$map,
	$author$project$Quotation$Common$Fields$Model$toSelectOptionValue,
	A2(
		$elm$core$List$map,
		$author$project$Utils$duplicate,
		A2($elm$core$List$map, $author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper, $author$project$Quotation$Common$Fields$Model$getChildrenTypeVariants)));
var $author$project$Quotation$Common$Fields$Model$initChildrenField = A2($author$project$Quotation$Common$Fields$Model$initSelectField, 12, $author$project$Quotation$Common$Fields$Model$childrenFieldOptions);
var $author$project$Quotation$Common$Fields$Model$ReportedClaimsFieldChangedOnCheck = 10;
var $author$project$Pyxis$Components$Field$RadioGroup$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$RadioGroup$init = {dq: $author$project$Pyxis$Components$Field$FieldStatus$init, f6: $elm$core$Maybe$Nothing, d4: $elm$core$Maybe$Nothing, gj: $elm$core$Maybe$Nothing, a2: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Field$RadioGroup$setOnCheck = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d4: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$setValue = F2(
	function (selectedValue, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				a2: $elm$core$Maybe$Just(selectedValue)
			});
	});
var $author$project$Quotation$Common$Fields$Model$initRadioGroupField = F3(
	function (onFieldChangedMsg, msgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Pyxis$Components$Field$RadioGroup$setOnCheck,
			msgMapper(onFieldChangedMsg),
			$author$project$Pyxis$Components$Field$RadioGroup$init);
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var value = fieldValue.a;
			return A2($author$project$Pyxis$Components$Field$RadioGroup$setValue, value, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initReportedClaimsField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(10);
var $author$project$Quotation$Common$Fields$Model$Data = $elm$core$Basics$identity;
var $author$project$Quotation$Common$Fields$Model$toFormData = function (formData) {
	return formData;
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialData = $author$project$Quotation$Common$Fields$Model$toFormData(
	{
		h8: A2($author$project$Quotation$Common$Fields$Model$initAdultsField, $author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		it: A2($author$project$Quotation$Common$Fields$Model$initChildrenField, $author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k8: A2($author$project$Quotation$Common$Fields$Model$initReportedClaimsField, $author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)
	});
var $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId = 'family-members-expandable-field';
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialEnabledFields = $elm$core$Set$singleton($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId);
var $author$project$Quotation$Common$StepModel$Model = $elm$core$Basics$identity;
var $author$project$Quotation$Common$StepModel$toStepModel = function (modelData) {
	return modelData;
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialState = F7(
	function (flags, tokenType, user, isViewInitialized, isDiscountMessageVisible, currentDate, expandedFieldsIds) {
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				i1: $author$project$Quotation$Family$FamilyInfoStep$Model$initialEnabledFields,
				i2: $elm$core$Set$empty,
				i5: expandedFieldsIds,
				nv: flags,
				dv: $author$project$Quotation$Family$FamilyInfoStep$Model$initialData,
				jK: isDiscountMessageVisible,
				dM: false,
				jZ: isViewInitialized,
				kc: $author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Common$Fields$Model$FilledField = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Fields$StepResult$pickAdultsField = function (_v0) {
	var adultsField = _v0.h8;
	return adultsField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickChildrenField = function (_v0) {
	var childrenField = _v0.it;
	return childrenField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickReportedClaimsField = function (_v0) {
	var reportedClaimsField = _v0.k8;
	return reportedClaimsField;
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialDataFromResult = function (stepResult) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			h8: A2(
				$author$project$Quotation$Common$Fields$Model$initAdultsField,
				$author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper(
						$author$project$Quotation$Common$Fields$StepResult$pickAdultsField(stepResult)))),
			it: A2(
				$author$project$Quotation$Common$Fields$Model$initChildrenField,
				$author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper(
						$author$project$Quotation$Common$Fields$StepResult$pickChildrenField(stepResult)))),
			k8: A2(
				$author$project$Quotation$Common$Fields$Model$initReportedClaimsField,
				$author$project$Quotation$Family$FamilyInfoStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickReportedClaimsField(stepResult)))
		});
};
var $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId = 'reported-claims-expandable-field';
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialEnabledFieldsFromResult = $elm$core$Set$fromList(
	_List_fromArray(
		[$author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId]));
var $author$project$Quotation$Family$FamilyInfoStep$Model$initialStateFromResult = F6(
	function (flags, tokenType, user, isDiscountMessageVisible, currentDate, result) {
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				i1: $author$project$Quotation$Family$FamilyInfoStep$Model$initialEnabledFieldsFromResult,
				i2: $elm$core$Set$empty,
				i5: $elm$core$Set$empty,
				nv: flags,
				dv: $author$project$Quotation$Family$FamilyInfoStep$Model$initialDataFromResult(result),
				jK: isDiscountMessageVisible,
				dM: false,
				jZ: false,
				kc: $author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Family$Model$initialModelFromQuote = F5(
	function (tokenType, user, flags, quote, city) {
		var hydratedState = A2($author$project$Quotation$Family$Model$hydrateStateFromQuote, quote, city);
		var familyInfoModel = function () {
			var _v0 = hydratedState.b2;
			if (!_v0.$) {
				var familyInfoStep = _v0.a;
				return A6($author$project$Quotation$Family$FamilyInfoStep$Model$initialStateFromResult, flags, tokenType, user, true, $author$project$Utils$initialDate, familyInfoStep);
			} else {
				return A7($author$project$Quotation$Family$FamilyInfoStep$Model$initialState, flags, tokenType, user, false, true, $author$project$Utils$initialDate, $elm$core$Set$empty);
			}
		}();
		return A3(
			$author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState,
			flags,
			hydratedState,
			$author$project$Quotation$Family$Model$FamilyInfoStep(familyInfoModel));
	});
var $author$project$Quotation$Family$Update$initWithQuote = F5(
	function (tokenType, user, flags, quote, city) {
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$Initialize))
				]),
			A5(
				$author$project$Quotation$Family$Model$initialModelFromQuote,
				tokenType,
				user,
				flags,
				quote,
				A2($elm$core$Maybe$map, $author$project$Quotation$Common$Types$ItalianCity, city)));
	});
var $author$project$Quotation$Family$Model$emptyHydrateState = {X: $elm$core$Maybe$Nothing, b2: $elm$core$Maybe$Nothing};
var $author$project$Quotation$Family$Model$initialFamilyModelWithStep = F2(
	function (flags, stepState) {
		return $author$project$Quotation$Family$Model$ModelInitialized(
			{nv: flags, aA: $author$project$Quotation$Family$Model$emptyHydrateState, cH: stepState});
	});
var $author$project$Quotation$Family$Model$initialFamilyInfoStepModel = F3(
	function (tokenType, user, flags) {
		return A2(
			$author$project$Quotation$Family$Model$initialFamilyModelWithStep,
			flags,
			$author$project$Quotation$Family$Model$FamilyInfoStep(
				A7($author$project$Quotation$Family$FamilyInfoStep$Model$initialState, flags, tokenType, user, false, true, $author$project$Utils$initialDate, $elm$core$Set$empty)));
	});
var $author$project$Quotation$Family$CustomerStep$Model$ClosedModal = {$: 2};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$NotAsked = {$: 3};
var $author$project$Quotation$Family$CustomerStep$Model$FieldChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Common$Fields$Model$BirthCityFieldChanged = function (a) {
	return {$: 19, a: a};
};
var $author$project$Quotation$Common$Fields$Model$BirthCityFieldChangedOnSelect = 17;
var $author$project$Quotation$Common$Types$alwaysTrueFilter = F2(
	function (_v0, _v1) {
		return true;
	});
var $author$project$Quotation$Common$Types$cityWithProvince = function (_v0) {
	var name = _v0.oq;
	var province = _v0.o6;
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		name,
		function (_v1) {
			var code = _v1.mH;
			return name + (' (' + (code + ')'));
		},
		province);
};
var $author$project$Quotation$Common$Types$birthCityWithProvince = function (birthCity) {
	if (birthCity.$ === 1) {
		var city = birthCity.a;
		return $author$project$Quotation$Common$Types$cityWithProvince(city);
	} else {
		return 'Stato Estero';
	}
};
var $author$project$Components$DebouncedAutocomplete$Model = $elm$core$Basics$identity;
var $author$project$Components$DebouncedAutocomplete$AutocompleteOnInput = {$: 2};
var $author$project$Components$DebouncedAutocomplete$AutocompleteOnSelect = {$: 3};
var $author$project$Pyxis$Components$Field$Autocomplete$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$Basics$ternaryOperator = F3(
	function (condition, functionOnTrue, functionOnFalse) {
		return condition ? functionOnTrue : functionOnFalse;
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getFilterFunction = F4(
	function (isCaseSensitive, isStartWith, filteringValue, value) {
		var filterFunction = A3($author$project$Pyxis$Commons$Basics$ternaryOperator, isStartWith, $elm$core$String$startsWith, $elm$core$String$contains);
		var convertForCaseSensitive = A3($author$project$Pyxis$Commons$Basics$ternaryOperator, isCaseSensitive, $elm$core$Basics$identity, $elm$core$String$toLower);
		return A2(
			filterFunction,
			convertForCaseSensitive(filteringValue),
			convertForCaseSensitive(value));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$init = {
	G: $elm$core$Maybe$Nothing,
	dq: $author$project$Pyxis$Components$Field$FieldStatus$init,
	bi: A2($author$project$Pyxis$Components$Field$Autocomplete$getFilterFunction, false, true),
	H: '',
	dA: false,
	bs: false,
	bt: false,
	f6: $elm$core$Maybe$Nothing,
	gj: $elm$core$Maybe$Nothing,
	gn: $elm$core$Maybe$Nothing,
	go: $elm$core$Maybe$Nothing,
	gL: $elm$core$Maybe$Nothing,
	d9: $elm$core$Maybe$Nothing,
	p8: $elm$core$Maybe$Nothing,
	hZ: $krisajenkins$remotedata$RemoteData$NotAsked
};
var $author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyCustom = F2(
	function (customFilter, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{bi: customFilter});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOnInput = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				go: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOnSelect = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d9: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Components$DebouncedAutocomplete$initAutocomplete = F2(
	function (optionsFilter, msgMapper) {
		return A2(
			$author$project$Pyxis$Components$Field$Autocomplete$setOnSelect,
			msgMapper($author$project$Components$DebouncedAutocomplete$AutocompleteOnSelect),
			A2(
				$author$project$Pyxis$Components$Field$Autocomplete$setOnInput,
				msgMapper($author$project$Components$DebouncedAutocomplete$AutocompleteOnInput),
				A2($author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyCustom, optionsFilter, $author$project$Pyxis$Components$Field$Autocomplete$init)));
	});
var $author$project$Components$DebouncedAutocomplete$init = F4(
	function (millis, valueToString, optionsFilter, msgMapper) {
		return {
			L: A2($author$project$Components$DebouncedAutocomplete$initAutocomplete, optionsFilter, msgMapper),
			fa: $Gizra$elm_debouncer$Debouncer$Basic$toDebouncer(
				A2(
					$Gizra$elm_debouncer$Debouncer$Basic$settleWhenQuietFor,
					$elm$core$Maybe$Just(
						$Gizra$elm_debouncer$Debouncer$Basic$fromSeconds(millis)),
					$Gizra$elm_debouncer$Debouncer$Basic$manual)),
			ka: msgMapper,
			gX: $elm$core$Maybe$Nothing,
			kv: optionsFilter,
			lT: valueToString
		};
	});
var $author$project$Components$DebouncedAutocomplete$setOnSelectAdditionalMsg = F2(
	function (msg, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				gX: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getOptionData = function (_v0) {
	var optionData = _v0;
	return optionData;
};
var $author$project$Pyxis$Components$Field$Autocomplete$getOptions = function (_v0) {
	var modelData = _v0;
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.n6;
				},
				modelData.bi(modelData.H))),
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$krisajenkins$remotedata$RemoteData$toMaybe(modelData.hZ)));
};
var $author$project$Pyxis$Components$Field$Autocomplete$setActiveOption = F2(
	function (option_, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{G: option_});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption = function (model) {
	var value = model.p8;
	if (!value.$) {
		var currentValue = value.a;
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
			model,
			A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						$elm$core$Basics$eq(currentValue))),
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	} else {
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
			model,
			$elm$core$List$head(
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	}
};
var $krisajenkins$remotedata$RemoteData$isSuccess = function (data) {
	if (data.$ === 3) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $krisajenkins$remotedata$RemoteData$mapError = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var x = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(x);
			case 2:
				var e = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(
					f(e));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			default:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
		}
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOptions = F2(
	function (optionsRemoteData, _v0) {
		var modelData = _v0;
		var values = A2(
			$krisajenkins$remotedata$RemoteData$mapError,
			$elm$core$Basics$always(0),
			optionsRemoteData);
		return $author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption(
			_Utils_update(
				modelData,
				{
					bs: $krisajenkins$remotedata$RemoteData$isSuccess(optionsRemoteData),
					hZ: values
				}));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Autocomplete$option = function (_v0) {
	var value = _v0.p8;
	var label = _v0.n6;
	return {nM: 'id-' + label, n6: label, p8: value};
};
var $author$project$Components$DebouncedAutocomplete$toOption = F2(
	function (valueToString, value) {
		return $author$project$Pyxis$Components$Field$Autocomplete$option(
			{
				n6: valueToString(value),
				p8: value
			});
	});
var $author$project$Components$DebouncedAutocomplete$setOptions = F2(
	function (optionsRemoteData, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				L: A2(
					$author$project$Pyxis$Components$Field$Autocomplete$setOptions,
					A2(
						$krisajenkins$remotedata$RemoteData$map,
						$elm$core$List$map(
							$author$project$Components$DebouncedAutocomplete$toOption(modelData.lT)),
						optionsRemoteData),
					modelData.L)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{G: $elm$core$Maybe$Nothing, dA: false, bs: false, bt: false});
};
var $author$project$Pyxis$Components$Field$Autocomplete$getLabelByValue = F2(
	function (model, value) {
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
				function ($) {
					return $.n6;
				}),
			A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						$elm$core$Basics$eq(value))),
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue = function (model) {
	var modelData = model;
	var filteringValue = A2(
		$elm$core$Maybe$withDefault,
		modelData.H,
		A2(
			$elm$core$Maybe$andThen,
			$author$project$Pyxis$Components$Field$Autocomplete$getLabelByValue(model),
			modelData.p8));
	return _Utils_update(
		modelData,
		{H: filteringValue});
};
var $author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				p8: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setValue = F2(
	function (value, model) {
		return $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed(
			$author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue(
				A2($author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue, value, model)));
	});
var $author$project$Components$DebouncedAutocomplete$setValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		var withUpdatedValue = F2(
			function (value_, _v1) {
				var modelData_ = _v1;
				return _Utils_update(
					modelData_,
					{
						L: A2($author$project$Pyxis$Components$Field$Autocomplete$setValue, value_, modelData_.L)
					});
			});
		var newModel = _Utils_update(
			modelData,
			{
				L: A2($author$project$Components$DebouncedAutocomplete$initAutocomplete, modelData.kv, modelData.ka)
			});
		return A2(
			withUpdatedValue,
			value,
			A2(
				$author$project$Components$DebouncedAutocomplete$setOptions,
				$krisajenkins$remotedata$RemoteData$succeed(
					_List_fromArray(
						[value])),
				newModel));
	});
var $author$project$Quotation$Common$Fields$Model$initDebouncedAutocompleteField = F7(
	function (valueToString, optionsFilter, onFieldChangedMsg, debAutMsgToFieldChangedMsgMapper, onFieldChangedMsgMapper, fieldChangedMsgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Components$DebouncedAutocomplete$setOnSelectAdditionalMsg,
			onFieldChangedMsgMapper(onFieldChangedMsg),
			A4(
				$author$project$Components$DebouncedAutocomplete$init,
				0.5,
				valueToString,
				optionsFilter,
				A2($elm$core$Basics$composeR, debAutMsgToFieldChangedMsgMapper, fieldChangedMsgMapper)));
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var value = fieldValue.a;
			return A2($author$project$Components$DebouncedAutocomplete$setValue, value, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initBirthCityField = A4($author$project$Quotation$Common$Fields$Model$initDebouncedAutocompleteField, $author$project$Quotation$Common$Types$birthCityWithProvince, $author$project$Quotation$Common$Types$alwaysTrueFilter, 17, $author$project$Quotation$Common$Fields$Model$BirthCityFieldChanged);
var $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChanged = function (a) {
	return {$: 20, a: a};
};
var $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChangedOnSelect = 18;
var $author$project$Quotation$Common$Types$pickBirthCountryName = function (birthCountry) {
	if (!birthCountry.$) {
		return 'Italia';
	} else {
		var country = birthCountry.a;
		return country.oq;
	}
};
var $author$project$Quotation$Common$Types$stringStartsWith = F2(
	function (filter, s) {
		return A2(
			$elm$core$String$startsWith,
			$elm$core$String$toLower(filter),
			$elm$core$String$toLower(s));
	});
var $author$project$Quotation$Common$Fields$Model$initBirthCountryField = A4($author$project$Quotation$Common$Fields$Model$initDebouncedAutocompleteField, $author$project$Quotation$Common$Types$pickBirthCountryName, $author$project$Quotation$Common$Types$stringStartsWith, 18, $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChanged);
var $author$project$Quotation$Common$Fields$Model$BirthdayFieldChangedOnInput = 16;
var $author$project$Pyxis$Components$Field$DateInput$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$DateInput$init = {dq: $author$project$Pyxis$Components$Field$FieldStatus$init, f6: $elm$core$Maybe$Nothing, gj: $elm$core$Maybe$Nothing, go: $elm$core$Maybe$Nothing, p8: ''};
var $author$project$Pyxis$Components$Field$DateInput$setOnInput = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				go: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$setValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{p8: value});
	});
var $author$project$Quotation$Common$Fields$Model$initDateInputField = F3(
	function (onFieldChangedMsg, msgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Pyxis$Components$Field$DateInput$setOnInput,
			msgMapper(onFieldChangedMsg),
			$author$project$Pyxis$Components$Field$DateInput$init);
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var value = fieldValue.a;
			return A2($author$project$Pyxis$Components$Field$DateInput$setValue, value, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initBirthdayField = $author$project$Quotation$Common$Fields$Model$initDateInputField(16);
var $author$project$Quotation$Common$Fields$Model$CommercialPurposesFieldChangedOnCheck = 22;
var $author$project$Pyxis$Components$Field$CheckboxGroup$setOnCheck = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d4: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$setValue = F2(
	function (checkedValues, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{au: checkedValues});
	});
var $author$project$Quotation$Common$Fields$Model$initCheckboxGroupField = F3(
	function (onFieldChangedMsg, msgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Pyxis$Components$Field$CheckboxGroup$setOnCheck,
			msgMapper(onFieldChangedMsg),
			$author$project$Pyxis$Components$Field$CheckboxGroup$init);
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var valuesList = fieldValue.a;
			return A2($author$project$Pyxis$Components$Field$CheckboxGroup$setValue, valuesList, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initCommercialPurposesField = $author$project$Quotation$Common$Fields$Model$initCheckboxGroupField(22);
var $author$project$Quotation$Common$Fields$Model$EmailFieldChangedOnInput = 19;
var $author$project$Pyxis$Components$Field$Input$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Input$init = {dq: $author$project$Pyxis$Components$Field$FieldStatus$init, f6: $elm$core$Maybe$Nothing, gj: $elm$core$Maybe$Nothing, go: $elm$core$Maybe$Nothing, p8: ''};
var $author$project$Pyxis$Components$Field$Input$setOnInput = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				go: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$Input$setValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{p8: value});
	});
var $author$project$Quotation$Common$Fields$Model$initInputField = F3(
	function (onFieldChangedMsg, msgMapper, fieldValue) {
		var initialStateField = A2(
			$author$project$Pyxis$Components$Field$Input$setOnInput,
			msgMapper(onFieldChangedMsg),
			$author$project$Pyxis$Components$Field$Input$init);
		if (!fieldValue.$) {
			return initialStateField;
		} else {
			var value = fieldValue.a;
			return A2($author$project$Pyxis$Components$Field$Input$setValue, value, initialStateField);
		}
	});
var $author$project$Quotation$Common$Fields$Model$initEmailField = $author$project$Quotation$Common$Fields$Model$initInputField(19);
var $author$project$Quotation$Common$Fields$Model$GenderFieldChangedOnCheck = 15;
var $author$project$Quotation$Common$Fields$Model$initGenderField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(15);
var $author$project$Quotation$Common$Fields$Model$NameFieldChangedOnInput = 13;
var $author$project$Quotation$Common$Fields$Model$initNameField = $author$project$Quotation$Common$Fields$Model$initInputField(13);
var $author$project$Quotation$Common$Fields$Model$PhoneFieldChangedOnInput = 20;
var $author$project$Quotation$Common$Fields$Model$initPhoneField = $author$project$Quotation$Common$Fields$Model$initInputField(20);
var $author$project$Quotation$Common$Fields$Model$PrivacyPolicyFieldChangedOnCheck = 21;
var $author$project$Quotation$Common$Fields$Model$initPrivacyPolicyField = $author$project$Quotation$Common$Fields$Model$initCheckboxGroupField(21);
var $author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChangedOnSelect = 9;
var $author$project$Quotation$Common$Fields$Model$initResidentialAddressField = A4(
	$author$project$Quotation$Common$Fields$Model$initDebouncedAutocompleteField,
	function ($) {
		return $.jf;
	},
	$author$project$Quotation$Common$Types$alwaysTrueFilter,
	9,
	$author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChanged);
var $author$project$Quotation$Common$Fields$Model$SurnameFieldChangedOnInput = 14;
var $author$project$Quotation$Common$Fields$Model$initSurnameField = $author$project$Quotation$Common$Fields$Model$initInputField(14);
var $author$project$Quotation$Common$Components$AddressFallback$ResidentialAddress = 1;
var $author$project$Utils$GTM$defaultFamilyParameterName = 'family_form';
var $author$project$Quotation$Common$Fields$Model$ResidentialFallbackAddressFieldsChanged = function (a) {
	return {$: 11, a: a};
};
var $author$project$Quotation$Common$Components$AddressFallback$Model = $elm$core$Basics$identity;
var $author$project$Quotation$Common$Components$AddressFallback$CityChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Common$Components$AddressFallback$CityChangedOnSelect = {$: 5};
var $author$project$Quotation$Common$Components$AddressFallback$Data = $elm$core$Basics$identity;
var $author$project$Quotation$Common$Components$AddressFallback$StreetNameChangedOnInput = {$: 1};
var $author$project$Quotation$Common$Components$AddressFallback$StreetNumberChangedOnInput = {$: 3};
var $author$project$Quotation$Common$Components$AddressFallback$ZipCodeChangedOnSelect = {$: 7};
var $author$project$Quotation$Common$Components$AddressFallback$initZipCode = F2(
	function (zipCodes, msgMapper) {
		var zipCodeAutocomplete = A2(
			$author$project$Pyxis$Components$Field$Autocomplete$setOnSelect,
			msgMapper($author$project$Quotation$Common$Components$AddressFallback$ZipCodeChangedOnSelect),
			A2($author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyCustom, $author$project$Quotation$Common$Types$stringStartsWith, $author$project$Pyxis$Components$Field$Autocomplete$init));
		if (((!zipCodes.$) && zipCodes.a.b) && (!zipCodes.a.b.b)) {
			var _v1 = zipCodes.a;
			var first = _v1.a;
			return A2($author$project$Pyxis$Components$Field$Autocomplete$setValue, first, zipCodeAutocomplete);
		} else {
			return zipCodeAutocomplete;
		}
	});
var $author$project$Quotation$Common$Components$AddressFallback$initData = function (msgMapper) {
	return {
		bW: A2(
			$author$project$Components$DebouncedAutocomplete$setOnSelectAdditionalMsg,
			msgMapper($author$project$Quotation$Common$Components$AddressFallback$CityChangedOnSelect),
			A4(
				$author$project$Components$DebouncedAutocomplete$init,
				0.5,
				$author$project$Quotation$Common$Types$cityWithProvince,
				$author$project$Quotation$Common$Types$alwaysTrueFilter,
				A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$CityChanged, msgMapper))),
		cI: A2(
			$author$project$Pyxis$Components$Field$Input$setOnInput,
			msgMapper($author$project$Quotation$Common$Components$AddressFallback$StreetNameChangedOnInput),
			$author$project$Pyxis$Components$Field$Input$init),
		cJ: A2(
			$author$project$Pyxis$Components$Field$Input$setOnInput,
			msgMapper($author$project$Quotation$Common$Components$AddressFallback$StreetNumberChangedOnInput),
			$author$project$Pyxis$Components$Field$Input$init),
		cT: A2($author$project$Quotation$Common$Components$AddressFallback$initZipCode, $elm$core$Maybe$Nothing, msgMapper)
	};
};
var $author$project$Quotation$Common$Components$AddressFallback$init = F4(
	function (analyticsParameterName, analyticsEventPrefix, addressType, msgMapper) {
		return {
			h7: addressType,
			ib: analyticsEventPrefix,
			ic: analyticsParameterName,
			dv: $author$project$Quotation$Common$Components$AddressFallback$initData(msgMapper),
			b8: false,
			fO: false,
			aD: msgMapper,
			ho: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Quotation$Common$Fields$Model$initAddressFallbackField = F5(
	function (afMsgToFieldChangedMsgMapper, fieldChangedMsgMapper, analyticsParameterName, analyticsEventPrefix, addressType) {
		return A4(
			$author$project$Quotation$Common$Components$AddressFallback$init,
			analyticsParameterName,
			analyticsEventPrefix,
			addressType,
			A2($elm$core$Basics$composeR, afMsgToFieldChangedMsgMapper, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$Model$initResidentialAddressFallbackField = $author$project$Quotation$Common$Fields$Model$initAddressFallbackField($author$project$Quotation$Common$Fields$Model$ResidentialFallbackAddressFieldsChanged);
var $author$project$Quotation$Family$CustomerStep$Model$stepPrefixValue = 'customer_step';
var $author$project$Quotation$Family$CustomerStep$Model$initialResidentialAddressFallback = A4($author$project$Quotation$Common$Fields$Model$initResidentialAddressFallbackField, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged, $author$project$Utils$GTM$defaultFamilyParameterName, $author$project$Quotation$Family$CustomerStep$Model$stepPrefixValue, 1);
var $author$project$Quotation$Family$CustomerStep$Model$initialData = function (initialEmail) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			ii: A3($author$project$Quotation$Common$Fields$Model$initBirthCityField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ij: A3($author$project$Quotation$Common$Fields$Model$initBirthCountryField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ik: A2($author$project$Quotation$Common$Fields$Model$initBirthdayField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			iB: A2($author$project$Quotation$Common$Fields$Model$initCommercialPurposesField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			i$: A2(
				$author$project$Quotation$Common$Fields$Model$initEmailField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					A2($elm$core$Maybe$withDefault, '', initialEmail))),
			jl: A2($author$project$Quotation$Common$Fields$Model$initGenderField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ki: A2($author$project$Quotation$Common$Fields$Model$initNameField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			kE: A2($author$project$Quotation$Common$Fields$Model$initPhoneField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			kT: A2($author$project$Quotation$Common$Fields$Model$initPrivacyPolicyField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			lb: $author$project$Quotation$Family$CustomerStep$Model$initialResidentialAddressFallback,
			lc: A3($author$project$Quotation$Common$Fields$Model$initResidentialAddressField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			lD: A2($author$project$Quotation$Common$Fields$Model$initSurnameField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)
		});
};
var $author$project$Quotation$Common$Fields$StepResult$pickBirthCityField = function (_v0) {
	var birthCityField = _v0.ii;
	return birthCityField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickBirthCountryField = function (_v0) {
	var birthCountryField = _v0.ij;
	return birthCountryField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickBirthDayField = function (_v0) {
	var birthDayField = _v0.ml;
	return birthDayField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickEmailField = function (_v0) {
	var emailField = _v0.i$;
	return emailField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickGenderField = function (_v0) {
	var genderField = _v0.jl;
	return genderField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickNameField = function (_v0) {
	var nameField = _v0.ki;
	return nameField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPhoneField = function (_v0) {
	var phoneField = _v0.kE;
	return phoneField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickResidentialAddressField = function (_v0) {
	var residentialAddressField = _v0.lc;
	return residentialAddressField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickSurnameField = function (_v0) {
	var surnameField = _v0.lD;
	return surnameField;
};
var $author$project$Quotation$Common$Fields$Model$CommercialPurposes = 1;
var $author$project$Quotation$Common$Fields$StepResult$pickPrivacyField = function (_v0) {
	var privacyField = _v0.o_;
	return privacyField;
};
var $author$project$Quotation$Common$Fields$StepResult$resultToCommercialPurposesFieldValue = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$StepResult$pickPrivacyField,
	$elm$core$List$filter(
		$elm$core$Basics$eq(1)));
var $author$project$Quotation$Common$Fields$Model$PrivacyPolicy = 0;
var $author$project$Quotation$Common$Fields$StepResult$resultToPrivacyPolicyFieldValue = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$StepResult$pickPrivacyField,
	$elm$core$List$filter(
		$elm$core$Basics$eq(0)));
var $author$project$Quotation$Family$CustomerStep$Model$initialDataFromResult = function (stepResult) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			ii: A3(
				$author$project$Quotation$Common$Fields$Model$initBirthCityField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Family$CustomerStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(stepResult))),
			ij: A3(
				$author$project$Quotation$Common$Fields$Model$initBirthCountryField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Family$CustomerStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCountryField(stepResult))),
			ik: A2(
				$author$project$Quotation$Common$Fields$Model$initBirthdayField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$justinmimbs$date$Date$toIsoString(
						$author$project$Quotation$Common$Fields$StepResult$pickBirthDayField(stepResult)))),
			iB: A2(
				$author$project$Quotation$Common$Fields$Model$initCommercialPurposesField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$resultToCommercialPurposesFieldValue(stepResult))),
			i$: A2(
				$author$project$Quotation$Common$Fields$Model$initEmailField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickEmailField(stepResult))),
			jl: A2(
				$author$project$Quotation$Common$Fields$Model$initGenderField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickGenderField(stepResult))),
			ki: A2(
				$author$project$Quotation$Common$Fields$Model$initNameField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickNameField(stepResult))),
			kE: A2(
				$author$project$Quotation$Common$Fields$Model$initPhoneField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPhoneField(stepResult))),
			kT: A2(
				$author$project$Quotation$Common$Fields$Model$initPrivacyPolicyField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$resultToPrivacyPolicyFieldValue(stepResult))),
			lb: $author$project$Quotation$Family$CustomerStep$Model$initialResidentialAddressFallback,
			lc: A3(
				$author$project$Quotation$Common$Fields$Model$initResidentialAddressField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Family$CustomerStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickResidentialAddressField(stepResult))),
			lD: A2(
				$author$project$Quotation$Common$Fields$Model$initSurnameField,
				$author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickSurnameField(stepResult)))
		});
};
var $toastal$either$Either$leftToMaybe = function (e) {
	if (e.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var x = e.a;
		return $elm$core$Maybe$Just(x);
	}
};
var $toastal$either$Either$unwrap = F3(
	function (d, f, e) {
		if (!e.$) {
			return d;
		} else {
			var a = e.a;
			return f(a);
		}
	});
var $toastal$either$Either$toMaybeVia = function (f) {
	return A2(
		$toastal$either$Either$unwrap,
		$elm$core$Maybe$Nothing,
		A2($elm$core$Basics$composeR, f, $elm$core$Maybe$Just));
};
var $toastal$either$Either$toMaybe = $toastal$either$Either$toMaybeVia($elm$core$Basics$identity);
var $toastal$either$Either$rightToMaybe = $toastal$either$Either$toMaybe;
var $author$project$Quotation$Family$CustomerStep$Model$initialState = F6(
	function (flags, tokenType, user, currentDate, familyInfoStepResult, initialStepData) {
		var initialFormDataResult = A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Family$CustomerStep$Model$initialDataFromResult,
			A2($elm$core$Maybe$andThen, $toastal$either$Either$leftToMaybe, initialStepData));
		var initialFormData = A2($elm$core$Maybe$andThen, $toastal$either$Either$rightToMaybe, initialStepData);
		var formData = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Quotation$Family$CustomerStep$Model$initialData(
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fi;
					},
					user)),
			A2($elm_community$maybe_extra$Maybe$Extra$or, initialFormData, initialFormDataResult));
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				nq: familyInfoStepResult,
				nv: flags,
				dv: formData,
				je: $author$project$RFQDTS$Http$Authenticator$AuthResponse$NotAsked,
				jK: false,
				dM: false,
				jT: false,
				jZ: false,
				kc: $author$project$Quotation$Family$CustomerStep$Model$ClosedModal,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Family$Model$CustomerStep = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Family$Update$initialCustomerStepModel = F6(
	function (flags, tokenType, user, hydrateState, customerStepState, familyInfoStepState) {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Family$Model$CustomerStepMsg($author$project$Quotation$Family$CustomerStep$Model$Initialize))
					]),
				A3(
					$author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState,
					flags,
					hydrateState,
					$author$project$Quotation$Family$Model$CustomerStep(
						A6($author$project$Quotation$Family$CustomerStep$Model$initialState, flags, tokenType, user, $author$project$Utils$initialDate, familyInfoStepState, customerStepState)))));
	});
var $author$project$Quotation$Family$Update$initialFamilyInfoStepModel = F5(
	function (flags, tokenType, user, hydrateState, stepState) {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$Initialize))
					]),
				A3(
					$author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState,
					flags,
					hydrateState,
					$author$project$Quotation$Family$Model$FamilyInfoStep(
						A6($author$project$Quotation$Family$FamilyInfoStep$Model$initialStateFromResult, flags, tokenType, user, true, $author$project$Utils$initialDate, stepState)))));
	});
var $author$project$Quotation$Family$Update$redirectModel = function (flags) {
	return A2(
		$author$project$Utils$UpdateReturn$withPushUrl,
		$author$project$Routing$NotFound,
		$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				$author$project$Quotation$Family$Model$ModelNotInitialized(flags))));
};
var $author$project$Quotation$Family$Update$initialModelFromFormHydrateState = F4(
	function (tokenType, user, step, model) {
		var flags = model.nv;
		var hydrateState = model.aA;
		switch (step) {
			case 1:
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A3($author$project$Quotation$Family$Model$initialFamilyInfoStepModel, tokenType, user, flags))),
					A2(
						$elm$core$Maybe$map,
						A4($author$project$Quotation$Family$Update$initialFamilyInfoStepModel, flags, tokenType, user, hydrateState),
						hydrateState.b2));
			case 2:
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Quotation$Family$Update$redirectModel(flags),
					A2(
						$elm$core$Maybe$map,
						A5($author$project$Quotation$Family$Update$initialCustomerStepModel, flags, tokenType, user, hydrateState, hydrateState.X),
						hydrateState.b2));
			default:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotFound,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							$author$project$Quotation$Family$Model$ModelInitialized(model))));
		}
	});
var $author$project$Quotation$Family$Update$maybeChangeStep = F4(
	function (tokenType, user, step, model) {
		var _v0 = _Utils_Tuple2(step, model.cH);
		_v0$2:
		while (true) {
			if (!_v0.b.$) {
				if (_v0.a === 1) {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									$author$project$Utils$send(
									$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$Initialize))
								]),
							$author$project$Quotation$Family$Model$ModelInitialized(model)));
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.a === 2) {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$Utils$withCommands,
							_List_fromArray(
								[
									$author$project$Utils$send(
									$author$project$Quotation$Family$Model$CustomerStepMsg($author$project$Quotation$Family$CustomerStep$Model$Initialize))
								]),
							$author$project$Quotation$Family$Model$ModelInitialized(model)));
				} else {
					break _v0$2;
				}
			}
		}
		return A4($author$project$Quotation$Family$Update$initialModelFromFormHydrateState, tokenType, user, step, model);
	});
var $author$project$Quotation$Family$Model$pickStepState = function (model) {
	if (model.$ === 2) {
		var stepState = model.a.cH;
		return $elm$core$Maybe$Just(stepState);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Family$Model$pickCustomerStepModel = function (model) {
	var _v0 = $author$project$Quotation$Family$Model$pickStepState(model);
	if ((!_v0.$) && (_v0.a.$ === 1)) {
		var customerStepModel = _v0.a.a;
		return $elm$core$Maybe$Just(customerStepModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Family$Model$pickFamilyInfoStepModel = function (model) {
	var _v0 = $author$project$Quotation$Family$Model$pickStepState(model);
	if ((!_v0.$) && (!_v0.a.$)) {
		var familyInfoStepModel = _v0.a.a;
		return $elm$core$Maybe$Just(familyInfoStepModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Family$Model$pickHydrateState = function (model) {
	if (model.$ === 2) {
		var hydrateState = model.a.aA;
		return $elm$core$Maybe$Just(hydrateState);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Family$Model$setCustomerHydrateState = F2(
	function (stepState, formState) {
		return _Utils_update(
			formState,
			{
				X: $elm$core$Maybe$Just(stepState)
			});
	});
var $author$project$Quotation$Family$Model$setCustomerStepModel = F2(
	function (model, customerStepModel) {
		if (model.$ === 2) {
			var hydrateState = model.a.aA;
			var flags = model.a.nv;
			return A3(
				$author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState,
				flags,
				hydrateState,
				$author$project$Quotation$Family$Model$CustomerStep(customerStepModel));
		} else {
			return A2(
				$author$project$Quotation$Family$Model$initialFamilyModelWithStep,
				$author$project$Quotation$Family$Model$pickFlags(model),
				$author$project$Quotation$Family$Model$CustomerStep(customerStepModel));
		}
	});
var $author$project$Quotation$Family$Model$setFamilyInfoHydrateState = F2(
	function (stepState, formState) {
		return _Utils_update(
			formState,
			{
				b2: $elm$core$Maybe$Just(stepState)
			});
	});
var $author$project$Quotation$Family$Model$setFamilyInfoStepModel = F2(
	function (model, familyInfoStepModel) {
		if (model.$ === 2) {
			var hydrateState = model.a.aA;
			var flags = model.a.nv;
			return A3(
				$author$project$Quotation$Family$Model$initialFamilyModelWithStepAndState,
				flags,
				hydrateState,
				$author$project$Quotation$Family$Model$FamilyInfoStep(familyInfoStepModel));
		} else {
			return A2(
				$author$project$Quotation$Family$Model$initialFamilyModelWithStep,
				$author$project$Quotation$Family$Model$pickFlags(model),
				$author$project$Quotation$Family$Model$FamilyInfoStep(familyInfoStepModel));
		}
	});
var $author$project$Quotation$Family$CustomerStep$Model$CurrentDate = function (a) {
	return {$: 14, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$GotNewSeed = function (a) {
	return {$: 15, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$InitializeView = {$: 1};
var $author$project$Quotation$Family$CustomerStep$Model$OpenPrivacyPolicyModal = {$: 0};
var $author$project$Quotation$Family$CustomerStep$Model$Response = function (a) {
	return {$: 5, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$SearchBirthCityResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$SearchBirthCountryResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$SearchResidentialAddressCityResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$SearchResidentialPlaceResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter = 'city_of_birth';
var $elm$http$Http$Timeout = {$: 1};
var $author$project$Quotation$Common$Fields$Model$pickBirthCityField = function (_v0) {
	var birthCityField = _v0.ii;
	return birthCityField;
};
var $author$project$Quotation$Common$Fields$Model$setBirthCity = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{ii: fieldValue});
	});
var $author$project$Quotation$Common$Fields$Model$birthCitySuggestionsMapper = F2(
	function (response, formData) {
		var tmpCityResponse = A2(
			$krisajenkins$remotedata$RemoteData$mapError,
			function (_v0) {
				return $elm$http$Http$Timeout;
			},
			A2(
				$krisajenkins$remotedata$RemoteData$map,
				$elm$core$List$map($author$project$Quotation$Common$Types$ItalianCity),
				response));
		return A2(
			$author$project$Quotation$Common$Fields$Model$setBirthCity,
			A2(
				$author$project$Components$DebouncedAutocomplete$setOptions,
				tmpCityResponse,
				$author$project$Quotation$Common$Fields$Model$pickBirthCityField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter = 'state_of_birth';
var $author$project$Quotation$Common$Types$italyCountryName = 'Italia';
var $author$project$Quotation$Common$Fields$Model$pickBirthCountryField = function (_v0) {
	var birthCountryField = _v0.ij;
	return birthCountryField;
};
var $author$project$Quotation$Common$Fields$Model$setBirthCountry = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{ij: fieldValue});
	});
var $author$project$Quotation$Common$Fields$Model$birthCountrySuggestionsMapper = F2(
	function (response, formData) {
		var tmpCountryResponse = A2(
			$krisajenkins$remotedata$RemoteData$mapError,
			function (_v0) {
				return $elm$http$Http$Timeout;
			},
			A2(
				$krisajenkins$remotedata$RemoteData$map,
				$elm$core$List$map($author$project$Quotation$Common$Types$ForeignCountry),
				A2(
					$krisajenkins$remotedata$RemoteData$map,
					$elm$core$List$filter(
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.oq;
							},
							$elm$core$Basics$neq($author$project$Quotation$Common$Types$italyCountryName))),
					response)));
		return A2(
			$author$project$Quotation$Common$Fields$Model$setBirthCountry,
			A2(
				$author$project$Components$DebouncedAutocomplete$setOptions,
				tmpCountryResponse,
				$author$project$Quotation$Common$Fields$Model$pickBirthCountryField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Analytics$birthdayAnalyticsParameter = 'date_of_birth';
var $author$project$Pyxis$Components$Field$Autocomplete$getValue = function (_v0) {
	var value = _v0.p8;
	return value;
};
var $author$project$Components$DebouncedAutocomplete$getValue = function (_v0) {
	var modelData = _v0;
	return $author$project$Pyxis$Components$Field$Autocomplete$getValue(modelData.L);
};
var $author$project$Quotation$Common$Fields$Model$checkIsForeignCity = function (formData) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (city) {
				return _Utils_eq(city, $author$project$Quotation$Common$Types$ForeignCity);
			},
			$author$project$Components$DebouncedAutocomplete$getValue(
				$author$project$Quotation$Common$Fields$Model$pickBirthCityField(formData))));
};
var $author$project$Quotation$Common$Components$AddressFallback$mapCity = F2(
	function (mapper, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				bW: mapper(data.bW)
			});
	});
var $author$project$Quotation$Common$Components$AddressFallback$mapFormData = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				dv: mapper(modelData.dv)
			});
	});
var $author$project$Components$DebouncedAutocomplete$setDropdownClosed = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{
			L: $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed(modelData.L)
		});
};
var $author$project$Quotation$Common$Components$AddressFallback$closeCitySelect = $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
	$author$project$Quotation$Common$Components$AddressFallback$mapCity($author$project$Components$DebouncedAutocomplete$setDropdownClosed));
var $author$project$Quotation$Common$Components$AddressFallback$mapZipCode = F2(
	function (mapper, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				cT: mapper(data.cT)
			});
	});
var $author$project$Quotation$Common$Components$AddressFallback$closeZipSelect = $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
	$author$project$Quotation$Common$Components$AddressFallback$mapZipCode($author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed));
var $author$project$Quotation$Common$Components$AddressFallback$closeAllSelect = function (model) {
	return $author$project$Quotation$Common$Components$AddressFallback$closeZipSelect(
		$author$project$Quotation$Common$Components$AddressFallback$closeCitySelect(model));
};
var $author$project$Quotation$Common$Fields$Model$closeBirthCitySelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setBirthCity,
		$author$project$Components$DebouncedAutocomplete$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickBirthCityField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$closeBirthCountrySelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setBirthCountry,
		$author$project$Components$DebouncedAutocomplete$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickBirthCountryField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$pickResidentialAddressField = function (_v0) {
	var residentialAddressField = _v0.lc;
	return residentialAddressField;
};
var $author$project$Quotation$Common$Fields$Model$setResidentialAddress = F2(
	function (residentialAddressField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{lc: residentialAddressField});
	});
var $author$project$Quotation$Common$Fields$Model$closeResidentialAddressSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setResidentialAddress,
		$author$project$Components$DebouncedAutocomplete$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(formData)),
		formData);
};
var $author$project$Quotation$Common$StepModel$mapFormData = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				dv: mapper(modelData.dv)
			});
	});
var $author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField = function (_v0) {
	var residentialAddressFallbackField = _v0.lb;
	return residentialAddressFallbackField;
};
var $author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback = F2(
	function (residentialAddressFallbackField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{lb: residentialAddressFallbackField});
	});
var $author$project$Quotation$Common$Fields$Model$residentialAddressFallbackMapper = F2(
	function (mapper, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback,
			mapper(
				$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(formData)),
			formData);
	});
var $author$project$Quotation$Family$CustomerStep$Model$closeAllSelect = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$residentialAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$closeAllSelect),
		A2(
			$author$project$Quotation$Common$StepModel$mapFormData,
			$author$project$Quotation$Common$Fields$Model$closeResidentialAddressSelect,
			A2(
				$author$project$Quotation$Common$StepModel$mapFormData,
				$author$project$Quotation$Common$Fields$Model$closeBirthCountrySelect,
				A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$closeBirthCitySelect, model))));
};
var $author$project$Quotation$Common$Analytics$commercialPurposesAnalyticsParameter = 'marketing_consent';
var $author$project$Quotation$Common$Analytics$buildingAdministratorInfoParameter = 'click_on_building_manager_modal';
var $author$project$Quotation$Common$Analytics$constructionPeriodInfoParameter = 'click_on_construction_period_modal';
var $author$project$Quotation$Common$Analytics$familyMembersInfoParameter = 'click_on_people_in_house_modal';
var $author$project$Quotation$Common$Analytics$ownershipTypeInfoParameter = 'click_on_owner_type_modal';
var $author$project$Quotation$Common$Analytics$propertyAddressInfoParameter = 'click_on_property_complete_address_autocomplete_modal';
var $author$project$Quotation$Common$Analytics$propertyFloorInfoParameter = 'click_on_floor_modal';
var $author$project$Quotation$Common$Analytics$propertyPurposeInfoParameter = 'click_on_use_type_modal';
var $author$project$Quotation$Common$Analytics$propertySizeInfoParameter = 'click_on_sqm_modal';
var $author$project$Quotation$Common$Analytics$reportedClaimsInfoParameter = 'click_on_past_incidents_modal';
var $author$project$Quotation$Common$Analytics$residentialAddressInfoParameter = 'click_on_residential_complete_address_autocomplete_modal';
var $author$project$Quotation$Common$Analytics$villaTypeInfoParameter = 'click_on_building_type_modal';
var $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 0:
			return $author$project$Quotation$Common$Analytics$ownershipTypeInfoParameter;
		case 1:
			return $author$project$Quotation$Common$Analytics$propertyPurposeInfoParameter;
		case 2:
			return $author$project$Quotation$Common$Analytics$villaTypeInfoParameter;
		case 3:
			return $author$project$Quotation$Common$Analytics$buildingAdministratorInfoParameter;
		case 4:
			return $author$project$Quotation$Common$Analytics$constructionPeriodInfoParameter;
		case 5:
			return $author$project$Quotation$Common$Analytics$propertySizeInfoParameter;
		case 6:
			return $author$project$Quotation$Common$Analytics$propertyAddressInfoParameter;
		case 7:
			return $author$project$Quotation$Common$Analytics$propertyFloorInfoParameter;
		case 8:
			return $author$project$Quotation$Common$Analytics$residentialAddressInfoParameter;
		case 9:
			return $author$project$Quotation$Common$Analytics$reportedClaimsInfoParameter;
		default:
			return $author$project$Quotation$Common$Analytics$familyMembersInfoParameter;
	}
};
var $author$project$Quotation$Family$CustomerStep$Update$conditionalFieldInfoModalAnalyticsCmd = function (fieldInfoModalState) {
	switch (fieldInfoModalState.$) {
		case 1:
			var fieldInfoModal = fieldInfoModalState.a;
			return $author$project$Utils$send(
				$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
					{
						bb: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
						ay: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
						n3: true
					}));
		case 0:
			return $elm$core$Platform$Cmd$none;
		default:
			return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Quotation$Family$CustomerStep$Model$ManualAddressToggleFromAutocomplete = 0;
var $author$project$Quotation$Family$CustomerStep$Model$ManualAddressToggleFromLink = 1;
var $author$project$Quotation$Common$Analytics$residentialAddressManualAutocompleteParameter = 'open_residential_manual_input_from_infobox';
var $author$project$Quotation$Common$Analytics$residentialAddressManualLinkParameter = 'open_residential_manual_input_from_autocomplete';
var $author$project$Quotation$Family$CustomerStep$Update$conditionalManualResidentialAddressCmd = F2(
	function (manualAddressToggleType, isManual) {
		return (isManual && (!manualAddressToggleType)) ? $author$project$Utils$send(
			$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$residentialAddressManualAutocompleteParameter, ay: $author$project$Quotation$Common$Analytics$residentialAddressManualAutocompleteParameter, n3: true})) : ((isManual && (manualAddressToggleType === 1)) ? $author$project$Utils$send(
			$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$residentialAddressManualLinkParameter, ay: $author$project$Quotation$Common$Analytics$residentialAddressManualLinkParameter, n3: true})) : $elm$core$Platform$Cmd$none);
	});
var $author$project$Quotation$Common$Analytics$privacyPolicyModalAnalyticsParameter = 'click_on_privacy_consent_modal';
var $author$project$Quotation$Family$CustomerStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd = function (isModalOpen) {
	return isModalOpen ? $author$project$Utils$send(
		$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
			{bb: $author$project$Quotation$Common$Analytics$privacyPolicyModalAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$privacyPolicyModalAnalyticsParameter, n3: true})) : $elm$core$Platform$Cmd$none;
};
var $author$project$Quotation$Common$StepModel$pickFormData = function (_v0) {
	var formData = _v0.dv;
	return formData;
};
var $author$project$Quotation$Common$Components$AddressFallback$pickResult = function (_v0) {
	var result = _v0.ho;
	return result;
};
var $author$project$Quotation$Common$StepModel$setIsResidentialAddressManual = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{jT: value});
	});
var $author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldValue = F4(
	function (picker, setter, value, model) {
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Quotation$Common$StepModel$mapFormData,
			model,
			setter(
				A2(
					$author$project$Components$DebouncedAutocomplete$setValue,
					value,
					picker(
						$author$project$Quotation$Common$StepModel$pickFormData(model)))));
	});
var $author$project$Quotation$Common$Updaters$updateResidentialAddressValue = A2($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldValue, $author$project$Quotation$Common$Fields$Model$pickResidentialAddressField, $author$project$Quotation$Common$Fields$Model$setResidentialAddress);
var $author$project$Quotation$Family$CustomerStep$Update$conditionallyUpdateResidentialAddress = function (model) {
	var _v0 = $author$project$Quotation$Common$Components$AddressFallback$pickResult(
		$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(model)));
	if (!_v0.$) {
		var result = _v0.a;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$toggleBlockScrollingClass(false)
				]),
			A2(
				$author$project$Quotation$Common$StepModel$setIsResidentialAddressManual,
				false,
				A2(
					$author$project$Quotation$Common$StepModel$mapFormData,
					$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback($author$project$Quotation$Family$CustomerStep$Model$initialResidentialAddressFallback),
					A2($author$project$Quotation$Common$Updaters$updateResidentialAddressValue, result, model))));
	} else {
		return $author$project$Utils$withoutCommands(model);
	}
};
var $author$project$Quotation$Common$Analytics$emailAnalyticsParameter = 'email_address';
var $author$project$Quotation$Common$Analytics$genderAnalyticsParameter = 'gender';
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $author$project$Quotation$Family$CustomerStep$Model$GoBackToFamilyInfoStep = F6(
	function (a, b, c, d, e, f) {
		return {$: 13, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$Quotation$Common$Analytics$backBtnParameter = 'click_on_back';
var $author$project$Quotation$Common$StepModel$pickCurrentDate = function (_v0) {
	var currentDate = _v0.iH;
	return currentDate;
};
var $author$project$Quotation$Common$StepModel$pickFamilyInfoStepResult = function (_v0) {
	var familyInfoStepResult = _v0.nq;
	return familyInfoStepResult;
};
var $author$project$Quotation$Common$StepModel$pickFlags = function (_v0) {
	var flags = _v0.nv;
	return flags;
};
var $author$project$Quotation$Common$StepModel$pickTokenType = function (_v0) {
	var tokenType = _v0.lK;
	return tokenType;
};
var $author$project$Quotation$Common$StepModel$pickUser = function (_v0) {
	var user = _v0.p6;
	return user;
};
var $author$project$Quotation$Family$CustomerStep$Update$goBackToFamilyInfoStep = function (model) {
	return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
		A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					A6(
						$author$project$Quotation$Family$CustomerStep$Model$GoBackToFamilyInfoStep,
						$author$project$Quotation$Common$StepModel$pickFlags(model),
						$author$project$Quotation$Common$StepModel$pickTokenType(model),
						$author$project$Quotation$Common$StepModel$pickUser(model),
						$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
						$author$project$Quotation$Common$StepModel$pickFamilyInfoStepResult(model),
						$author$project$Quotation$Common$StepModel$pickFormData(model))),
					$author$project$Utils$send(
					$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
						{bb: $author$project$Quotation$Common$Analytics$backBtnParameter, ay: $author$project$Quotation$Common$Analytics$backBtnParameter, n3: true}))
				]),
			model));
};
var $author$project$Quotation$Common$StepModel$setFormSubmissionResult = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{je: value});
	});
var $author$project$Quotation$Family$CustomerStep$Update$withResponse = function (response) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$setFormSubmissionResult(response),
		A2($elm$core$Basics$composeR, $author$project$Utils$withoutCommands, $author$project$Utils$UpdateReturn$addDefaultUpdateReturn));
};
var $author$project$Quotation$Family$CustomerStep$Update$handleQuoteResponse = F2(
	function (response, model) {
		if (!response.$) {
			if (!response.a.$) {
				var quoteId = response.a.a;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A3($author$project$Routing$Guarantees, 1, quoteId, $elm$core$Maybe$Nothing),
					A2($author$project$Quotation$Family$CustomerStep$Update$withResponse, response, model));
			} else {
				var error = response.a.a;
				var _v1 = error.k6;
				if (_v1 === 1) {
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$QuoteNotAvailable),
						A2($author$project$Quotation$Family$CustomerStep$Update$withResponse, response, model));
				} else {
					return A2($author$project$Quotation$Family$CustomerStep$Update$withResponse, response, model);
				}
			}
		} else {
			return A2($author$project$Quotation$Family$CustomerStep$Update$withResponse, response, model);
		}
	});
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$map3 = F4(
	function (func, _v0, _v1, _v2) {
		var genA = _v0;
		var genB = _v1;
		var genC = _v2;
		return function (seed0) {
			var _v3 = genA(seed0);
			var a = _v3.a;
			var seed1 = _v3.b;
			var _v4 = genB(seed1);
			var b = _v4.a;
			var seed2 = _v4.b;
			var _v5 = genC(seed2);
			var c = _v5.a;
			var seed3 = _v5.b;
			return _Utils_Tuple2(
				A3(func, a, b, c),
				seed3);
		};
	});
var $elm$random$Random$independentSeed = function (seed0) {
	var makeIndependentSeed = F3(
		function (state, b, c) {
			return $elm$random$Random$next(
				A2($elm$random$Random$Seed, state, (1 | (b ^ c)) >>> 0));
		});
	var gen = A2($elm$random$Random$int, 0, 4294967295);
	return A2(
		$elm$random$Random$step,
		A4($elm$random$Random$map3, makeIndependentSeed, gen, gen, gen),
		seed0);
};
var $elm_community$result_extra$Result$Extra$isOk = function (x) {
	if (!x.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Quotation$Common$Validation$requiredFieldErrorMessage = 'Campo obbligatorio';
var $author$project$Quotation$Common$Validation$isJust = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Err($author$project$Quotation$Common$Validation$requiredFieldErrorMessage),
		A2($elm$core$Maybe$map, $elm$core$Result$Ok, value));
};
var $author$project$Quotation$Common$Fields$Model$birthCityFieldValidation = $author$project$Quotation$Common$Validation$isJust;
var $author$project$Quotation$Common$StepModel$validatedBirthCityField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickBirthCityField,
	A2($elm$core$Basics$composeR, $author$project$Components$DebouncedAutocomplete$getValue, $author$project$Quotation$Common$Fields$Model$birthCityFieldValidation));
var $author$project$Quotation$Common$StepModel$isBirthCityFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedBirthCityField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Validation$invalidFieldErrorMessage = 'Il valore inserito non è valido';
var $author$project$Quotation$Common$Validation$differentFrom = F2(
	function (list, value) {
		return A2($elm$core$List$member, value, list) ? $elm$core$Result$Err($author$project$Quotation$Common$Validation$invalidFieldErrorMessage) : $elm$core$Result$Ok(value);
	});
var $author$project$Quotation$Common$Fields$Model$birthCountryFieldValidation = F2(
	function (isForeignCitySelected, value) {
		return isForeignCitySelected ? A2(
			$elm$core$Result$andThen,
			$author$project$Quotation$Common$Validation$differentFrom(
				_List_fromArray(
					[$author$project$Quotation$Common$Types$Italy])),
			$author$project$Quotation$Common$Validation$isJust(value)) : $elm$core$Result$Ok($author$project$Quotation$Common$Types$Italy);
	});
var $author$project$Quotation$Common$StepModel$validatedBirthCountryField = function (isForeignCitySelected) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$pickBirthCountryField,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Components$DebouncedAutocomplete$getValue,
			$author$project$Quotation$Common$Fields$Model$birthCountryFieldValidation(isForeignCitySelected)));
};
var $author$project$Quotation$Common$StepModel$isBirthCountryFieldValid = function (isForeignCitySelected) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickFormData,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$StepModel$validatedBirthCountryField(isForeignCitySelected),
			$elm_community$result_extra$Result$Extra$isOk));
};
var $author$project$Quotation$Common$Validation$ageRangeErrorMessage = F2(
	function (minAge, maxAge) {
		return 'Devi avere un\'età compresa tra ' + ($elm$core$String$fromInt(minAge) + (' anni e ' + ($elm$core$String$fromInt(maxAge) + ' anni')));
	});
var $author$project$Quotation$Common$Validation$ageRange = F4(
	function (minAge, maxAge, today, value) {
		var ageYears = A3($justinmimbs$date$Date$diff, 0, value, today);
		return ((_Utils_cmp(ageYears, minAge) > -1) && (_Utils_cmp(ageYears, maxAge) < 1)) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err(
			A2($author$project$Quotation$Common$Validation$ageRangeErrorMessage, minAge, maxAge));
	});
var $author$project$Quotation$Common$Validation$requiredString = function (value) {
	return $elm$core$String$isEmpty(value) ? $elm$core$Result$Err($author$project$Quotation$Common$Validation$requiredFieldErrorMessage) : $elm$core$Result$Ok(value);
};
var $author$project$Quotation$Common$Fields$Model$birthdayFieldValidation = function (currentDate) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Validation$requiredString,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Result$andThen(
				A2(
					$elm$core$Basics$composeR,
					$justinmimbs$date$Date$fromIsoString,
					$elm$core$Result$mapError(
						$elm$core$Basics$always('La data inserita non è valida')))),
			$elm$core$Result$andThen(
				A3($author$project$Quotation$Common$Validation$ageRange, 18, 100, currentDate))));
};
var $author$project$Pyxis$Components$Field$DateInput$getValue = function (_v0) {
	var value = _v0.p8;
	return value;
};
var $author$project$Quotation$Common$Fields$Model$pickBirthdayField = function (_v0) {
	var birthdayField = _v0.ik;
	return birthdayField;
};
var $author$project$Quotation$Common$StepModel$validatedBirthdayField = function (currentDate) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$pickBirthdayField,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$DateInput$getValue,
			$author$project$Quotation$Common$Fields$Model$birthdayFieldValidation(currentDate)));
};
var $author$project$Quotation$Common$StepModel$isBirthdayFieldValid = function (currentDate) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickFormData,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$StepModel$validatedBirthdayField(currentDate),
			$elm_community$result_extra$Result$Extra$isOk));
};
var $author$project$Quotation$Common$Fields$Model$pickCommercialPurposesField = function (_v0) {
	var commercialPurposesField = _v0.iB;
	return commercialPurposesField;
};
var $author$project$Quotation$Common$Fields$Model$isCommercialPurposesOptionChecked = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickCommercialPurposesField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$CheckboxGroup$getValue,
		$elm$core$List$member(1)));
var $author$project$Quotation$Common$Validation$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'));
var $author$project$Quotation$Common$Validation$emailFormat = function (value) {
	return A2($elm$regex$Regex$contains, $author$project$Quotation$Common$Validation$emailRegex, value) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err('Formato email non valido');
};
var $elm$core$String$trim = _String_trim;
var $author$project$Quotation$Common$Validation$requiredEmail = function (mapper) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$trim,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Validation$requiredString,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Result$andThen($author$project$Quotation$Common$Validation$emailFormat),
				$elm$core$Result$andThen(mapper))));
};
var $author$project$Quotation$Common$Fields$Model$emailFieldValidation = $author$project$Quotation$Common$Validation$requiredEmail($elm$core$Result$Ok);
var $author$project$Pyxis$Components$Field$Input$getValue = function (_v0) {
	var value = _v0.p8;
	return value;
};
var $author$project$Quotation$Common$Fields$Model$pickEmailField = function (_v0) {
	var emailField = _v0.i$;
	return emailField;
};
var $author$project$Quotation$Common$StepModel$validatedEmailField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickEmailField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Fields$Model$emailFieldValidation));
var $author$project$Quotation$Common$StepModel$isEmailFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedEmailField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Validation$requiredRadioCard = $elm$core$Result$fromMaybe($author$project$Quotation$Common$Validation$requiredFieldErrorMessage);
var $author$project$Quotation$Common$Fields$Model$genderFieldValidation = $author$project$Quotation$Common$Validation$requiredRadioCard;
var $author$project$Pyxis$Components$Field$RadioGroup$getValue = function (_v0) {
	var selectedValue = _v0.a2;
	return selectedValue;
};
var $author$project$Quotation$Common$Fields$Model$pickGenderField = function (_v0) {
	var genderField = _v0.jl;
	return genderField;
};
var $author$project$Quotation$Common$StepModel$validatedGenderField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickGenderField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$genderFieldValidation));
var $author$project$Quotation$Common$StepModel$isGenderFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedGenderField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Validation$stringLengthErrorMessage = F2(
	function (minLength, maxLength) {
		return 'Il valore inserito deve avere una lunghezza compresa tra ' + ($elm$core$String$fromInt(minLength) + (' e ' + ($elm$core$String$fromInt(maxLength) + ' caratteri')));
	});
var $author$project$Quotation$Common$Validation$stringLength = F3(
	function (minLength, maxLength, value) {
		return ((_Utils_cmp(
			$elm$core$String$length(value),
			minLength) > -1) && (_Utils_cmp(
			$elm$core$String$length(value),
			maxLength) < 1)) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err(
			A2($author$project$Quotation$Common$Validation$stringLengthErrorMessage, minLength, maxLength));
	});
var $author$project$Quotation$Common$Validation$minMaxLength = F3(
	function (mapper, minLength, maxLength) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Validation$requiredString,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Result$andThen(
					A2($author$project$Quotation$Common$Validation$stringLength, minLength, maxLength)),
				$elm$core$Result$andThen(mapper)));
	});
var $author$project$Quotation$Common$Fields$Model$nameFieldValidation = A3($author$project$Quotation$Common$Validation$minMaxLength, $elm$core$Result$Ok, 2, 100);
var $author$project$Quotation$Common$Fields$Model$pickNameField = function (_v0) {
	var nameField = _v0.ki;
	return nameField;
};
var $author$project$Quotation$Common$StepModel$validatedNameField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickNameField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Fields$Model$nameFieldValidation));
var $author$project$Quotation$Common$StepModel$isNameFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedNameField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Validation$phoneRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(([+]|00)39)?((3[1-9][0-9]))(\\d{6,7})$'));
var $author$project$Quotation$Common$Validation$phoneFormat = function (value) {
	return A2($elm$regex$Regex$contains, $author$project$Quotation$Common$Validation$phoneRegex, value) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err('Numero di telefono non valido');
};
var $author$project$Quotation$Common$Validation$requiredPhone = function (mapper) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$trim,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Validation$requiredString,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Result$andThen($author$project$Quotation$Common$Validation$phoneFormat),
				$elm$core$Result$andThen(mapper))));
};
var $author$project$Quotation$Common$Fields$Model$phoneFieldValidation = $author$project$Quotation$Common$Validation$requiredPhone($elm$core$Result$Ok);
var $author$project$Quotation$Common$Fields$Model$pickPhoneField = function (_v0) {
	var phoneField = _v0.kE;
	return phoneField;
};
var $author$project$Quotation$Common$StepModel$validatedPhoneField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPhoneField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Fields$Model$phoneFieldValidation));
var $author$project$Quotation$Common$StepModel$isPhoneFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedPhoneField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Fields$Model$pickPrivacyPolicyField = function (_v0) {
	var privacyPolicyField = _v0.kT;
	return privacyPolicyField;
};
var $author$project$Quotation$Common$Fields$Model$isPrivacyPolicyOptionChecked = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPrivacyPolicyField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$CheckboxGroup$getValue,
		$elm$core$List$member(0)));
var $author$project$Quotation$Common$Fields$Model$NotRequiredResidentialAddress = {$: 1};
var $author$project$Quotation$Common$Fields$Model$ResidentialAddress = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Common$Validation$placeFieldValidation = F3(
	function (picker, message, place) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Result$Err(message),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Basics$always(place),
					$elm$core$Result$Ok),
				picker(place)));
	});
var $author$project$Quotation$Common$Validation$placeValidation = function (place) {
	return A2(
		$elm$core$Result$andThen,
		A2(
			$author$project$Quotation$Common$Validation$placeFieldValidation,
			function ($) {
				return $.l8;
			},
			'Inserisci anche il numero civico per continuare'),
		A2(
			$elm$core$Result$andThen,
			A2(
				$author$project$Quotation$Common$Validation$placeFieldValidation,
				function ($) {
					return $.qg;
				},
				'Il CAP è obbligatorio'),
			A2(
				$elm$core$Result$andThen,
				A2(
					$author$project$Quotation$Common$Validation$placeFieldValidation,
					function ($) {
						return $.my;
					},
					'La Città è obbligatoria'),
				$elm$core$Result$Ok(place))));
};
var $author$project$Quotation$Common$Validation$placeAutocompleteValidation = function (value) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Err($author$project$Quotation$Common$Validation$requiredFieldErrorMessage),
		A2($elm$core$Maybe$map, $author$project$Quotation$Common$Validation$placeValidation, value));
};
var $author$project$Quotation$Common$Fields$Model$optionalResidentialAddressFieldValidation = function (isResidentialAddressRequired) {
	return isResidentialAddressRequired ? A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Validation$placeAutocompleteValidation,
		$elm$core$Result$map($author$project$Quotation$Common$Fields$Model$ResidentialAddress)) : $elm$core$Basics$always(
		$elm$core$Result$Ok($author$project$Quotation$Common$Fields$Model$NotRequiredResidentialAddress));
};
var $author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField = function (isResidentialAddressRequired) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Components$DebouncedAutocomplete$getValue,
			$author$project$Quotation$Common$Fields$Model$optionalResidentialAddressFieldValidation(isResidentialAddressRequired)));
};
var $author$project$Quotation$Common$StepModel$isResidentialAddressFieldValid = function (isResidentialAddressRequired) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickFormData,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField(isResidentialAddressRequired),
			$elm_community$result_extra$Result$Extra$isOk));
};
var $author$project$Quotation$Common$Fields$Model$pickSurnameField = function (_v0) {
	var surnameField = _v0.lD;
	return surnameField;
};
var $author$project$Quotation$Common$Fields$Model$surnameFieldValidation = A3($author$project$Quotation$Common$Validation$minMaxLength, $elm$core$Result$Ok, 1, 100);
var $author$project$Quotation$Common$StepModel$validatedSurnameField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickSurnameField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Fields$Model$surnameFieldValidation));
var $author$project$Quotation$Common$StepModel$isSurnameFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedSurnameField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$QuotationOld$Api$City$cityQuery = F2(
	function (active, city) {
		return A2(
			$author$project$Gql$Vianello$Query$cities,
			{
				dr: {
					a8: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(active),
					S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					oq: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(city),
					qg: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
				}
			},
			$author$project$QuotationOld$Api$City$citySelection);
	});
var $author$project$Quotation$Common$Api$City$citiesRequest = F4(
	function (filter, active, vianelloUrl, messageMapper) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			messageMapper,
			A2($author$project$QuotationOld$Api$City$cityQuery, active, filter));
	});
var $author$project$Quotation$Common$Command$maybeSearchCitiesCommand = F4(
	function (msg, messageMapper, active, vianelloUrl) {
		var commandPreCondition = function (filter) {
			return $elm$core$String$length(filter) > 1;
		};
		var buildCommand = function (filter) {
			return A2(
				$author$project$Utils$ifThenCmd,
				commandPreCondition(filter),
				A4($author$project$Quotation$Common$Api$City$citiesRequest, filter, active, vianelloUrl, messageMapper));
		};
		if (msg.$ === 6) {
			var value = msg.a;
			return buildCommand(value);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Quotation$Common$Command$maybeSearchCities = F4(
	function (msg, messageMapper, vianelloUrl, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					A4($author$project$Quotation$Common$Command$maybeSearchCitiesCommand, msg, messageMapper, false, vianelloUrl)
				]),
			model);
	});
var $author$project$Quotation$Common$Command$maybeSearchCitiesFallback = F4(
	function (msg, messageMapper, vianelloUrl, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		if (msg.$ === 4) {
			var subMsg = msg.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						cmd,
						A4($author$project$Quotation$Common$Command$maybeSearchCitiesCommand, subMsg, messageMapper, true, vianelloUrl)
					]),
				model);
		} else {
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				model);
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$int = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$int(value));
};
var $author$project$Gql$Vianello$InputObject$encodeCountryFilter = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'activeDate',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.a9,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.mK;
						},
						$author$project$VianelloScalarCodecs$codecs))),
				_Utils_Tuple2(
				'landRegister',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.S, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'limit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'name',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.oq, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Vianello$Query$countries = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'countries',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'filter', requiredArgs____.dr, $author$project$Gql$Vianello$InputObject$encodeCountryFilter)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$QuotationOld$Model$ExtraTypes$Country = function (name) {
	return {oq: name};
};
var $author$project$Gql$Vianello$Object$Country$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$QuotationOld$Api$Country$countrySelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Country$name,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$ExtraTypes$Country));
var $author$project$QuotationOld$Api$Country$countryQuery = function (country) {
	return A2(
		$author$project$Gql$Vianello$Query$countries,
		{
			dr: {
				a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				oq: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(country)
			}
		},
		$author$project$QuotationOld$Api$Country$countrySelection);
};
var $author$project$Quotation$Common$Api$City$countryRequest = F3(
	function (filter, vianelloUrl, messageMapper) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			messageMapper,
			$author$project$QuotationOld$Api$Country$countryQuery(filter));
	});
var $author$project$Quotation$Common$Command$maybeSearchCountriesCommand = F3(
	function (msg, messageMapper, vianelloUrl) {
		var commandPreCondition = function (filter) {
			return $elm$core$String$length(filter) > 3;
		};
		var buildCommand = function (filter) {
			return A2(
				$author$project$Utils$ifThenCmd,
				commandPreCondition(filter),
				A3($author$project$Quotation$Common$Api$City$countryRequest, filter, vianelloUrl, messageMapper));
		};
		if (msg.$ === 6) {
			var value = msg.a;
			return buildCommand(value);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Quotation$Common$Command$maybeSearchCountries = F4(
	function (msg, messageMapper, vianelloUrl, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					A3($author$project$Quotation$Common$Command$maybeSearchCountriesCommand, msg, messageMapper, vianelloUrl)
				]),
			model);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$float = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$float(value));
};
var $author$project$Gql$Vianello$InputObject$encodeInputLocation = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'latitude',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$float(input____.cd))),
				_Utils_Tuple2(
				'longitude',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$float(input____.ch)))
			]));
};
var $author$project$Gql$Vianello$InputObject$encodeSearchPlacesArgs = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'input',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.b7))),
				_Utils_Tuple2(
				'limit',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.T, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'location',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bw, $author$project$Gql$Vianello$InputObject$encodeInputLocation)),
				_Utils_Tuple2(
				'offset',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bx, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'provider',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bC, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'skipCache',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bG, $dillonkearns$elm_graphql$Graphql$Internal$Encode$bool))
			]));
};
var $author$project$Gql$Vianello$Query$searchPlaces = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'searchPlaces',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'args', requiredArgs____.ie, $author$project$Gql$Vianello$InputObject$encodeSearchPlacesArgs)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Quotation$Common$Types$Place = F5(
	function (formattedAddress, city, address, addressNumber, zipCode) {
		return {bP: address, l8: addressNumber, my: city, jf: formattedAddress, qg: zipCode};
	});
var $author$project$Gql$Vianello$Object$Place$address = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'address',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$Place$addressNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'addressNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$Place$city = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'city',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$Place$formattedAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'formattedAddress', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$Place$zipCode = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'zipCode',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Quotation$Common$Api$Place$searchPlacesSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$Place$zipCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$Place$addressNumber,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$Place$address,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Vianello$Object$Place$city,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Vianello$Object$Place$formattedAddress,
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Quotation$Common$Types$Place))))));
var $author$project$Quotation$Common$Api$Place$searchPlacesQuery = function (city) {
	return A2(
		$author$project$Gql$Vianello$Query$searchPlaces,
		{
			ie: {
				b7: city,
				T: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(10),
				bw: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				bx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				bC: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present('tom_tom'),
				bG: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
			}
		},
		$author$project$Quotation$Common$Api$Place$searchPlacesSelection);
};
var $author$project$Quotation$Common$Api$Place$searchPlacesRequest = F3(
	function (input, vianelloUrl, messageMapper) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			messageMapper,
			$author$project$Quotation$Common$Api$Place$searchPlacesQuery(input));
	});
var $author$project$Quotation$Common$Command$maybeSearchCommand = F3(
	function (msg, messageMapper, vianelloUrl) {
		var commandPreCondition = function (filter) {
			return $elm$core$String$length(filter) > 4;
		};
		var buildCommand = function (filter) {
			return A2(
				$author$project$Utils$ifThenCmd,
				commandPreCondition(filter),
				A3($author$project$Quotation$Common$Api$Place$searchPlacesRequest, filter, vianelloUrl, messageMapper));
		};
		if (msg.$ === 6) {
			var value = msg.a;
			return buildCommand(value);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Quotation$Common$Command$maybeSearchPlaces = F4(
	function (msg, messageMapper, vianelloUrl, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					A3($author$project$Quotation$Common$Command$maybeSearchCommand, msg, messageMapper, vianelloUrl)
				]),
			model);
	});
var $author$project$Quotation$Common$Analytics$nameAnalyticsParameter = 'first_name';
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $author$project$Utils$onViewportLoadCmd = function (msg) {
	return A2(
		$elm$core$Task$attempt,
		$elm$core$Basics$always(msg),
		$elm$browser$Browser$Dom$getViewport);
};
var $author$project$Quotation$Common$Analytics$phoneAnalyticsParameter = 'phone_mobile';
var $author$project$Quotation$Common$StepModel$pickVianelloUrl = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$pickFlags, $author$project$Types$pickApiUrl);
var $author$project$Quotation$Common$Analytics$privacyPolicyAnalyticsParameter = 'privacy_consent';
var $elm_community$result_extra$Result$Extra$andMap = F2(
	function (ra, rb) {
		var _v0 = _Utils_Tuple2(ra, rb);
		if (_v0.b.$ === 1) {
			var x = _v0.b.a;
			return $elm$core$Result$Err(x);
		} else {
			var o = _v0.a;
			var fn = _v0.b.a;
			return A2($elm$core$Result$map, fn, o);
		}
	});
var $elm_community$result_extra$Result$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $author$project$Quotation$Common$Fields$Model$commercialPurposesFieldValidation = function (values) {
	return $elm$core$Result$Ok(values);
};
var $author$project$Quotation$Common$StepModel$validatedCommercialPurposesField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickCommercialPurposesField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$CheckboxGroup$getValue, $author$project$Quotation$Common$Fields$Model$commercialPurposesFieldValidation));
var $author$project$Quotation$Common$Fields$Model$privacyPolicyFieldValidation = function (values) {
	return A2($elm$core$List$member, 0, values) ? $elm$core$Result$Ok(values) : $elm$core$Result$Err('Devi aver letto l\'informativa privacy prima di continuare');
};
var $author$project$Quotation$Common$StepModel$validatedPrivacyPolicyField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPrivacyPolicyField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$CheckboxGroup$getValue, $author$project$Quotation$Common$Fields$Model$privacyPolicyFieldValidation));
var $author$project$Quotation$Common$Fields$Model$residentialAddressFieldValidation = $author$project$Quotation$Common$Validation$placeAutocompleteValidation;
var $author$project$Quotation$Common$StepModel$validatedResidentialAddressField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField,
	A2($elm$core$Basics$composeR, $author$project$Components$DebouncedAutocomplete$getValue, $author$project$Quotation$Common$Fields$Model$residentialAddressFieldValidation));
var $author$project$Quotation$Family$CustomerStep$Model$buildStepResultData = F2(
	function (stepState, stepData) {
		var privacyFieldResult = A2(
			$elm$core$Result$map,
			$elm$core$List$concat,
			$elm_community$result_extra$Result$Extra$combine(
				_List_fromArray(
					[
						$author$project$Quotation$Common$StepModel$validatedPrivacyPolicyField(stepData),
						$author$project$Quotation$Common$StepModel$validatedCommercialPurposesField(stepData)
					])));
		var isForeignCitySelected = $author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState));
		var currentDate = $author$project$Quotation$Common$StepModel$pickCurrentDate(stepState);
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			privacyFieldResult,
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$StepModel$validatedPhoneField(stepData),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$Quotation$Common$StepModel$validatedEmailField(stepData),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						$author$project$Quotation$Common$StepModel$validatedResidentialAddressField(stepData),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2($author$project$Quotation$Common$StepModel$validatedBirthCountryField, isForeignCitySelected, stepData),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								$author$project$Quotation$Common$StepModel$validatedBirthCityField(stepData),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									A2($author$project$Quotation$Common$StepModel$validatedBirthdayField, currentDate, stepData),
									A2(
										$elm_community$result_extra$Result$Extra$andMap,
										$author$project$Quotation$Common$StepModel$validatedGenderField(stepData),
										A2(
											$elm_community$result_extra$Result$Extra$andMap,
											$author$project$Quotation$Common$StepModel$validatedSurnameField(stepData),
											A2(
												$elm_community$result_extra$Result$Extra$andMap,
												$author$project$Quotation$Common$StepModel$validatedNameField(stepData),
												$elm$core$Result$Ok($author$project$Quotation$Family$CustomerStep$Model$StepResultData)))))))))));
	});
var $author$project$Quotation$Family$CustomerStep$Model$getStepResult = function (stepState) {
	return A2(
		$elm$core$Result$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$author$project$Quotation$Family$CustomerStep$Model$buildStepResultData,
			stepState,
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Common$StepModel$setIsFormSubmitted = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{dM: value});
	});
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$Loading = {$: 2};
var $author$project$Quotation$Common$StepModel$pickCurrentSeed = function (_v0) {
	var currentSeed = _v0.iI;
	return currentSeed;
};
var $author$project$Quotation$Common$Analytics$proceedBtnParameter = 'click_on_proceed';
var $author$project$Quotation$Family$CustomerStep$Model$GotQuote = function (a) {
	return {$: 18, a: a};
};
var $author$project$AntiSpiderProtectedApi$MutationOp = $elm$core$Basics$identity;
var $author$project$AntiSpiderProtectedApi$ProtectedQuery = $elm$core$Basics$identity;
var $author$project$AntiSpiderProtectedApi$buildMutation = F2(
	function (source, selection) {
		return {kt: selection, lu: source};
	});
var $author$project$Gql$Vianello$Union$QuotationFormResult$Fragments = F2(
	function (onQuotationFormOk, onQuotationFormErrors) {
		return {gF: onQuotationFormErrors, gG: onQuotationFormOk};
	});
var $author$project$Gql$Vianello$Union$QuotationFormResult$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'QuotationFormOk', selections____.gG),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'QuotationFormErrors', selections____.gF)
			]));
};
var $author$project$Quotation$Common$Api$Quote$FormErrorResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Api$Quote$QuotationErrors = F3(
	function (id, errorList, reason) {
		return {nc: errorList, nM: id, k6: reason};
	});
var $author$project$Gql$Vianello$Object$QuotationFormErrors$errors = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'errors',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Vianello$Object$QuotationFormErrors$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Quotation$Common$Api$Quote$InvalidData = 0;
var $author$project$Quotation$Common$Api$Quote$QuoteNotAvailable = 1;
var $author$project$Quotation$Common$Api$Quote$Selection$quotationErrorReasonMap = function (reason) {
	if (!reason) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Quotation$Common$Api$Quote$QuotationError = F2(
	function (field, message) {
		return {ns: field, of: message};
	});
var $author$project$Gql$Vianello$Object$QuotationFormError$field = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'field',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Vianello$Object$QuotationFormError$message = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'message', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Quotation$Common$Api$Quote$Selection$quotationFormErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuotationFormError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuotationFormError$field,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Quotation$Common$Api$Quote$QuotationError)));
var $author$project$Gql$Vianello$Enum$QuotationFormErrorsReason$InvalidData = 0;
var $author$project$Gql$Vianello$Enum$QuotationFormErrorsReason$QuoteNotAvailable = 1;
var $author$project$Gql$Vianello$Enum$QuotationFormErrorsReason$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'INVALID_DATA':
				return $elm$json$Json$Decode$succeed(0);
			case 'QUOTE_NOT_AVAILABLE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid QuotationFormErrorsReason type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Vianello$Object$QuotationFormErrors$reason = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.QuotationFormErrorsReason.QuotationFormErrorsReason', 'reason', _List_Nil, $author$project$Gql$Vianello$Enum$QuotationFormErrorsReason$decoder);
var $author$project$Quotation$Common$Api$Quote$Selection$quotationFormErrorsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Quotation$Common$Api$Quote$Selection$quotationErrorReasonMap, $author$project$Gql$Vianello$Object$QuotationFormErrors$reason),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuotationFormErrors$errors($author$project$Quotation$Common$Api$Quote$Selection$quotationFormErrorSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$QuotationFormErrors$id,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Quotation$Common$Api$Quote$QuotationErrors))));
var $author$project$Quotation$Common$Api$Quote$Selection$quotationFormResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Quotation$Common$Api$Quote$Selection$quotationFormErrorsSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Quotation$Common$Api$Quote$FormErrorResponse));
var $author$project$Quotation$Common$Api$Quote$FormSuccessResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Vianello$Object$QuotationFormOk$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'VianelloScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Vianello$Scalar$unwrapCodecs($author$project$VianelloScalarCodecs$codecs).t.m0);
var $author$project$Quotation$Common$Api$Quote$Selection$quotationFormResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuotationFormOk$id,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Quotation$Common$Api$Quote$FormSuccessResponse));
var $author$project$Quotation$Common$Api$Quote$Selection$quotationFormSelection = $author$project$Gql$Vianello$Union$QuotationFormResult$fragments(
	A2($author$project$Gql$Vianello$Union$QuotationFormResult$Fragments, $author$project$Quotation$Common$Api$Quote$Selection$quotationFormResponseOkSelection, $author$project$Quotation$Common$Api$Quote$Selection$quotationFormResponseErrorSelection));
var $author$project$Gql$Vianello$InputObject$encodeAgreements = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'privacy',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$bool(input____.cv))),
				_Utils_Tuple2(
				'marketing',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$bool(input____.j8))),
				_Utils_Tuple2(
				'profilation',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$bool(input____.cw))),
				_Utils_Tuple2(
				'thirdMarketing',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$bool(input____.cM)))
			]));
};
var $author$project$Gql$Vianello$InputObject$encodeInputAddress = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'streetAddress',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.hN))),
				_Utils_Tuple2(
				'streetNumber',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.pO))),
				_Utils_Tuple2(
				'city',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.my))),
				_Utils_Tuple2(
				'cityLandRegister',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.P, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'zipCode',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.qg))),
				_Utils_Tuple2(
				'staircase',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.hH, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'entrance',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.fl, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Vianello$Enum$Gender$toString = function (enum____) {
	if (!enum____) {
		return 'M';
	} else {
		return 'F';
	}
};
var $author$project$Gql$Vianello$InputObject$encodeQuotationFamilyForm = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)(input____.nM))),
				_Utils_Tuple2(
				'pastIncidents',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.al))),
				_Utils_Tuple2(
				'colivingAdults',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.ae))),
				_Utils_Tuple2(
				'colivingMinors',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.af))),
				_Utils_Tuple2(
				'firstName',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.nu))),
				_Utils_Tuple2(
				'lastName',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.n8))),
				_Utils_Tuple2(
				'gender',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$Gender$toString, input____.ny))),
				_Utils_Tuple2(
				'dateOfBirth',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.mK;
						},
						$author$project$VianelloScalarCodecs$codecs)(input____.r))),
				_Utils_Tuple2(
				'cityOfBirth',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.p))),
				_Utils_Tuple2(
				'cityOfBirthLandRegister',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.k, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'countryOfBirth',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.q))),
				_Utils_Tuple2(
				'email',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.fi))),
				_Utils_Tuple2(
				'phoneMobile',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.F))),
				_Utils_Tuple2(
				'customerAddress',
				$elm$core$Maybe$Just(
					$author$project$Gql$Vianello$InputObject$encodeInputAddress(input____.W))),
				_Utils_Tuple2(
				'agreements',
				$elm$core$Maybe$Just(
					$author$project$Gql$Vianello$InputObject$encodeAgreements(input____.B))),
				_Utils_Tuple2(
				'originalQuoteId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.U,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))),
				_Utils_Tuple2(
				'originalSaveId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)))
			]));
};
var $author$project$Gql$Vianello$Mutation$registerFamilyPolicyData = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'registerFamilyPolicyData',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'formData', requiredArgs____.dv, $author$project$Gql$Vianello$InputObject$encodeQuotationFamilyForm)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$AntiSpiderProtectedApi$QuotationMutationResult = $elm$core$Basics$identity;
var $author$project$AntiSpiderProtectedApi$toQuotationResult = $elm$core$Basics$identity;
var $author$project$Quotation$Common$Api$Quote$Selection$familyQuotationSelection = function (quotationFormData) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$AntiSpiderProtectedApi$toQuotationResult,
		A2(
			$author$project$Gql$Vianello$Mutation$registerFamilyPolicyData,
			{dv: quotationFormData},
			$author$project$Quotation$Common$Api$Quote$Selection$quotationFormSelection));
};
var $author$project$Gql$Vianello$InputObject$QuotationFamilyForm = function (id) {
	return function (pastIncidents) {
		return function (colivingAdults) {
			return function (colivingMinors) {
				return function (firstName) {
					return function (lastName) {
						return function (gender) {
							return function (dateOfBirth) {
								return function (cityOfBirth) {
									return function (cityOfBirthLandRegister) {
										return function (countryOfBirth) {
											return function (email) {
												return function (phoneMobile) {
													return function (customerAddress) {
														return function (agreements) {
															return function (originalQuoteId) {
																return function (originalSaveId) {
																	return {B: agreements, p: cityOfBirth, k: cityOfBirthLandRegister, ae: colivingAdults, af: colivingMinors, q: countryOfBirth, W: customerAddress, r: dateOfBirth, fi: email, nu: firstName, ny: gender, nM: id, n8: lastName, U: originalQuoteId, w: originalSaveId, al: pastIncidents, F: phoneMobile};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Quotation$Common$Fields$Model$adultsTypeToIntMapper = function (adults) {
	switch (adults) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Gql$Vianello$InputObject$Agreements = F4(
	function (privacy, marketing, profilation, thirdMarketing) {
		return {j8: marketing, cv: privacy, cw: profilation, cM: thirdMarketing};
	});
var $author$project$Quotation$Common$Fields$StepResult$pickIsMarketingChecked = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$StepResult$pickPrivacyField,
	$elm$core$List$member(1));
var $author$project$Quotation$Common$Fields$StepResult$pickIsPrivacyChecked = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$StepResult$pickPrivacyField,
	$elm$core$List$member(0));
var $author$project$Quotation$Common$Api$Mapper$agreementsMapper = function (stepResult) {
	return A4(
		$author$project$Gql$Vianello$InputObject$Agreements,
		$author$project$Quotation$Common$Fields$StepResult$pickIsPrivacyChecked(stepResult),
		$author$project$Quotation$Common$Fields$StepResult$pickIsMarketingChecked(stepResult),
		false,
		false);
};
var $author$project$Quotation$Common$Fields$Model$childrenTypeToIntMapper = function (children) {
	switch (children) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Quotation$Common$Api$Mapper$genderMapper = function (stepResult) {
	var _v0 = $author$project$Quotation$Common$Fields$StepResult$pickGenderField(stepResult);
	if (!_v0) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Api$Helper$maybeToOptional = function (maybeValue) {
	return $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(maybeValue);
};
var $author$project$Quotation$Common$Types$pickBirthCityLandRegister = function (birthCity) {
	if (!birthCity.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var city = birthCity.a;
		return $elm$core$Maybe$Just(city.S);
	}
};
var $author$project$Quotation$Common$Types$pickBirthCityName = function (birthCity) {
	if (!birthCity.$) {
		return 'Stato Estero';
	} else {
		var city = birthCity.a;
		return city.oq;
	}
};
var $author$project$Gql$Vianello$InputObject$InputAddress = F7(
	function (streetAddress, streetNumber, city, cityLandRegister, zipCode, staircase, entrance) {
		return {my: city, P: cityLandRegister, fl: entrance, hH: staircase, hN: streetAddress, pO: streetNumber, qg: zipCode};
	});
var $author$project$Quotation$Common$Api$Mapper$placeToAddressMapper = function (_v0) {
	var address = _v0.bP;
	var addressNumber = _v0.l8;
	var city = _v0.my;
	var zipCode = _v0.qg;
	return A7(
		$author$project$Gql$Vianello$InputObject$InputAddress,
		A2($elm$core$Maybe$withDefault, '', address),
		A2($elm$core$Maybe$withDefault, '', addressNumber),
		A2($elm$core$Maybe$withDefault, '', city),
		$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
		A2($elm$core$Maybe$withDefault, '', zipCode),
		$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
		$dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent);
};
var $author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToIntMapper = function (claims) {
	switch (claims) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Quotation$Family$Api$Quote$formDataToQuotationForm = F4(
	function (uuid, quoteId, saveId, formData) {
		return $author$project$Gql$Vianello$InputObject$QuotationFamilyForm(uuid)(
			$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickReportedClaimsField(formData.fG)))(
			$author$project$Quotation$Common$Fields$Model$adultsTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickAdultsField(formData.fG)))(
			$author$project$Quotation$Common$Fields$Model$childrenTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickChildrenField(formData.fG)))(
			$author$project$Quotation$Common$Fields$StepResult$pickNameField(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickSurnameField(formData.X))(
			$author$project$Quotation$Common$Api$Mapper$genderMapper(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickBirthDayField(formData.X))(
			$author$project$Quotation$Common$Types$pickBirthCityName(
				$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(formData.X)))(
			$author$project$Api$Helper$maybeToOptional(
				$author$project$Quotation$Common$Types$pickBirthCityLandRegister(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(formData.X))))(
			$author$project$Quotation$Common$Types$pickBirthCountryName(
				$author$project$Quotation$Common$Fields$StepResult$pickBirthCountryField(formData.X)))(
			$author$project$Quotation$Common$Fields$StepResult$pickEmailField(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickPhoneField(formData.X))(
			$author$project$Quotation$Common$Api$Mapper$placeToAddressMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickResidentialAddressField(formData.X)))(
			$author$project$Quotation$Common$Api$Mapper$agreementsMapper(formData.X))(
			$author$project$Api$Helper$maybeToOptional(quoteId))(
			$author$project$Api$Helper$maybeToOptional(saveId));
	});
var $author$project$AntiSpiderProtectedApi$QuotationSource = $elm$core$Basics$identity;
var $author$project$AntiSpiderProtectedApi$fromQuotationSource = $elm$core$Basics$identity;
var $author$project$Quotation$Family$Api$Quote$quotationMutation = F4(
	function (formData, newQuoteUuid, originalQuoteUuid, originalSaveUuid) {
		var quotationForm = A4($author$project$Quotation$Family$Api$Quote$formDataToQuotationForm, newQuoteUuid, originalQuoteUuid, originalSaveUuid, formData);
		return A2(
			$author$project$AntiSpiderProtectedApi$buildMutation,
			$author$project$AntiSpiderProtectedApi$fromQuotationSource(
				A2(
					$elm$core$Basics$composeR,
					$author$project$Quotation$Family$CustomerStep$Model$GotQuote,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Family$Model$CustomerStepMsg, $author$project$Quotation$Model$FamilyMsg))),
			$author$project$Quotation$Common$Api$Quote$Selection$familyQuotationSelection(quotationForm));
	});
var $author$project$Quotation$Common$StepModel$setCurrentSeed = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{iI: value});
	});
var $author$project$Quotation$Common$Fields$StepResult$setEmailField = F2(
	function (fieldValue, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{i$: fieldValue});
	});
var $author$project$Quotation$Family$FormData$mappedCustomerResult = F2(
	function (state, customerStepResult) {
		var _v0 = $author$project$Quotation$Common$StepModel$pickUser(state);
		if (!_v0.$) {
			var email = _v0.a.fi;
			var admin = _v0.a.ma;
			return admin ? A2($author$project$Quotation$Common$Fields$StepResult$setEmailField, email, customerStepResult) : customerStepResult;
		} else {
			return customerStepResult;
		}
	});
var $author$project$Quotation$Family$FormData$toFormData = F2(
	function (state, customerStepResult) {
		return {
			X: A2($author$project$Quotation$Family$FormData$mappedCustomerResult, state, customerStepResult),
			fG: $author$project$Quotation$Common$StepModel$pickFamilyInfoStepResult(state)
		};
	});
var $danyx23$elm_uuid$Uuid$Barebones$hexGenerator = A2($elm$random$Random$int, 0, 15);
var $elm$random$Random$listHelp = F4(
	function (revList, n, gen, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(revList, seed);
			} else {
				var _v0 = gen(seed);
				var value = _v0.a;
				var newSeed = _v0.b;
				var $temp$revList = A2($elm$core$List$cons, value, revList),
					$temp$n = n - 1,
					$temp$gen = gen,
					$temp$seed = newSeed;
				revList = $temp$revList;
				n = $temp$n;
				gen = $temp$gen;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var $elm$random$Random$list = F2(
	function (n, _v0) {
		var gen = _v0;
		return function (seed) {
			return A4($elm$random$Random$listHelp, _List_Nil, n, gen, seed);
		};
	});
var $danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB = function (digit) {
	return (digit & 3) | 8;
};
var $danyx23$elm_uuid$Uuid$Barebones$hexDigits = function () {
	var mapChars = F2(
		function (offset, digit) {
			return $elm$core$Char$fromCode(digit + offset);
		});
	return $elm$core$Array$fromList(
		_Utils_ap(
			A2(
				$elm$core$List$map,
				mapChars(48),
				A2($elm$core$List$range, 0, 9)),
			A2(
				$elm$core$List$map,
				mapChars(97),
				A2($elm$core$List$range, 0, 5))));
}();
var $danyx23$elm_uuid$Uuid$Barebones$mapToHex = function (index) {
	var maybeResult = A2($elm$core$Array$get, index, $danyx23$elm_uuid$Uuid$Barebones$hexDigits);
	if (maybeResult.$ === 1) {
		return 'x';
	} else {
		var result = maybeResult.a;
		return result;
	}
};
var $danyx23$elm_uuid$Uuid$Barebones$toUuidString = function (thirtyOneHexDigits) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2($elm$core$List$take, 8, thirtyOneHexDigits))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						4,
						A2($elm$core$List$drop, 8, thirtyOneHexDigits)))),
				'-',
				'4',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 12, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$map,
						$danyx23$elm_uuid$Uuid$Barebones$limitDigitRange8ToB,
						A2(
							$elm$core$List$take,
							1,
							A2($elm$core$List$drop, 15, thirtyOneHexDigits))))),
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						3,
						A2($elm$core$List$drop, 16, thirtyOneHexDigits)))),
				'-',
				$elm$core$String$fromList(
				A2(
					$elm$core$List$map,
					$danyx23$elm_uuid$Uuid$Barebones$mapToHex,
					A2(
						$elm$core$List$take,
						12,
						A2($elm$core$List$drop, 19, thirtyOneHexDigits))))
			]));
};
var $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator = A2(
	$elm$random$Random$map,
	$danyx23$elm_uuid$Uuid$Barebones$toUuidString,
	A2($elm$random$Random$list, 31, $danyx23$elm_uuid$Uuid$Barebones$hexGenerator));
var $danyx23$elm_uuid$Uuid$uuidGenerator = A2($elm$random$Random$map, $elm$core$Basics$identity, $danyx23$elm_uuid$Uuid$Barebones$uuidStringGenerator);
var $author$project$Utils$UpdateReturn$addAntiSpiderProtectedQuery = F2(
	function (operation, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				c$: A2($elm$core$List$cons, operation, data.c$)
			});
	});
var $author$project$Utils$UpdateReturn$withAntiSpiderProtectedQuery = F2(
	function (query, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var ur = _v0.c;
		return _Utils_Tuple3(
			model,
			cmd,
			A2($author$project$Utils$UpdateReturn$addAntiSpiderProtectedQuery, query, ur));
	});
var $author$project$Quotation$Family$CustomerStep$Update$submitForm = F2(
	function (customerStepResult, model) {
		var formData = A2($author$project$Quotation$Family$FormData$toFormData, model, customerStepResult);
		var _v0 = A2(
			$elm$random$Random$step,
			$danyx23$elm_uuid$Uuid$uuidGenerator,
			$author$project$Quotation$Common$StepModel$pickCurrentSeed(model));
		var newUuid = _v0.a;
		var newSeed = _v0.b;
		return A2(
			$author$project$Utils$UpdateReturn$withAntiSpiderProtectedQuery,
			A4($author$project$Quotation$Family$Api$Quote$quotationMutation, formData, newUuid, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
			$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$send(
							$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
								{bb: $author$project$Quotation$Common$Analytics$proceedBtnParameter, ay: $author$project$Quotation$Common$Analytics$proceedBtnParameter, n3: true}))
						]),
					A2(
						$author$project$Quotation$Common$StepModel$setCurrentSeed,
						newSeed,
						A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, $author$project$RFQDTS$Http$Authenticator$AuthResponse$Loading, model)))));
	});
var $author$project$Quotation$Family$CustomerStep$Update$proceedFromCustomerStep = function (model) {
	var _v0 = $author$project$Quotation$Family$CustomerStep$Model$getStepResult(model);
	if (!_v0.$) {
		var customerStepResult = _v0.a;
		return A2($author$project$Quotation$Family$CustomerStep$Update$submitForm, customerStepResult, model);
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				A2($author$project$Quotation$Common$StepModel$setIsFormSubmitted, true, model)));
	}
};
var $author$project$Components$DebouncedAutocomplete$refillFilter = function (model) {
	var _v0 = $author$project$Components$DebouncedAutocomplete$getValue(model);
	if (!_v0.$) {
		var value = _v0.a;
		return A2($author$project$Components$DebouncedAutocomplete$setValue, value, model);
	} else {
		return model;
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$refillCityAutocomplete = $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
	$author$project$Quotation$Common$Components$AddressFallback$mapCity($author$project$Components$DebouncedAutocomplete$refillFilter));
var $author$project$Quotation$Common$Components$AddressFallback$refillAutocompleteFilter = function (model) {
	var _v0 = $author$project$Pyxis$Components$Field$Autocomplete$getValue(model);
	if (!_v0.$) {
		var value = _v0.a;
		return A2($author$project$Pyxis$Components$Field$Autocomplete$setValue, value, model);
	} else {
		return model;
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$refillZipAutocomplete = $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
	$author$project$Quotation$Common$Components$AddressFallback$mapZipCode($author$project$Quotation$Common$Components$AddressFallback$refillAutocompleteFilter));
var $author$project$Quotation$Common$Components$AddressFallback$refillAllAutocompleteFilter = function (model) {
	return $author$project$Quotation$Common$Components$AddressFallback$refillZipAutocomplete(
		$author$project$Quotation$Common$Components$AddressFallback$refillCityAutocomplete(model));
};
var $author$project$Quotation$Common$Fields$Model$refillBirthCityFilter = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setBirthCity,
		$author$project$Components$DebouncedAutocomplete$refillFilter(
			$author$project$Quotation$Common$Fields$Model$pickBirthCityField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$refillBirthCountryFilter = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setBirthCountry,
		$author$project$Components$DebouncedAutocomplete$refillFilter(
			$author$project$Quotation$Common$Fields$Model$pickBirthCountryField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$refillResidentialAddressFilter = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setResidentialAddress,
		$author$project$Components$DebouncedAutocomplete$refillFilter(
			$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(formData)),
		formData);
};
var $author$project$Quotation$Family$CustomerStep$Model$refillAllAutocompleteFilter = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$residentialAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$refillAllAutocompleteFilter),
		A2(
			$author$project$Quotation$Common$StepModel$mapFormData,
			$author$project$Quotation$Common$Fields$Model$refillResidentialAddressFilter,
			A2(
				$author$project$Quotation$Common$StepModel$mapFormData,
				$author$project$Quotation$Common$Fields$Model$refillBirthCountryFilter,
				A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$refillBirthCityFilter, model))));
};
var $author$project$Quotation$Common$Fields$Model$resetBirthCountryField = F2(
	function (onFieldChangedMsgMapper, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$Fields$Model$setBirthCountry(
			A3($author$project$Quotation$Common$Fields$Model$initBirthCountryField, onFieldChangedMsgMapper, fieldChangedMsgMapper, $author$project$Quotation$Common$Fields$Model$EmptyField));
	});
var $author$project$Quotation$Common$Fields$Model$tmpResponse = $krisajenkins$remotedata$RemoteData$mapError(
	$elm$core$Basics$always($elm$http$Http$Timeout));
var $author$project$Quotation$Common$Components$AddressFallback$updateCitiesOptions = function (cities) {
	return $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
		$author$project$Quotation$Common$Components$AddressFallback$mapCity(
			$author$project$Components$DebouncedAutocomplete$setOptions(cities)));
};
var $author$project$Quotation$Common$Fields$Model$residentialAddressCitySuggestionsMapper = F2(
	function (response, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback,
			A2(
				$author$project$Quotation$Common$Components$AddressFallback$updateCitiesOptions,
				$author$project$Quotation$Common$Fields$Model$tmpResponse(response),
				$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Analytics$residentialAddressParameter = 'residential_complete_address_autocomplete';
var $author$project$Quotation$Common$Fields$Model$residentialAddressSuggestionsMapper = F2(
	function (response, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setResidentialAddress,
			A2(
				$author$project$Components$DebouncedAutocomplete$setOptions,
				$author$project$Quotation$Common$Fields$Model$tmpResponse(response),
				$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Fields$Model$selectForeignCity = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setBirthCity,
		A2(
			$author$project$Components$DebouncedAutocomplete$setValue,
			$author$project$Quotation$Common$Types$ForeignCity,
			$author$project$Quotation$Common$Fields$Model$pickBirthCityField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$setBirthday = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{ik: fieldValue});
	});
var $author$project$Pyxis$Components$Field$DateInput$setOnBlur = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				f6: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Quotation$Common$Fields$Model$setBirthdayOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$DateInput$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickBirthdayField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setBirthday, mappedFieldValue, formData);
	});
var $author$project$Quotation$Common$StepModel$setCurrentDate = F2(
	function (currentDate, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{iH: currentDate});
	});
var $author$project$Quotation$Common$Fields$Model$setEmail = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{i$: fieldValue});
	});
var $author$project$Pyxis$Components$Field$Input$setOnBlur = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				f6: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Quotation$Common$Fields$Model$setEmailOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$Input$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickEmailField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setEmail, mappedFieldValue, formData);
	});
var $author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{jK: value});
	});
var $author$project$Quotation$Common$StepModel$setIsViewInitialized = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{jZ: value});
	});
var $author$project$Quotation$Common$StepModel$setModalState = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{kc: value});
	});
var $author$project$Quotation$Common$Fields$Model$setName = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{ki: fieldValue});
	});
var $author$project$Quotation$Common$Fields$Model$setNameOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$Input$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickNameField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setName, mappedFieldValue, formData);
	});
var $author$project$Quotation$Common$Fields$Model$setPhone = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{kE: fieldValue});
	});
var $author$project$Quotation$Common$Fields$Model$setPhoneOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$Input$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickPhoneField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setPhone, mappedFieldValue, formData);
	});
var $author$project$Quotation$Common$Fields$Model$setSurname = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{lD: fieldValue});
	});
var $author$project$Quotation$Common$Fields$Model$setSurnameOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$Input$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickSurnameField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setSurname, mappedFieldValue, formData);
	});
var $author$project$Quotation$Common$Analytics$surnameAnalyticsParameter = 'last_name';
var $author$project$Components$DebouncedAutocomplete$DebouncerMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$DebouncedAutocomplete$DelayedFire = {$: 5};
var $author$project$Components$DebouncedAutocomplete$Submit = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$DebouncedAutocomplete$conditionalOnSelectCmd = function (_v0) {
	var modelData = _v0;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2($elm$core$Maybe$map, $author$project$Utils$send, modelData.gX));
};
var $author$project$Pyxis$Components$Field$Autocomplete$getFilteringValue = function (_v0) {
	var filteringValue = _v0.H;
	return filteringValue;
};
var $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput = function (a) {
	return {$: 0, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$provideInput = $Gizra$elm_debouncer$Debouncer$Basic$ProvideInput;
var $Gizra$elm_debouncer$Debouncer$Internal$Check = function (a) {
	return {$: 4, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt = function (a) {
	return {$: 3, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal = function (a) {
	return {$: 2, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$Unsettled = function (a) {
	return {$: 1, a: a};
};
var $Gizra$elm_debouncer$Debouncer$Internal$cancel = function (_v0) {
	var config = _v0.a;
	var state = _v0.b;
	return A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, config, $Gizra$elm_debouncer$Debouncer$Internal$Settled);
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $Gizra$elm_debouncer$Debouncer$Internal$update = F2(
	function (msg, debouncer) {
		var wrappedConfig = debouncer.a;
		var config = wrappedConfig;
		var state = debouncer.b;
		switch (msg.$) {
			case 0:
				var input = msg.a;
				var time = msg.b;
				var newState = function () {
					if (!state.$) {
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							{
								bu: $elm$core$Maybe$Nothing,
								dU: time,
								aa: A2(config.aL, input, $elm$core$Maybe$Nothing),
								eJ: time
							});
					} else {
						var unsettled = state.a;
						return $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									dU: time,
									aa: A2(config.aL, input, unsettled.aa)
								}));
					}
				}();
				var newDebouncer = A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState);
				var checks = function () {
					if (!state.$) {
						return $elm_community$list_extra$List$Extra$unique(
							A2(
								$elm$core$List$filterMap,
								$elm$core$Basics$identity,
								_List_fromArray(
									[config.ag, config.ah, config.am])));
					} else {
						return A2(
							$elm$core$List$filterMap,
							$elm$core$Basics$identity,
							_List_fromArray(
								[config.am]));
					}
				}();
				var _v1 = A2(
					$elm$core$Tuple$mapFirst,
					A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty),
					A2(
						$elm$core$List$partition,
						function (interval) {
							return interval <= 0;
						},
						checks));
				var checkNow = _v1.a;
				var checkLater = _v1.b;
				var _v2 = checkNow ? A2(
					$Gizra$elm_debouncer$Debouncer$Internal$update,
					$Gizra$elm_debouncer$Debouncer$Internal$Check(time),
					newDebouncer) : _Utils_Tuple3(newDebouncer, _List_Nil, $elm$core$Maybe$Nothing);
				var checkedDebouncer = _v2.a;
				var checkedIntervals = _v2.b;
				var emit = _v2.c;
				return _Utils_Tuple3(
					checkedDebouncer,
					_Utils_ap(checkedIntervals, checkLater),
					emit);
			case 1:
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					$elm$core$Maybe$Nothing);
			case 2:
				var emit = function () {
					if (!state.$) {
						return $elm$core$Maybe$Nothing;
					} else {
						var unsettled = state.a;
						return unsettled.aa;
					}
				}();
				return _Utils_Tuple3(
					$Gizra$elm_debouncer$Debouncer$Internal$cancel(debouncer),
					_List_Nil,
					emit);
			case 3:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var _v7 = unsettled.aa;
					if (!_v7.$) {
						var newState = $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
							_Utils_update(
								unsettled,
								{
									bu: $elm$core$Maybe$Just(time),
									aa: $elm$core$Maybe$Nothing
								}));
						var intervals = function () {
							var _v8 = config.ag;
							if (!_v8.$) {
								var emit = _v8.a;
								return _List_fromArray(
									[emit]);
							} else {
								return _List_Nil;
							}
						}();
						return _Utils_Tuple3(
							A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
							intervals,
							unsettled.aa);
					} else {
						return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
					}
				}
			default:
				var time = msg.a;
				if (!state.$) {
					return _Utils_Tuple3(debouncer, _List_Nil, $elm$core$Maybe$Nothing);
				} else {
					var unsettled = state.a;
					var shouldSettle = A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							function (interval) {
								return _Utils_cmp(unsettled.dU + interval, time) < 1;
							},
							config.am));
					var becauseEmitWhileUnsettled = function () {
						var _v13 = config.ah;
						if (!_v13.$) {
							var interval = _v13.a;
							var _v14 = unsettled.bu;
							if (!_v14.$) {
								var lastEmittedAt = _v14.a;
								return _Utils_cmp(lastEmittedAt + interval, time) < 1;
							} else {
								return _Utils_cmp(unsettled.eJ + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var becauseEmitWhenUnsettled = function () {
						var _v11 = config.ag;
						if (!_v11.$) {
							var interval = _v11.a;
							var _v12 = unsettled.bu;
							if (!_v12.$) {
								return false;
							} else {
								return _Utils_cmp(unsettled.eJ + interval, time) < 1;
							}
						} else {
							return false;
						}
					}();
					var shouldEmit = (!_Utils_eq(unsettled.aa, $elm$core$Maybe$Nothing)) && (shouldSettle || (becauseEmitWhenUnsettled || becauseEmitWhileUnsettled));
					var emit = shouldEmit ? unsettled.aa : $elm$core$Maybe$Nothing;
					var intervals = function () {
						if (shouldEmit && (!shouldSettle)) {
							var _v10 = config.ah;
							if (!_v10.$) {
								var interval = _v10.a;
								return _List_fromArray(
									[interval]);
							} else {
								return _List_Nil;
							}
						} else {
							return _List_Nil;
						}
					}();
					var newState = shouldSettle ? $Gizra$elm_debouncer$Debouncer$Internal$Settled : (shouldEmit ? $Gizra$elm_debouncer$Debouncer$Internal$Unsettled(
						_Utils_update(
							unsettled,
							{
								bu: $elm$core$Maybe$Just(time),
								aa: $elm$core$Maybe$Nothing
							})) : state);
					return _Utils_Tuple3(
						A2($Gizra$elm_debouncer$Debouncer$Internal$Debouncer, wrappedConfig, newState),
						intervals,
						emit);
				}
		}
	});
var $Gizra$elm_debouncer$Debouncer$Basic$update = F2(
	function (msg, debouncer) {
		switch (msg.$) {
			case 0:
				var input = msg.a;
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2(
								$elm$core$Basics$composeL,
								$Gizra$elm_debouncer$Debouncer$Basic$MsgInternal,
								$Gizra$elm_debouncer$Debouncer$Internal$InputProvidedAt(input)),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			case 1:
				return _Utils_Tuple3(
					debouncer,
					A2(
						$elm$core$Task$perform,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$ManualEmitAt),
							$elm$time$Time$posixToMillis),
						$elm$time$Time$now),
					$elm$core$Maybe$Nothing);
			default:
				var subMsg = msg.a;
				var _v1 = A2($Gizra$elm_debouncer$Debouncer$Internal$update, subMsg, debouncer);
				var updatedDebouncer = _v1.a;
				var intervals = _v1.b;
				var output = _v1.c;
				var cmds = $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						function (interval) {
							return A2(
								$elm$core$Task$perform,
								A2(
									$elm$core$Basics$composeL,
									A2($elm$core$Basics$composeL, $Gizra$elm_debouncer$Debouncer$Basic$MsgInternal, $Gizra$elm_debouncer$Debouncer$Internal$Check),
									$elm$time$Time$posixToMillis),
								A2(
									$elm$core$Task$andThen,
									$elm$core$Basics$always($elm$time$Time$now),
									$elm$core$Process$sleep(interval)));
						},
						intervals));
				return _Utils_Tuple3(updatedDebouncer, cmds, output);
		}
	});
var $author$project$Pyxis$Commons$Events$Keyboard$isEnter = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(13);
var $author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				dq: mapper(modelData.dq)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange = F2(
	function (_v0, _v1) {
		var oldModelData = _v0;
		var newModelData = _v1;
		return (!_Utils_eq(oldModelData.G, newModelData.G)) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (_v2, onHoveredOptionChange) {
						var id = _v2.nM;
						return $author$project$Pyxis$Commons$Commands$dispatch(
							onHoveredOptionChange(id));
					}),
				newModelData.G,
				newModelData.gn)) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setHasFocus = F2(
	function (hasFocus, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{dA: hasFocus});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen = F2(
	function (isOpen, _v0) {
		var modelData = _v0;
		return A4(
			$author$project$Pyxis$Commons$Basics$ternaryOperator,
			isOpen,
			$author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption,
			$elm$core$Basics$identity,
			_Utils_update(
				modelData,
				{bs: isOpen}));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$handleOnSelect = function (value) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue(value),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed,
				$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus(
					$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true)))));
};
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(40);
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowUp = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(38);
var $author$project$Pyxis$Commons$Events$Keyboard$isEsc = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(27);
var $elm$core$Array$toIndexedList = function (array) {
	var len = array.a;
	var helper = F2(
		function (entry, _v0) {
			var index = _v0.a;
			var list = _v0.b;
			return _Utils_Tuple2(
				index - 1,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(index, entry),
					list));
		});
	return A3(
		$elm$core$Array$foldr,
		helper,
		_Utils_Tuple2(len - 1, _List_Nil),
		array).b;
};
var $author$project$Pyxis$Components$Field$Autocomplete$getActiveOptionWithIndex = function (model) {
	var modelData = model;
	var findOption = function (_v0) {
		var value = _v0.b.p8;
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						$elm$core$Basics$eq(value))),
				modelData.G));
	};
	return A2(
		$elm_community$list_extra$List$Extra$find,
		findOption,
		$elm$core$Array$toIndexedList(
			$elm$core$Array$fromList(
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model))));
};
var $author$project$Pyxis$Components$Field$Autocomplete$getValuesLength = function (_v0) {
	var modelData = _v0;
	return $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.n6;
					},
					modelData.bi(modelData.H))),
			A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, modelData.hZ)));
};
var $author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption = F2(
	function (moveByPositions, model) {
		var modelData = model;
		var newActiveOptionIndex = moveByPositions + A2(
			$elm$core$Maybe$withDefault,
			-1,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$first,
				$author$project$Pyxis$Components$Field$Autocomplete$getActiveOptionWithIndex(model)));
		return _Utils_update(
			modelData,
			{
				G: ((newActiveOptionIndex < 0) || (_Utils_cmp(
					newActiveOptionIndex,
					$author$project$Pyxis$Components$Field$Autocomplete$getValuesLength(model)) > -1)) ? modelData.G : A2(
					$elm$core$Array$get,
					newActiveOptionIndex,
					$elm$core$Array$fromList(
						$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)))
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$updateOnKeyEvent = F2(
	function (event, model) {
		var modelData = model;
		return $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown(event) ? A2($author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption, 1, model) : ($author$project$Pyxis$Commons$Events$Keyboard$isArrowUp(event) ? A2($author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption, -1, model) : ($author$project$Pyxis$Commons$Events$Keyboard$isEsc(event) ? $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed(model) : ($author$project$Pyxis$Commons$Events$Keyboard$isEnter(event) ? A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						A2($primait$prima_elm_extra$PrimaFunction$flip, $author$project$Pyxis$Components$Field$Autocomplete$handleOnSelect, model))),
				modelData.G)) : model)));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 0:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
						false,
						A2($author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen, false, model)));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
						true,
						A2($author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen, true, model)));
			case 3:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$elm$core$Basics$always(
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.go)),
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
							true,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen,
								$krisajenkins$remotedata$RemoteData$isSuccess(modelData.hZ),
								_Utils_update(
									modelData,
									{H: value, bt: true})))));
			case 4:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(false),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
							$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(false),
							_Utils_update(
								modelData,
								{G: $elm$core$Maybe$Nothing, H: '', bt: false, p8: $elm$core$Maybe$Nothing}))));
			case 5:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d9)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A2($author$project$Pyxis$Components$Field$Autocomplete$setValue, value, model)));
			case 6:
				var event = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$elm$core$Basics$always(
							A3(
								$author$project$Pyxis$Commons$Basics$ternaryOperator,
								$author$project$Pyxis$Commons$Events$Keyboard$isEnter(event) && (!_Utils_eq(modelData.G, $elm$core$Maybe$Nothing)),
								$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d9),
								$elm$core$Platform$Cmd$none)),
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2($author$project$Pyxis$Components$Field$Autocomplete$updateOnKeyEvent, event, model));
			default:
				var option_ = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
						$elm$core$Maybe$Just(option_),
						model));
		}
	});
var $author$project$Components$DebouncedAutocomplete$update = F3(
	function (msg, messageMapper, model) {
		update:
		while (true) {
			var state = model;
			switch (msg.$) {
				case 0:
					var subMsg = msg.a;
					var _v1 = A2($Gizra$elm_debouncer$Debouncer$Basic$update, subMsg, state.fa);
					var subModel = _v1.a;
					var subCmd = _v1.b;
					var emittedMsg = _v1.c;
					var mappedCmd = A2(
						$elm$core$Platform$Cmd$map,
						messageMapper,
						A2($elm$core$Platform$Cmd$map, $author$project$Components$DebouncedAutocomplete$DebouncerMsg, subCmd));
					var updatedModel = _Utils_update(
						state,
						{fa: subModel});
					if (!emittedMsg.$) {
						var emitted = emittedMsg.a;
						return A2(
							$elm$core$Tuple$mapSecond,
							function (cmd) {
								return $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[cmd, mappedCmd]));
							},
							A3($author$project$Components$DebouncedAutocomplete$update, emitted, messageMapper, updatedModel));
					} else {
						return _Utils_Tuple2(updatedModel, mappedCmd);
					}
				case 1:
					var subMsg = msg.a;
					var _v3 = A2($author$project$Pyxis$Components$Field$Autocomplete$update, subMsg, state.L);
					var autocompleteModel = _v3.a;
					var cmd = _v3.b;
					var updatedModel = _Utils_update(
						state,
						{L: autocompleteModel});
					return _Utils_Tuple2(updatedModel, cmd);
				case 2:
					var $temp$msg = $author$project$Components$DebouncedAutocomplete$DebouncerMsg(
						$Gizra$elm_debouncer$Debouncer$Basic$provideInput($author$project$Components$DebouncedAutocomplete$DelayedFire)),
						$temp$messageMapper = messageMapper,
						$temp$model = model;
					msg = $temp$msg;
					messageMapper = $temp$messageMapper;
					model = $temp$model;
					continue update;
				case 3:
					return A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Components$DebouncedAutocomplete$conditionalOnSelectCmd(model)
							]),
						model);
				case 4:
					return $author$project$Utils$withoutCommands(model);
				case 5:
					return A2(
						$elm$core$Tuple$mapSecond,
						$elm$core$Platform$Cmd$map(messageMapper),
						A2(
							$elm$core$Tuple$pair,
							model,
							$author$project$Utils$send(
								$author$project$Components$DebouncedAutocomplete$Submit(
									$author$project$Pyxis$Components$Field$Autocomplete$getFilteringValue(state.L)))));
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Quotation$Common$Updaters$updateFieldWithCmd = F5(
	function (updater, picker, setter, fieldMsg, model) {
		return A2(
			$elm$core$Tuple$mapFirst,
			A2(
				$elm$core$Basics$composeR,
				setter,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$StepModel$mapFormData, model)),
			A2(
				updater,
				fieldMsg,
				picker(
					$author$project$Quotation$Common$StepModel$pickFormData(model))));
	});
var $author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldWithCmd = function (msgMapper) {
	return $author$project$Quotation$Common$Updaters$updateFieldWithCmd(
		A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Components$DebouncedAutocomplete$update, msgMapper));
};
var $author$project$Quotation$Common$Updaters$updateBirthCityWithCmd = function (msgMapper) {
	return A3($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldWithCmd, msgMapper, $author$project$Quotation$Common$Fields$Model$pickBirthCityField, $author$project$Quotation$Common$Fields$Model$setBirthCity);
};
var $author$project$Quotation$Common$Updaters$updateBirthCountryWithCmd = function (msgMapper) {
	return A3($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldWithCmd, msgMapper, $author$project$Quotation$Common$Fields$Model$pickBirthCountryField, $author$project$Quotation$Common$Fields$Model$setBirthCountry);
};
var $author$project$Pyxis$Components$Field$DateInput$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dq: mapper(model.dq)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					A2(
						$author$project$Pyxis$Components$Field$DateInput$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					model);
			default:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.go)
						]),
					A2(
						$author$project$Pyxis$Components$Field$DateInput$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{p8: value})));
		}
	});
var $author$project$Quotation$Common$Updaters$updateDateInputFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Pyxis$Components$Field$DateInput$update);
var $author$project$Quotation$Common$Updaters$updateBirthdayWithCmd = A2($author$project$Quotation$Common$Updaters$updateDateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickBirthdayField, $author$project$Quotation$Common$Fields$Model$setBirthday);
var $author$project$Quotation$Common$Fields$Model$setCommercialPurposes = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{iB: fieldValue});
	});
var $author$project$Quotation$Common$Updaters$updateCheckboxFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Pyxis$Components$Field$CheckboxGroup$update);
var $author$project$Quotation$Common$Updaters$updateCommercialPurposesWithCmd = A2($author$project$Quotation$Common$Updaters$updateCheckboxFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickCommercialPurposesField, $author$project$Quotation$Common$Fields$Model$setCommercialPurposes);
var $author$project$Pyxis$Components$Field$Input$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dq: mapper(model.dq)
			});
	});
var $author$project$Pyxis$Components$Field$Input$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Input$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					model);
			default:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.go)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Input$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{p8: value})));
		}
	});
var $author$project$Quotation$Common$Updaters$updateInputFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Pyxis$Components$Field$Input$update);
var $author$project$Quotation$Common$Updaters$updateEmailWithCmd = A2($author$project$Quotation$Common$Updaters$updateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickEmailField, $author$project$Quotation$Common$Fields$Model$setEmail);
var $author$project$Quotation$Common$Fields$Model$setGender = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{jl: fieldValue});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$mapFieldStatus = F2(
	function (f, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dq: f(model.dq)
			});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 0:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d4)
						]),
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{
								a2: $elm$core$Maybe$Just(value)
							})));
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			default:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					model);
		}
	});
var $author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Pyxis$Components$Field$RadioGroup$update);
var $author$project$Quotation$Common$Updaters$updateGenderWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickGenderField, $author$project$Quotation$Common$Fields$Model$setGender);
var $author$project$Quotation$Common$Updaters$updateNameWithCmd = A2($author$project$Quotation$Common$Updaters$updateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickNameField, $author$project$Quotation$Common$Fields$Model$setName);
var $author$project$Quotation$Common$Updaters$updatePhoneWithCmd = A2($author$project$Quotation$Common$Updaters$updateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPhoneField, $author$project$Quotation$Common$Fields$Model$setPhone);
var $author$project$Quotation$Common$Fields$Model$setPrivacyPolicy = F2(
	function (fieldValue, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{kT: fieldValue});
	});
var $author$project$Quotation$Common$Updaters$updatePrivacyPolicyWithCmd = A2($author$project$Quotation$Common$Updaters$updateCheckboxFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPrivacyPolicyField, $author$project$Quotation$Common$Fields$Model$setPrivacyPolicy);
var $author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Common$Components$AddressFallback$getCityAnalyticsParameter = function (addressType) {
	if (!addressType) {
		return 'manual_input_city';
	} else {
		return 'manual_input_residential_residential_city';
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$getStreetNameAnalyticsParameter = function (addressType) {
	if (!addressType) {
		return 'manual_input_address';
	} else {
		return 'manual_input_residential_address';
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$getStreetNumberAnalyticsParameter = function (addressType) {
	if (!addressType) {
		return 'manual_input_street_number';
	} else {
		return 'manual_input_residential_street_number';
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$getZipCodeAnalyticsParameter = function (addressType) {
	if (!addressType) {
		return 'manual_input_postal_code';
	} else {
		return 'manual_input_residential_postal_code';
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$pickFormData = function (_v0) {
	var formData = _v0.dv;
	return formData;
};
var $author$project$Quotation$Common$Components$AddressFallback$cityFieldValidation = $author$project$Quotation$Common$Validation$isJust;
var $author$project$Quotation$Common$Components$AddressFallback$pickCityField = function (_v0) {
	var cityField = _v0.bW;
	return cityField;
};
var $author$project$Quotation$Common$Components$AddressFallback$validatedCityField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickCityField,
	A2($elm$core$Basics$composeR, $author$project$Components$DebouncedAutocomplete$getValue, $author$project$Quotation$Common$Components$AddressFallback$cityFieldValidation));
var $author$project$Quotation$Common$Components$AddressFallback$isCityFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$validatedCityField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Components$AddressFallback$pickStreetNameField = function (_v0) {
	var streetNameField = _v0.cI;
	return streetNameField;
};
var $author$project$Quotation$Common$Components$AddressFallback$streetNameFieldValidation = $author$project$Quotation$Common$Validation$requiredString;
var $author$project$Quotation$Common$Components$AddressFallback$validatedStreetNameField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickStreetNameField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Components$AddressFallback$streetNameFieldValidation));
var $author$project$Quotation$Common$Components$AddressFallback$isStreetNameFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$validatedStreetNameField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Components$AddressFallback$pickStreetNumberField = function (_v0) {
	var streetNumberField = _v0.cJ;
	return streetNumberField;
};
var $author$project$Quotation$Common$Components$AddressFallback$streetNumberFieldValidation = $author$project$Quotation$Common$Validation$requiredString;
var $author$project$Quotation$Common$Components$AddressFallback$validatedStreetNumberField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickStreetNumberField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Components$AddressFallback$streetNumberFieldValidation));
var $author$project$Quotation$Common$Components$AddressFallback$isStreetNumberFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$validatedStreetNumberField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Components$AddressFallback$pickZipCodeField = function (_v0) {
	var zipCodeField = _v0.cT;
	return zipCodeField;
};
var $author$project$Quotation$Common$Components$AddressFallback$zipCodeFieldValidation = $author$project$Quotation$Common$Validation$isJust;
var $author$project$Quotation$Common$Components$AddressFallback$validatedZipCodeField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickZipCodeField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Autocomplete$getValue, $author$project$Quotation$Common$Components$AddressFallback$zipCodeFieldValidation));
var $author$project$Quotation$Common$Components$AddressFallback$isZipCodeFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Components$AddressFallback$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$validatedZipCodeField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Components$AddressFallback$pickAddressType = function (_v0) {
	var addressType = _v0.h7;
	return addressType;
};
var $author$project$Quotation$Common$Components$AddressFallback$toGTMEventTag = function (_v0) {
	var parameterName = _v0.oO;
	var parameterPrefix = _v0.kA;
	var parameterValue = _v0.oQ;
	var eventId = _v0.ay;
	return $author$project$Utils$GTM$composeEventTag(
		{
			ay: $author$project$Utils$GTM$eventIdWithPrefix(
				{ay: eventId, oV: parameterPrefix}),
			ne: $author$project$Utils$GTM$defaultEventName,
			oO: parameterName,
			oP: parameterPrefix,
			oQ: parameterValue
		});
};
var $author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg = function (msg) {
	return _Utils_eq(msg, $author$project$Components$DebouncedAutocomplete$AutocompleteOnSelect);
};
var $author$project$Quotation$Common$Components$AddressFallback$setCity = F2(
	function (fieldValue, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{bW: fieldValue});
	});
var $author$project$Quotation$Common$Components$AddressFallback$setZipCode = F2(
	function (fieldValue, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{cT: fieldValue});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateZipCodeForSelection = F2(
	function (_v0, _v1) {
		var modelData = _v0;
		var zipCodes = _v1.qh;
		var toZipCodeOption = function (zipCode) {
			return $author$project$Pyxis$Components$Field$Autocomplete$option(
				{n6: zipCode.h1, p8: zipCode});
		};
		var newZipCode = A2(
			$author$project$Pyxis$Components$Field$Autocomplete$setOptions,
			$krisajenkins$remotedata$RemoteData$Success(
				A2($elm$core$List$map, toZipCodeOption, zipCodes)),
			A2(
				$author$project$Quotation$Common$Components$AddressFallback$initZipCode,
				$elm$core$Maybe$Just(zipCodes),
				modelData.aD));
		var isVisible = $elm$core$List$length(zipCodes) > 1;
		return _Utils_update(
			modelData,
			{
				dv: A2($author$project$Quotation$Common$Components$AddressFallback$setZipCode, newZipCode, modelData.dv),
				fO: isVisible
			});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateZipCode = F2(
	function (msg, model) {
		var formData = model.dv;
		return $author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg(msg) ? A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				$author$project$Quotation$Common$Components$AddressFallback$updateZipCodeForSelection(model),
				$author$project$Components$DebouncedAutocomplete$getValue(
					$author$project$Quotation$Common$Components$AddressFallback$pickCityField(formData)))) : model;
	});
var $author$project$Quotation$Common$Components$AddressFallback$isZipCodeSelected = function (_v0) {
	var formData = _v0.dv;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(
		$author$project$Pyxis$Components$Field$Autocomplete$getValue(
			$author$project$Quotation$Common$Components$AddressFallback$pickZipCodeField(formData)));
};
var $author$project$Quotation$Common$Components$AddressFallback$pickMsgMapper = function (_v0) {
	var msgMapper = _v0.aD;
	return msgMapper;
};
var $author$project$Quotation$Common$Components$AddressFallback$withConditionalZipCodeCmd = function (modelCmdPair) {
	var model = modelCmdPair.a;
	return $author$project$Quotation$Common$Components$AddressFallback$isZipCodeSelected(model) ? A2(
		$author$project$Utils$addCommand,
		$author$project$Utils$send(
			A2(
				$author$project$Quotation$Common$Components$AddressFallback$pickMsgMapper,
				model,
				$author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent(
					{
						bb: $author$project$Quotation$Common$Components$AddressFallback$getZipCodeAnalyticsParameter(
							$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
						ay: $author$project$Quotation$Common$Components$AddressFallback$getZipCodeAnalyticsParameter(
							$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
						n3: $author$project$Quotation$Common$Components$AddressFallback$isZipCodeFieldValid(model)
					}))),
		modelCmdPair) : modelCmdPair;
};
var $author$project$Quotation$Common$Components$AddressFallback$updateCityWithCmd = F2(
	function (debouncedAutocompleteMsg, model) {
		var formData = model.dv;
		var msgMapper = model.aD;
		return A4(
			$author$project$Utils$ternaryOperator,
			$author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg(debouncedAutocompleteMsg),
			$author$project$Quotation$Common$Components$AddressFallback$withConditionalZipCodeCmd,
			$elm$core$Basics$identity,
			A2(
				$elm$core$Tuple$mapFirst,
				$author$project$Quotation$Common$Components$AddressFallback$updateZipCode(debouncedAutocompleteMsg),
				A2(
					$elm$core$Tuple$mapFirst,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Quotation$Common$Components$AddressFallback$setCity,
						A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$Components$AddressFallback$mapFormData, model)),
					A3(
						$author$project$Components$DebouncedAutocomplete$update,
						debouncedAutocompleteMsg,
						A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$CityChanged, msgMapper),
						$author$project$Quotation$Common$Components$AddressFallback$pickCityField(formData)))));
	});
var $author$project$Quotation$Common$Components$AddressFallback$mapStreetName = F2(
	function (mapper, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				cI: mapper(data.cI)
			});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateStreetNameAnalyticsMsg = function (msg) {
	return $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
		$author$project$Quotation$Common$Components$AddressFallback$mapStreetName(
			$author$project$Pyxis$Components$Field$Input$setOnBlur(msg)));
};
var $author$project$Quotation$Common$Components$AddressFallback$setStreetName = F2(
	function (fieldValue, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{cI: fieldValue});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateStreetNameWithCmd = F2(
	function (inputMsg, model) {
		var formData = model.dv;
		return A2(
			$elm$core$Tuple$mapFirst,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Common$Components$AddressFallback$setStreetName,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$Components$AddressFallback$mapFormData, model)),
			A2(
				$author$project$Pyxis$Components$Field$Input$update,
				inputMsg,
				$author$project$Quotation$Common$Components$AddressFallback$pickStreetNameField(formData)));
	});
var $author$project$Quotation$Common$Components$AddressFallback$mapStreetNumber = F2(
	function (mapper, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{
				cJ: mapper(data.cJ)
			});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateStreetNumberAnalyticsMsg = function (msg) {
	return $author$project$Quotation$Common$Components$AddressFallback$mapFormData(
		$author$project$Quotation$Common$Components$AddressFallback$mapStreetNumber(
			$author$project$Pyxis$Components$Field$Input$setOnBlur(msg)));
};
var $author$project$Quotation$Common$Components$AddressFallback$setStreetNumber = F2(
	function (fieldValue, _v0) {
		var data = _v0;
		return _Utils_update(
			data,
			{cJ: fieldValue});
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateStreetNumberWithCmd = F2(
	function (inputMsg, model) {
		var formData = model.dv;
		return A2(
			$elm$core$Tuple$mapFirst,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Common$Components$AddressFallback$setStreetNumber,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$Components$AddressFallback$mapFormData, model)),
			A2(
				$author$project$Pyxis$Components$Field$Input$update,
				inputMsg,
				$author$project$Quotation$Common$Components$AddressFallback$pickStreetNumberField(formData)));
	});
var $author$project$Quotation$Common$Components$AddressFallback$updateZipCodeWithCmd = F2(
	function (autocompleteMsg, model) {
		var formData = model.dv;
		return A2(
			$elm$core$Tuple$mapFirst,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Common$Components$AddressFallback$setZipCode,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$Components$AddressFallback$mapFormData, model)),
			A2(
				$author$project$Pyxis$Components$Field$Autocomplete$update,
				autocompleteMsg,
				$author$project$Quotation$Common$Components$AddressFallback$pickZipCodeField(formData)));
	});
var $author$project$Quotation$Common$Components$AddressFallback$AddressFallbackResult = F4(
	function (streetName, streetNumber, city, zipCode) {
		return {my: city, hO: streetName, pO: streetNumber, qg: zipCode};
	});
var $author$project$Quotation$Common$Components$AddressFallback$getResult = function (_v0) {
	var formData = _v0.dv;
	return A2(
		$elm_community$result_extra$Result$Extra$andMap,
		$author$project$Quotation$Common$Components$AddressFallback$validatedZipCodeField(formData),
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$author$project$Quotation$Common$Components$AddressFallback$validatedCityField(formData),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$Components$AddressFallback$validatedStreetNumberField(formData),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$Quotation$Common$Components$AddressFallback$validatedStreetNameField(formData),
					$elm$core$Result$Ok($author$project$Quotation$Common$Components$AddressFallback$AddressFallbackResult)))));
};
var $author$project$Quotation$Common$Components$AddressFallback$getFormattedAddressValue = function (_v0) {
	var city = _v0.my;
	var zipCode = _v0.qg;
	var streetName = _v0.hO;
	var streetNumber = _v0.pO;
	var provinceString = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.mH;
				},
				$elm$core$Basics$append(' ')),
			city.o6));
	return streetName + (', ' + (streetNumber + (', ' + (zipCode.h1 + (' ' + (city.oq + (provinceString + ', Italia')))))));
};
var $author$project$Quotation$Common$Components$AddressFallback$resultToPlaceMapper = function (addressFallbackResult) {
	var city = addressFallbackResult.my;
	var zipCode = addressFallbackResult.qg;
	var streetName = addressFallbackResult.hO;
	var streetNumber = addressFallbackResult.pO;
	return A5(
		$author$project$Quotation$Common$Types$Place,
		$author$project$Quotation$Common$Components$AddressFallback$getFormattedAddressValue(addressFallbackResult),
		$elm$core$Maybe$Just(city.oq),
		$elm$core$Maybe$Just(streetName),
		$elm$core$Maybe$Just(streetNumber),
		$elm$core$Maybe$Just(zipCode.h1));
};
var $author$project$Quotation$Common$Components$AddressFallback$validate = function (model) {
	var modelData = model;
	var _v0 = $author$project$Quotation$Common$Components$AddressFallback$getResult(model);
	if (!_v0.$) {
		var addressFallbackResult = _v0.a;
		return _Utils_update(
			modelData,
			{
				ho: $elm$core$Maybe$Just(
					$author$project$Quotation$Common$Components$AddressFallback$resultToPlaceMapper(addressFallbackResult))
			});
	} else {
		return _Utils_update(
			modelData,
			{b8: true});
	}
};
var $author$project$Quotation$Common$Components$AddressFallback$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 0:
				var subMsg = msg.a;
				return A2($author$project$Quotation$Common$Components$AddressFallback$updateStreetNameWithCmd, subMsg, model);
			case 1:
				return $author$project$Utils$withoutCommands(
					A2(
						$author$project$Quotation$Common$Components$AddressFallback$updateStreetNameAnalyticsMsg,
						modelData.aD(
							$author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent(
								{
									bb: $author$project$Quotation$Common$Components$AddressFallback$getStreetNameAnalyticsParameter(
										$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
									ay: $author$project$Quotation$Common$Components$AddressFallback$getStreetNameAnalyticsParameter(
										$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
									n3: $author$project$Quotation$Common$Components$AddressFallback$isStreetNameFieldValid(model)
								})),
						model));
			case 2:
				var subMsg = msg.a;
				return A2($author$project$Quotation$Common$Components$AddressFallback$updateStreetNumberWithCmd, subMsg, model);
			case 3:
				return $author$project$Utils$withoutCommands(
					A2(
						$author$project$Quotation$Common$Components$AddressFallback$updateStreetNumberAnalyticsMsg,
						modelData.aD(
							$author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent(
								{
									bb: $author$project$Quotation$Common$Components$AddressFallback$getStreetNumberAnalyticsParameter(
										$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
									ay: $author$project$Quotation$Common$Components$AddressFallback$getStreetNumberAnalyticsParameter(
										$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
									n3: $author$project$Quotation$Common$Components$AddressFallback$isStreetNumberFieldValid(model)
								})),
						model));
			case 4:
				var subMsg = msg.a;
				return A2($author$project$Quotation$Common$Components$AddressFallback$updateCityWithCmd, subMsg, model);
			case 5:
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$send(
							modelData.aD(
								$author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent(
									{
										bb: $author$project$Quotation$Common$Components$AddressFallback$getCityAnalyticsParameter(
											$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
										ay: $author$project$Quotation$Common$Components$AddressFallback$getCityAnalyticsParameter(
											$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
										n3: $author$project$Quotation$Common$Components$AddressFallback$isCityFieldValid(model)
									})))
						]),
					model);
			case 6:
				var subMsg = msg.a;
				return A2($author$project$Quotation$Common$Components$AddressFallback$updateZipCodeWithCmd, subMsg, model);
			case 7:
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$send(
							modelData.aD(
								$author$project$Quotation$Common$Components$AddressFallback$SendGTMFormEvent(
									{
										bb: $author$project$Quotation$Common$Components$AddressFallback$getZipCodeAnalyticsParameter(
											$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
										ay: $author$project$Quotation$Common$Components$AddressFallback$getZipCodeAnalyticsParameter(
											$author$project$Quotation$Common$Components$AddressFallback$pickAddressType(model)),
										n3: $author$project$Quotation$Common$Components$AddressFallback$isZipCodeFieldValid(model)
									})))
						]),
					model);
			case 8:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$GTM$conditionallySendGTMFormEvent(
							{
								nf: $author$project$Quotation$Common$Components$AddressFallback$toGTMEventTag(
									{ay: eventId, oO: modelData.ic, kA: modelData.ib, oQ: analyticsParameter}),
								n3: isFieldValid
							})
						]),
					model);
			case 9:
				return $author$project$Utils$withoutCommands(
					$author$project$Quotation$Common$Components$AddressFallback$validate(model));
			default:
				return $author$project$Utils$withoutCommands(model);
		}
	});
var $author$project$Quotation$Common$Updaters$updateAddressFallbackFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Quotation$Common$Components$AddressFallback$update);
var $author$project$Quotation$Common$Updaters$updateResidentialAddressFallbackWithCmd = A2($author$project$Quotation$Common$Updaters$updateAddressFallbackFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField, $author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback);
var $author$project$Quotation$Common$Updaters$updateResidentialAddressWithCmd = function (msgMapper) {
	return A3($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldWithCmd, msgMapper, $author$project$Quotation$Common$Fields$Model$pickResidentialAddressField, $author$project$Quotation$Common$Fields$Model$setResidentialAddress);
};
var $author$project$Quotation$Common$Updaters$updateSurnameWithCmd = A2($author$project$Quotation$Common$Updaters$updateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickSurnameField, $author$project$Quotation$Common$Fields$Model$setSurname);
var $author$project$Quotation$Common$Command$withConditionalBirthCountryCmd = F4(
	function (autocompleteMsg, analyticsParameter, sendGTMFormEventMsgMapper, modelCmdPair) {
		var model = modelCmdPair.a;
		var isOnSelectMsg = $author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg(autocompleteMsg);
		var isForeignCitySelected = $author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
			$author$project$Quotation$Common$StepModel$pickFormData(model));
		return (isOnSelectMsg && (!isForeignCitySelected)) ? A2(
			$author$project$Utils$addCommand,
			$author$project$Utils$send(
				sendGTMFormEventMsgMapper(
					{
						bb: analyticsParameter,
						ay: analyticsParameter,
						n3: A2($author$project$Quotation$Common$StepModel$isBirthCountryFieldValid, isForeignCitySelected, model)
					})),
			modelCmdPair) : modelCmdPair;
	});
var $author$project$Quotation$Family$CustomerStep$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($elm$core$Task$perform, $author$project$Quotation$Family$CustomerStep$Model$CurrentDate, $justinmimbs$date$Date$today),
								A2($elm$random$Random$generate, $author$project$Quotation$Family$CustomerStep$Model$GotNewSeed, $elm$random$Random$independentSeed),
								$author$project$Utils$onViewportLoadCmd($author$project$Quotation$Family$CustomerStep$Model$InitializeView),
								$author$project$Ports$pushUserRole(0)
							]),
						model));
			case 1:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsViewInitialized, true, model)));
			case 2:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible, false, model)));
			case 5:
				switch (msg.a.$) {
					case 0:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$birthCitySuggestionsMapper(response),
									model)));
					case 1:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$birthCountrySuggestionsMapper(response),
									model)));
					case 2:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$residentialAddressSuggestionsMapper(response),
									model)));
					default:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$residentialAddressCitySuggestionsMapper(response),
									model)));
				}
			case 18:
				var response = msg.a;
				return A2($author$project$Quotation$Family$CustomerStep$Update$handleQuoteResponse, response, model);
			case 6:
				var isOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isOpen),
								$author$project$Quotation$Family$CustomerStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd(isOpen)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$setModalState,
							A3($author$project$Utils$ternaryOperator, isOpen, $author$project$Quotation$Family$CustomerStep$Model$OpenPrivacyPolicyModal, $author$project$Quotation$Family$CustomerStep$Model$ClosedModal),
							model)));
			case 8:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$GTM$conditionallySendGTMFormEvent(
								{
									nf: $author$project$Utils$GTM$composeEventTag(
										{
											ay: $author$project$Utils$GTM$eventIdWithPrefix(
												{ay: eventId, oV: $author$project$Quotation$Family$CustomerStep$Model$stepPrefixValue}),
											ne: $author$project$Utils$GTM$defaultEventName,
											oO: $author$project$Utils$GTM$defaultFamilyParameterName,
											oP: $author$project$Quotation$Family$CustomerStep$Model$stepPrefixValue,
											oQ: analyticsParameter
										}),
									n3: isFieldValid
								})
							]),
						model));
			case 7:
				var fieldInfoModalState = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(
								!_Utils_eq(fieldInfoModalState, $author$project$Quotation$Family$CustomerStep$Model$ClosedModal)),
								$author$project$Quotation$Family$CustomerStep$Update$conditionalFieldInfoModalAnalyticsCmd(fieldInfoModalState)
							]),
						A2($author$project$Quotation$Common$StepModel$setModalState, fieldInfoModalState, model)));
			case 9:
				var manualAddressToggleType = msg.a;
				var isManual = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isManual),
								A2($author$project$Quotation$Family$CustomerStep$Update$conditionalManualResidentialAddressCmd, manualAddressToggleType, isManual)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsResidentialAddressManual, isManual, model)));
			case 10:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(false)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$mapFormData,
							$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback($author$project$Quotation$Family$CustomerStep$Model$initialResidentialAddressFallback),
							A2($author$project$Quotation$Common$StepModel$setIsResidentialAddressManual, false, model))));
			case 11:
				return $author$project$Quotation$Family$CustomerStep$Update$proceedFromCustomerStep(model);
			case 12:
				return $author$project$Quotation$Family$CustomerStep$Update$goBackToFamilyInfoStep(model);
			case 13:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 16:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Family$CustomerStep$Model$refillAllAutocompleteFilter(
							$author$project$Quotation$Family$CustomerStep$Model$closeAllSelect(model))));
			case 14:
				var date = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentDate, date, model)));
			case 15:
				var seed = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentSeed, seed, model)));
			case 17:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 3:
				switch (msg.a.$) {
					case 15:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateNameWithCmd, inputMsg, model));
					case 16:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateSurnameWithCmd, inputMsg, model));
					case 17:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateGenderWithCmd, radioCardMsg, model));
					case 18:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateBirthdayWithCmd, inputMsg, model));
					case 19:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A4(
								$author$project$Quotation$Common$Command$withConditionalBirthCountryCmd,
								autocompleteMsg,
								$author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
								$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent,
								A4(
									$author$project$Quotation$Common$Command$maybeSearchCities,
									autocompleteMsg,
									A2($elm$core$Basics$composeR, $author$project$Quotation$Family$CustomerStep$Model$SearchBirthCityResponse, $author$project$Quotation$Family$CustomerStep$Model$Response),
									$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
									A3(
										$author$project$Quotation$Common$Updaters$updateBirthCityWithCmd,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCityFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
										autocompleteMsg,
										model))));
					case 20:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A4(
								$author$project$Quotation$Common$Command$maybeSearchCountries,
								autocompleteMsg,
								A2($elm$core$Basics$composeR, $author$project$Quotation$Family$CustomerStep$Model$SearchBirthCountryResponse, $author$project$Quotation$Family$CustomerStep$Model$Response),
								$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
								A3(
									$author$project$Quotation$Common$Updaters$updateBirthCountryWithCmd,
									A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
									autocompleteMsg,
									model)));
					case 10:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A4(
								$author$project$Quotation$Common$Command$maybeSearchPlaces,
								autocompleteMsg,
								A2($elm$core$Basics$composeR, $author$project$Quotation$Family$CustomerStep$Model$SearchResidentialPlaceResponse, $author$project$Quotation$Family$CustomerStep$Model$Response),
								$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
								A3(
									$author$project$Quotation$Common$Updaters$updateResidentialAddressWithCmd,
									A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
									autocompleteMsg,
									model)));
					case 11:
						var addressFallbackMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$applyTupleAsParameters,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$addCommand),
								A2(
									$elm$core$Tuple$mapFirst,
									$author$project$Quotation$Family$CustomerStep$Update$conditionallyUpdateResidentialAddress,
									A4(
										$author$project$Quotation$Common$Command$maybeSearchCitiesFallback,
										addressFallbackMsg,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Family$CustomerStep$Model$SearchResidentialAddressCityResponse, $author$project$Quotation$Family$CustomerStep$Model$Response),
										$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
										A2($author$project$Quotation$Common$Updaters$updateResidentialAddressFallbackWithCmd, addressFallbackMsg, model)))));
					case 21:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateEmailWithCmd, inputMsg, model));
					case 22:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePhoneWithCmd, inputMsg, model));
					case 24:
						var checkboxMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePrivacyPolicyWithCmd, checkboxMsg, model));
					case 25:
						var checkboxMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateCommercialPurposesWithCmd, checkboxMsg, model));
					case 23:
						var _v1 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{bb: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter, n3: true}))
									]),
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									A2($author$project$Quotation$Common$Fields$Model$resetBirthCountryField, $author$project$Quotation$Family$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
									A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$selectForeignCity, model))));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
			default:
				switch (msg.a) {
					case 13:
						var _v2 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setNameOnBlurMsg(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$nameAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$nameAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isNameFieldValid(model)
											})),
									model)));
					case 14:
						var _v3 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setSurnameOnBlurMsg(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$surnameAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$surnameAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isSurnameFieldValid(model)
											})),
									model)));
					case 15:
						var _v4 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$genderAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$genderAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isGenderFieldValid(model)
											}))
									]),
								model));
					case 16:
						var _v5 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setBirthdayOnBlurMsg(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthdayAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthdayAnalyticsParameter,
												n3: A2(
													$author$project$Quotation$Common$StepModel$isBirthdayFieldValid,
													$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
													model)
											})),
									model)));
					case 17:
						var _v6 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isBirthCityFieldValid(model)
											}))
									]),
								model));
					case 18:
						var _v7 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
												n3: A2(
													$author$project$Quotation$Common$StepModel$isBirthCountryFieldValid,
													$author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
														$author$project$Quotation$Common$StepModel$pickFormData(model)),
													model)
											}))
									]),
								model));
					case 9:
						var _v8 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$residentialAddressParameter,
												ay: $author$project$Quotation$Common$Analytics$residentialAddressParameter,
												n3: A2($author$project$Quotation$Common$StepModel$isResidentialAddressFieldValid, true, model)
											}))
									]),
								model));
					case 19:
						var _v9 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setEmailOnBlurMsg(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$emailAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$emailAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isEmailFieldValid(model)
											})),
									model)));
					case 20:
						var _v10 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setPhoneOnBlurMsg(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$phoneAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$phoneAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isPhoneFieldValid(model)
											})),
									model)));
					case 21:
						var _v11 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$privacyPolicyAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$privacyPolicyAnalyticsParameter,
												n3: $author$project$Quotation$Common$Fields$Model$isPrivacyPolicyOptionChecked(
													$author$project$Quotation$Common$StepModel$pickFormData(model))
											}))
									]),
								model));
					case 22:
						var _v12 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$commercialPurposesAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$commercialPurposesAnalyticsParameter,
												n3: $author$project$Quotation$Common$Fields$Model$isCommercialPurposesOptionChecked(
													$author$project$Quotation$Common$StepModel$pickFormData(model))
											}))
									]),
								model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
		}
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$CurrentDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation = function (a) {
	return {$: 9, a: a};
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$InitializeView = {$: 1};
var $author$project$Quotation$Family$FamilyInfoStep$Model$OpenPrivacyPolicyModal = {$: 1};
var $author$project$Quotation$Common$Analytics$adultsParameter = 'adults_in_house';
var $author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Common$Validation$requiredSelect = function (mapper) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Result$fromMaybe($author$project$Quotation$Common$Validation$requiredFieldErrorMessage),
		$elm$core$Result$andThen(mapper));
};
var $author$project$Quotation$Common$Fields$Model$stringToAdultsTypeMapper = function (value) {
	return A2(
		$elm_community$list_extra$List$Extra$find,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper,
			$elm$core$Basics$eq(value)),
		$author$project$Quotation$Common$Fields$Model$getAdultsTypeVariants);
};
var $author$project$Quotation$Common$Fields$Model$adultsFieldValidation = $author$project$Quotation$Common$Validation$requiredSelect(
	A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$stringToAdultsTypeMapper,
		$elm$core$Result$fromMaybe($author$project$Quotation$Common$Validation$invalidFieldErrorMessage)));
var $author$project$Pyxis$Components$Field$Select$getValue = function (_v0) {
	var selectedValue = _v0.a2;
	return selectedValue;
};
var $author$project$Quotation$Common$Fields$Model$pickAdultsField = function (_v0) {
	var adultsField = _v0.h8;
	return adultsField;
};
var $author$project$Quotation$Common$StepModel$validatedAdultsField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickAdultsField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Select$getValue, $author$project$Quotation$Common$Fields$Model$adultsFieldValidation));
var $author$project$Quotation$Common$StepModel$isAdultsFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedAdultsField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Fields$Model$stringToChildrenTypeMapper = function (value) {
	return A2(
		$elm_community$list_extra$List$Extra$find,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper,
			$elm$core$Basics$eq(value)),
		$author$project$Quotation$Common$Fields$Model$getChildrenTypeVariants);
};
var $author$project$Quotation$Common$Fields$Model$childrenFieldValidation = $author$project$Quotation$Common$Validation$requiredSelect(
	A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$stringToChildrenTypeMapper,
		$elm$core$Result$fromMaybe($author$project$Quotation$Common$Validation$invalidFieldErrorMessage)));
var $author$project$Quotation$Common$Fields$Model$pickChildrenField = function (_v0) {
	var childrenField = _v0.it;
	return childrenField;
};
var $author$project$Quotation$Common$StepModel$validatedChildrenField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickChildrenField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Select$getValue, $author$project$Quotation$Common$Fields$Model$childrenFieldValidation));
var $author$project$Quotation$Common$StepModel$isChildrenFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedChildrenField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isFamilyMembersFieldsValid = function (model) {
	return $author$project$Quotation$Common$StepModel$isAdultsFieldValid(model) && $author$project$Quotation$Common$StepModel$isChildrenFieldValid(model);
};
var $author$project$Quotation$Common$View$ExpandableField$StateUpdate = F2(
	function (id, isExpanded) {
		return {nM: id, n2: isExpanded};
	});
var $author$project$Quotation$Common$View$ExpandableField$animationDelayMs = 200;
var $author$project$Quotation$Common$View$ExpandableField$animationDurationMs = 300;
var $author$project$Utils$delayCmd = F2(
	function (delayMs, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$identity,
			A2(
				$elm$core$Task$andThen,
				$elm$core$Basics$always(
					$elm$core$Task$succeed(msg)),
				$elm$core$Process$sleep(delayMs)));
	});
var $elm_community$result_extra$Result$Extra$error = function (result) {
	if (!result.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var err = result.a;
		return $elm$core$Maybe$Just(err);
	}
};
var $author$project$Quotation$Common$Fields$Model$pickReportedClaimsField = function (_v0) {
	var reportedClaimsField = _v0.k8;
	return reportedClaimsField;
};
var $author$project$Quotation$Common$Fields$Model$reportedClaimsFieldValidation = $author$project$Quotation$Common$Validation$requiredRadioCard;
var $author$project$Quotation$Common$StepModel$validatedReportedClaimsField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickReportedClaimsField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$reportedClaimsFieldValidation));
var $author$project$Quotation$Family$FamilyInfoStep$Model$firstInvalidExpandableFieldId = function (stepState) {
	var stepData = $author$project$Quotation$Common$StepModel$pickFormData(stepState);
	return $elm_community$result_extra$Result$Extra$error(
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			A2(
				$elm$core$Result$mapError,
				$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId),
				$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData)),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				A2(
					$elm$core$Result$mapError,
					$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
					$author$project$Quotation$Common$StepModel$validatedChildrenField(stepData)),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					A2(
						$elm$core$Result$mapError,
						$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
						$author$project$Quotation$Common$StepModel$validatedAdultsField(stepData)),
					$elm$core$Result$Ok($author$project$Quotation$Family$FamilyInfoStep$Model$StepResultData)))));
};
var $author$project$Quotation$Family$FamilyInfoStep$Update$withExpandableFieldsUpdateCmd = F3(
	function (isOtherFieldExpanded, currentFieldId, model) {
		var postponedExpandableFieldsUpdate = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$View$ExpandableField$StateUpdate, true),
					$elm$core$List$singleton),
				$author$project$Quotation$Family$FamilyInfoStep$Model$firstInvalidExpandableFieldId(model)));
		var expandableFieldsUpdate = _List_fromArray(
			[
				A2($author$project$Quotation$Common$View$ExpandableField$StateUpdate, currentFieldId, false)
			]);
		return isOtherFieldExpanded ? A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					$author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation(expandableFieldsUpdate)),
					A2(
					$author$project$Utils$delayCmd,
					$author$project$Quotation$Common$View$ExpandableField$animationDelayMs + $author$project$Quotation$Common$View$ExpandableField$animationDurationMs,
					$author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation(postponedExpandableFieldsUpdate))
				]),
			model) : $author$project$Utils$withoutCommands(model);
	});
var $author$project$Quotation$Family$FamilyInfoStep$Update$familyMembersOnChange = F4(
	function (analyticsEventName, analyticsEventId, isFieldValid, model) {
		var isFieldCompleted = $author$project$Quotation$Common$StepModel$isFamilyMembersFieldsValid(model);
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$addCommand,
				$author$project$Utils$send(
					$author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent(
						{bb: analyticsEventName, ay: analyticsEventId, n3: isFieldValid})),
				A3($author$project$Quotation$Family$FamilyInfoStep$Update$withExpandableFieldsUpdateCmd, isFieldCompleted, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId, model)));
	});
var $author$project$Quotation$Common$Analytics$adultsParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_one';
		case 1:
			return '_two';
		case 2:
			return '_three';
		case 3:
			return '_four';
		default:
			return '_five_or_more';
	}
};
var $author$project$Quotation$Common$Analytics$getAdultsParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$adultsParameterSuffixMapper,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Quotation$Common$Fields$Model$stringToAdultsTypeMapper,
				$author$project$Pyxis$Components$Field$Select$getValue(
					$author$project$Quotation$Common$Fields$Model$pickAdultsField(
						$author$project$Quotation$Common$StepModel$pickFormData(model))))));
};
var $author$project$Quotation$Family$FamilyInfoStep$Update$adultsOnChange = F2(
	function (isFieldValid, model) {
		return A4(
			$author$project$Quotation$Family$FamilyInfoStep$Update$familyMembersOnChange,
			_Utils_ap(
				$author$project$Quotation$Common$Analytics$adultsParameter,
				$author$project$Quotation$Common$Analytics$getAdultsParameterSuffix(model)),
			$author$project$Quotation$Common$Analytics$adultsParameter,
			isFieldValid,
			model);
	});
var $author$project$Quotation$Common$Analytics$childrenParameter = 'minors_in_house';
var $author$project$Quotation$Common$Analytics$childrenParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_zero';
		case 1:
			return '_one';
		case 2:
			return '_two';
		case 3:
			return '_three';
		case 4:
			return '_four';
		default:
			return '_five_or_more';
	}
};
var $author$project$Quotation$Common$Analytics$getChildrenParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$childrenParameterSuffixMapper,
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Quotation$Common$Fields$Model$stringToChildrenTypeMapper,
				$author$project$Pyxis$Components$Field$Select$getValue(
					$author$project$Quotation$Common$Fields$Model$pickChildrenField(
						$author$project$Quotation$Common$StepModel$pickFormData(model))))));
};
var $author$project$Quotation$Family$FamilyInfoStep$Update$childrenOnChange = F2(
	function (isFieldValid, model) {
		return A4(
			$author$project$Quotation$Family$FamilyInfoStep$Update$familyMembersOnChange,
			_Utils_ap(
				$author$project$Quotation$Common$Analytics$childrenParameter,
				$author$project$Quotation$Common$Analytics$getChildrenParameterSuffix(model)),
			$author$project$Quotation$Common$Analytics$childrenParameter,
			isFieldValid,
			model);
	});
var $author$project$Quotation$Common$Fields$Model$setAdults = F2(
	function (adultsField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{h8: adultsField});
	});
var $author$project$Pyxis$Components$Field$Select$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dq: mapper(model.dq)
			});
	});
var $author$project$Pyxis$Components$Field$Select$setDropdownClosed = function (_v0) {
	var modelData = _v0;
	return A2(
		$author$project$Pyxis$Components$Field$Select$mapFieldStatus,
		function (fieldStatus) {
			return _Utils_update(
				fieldStatus,
				{n$: true});
		},
		_Utils_update(
			modelData,
			{jP: false}));
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$closeAdultsSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setAdults,
		$author$project$Pyxis$Components$Field$Select$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickAdultsField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$setChildren = F2(
	function (childrenField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{it: childrenField});
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$closeChildrenSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setChildren,
		$author$project$Pyxis$Components$Field$Select$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickChildrenField(formData)),
		formData);
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$closeAllSelect = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Family$FamilyInfoStep$Model$closeChildrenSelect,
		A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Family$FamilyInfoStep$Model$closeAdultsSelect, model));
};
var $author$project$Quotation$Family$FamilyInfoStep$Update$conditionalFieldInfoModalAnalyticsCmd = function (fieldInfoModalState) {
	if (!fieldInfoModalState.$) {
		var fieldInfoModal = fieldInfoModalState.a;
		return $author$project$Utils$send(
			function (parameter) {
				return $author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent(
					{bb: parameter, ay: parameter, n3: true});
			}(
				$author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal)));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Utils$delayedOnViewportLoadCmd = F2(
	function (delayMs, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$identity,
			A2(
				$elm$core$Task$andThen,
				$elm$core$Basics$always(
					$elm$core$Task$succeed(msg)),
				A2(
					$elm$core$Task$andThen,
					$elm$core$Basics$always(
						$elm$core$Process$sleep(delayMs)),
					$elm$browser$Browser$Dom$getViewport)));
	});
var $author$project$Quotation$Common$View$ExpandableField$firstFieldExpansionDelayMs = 700;
var $author$project$Quotation$Common$View$ExpandableField$toStateUpdateList = F3(
	function (id, toBeExpanded, expandedFieldsIds) {
		return A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A2($author$project$Quotation$Common$View$ExpandableField$StateUpdate, id, toBeExpanded)
				]),
			A2(
				$elm$core$List$map,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$View$ExpandableField$StateUpdate, false),
				A2(
					$elm$core$List$filter,
					$elm$core$Basics$neq(id),
					expandedFieldsIds)));
	});
var $author$project$Quotation$Common$Command$conditionalFirstFieldExpansionCmd = F2(
	function (maybeFirstInvalidExpandableFieldId, msgMapper) {
		if (!maybeFirstInvalidExpandableFieldId.$) {
			var id = maybeFirstInvalidExpandableFieldId.a;
			return A2(
				$author$project$Utils$delayedOnViewportLoadCmd,
				$author$project$Quotation$Common$View$ExpandableField$firstFieldExpansionDelayMs,
				msgMapper(
					A3($author$project$Quotation$Common$View$ExpandableField$toStateUpdateList, id, true, _List_Nil)));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Quotation$Common$Analytics$disclaimerPrivacyPolicyModalAnalyticsParameter = 'click_on_disclaimer_privacy_consent_modal';
var $author$project$Quotation$Family$FamilyInfoStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd = function (isModalOpen) {
	return isModalOpen ? $author$project$Utils$send(
		$author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent(
			{bb: $author$project$Quotation$Common$Analytics$disclaimerPrivacyPolicyModalAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$disclaimerPrivacyPolicyModalAnalyticsParameter, n3: true})) : $elm$core$Platform$Cmd$none;
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$NoOp = {$: 13};
var $author$project$Quotation$Family$FamilyInfoStep$Model$ProceedToCustomerStep = F5(
	function (a, b, c, d, e) {
		return {$: 12, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Quotation$Common$StepModel$pickExpandedFieldsIds = function (_v0) {
	var expandedFieldsIds = _v0.i5;
	return expandedFieldsIds;
};
var $author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$pickExpandedFieldsIds, $elm$core$Set$toList);
var $author$project$Quotation$Common$Command$conditionalFirstInvalidFieldExpansionCmd = F4(
	function (maybeFirstInvalidExpandableFieldId, msgMapper, msg, model) {
		if (!maybeFirstInvalidExpandableFieldId.$) {
			var id = maybeFirstInvalidExpandableFieldId.a;
			return A2(
				$elm$core$Set$member,
				id,
				$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model)) ? $elm$core$Platform$Cmd$none : $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						$author$project$Utils$send(
						msgMapper(
							A3(
								$author$project$Quotation$Common$View$ExpandableField$toStateUpdateList,
								id,
								true,
								$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(model)))),
						A2(
						$elm$core$Task$attempt,
						$elm$core$Basics$always(msg),
						A2(
							$elm$core$Task$andThen,
							function (info) {
								return A2($elm$browser$Browser$Dom$setViewport, 0, info.i_.h0 - 10);
							},
							A2(
								$elm$core$Task$andThen,
								$elm$core$Basics$always(
									$elm$browser$Browser$Dom$getElement(id)),
								$elm$core$Process$sleep($author$project$Quotation$Common$View$ExpandableField$animationDurationMs + 100))))
					]));
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$buildStepResultData = function (stepData) {
	return A2(
		$elm_community$result_extra$Result$Extra$andMap,
		$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData),
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$author$project$Quotation$Common$StepModel$validatedChildrenField(stepData),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$StepModel$validatedAdultsField(stepData),
				$elm$core$Result$Ok($author$project$Quotation$Family$FamilyInfoStep$Model$StepResultData))));
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$getStepResult = function (stepState) {
	return A2(
		$elm$core$Result$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		$author$project$Quotation$Family$FamilyInfoStep$Model$buildStepResultData(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Common$StepModel$pickEnabledFieldsIds = function (_v0) {
	var enabledFieldsIds = _v0.i1;
	return enabledFieldsIds;
};
var $author$project$Quotation$Common$StepModel$setEnabledValidationFieldsIds = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{i2: value});
	});
var $author$project$Quotation$Family$FamilyInfoStep$Update$proceedFromFamilyInfoStep = function (model) {
	var _v0 = $author$project$Quotation$Family$FamilyInfoStep$Model$getStepResult(model);
	if (!_v0.$) {
		var familyInfoStepResult = _v0.a;
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						A5(
							$author$project$Quotation$Family$FamilyInfoStep$Model$ProceedToCustomerStep,
							$author$project$Quotation$Common$StepModel$pickFlags(model),
							$author$project$Quotation$Common$StepModel$pickTokenType(model),
							$author$project$Quotation$Common$StepModel$pickUser(model),
							$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
							familyInfoStepResult)),
						$author$project$Utils$send(
						$author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent(
							{bb: $author$project$Quotation$Common$Analytics$proceedBtnParameter, ay: $author$project$Quotation$Common$Analytics$proceedBtnParameter, n3: true}))
					]),
				model));
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						A4(
						$author$project$Quotation$Common$Command$conditionalFirstInvalidFieldExpansionCmd,
						$author$project$Quotation$Family$FamilyInfoStep$Model$firstInvalidExpandableFieldId(model),
						$author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation,
						$author$project$Quotation$Family$FamilyInfoStep$Model$NoOp,
						model)
					]),
				A2(
					$author$project$Quotation$Common$StepModel$setIsFormSubmitted,
					true,
					A2(
						$author$project$Quotation$Common$StepModel$setEnabledValidationFieldsIds,
						$author$project$Quotation$Common$StepModel$pickEnabledFieldsIds(model),
						model))));
	}
};
var $author$project$Quotation$Common$Analytics$reportedClaimsParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_zero';
		case 1:
			return '_one';
		default:
			return '_two_or_more';
	}
};
var $author$project$Quotation$Common$Analytics$getReportedClaimsParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$reportedClaimsParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickReportedClaimsField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$StepModel$isReportedClaimsFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedReportedClaimsField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$Analytics$reportedClaimsParameter = 'past_incidents';
var $author$project$Quotation$Family$FamilyInfoStep$Update$reportedClaimsOnChange = function (model) {
	return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
		A2(
			$author$project$Utils$addCommand,
			$author$project$Utils$send(
				$author$project$Quotation$Family$FamilyInfoStep$Model$SendGTMFormEvent(
					{
						bb: _Utils_ap(
							$author$project$Quotation$Common$Analytics$reportedClaimsParameter,
							$author$project$Quotation$Common$Analytics$getReportedClaimsParameterSuffix(model)),
						ay: $author$project$Quotation$Common$Analytics$reportedClaimsParameter,
						n3: $author$project$Quotation$Common$StepModel$isReportedClaimsFieldValid(model)
					})),
			A3($author$project$Quotation$Family$FamilyInfoStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId, model)));
};
var $author$project$Quotation$Common$StepModel$setExpandedFieldsIds = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{i5: value});
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$stepPrefixValue = 'family_info_step';
var $author$project$Pyxis$Components$Field$Select$getSelectedOption = function (_v0) {
	var selectedValue = _v0.a2;
	var options = _v0.ku;
	return A2(
		$elm_community$list_extra$List$Extra$find,
		function (_v1) {
			var value = _v1.p8;
			return _Utils_eq(
				$elm$core$Maybe$Just(value),
				selectedValue);
		},
		options);
};
var $author$project$Pyxis$Components$Field$Select$pickOptionId = function (_v0) {
	var id = _v0.nM;
	return id;
};
var $author$project$Pyxis$Components$Field$Select$focusOnActiveOption = F2(
	function (model, msg) {
		var modelData = model;
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Select$pickOptionId,
					A2(
						$elm$core$Basics$composeR,
						$elm$browser$Browser$Dom$focus,
						$elm$core$Task$attempt(
							$elm$core$Basics$always(msg)))),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$or,
					modelData.G,
					$author$project$Pyxis$Components$Field$Select$getSelectedOption(model))));
	});
var $author$project$Pyxis$Components$Field$Select$attemptFocus = function (model) {
	var modelData = model;
	return A2(
		$primait$prima_elm_extra$PrimaCmd$fromMaybeMap,
		$author$project$Pyxis$Components$Field$Select$focusOnActiveOption(model),
		modelData.gj);
};
var $author$project$Pyxis$Components$Field$Select$getOptionData = function (_v0) {
	var optionData = _v0;
	return optionData;
};
var $author$project$Pyxis$Components$Field$Select$setActiveOption = F2(
	function (option_, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{G: option_});
	});
var $author$project$Pyxis$Components$Field$Select$initializeActiveOption = function (model) {
	var selectedValue = model.a2;
	var options = model.ku;
	if (!selectedValue.$) {
		var currentValue = selectedValue.a;
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Select$setActiveOption,
			model,
			A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Select$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						$elm$core$Basics$eq(currentValue))),
				options));
	} else {
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Select$setActiveOption,
			model,
			$elm$core$List$head(options));
	}
};
var $author$project$Pyxis$Components$Field$Select$isDropdownOpen = function (_v0) {
	var modelData = _v0;
	return modelData.jP;
};
var $author$project$Pyxis$Components$Field$Select$updateIsOpen = F2(
	function (isOpen, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{jP: isOpen});
	});
var $author$project$Pyxis$Components$Field$Select$updateSelectedValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				a2: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Pyxis$Commons$Events$Keyboard$isSpace = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(32);
var $author$project$Pyxis$Components$Field$Select$maybeDispatchOnHoveredOptionChange = F2(
	function (_v0, _v1) {
		var oldModelData = _v0;
		var newModelData = _v1;
		return (!_Utils_eq(oldModelData.G, newModelData.G)) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (_v2, onHoveredOptionChange) {
						var id = _v2.nM;
						return $author$project$Pyxis$Commons$Commands$dispatch(
							onHoveredOptionChange(id));
					}),
				newModelData.G,
				newModelData.gn)) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Pyxis$Components$Field$Select$pickOptionValue = function (_v0) {
	var value = _v0.p8;
	return value;
};
var $author$project$Pyxis$Components$Field$Select$getNewActiveOption = F2(
	function (moveByPositions, _v0) {
		var options = _v0.ku;
		var activeOption = _v0.G;
		var newOptionIndex = moveByPositions + A2(
			$elm$core$Maybe$withDefault,
			-1,
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					var index = _v1.dG;
					return index;
				},
				activeOption));
		return (newOptionIndex < 0) ? $elm$core$List$head(options) : ((_Utils_cmp(
			newOptionIndex,
			$elm$core$List$length(options)) > -1) ? $elm$core$List$head(
			$elm$core$List$reverse(options)) : A2(
			$elm$core$Array$get,
			newOptionIndex,
			$elm$core$Array$fromList(options)));
	});
var $author$project$Pyxis$Components$Field$Select$updateActiveOption = F2(
	function (moveByPositions, model) {
		var modelData = model;
		return _Utils_update(
			modelData,
			{
				G: A2($author$project$Pyxis$Components$Field$Select$getNewActiveOption, moveByPositions, model)
			});
	});
var $author$project$Pyxis$Components$Field$Select$withOnKeyDown = F2(
	function (event, model) {
		var onSelect = model.d9;
		var isOpen = model.jP;
		var activeOption = model.G;
		return (($author$project$Pyxis$Commons$Events$Keyboard$isArrowDown(event) || $author$project$Pyxis$Commons$Events$Keyboard$isSpace(event)) && (!isOpen)) ? A2(
			$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Field$Select$attemptFocus,
					$author$project$Pyxis$Components$Field$Select$maybeDispatchOnHoveredOptionChange(model)
				]),
			A2(
				$author$project$Pyxis$Components$Field$Select$updateIsOpen,
				true,
				$author$project$Pyxis$Components$Field$Select$initializeActiveOption(model))) : (($author$project$Pyxis$Commons$Events$Keyboard$isArrowDown(event) && isOpen) ? A2(
			$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Field$Select$attemptFocus,
					$author$project$Pyxis$Components$Field$Select$maybeDispatchOnHoveredOptionChange(model)
				]),
			A2($author$project$Pyxis$Components$Field$Select$updateActiveOption, 1, model)) : (($author$project$Pyxis$Commons$Events$Keyboard$isEnter(event) || ($author$project$Pyxis$Commons$Events$Keyboard$isSpace(event) && isOpen)) ? A2(
			$primait$prima_elm_extra$PrimaUpdate$withCmds,
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(onSelect)
				]),
			A2(
				$elm$core$Maybe$withDefault,
				model,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Select$pickOptionValue,
						A2(
							$elm$core$Basics$composeR,
							A2($primait$prima_elm_extra$PrimaFunction$flip, $author$project$Pyxis$Components$Field$Select$updateSelectedValue, model),
							$author$project$Pyxis$Components$Field$Select$setDropdownClosed)),
					activeOption))) : ($author$project$Pyxis$Commons$Events$Keyboard$isSpace(event) ? $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
			A2($author$project$Pyxis$Components$Field$Select$updateIsOpen, true, model)) : (($author$project$Pyxis$Commons$Events$Keyboard$isArrowUp(event) && isOpen) ? A2(
			$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Field$Select$attemptFocus,
					$author$project$Pyxis$Components$Field$Select$maybeDispatchOnHoveredOptionChange(model)
				]),
			A2($author$project$Pyxis$Components$Field$Select$updateActiveOption, -1, model)) : ($author$project$Pyxis$Commons$Events$Keyboard$isEsc(event) ? $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
			$author$project$Pyxis$Components$Field$Select$setDropdownClosed(model)) : $primait$prima_elm_extra$PrimaUpdate$withoutCmds(model))))));
	});
var $author$project$Pyxis$Components$Field$Select$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.f6)
						]),
					$author$project$Pyxis$Components$Field$Select$setDropdownClosed(model));
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gj)
						]),
					model);
			case 5:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d9)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Select$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A2(
							$author$project$Pyxis$Components$Field$Select$updateIsOpen,
							false,
							A2($author$project$Pyxis$Components$Field$Select$updateSelectedValue, value, model))));
			case 4:
				var option_ = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Pyxis$Components$Field$Select$setActiveOption,
						$elm$core$Maybe$Just(option_),
						model),
					$elm$core$Platform$Cmd$none);
			case 0:
				if (msg.a.$ === 1) {
					var value = msg.a.a.p8;
					return A2(
						$primait$prima_elm_extra$PrimaUpdate$withCmds,
						_List_fromArray(
							[
								$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.d9)
							]),
						A2(
							$author$project$Pyxis$Components$Field$Select$mapFieldStatus,
							$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
							A2(
								$author$project$Pyxis$Components$Field$Select$updateIsOpen,
								false,
								A2($author$project$Pyxis$Components$Field$Select$updateSelectedValue, value, model))));
				} else {
					var _v1 = msg.a;
					return A2(
						$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
						_List_fromArray(
							[
								A2($primait$prima_elm_extra$PrimaCmd$ifThenCmdMap, $author$project$Pyxis$Components$Field$Select$isDropdownOpen, $author$project$Pyxis$Components$Field$Select$attemptFocus)
							]),
						$author$project$Pyxis$Components$Field$Select$initializeActiveOption(
							A2($author$project$Pyxis$Components$Field$Select$updateIsOpen, !modelData.jP, model)));
				}
			default:
				var event = msg.a;
				return A2($author$project$Pyxis$Components$Field$Select$withOnKeyDown, event, model);
		}
	});
var $author$project$Quotation$Common$Updaters$updateSelectFieldWithCmd = $author$project$Quotation$Common$Updaters$updateFieldWithCmd($author$project$Pyxis$Components$Field$Select$update);
var $author$project$Quotation$Common$Updaters$updateAdultsWithCmd = A2($author$project$Quotation$Common$Updaters$updateSelectFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickAdultsField, $author$project$Quotation$Common$Fields$Model$setAdults);
var $author$project$Quotation$Common$Updaters$updateChildrenWithCmd = A2($author$project$Quotation$Common$Updaters$updateSelectFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickChildrenField, $author$project$Quotation$Common$Fields$Model$setChildren);
var $author$project$Quotation$Common$StepModel$setEnabledFieldsIds = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{i1: value});
	});
var $elm$core$Set$union = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$union, dict1, dict2);
	});
var $author$project$Quotation$Common$StepModel$updateEnabledFields = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$setEnabledFieldsIds,
		A2(
			$elm$core$Set$union,
			$author$project$Quotation$Common$StepModel$pickEnabledFieldsIds(model),
			$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model)),
		model);
};
var $author$project$Quotation$Common$StepModel$updateExpandedFields = F2(
	function (updates, model) {
		return A3(
			$elm_community$basics_extra$Basics$Extra$flip,
			$author$project$Quotation$Common$StepModel$setExpandedFieldsIds,
			model,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v0, acc) {
						var id = _v0.nM;
						var isExpanded = _v0.n2;
						return A3(
							$author$project$Utils$ternaryOperator,
							isExpanded,
							A2($elm$core$Set$insert, id, acc),
							A2($elm$core$Set$remove, id, acc));
					}),
				$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model),
				updates));
	});
var $author$project$Quotation$Common$Fields$Model$setReportedClaims = F2(
	function (reportedClaimsField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k8: reportedClaimsField});
	});
var $author$project$Quotation$Common$Updaters$updateReportedClaimsWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickReportedClaimsField, $author$project$Quotation$Common$Fields$Model$setReportedClaims);
var $author$project$Quotation$Family$FamilyInfoStep$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($elm$core$Task$perform, $author$project$Quotation$Family$FamilyInfoStep$Model$CurrentDate, $justinmimbs$date$Date$today),
								$author$project$Utils$onViewportLoadCmd($author$project$Quotation$Family$FamilyInfoStep$Model$InitializeView),
								$author$project$Ports$pushUserRole(0)
							]),
						A2($author$project$Quotation$Common$StepModel$setExpandedFieldsIds, $elm$core$Set$empty, model)));
			case 1:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2(
								$author$project$Quotation$Common$Command$conditionalFirstFieldExpansionCmd,
								$author$project$Quotation$Family$FamilyInfoStep$Model$firstInvalidExpandableFieldId(model),
								$author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsViewInitialized, true, model)));
			case 2:
				var today = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentDate, today, model)));
			case 3:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible, false, model)));
			case 6:
				var isOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isOpen),
								$author$project$Quotation$Family$FamilyInfoStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd(isOpen)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$setModalState,
							A3($author$project$Utils$ternaryOperator, isOpen, $author$project$Quotation$Family$FamilyInfoStep$Model$OpenPrivacyPolicyModal, $author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal),
							model)));
			case 7:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$GTM$conditionallySendGTMFormEvent(
								{
									nf: $author$project$Utils$GTM$composeEventTag(
										{
											ay: $author$project$Utils$GTM$eventIdWithPrefix(
												{ay: eventId, oV: $author$project$Quotation$Family$FamilyInfoStep$Model$stepPrefixValue}),
											ne: $author$project$Utils$GTM$defaultEventName,
											oO: $author$project$Utils$GTM$defaultFamilyParameterName,
											oP: $author$project$Quotation$Family$FamilyInfoStep$Model$stepPrefixValue,
											oQ: analyticsParameter
										}),
									n3: isFieldValid
								})
							]),
						model));
			case 8:
				var fieldInfoModalState = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(
								!_Utils_eq(fieldInfoModalState, $author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal)),
								$author$project$Quotation$Family$FamilyInfoStep$Update$conditionalFieldInfoModalAnalyticsCmd(fieldInfoModalState)
							]),
						A2($author$project$Quotation$Common$StepModel$setModalState, fieldInfoModalState, model)));
			case 9:
				var expandableFieldsTransaction = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Common$StepModel$updateEnabledFields(
							A2($author$project$Quotation$Common$StepModel$updateExpandedFields, expandableFieldsTransaction, model))));
			case 10:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Family$FamilyInfoStep$Model$closeAllSelect(model)));
			case 11:
				return $author$project$Quotation$Family$FamilyInfoStep$Update$proceedFromFamilyInfoStep(model);
			case 12:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 13:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 4:
				switch (msg.a.$) {
					case 12:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateReportedClaimsWithCmd, radioCardMsg, model));
					case 13:
						var selectMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateAdultsWithCmd, selectMsg, model));
					case 14:
						var selectMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateChildrenWithCmd, selectMsg, model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
			default:
				switch (msg.a) {
					case 11:
						var _v1 = msg.a;
						return A2(
							$author$project$Quotation$Family$FamilyInfoStep$Update$adultsOnChange,
							$author$project$Quotation$Common$StepModel$isAdultsFieldValid(model),
							model);
					case 12:
						var _v2 = msg.a;
						return A2(
							$author$project$Quotation$Family$FamilyInfoStep$Update$childrenOnChange,
							$author$project$Quotation$Common$StepModel$isChildrenFieldValid(model),
							model);
					case 10:
						var _v3 = msg.a;
						return $author$project$Quotation$Family$FamilyInfoStep$Update$reportedClaimsOnChange(model);
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
		}
	});
var $author$project$Utils$UpdateReturn$updateChildModel = F6(
	function (model, childModelUpdater, childMsg, rootModelUpdater, childModel, tagger) {
		var _v0 = A2(childModelUpdater, childMsg, childModel);
		var updatedChildModel = _v0.a;
		var command = _v0.b;
		var updateReturn = _v0.c;
		return _Utils_Tuple3(
			A2(rootModelUpdater, model, updatedChildModel),
			A2($elm$core$Platform$Cmd$map, tagger, command),
			updateReturn);
	});
var $author$project$Quotation$Family$Model$updateHydrateState = F2(
	function (updater, model) {
		if (model.$ === 2) {
			var modelState = model.a;
			return $author$project$Quotation$Family$Model$ModelInitialized(
				_Utils_update(
					modelState,
					{
						aA: updater(modelState.aA)
					}));
		} else {
			return model;
		}
	});
var $author$project$Quotation$Family$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var step = msg.a;
				var tokenType = msg.b;
				var user = msg.c;
				switch (model.$) {
					case 0:
						var flags = model.a;
						if (step === 1) {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$send(
											$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$Initialize))
										]),
									A3($author$project$Quotation$Family$Model$initialFamilyInfoStepModel, tokenType, user, flags)));
						} else {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(model));
						}
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					default:
						var modelState = model.a;
						return A4($author$project$Quotation$Family$Update$maybeChangeStep, tokenType, user, step, modelState);
				}
			case 1:
				var tokenType = msg.a;
				var user = msg.b;
				var flags = msg.c;
				var quote = msg.d;
				var city = msg.e;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Quotation, 1, 1),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A5($author$project$Quotation$Family$Update$initWithQuote, tokenType, user, flags, quote, city)));
			case 2:
				if (msg.a.$ === 12) {
					var _v4 = msg.a;
					var flags = _v4.a;
					var tokenType = _v4.b;
					var user = _v4.c;
					var date = _v4.d;
					var familyInfoStepResult = _v4.e;
					var newModel = A2(
						$author$project$Quotation$Family$Model$updateHydrateState,
						$author$project$Quotation$Family$Model$setFamilyInfoHydrateState(familyInfoStepResult),
						model);
					var initialState = A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.X;
						},
						$author$project$Quotation$Family$Model$pickHydrateState(newModel));
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A2($author$project$Routing$Quotation, 1, 2),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$Model$CustomerStepMsg($author$project$Quotation$Family$CustomerStep$Model$Initialize))
									]),
								A2(
									$author$project$Quotation$Family$Model$setCustomerStepModel,
									newModel,
									A6($author$project$Quotation$Family$CustomerStep$Model$initialState, flags, tokenType, user, date, familyInfoStepResult, initialState)))));
				} else {
					var familyInfoMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (familyInfoStepModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Family$FamilyInfoStep$Update$update,
									familyInfoMsg,
									A2($elm$core$Basics$always, $author$project$Quotation$Family$Model$setFamilyInfoStepModel, model),
									familyInfoStepModel,
									$author$project$Quotation$Family$Model$FamilyInfoStepMsg);
							},
							$author$project$Quotation$Family$Model$pickFamilyInfoStepModel(model)));
				}
			case 3:
				if (msg.a.$ === 13) {
					var _v5 = msg.a;
					var flags = _v5.a;
					var tokenType = _v5.b;
					var user = _v5.c;
					var currentDate = _v5.d;
					var familyInfoStepResult = _v5.e;
					var customerData = _v5.f;
					var newModel = A2(
						$author$project$Quotation$Family$Model$updateHydrateState,
						$author$project$Quotation$Family$Model$setCustomerHydrateState(
							$toastal$either$Either$Right(customerData)),
						A2(
							$author$project$Quotation$Family$Model$updateHydrateState,
							$author$project$Quotation$Family$Model$setFamilyInfoHydrateState(familyInfoStepResult),
							model));
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A2($author$project$Routing$Quotation, 1, 1),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$Initialize))
									]),
								A3(
									$elm_community$basics_extra$Basics$Extra$flip,
									$author$project$Quotation$Family$Model$setFamilyInfoStepModel,
									A6($author$project$Quotation$Family$FamilyInfoStep$Model$initialStateFromResult, flags, tokenType, user, true, currentDate, familyInfoStepResult),
									newModel))));
				} else {
					var customerMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (customerStepModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Family$CustomerStep$Update$update,
									customerMsg,
									A2($elm$core$Basics$always, $author$project$Quotation$Family$Model$setCustomerStepModel, model),
									customerStepModel,
									$author$project$Quotation$Family$Model$CustomerStepMsg);
							},
							$author$project$Quotation$Family$Model$pickCustomerStepModel(model)));
				}
			default:
				return $author$project$Quotation$Family$Update$updateModelOnBlur(model);
		}
	});
var $author$project$Quotation$Family$Update$updateModelOnBlur = function (model) {
	var _v0 = $author$project$Quotation$Family$Model$pickStepState(model);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return A2(
				$author$project$Quotation$Family$Update$update,
				$author$project$Quotation$Family$Model$FamilyInfoStepMsg($author$project$Quotation$Family$FamilyInfoStep$Model$OnBlurSelect),
				model);
		} else {
			return A2(
				$author$project$Quotation$Family$Update$update,
				$author$project$Quotation$Family$Model$CustomerStepMsg($author$project$Quotation$Family$CustomerStep$Model$OnBlurSelect),
				model);
		}
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(model));
	}
};
var $author$project$Quotation$Home$Model$AddressStepMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Home$Model$CustomerStepMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$Initialize = {$: 0};
var $author$project$Quotation$Home$CustomerStep$Model$Initialize = {$: 0};
var $author$project$Quotation$Home$PropertyStep$Model$Initialize = {$: 0};
var $author$project$Quotation$Home$Model$PropertyStepMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Home$Model$PropertyStep = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$StepResultData = F6(
	function (propertyAddressField, propertyFloorField, residentialAddressField, reportedClaimsField, adultsField, childrenField) {
		return {h8: adultsField, it: childrenField, k_: propertyAddressField, k$: propertyFloorField, k8: reportedClaimsField, lc: residentialAddressField};
	});
var $author$project$Quotation$Common$Fields$Model$GroundFloor = 0;
var $author$project$Quotation$Common$Fields$Model$NotRequiredPropertyFloor = 3;
var $author$project$Quotation$Common$Fields$Model$OtherFloor = 2;
var $author$project$Quotation$Common$Fields$Model$TopFloor = 1;
var $author$project$Quotation$Common$Fields$Model$quotePropertyFloorMapper = function (floorType) {
	if (!floorType.$) {
		switch (floorType.a) {
			case 0:
				var _v1 = floorType.a;
				return 0;
			case 1:
				var _v2 = floorType.a;
				return 2;
			default:
				var _v3 = floorType.a;
				return 1;
		}
	} else {
		return 3;
	}
};
var $author$project$Quotation$Common$Fields$Model$quoteResidentialAddress = function (address) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Quotation$Common$Fields$Model$NotRequiredResidentialAddress,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Types$quoteAddressMapper, $author$project$Quotation$Common$Fields$Model$ResidentialAddress),
			address));
};
var $author$project$Quotation$Home$AddressStep$Model$stepResultFromQuote = function (_v0) {
	var information = _v0.nT;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$elm_community$maybe_extra$Maybe$Extra$andMap,
			A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteChildrenMapper, information.nr),
			A2(
				$elm_community$maybe_extra$Maybe$Extra$andMap,
				A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteAdultsMapper, information.np),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$andMap,
					A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteClaimsMapper, information.pn),
					A2(
						$elm_community$maybe_extra$Maybe$Extra$andMap,
						$elm$core$Maybe$Just(
							$author$project$Quotation$Common$Fields$Model$quoteResidentialAddress(information.pq)),
						A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							$elm$core$Maybe$Just(
								$author$project$Quotation$Common$Fields$Model$quotePropertyFloorMapper(information.nw)),
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								A2($elm$core$Maybe$map, $author$project$Quotation$Common$Types$quoteAddressMapper, information.kY),
								$elm$core$Maybe$Just($author$project$Quotation$Home$AddressStep$Model$StepResultData))))))));
};
var $author$project$Quotation$Home$CustomerStep$Model$StepResultData = F9(
	function (nameField, surnameField, genderField, birthDayField, birthCityField, birthCountryField, emailField, phoneField, privacyField) {
		return {ii: birthCityField, ij: birthCountryField, ml: birthDayField, i$: emailField, jl: genderField, ki: nameField, kE: phoneField, o_: privacyField, lD: surnameField};
	});
var $author$project$Quotation$Home$CustomerStep$Model$stepResultFromQuote = F2(
	function (birthCity, _v0) {
		var information = _v0.nT;
		return A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Fields$StepResult$toStepResult,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$andMap,
				$elm$core$Maybe$Just(_List_Nil),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$andMap,
					$elm$core$Maybe$Just(information.oT),
					A2(
						$elm_community$maybe_extra$Maybe$Extra$andMap,
						$elm$core$Maybe$Just(information.fi),
						A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							$elm$core$Maybe$Just(
								$author$project$Quotation$Common$Fields$Model$quoteBirthCountryMapper(information.mj)),
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								$elm$core$Maybe$Just(
									A2($author$project$Quotation$Common$Fields$Model$quoteBirthCityMapper, birthCity, information)),
								A2(
									$elm_community$maybe_extra$Maybe$Extra$andMap,
									$elm$core$Maybe$Just(information.mn),
									A2(
										$elm_community$maybe_extra$Maybe$Extra$andMap,
										$elm$core$Maybe$Just(
											$author$project$Quotation$Common$Fields$Model$quoteGenderMapper(information.ny)),
										A2(
											$elm_community$maybe_extra$Maybe$Extra$andMap,
											$elm$core$Maybe$Just(information.lC),
											A2(
												$elm_community$maybe_extra$Maybe$Extra$andMap,
												$elm$core$Maybe$Just(information.oq),
												$elm$core$Maybe$Just($author$project$Quotation$Home$CustomerStep$Model$StepResultData)))))))))));
	});
var $author$project$Quotation$Home$PropertyStep$Model$StepResultData = F7(
	function (ownershipTypeField, propertyPurposeField, propertyTypeField, buildingAdministratorField, villaTypeField, constructionPeriodField, propertySizeField) {
		return {$9: buildingAdministratorField, iD: constructionPeriodField, kz: ownershipTypeField, k0: propertyPurposeField, k1: propertySizeField, k2: propertyTypeField, lY: villaTypeField};
	});
var $author$project$Quotation$Common$Fields$Model$NotRequiredAdministrator = 2;
var $author$project$Quotation$Common$Fields$Model$WithAdministrator = 0;
var $author$project$Quotation$Common$Fields$Model$WithoutAdministrator = 1;
var $author$project$Quotation$Common$Fields$Model$quoteBuildingAdministratorMapper = function (condominiumType) {
	if (!condominiumType.$) {
		if (condominiumType.a === 1) {
			var _v1 = condominiumType.a;
			return $elm$core$Maybe$Just(0);
		} else {
			var _v2 = condominiumType.a;
			return $elm$core$Maybe$Just(1);
		}
	} else {
		return $elm$core$Maybe$Just(2);
	}
};
var $author$project$Quotation$Common$Fields$Model$Before1970 = 0;
var $author$project$Quotation$Common$Fields$Model$From1970to1979 = 1;
var $author$project$Quotation$Common$Fields$Model$From1980to1989 = 2;
var $author$project$Quotation$Common$Fields$Model$From1990to1999 = 3;
var $author$project$Quotation$Common$Fields$Model$From2000to2009 = 4;
var $author$project$Quotation$Common$Fields$Model$From2010to2019 = 5;
var $author$project$Quotation$Common$Fields$Model$From2020 = 6;
var $author$project$Quotation$Common$Fields$Model$UnknownConstructionPeriod = 7;
var $author$project$Quotation$Common$Fields$Model$quoteConstructionPeriodMapper = function (constructionPeriod) {
	switch (constructionPeriod) {
		case 2:
			return 0;
		case 3:
			return 0;
		case 4:
			return 0;
		case 5:
			return 0;
		case 1:
			return 0;
		case 6:
			return 1;
		case 7:
			return 2;
		case 8:
			return 3;
		case 9:
			return 4;
		case 10:
			return 5;
		case 11:
			return 6;
		default:
			return 7;
	}
};
var $author$project$Quotation$Common$Fields$Model$Owned = 0;
var $author$project$Quotation$Common$Fields$Model$Rented = 1;
var $author$project$Quotation$Common$Fields$Model$quoteOwnershipTypeMapper = function (ownership) {
	switch (ownership) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Common$Fields$Model$MainHouse = 0;
var $author$project$Quotation$Common$Fields$Model$RentedHouse = 2;
var $author$project$Quotation$Common$Fields$Model$SecondHouse = 1;
var $author$project$Quotation$Common$Fields$Model$quotePropertyPurposeMapper = function (usageType) {
	switch (usageType) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 2:
			return $elm$core$Maybe$Just(2);
		case 3:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Common$Fields$Model$Apartment = 1;
var $author$project$Quotation$Common$Fields$Model$Villa = 0;
var $author$project$Quotation$Common$Fields$Model$quotePropertyTypeMapper = function (propertyType) {
	if (!propertyType) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Quotation$Common$Fields$Model$DetachedHouse = 0;
var $author$project$Quotation$Common$Fields$Model$NotRequiredVillaType = 2;
var $author$project$Quotation$Common$Fields$Model$TerracedHouse = 1;
var $author$project$Quotation$Common$Fields$Model$quoteVillaTypeMapper = function (houseType) {
	if (!houseType.$) {
		if (!houseType.a) {
			var _v1 = houseType.a;
			return $elm$core$Maybe$Just(0);
		} else {
			var _v2 = houseType.a;
			return $elm$core$Maybe$Just(1);
		}
	} else {
		return $elm$core$Maybe$Just(2);
	}
};
var $author$project$Quotation$Home$PropertyStep$Model$stepResultFromQuote = function (_v0) {
	var information = _v0.nT;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$elm_community$maybe_extra$Maybe$Extra$andMap,
			information.p3,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$andMap,
				A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quoteConstructionPeriodMapper, information.mP),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$andMap,
					$author$project$Quotation$Common$Fields$Model$quoteVillaTypeMapper(information.qa),
					A2(
						$elm_community$maybe_extra$Maybe$Extra$andMap,
						$author$project$Quotation$Common$Fields$Model$quoteBuildingAdministratorMapper(information.mN),
						A2(
							$elm_community$maybe_extra$Maybe$Extra$andMap,
							A2($elm$core$Maybe$map, $author$project$Quotation$Common$Fields$Model$quotePropertyTypeMapper, information.o4),
							A2(
								$elm_community$maybe_extra$Maybe$Extra$andMap,
								$author$project$Quotation$Common$Fields$Model$quotePropertyPurposeMapper(information.o1),
								A2(
									$elm_community$maybe_extra$Maybe$Extra$andMap,
									$author$project$Quotation$Common$Fields$Model$quoteOwnershipTypeMapper(information.oL),
									$elm$core$Maybe$Just($author$project$Quotation$Home$PropertyStep$Model$StepResultData)))))))));
};
var $author$project$Quotation$Home$Model$hydrateStateFromQuote = F2(
	function (quote, city) {
		return {
			ba: A2(
				$elm$core$Maybe$map,
				$toastal$either$Either$Left,
				$author$project$Quotation$Home$AddressStep$Model$stepResultFromQuote(quote)),
			X: A2(
				$elm$core$Maybe$map,
				$toastal$either$Either$Left,
				A2($author$project$Quotation$Home$CustomerStep$Model$stepResultFromQuote, city, quote)),
			a$: $author$project$Quotation$Home$PropertyStep$Model$stepResultFromQuote(quote)
		};
	});
var $author$project$Quotation$Home$Model$ModelInitialized = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Home$Model$initialHomeModelWithStepAndState = F3(
	function (flags, formState, stepState) {
		return $author$project$Quotation$Home$Model$ModelInitialized(
			{nv: flags, aA: formState, cH: stepState});
	});
var $author$project$Quotation$Home$PropertyStep$Model$ClosedModal = {$: 3};
var $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Common$Fields$Model$BuildingAdministratorFieldChangedOnCheck = 5;
var $author$project$Quotation$Common$Fields$Model$initBuildingAdministratorField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(5);
var $author$project$Quotation$Common$Fields$Model$ConstructionPeriodFieldChangedOnCheck = 2;
var $author$project$Quotation$Common$Fields$Model$initConstructionPeriodField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(2);
var $author$project$Quotation$Common$Fields$Model$OwnershipTypeFieldChangedOnCheck = 0;
var $author$project$Quotation$Common$Fields$Model$initOwnershipTypeField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(0);
var $author$project$Quotation$Common$Fields$Model$PropertyPurposeFieldChangedOnCheck = 1;
var $author$project$Quotation$Common$Fields$Model$initPropertyPurposeField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(1);
var $author$project$Quotation$Common$Fields$Model$PropertySizeFieldChangedOnInput = 6;
var $author$project$Quotation$Common$Fields$Model$initPropertySizeField = $author$project$Quotation$Common$Fields$Model$initInputField(6);
var $author$project$Quotation$Common$Fields$Model$PropertyTypeFieldChangedOnCheck = 3;
var $author$project$Quotation$Common$Fields$Model$initPropertyTypeField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(3);
var $author$project$Quotation$Common$Fields$Model$VillaTypeFieldChangedOnCheck = 4;
var $author$project$Quotation$Common$Fields$Model$initVillaTypeField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(4);
var $author$project$Quotation$Home$PropertyStep$Model$initialData = $author$project$Quotation$Common$Fields$Model$toFormData(
	{
		$9: A2($author$project$Quotation$Common$Fields$Model$initBuildingAdministratorField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		iD: A2($author$project$Quotation$Common$Fields$Model$initConstructionPeriodField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		kz: A2($author$project$Quotation$Common$Fields$Model$initOwnershipTypeField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k0: A2($author$project$Quotation$Common$Fields$Model$initPropertyPurposeField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k1: A2($author$project$Quotation$Common$Fields$Model$initPropertySizeField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k2: A2($author$project$Quotation$Common$Fields$Model$initPropertyTypeField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		lY: A2($author$project$Quotation$Common$Fields$Model$initVillaTypeField, $author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)
	});
var $author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId = 'ownership-type-expandable-field';
var $author$project$Quotation$Home$PropertyStep$Model$initialState = F7(
	function (flags, tokenType, user, isViewInitialized, isDiscountMessageVisible, currentDate, expandedFieldsIds) {
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				i1: $elm$core$Set$singleton($author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId),
				i2: $elm$core$Set$empty,
				i5: expandedFieldsIds,
				nv: flags,
				dv: $author$project$Quotation$Home$PropertyStep$Model$initialData,
				jK: isDiscountMessageVisible,
				dM: false,
				jZ: isViewInitialized,
				kc: $author$project$Quotation$Home$PropertyStep$Model$ClosedModal,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId = 'construction-period-expandable-field';
var $author$project$Quotation$Common$Fields$StepResult$pickBuildingAdministratorField = function (_v0) {
	var buildingAdministratorField = _v0.$9;
	return buildingAdministratorField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickConstructionPeriodField = function (_v0) {
	var constructionPeriodField = _v0.iD;
	return constructionPeriodField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickOwnershipTypeField = function (_v0) {
	var ownershipTypeField = _v0.kz;
	return ownershipTypeField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPropertyPurposeField = function (_v0) {
	var propertyPurposeField = _v0.k0;
	return propertyPurposeField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPropertySizeField = function (_v0) {
	var propertySizeField = _v0.k1;
	return propertySizeField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPropertyTypeField = function (_v0) {
	var propertyTypeField = _v0.k2;
	return propertyTypeField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickVillaTypeField = function (_v0) {
	var villaTypeField = _v0.lY;
	return villaTypeField;
};
var $author$project$Quotation$Home$PropertyStep$Model$initialDataFromResult = function (stepResult) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			$9: A2(
				$author$project$Quotation$Common$Fields$Model$initBuildingAdministratorField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickBuildingAdministratorField(stepResult))),
			iD: A2(
				$author$project$Quotation$Common$Fields$Model$initConstructionPeriodField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickConstructionPeriodField(stepResult))),
			kz: A2(
				$author$project$Quotation$Common$Fields$Model$initOwnershipTypeField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickOwnershipTypeField(stepResult))),
			k0: A2(
				$author$project$Quotation$Common$Fields$Model$initPropertyPurposeField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPropertyPurposeField(stepResult))),
			k1: A2(
				$author$project$Quotation$Common$Fields$Model$initPropertySizeField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$elm$core$String$fromInt(
						$author$project$Quotation$Common$Fields$StepResult$pickPropertySizeField(stepResult)))),
			k2: A2(
				$author$project$Quotation$Common$Fields$Model$initPropertyTypeField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPropertyTypeField(stepResult))),
			lY: A2(
				$author$project$Quotation$Common$Fields$Model$initVillaTypeField,
				$author$project$Quotation$Home$PropertyStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickVillaTypeField(stepResult)))
		});
};
var $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId = 'property-purpose-expandable-field';
var $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId = 'property-size-expandable-field';
var $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId = 'property-type-expandable-field';
var $author$project$Quotation$Home$PropertyStep$Model$initialStateFromResult = F6(
	function (flags, tokenType, user, isDiscountMessageVisible, currentDate, result) {
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				i1: $elm$core$Set$fromList(
					_List_fromArray(
						[$author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId, $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId, $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId, $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId])),
				i2: $elm$core$Set$empty,
				i5: $elm$core$Set$empty,
				nv: flags,
				dv: $author$project$Quotation$Home$PropertyStep$Model$initialDataFromResult(result),
				jK: isDiscountMessageVisible,
				dM: false,
				jZ: false,
				kc: $author$project$Quotation$Home$PropertyStep$Model$ClosedModal,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Home$Model$initialModelFromQuote = F5(
	function (tokenType, user, flags, quote, city) {
		var hydratedState = A2($author$project$Quotation$Home$Model$hydrateStateFromQuote, quote, city);
		var propertyModel = function () {
			var _v0 = hydratedState.a$;
			if (!_v0.$) {
				var propertyStep = _v0.a;
				return A6($author$project$Quotation$Home$PropertyStep$Model$initialStateFromResult, flags, tokenType, user, true, $author$project$Utils$initialDate, propertyStep);
			} else {
				return A7($author$project$Quotation$Home$PropertyStep$Model$initialState, flags, tokenType, user, false, true, $author$project$Utils$initialDate, $elm$core$Set$empty);
			}
		}();
		return A3(
			$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
			flags,
			hydratedState,
			$author$project$Quotation$Home$Model$PropertyStep(propertyModel));
	});
var $author$project$Quotation$Home$Update$initWithQuote = F5(
	function (tokenType, user, flags, quote, city) {
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					$author$project$Quotation$Home$Model$PropertyStepMsg($author$project$Quotation$Home$PropertyStep$Model$Initialize))
				]),
			A5(
				$author$project$Quotation$Home$Model$initialModelFromQuote,
				tokenType,
				user,
				flags,
				quote,
				A2($elm$core$Maybe$map, $author$project$Quotation$Common$Types$ItalianCity, city)));
	});
var $author$project$Quotation$Home$Model$emptyHydrateState = {ba: $elm$core$Maybe$Nothing, X: $elm$core$Maybe$Nothing, a$: $elm$core$Maybe$Nothing};
var $author$project$Quotation$Home$Model$initialHomeModelWithStep = F2(
	function (flags, stepState) {
		return $author$project$Quotation$Home$Model$ModelInitialized(
			{nv: flags, aA: $author$project$Quotation$Home$Model$emptyHydrateState, cH: stepState});
	});
var $author$project$Quotation$Home$Model$initialPropertyStepModel = F3(
	function (tokenType, user, flags) {
		return A2(
			$author$project$Quotation$Home$Model$initialHomeModelWithStep,
			flags,
			$author$project$Quotation$Home$Model$PropertyStep(
				A7($author$project$Quotation$Home$PropertyStep$Model$initialState, flags, tokenType, user, false, true, $author$project$Utils$initialDate, $elm$core$Set$empty)));
	});
var $author$project$Quotation$Home$AddressStep$Model$ClosedModal = {$: 1};
var $author$project$Quotation$Home$AddressStep$Model$FieldChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Common$Fields$Model$PropertyFloorFieldChangedOnCheck = 8;
var $author$project$Quotation$Common$Fields$Model$initPropertyFloorField = $author$project$Quotation$Common$Fields$Model$initRadioGroupField(8);
var $author$project$Quotation$Common$Fields$Model$PropertyAddressFieldChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Common$Fields$Model$PropertyAddressFieldChangedOnSelect = 7;
var $author$project$Quotation$Common$Fields$Model$initPropertyAddressField = A4(
	$author$project$Quotation$Common$Fields$Model$initDebouncedAutocompleteField,
	function ($) {
		return $.jf;
	},
	$author$project$Quotation$Common$Types$alwaysTrueFilter,
	7,
	$author$project$Quotation$Common$Fields$Model$PropertyAddressFieldChanged);
var $author$project$Quotation$Common$Components$AddressFallback$PropertyAddress = 0;
var $author$project$Quotation$Common$Fields$Model$PropertyFallbackAddressFieldsChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Common$Fields$Model$initPropertyAddressFallbackField = $author$project$Quotation$Common$Fields$Model$initAddressFallbackField($author$project$Quotation$Common$Fields$Model$PropertyFallbackAddressFieldsChanged);
var $author$project$Quotation$Home$AddressStep$Model$stepPrefixValue = 'address_and_details_step';
var $author$project$Quotation$Home$AddressStep$Model$initialPropertyAddressFallback = A4($author$project$Quotation$Common$Fields$Model$initPropertyAddressFallbackField, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, $author$project$Utils$GTM$defaultHomeAndFamilyParameterName, $author$project$Quotation$Home$AddressStep$Model$stepPrefixValue, 0);
var $author$project$Quotation$Home$AddressStep$Model$initialResidentialAddressFallback = A4($author$project$Quotation$Common$Fields$Model$initResidentialAddressFallbackField, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, $author$project$Utils$GTM$defaultHomeAndFamilyParameterName, $author$project$Quotation$Home$AddressStep$Model$stepPrefixValue, 1);
var $author$project$Quotation$Home$AddressStep$Model$initialData = $author$project$Quotation$Common$Fields$Model$toFormData(
	{
		h8: A2($author$project$Quotation$Common$Fields$Model$initAdultsField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		it: A2($author$project$Quotation$Common$Fields$Model$initChildrenField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		kZ: $author$project$Quotation$Home$AddressStep$Model$initialPropertyAddressFallback,
		k_: A3($author$project$Quotation$Common$Fields$Model$initPropertyAddressField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k$: A2($author$project$Quotation$Common$Fields$Model$initPropertyFloorField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		k8: A2($author$project$Quotation$Common$Fields$Model$initReportedClaimsField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
		lb: $author$project$Quotation$Home$AddressStep$Model$initialResidentialAddressFallback,
		lc: A3($author$project$Quotation$Common$Fields$Model$initResidentialAddressField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)
	});
var $author$project$Quotation$Common$Fields$StepResult$pickOptionalResidentialAddressField = function (_v0) {
	var residentialAddressField = _v0.lc;
	return residentialAddressField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPropertyAddressField = function (_v0) {
	var propertyAddressField = _v0.k_;
	return propertyAddressField;
};
var $author$project$Quotation$Common$Fields$StepResult$pickPropertyFloorField = function (_v0) {
	var propertyFloorField = _v0.k$;
	return propertyFloorField;
};
var $author$project$Quotation$Home$AddressStep$Model$initialDataFromResult = function (stepResult) {
	var residentialAutocompleteValue = function () {
		var _v0 = $author$project$Quotation$Common$Fields$StepResult$pickOptionalResidentialAddressField(stepResult);
		if (_v0.$ === 1) {
			return $author$project$Quotation$Common$Fields$Model$EmptyField;
		} else {
			var address = _v0.a;
			return $author$project$Quotation$Common$Fields$Model$FilledField(address);
		}
	}();
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			h8: A2(
				$author$project$Quotation$Common$Fields$Model$initAdultsField,
				$author$project$Quotation$Home$AddressStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper(
						$author$project$Quotation$Common$Fields$StepResult$pickAdultsField(stepResult)))),
			it: A2(
				$author$project$Quotation$Common$Fields$Model$initChildrenField,
				$author$project$Quotation$Home$AddressStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper(
						$author$project$Quotation$Common$Fields$StepResult$pickChildrenField(stepResult)))),
			kZ: $author$project$Quotation$Home$AddressStep$Model$initialPropertyAddressFallback,
			k_: A3(
				$author$project$Quotation$Common$Fields$Model$initPropertyAddressField,
				$author$project$Quotation$Home$AddressStep$Model$OnFieldChanged,
				$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPropertyAddressField(stepResult))),
			k$: A2(
				$author$project$Quotation$Common$Fields$Model$initPropertyFloorField,
				$author$project$Quotation$Home$AddressStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPropertyFloorField(stepResult))),
			k8: A2(
				$author$project$Quotation$Common$Fields$Model$initReportedClaimsField,
				$author$project$Quotation$Home$AddressStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickReportedClaimsField(stepResult))),
			lb: $author$project$Quotation$Home$AddressStep$Model$initialResidentialAddressFallback,
			lc: A3($author$project$Quotation$Common$Fields$Model$initResidentialAddressField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, residentialAutocompleteValue)
		});
};
var $elm_community$result_extra$Result$Extra$extract = F2(
	function (f, x) {
		if (!x.$) {
			var a = x.a;
			return a;
		} else {
			var e = x.a;
			return f(e);
		}
	});
var $author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$StepResult$pickPropertyTypeField,
	$elm$core$Basics$eq(1));
var $author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired = function (propertyStepResult) {
	return A2(
		$elm$core$List$member,
		$author$project$Quotation$Common$Fields$StepResult$pickPropertyPurposeField(propertyStepResult),
		_List_fromArray(
			[1, 2]));
};
var $author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId = 'property-address-expandable-field';
var $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId = 'property-floor-expandable-field';
var $author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId = 'residential-address-expandable-field';
var $author$project$Quotation$Common$Fields$Model$pickPropertyAddressField = function (_v0) {
	var propertyAddressField = _v0.k_;
	return propertyAddressField;
};
var $author$project$Quotation$Common$Fields$Model$propertyAddressFieldValidation = $author$project$Quotation$Common$Validation$placeAutocompleteValidation;
var $author$project$Quotation$Common$StepModel$validatedPropertyAddressField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField,
	A2($elm$core$Basics$composeR, $author$project$Components$DebouncedAutocomplete$getValue, $author$project$Quotation$Common$Fields$Model$propertyAddressFieldValidation));
var $author$project$Quotation$Common$Fields$Model$pickPropertyFloorField = function (_v0) {
	var propertyFloorField = _v0.k$;
	return propertyFloorField;
};
var $author$project$Quotation$Common$Fields$Model$propertyFloorFieldValidation = function (isPropertyFloorRequired) {
	return isPropertyFloorRequired ? $author$project$Quotation$Common$Validation$requiredRadioCard : $elm$core$Basics$always(
		$elm$core$Result$Ok(3));
};
var $author$project$Quotation$Common$StepModel$validatedPropertyFloorField = function (isPropertyFloorRequired) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$pickPropertyFloorField,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue,
			$author$project$Quotation$Common$Fields$Model$propertyFloorFieldValidation(isPropertyFloorRequired)));
};
var $author$project$Quotation$Home$AddressStep$Model$initialStepDataEnabledFieldsIds = F2(
	function (stepData, propertyStepResult) {
		return $elm_community$list_extra$List$Extra$unique(
			A2(
				$author$project$Utils$applyTupleAsParameters,
				$elm$core$List$append,
				A3(
					$elm$core$Tuple$mapBoth,
					$elm$core$List$map(
						$elm_community$result_extra$Result$Extra$extract($elm$core$Basics$identity)),
					$elm$core$List$map(
						$elm_community$result_extra$Result$Extra$extract($elm$core$Basics$identity)),
					A2(
						$elm$core$Tuple$mapSecond,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$head,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$map($elm$core$List$singleton),
								$elm$core$Maybe$withDefault(_List_Nil))),
						A2(
							$elm$core$List$partition,
							$elm_community$result_extra$Result$Extra$isOk,
							_List_fromArray(
								[
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId),
										$author$project$Quotation$Common$StepModel$validatedPropertyAddressField(stepData))),
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId),
										A2(
											$author$project$Quotation$Common$StepModel$validatedPropertyFloorField,
											$author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(propertyStepResult),
											stepData))),
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId),
										A2(
											$author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField,
											$author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(propertyStepResult),
											stepData))),
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId),
										$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData))),
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
										$author$project$Quotation$Common$StepModel$validatedAdultsField(stepData))),
									A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
									A2(
										$elm$core$Result$map,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
										$author$project$Quotation$Common$StepModel$validatedChildrenField(stepData)))
								]))))));
	});
var $author$project$Quotation$Home$AddressStep$Model$isPropertyFloorInStaleState = F2(
	function (previousStepResult, data) {
		return ($author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(previousStepResult) && _Utils_eq(
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickPropertyFloorField(data)),
			$elm$core$Maybe$Just(3))) || ((!$author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(previousStepResult)) && (!_Utils_eq(
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickPropertyFloorField(data)),
			$elm$core$Maybe$Just(3))));
	});
var $author$project$Quotation$Home$AddressStep$Model$isResidentialAddressInStaleState = F2(
	function (previousStepResult, data) {
		return (!$author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(previousStepResult)) && $elm_community$maybe_extra$Maybe$Extra$isJust(
			$author$project$Components$DebouncedAutocomplete$getValue(
				$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(data)));
	});
var $author$project$Quotation$Common$Fields$Model$setPropertyFloor = F2(
	function (propertyFloorField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k$: propertyFloorField});
	});
var $author$project$Quotation$Home$AddressStep$Model$initialState = F6(
	function (flags, tokenType, user, currentDate, previousStepResult, initialStepData) {
		var initialFormDataResult = A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Home$AddressStep$Model$initialDataFromResult,
			A2($elm$core$Maybe$andThen, $toastal$either$Either$leftToMaybe, initialStepData));
		var initialFormData = A2($elm$core$Maybe$andThen, $toastal$either$Either$rightToMaybe, initialStepData);
		var conditionalResidentialAddressReset = function (data) {
			return A3(
				$author$project$Utils$applyIf,
				A2($author$project$Quotation$Home$AddressStep$Model$isResidentialAddressInStaleState, previousStepResult, data),
				$author$project$Quotation$Common$Fields$Model$setResidentialAddress(
					A3($author$project$Quotation$Common$Fields$Model$initResidentialAddressField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)),
				data);
		};
		var conditionalPropertyFloorReset = function (data) {
			return A3(
				$author$project$Utils$applyIf,
				A2($author$project$Quotation$Home$AddressStep$Model$isPropertyFloorInStaleState, previousStepResult, data),
				$author$project$Quotation$Common$Fields$Model$setPropertyFloor(
					A2($author$project$Quotation$Common$Fields$Model$initPropertyFloorField, $author$project$Quotation$Home$AddressStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)),
				data);
		};
		var formData = conditionalResidentialAddressReset(
			conditionalPropertyFloorReset(
				A2(
					$elm$core$Maybe$withDefault,
					$author$project$Quotation$Home$AddressStep$Model$initialData,
					A2($elm_community$maybe_extra$Maybe$Extra$or, initialFormData, initialFormDataResult))));
		var enabledFields = $elm_community$maybe_extra$Maybe$Extra$isJust(initialStepData) ? $elm$core$Set$fromList(
			A2($author$project$Quotation$Home$AddressStep$Model$initialStepDataEnabledFieldsIds, formData, previousStepResult)) : $elm$core$Set$singleton($author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId);
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				i1: enabledFields,
				i2: $elm$core$Set$empty,
				i5: $elm$core$Set$empty,
				nv: flags,
				dv: formData,
				jK: false,
				dM: false,
				jS: false,
				jT: false,
				jZ: false,
				kc: $author$project$Quotation$Home$AddressStep$Model$ClosedModal,
				kL: $elm$core$Set$empty,
				o3: previousStepResult,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Home$CustomerStep$Model$ClosedModal = 1;
var $author$project$Quotation$Home$CustomerStep$Model$FieldChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$initialData = function (initialEmail) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			ii: A3($author$project$Quotation$Common$Fields$Model$initBirthCityField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ij: A3($author$project$Quotation$Common$Fields$Model$initBirthCountryField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ik: A2($author$project$Quotation$Common$Fields$Model$initBirthdayField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			iB: A2($author$project$Quotation$Common$Fields$Model$initCommercialPurposesField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			i$: A2(
				$author$project$Quotation$Common$Fields$Model$initEmailField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					A2($elm$core$Maybe$withDefault, '', initialEmail))),
			jl: A2($author$project$Quotation$Common$Fields$Model$initGenderField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			ki: A2($author$project$Quotation$Common$Fields$Model$initNameField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			kE: A2($author$project$Quotation$Common$Fields$Model$initPhoneField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			kT: A2($author$project$Quotation$Common$Fields$Model$initPrivacyPolicyField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField),
			lD: A2($author$project$Quotation$Common$Fields$Model$initSurnameField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Common$Fields$Model$EmptyField)
		});
};
var $author$project$Quotation$Home$CustomerStep$Model$initialDataFromResult = function (stepResult) {
	return $author$project$Quotation$Common$Fields$Model$toFormData(
		{
			ii: A3(
				$author$project$Quotation$Common$Fields$Model$initBirthCityField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Home$CustomerStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(stepResult))),
			ij: A3(
				$author$project$Quotation$Common$Fields$Model$initBirthCountryField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Home$CustomerStep$Model$FieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCountryField(stepResult))),
			ik: A2(
				$author$project$Quotation$Common$Fields$Model$initBirthdayField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$justinmimbs$date$Date$toIsoString(
						$author$project$Quotation$Common$Fields$StepResult$pickBirthDayField(stepResult)))),
			iB: A2(
				$author$project$Quotation$Common$Fields$Model$initCommercialPurposesField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$resultToCommercialPurposesFieldValue(stepResult))),
			i$: A2(
				$author$project$Quotation$Common$Fields$Model$initEmailField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickEmailField(stepResult))),
			jl: A2(
				$author$project$Quotation$Common$Fields$Model$initGenderField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickGenderField(stepResult))),
			ki: A2(
				$author$project$Quotation$Common$Fields$Model$initNameField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickNameField(stepResult))),
			kE: A2(
				$author$project$Quotation$Common$Fields$Model$initPhoneField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickPhoneField(stepResult))),
			kT: A2(
				$author$project$Quotation$Common$Fields$Model$initPrivacyPolicyField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$resultToPrivacyPolicyFieldValue(stepResult))),
			lD: A2(
				$author$project$Quotation$Common$Fields$Model$initSurnameField,
				$author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged,
				$author$project$Quotation$Common$Fields$Model$FilledField(
					$author$project$Quotation$Common$Fields$StepResult$pickSurnameField(stepResult)))
		});
};
var $author$project$Quotation$Home$CustomerStep$Model$initialState = F7(
	function (flags, tokenType, user, currentDate, propertyStepResult, addressStepResult, initialStepData) {
		var initialFormDataResult = A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Home$CustomerStep$Model$initialDataFromResult,
			A2($elm$core$Maybe$andThen, $toastal$either$Either$leftToMaybe, initialStepData));
		var initialFormData = A2($elm$core$Maybe$andThen, $toastal$either$Either$rightToMaybe, initialStepData);
		var formData = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Quotation$Home$CustomerStep$Model$initialData(
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fi;
					},
					user)),
			A2($elm_community$maybe_extra$Maybe$Extra$or, initialFormData, initialFormDataResult));
		return $author$project$Quotation$Common$StepModel$toStepModel(
			{
				l9: addressStepResult,
				iH: currentDate,
				iI: $elm$random$Random$initialSeed(
					$author$project$Types$pickSeed(flags)),
				nv: flags,
				dv: formData,
				je: $author$project$RFQDTS$Http$Authenticator$AuthResponse$NotAsked,
				jK: false,
				dM: false,
				jZ: false,
				kc: 1,
				o3: propertyStepResult,
				lK: tokenType,
				p6: user
			});
	});
var $author$project$Quotation$Home$Model$AddressStep = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Home$Update$initialAddressStepModel = F6(
	function (flags, tokenType, user, hydrateState, addressStepState, propertyStepState) {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Home$Model$AddressStepMsg($author$project$Quotation$Home$AddressStep$Model$Initialize))
					]),
				A3(
					$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
					flags,
					hydrateState,
					$author$project$Quotation$Home$Model$AddressStep(
						A6($author$project$Quotation$Home$AddressStep$Model$initialState, flags, tokenType, user, $author$project$Utils$initialDate, propertyStepState, addressStepState)))));
	});
var $author$project$Quotation$Home$Model$CustomerStep = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Home$Update$initialCustomerStepModel = F7(
	function (flags, tokenType, user, hydrateState, customerStepState, propertyStepState, addressStepState) {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Home$Model$CustomerStepMsg($author$project$Quotation$Home$CustomerStep$Model$Initialize))
					]),
				A3(
					$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
					flags,
					hydrateState,
					$author$project$Quotation$Home$Model$CustomerStep(
						A7($author$project$Quotation$Home$CustomerStep$Model$initialState, flags, tokenType, user, $author$project$Utils$initialDate, propertyStepState, addressStepState, customerStepState)))));
	});
var $author$project$Quotation$Home$Update$initialPropertyStepModel = F5(
	function (flags, tokenType, user, hydrateState, stepState) {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						$author$project$Quotation$Home$Model$PropertyStepMsg($author$project$Quotation$Home$PropertyStep$Model$Initialize))
					]),
				A3(
					$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
					flags,
					hydrateState,
					$author$project$Quotation$Home$Model$PropertyStep(
						A6($author$project$Quotation$Home$PropertyStep$Model$initialStateFromResult, flags, tokenType, user, true, $author$project$Utils$initialDate, stepState)))));
	});
var $author$project$Quotation$Home$Update$redirectModel = function (flags) {
	return A2(
		$author$project$Utils$UpdateReturn$withPushUrl,
		$author$project$Routing$NotFound,
		$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				$author$project$Quotation$Home$Model$ModelNotInitialized(flags))));
};
var $author$project$Quotation$Home$Update$initialModelFromFormHydrateState = F4(
	function (tokenType, user, step, model) {
		var flags = model.nv;
		var hydrateState = model.aA;
		switch (step) {
			case 1:
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A3($author$project$Quotation$Home$Model$initialPropertyStepModel, tokenType, user, flags))),
					A2(
						$elm$core$Maybe$map,
						A4($author$project$Quotation$Home$Update$initialPropertyStepModel, flags, tokenType, user, hydrateState),
						hydrateState.a$));
			case 2:
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Quotation$Home$Update$redirectModel(flags),
					A2(
						$elm$core$Maybe$map,
						A5($author$project$Quotation$Home$Update$initialAddressStepModel, flags, tokenType, user, hydrateState, hydrateState.ba),
						hydrateState.a$));
			case 3:
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Quotation$Home$Update$redirectModel(flags),
					A3(
						$elm$core$Maybe$map2,
						A5($author$project$Quotation$Home$Update$initialCustomerStepModel, flags, tokenType, user, hydrateState, hydrateState.X),
						hydrateState.a$,
						A2($elm$core$Maybe$andThen, $toastal$either$Either$leftToMaybe, hydrateState.ba)));
			default:
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotFound,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							$author$project$Quotation$Home$Model$ModelInitialized(model))));
		}
	});
var $author$project$Quotation$Home$Update$maybeChangeStep = F4(
	function (tokenType, user, step, model) {
		var _v0 = _Utils_Tuple2(step, model.cH);
		_v0$3:
		while (true) {
			switch (_v0.b.$) {
				case 0:
					if (_v0.a === 1) {
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$Model$PropertyStepMsg($author$project$Quotation$Home$PropertyStep$Model$Initialize))
									]),
								$author$project$Quotation$Home$Model$ModelInitialized(model)));
					} else {
						break _v0$3;
					}
				case 1:
					if (_v0.a === 2) {
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$Model$AddressStepMsg($author$project$Quotation$Home$AddressStep$Model$Initialize))
									]),
								$author$project$Quotation$Home$Model$ModelInitialized(model)));
					} else {
						break _v0$3;
					}
				default:
					if (_v0.a === 3) {
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$Model$CustomerStepMsg($author$project$Quotation$Home$CustomerStep$Model$Initialize))
									]),
								$author$project$Quotation$Home$Model$ModelInitialized(model)));
					} else {
						break _v0$3;
					}
			}
		}
		return A4($author$project$Quotation$Home$Update$initialModelFromFormHydrateState, tokenType, user, step, model);
	});
var $author$project$Quotation$Home$Model$pickStepState = function (model) {
	if (model.$ === 2) {
		var stepState = model.a.cH;
		return $elm$core$Maybe$Just(stepState);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Home$Model$pickAddressStepModel = function (model) {
	var _v0 = $author$project$Quotation$Home$Model$pickStepState(model);
	if ((!_v0.$) && (_v0.a.$ === 1)) {
		var addressStepModel = _v0.a.a;
		return $elm$core$Maybe$Just(addressStepModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Home$Model$pickCustomerStepModel = function (model) {
	var _v0 = $author$project$Quotation$Home$Model$pickStepState(model);
	if ((!_v0.$) && (_v0.a.$ === 2)) {
		var customerStepModel = _v0.a.a;
		return $elm$core$Maybe$Just(customerStepModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Home$Model$pickHydrateState = function (model) {
	if (model.$ === 2) {
		var hydrateState = model.a.aA;
		return $elm$core$Maybe$Just(hydrateState);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Home$Model$pickPropertyStepModel = function (model) {
	var _v0 = $author$project$Quotation$Home$Model$pickStepState(model);
	if ((!_v0.$) && (!_v0.a.$)) {
		var propertyStepModel = _v0.a.a;
		return $elm$core$Maybe$Just(propertyStepModel);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Quotation$Home$Model$setAddressHydrateState = F2(
	function (stepState, formState) {
		return _Utils_update(
			formState,
			{
				ba: $elm$core$Maybe$Just(stepState)
			});
	});
var $author$project$Quotation$Home$Model$setAddressStepModel = F2(
	function (model, addressStepModel) {
		if (model.$ === 2) {
			var hydrateState = model.a.aA;
			var flags = model.a.nv;
			return A3(
				$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
				flags,
				hydrateState,
				$author$project$Quotation$Home$Model$AddressStep(addressStepModel));
		} else {
			return A2(
				$author$project$Quotation$Home$Model$initialHomeModelWithStep,
				$author$project$Quotation$Home$Model$pickFlags(model),
				$author$project$Quotation$Home$Model$AddressStep(addressStepModel));
		}
	});
var $author$project$Quotation$Home$Model$setCustomerHydrateState = F2(
	function (stepState, formState) {
		return _Utils_update(
			formState,
			{
				X: $elm$core$Maybe$Just(stepState)
			});
	});
var $author$project$Quotation$Home$Model$setCustomerStepModel = F2(
	function (model, customerStepModel) {
		if (model.$ === 2) {
			var hydrateState = model.a.aA;
			var flags = model.a.nv;
			return A3(
				$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
				flags,
				hydrateState,
				$author$project$Quotation$Home$Model$CustomerStep(customerStepModel));
		} else {
			return A2(
				$author$project$Quotation$Home$Model$initialHomeModelWithStep,
				$author$project$Quotation$Home$Model$pickFlags(model),
				$author$project$Quotation$Home$Model$CustomerStep(customerStepModel));
		}
	});
var $author$project$Quotation$Home$Model$setPropertyHydrateState = F2(
	function (stepState, formState) {
		return _Utils_update(
			formState,
			{
				a$: $elm$core$Maybe$Just(stepState)
			});
	});
var $author$project$Quotation$Home$Model$setPropertyStepModel = F2(
	function (model, propertyStepModel) {
		if (model.$ === 2) {
			var hydrateState = model.a.aA;
			var flags = model.a.nv;
			return A3(
				$author$project$Quotation$Home$Model$initialHomeModelWithStepAndState,
				flags,
				hydrateState,
				$author$project$Quotation$Home$Model$PropertyStep(propertyStepModel));
		} else {
			return A2(
				$author$project$Quotation$Home$Model$initialHomeModelWithStep,
				$author$project$Quotation$Home$Model$pickFlags(model),
				$author$project$Quotation$Home$Model$PropertyStep(propertyStepModel));
		}
	});
var $author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation = function (a) {
	return {$: 10, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$InitializeView = {$: 1};
var $author$project$Quotation$Home$AddressStep$Model$Response = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$SearchPlaceResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$SearchPropertyAddressCityResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$SearchResidentialAddressCityResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$SearchResidentialPlaceResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Common$Fields$Model$closeAdultsSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setAdults,
		$author$project$Pyxis$Components$Field$Select$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickAdultsField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$closeChildrenSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setChildren,
		$author$project$Pyxis$Components$Field$Select$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickChildrenField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$setPropertyAddress = F2(
	function (propertyAddressField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k_: propertyAddressField});
	});
var $author$project$Quotation$Common$Fields$Model$closePropertyAddressSelect = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setPropertyAddress,
		$author$project$Components$DebouncedAutocomplete$setDropdownClosed(
			$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(formData)),
		formData);
};
var $author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField = function (_v0) {
	var propertyAddressFallbackField = _v0.kZ;
	return propertyAddressFallbackField;
};
var $author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback = F2(
	function (propertyAddressFallbackField, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{kZ: propertyAddressFallbackField});
	});
var $author$project$Quotation$Common$Fields$Model$propertyAddressFallbackMapper = F2(
	function (mapper, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback,
			mapper(
				$author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField(formData)),
			formData);
	});
var $author$project$Quotation$Home$AddressStep$Model$closeAllSelect = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$residentialAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$closeAllSelect),
		A2(
			$author$project$Quotation$Common$StepModel$mapFormData,
			$author$project$Quotation$Common$Fields$Model$propertyAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$closeAllSelect),
			A2(
				$author$project$Quotation$Common$StepModel$mapFormData,
				$author$project$Quotation$Common$Fields$Model$closeResidentialAddressSelect,
				A2(
					$author$project$Quotation$Common$StepModel$mapFormData,
					$author$project$Quotation$Common$Fields$Model$closePropertyAddressSelect,
					A2(
						$author$project$Quotation$Common$StepModel$mapFormData,
						$author$project$Quotation$Common$Fields$Model$closeChildrenSelect,
						A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$closeAdultsSelect, model))))));
};
var $author$project$Quotation$Home$AddressStep$Update$conditionalFieldInfoModalAnalyticsCmd = function (fieldInfoModalState) {
	if (!fieldInfoModalState.$) {
		var fieldInfoModal = fieldInfoModalState.a;
		return $author$project$Utils$send(
			$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
				{
					bb: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
					ay: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
					n3: true
				}));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Quotation$Home$AddressStep$Model$ManualAddressToggleFromAutocomplete = 0;
var $author$project$Quotation$Home$AddressStep$Model$ManualAddressToggleFromLink = 1;
var $author$project$Quotation$Common$Analytics$propertyAddressManualAutocompleteParameter = 'open_address_manual_input_from_infobox';
var $author$project$Quotation$Common$Analytics$propertyAddressManualLinkParameter = 'open_address_manual_input_from_autocomplete';
var $author$project$Quotation$Home$AddressStep$Update$conditionalManualPropertyAddressCmd = F2(
	function (manualAddressToggleType, isManual) {
		return (isManual && (!manualAddressToggleType)) ? $author$project$Utils$send(
			$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$propertyAddressManualAutocompleteParameter, ay: $author$project$Quotation$Common$Analytics$propertyAddressManualAutocompleteParameter, n3: true})) : ((isManual && (manualAddressToggleType === 1)) ? $author$project$Utils$send(
			$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$propertyAddressManualLinkParameter, ay: $author$project$Quotation$Common$Analytics$propertyAddressManualLinkParameter, n3: true})) : $elm$core$Platform$Cmd$none);
	});
var $author$project$Quotation$Home$AddressStep$Update$conditionalManualResidentialAddressCmd = F2(
	function (manualAddressToggleType, isManual) {
		return (isManual && (!manualAddressToggleType)) ? $author$project$Utils$send(
			$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$residentialAddressManualAutocompleteParameter, ay: $author$project$Quotation$Common$Analytics$residentialAddressManualAutocompleteParameter, n3: true})) : ((isManual && (manualAddressToggleType === 1)) ? $author$project$Utils$send(
			$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
				{bb: $author$project$Quotation$Common$Analytics$residentialAddressManualLinkParameter, ay: $author$project$Quotation$Common$Analytics$residentialAddressManualLinkParameter, n3: true})) : $elm$core$Platform$Cmd$none);
	});
var $author$project$Quotation$Common$StepModel$setIsPropertyAddressManual = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{jS: value});
	});
var $author$project$Quotation$Common$Updaters$updatePropertyAddressValue = A2($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldValue, $author$project$Quotation$Common$Fields$Model$pickPropertyAddressField, $author$project$Quotation$Common$Fields$Model$setPropertyAddress);
var $author$project$Quotation$Common$StepModel$pickPropertyStepResult = function (_v0) {
	var propertyStepResult = _v0.o3;
	return propertyStepResult;
};
var $author$project$Quotation$Home$AddressStep$Model$firstInvalidExpandableFieldId = function (stepState) {
	var stepData = $author$project$Quotation$Common$StepModel$pickFormData(stepState);
	var residentialAddressRequired = $author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(
		$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState));
	var propertyFloorRequired = $author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(
		$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState));
	return $elm_community$result_extra$Result$Extra$error(
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			A2(
				$elm$core$Result$mapError,
				$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
				$author$project$Quotation$Common$StepModel$validatedChildrenField(stepData)),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				A2(
					$elm$core$Result$mapError,
					$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId),
					$author$project$Quotation$Common$StepModel$validatedAdultsField(stepData)),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					A2(
						$elm$core$Result$mapError,
						$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId),
						$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData)),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						A2(
							$elm$core$Result$mapError,
							$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId),
							A2($author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField, residentialAddressRequired, stepData)),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2(
								$elm$core$Result$mapError,
								$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId),
								A2($author$project$Quotation$Common$StepModel$validatedPropertyFloorField, propertyFloorRequired, stepData)),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId),
									$author$project$Quotation$Common$StepModel$validatedPropertyAddressField(stepData)),
								$elm$core$Result$Ok($author$project$Quotation$Home$AddressStep$Model$StepResultData))))))));
};
var $author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd = F3(
	function (isOtherFieldExpanded, currentFieldId, model) {
		var postponedExpandableFieldsUpdate = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$View$ExpandableField$StateUpdate, true),
					$elm$core$List$singleton),
				$author$project$Quotation$Home$AddressStep$Model$firstInvalidExpandableFieldId(model)));
		var expandableFieldsUpdate = _List_fromArray(
			[
				A2($author$project$Quotation$Common$View$ExpandableField$StateUpdate, currentFieldId, false)
			]);
		return isOtherFieldExpanded ? A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					$author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation(expandableFieldsUpdate)),
					A2(
					$author$project$Utils$delayCmd,
					$author$project$Quotation$Common$View$ExpandableField$animationDelayMs + $author$project$Quotation$Common$View$ExpandableField$animationDurationMs,
					$author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation(postponedExpandableFieldsUpdate))
				]),
			model) : $author$project$Utils$withoutCommands(model);
	});
var $author$project$Quotation$Home$AddressStep$Update$conditionallyUpdatePropertyAddress = function (model) {
	var _v0 = $author$project$Quotation$Common$Components$AddressFallback$pickResult(
		$author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(model)));
	if (!_v0.$) {
		var result = _v0.a;
		return A2(
			$author$project$Utils$addCommand,
			$author$project$Utils$toggleBlockScrollingClass(false),
			A3(
				$author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd,
				true,
				$author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
				A2(
					$author$project$Quotation$Common$StepModel$setIsPropertyAddressManual,
					false,
					A2(
						$author$project$Quotation$Common$StepModel$mapFormData,
						$author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback($author$project$Quotation$Home$AddressStep$Model$initialPropertyAddressFallback),
						A2($author$project$Quotation$Common$Updaters$updatePropertyAddressValue, result, model)))));
	} else {
		return $author$project$Utils$withoutCommands(model);
	}
};
var $author$project$Quotation$Home$AddressStep$Update$conditionallyUpdateResidentialAddress = function (model) {
	var _v0 = $author$project$Quotation$Common$Components$AddressFallback$pickResult(
		$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(model)));
	if (!_v0.$) {
		var result = _v0.a;
		return A2(
			$author$project$Utils$addCommand,
			$author$project$Utils$toggleBlockScrollingClass(false),
			A3(
				$author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd,
				true,
				$author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
				A2(
					$author$project$Quotation$Common$StepModel$setIsResidentialAddressManual,
					false,
					A2(
						$author$project$Quotation$Common$StepModel$mapFormData,
						$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback($author$project$Quotation$Home$AddressStep$Model$initialResidentialAddressFallback),
						A2($author$project$Quotation$Common$Updaters$updateResidentialAddressValue, result, model)))));
	} else {
		return $author$project$Utils$withoutCommands(model);
	}
};
var $author$project$Quotation$Common$Analytics$propertyFloorParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_ground_floor';
		case 1:
			return '_top_floor';
		case 2:
			return '_other_floor';
		default:
			return '_not_required';
	}
};
var $author$project$Quotation$Common$Analytics$getPropertyFloorParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$propertyFloorParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickPropertyFloorField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Home$AddressStep$Model$GoBackToPropertyStep = F6(
	function (a, b, c, d, e, f) {
		return {$: 16, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$Quotation$Home$AddressStep$Update$goBackToPropertyStep = function (model) {
	return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
		A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					A6(
						$author$project$Quotation$Home$AddressStep$Model$GoBackToPropertyStep,
						$author$project$Quotation$Common$StepModel$pickFlags(model),
						$author$project$Quotation$Common$StepModel$pickTokenType(model),
						$author$project$Quotation$Common$StepModel$pickUser(model),
						$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
						$author$project$Quotation$Common$StepModel$pickPropertyStepResult(model),
						$author$project$Quotation$Common$StepModel$pickFormData(model))),
					$author$project$Utils$send(
					$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
						{bb: $author$project$Quotation$Common$Analytics$backBtnParameter, ay: $author$project$Quotation$Common$Analytics$backBtnParameter, n3: true}))
				]),
			model));
};
var $author$project$Quotation$Common$Types$isPlaceComplete = function (_v0) {
	var address = _v0.bP;
	var addressNumber = _v0.l8;
	var city = _v0.my;
	var zipCode = _v0.qg;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(address) && ($elm_community$maybe_extra$Maybe$Extra$isJust(addressNumber) && ($elm_community$maybe_extra$Maybe$Extra$isJust(city) && $elm_community$maybe_extra$Maybe$Extra$isJust(zipCode)));
};
var $author$project$Quotation$Common$StepModel$isPropertyAddressFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedPropertyAddressField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isPropertyFloorFieldValid = function (isPropertyFloorRequired) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickFormData,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$StepModel$validatedPropertyFloorField(isPropertyFloorRequired),
			$elm_community$result_extra$Result$Extra$isOk));
};
var $author$project$Quotation$Home$AddressStep$Model$NoOp = {$: 17};
var $author$project$Quotation$Home$AddressStep$Model$ProceedToCustomerStep = F6(
	function (a, b, c, d, e, f) {
		return {$: 14, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$Quotation$Home$AddressStep$Model$buildStepResult = F2(
	function (stepState, stepData) {
		var residentialAddressRequired = $author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(
			$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState));
		var propertyFloorRequired = $author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(
			$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState));
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$author$project$Quotation$Common$StepModel$validatedChildrenField(stepData),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$StepModel$validatedAdultsField(stepData),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						A2($author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField, residentialAddressRequired, stepData),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2($author$project$Quotation$Common$StepModel$validatedPropertyFloorField, propertyFloorRequired, stepData),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								$author$project$Quotation$Common$StepModel$validatedPropertyAddressField(stepData),
								$elm$core$Result$Ok($author$project$Quotation$Home$AddressStep$Model$StepResultData)))))));
	});
var $author$project$Quotation$Home$AddressStep$Model$getStepResult = function (stepState) {
	return A2(
		$elm$core$Result$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$author$project$Quotation$Home$AddressStep$Model$buildStepResult,
			stepState,
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Home$AddressStep$Update$proceedFromAddressStep = function (model) {
	var _v0 = $author$project$Quotation$Home$AddressStep$Model$getStepResult(model);
	if (!_v0.$) {
		var addressStepResult = _v0.a;
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						A6(
							$author$project$Quotation$Home$AddressStep$Model$ProceedToCustomerStep,
							$author$project$Quotation$Common$StepModel$pickFlags(model),
							$author$project$Quotation$Common$StepModel$pickTokenType(model),
							$author$project$Quotation$Common$StepModel$pickUser(model),
							$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
							$author$project$Quotation$Common$StepModel$pickPropertyStepResult(model),
							addressStepResult)),
						$author$project$Utils$send(
						$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
							{bb: $author$project$Quotation$Common$Analytics$proceedBtnParameter, ay: $author$project$Quotation$Common$Analytics$proceedBtnParameter, n3: true}))
					]),
				model));
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						A4(
						$author$project$Quotation$Common$Command$conditionalFirstInvalidFieldExpansionCmd,
						$author$project$Quotation$Home$AddressStep$Model$firstInvalidExpandableFieldId(model),
						$author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation,
						$author$project$Quotation$Home$AddressStep$Model$NoOp,
						model)
					]),
				A2(
					$author$project$Quotation$Common$StepModel$setIsFormSubmitted,
					true,
					A2(
						$author$project$Quotation$Common$StepModel$setEnabledValidationFieldsIds,
						$author$project$Quotation$Common$StepModel$pickEnabledFieldsIds(model),
						model))));
	}
};
var $author$project$Quotation$Common$Fields$Model$propertyAddressCitySuggestionsMapper = F2(
	function (response, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback,
			A2(
				$author$project$Quotation$Common$Components$AddressFallback$updateCitiesOptions,
				$author$project$Quotation$Common$Fields$Model$tmpResponse(response),
				$author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Analytics$propertyAddressParameter = 'property_complete_address_autocomplete';
var $author$project$Quotation$Common$Fields$Model$propertyAddressSuggestionsMapper = F2(
	function (response, formData) {
		return A2(
			$author$project$Quotation$Common$Fields$Model$setPropertyAddress,
			A2(
				$author$project$Components$DebouncedAutocomplete$setOptions,
				$author$project$Quotation$Common$Fields$Model$tmpResponse(response),
				$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(formData)),
			formData);
	});
var $author$project$Quotation$Common$Analytics$propertyFloorParameter = 'floor';
var $author$project$Quotation$Common$Fields$Model$refillPropertyAddressFilter = function (formData) {
	return A2(
		$author$project$Quotation$Common$Fields$Model$setPropertyAddress,
		$author$project$Components$DebouncedAutocomplete$refillFilter(
			$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(formData)),
		formData);
};
var $author$project$Quotation$Home$AddressStep$Model$refillAllAutocompleteFilter = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$residentialAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$refillAllAutocompleteFilter),
		A2(
			$author$project$Quotation$Common$StepModel$mapFormData,
			$author$project$Quotation$Common$Fields$Model$propertyAddressFallbackMapper($author$project$Quotation$Common$Components$AddressFallback$refillAllAutocompleteFilter),
			A2(
				$author$project$Quotation$Common$StepModel$mapFormData,
				$author$project$Quotation$Common$Fields$Model$refillResidentialAddressFilter,
				A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$refillPropertyAddressFilter, model))));
};
var $author$project$Quotation$Common$Updaters$updatePropertyAddressFallbackWithCmd = A2($author$project$Quotation$Common$Updaters$updateAddressFallbackFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField, $author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback);
var $author$project$Quotation$Common$Updaters$updatePropertyAddressWithCmd = function (msgMapper) {
	return A3($author$project$Quotation$Common$Updaters$updateDebouncedAutocompleteFieldWithCmd, msgMapper, $author$project$Quotation$Common$Fields$Model$pickPropertyAddressField, $author$project$Quotation$Common$Fields$Model$setPropertyAddress);
};
var $author$project$Quotation$Common$Updaters$updatePropertyFloorWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPropertyFloorField, $author$project$Quotation$Common$Fields$Model$setPropertyFloor);
var $author$project$Quotation$Home$AddressStep$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$onViewportLoadCmd($author$project$Quotation$Home$AddressStep$Model$InitializeView),
								$author$project$Ports$pushUserRole(0)
							]),
						A2($author$project$Quotation$Common$StepModel$setExpandedFieldsIds, $elm$core$Set$empty, model)));
			case 1:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2(
								$author$project$Quotation$Common$Command$conditionalFirstFieldExpansionCmd,
								$author$project$Quotation$Home$AddressStep$Model$firstInvalidExpandableFieldId(model),
								$author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsViewInitialized, true, model)));
			case 2:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible, false, model)));
			case 3:
				switch (msg.a.$) {
					case 0:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$propertyAddressSuggestionsMapper(response),
									model)));
					case 1:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$residentialAddressSuggestionsMapper(response),
									model)));
					case 2:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$propertyAddressCitySuggestionsMapper(response),
									model)));
					default:
						var response = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$residentialAddressCitySuggestionsMapper(response),
									model)));
				}
			case 4:
				var manualAddressToggleType = msg.a;
				var isManual = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isManual),
								A2($author$project$Quotation$Home$AddressStep$Update$conditionalManualPropertyAddressCmd, manualAddressToggleType, isManual)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsPropertyAddressManual, isManual, model)));
			case 5:
				var manualAddressToggleType = msg.a;
				var isManual = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isManual),
								A2($author$project$Quotation$Home$AddressStep$Update$conditionalManualResidentialAddressCmd, manualAddressToggleType, isManual)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsResidentialAddressManual, isManual, model)));
			case 8:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$GTM$conditionallySendGTMFormEvent(
								{
									nf: $author$project$Utils$GTM$composeEventTag(
										{
											ay: $author$project$Utils$GTM$eventIdWithPrefix(
												{ay: eventId, oV: $author$project$Quotation$Home$AddressStep$Model$stepPrefixValue}),
											ne: $author$project$Utils$GTM$defaultEventName,
											oO: $author$project$Utils$GTM$defaultHomeAndFamilyParameterName,
											oP: $author$project$Quotation$Home$AddressStep$Model$stepPrefixValue,
											oQ: analyticsParameter
										}),
									n3: isFieldValid
								})
							]),
						model));
			case 9:
				var fieldInfoModalState = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(
								!_Utils_eq(fieldInfoModalState, $author$project$Quotation$Home$AddressStep$Model$ClosedModal)),
								$author$project$Quotation$Home$AddressStep$Update$conditionalFieldInfoModalAnalyticsCmd(fieldInfoModalState)
							]),
						A2($author$project$Quotation$Common$StepModel$setModalState, fieldInfoModalState, model)));
			case 10:
				var expandableFieldsTransaction = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Common$StepModel$updateEnabledFields(
							A2($author$project$Quotation$Common$StepModel$updateExpandedFields, expandableFieldsTransaction, model))));
			case 11:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(false)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$mapFormData,
							$author$project$Quotation$Common$Fields$Model$setPropertyAddressFallback($author$project$Quotation$Home$AddressStep$Model$initialPropertyAddressFallback),
							A2($author$project$Quotation$Common$StepModel$setIsPropertyAddressManual, false, model))));
			case 12:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(false)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$mapFormData,
							$author$project$Quotation$Common$Fields$Model$setResidentialAddressFallback($author$project$Quotation$Home$AddressStep$Model$initialResidentialAddressFallback),
							A2($author$project$Quotation$Common$StepModel$setIsResidentialAddressManual, false, model))));
			case 13:
				return $author$project$Quotation$Home$AddressStep$Update$proceedFromAddressStep(model);
			case 14:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 15:
				return $author$project$Quotation$Home$AddressStep$Update$goBackToPropertyStep(model);
			case 16:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 17:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 18:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Home$AddressStep$Model$refillAllAutocompleteFilter(
							$author$project$Quotation$Home$AddressStep$Model$closeAllSelect(model))));
			case 6:
				switch (msg.a.$) {
					case 7:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$applyTupleAsParameters,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$addCommand),
								A2(
									$elm$core$Tuple$mapFirst,
									function (newModel) {
										return A3(
											$author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd,
											$author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg(autocompleteMsg) && A2(
												$elm$core$Maybe$withDefault,
												false,
												A2(
													$elm$core$Maybe$map,
													$author$project$Quotation$Common$Types$isPlaceComplete,
													$author$project$Components$DebouncedAutocomplete$getValue(
														$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(
															$author$project$Quotation$Common$StepModel$pickFormData(newModel))))),
											$author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
											newModel);
									},
									A4(
										$author$project$Quotation$Common$Command$maybeSearchPlaces,
										autocompleteMsg,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Home$AddressStep$Model$SearchPlaceResponse, $author$project$Quotation$Home$AddressStep$Model$Response),
										$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
										A3(
											$author$project$Quotation$Common$Updaters$updatePropertyAddressWithCmd,
											A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyAddressFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged),
											autocompleteMsg,
											model)))));
					case 8:
						var addressFallbackMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$applyTupleAsParameters,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$addCommand),
								A2(
									$elm$core$Tuple$mapFirst,
									$author$project$Quotation$Home$AddressStep$Update$conditionallyUpdatePropertyAddress,
									A4(
										$author$project$Quotation$Common$Command$maybeSearchCitiesFallback,
										addressFallbackMsg,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Home$AddressStep$Model$SearchPropertyAddressCityResponse, $author$project$Quotation$Home$AddressStep$Model$Response),
										$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
										A2($author$project$Quotation$Common$Updaters$updatePropertyAddressFallbackWithCmd, addressFallbackMsg, model)))));
					case 9:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePropertyFloorWithCmd, radioCardMsg, model));
					case 10:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$applyTupleAsParameters,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$addCommand),
								A2(
									$elm$core$Tuple$mapFirst,
									function (newModel) {
										return A3(
											$author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd,
											$author$project$Components$DebouncedAutocomplete$isAutocompleteOnSelectMsg(autocompleteMsg) && A2(
												$elm$core$Maybe$withDefault,
												false,
												A2(
													$elm$core$Maybe$map,
													$author$project$Quotation$Common$Types$isPlaceComplete,
													$author$project$Components$DebouncedAutocomplete$getValue(
														$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(
															$author$project$Quotation$Common$StepModel$pickFormData(newModel))))),
											$author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
											newModel);
									},
									A4(
										$author$project$Quotation$Common$Command$maybeSearchPlaces,
										autocompleteMsg,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Home$AddressStep$Model$SearchResidentialPlaceResponse, $author$project$Quotation$Home$AddressStep$Model$Response),
										$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
										A3(
											$author$project$Quotation$Common$Updaters$updateResidentialAddressWithCmd,
											A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged),
											autocompleteMsg,
											model)))));
					case 11:
						var addressFallbackMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$applyTupleAsParameters,
								$elm_community$basics_extra$Basics$Extra$flip($author$project$Utils$addCommand),
								A2(
									$elm$core$Tuple$mapFirst,
									$author$project$Quotation$Home$AddressStep$Update$conditionallyUpdateResidentialAddress,
									A4(
										$author$project$Quotation$Common$Command$maybeSearchCitiesFallback,
										addressFallbackMsg,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Home$AddressStep$Model$SearchResidentialAddressCityResponse, $author$project$Quotation$Home$AddressStep$Model$Response),
										$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
										A2($author$project$Quotation$Common$Updaters$updateResidentialAddressFallbackWithCmd, addressFallbackMsg, model)))));
					case 12:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateReportedClaimsWithCmd, radioCardMsg, model));
					case 13:
						var selectMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateAdultsWithCmd, selectMsg, model));
					case 14:
						var selectMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateChildrenWithCmd, selectMsg, model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
			default:
				switch (msg.a) {
					case 7:
						var _v1 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$propertyAddressParameter,
												ay: $author$project$Quotation$Common$Analytics$propertyAddressParameter,
												n3: $author$project$Quotation$Common$StepModel$isPropertyAddressFieldValid(model)
											}))
									]),
								model));
					case 8:
						var _v2 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$propertyFloorParameter,
												$author$project$Quotation$Common$Analytics$getPropertyFloorParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$propertyFloorParameter,
											n3: A2(
												$author$project$Quotation$Common$StepModel$isPropertyFloorFieldValid,
												$author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(
													$author$project$Quotation$Common$StepModel$pickPropertyStepResult(model)),
												model)
										})),
								A3($author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId, model)));
					case 9:
						var _v3 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$residentialAddressParameter,
												ay: $author$project$Quotation$Common$Analytics$residentialAddressParameter,
												n3: A2(
													$author$project$Quotation$Common$StepModel$isResidentialAddressFieldValid,
													$author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(
														$author$project$Quotation$Common$StepModel$pickPropertyStepResult(model)),
													model)
											}))
									]),
								model));
					case 10:
						var _v4 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$reportedClaimsParameter,
												$author$project$Quotation$Common$Analytics$getReportedClaimsParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$reportedClaimsParameter,
											n3: $author$project$Quotation$Common$StepModel$isReportedClaimsFieldValid(model)
										})),
								A3($author$project$Quotation$Home$AddressStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId, model)));
					case 11:
						var _v5 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
											{
												bb: _Utils_ap(
													$author$project$Quotation$Common$Analytics$adultsParameter,
													$author$project$Quotation$Common$Analytics$getAdultsParameterSuffix(model)),
												ay: $author$project$Quotation$Common$Analytics$adultsParameter,
												n3: $author$project$Quotation$Common$StepModel$isAdultsFieldValid(model)
											}))
									]),
								model));
					case 12:
						var _v6 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$AddressStep$Model$SendGTMFormEvent(
											{
												bb: _Utils_ap(
													$author$project$Quotation$Common$Analytics$childrenParameter,
													$author$project$Quotation$Common$Analytics$getChildrenParameterSuffix(model)),
												ay: $author$project$Quotation$Common$Analytics$childrenParameter,
												n3: $author$project$Quotation$Common$StepModel$isChildrenFieldValid(model)
											}))
									]),
								model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
		}
	});
var $author$project$Quotation$Home$CustomerStep$Model$CurrentDate = function (a) {
	return {$: 11, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$GotNewSeed = function (a) {
	return {$: 12, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$InitializeView = {$: 1};
var $author$project$Quotation$Home$CustomerStep$Model$OpenPrivacyPolicyModal = 0;
var $author$project$Quotation$Home$CustomerStep$Model$Response = function (a) {
	return {$: 5, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$SearchBirthCityResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$SearchBirthCountryResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$closeAllSelect = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$closeBirthCountrySelect,
		A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$closeBirthCitySelect, model));
};
var $author$project$Quotation$Home$CustomerStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd = function (isModalOpen) {
	return isModalOpen ? $author$project$Utils$send(
		$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
			{bb: $author$project$Quotation$Common$Analytics$privacyPolicyModalAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$privacyPolicyModalAnalyticsParameter, n3: true})) : $elm$core$Platform$Cmd$none;
};
var $author$project$Quotation$Common$Analytics$genderParameterSuffixMapper = function (value) {
	if (!value) {
		return '_male';
	} else {
		return '_female';
	}
};
var $author$project$Quotation$Common$Analytics$getGenderParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$genderParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickGenderField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Home$CustomerStep$Model$GoBackToAddressStep = F7(
	function (a, b, c, d, e, f, g) {
		return {$: 13, a: a, b: b, c: c, d: d, e: e, f: f, g: g};
	});
var $author$project$Quotation$Common$StepModel$pickAddressStepResult = function (_v0) {
	var addressStepResult = _v0.l9;
	return addressStepResult;
};
var $author$project$Quotation$Home$CustomerStep$Update$goBackToAddressStep = function (model) {
	return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
		A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					A7(
						$author$project$Quotation$Home$CustomerStep$Model$GoBackToAddressStep,
						$author$project$Quotation$Common$StepModel$pickFlags(model),
						$author$project$Quotation$Common$StepModel$pickTokenType(model),
						$author$project$Quotation$Common$StepModel$pickUser(model),
						$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
						$author$project$Quotation$Common$StepModel$pickPropertyStepResult(model),
						$author$project$Quotation$Common$StepModel$pickAddressStepResult(model),
						$author$project$Quotation$Common$StepModel$pickFormData(model))),
					$author$project$Utils$send(
					$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
						{bb: $author$project$Quotation$Common$Analytics$backBtnParameter, ay: $author$project$Quotation$Common$Analytics$backBtnParameter, n3: true}))
				]),
			model));
};
var $author$project$Quotation$Home$CustomerStep$Update$handleQuoteResponse = F2(
	function (response, model) {
		if (!response.$) {
			if (!response.a.$) {
				var quoteId = response.a.a;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A3($author$project$Routing$Guarantees, 0, quoteId, $elm$core$Maybe$Nothing),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, response, model))));
			} else {
				var error = response.a.a;
				var _v1 = error.k6;
				if (_v1 === 1) {
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$QuoteNotAvailable),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, response, model))));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, response, model)));
				}
			}
		} else {
			return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				$author$project$Utils$withoutCommands(
					A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, response, model)));
		}
	});
var $author$project$Quotation$Home$CustomerStep$Model$buildStepResultData = F2(
	function (stepState, stepData) {
		var privacyFieldResult = A2(
			$elm$core$Result$map,
			$elm$core$List$concat,
			$elm_community$result_extra$Result$Extra$combine(
				_List_fromArray(
					[
						$author$project$Quotation$Common$StepModel$validatedPrivacyPolicyField(stepData),
						$author$project$Quotation$Common$StepModel$validatedCommercialPurposesField(stepData)
					])));
		var isForeignCitySelected = $author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState));
		var currentDate = $author$project$Quotation$Common$StepModel$pickCurrentDate(stepState);
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			privacyFieldResult,
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$StepModel$validatedPhoneField(stepData),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$Quotation$Common$StepModel$validatedEmailField(stepData),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						A2($author$project$Quotation$Common$StepModel$validatedBirthCountryField, isForeignCitySelected, stepData),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							$author$project$Quotation$Common$StepModel$validatedBirthCityField(stepData),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								A2($author$project$Quotation$Common$StepModel$validatedBirthdayField, currentDate, stepData),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									$author$project$Quotation$Common$StepModel$validatedGenderField(stepData),
									A2(
										$elm_community$result_extra$Result$Extra$andMap,
										$author$project$Quotation$Common$StepModel$validatedSurnameField(stepData),
										A2(
											$elm_community$result_extra$Result$Extra$andMap,
											$author$project$Quotation$Common$StepModel$validatedNameField(stepData),
											$elm$core$Result$Ok($author$project$Quotation$Home$CustomerStep$Model$StepResultData))))))))));
	});
var $author$project$Quotation$Home$CustomerStep$Model$getStepResult = function (stepState) {
	return A2(
		$elm$core$Result$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$author$project$Quotation$Home$CustomerStep$Model$buildStepResultData,
			stepState,
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Home$CustomerStep$Model$GotQuote = function (a) {
	return {$: 6, a: a};
};
var $author$project$Gql$Vianello$InputObject$QuotationForm = function (id) {
	return function (propertyAddress) {
		return function (ownership) {
			return function (mortgageStatus) {
				return function (policyType) {
					return function (propertyUsageType) {
						return function (bankAppraisal) {
							return function (propertyInfo) {
								return function (firstName) {
									return function (lastName) {
										return function (gender) {
											return function (dateOfBirth) {
												return function (cityOfBirth) {
													return function (cityOfBirthLandRegister) {
														return function (countryOfBirth) {
															return function (email) {
																return function (phoneMobile) {
																	return function (customerAddress) {
																		return function (agreements) {
																			return function (originalQuoteId) {
																				return function (originalSaveId) {
																					return {B: agreements, bd: bankAppraisal, p: cityOfBirth, k: cityOfBirthLandRegister, q: countryOfBirth, W: customerAddress, r: dateOfBirth, fi: email, nu: firstName, ny: gender, nM: id, n8: lastName, cl: mortgageStatus, U: originalQuoteId, w: originalSaveId, cs: ownership, F: phoneMobile, bA: policyType, kY: propertyAddress, bB: propertyInfo, cx: propertyUsageType};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Vianello$Enum$MortgageStatus$WithoutMortgage = 1;
var $author$project$Quotation$Home$Api$Quote$ownershipTypeMapper = function (stepResult) {
	var ownership = $author$project$Quotation$Common$Fields$StepResult$pickOwnershipTypeField(stepResult);
	if (!ownership) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Quotation$Common$Api$Mapper$propertyAddressFieldMapper = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$StepResult$pickPropertyAddressField, $author$project$Quotation$Common$Api$Mapper$placeToAddressMapper);
var $author$project$Gql$Vianello$InputObject$PropertyInfo = F9(
	function (propertyType, condominiumType, houseType, floorType, totalAreaSqm, constructionPeriod, pastIncidents, colivingAdults, colivingMinors) {
		return {ae: colivingAdults, af: colivingMinors, mN: condominiumType, mP: constructionPeriod, bl: floorType, bp: houseType, al: pastIncidents, o4: propertyType, p3: totalAreaSqm};
	});
var $author$project$Quotation$Home$Api$Quote$condominiumTypeMapper = function (administrator) {
	switch (administrator) {
		case 0:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(1);
		case 1:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(0);
		default:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Quotation$Home$Api$Quote$constructionPeriodMapper = function (constructionPeriod) {
	switch (constructionPeriod) {
		case 0:
			return 1;
		case 1:
			return 6;
		case 2:
			return 7;
		case 3:
			return 8;
		case 4:
			return 9;
		case 5:
			return 10;
		case 6:
			return 11;
		default:
			return 0;
	}
};
var $author$project$Quotation$Home$Api$Quote$floorTypeMapper = function (propertyFloor) {
	switch (propertyFloor) {
		case 1:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(2);
		case 0:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(0);
		case 2:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(1);
		default:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Quotation$Home$Api$Quote$houseTypeMapper = function (houseType) {
	switch (houseType) {
		case 0:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(0);
		case 1:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(1);
		default:
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Quotation$Home$Api$Quote$propertyTypeMapper = function (propertyType) {
	if (!propertyType) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Quotation$Home$Api$Quote$propertyInfoMapper = function (data) {
	return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
		A9(
			$author$project$Gql$Vianello$InputObject$PropertyInfo,
			$author$project$Quotation$Home$Api$Quote$propertyTypeMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickPropertyTypeField(data.a$)),
			$author$project$Quotation$Home$Api$Quote$condominiumTypeMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickBuildingAdministratorField(data.a$)),
			$author$project$Quotation$Home$Api$Quote$houseTypeMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickVillaTypeField(data.a$)),
			$author$project$Quotation$Home$Api$Quote$floorTypeMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickPropertyFloorField(data.ba)),
			$author$project$Quotation$Common$Fields$StepResult$pickPropertySizeField(data.a$),
			$author$project$Quotation$Home$Api$Quote$constructionPeriodMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickConstructionPeriodField(data.a$)),
			$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickReportedClaimsField(data.ba)),
			$author$project$Quotation$Common$Fields$Model$adultsTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickAdultsField(data.ba)),
			$author$project$Quotation$Common$Fields$Model$childrenTypeToIntMapper(
				$author$project$Quotation$Common$Fields$StepResult$pickChildrenField(data.ba))));
};
var $author$project$Quotation$Home$Api$Quote$propertyUsageTypeMapper = function (stepResult) {
	var propertyPurpose = $author$project$Quotation$Common$Fields$StepResult$pickPropertyPurposeField(stepResult);
	switch (propertyPurpose) {
		case 0:
			return 0;
		case 2:
			return 2;
		default:
			return 1;
	}
};
var $author$project$Quotation$Common$Api$Mapper$residentialAddressMapper = function (stepResult) {
	var _v0 = $author$project$Quotation$Common$Fields$StepResult$pickOptionalResidentialAddressField(stepResult);
	if (!_v0.$) {
		var place = _v0.a;
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
			$author$project$Quotation$Common$Api$Mapper$placeToAddressMapper(place));
	} else {
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Quotation$Home$Api$Quote$formDataToQuotationForm = F4(
	function (uuid, quoteId, saveId, formData) {
		return $author$project$Gql$Vianello$InputObject$QuotationForm(uuid)(
			$author$project$Quotation$Common$Api$Mapper$propertyAddressFieldMapper(formData.ba))(
			$author$project$Quotation$Home$Api$Quote$ownershipTypeMapper(formData.a$))(1)($dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent)(
			$author$project$Quotation$Home$Api$Quote$propertyUsageTypeMapper(formData.a$))($dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent)(
			$author$project$Quotation$Home$Api$Quote$propertyInfoMapper(formData))(
			$author$project$Quotation$Common$Fields$StepResult$pickNameField(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickSurnameField(formData.X))(
			$author$project$Quotation$Common$Api$Mapper$genderMapper(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickBirthDayField(formData.X))(
			$author$project$Quotation$Common$Types$pickBirthCityName(
				$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(formData.X)))(
			$author$project$Api$Helper$maybeToOptional(
				$author$project$Quotation$Common$Types$pickBirthCityLandRegister(
					$author$project$Quotation$Common$Fields$StepResult$pickBirthCityField(formData.X))))(
			$author$project$Quotation$Common$Types$pickBirthCountryName(
				$author$project$Quotation$Common$Fields$StepResult$pickBirthCountryField(formData.X)))(
			$author$project$Quotation$Common$Fields$StepResult$pickEmailField(formData.X))(
			$author$project$Quotation$Common$Fields$StepResult$pickPhoneField(formData.X))(
			$author$project$Quotation$Common$Api$Mapper$residentialAddressMapper(formData.ba))(
			$author$project$Quotation$Common$Api$Mapper$agreementsMapper(formData.X))(
			$author$project$Api$Helper$maybeToOptional(quoteId))(
			$author$project$Api$Helper$maybeToOptional(saveId));
	});
var $author$project$Gql$Vianello$Enum$CondominiumType$toString = function (enum____) {
	if (!enum____) {
		return 'UP_TO_EIGHT_APARTMENT';
	} else {
		return 'MORE_THAN_EIGHT_APARTMENT';
	}
};
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'UNKNOWN';
		case 1:
			return 'BEFORE1970';
		case 2:
			return 'BEFORE1940';
		case 3:
			return 'DECADE1940';
		case 4:
			return 'DECADE1950';
		case 5:
			return 'DECADE1960';
		case 6:
			return 'DECADE1970';
		case 7:
			return 'DECADE1980';
		case 8:
			return 'DECADE1990';
		case 9:
			return 'DECADE2000';
		case 10:
			return 'DECADE2010';
		default:
			return 'DECADE2020';
	}
};
var $author$project$Gql$Vianello$Enum$FloorType$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'GROUND';
		case 1:
			return 'INTERMEDIATE';
		default:
			return 'TOP';
	}
};
var $author$project$Gql$Vianello$Enum$HouseType$toString = function (enum____) {
	if (!enum____) {
		return 'SINGLE_FAMILY';
	} else {
		return 'TERRACED_HOUSE';
	}
};
var $author$project$Gql$Vianello$Enum$PropertyType$toString = function (enum____) {
	if (!enum____) {
		return 'APARTMENT';
	} else {
		return 'HOUSE';
	}
};
var $author$project$Gql$Vianello$InputObject$encodePropertyInfo = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'propertyType',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$PropertyType$toString, input____.o4))),
				_Utils_Tuple2(
				'condominiumType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.mN,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gql$Vianello$Enum$CondominiumType$toString))),
				_Utils_Tuple2(
				'houseType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bp,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gql$Vianello$Enum$HouseType$toString))),
				_Utils_Tuple2(
				'floorType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bl,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gql$Vianello$Enum$FloorType$toString))),
				_Utils_Tuple2(
				'totalAreaSqm',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.p3))),
				_Utils_Tuple2(
				'constructionPeriod',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$ConstructionPeriod$toString, input____.mP))),
				_Utils_Tuple2(
				'pastIncidents',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.al))),
				_Utils_Tuple2(
				'colivingAdults',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.ae))),
				_Utils_Tuple2(
				'colivingMinors',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$int(input____.af)))
			]));
};
var $author$project$Gql$Vianello$Enum$MortgageStatus$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'ACTIVE';
		case 1:
			return 'WITHOUT_MORTGAGE';
		default:
			return 'NO_PROPERTY';
	}
};
var $author$project$Gql$Vianello$Enum$Ownership$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'OWNER';
		case 1:
			return 'TENANT';
		default:
			return 'NO_PROPERTY';
	}
};
var $author$project$Gql$Vianello$Enum$PolicyType$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'MORTGAGE';
		case 1:
			return 'MORTGAGE_EXTENSION';
		default:
			return 'COMPLETE';
	}
};
var $author$project$Gql$Vianello$Enum$PropertyUsage$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'RESIDENTIAL';
		case 1:
			return 'SECOND_HOUSE';
		case 2:
			return 'RENTED';
		case 3:
			return 'UNINHABITED';
		default:
			return 'NO_PROPERTY';
	}
};
var $author$project$Gql$Vianello$InputObject$encodeQuotationForm = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)(input____.nM))),
				_Utils_Tuple2(
				'propertyAddress',
				$elm$core$Maybe$Just(
					$author$project$Gql$Vianello$InputObject$encodeInputAddress(input____.kY))),
				_Utils_Tuple2(
				'ownership',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$Ownership$toString, input____.cs))),
				_Utils_Tuple2(
				'mortgageStatus',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$MortgageStatus$toString, input____.cl))),
				_Utils_Tuple2(
				'policyType',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.bA,
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$enum($author$project$Gql$Vianello$Enum$PolicyType$toString))),
				_Utils_Tuple2(
				'propertyUsageType',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$PropertyUsage$toString, input____.cx))),
				_Utils_Tuple2(
				'bankAppraisal',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bd, $dillonkearns$elm_graphql$Graphql$Internal$Encode$int)),
				_Utils_Tuple2(
				'propertyInfo',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.bB, $author$project$Gql$Vianello$InputObject$encodePropertyInfo)),
				_Utils_Tuple2(
				'firstName',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.nu))),
				_Utils_Tuple2(
				'lastName',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.n8))),
				_Utils_Tuple2(
				'gender',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Vianello$Enum$Gender$toString, input____.ny))),
				_Utils_Tuple2(
				'dateOfBirth',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.mK;
						},
						$author$project$VianelloScalarCodecs$codecs)(input____.r))),
				_Utils_Tuple2(
				'cityOfBirth',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.p))),
				_Utils_Tuple2(
				'cityOfBirthLandRegister',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.k, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'countryOfBirth',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.q))),
				_Utils_Tuple2(
				'email',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.fi))),
				_Utils_Tuple2(
				'phoneMobile',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.F))),
				_Utils_Tuple2(
				'customerAddress',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.W, $author$project$Gql$Vianello$InputObject$encodeInputAddress)),
				_Utils_Tuple2(
				'agreements',
				$elm$core$Maybe$Just(
					$author$project$Gql$Vianello$InputObject$encodeAgreements(input____.B))),
				_Utils_Tuple2(
				'originalQuoteId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.U,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))),
				_Utils_Tuple2(
				'originalSaveId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.w,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs)))
			]));
};
var $author$project$Gql$Vianello$Mutation$registerHousePolicyData = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'registerHousePolicyData',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'formData', requiredArgs____.dv, $author$project$Gql$Vianello$InputObject$encodeQuotationForm)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Quotation$Common$Api$Quote$Selection$homeQuotationSelection = function (quotationFormData) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$AntiSpiderProtectedApi$toQuotationResult,
		A2(
			$author$project$Gql$Vianello$Mutation$registerHousePolicyData,
			{dv: quotationFormData},
			$author$project$Quotation$Common$Api$Quote$Selection$quotationFormSelection));
};
var $author$project$Quotation$Home$Api$Quote$quotationMutation = F4(
	function (formData, newQuoteUuid, originalQuoteUuid, originalSaveUuid) {
		var quotationForm = A4($author$project$Quotation$Home$Api$Quote$formDataToQuotationForm, newQuoteUuid, originalQuoteUuid, originalSaveUuid, formData);
		return A2(
			$author$project$AntiSpiderProtectedApi$buildMutation,
			$author$project$AntiSpiderProtectedApi$fromQuotationSource(
				A2(
					$elm$core$Basics$composeR,
					$author$project$Quotation$Home$CustomerStep$Model$GotQuote,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Home$Model$CustomerStepMsg, $author$project$Quotation$Model$HomeMsg))),
			$author$project$Quotation$Common$Api$Quote$Selection$homeQuotationSelection(quotationForm));
	});
var $author$project$Quotation$Home$FormData$toFormData = F2(
	function (state, customerStepResult) {
		var maybeUser = $author$project$Quotation$Common$StepModel$pickUser(state);
		var mappedCustomerResult = function () {
			if (!maybeUser.$) {
				var email = maybeUser.a.fi;
				var admin = maybeUser.a.ma;
				return admin ? A2($author$project$Quotation$Common$Fields$StepResult$setEmailField, email, customerStepResult) : customerStepResult;
			} else {
				return customerStepResult;
			}
		}();
		return {
			ba: $author$project$Quotation$Common$StepModel$pickAddressStepResult(state),
			X: mappedCustomerResult,
			a$: $author$project$Quotation$Common$StepModel$pickPropertyStepResult(state)
		};
	});
var $author$project$Quotation$Home$CustomerStep$Update$submitForm = F2(
	function (customerStepResult, model) {
		var formData = A2($author$project$Quotation$Home$FormData$toFormData, model, customerStepResult);
		var _v0 = A2(
			$elm$random$Random$step,
			$danyx23$elm_uuid$Uuid$uuidGenerator,
			$author$project$Quotation$Common$StepModel$pickCurrentSeed(model));
		var newUuid = _v0.a;
		var newSeed = _v0.b;
		return A2(
			$author$project$Utils$UpdateReturn$withAntiSpiderProtectedQuery,
			A4($author$project$Quotation$Home$Api$Quote$quotationMutation, formData, newUuid, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
			$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[
							$author$project$Utils$send(
							$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
								{bb: $author$project$Quotation$Common$Analytics$proceedBtnParameter, ay: $author$project$Quotation$Common$Analytics$proceedBtnParameter, n3: true}))
						]),
					A2(
						$author$project$Quotation$Common$StepModel$setCurrentSeed,
						newSeed,
						A2($author$project$Quotation$Common$StepModel$setFormSubmissionResult, $author$project$RFQDTS$Http$Authenticator$AuthResponse$Loading, model)))));
	});
var $author$project$Quotation$Home$CustomerStep$Update$proceedFromCustomerStep = function (model) {
	var _v0 = $author$project$Quotation$Home$CustomerStep$Model$getStepResult(model);
	if (!_v0.$) {
		var customerStepResult = _v0.a;
		return A2($author$project$Quotation$Home$CustomerStep$Update$submitForm, customerStepResult, model);
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				A2($author$project$Quotation$Common$StepModel$setIsFormSubmitted, true, model)));
	}
};
var $author$project$Quotation$Home$CustomerStep$Model$refillAllAutocompleteFilter = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$mapFormData,
		$author$project$Quotation$Common$Fields$Model$refillBirthCountryFilter,
		A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$refillBirthCityFilter, model));
};
var $author$project$Quotation$Home$CustomerStep$Model$stepPrefixValue = 'user_info_step';
var $author$project$Quotation$Home$CustomerStep$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($elm$core$Task$perform, $author$project$Quotation$Home$CustomerStep$Model$CurrentDate, $justinmimbs$date$Date$today),
								A2($elm$random$Random$generate, $author$project$Quotation$Home$CustomerStep$Model$GotNewSeed, $elm$random$Random$independentSeed),
								$author$project$Utils$onViewportLoadCmd($author$project$Quotation$Home$CustomerStep$Model$InitializeView),
								$author$project$Ports$pushUserRole(0)
							]),
						model));
			case 1:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsViewInitialized, true, model)));
			case 2:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible, false, model)));
			case 5:
				if (!msg.a.$) {
					var response = msg.a.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$Quotation$Common$StepModel$mapFormData,
								$author$project$Quotation$Common$Fields$Model$birthCitySuggestionsMapper(response),
								model)));
				} else {
					var response = msg.a.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$Quotation$Common$StepModel$mapFormData,
								$author$project$Quotation$Common$Fields$Model$birthCountrySuggestionsMapper(response),
								model)));
				}
			case 6:
				var response = msg.a;
				return A2($author$project$Quotation$Home$CustomerStep$Update$handleQuoteResponse, response, model);
			case 7:
				var isOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isOpen),
								$author$project$Quotation$Home$CustomerStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd(isOpen)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$setModalState,
							A3($author$project$Utils$ternaryOperator, isOpen, 0, 1),
							model)));
			case 8:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$GTM$conditionallySendGTMFormEvent(
								{
									nf: $author$project$Utils$GTM$composeEventTag(
										{
											ay: $author$project$Utils$GTM$eventIdWithPrefix(
												{ay: eventId, oV: $author$project$Quotation$Home$CustomerStep$Model$stepPrefixValue}),
											ne: $author$project$Utils$GTM$defaultEventName,
											oO: $author$project$Utils$GTM$defaultHomeAndFamilyParameterName,
											oP: $author$project$Quotation$Home$CustomerStep$Model$stepPrefixValue,
											oQ: analyticsParameter
										}),
									n3: isFieldValid
								})
							]),
						model));
			case 9:
				return $author$project$Quotation$Home$CustomerStep$Update$proceedFromCustomerStep(model);
			case 10:
				return $author$project$Quotation$Home$CustomerStep$Update$goBackToAddressStep(model);
			case 13:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 14:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Home$CustomerStep$Model$refillAllAutocompleteFilter(
							$author$project$Quotation$Home$CustomerStep$Model$closeAllSelect(model))));
			case 11:
				var date = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentDate, date, model)));
			case 12:
				var seed = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentSeed, seed, model)));
			case 15:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 3:
				switch (msg.a.$) {
					case 15:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateNameWithCmd, inputMsg, model));
					case 16:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateSurnameWithCmd, inputMsg, model));
					case 17:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateGenderWithCmd, radioCardMsg, model));
					case 18:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateBirthdayWithCmd, inputMsg, model));
					case 19:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A4(
								$author$project$Quotation$Common$Command$withConditionalBirthCountryCmd,
								autocompleteMsg,
								$author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
								$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent,
								A4(
									$author$project$Quotation$Common$Command$maybeSearchCities,
									autocompleteMsg,
									A2($elm$core$Basics$composeR, $author$project$Quotation$Home$CustomerStep$Model$SearchBirthCityResponse, $author$project$Quotation$Home$CustomerStep$Model$Response),
									$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
									A3(
										$author$project$Quotation$Common$Updaters$updateBirthCityWithCmd,
										A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCityFieldChanged, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
										autocompleteMsg,
										model))));
					case 20:
						var autocompleteMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A4(
								$author$project$Quotation$Common$Command$maybeSearchCountries,
								autocompleteMsg,
								A2($elm$core$Basics$composeR, $author$project$Quotation$Home$CustomerStep$Model$SearchBirthCountryResponse, $author$project$Quotation$Home$CustomerStep$Model$Response),
								$author$project$Quotation$Common$StepModel$pickVianelloUrl(model),
								A3(
									$author$project$Quotation$Common$Updaters$updateBirthCountryWithCmd,
									A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChanged, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
									autocompleteMsg,
									model)));
					case 21:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateEmailWithCmd, inputMsg, model));
					case 22:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePhoneWithCmd, inputMsg, model));
					case 24:
						var checkboxMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePrivacyPolicyWithCmd, checkboxMsg, model));
					case 25:
						var checkboxMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateCommercialPurposesWithCmd, checkboxMsg, model));
					case 23:
						var _v1 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{bb: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter, n3: true}))
									]),
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									A2($author$project$Quotation$Common$Fields$Model$resetBirthCountryField, $author$project$Quotation$Home$CustomerStep$Model$OnFieldChanged, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
									A2($author$project$Quotation$Common$StepModel$mapFormData, $author$project$Quotation$Common$Fields$Model$selectForeignCity, model))));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
			default:
				switch (msg.a) {
					case 13:
						var _v2 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setNameOnBlurMsg(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$nameAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$nameAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isNameFieldValid(model)
											})),
									model)));
					case 14:
						var _v3 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setSurnameOnBlurMsg(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$surnameAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$surnameAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isSurnameFieldValid(model)
											})),
									model)));
					case 15:
						var _v4 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: _Utils_ap(
													$author$project$Quotation$Common$Analytics$genderAnalyticsParameter,
													$author$project$Quotation$Common$Analytics$getGenderParameterSuffix(model)),
												ay: $author$project$Quotation$Common$Analytics$genderAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isGenderFieldValid(model)
											}))
									]),
								model));
					case 16:
						var _v5 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setBirthdayOnBlurMsg(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthdayAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthdayAnalyticsParameter,
												n3: A2(
													$author$project$Quotation$Common$StepModel$isBirthdayFieldValid,
													$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
													model)
											})),
									model)));
					case 17:
						var _v6 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthCityAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isBirthCityFieldValid(model)
											}))
									]),
								model));
					case 18:
						var _v7 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$birthCountryAnalyticsParameter,
												n3: A2(
													$author$project$Quotation$Common$StepModel$isBirthCountryFieldValid,
													$author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
														$author$project$Quotation$Common$StepModel$pickFormData(model)),
													model)
											}))
									]),
								model));
					case 19:
						var _v8 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setEmailOnBlurMsg(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$emailAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$emailAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isEmailFieldValid(model)
											})),
									model)));
					case 20:
						var _v9 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setPhoneOnBlurMsg(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$phoneAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$phoneAnalyticsParameter,
												n3: $author$project$Quotation$Common$StepModel$isPhoneFieldValid(model)
											})),
									model)));
					case 21:
						var _v10 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$privacyPolicyAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$privacyPolicyAnalyticsParameter,
												n3: $author$project$Quotation$Common$Fields$Model$isPrivacyPolicyOptionChecked(
													$author$project$Quotation$Common$StepModel$pickFormData(model))
											}))
									]),
								model));
					case 22:
						var _v11 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$CustomerStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$commercialPurposesAnalyticsParameter,
												ay: $author$project$Quotation$Common$Analytics$commercialPurposesAnalyticsParameter,
												n3: $author$project$Quotation$Common$Fields$Model$isCommercialPurposesOptionChecked(
													$author$project$Quotation$Common$StepModel$pickFormData(model))
											}))
									]),
								model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
		}
	});
var $author$project$Quotation$Home$PropertyStep$Model$CurrentDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation = function (a) {
	return {$: 11, a: a};
};
var $author$project$Quotation$Home$PropertyStep$Model$InitializeView = {$: 1};
var $author$project$Quotation$Home$PropertyStep$Model$OpenPrivacyPolicyModal = {$: 2};
var $author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent = function (a) {
	return {$: 9, a: a};
};
var $author$project$Quotation$Common$Analytics$buildingAdministratorParameter = 'building_manager';
var $author$project$Quotation$Home$PropertyStep$Update$conditionalFieldInfoModalAnalyticsCmd = function (fieldInfoModalState) {
	if (fieldInfoModalState.$ === 1) {
		var fieldInfoModal = fieldInfoModalState.a;
		return $author$project$Utils$send(
			$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
				{
					bb: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
					ay: $author$project$Quotation$Common$Analytics$fieldInfoModalToAnalyticsParameter(fieldInfoModal),
					n3: true
				}));
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Quotation$Home$PropertyStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd = function (isModalOpen) {
	return isModalOpen ? $author$project$Utils$send(
		$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
			{bb: $author$project$Quotation$Common$Analytics$disclaimerPrivacyPolicyModalAnalyticsParameter, ay: $author$project$Quotation$Common$Analytics$disclaimerPrivacyPolicyModalAnalyticsParameter, n3: true})) : $elm$core$Platform$Cmd$none;
};
var $author$project$Quotation$Common$Analytics$constructionPeriodParameter = 'construction_period';
var $author$project$Quotation$Common$Fields$Model$pickPropertyTypeField = function (_v0) {
	var propertyTypeField = _v0.k2;
	return propertyTypeField;
};
var $author$project$Quotation$Common$StepModel$buildingAdministratorFieldValidation = F2(
	function (stepState, value) {
		var propertyTypeFieldValue = $author$project$Pyxis$Components$Field$RadioGroup$getValue(
			$author$project$Quotation$Common$Fields$Model$pickPropertyTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
		if ((!propertyTypeFieldValue.$) && (propertyTypeFieldValue.a === 1)) {
			var _v1 = propertyTypeFieldValue.a;
			return A2(
				$elm$core$Result$mapError,
				$elm$core$Basics$always($author$project$Quotation$Common$Validation$requiredFieldErrorMessage),
				A2(
					$elm$core$Result$andThen,
					$author$project$Quotation$Common$Validation$differentFrom(
						_List_fromArray(
							[2])),
					$author$project$Quotation$Common$Validation$requiredRadioCard(value)));
		} else {
			return $elm$core$Result$Ok(2);
		}
	});
var $author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField = function (_v0) {
	var buildingAdministratorField = _v0.$9;
	return buildingAdministratorField;
};
var $author$project$Quotation$Common$StepModel$validatedBuildingAdministratorField = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$buildingAdministratorFieldValidation,
		model,
		$author$project$Pyxis$Components$Field$RadioGroup$getValue(
			$author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField(
				$author$project$Quotation$Common$StepModel$pickFormData(model))));
};
var $author$project$Quotation$Common$Fields$Model$constructionPeriodFieldValidation = $author$project$Quotation$Common$Validation$requiredRadioCard;
var $author$project$Quotation$Common$Fields$Model$pickConstructionPeriodField = function (_v0) {
	var constructionPeriodField = _v0.iD;
	return constructionPeriodField;
};
var $author$project$Quotation$Common$StepModel$validatedConstructionPeriodField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickConstructionPeriodField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$constructionPeriodFieldValidation));
var $author$project$Quotation$Common$Fields$Model$ownershipTypeFieldValidation = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Validation$requiredRadioCard,
	$elm$core$Result$andThen(
		$author$project$Quotation$Common$Validation$differentFrom(
			_List_fromArray(
				[1]))));
var $author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField = function (_v0) {
	var ownershipTypeField = _v0.kz;
	return ownershipTypeField;
};
var $author$project$Quotation$Common$StepModel$validatedOwnershipTypeField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$ownershipTypeFieldValidation));
var $author$project$Quotation$Common$Fields$Model$pickPropertyPurposeField = function (_v0) {
	var propertyPurposeField = _v0.k0;
	return propertyPurposeField;
};
var $author$project$Quotation$Common$Fields$Model$propertyPurposeFieldValidation = $author$project$Quotation$Common$Validation$requiredRadioCard;
var $author$project$Quotation$Common$StepModel$validatedPropertyPurposeField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPropertyPurposeField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$propertyPurposeFieldValidation));
var $author$project$Quotation$Common$Fields$Model$pickPropertySizeField = function (_v0) {
	var propertySizeField = _v0.k1;
	return propertySizeField;
};
var $author$project$Quotation$Common$Validation$integerRangeErrorMessage = F2(
	function (minValue, maxValue) {
		return 'Inserisci un valore compreso tra ' + ($elm$core$String$fromInt(minValue) + (' e ' + $elm$core$String$fromInt(maxValue)));
	});
var $author$project$Quotation$Common$Validation$integerRange = F3(
	function (minValue, maxValue, value) {
		return ((_Utils_cmp(value, minValue) > -1) && (_Utils_cmp(value, maxValue) < 1)) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err(
			A2($author$project$Quotation$Common$Validation$integerRangeErrorMessage, minValue, maxValue));
	});
var $author$project$Quotation$Common$Validation$minMaxValue = F2(
	function (minValue, maxValue) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Common$Validation$requiredString,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Result$map($elm$core$String$toInt),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Result$andThen(
						$elm$core$Result$fromMaybe(
							A2($author$project$Quotation$Common$Validation$integerRangeErrorMessage, minValue, maxValue))),
					$elm$core$Result$andThen(
						A2($author$project$Quotation$Common$Validation$integerRange, minValue, maxValue)))));
	});
var $author$project$Quotation$Common$Fields$Model$propertySizeFieldValidation = function (value) {
	return A3(
		$author$project$Quotation$Common$Validation$minMaxValue,
		20,
		500,
		$elm$core$String$trim(value));
};
var $author$project$Quotation$Common$StepModel$validatedPropertySizeField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPropertySizeField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Input$getValue, $author$project$Quotation$Common$Fields$Model$propertySizeFieldValidation));
var $author$project$Quotation$Common$Fields$Model$propertyTypeFieldValidation = $author$project$Quotation$Common$Validation$requiredRadioCard;
var $author$project$Quotation$Common$StepModel$validatedPropertyTypeField = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickPropertyTypeField,
	A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$RadioGroup$getValue, $author$project$Quotation$Common$Fields$Model$propertyTypeFieldValidation));
var $author$project$Quotation$Common$Fields$Model$pickVillaTypeField = function (_v0) {
	var villaTypeField = _v0.lY;
	return villaTypeField;
};
var $author$project$Quotation$Common$StepModel$villaFieldValidation = F2(
	function (stepState, value) {
		var propertyTypeFieldValue = $author$project$Pyxis$Components$Field$RadioGroup$getValue(
			$author$project$Quotation$Common$Fields$Model$pickPropertyTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
		if ((!propertyTypeFieldValue.$) && (!propertyTypeFieldValue.a)) {
			var _v1 = propertyTypeFieldValue.a;
			return A2(
				$elm$core$Result$mapError,
				$elm$core$Basics$always($author$project$Quotation$Common$Validation$requiredFieldErrorMessage),
				A2(
					$elm$core$Result$andThen,
					$author$project$Quotation$Common$Validation$differentFrom(
						_List_fromArray(
							[2])),
					$author$project$Quotation$Common$Validation$requiredRadioCard(value)));
		} else {
			return $elm$core$Result$Ok(2);
		}
	});
var $author$project$Quotation$Common$StepModel$validatedVillaTypeField = function (model) {
	return A2(
		$author$project$Quotation$Common$StepModel$villaFieldValidation,
		model,
		$author$project$Pyxis$Components$Field$RadioGroup$getValue(
			$author$project$Quotation$Common$Fields$Model$pickVillaTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(model))));
};
var $author$project$Quotation$Home$PropertyStep$Model$firstInvalidExpandableFieldId = function (stepState) {
	var stepData = $author$project$Quotation$Common$StepModel$pickFormData(stepState);
	return $elm_community$result_extra$Result$Extra$error(
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			A2(
				$elm$core$Result$mapError,
				$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId),
				$author$project$Quotation$Common$StepModel$validatedPropertySizeField(stepData)),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				A2(
					$elm$core$Result$mapError,
					$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId),
					$author$project$Quotation$Common$StepModel$validatedConstructionPeriodField(stepData)),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					A2(
						$elm$core$Result$mapError,
						$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId),
						$author$project$Quotation$Common$StepModel$validatedVillaTypeField(stepState)),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						A2(
							$elm$core$Result$mapError,
							$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId),
							$author$project$Quotation$Common$StepModel$validatedBuildingAdministratorField(stepState)),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2(
								$elm$core$Result$mapError,
								$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId),
								$author$project$Quotation$Common$StepModel$validatedPropertyTypeField(stepData)),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								A2(
									$elm$core$Result$mapError,
									$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId),
									$author$project$Quotation$Common$StepModel$validatedPropertyPurposeField(stepData)),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									A2(
										$elm$core$Result$mapError,
										$elm$core$Basics$always($author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId),
										$author$project$Quotation$Common$StepModel$validatedOwnershipTypeField(stepData)),
									$elm$core$Result$Ok($author$project$Quotation$Home$PropertyStep$Model$StepResultData)))))))));
};
var $author$project$Quotation$Common$Analytics$buildingAdministratorParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_yes';
		case 1:
			return '_no';
		default:
			return '_not_required';
	}
};
var $author$project$Quotation$Common$Analytics$getBuildingAdministratorParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$buildingAdministratorParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$Analytics$constructionPeriodParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_before_1970';
		case 1:
			return '_from_1970_to_1979';
		case 2:
			return '_from_1980_to_1989';
		case 3:
			return '_from_1990_to_1999';
		case 4:
			return '_from_2000_to_2009';
		case 5:
			return '_from_2010_to_2019';
		case 6:
			return '_from_2020';
		default:
			return '_unknown';
	}
};
var $author$project$Quotation$Common$Analytics$getConstructionPeriodParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$constructionPeriodParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickConstructionPeriodField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$Analytics$ownershipTypeParameterSuffixMapper = function (value) {
	if (!value) {
		return '_yes';
	} else {
		return '_no';
	}
};
var $author$project$Quotation$Common$Analytics$getOwnershipTypeParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$ownershipTypeParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$Analytics$propertyPurposeParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_main_house';
		case 1:
			return '_second_house';
		default:
			return '_rented_house';
	}
};
var $author$project$Quotation$Common$Analytics$getPropertyPurposeParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$propertyPurposeParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickPropertyPurposeField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$Analytics$propertyTypeParameterSuffixMapper = function (value) {
	if (!value) {
		return '_villa';
	} else {
		return '_apartment';
	}
};
var $author$project$Quotation$Common$Analytics$getPropertyTypeParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$propertyTypeParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickPropertyTypeField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$Analytics$villaTypeParameterSuffixMapper = function (value) {
	switch (value) {
		case 0:
			return '_detached_house';
		case 1:
			return '_terraced_house';
		default:
			return '_not_required';
	}
};
var $author$project$Quotation$Common$Analytics$getVillaTypeParameterSuffix = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$author$project$Quotation$Common$Analytics$villaTypeParameterSuffixMapper,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue(
				$author$project$Quotation$Common$Fields$Model$pickVillaTypeField(
					$author$project$Quotation$Common$StepModel$pickFormData(model)))));
};
var $author$project$Quotation$Common$StepModel$isBuildingAdministratorFieldValid = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedBuildingAdministratorField, $elm_community$result_extra$Result$Extra$isOk);
var $author$project$Quotation$Common$StepModel$isConstructionPeriodFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedConstructionPeriodField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isOwnershipTypeFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedOwnershipTypeField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isPropertyPurposeFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedPropertyPurposeField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isPropertySizeFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedPropertySizeField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isPropertyTypeFieldValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedPropertyTypeField, $elm_community$result_extra$Result$Extra$isOk));
var $author$project$Quotation$Common$StepModel$isVillaTypeFieldValid = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$validatedVillaTypeField, $elm_community$result_extra$Result$Extra$isOk);
var $author$project$Quotation$Common$Analytics$ownershipTypeParameter = 'owner_type';
var $author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds = function (_v0) {
	var enabledValidationFieldsIds = _v0.i2;
	return enabledValidationFieldsIds;
};
var $author$project$Quotation$Home$PropertyStep$Model$NoOp = {$: 14};
var $author$project$Quotation$Home$PropertyStep$Model$ProceedToAddressStep = F5(
	function (a, b, c, d, e) {
		return {$: 13, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Quotation$Home$PropertyStep$Model$buildStepResultData = F2(
	function (stepState, stepData) {
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$author$project$Quotation$Common$StepModel$validatedPropertySizeField(stepData),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$Quotation$Common$StepModel$validatedConstructionPeriodField(stepData),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$Quotation$Common$StepModel$validatedVillaTypeField(stepState),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						$author$project$Quotation$Common$StepModel$validatedBuildingAdministratorField(stepState),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							$author$project$Quotation$Common$StepModel$validatedPropertyTypeField(stepData),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								$author$project$Quotation$Common$StepModel$validatedPropertyPurposeField(stepData),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									$author$project$Quotation$Common$StepModel$validatedOwnershipTypeField(stepData),
									$elm$core$Result$Ok($author$project$Quotation$Home$PropertyStep$Model$StepResultData))))))));
	});
var $author$project$Quotation$Home$PropertyStep$Model$getStepResult = function (stepState) {
	return A2(
		$elm$core$Result$map,
		$author$project$Quotation$Common$Fields$StepResult$toStepResult,
		A2(
			$author$project$Quotation$Home$PropertyStep$Model$buildStepResultData,
			stepState,
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Home$PropertyStep$Update$proceedFromPropertyStep = function (model) {
	var _v0 = $author$project$Quotation$Home$PropertyStep$Model$getStepResult(model);
	if (!_v0.$) {
		var propertyStepResult = _v0.a;
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						$author$project$Utils$send(
						A5(
							$author$project$Quotation$Home$PropertyStep$Model$ProceedToAddressStep,
							$author$project$Quotation$Common$StepModel$pickFlags(model),
							$author$project$Quotation$Common$StepModel$pickTokenType(model),
							$author$project$Quotation$Common$StepModel$pickUser(model),
							$author$project$Quotation$Common$StepModel$pickCurrentDate(model),
							propertyStepResult)),
						$author$project$Utils$send(
						$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
							{bb: $author$project$Quotation$Common$Analytics$proceedBtnParameter, ay: $author$project$Quotation$Common$Analytics$proceedBtnParameter, n3: true}))
					]),
				model));
	} else {
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						A4(
						$author$project$Quotation$Common$Command$conditionalFirstInvalidFieldExpansionCmd,
						$author$project$Quotation$Home$PropertyStep$Model$firstInvalidExpandableFieldId(model),
						$author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation,
						$author$project$Quotation$Home$PropertyStep$Model$NoOp,
						model)
					]),
				A2(
					$author$project$Quotation$Common$StepModel$setIsFormSubmitted,
					true,
					A2(
						$author$project$Quotation$Common$StepModel$setEnabledValidationFieldsIds,
						$author$project$Quotation$Common$StepModel$pickEnabledFieldsIds(model),
						model))));
	}
};
var $author$project$Quotation$Common$Analytics$propertyPurposeParameter = 'use_type';
var $author$project$Quotation$Common$Analytics$propertySizeParameter = 'sqm';
var $author$project$Quotation$Common$Analytics$propertyTypeParameter = 'property_type';
var $author$project$Quotation$Common$StepModel$pickIsDiscountMessageVisible = function (_v0) {
	var isDiscountMessageVisible = _v0.jK;
	var tokenType = _v0.lK;
	return isDiscountMessageVisible && (!tokenType);
};
var $author$project$Quotation$Home$PropertyStep$Model$resetModel = function (stepState) {
	return A7(
		$author$project$Quotation$Home$PropertyStep$Model$initialState,
		$author$project$Quotation$Common$StepModel$pickFlags(stepState),
		$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
		$author$project$Quotation$Common$StepModel$pickUser(stepState),
		true,
		$author$project$Quotation$Common$StepModel$pickIsDiscountMessageVisible(stepState),
		$author$project$Quotation$Common$StepModel$pickCurrentDate(stepState),
		$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(stepState));
};
var $author$project$Quotation$Common$Fields$Model$setPropertySize = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k1: field});
	});
var $author$project$Quotation$Common$Fields$Model$setPropertySizeOnBlurMsg = F2(
	function (msg, formData) {
		var mappedFieldValue = A2(
			$author$project$Pyxis$Components$Field$Input$setOnBlur,
			msg,
			$author$project$Quotation$Common$Fields$Model$pickPropertySizeField(formData));
		return A2($author$project$Quotation$Common$Fields$Model$setPropertySize, mappedFieldValue, formData);
	});
var $author$project$Quotation$Home$PropertyStep$Model$stepPrefixValue = 'property_info_step';
var $author$project$Quotation$Common$Fields$Model$setBuildingAdministrator = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{$9: field});
	});
var $author$project$Quotation$Common$Updaters$updateBuildingAdministratorWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField, $author$project$Quotation$Common$Fields$Model$setBuildingAdministrator);
var $author$project$Quotation$Common$Fields$Model$setConstructionPeriod = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{iD: field});
	});
var $author$project$Quotation$Common$Updaters$updateConstructionPeriodWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickConstructionPeriodField, $author$project$Quotation$Common$Fields$Model$setConstructionPeriod);
var $author$project$Quotation$Home$PropertyStep$Model$OpenOwnershipTypeModal = {$: 0};
var $author$project$Quotation$Home$PropertyStep$Model$updateOwnershipTypeModal = function (model) {
	var isRentedTypeSelected = _Utils_eq(
		$elm$core$Maybe$Just(1),
		$author$project$Pyxis$Components$Field$RadioGroup$getValue(
			$author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(model))));
	return isRentedTypeSelected ? A2($author$project$Quotation$Common$StepModel$setModalState, $author$project$Quotation$Home$PropertyStep$Model$OpenOwnershipTypeModal, model) : A2($author$project$Quotation$Common$StepModel$setModalState, $author$project$Quotation$Home$PropertyStep$Model$ClosedModal, model);
};
var $author$project$Quotation$Common$Fields$Model$setOwnershipType = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{kz: field});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$OnCheck = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$RadioGroup$updateValue = function (value) {
	return $author$project$Pyxis$Components$Field$RadioGroup$update(
		$author$project$Pyxis$Components$Field$RadioGroup$OnCheck(value));
};
var $author$project$Quotation$Common$Updaters$updateRadioGroupFieldValueWithCmd = F4(
	function (picker, setter, value, model) {
		return A2(
			$elm$core$Tuple$mapFirst,
			A2(
				$elm$core$Basics$composeR,
				setter,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$StepModel$mapFormData, model)),
			A2(
				$author$project$Pyxis$Components$Field$RadioGroup$updateValue,
				value,
				picker(
					$author$project$Quotation$Common$StepModel$pickFormData(model))));
	});
var $author$project$Quotation$Common$Updaters$updateOwnershipTypeValueWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldValueWithCmd, $author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField, $author$project$Quotation$Common$Fields$Model$setOwnershipType);
var $author$project$Quotation$Common$Updaters$updateOwnershipTypeWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField, $author$project$Quotation$Common$Fields$Model$setOwnershipType);
var $author$project$Quotation$Common$Fields$Model$setPropertyPurpose = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k0: field});
	});
var $author$project$Quotation$Common$Updaters$updatePropertyPurposeWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPropertyPurposeField, $author$project$Quotation$Common$Fields$Model$setPropertyPurpose);
var $author$project$Quotation$Common$Updaters$updatePropertySizeWithCmd = A2($author$project$Quotation$Common$Updaters$updateInputFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPropertySizeField, $author$project$Quotation$Common$Fields$Model$setPropertySize);
var $author$project$Quotation$Common$Fields$Model$setPropertyType = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{k2: field});
	});
var $author$project$Quotation$Common$Updaters$updatePropertyTypeWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickPropertyTypeField, $author$project$Quotation$Common$Fields$Model$setPropertyType);
var $author$project$Quotation$Common$Fields$Model$setVillaType = F2(
	function (field, _v0) {
		var stepData = _v0;
		return _Utils_update(
			stepData,
			{lY: field});
	});
var $author$project$Quotation$Common$Updaters$updateVillaTypeWithCmd = A2($author$project$Quotation$Common$Updaters$updateRadioGroupFieldWithCmd, $author$project$Quotation$Common$Fields$Model$pickVillaTypeField, $author$project$Quotation$Common$Fields$Model$setVillaType);
var $author$project$Quotation$Common$Analytics$villaTypeParameter = 'building_type';
var $author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd = F3(
	function (isOtherFieldExpanded, currentFieldId, model) {
		var postponedExpandableFieldsUpdate = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Quotation$Common$View$ExpandableField$StateUpdate, true),
					$elm$core$List$singleton),
				$author$project$Quotation$Home$PropertyStep$Model$firstInvalidExpandableFieldId(model)));
		var expandableFieldsUpdate = _List_fromArray(
			[
				A2($author$project$Quotation$Common$View$ExpandableField$StateUpdate, currentFieldId, false)
			]);
		return isOtherFieldExpanded ? A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					$author$project$Utils$send(
					$author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation(expandableFieldsUpdate)),
					A2(
					$author$project$Utils$delayCmd,
					$author$project$Quotation$Common$View$ExpandableField$animationDelayMs + $author$project$Quotation$Common$View$ExpandableField$animationDurationMs,
					$author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation(postponedExpandableFieldsUpdate))
				]),
			model) : $author$project$Utils$withoutCommands(model);
	});
var $author$project$Quotation$Home$PropertyStep$Model$isOwnershipTypeSelectionValid = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickFormData,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$RadioGroup$getValue,
			$elm$core$Basics$eq(
				$elm$core$Maybe$Just(0)))));
var $author$project$Quotation$Home$PropertyStep$Update$withOwnershipTypeOnCheckCmd = function (model) {
	return A2(
		$author$project$Utils$addCommand,
		$author$project$Utils$toggleBlockScrollingClass(
			!$author$project$Quotation$Home$PropertyStep$Model$isOwnershipTypeSelectionValid(model)),
		A3(
			$author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd,
			$author$project$Quotation$Home$PropertyStep$Model$isOwnershipTypeSelectionValid(model),
			$author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId,
			model));
};
var $author$project$Quotation$Home$PropertyStep$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($elm$core$Task$perform, $author$project$Quotation$Home$PropertyStep$Model$CurrentDate, $justinmimbs$date$Date$today),
								$author$project$Utils$onViewportLoadCmd($author$project$Quotation$Home$PropertyStep$Model$InitializeView),
								$author$project$Ports$pushUserRole(0)
							]),
						A2($author$project$Quotation$Common$StepModel$setExpandedFieldsIds, $elm$core$Set$empty, model)));
			case 1:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2(
								$author$project$Quotation$Common$Command$conditionalFirstFieldExpansionCmd,
								$author$project$Quotation$Home$PropertyStep$Model$firstInvalidExpandableFieldId(model),
								$author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation)
							]),
						A2($author$project$Quotation$Common$StepModel$setIsViewInitialized, true, model)));
			case 2:
				var today = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setCurrentDate, today, model)));
			case 3:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$Quotation$Common$StepModel$setIsDiscountMessageVisible, false, model)));
			case 4:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(false)
							]),
						$author$project$Quotation$Home$PropertyStep$Model$resetModel(model)));
			case 5:
				var _v1 = A2($author$project$Quotation$Common$Updaters$updateOwnershipTypeValueWithCmd, 0, model);
				var updatedModel = _v1.a;
				var cmd = _v1.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$addCommand,
						cmd,
						A2(
							$author$project$Utils$addCommand,
							$author$project$Utils$toggleBlockScrollingClass(false),
							A3(
								$author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd,
								true,
								$author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId,
								$author$project$Quotation$Home$PropertyStep$Model$updateOwnershipTypeModal(updatedModel)))));
			case 8:
				var isOpen = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(isOpen),
								$author$project$Quotation$Home$PropertyStep$Update$conditionalPrivacyPolicyModalAnalyticsCmd(isOpen)
							]),
						A2(
							$author$project$Quotation$Common$StepModel$setModalState,
							A3($author$project$Utils$ternaryOperator, isOpen, $author$project$Quotation$Home$PropertyStep$Model$OpenPrivacyPolicyModal, $author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
							model)));
			case 9:
				var isFieldValid = msg.a.n3;
				var analyticsParameter = msg.a.bb;
				var eventId = msg.a.ay;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$GTM$conditionallySendGTMFormEvent(
								{
									nf: $author$project$Utils$GTM$composeEventTag(
										{
											ay: $author$project$Utils$GTM$eventIdWithPrefix(
												{ay: eventId, oV: $author$project$Quotation$Home$PropertyStep$Model$stepPrefixValue}),
											ne: $author$project$Utils$GTM$defaultEventName,
											oO: $author$project$Utils$GTM$defaultHomeAndFamilyParameterName,
											oP: $author$project$Quotation$Home$PropertyStep$Model$stepPrefixValue,
											oQ: analyticsParameter
										}),
									n3: isFieldValid
								})
							]),
						model));
			case 10:
				var fieldInfoModalState = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Utils$toggleBlockScrollingClass(
								!_Utils_eq(fieldInfoModalState, $author$project$Quotation$Home$PropertyStep$Model$ClosedModal)),
								$author$project$Quotation$Home$PropertyStep$Update$conditionalFieldInfoModalAnalyticsCmd(fieldInfoModalState)
							]),
						A2($author$project$Quotation$Common$StepModel$setModalState, fieldInfoModalState, model)));
			case 11:
				var expandableFieldsTransaction = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						$author$project$Quotation$Common$StepModel$updateEnabledFields(
							A2($author$project$Quotation$Common$StepModel$updateExpandedFields, expandableFieldsTransaction, model))));
			case 12:
				return $author$project$Quotation$Home$PropertyStep$Update$proceedFromPropertyStep(model);
			case 13:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 14:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 6:
				switch (msg.a.$) {
					case 0:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateOwnershipTypeWithCmd, radioCardMsg, model));
					case 1:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePropertyPurposeWithCmd, radioCardMsg, model));
					case 3:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Quotation$Common$Updaters$updatePropertyTypeWithCmd,
								radioCardMsg,
								A2(
									$author$project$Quotation$Common$StepModel$setEnabledValidationFieldsIds,
									A2(
										$elm$core$Set$remove,
										$author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId,
										$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(model)),
									model)));
					case 4:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateVillaTypeWithCmd, radioCardMsg, model));
					case 5:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateBuildingAdministratorWithCmd, radioCardMsg, model));
					case 2:
						var radioCardMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updateConstructionPeriodWithCmd, radioCardMsg, model));
					case 6:
						var inputMsg = msg.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2($author$project$Quotation$Common$Updaters$updatePropertySizeWithCmd, inputMsg, model));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
			default:
				switch (msg.a) {
					case 0:
						var _v2 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$ownershipTypeParameter,
												$author$project$Quotation$Common$Analytics$getOwnershipTypeParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$ownershipTypeParameter,
											n3: $author$project$Quotation$Common$StepModel$isOwnershipTypeFieldValid(model)
										})),
								$author$project$Quotation$Home$PropertyStep$Update$withOwnershipTypeOnCheckCmd(
									$author$project$Quotation$Home$PropertyStep$Model$updateOwnershipTypeModal(model))));
					case 1:
						var _v3 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$propertyPurposeParameter,
												$author$project$Quotation$Common$Analytics$getPropertyPurposeParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$propertyPurposeParameter,
											n3: $author$project$Quotation$Common$StepModel$isPropertyPurposeFieldValid(model)
										})),
								A3($author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId, model)));
					case 3:
						var _v4 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
											{
												bb: _Utils_ap(
													$author$project$Quotation$Common$Analytics$propertyTypeParameter,
													$author$project$Quotation$Common$Analytics$getPropertyTypeParameterSuffix(model)),
												ay: $author$project$Quotation$Common$Analytics$propertyTypeParameter,
												n3: $author$project$Quotation$Common$StepModel$isPropertyTypeFieldValid(model)
											}))
									]),
								model));
					case 4:
						var _v5 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$villaTypeParameter,
												$author$project$Quotation$Common$Analytics$getVillaTypeParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$villaTypeParameter,
											n3: $author$project$Quotation$Common$StepModel$isVillaTypeFieldValid(model)
										})),
								A3($author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId, model)));
					case 5:
						var _v6 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$buildingAdministratorParameter,
												$author$project$Quotation$Common$Analytics$getBuildingAdministratorParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$buildingAdministratorParameter,
											n3: $author$project$Quotation$Common$StepModel$isBuildingAdministratorFieldValid(model)
										})),
								A3($author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId, model)));
					case 2:
						var _v7 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$addCommand,
								$author$project$Utils$send(
									$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
										{
											bb: _Utils_ap(
												$author$project$Quotation$Common$Analytics$constructionPeriodParameter,
												$author$project$Quotation$Common$Analytics$getConstructionPeriodParameterSuffix(model)),
											ay: $author$project$Quotation$Common$Analytics$constructionPeriodParameter,
											n3: $author$project$Quotation$Common$StepModel$isConstructionPeriodFieldValid(model)
										})),
								A3($author$project$Quotation$Home$PropertyStep$Update$withExpandableFieldsUpdateCmd, true, $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId, model)));
					case 6:
						var _v8 = msg.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$Quotation$Common$StepModel$mapFormData,
									$author$project$Quotation$Common$Fields$Model$setPropertySizeOnBlurMsg(
										$author$project$Quotation$Home$PropertyStep$Model$SendGTMFormEvent(
											{
												bb: $author$project$Quotation$Common$Analytics$propertySizeParameter,
												ay: $author$project$Quotation$Common$Analytics$propertySizeParameter,
												n3: $author$project$Quotation$Common$StepModel$isPropertySizeFieldValid(model)
											})),
									model)));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
				}
		}
	});
var $author$project$Quotation$Home$Model$updateHydrateState = F2(
	function (updater, model) {
		if (model.$ === 2) {
			var modelState = model.a;
			return $author$project$Quotation$Home$Model$ModelInitialized(
				_Utils_update(
					modelState,
					{
						aA: updater(modelState.aA)
					}));
		} else {
			return model;
		}
	});
var $author$project$Quotation$Home$AddressStep$Model$OnBlurSelect = {$: 18};
var $author$project$Quotation$Home$CustomerStep$Model$OnBlurSelect = {$: 14};
var $author$project$Quotation$Home$Update$updateModelOnBlur = function (model) {
	var _v0 = $author$project$Quotation$Home$Model$pickStepState(model);
	_v0$2:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 1:
					var addressStepModel = _v0.a.a;
					return A6(
						$author$project$Utils$UpdateReturn$updateChildModel,
						model,
						$author$project$Quotation$Home$AddressStep$Update$update,
						$author$project$Quotation$Home$AddressStep$Model$OnBlurSelect,
						A2($elm$core$Basics$always, $author$project$Quotation$Home$Model$setAddressStepModel, model),
						addressStepModel,
						$author$project$Quotation$Home$Model$AddressStepMsg);
				case 2:
					var customerStepModel = _v0.a.a;
					return A6(
						$author$project$Utils$UpdateReturn$updateChildModel,
						model,
						$author$project$Quotation$Home$CustomerStep$Update$update,
						$author$project$Quotation$Home$CustomerStep$Model$OnBlurSelect,
						A2($elm$core$Basics$always, $author$project$Quotation$Home$Model$setCustomerStepModel, model),
						customerStepModel,
						$author$project$Quotation$Home$Model$CustomerStepMsg);
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
		$author$project$Utils$withoutCommands(model));
};
var $author$project$Quotation$Home$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var step = msg.a;
				var tokenType = msg.b;
				var user = msg.c;
				switch (model.$) {
					case 0:
						var flags = model.a;
						if (step === 1) {
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$send(
											$author$project$Quotation$Home$Model$PropertyStepMsg($author$project$Quotation$Home$PropertyStep$Model$Initialize))
										]),
									A3($author$project$Quotation$Home$Model$initialPropertyStepModel, tokenType, user, flags)));
						} else {
							return A2(
								$author$project$Utils$UpdateReturn$withPushUrl,
								A2($author$project$Routing$Quotation, 0, 1),
								$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
									$author$project$Utils$withoutCommands(model)));
						}
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					default:
						var modelState = model.a;
						return A4($author$project$Quotation$Home$Update$maybeChangeStep, tokenType, user, step, modelState);
				}
			case 1:
				var tokenType = msg.a;
				var user = msg.b;
				var flags = msg.c;
				var quote = msg.d;
				var city = msg.e;
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					A2($author$project$Routing$Quotation, 0, 1),
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A5($author$project$Quotation$Home$Update$initWithQuote, tokenType, user, flags, quote, city)));
			case 2:
				if (msg.a.$ === 13) {
					var _v3 = msg.a;
					var flags = _v3.a;
					var tokenType = _v3.b;
					var user = _v3.c;
					var date = _v3.d;
					var propertyStepResult = _v3.e;
					var newModel = A2(
						$author$project$Quotation$Home$Model$updateHydrateState,
						$author$project$Quotation$Home$Model$setPropertyHydrateState(propertyStepResult),
						model);
					var initialState = A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.ba;
						},
						$author$project$Quotation$Home$Model$pickHydrateState(newModel));
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A2($author$project$Routing$Quotation, 0, 2),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$Model$AddressStepMsg($author$project$Quotation$Home$AddressStep$Model$Initialize))
									]),
								A3(
									$elm_community$basics_extra$Basics$Extra$flip,
									$author$project$Quotation$Home$Model$setAddressStepModel,
									A6($author$project$Quotation$Home$AddressStep$Model$initialState, flags, tokenType, user, date, propertyStepResult, initialState),
									newModel))));
				} else {
					var propertyStepMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (propertyStepModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Home$PropertyStep$Update$update,
									propertyStepMsg,
									A2($elm$core$Basics$always, $author$project$Quotation$Home$Model$setPropertyStepModel, model),
									propertyStepModel,
									$author$project$Quotation$Home$Model$PropertyStepMsg);
							},
							$author$project$Quotation$Home$Model$pickPropertyStepModel(model)));
				}
			case 3:
				switch (msg.a.$) {
					case 14:
						var _v4 = msg.a;
						var flags = _v4.a;
						var tokenType = _v4.b;
						var user = _v4.c;
						var date = _v4.d;
						var propertyStepResult = _v4.e;
						var addressStepResult = _v4.f;
						var newModel = A2(
							$author$project$Quotation$Home$Model$updateHydrateState,
							$author$project$Quotation$Home$Model$setAddressHydrateState(
								$toastal$either$Either$Left(addressStepResult)),
							A2(
								$author$project$Quotation$Home$Model$updateHydrateState,
								$author$project$Quotation$Home$Model$setPropertyHydrateState(propertyStepResult),
								model));
						var initialState = A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.X;
							},
							$author$project$Quotation$Home$Model$pickHydrateState(newModel));
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							A2($author$project$Routing$Quotation, 0, 3),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$send(
											$author$project$Quotation$Home$Model$CustomerStepMsg($author$project$Quotation$Home$CustomerStep$Model$Initialize))
										]),
									A2(
										$author$project$Quotation$Home$Model$setCustomerStepModel,
										newModel,
										A7($author$project$Quotation$Home$CustomerStep$Model$initialState, flags, tokenType, user, date, propertyStepResult, addressStepResult, initialState)))));
					case 16:
						var _v5 = msg.a;
						var flags = _v5.a;
						var tokenType = _v5.b;
						var user = _v5.c;
						var currentDate = _v5.d;
						var propertyResult = _v5.e;
						var addressPartialData = _v5.f;
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							A2($author$project$Routing$Quotation, 0, 1),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								A2(
									$author$project$Utils$withCommands,
									_List_fromArray(
										[
											$author$project$Utils$send(
											$author$project$Quotation$Home$Model$PropertyStepMsg($author$project$Quotation$Home$PropertyStep$Model$Initialize))
										]),
									A3(
										$elm_community$basics_extra$Basics$Extra$flip,
										$author$project$Quotation$Home$Model$setPropertyStepModel,
										A6($author$project$Quotation$Home$PropertyStep$Model$initialStateFromResult, flags, tokenType, user, true, currentDate, propertyResult),
										A2(
											$author$project$Quotation$Home$Model$updateHydrateState,
											$author$project$Quotation$Home$Model$setAddressHydrateState(
												$toastal$either$Either$Right(addressPartialData)),
											model)))));
					default:
						var addressStepMsg = msg.a;
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(model)),
							A2(
								$elm$core$Maybe$map,
								function (addressStepModel) {
									return A6(
										$author$project$Utils$UpdateReturn$updateChildModel,
										model,
										$author$project$Quotation$Home$AddressStep$Update$update,
										addressStepMsg,
										A2($elm$core$Basics$always, $author$project$Quotation$Home$Model$setAddressStepModel, model),
										addressStepModel,
										$author$project$Quotation$Home$Model$AddressStepMsg);
								},
								$author$project$Quotation$Home$Model$pickAddressStepModel(model)));
				}
			case 4:
				if (msg.a.$ === 13) {
					var _v6 = msg.a;
					var flags = _v6.a;
					var tokenType = _v6.b;
					var user = _v6.c;
					var currentDate = _v6.d;
					var propertyStepResult = _v6.e;
					var addressStepResult = _v6.f;
					var customerData = _v6.g;
					var newModel = A2(
						$author$project$Quotation$Home$Model$updateHydrateState,
						$author$project$Quotation$Home$Model$setCustomerHydrateState(
							$toastal$either$Either$Right(customerData)),
						A2(
							$author$project$Quotation$Home$Model$updateHydrateState,
							$author$project$Quotation$Home$Model$setAddressHydrateState(
								$toastal$either$Either$Left(addressStepResult)),
							A2(
								$author$project$Quotation$Home$Model$updateHydrateState,
								$author$project$Quotation$Home$Model$setPropertyHydrateState(propertyStepResult),
								model)));
					return A2(
						$author$project$Utils$UpdateReturn$withPushUrl,
						A2($author$project$Routing$Quotation, 0, 2),
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$send(
										$author$project$Quotation$Home$Model$AddressStepMsg($author$project$Quotation$Home$AddressStep$Model$Initialize))
									]),
								A3(
									$elm_community$basics_extra$Basics$Extra$flip,
									$author$project$Quotation$Home$Model$setAddressStepModel,
									A6(
										$author$project$Quotation$Home$AddressStep$Model$initialState,
										flags,
										tokenType,
										user,
										currentDate,
										propertyStepResult,
										$elm$core$Maybe$Just(
											$toastal$either$Either$Left(addressStepResult))),
									newModel))));
				} else {
					var customerStepMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (customerStepModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Home$CustomerStep$Update$update,
									customerStepMsg,
									A2($elm$core$Basics$always, $author$project$Quotation$Home$Model$setCustomerStepModel, model),
									customerStepModel,
									$author$project$Quotation$Home$Model$CustomerStepMsg);
							},
							$author$project$Quotation$Home$Model$pickCustomerStepModel(model)));
				}
			default:
				return $author$project$Quotation$Home$Update$updateModelOnBlur(model);
		}
	});
var $author$project$Quotation$Model$GotQuoteQueryResponse = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$NewQuotationSource = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Update$quoteQuery = function (id) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$NewQuotationSource(
			$author$project$Api$GetQuote($author$project$Quotation$Model$GotQuoteQueryResponse)),
		$author$project$Guarantees$Api$Quote$quoteQuery(id));
};
var $author$project$Quotation$Model$GotSaveQueryResponse = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Update$saveQuery = function (id) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$NewQuotationSource(
			$author$project$Api$GetSave($author$project$Quotation$Model$GotSaveQueryResponse)),
		$author$project$Guarantees$Api$Save$saveQuery(id));
};
var $author$project$Quotation$Update$withQuoteAndSaveQuery = F3(
	function (maybeQuoteId, maybeSaveId, updateReturn) {
		return A2(
			$elm$core$Maybe$withDefault,
			updateReturn,
			A2(
				$elm$core$Maybe$map,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$Utils$UpdateReturn$withQuery, updateReturn),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$or,
					A2($elm$core$Maybe$map, $author$project$Quotation$Update$quoteQuery, maybeQuoteId),
					A2($elm$core$Maybe$map, $author$project$Quotation$Update$saveQuery, maybeSaveId))));
	});
var $author$project$Quotation$Update$initializeEmptyModel = F5(
	function (formType, step, tokenType, user, flags) {
		if (!formType) {
			return A2(
				$author$project$Quotation$Update$update,
				A3($author$project$Quotation$Model$homeInitializeMsg, step, tokenType, user),
				$author$project$Quotation$Model$setHomeModel(
					$author$project$Quotation$Home$Model$ModelNotInitialized(flags)));
		} else {
			return A2(
				$author$project$Quotation$Update$update,
				A3($author$project$Quotation$Model$familyInitializeMsg, step, tokenType, user),
				$author$project$Quotation$Model$setFamilyModel(
					$author$project$Quotation$Family$Model$ModelNotInitialized(flags)));
		}
	});
var $author$project$Quotation$Update$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var formType = msg.a;
					var step = msg.b;
					var tokenType = msg.c;
					var user = msg.d;
					var _v1 = _Utils_Tuple2(model, formType);
					_v1$2:
					while (true) {
						if (!_v1.b) {
							if (_v1.a.$ === 2) {
								var _v2 = _v1.b;
								return A2(
									$author$project$Quotation$Update$update,
									A3($author$project$Quotation$Model$homeInitializeMsg, step, tokenType, user),
									model);
							} else {
								break _v1$2;
							}
						} else {
							if (_v1.a.$ === 3) {
								var _v3 = _v1.b;
								return A2(
									$author$project$Quotation$Update$update,
									A3($author$project$Quotation$Model$familyInitializeMsg, step, tokenType, user),
									model);
							} else {
								break _v1$2;
							}
						}
					}
					return A5(
						$author$project$Quotation$Update$initializeEmptyModel,
						formType,
						step,
						tokenType,
						user,
						$author$project$Quotation$Model$pickFlags(model));
				case 1:
					var maybeQuoteId = msg.a;
					var maybeSaveId = msg.b;
					var tokenType = msg.c;
					var user = msg.d;
					return A3(
						$author$project$Quotation$Update$withQuoteAndSaveQuery,
						maybeQuoteId,
						maybeSaveId,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A5(
									$author$project$Quotation$Model$hydratingModel,
									maybeQuoteId,
									maybeSaveId,
									tokenType,
									user,
									$author$project$Quotation$Model$pickFlags(model)))));
				case 8:
					var maybeFormType = msg.a;
					if ((!maybeFormType.$) && (maybeFormType.a === 1)) {
						var _v5 = maybeFormType.a;
						return A2(
							$author$project$Quotation$Update$update,
							A2($author$project$Quotation$Update$hydrateSubmodel, $author$project$Quotation$Update$familyMsgMapper, model),
							$author$project$Quotation$Model$setFamilyModel(
								$author$project$Quotation$Family$Model$ModelNotInitialized(
									$author$project$Quotation$Model$pickFlags(model))));
					} else {
						return A2(
							$author$project$Quotation$Update$update,
							A2($author$project$Quotation$Update$hydrateSubmodel, $author$project$Quotation$Update$homeMsgMapper, model),
							$author$project$Quotation$Model$setHomeModel(
								$author$project$Quotation$Home$Model$ModelNotInitialized(
									$author$project$Quotation$Model$pickFlags(model))));
					}
				case 2:
					var homeMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (homeModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Home$Update$update,
									homeMsg,
									$elm$core$Basics$always($author$project$Quotation$Model$setHomeModel),
									homeModel,
									$author$project$Quotation$Model$HomeMsg);
							},
							$author$project$Quotation$Model$pickHomeModel(model)));
				case 3:
					var familyMsg = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model)),
						A2(
							$elm$core$Maybe$map,
							function (familyModel) {
								return A6(
									$author$project$Utils$UpdateReturn$updateChildModel,
									model,
									$author$project$Quotation$Family$Update$update,
									familyMsg,
									$elm$core$Basics$always($author$project$Quotation$Model$setFamilyModel),
									familyModel,
									$author$project$Quotation$Model$FamilyMsg);
							},
							$author$project$Quotation$Model$pickFamilyModel(model)));
				case 4:
					switch (model.$) {
						case 0:
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(model));
						case 1:
							return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								$author$project$Utils$withoutCommands(model));
						case 2:
							var $temp$msg = $author$project$Quotation$Model$HomeMsg($author$project$Quotation$Home$Model$OnBlurSelect),
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update;
						default:
							var $temp$msg = $author$project$Quotation$Model$FamilyMsg($author$project$Quotation$Family$Model$OnBlurSelect),
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update;
					}
				case 5:
					var response = msg.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2($author$project$Quotation$Update$hydrateWithQuote, response, model));
				case 7:
					var response = msg.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2($author$project$Quotation$Update$hydrateWithCity, response, model));
				case 6:
					var response = msg.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2($author$project$Quotation$Update$hydrateWithSave, response, model));
				default:
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
			}
		}
	});
var $author$project$QuotationOld$Model$CurrentDate = function (a) {
	return {$: 19, a: a};
};
var $author$project$QuotationOld$Model$OnAutocompleteMsg = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$QuotationOld$Model$FormStep$Step = function (a) {
	return {$: 0, a: a};
};
var $author$project$QuotationOld$Model$FormData$pickAutocompleteFieldByName = function (name) {
	switch (name) {
		case 0:
			return A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.kY;
				},
				function ($) {
					return $.my;
				});
		case 1:
			return function ($) {
				return $.ih;
			};
		case 2:
			return function ($) {
				return $.mj;
			};
		default:
			return A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.pq;
				},
				function ($) {
					return $.my;
				});
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Debounce = function (a) {
	return {$: 5, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnFilter = {$: 1};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addCommands = F2(
	function (cmds, _v0) {
		var state = _v0.a;
		var cmd = _v0.b;
		var filter = _v0.c;
		return _Utils_Tuple3(
			state,
			$elm$core$Platform$Cmd$batch(
				_Utils_ap(
					_List_fromArray(
						[cmd]),
					cmds)),
			filter);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter = F2(
	function (filter, _v0) {
		var state = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(state, cmd, filter);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$close = function (_v0) {
	var state = _v0;
	return _Utils_update(
		state,
		{Z: false});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$maybeWithFilter = F2(
	function (filter, _v0) {
		var state = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple3(
			state,
			cmd,
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$hasReachedThreshold(state) ? $elm$core$Maybe$Just(value) : $elm$core$Maybe$Nothing;
				},
				filter));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$open = function (_v0) {
	var state = _v0;
	return _Utils_update(
		state,
		{Z: true});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$send = A2(
	$elm$core$Basics$composeL,
	$elm$core$Task$perform($elm$core$Basics$identity),
	$elm$core$Task$succeed);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnFilter = function (stateCmdFilter) {
	var state = stateCmdFilter.a;
	var cmd = stateCmdFilter.b;
	var filter = stateCmdFilter.c;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(filter) ? _Utils_Tuple3(
		_Utils_update(
			state,
			{av: $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loading}),
		cmd,
		filter) : stateCmdFilter;
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnInput = F2(
	function (value, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{
				dr: A2($elm$core$Maybe$map, $elm$core$String$toLower, value),
				Z: true
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoiceByIndex = function (index) {
	return A2(
		$elm$core$Basics$composeR,
		$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Array$fromList,
			$elm$core$Array$get(index)));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickFocusedItemIndex = function (_v0) {
	var stateConfig = _v0;
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		0,
		$elm$core$Tuple$first,
		$elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Basics$eq(stateConfig.az),
							$elm$core$Maybe$Just),
						function ($) {
							return $.p8;
						}),
					$elm$core$Tuple$second),
				A2(
					$elm$core$List$indexedMap,
					$elm$core$Tuple$pair,
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoices(stateConfig)))));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnKeyDown = function (stateModel) {
	var state = stateModel;
	var thereIsNoFocusedItem = _Utils_eq($elm$core$Maybe$Nothing, state.az);
	var focusedItemIndex = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickFocusedItemIndex(stateModel);
	var wentTooLow = A2(
		$primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooLow,
		focusedItemIndex,
		function () {
			var _v0 = state.av;
			if (!_v0.$) {
				var choices = _v0.a;
				return choices;
			} else {
				return _List_Nil;
			}
		}());
	return _Utils_update(
		state,
		{
			az: A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.p8;
				},
				A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoiceByIndex,
					(thereIsNoFocusedItem || wentTooLow) ? 0 : (focusedItemIndex + 1),
					state))
		});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnKeyUp = function (stateModel) {
	var state = stateModel;
	var focusedItemIndex = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickFocusedItemIndex(stateModel);
	return _Utils_update(
		state,
		{
			az: A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.p8;
				},
				A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$pickChoiceByIndex,
					$primait$pyxis_components$Prima$Pyxis$Form$Commons$KeyboardEvents$wentTooHigh(focusedItemIndex) ? focusedItemIndex : (focusedItemIndex - 1),
					state))
		});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnReset = function (_v0) {
	var state = _v0;
	return _Utils_update(
		state,
		{dr: $elm$core$Maybe$Nothing, Z: false, pC: $elm$core$Maybe$Nothing});
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnSelect = F2(
	function (value, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{Z: false, pC: value});
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$withCmds = F2(
	function (cmds, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(cmds));
	});
var $primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds = $primait$pyxis_components$Prima$Pyxis$Helpers$withCmds(_List_Nil);
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$update = F2(
	function (msg, stateModel) {
		var state = stateModel;
		switch (msg.$) {
			case 4:
				var value = msg.a;
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
					$elm$core$Maybe$Nothing,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$withCmds,
						_List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$send(
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Debounce(
									$Gizra$elm_debouncer$Debouncer$Basic$provideInput($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$OnFilter)))
							]),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnInput,
							$elm$core$Maybe$Just(value),
							stateModel)));
			case 5:
				var subMsg = msg.a;
				var _v1 = A2($Gizra$elm_debouncer$Debouncer$Basic$update, subMsg, state.fb);
				var debouncerState = _v1.a;
				var debouncerCmd = _v1.b;
				var emittedMsg = _v1.c;
				var debounceCmd = A2($elm$core$Platform$Cmd$map, $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Debounce, debouncerCmd);
				var updatedState = A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateDebouncer, debouncerState, stateModel);
				if (!emittedMsg.$) {
					var emitted = emittedMsg.a;
					return A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addCommands,
						_List_fromArray(
							[debounceCmd]),
						A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$update, emitted, updatedState));
				} else {
					return A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
						$elm$core$Maybe$Nothing,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Helpers$withCmds,
							_List_fromArray(
								[debounceCmd]),
							updatedState));
				}
			case 1:
				return $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnFilter(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$maybeWithFilter,
						state.dr,
						$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(stateModel)));
			case 2:
				var value = msg.a;
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
					$elm$core$Maybe$Nothing,
					$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnSelect,
							$elm$core$Maybe$Just(value),
							stateModel)));
			case 3:
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
					$elm$core$Maybe$Nothing,
					$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnReset(stateModel)));
			case 0:
				if (!msg.a.$) {
					switch (msg.a.a) {
						case 0:
							var _v3 = msg.a.a;
							return A2(
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
								$elm$core$Maybe$Nothing,
								$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnKeyUp(stateModel)));
						case 1:
							var _v4 = msg.a.a;
							return A2(
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
								$elm$core$Maybe$Nothing,
								$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
									$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnKeyDown(stateModel)));
						default:
							var _v5 = msg.a.a;
							return A2(
								$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
								$elm$core$Maybe$Nothing,
								$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
									A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateOnSelect, state.az, stateModel)));
					}
				} else {
					var _v6 = msg.a;
					return A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
						$elm$core$Maybe$Nothing,
						$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(stateModel));
				}
			case 7:
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
					$elm$core$Maybe$Nothing,
					$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$close(stateModel)));
			default:
				return A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$addReturningFilter,
					$elm$core$Maybe$Nothing,
					$primait$pyxis_components$Prima$Pyxis$Helpers$withoutCmds(
						$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$open(stateModel)));
		}
	});
var $author$project$QuotationOld$Model$FormData$autocompleteUpdate = F3(
	function (fieldName, autocompleteMsg, formData) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$update,
			autocompleteMsg,
			A2($author$project$QuotationOld$Model$FormData$pickAutocompleteFieldByName, fieldName, formData).cG);
	});
var $author$project$QuotationOld$Model$FormStep$Complete = {$: 1};
var $author$project$QuotationOld$Model$SelectField$emptyValue = function (field) {
	return _Utils_update(
		field,
		{p8: $elm$core$Maybe$Nothing});
};
var $author$project$QuotationOld$Model$updateFormData = F2(
	function (updater, model) {
		var formData = model.dv;
		return _Utils_update(
			model,
			{
				dv: updater(formData)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateRadioField = F3(
	function (fieldName, updater, formData) {
		switch (fieldName) {
			case 0:
				return _Utils_update(
					formData,
					{
						oL: updater(formData.oL)
					});
			case 1:
				return _Utils_update(
					formData,
					{
						cY: updater(formData.cY)
					});
			case 2:
				return _Utils_update(
					formData,
					{
						mN: updater(formData.mN)
					});
			case 3:
				return _Utils_update(
					formData,
					{
						qa: updater(formData.qa)
					});
			case 4:
				return _Utils_update(
					formData,
					{
						dI: updater(formData.dI)
					});
			case 5:
				return _Utils_update(
					formData,
					{
						o4: updater(formData.o4)
					});
			default:
				return _Utils_update(
					formData,
					{
						ny: updater(formData.ny)
					});
		}
	});
var $author$project$QuotationOld$Model$FormData$updateSelectField = F3(
	function (fieldName, updater, formData) {
		var updateZipCode = function (address) {
			return _Utils_update(
				address,
				{
					qg: updater(address.qg)
				});
		};
		switch (fieldName) {
			case 0:
				return _Utils_update(
					formData,
					{
						o1: updater(formData.o1)
					});
			case 1:
				return _Utils_update(
					formData,
					{
						nw: updater(formData.nw)
					});
			case 2:
				return _Utils_update(
					formData,
					{
						mP: updater(formData.mP)
					});
			case 3:
				return _Utils_update(
					formData,
					{
						pn: updater(formData.pn)
					});
			case 4:
				return _Utils_update(
					formData,
					{
						np: updater(formData.np)
					});
			case 5:
				return _Utils_update(
					formData,
					{
						nr: updater(formData.nr)
					});
			case 6:
				return _Utils_update(
					formData,
					{
						kY: updateZipCode(formData.kY)
					});
			default:
				return _Utils_update(
					formData,
					{
						pq: updateZipCode(formData.pq)
					});
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$orList = function (maybes) {
	orList:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (maybes.a.$ === 1) {
				var _v1 = maybes.a;
				var rest = maybes.b;
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue orList;
			} else {
				var answer = maybes.a.a;
				return $elm$core$Maybe$Just(answer);
			}
		}
	}
};
var $author$project$QuotationOld$Update$Validation$requiredErrorMessage = $elm$core$Maybe$Just('Richiesto');
var $author$project$QuotationOld$Model$RadioField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		var value = _v0.a;
		return $elm$core$String$isEmpty(value) ? $author$project$QuotationOld$Update$Validation$requiredErrorMessage : $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$RadioField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$RadioField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			if (!validation.$) {
				return $author$project$QuotationOld$Model$RadioField$requiredValidation;
			} else {
				return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$RadioField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$RadioField$updateValue = F2(
	function (value, field) {
		return $author$project$QuotationOld$Model$RadioField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Update$Helper$clearApartmentFields = function (model) {
	var formData = model.dv;
	return (_Utils_eq(
		formData.o4.p8,
		$elm$core$Maybe$Just('HOUSE')) ? A2(
		$elm$core$Basics$composeR,
		$author$project$QuotationOld$Model$updateFormData(
			A2(
				$author$project$QuotationOld$Model$FormData$updateRadioField,
				2,
				$author$project$QuotationOld$Model$RadioField$updateValue($elm$core$Maybe$Nothing))),
		$author$project$QuotationOld$Model$updateFormData(
			A2($author$project$QuotationOld$Model$FormData$updateSelectField, 1, $author$project$QuotationOld$Model$SelectField$emptyValue))) : $elm$core$Basics$identity)(model);
};
var $author$project$QuotationOld$Update$Helper$clearHouseFields = function (model) {
	var formData = model.dv;
	return (_Utils_eq(
		formData.o4.p8,
		$elm$core$Maybe$Just('APARTMENT')) ? $author$project$QuotationOld$Model$updateFormData(
		A2(
			$author$project$QuotationOld$Model$FormData$updateRadioField,
			3,
			$author$project$QuotationOld$Model$RadioField$updateValue($elm$core$Maybe$Nothing))) : $elm$core$Basics$identity)(model);
};
var $author$project$QuotationOld$Model$SelectField$updateState = F2(
	function (state, field) {
		return _Utils_update(
			field,
			{cG: state});
	});
var $author$project$QuotationOld$Model$SelectField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		var value = _v0.a;
		return $elm$core$String$isEmpty(value) ? $author$project$QuotationOld$Update$Validation$requiredErrorMessage : $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$SelectField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$SelectField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			if (!validation.$) {
				return $author$project$QuotationOld$Model$SelectField$requiredValidation;
			} else {
				return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$SelectField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$SelectField$updateValue = F2(
	function (value, field) {
		return $author$project$QuotationOld$Model$SelectField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Model$SelectField$updateStateAndValue = F3(
	function (choices, defaultValue, field) {
		var initState = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $primait$pyxis_components$Prima$Pyxis$Form$Select$init, $primait$pyxis_components$Prima$Pyxis$Form$Select$initWithDefault, defaultValue);
		return A2(
			$author$project$QuotationOld$Model$SelectField$updateValue,
			defaultValue,
			A3(
				$elm_community$basics_extra$Basics$Extra$flip,
				$author$project$QuotationOld$Model$SelectField$updateState,
				field,
				initState(
					A2(
						$elm$core$List$map,
						$author$project$Utils$applyTupleAsParameters($primait$pyxis_components$Prima$Pyxis$Form$Select$selectChoice),
						choices))));
	});
var $author$project$QuotationOld$Update$Helper$clearPropertyPurpose = function (model) {
	var formData = model.dv;
	return (_Utils_eq(
		formData.oL.p8,
		$elm$core$Maybe$Just('TENANT')) ? $author$project$QuotationOld$Model$updateFormData(
		A2(
			$author$project$QuotationOld$Model$FormData$updateSelectField,
			0,
			A2(
				$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
				$author$project$QuotationOld$Model$FormData$propertyPurposeOptions,
				$elm$core$Maybe$Just('RESIDENTIAL')))) : $elm$core$Basics$identity)(model);
};
var $author$project$QuotationOld$Update$Helper$clearUnnecessaryFormData = function (step) {
	return _Utils_eq(step, $author$project$QuotationOld$Model$FormStep$Complete) ? A2(
		$elm$core$Basics$composeR,
		$author$project$QuotationOld$Update$Helper$clearPropertyPurpose,
		A2($elm$core$Basics$composeR, $author$project$QuotationOld$Update$Helper$clearApartmentFields, $author$project$QuotationOld$Update$Helper$clearHouseFields)) : $elm$core$Basics$identity;
};
var $author$project$QuotationOld$Model$SelectField$close = function (field) {
	return _Utils_update(
		field,
		{
			cG: $primait$pyxis_components$Prima$Pyxis$Form$Select$close(field.cG)
		});
};
var $author$project$QuotationOld$Update$Helper$closeOtherSelects = F2(
	function (field, model) {
		var selectFields = _List_fromArray(
			[3, 1, 2, 4, 5]);
		var selectsToClose = A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(field),
			selectFields);
		return A3(
			$elm$core$List$foldl,
			A2(
				$elm$core$Basics$composeR,
				A2($elm_community$basics_extra$Basics$Extra$flip, $author$project$QuotationOld$Model$FormData$updateSelectField, $author$project$QuotationOld$Model$SelectField$close),
				$author$project$QuotationOld$Model$updateFormData),
			model,
			selectsToClose);
	});
var $author$project$Types$Utils$condominiumTypeToValue = function (_enum) {
	if (!_enum) {
		return $elm$core$Maybe$Just('UP_TO_EIGHT_APARTMENT');
	} else {
		return $elm$core$Maybe$Just('MORE_THAN_EIGHT_APARTMENT');
	}
};
var $author$project$Types$Utils$constructionPeriodToValue = function (_enum) {
	return $elm$core$Maybe$Just(
		function () {
			switch (_enum) {
				case 0:
					return 'UNKNOWN';
				case 1:
					return 'BEFORE1970';
				case 2:
					return 'BEFORE1940';
				case 3:
					return 'DECADE1940';
				case 4:
					return 'DECADE1950';
				case 5:
					return 'DECADE1960';
				case 6:
					return 'DECADE1970';
				case 7:
					return 'DECADE1980';
				case 8:
					return 'DECADE1990';
				case 9:
					return 'DECADE2000';
				case 10:
					return 'DECADE2010';
				default:
					return 'DECADE2020';
			}
		}());
};
var $author$project$Types$Utils$birthCityToLabel = function (city) {
	if (!city.$) {
		return 'Stato Estero';
	} else {
		var city_ = city.a;
		return city_;
	}
};
var $author$project$Types$Utils$birthCityToValue = function (city) {
	if (!city.$) {
		return 'Stato Estero';
	} else {
		var city_ = city.a;
		return city_;
	}
};
var $author$project$QuotationOld$Model$ExtraTypes$fromBirthCityToChoice = function (birthCity) {
	return _Utils_Tuple2(
		$author$project$Types$Utils$birthCityToLabel(birthCity),
		$author$project$Types$Utils$birthCityToValue(birthCity));
};
var $author$project$Types$Utils$genderToValue = function (_enum) {
	if (!_enum) {
		return 'M';
	} else {
		return 'F';
	}
};
var $author$project$QuotationOld$Model$GotCitiesWithDefault = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$QuotationOld$Api$City$getCitiesByLandRegisterRequest = F5(
	function (fieldName, vianelloUrl, city, active, landRegister) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			A3($author$project$QuotationOld$Model$GotCitiesWithDefault, fieldName, city, $elm$core$Maybe$Nothing),
			A3($author$project$QuotationOld$Api$City$citiesByLandRegisterQuery, city, landRegister, active));
	});
var $author$project$QuotationOld$Api$City$citiesByZipCodeQuery = F3(
	function (city, zipCode, active) {
		return A2(
			$author$project$Gql$Vianello$Query$cities,
			{
				dr: {
					a8: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(active),
					S: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					oq: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(city),
					qg: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(zipCode)
				}
			},
			$author$project$QuotationOld$Api$City$citySelection);
	});
var $author$project$QuotationOld$Api$City$getCitiesByZipCodeRequest = F5(
	function (fieldName, vianelloUrl, city, active, zipCode) {
		return A3(
			$author$project$Api$makeRequest,
			vianelloUrl,
			A3(
				$author$project$QuotationOld$Model$GotCitiesWithDefault,
				fieldName,
				city,
				$elm$core$Maybe$Just(zipCode)),
			A3($author$project$QuotationOld$Api$City$citiesByZipCodeQuery, city, zipCode, active));
	});
var $author$project$Types$Utils$houseTypeToValue = function (_enum) {
	if (!_enum) {
		return $elm$core$Maybe$Just('SINGLE_FAMILY');
	} else {
		return $elm$core$Maybe$Just('TERRACED_HOUSE');
	}
};
var $author$project$Types$Utils$ownershipToValue = function (_enum) {
	switch (_enum) {
		case 0:
			return 'OWNER';
		case 1:
			return 'TENANT';
		default:
			return '';
	}
};
var $author$project$Types$Utils$propertyTypeToValue = function (_enum) {
	if (!_enum) {
		return $elm$core$Maybe$Just('APARTMENT');
	} else {
		return $elm$core$Maybe$Just('HOUSE');
	}
};
var $author$project$Types$Utils$propertyUsageTypeToValue = function (_enum) {
	switch (_enum) {
		case 0:
			return $elm$core$Maybe$Just('RESIDENTIAL');
		case 1:
			return $elm$core$Maybe$Just('SECOND_HOUSE');
		case 2:
			return $elm$core$Maybe$Just('RENTED');
		case 3:
			return $elm$core$Maybe$Just('UNINHABITED');
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$QuotationOld$Model$FormData$updateCity = F2(
	function (updater, address) {
		return _Utils_update(
			address,
			{
				my: updater(address.my)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateAutocompleteField = F3(
	function (fieldName, updater, formData) {
		switch (fieldName) {
			case 0:
				return _Utils_update(
					formData,
					{
						kY: A2($author$project$QuotationOld$Model$FormData$updateCity, updater, formData.kY)
					});
			case 1:
				return _Utils_update(
					formData,
					{
						ih: updater(formData.ih)
					});
			case 2:
				return _Utils_update(
					formData,
					{
						mj: updater(formData.mj)
					});
			default:
				return _Utils_update(
					formData,
					{
						pq: A2($author$project$QuotationOld$Model$FormData$updateCity, updater, formData.pq)
					});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loaded = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initWithDefault = F2(
	function (defaultSelectedLabel, defaultSelectedValue) {
		return A7(
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$StateConfig,
			$elm$core$Maybe$Just(defaultSelectedValue),
			$elm$core$Maybe$Just(defaultSelectedValue),
			$elm$core$Maybe$Nothing,
			false,
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loaded(
				_List_fromArray(
					[
						{n6: defaultSelectedLabel, p8: defaultSelectedValue}
					])),
			$primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initDebouncer(0),
			1);
	});
var $author$project$QuotationOld$Model$AutocompleteField$updateState = F2(
	function (newState, field) {
		return _Utils_update(
			field,
			{cG: newState});
	});
var $author$project$QuotationOld$Model$AutocompleteField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		var value = _v0.a;
		return $elm$core$String$isEmpty(value) ? $author$project$QuotationOld$Update$Validation$requiredErrorMessage : $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$AutocompleteField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$AutocompleteField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			if (!validation.$) {
				return $author$project$QuotationOld$Model$AutocompleteField$requiredValidation;
			} else {
				return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$AutocompleteField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$AutocompleteField$updateValue = F2(
	function (value, field) {
		return $author$project$QuotationOld$Model$AutocompleteField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Model$AutocompleteField$updateByChoice = F2(
	function (_v0, field) {
		var value = _v0.a;
		var label = _v0.b;
		var newState = A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$initWithDefault, label, value);
		return A2(
			$author$project$QuotationOld$Model$AutocompleteField$updateValue,
			$elm$core$Maybe$Just(value),
			A2($author$project$QuotationOld$Model$AutocompleteField$updateState, newState, field));
	});
var $author$project$QuotationOld$Model$FormData$updateDatePickerField = F3(
	function (fieldName, updater, formData) {
		return _Utils_update(
			formData,
			{
				mn: updater(formData.mn)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateNumberField = F3(
	function (fieldName, updater, formData) {
		if (!fieldName) {
			return _Utils_update(
				formData,
				{
					o2: updater(formData.o2)
				});
		} else {
			return _Utils_update(
				formData,
				{
					dJ: updater(formData.dJ)
				});
		}
	});
var $author$project$QuotationOld$Model$FormData$updateAddressInternal = F2(
	function (updater, address) {
		return _Utils_update(
			address,
			{
				nX: updater(address.nX)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateAddressNumber = F2(
	function (updater, address) {
		return _Utils_update(
			address,
			{
				kp: updater(address.kp)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateAddressStair = F2(
	function (updater, address) {
		return _Utils_update(
			address,
			{
				pJ: updater(address.pJ)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateAddressStreet = F2(
	function (updater, address) {
		return _Utils_update(
			address,
			{
				lx: updater(address.lx)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateTextField = F3(
	function (fieldName, updater, formData) {
		switch (fieldName) {
			case 1:
				return _Utils_update(
					formData,
					{
						kY: A2($author$project$QuotationOld$Model$FormData$updateAddressNumber, updater, formData.kY)
					});
			case 2:
				return _Utils_update(
					formData,
					{
						kY: A2($author$project$QuotationOld$Model$FormData$updateAddressStair, updater, formData.kY)
					});
			case 3:
				return _Utils_update(
					formData,
					{
						kY: A2($author$project$QuotationOld$Model$FormData$updateAddressInternal, updater, formData.kY)
					});
			case 5:
				return _Utils_update(
					formData,
					{
						pq: A2($author$project$QuotationOld$Model$FormData$updateAddressNumber, updater, formData.pq)
					});
			case 6:
				return _Utils_update(
					formData,
					{
						pq: A2($author$project$QuotationOld$Model$FormData$updateAddressStair, updater, formData.pq)
					});
			case 7:
				return _Utils_update(
					formData,
					{
						pq: A2($author$project$QuotationOld$Model$FormData$updateAddressInternal, updater, formData.pq)
					});
			case 8:
				return _Utils_update(
					formData,
					{
						oq: updater(formData.oq)
					});
			case 9:
				return _Utils_update(
					formData,
					{
						lC: updater(formData.lC)
					});
			case 10:
				return _Utils_update(
					formData,
					{
						fi: updater(formData.fi)
					});
			case 11:
				return _Utils_update(
					formData,
					{
						oT: updater(formData.oT)
					});
			case 0:
				return _Utils_update(
					formData,
					{
						kY: A2($author$project$QuotationOld$Model$FormData$updateAddressStreet, updater, formData.kY)
					});
			default:
				return _Utils_update(
					formData,
					{
						pq: A2($author$project$QuotationOld$Model$FormData$updateAddressStreet, updater, formData.pq)
					});
		}
	});
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$QuotationOld$Model$DatePickerField$datePickerDateStringFormatter = function (datePickerDate) {
	var dateStringFormatter = function (dateString) {
		return (($elm$core$String$length(dateString) === 2) && (!A2($elm$core$String$endsWith, '/', dateString))) ? (dateString + '/') : ((($elm$core$String$length(dateString) === 5) && (!A2($elm$core$String$endsWith, '/', dateString))) ? (dateString + '/') : dateString);
	};
	if ((datePickerDate.$ === 1) && (!datePickerDate.a.$)) {
		var dateString = datePickerDate.a.a;
		return $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$PartialDate(
			$elm$core$Maybe$Just(
				dateStringFormatter(dateString)));
	} else {
		return datePickerDate;
	}
};
var $author$project$QuotationOld$Update$Validation$ageInRangeErrorMessage = $elm$core$Maybe$Just('Devi avere un\'età compresa tra 18 e 100');
var $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$toMaybeDate = function (date) {
	if (!date.$) {
		var parsedDate = date.a;
		return $elm$core$Maybe$Just(parsedDate);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$QuotationOld$Model$DatePickerField$ageInRangeValidation = F4(
	function (currentDate, minYears, maxYears, _v0) {
		var value = _v0.p8;
		var validateByDate = function (date) {
			var years = A3($justinmimbs$date$Date$diff, 0, date, currentDate);
			return ((_Utils_cmp(years, minYears) > -1) && (_Utils_cmp(years, maxYears) < 0)) ? $elm$core$Maybe$Nothing : $author$project$QuotationOld$Update$Validation$ageInRangeErrorMessage;
		};
		return A2(
			$elm$core$Maybe$andThen,
			validateByDate,
			$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$toMaybeDate(value));
	});
var $author$project$QuotationOld$Model$DatePickerField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$DatePickerField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$DatePickerField$validate = F2(
	function (today, field) {
		var execValidation = function (validation) {
			return function () {
				switch (validation.$) {
					case 0:
						return $author$project$QuotationOld$Model$DatePickerField$requiredValidation;
					case 3:
						var min = validation.a;
						var max = validation.b;
						return A3($author$project$QuotationOld$Model$DatePickerField$ageInRangeValidation, today, min, max);
					default:
						return $elm$core$Basics$always($elm$core$Maybe$Nothing);
				}
			}()(field);
		};
		return A2(
			$author$project$QuotationOld$Model$DatePickerField$updateErrorMessage,
			field,
			$elm_community$maybe_extra$Maybe$Extra$orList(
				A2($elm$core$List$map, execValidation, field.lS)));
	});
var $author$project$QuotationOld$Model$DatePickerField$updateValue = F3(
	function (today, value, field) {
		return A2(
			$author$project$QuotationOld$Model$DatePickerField$validate,
			today,
			_Utils_update(
				field,
				{
					p8: $author$project$QuotationOld$Model$DatePickerField$datePickerDateStringFormatter(value)
				}));
	});
var $author$project$QuotationOld$Update$Validation$maxValueErrorMessage = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$append('Non può essere maggiore di '),
		$elm$core$Maybe$Just));
var $author$project$QuotationOld$Model$NumberField$maxValidation = F2(
	function (max, field) {
		var _v0 = field.p8;
		if (!_v0.$) {
			var value = _v0.a;
			return (_Utils_cmp(value, max) > 0) ? $author$project$QuotationOld$Update$Validation$maxValueErrorMessage(max) : $elm$core$Maybe$Nothing;
		} else {
			return $author$project$QuotationOld$Update$Validation$maxValueErrorMessage(max);
		}
	});
var $author$project$QuotationOld$Update$Validation$minValueErrorMessage = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$append('Non può essere minore di '),
		$elm$core$Maybe$Just));
var $author$project$QuotationOld$Model$NumberField$minValidation = F2(
	function (min, field) {
		var _v0 = field.p8;
		if (!_v0.$) {
			var value = _v0.a;
			return (_Utils_cmp(value, min) < 0) ? $author$project$QuotationOld$Update$Validation$minValueErrorMessage(min) : $elm$core$Maybe$Nothing;
		} else {
			return $author$project$QuotationOld$Update$Validation$minValueErrorMessage(min);
		}
	});
var $author$project$QuotationOld$Model$NumberField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$NumberField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$NumberField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			switch (validation.$) {
				case 0:
					return $author$project$QuotationOld$Model$NumberField$requiredValidation;
				case 1:
					var min = validation.a;
					return $author$project$QuotationOld$Model$NumberField$minValidation(min);
				case 2:
					var max = validation.a;
					return $author$project$QuotationOld$Model$NumberField$maxValidation(max);
				default:
					return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$NumberField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$NumberField$updateValue = F2(
	function (strValue, field) {
		var value = A2($elm$core$Maybe$andThen, $elm$core$String$toInt, strValue);
		return $author$project$QuotationOld$Model$NumberField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Update$Validation$maxLengthErrorMessage = function () {
	var message = function (max) {
		return 'Non può essere più di ' + (max + ' lettere');
	};
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		A2($elm$core$Basics$composeR, message, $elm$core$Maybe$Just));
}();
var $author$project$QuotationOld$Model$TextField$maxLengthValidation = F2(
	function (max, field) {
		var _v0 = field.p8;
		if (!_v0.$) {
			var value = _v0.a;
			return (_Utils_cmp(
				$elm$core$String$length(value),
				max) > 0) ? $author$project$QuotationOld$Update$Validation$maxLengthErrorMessage(max) : $elm$core$Maybe$Nothing;
		} else {
			return $author$project$QuotationOld$Update$Validation$maxLengthErrorMessage(max);
		}
	});
var $author$project$QuotationOld$Update$Validation$minLengthErrorMessage = function (min) {
	return $elm$core$Maybe$Just(
		(min === 2) ? 'Non può essere di una sola lettera' : ('Non può essere meno di ' + ($elm$core$String$fromInt(min) + ' lettere')));
};
var $author$project$QuotationOld$Model$TextField$minLengthValidation = F2(
	function (min, field) {
		var _v0 = field.p8;
		if (!_v0.$) {
			var value = _v0.a;
			return (_Utils_cmp(
				$elm$core$String$length(value),
				min) < 0) ? $author$project$QuotationOld$Update$Validation$minLengthErrorMessage(min) : $elm$core$Maybe$Nothing;
		} else {
			return $author$project$QuotationOld$Update$Validation$minLengthErrorMessage(min);
		}
	});
var $author$project$QuotationOld$Model$TextField$requiredValidation = function (field) {
	var _v0 = field.p8;
	if (!_v0.$) {
		var value = _v0.a;
		return $elm$core$String$isEmpty(value) ? $author$project$QuotationOld$Update$Validation$requiredErrorMessage : $elm$core$Maybe$Nothing;
	} else {
		return $author$project$QuotationOld$Update$Validation$requiredErrorMessage;
	}
};
var $author$project$QuotationOld$Model$TextField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Update$Validation$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'));
var $author$project$QuotationOld$Update$Validation$emailValidationErrorMessage = $elm$core$Maybe$Just('Formato email non valido');
var $author$project$QuotationOld$Model$TextField$validEmailValidation = function (_v0) {
	var value = _v0.p8;
	var isValidEmail = A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		false,
		$elm$regex$Regex$contains($author$project$QuotationOld$Update$Validation$emailRegex),
		value);
	return isValidEmail ? $elm$core$Maybe$Nothing : $author$project$QuotationOld$Update$Validation$emailValidationErrorMessage;
};
var $author$project$QuotationOld$Update$Validation$phoneRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(([+]|00)39)?((3[1-9][0-9]))(\\d{6,7})$'));
var $author$project$QuotationOld$Update$Validation$phoneValidationErrorMessage = $elm$core$Maybe$Just('Numero di telefono non valido');
var $author$project$QuotationOld$Model$TextField$validPhoneValidation = function (_v0) {
	var value = _v0.p8;
	var isValidPhone = A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		false,
		$elm$regex$Regex$contains($author$project$QuotationOld$Update$Validation$phoneRegex),
		value);
	return isValidPhone ? $elm$core$Maybe$Nothing : $author$project$QuotationOld$Update$Validation$phoneValidationErrorMessage;
};
var $author$project$QuotationOld$Model$TextField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			switch (validation.$) {
				case 0:
					return $author$project$QuotationOld$Model$TextField$requiredValidation;
				case 1:
					var min = validation.a;
					return $author$project$QuotationOld$Model$TextField$minLengthValidation(min);
				case 2:
					var max = validation.a;
					return $author$project$QuotationOld$Model$TextField$maxLengthValidation(max);
				case 4:
					return $author$project$QuotationOld$Model$TextField$validEmailValidation;
				case 5:
					return $author$project$QuotationOld$Model$TextField$validPhoneValidation;
				default:
					return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$TextField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$TextField$updateValue = F2(
	function (value, field) {
		return $author$project$QuotationOld$Model$TextField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Update$Helper$quoteInfoToFormData = F2(
	function (quoteInfo, model) {
		var maybeUpdateResidenceAddress = function (maybeAddress) {
			if (!maybeAddress.$) {
				var address = maybeAddress.a;
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2(
							$author$project$QuotationOld$Model$FormData$updateTextField,
							4,
							$author$project$QuotationOld$Model$TextField$updateValue(
								$elm$core$Maybe$Just(address.lx)))),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2(
								$author$project$QuotationOld$Model$FormData$updateTextField,
								5,
								$author$project$QuotationOld$Model$TextField$updateValue(
									$elm$core$Maybe$Just(address.kp)))),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2(
									$author$project$QuotationOld$Model$FormData$updateTextField,
									6,
									$author$project$QuotationOld$Model$TextField$updateValue(address.hH))),
							$author$project$QuotationOld$Model$updateFormData(
								A2(
									$author$project$QuotationOld$Model$FormData$updateTextField,
									7,
									$author$project$QuotationOld$Model$TextField$updateValue(address.fl))))));
			} else {
				return $elm$core$Basics$identity;
			}
		};
		var maybeUpdatePropertyAddress = function (maybeAddress) {
			if (!maybeAddress.$) {
				var address = maybeAddress.a;
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2(
							$author$project$QuotationOld$Model$FormData$updateTextField,
							0,
							$author$project$QuotationOld$Model$TextField$updateValue(
								$elm$core$Maybe$Just(address.lx)))),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2(
								$author$project$QuotationOld$Model$FormData$updateTextField,
								1,
								$author$project$QuotationOld$Model$TextField$updateValue(
									$elm$core$Maybe$Just(address.kp)))),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2(
									$author$project$QuotationOld$Model$FormData$updateTextField,
									2,
									$author$project$QuotationOld$Model$TextField$updateValue(address.hH))),
							$author$project$QuotationOld$Model$updateFormData(
								A2(
									$author$project$QuotationOld$Model$FormData$updateTextField,
									3,
									$author$project$QuotationOld$Model$TextField$updateValue(address.fl))))));
			} else {
				return $elm$core$Basics$identity;
			}
		};
		var birthCityRequest = function () {
			var _v2 = quoteInfo.ih;
			if (!_v2.$) {
				return $elm$core$Platform$Cmd$none;
			} else {
				var city = _v2.a;
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						A4(
							$author$project$QuotationOld$Api$City$getCitiesByLandRegisterRequest,
							1,
							$author$project$Types$pickApiUrl(model.nv),
							city,
							true),
						quoteInfo.mm));
			}
		}();
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (_v0) {
							var city = _v0.my;
							var zipCode = _v0.qg;
							return A5(
								$author$project$QuotationOld$Api$City$getCitiesByZipCodeRequest,
								3,
								$author$project$Types$pickApiUrl(model.nv),
								city,
								true,
								zipCode);
						},
						quoteInfo.pq)),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var city = _v1.my;
							var zipCode = _v1.qg;
							return A5(
								$author$project$QuotationOld$Api$City$getCitiesByZipCodeRequest,
								0,
								$author$project$Types$pickApiUrl(model.nv),
								city,
								true,
								zipCode);
						},
						quoteInfo.kY)),
					birthCityRequest
				]),
			A2(
				$author$project$QuotationOld$Model$updateFormData,
				A2(
					$author$project$QuotationOld$Model$FormData$updateSelectField,
					5,
					A2(
						$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
						$author$project$QuotationOld$Model$FormData$familyUnderagesOptions,
						A2($elm$core$Maybe$map, $elm$core$String$fromInt, quoteInfo.nr))),
				A2(
					$author$project$QuotationOld$Model$updateFormData,
					A2(
						$author$project$QuotationOld$Model$FormData$updateSelectField,
						4,
						A2(
							$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
							$author$project$QuotationOld$Model$FormData$familyAdultsOptions,
							A2($elm$core$Maybe$map, $elm$core$String$fromInt, quoteInfo.np))),
					A2(
						$author$project$QuotationOld$Model$updateFormData,
						A2(
							$author$project$QuotationOld$Model$FormData$updateSelectField,
							3,
							A2(
								$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
								$author$project$QuotationOld$Model$FormData$reportedAccidentSelectOptions,
								A2($elm$core$Maybe$map, $elm$core$String$fromInt, quoteInfo.pn))),
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							A2(
								$author$project$QuotationOld$Model$FormData$updateSelectField,
								2,
								A2(
									$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
									$author$project$QuotationOld$Model$FormData$propertyConstructionPeriodOptions,
									A2($elm$core$Maybe$andThen, $author$project$Types$Utils$constructionPeriodToValue, quoteInfo.mP))),
							A2(
								$author$project$QuotationOld$Model$updateFormData,
								A2(
									$author$project$QuotationOld$Model$FormData$updateNumberField,
									0,
									$author$project$QuotationOld$Model$NumberField$updateValue(
										A2($elm$core$Maybe$map, $elm$core$String$fromInt, quoteInfo.p3))),
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									A2(
										$author$project$QuotationOld$Model$FormData$updateSelectField,
										1,
										A2(
											$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
											$author$project$QuotationOld$Model$FormData$flatFloorOptions,
											A2($elm$core$Maybe$andThen, $author$project$Types$Utils$floorTypeToValue, quoteInfo.nw))),
									A2(
										$author$project$QuotationOld$Model$updateFormData,
										A2(
											$author$project$QuotationOld$Model$FormData$updateRadioField,
											3,
											$author$project$QuotationOld$Model$RadioField$updateValue(
												A2($elm$core$Maybe$andThen, $author$project$Types$Utils$houseTypeToValue, quoteInfo.qa))),
										A2(
											$author$project$QuotationOld$Model$updateFormData,
											A2(
												$author$project$QuotationOld$Model$FormData$updateRadioField,
												2,
												$author$project$QuotationOld$Model$RadioField$updateValue(
													A2($elm$core$Maybe$andThen, $author$project$Types$Utils$condominiumTypeToValue, quoteInfo.mN))),
											A2(
												$author$project$QuotationOld$Model$updateFormData,
												A2(
													$author$project$QuotationOld$Model$FormData$updateRadioField,
													5,
													$author$project$QuotationOld$Model$RadioField$updateValue(
														A2($elm$core$Maybe$andThen, $author$project$Types$Utils$propertyTypeToValue, quoteInfo.o4))),
												A2(
													$author$project$QuotationOld$Model$updateFormData,
													A2(
														$author$project$QuotationOld$Model$FormData$updateSelectField,
														0,
														A2(
															$author$project$QuotationOld$Model$SelectField$updateStateAndValue,
															$author$project$QuotationOld$Model$FormData$propertyPurposeOptions,
															$author$project$Types$Utils$propertyUsageTypeToValue(quoteInfo.o1))),
													A2(
														$author$project$QuotationOld$Model$updateFormData,
														A2(
															$author$project$QuotationOld$Model$FormData$updateRadioField,
															0,
															$author$project$QuotationOld$Model$RadioField$updateValue(
																$elm$core$Maybe$Just(
																	$author$project$Types$Utils$ownershipToValue(quoteInfo.oL)))),
														A2(
															$author$project$QuotationOld$Model$updateFormData,
															A2(
																$author$project$QuotationOld$Model$FormData$updateTextField,
																11,
																$author$project$QuotationOld$Model$TextField$updateValue(
																	$elm$core$Maybe$Just(quoteInfo.oT))),
															A2(
																$author$project$QuotationOld$Model$updateFormData,
																A2(
																	$author$project$QuotationOld$Model$FormData$updateTextField,
																	10,
																	$author$project$QuotationOld$Model$TextField$updateValue(
																		$elm$core$Maybe$Just(quoteInfo.fi))),
																A2(
																	$author$project$QuotationOld$Model$updateFormData,
																	A2(
																		$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
																		2,
																		$author$project$QuotationOld$Model$AutocompleteField$updateByChoice(
																			$author$project$Utils$duplicate(quoteInfo.mj))),
																	A2(
																		$author$project$QuotationOld$Model$updateFormData,
																		A2(
																			$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
																			1,
																			$author$project$QuotationOld$Model$AutocompleteField$updateByChoice(
																				$author$project$QuotationOld$Model$ExtraTypes$fromBirthCityToChoice(quoteInfo.ih))),
																		A2(
																			$author$project$QuotationOld$Model$updateFormData,
																			A2(
																				$author$project$QuotationOld$Model$FormData$updateDatePickerField,
																				0,
																				A2(
																					$author$project$QuotationOld$Model$DatePickerField$updateValue,
																					model.dv.iH,
																					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$fromMaybeDate(
																						$elm$core$Maybe$Just(quoteInfo.mn)))),
																			A2(
																				$author$project$QuotationOld$Model$updateFormData,
																				A2(
																					$author$project$QuotationOld$Model$FormData$updateRadioField,
																					6,
																					$author$project$QuotationOld$Model$RadioField$updateValue(
																						$elm$core$Maybe$Just(
																							$author$project$Types$Utils$genderToValue(quoteInfo.ny)))),
																				A2(
																					$author$project$QuotationOld$Model$updateFormData,
																					A2(
																						$author$project$QuotationOld$Model$FormData$updateTextField,
																						9,
																						$author$project$QuotationOld$Model$TextField$updateValue(
																							$elm$core$Maybe$Just(quoteInfo.lC))),
																					A2(
																						$author$project$QuotationOld$Model$updateFormData,
																						A2(
																							$author$project$QuotationOld$Model$FormData$updateTextField,
																							8,
																							$author$project$QuotationOld$Model$TextField$updateValue(
																								$elm$core$Maybe$Just(quoteInfo.oq))),
																						A2(
																							maybeUpdateResidenceAddress,
																							quoteInfo.pq,
																							A2(maybeUpdatePropertyAddress, quoteInfo.kY, model))))))))))))))))))))));
	});
var $author$project$QuotationOld$Update$Helper$fillQuoteData = F2(
	function (queryResponse, model) {
		switch (queryResponse.$) {
			case 3:
				var quoteResponse = queryResponse.a;
				if (!quoteResponse.$) {
					var quote = quoteResponse.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2($author$project$QuotationOld$Update$Helper$quoteInfoToFormData, quote.nT, model));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
				}
			case 2:
				var err = queryResponse.a;
				return A2(
					$author$project$Utils$Auth$redirectNotAuthenticated,
					err,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$QuotationOld$Update$Helper$fillSaveData = F2(
	function (queryResponse, model) {
		switch (queryResponse.$) {
			case 3:
				var saveResponse = queryResponse.a;
				if (!saveResponse.$) {
					var save = saveResponse.a;
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2($author$project$QuotationOld$Update$Helper$quoteInfoToFormData, save.pd.nT, model));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
				}
			case 2:
				var err = queryResponse.a;
				return A2(
					$author$project$Utils$Auth$redirectNotAuthenticated,
					err,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
		}
	});
var $author$project$QuotationOld$Model$FormStep$OwnershipStep = function (a) {
	return {$: 1, a: a};
};
var $author$project$QuotationOld$Model$FormStep$PropertyInfoStep = function (a) {
	return {$: 2, a: a};
};
var $author$project$QuotationOld$Model$FormStep$UserDataStep = function (a) {
	return {$: 3, a: a};
};
var $author$project$QuotationOld$Model$FormStep$Valid = 0;
var $author$project$QuotationOld$Model$FormStep$intToFormStep = function (step) {
	_v0$3:
	while (true) {
		if (!step.$) {
			switch (step.a) {
				case 2:
					return $author$project$QuotationOld$Model$FormStep$OwnershipStep(0);
				case 3:
					return $author$project$QuotationOld$Model$FormStep$PropertyInfoStep(0);
				case 4:
					return $author$project$QuotationOld$Model$FormStep$UserDataStep(0);
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return $author$project$QuotationOld$Model$FormStep$PropertyAddressStep(0);
};
var $author$project$QuotationOld$Model$FormStep$validationStatusToBool = function (validation) {
	if (!validation) {
		return true;
	} else {
		return false;
	}
};
var $author$project$QuotationOld$Model$FormStep$isFormStepValid = function (formStep) {
	return $author$project$QuotationOld$Model$FormStep$validationStatusToBool(
		function () {
			switch (formStep.$) {
				case 0:
					var validationStatus = formStep.a;
					return validationStatus;
				case 1:
					var validationStatus = formStep.a;
					return validationStatus;
				case 2:
					var validationStatus = formStep.a;
					return validationStatus;
				case 3:
					var validationStatus = formStep.a;
					return validationStatus;
				default:
					return 0;
			}
		}());
};
var $author$project$QuotationOld$Update$Helper$updateCurrentSeed = F2(
	function (seed, model) {
		return _Utils_update(
			model,
			{iI: seed});
	});
var $author$project$QuotationOld$Model$updateFormStep = F2(
	function (formStep, model) {
		return _Utils_update(
			model,
			{fp: formStep});
	});
var $author$project$QuotationOld$Model$GotQuote = function (a) {
	return {$: 16, a: a};
};
var $author$project$Api$PostQuote = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$QuotationSource = function (a) {
	return {$: 0, a: a};
};
var $author$project$QuotationOld$Model$FormData$getCityByCityField = function (cityDict) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.p8;
		},
		$elm$core$Maybe$andThen(
			A2($elm_community$basics_extra$Basics$Extra$flip, $elm$core$Dict$get, cityDict)));
};
var $author$project$QuotationOld$Api$Quotation$getFieldStringValue = function (error) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.p8;
		},
		$elm$core$Result$fromMaybe(error));
};
var $author$project$QuotationOld$Api$Quotation$resultToOptional = function (result) {
	var optionalResult = function () {
		if (!result.$) {
			var value = result.a;
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
		} else {
			return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
		}
	}();
	return $elm$core$Result$Ok(optionalResult);
};
var $author$project$QuotationOld$Api$Quotation$formDataToAddress = F2(
	function (_v0, _v1) {
		var cityDict = _v0.iu;
		var city = _v1.my;
		var street = _v1.lx;
		var number = _v1.kp;
		var zipCode = _v1.qg;
		var stair = _v1.pJ;
		var internal = _v1.nX;
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$author$project$QuotationOld$Api$Quotation$resultToOptional(
				A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldInternal', internal)),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$author$project$QuotationOld$Api$Quotation$resultToOptional(
					A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldStair', stair)),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldZipCode', zipCode),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						$author$project$QuotationOld$Api$Quotation$resultToOptional(
							A2(
								$elm$core$Result$fromMaybe,
								'No FieldCityLandRegister',
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.S;
									},
									A2($author$project$QuotationOld$Model$FormData$getCityByCityField, cityDict, city)))),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2(
								$elm$core$Result$fromMaybe,
								'No FieldCity',
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.oq;
									},
									A2($author$project$QuotationOld$Model$FormData$getCityByCityField, cityDict, city))),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldNumber', number),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldStreet', street),
									$elm$core$Result$Ok($author$project$Gql$Vianello$InputObject$InputAddress))))))));
	});
var $author$project$QuotationOld$Api$Quotation$formDataToPrivacy = function (_v0) {
	var readPrivacyPolicy = _v0.pf;
	var commercialPurposes = _v0.mL;
	var marketResearch = _v0.od;
	var thirdParties = _v0.pV;
	return A2(
		$elm_community$result_extra$Result$Extra$andMap,
		$elm$core$Result$Ok(thirdParties.p8),
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$elm$core$Result$Ok(marketResearch.p8),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$elm$core$Result$Ok(commercialPurposes.p8),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$elm$core$Result$Ok(readPrivacyPolicy.p8),
					$elm$core$Result$Ok($author$project$Gql$Vianello$InputObject$Agreements)))));
};
var $author$project$Gql$Vianello$Enum$CondominiumType$fromString = function (enumString____) {
	switch (enumString____) {
		case 'UP_TO_EIGHT_APARTMENT':
			return $elm$core$Maybe$Just(0);
		case 'MORE_THAN_EIGHT_APARTMENT':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$ConstructionPeriod$fromString = function (enumString____) {
	switch (enumString____) {
		case 'UNKNOWN':
			return $elm$core$Maybe$Just(0);
		case 'BEFORE1970':
			return $elm$core$Maybe$Just(1);
		case 'BEFORE1940':
			return $elm$core$Maybe$Just(2);
		case 'DECADE1940':
			return $elm$core$Maybe$Just(3);
		case 'DECADE1950':
			return $elm$core$Maybe$Just(4);
		case 'DECADE1960':
			return $elm$core$Maybe$Just(5);
		case 'DECADE1970':
			return $elm$core$Maybe$Just(6);
		case 'DECADE1980':
			return $elm$core$Maybe$Just(7);
		case 'DECADE1990':
			return $elm$core$Maybe$Just(8);
		case 'DECADE2000':
			return $elm$core$Maybe$Just(9);
		case 'DECADE2010':
			return $elm$core$Maybe$Just(10);
		case 'DECADE2020':
			return $elm$core$Maybe$Just(11);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$FloorType$fromString = function (enumString____) {
	switch (enumString____) {
		case 'GROUND':
			return $elm$core$Maybe$Just(0);
		case 'INTERMEDIATE':
			return $elm$core$Maybe$Just(1);
		case 'TOP':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$HouseType$fromString = function (enumString____) {
	switch (enumString____) {
		case 'SINGLE_FAMILY':
			return $elm$core$Maybe$Just(0);
		case 'TERRACED_HOUSE':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$PropertyType$fromString = function (enumString____) {
	switch (enumString____) {
		case 'APARTMENT':
			return $elm$core$Maybe$Just(0);
		case 'HOUSE':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$QuotationOld$Api$Quotation$getFieldIntValue = function (error) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.p8;
		},
		$elm$core$Result$fromMaybe(error));
};
var $author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion = F2(
	function (error, convertToValue) {
		return A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.p8;
			},
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$andThen(convertToValue),
				$elm$core$Result$fromMaybe(error)));
	});
var $author$project$QuotationOld$Api$Quotation$formDataToPropertyInfo = function (formData) {
	var _v0 = formData;
	var propertyType = _v0.o4;
	var condominiumType = _v0.mN;
	var villaType = _v0.qa;
	var flatFloor = _v0.nw;
	var propertySize = _v0.o2;
	var constructionPeriod = _v0.mP;
	var reportedAccidents = _v0.pn;
	var familyAdults = _v0.np;
	var familyUnderages = _v0.nr;
	return A2(
		$elm_community$result_extra$Result$Extra$andMap,
		A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldFamilyUnderages', $elm$core$String$toInt, familyUnderages),
		A2(
			$elm_community$result_extra$Result$Extra$andMap,
			A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldFamilyAdults', $elm$core$String$toInt, familyAdults),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldReportedAccident', $elm$core$String$toInt, reportedAccidents),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldPropertyConstructionPeriod', $author$project$Gql$Vianello$Enum$ConstructionPeriod$fromString, constructionPeriod),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						A2($author$project$QuotationOld$Api$Quotation$getFieldIntValue, 'No FieldPropertySize', propertySize),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							$author$project$QuotationOld$Api$Quotation$resultToOptional(
								A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldFlatFloor', $author$project$Gql$Vianello$Enum$FloorType$fromString, flatFloor)),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								$author$project$QuotationOld$Api$Quotation$resultToOptional(
									A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldVillaType', $author$project$Gql$Vianello$Enum$HouseType$fromString, villaType)),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									$author$project$QuotationOld$Api$Quotation$resultToOptional(
										A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldCondominiumType', $author$project$Gql$Vianello$Enum$CondominiumType$fromString, condominiumType)),
									A2(
										$elm_community$result_extra$Result$Extra$andMap,
										A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldPropertyType', $author$project$Gql$Vianello$Enum$PropertyType$fromString, propertyType),
										$elm$core$Result$Ok($author$project$Gql$Vianello$InputObject$PropertyInfo))))))))));
};
var $author$project$Gql$Vianello$Enum$Gender$fromString = function (enumString____) {
	switch (enumString____) {
		case 'M':
			return $elm$core$Maybe$Just(0);
		case 'F':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$Ownership$fromString = function (enumString____) {
	switch (enumString____) {
		case 'OWNER':
			return $elm$core$Maybe$Just(0);
		case 'TENANT':
			return $elm$core$Maybe$Just(1);
		case 'NO_PROPERTY':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Vianello$Enum$PropertyUsage$fromString = function (enumString____) {
	switch (enumString____) {
		case 'RESIDENTIAL':
			return $elm$core$Maybe$Just(0);
		case 'SECOND_HOUSE':
			return $elm$core$Maybe$Just(1);
		case 'RENTED':
			return $elm$core$Maybe$Just(2);
		case 'UNINHABITED':
			return $elm$core$Maybe$Just(3);
		case 'NO_PROPERTY':
			return $elm$core$Maybe$Just(4);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$QuotationOld$Api$Quotation$getFieldDateValue = function (error) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.p8;
		},
		A2(
			$elm$core$Basics$composeR,
			$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$toMaybeDate,
			$elm$core$Result$fromMaybe(error)));
};
var $elm_community$result_extra$Result$Extra$or = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			return rb;
		} else {
			return ra;
		}
	});
var $elm_community$result_extra$Result$Extra$orElse = F2(
	function (ra, rb) {
		return A2($elm_community$result_extra$Result$Extra$or, rb, ra);
	});
var $author$project$QuotationOld$Api$Quotation$formDataToQuotationForm = F4(
	function (uuid, originalQuoteUuid, originalSaveUuid, formData) {
		var cityDict = formData.iu;
		var _v0 = formData;
		var propertyAddress = _v0.kY;
		var ownerOrTenant = _v0.oL;
		var propertyPurpose = _v0.o1;
		var name = _v0.oq;
		var surname = _v0.lC;
		var gender = _v0.ny;
		var birthday = _v0.mn;
		var birthCity = _v0.ih;
		var birthCountry = _v0.mj;
		var email = _v0.fi;
		var phone = _v0.oT;
		var residenceAddress = _v0.pq;
		var privacyData = _v0.oZ;
		return A2(
			$elm_community$result_extra$Result$Extra$andMap,
			$elm$core$Result$Ok(
				$author$project$Api$Helper$maybeToOptional(originalSaveUuid)),
			A2(
				$elm_community$result_extra$Result$Extra$andMap,
				$elm$core$Result$Ok(
					$author$project$Api$Helper$maybeToOptional(originalQuoteUuid)),
				A2(
					$elm_community$result_extra$Result$Extra$andMap,
					$author$project$QuotationOld$Api$Quotation$formDataToPrivacy(privacyData),
					A2(
						$elm_community$result_extra$Result$Extra$andMap,
						$author$project$QuotationOld$Api$Quotation$resultToOptional(
							A2($author$project$QuotationOld$Api$Quotation$formDataToAddress, formData, residenceAddress)),
						A2(
							$elm_community$result_extra$Result$Extra$andMap,
							A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldPhoneNumber', phone),
							A2(
								$elm_community$result_extra$Result$Extra$andMap,
								A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldEmailAddress', email),
								A2(
									$elm_community$result_extra$Result$Extra$andMap,
									A2(
										$elm_community$result_extra$Result$Extra$orElse,
										$elm$core$Result$Ok('Italia'),
										A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldBirthCountry', birthCountry)),
									A2(
										$elm_community$result_extra$Result$Extra$andMap,
										$elm$core$Result$Ok(
											$author$project$Api$Helper$maybeToOptional(
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.S;
													},
													A2($author$project$QuotationOld$Model$FormData$getCityByCityField, cityDict, birthCity)))),
										A2(
											$elm_community$result_extra$Result$Extra$andMap,
											A2(
												$elm$core$Result$fromMaybe,
												'No birthCity',
												A2(
													$elm_community$maybe_extra$Maybe$Extra$orElse,
													$elm$core$Maybe$Just('Stato estero'),
													A2(
														$elm$core$Maybe$map,
														function ($) {
															return $.oq;
														},
														A2($author$project$QuotationOld$Model$FormData$getCityByCityField, cityDict, birthCity)))),
											A2(
												$elm_community$result_extra$Result$Extra$andMap,
												A2($author$project$QuotationOld$Api$Quotation$getFieldDateValue, 'No FieldBirthday', birthday),
												A2(
													$elm_community$result_extra$Result$Extra$andMap,
													A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldGender', $author$project$Gql$Vianello$Enum$Gender$fromString, gender),
													A2(
														$elm_community$result_extra$Result$Extra$andMap,
														A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldSurname', surname),
														A2(
															$elm_community$result_extra$Result$Extra$andMap,
															A2($author$project$QuotationOld$Api$Quotation$getFieldStringValue, 'No FieldName', name),
															A2(
																$elm_community$result_extra$Result$Extra$andMap,
																$author$project$QuotationOld$Api$Quotation$resultToOptional(
																	$author$project$QuotationOld$Api$Quotation$formDataToPropertyInfo(formData)),
																A2(
																	$elm_community$result_extra$Result$Extra$andMap,
																	$elm$core$Result$Ok($dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent),
																	A2(
																		$elm_community$result_extra$Result$Extra$andMap,
																		A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldPropertyPurpose', $author$project$Gql$Vianello$Enum$PropertyUsage$fromString, propertyPurpose),
																		A2(
																			$elm_community$result_extra$Result$Extra$andMap,
																			$elm$core$Result$Ok($dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent),
																			A2(
																				$elm_community$result_extra$Result$Extra$andMap,
																				$elm$core$Result$Ok(1),
																				A2(
																					$elm_community$result_extra$Result$Extra$andMap,
																					A3($author$project$QuotationOld$Api$Quotation$getFieldStringValueWithConversion, 'No FieldOwnerOrTenant', $author$project$Gql$Vianello$Enum$Ownership$fromString, ownerOrTenant),
																					A2(
																						$elm_community$result_extra$Result$Extra$andMap,
																						A2($author$project$QuotationOld$Api$Quotation$formDataToAddress, formData, propertyAddress),
																						$elm$core$Result$Ok(
																							$author$project$Gql$Vianello$InputObject$QuotationForm(uuid))))))))))))))))))))));
	});
var $author$project$Api$QuotationMutationResult = function (a) {
	return {$: 3, a: a};
};
var $author$project$QuotationOld$Model$FormErrorResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$QuotationOld$Model$QuotationErrors = F3(
	function (id, errorList, reason) {
		return {nc: errorList, nM: id, k6: reason};
	});
var $author$project$QuotationOld$Model$InvalidData = 0;
var $author$project$QuotationOld$Model$QuoteNotAvailable = 1;
var $author$project$QuotationOld$Api$Quotation$quotationErrorReasonMap = function (reason) {
	if (!reason) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$QuotationOld$Model$QuotationError = F2(
	function (field, message) {
		return {ns: field, of: message};
	});
var $author$project$QuotationOld$Api$Quotation$quotationFormErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuotationFormError$message,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuotationFormError$field,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$QuotationError)));
var $author$project$QuotationOld$Api$Quotation$quotationFormErrorsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$QuotationOld$Api$Quotation$quotationErrorReasonMap, $author$project$Gql$Vianello$Object$QuotationFormErrors$reason),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$QuotationFormErrors$errors($author$project$QuotationOld$Api$Quotation$quotationFormErrorSelection),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Vianello$Object$QuotationFormErrors$id,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$QuotationErrors))));
var $author$project$QuotationOld$Api$Quotation$quotationFormResponseErrorSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$QuotationOld$Api$Quotation$quotationFormErrorsSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$FormErrorResponse));
var $author$project$QuotationOld$Model$FormSuccessResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$QuotationOld$Api$Quotation$quotationFormResponseOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$QuotationFormOk$id,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$QuotationOld$Model$FormSuccessResponse));
var $author$project$QuotationOld$Api$Quotation$quotationFormSelection = $author$project$Gql$Vianello$Union$QuotationFormResult$fragments(
	A2($author$project$Gql$Vianello$Union$QuotationFormResult$Fragments, $author$project$QuotationOld$Api$Quotation$quotationFormResponseOkSelection, $author$project$QuotationOld$Api$Quotation$quotationFormResponseErrorSelection));
var $author$project$QuotationOld$Api$Quotation$postQuotationMutation = function (quotationFormData) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$QuotationMutationResult,
		A2(
			$author$project$Gql$Vianello$Mutation$registerHousePolicyData,
			{dv: quotationFormData},
			$author$project$QuotationOld$Api$Quotation$quotationFormSelection));
};
var $author$project$QuotationOld$Api$Quotation$quotationMutation = F4(
	function (formData, newQuoteUuid, originalQuoteUuid, originalSaveUuid) {
		return A2(
			$elm$core$Result$map,
			function (quotationForm) {
				return A2(
					$author$project$Api$buildMutation,
					$author$project$Api$QuotationSource(
						$author$project$Api$PostQuote($author$project$QuotationOld$Model$GotQuote)),
					$author$project$QuotationOld$Api$Quotation$postQuotationMutation(quotationForm));
			},
			A4($author$project$QuotationOld$Api$Quotation$formDataToQuotationForm, newQuoteUuid, originalQuoteUuid, originalSaveUuid, formData));
	});
var $author$project$QuotationOld$Api$quotationMutation = $author$project$QuotationOld$Api$Quotation$quotationMutation;
var $author$project$QuotationOld$Update$Helper$withFormMutation = F5(
	function (formaData, quoteId, originalQuoteId, originalSaveId, updateReturn) {
		return A2(
			$elm$core$Result$withDefault,
			updateReturn,
			A2(
				$elm$core$Result$map,
				A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Utils$UpdateReturn$withQuery, updateReturn),
				A4($author$project$QuotationOld$Api$quotationMutation, formaData, quoteId, originalQuoteId, originalSaveId)));
	});
var $author$project$QuotationOld$Update$Helper$formNavigation = F2(
	function (nextStep, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var formStep = model.fp;
		var cmd = _v0.b;
		return function () {
			if ($author$project$QuotationOld$Model$FormStep$isFormStepValid(formStep)) {
				if (nextStep.$ === 1) {
					var _v2 = A2($elm$random$Random$step, $danyx23$elm_uuid$Uuid$uuidGenerator, model.iI);
					var newUuid = _v2.a;
					var newSeed = _v2.b;
					return A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Update$Helper$updateCurrentSeed(newSeed),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Utils$withCommands(
								_List_fromArray(
									[cmd])),
							A2(
								$elm$core$Basics$composeR,
								$author$project$Utils$UpdateReturn$addDefaultUpdateReturn,
								A4($author$project$QuotationOld$Update$Helper$withFormMutation, formData, newUuid, model.U, model.w))));
				} else {
					return A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormStep(
							$author$project$QuotationOld$Model$FormStep$intToFormStep(nextStep)),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Utils$withCommands(
								_List_fromArray(
									[cmd])),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn));
				}
			} else {
				return A2($elm$core$Basics$composeR, $author$project$Utils$withoutCommands, $author$project$Utils$UpdateReturn$addDefaultUpdateReturn);
			}
		}()(model);
	});
var $author$project$QuotationOld$Model$ExtraTypes$fromCityToChoice = function (_v0) {
	var name = _v0.oq;
	var province = _v0.o6;
	return _Utils_Tuple2(
		name,
		A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			name,
			function (_v1) {
				var code = _v1.mH;
				return name + (' (' + (code + ')'));
			},
			province));
};
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.l3 - 1)) + ($justinmimbs$date$Date$monthToNumber(date.ke) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.iK,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$QuotationOld$Model$FormData$initBirthdayDatePicker = F2(
	function (today, birthday) {
		var initialDate = function () {
			if (!birthday.$) {
				var date = birthday.a;
				return date;
			} else {
				return A3($justinmimbs$date$Date$add, 0, -18, today);
			}
		}();
		return $elm$core$Maybe$Just(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$init,
				initialDate,
				_Utils_Tuple2(
					A3($justinmimbs$date$Date$add, 0, -100, today),
					A3($justinmimbs$date$Date$add, 0, -18, today))));
	});
var $author$project$QuotationOld$Model$DatePickerField$updateState = F2(
	function (state, field) {
		return _Utils_update(
			field,
			{cG: state});
	});
var $author$project$QuotationOld$Model$FormData$initBirthdayWithDate = F2(
	function (today, formData) {
		return A3(
			$author$project$QuotationOld$Model$FormData$updateDatePickerField,
			0,
			$author$project$QuotationOld$Model$DatePickerField$updateState(
				A2($author$project$QuotationOld$Model$FormData$initBirthdayDatePicker, today, formData.mn.p8)),
			formData);
	});
var $author$project$Utils$listAppendWithoutDuplication = F2(
	function (list, item) {
		return A2($elm$core$List$member, item, list) ? list : A2($elm$core$List$cons, item, list);
	});
var $author$project$Utils$listWithDefault = F2(
	function (defaultValue, list) {
		return $elm$core$List$isEmpty(list) ? $elm$core$List$singleton(defaultValue) : list;
	});
var $author$project$QuotationOld$Model$FormData$updateCommercialPurposes = F2(
	function (updater, privacy) {
		return _Utils_update(
			privacy,
			{
				mL: updater(privacy.mL)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateDataProcessingConsent = F2(
	function (updater, privacy) {
		return _Utils_update(
			privacy,
			{
				mZ: updater(privacy.mZ)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateMarketResearch = F2(
	function (updater, privacy) {
		return _Utils_update(
			privacy,
			{
				od: updater(privacy.od)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateReadPrivacyPolicy = F2(
	function (updater, privacy) {
		return _Utils_update(
			privacy,
			{
				pf: updater(privacy.pf)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateThirdParties = F2(
	function (updater, privacy) {
		return _Utils_update(
			privacy,
			{
				pV: updater(privacy.pV)
			});
	});
var $author$project$QuotationOld$Model$FormData$updateCheckboxField = F3(
	function (fieldName, updater, formData) {
		switch (fieldName) {
			case 0:
				return _Utils_update(
					formData,
					{
						oZ: A2($author$project$QuotationOld$Model$FormData$updateReadPrivacyPolicy, updater, formData.oZ)
					});
			case 1:
				return _Utils_update(
					formData,
					{
						oZ: A2($author$project$QuotationOld$Model$FormData$updateDataProcessingConsent, updater, formData.oZ)
					});
			case 2:
				return _Utils_update(
					formData,
					{
						oZ: A2($author$project$QuotationOld$Model$FormData$updateCommercialPurposes, updater, formData.oZ)
					});
			case 3:
				return _Utils_update(
					formData,
					{
						oZ: A2($author$project$QuotationOld$Model$FormData$updateMarketResearch, updater, formData.oZ)
					});
			default:
				return _Utils_update(
					formData,
					{
						oZ: A2($author$project$QuotationOld$Model$FormData$updateThirdParties, updater, formData.oZ)
					});
		}
	});
var $author$project$QuotationOld$Model$CheckboxField$requiredPrivacyFieldErrorMessage = $elm$core$Maybe$Just('Conferma di aver letto l\'Informativa Privacy per continuare');
var $author$project$QuotationOld$Model$CheckboxField$requiredValidation = function (field) {
	return field.p8 ? $elm$core$Maybe$Nothing : $author$project$QuotationOld$Model$CheckboxField$requiredPrivacyFieldErrorMessage;
};
var $author$project$QuotationOld$Model$CheckboxField$updateErrorMessage = F2(
	function (field, errorMessage) {
		return _Utils_update(
			field,
			{i4: errorMessage});
	});
var $author$project$QuotationOld$Model$CheckboxField$validate = function (field) {
	var execValidation = function (validation) {
		return function () {
			if (!validation.$) {
				return $author$project$QuotationOld$Model$CheckboxField$requiredValidation;
			} else {
				return $elm$core$Basics$always($elm$core$Maybe$Nothing);
			}
		}()(field);
	};
	return A2(
		$author$project$QuotationOld$Model$CheckboxField$updateErrorMessage,
		field,
		$elm_community$maybe_extra$Maybe$Extra$orList(
			A2($elm$core$List$map, execValidation, field.lS)));
};
var $author$project$QuotationOld$Model$CheckboxField$updateValue = F2(
	function (value, field) {
		return $author$project$QuotationOld$Model$CheckboxField$validate(
			_Utils_update(
				field,
				{p8: value}));
	});
var $author$project$QuotationOld$Update$Helper$maybeAcceptAllConsent = F2(
	function (fieldName, value) {
		var _v0 = _Utils_Tuple2(fieldName, value);
		if ((_v0.a === 1) && _v0.b) {
			var _v1 = _v0.a;
			return A2(
				$elm$core$Basics$composeR,
				$author$project$QuotationOld$Model$updateFormData(
					A2(
						$author$project$QuotationOld$Model$FormData$updateCheckboxField,
						2,
						$author$project$QuotationOld$Model$CheckboxField$updateValue(value))),
				A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2(
							$author$project$QuotationOld$Model$FormData$updateCheckboxField,
							3,
							$author$project$QuotationOld$Model$CheckboxField$updateValue(value))),
					$author$project$QuotationOld$Model$updateFormData(
						A2(
							$author$project$QuotationOld$Model$FormData$updateCheckboxField,
							4,
							$author$project$QuotationOld$Model$CheckboxField$updateValue(value)))));
		} else {
			return $elm$core$Basics$identity;
		}
	});
var $author$project$QuotationOld$Update$Helper$maybeNotAvailable = function (_v0) {
	var formData = _v0.dv;
	return _Utils_eq(
		formData.oL.p8,
		$elm$core$Maybe$Just('TENANT')) ? $elm$core$Maybe$Just($author$project$NotAvailable$Model$NotOwner) : (_Utils_eq(
		formData.o1.p8,
		$elm$core$Maybe$Just('UNINHABITED')) ? $elm$core$Maybe$Just($author$project$NotAvailable$Model$Uninhabited) : $elm$core$Maybe$Nothing);
};
var $author$project$QuotationOld$Update$Helper$maybeRedirectToStepOne = F2(
	function (step, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		return (((!$elm$core$List$length(formData.hM)) && (step > 1)) ? $elm$core$Basics$identity : $elm$core$Basics$identity)(
			$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
				A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[cmd]),
					model)));
	});
var $author$project$QuotationOld$Model$AutocompleteField$autocompleteReset = function (field) {
	return _Utils_update(
		field,
		{cG: $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$init});
};
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$QuotationOld$Update$Helper$getIsFieldValid = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.i4;
	},
	$elm_community$maybe_extra$Maybe$Extra$isNothing);
var $author$project$Utils$GTM$getEventPrefixOld = function (_v0) {
	var propertyType = _v0.o4;
	var propertyPurpose = _v0.o1;
	var ownerOrTenant = _v0.oL;
	var typePrefix = $elm$core$String$fromInt(
		function () {
			var _v3 = propertyType.p8;
			_v3$2:
			while (true) {
				if (!_v3.$) {
					switch (_v3.a) {
						case 'APARTMENT':
							return 1;
						case 'HOUSE':
							return 2;
						default:
							break _v3$2;
					}
				} else {
					break _v3$2;
				}
			}
			return 0;
		}());
	var purposePrefix = $elm$core$String$fromInt(
		function () {
			var _v2 = propertyPurpose.p8;
			_v2$4:
			while (true) {
				if (!_v2.$) {
					switch (_v2.a) {
						case 'RESIDENTIAL':
							return 1;
						case 'SECOND_HOUSE':
							return 2;
						case 'RENTED':
							return 3;
						case 'UNINHABITED':
							return 4;
						default:
							break _v2$4;
					}
				} else {
					break _v2$4;
				}
			}
			return 0;
		}());
	var ownerPrefix = $elm$core$String$fromInt(
		function () {
			var _v1 = ownerOrTenant.p8;
			_v1$2:
			while (true) {
				if (!_v1.$) {
					switch (_v1.a) {
						case 'OWNER':
							return 1;
						case 'TENANT':
							return 2;
						default:
							break _v1$2;
					}
				} else {
					break _v1$2;
				}
			}
			return 0;
		}());
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			['p' + ownerPrefix, 'u' + purposePrefix, 'i' + typePrefix]));
};
var $author$project$Utils$GTM$sendGTMFormEventOld = F2(
	function (formData, fieldName) {
		return $author$project$Utils$GTM$simplePushToDataLayer(
			function (filledField) {
				return {
					mY: _List_fromArray(
						[
							_Utils_Tuple2('event', 'fieldFilledOut'),
							_Utils_Tuple2('filledField', filledField)
						]),
					nM: ''
				};
			}(
				function (prefix) {
					return prefix + ('|' + fieldName);
				}(
					$author$project$Utils$GTM$getEventPrefixOld(formData))));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEvent = F3(
	function (label, isValid, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var gtmEvent = isValid ? A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$elm$core$Platform$Cmd$none,
			$author$project$Utils$GTM$sendGTMFormEventOld(formData),
			label) : $elm$core$Platform$Cmd$none;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[gtmEvent, cmd]),
			model);
	});
var $author$project$QuotationOld$Update$Helper$maybeUpdateBirthCountry = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var maybeClearBirthCountry = function () {
			if (fieldName === 1) {
				return $author$project$QuotationOld$Model$updateFormData(
					A2($author$project$QuotationOld$Model$FormData$updateAutocompleteField, 2, $author$project$QuotationOld$Model$AutocompleteField$autocompleteReset));
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		var birthCity = formData.ih;
		var maybeSendEvent = $elm_community$maybe_extra$Maybe$Extra$isJust(birthCity.p8) ? A2(
			$author$project$QuotationOld$Update$Helper$sendGTMEvent,
			$elm$core$Maybe$Just('Stato di nascita'),
			$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.mj)) : $elm$core$Basics$identity;
		var updateBirthCityValue = $author$project$QuotationOld$Model$updateFormData(
			A2(
				$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
				2,
				$elm_community$maybe_extra$Maybe$Extra$isJust(birthCity.p8) ? $author$project$QuotationOld$Model$AutocompleteField$updateByChoice(
					_Utils_Tuple2('Italia', 'Italia')) : $author$project$QuotationOld$Model$AutocompleteField$autocompleteReset));
		return (!_Utils_eq(
			birthCity.p8,
			$elm$core$Maybe$Just('Stato Estero'))) ? maybeSendEvent(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				updateBirthCityValue(model))) : A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[cmd]),
			maybeClearBirthCountry(model));
	});
var $author$project$QuotationOld$Model$ExtraTypes$fromZipCodeToChoice = function (_v0) {
	var zip = _v0.h1;
	return $author$project$Utils$duplicate(zip);
};
var $author$project$QuotationOld$Model$FormData$updatePropertyZipCodes = F2(
	function (zipCodes, formData) {
		return _Utils_update(
			formData,
			{hf: zipCodes});
	});
var $author$project$QuotationOld$Model$FormData$updateResidenceZipCodes = F2(
	function (zipCodes, formData) {
		return _Utils_update(
			formData,
			{hn: zipCodes});
	});
var $author$project$QuotationOld$Model$SelectField$updateStateByChoices = F2(
	function (choices, field) {
		var defaultValue = function () {
			if (choices.b && (!choices.b.b)) {
				var _v1 = choices.a;
				var value = _v1.a;
				return $elm$core$Maybe$Just(value);
			} else {
				return field.p8;
			}
		}();
		var initState = A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $primait$pyxis_components$Prima$Pyxis$Form$Select$init, $primait$pyxis_components$Prima$Pyxis$Form$Select$initWithDefault, defaultValue);
		return A2(
			$author$project$QuotationOld$Model$SelectField$updateValue,
			defaultValue,
			A3(
				$elm_community$basics_extra$Basics$Extra$flip,
				$author$project$QuotationOld$Model$SelectField$updateState,
				field,
				initState(
					A2(
						$elm$core$List$map,
						$author$project$Utils$applyTupleAsParameters($primait$pyxis_components$Prima$Pyxis$Form$Select$selectChoice),
						choices))));
	});
var $author$project$QuotationOld$Update$Helper$maybeUpdateZipCodeChoices = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var zipCodes = A2(
			$elm$core$Basics$composeR,
			$author$project$QuotationOld$Model$FormData$getCityByCityField(formData.iu),
			$elm$core$Maybe$map(
				function ($) {
					return $.qh;
				}));
		var updateZipCodes = F2(
			function (field, choices) {
				return $author$project$QuotationOld$Model$updateFormData(
					A2(
						$author$project$QuotationOld$Model$FormData$updateSelectField,
						field.oq,
						$author$project$QuotationOld$Model$SelectField$updateStateByChoices(choices)));
			});
		var newZipCodeChoices = A2(
			$elm$core$Basics$composeR,
			zipCodes,
			$elm$core$Maybe$map(
				$elm$core$List$map($author$project$QuotationOld$Model$ExtraTypes$fromZipCodeToChoice)));
		var maybeUpdateZipCodes = F2(
			function (autocompleteField, selectField) {
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Basics$identity,
					A2(
						$elm$core$Maybe$map,
						updateZipCodes(selectField),
						newZipCodeChoices(autocompleteField)));
			});
		switch (fieldName) {
			case 0:
				var _v2 = formData.kY;
				var city = _v2.my;
				var zipCode = _v2.qg;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[cmd]),
					A3(
						maybeUpdateZipCodes,
						city,
						zipCode,
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							$author$project$QuotationOld$Model$FormData$updatePropertyZipCodes(
								zipCodes(city)),
							model)));
			case 3:
				var _v3 = formData.pq;
				var city = _v3.my;
				var zipCode = _v3.qg;
				return A2(
					$author$project$Utils$withCommands,
					_List_fromArray(
						[cmd]),
					A3(
						maybeUpdateZipCodes,
						city,
						zipCode,
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							$author$project$QuotationOld$Model$FormData$updateResidenceZipCodes(
								zipCodes(city)),
							model)));
			default:
				return _Utils_Tuple2(model, cmd);
		}
	});
var $author$project$QuotationOld$Model$GotCities = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$QuotationOld$Api$City$citiesRequest = F4(
	function (field, active, vianelloUrl, filter) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Api$makeRequest,
					vianelloUrl,
					$author$project$QuotationOld$Model$GotCities(field)),
				A2(
					$elm$core$Maybe$map,
					$author$project$QuotationOld$Api$City$cityQuery(active),
					filter)));
	});
var $author$project$QuotationOld$Api$cityRequest = $author$project$QuotationOld$Api$City$citiesRequest;
var $author$project$QuotationOld$Model$GotCountries = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$QuotationOld$Api$Country$countryRequest = F3(
	function (field, vianelloUrl, filter) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Api$makeRequest,
					vianelloUrl,
					$author$project$QuotationOld$Model$GotCountries(field)),
				A2($elm$core$Maybe$map, $author$project$QuotationOld$Api$Country$countryQuery, filter)));
	});
var $author$project$QuotationOld$Api$countryRequest = $author$project$QuotationOld$Api$Country$countryRequest;
var $author$project$QuotationOld$Api$requestByField = function (field) {
	switch (field) {
		case 0:
			return A2($author$project$QuotationOld$Api$cityRequest, field, true);
		case 1:
			return A2($author$project$QuotationOld$Api$cityRequest, field, false);
		case 2:
			return $author$project$QuotationOld$Api$countryRequest(field);
		default:
			return A2($author$project$QuotationOld$Api$cityRequest, field, true);
	}
};
var $author$project$QuotationOld$Update$Helper$maybeWithRequest = F3(
	function (filter, field, _v0) {
		var model = _v0.a;
		var flags = model.nv;
		var cmd = _v0.b;
		if (!filter.$) {
			var value = filter.a;
			return A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						cmd,
						A3(
						$author$project$QuotationOld$Api$requestByField,
						field,
						$author$project$Types$pickApiUrl(flags),
						$elm$core$Maybe$Just(value))
					]),
				model);
		} else {
			return _Utils_Tuple2(model, cmd);
		}
	});
var $author$project$QuotationOld$Update$Helper$genderParser = function (gender) {
	_v0$2:
	while (true) {
		if (!gender.$) {
			switch (gender.a) {
				case 'F':
					return 'B';
				case 'M':
					return 'A';
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return '';
};
var $author$project$Utils$getDateDistance = F3(
	function (unit, before, after) {
		return A3($justinmimbs$date$Date$diff, unit, after, before);
	});
var $author$project$QuotationOld$Update$Helper$pushAllToDataLayer = function (_v0) {
	var model = _v0.a;
	var formData = model.dv;
	var formStep = model.fp;
	var cmds = _v0.b;
	var updateReturn = _v0.c;
	if ($author$project$QuotationOld$Model$FormStep$isFormStepValid(formStep)) {
		var withDefaultAndLower = A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$withDefault(''),
			$elm$core$String$toLower);
		var propertyAddressCity = A2($author$project$QuotationOld$Model$FormData$getCityByCityField, formData.iu, formData.kY.my);
		var pickTextValueAndFormat = function (mapper) {
			return withDefaultAndLower(
				mapper(formData).p8);
		};
		var pickIntValueAndFormat = function (mapper) {
			return withDefaultAndLower(
				A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					mapper(formData).p8));
		};
		var dataLayerCmd = $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Location',
					pickTextValueAndFormat(
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kY;
							},
							function ($) {
								return $.my;
							}))),
					_Utils_Tuple2(
					'Province',
					withDefaultAndLower(
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.mH;
							},
							A2(
								$elm$core$Maybe$andThen,
								function ($) {
									return $.o6;
								},
								propertyAddressCity)))),
					_Utils_Tuple2(
					'Proprietario',
					pickTextValueAndFormat(
						function ($) {
							return $.oL;
						})),
					_Utils_Tuple2(
					'Utilizzo',
					pickTextValueAndFormat(
						function ($) {
							return $.o1;
						})),
					_Utils_Tuple2(
					'Immobile',
					pickTextValueAndFormat(
						function ($) {
							return $.o4;
						})),
					_Utils_Tuple2(
					'Dimensioni',
					pickIntValueAndFormat(
						function ($) {
							return $.o2;
						})),
					_Utils_Tuple2(
					'Costruzione',
					pickTextValueAndFormat(
						function ($) {
							return $.mP;
						})),
					_Utils_Tuple2(
					'S',
					pickTextValueAndFormat(
						function ($) {
							return $.pn;
						})),
					_Utils_Tuple2(
					'Maggiorenni',
					pickTextValueAndFormat(
						function ($) {
							return $.np;
						})),
					_Utils_Tuple2(
					'Minorenni',
					pickTextValueAndFormat(
						function ($) {
							return $.nr;
						})),
					_Utils_Tuple2(
					'G',
					$elm$core$String$toLower(
						$author$project$QuotationOld$Update$Helper$genderParser(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.ny;
								},
								function ($) {
									return $.p8;
								},
								formData)))),
					_Utils_Tuple2(
					'Età',
					A3(
						$elm_community$maybe_extra$Maybe$Extra$unwrap,
						'',
						$elm$core$String$fromInt,
						A2(
							$elm$core$Maybe$map,
							A2($author$project$Utils$getDateDistance, 0, formData.iH),
							$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$toMaybeDate(
								A3(
									$elm$core$Basics$composeR,
									function ($) {
										return $.mn;
									},
									function ($) {
										return $.p8;
									},
									formData)))))
				]));
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[dataLayerCmd, cmds])),
			updateReturn);
	} else {
		return _Utils_Tuple3(model, cmds, updateReturn);
	}
};
var $author$project$QuotationOld$Update$Helper$pushFormDataToDataLayer = function (model) {
	var formData = model.dv;
	var formStep = model.fp;
	return function () {
		if ($author$project$QuotationOld$Model$FormStep$isFormStepValid(formStep)) {
			var withDefaultAndLower = A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$withDefault(''),
				$elm$core$String$toLower);
			var propertyAddressCity = A2($author$project$QuotationOld$Model$FormData$getCityByCityField, formData.iu, formData.kY.my);
			var pickTextValueAndFormat = function (mapper) {
				return withDefaultAndLower(
					mapper(formData).p8);
			};
			var pickIntValueAndFormat = function (mapper) {
				return withDefaultAndLower(
					A2(
						$elm$core$Maybe$map,
						$elm$core$String$fromInt,
						mapper(formData).p8));
			};
			var dataLayerCmd = function () {
				switch (formStep.$) {
					case 0:
						return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Location',
									withDefaultAndLower(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.oq;
											},
											propertyAddressCity))),
									_Utils_Tuple2(
									'Province',
									withDefaultAndLower(
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.mH;
											},
											A2(
												$elm$core$Maybe$andThen,
												function ($) {
													return $.o6;
												},
												propertyAddressCity))))
								]));
					case 1:
						return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Proprietario',
									pickTextValueAndFormat(
										function ($) {
											return $.oL;
										})),
									_Utils_Tuple2(
									'Utilizzo',
									pickTextValueAndFormat(
										function ($) {
											return $.o1;
										}))
								]));
					case 2:
						return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Immobile',
									pickTextValueAndFormat(
										function ($) {
											return $.o4;
										})),
									_Utils_Tuple2(
									'Dimensioni',
									pickIntValueAndFormat(
										function ($) {
											return $.o2;
										})),
									_Utils_Tuple2(
									'Costruzione',
									pickTextValueAndFormat(
										function ($) {
											return $.mP;
										})),
									_Utils_Tuple2(
									'S',
									pickTextValueAndFormat(
										function ($) {
											return $.pn;
										})),
									_Utils_Tuple2(
									'Maggiorenni',
									pickTextValueAndFormat(
										function ($) {
											return $.np;
										})),
									_Utils_Tuple2(
									'Minorenni',
									pickTextValueAndFormat(
										function ($) {
											return $.nr;
										}))
								]));
					case 3:
						return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'G',
									$author$project$QuotationOld$Update$Helper$genderParser(formData.ny.p8)),
									_Utils_Tuple2(
									'Età',
									A3(
										$elm_community$maybe_extra$Maybe$Extra$unwrap,
										'',
										$elm$core$String$fromInt,
										A2(
											$elm$core$Maybe$map,
											A2($author$project$Utils$getDateDistance, 0, formData.iH),
											$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$toMaybeDate(formData.mn.p8))))
								]));
					default:
						return $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(_List_Nil);
				}
			}();
			return $author$project$Utils$withCommands(
				_List_fromArray(
					[dataLayerCmd]));
		} else {
			return $author$project$Utils$withoutCommands;
		}
	}()(model);
};
var $author$project$QuotationOld$Update$Helper$redirectToGuarantees = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var _v1 = model.je;
	if (!_v1.$) {
		if (!_v1.a.$) {
			var formId = _v1.a.a;
			return A2(
				$author$project$Utils$UpdateReturn$withPushUrl,
				A3($author$project$Routing$Guarantees, 0, formId, $elm$core$Maybe$Nothing),
				$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					_Utils_Tuple2(model, cmd)));
		} else {
			var error = _v1.a.a;
			var _v2 = error.k6;
			if (_v2 === 1) {
				return A2(
					$author$project$Utils$UpdateReturn$withPushUrl,
					$author$project$Routing$NotAvailable($author$project$NotAvailable$Model$QuoteNotAvailable),
					_Utils_Tuple3(model, cmd, $author$project$Utils$UpdateReturn$initUpdateReturn));
			} else {
				return _Utils_Tuple3(model, cmd, $author$project$Utils$UpdateReturn$initUpdateReturn);
			}
		}
	} else {
		return _Utils_Tuple3(model, cmd, $author$project$Utils$UpdateReturn$initUpdateReturn);
	}
};
var $author$project$QuotationOld$Update$Helper$resetZipCode = function (field) {
	switch (field) {
		case 0:
			return $author$project$QuotationOld$Model$updateFormData(
				A2($author$project$QuotationOld$Model$FormData$updateSelectField, 6, $author$project$QuotationOld$Model$SelectField$emptyValue));
		case 3:
			return $author$project$QuotationOld$Model$updateFormData(
				A2($author$project$QuotationOld$Model$FormData$updateSelectField, 7, $author$project$QuotationOld$Model$SelectField$emptyValue));
		default:
			return $elm$core$Basics$identity;
	}
};
var $author$project$Ports$scrollToElement = _Platform_outgoingPort('scrollToElement', $elm$json$Json$Encode$string);
var $author$project$QuotationOld$Update$Helper$sendGTMEventForAutocomplete = F3(
	function (autocompleteMsg, fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = function () {
			switch (fieldName) {
				case 0:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Comune'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.my;
								},
								formData)));
				case 1:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Città di nascita'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.ih));
				case 2:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Stato di nascita'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.mj));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Comune residenza'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.my;
								},
								formData)));
			}
		}();
		var label = _v1.a;
		var isValid = _v1.b;
		var maybeSend = function () {
			if (autocompleteMsg.$ === 2) {
				return A2($author$project$QuotationOld$Update$Helper$sendGTMEvent, label, isValid);
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		return maybeSend(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				model));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEventForDatePicker = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = _Utils_Tuple2(
			$elm$core$Maybe$Just('Data di nascita'),
			$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.mn));
		var label = _v1.a;
		var isValid = _v1.b;
		return A3(
			$author$project$QuotationOld$Update$Helper$sendGTMEvent,
			label,
			isValid,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEventForNumberIntField = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = function () {
			if (!fieldName) {
				return _Utils_Tuple2(
					$elm$core$Maybe$Just('mq'),
					$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.o2));
			} else {
				return _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.dJ));
			}
		}();
		var label = _v1.a;
		var isValid = _v1.b;
		return A3(
			$author$project$QuotationOld$Update$Helper$sendGTMEvent,
			label,
			isValid,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEventForRadioField = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = function () {
			switch (fieldName) {
				case 0:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Tipo proprietà'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.oL));
				case 1:
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.cY));
				case 2:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Tipo condominio'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.mN));
				case 3:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Tipo casa'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.qa));
				case 4:
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.dI));
				case 5:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Tipo immobile'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.o4));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Genere'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.ny));
			}
		}();
		var label = _v1.a;
		var isValid = _v1.b;
		return A3(
			$author$project$QuotationOld$Update$Helper$sendGTMEvent,
			label,
			isValid,
			_Utils_Tuple2(model, cmd));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEventForSelectField = F3(
	function (selectMsg, fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = function () {
			switch (fieldName) {
				case 0:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Tipo d\'uso'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.o1));
				case 1:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Piano dell\'appartamento'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.nw));
				case 2:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Periodo di costruzione'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.mP));
				case 3:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Sinistri denunciati'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.pn));
				case 4:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Maggiorenni conviventi'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.np));
				case 5:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Minorenni conviventi'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.nr));
				case 6:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Cap'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.qg;
								},
								formData)));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Cap residenza'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.qg;
								},
								formData)));
			}
		}();
		var label = _v1.a;
		var isValid = _v1.b;
		var maybeSend = function () {
			if (!selectMsg.$) {
				return A2($author$project$QuotationOld$Update$Helper$sendGTMEvent, label, isValid);
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		return maybeSend(
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[cmd]),
				model));
	});
var $author$project$QuotationOld$Update$Helper$sendGTMEventForTextField = F2(
	function (fieldName, _v0) {
		var model = _v0.a;
		var formData = model.dv;
		var cmd = _v0.b;
		var _v1 = function () {
			switch (fieldName) {
				case 0:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Indirizzo'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.lx;
								},
								formData)));
				case 1:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('n. civico'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.kp;
								},
								formData)));
				case 2:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Scala'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.pJ;
								},
								formData)));
				case 3:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Interno'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kY;
								},
								function ($) {
									return $.nX;
								},
								formData)));
				case 4:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Indirizzo residenza'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.lx;
								},
								formData)));
				case 5:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('n. civico residenza'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.kp;
								},
								formData)));
				case 6:
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.pJ;
								},
								formData)));
				case 7:
					return _Utils_Tuple2(
						$elm$core$Maybe$Nothing,
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(
							A3(
								$elm$core$Basics$composeR,
								function ($) {
									return $.pq;
								},
								function ($) {
									return $.nX;
								},
								formData)));
				case 8:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Nome'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.oq));
				case 9:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Cognome'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.lC));
				case 10:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Indirizzo email'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.fi));
				default:
					return _Utils_Tuple2(
						$elm$core$Maybe$Just('Cellulare'),
						$author$project$QuotationOld$Update$Helper$getIsFieldValid(formData.oT));
			}
		}();
		var label = _v1.a;
		var isValid = _v1.b;
		var sendEvent = isValid ? A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			$elm$core$Platform$Cmd$none,
			$author$project$Utils$GTM$sendGTMFormEventOld(formData),
			label) : $elm$core$Platform$Cmd$none;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[sendEvent, cmd]),
			model);
	});
var $author$project$QuotationOld$Model$FormData$togglePrivacyAccordion = F2(
	function (isOpen, formData) {
		var updatePrivacyAccordionState = function (privacy) {
			return _Utils_update(
				privacy,
				{
					h3: $primait$pyxis_components$Prima$Pyxis$Accordion$state(!isOpen)
				});
		};
		return _Utils_update(
			formData,
			{
				oZ: updatePrivacyAccordionState(formData.oZ)
			});
	});
var $author$project$QuotationOld$Update$Helper$toggleToolTip = F2(
	function (tooltip, current) {
		return _Utils_eq(tooltip, current) ? $elm$core$Maybe$Nothing : tooltip;
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$selectedValue = function (_v0) {
	var stateConfig = _v0;
	return stateConfig.pC;
};
var $author$project$QuotationOld$Model$AutocompleteField$updateByState = F2(
	function (newState, field) {
		var value = $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$selectedValue(newState);
		return A2(
			$author$project$QuotationOld$Model$AutocompleteField$updateValue,
			value,
			A2($author$project$QuotationOld$Model$AutocompleteField$updateState, newState, field));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$AutocompleteChoice = F2(
	function (value, label) {
		return {n6: label, p8: value};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$autocompleteChoice = F2(
	function (value, label) {
		return A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$AutocompleteChoice, value, label);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateChoices = F2(
	function (choices, _v0) {
		var state = _v0;
		return _Utils_update(
			state,
			{
				av: $primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$Loaded(choices)
			});
	});
var $author$project$QuotationOld$Model$AutocompleteField$updateChoices = F2(
	function (choices, field) {
		var autocompleteChoices = A2(
			$elm$core$List$map,
			$elm_community$basics_extra$Basics$Extra$uncurry($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$autocompleteChoice),
			choices);
		return _Utils_update(
			field,
			{
				cG: A2($primait$pyxis_components$Prima$Pyxis$Form$Autocomplete$updateChoices, autocompleteChoices, field.cG)
			});
	});
var $author$project$QuotationOld$Model$ExtraTypes$cityToDictionaryRecord = function (_v0) {
	var name = _v0.oq;
	var zipCodes = _v0.qh;
	var landRegister = _v0.S;
	var province = _v0.o6;
	return _Utils_Tuple2(
		name,
		{S: landRegister, oq: name, o6: province, qh: zipCodes});
};
var $author$project$QuotationOld$Model$ExtraTypes$cityListToDictionary = function (cities) {
	return $elm$core$Dict$fromList(
		A2($elm$core$List$map, $author$project$QuotationOld$Model$ExtraTypes$cityToDictionaryRecord, cities));
};
var $author$project$QuotationOld$Model$FormData$updateCityDict = F2(
	function (cities, formData) {
		return _Utils_update(
			formData,
			{
				iu: A2(
					$elm$core$Dict$union,
					formData.iu,
					$author$project$QuotationOld$Model$ExtraTypes$cityListToDictionary(cities))
			});
	});
var $author$project$QuotationOld$Model$FormData$updateCurrentDate = F2(
	function (today, formData) {
		return _Utils_update(
			formData,
			{iH: today});
	});
var $author$project$QuotationOld$Model$updateHighlightedDiscountMessageVisible = F2(
	function (value, model) {
		return _Utils_update(
			model,
			{fK: value});
	});
var $author$project$QuotationOld$Model$DatePickerField$updateIsOpen = F2(
	function (isOpen, field) {
		return _Utils_update(
			field,
			{jP: isOpen});
	});
var $author$project$QuotationOld$Update$Helper$updateOpenToolTip = F2(
	function (tooltip, model) {
		return _Utils_update(
			model,
			{ks: tooltip});
	});
var $author$project$QuotationOld$Model$updateOriginalQuoteId = F2(
	function (quoteId, model) {
		return _Utils_update(
			model,
			{U: quoteId});
	});
var $author$project$QuotationOld$Model$updateOriginalSaveId = F2(
	function (saveId, model) {
		return _Utils_update(
			model,
			{w: saveId});
	});
var $author$project$QuotationOld$Update$Helper$updateQuoteSubmissionResult = F2(
	function (fsr, model) {
		if (fsr.$ === 3) {
			var formSubmissionResult = fsr.a;
			return _Utils_update(
				model,
				{
					je: $elm$core$Maybe$Just(formSubmissionResult)
				});
		} else {
			return model;
		}
	});
var $author$project$QuotationOld$Model$DatePickerField$updateStateByMsg = F2(
	function (datePickerMsg, field) {
		var isOpen = function () {
			if (datePickerMsg.$ === 6) {
				return false;
			} else {
				return field.jP;
			}
		}();
		return A2(
			$author$project$QuotationOld$Model$DatePickerField$updateIsOpen,
			isOpen,
			A3(
				$elm_community$basics_extra$Basics$Extra$flip,
				$author$project$QuotationOld$Model$DatePickerField$updateState,
				field,
				A2(
					$elm$core$Maybe$map,
					$primait$pyxis_components$Prima$Pyxis$Form$DatePicker$update(datePickerMsg),
					field.cG)));
	});
var $author$project$QuotationOld$Model$DatePickerField$updateStateAndValueByMsg = F3(
	function (today, datePickerMsg, field) {
		return function (updatedField) {
			return A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				updatedField,
				A2(
					$elm_community$basics_extra$Basics$Extra$flip,
					$author$project$QuotationOld$Model$DatePickerField$updateValue(today),
					updatedField),
				A2($elm$core$Maybe$map, $primait$pyxis_components$Prima$Pyxis$Form$DatePicker$selectedDate, updatedField.cG));
		}(
			A2($author$project$QuotationOld$Model$DatePickerField$updateStateByMsg, datePickerMsg, field));
	});
var $author$project$QuotationOld$Model$SelectField$updateStateAndValueByMsg = F2(
	function (selectMsg, field) {
		var newState = A2($primait$pyxis_components$Prima$Pyxis$Form$Select$update, selectMsg, field.cG);
		var value = $primait$pyxis_components$Prima$Pyxis$Form$Select$selectedValue(newState);
		return A2(
			$author$project$QuotationOld$Model$SelectField$updateValue,
			value,
			A2($author$project$QuotationOld$Model$SelectField$updateState, newState, field));
	});
var $author$project$QuotationOld$Model$FormData$updateStepSubmitted = F2(
	function (formSteps, formData) {
		return _Utils_update(
			formData,
			{hM: formSteps});
	});
var $author$project$QuotationOld$Model$FormStep$boolToValidationStatus = function (isValid) {
	return isValid ? 0 : 1;
};
var $author$project$QuotationOld$Model$FormStep$PricesShownStep = {$: 4};
var $author$project$QuotationOld$Model$FormStep$updateFormStepValidationStatus = function (formStep) {
	switch (formStep.$) {
		case 0:
			return $author$project$QuotationOld$Model$FormStep$PropertyAddressStep;
		case 1:
			return $author$project$QuotationOld$Model$FormStep$OwnershipStep;
		case 2:
			return $author$project$QuotationOld$Model$FormStep$PropertyInfoStep;
		case 3:
			return $author$project$QuotationOld$Model$FormStep$UserDataStep;
		default:
			return $elm$core$Basics$always($author$project$QuotationOld$Model$FormStep$PricesShownStep);
	}
};
var $author$project$QuotationOld$Update$Helper$updateStepValidationStatus = function (model) {
	var formData = model.dv;
	var formStep = model.fp;
	var addUserDataStepFields = A2(
		$elm$core$Basics$composeR,
		$elm$core$List$append(
			_Utils_eq(
				formData.ih.p8,
				$elm$core$Maybe$Just('Stato Estero')) ? _List_fromArray(
				[formData.mj.i4]) : _List_Nil),
		$elm$core$List$append(
			(!_Utils_eq(
				formData.o1.p8,
				$elm$core$Maybe$Just('RESIDENTIAL'))) ? _List_fromArray(
				[formData.pq.lx.i4, formData.pq.kp.i4, formData.pq.my.i4, formData.pq.qg.i4]) : _List_Nil));
	var addPropertyInfoStepFields = $elm$core$List$append(
		_Utils_eq(
			formData.o4.p8,
			$elm$core$Maybe$Just('APARTMENT')) ? _List_fromArray(
			[formData.mN.i4, formData.nw.i4]) : (_Utils_eq(
			formData.o4.p8,
			$elm$core$Maybe$Just('HOUSE')) ? _List_fromArray(
			[formData.qa.i4]) : _List_Nil));
	var fieldsErrorMessage = function () {
		switch (formStep.$) {
			case 0:
				return _List_fromArray(
					[formData.kY.lx.i4, formData.kY.kp.i4, formData.kY.my.i4, formData.kY.qg.i4, formData.kY.pJ.i4, formData.kY.nX.i4]);
			case 1:
				return _List_fromArray(
					[formData.oL.i4, formData.o1.i4]);
			case 2:
				return addPropertyInfoStepFields(
					_List_fromArray(
						[formData.o4.i4, formData.o2.i4, formData.mP.i4, formData.pn.i4, formData.np.i4, formData.nr.i4]));
			case 3:
				return addUserDataStepFields(
					_List_fromArray(
						[formData.ny.i4, formData.oq.i4, formData.lC.i4, formData.mn.i4, formData.ih.i4, formData.fi.i4, formData.oT.i4, formData.oZ.pf.i4]));
			default:
				return _List_Nil;
		}
	}();
	var isValid = !A2($elm$core$List$any, $elm_community$maybe_extra$Maybe$Extra$isJust, fieldsErrorMessage);
	return A3(
		$elm_community$basics_extra$Basics$Extra$flip,
		$author$project$QuotationOld$Model$updateFormStep,
		model,
		A2(
			$author$project$QuotationOld$Model$FormStep$updateFormStepValidationStatus,
			formStep,
			$author$project$QuotationOld$Model$FormStep$boolToValidationStatus(isValid)));
};
var $author$project$QuotationOld$Model$TextField$updateVisible = F2(
	function (visible, field) {
		return _Utils_update(
			field,
			{lZ: visible});
	});
var $author$project$QuotationOld$Update$Helper$updateEmailOnForm = F2(
	function (email, model) {
		return A2(
			$author$project$QuotationOld$Model$updateFormData,
			A2(
				$author$project$QuotationOld$Model$FormData$updateTextField,
				10,
				$author$project$QuotationOld$Model$TextField$updateVisible(false)),
			A2(
				$author$project$QuotationOld$Model$updateFormData,
				A2(
					$author$project$QuotationOld$Model$FormData$updateTextField,
					10,
					$author$project$QuotationOld$Model$TextField$updateValue(
						$elm$core$Maybe$Just(email))),
				_Utils_update(
					model,
					{
						lP: $elm$core$Maybe$Just(email)
					})));
	});
var $author$project$QuotationOld$Update$Helper$updateUserEmail = F2(
	function (user, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				function (email) {
					return A2($author$project$QuotationOld$Update$Helper$updateEmailOnForm, email, model);
				},
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fi;
					},
					user)));
	});
var $author$project$QuotationOld$Update$Helper$updateUserType = F2(
	function (userType, model) {
		return _Utils_update(
			model,
			{lR: userType});
	});
var $author$project$QuotationOld$Update$Helper$updateZipCodeList = F2(
	function (cityFieldName, model) {
		var formData = model.dv;
		var zipCodes = A2(
			$elm$core$Basics$composeR,
			$author$project$QuotationOld$Model$FormData$getCityByCityField(formData.iu),
			$elm$core$Maybe$map(
				function ($) {
					return $.qh;
				}));
		return function () {
			switch (cityFieldName) {
				case 0:
					return $author$project$QuotationOld$Model$updateFormData(
						$author$project$QuotationOld$Model$FormData$updatePropertyZipCodes(
							zipCodes(formData.kY.my)));
				case 3:
					return $author$project$QuotationOld$Model$updateFormData(
						$author$project$QuotationOld$Model$FormData$updateResidenceZipCodes(
							zipCodes(formData.pq.my)));
				default:
					return $elm$core$Basics$identity;
			}
		}()(model);
	});
var $author$project$QuotationOld$Update$Helper$validateStepFields = function (model) {
	var formData = model.dv;
	var formStep = model.fp;
	var addUserDataStepFields = A2(
		$elm$core$Basics$composeR,
		_Utils_eq(
			formData.ih.p8,
			$elm$core$Maybe$Just('Stato Estero')) ? $author$project$QuotationOld$Model$updateFormData(
			A2($author$project$QuotationOld$Model$FormData$updateAutocompleteField, 2, $author$project$QuotationOld$Model$AutocompleteField$validate)) : $elm$core$Basics$identity,
		(!_Utils_eq(
			formData.o4.p8,
			$elm$core$Maybe$Just('RESIDENTIAL'))) ? A2(
			$elm$core$Basics$composeR,
			$author$project$QuotationOld$Model$updateFormData(
				A2($author$project$QuotationOld$Model$FormData$updateTextField, 4, $author$project$QuotationOld$Model$TextField$validate)),
			A2(
				$elm$core$Basics$composeR,
				$author$project$QuotationOld$Model$updateFormData(
					A2($author$project$QuotationOld$Model$FormData$updateTextField, 5, $author$project$QuotationOld$Model$TextField$validate)),
				A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateAutocompleteField, 3, $author$project$QuotationOld$Model$AutocompleteField$validate)),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2($author$project$QuotationOld$Model$FormData$updateSelectField, 7, $author$project$QuotationOld$Model$SelectField$validate)),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateTextField, 6, $author$project$QuotationOld$Model$TextField$validate)),
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateTextField, 7, $author$project$QuotationOld$Model$TextField$validate))))))) : $elm$core$Basics$identity);
	var addPropertyInfoStepFields = function () {
		var _v1 = formData.o4.p8;
		_v1$2:
		while (true) {
			if (!_v1.$) {
				switch (_v1.a) {
					case 'APARTMENT':
						return A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateRadioField, 2, $author$project$QuotationOld$Model$RadioField$validate)),
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateSelectField, 1, $author$project$QuotationOld$Model$SelectField$validate)));
					case 'HOUSE':
						return $author$project$QuotationOld$Model$updateFormData(
							A2($author$project$QuotationOld$Model$FormData$updateRadioField, 3, $author$project$QuotationOld$Model$RadioField$validate));
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return $elm$core$Basics$identity;
	}();
	return function () {
		switch (formStep.$) {
			case 0:
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateTextField, 0, $author$project$QuotationOld$Model$TextField$validate)),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2($author$project$QuotationOld$Model$FormData$updateTextField, 1, $author$project$QuotationOld$Model$TextField$validate)),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateAutocompleteField, 0, $author$project$QuotationOld$Model$AutocompleteField$validate)),
							A2(
								$elm$core$Basics$composeR,
								$author$project$QuotationOld$Model$updateFormData(
									A2($author$project$QuotationOld$Model$FormData$updateSelectField, 6, $author$project$QuotationOld$Model$SelectField$validate)),
								A2(
									$elm$core$Basics$composeR,
									$author$project$QuotationOld$Model$updateFormData(
										A2($author$project$QuotationOld$Model$FormData$updateTextField, 2, $author$project$QuotationOld$Model$TextField$validate)),
									$author$project$QuotationOld$Model$updateFormData(
										A2($author$project$QuotationOld$Model$FormData$updateTextField, 3, $author$project$QuotationOld$Model$TextField$validate)))))));
			case 1:
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateRadioField, 0, $author$project$QuotationOld$Model$RadioField$validate)),
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateSelectField, 0, $author$project$QuotationOld$Model$SelectField$validate)));
			case 2:
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateRadioField, 5, $author$project$QuotationOld$Model$RadioField$validate)),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2($author$project$QuotationOld$Model$FormData$updateNumberField, 0, $author$project$QuotationOld$Model$NumberField$validate)),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateSelectField, 2, $author$project$QuotationOld$Model$SelectField$validate)),
							A2(
								$elm$core$Basics$composeR,
								$author$project$QuotationOld$Model$updateFormData(
									A2($author$project$QuotationOld$Model$FormData$updateSelectField, 3, $author$project$QuotationOld$Model$SelectField$validate)),
								A2(
									$elm$core$Basics$composeR,
									$author$project$QuotationOld$Model$updateFormData(
										A2($author$project$QuotationOld$Model$FormData$updateSelectField, 4, $author$project$QuotationOld$Model$SelectField$validate)),
									A2(
										$elm$core$Basics$composeR,
										$author$project$QuotationOld$Model$updateFormData(
											A2($author$project$QuotationOld$Model$FormData$updateSelectField, 5, $author$project$QuotationOld$Model$SelectField$validate)),
										addPropertyInfoStepFields))))));
			case 3:
				return A2(
					$elm$core$Basics$composeR,
					$author$project$QuotationOld$Model$updateFormData(
						A2($author$project$QuotationOld$Model$FormData$updateRadioField, 6, $author$project$QuotationOld$Model$RadioField$validate)),
					A2(
						$elm$core$Basics$composeR,
						$author$project$QuotationOld$Model$updateFormData(
							A2($author$project$QuotationOld$Model$FormData$updateTextField, 8, $author$project$QuotationOld$Model$TextField$validate)),
						A2(
							$elm$core$Basics$composeR,
							$author$project$QuotationOld$Model$updateFormData(
								A2($author$project$QuotationOld$Model$FormData$updateTextField, 9, $author$project$QuotationOld$Model$TextField$validate)),
							A2(
								$elm$core$Basics$composeR,
								$author$project$QuotationOld$Model$updateFormData(
									A2(
										$author$project$QuotationOld$Model$FormData$updateDatePickerField,
										0,
										$author$project$QuotationOld$Model$DatePickerField$validate(formData.iH))),
								A2(
									$elm$core$Basics$composeR,
									$author$project$QuotationOld$Model$updateFormData(
										A2($author$project$QuotationOld$Model$FormData$updateAutocompleteField, 1, $author$project$QuotationOld$Model$AutocompleteField$validate)),
									A2(
										$elm$core$Basics$composeR,
										$author$project$QuotationOld$Model$updateFormData(
											A2($author$project$QuotationOld$Model$FormData$updateTextField, 10, $author$project$QuotationOld$Model$TextField$validate)),
										A2(
											$elm$core$Basics$composeR,
											$author$project$QuotationOld$Model$updateFormData(
												A2($author$project$QuotationOld$Model$FormData$updateTextField, 11, $author$project$QuotationOld$Model$TextField$validate)),
											A2(
												$elm$core$Basics$composeR,
												$author$project$QuotationOld$Model$updateFormData(
													A2($author$project$QuotationOld$Model$FormData$updateCheckboxField, 0, $author$project$QuotationOld$Model$CheckboxField$validate)),
												addUserDataStepFields))))))));
			default:
				return $elm$core$Basics$identity;
		}
	}()(model);
};
var $author$project$QuotationOld$Model$TextField$withConditionalTrim = function (fieldTextName) {
	switch (fieldTextName) {
		case 10:
			return $elm$core$String$trim;
		case 11:
			return $elm$core$String$trim;
		default:
			return $elm$core$Basics$identity;
	}
};
var $author$project$QuotationOld$Model$GotQuoteQueryResponse = function (a) {
	return {$: 22, a: a};
};
var $author$project$QuotationOld$Api$quoteQuery = function (id) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$QuotationSource(
			$author$project$Api$GetQuote($author$project$QuotationOld$Model$GotQuoteQueryResponse)),
		$author$project$Guarantees$Api$Quote$quoteQuery(id));
};
var $author$project$QuotationOld$Model$GotSaveQueryResponse = function (a) {
	return {$: 23, a: a};
};
var $author$project$QuotationOld$Api$saveQuery = function (id) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$QuotationSource(
			$author$project$Api$GetSave($author$project$QuotationOld$Model$GotSaveQueryResponse)),
		$author$project$Guarantees$Api$Save$saveQuery(id));
};
var $author$project$QuotationOld$Update$Helper$withQuoteAndSaveQuery = F3(
	function (maybeQuoteId, maybeSaveId, updateReturn) {
		return A2(
			$elm$core$Maybe$withDefault,
			updateReturn,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Utils$UpdateReturn$withQuery, updateReturn),
				A2(
					$elm_community$maybe_extra$Maybe$Extra$or,
					A2($elm$core$Maybe$map, $author$project$QuotationOld$Api$quoteQuery, maybeQuoteId),
					A2($elm$core$Maybe$map, $author$project$QuotationOld$Api$saveQuery, maybeSaveId))));
	});
var $author$project$QuotationOld$Update$update = F2(
	function (msg, model) {
		var formData = model.dv;
		var formStep = model.fp;
		switch (msg.$) {
			case 0:
				var tokenType = msg.a;
				var user = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Update$Helper$updateUserEmail,
							user,
							A2($author$project$QuotationOld$Update$Helper$updateUserType, tokenType, model))));
			case 2:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 3:
				var fieldName = msg.a;
				var autocompleteMsg = msg.b;
				var _v1 = A3($author$project$QuotationOld$Model$FormData$autocompleteUpdate, fieldName, autocompleteMsg, formData);
				var state = _v1.a;
				var autocompleteCmd = _v1.b;
				var filter = _v1.c;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A3(
						$author$project$QuotationOld$Update$Helper$maybeWithRequest,
						filter,
						fieldName,
						A3(
							$author$project$QuotationOld$Update$Helper$sendGTMEventForAutocomplete,
							autocompleteMsg,
							fieldName,
							A2(
								$author$project$QuotationOld$Update$Helper$maybeUpdateZipCodeChoices,
								fieldName,
								A2(
									$author$project$QuotationOld$Update$Helper$maybeUpdateBirthCountry,
									fieldName,
									A2(
										$author$project$Utils$withCommands,
										_List_fromArray(
											[
												A2(
												$elm$core$Platform$Cmd$map,
												$author$project$QuotationOld$Model$OnAutocompleteMsg(fieldName),
												autocompleteCmd)
											]),
										A2(
											$author$project$QuotationOld$Model$updateFormData,
											A2(
												$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
												fieldName,
												$author$project$QuotationOld$Model$AutocompleteField$updateByState(state)),
											model)))))));
			case 4:
				var fieldName = msg.a;
				var responseCities = msg.b;
				if (responseCities.$ === 3) {
					var cities = responseCities.a;
					var defaultForBirthCity = (fieldName === 1) ? $author$project$Utils$listWithDefault(
						_Utils_Tuple2('Stato Estero', 'Stato Estero')) : $elm$core$Basics$identity;
					var choices = defaultForBirthCity(
						A2($elm$core$List$map, $author$project$QuotationOld$Model$ExtraTypes$fromCityToChoice, cities));
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						A2(
							$author$project$QuotationOld$Update$Helper$maybeUpdateZipCodeChoices,
							fieldName,
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									$author$project$QuotationOld$Model$FormData$updateCityDict(cities),
									A2(
										$author$project$QuotationOld$Model$updateFormData,
										A2(
											$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
											fieldName,
											$author$project$QuotationOld$Model$AutocompleteField$updateChoices(choices)),
										A2($author$project$QuotationOld$Update$Helper$resetZipCode, fieldName, model))))));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
				}
			case 5:
				var fieldName = msg.a;
				var defaultCity = msg.b;
				var maybeDefaultZipCode = msg.c;
				var responseCities = msg.d;
				if (responseCities.$ === 3) {
					var cities = responseCities.a;
					var maybeUpdateZipCodeChoice = function () {
						switch (fieldName) {
							case 0:
								return A2(
									$elm$core$Maybe$withDefault,
									$elm$core$Basics$identity,
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$author$project$Utils$duplicate,
											A2(
												$elm$core$Basics$composeR,
												$elm$core$List$singleton,
												A2(
													$elm$core$Basics$composeR,
													$author$project$QuotationOld$Model$SelectField$updateStateByChoices,
													A2(
														$elm$core$Basics$composeR,
														$author$project$QuotationOld$Model$FormData$updateSelectField(6),
														$author$project$QuotationOld$Model$updateFormData)))),
										maybeDefaultZipCode));
							case 3:
								return A2(
									$elm$core$Maybe$withDefault,
									$elm$core$Basics$identity,
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$author$project$Utils$duplicate,
											A2(
												$elm$core$Basics$composeR,
												$elm$core$List$singleton,
												A2(
													$elm$core$Basics$composeR,
													$author$project$QuotationOld$Model$SelectField$updateStateByChoices,
													A2(
														$elm$core$Basics$composeR,
														$author$project$QuotationOld$Model$FormData$updateSelectField(7),
														$author$project$QuotationOld$Model$updateFormData)))),
										maybeDefaultZipCode));
							default:
								return $elm$core$Basics$identity;
						}
					}();
					var maybeDefaultCityChoice = A2(
						$elm$core$Maybe$map,
						$author$project$QuotationOld$Model$ExtraTypes$fromCityToChoice,
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.oq;
								},
								$elm$core$Basics$eq(defaultCity)),
							cities));
					var maybeUpdateChoice = function () {
						if (!maybeDefaultCityChoice.$) {
							var defaultChoice = maybeDefaultCityChoice.a;
							return $author$project$QuotationOld$Model$updateFormData(
								A2(
									$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
									fieldName,
									$author$project$QuotationOld$Model$AutocompleteField$updateByChoice(defaultChoice)));
						} else {
							return $elm$core$Basics$identity;
						}
					}();
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							maybeUpdateZipCodeChoice(
								A2(
									$author$project$QuotationOld$Update$Helper$updateZipCodeList,
									fieldName,
									A2(
										$author$project$QuotationOld$Model$updateFormData,
										$author$project$QuotationOld$Model$FormData$updateCityDict(cities),
										maybeUpdateChoice(model))))));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
				}
			case 6:
				var field = msg.a;
				var responseCountries = msg.b;
				if (responseCountries.$ === 3) {
					var countries = responseCountries.a;
					var choices = A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.oq;
							},
							$author$project$Utils$duplicate),
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.oq;
								},
								$elm$core$Basics$neq('Italia')),
							countries));
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$QuotationOld$Model$updateFormData,
								A2(
									$author$project$QuotationOld$Model$FormData$updateAutocompleteField,
									field,
									$author$project$QuotationOld$Model$AutocompleteField$updateChoices(choices)),
								model)));
				} else {
					return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model));
				}
			case 7:
				var fieldName = msg.a;
				var value = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							A2(
								$author$project$QuotationOld$Model$FormData$updateTextField,
								fieldName,
								$author$project$QuotationOld$Model$TextField$updateValue(
									$elm$core$Maybe$Just(
										A2($author$project$QuotationOld$Model$TextField$withConditionalTrim, fieldName, value)))),
							model)));
			case 8:
				var fieldName = msg.a;
				var value = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							A2(
								$author$project$QuotationOld$Model$FormData$updateNumberField,
								fieldName,
								$author$project$QuotationOld$Model$NumberField$updateValue(
									$elm$core$Maybe$Just(value))),
							model)));
			case 9:
				var fieldName = msg.a;
				var value = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$QuotationOld$Update$Helper$sendGTMEventForRadioField,
						fieldName,
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$QuotationOld$Model$updateFormData,
								A2(
									$author$project$QuotationOld$Model$FormData$updateRadioField,
									fieldName,
									$author$project$QuotationOld$Model$RadioField$updateValue(
										$elm$core$Maybe$Just(value))),
								model))));
			case 10:
				var fieldName = msg.a;
				var value = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A3(
							$author$project$QuotationOld$Update$Helper$maybeAcceptAllConsent,
							fieldName,
							value,
							A2(
								$author$project$QuotationOld$Model$updateFormData,
								A2(
									$author$project$QuotationOld$Model$FormData$updateCheckboxField,
									fieldName,
									$author$project$QuotationOld$Model$CheckboxField$updateValue(value)),
								model))));
			case 11:
				var fieldName = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$QuotationOld$Update$Helper$sendGTMEventForTextField,
						fieldName,
						$author$project$Utils$withoutCommands(model)));
			case 12:
				var fieldName = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$QuotationOld$Update$Helper$sendGTMEventForNumberIntField,
						fieldName,
						$author$project$Utils$withoutCommands(model)));
			case 13:
				var fieldName = msg.a;
				var selectMsg = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A3(
						$author$project$QuotationOld$Update$Helper$sendGTMEventForSelectField,
						selectMsg,
						fieldName,
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$QuotationOld$Update$Helper$closeOtherSelects,
								fieldName,
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									A2(
										$author$project$QuotationOld$Model$FormData$updateSelectField,
										fieldName,
										$author$project$QuotationOld$Model$SelectField$updateStateAndValueByMsg(selectMsg)),
									model)))));
			case 14:
				var fieldName = msg.a;
				var datePickerEvent = msg.b;
				switch (datePickerEvent.$) {
					case 0:
						var date = datePickerEvent.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$QuotationOld$Update$Helper$sendGTMEventForDatePicker,
								fieldName,
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$QuotationOld$Model$updateFormData,
										A2(
											$author$project$QuotationOld$Model$FormData$updateDatePickerField,
											fieldName,
											$author$project$QuotationOld$Model$DatePickerField$updateIsOpen(false)),
										A2(
											$author$project$QuotationOld$Model$updateFormData,
											A2(
												$author$project$QuotationOld$Model$FormData$updateDatePickerField,
												fieldName,
												A2($author$project$QuotationOld$Model$DatePickerField$updateValue, formData.iH, date)),
											model)))));
					case 1:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(model));
					case 3:
						var datePickerMsg = datePickerEvent.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$QuotationOld$Update$Helper$sendGTMEventForDatePicker,
								fieldName,
								$author$project$Utils$withoutCommands(
									A2(
										$author$project$QuotationOld$Model$updateFormData,
										A2(
											$author$project$QuotationOld$Model$FormData$updateDatePickerField,
											fieldName,
											A2($author$project$QuotationOld$Model$DatePickerField$updateStateAndValueByMsg, formData.iH, datePickerMsg)),
										model))));
					case 4:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									A2(
										$author$project$QuotationOld$Model$FormData$updateDatePickerField,
										fieldName,
										$author$project$QuotationOld$Model$DatePickerField$updateIsOpen(true)),
									model)));
					default:
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									A2(
										$author$project$QuotationOld$Model$FormData$updateDatePickerField,
										fieldName,
										$author$project$QuotationOld$Model$DatePickerField$updateIsOpen(false)),
									model)));
				}
			case 15:
				var nextStep = msg.a;
				var handleGuaranteesNotAvailable = function (_v9) {
					var model_ = _v9.a;
					var cmd = _v9.b;
					var _v8 = $author$project$QuotationOld$Update$Helper$maybeNotAvailable(model);
					if (!_v8.$) {
						var reason = _v8.a;
						return A2(
							$author$project$Utils$UpdateReturn$withPushUrl,
							$author$project$Routing$NotAvailable(reason),
							$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
								_Utils_Tuple2(model_, cmd)));
					} else {
						return A2(
							$author$project$QuotationOld$Update$Helper$formNavigation,
							nextStep,
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[cmd]),
								A2($author$project$QuotationOld$Update$Helper$clearUnnecessaryFormData, nextStep, model_)));
					}
				};
				var formSteps = A2($author$project$Utils$listAppendWithoutDuplication, formData.hM, formStep);
				return handleGuaranteesNotAvailable(
					$author$project$QuotationOld$Update$Helper$pushFormDataToDataLayer(
						$author$project$QuotationOld$Update$Helper$updateStepValidationStatus(
							$author$project$QuotationOld$Update$Helper$validateStepFields(
								A2(
									$author$project$QuotationOld$Model$updateFormData,
									$author$project$QuotationOld$Model$FormData$updateStepSubmitted(formSteps),
									model)))));
			case 16:
				var queryResponse = msg.a;
				return $author$project$QuotationOld$Update$Helper$redirectToGuarantees(
					$author$project$Utils$withoutCommands(
						A2($author$project$QuotationOld$Update$Helper$updateQuoteSubmissionResult, queryResponse, model)));
			case 1:
				var step = msg.a;
				var tokenType = msg.b;
				var user = msg.c;
				return A2(
					$author$project$QuotationOld$Update$Helper$maybeRedirectToStepOne,
					step,
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								A2($elm$core$Task$perform, $author$project$QuotationOld$Model$CurrentDate, $justinmimbs$date$Date$today)
							]),
						A2(
							$author$project$QuotationOld$Model$updateFormStep,
							$author$project$QuotationOld$Model$FormStep$intToFormStep(
								$author$project$QuotationOld$Model$FormStep$Step(step)),
							A2(
								$author$project$QuotationOld$Update$Helper$updateUserEmail,
								user,
								A2($author$project$QuotationOld$Update$Helper$updateUserType, tokenType, model)))));
			case 17:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(model));
			case 18:
				var bool = msg.b;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							$author$project$QuotationOld$Model$FormData$togglePrivacyAccordion(bool),
							model)));
			case 19:
				var today = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Model$updateFormData,
							$author$project$QuotationOld$Model$FormData$initBirthdayWithDate(today),
							A2(
								$author$project$QuotationOld$Model$updateFormData,
								$author$project$QuotationOld$Model$FormData$updateCurrentDate(today),
								model))));
			case 20:
				var toolTip = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2(
							$author$project$QuotationOld$Update$Helper$updateOpenToolTip,
							A2($author$project$QuotationOld$Update$Helper$toggleToolTip, toolTip, model.ks),
							model)));
			case 21:
				var quoteId = msg.a;
				var saveId = msg.b;
				return A3(
					$author$project$QuotationOld$Update$Helper$withQuoteAndSaveQuery,
					quoteId,
					saveId,
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(
							A2(
								$author$project$QuotationOld$Model$updateFormStep,
								$author$project$QuotationOld$Model$FormStep$PropertyAddressStep(1),
								A2(
									$author$project$QuotationOld$Model$updateOriginalSaveId,
									saveId,
									A2($author$project$QuotationOld$Model$updateOriginalQuoteId, quoteId, model))))));
			case 22:
				var quoteQueryResponse = msg.a;
				return A2($author$project$QuotationOld$Update$Helper$fillQuoteData, quoteQueryResponse, model);
			case 23:
				var saveQueryResponse = msg.a;
				return A2($author$project$QuotationOld$Update$Helper$fillSaveData, saveQueryResponse, model);
			case 24:
				var selector = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$Utils$withCommands,
						_List_fromArray(
							[
								$author$project$Ports$scrollToElement(selector)
							]),
						model));
			case 25:
				return $author$project$QuotationOld$Update$Helper$pushAllToDataLayer(
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			default:
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					$author$project$Utils$withoutCommands(
						A2($author$project$QuotationOld$Model$updateHighlightedDiscountMessageVisible, false, model)));
		}
	});
var $author$project$ThankYou$Model$DownloadStarted = 0;
var $author$project$ThankYou$Model$DownloadedInsuranceContract = function (a) {
	return {$: 7, a: a};
};
var $author$project$ThankYou$Update$Helper$authHeaders = function (_v0) {
	var token = _v0.p1;
	return _List_fromArray(
		[
			A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
		]);
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $author$project$Utils$Attachment$getContentType = function (_v0) {
	var headers = _v0.nH;
	return A2($elm$core$Dict$get, 'content-type', headers);
};
var $author$project$Utils$Attachment$filterMapFilename = function (headerValue) {
	if (((headerValue.b && (headerValue.a === 'filename')) && headerValue.b.b) && (!headerValue.b.b.b)) {
		var _v1 = headerValue.b;
		var value = _v1.a;
		return $elm$core$Maybe$Just(value);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Utils$Attachment$parseContentDisposition = function (contentDisposition) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filterMap,
			$author$project$Utils$Attachment$filterMapFilename,
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$trim,
					$elm$core$String$split('=')),
				A2($elm$core$String$split, ';', contentDisposition))));
};
var $author$project$Utils$Attachment$getFilename = function (_v0) {
	var headers = _v0.nH;
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Utils$Attachment$parseContentDisposition,
		A2($elm$core$Dict$get, 'content-disposition', headers));
};
var $author$project$Utils$Attachment$resolveAttachment = function (response) {
	switch (response.$) {
		case 0:
			var url = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadUrl(url));
		case 1:
			return $elm$core$Result$Err($elm$http$Http$Timeout);
		case 2:
			return $elm$core$Result$Err($elm$http$Http$NetworkError);
		case 3:
			var metadata = response.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadStatus(metadata.pL));
		default:
			var metadata = response.a;
			var body = response.b;
			return $elm$core$Result$Ok(
				{
					mQ: body,
					oj: $author$project$Utils$Attachment$getContentType(metadata),
					oq: $author$project$Utils$Attachment$getFilename(metadata)
				});
	}
};
var $author$project$ThankYou$Update$Helper$downloadFile = F2(
	function (url, model) {
		var tokenConfig = model.p2;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{iW: 0}),
			$elm$http$Http$request(
				{
					mo: $elm$http$Http$emptyBody,
					nk: A2($elm$http$Http$expectBytesResponse, $author$project$ThankYou$Model$DownloadedInsuranceContract, $author$project$Utils$Attachment$resolveAttachment),
					nH: A3($elm_community$maybe_extra$Maybe$Extra$unwrap, _List_Nil, $author$project$ThankYou$Update$Helper$authHeaders, tokenConfig),
					oi: 'GET',
					pW: $elm$core$Maybe$Nothing,
					p4: $elm$core$Maybe$Nothing,
					lO: url
				}));
	});
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $author$project$ThankYou$Update$Helper$handleAttachmentResult = F2(
	function (attachmentResult, model) {
		if (!attachmentResult.$) {
			var name = attachmentResult.a.oq;
			var mimeType = attachmentResult.a.oj;
			var content = attachmentResult.a.mQ;
			return _Utils_Tuple2(
				model,
				A3(
					$elm$file$File$Download$bytes,
					A2($elm$core$Maybe$withDefault, 'Contratto polizza.pdf', name),
					A2($elm$core$Maybe$withDefault, 'application/pdf', mimeType),
					content));
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$ThankYou$Model$getInsuranceFromInsuranceResponse = function (insuranceResponse) {
	if (!insuranceResponse.$) {
		var insurance = insuranceResponse.a;
		return $elm$core$Maybe$Just(insurance);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$ThankYou$Update$Helper$pushFormDataToDataLayer = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var updateReturn = _v0.c;
	var maybeSourceInfo = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.pI;
		},
		A2(
			$elm$core$Maybe$andThen,
			$author$project$ThankYou$Model$getInsuranceFromInsuranceResponse,
			$krisajenkins$remotedata$RemoteData$toMaybe(model.jB)));
	var dlCmd = $author$project$Utils$GTM$pushFormDataToDataLayerWithDefaultEvent(
		A2(
			$elm$core$List$cons,
			A3(
				$author$project$Utils$GTM$generateGTMTupleWithDefault,
				'PID',
				'',
				A2($elm$core$Maybe$map, $danyx23$elm_uuid$Uuid$toString, model.jA)),
			_Utils_ap(
				A3(
					$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
					'Iplatform',
					'',
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.ha;
						},
						maybeSourceInfo)),
				_Utils_ap(
					A3(
						$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
						'Ioperator',
						'',
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.g0;
							},
							maybeSourceInfo)),
					A3(
						$author$project$Utils$GTM$generateGTMTupleInListWithDefault,
						'Ireferral',
						'',
						A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.hj;
							},
							maybeSourceInfo))))));
	return _Utils_Tuple3(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[dlCmd, cmd])),
		updateReturn);
};
var $author$project$ThankYou$Update$Helper$updateDownloadStatus = F2(
	function (status, model) {
		return _Utils_update(
			model,
			{iW: status});
	});
var $author$project$Checkout$Model$getContractUrlFromDocuments = function (_v0) {
	var documents = _v0.iV;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.lO;
		},
		A2(
			$elm_community$list_extra$List$Extra$find,
			function (doc) {
				return !doc.iS;
			},
			documents));
};
var $author$project$ThankYou$Update$Helper$updateInsuranceResponse = F2(
	function (insuranceResponse, model) {
		return _Utils_update(
			model,
			{jB: insuranceResponse});
	});
var $author$project$ThankYou$Update$Helper$updateFromInsuranceResponse = F2(
	function (insuranceResponse, model) {
		_v0$2:
		while (true) {
			switch (insuranceResponse.$) {
				case 3:
					if (!insuranceResponse.a.$) {
						var insurance = insuranceResponse.a.a;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								_Utils_update(
									model,
									{
										fi: $elm$core$Maybe$Just(insurance.fi),
										nV: $elm$core$Maybe$Just(insurance.nV),
										jB: insuranceResponse,
										j4: $author$project$Checkout$Model$getContractUrlFromDocuments(insurance)
									})));
					} else {
						break _v0$2;
					}
				case 2:
					var err = insuranceResponse.a;
					return A2(
						$author$project$Utils$Auth$redirectNotAuthenticated,
						err,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								A2($author$project$ThankYou$Update$Helper$updateInsuranceResponse, insuranceResponse, model))));
				default:
					break _v0$2;
			}
		}
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				A2($author$project$ThankYou$Update$Helper$updateInsuranceResponse, insuranceResponse, model)));
	});
var $author$project$ThankYou$Update$Helper$updateFromOrderResponse = F2(
	function (orderResponse, model) {
		_v0$2:
		while (true) {
			switch (orderResponse.$) {
				case 3:
					if (!orderResponse.a.$) {
						var save = orderResponse.a.a.py;
						return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Utils$GTM$generateTupleAndPushWithDefaultEvent(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'FloorType',
												A2($elm$core$Maybe$andThen, $author$project$Types$Utils$floorTypeToValue, save.pd.nT.nw))
											]))
									]),
								_Utils_update(
									model,
									{
										fi: $elm$core$Maybe$Just(save.pd.nT.fi),
										g4: orderResponse
									})));
					} else {
						break _v0$2;
					}
				case 2:
					var err = orderResponse.a;
					return A2(
						$author$project$Utils$Auth$redirectNotAuthenticated,
						err,
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							$author$project$Utils$withoutCommands(
								_Utils_update(
									model,
									{g4: orderResponse}))));
				default:
					break _v0$2;
			}
		}
		return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
			$author$project$Utils$withoutCommands(
				_Utils_update(
					model,
					{g4: orderResponse})));
	});
var $author$project$ThankYou$Update$Helper$updateInsuranceId = F2(
	function (insuranceId, model) {
		return _Utils_update(
			model,
			{jA: insuranceId});
	});
var $author$project$ThankYou$Update$Helper$updateOrderId = F2(
	function (orderId, model) {
		return _Utils_update(
			model,
			{
				kw: $elm$core$Maybe$Just(orderId)
			});
	});
var $author$project$ThankYou$Update$Helper$updateTokenConfig = F2(
	function (tokenConfig, model) {
		return _Utils_update(
			model,
			{p2: tokenConfig});
	});
var $author$project$ThankYou$Model$GotInsurance = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$ThankYouSource = function (a) {
	return {$: 2, a: a};
};
var $author$project$Gql$Vianello$Query$insurance = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'insurance',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.nM,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Checkout$Api$Insurance$insuranceQuery = function (insuranceId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$InsuranceQueryResult,
		A2(
			$author$project$Gql$Vianello$Query$insurance,
			{nM: insuranceId},
			$author$project$Checkout$Api$Insurance$insuranceResponseSelection));
};
var $author$project$ThankYou$Api$Insurance$insuranceQuery = function (insuranceId) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$ThankYouSource(
			$author$project$Api$GetInsurance($author$project$ThankYou$Model$GotInsurance)),
		$author$project$Checkout$Api$Insurance$insuranceQuery(insuranceId));
};
var $author$project$ThankYou$Api$insuranceQuery = $author$project$ThankYou$Api$Insurance$insuranceQuery;
var $author$project$ThankYou$Update$Helper$withMaybeIsuranceQuery = function (_return) {
	var insuranceId = _return.a.jA;
	var insuranceResponse = _return.a.jB;
	var _v0 = _Utils_Tuple2(insuranceResponse, insuranceId);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a;
		var insuranceId_ = _v0.b.a;
		return A2(
			$author$project$Utils$UpdateReturn$withQuery,
			$author$project$ThankYou$Api$insuranceQuery(insuranceId_),
			_return);
	} else {
		return _return;
	}
};
var $author$project$Api$GetOrder = function (a) {
	return {$: 7, a: a};
};
var $author$project$ThankYou$Model$GotOrder = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$OrderQueryResult = function (a) {
	return {$: 7, a: a};
};
var $author$project$Gql$Vianello$Union$GetOrderResponse$Fragments = F2(
	function (onOrder, onOrderError) {
		return {gv: onOrder, gw: onOrderError};
	});
var $author$project$Gql$Vianello$Union$GetOrderResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Order', selections____.gv),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'OrderError', selections____.gw)
			]));
};
var $author$project$Checkout$Model$GetOrderError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Vianello$Object$OrderError$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'code', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Gql$Vianello$Object$OrderError$error = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'error', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Checkout$Api$Order$onError = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Vianello$Object$OrderError$code,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Vianello$Object$OrderError$error,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Types$GenericError)));
var $author$project$Checkout$Api$Order$getOrderErrorsSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$Order$onError,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$GetOrderError));
var $author$project$Checkout$Model$GetOrderOk = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Api$Order$getOrderOkSelection = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Checkout$Api$Order$orderSelection,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Checkout$Model$GetOrderOk));
var $author$project$Checkout$Api$Order$getOrderResultSelection = $author$project$Gql$Vianello$Union$GetOrderResponse$fragments(
	A2($author$project$Gql$Vianello$Union$GetOrderResponse$Fragments, $author$project$Checkout$Api$Order$getOrderOkSelection, $author$project$Checkout$Api$Order$getOrderErrorsSelection));
var $author$project$Gql$Vianello$Query$order = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'order',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'id',
					requiredArgs____.nM,
					A2(
						$author$project$Gql$Vianello$Scalar$unwrapEncoder,
						function ($) {
							return $.t;
						},
						$author$project$VianelloScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Checkout$Api$Order$orderQuery = function (orderId) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$author$project$Api$OrderQueryResult,
		A2(
			$author$project$Gql$Vianello$Query$order,
			{nM: orderId},
			$author$project$Checkout$Api$Order$getOrderResultSelection));
};
var $author$project$ThankYou$Api$Insurance$orderQuery = function (orderId) {
	return A2(
		$author$project$Api$buildQuery,
		$author$project$Api$ThankYouSource(
			$author$project$Api$GetOrder($author$project$ThankYou$Model$GotOrder)),
		$author$project$Checkout$Api$Order$orderQuery(orderId));
};
var $author$project$ThankYou$Api$orderQuery = $author$project$ThankYou$Api$Insurance$orderQuery;
var $author$project$ThankYou$Update$Helper$withMaybeOrderQuery = function (_return) {
	var orderId = _return.a.kw;
	var orderResponse = _return.a.g4;
	var _v0 = _Utils_Tuple2(orderResponse, orderId);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a;
		var orderId_ = _v0.b.a;
		return A2(
			$author$project$Utils$UpdateReturn$withQuery,
			$author$project$ThankYou$Api$orderQuery(orderId_),
			_return);
	} else {
		return _return;
	}
};
var $author$project$ThankYou$Update$update = F2(
	function (msg, model) {
		var flags = model.nv;
		switch (msg.$) {
			case 0:
				var orderId = msg.a;
				var insuranceId = msg.b;
				var tokenConfig = msg.c;
				return $author$project$ThankYou$Update$Helper$withMaybeOrderQuery(
					$author$project$ThankYou$Update$Helper$withMaybeIsuranceQuery(
						$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
							A2(
								$author$project$Utils$withCommands,
								_List_fromArray(
									[
										$author$project$Ports$pushUserRole(0)
									]),
								A2(
									$author$project$ThankYou$Update$Helper$updateTokenConfig,
									tokenConfig,
									A2(
										$author$project$ThankYou$Update$Helper$updateInsuranceId,
										insuranceId,
										A2($author$project$ThankYou$Update$Helper$updateOrderId, orderId, model)))))));
			case 1:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/reserved/login',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 2:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/assicurazioni-online',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 3:
				return A2(
					$author$project$Utils$UpdateReturn$withNewUrl,
					$author$project$Types$pickPrimaUrl(flags) + '/supporto-online',
					$author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
						$author$project$Utils$withoutCommands(model)));
			case 4:
				var insuranceResponse = msg.a;
				return $author$project$ThankYou$Update$Helper$pushFormDataToDataLayer(
					A2($author$project$ThankYou$Update$Helper$updateFromInsuranceResponse, insuranceResponse, model));
			case 5:
				var getorderresponse = msg.a;
				return A2($author$project$ThankYou$Update$Helper$updateFromOrderResponse, getorderresponse, model);
			case 6:
				var url = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2($author$project$ThankYou$Update$Helper$downloadFile, url, model));
			default:
				var attachment = msg.a;
				return $author$project$Utils$UpdateReturn$addDefaultUpdateReturn(
					A2(
						$author$project$ThankYou$Update$Helper$handleAttachmentResult,
						attachment,
						A2($author$project$ThankYou$Update$Helper$updateDownloadStatus, 1, model)));
		}
	});
var $author$project$RFQDTS$Http$Authenticator$JwtToken$encoder = function (_v0) {
	var token = _v0;
	return $elm$json$Json$Encode$string(token);
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$resultEncoder = function (_v0) {
	var r = _v0;
	return $elm$json$Json$Encode$int(r);
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$secretEncoder = function (_v0) {
	var s = _v0;
	return $elm$json$Json$Encode$string(s);
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$encoder = function (proofOfWork) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				's',
				$author$project$RFQDTS$Http$Authenticator$ProofOfWork$secretEncoder(proofOfWork.as)),
				_Utils_Tuple2(
				'r',
				$author$project$RFQDTS$Http$Authenticator$ProofOfWork$resultEncoder(proofOfWork.ar))
			]));
};
var $primait$prima_elm_extra$PrimaCmd$fromMsg = A2(
	$elm$core$Basics$composeL,
	$elm$core$Task$perform($elm$core$Basics$identity),
	$elm$core$Task$succeed);
var $author$project$RFQDTS$Http$Authenticator$SetItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$setItem = F2(
	function (key, serializedValue) {
		return $author$project$RFQDTS$Http$Authenticator$rfqDtsHttpAuthenticatorPort_(
			$author$project$RFQDTS$Http$Authenticator$portValueEncoder(
				$author$project$RFQDTS$Http$Authenticator$SetItem(
					{dS: serializedValue, fR: key})));
	});
var $author$project$RFQDTS$Http$Authenticator$Full = function (a) {
	return {$: 1, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$setJwtToken = F2(
	function (jwt, auth) {
		if (!auth.$) {
			var withAntiSpiderConfig = auth.a;
			return $author$project$RFQDTS$Http$Authenticator$AntiSpiderOnly(withAntiSpiderConfig);
		} else {
			var fullConfig = auth.a;
			return $author$project$RFQDTS$Http$Authenticator$Full(
				_Utils_update(
					fullConfig,
					{I: jwt}));
		}
	});
var $author$project$RFQDTS$Http$Authenticator$setProofOfWork = F2(
	function (proofOfWork, auth) {
		if (!auth.$) {
			var withAntiSpiderConfig = auth.a;
			return $author$project$RFQDTS$Http$Authenticator$AntiSpiderOnly(
				_Utils_update(
					withAntiSpiderConfig,
					{i: proofOfWork}));
		} else {
			var fullConfig = auth.a;
			return $author$project$RFQDTS$Http$Authenticator$Full(
				_Utils_update(
					fullConfig,
					{i: proofOfWork}));
		}
	});
var $author$project$RFQDTS$Http$Authenticator$update = F2(
	function (msg, authenticator) {
		switch (msg.$) {
			case 0:
				var jwtToken = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2(
						$author$project$RFQDTS$Http$Authenticator$setJwtToken,
						$elm$core$Maybe$Just(jwtToken),
						authenticator));
			case 1:
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(authenticator);
			case 2:
				var onGqlRequestListener = msg.a.aX;
				var maybeProofOfWork = msg.a.h;
				var maybeJwtToken = msg.a.v;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$primait$prima_elm_extra$PrimaCmd$fromMsg(onGqlRequestListener),
							A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$author$project$RFQDTS$Http$Authenticator$ProofOfWork$encoder,
									$author$project$RFQDTS$Http$Authenticator$setItem($author$project$RFQDTS$Http$Authenticator$ProofOfWork$sessionStorageKey)),
								maybeProofOfWork)),
							A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$author$project$RFQDTS$Http$Authenticator$JwtToken$encoder,
									$author$project$RFQDTS$Http$Authenticator$setItem($author$project$RFQDTS$Http$Authenticator$JwtToken$sessionStorageKey)),
								maybeJwtToken))
						]),
					A2(
						$author$project$RFQDTS$Http$Authenticator$setJwtToken,
						maybeJwtToken,
						A2($author$project$RFQDTS$Http$Authenticator$setProofOfWork, maybeProofOfWork, authenticator)));
			case 3:
				var proofOfWork = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2(
						$author$project$RFQDTS$Http$Authenticator$setProofOfWork,
						$elm$core$Maybe$Just(proofOfWork),
						authenticator));
			default:
				return A2($primait$prima_elm_extra$PrimaUpdate$withCmds, _List_Nil, authenticator);
		}
	});
var $author$project$Update$updateAuthenticator = F2(
	function (authenticatorMsg, model) {
		var _v0 = A2($author$project$RFQDTS$Http$Authenticator$update, authenticatorMsg, model.mf);
		var authenticator = _v0.a;
		var authenticatorCmd = _v0.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[authenticatorCmd]),
			_Utils_update(
				model,
				{mf: authenticator}));
	});
var $author$project$Model$ModalMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Routing$queryStringParameterToStringMapper = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + ('=' + value);
};
var $author$project$Routing$toQueryString = function (parametersList) {
	var queryStringResult = A2(
		$elm$core$String$join,
		'&',
		A2(
			$elm$core$List$map,
			$author$project$Routing$queryStringParameterToStringMapper,
			A2($elm$core$List$filterMap, $elm$core$Basics$identity, parametersList)));
	return $elm$core$String$isEmpty(queryStringResult) ? '' : ('?' + queryStringResult);
};
var $author$project$Routing$QueryStringParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Routing$toQuotationTypeParameter = A2(
	$elm$core$Basics$composeR,
	$author$project$Routing$quotationTypeToString,
	$elm$core$Maybe$map(
		$author$project$Routing$QueryStringParameter($author$project$Routing$quotationTypeParameterName)));
var $author$project$Routing$checkoutUrl = F3(
	function (quotationType, saveId, step) {
		return '/preventivo/' + ($danyx23$elm_uuid$Uuid$toString(saveId) + ('/pagamento/' + ($elm$core$String$fromInt(step) + $author$project$Routing$toQueryString(
			_List_fromArray(
				[
					$author$project$Routing$toQuotationTypeParameter(quotationType)
				])))));
	});
var $author$project$Routing$guaranteesUrlWithSave = F3(
	function (quotationType, quotationId, saveId) {
		return '/preventivo/' + ($danyx23$elm_uuid$Uuid$toString(quotationId) + ('/salvataggio/' + ($danyx23$elm_uuid$Uuid$toString(saveId) + ('/garanzie' + $author$project$Routing$toQueryString(
			_List_fromArray(
				[
					$author$project$Routing$toQuotationTypeParameter(quotationType)
				]))))));
	});
var $author$project$Routing$guaranteesUrlWithoutSave = F2(
	function (quotationType, quotationId) {
		return '/preventivo/' + ($danyx23$elm_uuid$Uuid$toString(quotationId) + ('/garanzie' + $author$project$Routing$toQueryString(
			_List_fromArray(
				[
					$author$project$Routing$toQuotationTypeParameter(quotationType)
				]))));
	});
var $author$project$Routing$guaranteesUrl = F3(
	function (quotationType, quotationId, maybeSaveId) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			A2($author$project$Routing$guaranteesUrlWithoutSave, quotationType, quotationId),
			A2($author$project$Routing$guaranteesUrlWithSave, quotationType, quotationId),
			maybeSaveId);
	});
var $author$project$Routing$modifyUrl = F2(
	function (maybeQuoteId, maybeSaveId) {
		var saveIdQueryParam = A2(
			$elm$core$Maybe$map,
			function (s) {
				return A2(
					$author$project$Routing$QueryStringParameter,
					$author$project$Routing$saveIdParameterName,
					$danyx23$elm_uuid$Uuid$toString(s));
			},
			maybeSaveId);
		var quoteIdQueryParam = A2(
			$elm$core$Maybe$map,
			function (q) {
				return A2(
					$author$project$Routing$QueryStringParameter,
					$author$project$Routing$quoteIdParameterName,
					$danyx23$elm_uuid$Uuid$toString(q));
			},
			maybeQuoteId);
		return '/modifica' + $author$project$Routing$toQueryString(
			_List_fromArray(
				[quoteIdQueryParam, saveIdQueryParam]));
	});
var $author$project$NotAvailable$Utils$reasonToString = function (reason) {
	switch (reason.$) {
		case 0:
			return 'uninhabited';
		case 2:
			var saveId = reason.a;
			return 'save-expired' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (uuid) {
						return '?saveId=' + $danyx23$elm_uuid$Uuid$toString(uuid);
					},
					saveId));
		case 3:
			return 'save-not-purchasable';
		case 4:
			return 'save-purchased';
		case 1:
			return 'not-owner';
		case 5:
			return 'offer-error';
		case 6:
			return 'offer-not-available';
		default:
			return 'quote-not-available';
	}
};
var $author$project$Routing$toThankYouPageQuotationTypeParameter = A2(
	$elm$core$Basics$composeR,
	$author$project$Routing$thankYouPageTypeToString,
	$elm$core$Maybe$map(
		$author$project$Routing$QueryStringParameter($author$project$Routing$quotationTypeParameterName)));
var $author$project$Routing$thankYouUrl = F3(
	function (thankYouPageType, orderId, insuranceId) {
		var thankYouPageTypeQueryParam = $author$project$Routing$toThankYouPageQuotationTypeParameter(thankYouPageType);
		var insuranceIdQueryParam = A2(
			$elm$core$Maybe$map,
			function (insuranceId_) {
				return A2(
					$author$project$Routing$QueryStringParameter,
					$author$project$Routing$insuranceIdParameterName,
					$danyx23$elm_uuid$Uuid$toString(insuranceId_));
			},
			insuranceId);
		return '/preventivo/' + ($danyx23$elm_uuid$Uuid$toString(orderId) + ('/grazie' + $author$project$Routing$toQueryString(
			_List_fromArray(
				[insuranceIdQueryParam, thankYouPageTypeQueryParam]))));
	});
var $author$project$Routing$generateUrl = function (r) {
	switch (r.$) {
		case 0:
			return '/';
		case 7:
			return '/not-found';
		case 1:
			if (!r.a) {
				var _v1 = r.a;
				var n = r.b;
				return '/preventivo-casa/' + $elm$core$String$fromInt(n);
			} else {
				var _v2 = r.a;
				var n = r.b;
				return '/preventivo-famiglia/' + $elm$core$String$fromInt(n);
			}
		case 2:
			var quotationType = r.a;
			var quoteId = r.b;
			var maybeSaveId = r.c;
			return A3($author$project$Routing$guaranteesUrl, quotationType, quoteId, maybeSaveId);
		case 3:
			var quotationType = r.a;
			var saveId = r.b;
			var step = r.c;
			return A3($author$project$Routing$checkoutUrl, quotationType, saveId, step);
		case 4:
			var thankYouPageTypeType = r.a;
			var orderId = r.b;
			var insuranceId = r.c;
			return A3($author$project$Routing$thankYouUrl, thankYouPageTypeType, orderId, insuranceId);
		case 5:
			var maybeQuoteId = r.a;
			var maybeSaveId = r.b;
			return A2($author$project$Routing$modifyUrl, maybeQuoteId, maybeSaveId);
		case 6:
			var reason = r.a;
			return '/not-available/' + $author$project$NotAvailable$Utils$reasonToString(reason);
		default:
			var offerId = r.a;
			return '/offer/' + $danyx23$elm_uuid$Uuid$toString(offerId);
	}
};
var $author$project$Utils$UpdateReturn$getAppStatus = function (_v0) {
	var status = _v0.hK;
	return status;
};
var $author$project$Utils$UpdateReturn$getLoadUrl = function (_v0) {
	var loadUrl = _v0.fT;
	return loadUrl;
};
var $author$project$Utils$UpdateReturn$getPushUrl = function (_v0) {
	var pushUrl = _v0.hg;
	return pushUrl;
};
var $author$project$Utils$UpdateReturn$getReplaceUrl = function (_v0) {
	var replaceUrl = _v0.hl;
	return replaceUrl;
};
var $author$project$Utils$UpdateReturn$getUpdateModalState = function (_v0) {
	var updateModalState = _v0.hW;
	return updateModalState;
};
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Utils$UpdateReturn$getCommand = F3(
	function (key, _v0, updateReturn) {
		return $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						$elm$browser$Browser$Navigation$pushUrl(key),
						A2(
							$elm$core$Maybe$map,
							$author$project$Routing$generateUrl,
							$author$project$Utils$UpdateReturn$getPushUrl(updateReturn)))),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						$elm$browser$Browser$Navigation$load,
						$author$project$Utils$UpdateReturn$getLoadUrl(updateReturn))),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						$elm$browser$Browser$Navigation$replaceUrl(key),
						A2(
							$elm$core$Maybe$map,
							$author$project$Routing$generateUrl,
							$author$project$Utils$UpdateReturn$getReplaceUrl(updateReturn)))),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $author$project$Model$ModalMsg, $author$project$Utils$send),
						$author$project$Utils$UpdateReturn$getUpdateModalState(updateReturn))),
					A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $author$project$Model$ChangeAppStatus, $author$project$Utils$send),
						$author$project$Utils$UpdateReturn$getAppStatus(updateReturn)))
				]));
	});
var $author$project$Utils$UpdateReturn$foldCommand = F2(
	function (operation, _v0) {
		var model = _v0.a;
		var commands = _v0.b;
		var _v1 = A2($author$project$Api$AuthClient$mapOperationToCommand, model, operation);
		var updatedModel = _v1.a;
		var command = _v1.b;
		return _Utils_Tuple2(
			updatedModel,
			A2($elm$core$List$cons, command, commands));
	});
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$ConsumerRequestError = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$withXAuthClientSecretHeader = function (_v0) {
	var s = _v0;
	return A2($dillonkearns$elm_graphql$Graphql$Http$withHeader, 'x-auth-client-secret', s);
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$withXAuthMasResultHeader = function (_v0) {
	var r = _v0;
	return A2(
		$dillonkearns$elm_graphql$Graphql$Http$withHeader,
		'x-auth-mas-result',
		$elm$core$String$fromInt(r));
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$addHeaders = function (proofOfWork) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$RFQDTS$Http$Authenticator$ProofOfWork$withXAuthClientSecretHeader(proofOfWork.as),
		$author$project$RFQDTS$Http$Authenticator$ProofOfWork$withXAuthMasResultHeader(proofOfWork.ar));
};
var $elm$core$Task$fail = _Scheduler_fail;
var $elm$core$Task$mapError = F2(
	function (convert, task) {
		return A2(
			$elm$core$Task$onError,
			A2($elm$core$Basics$composeL, $elm$core$Task$fail, convert),
			task);
	});
var $dillonkearns$elm_graphql$Graphql$Http$failTaskOnHttpSuccessWithErrors = function (successOrError) {
	if (!successOrError.$) {
		var value = successOrError.a;
		return $elm$core$Task$succeed(value);
	} else {
		var _v1 = successOrError.a;
		var possiblyParsedData = _v1.a;
		var graphqlErrorGraphqlErrorList = _v1.b;
		return $elm$core$Task$fail(
			A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, graphqlErrorGraphqlErrorList));
	}
};
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $dillonkearns$elm_graphql$Graphql$Http$jsonResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (response) {
			switch (response.$) {
				case 0:
					var url = response.a;
					return $elm$core$Result$Err(
						$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
				case 1:
					return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
				case 3:
					var metadata = response.a;
					var body = response.b;
					return $elm$core$Result$Err(
						A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
				default:
					var metadata = response.a;
					var body = response.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						var err = _v1.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
					}
			}
		});
};
var $dillonkearns$elm_graphql$Graphql$Http$resolver = function (request) {
	return $dillonkearns$elm_graphql$Graphql$Http$jsonResolver(
		$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(request).m0);
};
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$riskyTask = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{mb: true, mo: r.mo, nk: r.pr, nH: r.nH, oi: r.oi, pW: r.pW, p4: $elm$core$Maybe$Nothing, lO: r.lO});
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{mb: false, mo: r.mo, nk: r.pr, nH: r.nH, oi: r.oi, pW: r.pW, p4: $elm$core$Maybe$Nothing, lO: r.lO});
};
var $dillonkearns$elm_graphql$Graphql$Http$toTask = function (fullRequest) {
	var request = fullRequest;
	return A2(
		$elm$core$Task$andThen,
		$dillonkearns$elm_graphql$Graphql$Http$failTaskOnHttpSuccessWithErrors,
		A2(
			$elm$core$Task$mapError,
			$dillonkearns$elm_graphql$Graphql$Http$HttpError,
			function (readyRequest) {
				return (request.a7 ? $elm$http$Http$riskyTask : $elm$http$Http$task)(
					{
						mo: readyRequest.mo,
						nH: readyRequest.nH,
						oi: readyRequest.oi,
						pr: $dillonkearns$elm_graphql$Graphql$Http$resolver(fullRequest),
						pW: readyRequest.pW,
						lO: readyRequest.lO
					});
			}(
				$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest))));
};
var $author$project$RFQDTS$Http$Authenticator$withXAuthClientIdHeader = function (clientId) {
	return A2($dillonkearns$elm_graphql$Graphql$Http$withHeader, 'x-auth-client-id', clientId);
};
var $author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyRequestTask = F2(
	function (conf, proofOfWork) {
		return A2(
			$elm$core$Task$andThen,
			function (retType) {
				return $elm$core$Task$succeed(
					{i: proofOfWork, bD: retType});
			},
			A2(
				$elm$core$Task$mapError,
				function (err) {
					return {
						b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$ConsumerRequestError(err),
						h: $elm$core$Maybe$Just(proofOfWork)
					};
				},
				$dillonkearns$elm_graphql$Graphql$Http$toTask(
					A2(
						$author$project$RFQDTS$Http$Authenticator$ProofOfWork$addHeaders,
						proofOfWork,
						A2($author$project$RFQDTS$Http$Authenticator$withXAuthClientIdHeader, conf.mD, conf.eg)))));
	});
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$encodeChallengeRequest = function (clientId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'client_id',
				$elm$json$Json$Encode$string(clientId))
			]));
};
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError = function (a) {
	return {$: 3, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$Challenge = F3(
	function (salt, secret, target) {
		return {cz: salt, cA: secret, pT: target};
	});
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$Salt = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$Target = $elm$core$Basics$identity;
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$challengeDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$RFQDTS$Http$Authenticator$ProofOfWork$Challenge,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['c', 's']),
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		's',
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['c', 't']),
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string)));
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$fromStringToChallengeResult = function (response) {
	switch (response.$) {
		case 3:
			var meta = response.a;
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError(
					$elm$http$Http$BadStatus(meta.pL)));
		case 0:
			var string = response.a;
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError(
					$elm$http$Http$BadUrl(string)));
		case 4:
			var body = response.b;
			var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$RFQDTS$Http$Authenticator$ProofOfWork$challengeDecoder, body);
			if (_v1.$ === 1) {
				var parserError = _v1.a;
				return $elm$core$Result$Err(
					$author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError(
						$elm$http$Http$BadBody(
							'Proof of work parser error: ' + $elm$json$Json$Decode$errorToString(parserError))));
			} else {
				var challenge = _v1.a;
				return $elm$core$Result$Ok(challenge);
			}
		case 2:
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError($elm$http$Http$NetworkError));
		default:
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$ProofOfWorkRequestError($elm$http$Http$Timeout));
	}
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$fetchChallengeTask = F2(
	function (url, clientId) {
		return $elm$http$Http$task(
			{
				mo: $elm$http$Http$jsonBody(
					$author$project$RFQDTS$Http$Authenticator$ProofOfWork$encodeChallengeRequest(clientId)),
				nH: _List_fromArray(
					[
						A2($elm$http$Http$header, 'x-auth-client-id', clientId)
					]),
				oi: 'POST',
				pr: $elm$http$Http$stringResolver($author$project$RFQDTS$Http$Authenticator$ProofOfWork$fromStringToChallengeResult),
				pW: $elm$core$Maybe$Nothing,
				lO: url
			});
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $truqu$elm_md5$MD5$emptyWords = A2($elm$core$Array$repeat, 16, 0);
var $truqu$elm_md5$MD5$addUnsigned = F2(
	function (x, y) {
		return 4294967295 & (x + y);
	});
var $truqu$elm_md5$MD5$rotateLeft = F2(
	function (bits, input) {
		return (input << bits) | (input >>> (32 - bits));
	});
var $truqu$elm_md5$MD5$cmn = F8(
	function (fun, a, b, c, d, x, s, ac) {
		return A2(
			$truqu$elm_md5$MD5$addUnsigned,
			b,
			A2(
				$truqu$elm_md5$MD5$rotateLeft,
				s,
				A2(
					$truqu$elm_md5$MD5$addUnsigned,
					a,
					A2(
						$truqu$elm_md5$MD5$addUnsigned,
						ac,
						A2(
							$truqu$elm_md5$MD5$addUnsigned,
							A3(fun, b, c, d),
							x)))));
	});
var $truqu$elm_md5$MD5$f = F3(
	function (x, y, z) {
		return z ^ (x & (y ^ z));
	});
var $truqu$elm_md5$MD5$ff = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$f, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$g = F3(
	function (x, y, z) {
		return y ^ (z & (x ^ y));
	});
var $truqu$elm_md5$MD5$gg = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$g, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$h = F3(
	function (x, y, z) {
		return z ^ (x ^ y);
	});
var $truqu$elm_md5$MD5$hh = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$h, a, b, c, d, x, s, ac);
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $truqu$elm_md5$MD5$i = F3(
	function (x, y, z) {
		return y ^ (x | (~z));
	});
var $truqu$elm_md5$MD5$ii = F7(
	function (a, b, c, d, x, s, ac) {
		return A8($truqu$elm_md5$MD5$cmn, $truqu$elm_md5$MD5$i, a, b, c, d, x, s, ac);
	});
var $truqu$elm_md5$MD5$hex_ = F2(
	function (xs, acc) {
		var a = acc.cU;
		var b = acc.c1;
		var c = acc.c4;
		var d = acc.dh;
		if ((((((((((((((((xs.b && xs.b.b) && xs.b.b.b) && xs.b.b.b.b) && xs.b.b.b.b.b) && xs.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b) && (!xs.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b.b)) {
			var x0 = xs.a;
			var _v1 = xs.b;
			var x1 = _v1.a;
			var _v2 = _v1.b;
			var x2 = _v2.a;
			var _v3 = _v2.b;
			var x3 = _v3.a;
			var _v4 = _v3.b;
			var x4 = _v4.a;
			var _v5 = _v4.b;
			var x5 = _v5.a;
			var _v6 = _v5.b;
			var x6 = _v6.a;
			var _v7 = _v6.b;
			var x7 = _v7.a;
			var _v8 = _v7.b;
			var x8 = _v8.a;
			var _v9 = _v8.b;
			var x9 = _v9.a;
			var _v10 = _v9.b;
			var x10 = _v10.a;
			var _v11 = _v10.b;
			var x11 = _v11.a;
			var _v12 = _v11.b;
			var x12 = _v12.a;
			var _v13 = _v12.b;
			var x13 = _v13.a;
			var _v14 = _v13.b;
			var x14 = _v14.a;
			var _v15 = _v14.b;
			var x15 = _v15.a;
			var s44 = 21;
			var s43 = 15;
			var s42 = 10;
			var s41 = 6;
			var s34 = 23;
			var s33 = 16;
			var s32 = 11;
			var s31 = 4;
			var s24 = 20;
			var s23 = 14;
			var s22 = 9;
			var s21 = 5;
			var s14 = 22;
			var s13 = 17;
			var s12 = 12;
			var s11 = 7;
			var d00 = d;
			var c00 = c;
			var b00 = b;
			var a00 = a;
			var a01 = A7($truqu$elm_md5$MD5$ff, a00, b00, c00, d00, x0, s11, 3614090360);
			var d01 = A7($truqu$elm_md5$MD5$ff, d00, a01, b00, c00, x1, s12, 3905402710);
			var c01 = A7($truqu$elm_md5$MD5$ff, c00, d01, a01, b00, x2, s13, 606105819);
			var b01 = A7($truqu$elm_md5$MD5$ff, b00, c01, d01, a01, x3, s14, 3250441966);
			var a02 = A7($truqu$elm_md5$MD5$ff, a01, b01, c01, d01, x4, s11, 4118548399);
			var d02 = A7($truqu$elm_md5$MD5$ff, d01, a02, b01, c01, x5, s12, 1200080426);
			var c02 = A7($truqu$elm_md5$MD5$ff, c01, d02, a02, b01, x6, s13, 2821735955);
			var b02 = A7($truqu$elm_md5$MD5$ff, b01, c02, d02, a02, x7, s14, 4249261313);
			var a03 = A7($truqu$elm_md5$MD5$ff, a02, b02, c02, d02, x8, s11, 1770035416);
			var d03 = A7($truqu$elm_md5$MD5$ff, d02, a03, b02, c02, x9, s12, 2336552879);
			var c03 = A7($truqu$elm_md5$MD5$ff, c02, d03, a03, b02, x10, s13, 4294925233);
			var b03 = A7($truqu$elm_md5$MD5$ff, b02, c03, d03, a03, x11, s14, 2304563134);
			var a04 = A7($truqu$elm_md5$MD5$ff, a03, b03, c03, d03, x12, s11, 1804603682);
			var d04 = A7($truqu$elm_md5$MD5$ff, d03, a04, b03, c03, x13, s12, 4254626195);
			var c04 = A7($truqu$elm_md5$MD5$ff, c03, d04, a04, b03, x14, s13, 2792965006);
			var b04 = A7($truqu$elm_md5$MD5$ff, b03, c04, d04, a04, x15, s14, 1236535329);
			var a05 = A7($truqu$elm_md5$MD5$gg, a04, b04, c04, d04, x1, s21, 4129170786);
			var d05 = A7($truqu$elm_md5$MD5$gg, d04, a05, b04, c04, x6, s22, 3225465664);
			var c05 = A7($truqu$elm_md5$MD5$gg, c04, d05, a05, b04, x11, s23, 643717713);
			var b05 = A7($truqu$elm_md5$MD5$gg, b04, c05, d05, a05, x0, s24, 3921069994);
			var a06 = A7($truqu$elm_md5$MD5$gg, a05, b05, c05, d05, x5, s21, 3593408605);
			var d06 = A7($truqu$elm_md5$MD5$gg, d05, a06, b05, c05, x10, s22, 38016083);
			var c06 = A7($truqu$elm_md5$MD5$gg, c05, d06, a06, b05, x15, s23, 3634488961);
			var b06 = A7($truqu$elm_md5$MD5$gg, b05, c06, d06, a06, x4, s24, 3889429448);
			var a07 = A7($truqu$elm_md5$MD5$gg, a06, b06, c06, d06, x9, s21, 568446438);
			var d07 = A7($truqu$elm_md5$MD5$gg, d06, a07, b06, c06, x14, s22, 3275163606);
			var c07 = A7($truqu$elm_md5$MD5$gg, c06, d07, a07, b06, x3, s23, 4107603335);
			var b07 = A7($truqu$elm_md5$MD5$gg, b06, c07, d07, a07, x8, s24, 1163531501);
			var a08 = A7($truqu$elm_md5$MD5$gg, a07, b07, c07, d07, x13, s21, 2850285829);
			var d08 = A7($truqu$elm_md5$MD5$gg, d07, a08, b07, c07, x2, s22, 4243563512);
			var c08 = A7($truqu$elm_md5$MD5$gg, c07, d08, a08, b07, x7, s23, 1735328473);
			var b08 = A7($truqu$elm_md5$MD5$gg, b07, c08, d08, a08, x12, s24, 2368359562);
			var a09 = A7($truqu$elm_md5$MD5$hh, a08, b08, c08, d08, x5, s31, 4294588738);
			var d09 = A7($truqu$elm_md5$MD5$hh, d08, a09, b08, c08, x8, s32, 2272392833);
			var c09 = A7($truqu$elm_md5$MD5$hh, c08, d09, a09, b08, x11, s33, 1839030562);
			var b09 = A7($truqu$elm_md5$MD5$hh, b08, c09, d09, a09, x14, s34, 4259657740);
			var a10 = A7($truqu$elm_md5$MD5$hh, a09, b09, c09, d09, x1, s31, 2763975236);
			var d10 = A7($truqu$elm_md5$MD5$hh, d09, a10, b09, c09, x4, s32, 1272893353);
			var c10 = A7($truqu$elm_md5$MD5$hh, c09, d10, a10, b09, x7, s33, 4139469664);
			var b10 = A7($truqu$elm_md5$MD5$hh, b09, c10, d10, a10, x10, s34, 3200236656);
			var a11 = A7($truqu$elm_md5$MD5$hh, a10, b10, c10, d10, x13, s31, 681279174);
			var d11 = A7($truqu$elm_md5$MD5$hh, d10, a11, b10, c10, x0, s32, 3936430074);
			var c11 = A7($truqu$elm_md5$MD5$hh, c10, d11, a11, b10, x3, s33, 3572445317);
			var b11 = A7($truqu$elm_md5$MD5$hh, b10, c11, d11, a11, x6, s34, 76029189);
			var a12 = A7($truqu$elm_md5$MD5$hh, a11, b11, c11, d11, x9, s31, 3654602809);
			var d12 = A7($truqu$elm_md5$MD5$hh, d11, a12, b11, c11, x12, s32, 3873151461);
			var c12 = A7($truqu$elm_md5$MD5$hh, c11, d12, a12, b11, x15, s33, 530742520);
			var b12 = A7($truqu$elm_md5$MD5$hh, b11, c12, d12, a12, x2, s34, 3299628645);
			var a13 = A7($truqu$elm_md5$MD5$ii, a12, b12, c12, d12, x0, s41, 4096336452);
			var d13 = A7($truqu$elm_md5$MD5$ii, d12, a13, b12, c12, x7, s42, 1126891415);
			var c13 = A7($truqu$elm_md5$MD5$ii, c12, d13, a13, b12, x14, s43, 2878612391);
			var b13 = A7($truqu$elm_md5$MD5$ii, b12, c13, d13, a13, x5, s44, 4237533241);
			var a14 = A7($truqu$elm_md5$MD5$ii, a13, b13, c13, d13, x12, s41, 1700485571);
			var d14 = A7($truqu$elm_md5$MD5$ii, d13, a14, b13, c13, x3, s42, 2399980690);
			var c14 = A7($truqu$elm_md5$MD5$ii, c13, d14, a14, b13, x10, s43, 4293915773);
			var b14 = A7($truqu$elm_md5$MD5$ii, b13, c14, d14, a14, x1, s44, 2240044497);
			var a15 = A7($truqu$elm_md5$MD5$ii, a14, b14, c14, d14, x8, s41, 1873313359);
			var d15 = A7($truqu$elm_md5$MD5$ii, d14, a15, b14, c14, x15, s42, 4264355552);
			var c15 = A7($truqu$elm_md5$MD5$ii, c14, d15, a15, b14, x6, s43, 2734768916);
			var b15 = A7($truqu$elm_md5$MD5$ii, b14, c15, d15, a15, x13, s44, 1309151649);
			var a16 = A7($truqu$elm_md5$MD5$ii, a15, b15, c15, d15, x4, s41, 4149444226);
			var d16 = A7($truqu$elm_md5$MD5$ii, d15, a16, b15, c15, x11, s42, 3174756917);
			var c16 = A7($truqu$elm_md5$MD5$ii, c15, d16, a16, b15, x2, s43, 718787259);
			var b16 = A7($truqu$elm_md5$MD5$ii, b15, c16, d16, a16, x9, s44, 3951481745);
			var b17 = A2($truqu$elm_md5$MD5$addUnsigned, b00, b16);
			var c17 = A2($truqu$elm_md5$MD5$addUnsigned, c00, c16);
			var d17 = A2($truqu$elm_md5$MD5$addUnsigned, d00, d16);
			var a17 = A2($truqu$elm_md5$MD5$addUnsigned, a00, a16);
			return {cU: a17, c1: b17, c4: c17, dh: d17};
		} else {
			return acc;
		}
	});
var $truqu$elm_md5$MD5$iget = F2(
	function (index, array) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Array$get, index, array));
	});
var $truqu$elm_md5$MD5$consume = F2(
	function (_char, _v0) {
		var hashState = _v0.a;
		var _v1 = _v0.b;
		var byteCount = _v1.a;
		var words = _v1.b;
		var totalByteCount = _v0.c;
		var wordCount = (byteCount / 4) | 0;
		var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
		var bytePosition = 8 * (byteCount % 4);
		var code = _char << bytePosition;
		var newWord = oldWord | code;
		var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
		return (byteCount === 63) ? _Utils_Tuple3(
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState),
			_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
			totalByteCount + 1) : _Utils_Tuple3(
			hashState,
			_Utils_Tuple2(byteCount + 1, newWords),
			totalByteCount + 1);
	});
var $truqu$elm_md5$MD5$finishUp = function (_v0) {
	var hashState = _v0.a;
	var _v1 = _v0.b;
	var byteCount = _v1.a;
	var words = _v1.b;
	var totalByteCount = _v0.c;
	var wordCount = (byteCount / 4) | 0;
	var oldWord = A2($truqu$elm_md5$MD5$iget, wordCount, words);
	var bytePosition = 8 * (byteCount % 4);
	var code = 128 << bytePosition;
	var newWord = oldWord | code;
	var newWords = A3($elm$core$Array$set, wordCount, newWord, words);
	return (wordCount < 14) ? function (x) {
		return A2($truqu$elm_md5$MD5$hex_, x, hashState);
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, newWords)))) : function (x) {
		return A2(
			$truqu$elm_md5$MD5$hex_,
			x,
			A2(
				$truqu$elm_md5$MD5$hex_,
				$elm$core$Array$toList(newWords),
				hashState));
	}(
		$elm$core$Array$toList(
			A3(
				$elm$core$Array$set,
				15,
				totalByteCount >>> 29,
				A3($elm$core$Array$set, 14, totalByteCount << 3, $truqu$elm_md5$MD5$emptyWords))));
};
var $zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8 = F3(
	function (add, _char, acc) {
		return (_char < 128) ? A2(add, _char, acc) : ((_char < 2048) ? A2(
			add,
			128 | (63 & _char),
			A2(add, 192 | (_char >>> 6), acc)) : ((_char < 65536) ? A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(add, 224 | (_char >>> 12), acc))) : A2(
			add,
			128 | (63 & _char),
			A2(
				add,
				128 | (63 & (_char >>> 6)),
				A2(
					add,
					128 | (63 & (_char >>> 12)),
					A2(add, 240 | (_char >>> 18), acc))))));
	});
var $zwilias$elm_utf_tools$String$UTF8$foldl = F3(
	function (op, initialAcc, input) {
		return A3(
			$elm$core$String$foldl,
			F2(
				function (_char, acc) {
					return A3(
						$zwilias$elm_utf_tools$String$UTF8$utf32ToUtf8,
						op,
						$elm$core$Char$toCode(_char),
						acc);
				}),
			initialAcc,
			input);
	});
var $truqu$elm_md5$MD5$State = F4(
	function (a, b, c, d) {
		return {cU: a, c1: b, c4: c, dh: d};
	});
var $truqu$elm_md5$MD5$initialHashState = A4($truqu$elm_md5$MD5$State, 1732584193, 4023233417, 2562383102, 271733878);
var $truqu$elm_md5$MD5$hash = function (input) {
	return $truqu$elm_md5$MD5$finishUp(
		A3(
			$zwilias$elm_utf_tools$String$UTF8$foldl,
			$truqu$elm_md5$MD5$consume,
			_Utils_Tuple3(
				$truqu$elm_md5$MD5$initialHashState,
				_Utils_Tuple2(0, $truqu$elm_md5$MD5$emptyWords),
				0),
			input));
};
var $truqu$elm_md5$MD5$bytes = function (string) {
	var _v0 = $truqu$elm_md5$MD5$hash(string);
	var a = _v0.cU;
	var b = _v0.c1;
	var c = _v0.c4;
	var d = _v0.dh;
	return _List_fromArray(
		[a & 255, (a >>> 8) & 255, (a >>> 16) & 255, (a >>> 24) & 255, b & 255, (b >>> 8) & 255, (b >>> 16) & 255, (b >>> 24) & 255, c & 255, (c >>> 8) & 255, (c >>> 16) & 255, (c >>> 24) & 255, d & 255, (d >>> 8) & 255, (d >>> 16) & 255, (d >>> 24) & 255]);
};
var $truqu$elm_md5$MD5$toHex = function (_byte) {
	switch (_byte) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return 'a';
		case 11:
			return 'b';
		case 12:
			return 'c';
		case 13:
			return 'd';
		case 14:
			return 'e';
		case 15:
			return 'f';
		default:
			return _Utils_ap(
				$truqu$elm_md5$MD5$toHex((_byte / 16) | 0),
				$truqu$elm_md5$MD5$toHex(_byte % 16));
	}
};
var $truqu$elm_md5$MD5$hex = function (s) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (b, acc) {
				return _Utils_ap(
					acc,
					A3(
						$elm$core$String$padLeft,
						2,
						'0',
						$truqu$elm_md5$MD5$toHex(b)));
			}),
		'',
		$truqu$elm_md5$MD5$bytes(s));
};
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$solutionFound = F3(
	function (random, _v0, _v1) {
		var target = _v0;
		var salt = _v1;
		return A2(
			$elm$core$String$startsWith,
			target,
			$truqu$elm_md5$MD5$hex(
				_Utils_ap(
					$elm$core$String$fromInt(random),
					salt)));
	});
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$resolve = F3(
	function (randomGenerator, seed, newChallenge) {
		resolve:
		while (true) {
			var _v0 = A2($elm$random$Random$step, randomGenerator, seed);
			var random = _v0.a;
			var nextSeed = _v0.b;
			if (A3($author$project$RFQDTS$Http$Authenticator$ProofOfWork$solutionFound, random, newChallenge.pT, newChallenge.cz)) {
				return {ar: random, as: newChallenge.cA};
			} else {
				var $temp$randomGenerator = randomGenerator,
					$temp$seed = nextSeed,
					$temp$newChallenge = newChallenge;
				randomGenerator = $temp$randomGenerator;
				seed = $temp$seed;
				newChallenge = $temp$newChallenge;
				continue resolve;
			}
		}
	});
var $author$project$RFQDTS$Http$Authenticator$ProofOfWork$fetchTask = F2(
	function (url, clientId) {
		return A3(
			$elm$core$Task$map2,
			F2(
				function (time, challenge) {
					return A3(
						$author$project$RFQDTS$Http$Authenticator$ProofOfWork$resolve,
						A2($elm$random$Random$int, 0, $elm$random$Random$maxInt),
						$elm$random$Random$initialSeed(
							$elm$time$Time$posixToMillis(time)),
						challenge);
				}),
			$elm$time$Time$now,
			A2($author$project$RFQDTS$Http$Authenticator$ProofOfWork$fetchChallengeTask, url, clientId));
	});
var $author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyAuthRequestTask = F2(
	function (gqlRequest, conf) {
		return A2(
			$elm$core$Task$andThen,
			$author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyRequestTask(
				{mD: conf.mD, eg: gqlRequest}),
			A2(
				$elm$core$Maybe$withDefault,
				A2(
					$elm$core$Task$mapError,
					function (proofOfWorkError) {
						return {b0: proofOfWorkError, h: $elm$core$Maybe$Nothing};
					},
					A2($author$project$RFQDTS$Http$Authenticator$ProofOfWork$fetchTask, conf.mu, conf.mD)),
				A2($elm$core$Maybe$map, $elm$core$Task$succeed, conf.i)));
	});
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$Authorized = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$OnTaskSequenceFinished = function (a) {
	return {$: 2, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$Authorized = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$GqlRequestError = function (a) {
	return {$: 0, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$JWTTokenError = function (a) {
	return {$: 1, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$ProofOfWorkError = function (a) {
	return {$: 2, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$UnauthorizedError = {$: 3};
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$fromInternalResponse = function (response) {
	if (!response.$) {
		var retType = response.a;
		return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Authorized(retType);
	} else {
		switch (response.a.$) {
			case 1:
				var _v1 = response.a;
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure($author$project$RFQDTS$Http$Authenticator$AuthResponse$UnauthorizedError);
			case 0:
				var graphqlError = response.a.a;
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
					$author$project$RFQDTS$Http$Authenticator$AuthResponse$GqlRequestError(graphqlError));
			case 3:
				var httpError = response.a.a;
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
					$author$project$RFQDTS$Http$Authenticator$AuthResponse$ProofOfWorkError(httpError));
			default:
				var httpError = response.a.a;
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
					$author$project$RFQDTS$Http$Authenticator$AuthResponse$JWTTokenError(httpError));
		}
	}
};
var $author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyTaskResultToMsg = F3(
	function (authResponseTag, internalMsgTagger, result) {
		if (result.$ === 1) {
			var error = result.a.b0;
			var maybeProofOfWork = result.a.h;
			return internalMsgTagger(
				$author$project$RFQDTS$Http$Authenticator$OnTaskSequenceFinished(
					{
						v: $elm$core$Maybe$Nothing,
						h: maybeProofOfWork,
						aX: authResponseTag(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$fromInternalResponse(
								$author$project$RFQDTS$Http$Authenticator$InternalResponse$Failure(error)))
					}));
		} else {
			var retType = result.a.bD;
			var proofOfWork = result.a.i;
			return internalMsgTagger(
				$author$project$RFQDTS$Http$Authenticator$OnTaskSequenceFinished(
					{
						v: $elm$core$Maybe$Nothing,
						h: $elm$core$Maybe$Just(proofOfWork),
						aX: authResponseTag(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$fromInternalResponse(
								$author$project$RFQDTS$Http$Authenticator$InternalResponse$Authorized(retType)))
					}));
		}
	});
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError = function (a) {
	return {$: 2, a: a};
};
var $author$project$RFQDTS$Http$Authenticator$JwtToken$decoderForAPI = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$identity,
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string));
var $author$project$RFQDTS$Http$Authenticator$JwtToken$decodeStringBody = function (response) {
	switch (response.$) {
		case 3:
			var meta = response.a;
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError(
					$elm$http$Http$BadStatus(meta.pL)));
		case 0:
			var string = response.a;
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError(
					$elm$http$Http$BadUrl(string)));
		case 4:
			var body = response.b;
			var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$RFQDTS$Http$Authenticator$JwtToken$decoderForAPI, body);
			if (_v1.$ === 1) {
				var parserError = _v1.a;
				return $elm$core$Result$Err(
					$author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError(
						$elm$http$Http$BadBody(
							'JWT Token parser error: ' + $elm$json$Json$Decode$errorToString(parserError))));
			} else {
				var jwt = _v1.a;
				return $elm$core$Result$Ok(jwt);
			}
		case 2:
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError($elm$http$Http$NetworkError));
		default:
			return $elm$core$Result$Err(
				$author$project$RFQDTS$Http$Authenticator$InternalResponse$JwtTokenRequestError($elm$http$Http$Timeout));
	}
};
var $author$project$RFQDTS$Http$Authenticator$JwtToken$fetchTask = function (url) {
	return $elm$http$Http$task(
		{
			mo: $elm$http$Http$emptyBody,
			nH: _List_Nil,
			oi: 'POST',
			pr: $elm$http$Http$stringResolver($author$project$RFQDTS$Http$Authenticator$JwtToken$decodeStringBody),
			pW: $elm$core$Maybe$Nothing,
			lO: url
		});
};
var $author$project$RFQDTS$Http$Authenticator$JwtToken$toString = function (_v0) {
	var val = _v0;
	return val;
};
var $author$project$RFQDTS$Http$Authenticator$JwtToken$addAuthorizationHeader = A2(
	$elm$core$Basics$composeR,
	$author$project$RFQDTS$Http$Authenticator$JwtToken$toString,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$append('Bearer '),
		$dillonkearns$elm_graphql$Graphql$Http$withHeader('Authorization')));
var $author$project$RFQDTS$Http$Authenticator$fullRequestTask = function (conf) {
	return A2(
		$elm$core$Task$andThen,
		function (retType) {
			return $elm$core$Task$succeed(
				{I: conf.I, i: conf.i, bD: retType});
		},
		A2(
			$elm$core$Task$mapError,
			function (err) {
				return {
					b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$ConsumerRequestError(err),
					v: $elm$core$Maybe$Just(conf.I),
					h: $elm$core$Maybe$Just(conf.i)
				};
			},
			$dillonkearns$elm_graphql$Graphql$Http$toTask(
				A2(
					$author$project$RFQDTS$Http$Authenticator$JwtToken$addAuthorizationHeader,
					conf.I,
					A2(
						$author$project$RFQDTS$Http$Authenticator$ProofOfWork$addHeaders,
						conf.i,
						A2($author$project$RFQDTS$Http$Authenticator$withXAuthClientIdHeader, conf.mD, conf.eg))))));
};
var $author$project$RFQDTS$Http$Authenticator$fullAuthRequestTask = F2(
	function (gqlRequest, conf) {
		return A2(
			$elm$core$Task$andThen,
			$author$project$RFQDTS$Http$Authenticator$fullRequestTask,
			A3(
				$elm$core$Task$map2,
				F2(
					function (proofOfWork, jwtToken) {
						return {mD: conf.mD, I: jwtToken, i: proofOfWork, eg: gqlRequest};
					}),
				A2(
					$elm$core$Maybe$withDefault,
					A2(
						$elm$core$Task$mapError,
						function (error) {
							return {b0: error, v: $elm$core$Maybe$Nothing, h: $elm$core$Maybe$Nothing};
						},
						A2($author$project$RFQDTS$Http$Authenticator$ProofOfWork$fetchTask, conf.mu, conf.mD)),
					A2($elm$core$Maybe$map, $elm$core$Task$succeed, conf.i)),
				A2(
					$elm$core$Maybe$withDefault,
					A2(
						$elm$core$Task$mapError,
						function (error) {
							return {b0: error, v: $elm$core$Maybe$Nothing, h: $elm$core$Maybe$Nothing};
						},
						$author$project$RFQDTS$Http$Authenticator$JwtToken$fetchTask(conf.fP)),
					A2($elm$core$Maybe$map, $elm$core$Task$succeed, conf.I))));
	});
var $author$project$RFQDTS$Http$Authenticator$fullTaskResultToMsg = F3(
	function (authResponseTag, internalMsgTagger, result) {
		if (result.$ === 1) {
			var error = result.a.b0;
			var maybeProofOfWork = result.a.h;
			var maybeJwtToken = result.a.v;
			return internalMsgTagger(
				$author$project$RFQDTS$Http$Authenticator$OnTaskSequenceFinished(
					{
						v: maybeJwtToken,
						h: maybeProofOfWork,
						aX: authResponseTag(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$fromInternalResponse(
								$author$project$RFQDTS$Http$Authenticator$InternalResponse$Failure(error)))
					}));
		} else {
			var retType = result.a.bD;
			var proofOfWork = result.a.i;
			var jwtToken = result.a.I;
			return internalMsgTagger(
				$author$project$RFQDTS$Http$Authenticator$OnTaskSequenceFinished(
					{
						v: $elm$core$Maybe$Just(jwtToken),
						h: $elm$core$Maybe$Just(proofOfWork),
						aX: authResponseTag(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$fromInternalResponse(
								$author$project$RFQDTS$Http$Authenticator$InternalResponse$Authorized(retType)))
					}));
		}
	});
var $author$project$RFQDTS$Http$Authenticator$pickInternalMsgTagger = function (auth) {
	if (!auth.$) {
		var withAntiSpiderConfig = auth.a;
		return withAntiSpiderConfig.op;
	} else {
		var fullConfig = auth.a;
		return fullConfig.op;
	}
};
var $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError = {$: 1};
var $author$project$RFQDTS$Http$Authenticator$retryNTimesAntiSpiderOnlyAuthRequestTask = F3(
	function (recursivePayload, antiSpiderOnlyConf, err) {
		var _v0 = err.b0;
		if (((!_v0.$) && (_v0.a.$ === 1)) && (_v0.a.a.$ === 3)) {
			var _v1 = _v0.a.a;
			var metadata = _v1.a;
			var _v2 = metadata.pL;
			switch (_v2) {
				case 401:
					return (_Utils_cmp(recursivePayload.V, antiSpiderOnlyConf.aq) < 0) ? A2(
						$elm$core$Task$onError,
						A2(
							$author$project$RFQDTS$Http$Authenticator$retryNTimesAntiSpiderOnlyAuthRequestTask,
							_Utils_update(
								recursivePayload,
								{V: recursivePayload.V + 1}),
							antiSpiderOnlyConf),
						A2(
							$author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyAuthRequestTask,
							recursivePayload.nA,
							_Utils_update(
								antiSpiderOnlyConf,
								{i: $elm$core$Maybe$Nothing}))) : $elm$core$Task$fail(
						{b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError, h: err.h});
				case 403:
					return $elm$core$Task$fail(
						{b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError, h: err.h});
				default:
					return $elm$core$Task$fail(err);
			}
		} else {
			return $elm$core$Task$fail(err);
		}
	});
var $author$project$RFQDTS$Http$Authenticator$hasJWTTokenExpiredError = $elm$core$List$any(
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.of;
		},
		$elm$core$Basics$eq('unauthenticated')));
var $author$project$RFQDTS$Http$Authenticator$retryNTimesFullAuthRequestTask = F3(
	function (recursivePayload, fullConfig, err) {
		var _v0 = err.b0;
		_v0$2:
		while (true) {
			if (!_v0.$) {
				if (_v0.a.$ === 1) {
					if (_v0.a.a.$ === 3) {
						var _v1 = _v0.a.a;
						var metadata = _v1.a;
						var _v2 = metadata.pL;
						switch (_v2) {
							case 401:
								return (_Utils_cmp(recursivePayload.V, fullConfig.aq) < 0) ? A2(
									$elm$core$Task$onError,
									A2(
										$author$project$RFQDTS$Http$Authenticator$retryNTimesFullAuthRequestTask,
										_Utils_update(
											recursivePayload,
											{V: recursivePayload.V + 1}),
										fullConfig),
									A2(
										$author$project$RFQDTS$Http$Authenticator$fullAuthRequestTask,
										recursivePayload.nA,
										_Utils_update(
											fullConfig,
											{i: $elm$core$Maybe$Nothing}))) : $elm$core$Task$fail(
									{b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError, v: err.v, h: err.h});
							case 403:
								return $elm$core$Task$fail(
									{b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError, v: err.v, h: err.h});
							default:
								return $elm$core$Task$fail(err);
						}
					} else {
						break _v0$2;
					}
				} else {
					var _v3 = _v0.a;
					var graphQlErrors = _v3.b;
					return ($author$project$RFQDTS$Http$Authenticator$hasJWTTokenExpiredError(graphQlErrors) && (_Utils_cmp(recursivePayload.V, fullConfig.aq) < 1)) ? A2(
						$elm$core$Task$onError,
						A2(
							$author$project$RFQDTS$Http$Authenticator$retryNTimesFullAuthRequestTask,
							_Utils_update(
								recursivePayload,
								{V: recursivePayload.V + 1}),
							fullConfig),
						A2(
							$author$project$RFQDTS$Http$Authenticator$fullAuthRequestTask,
							recursivePayload.nA,
							_Utils_update(
								fullConfig,
								{I: $elm$core$Maybe$Nothing}))) : ($author$project$RFQDTS$Http$Authenticator$hasJWTTokenExpiredError(graphQlErrors) ? $elm$core$Task$fail(
						{b0: $author$project$RFQDTS$Http$Authenticator$InternalResponse$DefinitelyUnauthorizedError, v: err.v, h: err.h}) : $elm$core$Task$fail(err));
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$Task$fail(err);
	});
var $author$project$RFQDTS$Http$Authenticator$authGqlRequest = F2(
	function (conf, authenticator) {
		if (!authenticator.$) {
			var antiSpiderOnlyConfig = authenticator.a;
			return A2(
				$elm$core$Task$attempt,
				A2(
					$author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyTaskResultToMsg,
					conf.me,
					$author$project$RFQDTS$Http$Authenticator$pickInternalMsgTagger(authenticator)),
				A2(
					$elm$core$Task$onError,
					A2(
						$author$project$RFQDTS$Http$Authenticator$retryNTimesAntiSpiderOnlyAuthRequestTask,
						{V: 1, nA: conf.nA},
						antiSpiderOnlyConfig),
					A2($author$project$RFQDTS$Http$Authenticator$antiSpiderOnlyAuthRequestTask, conf.nA, antiSpiderOnlyConfig)));
		} else {
			var fullConfig = authenticator.a;
			return A2(
				$elm$core$Task$attempt,
				A2(
					$author$project$RFQDTS$Http$Authenticator$fullTaskResultToMsg,
					conf.me,
					$author$project$RFQDTS$Http$Authenticator$pickInternalMsgTagger(authenticator)),
				A2(
					$elm$core$Task$onError,
					A2(
						$author$project$RFQDTS$Http$Authenticator$retryNTimesFullAuthRequestTask,
						{V: 1, nA: conf.nA},
						fullConfig),
					A2($author$project$RFQDTS$Http$Authenticator$fullAuthRequestTask, conf.nA, fullConfig)));
		}
	});
var $author$project$AntiSpiderProtectedApi$extractResultFromQueryResult = function (queryResult) {
	var result = queryResult;
	return result;
};
var $dillonkearns$elm_graphql$Graphql$Http$mapError = F2(
	function (mapFn, error) {
		if (!error.$) {
			var possiblyParsedData = error.a;
			var graphqlErrorList = error.b;
			if (!possiblyParsedData.$) {
				var parsedData = possiblyParsedData.a;
				return A2(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData(
						mapFn(parsedData)),
					graphqlErrorList);
			} else {
				var unparsedData = possiblyParsedData.a;
				return A2(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(unparsedData),
					graphqlErrorList);
			}
		} else {
			var httpError = error.a;
			return $dillonkearns$elm_graphql$Graphql$Http$HttpError(httpError);
		}
	});
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$map = F2(
	function (f, data) {
		switch (data.$) {
			case 0:
				var value = data.a;
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Authorized(
					f(value));
			case 1:
				switch (data.a.$) {
					case 0:
						var e = data.a.a;
						return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$GqlRequestError(
								A2($dillonkearns$elm_graphql$Graphql$Http$mapError, f, e)));
					case 3:
						var _v1 = data.a;
						return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure($author$project$RFQDTS$Http$Authenticator$AuthResponse$UnauthorizedError);
					case 2:
						var e = data.a.a;
						return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$ProofOfWorkError(e));
					default:
						var e = data.a.a;
						return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Failure(
							$author$project$RFQDTS$Http$Authenticator$AuthResponse$JWTTokenError(e));
				}
			case 2:
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$Loading;
			default:
				return $author$project$RFQDTS$Http$Authenticator$AuthResponse$NotAsked;
		}
	});
var $author$project$AntiSpiderProtectedApi$withMaybeAuthorizationHeader = F2(
	function (tokenConfig, req) {
		return A2(
			$elm$core$Maybe$withDefault,
			req,
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var token = _v0.p1;
					return A3(
						$dillonkearns$elm_graphql$Graphql$Http$withHeader,
						'Authorization',
						$author$project$Utils$withBearer(token),
						req);
				},
				tokenConfig));
	});
var $author$project$AntiSpiderProtectedApi$withReferralHeader = F2(
	function (flags, req) {
		return A2(
			$elm$core$Maybe$withDefault,
			req,
			A2(
				$elm$core$Maybe$map,
				function (referral) {
					return A3($dillonkearns$elm_graphql$Graphql$Http$withHeader, 'X-Origin-Referral', referral, req);
				},
				$author$project$Types$pickReferral(flags)));
	});
var $author$project$AntiSpiderProtectedApi$request = F4(
	function (url, flags, maybeToken, operation) {
		var selection = operation;
		return A2(
			$author$project$AntiSpiderProtectedApi$withReferralHeader,
			flags,
			A2(
				$author$project$AntiSpiderProtectedApi$withMaybeAuthorizationHeader,
				maybeToken,
				A2($dillonkearns$elm_graphql$Graphql$Http$mutationRequest, url, selection)));
	});
var $author$project$AntiSpiderProtectedApi$taggerFromSource = function (querySource) {
	var tagger = querySource;
	return A2($elm$core$Basics$composeR, tagger, $author$project$Model$QuotationMsg);
};
var $author$project$AntiSpiderProtectedApi$toCommand = function (_v0) {
	var protectedQuery = _v0.o5;
	var flags = _v0.nv;
	var apiUrl = _v0.mc;
	var tokenConfig = _v0.p2;
	var authenticator = _v0.mf;
	var _v1 = function () {
		var query = protectedQuery;
		return _Utils_Tuple2(query.kt, query.lu);
	}();
	var operation = _v1.a;
	var source = _v1.b;
	return A2(
		$author$project$RFQDTS$Http$Authenticator$authGqlRequest,
		{
			me: A2(
				$elm$core$Basics$composeR,
				$author$project$RFQDTS$Http$Authenticator$AuthResponse$map($author$project$AntiSpiderProtectedApi$extractResultFromQueryResult),
				$author$project$AntiSpiderProtectedApi$taggerFromSource(source)),
			nA: A4($author$project$AntiSpiderProtectedApi$request, apiUrl, flags, tokenConfig, operation)
		},
		authenticator);
};
var $author$project$Utils$UpdateReturn$withQueryCommands = F2(
	function (_v0, _v1) {
		var operations = _v0.ec;
		var antiSpiderProtectedOperations = _v0.c$;
		var model = _v1.a;
		var cmd = _v1.b;
		var antiSpiderCommands = A3(
			$elm$core$List$foldl,
			F2(
				function (op, acc) {
					return A2(
						$elm$core$List$cons,
						$author$project$AntiSpiderProtectedApi$toCommand(
							{
								mc: $author$project$Types$pickApiUrl(model.nv),
								mf: model.mf,
								nv: model.nv,
								o5: op,
								p2: model.p2
							}),
						acc);
				}),
			_List_Nil,
			antiSpiderProtectedOperations);
		var _v2 = A3(
			$elm$core$List$foldl,
			$author$project$Utils$UpdateReturn$foldCommand,
			_Utils_Tuple2(model, _List_Nil),
			operations);
		var newModel = _v2.a;
		var commands = _v2.b;
		return A2(
			$author$project$Utils$withCommands,
			_List_fromArray(
				[
					cmd,
					$elm$core$Platform$Cmd$batch(
					$elm$core$List$concat(
						_List_fromArray(
							[commands, antiSpiderCommands])))
				]),
			newModel);
	});
var $author$project$Utils$UpdateReturn$updateChild = F5(
	function (model, operation, rootModelUpdater, childModelReader, tagger) {
		var key = model.fR;
		var _v0 = function () {
			if (!operation.$) {
				var childModelInitializer = operation.a;
				return childModelInitializer(
					childModelReader(model));
			} else {
				var msg = operation.a;
				var childModelUpdater = operation.b;
				return A2(
					childModelUpdater,
					msg,
					childModelReader(model));
			}
		}();
		var updatedSubModel = _v0.a;
		var command = _v0.b;
		var updateReturn = _v0.c;
		return A2(
			$author$project$Utils$UpdateReturn$withQueryCommands,
			updateReturn,
			A2(
				$author$project$Utils$withCommands,
				_List_fromArray(
					[
						A2($elm$core$Platform$Cmd$map, tagger, command),
						A3($author$project$Utils$UpdateReturn$getCommand, key, tagger, updateReturn)
					]),
				A2(rootModelUpdater, model, updatedSubModel)));
	});
var $author$project$Ports$pyxisStylesheets = _Platform_outgoingPort('pyxisStylesheets', $elm$json$Json$Encode$bool);
var $author$project$Update$updatePyxisStylesheets = function (route) {
	var isNewPyxisDisabled = function () {
		switch (route.$) {
			case 1:
				return false;
			case 0:
				return false;
			default:
				return true;
		}
	}();
	return $author$project$Ports$pyxisStylesheets(isNewPyxisDisabled);
};
var $author$project$Update$lazyInit = function (initialModel) {
	var _v0 = function () {
		var _v1 = initialModel.pw;
		switch (_v1.$) {
			case 0:
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A2(
							$author$project$Preselection$Preselection$Initialize,
							$author$project$Update$tokenType(initialModel.p2),
							initialModel.p6),
						$author$project$Preselection$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oW: subModel});
						}),
					function ($) {
						return $.oW;
					},
					$author$project$Model$PreselectionMsg);
			case 1:
				if (!_v1.a) {
					var _v2 = _v1.a;
					var step = _v1.b;
					return A5(
						$author$project$Utils$UpdateReturn$updateChild,
						initialModel,
						A2(
							$author$project$Utils$UpdateReturn$Update,
							A4(
								$author$project$Quotation$Model$Initialize,
								0,
								step,
								$author$project$Update$tokenType(initialModel.p2),
								initialModel.p6),
							$author$project$Quotation$Update$update),
						F2(
							function (rootModel, subModel) {
								return _Utils_update(
									rootModel,
									{pa: subModel});
							}),
						function ($) {
							return $.pa;
						},
						$author$project$Model$QuotationMsg);
				} else {
					var _v3 = _v1.a;
					var step = _v1.b;
					return A5(
						$author$project$Utils$UpdateReturn$updateChild,
						initialModel,
						A2(
							$author$project$Utils$UpdateReturn$Update,
							A4(
								$author$project$Quotation$Model$Initialize,
								1,
								step,
								$author$project$Update$tokenType(initialModel.p2),
								initialModel.p6),
							$author$project$Quotation$Update$update),
						F2(
							function (rootModel, subModel) {
								return _Utils_update(
									rootModel,
									{pa: subModel});
							}),
						function ($) {
							return $.pa;
						},
						$author$project$Model$QuotationMsg);
				}
			case 2:
				var quotationType = _v1.a;
				var quoteId = _v1.b;
				var maybeSaveId = _v1.c;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A4(
							$author$project$Guarantees$Model$Initialize,
							quotationType,
							quoteId,
							maybeSaveId,
							$author$project$Update$tokenType(initialModel.p2)),
						$author$project$Guarantees$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{nG: subModel});
						}),
					function ($) {
						return $.nG;
					},
					$author$project$Model$GuaranteesMsg);
			case 7:
				return _Utils_Tuple2(initialModel, $elm$core$Platform$Cmd$none);
			case 3:
				var quotationType = _v1.a;
				var saveId = _v1.b;
				var step = _v1.c;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A3($author$project$Checkout$Model$Initialize, quotationType, saveId, step),
						$author$project$Checkout$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{mw: subModel});
						}),
					function ($) {
						return $.mw;
					},
					$author$project$Model$CheckoutMsg);
			case 4:
				var orderId = _v1.b;
				var insuranceId = _v1.c;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A3($author$project$ThankYou$Model$Initialize, orderId, insuranceId, initialModel.p2),
						$author$project$ThankYou$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{pU: subModel});
						}),
					function ($) {
						return $.pU;
					},
					$author$project$Model$ThankYouMsg);
			case 8:
				var offerId = _v1.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A2($author$project$OneClick$Model$Initialize, offerId, initialModel.p2),
						$author$project$OneClick$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oH: subModel});
						}),
					function ($) {
						return $.oH;
					},
					$author$project$Model$OneClickMsg);
			case 6:
				var reason = _v1.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A2(
							$author$project$NotAvailable$Model$Initialize,
							reason,
							$author$project$Update$tokenType(initialModel.p2)),
						$author$project$NotAvailable$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oA: subModel});
						}),
					function ($) {
						return $.oA;
					},
					$author$project$Model$NotAvailableMsg);
			default:
				var maybeQuoteId = _v1.a;
				var maybeSaveId = _v1.b;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					initialModel,
					A2(
						$author$project$Utils$UpdateReturn$Update,
						A4(
							$author$project$Quotation$Model$FillFormMsg,
							maybeQuoteId,
							maybeSaveId,
							$author$project$Update$tokenType(initialModel.p2),
							initialModel.p6),
						$author$project$Quotation$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{pa: subModel});
						}),
					function ($) {
						return $.pa;
					},
					$author$project$Model$QuotationMsg);
		}
	}();
	var initialModel_ = _v0.a;
	var initializeCmd = _v0.b;
	return _Utils_Tuple2(
		initialModel_,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					initializeCmd,
					$author$project$Update$updatePyxisStylesheets(initialModel.pw)
				])));
};
var $author$project$Update$updateStatus = F2(
	function (status, model) {
		var newModel = _Utils_update(
			model,
			{c0: status});
		if (status === 1) {
			return $author$project$Update$lazyInit(newModel);
		} else {
			return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$updateUser = F2(
	function (user, model) {
		return _Utils_update(
			model,
			{
				p6: $elm$core$Maybe$Just(user)
			});
	});
var $author$project$Update$updateForRootQuery = F2(
	function (msg, model) {
		var userResponse = msg;
		switch (userResponse.$) {
			case 3:
				var user = userResponse.a;
				return A2(
					$author$project$Update$updateStatus,
					1,
					A2($author$project$Update$updateUser, user, model));
			case 2:
				return A2($author$project$Update$updateStatus, 1, model);
			default:
				return A2($author$project$Update$updateStatus, 1, model);
		}
	});
var $author$project$Update$innerUpdate = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.fR,
							$elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 1:
				var url = msg.a;
				var route = $author$project$Routing$toRoute(
					$elm$url$Url$toString(url));
				var _v2 = function () {
					switch (route.$) {
						case 0:
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A2(
										$author$project$Preselection$Preselection$Initialize,
										$author$project$Update$tokenType(model.p2),
										model.p6),
									$author$project$Preselection$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{oW: subModel});
									}),
								function ($) {
									return $.oW;
								},
								$author$project$Model$PreselectionMsg);
						case 1:
							if (!route.a) {
								var _v4 = route.a;
								var step = route.b;
								return A5(
									$author$project$Utils$UpdateReturn$updateChild,
									model,
									A2(
										$author$project$Utils$UpdateReturn$Update,
										A4(
											$author$project$Quotation$Model$Initialize,
											0,
											step,
											$author$project$Update$tokenType(model.p2),
											model.p6),
										$author$project$Quotation$Update$update),
									F2(
										function (rootModel, subModel) {
											return _Utils_update(
												rootModel,
												{pa: subModel});
										}),
									function ($) {
										return $.pa;
									},
									$author$project$Model$QuotationMsg);
							} else {
								var _v5 = route.a;
								var step = route.b;
								return A5(
									$author$project$Utils$UpdateReturn$updateChild,
									model,
									A2(
										$author$project$Utils$UpdateReturn$Update,
										A4(
											$author$project$Quotation$Model$Initialize,
											1,
											step,
											$author$project$Update$tokenType(model.p2),
											model.p6),
										$author$project$Quotation$Update$update),
									F2(
										function (rootModel, subModel) {
											return _Utils_update(
												rootModel,
												{pa: subModel});
										}),
									function ($) {
										return $.pa;
									},
									$author$project$Model$QuotationMsg);
							}
						case 2:
							var quotationType = route.a;
							var quoteId = route.b;
							var maybeSaveId = route.c;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A4(
										$author$project$Guarantees$Model$Initialize,
										quotationType,
										quoteId,
										maybeSaveId,
										$author$project$Update$tokenType(model.p2)),
									$author$project$Guarantees$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{nG: subModel});
									}),
								function ($) {
									return $.nG;
								},
								$author$project$Model$GuaranteesMsg);
						case 3:
							var quotationType = route.a;
							var saveId = route.b;
							var step = route.c;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A3($author$project$Checkout$Model$Initialize, quotationType, saveId, step),
									$author$project$Checkout$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{mw: subModel});
									}),
								function ($) {
									return $.mw;
								},
								$author$project$Model$CheckoutMsg);
						case 6:
							var reason = route.a;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A2(
										$author$project$NotAvailable$Model$Initialize,
										reason,
										$author$project$Update$tokenType(model.p2)),
									$author$project$NotAvailable$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{oA: subModel});
									}),
								function ($) {
									return $.oA;
								},
								$author$project$Model$NotAvailableMsg);
						case 4:
							var orderId = route.b;
							var insuranceId = route.c;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A3($author$project$ThankYou$Model$Initialize, orderId, insuranceId, model.p2),
									$author$project$ThankYou$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{pU: subModel});
									}),
								function ($) {
									return $.pU;
								},
								$author$project$Model$ThankYouMsg);
						case 5:
							var maybeQuoteId = route.a;
							var maybeSaveId = route.b;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A4(
										$author$project$Quotation$Model$FillFormMsg,
										maybeQuoteId,
										maybeSaveId,
										$author$project$Update$tokenType(model.p2),
										model.p6),
									$author$project$Quotation$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{pa: subModel});
									}),
								function ($) {
									return $.pa;
								},
								$author$project$Model$QuotationMsg);
						case 8:
							var offerId = route.a;
							return A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model,
								A2(
									$author$project$Utils$UpdateReturn$Update,
									A2($author$project$OneClick$Model$Initialize, offerId, model.p2),
									$author$project$OneClick$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{oH: subModel});
									}),
								function ($) {
									return $.oH;
								},
								$author$project$Model$OneClickMsg);
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}();
				var newModel = _v2.a;
				var subCommand = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{pw: route, lO: url}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								subCommand,
								A3($author$project$Utils$scrollTo, $author$project$Model$NoOp, 0, 70),
								$author$project$Update$updatePyxisStylesheets(route),
								$author$project$FlagshipIO$sendPageViewHit(url)
							])));
			case 2:
				var preselectionMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, preselectionMsg, $author$project$Preselection$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oW: subModel});
						}),
					function ($) {
						return $.oW;
					},
					$author$project$Model$PreselectionMsg);
			case 3:
				var quotationOldMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, quotationOldMsg, $author$project$QuotationOld$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{pb: subModel});
						}),
					function ($) {
						return $.pb;
					},
					$author$project$Model$QuotationOldMsg);
			case 4:
				var quotationMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, quotationMsg, $author$project$Quotation$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{pa: subModel});
						}),
					function ($) {
						return $.pa;
					},
					$author$project$Model$QuotationMsg);
			case 5:
				var guaranteesMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, guaranteesMsg, $author$project$Guarantees$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{nG: subModel});
						}),
					function ($) {
						return $.nG;
					},
					$author$project$Model$GuaranteesMsg);
			case 6:
				var checkoutMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, checkoutMsg, $author$project$Checkout$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{mw: subModel});
						}),
					function ($) {
						return $.mw;
					},
					$author$project$Model$CheckoutMsg);
			case 7:
				var isOpen = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{n4: isOpen}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var thankYouMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, thankYouMsg, $author$project$ThankYou$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{pU: subModel});
						}),
					function ($) {
						return $.pU;
					},
					$author$project$Model$ThankYouMsg);
			case 9:
				var oneClickMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, oneClickMsg, $author$project$OneClick$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oH: subModel});
						}),
					function ($) {
						return $.oH;
					},
					$author$project$Model$OneClickMsg);
			case 10:
				var notAvailableMsg = msg.a;
				return A5(
					$author$project$Utils$UpdateReturn$updateChild,
					model,
					A2($author$project$Utils$UpdateReturn$Update, notAvailableMsg, $author$project$NotAvailable$Update$update),
					F2(
						function (rootModel, subModel) {
							return _Utils_update(
								rootModel,
								{oA: subModel});
						}),
					function ($) {
						return $.oA;
					},
					$author$project$Model$NotAvailableMsg);
			case 11:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 14:
				var tokenResult = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(
						model,
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Utils$send(
								$author$project$Model$ChangeAppStatus(1)),
							A2(
								$author$project$Update$maybeTokenCommand,
								model.p2,
								$author$project$Types$pickApiUrl(model.nv)))),
					A2(
						$elm$core$Maybe$map,
						$author$project$Update$maybeAskForUser,
						A2(
							$elm$core$Maybe$map,
							$author$project$Api$AuthClient$changeAuthentication(model),
							$author$project$Api$Token$tokenResultToMaybeToken(tokenResult))));
			case 12:
				var tokenResult = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					A2(
						$author$project$Update$changeAppStatus,
						1,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none)),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Api$AuthClient$changeAuthentication(model),
							$author$project$Update$changeAppStatus(1)),
						$author$project$Api$Token$tokenResultToMaybeToken(tokenResult)));
			case 15:
				var tokenResult = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						$author$project$Api$AuthClient$changeAuthentication(model),
						$author$project$Api$Token$refreshTokenResultToMaybeToken(tokenResult)));
			case 16:
				var payload = msg.a;
				return A2($author$project$Api$AuthClient$manageUpdate, model, payload);
			case 17:
				var fromSections = msg.a;
				var pushFrom = F2(
					function (fromSection, _v7) {
						var model_ = _v7.a;
						var cmd_ = _v7.b;
						return A2(
							$author$project$Utils$addCommand,
							cmd_,
							A5(
								$author$project$Utils$UpdateReturn$updateChild,
								model_,
								A2($author$project$Utils$UpdateReturn$Update, $author$project$QuotationOld$Model$PushFormDataToDataLayer, $author$project$QuotationOld$Update$update),
								F2(
									function (rootModel, subModel) {
										return _Utils_update(
											rootModel,
											{pb: subModel});
									}),
								function ($) {
									return $.pb;
								},
								$author$project$Model$QuotationOldMsg));
					});
				return A3(
					$elm$core$List$foldl,
					pushFrom,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					fromSections);
			case 18:
				var status = msg.a;
				return A2($author$project$Update$updateStatus, status, model);
			case 19:
				var cookieBotInnerMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapFirst,
					function (cB) {
						return A2($author$project$Model$setCookieBot, cB, model);
					},
					A2(
						$author$project$CookieBot$update,
						cookieBotInnerMsg,
						$author$project$Model$pickCookieBot(model)));
			case 13:
				if (!msg.a.$) {
					var user = msg.a.a;
					return A2(
						$author$project$Update$updateStatus,
						1,
						A2($author$project$Update$updateUser, user, model));
				} else {
					return A2($author$project$Update$updateStatus, 1, model);
				}
			case 20:
				var result = msg.a;
				return A2($author$project$Update$updateForRootQuery, result, model);
			case 21:
				var authenticatorMsg = msg.a;
				return A2($author$project$Update$updateAuthenticator, authenticatorMsg, model);
			default:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$FlagshipIO$sendActionTrackingEventHit(
							{eP: 'app_successfully_booted--app_state', n6: $elm$core$Maybe$Nothing, p8: $elm$core$Maybe$Nothing})
						]),
					model);
		}
	});
var $author$project$Update$update = F2(
	function (msg, appState) {
		if (!appState.$) {
			var model = appState.a;
			return A2(
				$elm$core$Tuple$mapFirst,
				$elm$core$Result$Ok,
				A2($author$project$Update$innerUpdate, msg, model));
		} else {
			return $author$project$Utils$withoutCommands(appState);
		}
	});
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Components$Loader$Spinner = 0;
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $author$project$View$notAvailableReasonToTitle = function (reason) {
	switch (reason.$) {
		case 0:
			return 'Preventivo non disponibile';
		case 1:
			return 'Preventivo non disponibile';
		case 2:
			return 'Preventivo scaduto';
		case 3:
			return 'Preventivo non acquistabile';
		case 4:
			return 'Preventivo già acquistato';
		case 5:
			return 'Ops! Qualcosa è andato storto';
		case 6:
			return 'Pagina non disponibile';
		default:
			return 'Polizza non disponibile';
	}
};
var $author$project$View$familyQuotationStepToTitle = function (step) {
	return 'Polizza famiglia online';
};
var $author$project$View$homeQuotationStepToTitle = function (step) {
	switch (step) {
		case 1:
			return 'Preventivo polizza casa online: inserisci informazioni sull\'immobile';
		case 2:
			return 'Preventivo polizza casa online: inserisci indirizzo';
		case 3:
			return 'Preventivo polizza casa online: inserisci dati personali';
		default:
			return 'Polizza casa online';
	}
};
var $author$project$View$quotationStepToTitle = function (formType) {
	if (!formType) {
		return $author$project$View$homeQuotationStepToTitle;
	} else {
		return $author$project$View$familyQuotationStepToTitle;
	}
};
var $author$project$Components$QuoteInfoBox$Tiny = 0;
var $author$project$Components$QuoteInfoBox$box = F2(
	function (msg, quote) {
		return {c2: 'Riepilogo dati', c6: _List_Nil, $7: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing, dH: '', gc: msg, pd: quote, eF: $elm$core$Maybe$Nothing};
	});
var $author$project$Types$Quote$Selection$calculatePriceWithFilters = F6(
	function (packageFilter, clusterFilter, guaranteeFilter, priceType, paymentFrequency, _v0) {
		var sections = _v0.pB;
		return A3(
			$elm$core$List$foldl,
			$prikhi$decimal$Decimal$add,
			$prikhi$decimal$Decimal$zero,
			A2(
				$elm$core$List$map,
				$author$project$Types$Quote$Selection$mapPriceBlock(priceType),
				A2(
					$elm$core$List$concatMap,
					$author$project$Types$Quote$Selection$pickSelectedPriceBlocks(paymentFrequency),
					A2(
						$elm$core$List$filter,
						guaranteeFilter,
						A2(
							$elm$core$List$concatMap,
							function ($) {
								return $.nF;
							},
							A2(
								$elm$core$List$filter,
								clusterFilter,
								A2(
									$elm$core$List$concatMap,
									function ($) {
										return $.mG;
									},
									A2(
										$elm$core$List$filter,
										packageFilter,
										A2(
											$elm$core$List$concatMap,
											function ($) {
												return $.oN;
											},
											A2(
												$elm$core$List$concatMap,
												function ($) {
													return $.oM;
												},
												sections))))))))));
	});
var $author$project$Types$Quote$Selection$calculatePackagePrice = F3(
	function (_package, priceType, paymentFrequency) {
		return A5(
			$author$project$Types$Quote$Selection$calculatePriceWithFilters,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bH;
				},
				$elm$core$Basics$eq(_package.bH)),
			function ($) {
				return $.pC;
			},
			$author$project$Guarantees$Helpers$pickGuaranteeSelected,
			priceType,
			paymentFrequency);
	});
var $author$project$Types$Quote$Selection$calculatePackagePriceForVersion = F4(
	function (quotePrice, _v0, userSelection, _package) {
		var quote = _v0.pd;
		var _v1 = quote.lU;
		if (!_v1) {
			return A2($author$project$Guarantees$Helpers$calculatePackagePrice, _package, userSelection);
		} else {
			return A4($author$project$Types$Quote$Selection$calculatePackagePrice, _package, 0, 0, quotePrice);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Container$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Container$DirectionColumn = 1;
var $primait$pyxis_components$Prima$Pyxis$Container$Regular = 0;
var $primait$pyxis_components$Prima$Pyxis$Container$Options = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Container$defaultOptions = {cV: $elm$core$Maybe$Nothing, eY: _List_Nil, bX: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, mQ: _List_Nil, da: $elm$core$Maybe$Nothing, dm: $elm$core$Maybe$Nothing, dn: $elm$core$Maybe$Nothing, ng: _List_Nil, nK: $elm$core$Maybe$Nothing, nM: $elm$core$Maybe$Nothing, cD: _List_Nil, lz: _List_Nil, hQ: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing};
var $primait$pyxis_components$Prima$Pyxis$Container$column = {bm: 1, ku: $primait$pyxis_components$Prima$Pyxis$Container$defaultOptions, aH: 0};
var $author$project$Components$Card$Card = $elm$core$Basics$identity;
var $author$project$Components$Card$ContentSize = {$: 1};
var $author$project$Components$Card$card = function (content) {
	return {bY: $elm$core$Maybe$Nothing, c6: _List_Nil, mQ: content, hz: $author$project$Components$Card$ContentSize};
};
var $author$project$Checkout$Model$PackageModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$Sidebar$View$priceText = A2($elm$core$Basics$composeR, $author$project$Utils$pricify, $elm$html$Html$text);
var $primait$pyxis_components$Prima$Pyxis$Container$containerSizeToClass = function (containerSize) {
	if (!containerSize) {
		return $elm$html$Html$Attributes$class('container');
	} else {
		return $elm$html$Html$Attributes$class('container-fluid');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Container$flowTypeToClass = function (flowType) {
	if (!flowType) {
		return $elm$html$Html$Attributes$class('flex-direction-row');
	} else {
		return $elm$html$Html$Attributes$class('flex-direction-column');
	}
};
var $primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons = F2(
	function (mA, xs) {
		if (!mA.$) {
			var a = mA.a;
			return A2($elm$core$List$cons, a, xs);
		} else {
			return xs;
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Container$breakPointToClassSuffix = function (breakPoint) {
	switch (breakPoint) {
		case 0:
			return 'small';
		case 1:
			return 'medium';
		case 2:
			return 'large';
		default:
			return 'xlarge';
	}
};
var $primait$pyxis_components$Prima$Pyxis$Container$sizeModifierToClass = function (sizeModifier) {
	if (!sizeModifier.$) {
		var breakPoint = sizeModifier.a;
		return $elm$html$Html$Attributes$class(
			'container--on-bp-' + $primait$pyxis_components$Prima$Pyxis$Container$breakPointToClassSuffix(breakPoint));
	} else {
		var breakPoint = sizeModifier.a;
		return $elm$html$Html$Attributes$class(
			'container-fluid--on-bp-' + $primait$pyxis_components$Prima$Pyxis$Container$breakPointToClassSuffix(breakPoint));
	}
};
var $primait$pyxis_components$Prima$Pyxis$Container$optionalContainerHtmlAttributes = function (options) {
	return A2(
		$elm$core$List$append,
		options.lz,
		A2(
			$elm$core$List$append,
			A2($elm$core$List$map, $primait$pyxis_components$Prima$Pyxis$Container$sizeModifierToClass, options.cD),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
				options.eE,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
					options.hQ,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
						options.nM,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
							options.nK,
							A2(
								$elm$core$List$append,
								options.ng,
								A2(
									$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
									options.dn,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
										options.dm,
										A2(
											$elm$core$List$append,
											options.eY,
											A2(
												$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
												options.da,
												A2(
													$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
													options.bX,
													A2(
														$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
														options.c5,
														A2($primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons, options.cV, _List_Nil))))))))))))));
};
var $primait$pyxis_components$Prima$Pyxis$Container$container = F2(
	function (options, configuration) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$primait$pyxis_components$Prima$Pyxis$Container$containerSizeToClass(configuration.aH),
						$primait$pyxis_components$Prima$Pyxis$Container$flowTypeToClass(configuration.bm)
					]),
				$primait$pyxis_components$Prima$Pyxis$Container$optionalContainerHtmlAttributes(options)),
			options.mQ);
	});
var $primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerConfig = function (_v0) {
	var configurationRecord = _v0;
	return configurationRecord;
};
var $primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerOptions = function (_v0) {
	var containerOptions = _v0;
	return containerOptions;
};
var $primait$pyxis_components$Prima$Pyxis$Container$pickContainerOptions = A2(
	$elm$core$Basics$composeR,
	$primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerConfig,
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.ku;
		},
		$primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerOptions));
var $primait$pyxis_components$Prima$Pyxis$Container$render = function (config) {
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Container$container,
		$primait$pyxis_components$Prima$Pyxis$Container$pickContainerOptions(config),
		$primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerConfig(config));
};
var $primait$pyxis_components$Prima$Pyxis$Container$DirectionRow = 0;
var $primait$pyxis_components$Prima$Pyxis$Container$row = {bm: 0, ku: $primait$pyxis_components$Prima$Pyxis$Container$defaultOptions, aH: 0};
var $author$project$Checkout$Sidebar$View$toPackageDataTestId = function (packageLabel) {
	return A2(
		$elm$core$String$join,
		'-',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not),
			A2(
				$elm$core$String$split,
				' ',
				$elm$core$String$toLower(packageLabel))));
};
var $primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord = F2(
	function (containerOptionsMapper, config) {
		var containerConfig = $primait$pyxis_components$Prima$Pyxis$Container$deObfuscateContainerConfig(config);
		return _Utils_update(
			containerConfig,
			{
				ku: containerOptionsMapper(
					$primait$pyxis_components$Prima$Pyxis$Container$pickContainerOptions(config))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Container$withClass = F2(
	function (_class, config) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord,
			function (containerOptions) {
				return _Utils_update(
					containerOptions,
					{
						bX: $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class(_class))
					});
			},
			config);
	});
var $primait$pyxis_components$Prima$Pyxis$Container$withContent = F2(
	function (content, config) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord,
			function (containerOptions) {
				return _Utils_update(
					containerOptions,
					{mQ: content});
			},
			config);
	});
var $author$project$Checkout$Sidebar$View$guaranteesInfoBoxItemView = F4(
	function (quotePrice, _v0, userSelection, _package) {
		var quote = _v0.pd;
		var packagePrice = function () {
			var _v1 = quote.lU;
			if (!_v1) {
				return A2($author$project$Guarantees$Helpers$calculatePackagePrice, _package, userSelection);
			} else {
				return A4($author$project$Types$Quote$Selection$calculatePackagePrice, _package, 0, 0, quotePrice);
			}
		}();
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('display-flex')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon icon-info c-brand-alt-base fs-large'),
										$elm$html$Html$Events$onClick(
										$author$project$Checkout$Model$Toggle(
											$author$project$Checkout$Model$PackageModal(
												$elm$core$Maybe$Just(_package))))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-info-box__item-description'),
										A2(
										$elm$html$Html$Attributes$attribute,
										'data-test-id',
										$author$project$Checkout$Sidebar$View$toPackageDataTestId(_package.n6))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(_package.n6)
									]))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-info-box__item-price')
							]),
						_List_fromArray(
							[
								$author$project$Checkout$Sidebar$View$priceText(packagePrice)
							]))
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-info-box__item', $primait$pyxis_components$Prima$Pyxis$Container$row)));
	});
var $author$project$Guarantees$Helpers$pickSelectedPackages = F2(
	function (_v0, save) {
		var sections = _v0.pB;
		var selectedPackages = save.mO.oN;
		return A2(
			$elm$core$List$filter,
			function (_package) {
				return A2(
					$elm$core$List$any,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(_package.bH)),
					selectedPackages);
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.oN;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oM;
					},
					sections)));
	});
var $author$project$View$Helper$wrapInContainerColumn = function (elements) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2($primait$pyxis_components$Prima$Pyxis$Container$withContent, elements, $primait$pyxis_components$Prima$Pyxis$Container$column));
};
var $author$project$Checkout$Sidebar$View$guaranteesInfoBoxListView = F3(
	function (price, save, userSelection) {
		return $author$project$View$Helper$wrapInContainerColumn(
			A2(
				$elm$core$List$map,
				A3($author$project$Checkout$Sidebar$View$guaranteesInfoBoxItemView, price, save, userSelection),
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.l0;
					},
					A2($author$project$Guarantees$Helpers$pickSelectedPackages, price, save))));
	});
var $author$project$Components$Card$render = function (_v0) {
	var content = _v0.mQ;
	var classes = _v0.c6;
	var class_ = _v0.bY;
	var klass = 'card ' + A2($elm$core$Maybe$withDefault, '', class_);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(klass),
				$elm$html$Html$Attributes$classList(classes)
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Components$Card$withClass = F2(
	function (class_, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				bY: $elm$core$Maybe$Just(class_)
			});
	});
var $author$project$Checkout$Sidebar$View$guaranteesInfoBoxView = F3(
	function (price, save, userSelection) {
		return $author$project$Components$Card$render(
			A2(
				$author$project$Components$Card$withClass,
				'guarantees-info-box',
				$author$project$Components$Card$card(
					$primait$pyxis_components$Prima$Pyxis$Container$render(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Container$withContent,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('guarantees-info-box__title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Coperture incluse')
										])),
									A3($author$project$Checkout$Sidebar$View$guaranteesInfoBoxListView, price, save, userSelection)
								]),
							$primait$pyxis_components$Prima$Pyxis$Container$column)))));
	});
var $author$project$Checkout$Model$NoOp = {$: 20};
var $author$project$Components$QuoteInfoBox$withEffectiveDate = F4(
	function (isDateChangeAllowed, msg, effectiveDate, config) {
		return _Utils_update(
			config,
			{
				$7: $elm$core$Maybe$Just(
					{m8: effectiveDate, fJ: isDateChangeAllowed, gd: msg})
			});
	});
var $author$project$Checkout$Sidebar$View$mapWithEffectiveDateRenewal = function (_v0) {
	var effectiveDate = _v0.m8;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Basics$identity,
		A2(
			$elm$core$Maybe$map,
			A2($author$project$Components$QuoteInfoBox$withEffectiveDate, false, $author$project$Checkout$Model$NoOp),
			effectiveDate));
};
var $author$project$Checkout$Model$RedirectToStep = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Checkout$Sidebar$View$mapWithEffectiveDateStandard = F2(
	function (order, currentStep) {
		var save = order.py;
		return (currentStep === 2) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Basics$identity,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Components$QuoteInfoBox$withEffectiveDate,
					true,
					A2($author$project$Checkout$Model$RedirectToStep, save.nM, 1)),
				order.m8)) : $elm$core$Basics$identity;
	});
var $author$project$Checkout$Sidebar$View$mapWithEffectiveDate = F2(
	function (order, currentStep) {
		return $author$project$Checkout$Model$isRenewalOrder(order) ? $author$project$Checkout$Sidebar$View$mapWithEffectiveDateRenewal(order) : A2($author$project$Checkout$Sidebar$View$mapWithEffectiveDateStandard, order, currentStep);
	});
var $author$project$Components$InfoBox$box = {bX: '', mQ: _List_Nil, ds: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing};
var $author$project$Components$QuoteInfoBox$informationPackageLinkView = function (_v0) {
	var informationPackageLink = _v0.dH;
	return A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$href(informationPackageLink),
				$elm$html$Html$Attributes$class('btn btn--tertiary btn--small quote-box-item-button display-flex align-items-center'),
				$elm$html$Html$Attributes$target('_blank')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('btn__text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Set informativo')
					]))
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Button$withTinySize = function (_v0) {
	var buttonConfig = _v0;
	return _Utils_update(
		buttonConfig,
		{aH: 2});
};
var $author$project$Components$QuoteInfoBox$buttonSizeMapper = function (toggleSize) {
	if (!toggleSize) {
		return $primait$pyxis_components$Prima$Pyxis$Button$withTinySize;
	} else {
		return $primait$pyxis_components$Prima$Pyxis$Button$withSmallSize;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Button$Id = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Button$withId = function (id) {
	return $primait$pyxis_components$Prima$Pyxis$Button$addOption(
		$primait$pyxis_components$Prima$Pyxis$Button$Id(id));
};
var $author$project$Components$QuoteInfoBox$toggleSummaryView = F2(
	function (_v0, toggleSize) {
		var onClick = _v0.gc;
		var buttonLabel = _v0.c2;
		return $primait$pyxis_components$Prima$Pyxis$Button$render(
			A2(
				$author$project$Components$QuoteInfoBox$buttonSizeMapper,
				toggleSize,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
					onClick,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withClass,
						'quote-box-item-button',
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withId,
							'quote-box-data-summary-btn',
							$primait$pyxis_components$Prima$Pyxis$Button$tertiary(buttonLabel))))));
	});
var $author$project$Components$QuoteInfoBox$buttonsView = F2(
	function (quoteBoxConfig, toggleSize) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex justify-content-center')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex flex-direction-column')
						]),
					_List_fromArray(
						[
							A2($author$project$Components$QuoteInfoBox$toggleSummaryView, quoteBoxConfig, toggleSize),
							$author$project$Components$QuoteInfoBox$informationPackageLinkView(quoteBoxConfig)
						]))
				]));
	});
var $author$project$Components$QuoteInfoBox$footerContent = function (config) {
	var toggleSize = config.eF;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Components$QuoteInfoBox$buttonsView(config),
		toggleSize);
};
var $author$project$Components$InfoBox$render = function (config) {
	var title = config.eE;
	var footer = config.ds;
	var _class = config.bX;
	return $author$project$Components$Card$render(
		A2(
			$author$project$Components$Card$withClass,
			_class,
			$author$project$Components$Card$card(
				$primait$pyxis_components$Prima$Pyxis$Container$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Container$withContent,
						A2(
							$elm$core$List$cons,
							$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(title),
							_Utils_ap(
								config.mQ,
								_List_fromArray(
									[
										$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(footer)
									]))),
						$primait$pyxis_components$Prima$Pyxis$Container$column)))));
};
var $author$project$Components$QuoteInfoBox$changeEffectiveDateConditionalView = F2(
	function (isDateChangeAllowed, onClickEdit) {
		return isDateChangeAllowed ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-action-dark clickable'),
					A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'change-effective-date-btn'),
					$elm$html$Html$Events$onClick(onClickEdit)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Modifica')
				])) : $elm$html$Html$text('');
	});
var $ryan_haskell$date_format$DateFormat$DayOfMonthFixed = {$: 7};
var $ryan_haskell$date_format$DateFormat$dayOfMonthFixed = $ryan_haskell$date_format$DateFormat$DayOfMonthFixed;
var $ryan_haskell$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {pX: toAmPm, pY: toMonthAbbreviation, pZ: toMonthName, bL: toOrdinalSuffix, p_: toWeekdayAbbreviation, p$: toWeekdayName};
	});
var $ryan_haskell$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryan_haskell$date_format$DateFormat$Language$english = A6(
	$ryan_haskell$date_format$DateFormat$Language$Language,
	$ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryan_haskell$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryan_haskell$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryan_haskell$date_format$DateFormat$Language$toEnglishAmPm,
	$ryan_haskell$date_format$DateFormat$Language$toEnglishSuffix);
var $ryan_haskell$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.pX(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $ryan_haskell$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $ryan_haskell$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $ryan_haskell$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryan_haskell$date_format$DateFormat$days)))));
	});
var $ryan_haskell$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryan_haskell$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryan_haskell$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $ryan_haskell$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $ryan_haskell$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryan_haskell$date_format$DateFormat$months))));
	});
var $ryan_haskell$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryan_haskell$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $ryan_haskell$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryan_haskell$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryan_haskell$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryan_haskell$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $ryan_haskell$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $ryan_haskell$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$core$String$toUpper = _String_toUpper;
var $ryan_haskell$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryan_haskell$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryan_haskell$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryan_haskell$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryan_haskell$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryan_haskell$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryan_haskell$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.pY(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.pZ(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryan_haskell$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					1 + A2($ryan_haskell$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					3,
					A2($ryan_haskell$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.p_(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.p$(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.bL(num));
				}(
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($ryan_haskell$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryan_haskell$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryan_haskell$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryan_haskell$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryan_haskell$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryan_haskell$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					$ryan_haskell$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryan_haskell$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryan_haskell$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryan_haskell$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryan_haskell$date_format$DateFormat$format = $ryan_haskell$date_format$DateFormat$formatWithLanguage($ryan_haskell$date_format$DateFormat$Language$english);
var $ryan_haskell$date_format$DateFormat$MonthFixed = {$: 2};
var $ryan_haskell$date_format$DateFormat$monthFixed = $ryan_haskell$date_format$DateFormat$MonthFixed;
var $ryan_haskell$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryan_haskell$date_format$DateFormat$text = $ryan_haskell$date_format$DateFormat$Text;
var $ryan_haskell$date_format$DateFormat$YearNumber = {$: 16};
var $ryan_haskell$date_format$DateFormat$yearNumber = $ryan_haskell$date_format$DateFormat$YearNumber;
var $author$project$Utils$Time$dateFormatter = $ryan_haskell$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryan_haskell$date_format$DateFormat$dayOfMonthFixed,
			$ryan_haskell$date_format$DateFormat$text('/'),
			$ryan_haskell$date_format$DateFormat$monthFixed,
			$ryan_haskell$date_format$DateFormat$text('/'),
			$ryan_haskell$date_format$DateFormat$yearNumber
		]));
var $author$project$Utils$Time$formatDate = $author$project$Utils$Time$dateFormatter(
	$justinmimbs$timezone_data$TimeZone$europe__rome(0));
var $author$project$Components$QuoteInfoBox$icon = function (classes) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('icon c-brand-light fs-large ' + classes)
			]),
		_List_Nil);
};
var $author$project$Components$QuoteInfoBox$quoteIcon = function (iconType) {
	return $author$project$Components$QuoteInfoBox$icon('icon-' + iconType);
};
var $author$project$Components$QuoteInfoBox$orderEffectiveDateItem = function (_v0) {
	var effectiveDate = _v0.m8;
	var onClickEdit = _v0.gd;
	var isDateChangeAllowed = _v0.fJ;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quote-box__effective-date display-flex flex-direction-row')
			]),
		_List_fromArray(
			[
				$author$project$Components$QuoteInfoBox$quoteIcon('calendar'),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('display-flex flex-direction-column fs-small fw-base full-width quote-box-item-description')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-text-dark')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Decorrenza')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('quote-box__effective-date__content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-light c-text-base'),
										A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'effective-date')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Utils$Time$formatDate(effectiveDate) + ' dalle 24:00')
									])),
								A2($author$project$Components$QuoteInfoBox$changeEffectiveDateConditionalView, isDateChangeAllowed, onClickEdit)
							]))
					]))
			]));
};
var $author$project$Utils$dateFormatString = 'dd/MM/y';
var $author$project$Types$Utils$personToString = function (_v0) {
	var name = _v0.oq;
	var surname = _v0.lC;
	return name + (' ' + surname);
};
var $author$project$Components$QuoteInfoBox$quoteConditionItemView = function (_v0) {
	var item = _v0.a;
	var dataTestId = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quote-box-item-description-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('•')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('quote-box-item-description-text'),
						A2($elm$html$Html$Attributes$attribute, 'data-test-id', dataTestId)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(item)
					]))
			]));
};
var $author$project$Components$QuoteInfoBox$quoteConditionView = F4(
	function (icon_, txt, titleDataTestId, items) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						$author$project$Components$QuoteInfoBox$quoteIcon(icon_),
						$primait$pyxis_components$Prima$Pyxis$Container$render(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Container$withContent,
							A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fw-base c-text-dark quote-box-item-description-title'),
											A2($elm$html$Html$Attributes$attribute, 'data-test-id', titleDataTestId)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(txt)
										])),
								A2($elm$core$List$map, $author$project$Components$QuoteInfoBox$quoteConditionItemView, items)),
							A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'quote-box-item-description', $primait$pyxis_components$Prima$Pyxis$Container$column)))
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'flex-direction-row quote-box-item fs-small', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Components$QuoteInfoBox$renderContentFamily = F2(
	function (information, _v0) {
		return _List_fromArray(
			[
				A4(
				$author$project$Components$QuoteInfoBox$quoteConditionView,
				'people',
				$author$project$Types$Utils$personToString(information),
				'quote-box-birthday-title',
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Data di nascita: ' + A2($justinmimbs$date$Date$format, $author$project$Utils$dateFormatString, information.mn),
						'quote-box-birthday')
					]))
			]);
	});
var $author$project$Types$Utils$addressToString = function (_v0) {
	var street = _v0.lx;
	var number = _v0.kp;
	var city = _v0.my;
	var province = _v0.o6;
	if (!province.$) {
		var prov = province.a;
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0}, {1}\n {2} ({3})',
			_List_fromArray(
				[street, number, city, prov]));
	} else {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0}, {1}\n {2}',
			_List_fromArray(
				[street, number, city]));
	}
};
var $author$project$Types$Utils$condominiumTypeToLabel = function (_enum) {
	if (!_enum) {
		return 'Fino a 8 appartamenti';
	} else {
		return 'Oltre 8 appartamenti';
	}
};
var $author$project$Types$Utils$houseTypeToLabel = function (_enum) {
	if (!_enum) {
		return 'Singola monofamiliare';
	} else {
		return 'A schiera o plurifamiliare';
	}
};
var $author$project$Components$QuoteInfoBox$quotePropertyTypeDescriptions = function (_v0) {
	var propertyType = _v0.o4;
	var condominiumType = _v0.mN;
	var villaType = _v0.qa;
	var _v1 = _Utils_Tuple3(propertyType, condominiumType, villaType);
	_v1$2:
	while (true) {
		if (!_v1.a.$) {
			if (!_v1.b.$) {
				var pType = _v1.a.a;
				var cType = _v1.b.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						$author$project$Types$Utils$propertyTypeToLabel(pType),
						'quote-box-property-type'),
						_Utils_Tuple2(
						$author$project$Types$Utils$condominiumTypeToLabel(cType),
						'quote-box-condominium-type')
					]);
			} else {
				if (!_v1.c.$) {
					var pType = _v1.a.a;
					var hType = _v1.c.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							$author$project$Types$Utils$propertyTypeToLabel(pType),
							'quote-box-property-type'),
							_Utils_Tuple2(
							$author$project$Types$Utils$houseTypeToLabel(hType),
							'quote-box-villa-type')
						]);
				} else {
					break _v1$2;
				}
			}
		} else {
			break _v1$2;
		}
	}
	return _List_Nil;
};
var $author$project$Components$QuoteInfoBox$houseInfoBox = F2(
	function (information, formType) {
		if ((!formType.$) && (formType.a === 1)) {
			var _v1 = formType.a;
			return A4(
				$author$project$Components$QuoteInfoBox$quoteConditionView,
				'house',
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$addressToString, information.pq)),
				'quote-box-address-title',
				_List_Nil);
		} else {
			return A4(
				$author$project$Components$QuoteInfoBox$quoteConditionView,
				'house',
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$addressToString, information.kY)),
				'quote-box-address-title',
				$author$project$Components$QuoteInfoBox$quotePropertyTypeDescriptions(information));
		}
	});
var $author$project$Components$QuoteInfoBox$peopleInfoBox = F2(
	function (information, formType) {
		if ((!formType.$) && (formType.a === 1)) {
			var _v1 = formType.a;
			return A4(
				$author$project$Components$QuoteInfoBox$quoteConditionView,
				'people',
				$author$project$Types$Utils$personToString(information),
				'quote-box-name-title',
				_List_Nil);
		} else {
			return A4(
				$author$project$Components$QuoteInfoBox$quoteConditionView,
				'people',
				$author$project$Types$Utils$personToString(information),
				'quote-box-name-title',
				_List_fromArray(
					[
						_Utils_Tuple2(
						$author$project$Types$Utils$ownershipToLabel(information.oL),
						'quote-box-ownership'),
						_Utils_Tuple2(
						$author$project$Types$Utils$propertyUsageTypeToLabel(information.o1),
						'quote-box-property-purpose')
					]));
		}
	});
var $author$project$Components$QuoteInfoBox$renderContentHomeAndFamily = F2(
	function (information, formType) {
		return _List_fromArray(
			[
				A2($author$project$Components$QuoteInfoBox$peopleInfoBox, information, formType),
				A2($author$project$Components$QuoteInfoBox$houseInfoBox, information, formType)
			]);
	});
var $author$project$Components$QuoteInfoBox$getContentByProductType = function (productType) {
	if (!productType.$) {
		return $author$project$Components$QuoteInfoBox$renderContentHomeAndFamily;
	} else {
		return $author$project$Components$QuoteInfoBox$renderContentFamily;
	}
};
var $author$project$Components$QuoteInfoBox$renderContentByProductType = function (_v0) {
	var formType = _v0.nx;
	var productType = _v0.kX;
	var information = _v0.nT;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quote-box-vertical-alignment')
			]),
		A3($author$project$Components$QuoteInfoBox$getContentByProductType, productType, information, formType));
};
var $author$project$Components$QuoteInfoBox$renderContent = function (_v0) {
	var quote = _v0.pd;
	var effectiveDateConfig = _v0.$7;
	return _List_fromArray(
		[
			$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A2($elm$core$Maybe$map, $author$project$Components$QuoteInfoBox$orderEffectiveDateItem, effectiveDateConfig)),
			$author$project$Components$QuoteInfoBox$renderContentByProductType(quote)
		]);
};
var $author$project$Components$InfoBox$withClass = F2(
	function (_class, config) {
		return _Utils_update(
			config,
			{bX: config.bX + (' ' + _class)});
	});
var $author$project$Components$InfoBox$withContent = F2(
	function (content, config) {
		return _Utils_update(
			config,
			{mQ: content});
	});
var $author$project$Components$InfoBox$withFooter = F2(
	function (footer, config) {
		return _Utils_update(
			config,
			{
				ds: $elm$core$Maybe$Just(footer)
			});
	});
var $author$project$Components$InfoBox$withTitle = F2(
	function (title, config) {
		return _Utils_update(
			config,
			{
				eE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Components$QuoteInfoBox$render = function (config) {
	var header = config.dB;
	var classes = config.c6;
	return $author$project$Components$InfoBox$render(
		A3(
			$author$project$Utils$maybeApply,
			$author$project$Components$InfoBox$withFooter,
			$author$project$Components$QuoteInfoBox$footerContent(config),
			A2(
				$author$project$Components$InfoBox$withContent,
				$author$project$Components$QuoteInfoBox$renderContent(config),
				A2(
					$author$project$Components$InfoBox$withTitle,
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(header),
					A2(
						$author$project$Components$InfoBox$withClass,
						A2(
							$elm$core$String$join,
							' ',
							A2($elm$core$List$cons, 'quote-box', classes)),
						$author$project$Components$InfoBox$box)))));
};
var $author$project$Guarantees$Helpers$pickGuaranteeWeight = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.l0;
	});
var $author$project$Guarantees$Helpers$clusterToTuple = function (cluster) {
	return A2(
		$elm$core$List$map,
		$elm$core$Tuple$pair(cluster),
		A2($elm$core$List$sortBy, $author$project$Guarantees$Helpers$pickGuaranteeWeight, cluster.nF));
};
var $author$project$Checkout$PackageModal$View$Label = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$PackageModal$View$Deductible = function (a) {
	return {$: 3, a: a};
};
var $author$project$Checkout$PackageModal$View$guaranteeDeductibleCellType = F4(
	function (userSelection, packageSlug, clusterSlug, guaranteeSlug) {
		var optionValue = A2(
			$elm$core$Maybe$withDefault,
			'nessuna',
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Guarantees$Model$UserSelection$pickValueOptionByKey('name'),
				$elm$core$List$head(
					A6($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackageWithTypeAndCoverage, packageSlug, clusterSlug, guaranteeSlug, 'deductible', 'default', userSelection))));
		return $author$project$Checkout$PackageModal$View$Deductible(optionValue);
	});
var $author$project$Checkout$PackageModal$View$Limit = function (a) {
	return {$: 2, a: a};
};
var $author$project$Checkout$PackageModal$View$guaranteeLimitCellType = F4(
	function (userSelection, packageSlug, clusterSlug, guaranteeSlug) {
		var optionValue = A2(
			$elm$core$Maybe$withDefault,
			'nessuna',
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Guarantees$Model$UserSelection$pickValueOptionByKey('name'),
				$elm$core$List$head(
					A6($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteeOrClusterOptionsInPackageWithTypeAndCoverage, packageSlug, clusterSlug, guaranteeSlug, 'limit', 'default', userSelection))));
		return $author$project$Checkout$PackageModal$View$Limit(optionValue);
	});
var $author$project$Checkout$PackageModal$View$guaranteeCellMapperV1 = F3(
	function (userSelection, _package, _v0) {
		var cluster = _v0.a;
		var guarantee = _v0.b;
		return _List_fromArray(
			[
				$author$project$Checkout$PackageModal$View$Label(
				$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee)),
				A4(
				$author$project$Checkout$PackageModal$View$guaranteeLimitCellType,
				userSelection,
				_package.bH,
				cluster.bH,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)),
				A4(
				$author$project$Checkout$PackageModal$View$guaranteeDeductibleCellType,
				userSelection,
				_package.bH,
				cluster.bH,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee))
			]);
	});
var $author$project$View$SimpleTable$ByRow = 0;
var $author$project$Checkout$PackageModal$View$Header = function (a) {
	return {$: 1, a: a};
};
var $author$project$Checkout$PackageModal$View$guaranteeCellRender = function (guaranteeCell) {
	switch (guaranteeCell.$) {
		case 0:
			var label = guaranteeCell.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-heavy')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		case 1:
			var label = guaranteeCell.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-heavy')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		case 2:
			var label = guaranteeCell.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-text-base')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
		default:
			var label = guaranteeCell.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-text-base')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]));
	}
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$View$SimpleTable$renderBodyRow = function (cellRenderer) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				cellRenderer,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$elm$html$Html$td(_List_Nil)))),
		$elm$html$Html$tr(_List_Nil));
};
var $author$project$View$SimpleTable$bodyView = function (cellRenderer) {
	return $elm$core$List$map(
		$author$project$View$SimpleTable$renderBodyRow(cellRenderer));
};
var $Punie$elm_matrix$Matrix$Matrix = $elm$core$Basics$identity;
var $Punie$elm_matrix$Matrix$empty = {aT: $elm$core$Array$empty, aU: 0, aV: 0};
var $Punie$elm_matrix$Matrix$encode = F2(
	function (ncols, _v0) {
		var i = _v0.a;
		var j = _v0.b;
		return (((i - 1) * ncols) + j) - 1;
	});
var $Punie$elm_matrix$Matrix$decode = F2(
	function (ncols, index) {
		var r = index % ncols;
		var q = (index / ncols) | 0;
		return _Utils_Tuple2(q + 1, r + 1);
	});
var $Punie$elm_matrix$Matrix$initialize = F3(
	function (nrows, ncols, f) {
		var f_ = function (i) {
			return f(
				A2($Punie$elm_matrix$Matrix$decode, ncols, i));
		};
		var data = A2($elm$core$Array$initialize, nrows * ncols, f_);
		return {aT: data, aU: ncols, aV: nrows};
	});
var $Punie$elm_matrix$Matrix$itemAt = F2(
	function (index, list) {
		itemAt:
		while (true) {
			if (index < 0) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!list.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var x = list.a;
					var xs = list.b;
					if (!index) {
						return $elm$core$Maybe$Just(x);
					} else {
						var $temp$index = index - 1,
							$temp$list = xs;
						index = $temp$index;
						list = $temp$list;
						continue itemAt;
					}
				}
			}
		}
	});
var $Punie$elm_matrix$Matrix$unsafeGetFromList = F2(
	function (index, list) {
		unsafeGetFromList:
		while (true) {
			var _v0 = A2($Punie$elm_matrix$Matrix$itemAt, index, list);
			if (!_v0.$) {
				var value = _v0.a;
				return value;
			} else {
				var $temp$index = index,
					$temp$list = list;
				index = $temp$index;
				list = $temp$list;
				continue unsafeGetFromList;
			}
		}
	});
var $Punie$elm_matrix$Matrix$fromList = F3(
	function (n, m, list) {
		return (_Utils_cmp(
			$elm$core$List$length(list),
			n * m) < 0) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			A3(
				$Punie$elm_matrix$Matrix$initialize,
				n,
				m,
				function (i) {
					return A2(
						$Punie$elm_matrix$Matrix$unsafeGetFromList,
						A2($Punie$elm_matrix$Matrix$encode, m, i),
						list);
				}));
	});
var $Punie$elm_matrix$Matrix$fromLists = function (list) {
	if (!list.b) {
		return $elm$core$Maybe$Just($Punie$elm_matrix$Matrix$empty);
	} else {
		var xs = list.a;
		var xss = list.b;
		var n = 1 + $elm$core$List$length(xss);
		var m = $elm$core$List$length(xs);
		return (!m) ? $elm$core$Maybe$Just($Punie$elm_matrix$Matrix$empty) : A3(
			$Punie$elm_matrix$Matrix$fromList,
			n,
			m,
			$elm$core$List$concat(
				A2(
					$elm$core$List$cons,
					xs,
					A2(
						$elm$core$List$map,
						$elm$core$List$take(m),
						xss))));
	}
};
var $Punie$elm_matrix$Matrix$height = function (_v0) {
	var nrows = _v0.aV;
	return nrows;
};
var $Punie$elm_matrix$Matrix$get = F3(
	function (i, j, _v0) {
		var nrows = _v0.aV;
		var ncols = _v0.aU;
		var mvect = _v0.aT;
		return ((_Utils_cmp(i, nrows) > 0) || (_Utils_cmp(j, ncols) > 0)) ? $elm$core$Maybe$Nothing : A2(
			$elm$core$Array$get,
			A2(
				$Punie$elm_matrix$Matrix$encode,
				ncols,
				_Utils_Tuple2(i, j)),
			mvect);
	});
var $Punie$elm_matrix$Matrix$unsafeGet = F3(
	function (i, j, m) {
		unsafeGet:
		while (true) {
			var _v0 = A3($Punie$elm_matrix$Matrix$get, i, j, m);
			if (!_v0.$) {
				var v = _v0.a;
				return v;
			} else {
				var $temp$i = i,
					$temp$j = j,
					$temp$m = m;
				i = $temp$i;
				j = $temp$j;
				m = $temp$m;
				continue unsafeGet;
			}
		}
	});
var $Punie$elm_matrix$Matrix$width = function (_v0) {
	var ncols = _v0.aU;
	return ncols;
};
var $Punie$elm_matrix$Matrix$toLists = function (m) {
	return A2(
		$elm$core$List$concatMap,
		function (i) {
			return _List_fromArray(
				[
					A2(
					$elm$core$List$concatMap,
					function (j) {
						return _List_fromArray(
							[
								A3($Punie$elm_matrix$Matrix$unsafeGet, i, j, m)
							]);
					},
					A2(
						$elm$core$List$range,
						1,
						$Punie$elm_matrix$Matrix$width(m)))
				]);
		},
		A2(
			$elm$core$List$range,
			1,
			$Punie$elm_matrix$Matrix$height(m)));
};
var $Punie$elm_matrix$Matrix$transpose = function (m) {
	return A3(
		$Punie$elm_matrix$Matrix$initialize,
		$Punie$elm_matrix$Matrix$width(m),
		$Punie$elm_matrix$Matrix$height(m),
		function (_v0) {
			var i = _v0.a;
			var j = _v0.b;
			return A3($Punie$elm_matrix$Matrix$unsafeGet, j, i, m);
		});
};
var $author$project$View$SimpleTable$contentByRow = F2(
	function (dataListType, content) {
		if (!dataListType) {
			return content;
		} else {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_fromArray(
					[_List_Nil]),
				A2(
					$elm$core$Maybe$map,
					$Punie$elm_matrix$Matrix$toLists,
					A2(
						$elm$core$Maybe$map,
						$Punie$elm_matrix$Matrix$transpose,
						$Punie$elm_matrix$Matrix$fromLists(content))));
		}
	});
var $elm$html$Html$th = _VirtualDom_node('th');
var $author$project$View$SimpleTable$headerView = function (cellRenderer) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			A2(
				$elm$core$Basics$composeR,
				cellRenderer,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$elm$html$Html$th(_List_Nil)))),
		$elm$html$Html$tr(_List_Nil));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $author$project$View$SimpleTable$tableView = F4(
	function (cellRenderer, dataListType, maybeHeader, bodyContent) {
		var rendererHeader = $author$project$View$SimpleTable$headerView(cellRenderer);
		var rendererBody = A2(
			$author$project$View$SimpleTable$bodyView,
			cellRenderer,
			A2($author$project$View$SimpleTable$contentByRow, dataListType, bodyContent));
		var render = $elm$html$Html$table(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('simple-table')
				]));
		if (!maybeHeader.$) {
			var header = maybeHeader.a;
			return render(
				A2(
					$elm$core$List$cons,
					rendererHeader(header),
					rendererBody));
		} else {
			return render(rendererBody);
		}
	});
var $author$project$Checkout$PackageModal$View$renderGuaranteeListCell = function (guaranteeCells) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-column package-modal')
			]),
		_List_fromArray(
			[
				A4(
				$author$project$View$SimpleTable$tableView,
				$author$project$Checkout$PackageModal$View$guaranteeCellRender,
				0,
				$elm$core$Maybe$Just(
					_List_fromArray(
						[
							$author$project$Checkout$PackageModal$View$Header('Garanzie incluse'),
							$author$project$Checkout$PackageModal$View$Header('Massimali'),
							$author$project$Checkout$PackageModal$View$Header('Franchigie')
						])),
				guaranteeCells)
			]));
};
var $author$project$Checkout$PackageModal$View$guaranteeListViewV1 = F2(
	function (userSelection, _package) {
		var selectedGuarantees = A2(
			$elm$core$List$map,
			A2($author$project$Checkout$PackageModal$View$guaranteeCellMapperV1, userSelection, _package),
			A2(
				$elm$core$List$filter,
				function (_v0) {
					var cluster = _v0.a;
					var guarantee = _v0.b;
					return A4(
						$author$project$Guarantees$Model$UserSelection$isGuaranteeInPackageSelected,
						cluster.bH,
						$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
						_package.bH,
						userSelection);
				},
				A2(
					$elm$core$List$concatMap,
					$author$project$Guarantees$Helpers$clusterToTuple,
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.l0;
						},
						_package.mG))));
		return $author$project$Checkout$PackageModal$View$renderGuaranteeListCell(selectedGuarantees);
	});
var $author$project$Checkout$PackageModal$View$pickOptionName = function (option) {
	return A2($author$project$Guarantees$Helpers$pickValueOptionByKey, 'name', option);
};
var $author$project$Checkout$PackageModal$View$guaranteeDeductibleCellTypeV2 = F2(
	function (cluster, guarantee) {
		return $author$project$Checkout$PackageModal$View$Deductible(
			A2(
				$elm$core$Maybe$withDefault,
				'nessuna',
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Checkout$PackageModal$View$pickOptionName,
					A2($author$project$Guarantees$Helpers$findDeductibleOption, cluster, guarantee))));
	});
var $author$project$Checkout$PackageModal$View$guaranteeLimitCellTypeV2 = F2(
	function (cluster, guarantee) {
		return $author$project$Checkout$PackageModal$View$Limit(
			A2(
				$elm$core$Maybe$withDefault,
				'nessuna',
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Checkout$PackageModal$View$pickOptionName,
					A2($author$project$Guarantees$Helpers$findLimitOption, cluster, guarantee))));
	});
var $author$project$Checkout$PackageModal$View$guaranteeCellMapperV2 = function (_v0) {
	var cluster = _v0.a;
	var guarantee = _v0.b;
	return _List_fromArray(
		[
			$author$project$Checkout$PackageModal$View$Label(
			$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee)),
			A2($author$project$Checkout$PackageModal$View$guaranteeLimitCellTypeV2, cluster, guarantee),
			A2($author$project$Checkout$PackageModal$View$guaranteeDeductibleCellTypeV2, cluster, guarantee)
		]);
};
var $author$project$Checkout$PackageModal$View$guaranteeListViewV2 = function (_package) {
	var selectedGuarantees = A2(
		$elm$core$List$map,
		$author$project$Checkout$PackageModal$View$guaranteeCellMapperV2,
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Guarantees$Helpers$pickGuaranteeSelected),
			A2(
				$elm$core$List$concatMap,
				$author$project$Guarantees$Helpers$clusterToTuple,
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.pC;
					},
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.l0;
						},
						_package.mG)))));
	return $author$project$Checkout$PackageModal$View$renderGuaranteeListCell(selectedGuarantees);
};
var $author$project$Checkout$PackageModal$View$guaranteeListViewForVersion = F3(
	function (userSelection, quoteVersion, _package) {
		if (!quoteVersion) {
			return A2($author$project$Checkout$PackageModal$View$guaranteeListViewV1, userSelection, _package);
		} else {
			return $author$project$Checkout$PackageModal$View$guaranteeListViewV2(_package);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Modal$Light = 0;
var $primait$pyxis_components$Prima$Pyxis$Modal$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Modal$Options = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Modal$initialBodyOptions = {eY: _List_Nil, bX: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, mQ: _List_Nil, lz: _List_Nil};
var $primait$pyxis_components$Prima$Pyxis$Modal$initialFooterOptions = {eY: _List_Nil, bX: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, mQ: _List_Nil, lz: _List_Nil};
var $primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButtonId = 'modalHeaderCloseButton';
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodesTargetWith = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetClassName = $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodesTargetWith(
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['className']),
		$elm$json$Json$Decode$string));
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetId = $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodesTargetWith(
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['id']),
		$elm$json$Json$Decode$string));
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetFieldByInterceptor = function (supportedInterceptor) {
	if (!supportedInterceptor.$) {
		return $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetClassName;
	} else {
		return $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetId;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfContainsClass = F5(
	function (interceptedClass, targetClass, msg, stopPropagation, preventDefault) {
		return A2($elm$core$String$contains, interceptedClass, targetClass) ? $elm$json$Json$Decode$succeed(
			{of: msg, oX: preventDefault, pN: stopPropagation}) : $elm$json$Json$Decode$fail('ignoring');
	});
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfIdMatches = F5(
	function (interceptedId, targetId, msg, stopPropagation, preventDefault) {
		return _Utils_eq(interceptedId, targetId) ? $elm$json$Json$Decode$succeed(
			{of: msg, oX: preventDefault, pN: stopPropagation}) : $elm$json$Json$Decode$fail('ignoring');
	});
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfIntercepted = F2(
	function (_v0, targetIdentifier) {
		var interceptor = _v0.br;
		var msg = _v0.d1;
		var stopPropagation = _v0.pN;
		var preventDefault = _v0.oX;
		if (!interceptor.$) {
			var interceptedClass = interceptor.a;
			return A5($primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfContainsClass, interceptedClass, targetIdentifier, msg, stopPropagation, preventDefault);
		} else {
			var interceptedId = interceptor.a;
			return A5($primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfIdMatches, interceptedId, targetIdentifier, msg, stopPropagation, preventDefault);
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$nativeEventSlug = function (_v0) {
	var slug = _v0;
	return slug;
};
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$event = function (_v0) {
	var interceptedEventConfig = _v0;
	return A2(
		$elm$html$Html$Events$custom,
		$primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$nativeEventSlug(interceptedEventConfig.cm),
		A2(
			$elm$json$Json$Decode$andThen,
			$primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$emitIfIntercepted(interceptedEventConfig),
			$primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$decodeTargetFieldByInterceptor(interceptedEventConfig.br)));
};
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$NativeEvent = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$onClick = F2(
	function (interceptor, msg) {
		return {br: interceptor, d1: msg, cm: 'click', oX: false, pN: false};
	});
var $primait$pyxis_components$Prima$Pyxis$Commons$Interceptor$WithId = function (a) {
	return {$: 1, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Commons$Interceptor$targetWithId = function (id) {
	return $primait$pyxis_components$Prima$Pyxis$Commons$Interceptor$WithId(id);
};
var $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$withStopPropagation = function (_v0) {
	var interceptedEventConfig = _v0;
	return _Utils_update(
		interceptedEventConfig,
		{pN: true});
};
var $primait$pyxis_components$Prima$Pyxis$Modal$onCloseButtonClick = A2(
	$elm$core$Basics$composeR,
	$primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$onClick(
		$primait$pyxis_components$Prima$Pyxis$Commons$Interceptor$targetWithId($primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButtonId)),
	A2($elm$core$Basics$composeR, $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$withStopPropagation, $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$event));
var $primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButton = function (closeEvent) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal__header__close'),
				$elm$html$Html$Attributes$id($primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButtonId),
				$primait$pyxis_components$Prima$Pyxis$Modal$onCloseButtonClick(closeEvent)
			]),
		_List_Nil);
};
var $primait$pyxis_components$Prima$Pyxis$Modal$initialHeaderOptions = function (closeEvent) {
	return {
		eY: _List_Nil,
		bX: $elm$core$Maybe$Nothing,
		c5: $elm$core$Maybe$Nothing,
		mQ: _List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButton(closeEvent)
			]),
		lz: _List_Nil
	};
};
var $primait$pyxis_components$Prima$Pyxis$Modal$initialOverlayOptions = {eY: _List_Nil, bX: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, ng: _List_Nil, lz: _List_Nil};
var $primait$pyxis_components$Prima$Pyxis$Modal$initialModalOptions = function (closeEvent) {
	return {
		eY: _List_Nil,
		bU: $primait$pyxis_components$Prima$Pyxis$Modal$initialBodyOptions,
		bX: $elm$core$Maybe$Nothing,
		c5: $elm$core$Maybe$Nothing,
		ng: _List_Nil,
		b4: $primait$pyxis_components$Prima$Pyxis$Modal$initialFooterOptions,
		b6: $primait$pyxis_components$Prima$Pyxis$Modal$initialHeaderOptions(closeEvent),
		nM: $elm$core$Maybe$Nothing,
		cr: $primait$pyxis_components$Prima$Pyxis$Modal$initialOverlayOptions,
		lz: _List_Nil,
		eE: $elm$core$Maybe$Nothing
	};
};
var $primait$pyxis_components$Prima$Pyxis$Modal$medium = F2(
	function (initialVisibility, closeEvent) {
		return {
			ad: closeEvent,
			cc: 0,
			ku: $primait$pyxis_components$Prima$Pyxis$Modal$initialModalOptions(closeEvent),
			aH: 1,
			lZ: initialVisibility
		};
	});
var $author$project$Guarantees$View$Helpers$formatPaymentFrequency = function (paymentFrequency) {
	if (!paymentFrequency) {
		return ' / anno';
	} else {
		return ' / mese';
	}
};
var $author$project$Guarantees$View$Helpers$priceView = function (price) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fs-large fw-base c-text-dark')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Utils$pricify(price))
			]));
};
var $author$project$Guarantees$View$Helpers$priceWitFrequencyView = F2(
	function (price, paymentFrequency) {
		return A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Guarantees$View$Helpers$priceView(price),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-text-dark')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Guarantees$View$Helpers$formatPaymentFrequency(paymentFrequency))
						]))
				]));
	});
var $author$project$Checkout$PackageModal$View$packagePriceView = function (packagePrice) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency,
		$author$project$Guarantees$View$Helpers$priceWitFrequencyView(packagePrice));
};
var $author$project$Checkout$PackageModal$View$packageModalHeaderView = F3(
	function (packagePrice, userSelection, _package) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex justify-content-space-between align-items-center package-modal-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex fs-large fw-heavy c-text-dark ')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(_package.n6)
						])),
					A2($author$project$Checkout$PackageModal$View$packagePriceView, packagePrice, userSelection)
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$bodyAttributes = function (bodyOptions) {
	return A2(
		$elm$core$List$append,
		bodyOptions.lz,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('modal__content'),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
				bodyOptions.bX,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
					bodyOptions.c5,
					A2($elm$core$List$append, bodyOptions.eY, _List_Nil)))));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$body = function (bodyOptions) {
	return A2(
		$elm$html$Html$div,
		$primait$pyxis_components$Prima$Pyxis$Modal$bodyAttributes(bodyOptions),
		bodyOptions.mQ);
};
var $primait$pyxis_components$Prima$Pyxis$Modal$footerAttributes = function (footerOptions) {
	return A2(
		$elm$core$List$append,
		footerOptions.lz,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('modal__footer'),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
				footerOptions.bX,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
					footerOptions.c5,
					A2($elm$core$List$append, footerOptions.eY, _List_Nil)))));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$footer = function (footerOptions) {
	return A2(
		$elm$html$Html$div,
		$primait$pyxis_components$Prima$Pyxis$Modal$footerAttributes(footerOptions),
		footerOptions.mQ);
};
var $primait$pyxis_components$Prima$Pyxis$Modal$headerAttributes = function (headerOptions) {
	return A2(
		$elm$core$List$append,
		headerOptions.lz,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('modal__header'),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
				headerOptions.bX,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
					headerOptions.c5,
					A2($elm$core$List$append, headerOptions.eY, _List_Nil)))));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$header = function (headerOptions) {
	return A2(
		$elm$html$Html$div,
		$primait$pyxis_components$Prima$Pyxis$Modal$headerAttributes(headerOptions),
		headerOptions.mQ);
};
var $primait$pyxis_components$Prima$Pyxis$Modal$modalAttributes = function (modalOptions) {
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
		modalOptions.eE,
		A2(
			$elm$core$List$append,
			modalOptions.lz,
			A2(
				$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
				modalOptions.nM,
				A2(
					$elm$core$List$append,
					modalOptions.ng,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('modal__wrapper'),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
							modalOptions.bX,
							A2(
								$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
								modalOptions.c5,
								A2($elm$core$List$append, modalOptions.eY, _List_Nil))))))));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$modal = function (modalOptions) {
	return A2(
		$elm$html$Html$div,
		$primait$pyxis_components$Prima$Pyxis$Modal$modalAttributes(modalOptions),
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Modal$header(modalOptions.b6),
				$primait$pyxis_components$Prima$Pyxis$Modal$body(modalOptions.bU),
				$primait$pyxis_components$Prima$Pyxis$Modal$footer(modalOptions.b4)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$Dark = 1;
var $primait$pyxis_components$Prima$Pyxis$Modal$isDarkKind = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Modal$Large = 2;
var $primait$pyxis_components$Prima$Pyxis$Modal$isLargeSize = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Modal$isLightKind = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Modal$isMediumSize = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Modal$Small = 0;
var $primait$pyxis_components$Prima$Pyxis$Modal$isSmallSize = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Modal$overlayId = 'modalOverlay';
var $primait$pyxis_components$Prima$Pyxis$Modal$deObfuscateModalOptions = function (_v0) {
	var modalOptions = _v0;
	return modalOptions;
};
var $primait$pyxis_components$Prima$Pyxis$Modal$pickModalOptions = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.ku;
	},
	$primait$pyxis_components$Prima$Pyxis$Modal$deObfuscateModalOptions);
var $primait$pyxis_components$Prima$Pyxis$Modal$pickOverlayOptions = A2(
	$elm$core$Basics$composeR,
	$primait$pyxis_components$Prima$Pyxis$Modal$pickModalOptions,
	function ($) {
		return $.cr;
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$overlayAttributes = function (modalConfig) {
	var overlayOptions = $primait$pyxis_components$Prima$Pyxis$Modal$pickOverlayOptions(modalConfig);
	return A2(
		$elm$core$List$append,
		overlayOptions.lz,
		A2(
			$elm$core$List$append,
			overlayOptions.ng,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal', true),
							_Utils_Tuple2(
							'modal--small',
							$primait$pyxis_components$Prima$Pyxis$Modal$isSmallSize(modalConfig.aH)),
							_Utils_Tuple2(
							'modal--medium',
							$primait$pyxis_components$Prima$Pyxis$Modal$isMediumSize(modalConfig.aH)),
							_Utils_Tuple2(
							'modal--large',
							$primait$pyxis_components$Prima$Pyxis$Modal$isLargeSize(modalConfig.aH)),
							_Utils_Tuple2(
							'modal--light',
							$primait$pyxis_components$Prima$Pyxis$Modal$isLightKind(modalConfig.cc)),
							_Utils_Tuple2(
							'modal--dark',
							$primait$pyxis_components$Prima$Pyxis$Modal$isDarkKind(modalConfig.cc))
						])),
				A2(
					$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
					overlayOptions.c5,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$maybeCons,
						overlayOptions.bX,
						A2(
							$elm$core$List$append,
							overlayOptions.eY,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id($primait$pyxis_components$Prima$Pyxis$Modal$overlayId)
								])))))));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$overlay = F2(
	function (modalConfig, renderedModal) {
		return A2(
			$elm$html$Html$div,
			$primait$pyxis_components$Prima$Pyxis$Modal$overlayAttributes(modalConfig),
			renderedModal);
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$render = function (_v0) {
	var modalConfig = _v0;
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
		modalConfig.lZ,
		A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$overlay,
			modalConfig,
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Modal$modal(
					$primait$pyxis_components$Prima$Pyxis$Modal$pickModalOptions(modalConfig))
				])));
};
var $primait$pyxis_components$Prima$Pyxis$Modal$updateBodyOptions = F2(
	function (contentOptionsMapper, modalOptions) {
		return _Utils_update(
			modalOptions,
			{
				bU: contentOptionsMapper(modalOptions.bU)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions = F2(
	function (modalOptionsMapper, modalConfig) {
		return _Utils_update(
			modalConfig,
			{
				ku: modalOptionsMapper(
					$primait$pyxis_components$Prima$Pyxis$Modal$pickModalOptions(modalConfig))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent = F2(
	function (content, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			$primait$pyxis_components$Prima$Pyxis$Modal$updateBodyOptions(
				function (bodyOptions) {
					return _Utils_update(
						bodyOptions,
						{mQ: content});
				}),
			modalConfig);
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withClass = F2(
	function (_class, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			function (modalOptions) {
				return _Utils_update(
					modalOptions,
					{
						bX: $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$class(_class))
					});
			},
			modalConfig);
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$onOverlayClick = A2(
	$elm$core$Basics$composeR,
	$primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$onClick(
		$primait$pyxis_components$Prima$Pyxis$Commons$Interceptor$targetWithId($primait$pyxis_components$Prima$Pyxis$Modal$overlayId)),
	A2($elm$core$Basics$composeR, $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$withStopPropagation, $primait$pyxis_components$Prima$Pyxis$Commons$InterceptedEvents$event));
var $primait$pyxis_components$Prima$Pyxis$Modal$updateOverlayOptions = F2(
	function (headerOptionsMapper, modalOptions) {
		return _Utils_update(
			modalOptions,
			{
				cr: headerOptionsMapper(modalOptions.cr)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withCloseOnOverlayClick = function (_v0) {
	var modalConfig = _v0;
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
		$primait$pyxis_components$Prima$Pyxis$Modal$updateOverlayOptions(
			function (overlayOptions) {
				return _Utils_update(
					overlayOptions,
					{
						ng: A2(
							$elm$core$List$cons,
							$primait$pyxis_components$Prima$Pyxis$Modal$onOverlayClick(modalConfig.ad),
							overlayOptions.ng)
					});
			}),
		modalConfig);
};
var $primait$pyxis_components$Prima$Pyxis$Modal$updateFooterOptions = F2(
	function (footerOptionsMapper, modalOptions) {
		return _Utils_update(
			modalOptions,
			{
				b4: footerOptionsMapper(modalOptions.b4)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withFooterContent = F2(
	function (content, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			$primait$pyxis_components$Prima$Pyxis$Modal$updateFooterOptions(
				function (footerOptions) {
					return _Utils_update(
						footerOptions,
						{mQ: content});
				}),
			modalConfig);
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$updateHeaderOptions = F2(
	function (headerOptionsMapper, modalOptions) {
		return _Utils_update(
			modalOptions,
			{
				b6: headerOptionsMapper(modalOptions.b6)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withHeaderContent = F2(
	function (customContent, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			$primait$pyxis_components$Prima$Pyxis$Modal$updateHeaderOptions(
				function (headerOptions) {
					return _Utils_update(
						headerOptions,
						{
							mQ: A2(
								$elm$core$List$cons,
								$primait$pyxis_components$Prima$Pyxis$Modal$headerCloseButton(modalConfig.ad),
								customContent)
						});
				}),
			modalConfig);
	});
var $author$project$Checkout$PackageModal$View$packageModal = F4(
	function (userSelection, quoteVersion, _package, packagePrice) {
		return $primait$pyxis_components$Prima$Pyxis$Modal$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Modal$withFooterContent,
				_List_Nil,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent,
					_List_fromArray(
						[
							A3($author$project$Checkout$PackageModal$View$guaranteeListViewForVersion, userSelection, quoteVersion, _package)
						]),
					$primait$pyxis_components$Prima$Pyxis$Modal$withCloseOnOverlayClick(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderContent,
							_List_fromArray(
								[
									A3($author$project$Checkout$PackageModal$View$packageModalHeaderView, packagePrice, userSelection, _package)
								]),
							A2(
								$primait$pyxis_components$Prima$Pyxis$Modal$withClass,
								'package-modal-wrapper',
								A2(
									$primait$pyxis_components$Prima$Pyxis$Modal$medium,
									true,
									$author$project$Checkout$Model$Toggle(
										$author$project$Checkout$Model$PackageModal($elm$core$Maybe$Nothing)))))))));
	});
var $author$project$Checkout$PackageModal$View$view = F4(
	function (_package, packagePrice, userSelection, quoteVersion) {
		return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A3(
				$elm$core$Maybe$map2,
				A2($author$project$Checkout$PackageModal$View$packageModal, userSelection, quoteVersion),
				_package,
				packagePrice));
	});
var $author$project$Components$QuoteInfoBox$withButtonLabel = F2(
	function (label, config) {
		return _Utils_update(
			config,
			{c2: label});
	});
var $author$project$Components$QuoteInfoBox$withSummaryToggle = F2(
	function (toggleSize, config) {
		return _Utils_update(
			config,
			{
				eF: $elm$core$Maybe$Just(toggleSize)
			});
	});
var $author$project$Checkout$Sidebar$View$checkoutSidebarBoxes = F5(
	function (_v0, currentStep, order, userSelection, quotePrice) {
		var selectedPackage = _v0.ln;
		var save = order.py;
		var packagePrice = A2(
			$elm$core$Maybe$map,
			A3($author$project$Types$Quote$Selection$calculatePackagePriceForVersion, quotePrice, save, userSelection),
			selectedPackage);
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A3($author$project$Checkout$Sidebar$View$guaranteesInfoBoxView, quotePrice, save, userSelection),
						$author$project$Components$QuoteInfoBox$render(
						A2(
							$author$project$Components$QuoteInfoBox$withSummaryToggle,
							0,
							A3(
								$author$project$Checkout$Sidebar$View$mapWithEffectiveDate,
								order,
								currentStep,
								A2(
									$author$project$Components$QuoteInfoBox$withButtonLabel,
									'Riepilogo Dati',
									A2(
										$author$project$Components$QuoteInfoBox$box,
										$author$project$Checkout$Model$Toggle(
											$author$project$Checkout$Model$Summary(true)),
										save.pd))))),
						A4($author$project$Checkout$PackageModal$View$view, selectedPackage, packagePrice, userSelection, save.pd.lU)
					]),
				$primait$pyxis_components$Prima$Pyxis$Container$column));
	});
var $author$project$Checkout$Sidebar$View$checkoutSidebarView = function (_v0) {
	var order = _v0.oK;
	var userSelection = _v0.lQ;
	var options = _v0.ku;
	var currentStep = _v0.de;
	var quotePrice = _v0.k5;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A4(
			$elm$core$Maybe$map3,
			A2($author$project$Checkout$Sidebar$View$checkoutSidebarBoxes, options, currentStep),
			order,
			userSelection,
			quotePrice));
};
var $primait$pyxis_components$Prima$Pyxis$Message$Base = 0;
var $primait$pyxis_components$Prima$Pyxis$Message$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Message$Configuration = F4(
	function (type_, kind, content, options) {
		return {mQ: content, cc: kind, ku: options, ao: type_};
	});
var $primait$pyxis_components$Prima$Pyxis$Message$Error = 3;
var $primait$pyxis_components$Prima$Pyxis$Message$error = function (content) {
	return A4($primait$pyxis_components$Prima$Pyxis$Message$Configuration, 3, 0, content, _List_Nil);
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$Checkout$View$PaymentErrorView$paymentMethodToDescription = function (_v0) {
	var paymentMethod = _v0.oS;
	if (!paymentMethod.$) {
		var pm = paymentMethod.a;
		switch (pm) {
			case 0:
				return '';
			case 1:
				return 'la carta di credito inserita';
			default:
				return 'il tuo conto PayPal';
		}
	} else {
		return 'la carta di credito inserita';
	}
};
var $author$project$Checkout$View$PaymentErrorView$cardErrorMessage = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Non è stato possibile effettuare il pagamento con ' + ($author$project$Checkout$View$PaymentErrorView$paymentMethodToDescription(model) + '. Nessun importo è stato prelevato dal tuo conto.'))
				])),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Ti consigliamo di verificare con l\'emittente se la tua carta è abilitata ai pagamenti online e riprovare con il pagamento, oppure provare con un\'altra carta o altro metodo.')
				]))
		]);
};
var $author$project$Checkout$View$PaymentErrorView$bankCouldHaveSentSMS = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('La banca potrebbe averti inviato un sms di verifica della disponibilità dell\'importo, ma ti confermiamo che non abbiamo effettuato alcun prelievo dal tuo conto corrente.')
		]));
var $author$project$Checkout$View$PaymentErrorView$coundNotPay = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				'Non è stato possibile effettuare il pagamento con ' + ($author$project$Checkout$View$PaymentErrorView$paymentMethodToDescription(model) + '.'))
			]));
};
var $author$project$Checkout$View$PaymentErrorView$pleaseCheckInfos = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('Ti consigliamo di ricontrollare le informazioni inserite e riprovare il pagamento.')
		]));
var $author$project$Checkout$View$PaymentErrorView$defaultErrorMessage = function (model) {
	return _List_fromArray(
		[
			$author$project$Checkout$View$PaymentErrorView$coundNotPay(model),
			$author$project$Checkout$View$PaymentErrorView$bankCouldHaveSentSMS,
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$author$project$Checkout$View$PaymentErrorView$pleaseCheckInfos,
			A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			_Utils_eq(
				model.oS,
				$elm$core$Maybe$Just(1)),
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
					])))
		]);
};
var $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage = function (model) {
	return _List_fromArray(
		[
			$author$project$Checkout$View$PaymentErrorView$coundNotPay(model),
			$author$project$Checkout$View$PaymentErrorView$bankCouldHaveSentSMS,
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Ti invitiamo a riprovare con un\'altra carta.')
				]))
		]);
};
var $author$project$Checkout$View$PaymentErrorView$payPalServerSideErrorMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('')
			]))
	]);
var $author$project$Checkout$View$PaymentErrorView$temporaryErrorMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Si è verificato un errore nella transazione.')
			])),
		$author$project$Checkout$View$PaymentErrorView$bankCouldHaveSentSMS,
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti invitiamo a riprovare o ad utilizzare un altro metodo di pagamento.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
			]))
	]);
var $author$project$Checkout$View$PaymentErrorView$threeDSecureMessageText = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Il processo di verifica di sicurezza non è andato a buon fine.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti confermiamo che non è stato effettuato alcun prelievo dalla tua carta.')
			])),
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti consigliamo di riprovare il pagamento facendo particolare attenzione alle indicazioni fornite dalla tua banca durante l\'operazione.')
			]))
	]);
var $author$project$Checkout$View$PaymentErrorView$verificationMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Il processo di verifica non è andato a buon fine.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti confermiamo che non è stato effettuato alcun prelievo dalla tua carta.')
			])),
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti consigliamo di ricontrollare le informazioni inserite e riprovare il pagamento.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
			]))
	]);
var $author$project$Checkout$View$PaymentErrorView$errorMessageText = function (model) {
	var messageText = function () {
		var _v0 = model.mp.b0;
		if (!_v0.$) {
			if (!_v0.a.$) {
				var errorEvent = _v0.a.a;
				if (A2($elm$core$String$startsWith, 'THREEDS_', errorEvent.mH)) {
					return $author$project$Checkout$View$PaymentErrorView$threeDSecureMessageText;
				} else {
					var _v1 = errorEvent.b0;
					if (_v1 === 'Unsupported card type') {
						return $author$project$Checkout$View$PaymentErrorView$cardErrorMessage(model);
					} else {
						return $author$project$Checkout$View$PaymentErrorView$verificationMessage;
					}
				}
			} else {
				var serverErrorEvent = _v0.a.a;
				if (_Utils_eq(
					model.oS,
					$elm$core$Maybe$Just(2))) {
					return $author$project$Checkout$View$PaymentErrorView$payPalServerSideErrorMessage;
				} else {
					var _v2 = serverErrorEvent.mH;
					if (!_v2) {
						return $author$project$Checkout$View$PaymentErrorView$cardErrorMessage(model);
					} else {
						var _v3 = serverErrorEvent.of;
						switch (_v3) {
							case 'AuthorizationExpired':
								return $author$project$Checkout$View$PaymentErrorView$temporaryErrorMessage;
							case 'Authorized':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'Authorizing':
								return $author$project$Checkout$View$PaymentErrorView$defaultErrorMessage(model);
							case 'Failed':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'GatewayRejected':
								return $author$project$Checkout$View$PaymentErrorView$temporaryErrorMessage;
							case 'ProcessorDeclined':
								return $author$project$Checkout$View$PaymentErrorView$temporaryErrorMessage;
							case 'Settled':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'SettlementDeclined':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'SettlementPending':
								return $author$project$Checkout$View$PaymentErrorView$temporaryErrorMessage;
							case 'Settling':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'SubmittedForSettlement':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							case 'Voided':
								return $author$project$Checkout$View$PaymentErrorView$failedTransactionMessage(model);
							default:
								return $author$project$Checkout$View$PaymentErrorView$defaultErrorMessage(model);
						}
					}
				}
			}
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'payment-error-message')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-heavy')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Operazione non riuscita.')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fs-small')
					]),
				messageText)
			]));
};
var $author$project$Checkout$View$PaymentErrorView$isErrorIgnored = function (model) {
	var _v0 = model.mp.b0;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var clientErrorEvent = _v0.a.a;
			var _v1 = clientErrorEvent.mH;
			switch (_v1) {
				case 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME':
					return true;
				case 'PAYPAL_POPUP_OPEN_FAILED':
					return true;
				case 'PAYPAL_POPUP_CLOSED':
					return true;
				default:
					return false;
			}
		} else {
			return false;
		}
	} else {
		return true;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Message$Alert = 2;
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageAlert = $elm$core$Basics$eq(2);
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageError = $elm$core$Basics$eq(3);
var $primait$pyxis_components$Prima$Pyxis$Message$Info = 0;
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageInfo = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Message$Alt = 1;
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageKindAlt = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageKindBase = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Message$Success = 1;
var $primait$pyxis_components$Prima$Pyxis$Message$isMessageSuccess = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Message$buildClassList = F2(
	function (_v0, options) {
		var type_ = _v0.ao;
		var kind = _v0.cc;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$append,
				A2(
					$elm$core$List$map,
					A2($primait$pyxis_components$Prima$Pyxis$Helpers$flip, $elm$core$Tuple$pair, true),
					options.c6),
				A2(
					$elm$core$List$append,
					options.c5,
					_List_fromArray(
						[
							_Utils_Tuple2('message', true),
							_Utils_Tuple2(
							'message--primary',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageSuccess(type_)),
							_Utils_Tuple2(
							'message--info',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageInfo(type_)),
							_Utils_Tuple2(
							'message--alert',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageAlert(type_)),
							_Utils_Tuple2(
							'message--error',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageError(type_)),
							_Utils_Tuple2(
							'message--base',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageKindBase(kind)),
							_Utils_Tuple2(
							'message--alt',
							$primait$pyxis_components$Prima$Pyxis$Message$isMessageKindAlt(kind))
						]))));
	});
var $primait$pyxis_components$Prima$Pyxis$Message$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 1:
				var list = modifier.a;
				return _Utils_update(
					options,
					{
						c5: A2($elm$core$List$append, list, options.c5)
					});
			case 2:
				var action = modifier.a;
				return _Utils_update(
					options,
					{
						ng: A2($elm$core$List$cons, action, options.ng)
					});
			case 3:
				var attr = modifier.a;
				return _Utils_update(
					options,
					{
						eY: A2($elm$core$List$cons, attr, options.eY)
					});
			default:
				return _Utils_update(
					options,
					{eM: true});
		}
	});
var $primait$pyxis_components$Prima$Pyxis$Message$defaultOptions = {eY: _List_Nil, c5: _List_Nil, c6: _List_Nil, ng: _List_Nil, eM: false};
var $primait$pyxis_components$Prima$Pyxis$Message$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $primait$pyxis_components$Prima$Pyxis$Message$applyOption, $primait$pyxis_components$Prima$Pyxis$Message$defaultOptions, config.ku);
};
var $primait$pyxis_components$Prima$Pyxis$Message$buildAttributes = function (messageConfig) {
	var options = $primait$pyxis_components$Prima$Pyxis$Message$computeOptions(messageConfig);
	return A2(
		$elm$core$List$append,
		options.ng,
		A2(
			$elm$core$List$append,
			options.eY,
			_List_fromArray(
				[
					A2($primait$pyxis_components$Prima$Pyxis$Message$buildClassList, messageConfig, options)
				])));
};
var $elm$virtual_dom$VirtualDom$nodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_nodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconAlert = A3(
	$elm$svg$Svg$node,
	'svg',
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'role', 'icon'),
			A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
			A2($elm$html$Html$Attributes$attribute, 'viewBox', '0 0 1024 1024')
		]),
	_List_fromArray(
		[
			A3(
			$elm$svg$Svg$node,
			'title',
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('alert')
				])),
			A3(
			$elm$svg$Svg$node,
			'path',
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'd', 'M528 857.6c-121.6 0-236.8-12.8-345.6-35.2l-16-3.2c-6.4 0-12.8-6.4-16-12.8s-3.2-12.8-3.2-19.2c3.2-6.4 3.2-12.8 6.4-19.2 19.2-51.2 41.6-102.4 64-153.6 80-172.8 176-326.4 275.2-441.6 3.2-6.4 9.6-9.6 19.2-9.6 6.4 0 12.8 3.2 19.2 9.6C630.4 288 726.4 441.6 806.4 614.4c22.4 51.2 44.8 102.4 64 153.6 3.2 9.6 6.4 16 9.6 25.6 3.2 6.4 3.2 12.8-3.2 19.2-3.2 6.4-9.6 9.6-16 12.8-6.4 0-12.8 3.2-22.4 3.2-99.2 19.2-204.8 28.8-310.4 28.8zm-326.4-76.8c102.4 19.2 211.2 32 326.4 32 102.4 0 201.6-9.6 294.4-25.6-19.2-48-38.4-99.2-60.8-150.4C688 476.8 601.6 336 512 227.2 422.4 336 336 476.8 262.4 636.8c-22.4 48-41.6 96-60.8 144zM512 614.4c-12.8 0-22.4-9.6-22.4-22.4V409.6c0-12.8 9.6-22.4 22.4-22.4s22.4 9.6 22.4 22.4V592c0 9.6-9.6 22.4-22.4 22.4zm0 108.8c-12.8 0-22.4-9.6-22.4-22.4v-9.6c0-12.8 9.6-22.4 22.4-22.4s22.4 9.6 22.4 22.4v9.6c0 9.6-9.6 22.4-22.4 22.4z')
				]),
			_List_Nil)
		]));
var $primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconError = A3(
	$elm$svg$Svg$node,
	'svg',
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'role', 'icon'),
			A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
			A2($elm$html$Html$Attributes$attribute, 'viewBox', '0 0 1024 1024')
		]),
	_List_fromArray(
		[
			A3(
			$elm$svg$Svg$node,
			'title',
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('error')
				])),
			A3(
			$elm$svg$Svg$node,
			'path',
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'd', 'M512 873.6c-198.4 0-361.6-163.2-361.6-361.6S313.6 150.4 512 150.4 873.6 313.6 873.6 512 710.4 873.6 512 873.6zm0-681.6c-176 0-320 144-320 320s144 320 320 320 320-144 320-320-144-320-320-320zm0 361.6c-12.8 0-22.4-9.6-22.4-22.4V352c0-12.8 9.6-22.4 22.4-22.4s22.4 9.6 22.4 22.4v179.2c0 12.8-9.6 22.4-22.4 22.4zm38.4 83.2c0 22.4-16 38.4-38.4 38.4s-38.4-16-38.4-38.4 16-38.4 38.4-38.4 38.4 19.2 38.4 38.4z')
				]),
			_List_Nil)
		]));
var $primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconInfo = A3(
	$elm$svg$Svg$node,
	'svg',
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'role', 'icon'),
			A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
			A2($elm$html$Html$Attributes$attribute, 'viewBox', '0 0 1024 1024')
		]),
	_List_fromArray(
		[
			A3(
			$elm$svg$Svg$node,
			'title',
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('info')
				])),
			A3(
			$elm$svg$Svg$node,
			'path',
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'd', 'M512 873.6c-198.4 0-361.6-163.2-361.6-361.6S313.6 150.4 512 150.4 873.6 313.6 873.6 512 710.4 873.6 512 873.6zm0-681.6c-176 0-320 144-320 320s144 320 320 320 320-144 320-320-144-320-320-320zm0 550.4c-12.8 0-22.4-9.6-22.4-22.4V454.4c0-12.8 9.6-22.4 22.4-22.4s22.4 9.6 22.4 22.4V720c0 12.8-9.6 22.4-22.4 22.4zm0-342.4c-12.8 0-22.4-9.6-22.4-22.4v-38.4c0-12.8 9.6-22.4 22.4-22.4s22.4 9.6 22.4 22.4v38.4c0 12.8-9.6 22.4-22.4 22.4z')
				]),
			_List_Nil)
		]));
var $primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconOk = A3(
	$elm$svg$Svg$node,
	'svg',
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'role', 'icon'),
			A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
			A2($elm$html$Html$Attributes$attribute, 'viewBox', '0 0 1024 1024')
		]),
	_List_fromArray(
		[
			A3(
			$elm$svg$Svg$node,
			'title',
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('ok')
				])),
			A3(
			$elm$svg$Svg$node,
			'path',
			_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'd', 'M275.2 502.4c0 9.6-9.6 19.2-19.2 19.2s-19.2-9.6-19.2-19.2 9.6-19.2 19.2-19.2 19.2 9.6 19.2 19.2zm614.4-6.4v192c0 48-25.6 76.8-35.2 89.6l-41.6 44.8c-28.8 28.8-67.2 32-76.8 32H499.2c-54.4 0-86.4-28.8-92.8-35.2l-32-35.2v3.2c0 35.2-28.8 64-64 64H201.6c-35.2 0-64-28.8-64-64v-336c0-35.2 28.8-64 64-64h105.6c32 0 60.8 25.6 64 57.6l128-131.2 38.4-140.8c3.2-12.8 16-32 38.4-32h67.2c19.2 0 35.2 12.8 35.2 32v230.4h108.8c12.8 0 35.2 6.4 57.6 28.8l28.8 28.8c9.6 9.6 16 22.4 16 35.2zM336 784V451.2c0-16-12.8-28.8-28.8-28.8H201.6c-16 0-28.8 12.8-28.8 28.8V784c0 16 12.8 28.8 28.8 28.8h105.6c16 0 28.8-12.8 28.8-28.8zm518.4-288c0-6.4-3.2-9.6-6.4-16l-28.8-28.8c-12.8-12.8-25.6-16-28.8-16H675.2c-16-3.2-28.8-12.8-28.8-41.6V179.2H576l-3.2 3.2-41.6 144c0 3.2-3.2 6.4-6.4 9.6L368 499.2v233.6l60.8 64c3.2 3.2 25.6 25.6 67.2 25.6h240c3.2 0 32 0 51.2-19.2l41.6-44.8c6.4-6.4 25.6-28.8 25.6-64z')
				]),
			_List_Nil)
		]));
var $primait$pyxis_components$Prima$Pyxis$Message$render = function (config) {
	var type_ = config.ao;
	var content = config.mQ;
	return A2(
		$elm$html$Html$div,
		$primait$pyxis_components$Prima$Pyxis$Message$buildAttributes(config),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__icon')
					]),
				$primait$pyxis_components$Prima$Pyxis$Message$computeOptions(config).eM ? _List_fromArray(
					[
						A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
						$primait$pyxis_components$Prima$Pyxis$Message$isMessageSuccess(type_),
						$primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconOk),
						A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
						$primait$pyxis_components$Prima$Pyxis$Message$isMessageInfo(type_),
						$primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconInfo),
						A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
						$primait$pyxis_components$Prima$Pyxis$Message$isMessageAlert(type_),
						$primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconAlert),
						A2(
						$primait$pyxis_components$Prima$Pyxis$Helpers$renderIf,
						$primait$pyxis_components$Prima$Pyxis$Message$isMessageError(type_),
						$primait$pyxis_components$Prima$Pyxis$Message$Icons$renderIconError)
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'icon icon-ok',
										$primait$pyxis_components$Prima$Pyxis$Message$isMessageSuccess(type_)),
										_Utils_Tuple2(
										'icon icon-info',
										$primait$pyxis_components$Prima$Pyxis$Message$isMessageInfo(type_)),
										_Utils_Tuple2(
										'icon icon-attention',
										$primait$pyxis_components$Prima$Pyxis$Message$isMessageAlert(type_)),
										_Utils_Tuple2(
										'icon icon-danger',
										$primait$pyxis_components$Prima$Pyxis$Message$isMessageError(type_))
									]))
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__content')
					]),
				content)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Message$Class = function (a) {
	return {$: 0, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Message$addOption = F2(
	function (option, _v0) {
		var messageConfig = _v0;
		return _Utils_update(
			messageConfig,
			{
				ku: _Utils_ap(
					messageConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Message$withClass = function (class_) {
	return $primait$pyxis_components$Prima$Pyxis$Message$addOption(
		$primait$pyxis_components$Prima$Pyxis$Message$Class(class_));
};
var $author$project$Checkout$View$PaymentErrorView$braintreeErrorMessage = function (model) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		$author$project$Checkout$Model$isPaymentStep(model) && ($elm_community$maybe_extra$Maybe$Extra$isJust(model.mp.b0) && (!$author$project$Checkout$View$PaymentErrorView$isErrorIgnored(model))),
		$primait$pyxis_components$Prima$Pyxis$Message$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Message$withClass,
				'bg-background-dark',
				$primait$pyxis_components$Prima$Pyxis$Message$error(
					_List_fromArray(
						[
							$author$project$Checkout$View$PaymentErrorView$errorMessageText(model)
						])))));
};
var $author$project$Checkout$Model$isNotDefinedSave = A2(
	$elm$core$Basics$composeR,
	$author$project$Checkout$Model$getSaveType,
	$elm$core$Basics$eq(2));
var $primait$pyxis_components$Prima$Pyxis$Loader$Medium = 1;
var $primait$pyxis_components$Prima$Pyxis$Loader$isLoaderMedium = $elm$core$Basics$eq(1);
var $primait$pyxis_components$Prima$Pyxis$Loader$Small = 0;
var $primait$pyxis_components$Prima$Pyxis$Loader$isLoaderSmall = $elm$core$Basics$eq(0);
var $primait$pyxis_components$Prima$Pyxis$Loader$renderSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('loader__spinner')
		]),
	_List_Nil);
var $primait$pyxis_components$Prima$Pyxis$Loader$renderText = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('loader__text fs-small fw-heavy c-text-dark')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(content)
					]))
			]));
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $primait$pyxis_components$Prima$Pyxis$Loader$renderVehicle = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('150'),
			$elm$svg$Svg$Attributes$height('50'),
			$elm$svg$Svg$Attributes$class('loader__car')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('loader__car__group')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__wheel'),
							$elm$svg$Svg$Attributes$d('M184.9,106.3c-12.2,0-21.8-9.6-21.8-21.8s9.6-21.8,21.8-21.8c12.2,0,21.8,9.6,21.8,21.8 S197.1,106.3,184.9,106.3z M184.9,71.2c-7.4,0-12.8,5.9-12.8,12.8c0,7.4,5.9,12.8,12.8,12.8c7.4,0,12.8-5.9,12.8-12.8 C198.2,77.1,192.4,71.2,184.9,71.2z M281.2,106.3c-12.2,0-21.8-9.6-21.8-21.8s9.6-21.8,21.8-21.8c12.2,0,21.8,9.6,21.8,21.8 S292.9,106.3,281.2,106.3z M281.2,71.2c-7.4,0-12.8,5.9-12.8,12.8c0,7.4,5.9,12.8,12.8,12.8c7.4,0,12.8-5.9,12.8-12.8 S288.1,71.2,281.2,71.2z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__body'),
							$elm$svg$Svg$Attributes$d('M308.8,47.8l-43.6-9c-1.1-0.5-2.7-1.1-3.2-2.1l-17.6-22.9c-2.7-3.7-6.9-5.9-11.2-5.9h-60.6 c-4.3,0-8.5,2.1-11.2,5.3l-28.2,33.5c-3.2,3.7-4.3,9-3.2,13.8l4.8,18.1c1.6,6.4,7.4,10.6,13.8,10.6h17c2.1,0,4.3-2.1,4.3-4.3 c0-2.1-1.6-4.3-4.3-4.3h-17c-2.7,0-4.8-1.6-5.3-4.3l-4.8-18.1c-0.5-2.1,0-4.3,1.1-5.9l28.2-33.5c1.1-1.6,2.7-2.1,4.3-2.1h60.6 c1.6,0,3.2,0.5,4.3,2.1l17.6,22.9c2.1,2.7,5.3,4.8,8.5,5.3l43.6,9c2.7,0.5,4.8,3.2,4.8,5.9v12.2c0,3.2-2.7,5.9-5.9,5.9h-6.4 c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h6.4c8,0,14.4-6.4,14.9-14.4V62.2C320.6,55.2,315.8,49.4,308.8,47.8z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__body'),
							$elm$svg$Svg$Attributes$d('M241.8,40.9c0-2.1-1.6-4.3-4.3-4.3h-69.7c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h69.7 C239.7,45.1,241.8,43,241.8,40.9z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__body'),
							$elm$svg$Svg$Attributes$d('M263.6,80.2h-61.2c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h61.2c2.1,0,4.3-2.1,4.3-4.3 C267.9,82.4,266.3,80.2,263.6,80.2z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$line,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__line loader__car__group__line--top'),
							$elm$svg$Svg$Attributes$x1('68'),
							$elm$svg$Svg$Attributes$y1('46.7'),
							$elm$svg$Svg$Attributes$x2('114.2'),
							$elm$svg$Svg$Attributes$y2('46.7')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$line,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__line loader__car__group__line--bottom'),
							$elm$svg$Svg$Attributes$x1('33.2'),
							$elm$svg$Svg$Attributes$y1('65.9'),
							$elm$svg$Svg$Attributes$x2('99.4'),
							$elm$svg$Svg$Attributes$y2('65.9')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$line,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__group__line loader__car__group__line--middle'),
							$elm$svg$Svg$Attributes$x1('68'),
							$elm$svg$Svg$Attributes$y1('84.5'),
							$elm$svg$Svg$Attributes$x2('114.2'),
							$elm$svg$Svg$Attributes$y2('84.5')
						]),
					_List_Nil)
				]))
		]));
var $primait$pyxis_components$Prima$Pyxis$Loader$render = function (_v0) {
	var size = _v0.aH;
	var type_ = _v0.ao;
	var text = _v0.lG;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loader', true),
						_Utils_Tuple2(
						'loader--small',
						$primait$pyxis_components$Prima$Pyxis$Loader$isLoaderSmall(size)),
						_Utils_Tuple2(
						'loader--medium',
						$primait$pyxis_components$Prima$Pyxis$Loader$isLoaderMedium(size))
					]))
			]),
		_List_fromArray(
			[
				function () {
				if (!type_) {
					return $primait$pyxis_components$Prima$Pyxis$Loader$renderSpinner;
				} else {
					return $primait$pyxis_components$Prima$Pyxis$Loader$renderVehicle;
				}
			}(),
				A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$elm$html$Html$text(''),
				$primait$pyxis_components$Prima$Pyxis$Loader$renderText,
				text)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Loader$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Loader$Configuration = F3(
	function (type_, size, text) {
		return {aH: size, lG: text, ao: type_};
	});
var $primait$pyxis_components$Prima$Pyxis$Loader$Spinner = 0;
var $primait$pyxis_components$Prima$Pyxis$Loader$spinner = A3($primait$pyxis_components$Prima$Pyxis$Loader$Configuration, 0, 1, $elm$core$Maybe$Nothing);
var $primait$pyxis_components$Prima$Pyxis$Loader$Vehicle = 1;
var $primait$pyxis_components$Prima$Pyxis$Loader$vehicle = A3($primait$pyxis_components$Prima$Pyxis$Loader$Configuration, 1, 1, $elm$core$Maybe$Nothing);
var $primait$pyxis_components$Prima$Pyxis$Loader$withText = F2(
	function (txt, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				lG: $elm$core$Maybe$Just(txt)
			});
	});
var $author$project$Components$Loader$view = F2(
	function (loaderType, text) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('o-page-loader')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('o-page-loader__container')
						]),
					_List_fromArray(
						[
							function () {
							if (!loaderType) {
								return $primait$pyxis_components$Prima$Pyxis$Loader$render(
									A2($primait$pyxis_components$Prima$Pyxis$Loader$withText, text, $primait$pyxis_components$Prima$Pyxis$Loader$spinner));
							} else {
								return $primait$pyxis_components$Prima$Pyxis$Loader$render(
									A2($primait$pyxis_components$Prima$Pyxis$Loader$withText, text, $primait$pyxis_components$Prima$Pyxis$Loader$vehicle));
							}
						}()
						]))
				]));
	});
var $author$project$Checkout$View$checkoutLoader = function (model) {
	var brainTreeModel = model.mp;
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		(!brainTreeModel.c0) || (($author$project$Checkout$Model$isPaymentStep(model) && ((!brainTreeModel.jI) || (!brainTreeModel.jQ))) || $author$project$Checkout$Model$isNotDefinedSave(model)),
		A2($author$project$Components$Loader$view, 0, 'Ancora qualche secondo... Stiamo elaborando i tuoi dati'));
};
var $author$project$View$AccordionCard$accordionCard = F4(
	function (maybeTitle, content, reader, tagger) {
		return {mQ: content, fX: tagger, fY: maybeTitle, hi: reader};
	});
var $author$project$Checkout$Model$ConfirmEffectiveDate = {$: 12};
var $primait$pyxis_components$Prima$Pyxis$Button$callOut = function (label) {
	return A6($primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig, 0, false, 0, label, $elm$core$Maybe$Nothing, _List_Nil);
};
var $author$project$Checkout$Model$pickDateFromDatePickerDate = function (datePickerDate) {
	if (!datePickerDate.$) {
		var date = datePickerDate.a;
		return $elm$core$Maybe$Just(date);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Checkout$Model$pickSaveExpireDate = function (model) {
	var _v0 = model.g4;
	if ((_v0.$ === 3) && (!_v0.a.$)) {
		var saveExpiration = _v0.a.a.lh;
		return $elm$core$Maybe$Just(
			$author$project$Utils$Time$convertPosixToDate(saveExpiration));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Validation$ErrorWithMessage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Utils$Languages$It$dayWithSuffix = function (_v0) {
	return '';
};
var $author$project$Utils$Languages$It$monthNameShort = function (month) {
	switch (month) {
		case 0:
			return 'Gennaio';
		case 1:
			return 'Febbraio';
		case 2:
			return 'Marzo';
		case 3:
			return 'Aprile';
		case 4:
			return 'Maggio';
		case 5:
			return 'Giugno';
		case 6:
			return 'Luglio';
		case 7:
			return 'Agosto';
		case 8:
			return 'Settembre';
		case 9:
			return 'Ottobre';
		case 10:
			return 'Novembre';
		default:
			return 'Dicembre';
	}
};
var $author$project$Utils$Languages$It$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'Gennaio';
		case 1:
			return 'Febbraio';
		case 2:
			return 'Marzo';
		case 3:
			return 'Aprile';
		case 4:
			return 'Maggio';
		case 5:
			return 'Giugno';
		case 6:
			return 'Luglio';
		case 7:
			return 'Agosto';
		case 8:
			return 'Settembre';
		case 9:
			return 'Ottobre';
		case 10:
			return 'Novembre';
		default:
			return 'Dicembre';
	}
};
var $author$project$Utils$Languages$It$weekdayNameShort = function (wd) {
	switch (wd) {
		case 0:
			return 'Lunedì';
		case 1:
			return 'Martedì';
		case 2:
			return 'Mercoledì';
		case 3:
			return 'Giovedì';
		case 4:
			return 'Venerdì';
		case 5:
			return 'Sabato';
		default:
			return 'Domenica';
	}
};
var $author$project$Utils$Languages$It$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Lunedì';
		case 1:
			return 'Martedì';
		case 2:
			return 'Mercoledì';
		case 3:
			return 'Giovedì';
		case 4:
			return 'Venerdì';
		case 5:
			return 'Sabato';
		default:
			return 'Domenica';
	}
};
var $author$project$Utils$Languages$It$it = {m_: $author$project$Utils$Languages$It$dayWithSuffix, on: $author$project$Utils$Languages$It$monthToName, oo: $author$project$Utils$Languages$It$monthNameShort, qc: $author$project$Utils$Languages$It$weekdayToName, qd: $author$project$Utils$Languages$It$weekdayNameShort};
var $author$project$Checkout$EffectiveDate$View$formatDate = function (date) {
	return A3($justinmimbs$date$Date$formatWithLanguage, $author$project$Utils$Languages$It$it, 'd MMMM y', date);
};
var $author$project$Checkout$EffectiveDate$View$validateRange = F3(
	function (effectiveDate_, saveExpireDate, today) {
		return A3($justinmimbs$date$Date$isBetween, today, saveExpireDate, effectiveDate_) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			$primait$pyxis_components$Prima$Pyxis$Form$Validation$ErrorWithMessage(
				'La decorrenza deve essere tra le date ' + ($author$project$Checkout$EffectiveDate$View$formatDate(today) + (' e ' + $author$project$Checkout$EffectiveDate$View$formatDate(saveExpireDate)))));
	});
var $author$project$Checkout$EffectiveDate$View$effectiveDateValidation = function (model) {
	return $elm_community$maybe_extra$Maybe$Extra$join(
		A4(
			$elm$core$Maybe$map3,
			$author$project$Checkout$EffectiveDate$View$validateRange,
			$author$project$Checkout$Model$pickDateFromDatePickerDate(model.m8),
			$author$project$Checkout$Model$pickSaveExpireDate(model),
			model.iH));
};
var $author$project$Checkout$EffectiveDate$View$isEffectiveDateValid = function (model) {
	return $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$author$project$Checkout$EffectiveDate$View$effectiveDateValidation(model)) && $elm_community$maybe_extra$Maybe$Extra$isJust(
		$author$project$Checkout$Model$pickDateFromDatePickerDate(model.m8));
};
var $author$project$Checkout$EffectiveDate$View$confirmButtonView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__confirm-btn-wrapper')
			]),
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Button$render(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					!$author$project$Checkout$EffectiveDate$View$isEffectiveDateValid(model),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						$author$project$Checkout$Model$ConfirmEffectiveDate,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withId,
							'effective-date-confirm-btn',
							$primait$pyxis_components$Prima$Pyxis$Button$callOut('Conferma')))))
			]));
};
var $author$project$Checkout$EffectiveDate$View$effectiveDateDescription = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('display-flex flex-direction-row align-items-center effective-date-description')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon icon-ok fs-xxlarge c-brand-base')
						]),
					_List_Nil)
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fs-xsmall')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('La decorrenza della polizza indica la data a partire dalla quale verrà attivata la copertura assicurativa.')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Seleziona la data di decorrenza tenendo conto che questa data non può essere retroattiva: '),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw-heavy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('la tua copertura sarà valida a partire dalle ore 24:00 del giorno indicato.')
								]))
						]))
				]))
		]));
var $author$project$Checkout$Model$OnFocus = {$: 1};
var $author$project$Checkout$Model$OnIconClick = {$: 4};
var $author$project$Checkout$Model$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Checkout$Model$OnUpdate = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Date = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Form$Date$DateConfig = F3(
	function (options, reader, tagger) {
		return {ku: options, hi: reader, hS: tagger};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$date = F2(
	function (reader, tagger) {
		return A3($primait$pyxis_components$Prima$Pyxis$Form$Date$DateConfig, _List_Nil, reader, tagger);
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$DatePicker = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption = F2(
	function (option, _v0) {
		var inputConfig = _v0;
		return _Utils_update(
			inputConfig,
			{
				ku: _Utils_ap(
					inputConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withDatePicker = F2(
	function (reader, tagger) {
		return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
			A2($primait$pyxis_components$Prima$Pyxis$Form$Date$DatePicker, reader, tagger));
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Date$DatePickerVisibility = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withDatePickerVisibility = function (reader) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$DatePickerVisibility(reader));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Id = function (a) {
	return {$: 6, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withId = function (id) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$Id(id));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Large = 2;
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Size = function (a) {
	return {$: 13, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withLargeSize = $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
	$primait$pyxis_components$Prima$Pyxis$Form$Date$Size(2));
var $primait$pyxis_components$Prima$Pyxis$Form$Date$OnFocus = function (a) {
	return {$: 10, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withOnFocus = function (tagger) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$OnFocus(tagger));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$OnIconClick = function (a) {
	return {$: 11, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withOnIconClick = function (tagger) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$OnIconClick(tagger));
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$Validation = function (a) {
	return {$: 14, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Date$withValidation = function (validation) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Date$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$Validation(validation));
};
var $author$project$Checkout$EffectiveDate$View$effectiveDate = function (model) {
	var slug = 'effective-date';
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Form$Date$render,
		model,
		A2(
			$primait$pyxis_components$Prima$Pyxis$Form$Date$withOnIconClick,
			$author$project$Checkout$Model$EffectiveDate($author$project$Checkout$Model$OnIconClick),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Form$Date$withValidation,
				$author$project$Checkout$EffectiveDate$View$effectiveDateValidation,
				$primait$pyxis_components$Prima$Pyxis$Form$Date$withLargeSize(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Form$Date$withDatePickerVisibility,
						function ($) {
							return $.n1;
						},
						A3(
							$primait$pyxis_components$Prima$Pyxis$Form$Date$withDatePicker,
							function ($) {
								return $.m9;
							},
							A2($elm$core$Basics$composeL, $author$project$Checkout$Model$EffectiveDate, $author$project$Checkout$Model$OnUpdate),
							A2(
								$primait$pyxis_components$Prima$Pyxis$Form$Date$withOnFocus,
								$author$project$Checkout$Model$EffectiveDate($author$project$Checkout$Model$OnFocus),
								A2(
									$primait$pyxis_components$Prima$Pyxis$Form$Date$withId,
									slug,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Form$Date$date,
										function ($) {
											return $.m8;
										},
										A2($elm$core$Basics$composeL, $author$project$Checkout$Model$EffectiveDate, $author$project$Checkout$Model$OnInput))))))))));
};
var $author$project$Checkout$EffectiveDate$View$effectiveDateForm = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('effective-date-input display-flex'),
				A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'effective-date-input')
			]),
		$author$project$Checkout$EffectiveDate$View$effectiveDate(model));
};
var $author$project$Checkout$EffectiveDate$View$cardContentView = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Seleziona la data')
				])),
			$author$project$Checkout$EffectiveDate$View$effectiveDateForm(model),
			$author$project$Checkout$EffectiveDate$View$confirmButtonView(model),
			$author$project$Checkout$EffectiveDate$View$effectiveDateDescription
		]);
};
var $author$project$Checkout$EffectiveDate$View$todayDateView = function (currentDate) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fs-xsmall fw-light checkout-current-date')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Oggi è il'),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge--primary margin-left--10')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Checkout$EffectiveDate$View$formatDate(currentDate))
					]))
			]));
};
var $author$project$Checkout$EffectiveDate$View$cardTitleView = function (_v0) {
	var currentDate = _v0.iH;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container flex-direction-row justify-content-space-between align-items-center full-height checkout-card-title')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fs-base')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Data di decorrenza')
					])),
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Checkout$EffectiveDate$View$todayDateView, currentDate))
			]));
};
var $author$project$View$AccordionCard$isOpen = function (_v0) {
	var reader = _v0.hi;
	return reader;
};
var $author$project$View$AccordionCard$contentView = F2(
	function (model, config) {
		var wrapperClassList = _List_fromArray(
			[
				_Utils_Tuple2(
				'is-closed',
				!A2($author$project$View$AccordionCard$isOpen, config, model))
			]);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('accordion-card--content accordion__content '),
					$elm$html$Html$Attributes$classList(wrapperClassList)
				]),
			config.mQ);
	});
var $author$project$View$AccordionCard$titleView = F2(
	function (config, title) {
		var customClass = $elm$html$Html$Attributes$class('accordion-card--title accordion__toggle');
		var attributes = A2(
			$elm$core$List$cons,
			customClass,
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				_List_Nil,
				A2($elm$core$Basics$composeR, $elm$html$Html$Events$onClick, $elm$core$List$singleton),
				config.fX));
		return A2(
			$elm$html$Html$div,
			attributes,
			_List_fromArray(
				[title]));
	});
var $author$project$View$AccordionCard$render = F2(
	function (model, config) {
		var wrapperClasses = 'accordion accordion-card accordion--light ';
		var wrapperClassList = _List_fromArray(
			[
				_Utils_Tuple2(
				'is-open',
				A2($author$project$View$AccordionCard$isOpen, config, model))
			]);
		var accordionContent = A2($author$project$View$AccordionCard$contentView, model, config);
		var _v0 = config.fY;
		if (!_v0.$) {
			var title = _v0.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(wrapperClasses),
						$elm$html$Html$Attributes$classList(wrapperClassList)
					]),
				_List_fromArray(
					[
						A2($author$project$View$AccordionCard$titleView, config, title),
						accordionContent
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(wrapperClasses)
					]),
				_List_fromArray(
					[accordionContent]));
		}
	});
var $author$project$Checkout$EffectiveDate$View$view = function (model) {
	return A2(
		$author$project$View$AccordionCard$render,
		model,
		A4(
			$author$project$View$AccordionCard$accordionCard,
			$elm$core$Maybe$Just(
				$author$project$Checkout$EffectiveDate$View$cardTitleView(model)),
			$author$project$Checkout$EffectiveDate$View$cardContentView(model),
			$elm$core$Basics$always(true),
			$elm$core$Maybe$Nothing));
};
var $author$project$Checkout$Model$SelectPaymentMethod = function (a) {
	return {$: 3, a: a};
};
var $author$project$Checkout$Model$Pay = function (a) {
	return {$: 4, a: a};
};
var $author$project$Checkout$View$PaymentView$anyFieldInvalid = function (_v0) {
	var cardNumber = _v0.io;
	var expirationMonth = _v0.i7;
	var expirationYear = _v0.i8;
	var cvv = _v0.iJ;
	return !(cvv.jY && (expirationYear.jY && (expirationMonth.jY && cardNumber.jY)));
};
var $primait$pyxis_components$Prima$Pyxis$Button$loading = function (label) {
	return A6($primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig, 7, false, 0, label, $elm$core$Maybe$Nothing, _List_Nil);
};
var $author$project$Checkout$View$PaymentView$buyButtonView = F2(
	function (appStatus, braintreeFields) {
		return $primait$pyxis_components$Prima$Pyxis$Button$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Button$withId,
				'credit-card-buy-btn',
				(appStatus === 3) ? A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					true,
					$primait$pyxis_components$Prima$Pyxis$Button$loading('Acquista')) : A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					$author$project$Checkout$View$PaymentView$anyFieldInvalid(braintreeFields),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						$author$project$Checkout$Model$Pay(1),
						$primait$pyxis_components$Prima$Pyxis$Button$callOut('Acquista')))));
	});
var $author$project$Checkout$View$PaymentView$formInput = function (_v0) {
	var id = _v0.nM;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id(id),
				$elm$html$Html$Attributes$class('form-input')
			]),
		_List_Nil);
};
var $author$project$Checkout$View$PaymentView$formLabel = function (_v0) {
	var labelId = _v0.n7;
	var label = _v0.n6;
	var id = _v0.nM;
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$for(id),
				$elm$html$Html$Attributes$id(labelId),
				$elm$html$Html$Attributes$class('display-block')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Checkout$View$PaymentView$maybeRenderError = F2(
	function (shouldRender, errorMessage) {
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			shouldRender,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-error')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(errorMessage)
					])));
	});
var $author$project$Checkout$View$PaymentView$cardNumberView = function (cardNumberField) {
	var id = cardNumberField.nM;
	var isValid = cardNumberField.jY;
	var isPristine = cardNumberField.jR;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('is-pristine', isPristine),
						_Utils_Tuple2('is-valid', isValid),
						_Utils_Tuple2('has-error', (!isPristine) && (!isValid))
					])),
				A2($elm$html$Html$Attributes$attribute, 'data-field', id)
			]),
		_List_fromArray(
			[
				$author$project$Checkout$View$PaymentView$formLabel(cardNumberField),
				$author$project$Checkout$View$PaymentView$formInput(cardNumberField),
				A2($author$project$Checkout$View$PaymentView$maybeRenderError, (!isValid) && (!isPristine), 'Controlla il numero di carta')
			]));
};
var $author$project$Checkout$View$PaymentView$cvvView = function (cvvField) {
	var id = cvvField.nM;
	var isValid = cvvField.jY;
	var isPristine = cvvField.jR;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('cvv-wrapper', true),
						_Utils_Tuple2('is-pristine', isPristine),
						_Utils_Tuple2('is-valid', isValid)
					])),
				A2($elm$html$Html$Attributes$attribute, 'data-field', id)
			]),
		_List_fromArray(
			[
				$author$project$Checkout$View$PaymentView$formLabel(cvvField),
				$author$project$Checkout$View$PaymentView$formInput(cvvField),
				A2($author$project$Checkout$View$PaymentView$maybeRenderError, (!isValid) && (!isPristine), 'Controlla il cvv')
			]));
};
var $author$project$Checkout$View$PaymentView$expirationDateView = F2(
	function (expirationMonthField, expirationYearField) {
		var fieldInLineView = function (field) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('is-pristine', field.jR),
								_Utils_Tuple2('is-valid', field.jY)
							])),
						A2($elm$html$Html$Attributes$attribute, 'data-field', field.nM)
					]),
				_List_fromArray(
					[
						$author$project$Checkout$View$PaymentView$formInput(field)
					]));
		};
		return A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Data di scadenza')
						])),
					fieldInLineView(expirationMonthField),
					fieldInLineView(expirationYearField),
					A2($author$project$Checkout$View$PaymentView$maybeRenderError, ((!expirationMonthField.jY) && (!expirationMonthField.jR)) || ((!expirationYearField.jY) && (!expirationYearField.jR)), 'Controlla la data di scadenza')
				]));
	});
var $author$project$Checkout$View$PaymentView$paymentSvg = A2($author$project$View$Svg$svg, _List_Nil, 'pagamenti/');
var $author$project$Checkout$View$PaymentView$mobileAlertBox = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('display-flex flex-direction-row align-items-center credit-card-description')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('smartphone')
				]),
			_List_fromArray(
				[
					$author$project$Checkout$View$PaymentView$paymentSvg('smartphone')
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fw-heavy')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Tieni a portata di mano il tuo smartphone')
						])),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Il tuo istituto di credito potrebbe richiedere una verifica per completare il pagamento')
						]))
				]))
		]));
var $author$project$View$Helper$wrapInContainerRowWithClass = F2(
	function (class_, elements) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				elements,
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, class_, $primait$pyxis_components$Prima$Pyxis$Container$row)));
	});
var $author$project$Checkout$View$PaymentView$formView = function (_v0) {
	var cardNumber = _v0.io;
	var expirationMonth = _v0.i7;
	var expirationYear = _v0.i8;
	var cvv = _v0.iJ;
	return _List_fromArray(
		[
			$author$project$Checkout$View$PaymentView$cardNumberView(cardNumber),
			A2(
			$author$project$View$Helper$wrapInContainerRowWithClass,
			'credit-card-fields',
			_List_fromArray(
				[
					A2($author$project$Checkout$View$PaymentView$expirationDateView, expirationMonth, expirationYear),
					$author$project$Checkout$View$PaymentView$cvvView(cvv)
				])),
			$author$project$Checkout$View$PaymentView$mobileAlertBox
		]);
};
var $primait$pyxis_components$Prima$Pyxis$Container$withId = F2(
	function (id, config) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord,
			function (containerOptions) {
				return _Utils_update(
					containerOptions,
					{
						nM: $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$id(id))
					});
			},
			config);
	});
var $author$project$Checkout$View$PaymentView$creditCardContentView = function (model) {
	var brainTreeModel = model.mp;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Container$render(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withContent,
					$author$project$Checkout$View$PaymentView$formView(model.mp.jc),
					A2($primait$pyxis_components$Prima$Pyxis$Container$withId, 'braintree-form', $primait$pyxis_components$Prima$Pyxis$Container$column))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__confirm-btn-wrapper checkout__credit-card-btn-wrapper')
					]),
				_List_fromArray(
					[
						A2($author$project$Checkout$View$PaymentView$buyButtonView, brainTreeModel.c0, brainTreeModel.jc)
					]))
			]));
};
var $author$project$Checkout$View$PaymentView$acceptedCreditCardsItem = function (type_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('p-payment__box__accepted-cards__item')
			]),
		_List_fromArray(
			[
				$author$project$Checkout$View$PaymentView$paymentSvg(type_)
			]));
};
var $author$project$Checkout$View$PaymentView$acceptedCreditCards = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('display-flex flex-direction-row accepted-credit-cards justify-content-flex-end align-items-center')
		]),
	A2(
		$elm$core$List$map,
		$author$project$Checkout$View$PaymentView$acceptedCreditCardsItem,
		_List_fromArray(
			['visa', 'mastercard', 'maestro', 'postepay'])));
var $author$project$Checkout$Model$fromPaymentMethodToString = function (paymentMethod) {
	switch (paymentMethod) {
		case 0:
			return 'BankTransfer';
		case 1:
			return 'BraintreeCreditcard';
		default:
			return 'BraintreePaypal';
	}
};
var $author$project$Checkout$Model$BankTransfer = 0;
var $author$project$Checkout$Model$fromStringToMaybePaymentMethod = function (string) {
	switch (string) {
		case 'BankTransfer':
			return $elm$core$Maybe$Just(0);
		case 'BraintreeCreditcard':
			return $elm$core$Maybe$Just(1);
		case 'BraintreePaypal':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$radio = F3(
	function (reader, tagger, choices) {
		return {ku: _List_Nil, hh: choices, hi: reader, hS: tagger};
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$Id = function (a) {
	return {$: 3, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$addOption = F2(
	function (option, _v0) {
		var radioConfig = _v0;
		return _Utils_update(
			radioConfig,
			{
				ku: _Utils_ap(
					radioConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Form$Radio$withId = function (id) {
	return $primait$pyxis_components$Prima$Pyxis$Form$Radio$addOption(
		$primait$pyxis_components$Prima$Pyxis$Form$Radio$Id(id));
};
var $author$project$Checkout$View$PaymentView$paymentMethodRadioView = F4(
	function (model, value, label, id) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$primait$pyxis_components$Prima$Pyxis$Form$Radio$render,
				model,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Form$Radio$withId,
					id,
					A3(
						$primait$pyxis_components$Prima$Pyxis$Form$Radio$radio,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.oS;
							},
							$elm$core$Maybe$map($author$project$Checkout$Model$fromPaymentMethodToString)),
						A2($elm$core$Basics$composeR, $author$project$Checkout$Model$fromStringToMaybePaymentMethod, $author$project$Checkout$Model$SelectPaymentMethod),
						_List_fromArray(
							[
								{n6: label, p8: value}
							])))));
	});
var $author$project$Types$Quote$Selection$calculateTotalPrice = A3(
	$author$project$Types$Quote$Selection$calculatePriceWithFilters,
	function ($) {
		return $.pC;
	},
	function ($) {
		return $.pC;
	},
	$author$project$Guarantees$Helpers$pickGuaranteeSelected);
var $author$project$Checkout$View$PaymentView$packagesPriceView = F3(
	function (selection, _v0, quotePrice) {
		var save = _v0.py;
		var packages = $author$project$Guarantees$Helpers$pickPackagesFromSave(save);
		var selectionPrice = function () {
			var _v1 = save.pd.lU;
			if (!_v1) {
				return A2($author$project$Guarantees$Helpers$calculatePackagesPrice, packages, selection);
			} else {
				return A3($author$project$Types$Quote$Selection$calculateTotalPrice, 0, 0, quotePrice);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('package-price')
				]),
			_List_fromArray(
				[
					$author$project$Guarantees$View$Helpers$priceView(selectionPrice)
				]));
	});
var $author$project$Checkout$View$PaymentView$priceView = function (_v0) {
	var order = _v0.oK;
	var userSelection = _v0.lQ;
	var quotePrice = _v0.k5;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A4($elm$core$Maybe$map3, $author$project$Checkout$View$PaymentView$packagesPriceView, userSelection, order, quotePrice));
};
var $author$project$Checkout$View$PaymentView$securePaymentIcon = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('secure-payment')
		]),
	_List_fromArray(
		[
			$author$project$Checkout$View$PaymentView$paymentSvg('pagamento-sicuro')
		]));
var $author$project$Checkout$View$PaymentView$creditCardHeaderView = function (model) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[
					A4($author$project$Checkout$View$PaymentView$paymentMethodRadioView, model, 'BraintreeCreditcard', 'Carta di credito o prepagata', 'credit-card-radio'),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('credit-cards-secure-payment display-flex align-items-center')
						]),
					_List_fromArray(
						[$author$project$Checkout$View$PaymentView$acceptedCreditCards, $author$project$Checkout$View$PaymentView$securePaymentIcon])),
					$author$project$Checkout$View$PaymentView$priceView(model)
				]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'justify-content-space-between align-items-center credit-card-header', $primait$pyxis_components$Prima$Pyxis$Container$row)));
};
var $primait$pyxis_components$Prima$Pyxis$Message$infoAlt = function (content) {
	return A4($primait$pyxis_components$Prima$Pyxis$Message$Configuration, 0, 1, content, _List_Nil);
};
var $author$project$Checkout$View$PaymentView$paymentFavouriteAlert = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('a-container a-container--medium directionColumn payment-favourite-info')
		]),
	_List_fromArray(
		[
			$primait$pyxis_components$Prima$Pyxis$Message$render(
			$primait$pyxis_components$Prima$Pyxis$Message$infoAlt(
				_List_fromArray(
					[
						$elm$html$Html$text('Utilizzeremo il metodo di pagamento scelto come tuo preferito. Potrai modificarlo in seguito')
					])))
		]));
var $author$project$Checkout$View$PaymentView$paymentMethodBoxView = F2(
	function (model, contentView) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						contentView(model)
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'braintree', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Checkout$View$PayPalView$thirdPartRoot = F2(
	function (isPaymentBtnDisabled, status) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__paypal-btn-wrapper payment-box__cta')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('accordion-card__paypal', true),
									_Utils_Tuple2(
									'is-disabled',
									isPaymentBtnDisabled(status))
								])),
							$elm$html$Html$Attributes$id('braintree-paypal-button'),
							$elm$html$Html$Events$onClick(
							$author$project$Checkout$Model$Pay(2)),
							$elm$html$Html$Attributes$disabled(
							isPaymentBtnDisabled(status))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png')
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Checkout$View$PayPalView$paypalContentView = function (_v0) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('payment-box__paypal-caption c-text-base')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Accedi al tuo conto PayPal e paga in completa sicurezza. Verrai reindirizzato al sito PayPal per verificare il pagamento e completare l\'acquisto.')
						])),
					A2(
					$author$project$Checkout$View$PayPalView$thirdPartRoot,
					$elm$core$Basics$always(false),
					$elm$core$Maybe$Nothing)
				]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withId, 'braintree-form', $primait$pyxis_components$Prima$Pyxis$Container$column)));
};
var $author$project$Checkout$View$PaymentView$paypalHeaderView = function (model) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[
					A4($author$project$Checkout$View$PaymentView$paymentMethodRadioView, model, 'BraintreePaypal', 'PayPal', 'pay-pal-radio'),
					$author$project$Checkout$View$PaymentView$priceView(model)
				]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'justify-content-space-between align-items-center credit-card-header', $primait$pyxis_components$Prima$Pyxis$Container$row)));
};
var $author$project$Checkout$View$PaymentView$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$View$AccordionCard$render,
				model,
				A4(
					$author$project$View$AccordionCard$accordionCard,
					$elm$core$Maybe$Just(
						$author$project$Checkout$View$PaymentView$creditCardHeaderView(model)),
					_List_fromArray(
						[
							A2($author$project$Checkout$View$PaymentView$paymentMethodBoxView, model, $author$project$Checkout$View$PaymentView$creditCardContentView)
						]),
					function (m) {
						return _Utils_eq(
							m.oS,
							$elm$core$Maybe$Just(1));
					},
					$elm$core$Maybe$Just(
						$author$project$Checkout$Model$SelectPaymentMethod(
							$elm$core$Maybe$Just(1))))),
				A2(
				$author$project$View$AccordionCard$render,
				model,
				A4(
					$author$project$View$AccordionCard$accordionCard,
					$elm$core$Maybe$Just(
						$author$project$Checkout$View$PaymentView$paypalHeaderView(model)),
					_List_fromArray(
						[
							A2($author$project$Checkout$View$PaymentView$paymentMethodBoxView, model, $author$project$Checkout$View$PayPalView$paypalContentView)
						]),
					function (m) {
						return _Utils_eq(
							m.oS,
							$elm$core$Maybe$Just(2));
					},
					$elm$core$Maybe$Just(
						$author$project$Checkout$Model$SelectPaymentMethod(
							$elm$core$Maybe$Just(2))))),
				$author$project$Checkout$View$PaymentView$paymentFavouriteAlert
			]));
};
var $author$project$Checkout$View$currentStepView = function (model) {
	var _v0 = $author$project$Checkout$Model$getCheckoutStepType(model);
	switch (_v0) {
		case 0:
			return $author$project$Checkout$EffectiveDate$View$view(model);
		case 1:
			return $author$project$Checkout$View$PaymentView$view(model);
		default:
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var $author$project$Checkout$View$PaymentView$formatDate = function (date) {
	return A3($justinmimbs$date$Date$formatWithLanguage, $author$project$Utils$Languages$It$it, 'd MMMM y', date);
};
var $author$project$Checkout$View$PaymentView$effectiveDateInvalidMessageView = F3(
	function (saveExpireDate, today, saveId) {
		return $primait$pyxis_components$Prima$Pyxis$Message$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Message$withClass,
				'bg-background-dark',
				$primait$pyxis_components$Prima$Pyxis$Message$error(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fw-heavy')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											'La decorrenza deve essere tra le date ' + ($author$project$Checkout$View$PaymentView$formatDate(today) + (' e ' + ($author$project$Checkout$View$PaymentView$formatDate(saveExpireDate) + '. ')))),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													A2($author$project$Checkout$Model$RedirectToStep, saveId, 1)),
													$elm$html$Html$Attributes$class('c-action-dark cursor-pointer')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Modifica')
												]))
										]))
								]))
						]))));
	});
var $author$project$Checkout$View$PaymentView$effectiveDateInvalidView = function (model) {
	var saveId = model.pz;
	var currentDate = model.iH;
	var order = model.oK;
	var saveExpiration = A2(
		$elm$core$Maybe$map,
		$author$project$Utils$Time$convertPosixToDate,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.lh;
			},
			order));
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		(!$author$project$Checkout$Update$isEffectiveDateValid(model)) && ($author$project$Checkout$Model$isPaymentStep(model) && $author$project$Checkout$Model$isStandardSave(model)),
		$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A4($elm$core$Maybe$map3, $author$project$Checkout$View$PaymentView$effectiveDateInvalidMessageView, saveExpiration, currentDate, saveId)));
};
var $author$project$Components$SummaryBox$info = function (title) {
	return {dE: $elm$core$Maybe$Nothing, dF: $elm$core$Maybe$Nothing, ei: _List_Nil, eE: title};
};
var $author$project$Components$QuoteInfoModal$InfoData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$QuoteInfoModal$InfoLabel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$QuoteInfoModal$mapInfoType = function (_v0) {
	var data = _v0.C;
	var isVisible = _v0.fN;
	var stringToQuoteInfoViewType = F2(
		function (index, element) {
			if (!index) {
				return $author$project$Components$QuoteInfoModal$InfoLabel(element);
			} else {
				return $author$project$Components$QuoteInfoModal$InfoData(element);
			}
		});
	return {
		C: A2($elm$core$List$indexedMap, stringToQuoteInfoViewType, data),
		fN: isVisible
	};
};
var $author$project$Components$SummaryBox$iconView = function (icon) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(icon + ' icon c-brand-base fs-xlarge')
			]),
		_List_Nil);
};
var $author$project$Components$SummaryBox$imageView = function (image) {
	return A2(
		$elm$html$Html$img,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$src(image)
			]),
		_List_Nil);
};
var $author$project$Components$SummaryBox$headerView = F3(
	function (title, icon, image) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-row align-items-center')
				]),
			_List_fromArray(
				[
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Components$SummaryBox$iconView, icon)),
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Components$SummaryBox$imageView, image)),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(' c-brand-base fw-heavy summary-box-header-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Components$SummaryBox$render = F2(
	function (renderer, _v0) {
		var title = _v0.eE;
		var icon = _v0.dE;
		var image = _v0.dF;
		var rows = _v0.ei;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('summary-box')
				]),
			_List_fromArray(
				[
					A3($author$project$Components$SummaryBox$headerView, title, icon, image),
					A4(
					$author$project$View$SimpleTable$tableView,
					renderer,
					0,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.C;
						},
						A2(
							$elm$core$List$filter,
							function ($) {
								return $.fN;
							},
							rows)))
				]));
	});
var $author$project$Components$QuoteInfoModal$renderQuoteInfoType = function (infoType) {
	if (!infoType.$) {
		var value = infoType.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-text-base')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(value)
				]));
	} else {
		var value = infoType.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fw-heavy c-text-dark')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(value)
				]));
	}
};
var $author$project$Components$SummaryBox$withIcon = F2(
	function (icon, config) {
		return _Utils_update(
			config,
			{
				dE: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Components$SummaryBox$withRows = F2(
	function (rows, config) {
		return _Utils_update(
			config,
			{ei: rows});
	});
var $author$project$Components$QuoteInfoModal$customerAddressView = function (address) {
	var rows = _Utils_ap(
		A2(
			$elm$core$List$map,
			function (row) {
				return {C: row, fN: true};
			},
			_List_fromArray(
				[
					_List_fromArray(
					['Indirizzo', address.lx]),
					_List_fromArray(
					['N° civico', address.kp]),
					_List_fromArray(
					['Comune', address.my]),
					_List_fromArray(
					['Cap', address.qg])
				])),
		_List_fromArray(
			[
				{
				C: _List_fromArray(
					[
						'Scala',
						A2($elm$core$Maybe$withDefault, '-', address.hH)
					]),
				fN: $elm_community$maybe_extra$Maybe$Extra$isJust(address.hH)
			},
				{
				C: _List_fromArray(
					[
						'Interno',
						A2($elm$core$Maybe$withDefault, '-', address.fl)
					]),
				fN: $elm_community$maybe_extra$Maybe$Extra$isJust(address.fl)
			}
			]));
	return A2(
		$author$project$Components$SummaryBox$render,
		$author$project$Components$QuoteInfoModal$renderQuoteInfoType,
		A2(
			$author$project$Components$SummaryBox$withRows,
			A2($elm$core$List$map, $author$project$Components$QuoteInfoModal$mapInfoType, rows),
			A2(
				$author$project$Components$SummaryBox$withIcon,
				'icon-location',
				$author$project$Components$SummaryBox$info('Residenza del contraente'))));
};
var $author$project$Types$Utils$genderToLabel = function (_enum) {
	if (!_enum) {
		return 'Maschio';
	} else {
		return 'Femmina';
	}
};
var $author$project$Components$QuoteInfoModal$customerInformationView = function (model) {
	var rows = _List_fromArray(
		[
			{
			C: _List_fromArray(
				['Nome e Cognome', model.oq + (' ' + model.lC)]),
			fN: true
		},
			{
			C: _List_fromArray(
				[
					'Genere',
					$author$project$Types$Utils$genderToLabel(model.ny)
				]),
			fN: true
		},
			{
			C: _List_fromArray(
				[
					'Data di nascita',
					A2($justinmimbs$date$Date$format, $author$project$Utils$dateFormatString, model.mn)
				]),
			fN: true
		},
			{
			C: _List_fromArray(
				[
					'Città di nascita',
					$author$project$Types$Utils$birthCityToLabel(model.ih)
				]),
			fN: true
		},
			{
			C: _List_fromArray(
				['Paese di nascita', model.mj]),
			fN: _Utils_eq(model.ih, $author$project$Types$Quote$ForeignCountry)
		},
			{
			C: _List_fromArray(
				['Indirizzo email', model.fi]),
			fN: true
		},
			{
			C: _List_fromArray(
				['Telefono', model.oT]),
			fN: true
		}
		]);
	return A2(
		$author$project$Components$SummaryBox$render,
		$author$project$Components$QuoteInfoModal$renderQuoteInfoType,
		A2(
			$author$project$Components$SummaryBox$withRows,
			A2($elm$core$List$map, $author$project$Components$QuoteInfoModal$mapInfoType, rows),
			A2(
				$author$project$Components$SummaryBox$withIcon,
				'icon-people',
				$author$project$Components$SummaryBox$info('Contraente'))));
};
var $author$project$Components$QuoteInfoModal$PackageGuarantees = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$QuoteInfoModal$PackageLabel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$QuoteInfoModal$mapPackageInfo = F2(
	function (userSelection, _package) {
		return _List_fromArray(
			[
				$author$project$Components$QuoteInfoModal$PackageLabel(_package),
				$author$project$Components$QuoteInfoModal$PackageGuarantees(
				_Utils_Tuple2(_package, userSelection))
			]);
	});
var $author$project$Components$QuoteInfoModal$guaranteeItemView = function (guaranteeLabel) {
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-heavy c-text-dark')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(guaranteeLabel)
					]))
			]));
};
var $author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteesInPackage = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$filter,
			function (_v1) {
				var selected = _v1.pC;
				var guaranteeType = _v1.js;
				return selected || (!guaranteeType);
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.nF;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.mG;
					},
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							$elm$core$Basics$eq(packageSlug)),
						packages))));
	});
var $author$project$Guarantees$Helpers$pickSelectedGuarantees = F2(
	function (_package, userSelection) {
		var selectedGuarantees = A2($author$project$Guarantees$Model$UserSelection$pickSelectedGuaranteesInPackage, _package.bH, userSelection);
		return A2(
			$elm$core$List$filter,
			function (guarantee) {
				return A2(
					$elm$core$List$any,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.bH;
						},
						$elm$core$Basics$eq(
							$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee))),
					selectedGuarantees);
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.nF;
				},
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.l0;
					},
					_package.mG)));
	});
var $author$project$Components$QuoteInfoModal$guaranteesListView = F2(
	function (_package, userSelection) {
		var selectedGuaranteesLabel = A2(
			$elm$core$List$map,
			$author$project$Guarantees$Helpers$pickGuaranteeLabel,
			A2(
				$elm$core$List$sortBy,
				$author$project$Guarantees$Helpers$pickGuaranteeWeight,
				A2($author$project$Guarantees$Helpers$pickSelectedGuarantees, _package, userSelection)));
		return A2(
			$elm$html$Html$ul,
			_List_Nil,
			A2($elm$core$List$map, $author$project$Components$QuoteInfoModal$guaranteeItemView, selectedGuaranteesLabel));
	});
var $author$project$Components$QuoteInfoModal$renderPackageTypeCellView = function (packageViewType) {
	if (!packageViewType.$) {
		var _package = packageViewType.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('c-text-dark')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(_package.n6)
				]));
	} else {
		var _v1 = packageViewType.a;
		var _package = _v1.a;
		var userSelection = _v1.b;
		return A2($author$project$Components$QuoteInfoModal$guaranteesListView, _package, userSelection);
	}
};
var $author$project$Components$QuoteInfoModal$packagesInfoView = function (_v0) {
	var save = _v0.py;
	var userSelection = _v0.lQ;
	var rows = A2(
		$elm$core$List$map,
		function (row) {
			return {C: row, fN: true};
		},
		A2(
			$elm$core$List$map,
			$author$project$Components$QuoteInfoModal$mapPackageInfo(userSelection),
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.l0;
				},
				$author$project$Guarantees$Helpers$pickPackagesFromSave(save))));
	return A2(
		$author$project$Components$SummaryBox$render,
		$author$project$Components$QuoteInfoModal$renderPackageTypeCellView,
		A2(
			$author$project$Components$SummaryBox$withRows,
			rows,
			A2(
				$author$project$Components$SummaryBox$withIcon,
				'icon-sheet',
				$author$project$Components$SummaryBox$info('Garanzie incluse'))));
};
var $author$project$Components$SummaryBox$withImage = F2(
	function (image, config) {
		return _Utils_update(
			config,
			{
				dF: $elm$core$Maybe$Just(image)
			});
	});
var $author$project$Components$QuoteInfoModal$propertyInformationView = F2(
	function (model, propertyAddress) {
		var rows = A2(
			$elm$core$List$map,
			function (row) {
				return {C: row, fN: true};
			},
			_List_fromArray(
				[
					_List_fromArray(
					['Indirizzo', propertyAddress.lx]),
					_List_fromArray(
					['N° civico', propertyAddress.kp]),
					_List_fromArray(
					['Comune', propertyAddress.my]),
					_List_fromArray(
					['Cap', propertyAddress.qg]),
					_List_fromArray(
					[
						'Scala',
						A2($elm$core$Maybe$withDefault, '-', propertyAddress.hH)
					]),
					_List_fromArray(
					[
						'Interno',
						A2($elm$core$Maybe$withDefault, '-', propertyAddress.fl)
					]),
					_List_fromArray(
					[
						'Titolarità',
						$author$project$Types$Utils$ownershipToLabel(model.oL)
					]),
					_List_fromArray(
					[
						'Uso dell\'immobile',
						$author$project$Types$Utils$propertyUsageTypeToLabel(model.o1)
					])
				]));
		return A2(
			$author$project$Components$SummaryBox$render,
			$author$project$Components$QuoteInfoModal$renderQuoteInfoType,
			A2(
				$author$project$Components$SummaryBox$withRows,
				A2($elm$core$List$map, $author$project$Components$QuoteInfoModal$mapInfoType, rows),
				A2(
					$author$project$Components$SummaryBox$withImage,
					'/svg/form/building.svg',
					$author$project$Components$SummaryBox$info('Informazioni sull\'immobile'))));
	});
var $author$project$Types$Utils$floorTypeToLabel = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Piano terra o rialzato';
		case 1:
			return 'Tutti gli altri piani';
		default:
			return 'Ultimo piano';
	}
};
var $author$project$Components$QuoteInfoModal$pastIncidentsToCaption = function (pastIncidents) {
	switch (pastIncidents) {
		case 0:
			return 'Nessun sinistro';
		case 1:
			return '1';
		default:
			return '2 o più';
	}
};
var $author$project$Components$QuoteInfoModal$propertyTypeInformationView = F2(
	function (model, formType) {
		var title = function () {
			if ((!formType.$) && (formType.a === 1)) {
				var _v3 = formType.a;
				return 'Informazioni aggiuntive';
			} else {
				return 'Tipologia dell\'immobile';
			}
		}();
		var property_rows = _List_fromArray(
			[
				_List_fromArray(
				[
					'Tipo',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$propertyTypeToLabel, model.o4))
				]),
				_List_fromArray(
				[
					'Tipologia di casa o villa',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$houseTypeToLabel, model.qa))
				]),
				_List_fromArray(
				[
					'Tipologia condominio',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$condominiumTypeToLabel, model.mN))
				]),
				_List_fromArray(
				[
					'Piano dell\'appartamento',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$floorTypeToLabel, model.nw))
				]),
				_List_fromArray(
				[
					'Metri quadri commerciali',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, model.p3))
				]),
				_List_fromArray(
				[
					'Periodo di costruzione o ultima ristrutturazione significativa',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Types$Utils$constructionPeriodToLabel, model.mP))
				])
			]);
		var common_rows = _List_fromArray(
			[
				_List_fromArray(
				[
					'Sinistri denunciati a tuo carico nell\'ultimo triennio',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Components$QuoteInfoModal$pastIncidentsToCaption, model.pn))
				]),
				_List_fromArray(
				[
					'Maggiorenni conviventi',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Utils$familyMembersValueToLabel, model.np))
				]),
				_List_fromArray(
				[
					'Minorenni conviventi',
					A2(
					$elm$core$Maybe$withDefault,
					'-',
					A2($elm$core$Maybe$map, $author$project$Utils$familyMembersValueToLabel, model.nr))
				])
			]);
		var complete_rows = A2(
			$elm$core$List$map,
			function (row) {
				return {C: row, fN: true};
			},
			function () {
				if ((!formType.$) && (formType.a === 1)) {
					var _v1 = formType.a;
					return common_rows;
				} else {
					return _Utils_ap(property_rows, common_rows);
				}
			}());
		return A2(
			$author$project$Components$SummaryBox$render,
			$author$project$Components$QuoteInfoModal$renderQuoteInfoType,
			A2(
				$author$project$Components$SummaryBox$withRows,
				A2($elm$core$List$map, $author$project$Components$QuoteInfoModal$mapInfoType, complete_rows),
				A2(
					$author$project$Components$SummaryBox$withIcon,
					'icon-folder',
					$author$project$Components$SummaryBox$info(title))));
	});
var $author$project$Components$QuoteInfoModal$quoteInformationView = function (_v0) {
	var quote = _v0.pd;
	var guaranteeSelection = _v0.dz;
	var _v1 = quote;
	var formType = _v1.nx;
	var productType = _v1.kX;
	var information = _v1.nT;
	var propertyInfo = function () {
		if (!productType.$) {
			return $elm$core$Maybe$Just(
				A2($author$project$Components$QuoteInfoModal$propertyTypeInformationView, information, formType));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-column guarantees-summary-container-list')
			]),
		_List_fromArray(
			[
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Components$QuoteInfoModal$packagesInfoView, guaranteeSelection)),
				$author$project$Components$QuoteInfoModal$customerInformationView(information),
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$QuoteInfoModal$propertyInformationView(information),
					information.kY)),
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(propertyInfo),
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Components$QuoteInfoModal$customerAddressView, information.pq))
			]));
};
var $author$project$Components$QuoteInfoModal$scroller = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('guarantees-summary-container-scroller')
		]));
var $primait$pyxis_components$Prima$Pyxis$Button$withIcon = F2(
	function (icon, _v0) {
		var buttonConfig = _v0;
		return _Utils_update(
			buttonConfig,
			{
				dE: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Components$QuoteInfoModal$summaryHeaderCloseView = function (_v0) {
	var onClose = _v0.gf;
	return $primait$pyxis_components$Prima$Pyxis$Button$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
			onClose,
			A2(
				$primait$pyxis_components$Prima$Pyxis$Button$withIcon,
				'close',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withClass,
					'guarantees-summary-container-close',
					$primait$pyxis_components$Prima$Pyxis$Button$withTinySize(
						$primait$pyxis_components$Prima$Pyxis$Button$tertiary(''))))));
};
var $primait$pyxis_components$Prima$Pyxis$Button$Attribute = function (a) {
	return {$: 6, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Button$withAttribute = function (attr) {
	return $primait$pyxis_components$Prima$Pyxis$Button$addOption(
		$primait$pyxis_components$Prima$Pyxis$Button$Attribute(attr));
};
var $author$project$Components$QuoteInfoModal$summaryHeaderEditView = function (config) {
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A2(
			$elm$core$Maybe$map,
			function (onEdit) {
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('guarantees-summary-container-edit display-flex flex-direction-column align-items-center')
						]),
					_List_fromArray(
						[
							$primait$pyxis_components$Prima$Pyxis$Button$render(
							A2(
								$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
								onEdit,
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withAttribute,
									A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-guarantee-summary-edit-btn'),
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'guarantees-summary-container__edit-btn',
										$primait$pyxis_components$Prima$Pyxis$Button$tertiary('Modifica')))))
						]));
			},
			config.d6));
};
var $author$project$Components$QuoteInfoModal$summaryHeaderTitleView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('guarantees-summary-container-title fs-medium text-transform-uppercase fw-heavy c-text-dark')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Riepilogo dati')
		]));
var $author$project$Components$QuoteInfoModal$summaryHeaderView = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-row justify-content-space-between align-items-flex-start')
			]),
		_List_fromArray(
			[
				$author$project$Components$QuoteInfoModal$summaryHeaderTitleView,
				$author$project$Components$QuoteInfoModal$summaryHeaderEditView(config),
				$author$project$Components$QuoteInfoModal$summaryHeaderCloseView(config)
			]));
};
var $primait$pyxis_components$Prima$Pyxis$Container$withClassList = F2(
	function (classList, config) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord,
			function (containerOptions) {
				return _Utils_update(
					containerOptions,
					{
						c5: $elm$core$Maybe$Just(
							$elm$html$Html$Attributes$classList(classList))
					});
			},
			config);
	});
var $author$project$Components$QuoteInfoModal$summaryView = function (config) {
	var isOpen = config.jP;
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[
					$author$project$Components$QuoteInfoModal$summaryHeaderView(config),
					$author$project$Components$QuoteInfoModal$scroller(
					_List_fromArray(
						[
							$author$project$Components$QuoteInfoModal$quoteInformationView(config)
						]))
				]),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2('guarantees-summary-container-is-open', isOpen)
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-summary-container', $primait$pyxis_components$Prima$Pyxis$Container$column))));
};
var $author$project$Components$QuoteInfoModal$render = function (config) {
	return $author$project$Components$QuoteInfoModal$summaryView(config);
};
var $author$project$Components$QuoteInfoModal$summary = F3(
	function (onClose, isOpen, quote) {
		return {dz: $elm$core$Maybe$Nothing, jP: isOpen, gf: onClose, d6: $elm$core$Maybe$Nothing, pd: quote};
	});
var $author$project$Components$QuoteInfoModal$withGuaranteeSelection = F3(
	function (save, userSelection, config) {
		return _Utils_update(
			config,
			{
				dz: $elm$core$Maybe$Just(
					{py: save, lQ: userSelection})
			});
	});
var $author$project$Checkout$View$summaryModalView = F3(
	function (isSummaryOpen, _v0, userSelection) {
		var save = _v0.py;
		return $author$project$Components$QuoteInfoModal$render(
			A3(
				$author$project$Components$QuoteInfoModal$withGuaranteeSelection,
				save,
				userSelection,
				A3(
					$author$project$Components$QuoteInfoModal$summary,
					$author$project$Checkout$Model$Toggle(
						$author$project$Checkout$Model$Summary(false)),
					isSummaryOpen,
					save.pd)));
	});
var $author$project$Checkout$View$summaryModalConditionalView = F2(
	function (_v0, _v1) {
		var isSummaryOpen = _v0.jW;
		var order = _v1.oK;
		var userSelection = _v1.lQ;
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A3(
				$elm$core$Maybe$map2,
				$author$project$Checkout$View$summaryModalView(isSummaryOpen),
				order,
				userSelection));
	});
var $author$project$Checkout$StepBar$View$Step = $elm$core$Basics$identity;
var $author$project$Checkout$StepBar$View$leftSeparatorView = F2(
	function (_v0, titleIndex) {
		var currentStep = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__steps__separator checkout__steps__separator-left'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'checkout__steps__separator--completed',
							_Utils_cmp(titleIndex, currentStep) < 1)
						]))
				]),
			_List_Nil);
	});
var $author$project$Checkout$StepBar$View$rightSeparatorView = F2(
	function (_v0, titleIndex) {
		var currentStep = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__steps__separator checkout__steps__separator-right'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'checkout__steps__separator--completed',
							_Utils_cmp(titleIndex, currentStep) < 0)
						]))
				]),
			_List_Nil);
	});
var $author$project$Checkout$StepBar$View$checkoutSvg = A2(
	$author$project$View$Svg$svg,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('checkout__steps__icon')
		]),
	'checkout/');
var $author$project$Checkout$StepBar$View$stepIconView = F2(
	function (_v0, titleIndex) {
		var currentStep = _v0;
		return _Utils_eq(titleIndex, currentStep) ? $author$project$Checkout$StepBar$View$checkoutSvg('step-focused') : ((_Utils_cmp(titleIndex, currentStep) < 0) ? $author$project$Checkout$StepBar$View$checkoutSvg('step-completed') : $author$project$Checkout$StepBar$View$checkoutSvg('step-disabled'));
	});
var $author$project$Checkout$StepBar$View$iconWrapperView = F2(
	function (currentStep, titleIndex) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__steps__icon-wrapper')
				]),
			_List_fromArray(
				[
					A2($author$project$Checkout$StepBar$View$leftSeparatorView, currentStep, titleIndex),
					A2($author$project$Checkout$StepBar$View$stepIconView, currentStep, titleIndex),
					A2($author$project$Checkout$StepBar$View$rightSeparatorView, currentStep, titleIndex)
				]));
	});
var $author$project$Checkout$StepBar$View$stepTitleView = F3(
	function (_v0, titleIndex, title) {
		var currentStep = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__steps__title-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkout__steps__title'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'checkout__steps__title--completed',
									_Utils_cmp(titleIndex, currentStep) < 1)
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $author$project$Checkout$StepBar$View$stepItemView = F3(
	function (currentStep, titleIndex, title) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__steps__item')
				]),
			_List_fromArray(
				[
					A2($author$project$Checkout$StepBar$View$iconWrapperView, currentStep, titleIndex),
					A3($author$project$Checkout$StepBar$View$stepTitleView, currentStep, titleIndex, title)
				]));
	});
var $author$project$Checkout$StepBar$View$titlesListMapper = function (saveType) {
	switch (saveType) {
		case 0:
			return _List_fromArray(
				['Decorrenza', 'Pagamento', 'Emissione']);
		case 1:
			return _List_fromArray(
				['Pagamento', 'Emissione']);
		default:
			return _List_Nil;
	}
};
var $author$project$Checkout$StepBar$View$view = function (model) {
	var currentStep = model.de;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__steps-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__steps')
					]),
				A2(
					$elm$core$List$indexedMap,
					$author$project$Checkout$StepBar$View$stepItemView(currentStep - 1),
					$author$project$Checkout$StepBar$View$titlesListMapper(
						$author$project$Checkout$Model$getSaveType(model))))
			]));
};
var $author$project$Checkout$View$mainView = function (model) {
	var options = model.ku;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Checkout$View$PaymentErrorView$braintreeErrorMessage(model),
				$author$project$Checkout$View$PaymentView$effectiveDateInvalidView(model),
				$author$project$Checkout$StepBar$View$view(model),
				$author$project$Checkout$View$currentStepView(model),
				A2($author$project$Checkout$View$summaryModalConditionalView, options, model),
				$author$project$Checkout$View$checkoutLoader(model)
			]));
};
var $author$project$View$Template$mainView = function (content) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[content]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'template-main mq-small-full-width', $primait$pyxis_components$Prima$Pyxis$Container$column)));
};
var $author$project$View$Template$sideView = function (content) {
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[content]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'template-sidebar', $primait$pyxis_components$Prima$Pyxis$Container$column)));
};
var $author$project$View$Template$bodyView = F2(
	function (_v0, _v1) {
		var mainContent = _v0;
		var sideContent = _v1;
		return A2(
			$author$project$View$Helper$wrapInRowWithClass,
			'template-body',
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Container$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Container$withContent,
						function () {
							if (!sideContent.$) {
								var sc = sideContent.a;
								return _List_fromArray(
									[
										$author$project$View$Template$mainView(mainContent),
										$author$project$View$Template$sideView(sc)
									]);
							} else {
								return _List_fromArray(
									[
										$author$project$View$Template$mainView(mainContent)
									]);
							}
						}(),
						$primait$pyxis_components$Prima$Pyxis$Container$row))
				]));
	});
var $author$project$View$OldFooter$Config = $elm$core$Basics$identity;
var $primait$pyxis_components$Prima$Pyxis$Link$withHref = F2(
	function (href, _v0) {
		var linkConfig = _v0;
		return _Utils_update(
			linkConfig,
			{
				fz: $elm$core$Maybe$Just(href)
			});
	});
var $primait$pyxis_components$Prima$Pyxis$Link$Target = function (a) {
	return {$: 5, a: a};
};
var $primait$pyxis_components$Prima$Pyxis$Link$withTargetBlank = $primait$pyxis_components$Prima$Pyxis$Link$addOption(
	$primait$pyxis_components$Prima$Pyxis$Link$Target('_blank'));
var $author$project$View$OldFooter$defaultContent = A2(
	$elm$html$Html$p,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('fs-xsmall c-text-alt-light')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('© Prima Assicurazioni S.p.A. • P. IVA 08879250960 • Piazzale Loreto 17, 20131 Milano (MI).'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('Prima Assicurazioni S.p.A. è un intermediario assicurativo, iscritto alla Sezione A del Registro Unico degli Intermediari Assicurativi, '),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('che distribuisce prodotti di Great Lakes Insurance SE (Gruppo Munich Re), iptiQ EMEA P&C S.A. - Italia e iptiQ EMEA P&C S.A. - Germania.'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('Great Lakes Insurance SE è iscritta al Registro delle imprese di Assicurazione in Italia con il N° I.00093. Capitale sociale: € 131.776.704 (EUR).'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('iptiQ EMEA P&C S.A. - Rappresentanza Generale per l\'Italia è iscritta al Registro delle imprese di Assicurazione in Italia con il N° I.00170. Capitale sociale: € 5.700.000 (EUR).'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('iptiQ EMEA P&C S.A. - Rappresentanza Generale per la Germania è iscritta al Registro delle imprese di Assicurazione in Italia con il N° II.01729. Capitale sociale: € 5.700.000 (EUR).'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('Prima Assicurazioni S.p.A. è soggetta alla vigilanza dell\'IVASS ed iscritta al RUI con numero A000511660.'),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$elm$html$Html$text('Consulta gli estremi dell\'iscrizione al sito '),
			$primait$pyxis_components$Prima$Pyxis$Link$render(
			$primait$pyxis_components$Prima$Pyxis$Link$withTargetBlank(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Link$withHref,
					'https://servizi.ivass.it/RuirPubblica/',
					$primait$pyxis_components$Prima$Pyxis$Link$simple('www.servizi.ivass.it')))),
			$elm$html$Html$text('.')
		]));
var $author$project$View$OldFooter$create = {
	mQ: _List_fromArray(
		[$author$project$View$OldFooter$defaultContent]),
	ku: _List_Nil,
	en: true,
	eo: true
};
var $author$project$View$LogoOld$Base = 0;
var $author$project$View$LogoOld$Config = $elm$core$Basics$identity;
var $author$project$View$LogoOld$LogoConfiguration = F2(
	function (skin, options) {
		return {ku: options, ls: skin};
	});
var $author$project$View$LogoOld$base = A2($author$project$View$LogoOld$LogoConfiguration, 0, _List_Nil);
var $author$project$View$LogoOld$buildClasses = function (options) {
	return $elm$html$Html$Attributes$class(
		A2($elm$core$String$join, ' ', options.c6));
};
var $author$project$View$LogoOld$applyOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var _class = modifier.a;
				return _Utils_update(
					options,
					{
						c6: A2($elm$core$List$cons, _class, options.c6)
					});
			case 1:
				var id = modifier.a;
				return _Utils_update(
					options,
					{
						nM: $elm$core$Maybe$Just(id)
					});
			case 2:
				var url = modifier.a;
				return _Utils_update(
					options,
					{lO: url});
			default:
				var step = modifier.a;
				return _Utils_update(
					options,
					{de: step});
		}
	});
var $author$project$View$LogoOld$defaultOptions = {
	c6: _List_fromArray(
		['prima-logo']),
	de: $elm$core$Maybe$Nothing,
	nM: $elm$core$Maybe$Nothing,
	hU: 4,
	lO: 'https://www.prima.it'
};
var $author$project$View$LogoOld$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $author$project$View$LogoOld$applyOption, $author$project$View$LogoOld$defaultOptions, config.ku);
};
var $author$project$View$LogoOld$buildAttributes = function (logoConfig) {
	var options = $author$project$View$LogoOld$computeOptions(logoConfig);
	return A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$href(options.lO),
		A2(
			$elm$core$List$cons,
			$author$project$View$LogoOld$buildClasses(options),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM)
					]))));
};
var $elm$svg$Svg$Attributes$enableBackground = _VirtualDom_attribute('enable-background');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $author$project$View$LogoOld$getPrimaryColorBySkin = function (skin) {
	if (!skin) {
		return '#8334c2';
	} else {
		return '#ffffff';
	}
};
var $elm$svg$Svg$Attributes$version = _VirtualDom_attribute('version');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$View$LogoOld$renderLogo = function (skin) {
	return A2(
		$elm$svg$Svg$svg,
		_List_fromArray(
			[
				$elm$svg$Svg$Attributes$class('prima-logo__svg'),
				$elm$svg$Svg$Attributes$version('1.1'),
				$elm$svg$Svg$Attributes$x('0px'),
				$elm$svg$Svg$Attributes$y('0px'),
				$elm$svg$Svg$Attributes$viewBox('0 0 595.3 183'),
				$elm$svg$Svg$Attributes$enableBackground('new 0 0 595.3 183')
			]),
		_List_fromArray(
			[
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m277.3 47.7c-3.6 1.3-5.1 3.4-5 7.3 0.3 20.2 0.1 40.3 0.1 60.5v4.7c-0.3 5.2-3.1 8.5-7.5 8.5-4.6 0-8-3.4-8.1-8.6-0.1-24.3-0.1-48.6 0.1-72.9 0-2.4 1.7-5.6 3.7-6.9 16.7-10.7 34.8-13 53.7-5.9 3.6 1.3 6.5 4.2 10.2 6.7 8.7-7.1 18.7-10.7 30.3-10.6 22.9 0.1 35.5 10.5 36.5 32.3 0.9 19.3 0.4 38.6 0.3 57.9 0 5.1-3.4 8.2-7.9 8.1-4.1-0.1-7.5-3.3-7.6-8-0.2-14.1-0.1-28.3-0.2-42.4 0-4.1 0.1-8.3-0.1-12.4-1.1-17.2-12.4-25-30-20.5-3.2 0.8-6.3 2.5-9.2 4.3-3.4 2-4.9 4.9-4.8 9 0.3 20.5 0.2 41 0.1 61.5 0 5.1-3.2 8.4-7.5 8.4-4.5 0-7.6-3.2-7.9-8.2-0.1-1.4 0-2.8 0-4.1v-47c0-1.9 0-3.8-0.1-5.7-1.3-12.8-9.1-19.6-22.7-19.1 0-0.1-7.3 0-16.4 3.1')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m152.5 52.4c1.3-12.7 10.4-20.6 23.7-21.7 7.3-0.6 14.8 0.2 22.1 1.1 5.6 0.7 8.3 4.4 7.5 8.4s-4.7 6.1-10.2 5.6c-4.8-0.4-9.7-1.2-14.6-1-8.5 0.4-13 4.6-13.3 12.7-0.4 11.9-0.3 23.8-0.3 35.6v25.6c-0.1 6.4-2.8 9.9-7.6 10.1-5.2 0.2-8.2-3.5-8.3-9.9v-30.2c0.2-0.2-0.2-24.4 1-36.3')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m235.7 80v39.2c0 6-2.8 9.4-7.5 9.4-5 0-8-3.5-8-9.7v-54c0-6.5 2.6-9.8 7.6-9.9 5.2-0.1 7.9 3.2 7.9 10.1v14.9')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m227.6 47.1c-4.4 0-8.4-4-8.4-8.3 0-4.8 3.7-8.4 8.6-8.4s8.9 3.8 8.7 8.4c-0.1 4.5-4.2 8.3-8.9 8.3')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m131.9 59.5c-2.5-15.5-13.4-25.3-29.9-28.2-8.1-1.4-15.8-1.2-23.4 0.3-3.4 0.6-5.8 3.3-5.9 6.8-0.1 3.8 3.4 7.3 7.3 7.2 0.6 0 1.1-0.1 1.6-0.2 5.2-1.2 10.5-1.5 16.1-0.5 12.5 2.1 18.7 8.8 20 23.4 0.8 9.4 0.4 18.9-0.7 28.3-1.4 11.6-9.1 17.9-21.4 18.9-9.4 0.8-18.3-1-26.9-4.7-2.6-1.1-3.7-2.5-3.7-5.4 0.2-8.4 0.1-16.9 0.1-25.3 0-6.8-0.1-13.7 0-20.5v-12c0-4-3.5-7.4-7.8-7.5-1.3 0-2.5 0.2-3.6 0.7-9 4-15.4 7.3-30.2 15.1-5.6 3.1-7.1 7.5-4.5 11.7 2.4 4 6.7 4.8 12 1.9 6-3.3 12.2-6.4 18.5-9.4 0.1 32 0.1 72.8 0.1 103.7v0.1 0.1 0.2c0.2 4 3.6 7.1 7.8 7.1s7.6-3.2 7.8-7.1v-38.9c8.7 1.6 16.5 3.7 24.6 4.4 22.2 1.9 39.8-10.1 42.9-30.9 1.7-13.1 1.2-26.5-0.8-39.3')
					]),
				_List_Nil),
				A2(
				$elm$svg$Svg$path,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('prima-logo__svg__path'),
						$elm$svg$Svg$Attributes$fill(
						$author$project$View$LogoOld$getPrimaryColorBySkin(skin)),
						$elm$svg$Svg$Attributes$d('m489.1 59.6c-0.5-13.9-8.8-23.8-22.4-27.3-15.4-3.9-30.6-2-45.2 3.7-4.8 1.9-6.6 5.8-5.1 9.5 1.4 3.5 5.5 4.8 10.7 3.3 0 0 13.6-4.5 27-4.4 11.4 0.1 17.9 5.6 19.5 15.7 0.3 1.8 0.3 3.6 0.3 5.5l0.1 19.7v0.2 0.3 0.5c0 7.1 0.2 13.6-0.2 20-0.1 1.8-1.6 4.3-3.2 5.1-11.3 5.5-23.2 6.4-35.3 2.7-7.7-2.4-11.4-8.1-11.4-15.8 0-7 4.2-12.4 11.9-14.9 7.5-2.5 14.9-2.3 22.2-1.2 0.4 0.1 0.7 0.1 1.1 0.1 3.5 0 6.3-2.8 6.3-6.3 0-3.1-2.2-5.6-5.1-6.1-9.7-1.4-19.4-1.7-29.2 1.1-14.4 4.1-22.7 14-22.8 27.4 0 14.5 7.9 25.5 22.6 29.2 18.9 4.7 37.1 1.7 53.9-7.6 2.1-1.1 4.2-4.3 4.2-6.5 0.4-18.1 0.7-36 0.1-53.9')
					]),
				_List_Nil)
			]));
};
var $author$project$View$LogoOld$render = function (config) {
	var skin = config.ls;
	return A2(
		$author$project$View$Helper$wrapInContainerRowWithClass,
		'mq-small-flex-center',
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				A2(
					$elm$core$List$append,
					$author$project$View$LogoOld$buildAttributes(config),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$target('_blank')
						])),
				_List_fromArray(
					[
						$author$project$View$LogoOld$renderLogo(skin)
					])),
				function () {
				var _v0 = $author$project$View$LogoOld$computeOptions(config).de;
				if (!_v0.$) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('c-text-dark'),
								$elm$html$Html$Attributes$class('stepper__text')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mq-small-hidden')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fw-heavy')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Preventivo casa e famiglia ')
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('mr-1')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('step')
											]))
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('badge badge--primary')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Maybe$map,
												$elm$core$String$fromInt,
												$author$project$View$LogoOld$computeOptions(config).de))),
										$elm$html$Html$text('/'),
										$elm$html$Html$text(
										$elm$core$String$fromInt(
											$author$project$View$LogoOld$computeOptions(config).hU))
									]))
							]));
				} else {
					return $elm$html$Html$text('');
				}
			}()
			]));
};
var $author$project$View$LogoOld$Class = function (a) {
	return {$: 0, a: a};
};
var $author$project$View$LogoOld$addOption = F2(
	function (option, _v0) {
		var logoConfig = _v0;
		return _Utils_update(
			logoConfig,
			{
				ku: _Utils_ap(
					logoConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $author$project$View$LogoOld$withClass = function (_class) {
	return $author$project$View$LogoOld$addOption(
		$author$project$View$LogoOld$Class(_class));
};
var $author$project$View$LogoOld$Step = function (a) {
	return {$: 3, a: a};
};
var $author$project$View$LogoOld$withCurrentStep = function (step) {
	return $author$project$View$LogoOld$addOption(
		$author$project$View$LogoOld$Step(step));
};
var $author$project$View$LogoOld$Url = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$LogoOld$withUrl = function (url) {
	return $author$project$View$LogoOld$addOption(
		$author$project$View$LogoOld$Url(url));
};
var $author$project$View$Template$logoBar = F2(
	function (url, currentStep) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('template-logo-bar')
				]),
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Container$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Container$withContent,
						_List_fromArray(
							[
								$author$project$View$LogoOld$render(
								A2(
									$author$project$View$LogoOld$withUrl,
									url,
									A2(
										$author$project$View$LogoOld$withCurrentStep,
										currentStep,
										A2($author$project$View$LogoOld$withClass, 'template-logo-bar__logo', $author$project$View$LogoOld$base))))
							]),
						A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'mq-medium-flex-center', $primait$pyxis_components$Prima$Pyxis$Container$row)))
				]));
	});
var $author$project$View$LogoOld$Alt = 1;
var $author$project$View$LogoOld$alt = A2($author$project$View$LogoOld$LogoConfiguration, 1, _List_Nil);
var $author$project$View$OldFooter$buildClasses = function (options) {
	return $elm$html$Html$Attributes$class(
		A2($elm$core$String$join, ' ', options.c6));
};
var $author$project$View$OldFooter$applyOption = F2(
	function (modifier, options) {
		if (!modifier.$) {
			var _class = modifier.a;
			return _Utils_update(
				options,
				{
					c6: A2($elm$core$List$cons, _class, options.c6)
				});
		} else {
			var id = modifier.a;
			return _Utils_update(
				options,
				{
					nM: $elm$core$Maybe$Just(id)
				});
		}
	});
var $author$project$View$OldFooter$defaultOptions = {
	c6: _List_fromArray(
		['footer']),
	nM: $elm$core$Maybe$Nothing
};
var $author$project$View$OldFooter$computeOptions = function (_v0) {
	var config = _v0;
	return A3($elm$core$List$foldl, $author$project$View$OldFooter$applyOption, $author$project$View$OldFooter$defaultOptions, config.ku);
};
var $author$project$View$OldFooter$buildAttributes = function (buttonConfig) {
	var options = $author$project$View$OldFooter$computeOptions(buttonConfig);
	return A2(
		$elm$core$List$cons,
		$author$project$View$OldFooter$buildClasses(options),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.nM)
				])));
};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $author$project$View$OldFooter$renderPaymentMethods = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('footer__payment-methods text-align-center hide-small-down')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('footer__payment-methods__cards'),
					$elm$html$Html$Attributes$src('/images/payment-methods-list.png')
				]),
			_List_Nil)
		]));
var $author$project$View$OldFooter$render = function (config) {
	var showPaymentMethods = config.eo;
	var showLogo = config.en;
	var content = config.mQ;
	return A2(
		$elm$html$Html$footer,
		$author$project$View$OldFooter$buildAttributes(config),
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Container$render(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withContent,
					_List_fromArray(
						[
							A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, showPaymentMethods, $author$project$View$OldFooter$renderPaymentMethods),
							A2(
							$author$project$PrimaElm$Lib$Utils$Render$renderIf,
							showLogo,
							$author$project$View$LogoOld$render(
								A2($author$project$View$LogoOld$withClass, 'footer__logo', $author$project$View$LogoOld$alt))),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('footer__legal')
								]),
							content)
						]),
					$primait$pyxis_components$Prima$Pyxis$Container$column))
			]));
};
var $author$project$View$Template$topBarView = function (_v0) {
	var content = _v0.mQ;
	var isTopBarFixed = _v0.jX;
	var _class = _v0.bX;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(_class),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('sticky', isTopBarFixed)
					]))
			]),
		_List_fromArray(
			[
				$primait$pyxis_components$Prima$Pyxis$Container$render(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withContent,
					content,
					A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'justify-content-space-between align-items-center full-height', $primait$pyxis_components$Prima$Pyxis$Container$row)))
			]));
};
var $author$project$View$OldFooter$Class = function (a) {
	return {$: 0, a: a};
};
var $author$project$View$OldFooter$addOption = F2(
	function (option, _v0) {
		var footerConfig = _v0;
		return _Utils_update(
			footerConfig,
			{
				ku: _Utils_ap(
					footerConfig.ku,
					_List_fromArray(
						[option]))
			});
	});
var $author$project$View$OldFooter$withClass = function (_class) {
	return $author$project$View$OldFooter$addOption(
		$author$project$View$OldFooter$Class(_class));
};
var $author$project$View$Template$render = function (_v0) {
	var topBarContent = _v0.eH;
	var mainContent = _v0.dW;
	var sideContent = _v0.ep;
	var classes = _v0.c6;
	var logoUrl = _v0.dV;
	var currentStep = _v0.de;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('template' + classes)
			]),
		_List_fromArray(
			[
				A2($author$project$View$Template$logoBar, logoUrl, currentStep),
				A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				$elm$html$Html$text(''),
				$author$project$View$Template$topBarView,
				topBarContent),
				A2($author$project$View$Template$bodyView, mainContent, sideContent),
				$author$project$View$OldFooter$render(
				A2($author$project$View$OldFooter$withClass, 'mq-small-hidden', $author$project$View$OldFooter$create))
			]));
};
var $author$project$View$Template$Template = $elm$core$Basics$identity;
var $author$project$View$Template$MainContent = $elm$core$Basics$identity;
var $author$project$View$Template$initMainContent = $elm$html$Html$text('');
var $author$project$View$Template$SideContent = $elm$core$Basics$identity;
var $author$project$View$Template$initSideContent = $elm$core$Maybe$Nothing;
var $author$project$View$Template$template = {c6: '', de: $elm$core$Maybe$Nothing, dV: 'https://www.prima.it', dW: $author$project$View$Template$initMainContent, ep: $author$project$View$Template$initSideContent, eH: $elm$core$Maybe$Nothing};
var $author$project$Guarantees$Helpers$calculatePackagesFullPrice = F2(
	function (packages, userSelection) {
		return A3($author$project$Guarantees$Helpers$calculatePackagesPriceWithType, packages, userSelection, 1);
	});
var $author$project$Checkout$TopBar$View$getSelectionFullPrice = F3(
	function (selection, save, quotePrice) {
		var _v0 = save.pd.lU;
		if (!_v0) {
			return A2(
				$author$project$Guarantees$Helpers$calculatePackagesFullPrice,
				$author$project$Guarantees$Helpers$pickPackagesFromSave(save),
				selection);
		} else {
			return A3($author$project$Types$Quote$Selection$calculateTotalPrice, 1, 0, quotePrice);
		}
	});
var $author$project$Checkout$TopBar$View$getSelectionPrice = F3(
	function (selection, save, quotePrice) {
		var _v0 = save.pd.lU;
		if (!_v0) {
			return A2(
				$author$project$Guarantees$Helpers$calculatePackagesPrice,
				$author$project$Guarantees$Helpers$pickPackagesFromSave(save),
				selection);
		} else {
			return A3($author$project$Types$Quote$Selection$calculateTotalPrice, 0, 0, quotePrice);
		}
	});
var $author$project$Checkout$TopBar$View$discountMessageView = function (message) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__discount-badge')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__discount-badge-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]))
			]));
};
var $author$project$Guarantees$Helpers$matchDiscountType = F2(
	function (discountType, _v0) {
		var messageType = _v0.og;
		var kind = messageType;
		return _Utils_eq(kind, discountType);
	});
var $author$project$Guarantees$Helpers$pickDiscountMessageByType = F2(
	function (type_, _v0) {
		var messages = _v0.oh;
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				$author$project$Guarantees$Helpers$matchDiscountType(type_),
				messages));
	});
var $author$project$Checkout$TopBar$View$conditionalDiscountMessageView = F2(
	function (selectionFullPrice, selectionPrice) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Guarantees$Helpers$pickDiscountMessageByType(0),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mQ;
						},
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.mi;
							},
							$author$project$Checkout$TopBar$View$discountMessageView))),
				A2(
					$elm$core$Basics$composeR,
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe,
					$author$project$PrimaElm$Lib$Utils$Render$renderIf(
						A2($prikhi$decimal$Decimal$lt, selectionPrice, selectionFullPrice)))));
	});
var $author$project$Guarantees$View$Helpers$paymentFrequencyTextView = function (userSelection) {
	return $elm$html$Html$text(
		$author$project$Guarantees$View$Helpers$formatPaymentFrequency(
			$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection)));
};
var $author$project$Checkout$TopBar$View$priceContentView = F5(
	function (selection, save, quotePrice, selectionPrice, dataTestIdSuffix) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__price-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkout__top-bar__price-value'),
							A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'top-bar__price' + dataTestIdSuffix)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Utils$pricify(selectionPrice))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkout__top-bar__price-frequency')
						]),
					_List_fromArray(
						[
							$author$project$Guarantees$View$Helpers$paymentFrequencyTextView(selection)
						])),
					A3(
					$author$project$Checkout$TopBar$View$conditionalDiscountMessageView,
					A3($author$project$Checkout$TopBar$View$getSelectionFullPrice, selection, save, quotePrice),
					A3($author$project$Checkout$TopBar$View$getSelectionPrice, selection, save, quotePrice),
					quotePrice)
				]));
	});
var $author$project$Checkout$TopBar$View$priceDiscountView = F2(
	function (selectionFullPrice, selectionPrice) {
		return A2($prikhi$decimal$Decimal$lt, selectionPrice, selectionFullPrice) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__price-discount')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Utils$pricify(selectionFullPrice))
				])) : $elm$html$Html$text('');
	});
var $author$project$Checkout$TopBar$View$isExistingMotorCustomerBadgeVisible = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$Helpers$pickDiscountMessageByType(0),
	$elm_community$maybe_extra$Maybe$Extra$isJust);
var $author$project$Checkout$TopBar$View$titleTextMobileView = F2(
	function (_v0, quotePrice) {
		var productType = _v0.kX;
		if (!productType.$) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__title-text'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'checkout__top-bar__badge-visible',
								$author$project$Checkout$TopBar$View$isExistingMotorCustomerBadgeVisible(quotePrice))
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preventivo casa')
					]));
		} else {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__title-text'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'checkout__top-bar__badge-visible',
								$author$project$Checkout$TopBar$View$isExistingMotorCustomerBadgeVisible(quotePrice))
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preventivo famiglia')
					]));
		}
	});
var $author$project$Checkout$TopBar$View$titleMobileView = F3(
	function (selection, _v0, quotePrice) {
		var save = _v0.py;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__title')
				]),
			_List_fromArray(
				[
					A2($author$project$Checkout$TopBar$View$titleTextMobileView, save, quotePrice),
					A2(
					$author$project$Checkout$TopBar$View$priceDiscountView,
					A3($author$project$Checkout$TopBar$View$getSelectionFullPrice, selection, save, quotePrice),
					A3($author$project$Checkout$TopBar$View$getSelectionPrice, selection, save, quotePrice)),
					A5(
					$author$project$Checkout$TopBar$View$priceContentView,
					selection,
					save,
					quotePrice,
					A3($author$project$Checkout$TopBar$View$getSelectionPrice, selection, save, quotePrice),
					'-mobile')
				]));
	});
var $author$project$Checkout$TopBar$View$leftSideMobileView = F3(
	function (selection, order, quotePrice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__left-side')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkout__top-bar__title-content')
						]),
					_List_fromArray(
						[
							A3($author$project$Checkout$TopBar$View$titleMobileView, selection, order, quotePrice)
						]))
				]));
	});
var $author$project$Checkout$TopBar$View$conditionalLeftSideMobileView = function (_v0) {
	var order = _v0.oK;
	var userSelection = _v0.lQ;
	var quotePrice = _v0.k5;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A4($elm$core$Maybe$map3, $author$project$Checkout$TopBar$View$leftSideMobileView, userSelection, order, quotePrice));
};
var $author$project$Checkout$TopBar$View$frequencyView = function (classNames) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(classNames)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__payment-frequency-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pagamento annuale')
					]))
			]));
};
var $author$project$Checkout$TopBar$View$frequencyMobileView = function (quotePrice) {
	return $author$project$Checkout$TopBar$View$isExistingMotorCustomerBadgeVisible(quotePrice) ? $author$project$Checkout$TopBar$View$frequencyView('checkout__top-bar__payment-frequency-mobile checkout__top-bar__badge-visible') : $author$project$Checkout$TopBar$View$frequencyView('checkout__top-bar__payment-frequency-mobile');
};
var $author$project$Checkout$TopBar$View$saveCodeView = function (saveCode) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__save-code')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(saveCode)
			]));
};
var $author$project$Checkout$TopBar$View$rightSideMobileView = F2(
	function (_v0, quotePrice) {
		var save = _v0.py;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__right-side')
				]),
			_List_fromArray(
				[
					$author$project$Checkout$TopBar$View$frequencyMobileView(quotePrice),
					$author$project$Checkout$TopBar$View$saveCodeView(save.lg)
				]));
	});
var $author$project$Checkout$TopBar$View$conditionalRightSideMobileView = function (_v0) {
	var order = _v0.oK;
	var quotePrice = _v0.k5;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A3($elm$core$Maybe$map2, $author$project$Checkout$TopBar$View$rightSideMobileView, order, quotePrice));
};
var $author$project$Checkout$TopBar$View$dataMobileView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__data-mobile')
			]),
		_List_fromArray(
			[
				$author$project$Checkout$TopBar$View$conditionalLeftSideMobileView(model),
				$author$project$Checkout$TopBar$View$conditionalRightSideMobileView(model)
			]));
};
var $author$project$Checkout$TopBar$View$titleTextView = function (_v0) {
	var productType = _v0.kX;
	if (!productType.$) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__title-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Preventivo casa e famiglia')
				]));
	} else {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__title-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Preventivo famiglia')
				]));
	}
};
var $author$project$Checkout$TopBar$View$titleView = function (save) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__title')
			]),
		_List_fromArray(
			[
				$author$project$Checkout$TopBar$View$titleTextView(save),
				$author$project$Checkout$TopBar$View$saveCodeView(save.lg)
			]));
};
var $author$project$Checkout$TopBar$View$leftSideView = function (_v0) {
	var save = _v0.py;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__left-side')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__title-content')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$View$Svg$svg,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('checkout__top-bar__house-logo')
							]),
						'',
						'preventivo-casa'),
						$author$project$Checkout$TopBar$View$titleView(save)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__title-flat-background')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__title-curved-background')
					]),
				_List_Nil)
			]));
};
var $author$project$Checkout$TopBar$View$conditionalLeftSideView = function (_v0) {
	var order = _v0.oK;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A2($elm$core$Maybe$map, $author$project$Checkout$TopBar$View$leftSideView, order));
};
var $author$project$Checkout$TopBar$View$frequencyDesktopView = $author$project$Checkout$TopBar$View$frequencyView('checkout__top-bar__payment-frequency');
var $author$project$Checkout$TopBar$View$priceView = F3(
	function (selection, _v0, quotePrice) {
		var save = _v0.py;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__price')
				]),
			_List_fromArray(
				[
					$author$project$Checkout$TopBar$View$frequencyMobileView(quotePrice),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('checkout__top-bar__price-content')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Checkout$TopBar$View$priceDiscountView,
							A3($author$project$Checkout$TopBar$View$getSelectionFullPrice, selection, save, quotePrice),
							A3($author$project$Checkout$TopBar$View$getSelectionPrice, selection, save, quotePrice)),
							A5(
							$author$project$Checkout$TopBar$View$priceContentView,
							selection,
							save,
							quotePrice,
							A3($author$project$Checkout$TopBar$View$getSelectionPrice, selection, save, quotePrice),
							'-desktop')
						]))
				]));
	});
var $author$project$Checkout$TopBar$View$rightSideView = F3(
	function (selection, order, quotePrice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('checkout__top-bar__right-side')
				]),
			_List_fromArray(
				[
					$author$project$Checkout$TopBar$View$frequencyDesktopView,
					A3($author$project$Checkout$TopBar$View$priceView, selection, order, quotePrice)
				]));
	});
var $author$project$Checkout$TopBar$View$conditionalRightSideView = function (_v0) {
	var order = _v0.oK;
	var userSelection = _v0.lQ;
	var quotePrice = _v0.k5;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A4($elm$core$Maybe$map3, $author$project$Checkout$TopBar$View$rightSideView, userSelection, order, quotePrice));
};
var $author$project$Checkout$TopBar$View$dataView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__data-desktop')
			]),
		_List_fromArray(
			[
				$author$project$Checkout$TopBar$View$conditionalLeftSideView(model),
				$author$project$Checkout$TopBar$View$conditionalRightSideView(model)
			]));
};
var $author$project$View$MobileSummaryView$view = function (openSummary) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(openSummary),
				$elm$html$Html$Attributes$class('mobile-data-summary-button')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('display-flex align-items-flex-end')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mobile-data-summary-button__text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('riepilogo dati')
							])),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mobile-data-summary-button__icon icon-caret-right')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Checkout$TopBar$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('checkout__top-bar__content')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('checkout__top-bar__data-wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Checkout$TopBar$View$dataView(model),
						$author$project$Checkout$TopBar$View$dataMobileView(model)
					])),
				$author$project$View$MobileSummaryView$view(
				$author$project$Checkout$Model$Toggle(
					$author$project$Checkout$Model$Summary(true)))
			]));
};
var $author$project$View$Template$withClasses = F2(
	function (classes, _v0) {
		var templateConfig = _v0;
		return _Utils_update(
			templateConfig,
			{c6: templateConfig.c6 + (' ' + classes)});
	});
var $author$project$View$Template$withLogoUrl = F2(
	function (url, _v0) {
		var templateConfig = _v0;
		return _Utils_update(
			templateConfig,
			{dV: url});
	});
var $author$project$View$Template$withMainContent = F2(
	function (mainContent, _v0) {
		var templateConfig = _v0;
		return _Utils_update(
			templateConfig,
			{dW: mainContent});
	});
var $author$project$View$Template$withSideContent = F2(
	function (sideContent, _v0) {
		var templateConfig = _v0;
		return _Utils_update(
			templateConfig,
			{
				ep: $elm$core$Maybe$Just(sideContent)
			});
	});
var $author$project$View$Template$TopBarContent = $elm$core$Basics$identity;
var $author$project$View$Template$withTopBarContent = F4(
	function (isTopBarFixed, tabBarContent, topBarClass, _v0) {
		var templateConfig = _v0;
		return _Utils_update(
			templateConfig,
			{
				eH: $elm$core$Maybe$Just(
					{bX: topBarClass, mQ: tabBarContent, jX: isTopBarFixed})
			});
	});
var $author$project$Checkout$View$view = function (model) {
	var flags = model.nv;
	return $author$project$View$Template$render(
		A2(
			$author$project$View$Template$withLogoUrl,
			$author$project$Types$pickPrimaUrl(flags),
			A2(
				$author$project$View$Template$withSideContent,
				$author$project$Checkout$Sidebar$View$checkoutSidebarView(model),
				A2(
					$author$project$View$Template$withMainContent,
					$author$project$Checkout$View$mainView(model),
					A4(
						$author$project$View$Template$withTopBarContent,
						false,
						_List_fromArray(
							[
								$author$project$Checkout$TopBar$View$view(model)
							]),
						'checkout__top-bar',
						A2($author$project$View$Template$withClasses, 'checkout', $author$project$View$Template$template))))));
};
var $author$project$Types$redirectUrl = F2(
	function (flags, userType) {
		if (!userType) {
			return $author$project$Types$pickPrimaUrl(flags);
		} else {
			return $author$project$Types$pickIntermediariUrl(flags);
		}
	});
var $author$project$Guarantees$Model$RedirectToEdit = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Guarantees$Model$DetailModal = function (a) {
	return {$: 7, a: a};
};
var $author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded = F2(
	function (packageSlug, _v0) {
		var packages = _v0.oN;
		return A2(
			$elm$core$List$any,
			function (_v1) {
				var slug = _v1.bH;
				var inclusionRules = _v1.nR;
				return _Utils_eq(slug, packageSlug) && A2($elm$core$List$member, 1, inclusionRules);
			},
			packages);
	});
var $author$project$Guarantees$Helpers$calculatePackageFullPrice = F2(
	function (_package, userSelection) {
		return A3($author$project$Guarantees$Helpers$calculateSelectedPackagePrice, _package, userSelection, 1);
	});
var $author$project$Guarantees$View$Helpers$packagePriceView = F3(
	function (paymentFrequency, _package, userSelection) {
		var packagePrice = A2($author$project$Guarantees$Helpers$calculatePackagePrice, _package, userSelection);
		var packageFullPrice = A2($author$project$Guarantees$Helpers$calculatePackageFullPrice, _package, userSelection);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-packages-package-card-price-value')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					A2($prikhi$decimal$Decimal$lt, packagePrice, packageFullPrice),
					A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-small fw-base text-decoration-line-through c-text-base package-footer-multiple-discount')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Utils$pricify(packageFullPrice))
							]))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('package-footer-multiple-nodiscount')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Utils$pricify(packagePrice))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fs-small fw-base')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Guarantees$View$Helpers$formatPaymentFrequency(paymentFrequency))
								]))
						]))
				]));
	});
var $author$project$Guarantees$Model$PackageField = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $primait$pyxis_components$Prima$Pyxis$Button$primary = function (label) {
	return A6($primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig, 1, false, 0, label, $elm$core$Maybe$Nothing, _List_Nil);
};
var $author$project$Guarantees$View$Helpers$togglePackageButtonConfig = F2(
	function (_package, isPackageSelected) {
		return isPackageSelected ? A2(
			$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
			$author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$PackageField, _package, false)),
			$primait$pyxis_components$Prima$Pyxis$Button$tertiary('Rimuovi')) : A2(
			$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
			$author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$PackageField, _package, true)),
			$primait$pyxis_components$Prima$Pyxis$Button$primary('Seleziona'));
	});
var $author$project$Guarantees$View$V1$Details$modalFooter = F3(
	function (userSelection, _package, slug) {
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			!A2($author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, slug, userSelection),
			$primait$pyxis_components$Prima$Pyxis$Container$render(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withContent,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-background-dark fw-heavy')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Guarantees$View$Helpers$packagePriceView,
									$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection),
									_package,
									userSelection)
								])),
							A2(
							$author$project$PrimaElm$Lib$Utils$Render$renderIf,
							!A2($author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, slug, userSelection),
							A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$primait$pyxis_components$Prima$Pyxis$Button$render(
										$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
											A2(
												$author$project$Guarantees$View$Helpers$togglePackageButtonConfig,
												_package,
												A2($author$project$Guarantees$Model$UserSelection$isPackageSelected, slug, userSelection))))
									])))
						]),
					A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-package-detail-footer justify-content-space-between align-items-center ', $primait$pyxis_components$Prima$Pyxis$Container$row))));
	});
var $author$project$Guarantees$View$V1$Details$maybeAddFooter = F4(
	function (userSelection, _package, slug, modal) {
		return (!A2($author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, slug, userSelection)) ? A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$withFooterContent,
			_List_fromArray(
				[
					A3($author$project$Guarantees$View$V1$Details$modalFooter, userSelection, _package, slug)
				]),
			modal) : modal;
	});
var $author$project$Components$ArrowAccordionGroup$isAccordionOpen = function (accordionSlug) {
	return A2(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		false,
		$elm$core$Basics$eq(accordionSlug));
};
var $author$project$Components$ArrowAccordion$renderContent = F2(
	function (content, maybeRender) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (content_, doRender) {
						return doRender(content_);
					}),
				content,
				maybeRender));
	});
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Components$CheckboxSwitch$render = function (_v0) {
	var isChecked = _v0.dL;
	var onCheckMsg = _v0.gb;
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('switch')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('checkbox'),
						$elm$html$Html$Attributes$checked(isChecked),
						$elm$html$Html$Events$onCheck(onCheckMsg)
					]),
				_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('slider round')
					]),
				_List_Nil)
			]));
};
var $author$project$Components$ArrowAccordion$renderSwitch = function (_v0) {
	var maybeContent = _v0.cj;
	var checkBoxSwitch = _v0.bf;
	var isRenderSwitch = _v0.dP;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A3(
			$elm$core$Maybe$map2,
			F2(
				function (content, _switch) {
					return A2(
						$author$project$PrimaElm$Lib$Utils$Render$renderIf,
						isRenderSwitch(content),
						$author$project$Components$CheckboxSwitch$render(_switch));
				}),
			maybeContent,
			checkBoxSwitch));
};
var $author$project$Components$ArrowAccordion$renderTitle = F2(
	function (isOpen, config) {
		var slug = config.bH;
		var tagger = config.hS;
		var title = config.eE;
		var event = tagger(slug);
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('a-accordion__toggle  fs-small fw-heavy arrow-accordion-title'),
								$elm$html$Html$Events$onClick(event)
							]),
						_List_fromArray(
							[
								$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(title)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('display-flex align-items-center')
							]),
						_List_fromArray(
							[
								$author$project$Components$ArrowAccordion$renderSwitch(config),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$class('display-flex'),
										$elm$html$Html$Events$onClick(event)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('icon-caret-up', isOpen),
														_Utils_Tuple2('icon-caret-down', !isOpen),
														_Utils_Tuple2('icon c-brand-alt-base fs-large', true)
													]))
											]),
										_List_Nil)
									]))
							]))
					]),
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('c-action-base', isOpen),
							_Utils_Tuple2(' justify-content-space-between accordion accordion--light arrow-accordion-header  align-items-center', true)
						]),
					$primait$pyxis_components$Prima$Pyxis$Container$row)));
	});
var $author$project$Components$ArrowAccordion$render = F3(
	function (isOpen, contentRender, config) {
		var maybeContent = config.cj;
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2($author$project$Components$ArrowAccordion$renderTitle, isOpen, config),
						A2(
						$author$project$PrimaElm$Lib$Utils$Render$renderIf,
						isOpen,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('bg-shape-light full-width fs-small text-align-left arrow-accordion-content')
								]),
							_List_fromArray(
								[
									A2($author$project$Components$ArrowAccordion$renderContent, maybeContent, contentRender)
								])))
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'align-items-flex-start', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Components$ArrowAccordionGroup$renderGroup = function (_v0) {
	var accordionDict = _v0.at;
	var contentRender = _v0.db;
	var weightPicker = _v0.eO;
	var openAccordion = _v0.cp;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('arrow-accordion')
			]),
		A2(
			$elm$core$List$map,
			function (accordion) {
				var slug = accordion.bH;
				return A3(
					$author$project$Components$ArrowAccordion$render,
					A2($author$project$Components$ArrowAccordionGroup$isAccordionOpen, slug, openAccordion),
					contentRender,
					accordion);
			},
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.cj;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(weightPicker),
						$elm$core$Maybe$withDefault(0.0))),
				$elm$core$Dict$values(accordionDict))));
};
var $author$project$Guarantees$View$V1$Details$renderAccordionGroup = $author$project$Components$ArrowAccordionGroup$renderGroup;
var $author$project$Components$ArrowAccordionGroup$withWeightPicker = F2(
	function (weightPicker, config) {
		return _Utils_update(
			config,
			{eO: weightPicker});
	});
var $author$project$Guarantees$View$V1$Details$detailModal = F2(
	function (userSelection, _v0) {
		var showDetails = _v0.hx;
		var detailsAccordionGroup = _v0.iQ;
		var maybePackage = _v0.j9;
		return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				function (_package) {
					var label = _package.n6;
					var description = _package.m2;
					var slug = _package.bH;
					var claim = _package.mz;
					var maybeClaim = A2(
						$elm$core$Maybe$map,
						function (content) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-align-left')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('arrow-accordion-header-badge badge badge--primary')
											]),
										_List_fromArray(
											[content]))
									]));
						},
						A2($elm$core$Maybe$map, $elm$html$Html$text, claim));
					return $primait$pyxis_components$Prima$Pyxis$Modal$render(
						A4(
							$author$project$Guarantees$View$V1$Details$maybeAddFooter,
							userSelection,
							_package,
							slug,
							A2(
								$primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('package-modal-title text-align-left fw-heavy')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(label)
											])),
										A2(
										$author$project$PrimaElm$Lib$Utils$Render$renderIf,
										A2($author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, slug, userSelection),
										A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-align-left fs-small c-brand-base fw-base')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Sempre incluse nella polizza')
												]))),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-align-left fs-small c-text-base')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A2($elm$core$Maybe$withDefault, '', description))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-align-left fs-xxsmall fw-heavy c-text-dark arrow-accordion-description-include')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('COSA INCLUDE')
											])),
										$author$project$Guarantees$View$V1$Details$renderAccordionGroup(
										A2(
											$author$project$Components$ArrowAccordionGroup$withWeightPicker,
											function (_v1) {
												var g = _v1.b;
												return $author$project$Guarantees$Helpers$pickGuaranteeWeight(g);
											},
											detailsAccordionGroup))
									]),
								A2(
									$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderContent,
									_List_fromArray(
										[
											$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(maybeClaim)
										]),
									$primait$pyxis_components$Prima$Pyxis$Modal$withCloseOnOverlayClick(
										A2(
											$primait$pyxis_components$Prima$Pyxis$Modal$withClass,
											'package-modal-wrapper',
											A2(
												$primait$pyxis_components$Prima$Pyxis$Modal$medium,
												showDetails,
												$author$project$Guarantees$Model$OnToggle(
													$author$project$Guarantees$Model$DetailModal(!showDetails)))))))));
				},
				maybePackage));
	});
var $author$project$Guarantees$View$V1$Details$detailContainer = F2(
	function (userSelection, detailsModel) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2($author$project$Guarantees$View$V1$Details$detailModal, userSelection, detailsModel)
					]),
				$primait$pyxis_components$Prima$Pyxis$Container$column));
	});
var $author$project$Guarantees$Model$ShowDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$Guarantees$Helpers$pickGuaranteeClaim = A2(
	$elm$core$Basics$composeR,
	$author$project$Types$Quote$pickGuarantee,
	function ($) {
		return $.nC;
	});
var $author$project$Guarantees$View$V1$Packages$Multiple$guaranteeView = F3(
	function (_v0, _v1, guarantee) {
		var isExcluded = $author$project$Guarantees$Helpers$isGuaranteeExcluded(guarantee);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('justify-content-space-between guarantees-package-cluster-list-guarantees-guarantee')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-small fw-base'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-text-dark', !isExcluded),
									_Utils_Tuple2('c-text-base', isExcluded)
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee)),
							A3(
							$elm_community$maybe_extra$Maybe$Extra$unwrap,
							$elm$html$Html$text(''),
							A2(
								$elm$core$Basics$composeR,
								$elm$html$Html$text,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$singleton,
									$elm$html$Html$span(
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fs-xsmall ml-5-px')
											])))),
							$author$project$Guarantees$Helpers$pickGuaranteeClaim(guarantee))
						])),
					A3(
					$author$project$View$Svg$svg,
					_List_Nil,
					'pacchetti/',
					isExcluded ? 'negative' : 'check')
				]));
	});
var $author$project$Guarantees$Helpers$matchPaymentType = F2(
	function (paymentFrequency, guarantee) {
		var _v0 = _Utils_Tuple2(guarantee, paymentFrequency);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b) {
					var _v1 = _v0.b;
					return true;
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b === 1) {
					var _v2 = _v0.b;
					return true;
				} else {
					break _v0$2;
				}
			}
		}
		return false;
	});
var $author$project$Guarantees$View$V1$Packages$Multiple$guaranteesView = F2(
	function (userSelection, _package) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('package-content')
				]),
			A2(
				$elm$core$List$map,
				A2($author$project$Guarantees$View$V1$Packages$Multiple$guaranteeView, userSelection, _package),
				A2(
					$elm$core$List$filter,
					$author$project$Guarantees$Helpers$matchPaymentType(
						$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection)),
					A2(
						$elm$core$List$sortBy,
						$author$project$Guarantees$Helpers$pickGuaranteeWeight,
						A2(
							$elm$core$List$concatMap,
							function ($) {
								return $.nF;
							},
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.l0;
								},
								_package.mG))))));
	});
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Guarantees$View$Helpers$toggleDetailsView = F2(
	function (maybeLabel, event) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fs-small fw-base c-brand-alt-base is-active guarantees-package-detail-link display-flex'),
					$elm$html$Html$Events$onClick(event)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('guarantees-package-detail-link-caption')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($elm$core$Maybe$withDefault, 'Dettagli', maybeLabel))
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon icon-help fs-large guarantees-package-detail-icon fw-base')
						]),
					_List_Nil)
				]));
	});
var $author$project$Guarantees$View$V1$Packages$Multiple$packageCardContentView = F3(
	function (userSelection, _package, isPackageSelected) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('package-head')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('display-flex flex-direction-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fs-base-on-bp-large fw-heavy c-text-dark'),
												A2($elm$html$Html$Attributes$style, 'text-align', 'center')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(_package.n6)
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('badge badge--primary guarantees-packages-package-card-badge')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A2($elm$core$Maybe$withDefault, _package.n6, _package.mz))
											]))
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mq-xsmall-only guarantees-packages-package-card-details fs-small')
									]),
								_List_fromArray(
									[
										A2(
										$author$project$Guarantees$View$Helpers$toggleDetailsView,
										$elm$core$Maybe$Nothing,
										$author$project$Guarantees$Model$ShowDetails(_package))
									]))
							])),
						A2($author$project$Guarantees$View$V1$Packages$Multiple$guaranteesView, userSelection, _package),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mq-xsmall-hidden guarantees-packages-package-card-details fs-small')
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Guarantees$View$Helpers$toggleDetailsView,
								$elm$core$Maybe$Nothing,
								$author$project$Guarantees$Model$ShowDetails(_package))
							])),
						A2(
						$elm$html$Html$hr,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mq-xsmall-hidden')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('package-footer-multiple')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-packages-package-card-price fs-medium fw-base'),
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('c-brand-alt-dark', isPackageSelected),
												_Utils_Tuple2('c-text-dark', !isPackageSelected)
											]))
									]),
								_List_fromArray(
									[
										A3(
										$author$project$Guarantees$View$Helpers$packagePriceView,
										$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection),
										_package,
										userSelection)
									])),
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
									A2($author$project$Guarantees$View$Helpers$togglePackageButtonConfig, _package, isPackageSelected)))
							]))
					]),
				$primait$pyxis_components$Prima$Pyxis$Container$column));
	});
var $author$project$Components$Card$withClassList = F2(
	function (classes, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{c6: classes});
	});
var $author$project$Guarantees$View$V1$Packages$Multiple$packageCardView = F4(
	function (userSelection, indexVisiblePackage, currentIndex, _package) {
		var isSelected = A2($author$project$Guarantees$Model$UserSelection$isPackageSelected, _package.bH, userSelection);
		return $author$project$Components$Card$render(
			A2(
				$author$project$Components$Card$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2('bg-brand-alt-light', isSelected),
						_Utils_Tuple2('border-selected', isSelected),
						_Utils_Tuple2(
						'mq-medium-hidden',
						!_Utils_eq(indexVisiblePackage, currentIndex))
					]),
				A2(
					$author$project$Components$Card$withClass,
					'guarantees-packages-package-card-container',
					$author$project$Components$Card$card(
						A3($author$project$Guarantees$View$V1$Packages$Multiple$packageCardContentView, userSelection, _package, isSelected)))));
	});
var $author$project$Guarantees$View$V1$Packages$Multiple$multiplePackagesView = F3(
	function (userSelection, packages, indexVisiblePackage) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				A2(
					$elm$core$List$indexedMap,
					A2($author$project$Guarantees$View$V1$Packages$Multiple$packageCardView, userSelection, indexVisiblePackage),
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.l0;
						},
						packages)),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'justify-content-space-between multiple-packages', $primait$pyxis_components$Prima$Pyxis$Container$row)));
	});
var $author$project$Guarantees$Model$UpdateVisiblePackage = function (a) {
	return {$: 9, a: a};
};
var $author$project$Guarantees$View$V1$Packages$View$packageTab = F3(
	function (indexVisiblePackage, index, _package) {
		var tabLabel = $elm$html$Html$text(
			function () {
				var _v0 = A2($elm$core$String$split, ' ', _package.n6);
				_v0$2:
				while (true) {
					if (_v0.b) {
						if (!_v0.b.b) {
							var generic = _v0.a;
							return generic;
						} else {
							if (!_v0.b.b.b) {
								var _v1 = _v0.b;
								var specific = _v1.a;
								return specific;
							} else {
								break _v0$2;
							}
						}
					} else {
						break _v0$2;
					}
				}
				return _package.n6;
			}());
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'selected',
							_Utils_eq(indexVisiblePackage, index))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Guarantees$Model$UpdateVisiblePackage(index))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('icon icon-umbrella', _package.bH === 'casa_protetta'),
									_Utils_Tuple2('icon icon-alarm', _package.bH === 'casa_sicura'),
									_Utils_Tuple2('icon icon-diamond', _package.bH === 'casa_super')
								]))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tab-label')
						]),
					_List_fromArray(
						[tabLabel]))
				]));
	});
var $author$project$Components$InfoBanner$init = function (event) {
	return {fm: event, cf: $elm$core$Maybe$Nothing, dZ: $elm$core$Maybe$Nothing, ck: $elm$core$Maybe$Nothing, eh: $elm$core$Maybe$Nothing};
};
var $author$project$Components$InfoBanner$render = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('guarantee-banner no-wrap box-shadow--medium display-flex justify-content-flex-end ')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantee-banner-left c-text-alt-light')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantee-banner-left__content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon icon-ok fs-large guarantee-banner-icon')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('mq-medium-hidden')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fw-heavy fs-small')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A2(
													$elm$core$Maybe$withDefault,
													'',
													A2($elm$core$Maybe$map, $elm$core$Tuple$first, config.cf)))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fs-small')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A2(
													$elm$core$Maybe$withDefault,
													'',
													A2($elm$core$Maybe$map, $elm$core$Tuple$second, config.cf)))
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantee-banner-center c-text-dark mq-small-hidden')
					]),
				A2(
					$elm$core$List$map,
					function (label) {
						return A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fs-small fw-base')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon icon-check-checkbox fs-base c-brand-base')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('guarantee-banner-center-element')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(label)
										]))
								]));
					},
					A2($elm$core$Maybe$withDefault, _List_Nil, config.dZ))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantee-banner-center c-text-dark  hide-medium-up')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy fs-small')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2($elm$core$Maybe$map, $elm$core$Tuple$first, config.ck)))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-small c-brand-base')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A2(
									$elm$core$Maybe$withDefault,
									'',
									A2($elm$core$Maybe$map, $elm$core$Tuple$second, config.ck)))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantee-banner-right')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-small fw-base c-brand-alt-base is-active guarantees-package-detail-link display-flex justify-content-flex-end')
							]),
						_List_fromArray(
							[
								A2($author$project$Guarantees$View$Helpers$toggleDetailsView, config.eh, config.fm)
							]))
					]))
			]));
};
var $author$project$Components$InfoBanner$withLeftLabels = F2(
	function (labels, config) {
		return _Utils_update(
			config,
			{
				cf: $elm$core$Maybe$Just(labels)
			});
	});
var $author$project$Components$InfoBanner$withMiddleDesktopList = F2(
	function (list, config) {
		return _Utils_update(
			config,
			{
				dZ: $elm$core$Maybe$Just(list)
			});
	});
var $author$project$Components$InfoBanner$withMiddleMobileLabels = F2(
	function (labels, config) {
		return _Utils_update(
			config,
			{
				ck: $elm$core$Maybe$Just(labels)
			});
	});
var $author$project$Components$InfoBanner$withRightLabel = F2(
	function (label, config) {
		return _Utils_update(
			config,
			{
				eh: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Guarantees$View$V1$Packages$Single$bannerView = F2(
	function (_package, guaranteeLabelList) {
		return $author$project$Components$InfoBanner$render(
			A2(
				$author$project$Components$InfoBanner$withRightLabel,
				'Scopri le garanzie incluse',
				A2(
					$author$project$Components$InfoBanner$withMiddleMobileLabels,
					_Utils_Tuple2(_package.n6, 'Sempre incluse'),
					A2(
						$author$project$Components$InfoBanner$withMiddleDesktopList,
						guaranteeLabelList,
						A2(
							$author$project$Components$InfoBanner$withLeftLabels,
							_Utils_Tuple2(_package.n6, 'Sempre incluse'),
							$author$project$Components$InfoBanner$init(
								$author$project$Guarantees$Model$ShowDetails(_package)))))));
	});
var $author$project$Guarantees$View$V1$Packages$Single$guarantees = F2(
	function (userSelection, _package) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$second,
				$author$project$Guarantees$Helpers$matchPaymentType(
					$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection))),
			A2(
				$elm$core$List$concatMap,
				$author$project$Guarantees$Helpers$clusterToTuple,
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.l0;
					},
					_package.mG)));
	});
var $author$project$Guarantees$View$V1$Packages$Single$bannerSection = F2(
	function (userSelection, _package) {
		return A2(
			$author$project$Guarantees$View$V1$Packages$Single$bannerView,
			_package,
			A2(
				$elm$core$List$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Guarantees$Helpers$pickGuaranteeLabel),
				A2($author$project$Guarantees$View$V1$Packages$Single$guarantees, userSelection, _package)));
	});
var $author$project$Components$Box$Box = $elm$core$Basics$identity;
var $author$project$Components$Box$ContentSize = {$: 1};
var $author$project$Components$Box$box = function (content) {
	return {bY: '', c6: _List_Nil, mQ: content, hz: $author$project$Components$Box$ContentSize};
};
var $author$project$Components$Box$render = function (_v0) {
	var content = _v0.mQ;
	var classes = _v0.c6;
	var class_ = _v0.bY;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('box' + (' ' + class_)),
				$elm$html$Html$Attributes$classList(classes)
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Guarantees$View$V1$Packages$Single$singlePackageAddRemoveView = F3(
	function (_package, isPackageSelected, isPackageAlwaysIncluded) {
		var button = isPackageSelected ? A2(
			$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
			$author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$PackageField, _package, false)),
			$primait$pyxis_components$Prima$Pyxis$Button$tertiary('Rimuovi')) : A2(
			$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
			$author$project$Guarantees$Model$OnToggle(
				A2($author$project$Guarantees$Model$PackageField, _package, true)),
			$primait$pyxis_components$Prima$Pyxis$Button$primary('Seleziona'));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-column align-content-flex-end guarantees-button')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					!isPackageAlwaysIncluded,
					$primait$pyxis_components$Prima$Pyxis$Button$render(
						$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
							A2($primait$pyxis_components$Prima$Pyxis$Button$withClass, 'align-self-end', button)))),
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					isPackageAlwaysIncluded,
					A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('align-self-end c-brand-base fs-xsmall fw-base')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Copertura minima di polizza')
							])))
				]));
	});
var $primait$pyxis_components$Prima$Pyxis$Container$addOptionalEvent = F2(
	function (event, config) {
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Container$updateOptionsRecord,
			function (containerOptions) {
				return _Utils_update(
					containerOptions,
					{
						ng: _Utils_ap(
							containerOptions.ng,
							_List_fromArray(
								[event]))
					});
			},
			config);
	});
var $primait$pyxis_components$Prima$Pyxis$Container$withOnClick = function (msg) {
	return $primait$pyxis_components$Prima$Pyxis$Container$addOptionalEvent(
		$elm$html$Html$Events$onClick(msg));
};
var $author$project$Guarantees$View$V1$Packages$Single$withOnClick = F5(
	function (_package, cluster, guaranteeFrequency, isPackageSelected, config) {
		return ($author$project$Guarantees$Helpers$pickGuaranteeType(guaranteeFrequency) === 1) ? A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withOnClick,
			$author$project$Guarantees$Model$OnToggle(
				A4($author$project$Guarantees$Model$Guarantee, _package, cluster, guaranteeFrequency, !isPackageSelected)),
			config) : config;
	});
var $author$project$Guarantees$View$V1$Packages$Single$guaranteeView = F3(
	function (userSelection, _package, _v0) {
		var cluster = _v0.a;
		var guarantee = _v0.b;
		var isPackageSelected = A4($author$project$Guarantees$View$Helpers$isGuaranteeInPackageSelected, cluster, guarantee, _package, userSelection);
		var guaranteeToggleLabel = isPackageSelected ? 'Rimuovi' : 'Aggiungi';
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-small fw-base'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('c-text-dark', isPackageSelected),
										_Utils_Tuple2('c-text-base', !isPackageSelected)
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee))
							])),
						A2(
						$author$project$PrimaElm$Lib$Utils$Render$renderIf,
						$author$project$Guarantees$Helpers$pickGuaranteeType(guarantee) === 1,
						function (checkbox) {
							return A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-transform-uppercase fs-xsmall fw-heavy c-action-dark checkbox-text mq-small-hidden')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(guaranteeToggleLabel)
											])),
										checkbox
									]));
						}(
							$author$project$Components$CheckboxSwitch$render(
								A4($author$project$Guarantees$View$Helpers$guaranteeSwitch, _package, cluster, guarantee, userSelection))))
					]),
				A5(
					$author$project$Guarantees$View$V1$Packages$Single$withOnClick,
					_package,
					cluster,
					guarantee,
					isPackageSelected,
					A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-package-cluster-list-guarantees-guarantee justify-content-space-between align-items-center', $primait$pyxis_components$Prima$Pyxis$Container$row))));
	});
var $author$project$Guarantees$View$V1$Packages$Single$guaranteesListView = F2(
	function (userSelection, _package) {
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			$elm$core$List$length(
				A2($author$project$Guarantees$View$V1$Packages$Single$guarantees, userSelection, _package)) > 1,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-package-cluster-list-guarantees')
					]),
				A2(
					$elm$core$List$map,
					A2($author$project$Guarantees$View$V1$Packages$Single$guaranteeView, userSelection, _package),
					A2($author$project$Guarantees$View$V1$Packages$Single$guarantees, userSelection, _package))));
	});
var $author$project$Guarantees$View$V1$Packages$Single$singlePackageLabelView = F2(
	function (label, _package) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('package-head')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-base fw-heavy c-text-dark')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mq-xsmall-only')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Guarantees$View$Helpers$toggleDetailsView,
							$elm$core$Maybe$Nothing,
							$author$project$Guarantees$Model$ShowDetails(_package))
						]))
				]));
	});
var $author$project$Guarantees$View$V1$Packages$Single$singlePackageGuaranteesListView = F2(
	function (userSelection, _package) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-column guarantees-list')
				]),
			_List_fromArray(
				[
					A2($author$project$Guarantees$View$V1$Packages$Single$singlePackageLabelView, _package.n6, _package),
					A2($author$project$Guarantees$View$V1$Packages$Single$guaranteesListView, userSelection, _package),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('mq-xsmall-hidden')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Guarantees$View$Helpers$toggleDetailsView,
							$elm$core$Maybe$Nothing,
							$author$project$Guarantees$Model$ShowDetails(_package))
						]))
				]));
	});
var $author$project$Guarantees$View$V1$Packages$Single$singlePackagePriceView = F3(
	function (userSelection, _package, selected) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-column guarantees-price')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-medium fw-base align-self-end'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('c-brand-alt-dark', selected),
									_Utils_Tuple2('c-text-dark', !selected)
								]))
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Guarantees$View$Helpers$packagePriceView,
							$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection),
							_package,
							userSelection)
						]))
				]));
	});
var $author$project$Components$Box$withClassList = F2(
	function (classes, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{c6: classes});
	});
var $author$project$Guarantees$View$V1$Packages$Single$singlePackageGuaranteesView = F2(
	function (userSelection, _package) {
		var isSelected = A2($author$project$Guarantees$Model$UserSelection$isPackageSelected, _package.bH, userSelection);
		var isAlwaysIncluded = A2($author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, _package.bH, userSelection);
		return (!isAlwaysIncluded) ? $author$project$Components$Box$render(
			A2(
				$author$project$Components$Box$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2('bg-brand-alt-light', isSelected),
						_Utils_Tuple2('border-selected', isSelected)
					]),
				$author$project$Components$Box$box(
					$primait$pyxis_components$Prima$Pyxis$Container$render(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Container$withContent,
							_List_fromArray(
								[
									A2($author$project$Guarantees$View$V1$Packages$Single$singlePackageGuaranteesListView, userSelection, _package),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('package-footer')
										]),
									_List_fromArray(
										[
											A3($author$project$Guarantees$View$V1$Packages$Single$singlePackagePriceView, userSelection, _package, isSelected),
											A3($author$project$Guarantees$View$V1$Packages$Single$singlePackageAddRemoveView, _package, isSelected, isAlwaysIncluded)
										]))
								]),
							A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'package', $primait$pyxis_components$Prima$Pyxis$Container$row)))))) : A2($author$project$Guarantees$View$V1$Packages$Single$bannerSection, userSelection, _package);
	});
var $author$project$Guarantees$View$V1$Packages$Single$singlePackageView = F2(
	function (userSelection, _package) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withClass,
				'guarantees-package',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Container$withContent,
					_List_fromArray(
						[
							A2($author$project$Guarantees$View$V1$Packages$Single$singlePackageGuaranteesView, userSelection, _package)
						]),
					$primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Guarantees$View$V1$Packages$View$packageGroupView = F3(
	function (userSelection, indexVisiblePackage, _v0) {
		var packages = _v0.oN;
		if (packages.b && (!packages.b.b)) {
			var _package = packages.a;
			return A2($author$project$Guarantees$View$V1$Packages$Single$singlePackageView, userSelection, _package);
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('packages-tabs tab-list justify-content-space-between')
							]),
						A2(
							$elm$core$List$indexedMap,
							$author$project$Guarantees$View$V1$Packages$View$packageTab(indexVisiblePackage),
							packages)),
						A3($author$project$Guarantees$View$V1$Packages$Multiple$multiplePackagesView, userSelection, packages, indexVisiblePackage)
					]));
		}
	});
var $author$project$Guarantees$View$V1$Packages$View$packageGroupsView = F3(
	function (userSelection, packageGroups, indexVisiblePackage) {
		return A2(
			$elm$core$List$map,
			A2($author$project$Guarantees$View$V1$Packages$View$packageGroupView, userSelection, indexVisiblePackage),
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.l0;
				},
				packageGroups));
	});
var $author$project$Guarantees$View$V1$Sections$sectionBodyView = F3(
	function (userSelection, section, indexVisiblePackage) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				A3($author$project$Guarantees$View$V1$Packages$View$packageGroupsView, userSelection, section.oM, indexVisiblePackage),
				$primait$pyxis_components$Prima$Pyxis$Container$column));
	});
var $author$project$Guarantees$View$V1$Sections$sectionDescriptionView = function (description) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fs-small c-text-base'),
				A2($elm$html$Html$Attributes$style, 'padding-left', '7px')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(description)
			]));
};
var $author$project$Guarantees$View$V1$Sections$sectionIconView = function (slug) {
	return A3($author$project$View$Svg$svg, _List_Nil, 'cluster/', slug);
};
var $author$project$Guarantees$View$V1$Sections$sectionTitleView = function (_v0) {
	var label = _v0.n6;
	var slug = _v0.bH;
	var description = _v0.m2;
	return $primait$pyxis_components$Prima$Pyxis$Container$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Container$withContent,
			_List_fromArray(
				[
					$author$project$Guarantees$View$V1$Sections$sectionIconView(slug),
					$author$project$View$Helper$wrapInCol(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fw-base fs-large c-text-dark'),
									A2($elm$html$Html$Attributes$style, 'padding-top', '7px'),
									A2($elm$html$Html$Attributes$style, 'padding-left', '7px')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								])),
							$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
							A2($elm$core$Maybe$map, $author$project$Guarantees$View$V1$Sections$sectionDescriptionView, description))
						]))
				]),
			A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-sections-item-title', $primait$pyxis_components$Prima$Pyxis$Container$row)));
};
var $author$project$Guarantees$View$V1$Sections$sectionView = F3(
	function (userSelection, indexVisiblePackage, section) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						A2(
						$author$project$PrimaElm$Lib$Utils$Render$renderIf,
						!section.n5,
						$author$project$Guarantees$View$V1$Sections$sectionTitleView(section)),
						A3($author$project$Guarantees$View$V1$Sections$sectionBodyView, userSelection, section, indexVisiblePackage)
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-sections-item', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Guarantees$View$V1$Sections$sectionsView = F3(
	function (userSelection, sections, indexVisiblePackage) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				A2(
					$elm$core$List$map,
					A2($author$project$Guarantees$View$V1$Sections$sectionView, userSelection, indexVisiblePackage),
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.l0;
						},
						sections)),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, 'guarantees-sections', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Guarantees$View$V1$Sections$quotePriceView = F3(
	function (userSelection, price, indexVisiblePackage) {
		return A3($author$project$Guarantees$View$V1$Sections$sectionsView, userSelection, price.pB, indexVisiblePackage);
	});
var $author$project$Guarantees$Model$BottomBarCartSummary = function (a) {
	return {$: 9, a: a};
};
var $author$project$Guarantees$View$BottomBar$cartBtnView = F2(
	function (isSummaryVisible, selectedItems) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-bottombar__content__cart-btn'),
					$elm$html$Html$Events$onClick(
					$author$project$Guarantees$Model$OnToggle(
						$author$project$Guarantees$Model$BottomBarCartSummary(!isSummaryVisible)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon icon-cart guarantees-bottombar__content__cart-btn__icon')
						]),
					_List_Nil),
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					selectedItems > 0,
					A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__content__counter-badge')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(selectedItems))
							])))
				]));
	});
var $author$project$Guarantees$Model$CartSummaryPackage = function (a) {
	return {$: 10, a: a};
};
var $author$project$Guarantees$View$BottomBar$cartSummaryGuaranteeView = function (guaranteeLabel) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('guarantees-bottombar__summary__guarantee-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-bottombar__summary__guarantee-img-wrapper')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$View$Svg$svg,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__guarantee-img')
							]),
						'pacchetti/',
						'check')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-bottombar__summary__guarantee-label-wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__guarantee-label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(guaranteeLabel)
							]))
					]))
			]));
};
var $author$project$Guarantees$View$BottomBar$cartSummaryPackageView = F2(
	function (_v0, _v1) {
		var quote = _v0.pd;
		var userSelection = _v0.lQ;
		var label = _v1.n6;
		var price = _v1.oY;
		var selectedGuaranteesLabels = _v1.pD;
		var isExpanded = _v1.n2;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-left-wrapper')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('icon-caret-right', true),
												_Utils_Tuple2('guarantees-bottombar__summary__package-arrow', true),
												_Utils_Tuple2('guarantees-bottombar__summary__package-arrow--expanded', isExpanded),
												_Utils_Tuple2('guarantees-bottombar__summary__package-arrow--hidden', quote.lU === 1)
											]))
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-label-wrapper')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$classList(
												_List_fromArray(
													[
														_Utils_Tuple2('guarantees-bottombar__summary__package-label', true),
														_Utils_Tuple2('guarantees-bottombar__summary__package-label--expanded', isExpanded)
													]))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(label)
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-price-wrapper')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-price')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(price)
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('guarantees-bottombar__summary__package-price-frequency')
											]),
										_List_fromArray(
											[
												$author$project$Guarantees$View$Helpers$paymentFrequencyTextView(userSelection)
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('guarantees-bottombar__summary__guarantees-list', true),
								_Utils_Tuple2('guarantees-bottombar__summary__guarantees-list--collapsed', !isExpanded)
							]))
					]),
				A2($elm$core$List$map, $author$project$Guarantees$View$BottomBar$cartSummaryGuaranteeView, selectedGuaranteesLabels))
			]);
	});
var $author$project$Guarantees$View$BottomBar$cartSummaryPackageViewByVersion = F2(
	function (viewConfig, cartSummaryElement) {
		var quote = viewConfig.pd;
		var slug = cartSummaryElement.bH;
		var _v0 = quote.lU;
		if (!_v0) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-bottombar__summary__element-wrapper'),
						$elm$html$Html$Events$onClick(
						$author$project$Guarantees$Model$OnToggle(
							$author$project$Guarantees$Model$CartSummaryPackage(slug)))
					]),
				A2($author$project$Guarantees$View$BottomBar$cartSummaryPackageView, viewConfig, cartSummaryElement));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-bottombar__summary__element-wrapper')
					]),
				A2($author$project$Guarantees$View$BottomBar$cartSummaryPackageView, viewConfig, cartSummaryElement));
		}
	});
var $author$project$Types$Quote$CartSummaryElement = F5(
	function (slug, label, price, selectedGuaranteesLabels, isExpanded) {
		return {n2: isExpanded, n6: label, oY: price, pD: selectedGuaranteesLabels, bH: slug};
	});
var $author$project$Guarantees$View$BottomBar$getSelectedGuaranteesLabels = F2(
	function (userSelection, _package) {
		return A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Guarantees$Helpers$pickGuaranteeLabel),
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var clusterSlug = _v1.a;
					var guarantee = _v1.b;
					return A4(
						$author$project$Guarantees$Model$UserSelection$isGuaranteeInPackageSelected,
						clusterSlug,
						$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
						_package.bH,
						userSelection);
				},
				A2(
					$elm$core$List$sortBy,
					A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$Guarantees$Helpers$pickGuaranteeWeight),
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Tuple$second,
							$author$project$Guarantees$Helpers$matchPaymentType(
								$author$project$Guarantees$Model$UserSelection$pickPaymentFrequency(userSelection))),
						A2(
							$elm$core$List$concatMap,
							function (_v0) {
								var clusterSlug = _v0.a;
								var guarantees = _v0.b;
								return A2(
									$elm$core$List$map,
									function (guarantee) {
										return A2($elm$core$Tuple$pair, clusterSlug, guarantee);
									},
									guarantees);
							},
							A2(
								$elm$core$List$map,
								A2(
									$elm$core$Tuple$mapBoth,
									function ($) {
										return $.bH;
									},
									function ($) {
										return $.nF;
									}),
								A2(
									$elm$core$List$map,
									function (cluster) {
										return A2($elm$core$Tuple$pair, cluster, cluster);
									},
									A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.l0;
										},
										_package.mG))))))));
	});
var $author$project$Guarantees$View$BottomBar$createCartSummaryElementV1 = F3(
	function (model, userSelection, _package) {
		return A5(
			$author$project$Types$Quote$CartSummaryElement,
			_package.bH,
			_package.n6,
			$author$project$Utils$pricify(
				A3($author$project$Guarantees$Helpers$calculateSelectedPackagePrice, _package, userSelection, 0)),
			A2($author$project$Guarantees$View$BottomBar$getSelectedGuaranteesLabels, userSelection, _package),
			A2($elm$core$Set$member, _package.bH, model.im.ip));
	});
var $author$project$Guarantees$View$BottomBar$createCartSummaryElementV2 = function (_v0) {
	var label = _v0.n6;
	var price = _v0.oY;
	return A5(
		$author$project$Types$Quote$CartSummaryElement,
		'',
		label,
		$author$project$Utils$pricify(price),
		_List_Nil,
		false);
};
var $author$project$Guarantees$View$Helpers$createClusterLabelPrice = function (cluster) {
	return {
		jk: A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 1, 0),
		n6: function ($) {
			return $.n6;
		}(cluster),
		oY: A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0)
	};
};
var $author$project$Guarantees$View$Helpers$createGuaranteeLabelPrice = function (guaranteeFrequency) {
	return {
		jk: A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guaranteeFrequency, 1, 0),
		n6: A2(
			$elm$core$Basics$composeR,
			$author$project$Types$Quote$pickGuarantee,
			function ($) {
				return $.n6;
			})(guaranteeFrequency),
		oY: A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guaranteeFrequency, 0, 0)
	};
};
var $author$project$Guarantees$View$Helpers$getSelectedGuaranteesItemsTuplesV2 = function (_v0) {
	var guarantees = _v0.nF;
	return A2(
		$elm$core$List$map,
		$author$project$Guarantees$View$Helpers$createGuaranteeLabelPrice,
		A2(
			$elm$core$List$sortBy,
			$author$project$Guarantees$Helpers$pickGuaranteeWeight,
			A2(
				$elm$core$List$filter,
				$author$project$Guarantees$Helpers$isGuaranteeOptional,
				A2($elm$core$List$filter, $author$project$Guarantees$Helpers$pickGuaranteeSelected, guarantees))));
};
var $author$project$Guarantees$View$Helpers$getClusterSelectedItemsV2 = function (cluster) {
	return A2(
		$elm$core$List$cons,
		$author$project$Guarantees$View$Helpers$createClusterLabelPrice(cluster),
		$author$project$Guarantees$View$Helpers$getSelectedGuaranteesItemsTuplesV2(cluster));
};
var $author$project$Types$Quote$isSectionDisplayed = F2(
	function (sectionFilter, sectionSlug) {
		if (!sectionFilter.$) {
			var selectedSectionSlug = sectionFilter.a;
			return _Utils_eq(selectedSectionSlug, sectionSlug);
		} else {
			return true;
		}
	});
var $author$project$Guarantees$View$Helpers$getDisplayedPackagesV2 = F2(
	function (_v0, sectionFilter) {
		var sections = _v0.pB;
		return A2(
			$elm$core$List$concatMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$concatMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.oM;
						},
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$sortBy(
								function ($) {
									return $.l0;
								}),
							$elm$core$List$map(
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.oN;
									},
									$elm$core$List$sortBy(
										function ($) {
											return $.l0;
										}))))),
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.l0;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								$author$project$Types$Quote$isSectionDisplayed(sectionFilter)),
							sections)))));
	});
var $author$project$Guarantees$View$Helpers$getPackageSelectedClustersV2 = F2(
	function (price, _v0) {
		var clusters = _v0.mG;
		return A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.l0;
			},
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$Helpers$isClusterAlwaysIncluded(price),
					$elm$core$Basics$not),
				A2(
					$elm$core$List$filter,
					function ($) {
						return $.pC;
					},
					clusters)));
	});
var $author$project$Guarantees$View$Helpers$getSelectedCardsV2 = function (price) {
	return $elm$core$List$concat(
		$elm$core$List$concat(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Guarantees$View$Helpers$getPackageSelectedClustersV2(price),
					$elm$core$List$map($author$project$Guarantees$View$Helpers$getClusterSelectedItemsV2)),
				A2($author$project$Guarantees$View$Helpers$getDisplayedPackagesV2, price, $author$project$Types$Quote$NoSectionFilter))));
};
var $author$project$Guarantees$View$BottomBar$getCartSummaryElements = F2(
	function (model, _v0) {
		var quote = _v0.pd;
		var price = _v0.oY;
		var userSelection = _v0.lQ;
		var _v1 = quote.lU;
		if (!_v1) {
			return A2(
				$elm$core$List$map,
				A2($author$project$Guarantees$View$BottomBar$createCartSummaryElementV1, model, userSelection),
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.l0;
					},
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bH;
							},
							A2(
								$elm$core$Basics$composeR,
								A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Model$UserSelection$isPackageAlwaysIncluded, userSelection),
								$elm$core$Basics$not)),
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								A2($author$project$PrimaElm$Lib$Utils$FP$flip, $author$project$Guarantees$Model$UserSelection$isPackageSelected, userSelection)),
							A2(
								$elm$core$List$concatMap,
								function ($) {
									return $.oN;
								},
								A2(
									$elm$core$List$concatMap,
									function ($) {
										return $.oM;
									},
									price.pB))))));
		} else {
			return A2(
				$elm$core$List$map,
				$author$project$Guarantees$View$BottomBar$createCartSummaryElementV2,
				$author$project$Guarantees$View$Helpers$getSelectedCardsV2(price));
		}
	});
var $author$project$Guarantees$View$BottomBar$withNoDataFallbackText = function (cartSummaryPackagesViews) {
	return ($elm$core$List$length(cartSummaryPackagesViews) > 0) ? cartSummaryPackagesViews : _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-bottombar__summary__no-data')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('guarantees-bottombar__summary__no-data-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Non hai ancora aggiunto nessuna copertura')
						]))
				]))
		]);
};
var $author$project$Guarantees$View$BottomBar$cartSummaryView = function (model) {
	var viewConfig = model.h$;
	var bottomBarModel = model.im;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('guarantees-bottombar__summary-wrapper', true),
						_Utils_Tuple2('guarantees-bottombar__summary-wrapper--collapsed', !bottomBarModel.jH)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('guarantees-bottombar__summary', true),
								_Utils_Tuple2('guarantees-bottombar__summary--transparent', !bottomBarModel.jH)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__header')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-bottombar__summary__header-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Il tuo preventivo')
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-bottombar__summary__header-icon'),
										$elm$html$Html$Events$onClick(
										$author$project$Guarantees$Model$OnToggle(
											$author$project$Guarantees$Model$BottomBarCartSummary(false)))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon icon-delete')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('guarantees-bottombar__summary__content-area-outer')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('guarantees-bottombar__summary__content-area-inner')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('guarantees-bottombar__summary__content')
											]),
										A2(
											$elm$core$Maybe$withDefault,
											_List_fromArray(
												[
													$elm$html$Html$text('')
												]),
											A2(
												$elm$core$Maybe$map,
												$author$project$Guarantees$View$BottomBar$withNoDataFallbackText,
												A3(
													$elm$core$Maybe$map2,
													A2($elm$core$Basics$composeR, $author$project$Guarantees$View$BottomBar$cartSummaryPackageViewByVersion, $elm$core$List$map),
													viewConfig,
													A2(
														$elm$core$Maybe$map,
														$author$project$Guarantees$View$BottomBar$getCartSummaryElements(model),
														viewConfig)))))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('guarantees-bottombar__bottom-tag', true),
								_Utils_Tuple2('guarantees-bottombar__bottom-tag--transparent', !bottomBarModel.jH)
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Guarantees$Model$UserSelection$countChosenPackages = function (_v0) {
	var packages = _v0.oN;
	return $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (_package) {
				return function ($) {
					return $.pC;
				}(_package) && A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.nR;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$member(1),
						$elm$core$Basics$not))(_package);
			},
			packages));
};
var $author$project$Guarantees$View$BottomBar$countSelectedItems = function (viewConfig) {
	if (!viewConfig.$) {
		var quote = viewConfig.a.pd;
		var price = viewConfig.a.oY;
		var userSelection = viewConfig.a.lQ;
		var _v1 = quote.lU;
		if (!_v1) {
			return $author$project$Guarantees$Model$UserSelection$countChosenPackages(userSelection);
		} else {
			return $elm$core$List$length(
				$author$project$Guarantees$View$Helpers$getSelectedCardsV2(price));
		}
	} else {
		return 0;
	}
};
var $author$project$Types$Quote$Selection$validateClusterRules = F2(
	function (rule, clusters) {
		var selectedClusters = A2(
			$elm$core$List$map,
			function ($) {
				return $.bH;
			},
			clusters);
		var _v0 = rule.le;
		if (!_v0) {
			return A2(
				$elm$core$List$any,
				function (cluster) {
					return A2($elm$core$List$member, cluster, selectedClusters);
				},
				rule.mG);
		} else {
			return false;
		}
	});
var $author$project$Types$Quote$Selection$validateClustersRules = F2(
	function (rules, clusters) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (rule, accumulator) {
					return A2($author$project$Types$Quote$Selection$validateClusterRules, rule, clusters) || accumulator;
				}),
			false,
			rules);
	});
var $author$project$Types$Quote$Selection$isAnyOfClusterSelected = function (_v0) {
	var sections = _v0.pB;
	var inclusionClustersRules = _v0.nP;
	return A2(
		$author$project$Types$Quote$Selection$validateClustersRules,
		inclusionClustersRules,
		A2(
			$elm$core$List$filter,
			function ($) {
				return $.pC;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.mG;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oN;
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.oM;
						},
						sections)))));
};
var $author$project$Guarantees$Model$UserSelection$isAnyOfSelected = function (_v0) {
	var packages = _v0.oN;
	return A2(
		$elm$core$List$any,
		function (_v1) {
			var inclusionRules = _v1.nR;
			var selected = _v1.pC;
			return A2($elm$core$List$member, 0, inclusionRules) && selected;
		},
		packages);
};
var $author$project$Guarantees$View$Helpers$checkProceedActionByVersion = function (_v0) {
	var price = _v0.oY;
	var quote = _v0.pd;
	var userSelection = _v0.lQ;
	var _v1 = quote.lU;
	if (!_v1) {
		return $author$project$Guarantees$Model$UserSelection$isAnyOfSelected(userSelection);
	} else {
		return $author$project$Types$Quote$Selection$isAnyOfClusterSelected(price);
	}
};
var $author$project$Guarantees$View$Helpers$isProceedActionAllowedByVersion = function (viewConfig) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2($elm$core$Maybe$map, $author$project$Guarantees$View$Helpers$checkProceedActionByVersion, viewConfig));
};
var $author$project$Guarantees$View$Helpers$getIsProceedActionAllowed = F2(
	function (viewConfig, _v0) {
		var isSubmitting = _v0.jV;
		return $author$project$Guarantees$View$Helpers$isProceedActionAllowedByVersion(viewConfig) && (!isSubmitting);
	});
var $author$project$Guarantees$Model$NoOp = {$: 23};
var $author$project$Guarantees$Model$SubmitSave = {$: 3};
var $primait$pyxis_components$Prima$Pyxis$Button$withLoading = F2(
	function (checkLoading, _v0) {
		var buttonConfig = _v0;
		return _Utils_update(
			buttonConfig,
			{fL: checkLoading});
	});
var $author$project$Guarantees$View$Helpers$proceedBtnView = F4(
	function (btnWrapperClass, dataIdPrefix, isProceedActionAllowed, isSubmitting) {
		var btnText = isSubmitting ? '' : 'Procedi';
		var btnMsg = isProceedActionAllowed ? $author$project$Guarantees$Model$SubmitSave : $author$project$Guarantees$Model$NoOp;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(btnWrapperClass)
				]),
			_List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Button$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						btnMsg,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withLoading,
							isSubmitting,
							A2(
								$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
								(!isProceedActionAllowed) && (!isSubmitting),
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withAttribute,
									A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-' + (dataIdPrefix + '-guarantees-proceed-btn')),
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'guarantees-price-proceed-btn',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut(btnText)))))))
				]));
	});
var $author$project$Guarantees$View$V1$TopBar$mapPriceViewConfig = function (_v0) {
	var userSelection = _v0.lQ;
	var price = _v0.oY;
	var quote = _v0.pd;
	var packages = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.oN;
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.oM;
			},
			price.pB));
	var selectionFullPrice = function () {
		var _v2 = quote.lU;
		if (!_v2) {
			return A2($author$project$Guarantees$Helpers$calculatePackagesFullPrice, packages, userSelection);
		} else {
			return A3($author$project$Types$Quote$Selection$calculateTotalPrice, 1, 0, price);
		}
	}();
	var selectionPrice = function () {
		var _v1 = quote.lU;
		if (!_v1) {
			return A2($author$project$Guarantees$Helpers$calculatePackagesPrice, packages, userSelection);
		} else {
			return A3($author$project$Types$Quote$Selection$calculateTotalPrice, 0, 0, price);
		}
	}();
	var discountMessage = A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.mQ;
			},
			function ($) {
				return $.mi;
			}),
		A2($author$project$Guarantees$Helpers$pickDiscountMessageByType, 0, price));
	return {dl: discountMessage, ht: selectionFullPrice, em: selectionPrice, lQ: userSelection};
};
var $prikhi$decimal$Decimal$eq = F2(
	function (a, b) {
		var _v0 = A2($prikhi$decimal$Decimal$compare, a, b);
		if (_v0 === 1) {
			return true;
		} else {
			return false;
		}
	});
var $author$project$Guarantees$View$V1$TopBar$messageDiscountView = function (_v0) {
	var discountMessage = _v0.dl;
	var selectionPrice = _v0.em;
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		$elm_community$maybe_extra$Maybe$Extra$isJust(discountMessage) && A2($prikhi$decimal$Decimal$eq, selectionPrice, $prikhi$decimal$Decimal$zero),
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-topbar-price-message mq-xxsmall-hidden')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-small')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Acquista la tua polizza')
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fs-small fw-heavy')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('con lo sconto del 10%')
						]))
				])));
};
var $author$project$Guarantees$View$V1$TopBar$maybeMessageDiscountView = function (maybeViewData) {
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A2($elm$core$Maybe$map, $author$project$Guarantees$View$V1$TopBar$messageDiscountView, maybeViewData));
};
var $author$project$Guarantees$View$Helpers$discountView = function (message) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('badge badge--primary guarantees-topbar-price-discount-badge')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(message)
					]))
			]));
};
var $prikhi$decimal$Decimal$neq = F2(
	function (a, b) {
		return !A2($prikhi$decimal$Decimal$eq, a, b);
	});
var $author$project$Guarantees$View$Helpers$selectionPriceBoxView = F5(
	function (userSelection, discountMessage, titleMessage, selectionPrice, selectionFullPrice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-topbar-price-content')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					A2($prikhi$decimal$Decimal$lt, selectionPrice, selectionFullPrice),
					A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fs-medium text-decoration-line-through c-text-base guarantees-topbar-price-discount-value')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Utils$pricify(selectionFullPrice))
							]))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('guarantees-price-value-frequency-wrapper', true),
									_Utils_Tuple2(
									'guarantees-topbar-price-content-nodiscount',
									A2($prikhi$decimal$Decimal$eq, selectionPrice, selectionFullPrice) && A2($prikhi$decimal$Decimal$neq, selectionPrice, $prikhi$decimal$Decimal$zero))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('guarantees-price-value')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$PrimaElm$Lib$Utils$Render$renderIf,
									A2($prikhi$decimal$Decimal$eq, selectionPrice, selectionFullPrice) && A2($prikhi$decimal$Decimal$neq, selectionPrice, $prikhi$decimal$Decimal$zero),
									A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('guarantees-price-label')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(titleMessage)
											]))),
									$elm$html$Html$text(
									$author$project$Utils$pricify(selectionPrice))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('guarantees-price-frequency')
								]),
							_List_fromArray(
								[
									$author$project$Guarantees$View$Helpers$paymentFrequencyTextView(userSelection)
								]))
						])),
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$renderIf,
					A2($prikhi$decimal$Decimal$lt, selectionPrice, selectionFullPrice),
					$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Guarantees$View$Helpers$discountView, discountMessage)))
				]));
	});
var $author$project$Guarantees$View$V1$TopBar$packagesPriceView = function (_v0) {
	var discountMessage = _v0.dl;
	var selectionPrice = _v0.em;
	var selectionFullPrice = _v0.ht;
	var userSelection = _v0.lQ;
	return A5($author$project$Guarantees$View$Helpers$selectionPriceBoxView, userSelection, discountMessage, 'Preventivo Casa', selectionPrice, selectionFullPrice);
};
var $author$project$Guarantees$View$V1$TopBar$priceView = function (maybeViewData) {
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A2($elm$core$Maybe$map, $author$project$Guarantees$View$V1$TopBar$packagesPriceView, maybeViewData));
};
var $author$project$Guarantees$View$V1$TopBar$totalPriceView = function (_v0) {
	var viewConfig = _v0.h$;
	var priceViewConfig = A2($elm$core$Maybe$map, $author$project$Guarantees$View$V1$TopBar$mapPriceViewConfig, viewConfig);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('guarantees-topbar-price-container')
			]),
		_List_fromArray(
			[
				$author$project$Guarantees$View$V1$TopBar$maybeMessageDiscountView(priceViewConfig),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('guarantees-topbar-price fs-small fw-base')
					]),
				_List_fromArray(
					[
						$author$project$Guarantees$View$V1$TopBar$priceView(priceViewConfig)
					]))
			]));
};
var $author$project$Guarantees$View$BottomBar$view = F2(
	function (isOpen, model) {
		var viewConfig = model.h$;
		var bottomBarModel = model.im;
		var ui = model.bM;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-bottombar-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('guarantees-bottombar'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('guarantees-bottombar--collapsed', !isOpen)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('position-relative display-flex full-width')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('guarantees-bottombar__title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Il tuo preventivo')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('guarantees-bottombar__s-shaped-curve-wrapper')
										]),
									_List_fromArray(
										[
											A3(
											$author$project$View$Svg$svg,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('guarantees-bottombar__s-shaped-curve')
												]),
											'garanzie/',
											'curva_ad_s')
										])),
									$author$project$Guarantees$View$BottomBar$cartSummaryView(model)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('guarantees-bottombar__content-wrapper')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('guarantees-bottombar__content')
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Guarantees$View$BottomBar$cartBtnView,
											bottomBarModel.jH,
											$author$project$Guarantees$View$BottomBar$countSelectedItems(viewConfig)),
											$author$project$Guarantees$View$V1$TopBar$totalPriceView(model),
											A4(
											$author$project$Guarantees$View$Helpers$proceedBtnView,
											'guarantees-bottombar__content__proceed',
											'bottombar',
											A2($author$project$Guarantees$View$Helpers$getIsProceedActionAllowed, viewConfig, ui),
											ui.jV)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('guarantees-bottombar__overlay', true),
									_Utils_Tuple2('guarantees-bottombar__overlay--collapsed', !bottomBarModel.jH)
								]))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('')
						]))
				]));
	});
var $author$project$Components$QuoteInfoModal$withEdit = F2(
	function (onEdit, config) {
		return _Utils_update(
			config,
			{
				d6: $elm$core$Maybe$Just(onEdit)
			});
	});
var $author$project$Guarantees$View$V1$MainContent$view = F4(
	function (model, userSelection, quote, price) {
		var detailsModel = model.fd;
		var indexVisiblePackage = model.jw;
		var options = model.ku;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$Guarantees$View$V1$Sections$quotePriceView, userSelection, price, indexVisiblePackage),
					A2($author$project$Guarantees$View$V1$Details$detailContainer, userSelection, detailsModel),
					$author$project$Components$QuoteInfoModal$render(
					A2(
						$author$project$Components$QuoteInfoModal$withEdit,
						A2(
							$author$project$Guarantees$Model$RedirectToEdit,
							$elm$core$Maybe$Just(quote.nM),
							$elm$core$Maybe$Nothing),
						A3(
							$author$project$Components$QuoteInfoModal$summary,
							$author$project$Guarantees$Model$OnToggle(
								$author$project$Guarantees$Model$Summary(false)),
							options.jW,
							quote))),
					A2($author$project$Guarantees$View$BottomBar$view, true, model)
				]));
	});
var $author$project$Guarantees$View$Helpers$getDefaultSaveCode = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.ft;
	},
	A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$andThen($author$project$Guarantees$Model$getSaveFromSaveResponse),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					function ($) {
						return $.lg;
					}),
				$elm$core$Maybe$withDefault('')))));
var $author$project$Components$QuoteInfoBox$guaranteesHeaderView = function (saveCode) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('quote-box__header-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Preventivo '),
						$elm$html$Html$text(saveCode)
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('quote-box__header-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Per il calcolo del preventivo utilizzeremo le informazioni fornite da te\n                e i dati assicurativi presenti nelle nostre banche dati.')
					]))
			]));
};
var $author$project$Components$QuoteInfoBox$withClass = F2(
	function (_class, config) {
		return _Utils_update(
			config,
			{
				c6: A2($elm$core$List$cons, _class, config.c6)
			});
	});
var $author$project$Components$QuoteInfoBox$withHeader = F2(
	function (header, config) {
		return _Utils_update(
			config,
			{
				dB: $elm$core$Maybe$Just(header)
			});
	});
var $author$project$Guarantees$View$V1$Sidebar$view = F2(
	function (model, quote) {
		return $primait$pyxis_components$Prima$Pyxis$Container$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Container$withContent,
				_List_fromArray(
					[
						$author$project$Components$QuoteInfoBox$render(
						A2(
							$author$project$Components$QuoteInfoBox$withSummaryToggle,
							0,
							A2(
								$author$project$Components$QuoteInfoBox$withClass,
								'quote-box-guarantees',
								A2(
									$author$project$Components$QuoteInfoBox$withHeader,
									$author$project$Components$QuoteInfoBox$guaranteesHeaderView(
										$author$project$Guarantees$View$Helpers$getDefaultSaveCode(model)),
									A2(
										$author$project$Components$QuoteInfoBox$withButtonLabel,
										'Riepilogo e modifica dati',
										A2(
											$author$project$Components$QuoteInfoBox$box,
											$author$project$Guarantees$Model$OnToggle(
												$author$project$Guarantees$Model$Summary(true)),
											quote))))))
					]),
				A2($primait$pyxis_components$Prima$Pyxis$Container$withClass, '', $primait$pyxis_components$Prima$Pyxis$Container$column)));
	});
var $author$project$Utils$mapAndRender = F2(
	function (render, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return render(a);
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$View$TopBarElements$saveCodeView = function (saveCode) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('c-text-base')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(saveCode)
			]));
};
var $author$project$View$TopBarElements$titleText = function (_v0) {
	var productType = _v0.kX;
	if (!productType.$) {
		return 'Preventivo casa e famiglia';
	} else {
		return 'Preventivo famiglia';
	}
};
var $author$project$View$TopBarElements$topBarTitle = A2($elm_community$maybe_extra$Maybe$Extra$unwrap, 'Preventivo casa e famiglia', $author$project$View$TopBarElements$titleText);
var $author$project$View$TopBarElements$topBarTitleView = F2(
	function (save, openSummary) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('guarantees-topbar-container')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$View$Helper$wrapInRowWithClass,
					'full-height align-items-center guarantees-topbar-title mq-xsmall-hidden',
					_List_fromArray(
						[
							A3(
							$author$project$View$Svg$svg,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('house-logo')
								]),
							'',
							'preventivo-casa'),
							$author$project$View$Helper$wrapInCol(
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-transform-uppercase')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$View$TopBarElements$topBarTitle(save))
										])),
									$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.lg;
											},
											$author$project$View$TopBarElements$saveCodeView),
										save))
								]))
						])),
					A2($author$project$Utils$mapAndRender, $author$project$View$MobileSummaryView$view, openSummary)
				]));
	});
var $author$project$Guarantees$View$V1$TopBar$view = function (model) {
	var viewConfig = model.h$;
	var ui = model.bM;
	return _List_fromArray(
		[
			A2(
			$author$project$View$TopBarElements$topBarTitleView,
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Just(
				$author$project$Guarantees$Model$OnToggle(
					$author$project$Guarantees$Model$Summary(true)))),
			$author$project$Guarantees$View$V1$TopBar$totalPriceView(model),
			A4(
			$author$project$Guarantees$View$Helpers$proceedBtnView,
			'guarantees-topbar-price-proceed',
			'topbar',
			A2($author$project$Guarantees$View$Helpers$getIsProceedActionAllowed, viewConfig, ui),
			ui.jV)
		]);
};
var $author$project$Guarantees$View$templateV1 = F2(
	function (model, _v0) {
		var userType = model.lR;
		var flags = model.nv;
		var userSelection = _v0.lQ;
		var quote = _v0.pd;
		var price = _v0.oY;
		return $author$project$View$Template$render(
			A2(
				$author$project$View$Template$withLogoUrl,
				A2($author$project$Types$redirectUrl, flags, userType),
				A2(
					$author$project$View$Template$withSideContent,
					A2($author$project$Guarantees$View$V1$Sidebar$view, model, quote),
					A2(
						$author$project$View$Template$withMainContent,
						A4($author$project$Guarantees$View$V1$MainContent$view, model, userSelection, quote, price),
						A4(
							$author$project$View$Template$withTopBarContent,
							false,
							$author$project$Guarantees$View$V1$TopBar$view(model),
							'template-top-bar bg-background-alt-light c-text-alt-light guarantees-topbar',
							A2($author$project$View$Template$withClasses, 'guarantees', $author$project$View$Template$template))))));
	});
var $author$project$Guarantees$Model$Cancel = {$: 1};
var $author$project$Guarantees$Model$CloseDependenciesDialog = function (a) {
	return {$: 20, a: a};
};
var $author$project$Guarantees$Model$Confirm = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Dialog$Config = $elm$core$Basics$identity;
var $author$project$Components$Dialog$dialog = F3(
	function (isOpen, closeMessage, content) {
		return {cX: _List_Nil, c6: _List_Nil, iw: closeMessage, mQ: content, e4: _List_Nil, dt: _List_Nil, dC: _List_Nil, fw: $elm$core$Maybe$Nothing, jP: isOpen};
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$ViewResult = $elm$core$Basics$identity;
var $author$project$Guarantees$Model$ClusterType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$View$V2$DependenciesDialog$createClusterCard = function (cluster) {
	return $author$project$Guarantees$Model$ClusterType(cluster);
};
var $author$project$Guarantees$Model$GuaranteeType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Guarantees$View$V2$DependenciesDialog$createGuaranteeCard = function (guaranteeFrequency) {
	return $author$project$Guarantees$Model$GuaranteeType(guaranteeFrequency);
};
var $author$project$Types$Quote$Selection$pickClusterByGuaranteeSlug = F2(
	function (guaranteeSlug, _v0) {
		var sections = _v0.pB;
		var guaranteeFilter = A2(
			$elm$core$Basics$composeR,
			$author$project$Types$Quote$pickGuarantee,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bH;
				},
				$elm$core$Basics$eq(guaranteeSlug)));
		var clusterFilter = A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.nF;
			},
			$elm$core$List$any(guaranteeFilter));
		return A2(
			$elm_community$list_extra$List$Extra$find,
			clusterFilter,
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.mG;
				},
				A2(
					$elm$core$List$concatMap,
					function ($) {
						return $.oN;
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.oM;
						},
						sections))));
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$createCardByGuaranteeType = F2(
	function (price, guaranteeFrequency) {
		return $author$project$Guarantees$Helpers$isGuaranteeIncluded(guaranteeFrequency) ? A2(
			$elm$core$Maybe$map,
			$author$project$Guarantees$View$V2$DependenciesDialog$createClusterCard,
			A3(
				$elm_community$basics_extra$Basics$Extra$flip,
				$author$project$Types$Quote$Selection$pickClusterByGuaranteeSlug,
				price,
				$author$project$Guarantees$Helpers$pickGuaranteeSlug(guaranteeFrequency))) : $elm$core$Maybe$Just(
			$author$project$Guarantees$View$V2$DependenciesDialog$createGuaranteeCard(guaranteeFrequency));
	});
var $author$project$Guarantees$View$Helpers$pickCardSlug = function (card) {
	if (!card.$) {
		var cluster = card.a;
		return cluster.bH;
	} else {
		var guarantee = card.a;
		return $author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee);
	}
};
var $author$project$Guarantees$View$V2$DependenciesDialog$dependenciesGuaranteeWeight = function (guaranteeFrequency) {
	return (!$author$project$Guarantees$Helpers$pickGuaranteeType(guaranteeFrequency)) ? (-1) : $author$project$Guarantees$Helpers$pickGuaranteeWeight(guaranteeFrequency);
};
var $author$project$Guarantees$View$V2$DependenciesDialog$sortedDependenciesGuarantees = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.pB;
	},
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$concatMap(
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.oM;
				},
				$elm$core$List$sortBy(
					function ($) {
						return $.l0;
					}))),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$concatMap(
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.oN;
					},
					$elm$core$List$sortBy(
						function ($) {
							return $.l0;
						}))),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$concatMap(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mG;
						},
						$elm$core$List$sortBy(
							function ($) {
								return $.l0;
							}))),
				$elm$core$List$concatMap(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.nF;
						},
						$elm$core$List$sortBy($author$project$Guarantees$View$V2$DependenciesDialog$dependenciesGuaranteeWeight)))))));
var $author$project$Guarantees$View$V2$DependenciesDialog$getGuaranteesIndicesDict = A2(
	$elm$core$Basics$composeR,
	$author$project$Guarantees$View$V2$DependenciesDialog$sortedDependenciesGuarantees,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map($author$project$Guarantees$Helpers$pickGuaranteeSlug),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$indexedMap(
				$elm_community$basics_extra$Basics$Extra$flip($elm$core$Tuple$pair)),
			$elm$core$Dict$fromList)));
var $author$project$Guarantees$View$V2$DependenciesDialog$sortedDependenciesList = function (price) {
	return $elm$core$List$sortBy(
		A2(
			$elm$core$Basics$composeR,
			$author$project$Guarantees$Helpers$pickGuaranteeSlug,
			A2(
				$elm$core$Basics$composeR,
				A2(
					$elm_community$basics_extra$Basics$Extra$flip,
					$elm$core$Dict$get,
					$author$project$Guarantees$View$V2$DependenciesDialog$getGuaranteesIndicesDict(price)),
				$elm$core$Maybe$withDefault(-1))));
};
var $author$project$Guarantees$View$V2$DependenciesDialog$getDependenciesCards = function (price) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Guarantees$View$V2$DependenciesDialog$sortedDependenciesList(price),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filterMap(
				$author$project$Guarantees$View$V2$DependenciesDialog$createCardByGuaranteeType(price)),
			$elm_community$list_extra$List$Extra$uniqueBy($author$project$Guarantees$View$Helpers$pickCardSlug)));
};
var $author$project$Guarantees$View$V2$DependenciesDialog$iconView = function (isAncestorType) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-dependencies-dialog__content-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-dependencies-dialog__icon-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-dependencies-dialog__icon icon icon-house-var')
							]),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-dependencies-dialog__icon-badge'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('v2-dependencies-dialog__icon-badge--add', isAncestorType),
										_Utils_Tuple2('v2-dependencies-dialog__icon-badge--remove', !isAncestorType)
									]))
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Guarantees$View$Helpers$isSingleGuaranteeCluster = function (_v0) {
	var guarantees = _v0.nF;
	return ($elm$core$List$length(guarantees) === 1) && A2($elm$core$List$all, $author$project$Guarantees$Helpers$isGuaranteeIncluded, guarantees);
};
var $author$project$Guarantees$View$Helpers$getClusterSingleGuarantee = function (cluster) {
	return $author$project$Guarantees$View$Helpers$isSingleGuaranteeCluster(cluster) ? $elm$core$List$head(cluster.nF) : $elm$core$Maybe$Nothing;
};
var $author$project$Guarantees$View$Helpers$getCardTitle = function (card) {
	if (!card.$) {
		var guaranteeCluster = card.a;
		var label = guaranteeCluster.n6;
		return A3(
			$author$project$Utils$ternaryOperator,
			$author$project$Guarantees$View$Helpers$isSingleGuaranteeCluster(guaranteeCluster),
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				label,
				$author$project$Guarantees$Helpers$pickGuaranteeLabel,
				$author$project$Guarantees$View$Helpers$getClusterSingleGuarantee(guaranteeCluster)),
			label);
	} else {
		var guaranteeFrequency = card.a;
		return $author$project$Guarantees$Helpers$pickGuaranteeLabel(guaranteeFrequency);
	}
};
var $author$project$Guarantees$View$Helpers$getCardPrice = F2(
	function (card, priceType) {
		if (!card.$) {
			var cluster = card.a;
			return A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, priceType, 0);
		} else {
			var guaranteeFrequency = card.a;
			return A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guaranteeFrequency, priceType, 0);
		}
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$singleCardPriceWithPrefixView = F2(
	function (price, prefix) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-price-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-price')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							_Utils_ap(
								prefix,
								$author$project$Utils$pricify(price)))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-price-frequency')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' /anno')
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$singleCardPriceView = function (card) {
	return A2(
		$author$project$Guarantees$View$V2$DependenciesDialog$singleCardPriceWithPrefixView,
		A2($author$project$Guarantees$View$Helpers$getCardPrice, card, 0),
		'');
};
var $author$project$Guarantees$View$V2$DependenciesDialog$listItemView = function (card) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-dependencies-dialog__list-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-icon-wrapper')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$View$Svg$svg,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-icon')
							]),
						'garanzie/v2/',
						$author$project$Guarantees$View$Helpers$pickCardSlug(card))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-data')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-title-wrapper')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('v2-dependencies-dialog__item-title')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Guarantees$View$Helpers$getCardTitle(card))
									]))
							])),
						$author$project$Guarantees$View$V2$DependenciesDialog$singleCardPriceView(card)
					]))
			]));
};
var $author$project$Guarantees$View$V2$DependenciesDialog$listView = function (cards) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-dependencies-dialog__content-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-dependencies-dialog__list')
					]),
				A2($elm$core$List$map, $author$project$Guarantees$View$V2$DependenciesDialog$listItemView, cards))
			]));
};
var $author$project$Guarantees$View$V2$DependenciesDialog$messageText = F2(
	function (cardLabel, isAncestorType) {
		return isAncestorType ? ('Per aggiungere ' + (cardLabel + ', dovrai aggiungere queste garanzie:')) : ('Rimuovendo ' + (cardLabel + ', verranno rimosse queste garanzie:'));
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$messageView = F2(
	function (cardLabel, isAncestorType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-dependencies-dialog__content-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-dependencies-dialog__message-wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-dependencies-dialog__message')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A2($author$project$Guarantees$View$V2$DependenciesDialog$messageText, cardLabel, isAncestorType))
								]))
						]))
				]));
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$dialogContentView = F4(
	function (cardLabel, dependenciesList, isAncestorType, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-dependencies-dialog__content')
				]),
			_List_fromArray(
				[
					$author$project$Guarantees$View$V2$DependenciesDialog$iconView(isAncestorType),
					A2($author$project$Guarantees$View$V2$DependenciesDialog$messageView, cardLabel, isAncestorType),
					$author$project$Guarantees$View$V2$DependenciesDialog$listView(
					A2($author$project$Guarantees$View$V2$DependenciesDialog$getDependenciesCards, price, dependenciesList))
				]));
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$dialogHeaderView = function (title) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-dependencies-dialog__header-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]))
		]);
};
var $author$project$Guarantees$View$V2$DependenciesDialog$addingTypeView = F4(
	function (onConfirmMsg, cardLabel, ancestorDependenciesList, price) {
		return {
			mQ: _List_fromArray(
				[
					A4($author$project$Guarantees$View$V2$DependenciesDialog$dialogContentView, cardLabel, ancestorDependenciesList, true, price)
				]),
			dB: $author$project$Guarantees$View$V2$DependenciesDialog$dialogHeaderView('Aggiungi garanzie'),
			d1: onConfirmMsg
		};
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$removingTypeView = F4(
	function (onConfirmMsg, cardLabel, descendantDependenciesList, price) {
		return {
			mQ: _List_fromArray(
				[
					A4($author$project$Guarantees$View$V2$DependenciesDialog$dialogContentView, cardLabel, descendantDependenciesList, false, price)
				]),
			dB: $author$project$Guarantees$View$V2$DependenciesDialog$dialogHeaderView('Rimuovi garanzie'),
			d1: onConfirmMsg
		};
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$getViewResult = F2(
	function (dialogType, price) {
		if (!dialogType.$) {
			var onConfirmMsg = dialogType.a;
			var cardLabel = dialogType.b;
			var ancestorDependenciesList = dialogType.c;
			return A4($author$project$Guarantees$View$V2$DependenciesDialog$addingTypeView, onConfirmMsg, cardLabel, ancestorDependenciesList, price);
		} else {
			var onConfirmMsg = dialogType.a;
			var cardLabel = dialogType.b;
			var descendantDependenciesList = dialogType.c;
			return A4($author$project$Guarantees$View$V2$DependenciesDialog$removingTypeView, onConfirmMsg, cardLabel, descendantDependenciesList, price);
		}
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$getViewResultContent = F2(
	function (dialogType, price) {
		var _v0 = A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResult, dialogType, price);
		var content = _v0.mQ;
		return content;
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$getViewResultHeader = F2(
	function (dialogType, price) {
		var _v0 = A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResult, dialogType, price);
		var header = _v0.dB;
		return header;
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$getViewResultMsg = F2(
	function (dialogType, price) {
		var _v0 = A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResult, dialogType, price);
		var msg = _v0.d1;
		return msg;
	});
var $author$project$Components$Dialog$actionButtonView = function (buttonConfig) {
	return $primait$pyxis_components$Prima$Pyxis$Button$render(buttonConfig);
};
var $author$project$Components$Dialog$renderActionButtons = $elm$core$List$map($author$project$Components$Dialog$actionButtonView);
var $primait$pyxis_components$Prima$Pyxis$Modal$small = F2(
	function (initialVisibility, closeEvent) {
		return {
			ad: closeEvent,
			cc: 0,
			ku: $primait$pyxis_components$Prima$Pyxis$Modal$initialModalOptions(closeEvent),
			aH: 0,
			lZ: initialVisibility
		};
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withFooterClass = F2(
	function (_class, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			$primait$pyxis_components$Prima$Pyxis$Modal$updateFooterOptions(
				function (footerOptions) {
					return _Utils_update(
						footerOptions,
						{
							bX: $elm$core$Maybe$Just(
								$elm$html$Html$Attributes$class(_class))
						});
				}),
			modalConfig);
	});
var $primait$pyxis_components$Prima$Pyxis$Modal$withHeaderClass = F2(
	function (_class, _v0) {
		var modalConfig = _v0;
		return A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$updateModalOptions,
			$primait$pyxis_components$Prima$Pyxis$Modal$updateHeaderOptions(
				function (headerOptions) {
					return _Utils_update(
						headerOptions,
						{
							bX: $elm$core$Maybe$Just(
								$elm$html$Html$Attributes$class(_class))
						});
				}),
			modalConfig);
	});
var $author$project$Components$Dialog$render = function (_v0) {
	var content = _v0.mQ;
	var actionButtons = _v0.cX;
	var classes = _v0.c6;
	var closeMessage = _v0.iw;
	var isOpen = _v0.jP;
	var headerContent = _v0.fw;
	var headerClasses = _v0.dC;
	var footerClasses = _v0.dt;
	return $primait$pyxis_components$Prima$Pyxis$Modal$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$withFooterContent,
			$author$project$Components$Dialog$renderActionButtons(actionButtons),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Modal$withFooterClass,
				A2($elm$core$String$join, ' ', footerClasses),
				A2(
					$primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent,
					content,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Modal$withClass,
						A2($elm$core$String$join, ' ', classes),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderClass,
							A2($elm$core$String$join, ' ', headerClasses),
							A3(
								$author$project$Utils$maybeApply,
								$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderContent,
								headerContent,
								A2($primait$pyxis_components$Prima$Pyxis$Modal$small, isOpen, closeMessage))))))));
};
var $primait$pyxis_components$Prima$Pyxis$Button$secondary = function (label) {
	return A6($primait$pyxis_components$Prima$Pyxis$Button$ButtonConfig, 3, false, 0, label, $elm$core$Maybe$Nothing, _List_Nil);
};
var $author$project$Utils$listAppendElement = F2(
	function (element, elementList) {
		return A2(
			$elm$core$List$append,
			elementList,
			$elm$core$List$singleton(element));
	});
var $author$project$Components$Dialog$withActionButton = F2(
	function (actionButton, _v0) {
		var config = _v0;
		var actionButtons = config.cX;
		return _Utils_update(
			config,
			{
				cX: A2($author$project$Utils$listAppendElement, actionButton, actionButtons)
			});
	});
var $author$project$Components$Dialog$withClass = F2(
	function (_class, _v0) {
		var config = _v0;
		var classes = config.c6;
		return _Utils_update(
			config,
			{
				c6: A2($author$project$Utils$listAppendElement, _class, classes)
			});
	});
var $author$project$Components$Dialog$withFooterClass = F2(
	function (_class, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				dt: A2($author$project$Utils$listAppendElement, _class, config.dt)
			});
	});
var $author$project$Components$Dialog$withHeaderClass = F2(
	function (_class, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				dC: A2($author$project$Utils$listAppendElement, _class, config.dC)
			});
	});
var $author$project$Components$Dialog$withHeaderContent = F2(
	function (content, _v0) {
		var config = _v0;
		return _Utils_update(
			config,
			{
				fw: $elm$core$Maybe$Just(content)
			});
	});
var $author$project$Guarantees$View$V2$DependenciesDialog$render = F2(
	function (price, dialogType) {
		return $author$project$Components$Dialog$render(
			A2(
				$author$project$Components$Dialog$withFooterClass,
				'v2-dependencies-dialog__footer',
				A2(
					$author$project$Components$Dialog$withActionButton,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						$author$project$Guarantees$Model$CloseDependenciesDialog(
							$author$project$Guarantees$Model$Confirm(
								A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResultMsg, dialogType, price))),
						$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
							A2(
								$primait$pyxis_components$Prima$Pyxis$Button$withAttribute,
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-dependencies-dialog-confirm-btn'),
								$primait$pyxis_components$Prima$Pyxis$Button$primary('Conferma')))),
					A2(
						$author$project$Components$Dialog$withActionButton,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
							$author$project$Guarantees$Model$CloseDependenciesDialog($author$project$Guarantees$Model$Cancel),
							$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withAttribute,
									A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-dependencies-dialog-cancel-btn'),
									$primait$pyxis_components$Prima$Pyxis$Button$secondary('Annulla')))),
						A2(
							$author$project$Components$Dialog$withHeaderClass,
							'v2-dependencies-dialog__header',
							A2(
								$author$project$Components$Dialog$withHeaderContent,
								A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResultHeader, dialogType, price),
								A2(
									$author$project$Components$Dialog$withClass,
									'dialog v2-dependencies-dialog',
									A3(
										$author$project$Components$Dialog$dialog,
										true,
										$author$project$Guarantees$Model$CloseDependenciesDialog($author$project$Guarantees$Model$Cancel),
										A2($author$project$Guarantees$View$V2$DependenciesDialog$getViewResultContent, dialogType, price)))))))));
	});
var $author$project$Guarantees$View$V2$MainContent$dialogView = F2(
	function (price, dialog) {
		if (!dialog.$) {
			var dialogType = dialog.a;
			return A2($author$project$Guarantees$View$V2$DependenciesDialog$render, price, dialogType);
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Guarantees$Model$generateNavigableModalExternalModel = function (model) {
	return {
		ax: model.bM.ax,
		aS: model.bM.aS,
		k5: A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.oY;
			},
			model.h$)
	};
};
var $author$project$Guarantees$View$V2$MainContent$getGuaranteesPartitions = function (cluster) {
	var guarantees = cluster.nF;
	return A3(
		$elm$core$List$foldl,
		F2(
			function (guarantee, _v0) {
				var clusterCard = _v0.a;
				var guaranteeCards = _v0.b;
				return _Utils_Tuple2(
					clusterCard,
					_Utils_ap(
						guaranteeCards,
						$elm$core$List$singleton(
							$author$project$Guarantees$Model$GuaranteeType(guarantee))));
			}),
		_Utils_Tuple2(
			$author$project$Guarantees$Model$ClusterType(cluster),
			_List_Nil),
		A2(
			$elm$core$List$filter,
			$author$project$Guarantees$Helpers$isGuaranteeOptional,
			A2($elm$core$List$sortBy, $author$project$Guarantees$Helpers$pickGuaranteeWeight, guarantees)));
};
var $author$project$Guarantees$View$V2$MainContent$getClustersCards = function (_v0) {
	var clusters = _v0.mG;
	return A2(
		$elm$core$List$map,
		$author$project$Guarantees$View$V2$MainContent$getGuaranteesPartitions,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.l0;
			},
			clusters));
};
var $author$project$Guarantees$View$V2$MainContent$optionalGuaranteesTextRowView = function (text) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-optional-guarantees')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-optional-guarantees-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(text)
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$additionalGuaranteesTextConditionalView = function (optionalGuaranteesList) {
	return A3(
		$author$project$Utils$ternaryOperator,
		$elm$core$List$length(optionalGuaranteesList) > 0,
		$author$project$Guarantees$View$V2$MainContent$optionalGuaranteesTextRowView('Garanzie aggiuntive:'),
		$elm$html$Html$text(''));
};
var $author$project$Guarantees$View$V2$MainContent$areClusterCardsVisible = function (price) {
	var clustersSlugs = A2(
		$elm$core$List$map,
		function ($) {
			return $.bH;
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.mG;
			},
			A2($author$project$Guarantees$View$Helpers$getDisplayedPackagesV2, price, $author$project$Types$Quote$NoSectionFilter)));
	return $elm$core$List$all(
		A2($elm_community$basics_extra$Basics$Extra$flip, $elm$core$List$member, clustersSlugs));
};
var $author$project$Guarantees$View$V2$MainContent$bundleDiscountMessageContent = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('v2-bundle-discount-message__text-wrapper')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-bundle-discount-message__text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Selezionando \"Protezione locali\" hai accesso a sconti esclusivi nelle garanzie Famiglia')
				]))
		]));
var $author$project$Guarantees$Model$DismissHighlightedMessage = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$Components$HighlightedMessage$init = F3(
	function (isVisible, content, onClickMsg) {
		return {cZ: _List_Nil, mQ: content, fN: isVisible, ge: onClickMsg};
	});
var $author$project$Components$HighlightedMessage$render = function (_v0) {
	var onClickMsg = _v0.ge;
	var isVisible = _v0.fN;
	var content = _v0.mQ;
	var additionalClasses = _v0.cZ;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$cons, 'highlighted-message badge badge--primary', additionalClasses))),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('hidden', !isVisible)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('highlighted-message__icon-ok icon icon-ok')
					]),
				_List_Nil),
				content,
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('highlighted-message__icon-close icon icon-close'),
						$elm$html$Html$Events$onClick(onClickMsg)
					]),
				_List_Nil)
			]));
};
var $author$project$Components$HighlightedMessage$withAdditionalClasses = F2(
	function (additionalClasses, highlightedMessage) {
		return _Utils_update(
			highlightedMessage,
			{cZ: additionalClasses});
	});
var $author$project$Guarantees$View$V2$MainContent$highlightedMessageView = F3(
	function (isVisible, clusterSlug, content) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				A2(
					$elm$core$Basics$composeR,
					$danyx23$elm_uuid$Uuid$toString,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$Model$DismissHighlightedMessage(clusterSlug),
						A2(
							$elm$core$Basics$composeR,
							A2($author$project$Components$HighlightedMessage$init, isVisible, content),
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$HighlightedMessage$withAdditionalClasses(
									_List_fromArray(
										['v2-bundle-discount-message'])),
								$author$project$Components$HighlightedMessage$render))))),
			$elm$core$Maybe$withDefault(
				$elm$html$Html$text('')));
	});
var $author$project$Guarantees$View$V2$MainContent$bundleDiscountMessageConditionalView = F3(
	function (_v0, price, cluster) {
		var ui = _v0.bM;
		var quoteId = _v0.cy;
		return A3(
			$author$project$Utils$ternaryOperator,
			$author$project$Guarantees$View$Helpers$pickCardSlug(cluster) === 'protezione_locali',
			A4(
				$author$project$Guarantees$View$V2$MainContent$highlightedMessageView,
				ui.fH && A2(
					$author$project$Guarantees$View$V2$MainContent$areClusterCardsVisible,
					price,
					_List_fromArray(
						['protezione_locali', 'rc_famiglia'])),
				$author$project$Guarantees$View$Helpers$pickCardSlug(cluster),
				$author$project$Guarantees$View$V2$MainContent$bundleDiscountMessageContent,
				quoteId),
			$elm$html$Html$text(''));
	});
var $author$project$Guarantees$View$V2$MainContent$cardClusterTypeDataTestId = F2(
	function (suffix, _v0) {
		var slug = _v0.bH;
		return 'id-' + (slug + ('-' + suffix));
	});
var $author$project$Guarantees$View$V2$MainContent$cardGuaranteeTypeDataTestId = F2(
	function (suffix, guarantee) {
		return 'id-' + ($author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee) + ('-' + suffix));
	});
var $author$project$Guarantees$View$V2$MainContent$cardTypeDataTestId = F2(
	function (suffix, cardType) {
		if (cardType.$ === 1) {
			var guarantee = cardType.a;
			return A2($author$project$Guarantees$View$V2$MainContent$cardGuaranteeTypeDataTestId, suffix, guarantee);
		} else {
			var cluster = cardType.a;
			return A2($author$project$Guarantees$View$V2$MainContent$cardClusterTypeDataTestId, suffix, cluster);
		}
	});
var $author$project$Guarantees$View$V2$MainContent$pickCardIsSelected = function (card) {
	if (!card.$) {
		var selected = card.a.pC;
		return selected;
	} else {
		var guarantee = card.a;
		return $author$project$Guarantees$Helpers$pickGuaranteeSelected(guarantee);
	}
};
var $author$project$Guarantees$View$V2$MainContent$getActionBtnLabel = function (card) {
	return A3(
		$author$project$Utils$ternaryOperator,
		$author$project$Guarantees$View$V2$MainContent$pickCardIsSelected(card),
		'Rimuovi',
		'Aggiungi');
};
var $author$project$Guarantees$View$V2$MainContent$getNotSelectedActionBtnMsg = F2(
	function (price, card) {
		if (!card.$) {
			var cluster = card.a;
			return $author$project$Guarantees$View$Helpers$getNotSelectedClusterActionBtnMsg(cluster);
		} else {
			var guaranteeFrequency = card.a;
			return A2($author$project$Guarantees$View$Helpers$getNotSelectedGuaranteeActionBtnMsg, price, guaranteeFrequency);
		}
	});
var $author$project$Guarantees$View$V2$MainContent$getSelectedActionBtnMsg = F2(
	function (price, card) {
		if (!card.$) {
			var cluster = card.a;
			return A2($author$project$Guarantees$View$Helpers$getSelectedClusterActionBtnMsg, price, cluster);
		} else {
			var guaranteeFrequency = card.a;
			return A2($author$project$Guarantees$View$Helpers$getSelectedGuaranteeActionBtnMsg, price, guaranteeFrequency);
		}
	});
var $author$project$Guarantees$View$V2$MainContent$getActionBtnMsg = F2(
	function (price, card) {
		return $author$project$Guarantees$View$V2$MainContent$pickCardIsSelected(card) ? A2($author$project$Guarantees$View$V2$MainContent$getSelectedActionBtnMsg, price, card) : A2($author$project$Guarantees$View$V2$MainContent$getNotSelectedActionBtnMsg, price, card);
	});
var $author$project$Guarantees$View$V2$MainContent$actionBtnView = F3(
	function (card, price, isDisabled) {
		return $primait$pyxis_components$Prima$Pyxis$Button$render(
			$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					isDisabled,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withAttribute,
						A2(
							$elm$html$Html$Attributes$attribute,
							'data-test-id',
							A2($author$project$Guarantees$View$V2$MainContent$cardTypeDataTestId, 'add-remove-btn', card)),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withClass,
							'v2-card-action-btn',
							A2(
								$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
								A2($author$project$Guarantees$View$V2$MainContent$getActionBtnMsg, price, card),
								$primait$pyxis_components$Prima$Pyxis$Button$tertiary(
									$author$project$Guarantees$View$V2$MainContent$getActionBtnLabel(card))))))));
	});
var $author$project$Guarantees$View$V2$MainContent$isSingleType = function (card) {
	if (!card.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Guarantees$View$V2$MainContent$cardActionBtnView = F3(
	function (isSelectionDisabled, card, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-action'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'is-single-guarantee',
							$author$project$Guarantees$View$V2$MainContent$isSingleType(card))
						]))
				]),
			_List_fromArray(
				[
					A3($author$project$Guarantees$View$V2$MainContent$actionBtnView, card, price, isSelectionDisabled)
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$discountedPriceView = F2(
	function (price, fullPrice) {
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			A2($prikhi$decimal$Decimal$lt, price, fullPrice),
			A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price__value--discounted')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Utils$pricify(fullPrice))
					])));
	});
var $author$project$Guarantees$View$V2$MainContent$cardPriceWithPrefixView = F3(
	function (price, fullPrice, prefix) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-price')
				]),
			_List_fromArray(
				[
					A2($author$project$Guarantees$View$V2$MainContent$discountedPriceView, price, fullPrice),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-price__value')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							_Utils_ap(
								prefix,
								$author$project$Utils$pricify(price)))
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-price__frequency')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('/anno')
						]))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$cardClusterPriceView = function (cluster) {
	var price = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 1, 0);
	return A3($author$project$Guarantees$View$V2$MainContent$cardPriceWithPrefixView, price, fullPrice, '');
};
var $author$project$Guarantees$View$V2$MainContent$cardGuaranteePriceView = function (guarantee) {
	var price = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 1, 0);
	return A3($author$project$Guarantees$View$V2$MainContent$cardPriceWithPrefixView, price, fullPrice, '+ ');
};
var $author$project$Guarantees$View$V2$MainContent$cardPriceView = function (card) {
	if (!card.$) {
		var cluster = card.a;
		return $author$project$Guarantees$View$V2$MainContent$cardClusterPriceView(cluster);
	} else {
		var guarantee = card.a;
		return $author$project$Guarantees$View$V2$MainContent$cardGuaranteePriceView(guarantee);
	}
};
var $author$project$Guarantees$View$V2$MainContent$cardActionAreaView = F3(
	function (isSelectionDisabled, card, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-action-area')
				]),
			_List_fromArray(
				[
					$author$project$Guarantees$View$V2$MainContent$cardPriceView(card),
					A3($author$project$Guarantees$View$V2$MainContent$cardActionBtnView, isSelectionDisabled, card, price)
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$cardContentSeparatorView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('v2-card-content-separator-area')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-content-separator')
				]),
			_List_Nil)
		]));
var $author$project$Guarantees$View$V2$MainContent$pickCardPaymentFrequency = function (card) {
	if (!card.$) {
		return 0;
	} else {
		return 0;
	}
};
var $author$project$Guarantees$View$V2$MainContent$cardIncludedAreaView = function (card) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-card-action-area')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-included')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-card-price__value')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Incluse')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-card-price__frequency')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								'€0 ' + A3(
									$author$project$Utils$ternaryOperator,
									!$author$project$Guarantees$View$V2$MainContent$pickCardPaymentFrequency(card),
									'/anno',
									'/mese'))
							]))
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$clusterCardDependenciesView = F3(
	function (price, dependencies, _v0) {
		var guarantees = _v0.nF;
		if (guarantees.b && (!guarantees.b.b)) {
			var guarantee = guarantees.a;
			return A3($author$project$Guarantees$View$V2$Details$singleGuaranteeCardDependenciesView, price, dependencies, guarantee);
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Guarantees$View$V2$MainContent$cardDependencyView = F3(
	function (price, dependencies, card) {
		if (!card.$) {
			var cluster = card.a;
			return A3($author$project$Guarantees$View$V2$MainContent$clusterCardDependenciesView, price, dependencies, cluster);
		} else {
			var guarantee = card.a;
			return A3($author$project$Guarantees$View$V2$Details$singleGuaranteeCardDependenciesView, price, dependencies, guarantee);
		}
	});
var $author$project$Guarantees$Model$OpenClusterDetailModal = function (a) {
	return {$: 13, a: a};
};
var $author$project$Guarantees$View$V2$MainContent$cardLabelActionClusterView = function (cluster) {
	var guarantees = cluster.nF;
	var modalOpenClickEvent = function () {
		if (guarantees.b && (!guarantees.b.b)) {
			var guarantee = guarantees.a;
			return $author$project$Guarantees$Model$OpenGuaranteeDetailModal(guarantee);
		} else {
			return $author$project$Guarantees$Model$OpenClusterDetailModal;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-card-label__action'),
				A2(
				$elm$html$Html$Attributes$attribute,
				'data-test-id',
				A2($author$project$Guarantees$View$V2$MainContent$cardClusterTypeDataTestId, 'details-btn', cluster)),
				$elm$html$Html$Events$onClick(
				modalOpenClickEvent(cluster))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-label__action__text v2-card-label__action__text-mobile')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Scopri')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-label__action__text v2-card-label__action__text-desktop')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Scopri e modifica')
					])),
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-label__action__icon icon-caret-right')
					]),
				_List_Nil)
			]));
};
var $author$project$Guarantees$View$V2$MainContent$cardLabelActionGuaranteeViewWithCluster = F2(
	function (guarantee, cluster) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-label__action'),
					A2(
					$elm$html$Html$Attributes$attribute,
					'data-test-id',
					A2($author$project$Guarantees$View$V2$MainContent$cardGuaranteeTypeDataTestId, 'details-btn', guarantee)),
					$elm$html$Html$Events$onClick(
					A2($author$project$Guarantees$Model$OpenGuaranteeDetailModal, guarantee, cluster))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__action__text v2-card-label__action__text-mobile is-single-guarantee')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Scopri')
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__action__text v2-card-label__action__text-desktop is-single-guarantee')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Scopri e modifica')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__action__icon icon-caret-right')
						]),
					_List_Nil)
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$cardLabelActionGuaranteeView = F2(
	function (price, guarantee) {
		return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				$author$project$Guarantees$View$V2$MainContent$cardLabelActionGuaranteeViewWithCluster(guarantee),
				A2(
					$author$project$Guarantees$Helpers$pickClusterByGuaranteeSlug,
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee),
					price)));
	});
var $author$project$Guarantees$View$V2$MainContent$cardLabelActionView = F2(
	function (price, card) {
		if (!card.$) {
			var cluster = card.a;
			return $author$project$Guarantees$View$V2$MainContent$cardLabelActionClusterView(cluster);
		} else {
			var guarantee = card.a;
			return A2($author$project$Guarantees$View$V2$MainContent$cardLabelActionGuaranteeView, price, guarantee);
		}
	});
var $author$project$Guarantees$View$V2$MainContent$cardLabelMobilePriceIncludedView = function (paymentFrequency) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-card-price-mobile')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__value v2-card-price-mobile__value-included')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Incluse')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__frequency')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'€0 ' + A3($author$project$Utils$ternaryOperator, !paymentFrequency, '/anno', '/mese'))
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$discountedPriceMobileView = F2(
	function (price, fullPrice) {
		return A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			A2($prikhi$decimal$Decimal$lt, price, fullPrice),
			A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__value-discounted')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Utils$pricify(fullPrice))
					])));
	});
var $author$project$Guarantees$View$V2$MainContent$cardClusterLabelMobilePriceView = function (cluster) {
	var price = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateClusterPrice, cluster, 1, 0);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-card-price-mobile')
			]),
		_List_fromArray(
			[
				A2($author$project$Guarantees$View$V2$MainContent$discountedPriceMobileView, price, fullPrice),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__value')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Utils$pricify(price))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__frequency')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('/anno')
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$cardGuaranteeLabelMobilePriceView = function (guarantee) {
	var price = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 0, 0);
	var fullPrice = A3($author$project$Types$Quote$Selection$calculateGuaranteePrice, guarantee, 1, 0);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-card-price-mobile is-single-guarantee')
			]),
		_List_fromArray(
			[
				A2($author$project$Guarantees$View$V2$MainContent$discountedPriceMobileView, price, fullPrice),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__value')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'+ ' + $author$project$Utils$pricify(price))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-card-price-mobile__frequency is-single-guarantee')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('/anno')
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$cardLabelMobilePriceView = function (card) {
	if (!card.$) {
		var cluster = card.a;
		return $author$project$Guarantees$View$V2$MainContent$cardClusterLabelMobilePriceView(cluster);
	} else {
		var guarantee = card.a;
		return $author$project$Guarantees$View$V2$MainContent$cardGuaranteeLabelMobilePriceView(guarantee);
	}
};
var $author$project$Guarantees$View$V2$MainContent$checkBoxIconView = F4(
	function (isSelected, isAlwaysIncluded, isSelectionDisabled, onToggleMsg) {
		return (isAlwaysIncluded || isSelectionDisabled) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-label__checkbox v2-card-label__checkbox--disabled'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('v2-card-label__checkbox--selected', isSelected)
						]))
				]),
			_List_Nil) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-label__checkbox'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('v2-card-label__checkbox--selected', isSelected)
						])),
					$elm$html$Html$Events$onClick(onToggleMsg)
				]),
			_List_Nil);
	});
var $author$project$Guarantees$View$V2$MainContent$isCardAlwaysIncluded = F2(
	function (price, card) {
		if (!card.$) {
			var cluster = card.a;
			return A2($author$project$Guarantees$Helpers$isClusterAlwaysIncluded, price, cluster);
		} else {
			return false;
		}
	});
var $author$project$Guarantees$View$V2$MainContent$isCardSelectionDisabled = F2(
	function (dependencies, card) {
		if (!card.$) {
			var guarantees = card.a.nF;
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$author$project$Utils$mapSingleton,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Guarantees$Helpers$isGuaranteeDependenciesSelected(dependencies),
						$elm$core$Basics$not),
					guarantees));
		} else {
			return false;
		}
	});
var $author$project$Guarantees$View$V2$MainContent$cardLabelView = F3(
	function (dependencies, price, card) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-label')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$View$Svg$svg,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__icon'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'v2-card-label__icon--gray',
									A2($author$project$Guarantees$View$V2$MainContent$isCardSelectionDisabled, dependencies, card))
								]))
						]),
					'garanzie/v2/',
					$author$project$Guarantees$View$Helpers$pickCardSlug(card)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-label__text-area')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-card-label__title-area')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('v2-card-label__title-wrapper')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('v2-card-label__title')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Guarantees$View$Helpers$getCardTitle(card))
												]))
										])),
									A4(
									$author$project$Guarantees$View$V2$MainContent$checkBoxIconView,
									$author$project$Guarantees$View$V2$MainContent$pickCardIsSelected(card),
									A2($author$project$Guarantees$View$V2$MainContent$isCardAlwaysIncluded, price, card),
									A2($author$project$Guarantees$View$V2$MainContent$isCardSelectionDisabled, dependencies, card),
									A2($author$project$Guarantees$View$V2$MainContent$getActionBtnMsg, price, card))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('display-flex'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'flex-direction-row',
											$author$project$Guarantees$View$V2$MainContent$isSingleType(card)),
											_Utils_Tuple2(
											'flex-wrap-wrap',
											$author$project$Guarantees$View$V2$MainContent$isSingleType(card)),
											_Utils_Tuple2(
											'flex-direction-column',
											!$author$project$Guarantees$View$V2$MainContent$isSingleType(card))
										]))
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Utils$ternaryOperator,
									A2($author$project$Guarantees$View$V2$MainContent$isCardAlwaysIncluded, price, card),
									$author$project$Guarantees$View$V2$MainContent$cardLabelMobilePriceIncludedView(
										$author$project$Guarantees$View$V2$MainContent$pickCardPaymentFrequency(card)),
									$author$project$Guarantees$View$V2$MainContent$cardLabelMobilePriceView(card)),
									A2($author$project$Guarantees$View$V2$MainContent$cardLabelActionView, price, card)
								])),
							A3($author$project$Guarantees$View$V2$MainContent$cardDependencyView, price, dependencies, card)
						]))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$includedGuaranteeView = F2(
	function (cluster, guarantee) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-included-guarantees__item'),
					$elm$html$Html$Events$onClick(
					A2($author$project$Guarantees$Model$OpenGuaranteeDetailModal, guarantee, cluster))
				]),
			_List_fromArray(
				[
					A3(
					$author$project$View$Svg$svg,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-included-guarantees__item-icon')
						]),
					'garanzie/',
					$author$project$Guarantees$Helpers$pickGuaranteeSlug(guarantee)),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-included-guarantees__item-label')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Guarantees$Helpers$pickGuaranteeLabel(guarantee))
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-included-guarantees__item-caret icon-caret-right')
						]),
					_List_Nil)
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$includedGuaranteesTitleView = function (clusterLabel) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-included-guarantees__title')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-included-guarantees__title-desktop')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Include:')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-included-guarantees__title-mobile')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(clusterLabel + ' base include:')
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$includedGuaranteesListView = F2(
	function (isMobile, _v0) {
		var cluster = _v0.a;
		var guarantees = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('v2-included-guarantees', !isMobile),
							_Utils_Tuple2('v2-included-guarantees-mobile', isMobile)
						]))
				]),
			A2(
				$elm$core$List$cons,
				$author$project$Guarantees$View$V2$MainContent$includedGuaranteesTitleView(cluster.n6),
				A2(
					$elm$core$List$map,
					$author$project$Guarantees$View$V2$MainContent$includedGuaranteeView(cluster),
					guarantees)));
	});
var $author$project$Guarantees$View$V2$MainContent$maybeIncludedGuaranteesData = function (card) {
	if (!card.$) {
		var cluster = card.a;
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$pair(cluster),
			A4(
				$author$project$Utils$ternaryOperator,
				$author$project$Guarantees$View$Helpers$isSingleGuaranteeCluster(cluster),
				$elm$core$Basics$always($elm$core$Maybe$Nothing),
				$author$project$Utils$fromListToMaybe,
				A2($elm$core$List$filter, $author$project$Guarantees$Helpers$isGuaranteeIncluded, cluster.nF)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Guarantees$View$V2$MainContent$cardLabelsAreaView = F3(
	function (dependencies, price, card) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card-labels-area')
				]),
			_List_fromArray(
				[
					A3($author$project$Guarantees$View$V2$MainContent$cardLabelView, dependencies, price, card),
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					$author$project$Guarantees$View$V2$MainContent$includedGuaranteesListView(false),
					$author$project$Guarantees$View$V2$MainContent$maybeIncludedGuaranteesData(card))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$cardView = F3(
	function (price, dependencies, card) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-card'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'v2-selected-card',
							$author$project$Guarantees$View$V2$MainContent$pickCardIsSelected(card))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-content')
						]),
					_List_fromArray(
						[
							A3($author$project$Guarantees$View$V2$MainContent$cardLabelsAreaView, dependencies, price, card),
							$author$project$Guarantees$View$V2$MainContent$cardContentSeparatorView,
							A3(
							$author$project$Utils$ternaryOperator,
							A2($author$project$Guarantees$View$V2$MainContent$isCardAlwaysIncluded, price, card),
							$author$project$Guarantees$View$V2$MainContent$cardIncludedAreaView(card),
							A3(
								$author$project$Guarantees$View$V2$MainContent$cardActionAreaView,
								A2($author$project$Guarantees$View$V2$MainContent$isCardSelectionDisabled, dependencies, card),
								card,
								price))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-card-content-mobile')
						]),
					_List_fromArray(
						[
							A2(
							$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
							$author$project$Guarantees$View$V2$MainContent$includedGuaranteesListView(true),
							$author$project$Guarantees$View$V2$MainContent$maybeIncludedGuaranteesData(card))
						]))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$clusterView = F4(
	function (model, price, dependencies, _v0) {
		var cluster = _v0.a;
		var optionalGuarantees = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-package__cluster')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex flex-direction-column')
						]),
					$elm$core$List$singleton(
						A3($author$project$Guarantees$View$V2$MainContent$cardView, price, dependencies, cluster))),
					A3($author$project$Guarantees$View$V2$MainContent$bundleDiscountMessageConditionalView, model, price, cluster),
					$author$project$Guarantees$View$V2$MainContent$additionalGuaranteesTextConditionalView(optionalGuarantees),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex flex-direction-column')
						]),
					A2(
						$elm$core$List$map,
						A2($author$project$Guarantees$View$V2$MainContent$cardView, price, dependencies),
						optionalGuarantees))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$packageCardsView = F4(
	function (model, price, dependencies, clusters) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-column')
				]),
			A2(
				$elm$core$List$map,
				A3($author$project$Guarantees$View$V2$MainContent$clusterView, model, price, dependencies),
				clusters));
	});
var $author$project$Guarantees$View$V2$MainContent$hasNoOptionGroups = function (_v0) {
	var guarantees = _v0.nF;
	var optionGroups = _v0.g2;
	return $elm$core$List$isEmpty(optionGroups) && A2(
		$elm$core$List$all,
		A2($elm$core$Basics$composeR, $author$project$Guarantees$Helpers$pickGuaranteeOptionGroups, $elm$core$List$isEmpty),
		guarantees);
};
var $author$project$Guarantees$Model$None = {$: 0};
var $author$project$Guarantees$View$V2$MainContent$packageHeaderLimitsView = function (cluster) {
	var onClickMsg = A2($author$project$Guarantees$Model$OpenEditOptionsDetailModal, cluster, $author$project$Guarantees$Model$None);
	var editOptionsLink = $primait$pyxis_components$Prima$Pyxis$Link$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Link$withOnClick,
			onClickMsg,
			A2(
				$primait$pyxis_components$Prima$Pyxis$Link$withClass,
				'cursor-pointer',
				$primait$pyxis_components$Prima$Pyxis$Link$simple('FRANCHIGIE E MASSIMALI'))));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-package-header__limits')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$View$Svg$svg,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-package-header__limits-icon')
					]),
				'garanzie/',
				'edit_green'),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-package-header__limits-desktop')
					]),
				_List_fromArray(
					[editOptionsLink])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-package-header__limits-mobile'),
						$elm$html$Html$Events$onClick(onClickMsg)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('massimali')
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$packageHeaderView = F2(
	function (label, _v0) {
		var clusterCard = _v0.a;
		var maybeClusterWithEditableLimits = function () {
			if (!clusterCard.$) {
				var cluster_ = clusterCard.a;
				return A3(
					$author$project$Utils$ternaryOperator,
					$author$project$Guarantees$View$V2$MainContent$hasNoOptionGroups(cluster_),
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(cluster_));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-package-header')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-package-header__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2($author$project$PrimaElm$Lib$Utils$Render$maybeMapRender, $author$project$Guarantees$View$V2$MainContent$packageHeaderLimitsView, maybeClusterWithEditableLimits)
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$packageView = F4(
	function (model, price, dependencies, _package) {
		var label = _package.n6;
		var card = $elm$core$List$head(
			$author$project$Guarantees$View$V2$MainContent$getClustersCards(_package));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-package')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$PrimaElm$Lib$Utils$Render$maybeMapRender,
					$author$project$Guarantees$View$V2$MainContent$packageHeaderView(label),
					card),
					A4(
					$author$project$Guarantees$View$V2$MainContent$packageCardsView,
					model,
					price,
					dependencies,
					$author$project$Guarantees$View$V2$MainContent$getClustersCards(_package))
				]));
	});
var $author$project$Components$NavigableModal$getModalPayload = F2(
	function (externalModel, pageType) {
		if (!pageType.$) {
			var modalPayload = pageType.a;
			return $elm$core$Maybe$Just(modalPayload);
		} else {
			var pagePayloadMapper = pageType.a;
			var pageRenderer = pageType.b;
			return A2(
				$elm$core$Maybe$map,
				pageRenderer,
				pagePayloadMapper(externalModel));
		}
	});
var $author$project$Components$NavigableModal$pickCurrentPage = function (_v0) {
	var pages = _v0.aZ;
	return $elm$core$List$head(pages);
};
var $author$project$Components$NavigableModal$isBackVisible = function (_v0) {
	var pages = _v0.aZ;
	return $elm$core$List$length(pages) > 1;
};
var $author$project$Components$NavigableModal$backArrowButtonView = function (backMsg) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-back-button icon icon-arrow-sx-button'),
				$elm$html$Html$Events$onClick(backMsg)
			]),
		_List_Nil);
};
var $author$project$Components$NavigableModal$renderBackButton = $author$project$Components$NavigableModal$backArrowButtonView;
var $author$project$Components$NavigableModal$renderHeader = function (title) {
	return A2(
		$elm$html$Html$span,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Components$NavigableModal$renderHeaderWithBack = F3(
	function (title, backMsg, model) {
		return _List_fromArray(
			[
				A2(
				$author$project$PrimaElm$Lib$Utils$Render$renderIf,
				$author$project$Components$NavigableModal$isBackVisible(model),
				$author$project$Components$NavigableModal$renderBackButton(backMsg)),
				$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Components$NavigableModal$renderHeader, title))
			]);
	});
var $author$project$Components$NavigableModal$renderPage = F2(
	function (model, _v0) {
		var isOpen = model.jP;
		var backMsg = model.e$;
		var closingMsg = model.e2;
		var title = _v0.eE;
		var content = _v0.mQ;
		var footer = _v0.ds;
		return $primait$pyxis_components$Prima$Pyxis$Modal$render(
			A3(
				$author$project$Utils$maybeApply,
				$primait$pyxis_components$Prima$Pyxis$Modal$withFooterContent,
				footer,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent,
					content,
					A2(
						$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderContent,
						A3($author$project$Components$NavigableModal$renderHeaderWithBack, title, backMsg, model),
						A2(
							$primait$pyxis_components$Prima$Pyxis$Modal$withHeaderClass,
							'display-flex flex-direction-row align-items-center',
							$primait$pyxis_components$Prima$Pyxis$Modal$withCloseOnOverlayClick(
								A2($primait$pyxis_components$Prima$Pyxis$Modal$medium, isOpen, closingMsg)))))));
	});
var $author$project$Components$NavigableModal$render = F2(
	function (externalModel, model) {
		var config = model;
		return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				$author$project$Components$NavigableModal$renderPage(model),
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Components$NavigableModal$getModalPayload(externalModel),
					$author$project$Components$NavigableModal$pickCurrentPage(config))));
	});
var $author$project$Guarantees$Model$FilterSection = function (a) {
	return {$: 18, a: a};
};
var $author$project$Guarantees$View$V2$MainContent$allSectionFilterConfig = function (selectedSection) {
	return {
		dQ: _Utils_eq(selectedSection, $author$project$Types$Quote$NoSectionFilter),
		n6: 'Tutte',
		d1: $author$project$Guarantees$Model$FilterSection($author$project$Types$Quote$NoSectionFilter)
	};
};
var $author$project$Types$Quote$SectionFilter = function (a) {
	return {$: 0, a: a};
};
var $author$project$Guarantees$View$V2$MainContent$sectionToSectionFilterConfig = F2(
	function (selectedSection, _v0) {
		var slug = _v0.bH;
		var label = _v0.n6;
		return {
			dQ: (!_Utils_eq(selectedSection, $author$project$Types$Quote$NoSectionFilter)) && A2($author$project$Types$Quote$isSectionDisplayed, selectedSection, slug),
			n6: label,
			d1: $author$project$Guarantees$Model$FilterSection(
				$author$project$Types$Quote$SectionFilter(slug))
		};
	});
var $author$project$Guarantees$View$V2$MainContent$sectionfilterview = function (_v0) {
	var label = _v0.n6;
	var isSelected = _v0.dQ;
	var msg = _v0.d1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('v2-filter__item', true),
						_Utils_Tuple2('v2-filter__item--selected', isSelected)
					])),
				$elm$html$Html$Events$onClick(msg)
			]),
		_List_fromArray(
			[
				A2(
				$author$project$PrimaElm$Lib$Utils$Render$renderIf,
				isSelected,
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-filter__item__tip')
						]),
					_List_Nil)),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]))
			]));
};
var $author$project$Guarantees$View$V2$MainContent$sectionFilterListView = F2(
	function (selectedSection, quotePrice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-filter')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-filter-list')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Guarantees$View$V2$MainContent$sectionfilterview,
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									$author$project$Guarantees$View$V2$MainContent$allSectionFilterConfig(selectedSection)
								]),
							A2(
								$elm$core$List$map,
								$author$project$Guarantees$View$V2$MainContent$sectionToSectionFilterConfig(selectedSection),
								A2(
									$elm$core$List$filter,
									function ($) {
										return $.nt;
									},
									quotePrice.pB)))))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$sectionFilterView = F2(
	function (selectedSection, quotePrice) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-filter-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Aggiungi garanzie')
						])),
					A2($author$project$Guarantees$View$V2$MainContent$sectionFilterListView, selectedSection, quotePrice)
				]));
	});
var $author$project$Components$QuoteInfoBox$Small = 1;
var $author$project$Guarantees$View$Helpers$pickInformationPackageLink = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.dH;
		},
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.pd;
			},
			model.h$));
};
var $author$project$Guarantees$View$Helpers$buildDownloadInformationPackage = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		$author$project$Guarantees$View$Helpers$pickInformationPackageLink(model));
};
var $author$project$Components$QuoteInfoBox$withInformationPackageLink = F2(
	function (link, config) {
		return _Utils_update(
			config,
			{dH: link});
	});
var $author$project$Guarantees$View$V2$QuoteInfoMobile$view = F3(
	function (model, quote, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-quote-box-mobile')
				]),
			_List_fromArray(
				[
					$author$project$Components$QuoteInfoBox$render(
					A2(
						$author$project$Components$QuoteInfoBox$withInformationPackageLink,
						$author$project$Guarantees$View$Helpers$buildDownloadInformationPackage(model),
						A2(
							$author$project$Components$QuoteInfoBox$withSummaryToggle,
							1,
							A2(
								$author$project$Components$QuoteInfoBox$withClass,
								A3(
									$author$project$Utils$ternaryOperator,
									$author$project$Types$Quote$Selection$isAnyOfClusterSelected(price),
									'items-selected',
									''),
								A2(
									$author$project$Components$QuoteInfoBox$withClass,
									'quote-box-guarantees',
									A2(
										$author$project$Components$QuoteInfoBox$withHeader,
										$author$project$Components$QuoteInfoBox$guaranteesHeaderView(
											$author$project$Guarantees$View$Helpers$getDefaultSaveCode(model)),
										A2(
											$author$project$Components$QuoteInfoBox$withButtonLabel,
											'Riepilogo e modifica dati',
											A2(
												$author$project$Components$QuoteInfoBox$box,
												$author$project$Guarantees$Model$OnToggle(
													$author$project$Guarantees$Model$Summary(true)),
												quote))))))))
				]));
	});
var $author$project$Guarantees$View$V2$MainContent$view = F3(
	function (model, quote, price) {
		var options = model.ku;
		var ui = model.bM;
		var dependenciesDialog = model.iN;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex flex-direction-column')
				]),
			_List_fromArray(
				[
					A2($author$project$Guarantees$View$V2$MainContent$sectionFilterView, ui.hr, price),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A3(
							$author$project$Guarantees$View$V2$MainContent$packageView,
							model,
							price,
							$author$project$Guarantees$Helpers$calculateGuaranteesDependenciesRules(price)),
						A2($author$project$Guarantees$View$Helpers$getDisplayedPackagesV2, price, ui.hr))),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3($author$project$Guarantees$View$V2$QuoteInfoMobile$view, model, quote, price),
							$author$project$Components$QuoteInfoModal$render(
							A2(
								$author$project$Components$QuoteInfoModal$withEdit,
								A2(
									$author$project$Guarantees$Model$RedirectToEdit,
									$elm$core$Maybe$Just(quote.nM),
									$elm$core$Maybe$Nothing),
								A3(
									$author$project$Components$QuoteInfoModal$summary,
									$author$project$Guarantees$Model$OnToggle(
										$author$project$Guarantees$Model$Summary(false)),
									options.jW,
									quote))),
							A2(
							$author$project$Guarantees$View$BottomBar$view,
							$author$project$Types$Quote$Selection$isAnyOfClusterSelected(price),
							model),
							A2(
							$author$project$Components$NavigableModal$render,
							$author$project$Guarantees$Model$generateNavigableModalExternalModel(model),
							ui.d3),
							A2($author$project$Guarantees$View$V2$MainContent$dialogView, price, dependenciesDialog)
						]))
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$actionBoxDiscountPriceView = F3(
	function (price, fullPrice, discountMessage) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-sidebar-card__price-discounted')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-sidebar-card__price-discounted-badge')
						]),
					_List_fromArray(
						[
							A2($author$project$PrimaElm$Lib$Utils$Render$maybeMapRender, $elm$html$Html$text, discountMessage)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-sidebar-card__price__yearly')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__fullprice__value')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Utils$pricify(fullPrice))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__price__currency')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('€ ')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__price__value'),
									A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'sidebar-card__price')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Utils$priceDecimalsFormat(
										$prikhi$decimal$Decimal$toFloat(price)))
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__price__frequency')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(' / anno')
								]))
						]))
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$actionBoxNoDiscountPriceView = function (price) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-sidebar-card__price')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-sidebar-card__price__yearly')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__price__currency')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('€ ')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__price__value'),
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'sidebar-card__price')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Utils$priceDecimalsFormat(
									$prikhi$decimal$Decimal$toFloat(price)))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__price__frequency')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(' / anno')
							]))
					]))
			]));
};
var $author$project$Guarantees$View$V2$Sidebar$actionBoxPriceView = F3(
	function (price, fullPrice, discountMessage) {
		return A2($prikhi$decimal$Decimal$lt, price, fullPrice) ? A3($author$project$Guarantees$View$V2$Sidebar$actionBoxDiscountPriceView, price, fullPrice, discountMessage) : $author$project$Guarantees$View$V2$Sidebar$actionBoxNoDiscountPriceView(price);
	});
var $author$project$Guarantees$View$V2$Sidebar$actionBoxView = F3(
	function (viewConfig, ui, quotePrice) {
		var price = A3($author$project$Types$Quote$Selection$calculateTotalPrice, 0, 0, quotePrice);
		var fullPrice = A3($author$project$Types$Quote$Selection$calculateTotalPrice, 1, 0, quotePrice);
		var discountMessage = A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.mQ;
				},
				function ($) {
					return $.mi;
				}),
			A2($author$project$Guarantees$Helpers$pickDiscountMessageByType, 0, quotePrice));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('v2-sidebar-card__action', true),
							_Utils_Tuple2(
							'v2-sidebar-card__action--discounted',
							A2($prikhi$decimal$Decimal$lt, price, fullPrice))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-sidebar-card__action-bg')
						]),
					_List_Nil),
					A3($author$project$Guarantees$View$V2$Sidebar$actionBoxPriceView, price, fullPrice, discountMessage),
					A4(
					$author$project$Guarantees$View$Helpers$proceedBtnView,
					'v2-sidebar-card__proceed-btn',
					'sidebar',
					A2($author$project$Guarantees$View$Helpers$getIsProceedActionAllowed, viewConfig, ui),
					ui.jV)
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$noItemsSelectedView = function (quotePrice) {
	var discountMessage = A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.mQ;
			},
			function ($) {
				return $.mi;
			}),
		A2($author$project$Guarantees$Helpers$pickDiscountMessageByType, 0, quotePrice));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-sidebar-card__no-data-box')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__no-data-discounted-badge')
							]),
						_List_fromArray(
							[
								A2($author$project$PrimaElm$Lib$Utils$Render$maybeMapRender, $elm$html$Html$text, discountMessage)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__no-data-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Aggiungi garanzie per conoscere il prezzo del tuo preventivo.')
							]))
					]))
			]));
};
var $author$project$Guarantees$View$V2$Sidebar$getCardLowerContent = F3(
	function (viewConfig, ui, price) {
		return $author$project$Types$Quote$Selection$isAnyOfClusterSelected(price) ? A3($author$project$Guarantees$View$V2$Sidebar$actionBoxView, viewConfig, ui, price) : $author$project$Guarantees$View$V2$Sidebar$noItemsSelectedView(price);
	});
var $author$project$Guarantees$View$V2$Sidebar$lowerView = F3(
	function (viewConfig, ui, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-sidebar-card__lower')
				]),
			_List_fromArray(
				[
					A3($author$project$Guarantees$View$V2$Sidebar$getCardLowerContent, viewConfig, ui, price)
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$getListMaxItems = 5;
var $author$project$Guarantees$View$V2$Sidebar$isItemsListViewFull = F2(
	function (isSidebarListExpanded, price) {
		return isSidebarListExpanded || (_Utils_cmp(
			$elm$core$List$length(
				$author$project$Guarantees$View$Helpers$getSelectedCardsV2(price)),
			$author$project$Guarantees$View$V2$Sidebar$getListMaxItems) < 1);
	});
var $author$project$Guarantees$Model$SidebarCardList = {$: 11};
var $author$project$Guarantees$View$V2$Sidebar$expandListBtnView = F2(
	function (isExpanded, listItems) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-sidebar-card__btn-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-sidebar-card__btn'),
							$elm$html$Html$Events$onClick(
							$author$project$Guarantees$Model$OnToggle($author$project$Guarantees$Model$SidebarCardList))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__btn-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A3(
										$author$project$Utils$ternaryOperator,
										isExpanded,
										'Riduci',
										'Mostra altre ' + $elm$core$String$fromInt((listItems - $author$project$Guarantees$View$V2$Sidebar$getListMaxItems) + 1)))
								])),
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__list-arrow'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('icon-caret-down', !isExpanded),
											_Utils_Tuple2('icon-caret-up', isExpanded)
										]))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$itemsListBtnView = F2(
	function (isListExpanded, itemsList) {
		return isListExpanded ? A3(
			$author$project$Utils$ternaryOperator,
			_Utils_cmp(
				$elm$core$List$length(itemsList),
				$author$project$Guarantees$View$V2$Sidebar$getListMaxItems) < 1,
			$elm$html$Html$text(''),
			A2(
				$author$project$Guarantees$View$V2$Sidebar$expandListBtnView,
				true,
				$elm$core$List$length(itemsList))) : A2(
			$author$project$Guarantees$View$V2$Sidebar$expandListBtnView,
			false,
			$elm$core$List$length(itemsList));
	});
var $author$project$Guarantees$View$V2$Sidebar$itemView = function (_v0) {
	var label = _v0.n6;
	var price = _v0.oY;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-sidebar-card__item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-sidebar-card__item__label-box')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-sidebar-card__item__label')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-sidebar-card__item__price-box')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Utils$pricify(price))
					]))
			]));
};
var $author$project$Guarantees$View$V2$Sidebar$itemsListFullView = function (itemsList) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-sidebar-card__items-list')
			]),
		A2($elm$core$List$map, $author$project$Guarantees$View$V2$Sidebar$itemView, itemsList));
};
var $author$project$Guarantees$View$V2$Sidebar$getPartialListItems = function (itemsList) {
	return A2($elm$core$List$take, $author$project$Guarantees$View$V2$Sidebar$getListMaxItems - 1, itemsList);
};
var $author$project$Guarantees$View$V2$Sidebar$itemsListPartialView = function (itemsList) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-sidebar-card__items-list')
			]),
		A2(
			$elm$core$List$map,
			$author$project$Guarantees$View$V2$Sidebar$itemView,
			$author$project$Guarantees$View$V2$Sidebar$getPartialListItems(itemsList)));
};
var $author$project$Guarantees$View$V2$Sidebar$itemsListView = function (isListExpanded) {
	return A3($author$project$Utils$ternaryOperator, isListExpanded, $author$project$Guarantees$View$V2$Sidebar$itemsListFullView, $author$project$Guarantees$View$V2$Sidebar$itemsListPartialView);
};
var $author$project$Guarantees$View$V2$Sidebar$upperView = F2(
	function (_v0, price) {
		var isSidebarListExpanded = _v0.jU;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-sidebar-card__upper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-sidebar-card__header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Il tuo preventivo')
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('v2-sidebar-card__subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Garanzie aggiunte:')
								]))
						])),
					A2(
					$author$project$Guarantees$View$V2$Sidebar$itemsListView,
					A2($author$project$Guarantees$View$V2$Sidebar$isItemsListViewFull, isSidebarListExpanded, price),
					$author$project$Guarantees$View$Helpers$getSelectedCardsV2(price)),
					A2(
					$author$project$Guarantees$View$V2$Sidebar$itemsListBtnView,
					A2($author$project$Guarantees$View$V2$Sidebar$isItemsListViewFull, isSidebarListExpanded, price),
					$author$project$Guarantees$View$Helpers$getSelectedCardsV2(price))
				]));
	});
var $author$project$Guarantees$View$V2$Sidebar$view = F2(
	function (model, price) {
		var viewConfig = model.h$;
		var ui = model.bM;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-sidebar-card')
				]),
			_List_fromArray(
				[
					A2($author$project$Guarantees$View$V2$Sidebar$upperView, model, price),
					A3($author$project$Guarantees$View$V2$Sidebar$lowerView, viewConfig, ui, price)
				]));
	});
var $author$project$Guarantees$View$V2$TopBar$fixedFrequencyView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('v2-top-bar__frequency__fixed')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-top-bar__frequency__fixed-img icon-wallet')
				]),
			_List_Nil),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-top-bar__frequency__fixed-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Pagamento annuale')
				]))
		]));
var $author$project$Guarantees$View$V2$TopBar$separatorView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('v2-top-bar__separator')
		]),
	_List_Nil);
var $author$project$Guarantees$View$V2$TopBar$frequencyView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('v2-top-bar__frequency-wrapper')
		]),
	_List_fromArray(
		[
			$author$project$Guarantees$View$V2$TopBar$separatorView,
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-top-bar__frequency')
				]),
			_List_fromArray(
				[$author$project$Guarantees$View$V2$TopBar$fixedFrequencyView]))
		]));
var $author$project$Guarantees$View$V2$TopBar$boxedUserDataContentView = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-top-bar__data__box')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Guarantees$View$V2$TopBar$getInformationPackageLink = function (href) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		href !== '',
		A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-top-bar__data__action'),
					A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-information-set-download-link'),
					$elm$html$Html$Attributes$href(href),
					$elm$html$Html$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('v2-top-bar__data__action-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Set informativo')
						])),
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon-download v2-top-bar__data__action-icon')
						]),
					_List_Nil)
				])));
};
var $author$project$Guarantees$View$V2$TopBar$quoteAndSummaryButtonView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('v2-top-bar__data__left')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-top-bar__data__save-code')
					]),
				_List_fromArray(
					[
						A2($elm$core$Basics$composeR, $elm$core$String$trim, $elm$html$Html$text)(
						'Preventivo ' + $author$project$Guarantees$View$Helpers$getDefaultSaveCode(model))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-top-bar__data__action'),
						A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'id-summary_details-btn'),
						$elm$html$Html$Events$onClick(
						$author$project$Guarantees$Model$OnToggle(
							$author$project$Guarantees$Model$Summary(true)))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-top-bar__data__action-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Riepilogo e modifica dati')
							])),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('icon-caret-right v2-top-bar__data__action-icon')
							]),
						_List_Nil)
					])),
				$author$project$Guarantees$View$V2$TopBar$getInformationPackageLink(
				$author$project$Guarantees$View$Helpers$buildDownloadInformationPackage(model))
			]));
};
var $author$project$Guarantees$View$V2$TopBar$getUserDataContentList = F2(
	function (model, _v0) {
		var formType = _v0.nx;
		var productType = _v0.kX;
		var information = _v0.nT;
		return A2(
			$elm$core$List$append,
			$elm$core$List$singleton(
				$author$project$Guarantees$View$V2$TopBar$quoteAndSummaryButtonView(model)),
			A3($author$project$Components$QuoteInfoBox$getContentByProductType, productType, information, formType));
	});
var $author$project$Guarantees$View$V2$TopBar$userDataView = F2(
	function (model, quote) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('v2-top-bar__data')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Guarantees$View$V2$TopBar$boxedUserDataContentView,
				A2($author$project$Guarantees$View$V2$TopBar$getUserDataContentList, model, quote)));
	});
var $author$project$Guarantees$View$V2$TopBar$view = F2(
	function (model, quote) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-top-bar-desktop')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('v2-top-bar__content')
							]),
						_List_fromArray(
							[
								A2($author$project$Guarantees$View$V2$TopBar$userDataView, model, quote),
								$author$project$Guarantees$View$V2$TopBar$separatorView,
								$author$project$Guarantees$View$V2$TopBar$frequencyView
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('v2-top-bar-mobile')
					]),
				_List_fromArray(
					[
						$author$project$View$MobileSummaryView$view(
						$author$project$Guarantees$Model$OnToggle(
							$author$project$Guarantees$Model$Summary(true)))
					]))
			]);
	});
var $author$project$Guarantees$View$templateV2 = F2(
	function (model, _v0) {
		var userType = model.lR;
		var flags = model.nv;
		var quote = _v0.pd;
		var price = _v0.oY;
		return $author$project$View$Template$render(
			A2(
				$author$project$View$Template$withLogoUrl,
				A2($author$project$Types$redirectUrl, flags, userType),
				A2(
					$author$project$View$Template$withSideContent,
					A2($author$project$Guarantees$View$V2$Sidebar$view, model, price),
					A2(
						$author$project$View$Template$withMainContent,
						A3($author$project$Guarantees$View$V2$MainContent$view, model, quote, price),
						A4(
							$author$project$View$Template$withTopBarContent,
							false,
							A2($author$project$Guarantees$View$V2$TopBar$view, model, quote),
							'v2-top-bar',
							A2(
								$author$project$View$Template$withClasses,
								A3(
									$author$project$Utils$ternaryOperator,
									$author$project$Types$Quote$Selection$isAnyOfClusterSelected(price),
									' items-selected',
									''),
								A2($author$project$View$Template$withClasses, 'v2-guarantees', $author$project$View$Template$template)))))));
	});
var $author$project$Guarantees$View$getTemplateByVersion = F2(
	function (model, viewConfig) {
		var quote = viewConfig.pd;
		var _v0 = quote.lU;
		if (!_v0) {
			return A2($author$project$Guarantees$View$templateV1, model, viewConfig);
		} else {
			return A2($author$project$Guarantees$View$templateV2, model, viewConfig);
		}
	});
var $author$project$Guarantees$View$view = function (model) {
	var viewConfig = model.h$;
	return $author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
		A2(
			$elm$core$Maybe$map,
			$author$project$Guarantees$View$getTemplateByVersion(model),
			viewConfig));
};
var $author$project$NotAvailable$ViewConfig$View$figureView = function (_v0) {
	var reason = _v0.k6;
	var figureSource = function () {
		if (!reason.$) {
			switch (reason.a.$) {
				case 0:
					var _v2 = reason.a;
					return 'casa-disabitata';
				case 1:
					var _v3 = reason.a;
					return 'casa-affitto';
				case 2:
					return 'casa-disabitata';
				case 4:
					var _v4 = reason.a;
					return 'thankyou-casa';
				case 3:
					var _v5 = reason.a;
					return 'casa-disabitata';
				case 5:
					var _v6 = reason.a;
					return 'casa-disabitata';
				case 6:
					var _v7 = reason.a;
					return 'casa-disabitata';
				default:
					var _v8 = reason.a;
					return 'casa-disabitata';
			}
		} else {
			return '';
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero__figure')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__figure__picture')
					]),
				_List_fromArray(
					[
						A3(
						$author$project$View$Svg$svg,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('hero__figure__picture__image thank-you-image')
							]),
						'',
						figureSource)
					]))
			]));
};
var $author$project$NotAvailable$Model$AgentsHome = {$: 8};
var $author$project$NotAvailable$Model$Modify = function (a) {
	return {$: 5, a: a};
};
var $author$project$NotAvailable$Model$PrimaHome = {$: 7};
var $author$project$NotAvailable$Model$ReservedArea = {$: 6};
var $author$project$NotAvailable$ViewConfig$View$heroAdditionalContentView = function (_v0) {
	var reason = _v0.k6;
	var tokenType = _v0.lK;
	var flags = _v0.nv;
	var _v1 = _Utils_Tuple2(reason, tokenType);
	_v1$7:
	while (true) {
		if (_v1.b === 1) {
			var _v2 = _v1.b;
			return _List_fromArray(
				[
					$primait$pyxis_components$Prima$Pyxis$Button$render(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						$author$project$NotAvailable$Model$AgentsHome,
						A2(
							$primait$pyxis_components$Prima$Pyxis$Button$withClass,
							'notavailable-button',
							$primait$pyxis_components$Prima$Pyxis$Button$callOut('Torna alla Piattaforma'))))
				]);
		} else {
			if (!_v1.a.$) {
				switch (_v1.a.a.$) {
					case 2:
						var saveId = _v1.a.a.a;
						var _v3 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$Modify(saveId),
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Nuovo Preventivo'))))
							]);
					case 4:
						var _v4 = _v1.a.a;
						var _v5 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$ReservedArea,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Area Riservata'))))
							]);
					case 3:
						var _v6 = _v1.a.a;
						var _v7 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$PrimaHome,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Torna alla Home'))))
							]);
					case 6:
						var _v8 = _v1.a.a;
						var _v9 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$PrimaHome,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Torna alla Home'))))
							]);
					case 5:
						var _v10 = _v1.a.a;
						var _v11 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$ReservedArea,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Area Riservata'))))
							]);
					case 7:
						var _v12 = _v1.a.a;
						var _v13 = _v1.b;
						return _List_fromArray(
							[
								$primait$pyxis_components$Prima$Pyxis$Button$render(
								A2(
									$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
									$author$project$NotAvailable$Model$PrimaHome,
									A2(
										$primait$pyxis_components$Prima$Pyxis$Button$withClass,
										'notavailable-button',
										$primait$pyxis_components$Prima$Pyxis$Button$callOut('Torna alla Home'))))
							]);
					default:
						break _v1$7;
				}
			} else {
				break _v1$7;
			}
		}
	}
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('notavailable-paragraph')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Consulta ')
						])),
					$primait$pyxis_components$Prima$Pyxis$Link$render(
					$primait$pyxis_components$Prima$Pyxis$Link$withTargetBlank(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Link$withHref,
							$author$project$Types$pickPrimaUrl(flags) + '/supporto-online',
							$primait$pyxis_components$Prima$Pyxis$Link$simple('guide')))),
					$elm$html$Html$text(' e '),
					$primait$pyxis_components$Prima$Pyxis$Link$render(
					$primait$pyxis_components$Prima$Pyxis$Link$withTargetBlank(
						A2(
							$primait$pyxis_components$Prima$Pyxis$Link$withHref,
							$author$project$Types$pickPrimaUrl(flags) + '/supporto-online',
							$primait$pyxis_components$Prima$Pyxis$Link$simple('domande frequenti')))),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(' per saperne di più.')
						]))
				])),
			$primait$pyxis_components$Prima$Pyxis$Button$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
				$author$project$NotAvailable$Model$PrimaHome,
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withClass,
					'notavailable-button',
					$primait$pyxis_components$Prima$Pyxis$Button$callOut('Torna alla home'))))
		]);
};
var $author$project$NotAvailable$ViewConfig$View$heroIntroParagraphView = function (_v0) {
	var reason = _v0.k6;
	var tokenType = _v0.lK;
	var _v1 = _Utils_Tuple2(reason, tokenType);
	if (_v1.b === 1) {
		var _v2 = _v1.b;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Non è possibile calcolare il preventivo.'),
					A2(
					$elm$html$Html$p,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Per maggiori informazioni, contatta il tuo Account Manager di riferimento.')
						]))
				]));
	} else {
		if (!_v1.a.$) {
			switch (_v1.a.a.$) {
				case 0:
					var _v3 = _v1.a.a;
					var _v4 = _v1.b;
					return $elm$html$Html$text('Purtroppo non è possibile calcolare un preventivo per immobili non abitati: in caso di sinistro, infatti, potrebbe essere necessario dimostrare l\'effettivo utilizzo dell\'immobile tramite visure di consumi e utenze domestiche.');
				case 1:
					var _v5 = _v1.a.a;
					var _v6 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Purtroppo non è possibile calcolare un preventivo per immobili con ')
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('contratto di affitto, usufrutto, o comodato d\'uso')
									])),
								$elm$html$Html$text('.'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Questi preventivi saranno presto disponibili, rimani aggiornato su Prima.it')
									]))
							]));
				case 2:
					var _v7 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Non è più possibile acquistare questo preventivo perché ha superato il suo periodo di validità.'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Calcola subito un nuovo preventivo per la tua polizza Casa e Famiglia.')
									]))
							]));
				case 3:
					var _v8 = _v1.a.a;
					var _v9 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Non è possibile acquistare questo preventivo perché non è più valido.'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Calcola un nuovo preventivo per la tua polizza Casa e Famiglia.')
									]))
							]));
				case 5:
					var _v10 = _v1.a.a;
					var _v11 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Si è verificato un problema, ma il preventivo potrebbe essere già disponibile:'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('controlla nella tua Area riservata o riprova più tardi.')
									]))
							]));
				case 6:
					var _v12 = _v1.a.a;
					var _v13 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('La pagina non è più disponibile per motivi di sicurezza.'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Ti abbiamo inviato una nuova email per visualizzare l\'offerta.')
									]))
							]));
				case 4:
					var _v14 = _v1.a.a;
					var _v15 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Hai già completato l\'acquisto di questo preventivo.'),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Accedi alla tua Area riservata inserendo email e password per visualizzare il contratto di polizza e gestire la tua copertura')
									]))
							]));
				default:
					var _v16 = _v1.a.a;
					var _v17 = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('In base ai dati che hai inserito, non è possibile acquistare una polizza Casa e Famiglia.')
							]));
			}
		} else {
			var _v18 = _v1.a;
			return $elm$html$Html$text('');
		}
	}
};
var $author$project$NotAvailable$ViewConfig$View$heroTitleContentView = function (_v0) {
	var reason = _v0.k6;
	var tokenType = _v0.lK;
	var _v1 = _Utils_Tuple2(reason, tokenType);
	_v1$6:
	while (true) {
		if (_v1.b === 1) {
			var _v2 = _v1.b;
			return $elm$html$Html$text('Preventivo non disponibile');
		} else {
			if (!_v1.a.$) {
				switch (_v1.a.a.$) {
					case 2:
						var _v3 = _v1.b;
						return $elm$html$Html$text('Preventivo scaduto');
					case 4:
						var _v4 = _v1.a.a;
						var _v5 = _v1.b;
						return $elm$html$Html$text('Preventivo già acquistato');
					case 5:
						var _v6 = _v1.a.a;
						var _v7 = _v1.b;
						return $elm$html$Html$text('Ops! Qualcosa è andato storto');
					case 6:
						var _v8 = _v1.a.a;
						var _v9 = _v1.b;
						return $elm$html$Html$text('Pagina non disponibile');
					case 7:
						var _v10 = _v1.a.a;
						var _v11 = _v1.b;
						return $elm$html$Html$text('Polizza non acquistabile');
					default:
						break _v1$6;
				}
			} else {
				break _v1$6;
			}
		}
	}
	return $elm$html$Html$text('Preventivo non disponibile');
};
var $author$project$NotAvailable$ViewConfig$View$heroTitleView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero__intro hero__intro--with-figure')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__intro__title c-text-dark thankyou-hero-title')
					]),
				_List_fromArray(
					[
						$author$project$NotAvailable$ViewConfig$View$heroTitleContentView(model)
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__intro__paragraph')
					]),
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$p,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$NotAvailable$ViewConfig$View$heroIntroParagraphView(model)
							])),
					$author$project$NotAvailable$ViewConfig$View$heroAdditionalContentView(model)))
			]));
};
var $author$project$NotAvailable$View$hero = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__container container')
					]),
				_List_fromArray(
					[
						$author$project$NotAvailable$ViewConfig$View$heroTitleView(model),
						$author$project$NotAvailable$ViewConfig$View$figureView(model)
					]))
			]));
};
var $author$project$NotAvailable$View$view = function (model) {
	var mainContent = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$View$Helper$wrapInContainerColumn(
				_List_fromArray(
					[
						$author$project$NotAvailable$View$hero(model)
					]))
			]));
	return $author$project$View$Template$render(
		A2(
			$author$project$View$Template$withLogoUrl,
			$author$project$Types$pickPrimaUrl(model.nv),
			A2(
				$author$project$View$Template$withMainContent,
				mainContent,
				A2($author$project$View$Template$withClasses, 'thank-you', $author$project$View$Template$template))));
};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $author$project$OneClick$View$extractHeroImageUrl = F2(
	function (_default, mapper) {
		return A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.oB;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.m4;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.nJ;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(mapper),
						$elm$core$Maybe$withDefault(_default)))));
	});
var $author$project$OneClick$View$desktopHeroImageUrl = A2(
	$author$project$OneClick$View$extractHeroImageUrl,
	'/svg/oneclick/background-desktop.svg',
	function ($) {
		return $.m3;
	});
var $elm$html$Html$Attributes$media = _VirtualDom_attribute('media');
var $author$project$OneClick$View$mobileHeroImageUrl = A2(
	$author$project$OneClick$View$extractHeroImageUrl,
	'/svg/oneclick/background-mobile.svg',
	function ($) {
		return $.om;
	});
var $author$project$OneClick$View$backgroundContentView = function (_v0) {
	var state = _v0.cG;
	if (state.$ === 3) {
		var modelState = state.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__lazy-loading-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__background-picture-wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__background-picture-cover')
								]),
							_List_Nil),
							A3(
							$elm$html$Html$node,
							'picture',
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__background-picture')
								]),
							_List_fromArray(
								[
									A3(
									$elm$html$Html$node,
									'source',
									_List_fromArray(
										[
											A2(
											$elm$html$Html$Attributes$attribute,
											'srcset',
											$author$project$OneClick$View$desktopHeroImageUrl(modelState)),
											A2($elm$html$Html$Attributes$attribute, 'type', 'image/svg+xml'),
											$elm$html$Html$Attributes$media('(min-width: 992px)')
										]),
									_List_Nil),
									A3(
									$elm$html$Html$node,
									'source',
									_List_fromArray(
										[
											A2(
											$elm$html$Html$Attributes$attribute,
											'srcset',
											$author$project$OneClick$View$mobileHeroImageUrl(modelState)),
											A2($elm$html$Html$Attributes$attribute, 'type', 'image/svg+xml'),
											$elm$html$Html$Attributes$media('(min-width: 1px)')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$img,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('one-click__background-img'),
											$elm$html$Html$Attributes$src(
											$author$project$OneClick$View$mobileHeroImageUrl(modelState)),
											$elm$html$Html$Attributes$alt('Immagine di background'),
											A2($elm$html$Html$Attributes$attribute, 'loading', 'lazy')
										]),
									_List_Nil)
								]))
						]))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$OneClick$View$errorsView = $elm$html$Html$text('');
var $author$project$OneClick$View$loadingView = A2($author$project$Components$Loader$view, 0, 'Ancora qualche secondo... Stiamo elaborando i tuoi dati');
var $author$project$OneClick$View$checkoutLoader = function (_v0) {
	var brainTreeModel = _v0.mp;
	return A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, !brainTreeModel.c0, $author$project$OneClick$View$loadingView);
};
var $author$project$OneClick$Model$ToggleInfoModal = function (a) {
	return {$: 14, a: a};
};
var $author$project$OneClick$View$Infobox$slugToIcon = function (slug) {
	switch (slug) {
		case 'info_thumb_up':
			return 'icon-ok';
		case 'info_ski_boot':
			return 'icon-ski';
		default:
			return slug;
	}
};
var $author$project$OneClick$View$Infobox$view = function (infoBox) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__infobox')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(
						'one-click__icon icon ' + $author$project$OneClick$View$Infobox$slugToIcon(infoBox.bH))
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__infobox__message')
					]),
				_List_fromArray(
					[
						$author$project$Utils$htmlRender(infoBox.of)
					])),
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__icon-help icon icon-help'),
						$elm$html$Html$Events$onClick(
						$author$project$OneClick$Model$ToggleInfoModal(true))
					]),
				_List_Nil)
			]));
};
var $author$project$OneClick$View$Infobox$conditionalView = function (_v0) {
	var infobox = _v0.nS;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2($elm$core$Maybe$map, $author$project$OneClick$View$Infobox$view, infobox));
};
var $author$project$OneClick$View$infoboxModalBadge = function (title) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('badge badge--primary one-click__infobox-modal__badge')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$OneClick$View$infoboxModalContent = function (_v0) {
	var description = _v0.m2;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__infobox-modal__content')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$infoboxModalBadge(description.eE),
				$author$project$Utils$htmlRender(description.of)
			]));
};
var $author$project$OneClick$View$conditionalInfoboxModalContent = function (_v0) {
	var details = _v0.m4;
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2($elm$core$Maybe$map, $author$project$OneClick$View$infoboxModalContent, details.nS));
};
var $author$project$OneClick$View$infoboxModal = function (_v0) {
	var isInfoModalOpen = _v0.jO;
	var offer = _v0.oB;
	return $primait$pyxis_components$Prima$Pyxis$Modal$render(
		A2(
			$primait$pyxis_components$Prima$Pyxis$Modal$withBodyContent,
			_List_fromArray(
				[
					$author$project$OneClick$View$conditionalInfoboxModalContent(offer)
				]),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Modal$withClass,
				'one-click__infobox-modal',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Modal$medium,
					isInfoModalOpen,
					$author$project$OneClick$Model$ToggleInfoModal(false)))));
};
var $author$project$OneClick$Model$ToggleSummary = function (a) {
	return {$: 6, a: a};
};
var $author$project$OneClick$View$addressMapper = function (address) {
	return {my: address.my, fl: $elm$core$Maybe$Nothing, kp: address.pO, o6: $elm$core$Maybe$Nothing, hH: $elm$core$Maybe$Nothing, lx: address.hN, qg: address.qg};
};
var $author$project$OneClick$View$genderMapper = function (gender) {
	if (!gender) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$OneClick$View$contractorToQuoteInformationMapper = function (_v0) {
	var firstName = _v0.nu;
	var lastName = _v0.n8;
	var email = _v0.fi;
	var phoneNumber = _v0.cu;
	var address = _v0.bP;
	var birthCity = _v0.ih;
	var birthCountry = _v0.mj;
	var gender = _v0.ny;
	var birthDate = _v0.mk;
	return {
		ih: birthCity,
		mj: birthCountry,
		mm: $elm$core$Maybe$Nothing,
		mn: birthDate,
		mN: $elm$core$Maybe$Nothing,
		mP: $elm$core$Maybe$Nothing,
		fi: email,
		np: $elm$core$Maybe$Nothing,
		nr: $elm$core$Maybe$Nothing,
		nw: $elm$core$Maybe$Nothing,
		ny: $author$project$OneClick$View$genderMapper(gender),
		oq: firstName,
		oL: 2,
		oT: phoneNumber,
		kY: $elm$core$Maybe$Nothing,
		o1: 4,
		o4: $elm$core$Maybe$Nothing,
		pn: $elm$core$Maybe$Nothing,
		pq: $elm$core$Maybe$Just(
			$author$project$OneClick$View$addressMapper(address)),
		lC: lastName,
		p3: $elm$core$Maybe$Nothing,
		qa: $elm$core$Maybe$Nothing
	};
};
var $author$project$OneClick$View$offerToQuoteMapper = function (_v0) {
	var id = _v0.nM;
	var contractor = _v0.mT;
	return {
		nx: $elm$core$Maybe$Nothing,
		nM: id,
		nT: $author$project$OneClick$View$contractorToQuoteInformationMapper(contractor),
		dH: '',
		kR: _List_Nil,
		kX: $author$project$Types$Quote$ProductTypeFamily(''),
		pI: {nZ: $elm$core$Maybe$Nothing, g0: $elm$core$Maybe$Nothing, ha: $elm$core$Maybe$Nothing, hj: $elm$core$Maybe$Nothing},
		lU: 0
	};
};
var $author$project$OneClick$View$quoteInfoModalView = F2(
	function (isSummaryOpen, offer) {
		return $author$project$Components$QuoteInfoModal$render(
			A3(
				$author$project$Components$QuoteInfoModal$summary,
				$author$project$OneClick$Model$ToggleSummary(false),
				isSummaryOpen,
				$author$project$OneClick$View$offerToQuoteMapper(offer)));
	});
var $author$project$OneClick$View$Hero$backgroundCoverView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__hero__background-cover')
		]),
	_List_Nil);
var $author$project$OneClick$Model$HeroButtonClick = {$: 4};
var $author$project$OneClick$View$Hero$buttonView = function (buttonText) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__hero__cta-btn'),
				$elm$html$Html$Events$onClick($author$project$OneClick$Model$HeroButtonClick)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__hero__cta-btn-text')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(buttonText)
					]))
			]));
};
var $author$project$OneClick$View$Hero$subtitleView = F2(
	function (subtitleText, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__hero__subtitle-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__hero__subtitle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(subtitleText)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__hero__price badge badge--primary')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Utils$pricify(price))
						]))
				]));
	});
var $author$project$OneClick$View$Hero$titleView = function (titleText) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__hero__title-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__hero__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(titleText)
					]))
			]));
};
var $author$project$OneClick$View$Hero$view = F2(
	function (_v0, price) {
		var title = _v0.eE;
		var subtitle = _v0.eu;
		var cta = _v0.iG;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__hero')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Hero$titleView(title),
					A2($author$project$OneClick$View$Hero$subtitleView, subtitle, price),
					$author$project$OneClick$View$Hero$buttonView(cta),
					$author$project$OneClick$View$Hero$backgroundCoverView
				]));
	});
var $author$project$OneClick$View$Jumbotron$slugToIcon = function (slug) {
	switch (slug) {
		case 'rc_vita_privata':
			return 'icon-home-injuries-freetime';
		case 'rc_animali_domestici':
			return 'icon-home-damage-pets';
		case 'rc_bici_elettriche':
			return 'icon-home-damage-ebike';
		case 'rc_famiglia':
			return 'icon-family';
		case 'rc_sport':
			return 'icon-generic-sport';
		default:
			return slug;
	}
};
var $author$project$OneClick$View$Jumbotron$jumbotronCardView = function (_v0) {
	var title = _v0.eE;
	var slug = _v0.bH;
	var subtitle = _v0.eu;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__card')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__card-title-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__jumbotron__card-icon')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class(
										'icon ' + $author$project$OneClick$View$Jumbotron$slugToIcon(slug))
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__jumbotron__card-title-wrapper')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('one-click__jumbotron__card-title-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(title)
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__card-subtitle-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__jumbotron__card-subtitle-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(subtitle)
							]))
					]))
			]));
};
var $author$project$OneClick$View$Jumbotron$jumbotronCardsRowView = function (_v0) {
	var cards = _v0.mt;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__cards-row')
			]),
		$elm$core$List$reverse(
			A2($elm$core$List$map, $author$project$OneClick$View$Jumbotron$jumbotronCardView, cards)));
};
var $author$project$OneClick$Model$JumbotronCtaClick = {$: 5};
var $author$project$OneClick$View$Jumbotron$jumbotronCtaView = function (_v0) {
	var cta = _v0.iG;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__cta-wrapper'),
				$elm$html$Html$Events$onClick($author$project$OneClick$Model$JumbotronCtaClick)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__cta')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__jumbotron__cta-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(cta)
							])),
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__jumbotron__cta-icon icon-caret-right')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$OneClick$View$Jumbotron$jumbotronSubtitleView = function (_v0) {
	var subtitle = _v0.eu;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__subtitle')
			]),
		_List_fromArray(
			[
				$author$project$Utils$htmlRender(subtitle)
			]));
};
var $author$project$OneClick$View$Jumbotron$jumbotronTitleView = function (_v0) {
	var title = _v0.eE;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__title-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]))
			]));
};
var $author$project$OneClick$View$Jumbotron$jumbotronTextRowView = function (offerBox) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron__text-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__text-area')
					]),
				_List_fromArray(
					[
						$author$project$OneClick$View$Jumbotron$jumbotronTitleView(offerBox)
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__jumbotron__text-area')
					]),
				_List_fromArray(
					[
						$author$project$OneClick$View$Jumbotron$jumbotronSubtitleView(offerBox),
						$author$project$OneClick$View$Jumbotron$jumbotronCtaView(offerBox)
					]))
			]));
};
var $author$project$OneClick$View$Jumbotron$view = function (_v0) {
	var offerBox = _v0.oC;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__jumbotron')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Jumbotron$jumbotronTextRowView(offerBox),
				$author$project$OneClick$View$Jumbotron$jumbotronCardsRowView(offerBox)
			]));
};
var $author$project$OneClick$View$OfferCoverage$ExcludedCoverageCard = 1;
var $author$project$OneClick$View$OfferCoverage$IncludedCoverageCard = 0;
var $author$project$OneClick$View$OfferCoverage$cardTitleIconView = function (coverageType) {
	if (!coverageType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-title-icon one-click__offer-coverage__icon-check-mark')
				]),
			_List_Nil);
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-title-icon one-click__offer-coverage__icon-times')
				]),
			_List_Nil);
	}
};
var $author$project$OneClick$View$OfferCoverage$cardTitleTextView = function (coverageType) {
	if (!coverageType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-title-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cosa copre?')
				]));
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-title-text')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Cosa non copre?')
				]));
	}
};
var $author$project$OneClick$View$OfferCoverage$cardRowDotView = function (coverageType) {
	if (!coverageType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-row-dot one-click__offer-coverage__card-row-dot--green')
				]),
			_List_Nil);
	} else {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-row-dot one-click__offer-coverage__card-row-dot--red')
				]),
			_List_Nil);
	}
};
var $author$project$OneClick$View$OfferCoverage$coverageListItemView = F2(
	function (coverageType, coverageElementDescription) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card-row')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__offer-coverage__card-icon-wrapper')
						]),
					_List_fromArray(
						[
							$author$project$OneClick$View$OfferCoverage$cardRowDotView(coverageType)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__offer-coverage__card-row-text-wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__offer-coverage__card-row-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(coverageElementDescription)
								]))
						]))
				]));
	});
var $author$project$OneClick$View$OfferCoverage$offerCoverageCardView = F2(
	function (coverageType, coverageElementsList) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__offer-coverage__card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__offer-coverage__card-title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__offer-coverage__card-icon-wrapper')
								]),
							_List_fromArray(
								[
									$author$project$OneClick$View$OfferCoverage$cardTitleIconView(coverageType)
								])),
							$author$project$OneClick$View$OfferCoverage$cardTitleTextView(coverageType)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__offer-coverage__card-list')
						]),
					A2(
						$elm$core$List$map,
						$author$project$OneClick$View$OfferCoverage$coverageListItemView(coverageType),
						coverageElementsList))
				]));
	});
var $author$project$OneClick$View$OfferCoverage$offerCoverageCardsView = function (_v0) {
	var includes = _v0.nO;
	var excludes = _v0.nh;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__offer-coverage__cards-list-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__offer-coverage__cards-list')
					]),
				_List_fromArray(
					[
						A2($author$project$OneClick$View$OfferCoverage$offerCoverageCardView, 0, includes),
						A2($author$project$OneClick$View$OfferCoverage$offerCoverageCardView, 1, excludes)
					]))
			]));
};
var $author$project$OneClick$View$OfferCoverage$view = function (_v0) {
	var offerCoverage = _v0.oD;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('one-click__offer-coverage'),
				$elm$html$Html$Attributes$class('one-click__offer-coverage')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$OfferCoverage$offerCoverageCardsView(offerCoverage)
			]));
};
var $author$project$OneClick$View$PaymentErrorView$paymentMethodToDescription = function (_v0) {
	var paymentMethod = _v0.oS;
	switch (paymentMethod) {
		case 2:
			return 'il metodo di pagamento selezionato';
		case 0:
			return 'la carta di credito inserita';
		default:
			return 'il tuo conto PayPal';
	}
};
var $author$project$OneClick$View$PaymentErrorView$cardErrorMessage = function (model) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Non è stato possibile effettuare il pagamento con ' + ($author$project$OneClick$View$PaymentErrorView$paymentMethodToDescription(model) + '. Nessun importo è stato prelevato dal tuo conto.'))
				])),
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Ti consigliamo di verificare con l\'emittente se la tua carta è abilitata ai pagamenti online e riprovare con il pagamento, oppure provare con un\'altra carta o altro metodo.')
				]))
		]);
};
var $author$project$OneClick$View$PaymentErrorView$bankCouldHaveSentSMS = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('La banca potrebbe averti inviato un sms di verifica della disponibilità dell\'importo, ma ti confermiamo che non abbiamo effettuato alcun prelievo dal tuo conto corrente.')
		]));
var $author$project$OneClick$View$PaymentErrorView$coundNotPay = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				'Non è stato possibile effettuare il pagamento con ' + ($author$project$OneClick$View$PaymentErrorView$paymentMethodToDescription(model) + '.'))
			]));
};
var $author$project$OneClick$View$PaymentErrorView$pleaseCheckInfos = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('Ti consigliamo di ricontrollare le informazioni inserite e riprovare il pagamento.')
		]));
var $author$project$OneClick$View$PaymentErrorView$defaultErrorMessage = function (model) {
	return _List_fromArray(
		[
			$author$project$OneClick$View$PaymentErrorView$coundNotPay(model),
			$author$project$OneClick$View$PaymentErrorView$bankCouldHaveSentSMS,
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			$author$project$OneClick$View$PaymentErrorView$pleaseCheckInfos,
			A2(
			$author$project$PrimaElm$Lib$Utils$Render$renderIf,
			!model.oS,
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
					])))
		]);
};
var $author$project$OneClick$View$PaymentErrorView$payPalServerSideErrorMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('')
			]))
	]);
var $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage = function (model) {
	return _List_fromArray(
		[
			$author$project$OneClick$View$PaymentErrorView$coundNotPay(model),
			$author$project$OneClick$View$PaymentErrorView$bankCouldHaveSentSMS,
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Ti invitiamo a riprovare con un\'altra carta.')
				]))
		]);
};
var $author$project$OneClick$View$PaymentErrorView$temporaryErrorMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Si è verificato un errore nella transazione.')
			])),
		$author$project$OneClick$View$PaymentErrorView$bankCouldHaveSentSMS,
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti invitiamo a riprovare o ad utilizzare un altro metodo di pagamento.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
			]))
	]);
var $author$project$OneClick$View$PaymentErrorView$paymentFailedErrorMessage = F2(
	function (model, failure) {
		var _v0 = failure.hK;
		switch (_v0) {
			case 0:
				return $author$project$OneClick$View$PaymentErrorView$temporaryErrorMessage;
			case 1:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 2:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 3:
				return $author$project$OneClick$View$PaymentErrorView$temporaryErrorMessage;
			case 4:
				return $author$project$OneClick$View$PaymentErrorView$temporaryErrorMessage;
			case 5:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 6:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 7:
				return $author$project$OneClick$View$PaymentErrorView$temporaryErrorMessage;
			case 8:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 9:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			case 10:
				return $author$project$OneClick$View$PaymentErrorView$failedTransactionMessage(model);
			default:
				return $author$project$OneClick$View$PaymentErrorView$defaultErrorMessage(model);
		}
	});
var $author$project$OneClick$View$PaymentErrorView$threeDSecureMessageText = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Il processo di verifica di sicurezza non è andato a buon fine.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti confermiamo che non è stato effettuato alcun prelievo dalla tua carta.')
			])),
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti consigliamo di riprovare il pagamento facendo particolare attenzione alle indicazioni fornite dalla tua banca durante l\'operazione.')
			]))
	]);
var $author$project$OneClick$View$PaymentErrorView$verificationMessage = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Il processo di verifica non è andato a buon fine.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti confermiamo che non è stato effettuato alcun prelievo dalla tua carta.')
			])),
		A2($elm$html$Html$br, _List_Nil, _List_Nil),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Ti consigliamo di ricontrollare le informazioni inserite e riprovare il pagamento.')
			])),
		A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text('Se il problema persiste ti invitiamo a riprovare il pagamento con un\'altra carta.')
			]))
	]);
var $author$project$OneClick$View$PaymentErrorView$errorMessageText = function (model) {
	var messageText = function () {
		var _v0 = model.mp.b0;
		if (!_v0.$) {
			if (!_v0.a.$) {
				var errorEvent = _v0.a.a;
				if (A2($elm$core$String$startsWith, 'THREEDS_', errorEvent.mH)) {
					return $author$project$OneClick$View$PaymentErrorView$threeDSecureMessageText;
				} else {
					var _v1 = errorEvent.b0;
					if (_v1 === 'Unsupported card type') {
						return $author$project$OneClick$View$PaymentErrorView$cardErrorMessage(model);
					} else {
						return $author$project$OneClick$View$PaymentErrorView$verificationMessage;
					}
				}
			} else {
				var serverErrorEvent = _v0.a.a;
				if (model.oS === 1) {
					return $author$project$OneClick$View$PaymentErrorView$payPalServerSideErrorMessage;
				} else {
					if (!serverErrorEvent.$) {
						return $author$project$OneClick$View$PaymentErrorView$cardErrorMessage(model);
					} else {
						var details = serverErrorEvent.a;
						if (!details.$) {
							return $author$project$OneClick$View$PaymentErrorView$defaultErrorMessage(model);
						} else {
							var failure = details.a;
							return A2($author$project$OneClick$View$PaymentErrorView$paymentFailedErrorMessage, model, failure);
						}
					}
				}
			}
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fw-heavy')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Operazione non riuscita.')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fs-small')
					]),
				messageText)
			]));
};
var $author$project$OneClick$View$PaymentErrorView$isErrorIgnored = function (model) {
	var _v0 = model.mp.b0;
	if (!_v0.$) {
		if (!_v0.a.$) {
			var clientErrorEvent = _v0.a.a;
			var _v1 = clientErrorEvent.mH;
			switch (_v1) {
				case 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME':
					return true;
				case 'PAYPAL_POPUP_OPEN_FAILED':
					return true;
				case 'PAYPAL_POPUP_CLOSED':
					return true;
				default:
					return false;
			}
		} else {
			return false;
		}
	} else {
		return true;
	}
};
var $author$project$OneClick$View$PaymentErrorView$braintreeErrorMessage = function (model) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		$elm_community$maybe_extra$Maybe$Extra$isJust(model.mp.b0) && (!$author$project$OneClick$View$PaymentErrorView$isErrorIgnored(model)),
		$primait$pyxis_components$Prima$Pyxis$Message$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Message$withClass,
				'one-click__payment__error-message',
				$primait$pyxis_components$Prima$Pyxis$Message$error(
					_List_fromArray(
						[
							$author$project$OneClick$View$PaymentErrorView$errorMessageText(model)
						])))));
};
var $author$project$OneClick$Model$SelectPaymentMethod = function (a) {
	return {$: 7, a: a};
};
var $author$project$Payment$Model$paymentMethodToString = function (paymentMethod) {
	switch (paymentMethod) {
		case 0:
			return 'credit-card';
		case 1:
			return 'paypal';
		default:
			return 'preloaded';
	}
};
var $author$project$Payment$Model$Preloaded = 2;
var $author$project$Payment$Model$stringToPaymentMethod = function (paymentMethodSlug) {
	return _Utils_eq(
		paymentMethodSlug,
		$author$project$Payment$Model$paymentMethodToString(0)) ? $elm$core$Maybe$Just(0) : (_Utils_eq(
		paymentMethodSlug,
		$author$project$Payment$Model$paymentMethodToString(1)) ? $elm$core$Maybe$Just(1) : (_Utils_eq(
		paymentMethodSlug,
		$author$project$Payment$Model$paymentMethodToString(2)) ? $elm$core$Maybe$Just(2) : $elm$core$Maybe$Nothing));
};
var $author$project$OneClick$View$Payment$paymentMethodRadioConfig = function (radioChoice) {
	return A2(
		$primait$pyxis_components$Prima$Pyxis$Form$Radio$withId,
		radioChoice.p8,
		A3(
			$primait$pyxis_components$Prima$Pyxis$Form$Radio$radio,
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$paymentMethodToString, $elm$core$Maybe$Just),
			A2($elm$core$Basics$composeR, $author$project$Payment$Model$stringToPaymentMethod, $author$project$OneClick$Model$SelectPaymentMethod),
			_List_fromArray(
				[radioChoice])));
};
var $author$project$OneClick$View$Payment$paymentMethodLabelMapper = function (paymentMethod) {
	switch (paymentMethod) {
		case 0:
			return 'Carta di credito o prepagata';
		case 1:
			return 'PayPal';
		default:
			return 'I tuoi metodi predefiniti';
	}
};
var $author$project$OneClick$View$Payment$radioChoiceMapper = function (paymentMethod) {
	switch (paymentMethod) {
		case 0:
			return {
				n6: $author$project$OneClick$View$Payment$paymentMethodLabelMapper(0),
				p8: $author$project$Payment$Model$paymentMethodToString(0)
			};
		case 1:
			return {
				n6: $author$project$OneClick$View$Payment$paymentMethodLabelMapper(1),
				p8: $author$project$Payment$Model$paymentMethodToString(1)
			};
		default:
			return {
				n6: $author$project$OneClick$View$Payment$paymentMethodLabelMapper(2),
				p8: $author$project$Payment$Model$paymentMethodToString(2)
			};
	}
};
var $author$project$OneClick$View$Payment$paymentMethodOptionDesktopView = F2(
	function (currentPaymentMethod, paymentMethodChoice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__option-desktop-text')
				]),
			A2(
				$primait$pyxis_components$Prima$Pyxis$Form$Radio$render,
				currentPaymentMethod,
				$author$project$OneClick$View$Payment$paymentMethodRadioConfig(
					$author$project$OneClick$View$Payment$radioChoiceMapper(paymentMethodChoice))));
	});
var $author$project$OneClick$View$Payment$paymentMethodOptionMobileView = F2(
	function (currentPaymentMethod, paymentMethodChoice) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__option-mobile'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'one-click__payment__option-mobile--selected',
							_Utils_eq(currentPaymentMethod, paymentMethodChoice))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$OneClick$Model$SelectPaymentMethod(
						$elm$core$Maybe$Just(paymentMethodChoice)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__option-mobile-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$OneClick$View$Payment$paymentMethodLabelMapper(paymentMethodChoice))
						]))
				]));
	});
var $author$project$OneClick$View$Payment$paymentMethodOptionsView = function (paymentMethod) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__options')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__options-mobile-wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__options-mobile')
							]),
						A2(
							$elm$core$List$map,
							$author$project$OneClick$View$Payment$paymentMethodOptionMobileView(paymentMethod),
							_List_fromArray(
								[0, 1])))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__options-desktop')
					]),
				A2(
					$elm$core$List$map,
					$author$project$OneClick$View$Payment$paymentMethodOptionDesktopView(paymentMethod),
					_List_fromArray(
						[0, 1])))
			]));
};
var $author$project$OneClick$Model$Pay = function (a) {
	return {$: 12, a: a};
};
var $author$project$OneClick$View$Payment$anyFieldInvalid = function (_v0) {
	var cardNumber = _v0.io;
	var expirationMonth = _v0.i7;
	var expirationYear = _v0.i8;
	var cvv = _v0.iJ;
	return !(cvv.jY && (expirationYear.jY && (expirationMonth.jY && cardNumber.jY)));
};
var $author$project$OneClick$View$Payment$actionButtonView = F2(
	function (appStatus, braintreeFields) {
		return (appStatus === 3) ? $primait$pyxis_components$Prima$Pyxis$Button$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Button$withClass,
				'one-click__payment__action-button',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					true,
					$primait$pyxis_components$Prima$Pyxis$Button$loading('Acquista')))) : $primait$pyxis_components$Prima$Pyxis$Button$render(
			A2(
				$primait$pyxis_components$Prima$Pyxis$Button$withClass,
				'one-click__payment__action-button',
				A2(
					$primait$pyxis_components$Prima$Pyxis$Button$withDisabled,
					$author$project$OneClick$View$Payment$anyFieldInvalid(braintreeFields),
					A2(
						$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
						$author$project$OneClick$Model$Pay(0),
						$primait$pyxis_components$Prima$Pyxis$Button$callOut('Acquista')))));
	});
var $author$project$OneClick$View$Payment$paymentPriceView = function (price) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__price')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__price-wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__price-currency')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('€')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__price-value')
							]),
						_List_fromArray(
							[
								A2(
								$elm$core$Basics$composeR,
								$prikhi$decimal$Decimal$toFloat,
								A2($elm$core$Basics$composeR, $author$project$Utils$priceDecimalsFormat, $elm$html$Html$text))(price)
							]))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__price-frequency')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('all\'anno')
					]))
			]));
};
var $author$project$OneClick$View$Payment$creditCardButtonSectionView = F2(
	function (_v0, price) {
		var appStatus = _v0.c0;
		var fields = _v0.jc;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-button-section one-click__payment__credit-card-section')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__credit-card-button-row')
						]),
					_List_fromArray(
						[
							$author$project$OneClick$View$Payment$paymentPriceView(price),
							A2($author$project$OneClick$View$Payment$actionButtonView, appStatus, fields)
						]))
				]));
	});
var $author$project$OneClick$View$Payment$acceptedPaymentSvg = function (svgName) {
	return A3(
		$author$project$View$Svg$svg,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__accepted-card-logo')
			]),
		'oneclick/',
		svgName);
};
var $author$project$OneClick$View$Payment$securePaymentSvg = A3(
	$author$project$View$Svg$svg,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__secure-payment-img')
		]),
	'pagamenti/',
	'pagamento-sicuro');
var $author$project$OneClick$View$Payment$creditCardIconsSectionView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__credit-card-icons one-click__payment__credit-card-section')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__secure-payment-img-wrapper')
				]),
			_List_fromArray(
				[$author$project$OneClick$View$Payment$securePaymentSvg])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__accepted-cards')
				]),
			A2(
				$elm$core$List$map,
				$author$project$OneClick$View$Payment$acceptedPaymentSvg,
				_List_fromArray(
					['visa', 'mastercard', 'maestrocard', 'postepay'])))
		]));
var $author$project$OneClick$View$Payment$creditCardActionRowView = F2(
	function (brainTreeModel, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-action-row')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$creditCardIconsSectionView,
					A2($author$project$OneClick$View$Payment$creditCardButtonSectionView, brainTreeModel, price)
				]));
	});
var $author$project$OneClick$View$Payment$creditCardFieldLabelView = function (_v0) {
	var labelId = _v0.n7;
	var label = _v0.n6;
	var id = _v0.nM;
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$for(id),
				$elm$html$Html$Attributes$id(labelId),
				$elm$html$Html$Attributes$class('one-click__payment__credit-card-field-label')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$OneClick$View$Payment$creditCardInputFieldView = function (_v0) {
	var id = _v0.nM;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id(id),
				$elm$html$Html$Attributes$class('one-click__payment__credit-card-input-field')
			]),
		_List_Nil);
};
var $author$project$OneClick$View$Payment$maybeRenderError = F2(
	function (shouldRender, errorMessage) {
		var errorView = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-error')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(errorMessage)
				]));
		return A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, shouldRender, errorView);
	});
var $author$project$OneClick$View$Payment$cardNumberView = function (cardNumberField) {
	var id = cardNumberField.nM;
	var isValid = cardNumberField.jY;
	var isPristine = cardNumberField.jR;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('is-pristine', isPristine),
						_Utils_Tuple2('is-valid', isValid),
						_Utils_Tuple2('has-error', (!isPristine) && (!isValid))
					])),
				A2($elm$html$Html$Attributes$attribute, 'data-field', id),
				$elm$html$Html$Attributes$class('one-click__payment__credit-card-number')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$creditCardFieldLabelView(cardNumberField),
				$author$project$OneClick$View$Payment$creditCardInputFieldView(cardNumberField),
				A2($author$project$OneClick$View$Payment$maybeRenderError, (!isValid) && (!isPristine), 'Controlla il numero di carta')
			]));
};
var $author$project$OneClick$View$Payment$creditCardNumberSectionView = function (cardNumberField) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__credit-card-section')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$cardNumberView(cardNumberField)
			]));
};
var $author$project$OneClick$View$Payment$cvvTooltipText = 'Il CVV è il codice segreto a 3 cifre che puoi trovare sul retro della tua carta.';
var $author$project$OneClick$View$Payment$cvvTooltipMobileView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__cvv-tooltip one-click__payment__cvv-tooltip-mobile tooltip')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text($author$project$OneClick$View$Payment$cvvTooltipText)
		]));
var $author$project$OneClick$View$Payment$cvvFieldWrapperView = function (cvvField) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__cvv-field-wrapper')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$creditCardInputFieldView(cvvField)
			]));
};
var $author$project$OneClick$View$Payment$cvvIconView = function (isCvvTooltipVisible) {
	return A2(
		$elm$html$Html$i,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__cvv-icon icon-help'),
				$elm$html$Html$Events$onClick(
				$author$project$OneClick$Model$ToggleCvvTooltip(!isCvvTooltipVisible))
			]),
		_List_Nil);
};
var $author$project$OneClick$View$Payment$cvvTooltipView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__cvv-tooltip tooltip tooltip--bottom')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text($author$project$OneClick$View$Payment$cvvTooltipText)
		]));
var $author$project$OneClick$View$Payment$cvvIconWrapperView = function (isCvvTooltipVisible) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__cvv-icon-wrapper')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$cvvIconView(isCvvTooltipVisible),
				A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, isCvvTooltipVisible, $author$project$OneClick$View$Payment$cvvTooltipView)
			]));
};
var $author$project$OneClick$View$Payment$cvvFieldRowView = F2(
	function (cvvField, isCvvTooltipVisible) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__cvv-field-row')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$cvvFieldWrapperView(cvvField),
					$author$project$OneClick$View$Payment$cvvIconWrapperView(isCvvTooltipVisible)
				]));
	});
var $author$project$OneClick$View$Payment$cvvView = F2(
	function (cvvField, isCvvTooltipVisible) {
		var id = cvvField.nM;
		var isValid = cvvField.jY;
		var isPristine = cvvField.jR;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('cvv-wrapper', true),
							_Utils_Tuple2('is-pristine', isPristine),
							_Utils_Tuple2('is-valid', isValid)
						])),
					A2($elm$html$Html$Attributes$attribute, 'data-field', id),
					$elm$html$Html$Attributes$class('one-click__payment__cvv')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$creditCardFieldLabelView(cvvField),
					A2($author$project$OneClick$View$Payment$cvvFieldRowView, cvvField, isCvvTooltipVisible),
					A2($author$project$OneClick$View$Payment$maybeRenderError, (!isValid) && (!isPristine), 'Controlla il cvv')
				]));
	});
var $author$project$OneClick$View$Payment$expirationDateFieldView = F2(
	function (field, className) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('is-pristine', field.jR),
							_Utils_Tuple2('is-valid', field.jY)
						])),
					A2($elm$html$Html$Attributes$attribute, 'data-field', field.nM),
					$elm$html$Html$Attributes$class(className)
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$creditCardInputFieldView(field)
				]));
	});
var $author$project$OneClick$View$Payment$expirationDateFieldsWrapperView = F2(
	function (expirationMonthField, expirationYearField) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-date-fields')
				]),
			_List_fromArray(
				[
					A2($author$project$OneClick$View$Payment$expirationDateFieldView, expirationMonthField, 'one-click__payment__credit-card-expiration-month'),
					A2($author$project$OneClick$View$Payment$expirationDateFieldView, expirationYearField, 'one-click__payment__credit-card-expiration-year')
				]));
	});
var $author$project$OneClick$View$Payment$expirationDateLabelView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__credit-card-field-label')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Data di scadenza')
		]));
var $author$project$OneClick$View$Payment$expirationDateView = F2(
	function (expirationMonthField, expirationYearField) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-expiration-date')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$expirationDateLabelView,
					A2($author$project$OneClick$View$Payment$expirationDateFieldsWrapperView, expirationMonthField, expirationYearField),
					A2($author$project$OneClick$View$Payment$maybeRenderError, ((!expirationMonthField.jY) && (!expirationMonthField.jR)) || ((!expirationYearField.jY) && (!expirationYearField.jR)), 'Controlla la data di scadenza')
				]));
	});
var $author$project$OneClick$View$Payment$expirationDateSectionView = F4(
	function (expirationMonth, expirationYear, cvv, isCvvTooltipVisible) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-date-section one-click__payment__credit-card-section')
				]),
			_List_fromArray(
				[
					A2($author$project$OneClick$View$Payment$expirationDateView, expirationMonth, expirationYear),
					A2($author$project$OneClick$View$Payment$cvvView, cvv, isCvvTooltipVisible)
				]));
	});
var $author$project$OneClick$View$Payment$creditCardFieldsView = F2(
	function (_v0, isCvvTooltipVisible) {
		var cardNumber = _v0.io;
		var expirationMonth = _v0.i7;
		var expirationYear = _v0.i8;
		var cvv = _v0.iJ;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__credit-card-fields'),
					$elm$html$Html$Attributes$id('braintree-form')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$creditCardNumberSectionView(cardNumber),
					A4($author$project$OneClick$View$Payment$expirationDateSectionView, expirationMonth, expirationYear, cvv, isCvvTooltipVisible),
					A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, isCvvTooltipVisible, $author$project$OneClick$View$Payment$cvvTooltipMobileView)
				]));
	});
var $author$project$OneClick$View$Payment$creditCardSelectedMethodView = F4(
	function (btModel, paymentMethod, isCvvTooltipVisible, price) {
		var fields = btModel.jc;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__selected-method-content'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('one-click__payment__selected-method-content--visible', !paymentMethod)
						]))
				]),
			_List_fromArray(
				[
					A2($author$project$OneClick$View$Payment$creditCardFieldsView, fields, isCvvTooltipVisible),
					A2($author$project$OneClick$View$Payment$creditCardActionRowView, btModel, price)
				]));
	});
var $author$project$OneClick$View$Payment$payPalSvg = A3(
	$author$project$View$Svg$svg,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__paypal-logo')
		]),
	'oneclick/',
	'paypal-negative');
var $author$project$OneClick$View$Payment$paypalButtonView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__paypal-button'),
			$elm$html$Html$Events$onClick(
			$author$project$OneClick$Model$Pay(1))
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Paga con'),
			$author$project$OneClick$View$Payment$payPalSvg
		]));
var $author$project$OneClick$View$Payment$paypalActionRowView = function (price) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__paypal-action-row')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$paymentPriceView(price),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__paypal-button-wrapper')
					]),
				_List_fromArray(
					[
						$author$project$OneClick$View$Payment$paypalButtonView,
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__paypal-button-message')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Il modo più rapido e sicuro per pagare')
							]))
					]))
			]));
};
var $author$project$OneClick$View$Payment$paypalMessageRowView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('one-click__payment__paypal-message-row')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__paypal-title')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__paypal-title-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Paga in totale sicurezza con il tuo account PayPal.')
						]))
				])),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__paypal-subtitle')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__paypal-subtitle-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Inserisci i dati del tuo profilo e scegli una delle carte di credito registrate sul tuo conto.')
						]))
				]))
		]));
var $author$project$OneClick$View$Payment$paypalSelectedMethodView = F2(
	function (price, paymentMethod) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__selected-method-content'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('one-click__payment__selected-method-content--visible', paymentMethod === 1)
						]))
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$paypalMessageRowView,
					$author$project$OneClick$View$Payment$paypalActionRowView(price)
				]));
	});
var $author$project$OneClick$View$Payment$preloadedSelectedMethodView = function (paymentMethod) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__selected-method-content'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('one-click__payment__selected-method-content--visible', paymentMethod === 2)
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('')
			]));
};
var $author$project$OneClick$View$Payment$paymentSelectedMethodView = F4(
	function (btModel, paymentMethod, isCvvTooltipVisible, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__selected-method')
				]),
			_List_fromArray(
				[
					A4($author$project$OneClick$View$Payment$creditCardSelectedMethodView, btModel, paymentMethod, isCvvTooltipVisible, price),
					A2($author$project$OneClick$View$Payment$paypalSelectedMethodView, price, paymentMethod),
					$author$project$OneClick$View$Payment$preloadedSelectedMethodView(paymentMethod)
				]));
	});
var $author$project$OneClick$View$Payment$paymentMethodContainerView = F4(
	function (btModel, paymentMethod, isCvvTooltipVisible, price) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__container')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$paymentMethodOptionsView(paymentMethod),
					A4($author$project$OneClick$View$Payment$paymentSelectedMethodView, btModel, paymentMethod, isCvvTooltipVisible, price)
				]));
	});
var $author$project$OneClick$View$Payment$effectiveDateTooltipText = 'La modifica della decorrenza sarà presto disponibile e potrai personalizzare ancora di più la tua copertura.';
var $author$project$OneClick$View$Payment$effectiveDateTooltipMobileView = function (effectiveDateTooltipState) {
	if (!effectiveDateTooltipState.$) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__effective-date-tooltip-mobile tooltip')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text($author$project$OneClick$View$Payment$effectiveDateTooltipText)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$OneClick$View$Payment$effectiveDateTooltipVisibleView = function (effectiveDateTooltipPosition) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__effective-date-tooltip tooltip'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('tooltip--top', !effectiveDateTooltipPosition),
						_Utils_Tuple2('tooltip--bottom', effectiveDateTooltipPosition === 1)
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text($author$project$OneClick$View$Payment$effectiveDateTooltipText)
			]));
};
var $author$project$OneClick$View$Payment$effectiveDateTooltipView = function (tooltipState) {
	if (!tooltipState.$) {
		var effectiveDateTooltipPosition = tooltipState.a;
		return $author$project$OneClick$View$Payment$effectiveDateTooltipVisibleView(effectiveDateTooltipPosition);
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$OneClick$View$Payment$quotationDataView = F3(
	function (effectiveDate, tooltipState, _v0) {
		var firstName = _v0.nu;
		var lastName = _v0.n8;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__quotation-data')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__quotation-name-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__quotation-icon-wrapper')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('one-click__payment__quotation-icon icon-people icon')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__quotation-name-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(firstName + (' ' + lastName))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__effective-date-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__quotation-icon icon-date icon')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__effective-date-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Utils$Time$formatDate(effectiveDate) + ' alle 24:00')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id('one-click__payment__effective-date-action-wrapper'),
									$elm$html$Html$Attributes$class('one-click__payment__effective-date-action-wrapper')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('one-click__payment__effective-date-action'),
											$elm$html$Html$Events$onClick(
											$author$project$OneClick$Model$ToggleEffectiveDateTooltip(
												_Utils_eq(tooltipState, $author$project$OneClick$Model$Hidden)))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('modifica decorrenza')
										])),
									$author$project$OneClick$View$Payment$effectiveDateTooltipView(tooltipState)
								]))
						]))
				]));
	});
var $author$project$OneClick$View$Payment$quotationLimitSvg = function (svgName) {
	return A3(
		$author$project$View$Svg$svg,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__quotation-limits-icon')
			]),
		'oneclick/',
		svgName);
};
var $author$project$OneClick$View$Payment$quotationLimitsView = F2(
	function (limit, deductible) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__quotation-limits')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__quotation-limits-row')
						]),
					_List_fromArray(
						[
							$author$project$OneClick$View$Payment$quotationLimitSvg('franchise'),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__quotation-limits-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Franchigia ' + deductible)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('one-click__payment__quotation-limits-row')
						]),
					_List_fromArray(
						[
							$author$project$OneClick$View$Payment$quotationLimitSvg('maximal'),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('one-click__payment__quotation-limits-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Massimale ' + limit)
								]))
						]))
				]));
	});
var $author$project$OneClick$View$Payment$quotationTitleView = function (saveCode) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__quotation-title')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__quotation-title-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__quotation-title-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Preventivo RC Famiglia')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('badge badge--primary')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(saveCode)
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__data-summary-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__data-summary-cta'),
								$elm$html$Html$Events$onClick(
								$author$project$OneClick$Model$ToggleSummary(true))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('one-click__payment__data-summary-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Vedi riepilogo dati')
									])),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('one-click__payment__data-summary-icon icon-caret-right')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$OneClick$View$Payment$quotationContainerView = F2(
	function (_v0, tooltipState) {
		var saveCode = _v0.lg;
		var effectiveDate = _v0.m8;
		var contractor = _v0.mT;
		var limit = _v0.T;
		var deductible = _v0.m1;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__container')
				]),
			_List_fromArray(
				[
					$author$project$OneClick$View$Payment$quotationTitleView(saveCode),
					A3($author$project$OneClick$View$Payment$quotationDataView, effectiveDate, tooltipState, contractor),
					$author$project$OneClick$View$Payment$effectiveDateTooltipMobileView(tooltipState),
					A2($author$project$OneClick$View$Payment$quotationLimitsView, limit, deductible)
				]));
	});
var $author$project$OneClick$View$Payment$paymentDataView = F5(
	function (btModel, offer, paymentMethod, effectiveDateTooltipState, isCvvTooltipVisible) {
		var price = offer.oY;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('one-click__payment__data')
				]),
			_List_fromArray(
				[
					A2($author$project$OneClick$View$Payment$quotationContainerView, offer, effectiveDateTooltipState),
					A4($author$project$OneClick$View$Payment$paymentMethodContainerView, btModel, paymentMethod, isCvvTooltipVisible, price)
				]));
	});
var $author$project$OneClick$View$Payment$paymentDataBoxView = function (_v0) {
	var isCvvTooltipVisible = _v0.jJ;
	var effectiveDateTooltipState = _v0.iZ;
	var brainTreeModel = _v0.mp;
	var offer = _v0.oB;
	var paymentMethod = _v0.oS;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__data-box')
			]),
		_List_fromArray(
			[
				A5($author$project$OneClick$View$Payment$paymentDataView, brainTreeModel, offer, paymentMethod, effectiveDateTooltipState, isCvvTooltipVisible),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__data-message-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__data-message-bold')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Tieni a portata di mano il tuo smartphone:')
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('one-click__payment__data-message')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('il tuo istituto di credito potrebbe chiedere una verifica per completare il pagamento.')
							]))
					]))
			]));
};
var $author$project$OneClick$View$Payment$purchaseSectionHeaderView = function (_v0) {
	var details = _v0.m4;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__payment__title-box')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(details.o8.eE)
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('one-click__payment__subtitle')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(details.o8.eu)
					]))
			]));
};
var $author$project$OneClick$View$Payment$view = function (modelState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('one-click__payment'),
				$elm$html$Html$Attributes$class('one-click__payment')
			]),
		_List_fromArray(
			[
				$author$project$OneClick$View$Payment$purchaseSectionHeaderView(modelState.oB),
				$author$project$OneClick$View$PaymentErrorView$braintreeErrorMessage(modelState),
				$author$project$OneClick$View$Payment$paymentDataBoxView(modelState)
			]));
};
var $author$project$OneClick$View$offerView = function (modelState) {
	var offer = modelState.oB;
	var isSummaryOpen = modelState.jW;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('one-click__main')
			]),
		_List_fromArray(
			[
				A2($author$project$OneClick$View$Hero$view, offer.m4, offer.oY),
				$author$project$OneClick$View$Infobox$conditionalView(offer.m4),
				$author$project$OneClick$View$Jumbotron$view(offer.m4),
				$author$project$OneClick$View$OfferCoverage$view(offer.m4),
				$author$project$OneClick$View$Payment$view(modelState),
				$author$project$OneClick$View$checkoutLoader(modelState),
				A2($author$project$OneClick$View$quoteInfoModalView, isSummaryOpen, offer),
				$author$project$OneClick$View$infoboxModal(modelState)
			]));
};
var $author$project$OneClick$View$mainContentView = function (_v0) {
	var state = _v0.cG;
	switch (state.$) {
		case 0:
			return $author$project$OneClick$View$loadingView;
		case 1:
			return $author$project$OneClick$View$errorsView;
		case 2:
			return $author$project$OneClick$View$loadingView;
		default:
			var modelState = state.a;
			return $author$project$OneClick$View$offerView(modelState);
	}
};
var $author$project$OneClick$View$view = function (model) {
	var flags = model.nv;
	return $author$project$View$Template$render(
		A2(
			$author$project$View$Template$withLogoUrl,
			$author$project$Types$pickPrimaUrl(flags),
			A2(
				$author$project$View$Template$withMainContent,
				$author$project$OneClick$View$mainContentView(model),
				A4(
					$author$project$View$Template$withTopBarContent,
					false,
					_List_fromArray(
						[
							$author$project$OneClick$View$backgroundContentView(model)
						]),
					'one-click__top-bar-separator',
					A2($author$project$View$Template$withClasses, 'one-click', $author$project$View$Template$template)))));
};
var $author$project$Pyxis$Components$Form$Fieldset$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$Fieldset$L = 2;
var $author$project$Pyxis$Components$Form$Fieldset$config = {eQ: _List_Nil, c5: _List_Nil, mQ: _List_Nil, ds: _List_Nil, dB: _List_Nil, nM: $elm$core$Maybe$Nothing, dO: false, aR: $elm$core$Maybe$Nothing, cg: 2};
var $author$project$Pyxis$Components$Form$Legend$Center = 0;
var $author$project$Pyxis$Components$Form$Legend$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$Legend$config = {h6: $elm$core$Maybe$Nothing, c_: 0, c5: _List_Nil, m2: $elm$core$Maybe$Nothing, nM: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Form$Legend$Left = 1;
var $author$project$Pyxis$Components$Form$Legend$isAlignedLeft = $elm$core$Basics$eq(1);
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Pyxis$Commons$Attributes$none = A2($elm$html$Html$Attributes$property, 'none@pyxis-elm', $elm$json$Json$Encode$null);
var $author$project$Pyxis$Commons$Attributes$maybe = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(f),
		$elm$core$Maybe$withDefault($author$project$Pyxis$Commons$Attributes$none));
};
var $author$project$Pyxis$Components$Icon$Boxed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Icon$Brand = 1;
var $author$project$Pyxis$Components$Icon$brand = $author$project$Pyxis$Components$Icon$Boxed(1);
var $author$project$Pyxis$Components$Icon$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Icon$Default = {$: 0};
var $author$project$Pyxis$Components$Icon$M = 1;
var $author$project$Pyxis$Commons$Properties$Theme$Default = 0;
var $author$project$Pyxis$Commons$Properties$Theme$default = 0;
var $author$project$Pyxis$Components$Icon$config = function (icon) {
	return {eV: $elm$core$Maybe$Nothing, eX: $elm$core$Maybe$Nothing, c5: _List_Nil, dE: icon, aH: 1, hP: $author$project$Pyxis$Components$Icon$Default, eB: $author$project$Pyxis$Commons$Properties$Theme$default};
};
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $author$project$Pyxis$Commons$String$fromBool = function (bool) {
	return bool ? 'true' : 'false';
};
var $author$project$Pyxis$Commons$Attributes$ariaHidden = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-hidden'));
var $author$project$Pyxis$Commons$Attributes$ariaLabel = $elm$html$Html$Attributes$attribute('aria-label');
var $author$project$Pyxis$Commons$Attributes$ariaLabelledBy = $elm$html$Html$Attributes$attribute('aria-labelledby');
var $author$project$Pyxis$Components$Icon$getSizeClass = function (size) {
	switch (size) {
		case 2:
			return 'icon--size-l';
		case 1:
			return 'icon--size-m';
		default:
			return 'icon--size-s';
	}
};
var $author$project$Pyxis$Components$Icon$getVariantClass = function (variant) {
	switch (variant) {
		case 0:
			return 'icon--boxed';
		case 1:
			return 'icon--boxed icon--brand';
		case 2:
			return 'icon--boxed icon--success';
		case 3:
			return 'icon--boxed icon--alert';
		default:
			return 'icon--boxed icon--error';
	}
};
var $author$project$Pyxis$Commons$Properties$Theme$Alternative = 1;
var $author$project$Pyxis$Commons$Properties$Theme$isAlternative = $elm$core$Basics$eq(1);
var $author$project$Pyxis$Components$Icon$getStyleClass = F2(
	function (style, theme) {
		var _v0 = _Utils_Tuple2(
			style,
			$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme));
		if (!_v0.a.$) {
			if (_v0.b) {
				var _v1 = _v0.a;
				return 'icon--boxed icon--alt';
			} else {
				var _v2 = _v0.a;
				return '';
			}
		} else {
			if (_v0.b) {
				var variant = _v0.a.a;
				return 'icon--alt ' + $author$project$Pyxis$Components$Icon$getVariantClass(variant);
			} else {
				var variant = _v0.a.a;
				return $author$project$Pyxis$Components$Icon$getVariantClass(variant);
			}
		}
	});
var $author$project$Pyxis$Components$Icon$getClassList = function (_v0) {
	var style = _v0.hP;
	var theme = _v0.eB;
	var size = _v0.aH;
	var classList = _v0.c5;
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Tuple$first,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not)),
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('icon', true),
					_Utils_Tuple2(
					$author$project$Pyxis$Components$Icon$getSizeClass(size),
					true),
					_Utils_Tuple2(
					A2($author$project$Pyxis$Components$Icon$getStyleClass, style, theme),
					true)
				]),
			classList));
};
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$virtual_dom$VirtualDom$attributeNS = F3(
	function (namespace, key, value) {
		return A3(
			_VirtualDom_attributeNS,
			namespace,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $Garados007$elm_svg_parser$SvgParser$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$core$String$startsWith, 'xlink:', name) ? A3($elm$virtual_dom$VirtualDom$attributeNS, 'http://www.w3.org/1999/xlink', name, value) : (A2($elm$core$String$startsWith, 'xml:', name) ? A3($elm$virtual_dom$VirtualDom$attributeNS, 'http://www.w3.org/XML/1998/namespace', name, value) : A2($elm$virtual_dom$VirtualDom$attribute, name, value));
};
var $Garados007$elm_svg_parser$SvgParser$elementToSvg = function (element) {
	return A3(
		$elm$svg$Svg$node,
		element.oq,
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.eY),
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.ac));
};
var $Garados007$elm_svg_parser$SvgParser$nodeToSvg = function (svgNode) {
	switch (svgNode.$) {
		case 0:
			var element = svgNode.a;
			return $Garados007$elm_svg_parser$SvgParser$elementToSvg(element);
		case 1:
			var content = svgNode.a;
			return $elm$svg$Svg$text(content);
		default:
			var content = svgNode.a;
			return $elm$svg$Svg$text('');
	}
};
var $andre_dietrich$parser_combinators$Combine$Parser = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$app = function (_v0) {
	var inner = _v0;
	return inner;
};
var $andre_dietrich$parser_combinators$Combine$andThen = F2(
	function (f, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return A3(
						$andre_dietrich$parser_combinators$Combine$app,
						f(res),
						rstate,
						rstream);
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(ms));
				}
			});
	});
var $pilatch$flip$Flip$flip = F3(
	function (_function, argB, argA) {
		return A2(_function, argA, argB);
	});
var $andre_dietrich$parser_combinators$Combine$bimap = F3(
	function (fok, ferr, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return _Utils_Tuple3(
						rstate,
						rstream,
						$elm$core$Result$Ok(
							fok(res)));
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(
							ferr(ms)));
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$map = F2(
	function (f, p) {
		return A3($andre_dietrich$parser_combinators$Combine$bimap, f, $elm$core$Basics$identity, p);
	});
var $andre_dietrich$parser_combinators$Combine$andMap = F2(
	function (rp, lp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andThen,
			A2($pilatch$flip$Flip$flip, $andre_dietrich$parser_combinators$Combine$map, rp),
			lp);
	});
var $Garados007$elm_svg_parser$SvgParser$flip = F3(
	function (func, b, a) {
		return A2(func, a, b);
	});
var $Garados007$elm_svg_parser$SvgParser$andMapRight = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
				lp));
	});
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $andre_dietrich$parser_combinators$Combine$regexer = F5(
	function (input, output, pat, state, stream) {
		var pattern = A2($elm$core$String$startsWith, '^', pat) ? pat : ('^' + pat);
		var _v0 = A3(
			$elm$regex$Regex$findAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				input(pattern)),
			stream.b7);
		if (_v0.b && (!_v0.b.b)) {
			var match = _v0.a;
			var len = $elm$core$String$length(match.ci);
			var pos = stream.ee + len;
			var rem = A2($elm$core$String$dropLeft, len, stream.b7);
			return _Utils_Tuple3(
				state,
				_Utils_update(
					stream,
					{b7: rem, ee: pos}),
				$elm$core$Result$Ok(
					output(match)));
		} else {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Err(
					_List_fromArray(
						['expected input matching Regexp /' + (pattern + '/')])));
		}
	});
var $andre_dietrich$parser_combinators$Combine$regex = A2(
	$elm$core$Basics$composeR,
	A2(
		$andre_dietrich$parser_combinators$Combine$regexer,
		$elm$regex$Regex$fromString,
		function ($) {
			return $.ci;
		}),
	$elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$mapError = $andre_dietrich$parser_combinators$Combine$bimap($elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$onerror = F2(
	function (m, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$mapError,
			$elm$core$Basics$always(
				_List_fromArray(
					[m])),
			p);
	});
var $andre_dietrich$parser_combinators$Combine$whitespace = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'optional whitespace',
	$andre_dietrich$parser_combinators$Combine$regex('\\s*'));
var $Garados007$elm_svg_parser$SvgParser$doctypeDeclarationParser = A2(
	$Garados007$elm_svg_parser$SvgParser$andMapRight,
	$andre_dietrich$parser_combinators$Combine$whitespace,
	$andre_dietrich$parser_combinators$Combine$regex('<!DOCTYPE(?:\"(?:\\\\.|[^\"])*\"|\'(?:\\\\.|[^\'])*\'|[^>])*>'));
var $andre_dietrich$parser_combinators$Combine$many = function (p) {
	var accumulate = F3(
		function (acc, state, stream) {
			accumulate:
			while (true) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					if (_Utils_eq(stream, rstream)) {
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$List$reverse(acc));
					} else {
						var $temp$acc = A2($elm$core$List$cons, res, acc),
							$temp$state = rstate,
							$temp$stream = rstream;
						acc = $temp$acc;
						state = $temp$state;
						stream = $temp$stream;
						continue accumulate;
					}
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$List$reverse(acc));
				}
			}
		});
	return F2(
		function (state, stream) {
			var _v1 = A3(accumulate, _List_Nil, state, stream);
			var rstate = _v1.a;
			var rstream = _v1.b;
			var res = _v1.c;
			return _Utils_Tuple3(
				rstate,
				rstream,
				$elm$core$Result$Ok(res));
		});
};
var $Garados007$elm_svg_parser$SvgParser$SvgElement = function (a) {
	return {$: 0, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$andMapLeft = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$Basics$always, lp));
	});
var $andre_dietrich$parser_combinators$Combine$emptyErr = F2(
	function (state, stream) {
		return _Utils_Tuple3(
			state,
			stream,
			$elm$core$Result$Err(_List_Nil));
	});
var $andre_dietrich$parser_combinators$Combine$or = F2(
	function (lp, rp) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, lp, state, stream);
				if (!_v0.c.$) {
					var res = _v0;
					return res;
				} else {
					var lms = _v0.c.a;
					var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, rp, state, stream);
					if (!_v1.c.$) {
						var res = _v1;
						return res;
					} else {
						var rms = _v1.c.a;
						return _Utils_Tuple3(
							state,
							stream,
							$elm$core$Result$Err(
								_Utils_ap(lms, rms)));
					}
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$choice = function (xs) {
	return A3($elm$core$List$foldr, $andre_dietrich$parser_combinators$Combine$or, $andre_dietrich$parser_combinators$Combine$emptyErr, xs);
};
var $Garados007$elm_svg_parser$SvgParser$SvgComment = function (a) {
	return {$: 2, a: a};
};
var $andre_dietrich$parser_combinators$Combine$primitive = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$Char$satisfy = function (pred) {
	return $andre_dietrich$parser_combinators$Combine$primitive(
		F2(
			function (state, stream) {
				var message = 'could not satisfy predicate';
				var _v0 = $elm$core$String$uncons(stream.b7);
				if (!_v0.$) {
					var _v1 = _v0.a;
					var h = _v1.a;
					var rest = _v1.b;
					return pred(h) ? _Utils_Tuple3(
						state,
						_Utils_update(
							stream,
							{b7: rest, ee: stream.ee + 1}),
						$elm$core$Result$Ok(h)) : _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				}
			}));
};
var $andre_dietrich$parser_combinators$Combine$Char$anyChar = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'expected any character',
	$andre_dietrich$parser_combinators$Combine$Char$satisfy(
		$elm$core$Basics$always(true)));
var $andre_dietrich$parser_combinators$Combine$succeed = function (res) {
	return F2(
		function (state, stream) {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Ok(res));
		});
};
var $andre_dietrich$parser_combinators$Combine$lazy = function (t) {
	return A2(
		$andre_dietrich$parser_combinators$Combine$andThen,
		t,
		$andre_dietrich$parser_combinators$Combine$succeed(0));
};
var $andre_dietrich$parser_combinators$Combine$manyTill = F2(
	function (p, end_) {
		var accumulate = F3(
			function (acc, state, stream) {
				accumulate:
				while (true) {
					var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, end_, state, stream);
					if (!_v0.c.$) {
						var rstate = _v0.a;
						var rstream = _v0.b;
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$Result$Ok(
								$elm$core$List$reverse(acc)));
					} else {
						var estate = _v0.a;
						var estream = _v0.b;
						var ms = _v0.c.a;
						var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
						if (!_v1.c.$) {
							var rstate = _v1.a;
							var rstream = _v1.b;
							var res = _v1.c.a;
							var $temp$acc = A2($elm$core$List$cons, res, acc),
								$temp$state = rstate,
								$temp$stream = rstream;
							acc = $temp$acc;
							state = $temp$state;
							stream = $temp$stream;
							continue accumulate;
						} else {
							return _Utils_Tuple3(
								estate,
								estream,
								$elm$core$Result$Err(ms));
						}
					}
				}
			});
		return accumulate(_List_Nil);
	});
var $andre_dietrich$parser_combinators$Combine$string = function (s) {
	return F2(
		function (state, stream) {
			if (A2($elm$core$String$startsWith, s, stream.b7)) {
				var len = $elm$core$String$length(s);
				var pos = stream.ee + len;
				var rem = A2($elm$core$String$dropLeft, len, stream.b7);
				return _Utils_Tuple3(
					state,
					_Utils_update(
						stream,
						{b7: rem, ee: pos}),
					$elm$core$Result$Ok(s));
			} else {
				return _Utils_Tuple3(
					state,
					stream,
					$elm$core$Result$Err(
						_List_fromArray(
							['expected \"' + (s + '\"')])));
			}
		});
};
var $Garados007$elm_svg_parser$SvgParser$commentParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			A2($elm$core$Basics$composeL, $Garados007$elm_svg_parser$SvgParser$SvgComment, $elm$core$String$fromList),
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					$andre_dietrich$parser_combinators$Combine$whitespace,
					$andre_dietrich$parser_combinators$Combine$string('<!--')),
				A2(
					$andre_dietrich$parser_combinators$Combine$manyTill,
					$andre_dietrich$parser_combinators$Combine$Char$anyChar,
					$andre_dietrich$parser_combinators$Combine$string('-->'))));
	});
var $Garados007$elm_svg_parser$SvgParser$Element = F3(
	function (name, attributes, children) {
		return {eY: attributes, ac: children, oq: name};
	});
var $andre_dietrich$parser_combinators$Combine$optional = F2(
	function (res, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			p,
			$andre_dietrich$parser_combinators$Combine$succeed(res));
	});
var $Garados007$elm_svg_parser$SvgParser$attributeParser = A2(
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$optional,
		'',
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$string('=\"'),
				$andre_dietrich$parser_combinators$Combine$regex('[^\"]*')),
			$andre_dietrich$parser_combinators$Combine$string('\"'))),
	A2(
		$andre_dietrich$parser_combinators$Combine$map,
		$elm$core$Tuple$pair,
		$andre_dietrich$parser_combinators$Combine$regex('[^=>/]+')));
var $andre_dietrich$parser_combinators$Combine$keep = F2(
	function (p1, p2) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			p1,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$pilatch$flip$Flip$flip($elm$core$Basics$always),
				p2));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy1 = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			$andre_dietrich$parser_combinators$Combine$many(
				A2($andre_dietrich$parser_combinators$Combine$keep, p, sep)),
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$List$cons, p));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			A2($andre_dietrich$parser_combinators$Combine$sepBy1, sep, p),
			$andre_dietrich$parser_combinators$Combine$succeed(_List_Nil));
	});
var $Garados007$elm_svg_parser$SvgParser$openingParser = A3(
	$Garados007$elm_svg_parser$SvgParser$flip,
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$andMap,
		$andre_dietrich$parser_combinators$Combine$regex('[^/>\\s]+'),
		A2(
			$andre_dietrich$parser_combinators$Combine$map,
			F3(
				function (_v0, tagName, attributes) {
					return A3($Garados007$elm_svg_parser$SvgParser$Element, tagName, attributes, _List_Nil);
				}),
			$andre_dietrich$parser_combinators$Combine$string('<'))),
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapLeft,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			A2($andre_dietrich$parser_combinators$Combine$sepBy, $andre_dietrich$parser_combinators$Combine$whitespace, $Garados007$elm_svg_parser$SvgParser$attributeParser)),
		$andre_dietrich$parser_combinators$Combine$whitespace));
var $Garados007$elm_svg_parser$SvgParser$SvgText = function (a) {
	return {$: 1, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$textParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			$Garados007$elm_svg_parser$SvgParser$SvgText,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$whitespace,
				$andre_dietrich$parser_combinators$Combine$regex('[^<]+')));
	});
var $Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser = function (element) {
	var childrenParser = A2(
		$andre_dietrich$parser_combinators$Combine$map,
		function (children) {
			return _Utils_update(
				element,
				{ac: children});
		},
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapLeft,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						$andre_dietrich$parser_combinators$Combine$whitespace,
						$andre_dietrich$parser_combinators$Combine$string('>')),
					$andre_dietrich$parser_combinators$Combine$many(
						$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser())),
				$andre_dietrich$parser_combinators$Combine$whitespace),
			$andre_dietrich$parser_combinators$Combine$string('</' + (element.oq + '>'))));
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v2) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						A2(
							$Garados007$elm_svg_parser$SvgParser$andMapRight,
							$andre_dietrich$parser_combinators$Combine$whitespace,
							$andre_dietrich$parser_combinators$Combine$string('/>')),
						$andre_dietrich$parser_combinators$Combine$succeed(element)),
						childrenParser
					]));
		});
};
function $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v1) {
			return A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$SvgElement,
				A2(
					$andre_dietrich$parser_combinators$Combine$andThen,
					$Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser,
					A2(
						$andre_dietrich$parser_combinators$Combine$andMap,
						$Garados007$elm_svg_parser$SvgParser$openingParser,
						A2(
							$andre_dietrich$parser_combinators$Combine$map,
							$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
							$andre_dietrich$parser_combinators$Combine$whitespace))));
		});
}
function $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v0) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						$Garados007$elm_svg_parser$SvgParser$textParser,
						$Garados007$elm_svg_parser$SvgParser$commentParser,
						$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser()
					]));
		});
}
var $Garados007$elm_svg_parser$SvgParser$elementParser = $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$elementParser;
};
var $Garados007$elm_svg_parser$SvgParser$nodeParser = $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$nodeParser;
};
var $andre_dietrich$parser_combinators$Combine$InputStream = F3(
	function (data, input, position) {
		return {C: data, b7: input, ee: position};
	});
var $andre_dietrich$parser_combinators$Combine$initStream = function (s) {
	return A3($andre_dietrich$parser_combinators$Combine$InputStream, s, s, 0);
};
var $andre_dietrich$parser_combinators$Combine$runParser = F3(
	function (p, st, s) {
		var _v0 = A3(
			$andre_dietrich$parser_combinators$Combine$app,
			p,
			st,
			$andre_dietrich$parser_combinators$Combine$initStream(s));
		if (!_v0.c.$) {
			var state = _v0.a;
			var stream = _v0.b;
			var res = _v0.c.a;
			return $elm$core$Result$Ok(
				_Utils_Tuple3(state, stream, res));
		} else {
			var state = _v0.a;
			var stream = _v0.b;
			var ms = _v0.c.a;
			return $elm$core$Result$Err(
				_Utils_Tuple3(state, stream, ms));
		}
	});
var $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser = A2(
	$andre_dietrich$parser_combinators$Combine$map,
	$elm$core$String$fromList,
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapRight,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			$andre_dietrich$parser_combinators$Combine$string('<?xml')),
		A2(
			$andre_dietrich$parser_combinators$Combine$manyTill,
			$andre_dietrich$parser_combinators$Combine$Char$anyChar,
			$andre_dietrich$parser_combinators$Combine$string('?>'))));
var $Garados007$elm_svg_parser$SvgParser$parseToNodes = function (input) {
	var _v0 = A3(
		$andre_dietrich$parser_combinators$Combine$runParser,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			A2($andre_dietrich$parser_combinators$Combine$optional, '', $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser),
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				A2($andre_dietrich$parser_combinators$Combine$optional, '', $Garados007$elm_svg_parser$SvgParser$doctypeDeclarationParser),
				$andre_dietrich$parser_combinators$Combine$many($Garados007$elm_svg_parser$SvgParser$nodeParser))),
		_List_Nil,
		input);
	if (!_v0.$) {
		var _v1 = _v0.a;
		var svgNodes = _v1.c;
		return $elm$core$Result$Ok(svgNodes);
	} else {
		var _v2 = _v0.a;
		var errors = _v2.c;
		return $elm$core$Result$Err(
			A2($elm$core$String$join, ' or ', errors));
	}
};
var $Garados007$elm_svg_parser$SvgParser$parse = function (input) {
	var toHtml = function (svgNodes) {
		toHtml:
		while (true) {
			if (svgNodes.b) {
				if (!svgNodes.a.$) {
					var element = svgNodes.a.a;
					var tl = svgNodes.b;
					if (element.oq === 'svg') {
						return $elm$core$Result$Ok(
							A2(
								$elm$svg$Svg$svg,
								A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.eY),
								A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.ac)));
					} else {
						var $temp$svgNodes = tl;
						svgNodes = $temp$svgNodes;
						continue toHtml;
					}
				} else {
					var tl = svgNodes.b;
					var $temp$svgNodes = tl;
					svgNodes = $temp$svgNodes;
					continue toHtml;
				}
			} else {
				return $elm$core$Result$Err('No svg found');
			}
		}
	};
	return A2(
		$elm$core$Result$andThen,
		toHtml,
		$Garados007$elm_svg_parser$SvgParser$parseToNodes(input));
};
var $author$project$Pyxis$Commons$Attributes$renderIf = F2(
	function (bool, attr) {
		return bool ? attr : $author$project$Pyxis$Commons$Attributes$none;
	});
var $author$project$Pyxis$Commons$Render$empty = $elm$html$Html$text('');
var $author$project$Pyxis$Commons$Render$renderMaybe = $elm$core$Maybe$withDefault($author$project$Pyxis$Commons$Render$empty);
var $author$project$Pyxis$Commons$Attributes$role = $elm$html$Html$Attributes$attribute('role');
var $author$project$Pyxis$Components$IconSet$toLabel = function (icon) {
	switch (icon) {
		case 0:
			return 'alarm';
		case 1:
			return 'android';
		case 2:
			return 'apple';
		case 3:
			return 'arrow-down';
		case 4:
			return 'arrow-left';
		case 5:
			return 'arrow-right';
		case 6:
			return 'arrow-up';
		case 7:
			return 'baloon';
		case 8:
			return 'bell';
		case 9:
			return 'book';
		case 10:
			return 'buildings';
		case 11:
			return 'burger';
		case 12:
			return 'calendar-clock';
		case 13:
			return 'calendar-mail';
		case 14:
			return 'calendar';
		case 15:
			return 'camera';
		case 16:
			return 'car';
		case 17:
			return 'cards-overlap';
		case 18:
			return 'cart';
		case 19:
			return 'chart';
		case 20:
			return 'chat';
		case 21:
			return 'check-circle';
		case 22:
			return 'check-square';
		case 23:
			return 'check';
		case 24:
			return 'chevron-circle-down';
		case 25:
			return 'chevron-circle-left';
		case 26:
			return 'chevron-circle-right';
		case 27:
			return 'chevron-circle-up';
		case 28:
			return 'chevron-down';
		case 29:
			return 'chevron-left';
		case 30:
			return 'chevron-right';
		case 31:
			return 'chevron-up';
		case 32:
			return 'clock-24';
		case 33:
			return 'clock';
		case 34:
			return 'computer';
		case 35:
			return 'credit-card';
		case 36:
			return 'cross-circle';
		case 37:
			return 'cross-square';
		case 38:
			return 'cross';
		case 39:
			return 'damage-third-parties';
		case 40:
			return 'devices';
		case 41:
			return 'diamond';
		case 42:
			return 'document-alert';
		case 43:
			return 'document-signed';
		case 44:
			return 'document';
		case 45:
			return 'download';
		case 46:
			return 'euro';
		case 47:
			return 'exclamation-circle';
		case 48:
			return 'exclamation-triangle';
		case 49:
			return 'external-link';
		case 50:
			return 'eye-slashed';
		case 51:
			return 'eye';
		case 52:
			return 'facebook';
		case 53:
			return 'family-rc';
		case 54:
			return 'fax';
		case 55:
			return 'floppy-disk';
		case 56:
			return 'folder';
		case 57:
			return 'gear';
		case 58:
			return 'gift';
		case 59:
			return 'grid';
		case 60:
			return 'home-damage-ebike';
		case 61:
			return 'home-damage-family';
		case 62:
			return 'home-damage-pets';
		case 63:
			return 'home-damage-third-parties';
		case 64:
			return 'home-domestic-thefts';
		case 65:
			return 'home-emergencies-repairs';
		case 66:
			return 'home-fire-explosion';
		case 67:
			return 'home-furniture-content';
		case 68:
			return 'home-injuries-family';
		case 69:
			return 'home-injuries-freetime';
		case 70:
			return 'home-insurance-home';
		case 71:
			return 'home-legal-protection';
		case 72:
			return 'home-natural-events';
		case 73:
			return 'home-robberies-outside';
		case 74:
			return 'home-telephone-legal-advice';
		case 75:
			return 'home-theft-valuable';
		case 76:
			return 'home';
		case 77:
			return 'hourglass';
		case 78:
			return 'id-card';
		case 79:
			return 'info-circle';
		case 80:
			return 'instagram';
		case 81:
			return 'insurance-home';
		case 82:
			return 'jobs';
		case 83:
			return 'key';
		case 84:
			return 'keyhole';
		case 85:
			return 'link';
		case 86:
			return 'linkedin';
		case 87:
			return 'list-circle';
		case 88:
			return 'list';
		case 89:
			return 'loader';
		case 90:
			return 'local-protection';
		case 91:
			return 'lock-open';
		case 92:
			return 'lock';
		case 93:
			return 'logout';
		case 94:
			return 'magic-wand';
		case 95:
			return 'magnifying-glass';
		case 96:
			return 'mail';
		case 97:
			return 'map-marker';
		case 98:
			return 'medal';
		case 99:
			return 'minus-circle';
		case 100:
			return 'minus-square';
		case 101:
			return 'minus';
		case 102:
			return 'moped';
		case 103:
			return 'more-horizontal-circle';
		case 104:
			return 'more-horizontal';
		case 105:
			return 'more-vertical';
		case 106:
			return 'motorcycle';
		case 107:
			return 'odometer';
		case 108:
			return 'paper-plane';
		case 109:
			return 'paperclip';
		case 110:
			return 'pec';
		case 111:
			return 'pen-form';
		case 112:
			return 'pen';
		case 113:
			return 'phone-24';
		case 114:
			return 'phone';
		case 115:
			return 'plate';
		case 116:
			return 'plus-circle';
		case 117:
			return 'plus-square';
		case 118:
			return 'plus';
		case 119:
			return 'pound';
		case 120:
			return 'prima';
		case 121:
			return 'question-circle';
		case 122:
			return 'reload';
		case 123:
			return 'shield-check';
		case 124:
			return 'ski';
		case 125:
			return 'smartphone';
		case 126:
			return 'star-filled';
		case 127:
			return 'star';
		case 128:
			return 'steering-wheel';
		case 129:
			return 'switch';
		case 130:
			return 'tablet';
		case 131:
			return 'thumb-up';
		case 132:
			return 'trash-bin';
		case 133:
			return 'tshirt-sport';
		case 134:
			return 'twitter';
		case 135:
			return 'umbrella';
		case 136:
			return 'upload';
		case 137:
			return 'user-check';
		case 138:
			return 'user-circle';
		case 139:
			return 'user-cross';
		case 140:
			return 'user-minus';
		case 141:
			return 'user-plus';
		case 142:
			return 'user';
		case 143:
			return 'users';
		case 144:
			return 'van';
		case 145:
			return 'vehicle-collision-kasko';
		case 146:
			return 'vehicle-driver-injuries';
		case 147:
			return 'vehicle-full-kasko';
		case 148:
			return 'vehicle-glasses';
		case 149:
			return 'vehicle-legal-protection';
		case 150:
			return 'vehicle-natural-events';
		case 151:
			return 'vehicle-ricourse-waiver';
		case 152:
			return 'vehicle-roadside-assistance';
		case 153:
			return 'vehicle-secured-bonus';
		case 154:
			return 'vehicle-theft-fire';
		case 155:
			return 'vehicle-vandalism';
		case 156:
			return 'wallet';
		case 157:
			return 'whatsapp';
		case 158:
			return 'wrench';
		default:
			return 'youtube';
	}
};
var $author$project$Pyxis$Components$IconSet$svgAlarm = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.99 19.22l-2.08-7.78C18.2 8.82 15.82 7 13.11 7h-2.22a5.99 5.99 0 0 0-5.79 4.45l-2.08 7.78A2.99 2.99 0 0 0 5.91 23h12.18c1.97 0 3.41-1.87 2.9-3.78zM7.03 11.96C7.5 10.22 9.08 9 10.89 9h2.22c1.81 0 3.4 1.22 3.86 2.96L18.32 17H5.68l1.35-5.04zm11.85 8.65c-.11.14-.36.39-.79.39H5.91c-.43 0-.68-.25-.79-.39-.12-.15-.29-.45-.18-.87l.2-.74h13.72l.2.74c.11.42-.06.72-.18.87zM5.64 6.05a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29.39-.39.39-1.02 0-1.41L5.64 3.22c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l1.41 1.42zM12 4c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1s-1 .45-1 1v2c0 .55.45 1 1 1zm5.66 2.34c.26 0 .51-.1.71-.29l1.41-1.41c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-1.41 1.41c-.39.39-.39 1.02 0 1.41.18.2.44.29.7.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgAndroid = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.06 15.06a.92.92 0 1 1 0-1.84.92.92 0 1 1 0 1.84m-10.12 0a.92.92 0 0 1 0-1.84.92.92 0 0 1 .92.92.93.93 0 0 1-.92.92m10.45-5.52l1.83-3.17c.1-.18.04-.42-.14-.52s-.42-.04-.52.14L16.71 9.2c-1.42-.64-3.01-1-4.71-1s-3.29.36-4.71 1.01L5.44 5.99a.39.39 0 0 0-.52-.14c-.18.11-.25.34-.14.52l1.83 3.17c-3.15 1.71-5.3 4.9-5.61 8.66h22c-.31-3.76-2.47-6.95-5.61-8.66\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgApple = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 12.7a4.98 4.98 0 0 1 2.38-4.19c-.93-1.32-2.42-2.13-4.03-2.18-1.7-.18-3.34 1.02-4.21 1.02-.88 0-2.21-1-3.65-.97-1.88.05-3.59 1.1-4.51 2.75-1.95 3.38-.5 8.35 1.38 11.09.94 1.34 2.03 2.83 3.46 2.78 1.4-.06 1.92-.89 3.62-.89 1.68 0 2.17.89 3.63.86 1.5-.02 2.45-1.34 3.35-2.7.67-.95 1.19-2.01 1.53-3.12-1.79-.76-2.95-2.51-2.95-4.45zm-2.76-8.17A4.96 4.96 0 0 0 16.37 1a4.99 4.99 0 0 0-3.24 1.68 4.7 4.7 0 0 0-1.16 3.4c1.27.01 2.47-.56 3.27-1.55z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.18 15.82c-.39-.39-1.02-.39-1.41 0L13 19.59V2c0-.55-.45-1-1-1s-1 .45-1 1v17.59l-3.76-3.76c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l5.47 5.47a.99.99 0 0 0 .7.29c.27 0 .52-.11.71-.29l5.47-5.47a1.01 1.01 0 0 0 0-1.42z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8.18 18.18c.39-.39.39-1.02 0-1.41L4.41 13H22c.55 0 1-.45 1-1s-.45-1-1-1H4.41l3.76-3.76c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0L1.29 11.3A.99.99 0 0 0 1 12c0 .27.11.52.29.71l5.47 5.47a1.01 1.01 0 0 0 1.42 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.82 5.82c-.39.39-.39 1.02 0 1.41L19.59 11H2c-.55 0-1 .45-1 1s.45 1 1 1h17.59l-3.76 3.76c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l5.47-5.47A.99.99 0 0 0 23 12c0-.27-.11-.52-.29-.71l-5.47-5.47a1.01 1.01 0 0 0-1.42 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5.82 8.18c.39.39 1.02.39 1.41 0L11 4.41V22c0 .55.45 1 1 1s1-.45 1-1V4.41l3.76 3.76c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41L12.7 1.29A.99.99 0 0 0 12 1c-.27 0-.52.11-.71.29L5.82 6.76a1.01 1.01 0 0 0 0 1.42z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBaloon = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16 12H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-4-4H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h4c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm8.25-5c.41 0 .75.34.75.75v11c0 .41-.34.75-.75.75H7.33c-1.07 0-2.07.42-2.83 1.17L3 18.17V3.75c0-.41.34-.75.75-.75h16.5m0-2H3.75A2.75 2.75 0 0 0 1 3.75v16.84c0 .6.49 1 1.01 1 .25 0 .5-.09.7-.29l3.21-3.21c.38-.38.88-.59 1.41-.59h12.92A2.75 2.75 0 0 0 23 14.75v-11A2.75 2.75 0 0 0 20.25 1h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBell = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.13 19l-1.73-3c-.26-.46-.4-.97-.4-1.5V10c0-3.74-2.56-6.86-6.03-7.74.02-.09.03-.17.03-.26 0-1.1-.9-2-2-2s-2 .9-2 2c0 .09.01.17.03.26C6.56 3.14 4 6.26 4 10v4.5c0 .53-.14 1.04-.4 1.5l-1.73 3c-.38.67.1 1.5.87 1.5h5.32A3.97 3.97 0 0 0 12 24c2.04 0 3.7-1.53 3.95-3.5h5.32c.77 0 1.25-.83.86-1.5zM12 22c-.93 0-1.71-.64-1.93-1.5h3.86c-.22.86-1 1.5-1.93 1.5zm3.7-3.5h-2.39-2.62H8.3 4.46l.87-1.5A4.98 4.98 0 0 0 6 14.5V10c0-3.31 2.69-6 6-6s6 2.69 6 6v4.5a4.98 4.98 0 0 0 .67 2.5l.87 1.5H15.7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBook = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 2.39a1.86 1.86 0 0 0-.52.07L12 5 2.52 2.46A1.91 1.91 0 0 0 2 2.39c-1.07 0-2 .87-2 2v12.09a3 3 0 0 0 2.22 2.9L12 22l9.78-2.62a3 3 0 0 0 2.22-2.9V4.39c0-1.13-.93-2-2-2zM2 16.48V4.39l9 2.41v12.86l-8.26-2.21c-.44-.12-.74-.52-.74-.97zm20 0c0 .45-.3.85-.74.97L13 19.66V6.8l9-2.41v12.09zM16 10c.09 0 .17-.01.26-.03l3-.8c.53-.14.85-.69.71-1.22s-.69-.85-1.22-.71l-3 .8c-.53.14-.85.69-.71 1.22.11.45.52.74.96.74zm2.74 1.23l-3 .8c-.53.14-.85.69-.71 1.22A1 1 0 0 0 16 14c.09 0 .17-.01.26-.03l3-.8c.53-.14.85-.69.71-1.22s-.69-.86-1.23-.72z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBuildings = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5 21c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v3.38c.6-.35 1.28-.55 2-.55V4c0-1.66-1.34-3-3-3H5C3.34 1 2 2.34 2 4v16c0 1.66 1.34 3 3 3h3.56c-.35-.59-.56-1.27-.56-2H5zM7 8c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm0 5c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm4-5c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm10.12 5.12l-3.71-3.71c-.39-.39-.9-.59-1.41-.59s-1.02.2-1.41.59l-3.71 3.71c-.56.56-.88 1.33-.88 2.12V21c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-5.76c0-.79-.32-1.56-.88-2.12zM20 21h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-3v-5.76a.99.99 0 0 1 .29-.71l3.71-3.7h0l3.71 3.71c.19.18.29.44.29.7V21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBurger = '<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><use xlink:href=\"#B\"/><use xlink:href=\"#B\" y=\"8\"/><use xlink:href=\"#B\" y=\"16\"/><defs ><path id=\"B\" d=\"M21 5H3c-.55 0-1-.45-1-1s.45-1 1-1h18c.55 0 1 .45 1 1s-.45 1-1 1z\"/></defs></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3zM4 5h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2H3V6c0-.55.45-1 1-1zm16 16H4c-.55 0-1-.45-1-1V10h18v10c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendarClock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h6.36c-.71-.57-1.33-1.24-1.83-2H4c-.55 0-1-.45-1-1V10h6.31c.73-.81 1.6-1.49 2.58-2H3V6c0-.55.45-1 1-1h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2.52c.76.51 1.43 1.13 2 1.83V6c0-1.66-1.34-3-3-3zm-4 6c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm1-5.41v-2.67c0-.55-.45-1-1-1s-1 .45-1 1v3.5l1.63 1.63a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29.39-.39.39-1.02 0-1.41L17 15.59z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendarMail = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h2.97c-.36-.59-.58-1.27-.62-2H4c-.55 0-1-.45-1-1V10h18v.02c.73.04 1.41.25 2 .62V6c0-1.66-1.34-3-3-3zM3 8V6c0-.55.45-1 1-1h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2H3zm17.8 4H10.53c-1.22 0-2.2.98-2.2 2.2v6.6c0 1.22.98 2.2 2.2 2.2H20.8c1.22 0 2.2-.98 2.2-2.2v-6.6c0-1.22-.98-2.2-2.2-2.2zm-.01 2l-5.12 2.96L10.55 14h10.24zm.01 7H10.53a.2.2 0 0 1-.2-.2v-4.61l4.83 2.79c.15.09.33.13.5.13s.35-.04.5-.13L21 16.19v4.61a.2.2 0 0 1-.2.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCamera = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.76 5a.99.99 0 0 1 .71.29l1.83 1.83c.56.57 1.31.88 2.11.88H20c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h1.59a2.97 2.97 0 0 0 2.12-.88l1.83-1.83c.18-.19.44-.29.7-.29h3.52m0-2h-3.51c-.8 0-1.56.32-2.12.88L6.29 5.71a1.01 1.01 0 0 1-.7.29H4C2.34 6 1 7.34 1 9v10c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h-1.59c-.27 0-.52-.11-.71-.29l-1.83-1.83c-.55-.56-1.32-.88-2.11-.88h0zM12 10a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.78 10l-4.1-1.1-1.1-1.9c-.54-.93-1.53-1.5-2.6-1.5H5.84c-.8 0-1.56.32-2.12.88l-2.5 2.5c-.76.76-1.05 1.86-.78 2.9l.67 2.5c.26.98.99 1.74 1.92 2.06L3 16c0 1.66 1.34 3 3 3s3-1.34 3-3c0 .17-.02.34-.05.5h7.1c-.03-.16-.05-.33-.05-.5 0 1.66 1.34 3 3 3s3-1.34 3-3c0 .11-.02.22-.03.33 1.18-.4 2.03-1.51 2.03-2.83v-.6a3 3 0 0 0-2.22-2.9zM6 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm13 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm3-3.5c0 .37-.21.67-.5.85.01.01.01.03.02.04A2.97 2.97 0 0 0 19 13c-1.11 0-2.06.61-2.58 1.5H8.58C8.06 13.61 7.11 13 6 13a2.97 2.97 0 0 0-2.52 1.39c.01-.01.02-.03.02-.05a1.01 1.01 0 0 1-.45-.58l-.67-2.5a1.03 1.03 0 0 1 .26-.97l2.5-2.5c.18-.19.44-.29.7-.29h8.14c.36 0 .69.19.87.5l1.1 1.9c.27.46.7.79 1.21.93l4.1 1.1c.44.12.74.52.74.97v.6zm-8.8-5H8.4c-.55 0-1 .45-1 1s.45 1 1 1h4.8c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCardsOverlap = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.5 7c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1h-9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9m0-2h-9c-1.66 0-3 1.34-3 3v12c0 1.66 1.34 3 3 3h9c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3h0zm-14 9.82V4c0-.55.45-1 1-1H13h1.5 2.82c-.42-1.16-1.52-2-2.82-2h-9c-1.66 0-3 1.34-3 3v12c0 1.3.84 2.4 2 2.82V16v-1.18z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCart = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.47 18h-7.93a3 3 0 0 1-2.9-2.22l-2.3-8.56C4.99 5.91 3.81 5 2.45 5H2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h.45c2.26 0 4.24 1.52 4.83 3.71L7.62 8h13.22c.63 0 1.2.29 1.59.78a2.01 2.01 0 0 1 .35 1.74l-1.41 5.26c-.36 1.31-1.55 2.22-2.9 2.22zM8.16 10l1.41 5.26c.12.44.51.74.96.74h7.93c.45 0 .85-.3.97-.74L20.84 10H8.16zM10 19a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3h0zm9 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChart = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3c.55 0 1 .45 1 1v16c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h16m0-2H4C2.34 1 1 2.34 1 4v16c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3h0zM7 18h0c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm5 0h0c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1zm5 0h0c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v7c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChat = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.5 12c1.29 0 2.52.55 3.37 1.52.87.98 1.25 2.25 1.09 3.56a4.56 4.56 0 0 1-3.68 3.85c-.26.05-.52.07-.78.07a4.53 4.53 0 0 1-2.24-.6l-.72-.41-.71.19.19-.71-.42-.73a4.47 4.47 0 0 1-.54-3.02c.32-1.9 1.94-3.45 3.85-3.68A3.99 3.99 0 0 1 7.5 12m0-2a7.86 7.86 0 0 0-.83.05c-2.79.35-5.11 2.56-5.58 5.33a6.5 6.5 0 0 0 .78 4.36l-.42 1.58c-.18.65.33 1.26.96 1.26.09 0 .17-.01.26-.04l1.58-.42A6.43 6.43 0 0 0 7.5 23a7.02 7.02 0 0 0 1.11-.09 6.52 6.52 0 0 0 5.33-5.58c.5-3.96-2.58-7.33-6.44-7.33h0zm14.39 3.37c.9-1.56 1.32-3.44 1-5.43-.57-3.63-3.61-6.51-7.26-6.89a8.65 8.65 0 0 0-.88-.05C10.6 1 7.18 4.07 6.6 8.06A6.69 6.69 0 0 1 7.5 8c.38 0 .75.03 1.11.08.21-1.11.71-2.15 1.49-3.01A6.26 6.26 0 0 1 14.75 3c.22 0 .45.01.68.04 2.75.29 5.06 2.48 5.49 5.21a6.22 6.22 0 0 1-.76 4.12l-.41.72.21.8.21.79-.79-.21-.8-.21-.72.41a6.51 6.51 0 0 1-1.96.72c.09.67.11 1.35.03 2.03 1.05-.15 2.04-.5 2.92-1.01l2.46.66c.09.02.18.04.26.04.63 0 1.14-.61.96-1.26l-.64-2.48z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10 18c-.26 0-.51-.1-.71-.29L4 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L10 15.59 19.59 6c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-10.29 10.3c-.2.19-.45.29-.71.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheckCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm-1 16c-.26 0-.51-.1-.71-.29L7 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L11 13.59 16.59 8c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheckSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-6 15c-.26 0-.51-.1-.71-.29L7 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L11 13.59 16.59 8c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 16c-.26 0-.51-.1-.71-.29L7 11.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L12 13.59 15.59 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm.59 17L8.3 12.71c-.39-.39-.39-1.02 0-1.41L12.59 7c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41L10.41 12 14 15.59c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm-2 17h0c-.39-.39-.39-1.02 0-1.41L13.59 12 10 8.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l4.29 4.29c.39.39.39 1.02 0 1.41L11.41 17c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm3.59 14L12 10.41 8.41 14c-.39.39-1.02.39-1.41 0h0c-.39-.39-.39-1.02 0-1.41l4.29-4.29c.39-.39 1.02-.39 1.41 0l4.3 4.29c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 17c-.26 0-.51-.1-.71-.29L5 10.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L12 14.59 17.59 9c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.59 19L7.3 12.71c-.39-.39-.39-1.02 0-1.41L13.59 5c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41L9.41 12 15 17.59c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 19h0c-.39-.39-.39-1.02 0-1.41L14.59 12 9 6.41C8.61 6.02 8.61 5.39 9 5h0c.39-.39 1.02-.39 1.41 0l6.29 6.29c.39.39.39 1.02 0 1.41L10.41 19c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.59 15L12 9.41 6.41 15c-.39.39-1.02.39-1.41 0h0c-.39-.39-.39-1.02 0-1.41l6.29-6.29c.39-.39 1.02-.39 1.41 0l6.3 6.29c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgClock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 0a12 12 0 1 0 0 24 12 12 0 1 0 0-24zm0 22A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10zm1-10.41V5c0-.55-.45-1-1-1s-1 .45-1 1v7.41l4.29 4.29c.2.2.45.3.71.3s.51-.1.71-.29c.39-.39.39-1.02 0-1.41L13 11.59z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgClock24 = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.69 4.43c-.03-.09-.05-.26-.05-.42 0-1.06.78-2.01 2.27-2.01 1.44 0 2.26.9 2.26 1.98 0 .71-.34 1.3-1.1 1.72l-.99.56c-.15.08-.33.26-.36.38h2.45V8h-4.55v-.1c0-1.11.33-1.97 1.41-2.6l.93-.55c.43-.24.59-.44.59-.78 0-.31-.24-.58-.66-.58-.47 0-.71.34-.71.72a1.17 1.17 0 0 0 .04.32h-1.53zm5.17.85l2.4-3.16h1.89v3.34H24V6.8h-.85V8h-1.62V6.8h-2.66V5.28zm2.67-1.46L20.3 5.46h1.22V3.82zM14 18c-.26 0-.51-.1-.71-.29L10 14.41V8c0-.55.45-1 1-1s1 .45 1 1v5.59l2.71 2.71c.39.39.39 1.02 0 1.41-.2.19-.45.29-.71.29zm5.47-8c.33.94.53 1.95.53 3 0 4.96-4.04 9-9 9s-9-4.04-9-9 4.04-9 9-9c.21 0 .42.02.62.03v-2c-.2-.01-.41-.03-.62-.03C4.92 2 0 6.92 0 13s4.92 11 11 11 11-4.92 11-11a10.98 10.98 0 0 0-.42-3h-2.11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgComputer = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 2H4C2.34 2 1 3.34 1 5v11c0 1.66 1.34 3 3 3h6v1H8c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1h-2v-1h6c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3zm1 14c0 .55-.45 1-1 1h-6-4-6c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCreditCard = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 2.5H3c-1.66 0-3 1.34-3 3v13c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3v-13c0-1.66-1.34-3-3-3zm-18 2h18c.55 0 1 .45 1 1v2H2v-2c0-.55.45-1 1-1zm18 15H3c-.55 0-1-.45-1-1v-7h20v7c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCross = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.0098 3.99001C19.6198 3.60001 18.9898 3.60001 18.5998 3.99001L11.9998 10.59L5.39977 3.99001C5.00977 3.60001 4.37977 3.60001 3.98977 3.99001C3.59977 4.38001 3.59977 5.01001 3.98977 5.40001L10.5898 12L3.98977 18.6C3.59977 18.99 3.59977 19.62 3.98977 20.01C4.37977 20.4 5.00977 20.4 5.39977 20.01L11.9998 13.41L18.5998 20.01C18.9898 20.4 19.6198 20.4 20.0098 20.01C20.3998 19.62 20.3998 18.99 20.0098 18.6L13.4098 12L20.0098 5.40001C20.3998 5.01001 20.3998 4.38001 20.0098 3.99001V3.99001Z\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgCrossCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm1.41 12l2.48-2.48c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0L12 10.59 9.52 8.11c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41L10.59 12l-2.48 2.48c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L12 13.41l2.48 2.48c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41L13.41 12z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCrossSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1.11 7.11h0c-.39-.39-1.02-.39-1.41 0L12 10.59 9.52 8.11c-.39-.39-1.02-.39-1.41 0h0c-.39.39-.39 1.02 0 1.41L10.59 12l-2.48 2.48c-.39.39-.39 1.02 0 1.41h0c.39.39 1.02.39 1.41 0L12 13.41l2.48 2.48c.39.39 1.02.39 1.41 0h0c.39-.39.39-1.02 0-1.41L13.41 12l2.48-2.48c.39-.39.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDamageThirdParties = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.3 18H14c-1.1 0-2-.9-2-2v-1c0-.55-.45-1-1-1v-1.09c2.88-.5 5-3.15 5-6.16V2.41c0-.6-.49-1-1.01-1-.25 0-.5.09-.7.29l-.59.59a.98.98 0 0 1-.71.29c-.26 0-.51-.1-.71-.29L10.69.7a.98.98 0 0 0-.71-.29c-.26 0-.51.1-.71.29L7.71 2.29c-.2.2-.45.3-.71.3s-.51-.1-.71-.29l-.58-.59c-.2-.2-.45-.29-.7-.29-.51 0-1.01.4-1.01 1V7c0 2.97 2.16 5.43 5 5.91V14H4.93C3.61 14 2.65 15.25 3 16.52l1.41 5.26C4.76 23.09 5.94 24 7.3 24h5.4a3 3 0 0 0 2.9-2.22l.67-2.52A1 1 0 0 0 15.3 18zM6 7V4.42c.32.11.65.17 1 .17a2.97 2.97 0 0 0 2.12-.88l.88-.88.88.88c.57.56 1.32.88 2.12.88.35 0 .68-.06 1-.17v2.33c0 2.28-1.74 4.19-3.88 4.25H10a4 4 0 0 1-4-4zm7.66 14.26c-.11.44-.51.74-.96.74H7.3c-.45 0-.85-.3-.97-.74L4.93 16H10a4 4 0 0 0 4 4h0l-.34 1.26zM19 20.8a1.97 1.97 0 0 0-1.73 1l-.4.7c-.38.67.1 1.5.87 1.5h2.54c.77 0 1.25-.83.87-1.5l-.4-.7c-.4-.66-1.08-1-1.75-1h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDevices = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 8c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h3m0-2h-3c-1.66 0-3 1.34-3 3v10c0 1.66 1.34 3 3 3h3c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h0zm-8 11h-2-6c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h13 1.3H20h2.82C22.4 2.84 21.3 2 20 2H4C2.34 2 1 3.34 1 5v11c0 1.66 1.34 3 3 3h6v1H8c-.55 0-1 .45-1 1s.45 1 1 1h5.03c-.64-.84-1.03-1.87-1.03-3v-2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDiamond = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.95 7.84L19.58 2c-.36-.62-1.02-1-1.73-1H6.15c-.71 0-1.37.38-1.73 1L1.05 7.84a1.98 1.98 0 0 0 .15 2.22l9.22 11.9a1.98 1.98 0 0 0 3.16 0l9.22-11.9a2.02 2.02 0 0 0 .15-2.22zM14.81 10L12 17.98 9.2 10h5.61zM9.55 8L12 3.94 14.45 8h-4.9zm4.22-5h3.5l-1.29 3.66L13.77 3zM8.02 6.66L6.74 3h3.49L8.02 6.66zm1.87 11.35L3.69 10h3.39l2.81 8.01zM16.93 10h3.39l-6.2 8.01L16.93 10zm3.8-2h-3.1l1.17-3.34L20.73 8zM5.2 4.66L6.37 8h-3.1L5.2 4.66z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocument = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.12 7.12L13.88.88C13.32.32 12.55 0 11.76 0H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14 3.83L17.17 7H14V3.83zM19 21c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocumentAlert = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.12 7.12L14.88.88C14.32.32 13.55 0 12.76 0H7a3.01 3.01 0 0 0-3 3v6.52c.62-.41 1.29-.74 2-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21c0 .55-.45 1-1 1h-2.52c-.51.76-1.13 1.43-1.83 2H19a3.01 3.01 0 0 0 3-3V9.24c0-.79-.32-1.56-.88-2.12zM15 3.83L18.17 7H15V3.83zM9 12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5m0-2c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7h0zm0 8c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1z\"/><circle cx=\"9\" cy=\"20\" r=\"1\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocumentSigned = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.12 7.12L13.88.88C13.32.32 12.55 0 11.76 0H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14 3.83L17.17 7H14V3.83zM19 21c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21zm-3-3h-6c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1zm0-3h-2c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDownload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 23H6a4.02 4.02 0 0 1-3.8-2.74c-.2-.62.29-1.26.95-1.26h0c.42 0 .82.24.95.63.27.8 1.02 1.37 1.9 1.37h12c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 18 23zm-2-12c-.39-.39-1.02-.39-1.41 0L13 12.59V2c0-.55-.45-1-1-1s-1 .45-1 1v10.59L9.41 11c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l3.29 3.29c.2.2.45.3.71.3s.51-.1.71-.29l3.29-3.3c.39-.39.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEuro = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 20c-3.35 0-6.22-2.07-7.41-5H13v-2H6.07c-.04-.33-.07-.66-.07-1s.03-.67.07-1H15V9H6.59C7.78 6.07 10.65 4 14 4a7.95 7.95 0 0 1 5.66 2.34l1.41-1.41A9.93 9.93 0 0 0 14 2C9.53 2 5.74 4.95 4.46 9H3v2h1.05c-.03.33-.05.66-.05 1s.02.67.05 1H3v2h1.46c1.28 4.05 5.07 7 9.54 7a9.93 9.93 0 0 0 7.07-2.93l-1.41-1.41A7.95 7.95 0 0 1 14 20z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExclamationCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 14.37h0c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v7c0 .56-.45 1-1 1z\"/><circle cx=\"12\" cy=\"17.38\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExclamationTriangle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2.77c.18 0 .62.05.88.52l8.85 16.23a.97.97 0 0 1-.02.99c-.09.15-.34.49-.86.49H3.15c-.51 0-.77-.34-.86-.49s-.26-.54-.02-.99l8.85-16.23c.26-.47.7-.52.88-.52m0-2c-1.03 0-2.07.52-2.63 1.56L.51 18.56C-.58 20.56.87 23 3.14 23h17.7c2.28 0 3.72-2.44 2.63-4.44L14.63 2.33C14.07 1.29 13.03.77 12 .77h0zM12 15h0c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1z\"/><circle cx=\"12\" cy=\"18\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExternalLink = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 22H7c-2.76 0-5-2.24-5-5V7c0-2.76 2.24-5 5-5h3c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1H7C5.34 4 4 5.34 4 7v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3v-3c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v3c0 2.76-2.24 5-5 5zm5-21h-7c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h4.59L12 10.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L21 4.41V9c0 .55.45 1 1 1h0c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEye = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 6c4.69 0 7.82 3.39 9.27 5.4.26.36.26.83 0 1.19C19.82 14.61 16.69 18 12 18s-7.82-3.39-9.27-5.4c-.26-.36-.26-.83 0-1.19C4.18 9.39 7.31 6 12 6m0-2C6.43 4 2.8 7.88 1.1 10.24c-.76 1.06-.76 2.47 0 3.52C2.8 16.12 6.43 20 12 20s9.2-3.88 10.9-6.24c.76-1.06.76-2.47 0-3.52C21.2 7.88 17.57 4 12 4h0zm0 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEyeSlashed = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.9 10.24C21.2 7.88 17.57 4 12 4c-2.03 0-3.79.52-5.3 1.28l1.5 1.51C9.33 6.31 10.59 6 12 6c4.69 0 7.82 3.39 9.27 5.4.26.36.26.83 0 1.19-.76 1.06-1.98 2.49-3.65 3.61l1.44 1.44c1.73-1.24 3.01-2.74 3.83-3.89.76-1.05.76-2.45.01-3.51zM12 10c1.1 0 2 .9 2 2 0 .18-.03.35-.07.51l1.51 1.51A3.95 3.95 0 0 0 16 12a4 4 0 0 0-4-4c-.74 0-1.43.22-2.02.57l1.51 1.51A1.7 1.7 0 0 1 12 10zm7.71 10.29l-1.87-1.87-1.48-1.48-1.85-1.85-1.42-1.42-2.76-2.76-1.42-1.42-2.03-2.02-1.45-1.45-1.72-1.73c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l1.53 1.53a18.18 18.18 0 0 0-2.72 3c-.76 1.06-.76 2.47 0 3.52C2.8 16.12 6.43 20 12 20a11.57 11.57 0 0 0 3.91-.68l2.39 2.39c.19.19.44.29.7.29s.51-.1.71-.29a1.01 1.01 0 0 0 0-1.42zM12 18c-4.69 0-7.82-3.39-9.27-5.4-.26-.36-.26-.83 0-1.19.57-.8 1.42-1.81 2.51-2.75l2.81 2.81c-.02.17-.05.35-.05.53a4 4 0 0 0 4 4c.18 0 .36-.03.53-.05l1.77 1.77A9.99 9.99 0 0 1 12 18z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFacebook = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M24 12.07C24 5.27 18.41-.22 11.6 0 5.56.2.47 5.11.03 11.18-.43 17.6 4.11 23.05 10.12 24v-8.44H7.08v-3.49h3.05V9.41c0-3.03 1.79-4.7 4.53-4.7 1.31 0 2.69.24 2.69.24v2.97h-1.51c-1.49 0-1.96.93-1.96 1.89v2.26h3.33l-.53 3.49h-2.8V24C19.61 23.09 24 18.1 24 12.07z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFamilyRc = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13zm-8.35 8.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11-2-.7-3.37-1.79-4.31-2.96.12-.43.49-.76.96-.76h8c.47 0 .84.33.95.76-.94 1.18-2.31 2.26-4.3 2.96zm5.45-4.84c-.54-.54-1.28-.88-2.1-.88H8c-.82 0-1.56.34-2.1.88-.9-1.95-.9-3.7-.9-3.83V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .14 0 1.88-.9 3.84zM12 6a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFax = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5.62 10.2V15H3.64v-4.8H2.61V8.54h1.03v-.56c0-1.48.9-2.5 2.47-2.5.4 0 .75.05.94.14v1.61c-.1-.03-.27-.06-.59-.06-.35 0-.84.16-.84.87v.49h1.4v1.66h-1.4zm4.03.89l1.52-.23c.35-.05.47-.22.47-.44 0-.38-.33-.7-.95-.7-.69 0-1.07.47-1.1.95l-1.72-.35c.07-.94.93-2.16 2.83-2.16 2.09 0 2.86 1.17 2.86 2.5v3.16c0 .51.06.94.08 1h-1.78c-.01-.05-.06-.29-.06-.73-.35.55-.97.91-1.83.91-1.42 0-2.24-.94-2.24-1.96.01-1.15.85-1.8 1.92-1.95zm1.99 1.19V12l-1.22.2c-.42.06-.73.26-.73.71 0 .34.22.66.77.66.58 0 1.18-.29 1.18-1.29zm5.11-.5l-2.26-3.24h2.33l1.16 1.77 1.13-1.77h2.21l-2.24 3.16 2.31 3.3h-2.31l-1.2-1.82L16.7 15h-2.17l2.22-3.22z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFloppyDisk = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.12 7.12L18 3h0l-1.12-1.12c-.56-.56-1.33-.88-2.12-.88H6 4C2.34 1 1 2.34 1 4v16c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14.76 3a.99.99 0 0 1 .71.29l.53.54V9c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1V3h6.76zM21 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h2v6c0 1.66 1.34 3 3 3h6c1.66 0 3-1.34 3-3V5.83l2.71 2.71c.19.18.29.44.29.7V20z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFolder = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8.76 5a.99.99 0 0 1 .71.29l1.83 1.83c.56.57 1.31.88 2.11.88H20c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h4.76m0-2H4C2.34 3 1 4.34 1 6v12c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h-6.59c-.27 0-.52-.11-.71-.29l-1.83-1.83C10.32 3.32 9.55 3 8.76 3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGear = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 3v.95a2 2 0 0 0 1.11 1.79c.3.15.55.29.76.43.33.22.72.34 1.11.34.34 0 .69-.09 1-.27l.81-.47 2 3.46-.82.47c-.66.38-1.04 1.1-1 1.86l.01.15.02.29-.01.29-.01.15c-.05.76.34 1.48 1 1.86l.82.47-2 3.46-.81-.47c-.31-.18-.66-.27-1-.27a1.96 1.96 0 0 0-1.11.34c-.21.14-.46.28-.76.43A1.99 1.99 0 0 0 14 20.05V21h-4v-.95a2 2 0 0 0-1.11-1.79c-.3-.15-.55-.29-.76-.43-.33-.22-.72-.34-1.11-.34-.34 0-.69.09-1 .27l-.81.47-2-3.46.82-.47c.66-.38 1.04-1.1 1-1.86l-.01-.15L5 12l.01-.29.01-.15c.05-.76-.34-1.48-1-1.86l-.81-.47 2-3.46.81.47c.31.18.66.27 1 .27a1.96 1.96 0 0 0 1.11-.34c.21-.14.46-.28.76-.43A2 2 0 0 0 10 3.95V3h4m0-2h-4c-1.1 0-2 .9-2 2v.95c-.34.16-.67.35-.98.56l-.81-.47c-.31-.18-.66-.27-1-.27-.69 0-1.36.36-1.73 1l-2 3.46c-.55.96-.22 2.18.73 2.73l.82.47L3 12c0 .19.02.37.03.56l-.82.47c-.96.55-1.28 1.78-.73 2.73l2 3.46c.37.64 1.04 1 1.73 1a2.03 2.03 0 0 0 1-.27l.81-.47a7.36 7.36 0 0 0 .98.57V21c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2v-.95c.34-.17.67-.35.98-.56l.81.47c.31.18.66.27 1 .27.69 0 1.36-.36 1.73-1l2-3.46c.55-.96.22-2.18-.73-2.73l-.82-.47L21 12c0-.19-.02-.37-.03-.56l.82-.47c.96-.55 1.28-1.78.73-2.73l-2-3.46c-.37-.64-1.04-1-1.73-1a2.03 2.03 0 0 0-1 .27l-.81.47a6.41 6.41 0 0 0-.98-.57V3c0-1.1-.9-2-2-2h0zm-2 8a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGift = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 7h-4c1.66 0 3-1.34 3-3h0c0-1.66-1.34-3-3-3s-3 1.34-3 3c0-1.66-1.34-3-3-3S6 2.34 6 4h0c0 1.66 1.34 3 3 3H5c-1.66 0-3 1.34-3 3v10c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V10c0-1.66-1.34-3-3-3zm0 2c.55 0 1 .45 1 1v4h-7V9h6zm-5-5c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1h-1V4zM8 4c0-.55.45-1 1-1s1 .45 1 1v1H9c-.55 0-1-.45-1-1zM5 9h6v5H4v-4c0-.55.45-1 1-1zM4 20v-4h7v5H5c-.55 0-1-.45-1-1zm15 1h-6v-5h7v4c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGrid = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 3v6H3V3h6m0-2H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0zm12 2v6h-6V3h6m0-2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0zM9 15v6H3v-6h6m0-2H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h0zm12 2v6h-6v-6h6m0-2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1h-3v-5a3.01 3.01 0 0 0-3-3h-2a3.01 3.01 0 0 0-3 3v5H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h3c1.1 0 2-.9 2-2v-5c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v5c0 1.1.9 2 2 2h3c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageEbike = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 13c-.1 0-.2.02-.3.03l-.82-3.07C17.41 8.22 15.83 7 14.02 7H13c-.55 0-1 .45-1 1s.45 1 1 1h1.02c.9 0 1.7.61 1.93 1.48l.01.03-4.46 2.57-1.16-1.16A.96.96 0 0 0 11 11c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1h1.59l2.13 2.13-.99.57C7.81 13.66 6.49 13 5 13c-2.76 0-5 2.24-5 5s2.24 5 5 5a5 5 0 0 0 4.9-4h4.2a5 5 0 0 0 4.9 4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-2.5-.48l.28 1.03c-1.35.68-2.36 1.93-2.67 3.45H9.9c-.04-.2-.11-.38-.18-.57l6.78-3.91zM5 21a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3c1.3 0 2.4.84 2.82 2H5c-.55 0-1 .45-1 1s.45 1 1 1h2.82C7.4 20.16 6.3 21 5 21zm14 0a3.01 3.01 0 0 1-3-3c0-1.02.52-1.93 1.3-2.47l.73 2.73c.12.45.53.74.97.74.09 0 .17-.01.26-.03.53-.14.85-.69.71-1.22l-.73-2.72c1.54.12 2.76 1.4 2.76 2.97a3.01 3.01 0 0 1-3 3zM8 2v3H3l7 3V5h5L8 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageFamily = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16.37 9.52a8.01 8.01 0 0 0 0 8.96A6.99 6.99 0 0 0 18 14a6.99 6.99 0 0 0-1.63-4.48zM7.12 8.17A6.96 6.96 0 0 1 11 7a6.96 6.96 0 0 1 3.88 1.17C13.7 9.81 13 11.82 13 14s.7 4.19 1.88 5.83A6.96 6.96 0 0 1 11 21a6.96 6.96 0 0 1-3.88-1.17C8.3 18.19 9 16.18 9 14s-.7-4.19-1.88-5.83zm10.59 11.82a8.71 8.71 0 0 1-1.5 1.34C14.74 22.38 12.95 23 11 23s-3.74-.62-5.21-1.67a8.71 8.71 0 0 1-1.5-1.34 8.97 8.97 0 0 1 0-11.98 8.71 8.71 0 0 1 1.5-1.34C7.26 5.62 9.05 5 11 5s3.74.62 5.21 1.67a8.71 8.71 0 0 1 1.5 1.34 8.97 8.97 0 0 1 0 11.98zM5.62 9.53C4.61 10.74 4 12.3 4 14s.61 3.26 1.62 4.47C6.49 17.2 7 15.65 7 14s-.51-3.2-1.38-4.47z\" fill-rule=\"evenodd\"/><path d=\"M19.07 5.93c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l1.41-1.41c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-1.41 1.41c-.19.19-.44.29-.7.29zM20.66 8c-.35 0-.68-.18-.87-.5-.28-.48-.11-1.09.37-1.37l1.73-1c.48-.28 1.09-.11 1.37.37s.11 1.09-.37 1.37l-1.73 1a1.01 1.01 0 0 1-.5.13zM17 4.34a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l1-1.73A1.01 1.01 0 0 1 18.5.74a1.01 1.01 0 0 1 .37 1.37l-1 1.73a1.02 1.02 0 0 1-.87.5z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamagePets = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M4 9c.15 0 .45.04.71.29.39.39.39 1.02 0 1.41-.26.26-.56.3-.71.3s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C5.54 7.29 4.77 7 4 7h0zm4.5-3c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C10.04 2.29 9.27 2 8.5 2h0zm7 2c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C17.04 2.29 16.27 2 15.5 2h0zM20 9c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C21.54 7.29 20.77 7 20 7h0zm-8 5.49c1.07 0 2.07.42 2.83 1.17l1.96 1.96c.97.97 1.11 2.49.31 3.47a2.46 2.46 0 0 1-1.93.91c-.35 0-.69-.07-1.01-.21-.69-.31-1.42-.46-2.16-.46s-1.47.16-2.16.46c-.32.14-.66.21-1.01.21-.74 0-1.45-.33-1.93-.91-.8-.97-.67-2.49.31-3.47l1.96-1.96A3.96 3.96 0 0 1 12 12.49m0-2c-1.54 0-3.07.59-4.24 1.76L5.8 14.21c-1.66 1.66-1.92 4.35-.43 6.15C6.26 21.45 7.55 22 8.83 22c.62 0 1.24-.13 1.82-.38a3.38 3.38 0 0 1 1.35-.29c.46 0 .92.1 1.35.29.58.26 1.2.38 1.82.38a4.49 4.49 0 0 0 3.47-1.64c1.49-1.81 1.22-4.5-.43-6.15l-1.96-1.96A5.97 5.97 0 0 0 12 10.49h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageThirdParties = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 18h-.23L16.08 4.22C15.73 2.91 14.53 2 13.18 2h-2.36a3 3 0 0 0-2.9 2.22L4.23 18H4c-1.1 0-2 .9-2 2v1c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2zM10.82 4h2.36c.45 0 .85.3.97.74L15.02 8H8.98l.87-3.26c.12-.44.52-.74.97-.74zm-2.37 6h7.11l.8 3H7.64l.81-3zm-1.34 5h9.79l.8 3H6.3l.81-3zM20 21H4v-1h16v1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDomesticThefts = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.83 9.9L8.43 2.17a2.89 2.89 0 0 0-1.49-.41c-1.04 0-2.05.54-2.6 1.5l-2 3.47C1.51 8.16 2 10 3.43 10.83L8 13.46V15c0 1.1-.9 2-2 2h-.11c-.38-1.87-1.8-3.37-3.64-3.84a1.07 1.07 0 0 0-.25-.03c-.54 0-1 .44-1 1.01v7.74a1.01 1.01 0 0 0 1 1.01 1.07 1.07 0 0 0 .25-.03c1.84-.47 3.26-1.97 3.64-3.84H6a4 4 0 0 0 4-4v-.38l1.83 1.06a2.96 2.96 0 0 0 1.5.4c.36 0 .73-.07 1.08-.2.23.22.49.42.78.59.63.36 1.32.54 2 .54a4.02 4.02 0 0 0 3.47-2 4 4 0 0 0 .42-2.93l.75-.44c.66-.41.66-1.38 0-1.76zM3 20.24v-4.48a3.01 3.01 0 0 1 0 4.48zM18.92 14c-.35.61-1.02 1-1.73 1-.3 0-.59-.07-.86-.2l2.85-1.64a1.96 1.96 0 0 1-.26.84zm-.61-2.65l-3.11 1.8-1.37.79c-.16.09-.32.13-.49.13h-.01c-.18 0-.35-.04-.5-.13l-8.4-4.85c-.47-.27-.64-.88-.36-1.36l2-3.47a.99.99 0 0 1 .87-.5c.17 0 .34.05.49.14l8.99 5.18 2.91 1.68-1.02.59z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeEmergenciesRepairs = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 5h-3.18C19.4 3.84 18.3 3 17 3c0-1.1-.9-2-2-2H5C2.24 1 0 3.24 0 6a5 5 0 0 0 4 4.9V20c0 1.66 1.34 3 3 3h.7a3 3 0 0 0 2.9-2.22L13.22 11H15c1.1 0 2-.9 2-2 1.3 0 2.4-.84 2.82-2H23c.55 0 1-.45 1-1s-.45-1-1-1zM8.66 20.26c-.11.44-.51.74-.96.74H7c-.55 0-1-.45-1-1v-9h5.14l-2.48 9.26zM15 9h-1.25H5a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3h10v6zm2-2V5c.55 0 1 .45 1 1s-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeFireExplosion = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10.78 3.16c4.68.85 8.22 5 8.22 9.84v.69c0 3.85-2.87 7.06-6.53 7.29-.16.01-.31.02-.47.02a7.01 7.01 0 0 1-7-7c0-2.13.95-4.11 2.6-5.45 1.63-1.32 2.74-3.2 3.18-5.39m-.86-2.12c-.49 0-.92.36-.97.85-.2 2.04-1.11 3.9-2.61 5.1C4.3 8.64 3 11.17 3 14c0 4.97 4.03 9 9 9 .2 0 .4-.01.6-.02 4.78-.31 8.4-4.5 8.4-9.29V13c0-6.29-4.84-11.45-11-11.96h-.08 0zm3.91 11.09c.67 1.03.74 2.38.1 3.49l-.14.24c-.4.69-1.14 1.14-1.89 1.14a1.7 1.7 0 0 1-.74-.16c-.53-.24-.91-.67-1.09-1.22-.17-.54-.11-1.12.17-1.61a1.99 1.99 0 0 1 1.42-.98c.77-.11 1.51-.43 2.17-.9m.12-2.42c-.28 0-.56.13-.77.35-.5.53-1.13.9-1.83 1-1.15.18-2.22.86-2.84 1.95-1.17 2.03-.37 4.65 1.81 5.64.51.23 1.05.34 1.58.34 1.45 0 2.86-.81 3.62-2.14l.14-.24a5.34 5.34 0 0 0-1.14-6.71 1 1 0 0 0-.57-.19h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeFurnitureContent = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 10.18V7c0-.55-.45-1-1-1h-1a3.01 3.01 0 0 1-3-3V2c0-.55-.45-1-1-1H8a4 4 0 0 0-4 4v5.18C2.84 10.6 2 11.7 2 13v6c0 1.1.9 2 2 2 0 1.1.9 2 2 2s2-.9 2-2H6h12-2c0 1.1.9 2 2 2s2-.9 2-2c1.1 0 2-.9 2-2v-6c0-1.3-.84-2.4-2-2.82zM8 16h8v3H8v-3zm-4-3c0-.55.45-1 1-1s1 .45 1 1v6h0 0-2v-6zm12 0v1H8v-1c0-1.3-.84-2.4-2-2.82V5c0-1.1.9-2 2-2h5c0 2.76 2.24 5 5 5v2.18c-1.16.42-2 1.52-2 2.82zm4 6h-2v-6c0-.55.45-1 1-1s1 .45 1 1v6z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFamily = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15 3v4 2h2 4v6h-4-2v2 4H9v-4-2H7 3V9h4 2V7 3h6m0-2H9c-1.1 0-2 .9-2 2v4H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h4v4c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-4h4c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-4V3c0-1.1-.9-2-2-2h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFreetime = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.49 3.51h0a5.99 5.99 0 0 0-4.24-1.76c-1.53 0-3.08.59-4.25 1.76L3.51 12c-2.34 2.34-2.34 6.14 0 8.49a5.99 5.99 0 0 0 8.48 0l8.5-8.49c2.34-2.34 2.34-6.14 0-8.49zm-9.9 15.56a3.96 3.96 0 0 1-2.83 1.17c-1.07 0-2.07-.42-2.83-1.17a3.96 3.96 0 0 1-1.17-2.83c0-1.07.42-2.07 1.17-2.83l3.54-3.54 5.66 5.66-3.54 3.54zm8.48-8.48l-3.54 3.54-5.65-5.67 3.54-3.54a3.96 3.96 0 0 1 2.83-1.17c1.07 0 2.07.42 2.83 1.17a4.03 4.03 0 0 1-.01 5.67z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInsuranceHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0zm3 10.83V10.5a3.01 3.01 0 0 0-3-3 3.01 3.01 0 0 0-3 3v1.57c-.86.22-1.5 1-1.5 1.93v3c0 1.1.9 2 2 2h5c1.1 0 2-.9 2-2v-3c0-.93-.64-1.71-1.5-1.93zM12 9.5c.55 0 1 .45 1 1V12h-2v-1.5c0-.55.45-1 1-1zm2.5 7.5h-5v-3h5v3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeLegalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.65 21.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v6.81l1.96 1.13c.05-.51.04-.83.04-.83V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.94 2.39C3.75 4.02 3 4.88 3 5.89V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22c2.68-.94 4.41-2.46 5.55-4.07l-1.75-1.01c-.94 1.27-2.34 2.45-4.46 3.19zm8.95-5.21l-5.63-3.25.5-.87a3 3 0 0 0-1.1-4.1l-.87-.5c-.47-.27-.99-.4-1.5-.4-1.04 0-2.05.54-2.6 1.5l-2 3.46a3 3 0 0 0 1.1 4.1l.87.5c.47.27.99.4 1.5.4 1.04 0 2.05-.54 2.6-1.5l.5-.87 5.63 3.25a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5.27-.46.1-1.07-.37-1.35zm-8.87-1.65c-.18.31-.51.5-.87.5-.18 0-.34-.05-.5-.13l-.87-.5c-.31-.18-.43-.46-.47-.61a1 1 0 0 1 .1-.76l2-3.46c.18-.31.51-.5.87-.5.18 0 .34.05.5.13l.87.5c.31.18.43.46.47.61a1 1 0 0 1-.1.76l-2 3.46z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeNaturalEvents = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.11 23a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l2.02-3.5H10c-.36 0-.69-.19-.87-.5s-.18-.69 0-1l2.89-5a1.01 1.01 0 0 1 1.37-.37 1.01 1.01 0 0 1 .37 1.37L11.73 16H15c.36 0 .69.19.87.5s.18.69 0 1l-2.89 5c-.19.32-.52.5-.87.5zm6.88-15.86c0-.05.01-.09.01-.14 0-3.31-2.69-6-6-6-2.62 0-4.85 1.69-5.66 4.03C7.22 5.03 7.12 5 7 5c-2.76 0-5 2.24-5 5s2.24 5 5 5h.69l1.15-2H7a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 2.93-3l.27.03 1.52.1.5-1.44C9.78 4.08 11.3 3 13 3c2.19 0 3.98 1.77 4 3.96 0 .04-.01.09-.01.13l-.04 1.59 1.54.4C19.38 9.3 20 10.1 20 11c0 1.1-.9 2-2 2h-2.29c-.06.17-.13.34-.22.5l-.87 1.5H18a4 4 0 0 0 4-4 3.99 3.99 0 0 0-3.01-3.86z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeRobberiesOutside = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19.3 10.31c-.54-2.33-2.16-4.25-4.31-5.18 0-.05.01-.09.01-.13 0-1.66-1.34-3-3-3S9 3.34 9 5c0 .04.01.08.01.12-2.15.94-3.77 2.86-4.31 5.19-1 .48-1.7 1.5-1.7 2.69 0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3 0-1.19-.7-2.21-1.7-2.69zm-8.19-5.75c.17-.33.5-.56.89-.56s.72.23.89.56c-.3-.04-.59-.06-.89-.06s-.59.02-.89.06zM18 14H6c-.55 0-1-.45-1-1a1 1 0 0 1 .57-.89l.86-.42.22-.93C7.23 8.25 9.43 6.5 12 6.5s4.77 1.75 5.35 4.26l.22.93.86.42c.28.13.57.44.57.89 0 .55-.45 1-1 1zm1.87 4h-7.74c-.05 0-.09.01-.13.02-.04-.01-.09-.02-.13-.02H4.13c-.65 0-1.14.62-.97 1.25A4.99 4.99 0 0 0 8 23c1.64 0 3.09-.8 4-2.02.91 1.22 2.36 2.02 4 2.02a4.99 4.99 0 0 0 4.84-3.75c.17-.63-.32-1.25-.97-1.25zM8 21a3.01 3.01 0 0 1-2.24-1h4.48A3.01 3.01 0 0 1 8 21zm8 0a3.01 3.01 0 0 1-2.24-1h4.48A3.01 3.01 0 0 1 16 21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeTelephoneLegalAdvice = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 2.67c.71.46 1.7.91 3 1.15v2.92c0 .03-.03 3.14-3 4.17-2.86-.99-2.99-3.86-3-4.17V3.82c1.3-.23 2.29-.69 3-1.15m0-2.24c-.22 0-.44.07-.61.22-.61.5-1.7 1.14-3.48 1.31a1 1 0 0 0-.91.99v3.8s0 4.61 4.43 6.09a1.79 1.79 0 0 0 .57.09c.19 0 .38-.03.57-.09C22 11.36 22 6.75 22 6.75v-3.8a1 1 0 0 0-.9-.99c-1.78-.17-2.88-.8-3.48-1.31A.96.96 0 0 0 17 .43h0zm-.98 22.25a4 4 0 0 1-.84-.09C8.3 21.11 2.89 15.7 1.41 8.81A3.88 3.88 0 0 1 2.2 5.5C2.97 4.55 4.11 4 5.33 4h1.03A3 3 0 0 1 9.3 6.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.13 2.65 3.5 3.67l.08-.08c.71-.71 1.73-1.02 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.47.88zM5.33 6a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25 1.98 1.98 0 0 0 1.64-.39 2.01 2.01 0 0 0 .75-1.57v-1.03c0-.47-.34-.89-.8-.98l-2.34-.47c-.33-.06-.67.04-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.56-.79-.46-1.86.24-2.55.27-.27.37-.61.31-.94L7.34 6.8a1 1 0 0 0-.98-.8H5.33z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeTheftValuable = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.73 7.93l1.5-2.6a2 2 0 0 0-.32-2.41l-1.33-1.33c-.37-.38-.88-.59-1.41-.59H9.83c-.53 0-1.04.21-1.42.59L7.09 2.91a2 2 0 0 0-.32 2.42l1.5 2.6C5.73 9.27 4 11.93 4 15a8 8 0 1 0 16 0c0-3.07-1.73-5.73-4.27-7.07zM8.5 4.33L9.83 3h4.34 0l1.33 1.33-1.67 2.89C13.24 7.08 12.63 7 12 7s-1.24.08-1.83.22L8.5 4.33zM12 21c-3.31 0-6-2.69-6-6 0-2.32 1.33-4.34 3.27-5.34a5.98 5.98 0 0 1 5.46 0c1.94 1 3.27 3.01 3.27 5.34 0 3.31-2.69 6-6 6z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHourglass = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.83 8.59C18.57 7.84 19 6.81 19 5.76V4a3.01 3.01 0 0 0-3-3H8a3.01 3.01 0 0 0-3 3v1.76c0 1.05.43 2.08 1.17 2.83l2.71 2.71a.99.99 0 0 1 .29.71.99.99 0 0 1-.29.71l-2.71 2.71C5.42 16.17 5 17.17 5 18.24V20a3.01 3.01 0 0 0 3 3h8a3.01 3.01 0 0 0 3-3v-1.76c0-1.07-.42-2.07-1.17-2.83l-2.71-2.71a.99.99 0 0 1-.29-.71.99.99 0 0 1 .29-.71l2.71-2.69zM17 20c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-1h10v1zm-3.29-5.88l2.71 2.71c.05.05.09.11.14.17H7.45l.14-.17 2.71-2.71a2.97 2.97 0 0 0 .88-2.12 2.97 2.97 0 0 0-.88-2.12L7.59 7.17C7.21 6.8 7 6.28 7 5.76V4c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v1.76c0 .53-.21 1.04-.59 1.41L13.7 9.88a2.97 2.97 0 0 0-.88 2.12c.01.8.32 1.55.89 2.12z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgIdCard = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 2.5H3c-1.66 0-3 1.34-3 3v13c0 1.66 1.34 3 3 3h3 12 3c1.66 0 3-1.34 3-3v-13c0-1.66-1.34-3-3-3zm-13 17v-2c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v2H8zm14-1c0 .55-.45 1-1 1h-3v-2c0-1.66-1.34-3-3-3H9c-1.66 0-3 1.34-3 3v2H3c-.55 0-1-.45-1-1v-13c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v13zm-10-12c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 1 1 0 3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInfoCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 18.62h0c-.55 0-1-.45-1-1v-6h0c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h1c.55 0 1 .45 1 1v7c0 .56-.45 1-1 1z\"/><circle cx=\"12\" cy=\"6.63\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInstagram = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2.98l4.44.06c1.07.05 1.65.23 2.04.38.51.2.88.44 1.26.82s.62.75.82 1.26c.15.39.33.97.38 2.04l.06 4.44-.06 4.44c-.05 1.07-.23 1.65-.38 2.04-.2.51-.44.88-.82 1.26s-.75.62-1.26.82c-.39.15-.97.33-2.04.38l-4.44.06c-2.93 0-3.29-.01-4.44-.06-1.07-.05-1.65-.23-2.04-.38-.51-.2-.88-.44-1.26-.82-.4-.35-.64-.72-.84-1.23-.15-.39-.33-.97-.38-2.04L2.98 12l.06-4.44c.06-1.08.23-1.66.38-2.05a3.34 3.34 0 0 1 .83-1.26 3.34 3.34 0 0 1 1.26-.83c.39-.15.97-.32 2.05-.37L12 2.98M12 1l-4.54.07c-1.17.05-1.97.24-2.67.51-.72.28-1.33.65-1.94 1.27-.62.61-.99 1.22-1.27 1.94-.27.7-.46 1.5-.51 2.67L1 12l.07 4.54c.05 1.17.24 1.97.51 2.67a5.42 5.42 0 0 0 1.27 1.95c.61.61 1.22.99 1.95 1.27.7.27 1.5.46 2.67.51L12 23l4.54-.07c1.17-.05 1.97-.24 2.67-.51a5.42 5.42 0 0 0 1.95-1.27c.61-.61.99-1.22 1.27-1.95.27-.7.46-1.5.51-2.67L23 12l-.07-4.54c-.05-1.17-.24-1.97-.51-2.67a5.42 5.42 0 0 0-1.27-1.95c-.61-.61-1.22-.99-1.95-1.27-.7-.27-1.5-.46-2.67-.51L12 1h0zm0 5.35a5.65 5.65 0 0 0 0 11.3 5.65 5.65 0 0 0 0-11.3zm0 9.32c-2.03 0-3.67-1.64-3.67-3.67S9.97 8.33 12 8.33s3.67 1.64 3.67 3.67-1.64 3.67-3.67 3.67z\"/><circle cx=\"17.87\" cy=\"6.13\" r=\"1.32\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInsuranceHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0zm3 10.83V10.5a3.01 3.01 0 0 0-3-3 3.01 3.01 0 0 0-3 3v1.57c-.86.22-1.5 1-1.5 1.93v3c0 1.1.9 2 2 2h5c1.1 0 2-.9 2-2v-3c0-.93-.64-1.71-1.5-1.93zM12 9.5c.55 0 1 .45 1 1V12h-2v-1.5c0-.55.45-1 1-1zm2.5 7.5h-5v-3h5v3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgJobs = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 6h-3a4 4 0 0 0-4-4h-2a4 4 0 0 0-4 4H4C2.34 6 1 7.34 1 9v4 7c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3zM9 6c0-1.1.9-2 2-2h2c1.1 0 2 .9 2 2H9zM4 8h16c.55 0 1 .45 1 1v3h-7c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1H3V9c0-.55.45-1 1-1zm16 13H4c-.55 0-1-.45-1-1v-6h7v1c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-1h7v6c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgKey = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8 19a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3 3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 8a7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7c.76 0 1.5.12 2.22.36l8.07-8.07c.19-.18.44-.29.71-.29h3c.55 0 1 .45 1 1v3c0 .27-.11.52-.29.71l-1 1c-.19.18-.44.29-.71.29h-1v1c0 .27-.11.52-.29.71l-1 1c-.19.18-.44.29-.71.29h-1v1c0 .27-.11.52-.29.71l-2.07 2.07c.24.72.36 1.46.36 2.22a7.01 7.01 0 0 1-7 7zm0-12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5c0-.71-.15-1.4-.45-2.05a1.01 1.01 0 0 1 .2-1.12L15 10.59V9c0-.55.45-1 1-1h1.59l.41-.41V6c0-.55.45-1 1-1h1.59l.41-.41V3h-1.59l-8.24 8.24a.99.99 0 0 1-1.12.2C9.4 11.15 8.71 11 8 11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgKeyhole = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3c2.76 0 5 2.24 5 5a4.99 4.99 0 0 1-1.84 3.87l-1 .82.33 1.25 1.9 7.06H7.61l1.89-7.07.33-1.25-1-.82C7.71 10.95 7.04 9.58 7 8.13c-.03-1.31.47-2.58 1.42-3.57.94-.98 2.19-1.54 3.49-1.56H12m0-2h-.12C8.06 1.06 4.9 4.36 5 8.18c.05 2.11 1.04 3.99 2.57 5.24l-1.89 7.07C5.33 21.75 6.29 23 7.61 23h8.79a2 2 0 0 0 1.93-2.52l-1.89-7.07C18 12.13 19 10.18 19 8c0-3.87-3.13-7-7-7h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLink = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 13H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm-8 6H7a7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7h2c.55 0 1 .45 1 1s-.45 1-1 1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h2c.55 0 1 .45 1 1s-.45 1-1 1zm8 0h-2c-.55 0-1-.45-1-1s.45-1 1-1h2c2.76 0 5-2.24 5-5s-2.24-5-5-5h-2c-.55 0-1-.45-1-1s.45-1 1-1h2a7.01 7.01 0 0 1 7 7 7.01 7.01 0 0 1-7 7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLinkedin = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" d=\"M21.41 1H2.66C1.76 1 1 1.71 1 2.58v18.83c0 .88.76 1.59 1.66 1.59h18.75c.9 0 1.59-.71 1.59-1.58V2.58A1.58 1.58 0 0 0 21.41 1zM9.71 9.25h2.98v1.5c.43-.86 1.53-1.63 3.18-1.63 3.17 0 3.92 1.71 3.92 4.86v5.82h-3.21v-5.11c0-1.79-.43-2.8-1.52-2.8-1.51 0-2.14 1.09-2.14 2.8v5.11H9.71V9.25zm-5.5 10.54h3.21V9.25H4.21v10.54zM7.87 5.81c0 1.14-.92 2.06-2.06 2.06s-2.06-.92-2.06-2.06.92-2.06 2.06-2.06 2.06.92 2.06 2.06z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgList = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 6H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h14c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-7 7H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h7c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm4 7H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h11c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/><circle cx=\"2.25\" cy=\"5\" r=\"1.25\"/><circle cx=\"2.25\" cy=\"12\" r=\"1.25\"/><circle cx=\"2.25\" cy=\"19\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgListCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm6 9h-7c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h7c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-5 4h-2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h2c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm3 4h-5c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h5c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/><circle cx=\"7\" cy=\"12\" r=\"1\"/><circle cx=\"7\" cy=\"8\" r=\"1\"/><circle cx=\"7\" cy=\"16\" r=\"1\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLoader = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12,24C5.38,24,0,18.62,0,12S5.38,0,12,0c0.55,0,1,0.45,1,1s-0.45,1-1,1C6.49,2,2,6.49,2,12s4.49,10,10,10s10-4.49,10-10   c0-0.55,0.45-1,1-1s1,0.45,1,1C24,18.62,18.62,24,12,24z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLocalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .26.02 6.46-6.35 8.68-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM12 8c1.1 0 2 .9 2 2 0 .61-.28 1.05-.51 1.31a2.94 2.94 0 0 0-.62 2.74l.52 1.95H10.6l.52-1.93c.27-.99.03-2.03-.62-2.77-.26-.29-.66-.92-.44-1.82.17-.69.75-1.26 1.44-1.42A2.2 2.2 0 0 1 12 8m0-2c-.31 0-.62.03-.94.11A3.98 3.98 0 0 0 8.12 9c-.34 1.4.06 2.7.88 3.63.22.25.28.61.19.93l-.85 3.19c-.17.63.31 1.25.96 1.25h5.39a1 1 0 0 0 .97-1.26l-.86-3.21c-.08-.31-.03-.65.18-.89A3.93 3.93 0 0 0 16 10a4 4 0 0 0-4-4h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 10V7c0-3.31-2.69-6-6-6S6 3.69 6 7v3c-1.66 0-3 1.34-3 3v7c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7c0-1.66-1.34-3-3-3zM8 7a4 4 0 1 1 8 0v3H8V7zm11 13c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLockOpen = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 10h0V7.21c0-3.05-2.19-5.77-5.21-6.16C9.54.63 6.7 2.84 6.11 5.84c-.12.6.38 1.16 1 1.16.48 0 .88-.34.98-.81.4-1.94 2.22-3.37 4.32-3.17 2.08.2 3.6 2.07 3.6 4.16V10H6c-1.66 0-3 1.34-3 3v7c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7c0-1.66-1.34-3-3-3zm1 10c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLogout = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.71 11.29L18.41 8c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41L18.59 11H12c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h6.59L17 14.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l3.29-3.29a1 1 0 0 0 .01-1.42zM15 23H7a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h8a4.02 4.02 0 0 1 3.8 2.74c.2.62-.29 1.26-.95 1.26h0c-.42 0-.82-.24-.95-.63C16.63 3.57 15.88 3 15 3H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 15 23z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMagicWand = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 6.59L17.41 5c-.78-.78-2.05-.78-2.83 0L2 17.59c-.78.78-.78 2.05 0 2.83L3.59 22c.39.39.9.58 1.41.58s1.02-.2 1.41-.58L19 9.41a1.99 1.99 0 0 0 0-2.82zm-3-.18L17.59 8 16.5 9.09 14.91 7.5 16 6.41zM5 20.59L3.41 19 13.5 8.91l1.59 1.59L5 20.59zM8 1l-.61 1.82a3 3 0 0 1-1.5 1.74L5 5l.89.44a3 3 0 0 1 1.5 1.74L8 9l.61-1.82a2.98 2.98 0 0 1 1.5-1.73L11 5l-.89-.44a3 3 0 0 1-1.5-1.74L8 1h0zm11 12l-.61 1.82a2.98 2.98 0 0 1-1.5 1.73L16 17l.89.44a3.01 3.01 0 0 1 1.5 1.73L19 21l.61-1.82a2.98 2.98 0 0 1 1.5-1.73L22 17l-.89-.44a3.01 3.01 0 0 1-1.5-1.73L19 13h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMagnifyingGlass = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 21.59l-6.83-6.83C17.31 13.31 18 11.49 18 9.5A8.51 8.51 0 0 0 9.5 1 8.51 8.51 0 0 0 1 9.5 8.51 8.51 0 0 0 9.5 18c1.99 0 3.81-.69 5.26-1.83L21.59 23c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41zM9.5 16C5.92 16 3 13.08 3 9.5S5.92 3 9.5 3 16 5.92 16 9.5 13.08 16 9.5 16z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMail = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 3H3C1.34 3 0 4.34 0 6v12c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3zM3 5h18c.55 0 1 .45 1 1v.07l-10 5.77L2 6.07V6c0-.55.45-1 1-1zm18 14H3c-.55 0-1-.45-1-1V8.38l9.5 5.48c.15.09.33.14.5.14s.35-.04.5-.13L22 8.38V18c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMapMarker = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3c3.58 0 6.5 2.92 6.5 6.5 0 .66-.1 1.32-.3 1.95-.1.31-.22.62-.39.95l-.45.78-4.09 7.08A1.46 1.46 0 0 1 12 21c-.52 0-1.01-.28-1.27-.73l-4.09-7.09-.45-.78a5.3 5.3 0 0 1-.39-.95c-.27-.86-.36-1.77-.26-2.68.35-3.15 3.11-5.68 6.29-5.77H12m0-2h-.23c-4.18.11-7.76 3.39-8.22 7.55-.13 1.23-.01 2.41.34 3.5.14.45.33.89.54 1.31l.48.83L9 21.27c.62 1.07 1.76 1.73 3 1.73h0c1.24 0 2.38-.66 3-1.73l4.09-7.08.48-.83c.21-.42.4-.85.54-1.31.25-.8.39-1.66.39-2.55A8.51 8.51 0 0 0 12 1h0zm0 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMedal = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 11.13l2.28 1.37-.6-2.59 2.01-1.74-2.65-.22L12 5.5l-1.04 2.44-2.64.23 2.01 1.74-.6 2.59L12 11.13zM21 9c0-4.97-4.03-9-9-9S3 4.03 3 9a8.94 8.94 0 0 0 2 5.65v6.74c0 1.13.93 2 2 2a1.86 1.86 0 0 0 .52-.07l3.71-.99a2.93 2.93 0 0 1 .78-.1 2.98 2.98 0 0 1 .78.1l3.71.99a1.91 1.91 0 0 0 .52.07c1.07 0 2-.87 2-2v-6.74A9.04 9.04 0 0 0 21 9zm-9-7a7.01 7.01 0 0 1 7 7 7.01 7.01 0 0 1-7 7 7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7zM5.87 15.57c.05.04.1.09.15.13a.64.64 0 0 1-.15-.13zm7.42 4.83c-.42-.11-.86-.17-1.29-.17-.44 0-.87.06-1.29.17L7 21.39v-4.91A8.94 8.94 0 0 0 12 18a8.94 8.94 0 0 0 5-1.52v4.91l-3.71-.99zm4.7-4.69c.05-.04.1-.09.15-.13-.05.04-.1.08-.15.13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 13H5c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h14c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinusCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm4 13H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinusSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1 12H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoped = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 15c.55 0 1 .45 1 1s-.45 1-1 1c-.56 0-1-.45-1-1s.44-1 1-1zm-15.6-.5h-.79l.62-1.24A4.98 4.98 0 0 1 7.7 10.5h2.91l-.69 2.05c-.21.65-.2 1.33.02 1.95H8.6 3.4zm3.46 2c-.17.3-.49.5-.86.5s-.7-.2-.86-.5h1.72zm9.17-.11C16.22 17.86 17.47 19 19 19a3.01 3.01 0 0 0 3-3c0-1.41-.97-2.58-2.27-2.9.18-.5.27-1.04.27-1.6v-.17a4.02 4.02 0 0 0-.57-2.06L17.76 6.5H18c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-2-1a.99.99 0 0 0-1 1c0 .55.44 1 1 1h.43l2.28 3.8a1.98 1.98 0 0 1 .29 1.03v.17a3.01 3.01 0 0 1-3 3h-2.23c-.33 0-.62-.16-.81-.42s-.24-.59-.14-.9l.69-2.05c.2-.61.1-1.28-.28-1.8-.37-.52-.98-.83-1.62-.83H7.7a6.97 6.97 0 0 0-6.26 3.87L.82 13.6a2.02 2.02 0 0 0 .09 1.95 2.01 2.01 0 0 0 1.7.95h.44A2.98 2.98 0 0 0 6 19a2.99 2.99 0 0 0 2.95-2.5H15a5 5 0 0 0 1.03-.11z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreHorizontal = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"5\" cy=\"12\" r=\"2\"/><circle cx=\"19\" cy=\"12\" r=\"2\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreHorizontalCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0z\"/><circle cx=\"12\" cy=\"12\" r=\"1.25\"/><circle cx=\"7.75\" cy=\"12\" r=\"1.25\"/><circle cx=\"16.25\" cy=\"12\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreVertical = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"12\" cy=\"5\" r=\"2\"/><circle cx=\"12\" cy=\"19\" r=\"2\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMotorcycle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 11c-.37 0-.72.07-1.06.16L16.25 6.5c-.54-.93-1.53-1.5-2.6-1.5H12c-.55 0-1 .45-1 1s.45 1 1 1h1.65c.36 0 .69.19.87.5l.28.5h-3.01a6.92 6.92 0 0 0-1.81.24L6.21 9.25c-.54-.75-1.29-1.3-2.19-1.54l-2.76-.74c-.53-.14-1.08.18-1.23.71-.14.53.17 1.08.71 1.22l2.76.74a1.96 1.96 0 0 1 1.21.93l.34.58C4.71 11.07 4.37 11 4 11a4 4 0 1 0 0 8c1.89 0 3.46-1.31 3.88-3.08.57.67 1.39 1.08 2.27 1.08h1.69c1.07 0 2.06-.58 2.6-1.5l2.35-4.06.41.71c-.74.72-1.2 1.73-1.2 2.85a4 4 0 1 0 8 0 4 4 0 0 0-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm8.71-2.5c-.18.31-.51.5-.87.5h-1.69c-.36 0-.69-.19-.87-.5l-2-3.47 3.21-.86c.42-.11.86-.17 1.29-.17h3.52l-2.59 4.5zM20 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgOdometer = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.42 17.91c1-1.75 1.58-3.76 1.58-5.91a12.03 12.03 0 0 0-1.59-5.96c-.01-.01-.01-.03-.02-.04-.01-.02-.03-.02-.04-.04-1.04-1.79-2.53-3.27-4.32-4.32-.01-.01-.01-.02-.03-.03-.01-.01-.03-.01-.04-.01C16.2.59 14.17 0 12 0h0 0l-.48.01C9.58.08 7.75.64 6.14 1.56c-.05.02-.1.02-.14.05-.06.03-.1.09-.16.13a12.3 12.3 0 0 0-4.1 4.11c-.05.05-.1.09-.13.15-.03.04-.03.08-.04.12C.66 7.71.11 9.52.02 11.43c-.01.16.01.31.01.47-.01.04-.03.07-.03.1 0 .04.02.07.02.11a11.92 11.92 0 0 0 1.56 5.81c.01.02.01.05.03.08.01.02.03.04.05.06a11.85 11.85 0 0 0 2.8 3.26c.53.44 1.21.68 1.9.68h11.28c.7 0 1.37-.24 1.91-.68 1.11-.9 2.04-2 2.77-3.23.02-.03.05-.06.07-.09s.02-.06.03-.09zm-4.13 1.86a1.01 1.01 0 0 1-.65.23H6.36a1.01 1.01 0 0 1-.65-.23c-.7-.57-1.31-1.23-1.82-1.93l.81-.47A1.01 1.01 0 0 0 5.07 16a1.01 1.01 0 0 0-1.37-.37l-.83.48c-.43-.97-.7-2.02-.81-3.11H3c.55 0 1-.45 1-1s-.45-1-1-1h-.94a9.94 9.94 0 0 1 .83-3.11l.82.47c.15.1.33.14.49.14.35 0 .68-.18.87-.5.28-.48.11-1.09-.37-1.37l-.8-.46c.63-.87 1.4-1.64 2.27-2.27l.46.8c.19.32.52.5.87.5a1.01 1.01 0 0 0 .5-.13 1.01 1.01 0 0 0 .37-1.37l-.47-.81c.96-.44 2.01-.72 3.1-.83V3c0 .55.45 1 1 1s1-.45 1-1v-.95a9.75 9.75 0 0 1 3.1.84l-.47.82A1 1 0 0 0 16 5.07a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5l.47-.81c.87.63 1.64 1.4 2.27 2.27l-.81.47c-.48.28-.65.89-.37 1.37.19.32.52.5.87.5a1.01 1.01 0 0 0 .5-.13l.81-.47a9.82 9.82 0 0 1 .84 3.1H21c-.55 0-1 .45-1 1s.45 1 1 1h.94a9.95 9.95 0 0 1-.83 3.1l-.82-.47c-.48-.28-1.09-.11-1.37.37s-.11 1.09.37 1.37l.81.47c-.51.7-1.11 1.36-1.81 1.93zM13 9.14V7c0-.55-.45-1-1-1s-1 .45-1 1v2.14c-1.72.45-3 2-3 3.86a4 4 0 1 0 8 0c0-1.86-1.28-3.41-3-3.86zM12 15c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPaperPlane = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.5 11.13L4.09 1.66c-.16-.09-.33-.14-.5-.14-.61 0-1.15.58-.97 1.26l2.4 8.96a.92.92 0 0 1 0 .52l-2.4 8.96c-.18.68.35 1.26.97 1.26.17 0 .34-.04.5-.14l16.41-9.47c.67-.39.67-1.35 0-1.74zM16.27 11H6.9L5.18 4.6 16.27 11zM6.9 13h9.37L5.18 19.4 6.9 13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPaperclip = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12,24c-3.86,0-7-3.14-7-7V5c0-2.76,2.24-5,5-5s5,2.24,5,5v12c0,1.65-1.35,3-3,3s-3-1.35-3-3V6c0-0.55,0.45-1,1-1   s1,0.45,1,1v11c0,0.55,0.45,1,1,1s1-0.45,1-1V5c0-1.65-1.35-3-3-3S7,3.35,7,5v12c0,2.76,2.24,5,5,5s5-2.24,5-5V6c0-0.55,0.45-1,1-1   s1,0.45,1,1v11C19,20.86,15.86,24,12,24z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPec = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M1.14 17.27V8.34h1.91V9c.27-.42.98-.82 1.9-.82 1.87 0 3 1.43 3 3.37 0 1.98-1.27 3.39-3.08 3.39-.83 0-1.46-.29-1.75-.65v2.98H1.14zm3.4-7.3c-.77 0-1.46.52-1.46 1.6 0 1.07.69 1.61 1.46 1.61.78 0 1.47-.53 1.47-1.61s-.69-1.6-1.47-1.6zm10.7 2.97C14.93 14.06 13.9 15 12.27 15c-1.78 0-3.37-1.27-3.37-3.45 0-2.08 1.55-3.41 3.22-3.41 2 0 3.24 1.24 3.24 3.32a4.59 4.59 0 0 1-.04.61h-4.5c.04.73.7 1.25 1.47 1.25.71 0 1.12-.34 1.3-.84l1.65.46zm-1.79-2.14c-.03-.49-.36-1.09-1.3-1.09-.83 0-1.25.61-1.29 1.09h2.59zm4.82.78c0 1.03.69 1.6 1.51 1.6.77 0 1.2-.48 1.34-.96l1.74.53c-.28 1.13-1.35 2.25-3.08 2.25-1.92 0-3.47-1.42-3.47-3.42 0-2.01 1.51-3.43 3.41-3.43 1.78 0 2.82 1.09 3.11 2.25l-1.77.53c-.14-.49-.53-.96-1.3-.96-.82 0-1.49.57-1.49 1.61z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPen = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.22 3.5L20.5 1.78a3 3 0 0 0-4.24 0L1.88 16.16c-.57.56-.88 1.32-.88 2.12V22c0 .55.45 1 1 1h3.72a2.97 2.97 0 0 0 2.12-.88L22.22 7.74a2.97 2.97 0 0 0 .88-2.12c0-.8-.32-1.55-.88-2.12zM6.43 20.71a1.05 1.05 0 0 1-.71.29H3v-2.72a1.05 1.05 0 0 1 .29-.71L14.74 6.13l3.13 3.13L6.43 20.71zM20.8 6.33l-1.52 1.52-3.13-3.13 1.52-1.52c.39-.39 1.02-.39 1.41 0l1.72 1.72a.99.99 0 0 1 .29.71c.01.26-.1.51-.29.7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPenForm = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 2.59L21.41 1c-.78-.78-2.05-.78-2.83 0l-9.29 9.29c-.18.19-.29.44-.29.71v3c0 .55.45 1 1 1h3c.27 0 .52-.11.71-.29L23 5.41a1.99 1.99 0 0 0 0-2.82zM12.59 13H11v-1.59l6-6L18.59 7l-6 6zM20 5.59L18.41 4 20 2.41 21.59 4 20 5.59zM17 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h5.76l2-2H4C2.34 5 1 6.34 1 8v12c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7.76l-2 2V20zM6 19h8c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPhone = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.02 21.68c-.28 0-.56-.03-.83-.09A17.98 17.98 0 0 1 2.41 7.81 3.88 3.88 0 0 1 3.2 4.5C3.97 3.55 5.11 3 6.33 3h1.03c1.42 0 2.66 1.01 2.94 2.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.14 2.65 3.52 3.68l-.01-.02.08-.08c.71-.71 1.72-1.01 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.48.89zM6.33 5a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25c.58.13 1.17-.02 1.64-.4a2.01 2.01 0 0 0 .75-1.57v-1.03a1 1 0 0 0-.8-.98l-2.34-.47a.99.99 0 0 0-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.57-.79-.47-1.87.22-2.56.27-.27.37-.61.31-.94L8.34 5.8a1 1 0 0 0-.98-.8H6.33z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPhone24 = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16.02 21.68c-.28 0-.56-.03-.83-.09A17.98 17.98 0 0 1 1.41 7.81 3.88 3.88 0 0 1 2.2 4.5C2.97 3.55 4.11 3 5.33 3h1.03A3 3 0 0 1 9.3 5.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.14 2.65 3.52 3.68l-.01-.02.08-.08c.71-.71 1.72-1.02 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.48.89zM5.33 5a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25c.58.13 1.17-.02 1.64-.4a2.01 2.01 0 0 0 .75-1.57v-1.03a1 1 0 0 0-.8-.98l-2.34-.47c-.33-.06-.67.04-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.57-.79-.47-1.87.22-2.56.27-.27.37-.61.31-.94L7.34 5.8a1 1 0 0 0-.98-.8H5.33zm6.36-.57c-.03-.09-.05-.26-.05-.42 0-1.06.78-2.01 2.27-2.01 1.44 0 2.26.9 2.26 1.98 0 .71-.34 1.3-1.1 1.72l-.99.56c-.15.08-.33.26-.36.38h2.45V8h-4.55v-.1c0-1.11.33-1.97 1.41-2.6l.93-.55c.43-.24.59-.44.59-.78 0-.31-.24-.58-.66-.58-.47 0-.71.34-.71.72a1.17 1.17 0 0 0 .04.32h-1.53zm5.17.85l2.4-3.16h1.89v3.34H22V6.8h-.85V8h-1.62V6.8h-2.66V5.28zm2.67-1.46L18.3 5.46h1.22V3.82z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlate = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 5c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h18m0-2H3C1.34 3 0 4.34 0 6v12c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3h0zM3.98 9.95c.98-.02 1.49-.49 1.63-1.19h1.53v6.49H5.3v-4.04H3.98V9.95zm4.29 0c.98-.02 1.49-.49 1.63-1.19h1.53v6.49H9.59v-4.04H8.27V9.95zm4.33 3.18v-1.59h3.09v1.59H12.6zm4.27-3.18c.98-.02 1.49-.49 1.63-1.19h1.53v6.49h-1.84v-4.04h-1.32V9.95z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 11h-6V5c0-.55-.45-1-1-1s-1 .45-1 1v6H5c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1v-6h6c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlusCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm4 11h-3V8c0-.55-.45-1-1-1s-1 .45-1 1v3H8c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlusSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1 10h-3V8c0-.55-.45-1-1-1h0c-.55 0-1 .45-1 1v3H8c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h3v3c0 .55.45 1 1 1h0c.55 0 1-.45 1-1v-3h3c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPound = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.41 20l1.06-1.06A6.97 6.97 0 0 0 10.5 14H14v-2h-3.78c-.15-.52-.35-1.03-.63-1.51l-.95-1.65c-.56-.97-.56-2.16.01-3.03C9.4 4.68 10.65 4 12 4c1.59 0 3.03.94 3.67 2.4l1.83-.8C16.54 3.41 14.39 2 12 2 9.97 2 8.09 3.01 6.98 4.71 6 6.21 5.97 8.22 6.91 9.84l.95 1.65c.1.17.17.34.24.51H6v2h2.52c0 1.29-.49 2.56-1.45 3.52L6 18.59a2 2 0 0 0-.43 2.18A1.98 1.98 0 0 0 7.41 22H18v-2H7.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPrima = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm5.08 8.76c-.21-1.44-1.37-2.53-2.81-2.66-.72-.13-1.45-.13-2.15.02-.37.04-.65.36-.65.74.02.41.37.75.78.76.06 0 .13 0 .19-.02.47-.11.94-.12 1.42-.05 1 .09 1.77.98 1.7 2 .06.84.04 1.69-.07 2.54a1.76 1.76 0 0 1-1.82 1.6c-.82.07-1.64-.07-2.4-.42-.16-.04-.27-.2-.25-.37v-1.53-.76-.62-1.24-1.1c-.02-.44-.39-.79-.84-.8-.13.01-.25.03-.37.08L7.05 8.31c-.3.17-.5.39-.56.63-.05.2-.01.41.11.61.26.42.72.5 1.25.21L9.35 9v9.24a.8.8 0 0 0 .79.76h.04a.81.81 0 0 0 .81-.77v-3.38l.64.12 1.44.24a3.49 3.49 0 0 0 2.62-.6 1.56 1.56 0 0 0 .2-.16c.15-.13.29-.27.42-.42s.24-.31.34-.48.19-.35.26-.53c.04-.09.07-.19.09-.28l.08-.33c.04-.24.06-.48.08-.72l.04-.73v-.74l-.04-.74-.08-.72z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgQuestionCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0z\"/><circle cx=\"12\" cy=\"17.37\" r=\"1.25\"/><path d=\"M13 14.37h-2v-.1c0-1.3.73-2.47 1.95-3.14.77-.42 1.16-1.25 1.02-2.12a2.03 2.03 0 0 0-1.59-1.61c-.61-.11-1.2.04-1.66.43a1.93 1.93 0 0 0-.59.85c-.15.4-.48.69-.9.69h-.15a.93.93 0 0 1-.89-1.21c.22-.71.65-1.36 1.24-1.85 1.23-1.03 3-1.25 4.53-.45a3.64 3.64 0 0 1 1.5 1.46c1.16 2.09.37 4.53-1.55 5.57-.57.31-.91.83-.91 1.38v.1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgReload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 0c-.55 0-1 .45-1 1v2.08c-4.71-4.23-11.97-4.09-16.49.44-4.68 4.68-4.68 12.29 0 16.97A11.96 11.96 0 0 0 12 24a11.96 11.96 0 0 0 8.49-3.51c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0c-3.9 3.9-10.25 3.9-14.15 0s-3.9-10.25 0-14.15 10.25-3.9 14.15 0c.02.03.06.04.1.07H16c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgShieldCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85V13v.05c0 .06.09 6.42-6.35 8.67-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM11.5 16c-.26 0-.51-.1-.71-.29l-2.29-2.3c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l1.59 1.59L15.09 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSki = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.71 18.29c-.39-.39-1.02-.39-1.41 0l-.55.55c-1.26 1.26-3.11 1.75-4.83 1.29l-3.85-1.03 1.75-1.75a2.97 2.97 0 0 0 .78-2.9c-.28-1.05-1.07-1.84-2.12-2.12l-.86-.23c-.5-.13-.66-.54-.71-.71-.04-.17-.11-.6.26-.96l.32-.32 3.02 1.75A.87.87 0 0 0 15 12c.35 0 .68-.18.87-.5.28-.48.11-1.09-.37-1.37l-2.56-1.48 1.89-1.89c.36.16.76.24 1.17.24 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .51.14.98.36 1.4l-2.22 2.22-6.41-3.7c-.47-.28-1.08-.11-1.36.37-.27.48-.11 1.09.37 1.36l5.95 3.44c-.72.76-1 1.81-.73 2.83.28 1.05 1.07 1.84 2.12 2.12l.86.23c.5.13.66.54.71.71.04.17.11.6-.26.97l-2.56 2.56-7.58-2.03c-.53-.14-1.08.17-1.22.71-.14.53.17 1.08.71 1.22l13.65 3.66c.59.16 1.2.24 1.8.24 1.83 0 3.63-.72 4.96-2.05l.55-.55a1 1 0 0 0 .01-1.42zM16 3c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSmartphone = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16 2c.55 0 1 .45 1 1v18c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h8m0-2H8C6.34 0 5 1.34 5 3v18c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3h0zm-4 18.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 1 0 0-2.5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgStar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 4.93l1.67 4 .47 1.12 1.21.1 4.32.35-3.29 2.82-.92.79.28 1.19 1 4.22-3.7-2.25-1.04-.64-1.04.63-3.7 2.25 1-4.22.28-1.19-.92-.79-3.29-2.81 4.32-.35 1.21-.1.47-1.12 1.67-4m0-3.6a.98.98 0 0 0-.92.62L8.49 8.16l-6.71.54c-.89.07-1.24 1.18-.57 1.76l5.11 4.38-1.56 6.55c-.16.67.37 1.23.97 1.23.17 0 .35-.05.52-.15l5.75-3.5 5.75 3.5c.17.1.35.15.52.15.6 0 1.13-.56.97-1.23l-1.56-6.55 5.11-4.38c.67-.58.32-1.68-.57-1.76l-6.71-.54-2.59-6.21a.98.98 0 0 0-.92-.62h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgStarFilled = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.92 1.95l2.59 6.21 6.71.54c.89.07 1.24 1.18.57 1.76l-5.11 4.38 1.56 6.55c.21.86-.73 1.55-1.49 1.08L12 18.97l-5.75 3.5c-.76.46-1.7-.22-1.49-1.08l1.56-6.55-5.11-4.38c-.68-.58-.32-1.69.57-1.76l6.71-.54 2.59-6.21a1 1 0 0 1 1.84 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSteeringWheel = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 0a12 12 0 1 0 0 24 12 12 0 1 0 0-24zm0 2c3.69 0 6.91 2.02 8.65 5H3.35C5.09 4.02 8.31 2 12 2zM2.2 14h2.52a3 3 0 0 1 2.94 2.41l1 5C5.4 20.25 2.91 17.45 2.2 14zm8.6 7.92l-1.18-5.9C9.16 13.69 7.1 12 4.72 12H2a9.91 9.91 0 0 1 .46-3h19.08a9.91 9.91 0 0 1 .46 3h-2.72c-2.38 0-4.44 1.69-4.9 4.02l-1.18 5.9c-.4.05-.79.08-1.2.08s-.8-.03-1.2-.08zm4.54-.5l1-5c.28-1.4 1.52-2.41 2.94-2.41h2.52c-.71 3.44-3.2 6.24-6.46 7.41z\"/><circle cx=\"12\" cy=\"12\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSwitch = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 22c-.39.39-1.02.39-1.41 0L1.3 15.71c-.29-.29-.37-.72-.22-1.09S1.6 14 2 14h20c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1H4.41L9 20.59c.39.39.39 1.02 0 1.41h0zm13-12H2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h17.59L15 3.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l6.29 6.29c.29.29.37.72.22 1.09S22.4 10 22 10z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTablet = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 2c.55 0 1 .45 1 1v18c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h12m0-2H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3h0zm-6 18.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 1 0 0-2.5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgThumbUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.1 11.09l-2.96-.79c-.44-.12-.74-.51-.74-.97V4c0-1.66-1.34-3-3-3h-.03c-.71 0-1.37.38-1.73 1l-4.85 8.4c-.18.31-.51.5-.87.5H4c-1.66 0-3 1.34-3 3V20c0 1.66 1.34 3 3 3h2.5 11.21a3 3 0 0 0 2.9-2.22l1.61-6.02c.43-1.6-.52-3.24-2.12-3.67zM3 20v-6.1c0-.55.45-1 1-1h1.5V21H4c-.55 0-1-.45-1-1zm17.29-5.76l-1.61 6.02c-.12.44-.51.74-.97.74H7.5v-8.56c.41-.26.77-.6 1.02-1.04L13.37 3h.03c.55 0 1 .45 1 1v5.33a3 3 0 0 0 2.22 2.9l2.96.79c.54.14.85.69.71 1.22z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTrashBin = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 1h-4c-1.1 0-2 .9-2 2h8c0-1.1-.9-2-2-2zm7 3H3c-.55 0-1 .45-1 1s.45 1 1 1h.54v12c0 2.76 2.24 5 5 5h7c2.76 0 5-2.24 5-5V6H21c.55 0 1-.45 1-1s-.45-1-1-1zm-2.46 14a3.01 3.01 0 0 1-3 3h-7a3.01 3.01 0 0 1-3-3V6h13v12zM9 19c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v9c0 .55.45 1 1 1zm6 0c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v9c0 .55.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTshirtSport = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.4 3.73l1.06 1.06c.94.94 2.2 1.46 3.54 1.46s2.59-.52 3.54-1.46l1.06-1.06 3.4.91V8.5h-1-2v2 10H7v-10-2H5 4V4.64l3.4-.91M16 1.5l-1.88 1.88a3 3 0 0 1-2.12.88c-.77 0-1.54-.29-2.12-.88L8 1.5 3.48 2.71C2.61 2.94 2 3.74 2 4.64V8.5c0 1.1.9 2 2 2h1v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-10h1c1.1 0 2-.9 2-2V4.64c0-.91-.61-1.7-1.48-1.93L16 1.5h0zm-5 16v-6H9v-1.29c1.33-.01 2.15-.76 2.33-1.71H13v9h-2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTwitter = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.54 7.1l.01.64c0 6.51-4.95 14.01-14.01 14.01h0c-2.68 0-5.3-.76-7.55-2.21.39.05.78.07 1.17.07 2.22 0 4.37-.74 6.12-2.11-2.11-.04-3.95-1.41-4.6-3.42A5.04 5.04 0 0 0 4.9 14C2.6 13.54.95 11.52.95 9.17v-.06a4.92 4.92 0 0 0 2.25.61C1.03 8.28.36 5.4 1.67 3.15c2.5 3.08 6.19 4.95 10.15 5.14a4.93 4.93 0 0 1 1.42-4.7 4.94 4.94 0 0 1 6.97.21 9.86 9.86 0 0 0 3.13-1.2c-.37 1.14-1.14 2.11-2.16 2.72A9.61 9.61 0 0 0 24 4.55a9.78 9.78 0 0 1-2.46 2.55z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUmbrella = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 1h-.21C5.74 1.11 1 6.31 1 12.36v.02c0 .58.48 1 1 1 .15 0 .3-.03.45-.11l.76-.38a4.02 4.02 0 0 1 3.58 0l1.69.85c.14.07.29.11.45.11a.99.99 0 0 0 .55-.17l.3-.2a4.03 4.03 0 0 1 1.22-.54V20c0 .55-.45 1-1 1s-1-.45-1-1-.45-1-1-1-1 .45-1 1a3.01 3.01 0 0 0 3 3 3.01 3.01 0 0 0 3-3v-7.06a3.8 3.8 0 0 1 1.22.54l.3.2c.17.11.36.17.55.17.15 0 .31-.03.45-.11l1.69-.85a4.02 4.02 0 0 1 3.58 0l.76.38c.15.07.3.11.45.11.52 0 1-.42 1-1V12c0-6.08-4.92-11-11-11zm7 9.47c-.93 0-1.85.22-2.68.63l-1.18.59c-.95-.58-2.03-.89-3.14-.89s-2.19.31-3.14.89l-1.18-.59c-.83-.41-1.75-.63-2.68-.63a5.95 5.95 0 0 0-1.87.3c.73-4.33 4.35-7.69 8.7-7.77H12c4.55 0 8.32 3.4 8.92 7.79-.62-.21-1.27-.32-1.92-.32z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUpload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 23H6a4.02 4.02 0 0 1-3.8-2.74c-.2-.62.29-1.26.95-1.26h0c.42 0 .82.24.95.63.27.8 1.02 1.37 1.9 1.37h12c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 18 23zM16 5.59L12.71 2.3c-.2-.2-.45-.3-.71-.3s-.51.1-.71.29L8 5.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L11 5.41V16c0 .55.45 1 1 1s1-.45 1-1V5.41L14.59 7c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUser = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C9.24 1 7 3.24 7 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm5 14c1.1 0 2 .9 2 2v4H5v-4c0-1.1.9-2 2-2h10m0-2H7a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4a4 4 0 0 0-4-4h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm6 16c-.26 0-.51-.1-.71-.29L14 14.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L17 14.59 20.59 11c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3zm2 3c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/><path d=\"M17 20.65A9.9 9.9 0 0 1 12 22c-1.82 0-3.53-.49-5-1.34V18a.99.99 0 0 1 1-1h8c.55 0 1 .44 1 1v2.65zM5 19.14C3.15 17.32 2 14.79 2 12 2 6.48 6.48 2 12 2c5.51 0 10 4.48 10 10 0 2.79-1.15 5.32-3 7.14V18c0-1.66-1.35-3-3-3H8c-1.66 0-3 1.34-3 3v1.14zm0 2.6c.62.46 1.29.85 2 1.17 1.52.7 3.22 1.09 5 1.09s3.48-.39 5-1.09a11.13 11.13 0 0 0 2-1.17c3.03-2.18 5-5.73 5-9.74 0-6.63-5.38-12-12-12A12 12 0 0 0 0 12c0 4.01 1.97 7.56 5 9.74z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCross = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm9.89 15.89h0c.39-.39.39-1.02 0-1.41L19.41 14l1.48-1.48c.39-.39.39-1.02 0-1.41h0c-.39-.39-1.02-.39-1.41 0L18 12.59l-1.48-1.48c-.39-.39-1.02-.39-1.41 0h0c-.39.39-.39 1.02 0 1.41L16.59 14l-1.48 1.48c-.39.39-.39 1.02 0 1.41h0c.39.39 1.02.39 1.41 0L18 15.41l1.48 1.48c.39.39 1.02.39 1.41 0zM19 20c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserMinus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm8 19c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1zm2-4h-6c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h6c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserPlus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm7 17.09h0c.55 0 1-.45 1-1V15h2.09c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H19v-2.09c0-.55-.45-1-1-1h0c-.55 0-1 .45-1 1V13h-2.09c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1H17v2.09c0 .55.45 1 1 1zM19 20c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUsers = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10 11c.98 0 1.88-.29 2.65-.77-.21.13-.42.25-.65.35a4.94 4.94 0 0 0 2 .42c2.76 0 5-2.24 5-5s-2.24-5-5-5a4.94 4.94 0 0 0-2 .42c.23.1.44.22.65.35C11.88 1.29 10.98 1 10 1 7.24 1 5 3.24 5 6s2.24 5 5 5zm2.99-1.01l.26-.21-.26.21zM14 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3c.61-.84 1-1.87 1-3s-.39-2.16-1.02-3zm-.76-.78l-.26-.21.26.21zM10 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3zm9 10h-4c.24 0 .48.03.71.07-.23-.04-.47-.07-.71-.07H5a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-4c0-.73-.21-1.41-.55-2H19c1.1 0 2 .9 2 2v4h-2c0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h4c1.1 0 2-.9 2-2v-4a4 4 0 0 0-4-4zm-2 8H3v-4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v4zm-1.09-7.89a3.74 3.74 0 0 1 .54.17c-.18-.07-.36-.12-.54-.17zm.79.28c.13.06.26.13.38.21l-.38-.21zm.69.42l.16.13-.16-.13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVan = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.6 11.8L21.98 9c-.54-.93-1.53-1.5-2.6-1.5H18v-1c0-1.66-1.34-3-3-3H3c-1.66 0-3 1.34-3 3v9c0 1.1.9 2 2 2h.05a2.99 2.99 0 0 0 5.9 0h7.1a2.99 2.99 0 0 0 5.9 0H22c1.1 0 2-.9 2-2v-2.2c0-.52-.14-1.04-.4-1.5zM2 6.5c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v1 7.28a2.87 2.87 0 0 0-.58.72H7.58C7.06 14.61 6.11 14 5 14s-2.06.61-2.58 1.5H2v-9zM5 18c-.37 0-.67-.21-.85-.5-.09-.15-.15-.31-.15-.5 0-.55.45-1 1-1s1 .45 1 1c0 .19-.06.35-.15.5-.18.29-.48.5-.85.5zm13 0c-.37 0-.67-.21-.85-.5-.09-.15-.15-.31-.15-.5 0-.55.45-1 1-1s1 .45 1 1c0 .19-.06.35-.15.5-.18.29-.48.5-.85.5zm4-2.5h-1.42c-.52-.89-1.47-1.5-2.58-1.5V9.5h1.38c.36 0 .69.19.87.5l1.62 2.8c.09.15.13.32.13.5v2.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleCollisionKasko = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 7c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1zm4 0a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l1.5-2.6A1.01 1.01 0 0 1 23 2.53a1.01 1.01 0 0 1 .37 1.37l-1.5 2.6A1.02 1.02 0 0 1 21 7zm-8 0c-.35 0-.68-.18-.87-.5l-1.5-2.6c-.27-.48-.11-1.09.37-1.36.48-.28 1.09-.11 1.37.37l1.5 2.6c.28.48.11 1.09-.37 1.37-.16.08-.33.12-.5.12zm8.77 6l-2.4-.64 1.43-.83c.61-.35 1.3-.53 2-.53h.2c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-.2c-1.05 0-2.09.27-3 .8l-2.5 1.45-.72-1.25c-.54-.93-1.53-1.5-2.6-1.5H5.84c-.8 0-1.56.31-2.12.88l-2.5 2.49c-.76.76-1.05 1.87-.78 2.9l.67 2.5c.27.98 1 1.75 1.92 2.07a2.99 2.99 0 0 0 5.92.16h7.1A2.98 2.98 0 0 0 19 22c1.54 0 2.81-1.17 2.98-2.67A3 3 0 0 0 24 16.5v-.61c0-1.35-.92-2.54-2.23-2.89zM6.86 19.5c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm13 0c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm2.14-3c0 .36-.2.68-.49.86C20.97 16.54 20.05 16 19 16c-1.11 0-2.08.6-2.6 1.5H8.6C8.08 16.6 7.11 16 6 16c-1.05 0-1.97.54-2.51 1.35-.21-.13-.38-.34-.44-.59l-.67-2.51c-.1-.34 0-.71.25-.96l2.5-2.5a.99.99 0 0 1 .71-.29h8.14c.35 0 .69.19.86.5l1.1 1.9a2.01 2.01 0 0 0 1.22.93l4.09 1.1c.44.12.75.51.75.96v.61zm-8.8-5H8.4a.99.99 0 0 0-1 1c0 .55.44 1 1 1h4.8c.55 0 1-.45 1-1 0-.56-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleDriverInjuries = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19.07 12l1.41-1.41c1.56-1.56 1.56-4.09 0-5.66l-1.41-1.41a3.98 3.98 0 0 0-2.83-1.17 3.98 3.98 0 0 0-2.83 1.17L12 4.93l-1.41-1.41c-.79-.79-1.81-1.18-2.83-1.18a4 4 0 0 0-2.83 1.17L3.51 4.93c-1.56 1.56-1.56 4.09 0 5.66L4.93 12l-1.41 1.41c-1.56 1.56-1.56 4.09 0 5.66l1.41 1.41a3.98 3.98 0 0 0 2.83 1.17 3.98 3.98 0 0 0 2.83-1.17l7.07-7.07 1.41 1.41c.78.78.78 2.05 0 2.83l-1.41 1.41c-.38.38-.88.59-1.41.59s-1.04-.21-1.41-.59l-1.41-1.41L12 19.07l1.41 1.41a3.98 3.98 0 0 0 2.83 1.17 3.98 3.98 0 0 0 2.83-1.17l1.41-1.41c1.56-1.56 1.56-4.09 0-5.66L19.07 12zM4.93 9.17c-.38-.38-.59-.88-.59-1.41s.21-1.04.59-1.41l1.41-1.41c.38-.38.88-.59 1.41-.59s1.04.21 1.41.59l1.41 1.41-4.24 4.24-1.4-1.42zm4.24 9.9c-.38.38-.88.59-1.41.59s-1.04-.21-1.41-.59l-1.41-1.41c-.78-.78-.78-2.05 0-2.83l9.9-9.9c.38-.38.88-.59 1.41-.59s1.04.21 1.41.59l1.41 1.41c.38.38.59.88.59 1.41s-.21 1.04-.59 1.41l-9.9 9.91zm3.54-10.19c-.26 0-.51.1-.71.29L9.17 12c-.39.39-.39 1.02 0 1.41l1.41 1.41a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29L14.83 12c.39-.39.39-1.02 0-1.41l-1.41-1.41a1.01 1.01 0 0 0-.71-.3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleFullKasko = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .26.02 6.46-6.35 8.68-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM11.5 16c-.26 0-.51-.1-.71-.29l-2.29-2.3c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l1.59 1.59L15.09 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleGlasses = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 4c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1h-9.65l1.25-4.68c.28-1.03.13-2.11-.4-3.04l-2.06-3.57L11.41 4H21m0-2H10.64c-.45 0-.85.3-.97.74L8.21 8.2c-.14.51-.07 1.06.2 1.52l2.06 3.57c.27.46.34 1.01.2 1.52l-1.59 5.94c-.17.63.31 1.25.97 1.25H21c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3h0zM5.27 4l-.99 3.68c-.28 1.03-.13 2.11.4 3.04l2.06 3.57L5.21 20H3c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2.27m1.3-2H3C1.34 2 0 3.34 0 5v14c0 1.66 1.34 3 3 3h2.98c.45 0 .85-.3.97-.74l1.73-6.46c.14-.51.07-1.06-.2-1.52L6.41 9.72c-.26-.46-.33-1.01-.2-1.52l1.32-4.94A.99.99 0 0 0 6.57 2h0zM15 12c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l4-4c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-4 4c-.19.19-.44.29-.7.29zm2 3c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l2-2c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-2 2c-.19.19-.44.29-.7.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleLegalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.65 21.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v6.81l1.96 1.13c.05-.51.04-.83.04-.83V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.94 2.39C3.75 4.02 3 4.88 3 5.89V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22.44 0 .89-.07 1.31-.22 2.68-.94 4.41-2.46 5.55-4.07l-1.75-1.01c-.94 1.27-2.34 2.45-4.46 3.19zm8.95-5.21l-5.63-3.25.5-.87a3 3 0 0 0-1.1-4.1l-.87-.5c-.47-.27-.99-.4-1.5-.4-1.04 0-2.05.54-2.6 1.5l-2 3.46a3 3 0 0 0 1.1 4.1l.87.5c.47.27.99.4 1.5.4 1.04 0 2.05-.54 2.6-1.5l.5-.87 5.63 3.25a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5.27-.46.1-1.07-.37-1.35zm-8.87-1.65c-.18.31-.51.5-.87.5-.18 0-.34-.05-.5-.13l-.87-.5c-.31-.18-.43-.46-.47-.61a1 1 0 0 1 .1-.76l2-3.46c.18-.31.51-.5.87-.5.18 0 .34.05.5.13l.87.5c.31.18.43.46.47.61a1 1 0 0 1-.1.76l-2 3.46z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleNaturalEvents = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.11 23a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l2.02-3.5H10c-.36 0-.69-.19-.87-.5s-.18-.69 0-1l2.89-5a1.01 1.01 0 0 1 1.37-.37 1.01 1.01 0 0 1 .37 1.37L11.73 16H15c.36 0 .69.19.87.5s.18.69 0 1l-2.89 5c-.19.32-.52.5-.87.5zm6.88-15.86c0-.05.01-.09.01-.14 0-3.31-2.69-6-6-6-2.62 0-4.85 1.69-5.66 4.03C7.22 5.03 7.12 5 7 5c-2.76 0-5 2.24-5 5s2.24 5 5 5h.69l1.15-2H7a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 2.93-3l.27.03 1.52.1.5-1.44C9.78 4.08 11.3 3 13 3c2.19 0 3.98 1.77 4 3.96 0 .04-.01.09-.01.13l-.04 1.59 1.54.4C19.38 9.3 20 10.1 20 11c0 1.1-.9 2-2 2h-2.29c-.06.17-.13.34-.22.5l-.87 1.5H18a4 4 0 0 0 4-4 3.99 3.99 0 0 0-3.01-3.86z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleRicourseWaiver = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.12 7.12L14.88.88C14.32.32 13.55 0 12.76 0H7C5.34 0 4 1.34 4 3v2.18c.31-.11.65-.18 1-.18h1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21c0 .55-.45 1-1 1h-8.35c-.26.75-.67 1.42-1.19 2H19c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM15 7V3.83L18.17 7H15zm-9 9.14V13h1c.55 0 1-.45 1-1s-.45-1-1-1H6V9h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1v8.14c-1.72.45-3 2-3 3.86a4 4 0 1 0 8 0c0-1.86-1.28-3.41-3-3.86zM5 22c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleRoadsideAssistance = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.59 11.8l-2.48-4.3c-.54-.93-1.53-1.5-2.6-1.5H15c-1.11 0-2 .89-2 2v2.59l-7.29-7.3c-.29-.28-.72-.37-1.09-.21C4.24 3.23 4 3.6 4 4v4.28c-.6.34-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V6.41l6.09 6.09H2c-1.11 0-2 .89-2 2v2a2 2 0 0 0 2 2h.05A2.98 2.98 0 0 0 5 21a2.99 2.99 0 0 0 2.95-2.5H13h2 .05A2.98 2.98 0 0 0 18 21a2.99 2.99 0 0 0 2.95-2.5H22c1.1 0 2-.9 2-2v-3.2a2.94 2.94 0 0 0-.41-1.5zM5.86 18.5c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm7.14-2H7.6C7.08 15.6 6.11 15 5 15s-2.08.6-2.6 1.5H2v-2h11v2zm5.86 2c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm3.14-2h-1.4c-.52-.9-1.49-1.5-2.6-1.5s-2.08.6-2.6 1.5H15v-4V8h3.51c.36 0 .69.19.87.5l2.48 4.3c.09.15.14.33.14.5v3.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleSecuredBonus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85V13v.05c0 .06.09 6.42-6.35 8.67-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zm0 13.4L14.6 16l-.69-2.96 2.3-1.99-3.03-.26L12 8l-1.18 2.79-3.03.26 2.3 1.99L9.4 16l2.6-1.57z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleTheftFire = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 4h-3.09l2.88-1.66a1.01 1.01 0 0 0 .37-1.37A1.01 1.01 0 0 0 17.79.6L14 2.78C13.48 2.3 12.78 2 12 2c-1.33 0-2.44.87-2.84 2.07C6.25 4.47 4 6.98 4 10v8c0 .55.45 1 1 1s1-.45 1-1v-8c0-1.94 1.38-3.55 3.21-3.92.08.21.18.42.31.6C8.02 7.55 7 9.15 7 11v11c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V11c0-1.85-1.02-3.45-2.52-4.32a2.85 2.85 0 0 0 .33-.68H19c.55 0 1-.45 1-1s-.45-1-1-1zm-4 18H9V11a3.01 3.01 0 0 1 3-3 3.01 3.01 0 0 1 3 3v11zM11 5c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleVandalism = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.06 1.22a4.61 4.61 0 0 0-3.63 1.79l-9.97 13c-.27.35-.65.6-1.07.71l-.69.19c-.7.19-1.23.72-1.41 1.41a1.97 1.97 0 0 0 .52 1.93l1.79 1.79c.38.38.88.59 1.41.59a1.98 1.98 0 0 0 .53-.07c.7-.19 1.23-.72 1.41-1.41l.18-.69c.11-.42.37-.8.71-1.07l13-9.97c1.12-.86 1.79-2.21 1.79-3.63 0-2.52-2.05-4.57-4.57-4.57zM5.2 19.94l-.18.69-1.79-1.79.69-.18c.33-.09.64-.23.93-.4l.75.75c-.17.3-.31.6-.4.93zM19.63 7.83L6.96 17.55l-.66-.66 9.72-12.67c.48-.63 1.25-1 2.04-1a2.57 2.57 0 0 1 2.57 2.57c0 .79-.37 1.56-1 2.04zM5 4h6c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zM3 7h6c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1s.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWallet = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 6.18v-.86a2.99 2.99 0 0 0-1.17-2.38 3 3 0 0 0-2.6-.52L3.79 6.02C2.23 6.13 1 7.42 1 9v11c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.3-.84-2.4-2-2.82zm-3.26-1.83c.3-.08.62-.02.87.17a1.02 1.02 0 0 1 .39.8V6h-7.41l6.15-1.65zM21 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v11z\"/><circle cx=\"17.5\" cy=\"14.5\" r=\"1.5\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWhatsapp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.5 3.49C18.25 1.24 15.25 0 12.05 0 5.46 0 .1 5.33.1 11.89a11.82 11.82 0 0 0 1.6 5.95L0 24l6.33-1.65a11.96 11.96 0 0 0 5.71 1.45h0C18.64 23.79 24 18.46 24 11.9c0-3.18-1.24-6.16-3.5-8.41zm-8.45 18.3h0c-1.79 0-3.53-.48-5.06-1.38l-.36-.21-3.76.98 1-3.65-.24-.37c-.99-1.57-1.52-3.39-1.52-5.26 0-5.45 4.46-9.88 9.94-9.88a9.87 9.87 0 0 1 7.02 2.9c1.88 1.87 2.91 4.35 2.91 6.99 0 5.44-4.46 9.88-9.93 9.88zm5.45-7.41c-.3-.15-1.77-.87-2.04-.97s-.47-.15-.67.15-.77.97-.95 1.17c-.17.2-.35.22-.65.07s-1.26-.46-2.4-1.47c-.89-.79-1.49-1.76-1.66-2.06s-.02-.46.13-.61c.13-.13.3-.35.45-.52s.2-.3.3-.5.05-.37-.02-.52-.68-1.6-.93-2.2c-.24-.58-.49-.5-.67-.51-.17-.01-.37-.01-.57-.01a1.1 1.1 0 0 0-.8.37c-.28.3-1.04 1.02-1.04 2.48s1.07 2.88 1.22 3.07c.15.2 2.11 3.2 5.1 4.49.71.31 1.27.49 1.7.63.72.23 1.37.19 1.88.12.57-.09 1.77-.72 2.02-1.41s.25-1.29.17-1.41c-.07-.13-.27-.21-.57-.36z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWrench = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14.35 3.99l.33.01-2.44 2.44a2.97 2.97 0 0 0-.88 2.12 2.97 2.97 0 0 0 .88 2.12l1.41 1.41a2.97 2.97 0 0 0 2.12.88 2.97 2.97 0 0 0 2.12-.88l2.45-2.45c.09 1.68-.52 3.35-1.74 4.57a5.96 5.96 0 0 1-4.24 1.75c-.22 0-.44-.01-.66-.04l-.95-.1-.68.68-3.37 3.37a.96.96 0 0 1-.7.3c-.15 0-.45-.04-.71-.29l-2.83-2.83c-.39-.39-.39-1.02 0-1.41l3.37-3.37.68-.68-.1-.95c-.23-2.09.62-4.11 2.29-5.41 1.02-.8 2.32-1.24 3.65-1.24m7.3 4.36h0 0m-7.3-6.36c-1.74 0-3.47.55-4.89 1.66-2.33 1.82-3.34 4.57-3.05 7.2l-3.37 3.37a3 3 0 0 0 0 4.24l2.83 2.83c.59.59 1.35.88 2.12.88s1.54-.29 2.12-.88l3.37-3.37.88.05c2.05 0 4.09-.78 5.65-2.34 2.27-2.27 2.88-5.55 1.86-8.38-.15-.41-.53-.62-.92-.62-.26 0-.52.1-.72.3l-3.75 3.75a.98.98 0 0 1-.71.29c-.26 0-.51-.1-.71-.29l-1.41-1.41c-.39-.39-.39-1.02 0-1.41l3.75-3.75c.53-.53.31-1.42-.4-1.66-.84-.31-1.74-.46-2.65-.46h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgYoutube = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.5 6.22c-.28-1.03-1.09-1.85-2.12-2.12-1.87-.5-9.38-.5-9.38-.5s-7.51 0-9.38.5C1.59 4.38.78 5.19.5 6.22 0 8.09 0 12 0 12s0 3.91.5 5.78c.28 1.03 1.09 1.85 2.12 2.12 1.87.5 9.38.5 9.38.5s7.51 0 9.38-.5c1.03-.28 1.85-1.09 2.12-2.12.5-1.87.5-5.78.5-5.78s0-3.91-.5-5.78z\"/><path d=\"M9.6 15.6l6.24-3.6L9.6 8.4z\" fill=\"#fff\"/></svg>';
var $author$project$Pyxis$Components$IconSet$toString = function (icon) {
	switch (icon) {
		case 0:
			return $author$project$Pyxis$Components$IconSet$svgAlarm;
		case 1:
			return $author$project$Pyxis$Components$IconSet$svgAndroid;
		case 2:
			return $author$project$Pyxis$Components$IconSet$svgApple;
		case 3:
			return $author$project$Pyxis$Components$IconSet$svgArrowDown;
		case 4:
			return $author$project$Pyxis$Components$IconSet$svgArrowLeft;
		case 5:
			return $author$project$Pyxis$Components$IconSet$svgArrowRight;
		case 6:
			return $author$project$Pyxis$Components$IconSet$svgArrowUp;
		case 7:
			return $author$project$Pyxis$Components$IconSet$svgBaloon;
		case 8:
			return $author$project$Pyxis$Components$IconSet$svgBell;
		case 9:
			return $author$project$Pyxis$Components$IconSet$svgBook;
		case 10:
			return $author$project$Pyxis$Components$IconSet$svgBuildings;
		case 11:
			return $author$project$Pyxis$Components$IconSet$svgBurger;
		case 12:
			return $author$project$Pyxis$Components$IconSet$svgCalendarClock;
		case 13:
			return $author$project$Pyxis$Components$IconSet$svgCalendarMail;
		case 14:
			return $author$project$Pyxis$Components$IconSet$svgCalendar;
		case 15:
			return $author$project$Pyxis$Components$IconSet$svgCamera;
		case 16:
			return $author$project$Pyxis$Components$IconSet$svgCar;
		case 17:
			return $author$project$Pyxis$Components$IconSet$svgCardsOverlap;
		case 18:
			return $author$project$Pyxis$Components$IconSet$svgCart;
		case 19:
			return $author$project$Pyxis$Components$IconSet$svgChart;
		case 20:
			return $author$project$Pyxis$Components$IconSet$svgChat;
		case 21:
			return $author$project$Pyxis$Components$IconSet$svgCheckCircle;
		case 22:
			return $author$project$Pyxis$Components$IconSet$svgCheckSquare;
		case 23:
			return $author$project$Pyxis$Components$IconSet$svgCheck;
		case 24:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleDown;
		case 25:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleLeft;
		case 26:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleRight;
		case 27:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleUp;
		case 28:
			return $author$project$Pyxis$Components$IconSet$svgChevronDown;
		case 29:
			return $author$project$Pyxis$Components$IconSet$svgChevronLeft;
		case 30:
			return $author$project$Pyxis$Components$IconSet$svgChevronRight;
		case 31:
			return $author$project$Pyxis$Components$IconSet$svgChevronUp;
		case 32:
			return $author$project$Pyxis$Components$IconSet$svgClock24;
		case 33:
			return $author$project$Pyxis$Components$IconSet$svgClock;
		case 34:
			return $author$project$Pyxis$Components$IconSet$svgComputer;
		case 35:
			return $author$project$Pyxis$Components$IconSet$svgCreditCard;
		case 36:
			return $author$project$Pyxis$Components$IconSet$svgCrossCircle;
		case 37:
			return $author$project$Pyxis$Components$IconSet$svgCrossSquare;
		case 38:
			return $author$project$Pyxis$Components$IconSet$svgCross;
		case 39:
			return $author$project$Pyxis$Components$IconSet$svgDamageThirdParties;
		case 40:
			return $author$project$Pyxis$Components$IconSet$svgDevices;
		case 41:
			return $author$project$Pyxis$Components$IconSet$svgDiamond;
		case 42:
			return $author$project$Pyxis$Components$IconSet$svgDocumentAlert;
		case 43:
			return $author$project$Pyxis$Components$IconSet$svgDocumentSigned;
		case 44:
			return $author$project$Pyxis$Components$IconSet$svgDocument;
		case 45:
			return $author$project$Pyxis$Components$IconSet$svgDownload;
		case 46:
			return $author$project$Pyxis$Components$IconSet$svgEuro;
		case 47:
			return $author$project$Pyxis$Components$IconSet$svgExclamationCircle;
		case 48:
			return $author$project$Pyxis$Components$IconSet$svgExclamationTriangle;
		case 49:
			return $author$project$Pyxis$Components$IconSet$svgExternalLink;
		case 50:
			return $author$project$Pyxis$Components$IconSet$svgEyeSlashed;
		case 51:
			return $author$project$Pyxis$Components$IconSet$svgEye;
		case 52:
			return $author$project$Pyxis$Components$IconSet$svgFacebook;
		case 53:
			return $author$project$Pyxis$Components$IconSet$svgFamilyRc;
		case 54:
			return $author$project$Pyxis$Components$IconSet$svgFax;
		case 55:
			return $author$project$Pyxis$Components$IconSet$svgFloppyDisk;
		case 56:
			return $author$project$Pyxis$Components$IconSet$svgFolder;
		case 57:
			return $author$project$Pyxis$Components$IconSet$svgGear;
		case 58:
			return $author$project$Pyxis$Components$IconSet$svgGift;
		case 59:
			return $author$project$Pyxis$Components$IconSet$svgGrid;
		case 60:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageEbike;
		case 61:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageFamily;
		case 62:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamagePets;
		case 63:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageThirdParties;
		case 64:
			return $author$project$Pyxis$Components$IconSet$svgHomeDomesticThefts;
		case 65:
			return $author$project$Pyxis$Components$IconSet$svgHomeEmergenciesRepairs;
		case 66:
			return $author$project$Pyxis$Components$IconSet$svgHomeFireExplosion;
		case 67:
			return $author$project$Pyxis$Components$IconSet$svgHomeFurnitureContent;
		case 68:
			return $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFamily;
		case 69:
			return $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFreetime;
		case 70:
			return $author$project$Pyxis$Components$IconSet$svgHomeInsuranceHome;
		case 71:
			return $author$project$Pyxis$Components$IconSet$svgHomeLegalProtection;
		case 72:
			return $author$project$Pyxis$Components$IconSet$svgHomeNaturalEvents;
		case 73:
			return $author$project$Pyxis$Components$IconSet$svgHomeRobberiesOutside;
		case 74:
			return $author$project$Pyxis$Components$IconSet$svgHomeTelephoneLegalAdvice;
		case 75:
			return $author$project$Pyxis$Components$IconSet$svgHomeTheftValuable;
		case 76:
			return $author$project$Pyxis$Components$IconSet$svgHome;
		case 77:
			return $author$project$Pyxis$Components$IconSet$svgHourglass;
		case 78:
			return $author$project$Pyxis$Components$IconSet$svgIdCard;
		case 79:
			return $author$project$Pyxis$Components$IconSet$svgInfoCircle;
		case 80:
			return $author$project$Pyxis$Components$IconSet$svgInstagram;
		case 81:
			return $author$project$Pyxis$Components$IconSet$svgInsuranceHome;
		case 82:
			return $author$project$Pyxis$Components$IconSet$svgJobs;
		case 83:
			return $author$project$Pyxis$Components$IconSet$svgKey;
		case 84:
			return $author$project$Pyxis$Components$IconSet$svgKeyhole;
		case 85:
			return $author$project$Pyxis$Components$IconSet$svgLink;
		case 86:
			return $author$project$Pyxis$Components$IconSet$svgLinkedin;
		case 87:
			return $author$project$Pyxis$Components$IconSet$svgListCircle;
		case 88:
			return $author$project$Pyxis$Components$IconSet$svgList;
		case 89:
			return $author$project$Pyxis$Components$IconSet$svgLoader;
		case 90:
			return $author$project$Pyxis$Components$IconSet$svgLocalProtection;
		case 91:
			return $author$project$Pyxis$Components$IconSet$svgLockOpen;
		case 92:
			return $author$project$Pyxis$Components$IconSet$svgLock;
		case 93:
			return $author$project$Pyxis$Components$IconSet$svgLogout;
		case 94:
			return $author$project$Pyxis$Components$IconSet$svgMagicWand;
		case 95:
			return $author$project$Pyxis$Components$IconSet$svgMagnifyingGlass;
		case 96:
			return $author$project$Pyxis$Components$IconSet$svgMail;
		case 97:
			return $author$project$Pyxis$Components$IconSet$svgMapMarker;
		case 98:
			return $author$project$Pyxis$Components$IconSet$svgMedal;
		case 99:
			return $author$project$Pyxis$Components$IconSet$svgMinusCircle;
		case 100:
			return $author$project$Pyxis$Components$IconSet$svgMinusSquare;
		case 101:
			return $author$project$Pyxis$Components$IconSet$svgMinus;
		case 102:
			return $author$project$Pyxis$Components$IconSet$svgMoped;
		case 103:
			return $author$project$Pyxis$Components$IconSet$svgMoreHorizontalCircle;
		case 104:
			return $author$project$Pyxis$Components$IconSet$svgMoreHorizontal;
		case 105:
			return $author$project$Pyxis$Components$IconSet$svgMoreVertical;
		case 106:
			return $author$project$Pyxis$Components$IconSet$svgMotorcycle;
		case 107:
			return $author$project$Pyxis$Components$IconSet$svgOdometer;
		case 108:
			return $author$project$Pyxis$Components$IconSet$svgPaperPlane;
		case 109:
			return $author$project$Pyxis$Components$IconSet$svgPaperclip;
		case 110:
			return $author$project$Pyxis$Components$IconSet$svgPec;
		case 111:
			return $author$project$Pyxis$Components$IconSet$svgPenForm;
		case 112:
			return $author$project$Pyxis$Components$IconSet$svgPen;
		case 113:
			return $author$project$Pyxis$Components$IconSet$svgPhone24;
		case 114:
			return $author$project$Pyxis$Components$IconSet$svgPhone;
		case 115:
			return $author$project$Pyxis$Components$IconSet$svgPlate;
		case 116:
			return $author$project$Pyxis$Components$IconSet$svgPlusCircle;
		case 117:
			return $author$project$Pyxis$Components$IconSet$svgPlusSquare;
		case 118:
			return $author$project$Pyxis$Components$IconSet$svgPlus;
		case 119:
			return $author$project$Pyxis$Components$IconSet$svgPound;
		case 120:
			return $author$project$Pyxis$Components$IconSet$svgPrima;
		case 121:
			return $author$project$Pyxis$Components$IconSet$svgQuestionCircle;
		case 122:
			return $author$project$Pyxis$Components$IconSet$svgReload;
		case 123:
			return $author$project$Pyxis$Components$IconSet$svgShieldCheck;
		case 124:
			return $author$project$Pyxis$Components$IconSet$svgSki;
		case 125:
			return $author$project$Pyxis$Components$IconSet$svgSmartphone;
		case 126:
			return $author$project$Pyxis$Components$IconSet$svgStarFilled;
		case 127:
			return $author$project$Pyxis$Components$IconSet$svgStar;
		case 128:
			return $author$project$Pyxis$Components$IconSet$svgSteeringWheel;
		case 129:
			return $author$project$Pyxis$Components$IconSet$svgSwitch;
		case 130:
			return $author$project$Pyxis$Components$IconSet$svgTablet;
		case 131:
			return $author$project$Pyxis$Components$IconSet$svgThumbUp;
		case 132:
			return $author$project$Pyxis$Components$IconSet$svgTrashBin;
		case 133:
			return $author$project$Pyxis$Components$IconSet$svgTshirtSport;
		case 134:
			return $author$project$Pyxis$Components$IconSet$svgTwitter;
		case 135:
			return $author$project$Pyxis$Components$IconSet$svgUmbrella;
		case 136:
			return $author$project$Pyxis$Components$IconSet$svgUpload;
		case 137:
			return $author$project$Pyxis$Components$IconSet$svgUserCheck;
		case 138:
			return $author$project$Pyxis$Components$IconSet$svgUserCircle;
		case 139:
			return $author$project$Pyxis$Components$IconSet$svgUserCross;
		case 140:
			return $author$project$Pyxis$Components$IconSet$svgUserMinus;
		case 141:
			return $author$project$Pyxis$Components$IconSet$svgUserPlus;
		case 142:
			return $author$project$Pyxis$Components$IconSet$svgUser;
		case 143:
			return $author$project$Pyxis$Components$IconSet$svgUsers;
		case 144:
			return $author$project$Pyxis$Components$IconSet$svgVan;
		case 145:
			return $author$project$Pyxis$Components$IconSet$svgVehicleCollisionKasko;
		case 146:
			return $author$project$Pyxis$Components$IconSet$svgVehicleDriverInjuries;
		case 147:
			return $author$project$Pyxis$Components$IconSet$svgVehicleFullKasko;
		case 148:
			return $author$project$Pyxis$Components$IconSet$svgVehicleGlasses;
		case 149:
			return $author$project$Pyxis$Components$IconSet$svgVehicleLegalProtection;
		case 150:
			return $author$project$Pyxis$Components$IconSet$svgVehicleNaturalEvents;
		case 151:
			return $author$project$Pyxis$Components$IconSet$svgVehicleRicourseWaiver;
		case 152:
			return $author$project$Pyxis$Components$IconSet$svgVehicleRoadsideAssistance;
		case 153:
			return $author$project$Pyxis$Components$IconSet$svgVehicleSecuredBonus;
		case 154:
			return $author$project$Pyxis$Components$IconSet$svgVehicleTheftFire;
		case 155:
			return $author$project$Pyxis$Components$IconSet$svgVehicleVandalism;
		case 156:
			return $author$project$Pyxis$Components$IconSet$svgWallet;
		case 157:
			return $author$project$Pyxis$Components$IconSet$svgWhatsapp;
		case 158:
			return $author$project$Pyxis$Components$IconSet$svgWrench;
		default:
			return $author$project$Pyxis$Components$IconSet$svgYoutube;
	}
};
var $author$project$Pyxis$Components$Icon$render = function (configuration) {
	var ariaLabel = configuration.eV;
	var ariaLabelledby = configuration.eX;
	var icon = configuration.dE;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				$author$project$Pyxis$Components$Icon$getClassList(configuration)),
				$author$project$Pyxis$Commons$Attributes$ariaHidden(
				$elm_community$maybe_extra$Maybe$Extra$isNothing(ariaLabel) && $elm_community$maybe_extra$Maybe$Extra$isNothing(ariaLabelledby)),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabel, ariaLabel),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabelledBy, ariaLabelledby),
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$elm_community$maybe_extra$Maybe$Extra$isJust(ariaLabel) || $elm_community$maybe_extra$Maybe$Extra$isJust(ariaLabelledby),
				$author$project$Pyxis$Commons$Attributes$role('img')),
				A2(
				$elm$html$Html$Attributes$attribute,
				'data-name',
				$author$project$Pyxis$Components$IconSet$toLabel(icon))
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				$elm$core$Result$toMaybe(
					$Garados007$elm_svg_parser$SvgParser$parse(
						$author$project$Pyxis$Components$IconSet$toString(icon))))
			]));
};
var $author$project$Pyxis$Components$Icon$withStyle = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{hP: a});
	});
var $author$project$Pyxis$Components$Form$Legend$renderAddonByType = function (type_) {
	if (!type_.$) {
		var icon = type_.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-legend__addon')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withStyle,
						$author$project$Pyxis$Components$Icon$brand,
						$author$project$Pyxis$Components$Icon$config(icon)))
				]));
	} else {
		var url = type_.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-legend__addon')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(url),
							$elm$html$Html$Attributes$height(80)
						]),
					_List_Nil)
				]));
	}
};
var $author$project$Pyxis$Components$Form$Legend$renderDescription = function (str) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-legend__text')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $author$project$Pyxis$Components$Form$Legend$renderTitle = function (str) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-legend__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $author$project$Pyxis$Commons$Attributes$testId = $elm$html$Html$Attributes$attribute('data-test-id');
var $author$project$Pyxis$Components$Form$Legend$render = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$legend,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('form-legend', true),
							_Utils_Tuple2(
							'form-legend--align-left',
							$author$project$Pyxis$Components$Form$Legend$isAlignedLeft(configuration.c_))
						]),
					configuration.c5)),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configuration.nM),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configuration.eA)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Legend$renderAddonByType, configuration.h6)),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Legend$renderTitle, configuration.eE)),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Legend$renderDescription, configuration.m2))
			]));
};
var $author$project$Pyxis$Components$Form$Legend$withAlignmentLeft = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{c_: 1});
};
var $author$project$Pyxis$Components$Form$Legend$withDescription = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				m2: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Pyxis$Components$Form$Legend$withTitle = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eE: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Preselection$InitializedPreselection$contentHeader = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Scegli di proteggere la tua casa, la tua famiglia o entrambe',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Cosa vuoi assicurare?', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Preselection$InitializedPreselection$FormTypeFieldChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Preselection$InitializedPreselection$OpenModal = {$: 2};
var $author$project$Pyxis$Components$Field$OptionCard$ImgUrlAddon = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$OptionCard$addonImage = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$CheckboxGroup$addonImage = $author$project$Pyxis$Components$Field$OptionCard$addonImage;
var $author$project$Pyxis$Components$Field$CheckboxGroup$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$CheckboxGroup$Default = 2;
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$config = function (name) {
	return {
		eQ: $elm$core$Maybe$Nothing,
		c5: _List_Nil,
		e9: _List_Nil,
		ff: false,
		b1: $elm$core$Maybe$Nothing,
		fy: $elm$core$Maybe$Nothing,
		nM: 'id-' + name,
		b8: false,
		n6: $elm$core$Maybe$Nothing,
		ce: 2,
		oq: name,
		ku: $author$project$Pyxis$Components$Field$CheckboxGroup$OptionList(_List_Nil),
		eA: $elm$core$Maybe$Nothing,
		cQ: $elm$core$Maybe$Nothing
	};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$Vertical = 1;
var $author$project$Pyxis$Components$Field$CheckboxGroup$layoutVertical = 1;
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCard = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$OptionCard$AddonSizeLType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$OptionCard$addonSizeLType = $author$project$Pyxis$Components$Field$OptionCard$AddonSizeLType;
var $author$project$Pyxis$Components$Field$OptionCard$L = 1;
var $author$project$Pyxis$Components$Field$OptionCard$sizeL = 1;
var $author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeL = function (_v0) {
	var addon = _v0.h6;
	var text = _v0.lG;
	var title = _v0.eE;
	var value = _v0.p8;
	return {
		h6: $elm$core$Maybe$Just(
			$author$project$Pyxis$Components$Field$OptionCard$addonSizeLType(addon)),
		ff: false,
		aH: $author$project$Pyxis$Components$Field$OptionCard$sizeL,
		lG: text,
		eE: title,
		p8: value
	};
};
var $author$project$Preselection$InitializedPreselection$pickIsFormSubmitted = function (_v0) {
	var isFormSubmitted = _v0.dM;
	return isFormSubmitted;
};
var $author$project$Pyxis$Components$Form$FormItem$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$FormItem$config = F2(
	function (fieldConfig, field) {
		return {eQ: $elm$core$Maybe$Nothing, ns: field, i9: fieldConfig, n6: $elm$core$Maybe$Nothing};
	});
var $author$project$Pyxis$Components$Field$Error$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Error$idFromFieldId = function (fieldId) {
	return fieldId + '-error';
};
var $author$project$Pyxis$Components$Field$Error$config = F3(
	function (id, validationResult, showingStrategy) {
		return {
			nM: $author$project$Pyxis$Components$Field$Error$idFromFieldId(id),
			n$: false,
			n0: false,
			b8: false,
			lr: showingStrategy,
			hX: validationResult
		};
	});
var $author$project$Pyxis$Commons$Basics$forwardValue = F2(
	function (value, _function) {
		return _function(value);
	});
var $author$project$Pyxis$Components$Field$Error$withIsBlurred = F2(
	function (isBlurred, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{n$: isBlurred});
	});
var $author$project$Pyxis$Components$Field$Error$withIsDirty = F2(
	function (isDirty, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{n0: isDirty});
	});
var $author$project$Pyxis$Components$Field$Error$withIsSubmitted = F2(
	function (isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{b8: isSubmitted});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var checkedValues = _v0.au;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(checkedValues),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Commons$ValidationResult$getErrorMessage = function (result) {
	if (!result.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var errorMessage = result.a;
		return $elm$core$Maybe$Just(errorMessage);
	}
};
var $author$project$Pyxis$Components$Field$Error$getErrorIf = F2(
	function (condition, validationResult) {
		return condition ? $author$project$Pyxis$Commons$ValidationResult$getErrorMessage(validationResult) : $elm$core$Maybe$Nothing;
	});
var $author$project$Pyxis$Components$Field$Error$getErrorMessage = F2(
	function (_v0, validationResult) {
		var showingStrategy = _v0.lr;
		var isSubmitted = _v0.b8;
		var isDirty = _v0.n0;
		var isBlurred = _v0.n$;
		switch (showingStrategy) {
			case 0:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted || isDirty, validationResult);
			case 1:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted || isBlurred, validationResult);
			default:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted, validationResult);
		}
	});
var $author$project$Pyxis$Components$Field$Error$isVisible = function (maybeConfig) {
	return $elm_community$maybe_extra$Maybe$Extra$isJust(
		A2(
			$elm$core$Maybe$andThen,
			function (config_) {
				var validationResult = config_.hX;
				return A2($author$project$Pyxis$Components$Field$Error$getErrorMessage, config_, validationResult);
			},
			maybeConfig));
};
var $author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible = F2(
	function (error, fieldId) {
		return $author$project$Pyxis$Components$Field$Error$isVisible(error) ? $elm$core$Maybe$Just(
			$author$project$Pyxis$Components$Field$Error$idFromFieldId(fieldId)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Pyxis$Components$Field$Label$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Label$withFor = F2(
	function (_for, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				du: $elm$core$Maybe$Just(_for)
			});
	});
var $author$project$Pyxis$Components$Field$Label$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nM: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Form$FormItem$customizeLabel = function (configData) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Label$withId(configData.nM + '-label'),
		$author$project$Pyxis$Components$Field$Label$withFor(configData.nM));
};
var $author$project$Pyxis$Components$Field$Label$getFormLabelSizeClass = function (size) {
	if (!size) {
		return 'form-label--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Tooltip$getTooltipPositionClass = function (position) {
	switch (position) {
		case 0:
			return 'tooltip--bottom';
		case 1:
			return 'tooltip--bottom-left';
		case 2:
			return 'tooltip--bottom-right';
		case 3:
			return 'tooltip--left';
		case 4:
			return 'tooltip--right';
		case 5:
			return 'tooltip--top';
		case 6:
			return 'tooltip--top-left';
		default:
			return 'tooltip--top-right';
	}
};
var $author$project$Pyxis$Components$Tooltip$getTooltipVariantClass = function (variant) {
	if (!variant) {
		return '';
	} else {
		return 'tooltip--brand';
	}
};
var $author$project$Pyxis$Components$Tooltip$getClassList = function (_v0) {
	var variant = _v0.h_;
	var theme = _v0.eB;
	var position = _v0.ee;
	var classList = _v0.c5;
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('tooltip', true),
				_Utils_Tuple2(
				'tooltip--alt',
				$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
				_Utils_Tuple2(
				$author$project$Pyxis$Components$Tooltip$getTooltipVariantClass(variant),
				true),
				_Utils_Tuple2(
				$author$project$Pyxis$Components$Tooltip$getTooltipPositionClass(position),
				true)
			]),
		classList);
};
var $author$project$Pyxis$Components$Icon$S = 0;
var $author$project$Pyxis$Components$Icon$sizeS = 0;
var $author$project$Pyxis$Components$Icon$withSize = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{aH: a});
	});
var $author$project$Pyxis$Components$Tooltip$renderIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Icon$config,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS),
		$author$project$Pyxis$Components$Icon$render));
var $author$project$Pyxis$Components$Tooltip$ShowModal = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$Basics$applyIfJust = F2(
	function (f, a) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Basics$identity,
			A2($elm$core$Maybe$map, f, a));
	});
var $author$project$Pyxis$Components$Modal$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$M = 1;
var $author$project$Pyxis$Components$Modal$config = {cW: $elm$core$Maybe$Nothing, c5: _List_Nil, mQ: _List_Nil, ds: $elm$core$Maybe$Nothing, dB: $elm$core$Maybe$Nothing, dK: false, d5: $elm$core$Maybe$Nothing, aH: 1, eA: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Modal$Header$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$Header$config = {mi: $elm$core$Maybe$Nothing, c5: _List_Nil, c7: $elm$core$Maybe$Nothing, df: $elm$core$Maybe$Nothing, dE: $elm$core$Maybe$Nothing, dR: false, eE: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Commons$Attributes$ariaDescribedBy = $elm$html$Html$Attributes$attribute('aria-describedby');
var $author$project$Pyxis$Components$Modal$contentId = function (id) {
	return id + '-content';
};
var $author$project$Pyxis$Components$Modal$getModalSizeClass = function (size) {
	switch (size) {
		case 0:
			return 'modal--s';
		case 1:
			return 'modal--m';
		default:
			return 'modal--l';
	}
};
var $author$project$Pyxis$Components$Modal$Footer$renderButtons = function (fullWidthButton) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal__footer__buttons', true),
						_Utils_Tuple2('modal__footer__buttons--full-width', fullWidthButton)
					]))
			]));
};
var $author$project$Pyxis$Components$Modal$Footer$renderText = function (text) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal__footer__text')
			]),
		_List_fromArray(
			[text]));
};
var $author$project$Pyxis$Components$Modal$Footer$render = function (_v0) {
	var classList = _v0.c5;
	var customContent = _v0.df;
	var isSticky = _v0.dR;
	var fullWidthButton = _v0.dx;
	var text = _v0.lG;
	var buttons = _v0.c3;
	var theme = _v0.eB;
	return A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal__footer', true),
						_Utils_Tuple2('modal__footer--sticky', isSticky),
						_Utils_Tuple2(
						'modal__footer--alt',
						$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
						_Utils_Tuple2(
						'modal__footer--custom',
						$elm_community$maybe_extra$Maybe$Extra$isJust(customContent))
					])),
				$elm$html$Html$Attributes$classList(classList)
			]),
		A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Footer$renderText, text)),
					A2($author$project$Pyxis$Components$Modal$Footer$renderButtons, fullWidthButton, buttons)
				]),
			customContent));
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Pyxis$Components$Badge$getBadgeVariantClass = function (variant) {
	switch (variant) {
		case 2:
			return 'badge--action';
		case 5:
			return 'badge--alert';
		case 1:
			return 'badge--brand';
		case 7:
			return 'badge--brand-gradient';
		case 3:
			return 'badge--error';
		case 8:
			return 'badge--ghost';
		case 0:
			return '';
		case 6:
			return 'badge--neutral-gradient';
		default:
			return 'badge--success';
	}
};
var $author$project$Pyxis$Components$Badge$render = function (_v0) {
	var classList = _v0.c5;
	var id = _v0.nM;
	var testId = _v0.eA;
	var text = _v0.lG;
	var theme = _v0.eB;
	var variant = _v0.h_;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('badge', true),
						_Utils_Tuple2(
						'badge--alt',
						$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Badge$getBadgeVariantClass(variant),
						true)
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(text)
			]));
};
var $author$project$Pyxis$Components$Modal$Header$renderBadge = function (badge) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal__header__badge')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Badge$render(badge)
			]));
};
var $author$project$Pyxis$Components$Button$Button = {$: 0};
var $author$project$Pyxis$Components$Button$button = $author$project$Pyxis$Components$Button$Button;
var $author$project$Pyxis$Components$IconSet$Cross = 38;
var $author$project$Pyxis$Components$IconSet$cross = 38;
var $author$project$Pyxis$Commons$Attributes$ariaDisabled = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-disabled'));
var $author$project$Pyxis$Components$AriaLive$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$AriaLive$config = function (text) {
	return text;
};
var $author$project$Pyxis$Commons$Attributes$getAriaDisabledState = F2(
	function (maybeDisabled, loading) {
		var _v0 = _Utils_Tuple2(maybeDisabled, loading);
		if (!_v0.b) {
			if (!_v0.a.$) {
				var disabled = _v0.a.a;
				return $elm$core$Maybe$Just(disabled);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Just(true);
		}
	});
var $author$project$Pyxis$Components$Button$getButtonIconClass = function (icon) {
	switch (icon.$) {
		case 0:
			return 'button--append-icon';
		case 1:
			return 'button--prepend-icon';
		case 2:
			return 'button--icon-only';
		default:
			return '';
	}
};
var $author$project$Pyxis$Components$Button$getButtonSizeClass = function (size) {
	switch (size) {
		case 0:
			return 'button--xl';
		case 1:
			return 'button--l';
		case 2:
			return 'button--m';
		default:
			return 'button--s';
	}
};
var $author$project$Pyxis$Components$Button$getButtonVariantClass = function (variant) {
	switch (variant) {
		case 0:
			return 'button--primary';
		case 1:
			return 'button--secondary';
		case 2:
			return 'button--tertiary';
		case 3:
			return 'button--brand';
		case 5:
			return 'button--ghost';
		default:
			return 'button--danger';
	}
};
var $author$project$Pyxis$Components$Button$getButtonWidthClass = function (width) {
	switch (width) {
		case 2:
			return '';
		case 0:
			return 'button--min-width';
		default:
			return 'button--full-width';
	}
};
var $primait$prima_elm_extra$PrimaFunction$ifThenElse = F3(
	function (condition, a, b) {
		return condition ? a : b;
	});
var $author$project$Pyxis$Components$Button$isAppend = function (buttonIcon) {
	if (!buttonIcon.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Button$isPrepend = function (buttonIcon) {
	if (buttonIcon.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Button$isStandalone = function (buttonIcon) {
	if (buttonIcon.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$Negative = 0;
var $author$project$Pyxis$Commons$Attributes$TabIndex$negative = 0;
var $author$project$Pyxis$Commons$Attributes$ariaLivePolite = A2($elm$html$Html$Attributes$attribute, 'aria-live', 'polite');
var $author$project$Pyxis$Components$AriaLive$render = function (_v0) {
	var text = _v0;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sr-only'),
				$author$project$Pyxis$Commons$Attributes$role('status'),
				$author$project$Pyxis$Commons$Attributes$ariaLivePolite
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(text)
			]));
};
var $author$project$Pyxis$Components$Icon$sizeM = 1;
var $author$project$Pyxis$Components$Button$applyIconSize = function (size) {
	switch (size) {
		case 0:
			return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM);
		case 1:
			return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM);
		case 2:
			return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
		default:
			return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
	}
};
var $author$project$Pyxis$Components$Button$pickIcon = function (buttonIcon) {
	switch (buttonIcon.$) {
		case 0:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		case 1:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		case 2:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pyxis$Components$Button$renderIcon = F2(
	function (size, icon) {
		return $author$project$Pyxis$Commons$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Icon$config,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Button$applyIconSize(size),
						$author$project$Pyxis$Components$Icon$render)),
				$author$project$Pyxis$Components$Button$pickIcon(icon)));
	});
var $author$project$Pyxis$Commons$Render$renderIf = F2(
	function (condition, html) {
		return condition ? html : $author$project$Pyxis$Commons$Render$empty;
	});
var $author$project$Pyxis$Components$Button$renderTag = function (_v0) {
	var configData = _v0;
	var _v1 = configData.ao;
	if (_v1.$ === 3) {
		return $elm$html$Html$a;
	} else {
		return $elm$html$Html$button;
	}
};
var $author$project$Pyxis$Commons$Render$renderUnless = function (condition) {
	return $author$project$Pyxis$Commons$Render$renderIf(!condition);
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$toInt = function (index) {
	if (!index) {
		return -1;
	} else {
		return 0;
	}
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$tabIndex = A2($elm$core$Basics$composeR, $author$project$Pyxis$Commons$Attributes$TabIndex$toInt, $elm$html$Html$Attributes$tabindex);
var $author$project$Pyxis$Commons$Attributes$LinkTarget$toString = function (linkTarget) {
	switch (linkTarget) {
		case 0:
			return '_blank';
		case 1:
			return '_self';
		case 2:
			return '_parent';
		case 3:
			return '_top';
		default:
			return 'framename';
	}
};
var $author$project$Pyxis$Commons$Attributes$target = A2($elm$core$Basics$composeR, $author$project$Pyxis$Commons$Attributes$LinkTarget$toString, $elm$html$Html$Attributes$target);
var $author$project$Pyxis$Components$Button$typeToAttribute = function (type_) {
	switch (type_.$) {
		case 1:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('submit')
				]);
		case 2:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('reset')
				]);
		case 0:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button')
				]);
		default:
			var href = type_.a;
			var target = type_.b;
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$href(href),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$target, target)
				]);
	}
};
var $author$project$Pyxis$Components$Button$render = function (configuration) {
	var configData = configuration;
	return A3(
		$author$project$Pyxis$Components$Button$renderTag,
		configuration,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('button', true),
								_Utils_Tuple2(
								'button--alt',
								$author$project$Pyxis$Commons$Properties$Theme$isAlternative(configData.eB)),
								_Utils_Tuple2('button--loading', configData.bv),
								_Utils_Tuple2('button--shadow', configData.hw),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonIconClass(configData.dE),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonVariantClass(configData.h_),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonWidthClass(configData.cR),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonSizeClass(configData.aH),
								true)
							]),
						configData.c5)),
					$elm$html$Html$Attributes$disabled(configData.ff),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Events$onClick, configData.gc),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					A2(
						$elm$core$Basics$composeR,
						$elm$json$Json$Decode$succeed,
						$elm$html$Html$Events$on('mousedown')),
					configData.gt),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configData.nM),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabelledBy, configData.eW),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaDescribedBy, configData.eT),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabel, configData.eV),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					$author$project$Pyxis$Commons$Attributes$ariaDisabled,
					A2($author$project$Pyxis$Commons$Attributes$getAriaDisabledState, configData.eU, configData.bv)),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					$author$project$Pyxis$Commons$Attributes$TabIndex$tabIndex,
					A3(
						$primait$prima_elm_extra$PrimaFunction$ifThenElse,
						configData.bv,
						$elm$core$Maybe$Just($author$project$Pyxis$Commons$Attributes$TabIndex$negative),
						configData.hQ))
				]),
			$author$project$Pyxis$Components$Button$typeToAttribute(configData.ao)),
		_List_fromArray(
			[
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				configData.bv,
				$author$project$Pyxis$Components$AriaLive$render(
					$author$project$Pyxis$Components$AriaLive$config(configData.fU))),
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				$author$project$Pyxis$Components$Button$isPrepend(configData.dE) || $author$project$Pyxis$Components$Button$isStandalone(configData.dE),
				A2($author$project$Pyxis$Components$Button$renderIcon, configData.aH, configData.dE)),
				A2(
				$author$project$Pyxis$Commons$Render$renderUnless,
				$author$project$Pyxis$Components$Button$isStandalone(configData.dE),
				$elm$html$Html$text(configData.lG)),
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				$author$project$Pyxis$Components$Button$isAppend(configData.dE),
				A2($author$project$Pyxis$Components$Button$renderIcon, configData.aH, configData.dE))
			]));
};
var $author$project$Pyxis$Components$Button$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Button$S = 3;
var $author$project$Pyxis$Components$Button$sizeS = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{aH: 3});
};
var $author$project$Pyxis$Components$Button$Tertiary = 2;
var $author$project$Pyxis$Components$Button$Inherit = 2;
var $author$project$Pyxis$Components$Button$L = 1;
var $author$project$Pyxis$Components$Button$None = {$: 3};
var $author$project$Pyxis$Components$Button$config = function (variant) {
	return {eT: $elm$core$Maybe$Nothing, eU: $elm$core$Maybe$Nothing, eV: $elm$core$Maybe$Nothing, eW: $elm$core$Maybe$Nothing, c5: _List_Nil, ff: false, dE: $author$project$Pyxis$Components$Button$None, nM: $elm$core$Maybe$Nothing, bv: false, fU: 'Loading...', gc: $elm$core$Maybe$Nothing, gt: $elm$core$Maybe$Nothing, hw: false, aH: 1, hQ: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, lG: '', eB: $author$project$Pyxis$Commons$Properties$Theme$default, ao: $author$project$Pyxis$Components$Button$Button, h_: variant, cR: 2};
};
var $author$project$Pyxis$Components$Button$tertiary = $author$project$Pyxis$Components$Button$config(2);
var $author$project$Pyxis$Components$Button$withAriaLabel = F2(
	function (ariaLabel, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eV: $elm$core$Maybe$Just(ariaLabel)
			});
	});
var $author$project$Pyxis$Components$Button$Standalone = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Button$withIconOnly = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				dE: $author$project$Pyxis$Components$Button$Standalone(icon)
			});
	});
var $author$project$Pyxis$Components$Button$withOnClick = F2(
	function (onClick, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gc: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$Pyxis$Components$Button$withSize = function (setSize) {
	return setSize;
};
var $author$project$Pyxis$Components$Button$withType = F2(
	function (type_, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ao: type_});
	});
var $author$project$Pyxis$Components$Modal$Header$renderCloseButton = function (_v0) {
	var msg = _v0.d1;
	var ariaLabel = _v0.eV;
	return $author$project$Pyxis$Components$Button$render(
		A2(
			$author$project$Pyxis$Components$Button$withIconOnly,
			$author$project$Pyxis$Components$IconSet$cross,
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				msg,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withAriaLabel,
						ariaLabel,
						A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeS, $author$project$Pyxis$Components$Button$tertiary))))));
};
var $author$project$Pyxis$Components$Modal$Header$renderIcon = $author$project$Pyxis$Components$Icon$render;
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Pyxis$Components$Modal$Header$renderTitle = F2(
	function (id, title) {
		return A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal__header__title'),
					$elm$html$Html$Attributes$id(id)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]));
	});
var $author$project$Pyxis$Components$Modal$Header$render = F2(
	function (titleId, _v0) {
		var classList = _v0.c5;
		var closeButton = _v0.c7;
		var customContent = _v0.df;
		var isSticky = _v0.dR;
		var title = _v0.eE;
		var badge = _v0.mi;
		var icon = _v0.dE;
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal__header', true),
							_Utils_Tuple2('modal__header--sticky', isSticky)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal__header__wrapper', true),
									_Utils_Tuple2(
									'modal__header__wrapper--custom',
									$elm_community$maybe_extra$Maybe$Extra$isJust(customContent))
								])),
							$elm$html$Html$Attributes$classList(classList)
						]),
					A2(
						$elm$core$Maybe$withDefault,
						_List_fromArray(
							[
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderBadge, badge)),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderIcon, icon)),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2(
									$elm$core$Maybe$map,
									$author$project$Pyxis$Components$Modal$Header$renderTitle(titleId),
									title))
							]),
						customContent)),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderCloseButton, closeButton))
				]));
	});
var $author$project$Pyxis$Components$Modal$screenReaderDescriptionId = function (id) {
	return id + '-sr-description';
};
var $author$project$Pyxis$Components$Modal$renderDescribedBy = F2(
	function (id, description) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sr-only'),
					$elm$html$Html$Attributes$id(
					$author$project$Pyxis$Components$Modal$screenReaderDescriptionId(id))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(description)
				]));
	});
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Pyxis$Components$Modal$titleId = function (id) {
	return id + '-header-title';
};
var $author$project$Pyxis$Components$Modal$Header$withCloseButton = F2(
	function (onCloseData, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{c7: onCloseData});
	});
var $author$project$Pyxis$Components$Modal$render = F2(
	function (_v0, _v1) {
		var id = _v0.nM;
		var isOpen = _v0.jP;
		var classList = _v1.c5;
		var header = _v1.dB;
		var content = _v1.mQ;
		var footer = _v1.ds;
		var size = _v1.aH;
		var onCloseMsg = _v1.d5;
		var accessibleDescription = _v1.cW;
		var testId = _v1.eA;
		var isCentered = _v1.dK;
		return A2(
			$author$project$Pyxis$Commons$Render$renderIf,
			isOpen,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal-backdrop', true),
								_Utils_Tuple2('modal-backdrop--show', isOpen)
							])),
						$elm$html$Html$Attributes$classList(classList)
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2(
							$elm$core$Maybe$map,
							$author$project$Pyxis$Components$Modal$renderDescribedBy(id),
							accessibleDescription)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-close'),
								A2(
								$author$project$Pyxis$Commons$Attributes$maybe,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.d1;
									},
									$elm$html$Html$Events$onClick),
								onCloseMsg)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$section,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal', true),
										_Utils_Tuple2(
										$author$project$Pyxis$Components$Modal$getModalSizeClass(size),
										true),
										_Utils_Tuple2('modal--center', isCentered)
									])),
								$elm$html$Html$Attributes$id(id),
								$author$project$Pyxis$Commons$Attributes$role('dialog'),
								A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
								$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
								$author$project$Pyxis$Components$Modal$titleId(id)),
								$author$project$Pyxis$Commons$Attributes$ariaDescribedBy(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Pyxis$Components$Modal$contentId(id),
									A2(
										$elm$core$Maybe$map,
										$elm$core$Basics$always(
											$author$project$Pyxis$Components$Modal$screenReaderDescriptionId(id)),
										accessibleDescription))),
								A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true')
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2(
									$elm$core$Maybe$map,
									$author$project$Pyxis$Components$Modal$Header$render(
										$author$project$Pyxis$Components$Modal$titleId(id)),
									A2(
										$elm$core$Maybe$map,
										$author$project$Pyxis$Components$Modal$Header$withCloseButton(onCloseMsg),
										header))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal__content'),
										$elm$html$Html$Attributes$id(
										$author$project$Pyxis$Components$Modal$contentId(id))
									]),
								content),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Footer$render, footer))
							]))
					])));
	});
var $author$project$Pyxis$Components$Modal$withCloseMsg = F3(
	function (msg, ariaLabel, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				d5: $elm$core$Maybe$Just(
					{eV: ariaLabel, d1: msg})
			});
	});
var $author$project$Pyxis$Components$Modal$withContent = F2(
	function (content, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{mQ: content});
	});
var $author$project$Pyxis$Components$Modal$withHeader = F2(
	function (header, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				dB: $elm$core$Maybe$Just(header)
			});
	});
var $author$project$Pyxis$Components$Modal$Header$withIcon = F2(
	function (icon, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				dE: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Pyxis$Components$Modal$Header$withTitle = F2(
	function (title, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				eE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Pyxis$Components$Tooltip$renderModal = F3(
	function (tagger, modalModel, _v0) {
		var icon = _v0.dE;
		var title = _v0.eE;
		var content = _v0.mQ;
		var closeButtonLabel = _v0.c8;
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			modalModel,
			A2(
				$author$project$Pyxis$Components$Modal$withContent,
				_List_fromArray(
					[content]),
				A2(
					$author$project$Pyxis$Components$Modal$withHeader,
					A3(
						$author$project$Pyxis$Commons$Basics$applyIfJust,
						$author$project$Pyxis$Components$Modal$Header$withIcon,
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Icon$config, icon),
						A3($author$project$Pyxis$Commons$Basics$applyIfJust, $author$project$Pyxis$Components$Modal$Header$withTitle, title, $author$project$Pyxis$Components$Modal$Header$config)),
					A3(
						$author$project$Pyxis$Components$Modal$withCloseMsg,
						tagger(false),
						closeButtonLabel,
						$author$project$Pyxis$Components$Modal$config))));
	});
var $author$project$Pyxis$Components$Tooltip$renderTrigger = F2(
	function (tagger, _v0) {
		var customTrigger = _v0.dg;
		var triggerIcon = _v0.eI;
		var theme = _v0.eB;
		var id = _v0.nM;
		return $elm_community$maybe_extra$Maybe$Extra$isJust(customTrigger) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					tagger(true))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(customTrigger)
				])) : A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('tooltip-trigger', true),
							_Utils_Tuple2(
							'tooltip-trigger--alt',
							$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme))
						])),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(id),
					$elm$html$Html$Events$onClick(
					tagger(true)),
					$elm$html$Html$Attributes$type_('button')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					$author$project$Pyxis$Components$Icon$config(triggerIcon))
				]));
	});
var $author$project$Pyxis$Components$Tooltip$wrapperId = function (id) {
	return id + '-wrapper';
};
var $author$project$Pyxis$Components$Tooltip$render = F3(
	function (tagger, _v0, tooltipConfig) {
		var showModal = _v0;
		var content = tooltipConfig.mQ;
		var icon = tooltipConfig.dE;
		var id = tooltipConfig.nM;
		var testId = tooltipConfig.eA;
		var isMobile = tooltipConfig._;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tooltip-wrapper'),
					$elm$html$Html$Attributes$id(
					$author$project$Pyxis$Components$Tooltip$wrapperId(id)),
					$author$project$Pyxis$Commons$Attributes$testId(
					$author$project$Pyxis$Components$Tooltip$wrapperId(id))
				]),
			_List_fromArray(
				[
					A2($author$project$Pyxis$Components$Tooltip$renderTrigger, tagger, tooltipConfig),
					isMobile ? A3($author$project$Pyxis$Components$Tooltip$renderModal, tagger, showModal, tooltipConfig) : A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							$author$project$Pyxis$Components$Tooltip$getClassList(tooltipConfig)),
							$author$project$Pyxis$Commons$Attributes$role('tooltip'),
							A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
							$elm$html$Html$Attributes$id(id)
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Render$renderMaybe(
							A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tooltip$renderIcon, icon)),
							content
						]))
				]));
	});
var $author$project$Pyxis$Commons$Render$renderMaybeText = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($elm$html$Html$text),
	$author$project$Pyxis$Commons$Render$renderMaybe);
var $elm$html$Html$small = _VirtualDom_node('small');
var $author$project$Pyxis$Components$Field$Label$renderSubText = function (subText) {
	return A2(
		$elm$html$Html$small,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-label__sub')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(subText)
			]));
};
var $author$project$Pyxis$Components$Field$Label$wrapperId = $elm$core$Maybe$map(
	A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Basics$append, '-wrapper'));
var $author$project$Pyxis$Components$Field$Label$render = function (_v0) {
	var _for = _v0.du;
	var classList = _v0.c5;
	var id = _v0.nM;
	var testId = _v0.eA;
	var size = _v0.aH;
	var text = _v0.lG;
	var subText = _v0.et;
	var tooltip = _v0.eG;
	var isHidden = _v0.dN;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-label-wrapper'),
				$elm$html$Html$Attributes$classList(classList),
				A2(
				$author$project$Pyxis$Commons$Attributes$maybe,
				$author$project$Pyxis$Commons$Attributes$testId,
				$author$project$Pyxis$Components$Field$Label$wrapperId(id))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-label', true),
								_Utils_Tuple2('sr-only', isHidden),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$Label$getFormLabelSizeClass(size),
								true)
							])),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$for, _for),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybeText(text),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$Label$renderSubText, subText))
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					function (tooltipConfig) {
						return A3($author$project$Pyxis$Components$Tooltip$render, tooltipConfig.hS, tooltipConfig.kd, tooltipConfig.iC);
					},
					tooltip))
			]));
};
var $author$project$Pyxis$Components$IconSet$ExclamationCircle = 47;
var $author$project$Pyxis$Components$IconSet$exclamationCircle = 47;
var $author$project$Pyxis$Components$Field$Error$renderError = F2(
	function (id, error) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-item__error-message'),
					$elm$html$Html$Attributes$id(id),
					$author$project$Pyxis$Commons$Attributes$role('alert')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeS,
						$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$exclamationCircle))),
					$elm$html$Html$text(error)
				]));
	});
var $author$project$Pyxis$Components$Field$Error$render = function (config_) {
	var id = config_.nM;
	var validationResult = config_.hX;
	return $author$project$Pyxis$Commons$Render$renderMaybe(
		A2(
			$elm$core$Maybe$map,
			$author$project$Pyxis$Components$Field$Error$renderError(id),
			A2($author$project$Pyxis$Components$Field$Error$getErrorMessage, config_, validationResult)));
};
var $author$project$Pyxis$Components$Field$Hint$render = function (_v0) {
	var id = _v0.nM;
	var message = _v0.of;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-item__hint'),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(message)
			]));
};
var $author$project$Pyxis$Components$Field$Hint$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Hint$toId = function (fieldId) {
	return fieldId + '-hint';
};
var $author$project$Pyxis$Components$Field$Hint$withFieldId = F2(
	function (fieldId, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nM: $elm$core$Maybe$Just(
					$author$project$Pyxis$Components$Field$Hint$toId(fieldId))
			});
	});
var $author$project$Pyxis$Components$Form$FormItem$renderErrorOrHint = F3(
	function (id, errorConfig, hintConfig) {
		return $author$project$Pyxis$Components$Field$Error$isVisible(errorConfig) ? $author$project$Pyxis$Commons$Render$renderMaybe(
			A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$Error$render, errorConfig)) : $author$project$Pyxis$Commons$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Hint$withFieldId(id),
					$author$project$Pyxis$Components$Field$Hint$render),
				hintConfig));
	});
var $author$project$Pyxis$Components$Form$FormItem$render = F2(
	function (error, _v0) {
		var label = _v0.n6;
		var field = _v0.ns;
		var fieldConfig = _v0.i9;
		var additionalContent = _v0.eQ;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-item')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Pyxis$Components$Form$FormItem$customizeLabel(fieldConfig),
							$author$project$Pyxis$Components$Field$Label$render),
						label)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-item__wrapper')
						]),
					_List_fromArray(
						[
							field,
							A3($author$project$Pyxis$Components$Form$FormItem$renderErrorOrHint, fieldConfig.nM, error, fieldConfig.fy)
						])),
					$author$project$Pyxis$Commons$Render$renderMaybe(additionalContent)
				]));
	});
var $author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint = F2(
	function (errorId, hintId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Pyxis$Commons$Attributes$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Attributes$ariaDescribedBy,
				A2($elm_community$maybe_extra$Maybe$Extra$or, errorId, hintId)));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardControlLayoutClass = function (layout) {
	switch (layout) {
		case 2:
			return '';
		case 0:
			return '';
		default:
			return 'form-control-group--column';
	}
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$labelId = function (id) {
	return id + '-label';
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus = {$: 1};
var $author$project$Pyxis$Commons$Attributes$dataAttribute = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, 'data-' + key, value);
};
var $author$project$Pyxis$Commons$Attributes$appendDataAttrList = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map($author$project$Pyxis$Commons$Attributes$dataAttribute),
	$elm$core$List$append);
var $author$project$Pyxis$Commons$Attributes$ariaInvalid = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-invalid'));
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderCheckbox = F5(
	function (tagger, configData, checkedValues, hasError, _v0) {
		var disabled = _v0.ff;
		var value = _v0.p8;
		var label = _v0.n6;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-control', true),
							_Utils_Tuple2('form-control-option--error', hasError)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						configData.e9,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-control__checkbox', true),
										_Utils_Tuple2('form-control--disabled', disabled || configData.ff)
									])),
								$elm$html$Html$Attributes$checked(
								A2($elm$core$List$member, value, checkedValues)),
								$elm$html$Html$Attributes$disabled(disabled || configData.ff),
								$elm$html$Html$Attributes$name(configData.oq),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck(value),
									tagger)),
								$elm$html$Html$Events$onFocus(
								tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus)),
								$elm$html$Html$Events$onBlur(
								tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur)),
								$author$project$Pyxis$Commons$Attributes$ariaInvalid(hasError)
							])),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control__text')
						]),
					_List_fromArray(
						[label]))
				]));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderOptions = F5(
	function (tagger, configData, errorId, checkedValues, options) {
		var renderer = A4(
			$author$project$Pyxis$Components$Field$CheckboxGroup$renderCheckbox,
			tagger,
			configData,
			checkedValues,
			$elm_community$maybe_extra$Maybe$Extra$isJust(errorId));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-control-group', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardControlLayoutClass(configData.ce),
							true)
						])),
					$elm$html$Html$Attributes$classList(configData.c5),
					$elm$html$Html$Attributes$id(configData.nM),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$elm$core$List$length(options) > 1,
					$author$project$Pyxis$Commons$Attributes$role('group')),
					$author$project$Pyxis$Commons$Attributes$ariaDisabled(configData.ff),
					A2(
					$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
					errorId,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always(
							$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
						configData.fy)),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					$author$project$Pyxis$Components$Field$CheckboxGroup$labelId(configData.nM))
				]),
			A2($elm$core$List$map, renderer, options));
	});
var $author$project$Pyxis$Components$Field$OptionCard$Config = F4(
	function (name, fieldId, label, groupDisabled) {
		return {ja: fieldId, jr: groupDisabled, n6: label, oq: name};
	});
var $author$project$Pyxis$Components$Field$OptionCard$config = $author$project$Pyxis$Components$Field$OptionCard$Config;
var $author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardGroupLayoutClass = function (layout) {
	switch (layout) {
		case 2:
			return '';
		case 1:
			return 'form-card-group--column';
		default:
			return 'form-card-group--row';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$Checkbox = 1;
var $author$project$Pyxis$Commons$String$toKebabCase = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toLower,
	A2($elm$core$String$replace, ' ', '-'));
var $author$project$Pyxis$Components$Field$OptionCard$generateOptionId = F3(
	function (_v0, _v1, index) {
		var fieldId = _v0.ja;
		var text = _v1.lG;
		var title = _v1.eE;
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					fieldId,
					$author$project$Pyxis$Commons$String$toKebabCase(
					A2(
						$elm$core$String$left,
						25,
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$String$fromInt(index),
							A2($elm_community$maybe_extra$Maybe$Extra$or, title, text)))),
					'option'
				]));
	});
var $author$project$Pyxis$Components$Field$OptionCard$getFormCardSizeClass = function (size) {
	if (size === 1) {
		return 'form-card--l';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumAppendAddon = function (addon) {
	if (!addon.$) {
		var str = addon.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__addon')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderAppendAddon = function (addon) {
	if (!addon.$) {
		var addonSizeM = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumAppendAddon(addonSizeM);
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderContent = F2(
	function (identifier, str) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__' + identifier)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	});
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeLargePrependAddon = function (_v0) {
	var url = _v0;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-card__addon')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(url),
						$elm$html$Html$Attributes$width(72),
						$elm$html$Html$Attributes$height(72),
						$elm$html$Html$Attributes$alt(''),
						$author$project$Pyxis$Commons$Attributes$ariaHidden(true)
					]),
				_List_Nil)
			]));
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumPrependAddon = function (addon) {
	if (addon.$ === 1) {
		var icon = addon.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__addon form-card__addon--with-icon'),
					$author$project$Pyxis$Commons$Attributes$testId(
					$author$project$Pyxis$Components$IconSet$toLabel(icon))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeM,
						$author$project$Pyxis$Components$Icon$config(icon)))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderPrependAddon = function (addon) {
	if (!addon.$) {
		var addonSizeM = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumPrependAddon(addonSizeM);
	} else {
		var addonSizeL = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeLargePrependAddon(addonSizeL);
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$toType = function (type_) {
	if (!type_) {
		return 'radio';
	} else {
		return 'checkbox';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$render = F6(
	function (type_, hasError, attributes, configData, index, option) {
		var name = configData.oq;
		var groupDisabled = configData.jr;
		var id = A3($author$project$Pyxis$Components$Field$OptionCard$generateOptionId, configData, option, index);
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-card', true),
							_Utils_Tuple2('form-card--error', hasError),
							_Utils_Tuple2('form-card--checked', option.dL),
							_Utils_Tuple2('form-card--disabled', option.ff || groupDisabled),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$OptionCard$getFormCardSizeClass(option.aH),
							true)
						]))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$renderPrependAddon, option.h6)),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-card__content-wrapper')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Field$OptionCard$renderContent('title'),
								option.eE)),
							$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Field$OptionCard$renderContent('text'),
								option.lG))
						])),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$renderAppendAddon, option.h6)),
					A2(
					$elm$html$Html$input,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						attributes,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_(
								$author$project$Pyxis$Components$Field$OptionCard$toType(type_)),
								$elm$html$Html$Attributes$class(
								'form-control__' + $author$project$Pyxis$Components$Field$OptionCard$toType(type_)),
								$elm$html$Html$Attributes$checked(option.dL),
								$elm$html$Html$Attributes$disabled(option.ff || groupDisabled),
								$elm$html$Html$Attributes$id(id),
								$author$project$Pyxis$Commons$Attributes$testId(id),
								$elm$html$Html$Attributes$name(name),
								$elm$html$Html$Events$onCheck(option.d4),
								$elm$html$Html$Events$onFocus(option.gj),
								$elm$html$Html$Events$onBlur(option.f6)
							])),
					_List_Nil)
				]));
	});
var $author$project$Pyxis$Components$Field$OptionCard$renderCheckbox = $author$project$Pyxis$Components$Field$OptionCard$render(1);
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderOptionsCard = F3(
	function (_v0, errorId, options) {
		var name = _v0.oq;
		var layout = _v0.ce;
		var classList = _v0.c5;
		var id = _v0.nM;
		var disabled = _v0.ff;
		var hint = _v0.fy;
		var testId = _v0.eA;
		var dataAttrList = _v0.e9;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-card-group', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardGroupLayoutClass(layout),
							true)
						])),
					$elm$html$Html$Attributes$classList(classList),
					$elm$html$Html$Attributes$id(id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					$author$project$Pyxis$Commons$Attributes$role('group'),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					$author$project$Pyxis$Components$Field$CheckboxGroup$labelId(id)),
					$author$project$Pyxis$Commons$Attributes$ariaDisabled(disabled),
					A2(
					$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
					errorId,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always(
							$author$project$Pyxis$Components$Field$Hint$toId(id)),
						hint))
				]),
			A2(
				$elm$core$List$indexedMap,
				A3(
					$author$project$Pyxis$Components$Field$OptionCard$renderCheckbox,
					$elm_community$maybe_extra$Maybe$Extra$isJust(errorId),
					dataAttrList,
					A4($author$project$Pyxis$Components$Field$OptionCard$config, name, id, $elm$core$Maybe$Nothing, disabled)),
				options));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$toInternal = F3(
	function (tagger, checkedValues, _v0) {
		var addon = _v0.h6;
		var text = _v0.lG;
		var title = _v0.eE;
		var value = _v0.p8;
		var size = _v0.aH;
		var disabled = _v0.ff;
		var isChecked = A2($elm$core$List$member, value, checkedValues);
		return {
			h6: addon,
			ff: disabled,
			dL: isChecked,
			f6: tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur),
			d4: A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck(value),
				tagger),
			gj: tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus),
			aH: size,
			lG: text,
			eE: title,
			p8: value
		};
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderControlGroup = F5(
	function (tagger, configData, errorId, checkedValues, options) {
		if (!options.$) {
			var optionSimples = options.a;
			return A5($author$project$Pyxis$Components$Field$CheckboxGroup$renderOptions, tagger, configData, errorId, checkedValues, optionSimples);
		} else {
			var optionsCard = options.a;
			return A3(
				$author$project$Pyxis$Components$Field$CheckboxGroup$renderOptionsCard,
				configData,
				errorId,
				A2(
					$elm$core$List$map,
					A2($author$project$Pyxis$Components$Field$CheckboxGroup$toInternal, tagger, checkedValues),
					optionsCard));
		}
	});
var $author$project$Pyxis$Components$Form$FormItem$withAdditionalContent = F2(
	function (additionalContent, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{eQ: additionalContent});
	});
var $author$project$Pyxis$Components$Form$FormItem$withLabel = F2(
	function (label, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{n6: label});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$render = F3(
	function (tagger, model, config_) {
		var checkedValues = model.au;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$CheckboxGroup$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.eQ,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.n6,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A5(
							$author$project$Pyxis$Components$Field$CheckboxGroup$renderControlGroup,
							tagger,
							configData,
							A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
							checkedValues,
							configData.ku)))));
	});
var $author$project$Pyxis$Components$Button$Ghost = 5;
var $author$project$Pyxis$Components$Button$ghost = $author$project$Pyxis$Components$Button$config(5);
var $author$project$Pyxis$Components$IconSet$QuestionCircle = 121;
var $author$project$Pyxis$Components$IconSet$questionCircle = 121;
var $author$project$Pyxis$Components$Button$M = 2;
var $author$project$Pyxis$Components$Button$sizeM = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{aH: 2});
};
var $author$project$Pyxis$Components$Button$Append = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Button$withIconAppend = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				dE: $author$project$Pyxis$Components$Button$Append(icon)
			});
	});
var $author$project$Pyxis$Components$Button$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eA: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Button$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{lG: text});
	});
var $author$project$View$InfoModalButton$view = function (_v0) {
	var id = _v0.nM;
	var onClick = _v0.gc;
	var content = _v0.mQ;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex-row')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Button$render(
				A2(
					$author$project$Pyxis$Components$Button$withSize,
					$author$project$Pyxis$Components$Button$sizeM,
					A2(
						$author$project$Pyxis$Components$Button$withIconAppend,
						$author$project$Pyxis$Components$IconSet$questionCircle,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							content,
							A2(
								$author$project$Pyxis$Components$Button$withAriaLabel,
								'Apri modale informativa',
								A2(
									$author$project$Pyxis$Components$Button$withTestId,
									id + '-info-link',
									A2($author$project$Pyxis$Components$Button$withOnClick, onClick, $author$project$Pyxis$Components$Button$ghost)))))))
			]));
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$withLayout = F2(
	function (layout, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{ce: layout});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCardList = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$withOptionsCard = F2(
	function (options, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				ku: $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCardList(options)
			});
	});
var $author$project$Pyxis$Commons$ValidationResult$Invalid = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Commons$ValidationResult$Valid = {$: 0};
var $author$project$Pyxis$Commons$ValidationResult$fromResult = function (result) {
	if (!result.$) {
		return $author$project$Pyxis$Commons$ValidationResult$Valid;
	} else {
		var error = result.a;
		return $author$project$Pyxis$Commons$ValidationResult$Invalid(error);
	}
};
var $author$project$Pyxis$Components$Field$Error$OnSubmit = 2;
var $author$project$Pyxis$Components$Field$Error$onSubmit = 2;
var $author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Preselection$InitializedPreselection$formTypeFieldView = function (modelData) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('preselection__field-content-grid')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Pyxis$Components$Field$CheckboxGroup$render,
				$author$project$Preselection$InitializedPreselection$FormTypeFieldChanged,
				$author$project$Preselection$InitializedPreselection$pickFormTypeField(modelData),
				A2(
					$author$project$Pyxis$Components$Field$CheckboxGroup$withOptionsCard,
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeL(
							{
								h6: $author$project$Pyxis$Components$Field$CheckboxGroup$addonImage('/svg/preselection/home.svg'),
								lG: $elm$core$Maybe$Just('Proteggila da danni e imprevisti'),
								eE: $elm$core$Maybe$Just('La tua casa'),
								p8: 0
							}),
							$author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeL(
							{
								h6: $author$project$Pyxis$Components$Field$CheckboxGroup$addonImage('/svg/preselection/family.svg'),
								lG: $elm$core$Maybe$Just('Proteggila da infortuni e danni a terzi'),
								eE: $elm$core$Maybe$Just('La tua famiglia'),
								p8: 1
							})
						]),
					A3(
						$author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit,
						$author$project$Preselection$InitializedPreselection$formTypeFieldValidation,
						$author$project$Preselection$InitializedPreselection$pickIsFormSubmitted(modelData),
						A2(
							$author$project$Pyxis$Components$Field$CheckboxGroup$withLayout,
							$author$project$Pyxis$Components$Field$CheckboxGroup$layoutVertical,
							$author$project$Pyxis$Components$Field$CheckboxGroup$config('form-type'))))),
				$author$project$View$InfoModalButton$view(
				{mQ: 'Leggi cosa puoi assicurare', nM: 'form-type', gc: $author$project$Preselection$InitializedPreselection$OpenModal})
			]));
};
var $author$project$Pyxis$Components$Grid$Col = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Col$buildConfiguration = F2(
	function (options, config) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, c) {
					var mapper = _v0;
					return mapper(c);
				}),
			config,
			options);
	});
var $author$project$Pyxis$Commons$Breakpoint$Base = 0;
var $author$project$Pyxis$Commons$Breakpoint$sizeBase = 0;
var $author$project$Pyxis$Components$Grid$SpanSize$Span1 = 0;
var $author$project$Pyxis$Components$Grid$SpanSize$span1 = 0;
var $author$project$Pyxis$Components$Grid$initialColConfiguration = {ab: $author$project$Pyxis$Commons$Breakpoint$sizeBase, bV: $author$project$Pyxis$Components$Grid$SpanSize$span1, ac: _List_Nil, c5: _List_Nil, cF: $author$project$Pyxis$Components$Grid$SpanSize$span1};
var $author$project$Pyxis$Components$Grid$col = F2(
	function (options, children) {
		var configuration = A2($author$project$Pyxis$Components$Grid$Col$buildConfiguration, options, $author$project$Pyxis$Components$Grid$initialColConfiguration);
		return {ab: configuration.ab, bV: configuration.bV, ac: children, c5: configuration.c5, cF: configuration.cF};
	});
var $author$project$Pyxis$Components$Grid$Row = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Row$buildConfiguration = F2(
	function (options, config) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, c) {
					var mapper = _v0;
					return mapper(c);
				}),
			config,
			options);
	});
var $author$project$Pyxis$Components$Grid$Row$L = 2;
var $author$project$Pyxis$Components$Grid$Row$rowSizeL = 2;
var $author$project$Pyxis$Components$Grid$initialRowConfiguration = {ab: $author$project$Pyxis$Commons$Breakpoint$sizeBase, ac: _List_Nil, c5: _List_Nil, aH: $author$project$Pyxis$Components$Grid$Row$rowSizeL};
var $author$project$Pyxis$Components$Grid$row = F2(
	function (options, children) {
		var configuration = A2($author$project$Pyxis$Components$Grid$Row$buildConfiguration, options, $author$project$Pyxis$Components$Grid$initialRowConfiguration);
		return {ab: configuration.ab, ac: children, c5: configuration.c5, aH: configuration.aH};
	});
var $author$project$Pyxis$Components$Grid$Row$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Row$S = 0;
var $author$project$Pyxis$Components$Grid$Row$sizeS = function (config) {
	return _Utils_update(
		config,
		{aH: 0});
};
var $author$project$Preselection$InitializedPreselection$toRow = function (content) {
	return A2(
		$author$project$Pyxis$Components$Grid$row,
		_List_fromArray(
			[$author$project$Pyxis$Components$Grid$Row$sizeS]),
		_List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$col,
				_List_Nil,
				_List_fromArray(
					[content]))
			]));
};
var $author$project$Preselection$InitializedPreselection$formTypeFieldRow = function (modelData) {
	return $author$project$Preselection$InitializedPreselection$toRow(
		$author$project$Preselection$InitializedPreselection$formTypeFieldView(modelData));
};
var $author$project$Preselection$InitializedPreselection$fieldsetContent = function (modelData) {
	return _List_fromArray(
		[
			$author$project$Preselection$InitializedPreselection$formTypeFieldRow(modelData)
		]);
};
var $author$project$Preselection$InitializedPreselection$Proceed = {$: 6};
var $author$project$Pyxis$Components$IconSet$ArrowRight = 5;
var $author$project$Pyxis$Components$IconSet$arrowRight = 5;
var $author$project$Pyxis$Components$Button$Primary = 0;
var $author$project$Pyxis$Components$Button$primary = $author$project$Pyxis$Components$Button$config(0);
var $author$project$Pyxis$Components$Button$XL = 0;
var $author$project$Pyxis$Components$Button$sizeXL = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{aH: 0});
};
var $author$project$Pyxis$Components$Button$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nM: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Preselection$InitializedPreselection$proceedButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('button-row justify-content-end')
		]),
	_List_fromArray(
		[
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withText,
				'Procedi',
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					$author$project$Preselection$InitializedPreselection$Proceed,
					A2(
						$author$project$Pyxis$Components$Button$withIconAppend,
						$author$project$Pyxis$Components$IconSet$arrowRight,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeXL,
								A2(
									$author$project$Pyxis$Components$Button$withTestId,
									'proceed-btn',
									A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary))))))))
		]));
var $author$project$Pyxis$Components$Form$Fieldset$withContent = F2(
	function (grid, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{mQ: grid});
	});
var $author$project$Pyxis$Components$Form$Fieldset$withFooter = F2(
	function (rows, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ds: rows});
	});
var $author$project$Pyxis$Components$Form$Fieldset$withHeader = F2(
	function (rows, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{dB: rows});
	});
var $author$project$Preselection$InitializedPreselection$fieldsetConfig = function (modelData) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		_List_fromArray(
			[
				$author$project$Preselection$InitializedPreselection$toRow($author$project$Preselection$InitializedPreselection$proceedButton)
			]),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Preselection$InitializedPreselection$fieldsetContent(modelData),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				_List_fromArray(
					[
						$author$project$Preselection$InitializedPreselection$toRow($author$project$Preselection$InitializedPreselection$contentHeader)
					]),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Preselection$InitializedPreselection$CloseModal = {$: 3};
var $author$project$Pyxis$Components$Modal$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$init = function (id) {
	return {fe: $elm$core$Maybe$Nothing, fj: $elm$core$Maybe$Nothing, nM: id, jP: false, eq: $elm$core$Maybe$Nothing};
};
var $author$project$Pyxis$Components$Modal$Open = 0;
var $author$project$Pyxis$Commons$Basics$maybeForwardValue = function (value) {
	return $elm$core$Maybe$map(
		$author$project$Pyxis$Commons$Basics$forwardValue(value));
};
var $author$project$Pyxis$Components$Modal$updateIsOpen = F2(
	function (isOpen, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{jP: isOpen});
	});
var $primait$prima_elm_extra$PrimaUpdate$withCmd = F2(
	function (cmd, model) {
		return _Utils_Tuple2(model, cmd);
	});
var $author$project$Pyxis$Components$Modal$update = F2(
	function (msg, model) {
		var enableFocusTrap = model.fj;
		var disableFocusTrap = model.fe;
		var id = model.nM;
		var startingElementId = model.eq;
		if (!msg) {
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmd,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$author$project$Pyxis$Commons$Basics$maybeForwardValue,
						{eq: startingElementId, qf: id},
						enableFocusTrap)),
				A2($author$project$Pyxis$Components$Modal$updateIsOpen, true, model));
		} else {
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmd,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($author$project$Pyxis$Commons$Basics$maybeForwardValue, 0, disableFocusTrap)),
				A2($author$project$Pyxis$Components$Modal$updateIsOpen, false, model));
		}
	});
var $author$project$Pyxis$Components$Modal$open = $author$project$Pyxis$Components$Modal$update(0);
var $author$project$Utils$initSimpleModal = F2(
	function (id, isOpen) {
		return isOpen ? $author$project$Pyxis$Components$Modal$open(
			$author$project$Pyxis$Components$Modal$init(id)).a : $author$project$Pyxis$Components$Modal$init(id);
	});
var $author$project$Preselection$InitializedPreselection$ModalTabsUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pyxis$Components$Tabs$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$Left = 0;
var $author$project$Pyxis$Components$Tabs$Underline = 0;
var $author$project$Pyxis$Components$Tabs$underline = 0;
var $author$project$Pyxis$Components$Tabs$config = function (id) {
	return {cW: $elm$core$Maybe$Nothing, c5: _List_Nil, e8: $elm$core$Maybe$Nothing, fv: false, nM: id, aF: 0, cK: _List_Nil, eA: $elm$core$Maybe$Nothing, eB: $author$project$Pyxis$Commons$Properties$Theme$default, h_: $author$project$Pyxis$Components$Tabs$underline};
};
var $author$project$Preselection$InitializedPreselection$tabContentRowDot = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('preselection__modal-tab-row__dot-container')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('preselection__modal-tab-row__dot')
				]),
			_List_Nil)
		]));
var $author$project$Preselection$InitializedPreselection$tabContentRowText = F2(
	function (boldText, text) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('preselection__modal-tab-row__text-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('preselection__modal-tab-row__bold-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(boldText)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('preselection__modal-tab-row__text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(' ' + text)
						]))
				]));
	});
var $author$project$Preselection$InitializedPreselection$tabContentRow = F2(
	function (boldText, text) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('preselection__modal-tab-row')
				]),
			_List_fromArray(
				[
					$author$project$Preselection$InitializedPreselection$tabContentRowDot,
					A2($author$project$Preselection$InitializedPreselection$tabContentRowText, boldText, text)
				]));
	});
var $author$project$Preselection$InitializedPreselection$familyModalTabContent = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Danni causati a terzi dai familiari', 'anche con bici e monopattini elettrici'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Danni causati dagli animali domestici', 'a cose o persone'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Infortuni e lesioni fisiche', 'nel tempo libero, in casa e fuori casa'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Spese legali', 'in caso di procedimenti giudiziali o stragiudiziali')
		]));
var $author$project$Pyxis$Components$IconSet$FamilyRc = 53;
var $author$project$Pyxis$Components$IconSet$familyRc = 53;
var $author$project$Pyxis$Components$Tabs$Tab = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$tab = {mi: $elm$core$Maybe$Nothing, mQ: _List_Nil, dE: $elm$core$Maybe$Nothing, n6: $elm$core$Maybe$Nothing, lG: ''};
var $author$project$Pyxis$Components$Tabs$withContent = F2(
	function (content, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{mQ: content});
	});
var $author$project$Pyxis$Components$Tabs$withIcon = F2(
	function (icon, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{
				dE: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Pyxis$Components$Tabs$withText = F2(
	function (text, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{lG: text});
	});
var $author$project$Preselection$InitializedPreselection$familyModalTab = A2(
	$author$project$Pyxis$Components$Tabs$withContent,
	_List_fromArray(
		[$author$project$Preselection$InitializedPreselection$familyModalTabContent]),
	A2(
		$author$project$Pyxis$Components$Tabs$withIcon,
		$author$project$Pyxis$Components$IconSet$familyRc,
		A2($author$project$Pyxis$Components$Tabs$withText, 'Famiglia', $author$project$Pyxis$Components$Tabs$tab)));
var $author$project$Pyxis$Components$IconSet$HomeInsuranceHome = 70;
var $author$project$Pyxis$Components$IconSet$homeInsuranceHome = 70;
var $author$project$Preselection$InitializedPreselection$homeModalTabContent = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Muri e locali', 'in caso di guasti, rotture, incendi ed eventi naturali'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Mobili e contenuto', 'in caso di scoppi, sbalzi di tensione e danni da acqua condotta'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Danni causati a terzi', 'legati alla casa di tua proprietà'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Beni personali', 'in caso di furti e rapine, anche fuori dall\'abitazione'),
			A2($author$project$Preselection$InitializedPreselection$tabContentRow, 'Spese legali', 'in caso di procedimenti giudiziali o stragiudiziali')
		]));
var $author$project$Preselection$InitializedPreselection$homeModalTab = A2(
	$author$project$Pyxis$Components$Tabs$withContent,
	_List_fromArray(
		[$author$project$Preselection$InitializedPreselection$homeModalTabContent]),
	A2(
		$author$project$Pyxis$Components$Tabs$withIcon,
		$author$project$Pyxis$Components$IconSet$homeInsuranceHome,
		A2($author$project$Pyxis$Components$Tabs$withText, 'Casa', $author$project$Pyxis$Components$Tabs$tab)));
var $author$project$Pyxis$Components$Tabs$Pill = 1;
var $author$project$Pyxis$Components$Tabs$pill = 1;
var $author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId = function (id) {
	return id + '-accessible-description';
};
var $author$project$Pyxis$Components$Tabs$renderAccessibleDescription = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$html$Html$text,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$html$Html$span(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sr-only'),
						$elm$html$Html$Attributes$id(id)
					]))));
};
var $author$project$Pyxis$Components$Tabs$Center = 1;
var $author$project$Pyxis$Components$Tabs$Right = 2;
var $author$project$Pyxis$Components$Tabs$getTablistClasses = function (_v0) {
	var variant = _v0.h_;
	var theme = _v0.eB;
	var hasBorder = _v0.fv;
	var placement = _v0.aF;
	return _List_fromArray(
		[
			_Utils_Tuple2('tabs__tablist', true),
			_Utils_Tuple2('tabs__tablist--with-pills', 1 === variant),
			_Utils_Tuple2('tabs__tablist--with-border', hasBorder),
			_Utils_Tuple2(
			'tabs__tablist--alt',
			$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
			_Utils_Tuple2('justify-content-center', placement === 1),
			_Utils_Tuple2('justify-content-flex-end', placement === 2)
		]);
};
var $author$project$Pyxis$Components$Tabs$offsetAttribute = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2(
		$elm$core$Basics$composeR,
		A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Basics$append, 'px'),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$append('--offset: '),
			$elm$html$Html$Attributes$attribute('style'))));
var $author$project$Pyxis$Components$Tabs$OnClick = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Tabs$OnKeyDown = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Pyxis$Commons$Attributes$ariaControls = $elm$html$Html$Attributes$attribute('aria-controls');
var $author$project$Pyxis$Commons$Attributes$ariaSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-selected'));
var $author$project$Pyxis$Components$Tabs$getTabPanelId = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		$elm$core$Basics$append(id + '-tabpanel-'));
};
var $author$project$Pyxis$Components$Tabs$handleKeyDown = F2(
	function (msg, key) {
		return _Utils_Tuple2(
			msg(key),
			A2(
				$elm$core$List$any,
				function (check) {
					return check(key);
				},
				_List_fromArray(
					[$author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft, $author$project$Pyxis$Commons$Events$Keyboard$isArrowRight, $author$project$Pyxis$Commons$Events$Keyboard$isHome, $author$project$Pyxis$Commons$Events$Keyboard$isEnd])));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$Event = $elm$core$Basics$identity;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Pyxis$Commons$Events$Keyboard$decoder = function (tagger) {
	return A6(
		$elm$json$Json$Decode$map5,
		F5(
			function (keyCode, key, ctrlKey, shiftKey, innerHtml) {
				return tagger(
					{mX: ctrlKey, jz: innerHtml, fR: key, j0: keyCode, pG: shiftKey});
			}),
		A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
		A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A2($elm$json$Json$Decode$field, 'innerHTML', $elm$json$Json$Decode$string)));
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn = F2(
	function (event, tagger) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			$author$project$Pyxis$Commons$Events$Keyboard$decoder(tagger));
	});
var $author$project$Pyxis$Components$Badge$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Badge$withTheme = F2(
	function (theme, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{eB: theme});
	});
var $author$project$Pyxis$Components$Tabs$renderTabBadge = F2(
	function (theme, badge) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab__addon')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Badge$render(
					A2($author$project$Pyxis$Components$Badge$withTheme, theme, badge))
				]));
	});
var $author$project$Pyxis$Components$Tabs$renderTabIcon = function (icon) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab__icon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				$author$project$Pyxis$Components$Icon$config(icon))
			]));
};
var $author$project$Pyxis$Components$Tabs$renderTabLabel = function (label) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab__addon')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Pyxis$Components$Tabs$renderTabLine = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('tab__line')
		]),
	_List_Nil);
var $author$project$Pyxis$Components$Tabs$renderTab = F5(
	function (tagger, _v0, _v1, index, _v2) {
		var activeIndex = _v0.O;
		var id = _v1.nM;
		var tabs = _v1.cK;
		var theme = _v1.eB;
		var variant = _v1.h_;
		var tabConfig = _v2;
		return A2(
			$elm$html$Html$map,
			tagger,
			A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Attributes$ariaSelected(
						_Utils_eq(activeIndex, index)),
						$author$project$Pyxis$Commons$Attributes$ariaControls(
						A2($author$project$Pyxis$Components$Tabs$getTabPanelId, id, index)),
						$author$project$Pyxis$Commons$Attributes$role('tab'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('tab', true),
								_Utils_Tuple2(
								'tab--alt',
								$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
								_Utils_Tuple2('tab--pills', variant === 1)
							])),
						$elm$html$Html$Attributes$id(
						A2($author$project$Pyxis$Components$Tabs$getTabId, id, index)),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Attributes$tabindex(
						A3(
							$primait$prima_elm_extra$PrimaFunction$ifThenElse,
							_Utils_eq(activeIndex, index),
							0,
							-1)),
						$elm$html$Html$Events$onClick(
						$author$project$Pyxis$Components$Tabs$OnClick(index)),
						A2(
						$author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn,
						'keydown',
						$author$project$Pyxis$Components$Tabs$handleKeyDown(
							A2(
								$author$project$Pyxis$Components$Tabs$OnKeyDown,
								$elm$core$List$length(tabs),
								id)))
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tabs$renderTabIcon, tabConfig.dE)),
						$elm$html$Html$text(tabConfig.lG),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2(
							$elm$core$Maybe$map,
							$author$project$Pyxis$Components$Tabs$renderTabBadge(theme),
							tabConfig.mi)),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tabs$renderTabLabel, tabConfig.n6)),
						A2($author$project$Pyxis$Commons$Render$renderIf, !variant, $author$project$Pyxis$Components$Tabs$renderTabLine)
					])));
	});
var $author$project$Pyxis$Components$Tabs$renderTabList = F3(
	function (tagger, model, config_) {
		var accessibleDescription = config_.cW;
		var customMobileOffset = config_.e8;
		var id = config_.nM;
		var tabs = config_.cK;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tabs__tablist-wrapper'),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Tabs$offsetAttribute, customMobileOffset)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2(
								$author$project$Pyxis$Commons$Attributes$renderIf,
								$elm_community$maybe_extra$Maybe$Extra$isJust(accessibleDescription),
								$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
									$author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId(id))),
								$author$project$Pyxis$Commons$Attributes$role('tablist'),
								$elm$html$Html$Attributes$classList(
								$author$project$Pyxis$Components$Tabs$getTablistClasses(config_))
							]),
						A2(
							$elm$core$List$indexedMap,
							A3($author$project$Pyxis$Components$Tabs$renderTab, tagger, model, config_),
							tabs))
					]))
			]);
	});
var $elm$html$Html$Attributes$hidden = $elm$html$Html$Attributes$boolProperty('hidden');
var $author$project$Pyxis$Components$Tabs$renderTabPanel = F4(
	function (_v0, _v1, index, _v2) {
		var activeIndex = _v0.O;
		var id = _v1.nM;
		var tabConfig = _v2;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					A2($author$project$Pyxis$Components$Tabs$getTabId, id, index)),
					$author$project$Pyxis$Commons$Attributes$role('tabpanel'),
					$elm$html$Html$Attributes$hidden(
					!_Utils_eq(activeIndex, index)),
					$elm$html$Html$Attributes$class('tabs__tab-panel'),
					$elm$html$Html$Attributes$id(
					A2($author$project$Pyxis$Components$Tabs$getTabPanelId, id, index))
				]),
			tabConfig.mQ);
	});
var $author$project$Pyxis$Components$Tabs$renderTabPanelList = F2(
	function (model, config_) {
		var tabs = config_.cK;
		return A2(
			$elm$core$List$indexedMap,
			A2($author$project$Pyxis$Components$Tabs$renderTabPanel, model, config_),
			tabs);
	});
var $author$project$Pyxis$Components$Tabs$render = F3(
	function (tagger, model, config_) {
		var conf = config_;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2('tabs', true),
						conf.c5)),
					$elm$html$Html$Attributes$id(conf.nM),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, conf.eA)
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						$elm$core$List$singleton(
						$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Tabs$renderAccessibleDescription(
									$author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId(conf.nM)),
								conf.cW))),
						A3($author$project$Pyxis$Components$Tabs$renderTabList, tagger, model, config_),
						A2($author$project$Pyxis$Components$Tabs$renderTabPanelList, model, config_)
					])));
	});
var $author$project$Pyxis$Components$Tabs$withTabs = F2(
	function (tabs, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{cK: tabs});
	});
var $author$project$Pyxis$Components$Tabs$withVariant = F2(
	function (variant, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{h_: variant});
	});
var $author$project$Preselection$InitializedPreselection$infoModalTabs = function (tabsModel) {
	return A3(
		$author$project$Pyxis$Components$Tabs$render,
		$author$project$Preselection$InitializedPreselection$ModalTabsUpdated,
		tabsModel,
		A2(
			$author$project$Pyxis$Components$Tabs$withVariant,
			$author$project$Pyxis$Components$Tabs$pill,
			A2(
				$author$project$Pyxis$Components$Tabs$withTabs,
				_List_fromArray(
					[$author$project$Preselection$InitializedPreselection$homeModalTab, $author$project$Preselection$InitializedPreselection$familyModalTab]),
				$author$project$Pyxis$Components$Tabs$config('info-modal-tabs'))));
};
var $author$project$Preselection$InitializedPreselection$modalSubtitle = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('preselection__modal-subtitle')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Potrai personalizzare la polizza più avanti e scegliere cosa assicurare')
		]));
var $author$project$Preselection$InitializedPreselection$modalContent = F2(
	function (minHeight, tabsModel) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id($author$project$Preselection$InitializedPreselection$modalContentId),
						A2(
						$elm$html$Html$Attributes$style,
						'min-height',
						$elm$core$String$fromFloat(minHeight) + 'px'),
						$elm$html$Html$Attributes$class('text-m-book margin-b-s')
					]),
				_List_fromArray(
					[
						$author$project$Preselection$InitializedPreselection$modalSubtitle,
						$author$project$Preselection$InitializedPreselection$infoModalTabs(tabsModel)
					]))
			]);
	});
var $author$project$Preselection$InitializedPreselection$modalHeader = A2($author$project$Pyxis$Components$Modal$Header$withTitle, 'Scegli cosa proteggere', $author$project$Pyxis$Components$Modal$Header$config);
var $author$project$Preselection$InitializedPreselection$modalId = 'info-modal';
var $author$project$Pyxis$Components$Modal$S = 0;
var $author$project$Pyxis$Components$Modal$sizeS = 0;
var $author$project$Pyxis$Components$Modal$withIsCentered = F2(
	function (isCentered, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{dK: isCentered});
	});
var $author$project$Pyxis$Components$Modal$withSize = F2(
	function (size, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{aH: size});
	});
var $author$project$Pyxis$Components$Modal$withTestId = F2(
	function (id, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				eA: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Preselection$InitializedPreselection$modalView = F3(
	function (isOpen, contentMinHeight, tabsModel) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			A2($author$project$Utils$initSimpleModal, $author$project$Preselection$InitializedPreselection$modalId, isOpen),
			A2(
				$author$project$Pyxis$Components$Modal$withContent,
				A2($author$project$Preselection$InitializedPreselection$modalContent, contentMinHeight, tabsModel),
				A2(
					$author$project$Pyxis$Components$Modal$withHeader,
					$author$project$Preselection$InitializedPreselection$modalHeader,
					A3(
						$author$project$Pyxis$Components$Modal$withCloseMsg,
						$author$project$Preselection$InitializedPreselection$CloseModal,
						'Chiudi',
						A2(
							$author$project$Pyxis$Components$Modal$withIsCentered,
							true,
							A2(
								$author$project$Pyxis$Components$Modal$withSize,
								$author$project$Pyxis$Components$Modal$sizeS,
								A2($author$project$Pyxis$Components$Modal$withTestId, $author$project$Preselection$InitializedPreselection$modalId, $author$project$Pyxis$Components$Modal$config)))))));
	});
var $author$project$Preselection$InitializedPreselection$pickIsModalOpen = function (_v0) {
	var isModalOpen = _v0.n4;
	return isModalOpen;
};
var $author$project$Preselection$InitializedPreselection$pickModalContentMinHeight = function (_v0) {
	var modalContentMinHeight = _v0.d_;
	return modalContentMinHeight;
};
var $author$project$Pyxis$Components$Grid$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Gap$L = 2;
var $author$project$Pyxis$Components$Grid$Gap$sizeL = 2;
var $author$project$Pyxis$Components$Grid$gapL = function (c) {
	return _Utils_update(
		c,
		{bn: $author$project$Pyxis$Components$Grid$Gap$sizeL});
};
var $author$project$Pyxis$Components$Grid$Gap$M = 1;
var $author$project$Pyxis$Components$Grid$Gap$sizeM = 1;
var $author$project$Pyxis$Components$Grid$initialConfiguration = {ac: _List_Nil, bn: $author$project$Pyxis$Components$Grid$Gap$sizeM};
var $author$project$Pyxis$Components$Grid$Gap$isSizeL = $elm$core$Basics$eq(2);
var $author$project$Pyxis$Commons$Breakpoint$toString = function (breakpoint) {
	switch (breakpoint) {
		case 0:
			return 'base';
		case 1:
			return '2xs';
		case 2:
			return 'xs';
		case 3:
			return 's';
		case 4:
			return 'm';
		case 5:
			return 'l';
		default:
			return 'xl';
	}
};
var $author$project$Pyxis$Components$Grid$getRowColumnsFromClass = function (breakpointType) {
	var breakpoint = $author$project$Pyxis$Commons$Breakpoint$toString(breakpointType);
	return 'grid__row--from-' + breakpoint;
};
var $author$project$Pyxis$Components$Grid$Row$M = 1;
var $author$project$Pyxis$Components$Grid$Row$isSizeM = $elm$core$Basics$eq(1);
var $author$project$Pyxis$Components$Grid$Row$isSizeS = $elm$core$Basics$eq(0);
var $author$project$Pyxis$Components$Grid$SpanSize$toInt = function (spanSize) {
	switch (spanSize) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Pyxis$Components$Grid$SpanSize$toString = A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Grid$SpanSize$toInt, $elm$core$String$fromInt);
var $author$project$Pyxis$Components$Grid$getColumnSpanAtBreakpointClass = F2(
	function (spanSize, breakpointType) {
		var span = $author$project$Pyxis$Components$Grid$SpanSize$toString(spanSize);
		var breakpoint = $author$project$Pyxis$Commons$Breakpoint$toString(breakpointType);
		return 'grid__row__column--span-' + (span + ('-from-' + breakpoint));
	});
var $author$project$Pyxis$Components$Grid$getColumnSpanClass = function (spanSize) {
	return 'grid__row__column--span-' + $author$project$Pyxis$Components$Grid$SpanSize$toString(spanSize);
};
var $author$project$Pyxis$Components$Grid$renderCol = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('grid__row__column', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Grid$getColumnSpanClass(configuration.cF),
							true),
							_Utils_Tuple2(
							A2($author$project$Pyxis$Components$Grid$getColumnSpanAtBreakpointClass, configuration.bV, configuration.ab),
							true)
						]),
					configuration.c5))
			]),
		configuration.ac);
};
var $author$project$Pyxis$Components$Grid$renderRow = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('grid__row', true),
							_Utils_Tuple2(
							'grid__row--m',
							$author$project$Pyxis$Components$Grid$Row$isSizeM(configuration.aH)),
							_Utils_Tuple2(
							'grid__row--s',
							$author$project$Pyxis$Components$Grid$Row$isSizeS(configuration.aH)),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Grid$getRowColumnsFromClass(configuration.ab),
							true)
						]),
					configuration.c5))
			]),
		A2($elm$core$List$map, $author$project$Pyxis$Components$Grid$renderCol, configuration.ac));
};
var $author$project$Pyxis$Components$Grid$render = F2(
	function (options, children) {
		var configuration = A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, config) {
					var mapper = _v0;
					return mapper(config);
				}),
			$author$project$Pyxis$Components$Grid$initialConfiguration,
			options);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('grid', true),
							_Utils_Tuple2(
							'grid--gap-l',
							$author$project$Pyxis$Components$Grid$Gap$isSizeL(configuration.bn))
						]))
				]),
			A2($elm$core$List$map, $author$project$Pyxis$Components$Grid$renderRow, children));
	});
var $author$project$Pyxis$Components$Grid$Gap$S = 0;
var $author$project$Pyxis$Components$Grid$Gap$sizeS = 0;
var $author$project$Pyxis$Components$Grid$gapS = function (c) {
	return _Utils_update(
		c,
		{bn: $author$project$Pyxis$Components$Grid$Gap$sizeS});
};
var $author$project$Pyxis$Components$Grid$oneColRowFullWidth = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Grid$col(_List_Nil),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$author$project$Pyxis$Components$Grid$row(_List_Nil)));
var $author$project$Pyxis$Components$Form$Fieldset$renderContent = function (content) {
	return _List_fromArray(
		[
			$author$project$Pyxis$Components$Grid$oneColRowFullWidth(
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Components$Grid$render,
					_List_fromArray(
						[$author$project$Pyxis$Components$Grid$gapS]),
					content)
				]))
		]);
};
var $author$project$Pyxis$Components$Grid$Row$sizeL = function (config) {
	return _Utils_update(
		config,
		{aH: 2});
};
var $author$project$Pyxis$Components$Grid$Row$sizeM = function (config) {
	return _Utils_update(
		config,
		{aH: 1});
};
var $author$project$Pyxis$Components$Form$Fieldset$getRowSize = function (rowSize) {
	switch (rowSize) {
		case 0:
			return $author$project$Pyxis$Components$Grid$Row$sizeS;
		case 1:
			return $author$project$Pyxis$Components$Grid$Row$sizeM;
		default:
			return $author$project$Pyxis$Components$Grid$Row$sizeL;
	}
};
var $author$project$Pyxis$Components$Form$Fieldset$renderLegendRow = F2(
	function (rowSize, legend) {
		return _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Fieldset$getRowSize(rowSize)
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Components$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Form$Legend$render(legend)
							]))
					]))
			]);
	});
var $author$project$Pyxis$Components$Form$Fieldset$renderVisualLegend = function (rowSize) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(
			$author$project$Pyxis$Components$Form$Fieldset$renderLegendRow(rowSize)),
		$elm$core$Maybe$withDefault(_List_Nil));
};
var $author$project$Pyxis$Components$Form$Legend$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{c5: classList});
	});
var $author$project$Pyxis$Components$Form$Fieldset$render = function (_v0) {
	var classList = _v0.c5;
	var header = _v0.dB;
	var content = _v0.mQ;
	var footer = _v0.ds;
	var legend = _v0.aR;
	var additionalContent = _v0.eQ;
	var isHiddenLegend = _v0.dO;
	var legendRowSize = _v0.cg;
	var id = _v0.nM;
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, id)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Form$Legend$withClassList(
							_List_fromArray(
								[
									_Utils_Tuple2('sr-only', true)
								])),
						$author$project$Pyxis$Components$Form$Legend$render),
					legend)),
				A2(
				$author$project$Pyxis$Components$Grid$render,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$gapL]),
				_Utils_ap(
					additionalContent,
					_Utils_ap(
						(!isHiddenLegend) ? A2($author$project$Pyxis$Components$Form$Fieldset$renderVisualLegend, legendRowSize, legend) : _List_Nil,
						_Utils_ap(
							header,
							_Utils_ap(
								$author$project$Pyxis$Components$Form$Fieldset$renderContent(content),
								footer)))))
			]));
};
var $author$project$Preselection$InitializedPreselection$contentView = function (modelData) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('preselection__main')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-responsive')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Fieldset$render(
						$author$project$Preselection$InitializedPreselection$fieldsetConfig(modelData)),
						A3(
						$author$project$Preselection$InitializedPreselection$modalView,
						$author$project$Preselection$InitializedPreselection$pickIsModalOpen(modelData),
						$author$project$Preselection$InitializedPreselection$pickModalContentMinHeight(modelData),
						$author$project$Preselection$InitializedPreselection$pickModalTabs(modelData))
					]))
			]));
};
var $author$project$Preselection$InitializedPreselection$pickFlags = function (_v0) {
	var flags = _v0.nv;
	return flags;
};
var $author$project$Preselection$InitializedPreselection$pickTokenType = function (_v0) {
	var tokenType = _v0.lK;
	return tokenType;
};
var $author$project$View$TopBar$MobileVisibleLogo = 0;
var $author$project$View$TopBar$WithoutRightSideContent = {$: 1};
var $author$project$View$TopBar$getRightSideContentHtml = function (optionalRightSideContent) {
	if (!optionalRightSideContent.$) {
		var htmlContent = optionalRightSideContent.a;
		return htmlContent;
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$View$TopBar$isLogoVisibleInMobile = function (logoType) {
	return !logoType;
};
var $author$project$View$Logo$Brand = function (a) {
	return {$: 0, a: a};
};
var $author$project$View$Logo$render = function (logoColor) {
	var svgName = function () {
		if (logoColor.$ === 1) {
			return 'prima-logo-white';
		} else {
			return 'prima-logo-brand';
		}
	}();
	var additionalAttrs = function () {
		if (logoColor.$ === 1) {
			return _List_Nil;
		} else {
			var brandLink = logoColor.a;
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$href(brandLink)
				]);
		}
	}();
	return A2(
		$elm$html$Html$a,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$target('_blank'),
			additionalAttrs),
		_List_fromArray(
			[
				A3(
				$author$project$View$Svg$svg,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('prima-logo')
					]),
				'',
				svgName)
			]));
};
var $author$project$View$TopBar$logoView = F2(
	function (flags, tokenType) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('top-bar__logo')
				]),
			_List_fromArray(
				[
					$author$project$View$Logo$render(
					$author$project$View$Logo$Brand(
						A2($author$project$Types$redirectUrl, flags, tokenType)))
				]));
	});
var $author$project$View$TopBar$view = F4(
	function (flags, tokenType, logoType, optionalRightSideContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('top-bar'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'top-bar--logo-mobile-visible',
							$author$project$View$TopBar$isLogoVisibleInMobile(logoType))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('top-bar__content')
						]),
					_List_fromArray(
						[
							A2($author$project$View$TopBar$logoView, flags, tokenType),
							$author$project$View$TopBar$getRightSideContentHtml(optionalRightSideContent)
						]))
				]));
	});
var $author$project$Preselection$InitializedPreselection$topBar = F2(
	function (flags, tokenType) {
		return A4($author$project$View$TopBar$view, flags, tokenType, 0, $author$project$View$TopBar$WithoutRightSideContent);
	});
var $author$project$View$Footer$footerTextView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-s-book c-neutral-light')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('© Prima Assicurazioni S.p.A. • P. IVA 08879250960 • Piazzale Loreto 17, 20131 Milano (MI)')
		]));
var $author$project$View$Logo$White = {$: 1};
var $author$project$View$Footer$logoView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('quotation__footer__logo')
		]),
	_List_fromArray(
		[
			$author$project$View$Logo$render($author$project$View$Logo$White)
		]));
var $author$project$View$Footer$view = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('quotation__footer')
		]),
	_List_fromArray(
		[$author$project$View$Footer$logoView, $author$project$View$Footer$footerTextView]));
var $author$project$Preselection$InitializedPreselection$view = function (modelData) {
	return A2(
		$elm$html$Html$map,
		$author$project$Preselection$InitializedPreselection$pickParentMsg(modelData),
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('preselection')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Preselection$InitializedPreselection$topBar,
					$author$project$Preselection$InitializedPreselection$pickFlags(modelData),
					$author$project$Preselection$InitializedPreselection$pickTokenType(modelData)),
					$author$project$Preselection$InitializedPreselection$contentView(modelData),
					$author$project$View$Footer$view
				])));
};
var $author$project$Preselection$Preselection$view = function (preselection) {
	if (!preselection.$) {
		return $elm$html$Html$text('');
	} else {
		var initializedPreselection = preselection.a;
		return $author$project$Preselection$InitializedPreselection$view(initializedPreselection);
	}
};
var $author$project$Quotation$View$footerView = function (model) {
	switch (model.$) {
		case 2:
			return $author$project$View$Footer$view;
		case 3:
			return $author$project$View$Footer$view;
		case 0:
			return $elm$html$Html$text('');
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$Common$StepModel$pickIsViewInitialized = function (_v0) {
	var isViewInitialized = _v0.jZ;
	return isViewInitialized;
};
var $author$project$Quotation$Family$Model$pickIsViewInitializedFromStepState = function (stepState) {
	if (!stepState.$) {
		var state = stepState.a;
		return $author$project$Quotation$Common$StepModel$pickIsViewInitialized(state);
	} else {
		var state = stepState.a;
		return $author$project$Quotation$Common$StepModel$pickIsViewInitialized(state);
	}
};
var $author$project$Quotation$Family$Model$pickIsViewInitialized = function (model) {
	if (model.$ === 2) {
		var stepState = model.a.cH;
		return $author$project$Quotation$Family$Model$pickIsViewInitializedFromStepState(stepState);
	} else {
		return false;
	}
};
var $author$project$Quotation$Home$Model$pickIsViewInitializedFromStepState = function (stepState) {
	switch (stepState.$) {
		case 0:
			var state = stepState.a;
			return $author$project$Quotation$Common$StepModel$pickIsViewInitialized(state);
		case 1:
			var state = stepState.a;
			return $author$project$Quotation$Common$StepModel$pickIsViewInitialized(state);
		default:
			var state = stepState.a;
			return $author$project$Quotation$Common$StepModel$pickIsViewInitialized(state);
	}
};
var $author$project$Quotation$Home$Model$pickIsViewInitialized = function (model) {
	if (model.$ === 2) {
		var stepState = model.a.cH;
		return $author$project$Quotation$Home$Model$pickIsViewInitializedFromStepState(stepState);
	} else {
		return false;
	}
};
var $author$project$Quotation$Model$pickIsViewInitialized = function (model) {
	switch (model.$) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			var homeModel = model.a;
			return $author$project$Quotation$Home$Model$pickIsViewInitialized(homeModel);
		default:
			var familyModel = model.a;
			return $author$project$Quotation$Family$Model$pickIsViewInitialized(familyModel);
	}
};
var $author$project$Quotation$Family$CustomerStep$Model$TogglePrivacyPolicy = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$GoBack = {$: 12};
var $author$project$Quotation$Family$CustomerStep$Model$Proceed = {$: 11};
var $author$project$Pyxis$Components$IconSet$ChevronLeft = 29;
var $author$project$Pyxis$Components$IconSet$chevronLeft = 29;
var $author$project$RFQDTS$Http$Authenticator$AuthResponse$isLoading = function (response) {
	if (response.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Quotation$Common$StepModel$pickFormSubmissionResult = function (_v0) {
	var formSubmissionResult = _v0.je;
	return formSubmissionResult;
};
var $author$project$Quotation$Common$StepModel$isSubmittingFormData = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$StepModel$pickFormSubmissionResult, $author$project$RFQDTS$Http$Authenticator$AuthResponse$isLoading);
var $author$project$Pyxis$Components$Button$withDisabled = F2(
	function (isDisabled, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ff: isDisabled});
	});
var $author$project$Pyxis$Components$Button$Prepend = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Button$withIconPrepend = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				dE: $author$project$Pyxis$Components$Button$Prepend(icon)
			});
	});
var $author$project$Pyxis$Components$Button$withLoading = F2(
	function (isLoading, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{bv: isLoading});
	});
var $author$project$Quotation$Family$CustomerStep$View$buttonsRow = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button-row')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withText,
							'Indietro',
							A2(
								$author$project$Pyxis$Components$Button$withDisabled,
								$author$project$Quotation$Common$StepModel$isSubmittingFormData(stepState),
								A2(
									$author$project$Pyxis$Components$Button$withOnClick,
									$author$project$Quotation$Family$CustomerStep$Model$GoBack,
									A2(
										$author$project$Pyxis$Components$Button$withIconPrepend,
										$author$project$Pyxis$Components$IconSet$chevronLeft,
										A2(
											$author$project$Pyxis$Components$Button$withType,
											$author$project$Pyxis$Components$Button$button,
											A2(
												$author$project$Pyxis$Components$Button$withTestId,
												'go-back-btn',
												A2($author$project$Pyxis$Components$Button$withId, 'go-back-btn', $author$project$Pyxis$Components$Button$ghost)))))))),
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withText,
							'Mostra prezzo',
							A2(
								$author$project$Pyxis$Components$Button$withOnClick,
								$author$project$Quotation$Family$CustomerStep$Model$Proceed,
								A2(
									$author$project$Pyxis$Components$Button$withLoading,
									$author$project$Quotation$Common$StepModel$isSubmittingFormData(stepState),
									A2(
										$author$project$Pyxis$Components$Button$withIconAppend,
										$author$project$Pyxis$Components$IconSet$arrowRight,
										A2(
											$author$project$Pyxis$Components$Button$withType,
											$author$project$Pyxis$Components$Button$button,
											A2(
												$author$project$Pyxis$Components$Button$withSize,
												$author$project$Pyxis$Components$Button$sizeXL,
												A2(
													$author$project$Pyxis$Components$Button$withTestId,
													'proceed-btn',
													A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary)))))))))
					]))
			]));
};
var $author$project$Quotation$Common$Fields$View$InfoLink = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Common$Fields$View$InfoMessage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$ToggleManualResidentialAddress = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Quotation$Common$Fields$Model$SelectForeignCountry = {$: 23};
var $author$project$Pyxis$Components$Field$Autocomplete$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Autocomplete$M = 1;
var $author$project$Pyxis$Components$Field$Autocomplete$config = function (name) {
	return {eQ: $elm$core$Maybe$Nothing, c5: _List_Nil, e9: _List_Nil, ff: false, b1: $elm$core$Maybe$Nothing, fn: $elm$core$Maybe$Nothing, fx: $elm$core$Maybe$Nothing, fy: $elm$core$Maybe$Nothing, nM: 'id-' + name, b8: false, n6: $elm$core$Maybe$Nothing, oq: name, f2: 'No results found.', oU: '', hm: 'Reset', aH: 1, ew: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, cQ: $elm$core$Maybe$Nothing};
};
var $author$project$Pyxis$Components$Field$Label$M = 1;
var $author$project$Pyxis$Components$Field$Label$config = {c5: _List_Nil, du: $elm$core$Maybe$Nothing, nM: $elm$core$Maybe$Nothing, dN: false, aH: 1, et: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, lG: $elm$core$Maybe$Nothing, eG: $elm$core$Maybe$Nothing};
var $author$project$Components$DebouncedAutocomplete$noResultsFoundMessage = 'Nessun risultato trovato';
var $author$project$Components$DebouncedAutocomplete$Autocomplete = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var value = _v0.p8;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Form$Dropdown$HeaderAndOptions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$headerAndOptions = $author$project$Pyxis$Components$Form$Dropdown$HeaderAndOptions;
var $author$project$Pyxis$Components$Form$Dropdown$M = 1;
var $author$project$Pyxis$Components$Form$Dropdown$sizeM = 1;
var $author$project$Pyxis$Components$Form$Dropdown$S = 0;
var $author$project$Pyxis$Components$Form$Dropdown$sizeS = 0;
var $author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Form$Dropdown$sizeS;
	} else {
		return $author$project$Pyxis$Components$Form$Dropdown$sizeM;
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$NoResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$noResult = $author$project$Pyxis$Components$Form$Dropdown$NoResult;
var $author$project$Pyxis$Components$Form$Dropdown$Options = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$options = $author$project$Pyxis$Components$Form$Dropdown$Options;
var $author$project$Pyxis$Components$Form$Dropdown$getDropdownWrapperSizeClass = function (size) {
	if (!size) {
		return 'form-dropdown-wrapper--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$hasHeader = function (content_) {
	if (content_.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $author$project$Pyxis$Components$Form$Dropdown$renderHeader = function (content_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__header')
			]),
		_List_fromArray(
			[content_]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderNoResult = function (_v0) {
	var label = _v0.n6;
	var action = _v0.eP;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__no-results')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$elm$html$Html$div(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-dropdown__no-results__action')
								]))),
					action))
			]));
};
var $author$project$Pyxis$Components$Icon$withClassList = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{c5: a});
	});
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Icon$config,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Icon$withClassList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-dropdown__suggestion__icon', true)
					])),
			$author$project$Pyxis$Components$Icon$render)));
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionSubtitle = function (subtitle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion__subtitle')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(subtitle)
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestion = function (_v0) {
	var icon = _v0.dE;
	var title = _v0.eE;
	var subtitle = _v0.eu;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Dropdown$renderSuggestionIcon(icon),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-dropdown__suggestion__wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Dropdown$renderSuggestionTitle(title),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionSubtitle, subtitle))
					]))
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderContent = function (content_) {
	switch (content_.$) {
		case 1:
			var config = content_.a;
			return A2(
				$elm$core$List$cons,
				$author$project$Pyxis$Components$Form$Dropdown$renderHeader(config.dB),
				config.ku);
		case 2:
			var options_ = content_.a;
			return options_;
		case 0:
			var noResultData = content_.a;
			return _List_fromArray(
				[
					$author$project$Pyxis$Components$Form$Dropdown$renderNoResult(noResultData)
				]);
		default:
			var config = content_.a;
			return _List_fromArray(
				[
					$author$project$Pyxis$Components$Form$Dropdown$renderSuggestion(config)
				]);
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$render = F3(
	function (id, size, content_) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-dropdown-wrapper'),
					$elm$html$Html$Attributes$class(
					$author$project$Pyxis$Components$Form$Dropdown$getDropdownWrapperSizeClass(size))
				]),
			_List_fromArray(
				[
					A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'listbox'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-dropdown', true),
									_Utils_Tuple2(
									'form-dropdown--with-header',
									$author$project$Pyxis$Components$Form$Dropdown$hasHeader(content_))
								])),
							$elm$html$Html$Attributes$id(id + '-dropdown-list')
						]),
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (index, item) {
								return _Utils_Tuple2(
									$elm$core$String$fromInt(index),
									item);
							}),
						$author$project$Pyxis$Components$Form$Dropdown$renderContent(content_)))
				]));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$OnHover = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pyxis$Commons$Events$preventDefaultOn = F2(
	function (event, msg) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (msg_) {
					return _Utils_Tuple2(msg_, true);
				},
				$elm$json$Json$Decode$succeed(msg)));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$renderOptionText = F2(
	function (filteringValue, label) {
		var matchStartIndex = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$head(
				A2(
					$elm$core$String$indexes,
					$elm$core$String$toLower(filteringValue),
					$elm$core$String$toLower(label))));
		var matchEndIndex = matchStartIndex + $elm$core$String$length(filteringValue);
		var labelStart = A3($elm$core$String$slice, 0, matchStartIndex, label);
		var labelEnd = A3(
			$elm$core$String$slice,
			matchEndIndex,
			$elm$core$String$length(label),
			label);
		var labelCenter = A3($elm$core$String$slice, matchStartIndex, matchEndIndex, label);
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$strong,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-m-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(labelStart)
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-m-book')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(labelCenter)
					])),
				A2(
				$elm$html$Html$strong,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-m-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(labelEnd)
					]))
			]);
	});
var $author$project$Pyxis$Components$Field$Autocomplete$renderOptionsItem = F3(
	function (msgMapper, _v0, option_) {
		var modelData = _v0;
		var value = option_.p8;
		var label = option_.n6;
		var id = option_.nM;
		var isActiveOption = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.p8;
						},
						$elm$core$Basics$eq(value))),
				modelData.G));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-dropdown__item'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'form-dropdown__item--active',
							_Utils_eq(
								modelData.p8,
								$elm$core$Maybe$Just(value))),
							_Utils_Tuple2('form-dropdown__item--hover', isActiveOption)
						])),
					$elm$html$Html$Attributes$id(id),
					$author$project$Pyxis$Commons$Attributes$role('option'),
					A2(
					$author$project$Pyxis$Commons$Events$preventDefaultOn,
					'mousedown',
					msgMapper(
						$author$project$Pyxis$Components$Field$Autocomplete$OnSelect(value))),
					$elm$html$Html$Events$onMouseEnter(
					msgMapper(
						$author$project$Pyxis$Components$Field$Autocomplete$OnHover(option_)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$renderOptionText,
						modelData.H,
						$elm$core$String$trim(label)))
				]));
	});
var $author$project$Pyxis$Components$Form$Dropdown$Suggestion = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$suggestion = $author$project$Pyxis$Components$Form$Dropdown$Suggestion;
var $author$project$Pyxis$Components$Field$Autocomplete$renderDropdown = F3(
	function (msgMapper, model, _v0) {
		var modelData = model;
		var configData = _v0;
		var renderedOptions = A2(
			$elm$core$List$map,
			A2($author$project$Pyxis$Components$Field$Autocomplete$renderOptionsItem, msgMapper, model),
			$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model));
		var noAvailableOptions = (!$elm$core$List$length(
			$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model))) && $krisajenkins$remotedata$RemoteData$isSuccess(modelData.hZ);
		return ($elm$core$String$isEmpty(modelData.H) && $elm$core$List$isEmpty(renderedOptions)) ? A2(
			$elm$core$Maybe$map,
			A2(
				$author$project$Pyxis$Components$Form$Dropdown$render,
				configData.nM,
				$author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize(configData.aH)),
			A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Dropdown$suggestion, configData.ew)) : (modelData.dA ? $elm$core$Maybe$Just(
			A3(
				$author$project$Pyxis$Components$Form$Dropdown$render,
				configData.nM,
				$author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize(configData.aH),
				function () {
					if (noAvailableOptions) {
						return $author$project$Pyxis$Components$Form$Dropdown$noResult(
							{eP: configData.fn, n6: configData.f2});
					} else {
						var _v1 = _Utils_Tuple2(configData.fx, configData.ew);
						if (!_v1.a.$) {
							var headerLabel = _v1.a.a;
							return $author$project$Pyxis$Components$Form$Dropdown$headerAndOptions(
								{
									dB: $elm$html$Html$text(headerLabel),
									ku: renderedOptions
								});
						} else {
							if (!_v1.b.$) {
								var _v2 = _v1.a;
								var suggestionConfig = _v1.b.a;
								return ((!$krisajenkins$remotedata$RemoteData$isSuccess(modelData.hZ)) && $elm$core$String$isEmpty(modelData.H)) ? $author$project$Pyxis$Components$Form$Dropdown$suggestion(suggestionConfig) : $author$project$Pyxis$Components$Form$Dropdown$options(renderedOptions);
							} else {
								var _v3 = _v1.a;
								var _v4 = _v1.b;
								return $author$project$Pyxis$Components$Form$Dropdown$options(renderedOptions);
							}
						}
					}
				}())) : $elm$core$Maybe$Nothing);
	});
var $author$project$Pyxis$Components$Field$Autocomplete$OnBlur = {$: 0};
var $author$project$Pyxis$Components$Field$Autocomplete$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$Autocomplete$OnInput = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Commons$Attributes$ariaActiveDescendant = $elm$html$Html$Attributes$attribute('aria-activedescendant');
var $author$project$Pyxis$Commons$Attributes$ariaAutocomplete = $elm$html$Html$Attributes$attribute('aria-autocomplete');
var $author$project$Pyxis$Commons$Attributes$ariaExpanded = function (isExpanded) {
	return A2(
		$elm$html$Html$Attributes$attribute,
		'aria-expanded',
		A3($primait$prima_elm_extra$PrimaFunction$ifThenElse, isExpanded, 'true', 'false'));
};
var $author$project$Pyxis$Commons$Attributes$ariaOwns = $elm$html$Html$Attributes$attribute('aria-owns');
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Pyxis$Components$Field$Autocomplete$getFormFieldSizeClass = function (size) {
	if (!size) {
		return 'form-field__autocomplete--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnKeyDown = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$handleSelectKeydown = function (key) {
	return _Utils_Tuple2(
		$author$project$Pyxis$Components$Field$Autocomplete$OnKeyDown(key),
		A2(
			$elm$core$List$any,
			function (check) {
				return check(key);
			},
			_List_fromArray(
				[$author$project$Pyxis$Commons$Events$Keyboard$isArrowUp, $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown, $author$project$Pyxis$Commons$Events$Keyboard$isEnter])));
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnReset = {$: 4};
var $author$project$Pyxis$Components$IconSet$MagnifyingGlass = 95;
var $author$project$Pyxis$Components$IconSet$magnifyingGlass = 95;
var $author$project$Pyxis$Components$Field$Autocomplete$getFieldAddonIcon = function (_v0) {
	var modelData = _v0;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(modelData.p8) ? $author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$cross) : $author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$magnifyingGlass);
};
var $author$project$Pyxis$Components$Field$Autocomplete$getFormFieldAddonSizeClass = function (size) {
	if (!size) {
		return 'form-field__addon__reset--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$iconSizeByAutocompleteSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$renderFieldIconAddon = F3(
	function (size, resetLabel, model) {
		var modelData = model;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-field__addon', true),
							_Utils_Tuple2(
							'form-field__addon--loading',
							$krisajenkins$remotedata$RemoteData$isLoading(modelData.hZ))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$elm_community$maybe_extra$Maybe$Extra$isNothing(modelData.p8),
					$author$project$Pyxis$Components$Icon$render(
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Field$Autocomplete$iconSizeByAutocompleteSize(size),
							$author$project$Pyxis$Components$Field$Autocomplete$getFieldAddonIcon(model)))),
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$elm_community$maybe_extra$Maybe$Extra$isJust(modelData.p8),
					A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-field__addon__reset', true),
										_Utils_Tuple2(
										$author$project$Pyxis$Components$Field$Autocomplete$getFormFieldAddonSizeClass(size),
										true)
									])),
								$elm$html$Html$Attributes$type_('button'),
								$elm$html$Html$Events$onClick($author$project$Pyxis$Components$Field$Autocomplete$OnReset),
								$author$project$Pyxis$Commons$Attributes$ariaLabel(resetLabel)
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Icon$render(
								A2(
									$author$project$Pyxis$Components$Icon$withSize,
									$author$project$Pyxis$Components$Field$Autocomplete$iconSizeByAutocompleteSize(size),
									$author$project$Pyxis$Components$Field$Autocomplete$getFieldAddonIcon(model)))
							])))
				]));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$renderField = F4(
	function (error, msgMapper, model, _v0) {
		var modelData = model;
		var configData = _v0;
		var activeOptionId = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					function ($) {
						return $.nM;
					}),
				modelData.G));
		return A2(
			$elm$html$Html$map,
			msgMapper,
			A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						A2(
							$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
							configData.e9,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-field__autocomplete', true),
											_Utils_Tuple2(
											'form-field__autocomplete--filled',
											$elm_community$maybe_extra$Maybe$Extra$isJust(modelData.p8)),
											_Utils_Tuple2(
											$author$project$Pyxis$Components$Field$Autocomplete$getFormFieldSizeClass(configData.aH),
											true)
										])),
									A2($author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn, 'keydown', $author$project$Pyxis$Components$Field$Autocomplete$handleSelectKeydown),
									$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$Autocomplete$OnFocus),
									$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$Autocomplete$OnBlur),
									$elm$html$Html$Events$onInput($author$project$Pyxis$Components$Field$Autocomplete$OnInput),
									$elm$html$Html$Attributes$id(configData.nM),
									A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
									$elm$html$Html$Attributes$name(configData.oq),
									$author$project$Pyxis$Commons$Attributes$ariaAutocomplete('both'),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									modelData.bs,
									$author$project$Pyxis$Commons$Attributes$ariaExpanded(true)),
									$author$project$Pyxis$Commons$Attributes$role('combobox'),
									$author$project$Pyxis$Commons$Attributes$ariaOwns(configData.nM + '-dropdown-list'),
									$author$project$Pyxis$Commons$Attributes$ariaActiveDescendant(activeOptionId),
									$elm$html$Html$Attributes$autocomplete(false),
									$elm$html$Html$Attributes$disabled(configData.ff),
									$elm$html$Html$Attributes$placeholder(configData.oU),
									$elm$html$Html$Attributes$type_('text'),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									!modelData.bt,
									$elm$html$Html$Attributes$value(modelData.H)),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									modelData.bt,
									$elm$html$Html$Attributes$value(modelData.H)),
									$author$project$Pyxis$Commons$Attributes$ariaInvalid(
									$author$project$Pyxis$Components$Field$Error$isVisible(error)),
									A2(
									$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
									A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
									A2(
										$elm$core$Maybe$map,
										$elm$core$Basics$always(
											$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
										configData.fy))
								])),
						_List_Nil),
						A3($author$project$Pyxis$Components$Field$Autocomplete$renderFieldIconAddon, configData.aH, configData.hm, model)
					])));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$render = F3(
	function (msgMapper, model, config_) {
		var modelData = model;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Autocomplete$generateErrorConfig, model, config_);
		var dropdown = A3($author$project$Pyxis$Components$Field$Autocomplete$renderDropdown, msgMapper, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.eQ,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.n6,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-field', true),
											_Utils_Tuple2(
											'form-field--with-opened-dropdown',
											modelData.bs && ($elm_community$maybe_extra$Maybe$Extra$isJust(dropdown) && $krisajenkins$remotedata$RemoteData$isSuccess(modelData.hZ))),
											_Utils_Tuple2(
											'form-field--error',
											$author$project$Pyxis$Components$Field$Error$isVisible(error)),
											_Utils_Tuple2('form-field--disabled', configData.ff)
										])),
									$elm$html$Html$Attributes$classList(configData.c5)
								]),
							_List_fromArray(
								[
									A4($author$project$Pyxis$Components$Field$Autocomplete$renderField, error, msgMapper, model, config_),
									$author$project$Pyxis$Commons$Render$renderMaybe(dropdown)
								]))))));
	});
var $author$project$Components$DebouncedAutocomplete$render = F3(
	function (messageMapper, _v0, cfg) {
		var autocomplete = _v0.L;
		return A3(
			$author$project$Pyxis$Components$Field$Autocomplete$render,
			A2($elm$core$Basics$composeR, $author$project$Components$DebouncedAutocomplete$Autocomplete, messageMapper),
			autocomplete,
			cfg);
	});
var $author$project$Quotation$Common$Fields$View$toFieldDataTestId = function (id) {
	return 'id-' + id;
};
var $author$project$Pyxis$Components$Field$Autocomplete$withLabel = F2(
	function (label, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				n6: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withNoResultFoundAction = F2(
	function (action, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				fn: $elm$core$Maybe$Just(action)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage = F2(
	function (message, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{f2: message});
	});
var $author$project$Pyxis$Components$Button$withOnMouseDown = F2(
	function (onMouseDown, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gt: $elm$core$Maybe$Just(onMouseDown)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder = F2(
	function (placeholder, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{oU: placeholder});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withTestId = F2(
	function (id, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				eA: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Field$Label$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				lG: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Quotation$Common$Fields$View$birthCity = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Components$DebouncedAutocomplete$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCityFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Autocomplete$withNoResultFoundAction,
				$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnMouseDown,
						msgMapper($author$project$Quotation$Common$Fields$Model$SelectForeignCountry),
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2($author$project$Pyxis$Components$Button$withText, 'Stato Estero', $author$project$Pyxis$Components$Button$ghost)))),
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
					$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
						'Cerca comune di nascita',
						A3(
							$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
								A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo comune di nascita?', $author$project$Pyxis$Components$Field$Label$config),
								A2(
									$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
									$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
									$author$project$Pyxis$Components$Field$Autocomplete$config(id))))))));
	});
var $author$project$Quotation$Common$Fields$View$birthCityId = 'birth-city';
var $author$project$Quotation$Common$StepModel$pickIsFormSubmitted = function (_v0) {
	var isFormSubmitted = _v0.dM;
	return isFormSubmitted;
};
var $author$project$Quotation$Common$View$FormRows$birthCityRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$birthCity,
			$author$project$Quotation$Common$Fields$View$birthCityId,
			$author$project$Quotation$Common$Fields$Model$birthCityFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickBirthCityField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$RowSizeS = 0;
var $author$project$Quotation$Common$View$FormRows$simpleColRowSizeMapper = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Grid$Row$sizeS;
	} else {
		return $author$project$Pyxis$Components$Grid$Row$sizeM;
	}
};
var $author$project$Quotation$Common$View$FormRows$toSimpleColRow = F2(
	function (size, content) {
		return A2(
			$author$project$Pyxis$Components$Grid$row,
			_List_fromArray(
				[
					$author$project$Quotation$Common$View$FormRows$simpleColRowSizeMapper(size)
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Components$Grid$col,
					_List_Nil,
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Quotation$Common$View$FormRows$toSimpleColRowS = $author$project$Quotation$Common$View$FormRows$toSimpleColRow(0);
var $author$project$Quotation$Common$View$FormRows$birthCitySimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$birthCityRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$View$birthCountry = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Components$DebouncedAutocomplete$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthCountryFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
				$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
					'Cerca stato di nascita',
					A3(
						$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
							A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo stato di nascita?', $author$project$Pyxis$Components$Field$Label$config),
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
								$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
								$author$project$Pyxis$Components$Field$Autocomplete$config(id)))))));
	});
var $author$project$Quotation$Common$Fields$View$birthCountryId = 'birth-country';
var $author$project$Quotation$Common$View$FormRows$birthCountryRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$birthCountry,
			$author$project$Quotation$Common$Fields$View$birthCountryId,
			$author$project$Quotation$Common$Fields$Model$birthCountryFieldValidation(
				$author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
					$author$project$Quotation$Common$StepModel$pickFormData(stepState))),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickBirthCountryField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$birthCountrySimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$birthCountryRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$Model$BirthdayFieldChanged = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pyxis$Components$Field$DateInput$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$DateInput$M = 1;
var $author$project$Pyxis$Components$Field$DateInput$config = function (name) {
	return {eQ: $elm$core$Maybe$Nothing, c5: _List_Nil, e9: _List_Nil, ff: false, b1: $elm$core$Maybe$Nothing, fy: $elm$core$Maybe$Nothing, nM: 'id-' + name, b8: false, n6: $elm$core$Maybe$Nothing, fW: $elm$core$Maybe$Nothing, f_: $elm$core$Maybe$Nothing, oq: name, aH: 1, hL: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, cQ: $elm$core$Maybe$Nothing, hY: $elm$core$Basics$identity};
};
var $author$project$Pyxis$Components$Field$DateInput$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var value = _v0.p8;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$IconSet$Calendar = 14;
var $author$project$Pyxis$Components$IconSet$calendar = 14;
var $author$project$Pyxis$Components$Field$DateInput$iconSizeByInputSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$DateInput$renderAddon = function (_v0) {
	var size = _v0.aH;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-field__addon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Field$DateInput$iconSizeByInputSize(size),
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$calendar)))
			]));
};
var $author$project$Pyxis$Components$Field$DateInput$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$DateInput$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$DateInput$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$DateInput$getFormFieldDateSizeClass = function (size) {
	if (!size) {
		return 'form-field__date--s';
	} else {
		return '';
	}
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Pyxis$Components$Field$DateInput$renderInput = F3(
	function (error, _v0, _v1) {
		var modelData = _v0;
		var configData = _v1;
		return A2(
			$elm$html$Html$input,
			A2(
				$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
				configData.e9,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(configData.nM),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field__date', true),
								_Utils_Tuple2(
								'form-field__date--filled',
								$elm_community$result_extra$Result$Extra$isOk(
									$justinmimbs$date$Date$fromIsoString(modelData.p8))),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$DateInput$getFormFieldDateSizeClass(configData.aH),
								true)
							])),
						$elm$html$Html$Attributes$disabled(configData.ff),
						$elm$html$Html$Attributes$value(modelData.p8),
						$elm$html$Html$Attributes$type_('date'),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
						$elm$html$Html$Attributes$name(configData.oq),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$min, configData.f_),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$max, configData.fW),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$step, configData.hL),
						$author$project$Pyxis$Commons$Attributes$ariaInvalid(
						$author$project$Pyxis$Components$Field$Error$isVisible(error)),
						A2(
						$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
						A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(
								$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
							configData.fy)),
						$elm$html$Html$Events$onInput(
						A2($elm$core$Basics$composeR, configData.hY, $author$project$Pyxis$Components$Field$DateInput$OnInput)),
						$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$DateInput$OnFocus),
						$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$DateInput$OnBlur)
					])),
			_List_Nil);
	});
var $author$project$Pyxis$Components$Field$DateInput$renderField = F3(
	function (error, model, configuration) {
		var disabled = configuration.ff;
		var classList = configuration.c5;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field', true),
								_Utils_Tuple2(
								'form-field--error',
								$author$project$Pyxis$Components$Field$Error$isVisible(error)),
								_Utils_Tuple2('form-field--disabled', disabled),
								_Utils_Tuple2('form-field--with-prepend-icon', true)
							]),
						classList))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-field__wrapper')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Field$DateInput$renderAddon(configuration),
							A3($author$project$Pyxis$Components$Field$DateInput$renderInput, error, model, configuration)
						]))
				]));
	});
var $author$project$Pyxis$Components$Field$DateInput$render = F3(
	function (tagger, model, config_) {
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$DateInput$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.eQ,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.n6,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$map,
							tagger,
							A3($author$project$Pyxis$Components$Field$DateInput$renderField, error, model, config_))))));
	});
var $author$project$Pyxis$Components$Field$DateInput$withLabel = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				n6: $elm$core$Maybe$Just(a)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eA: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Quotation$Common$Fields$View$birthday = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$DateInput$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BirthdayFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$DateInput$withLabel,
				A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è la tua data di nascita?', $author$project$Pyxis$Components$Field$Label$config),
				A3(
					$author$project$Pyxis$Components$Field$DateInput$withValidationOnSubmit,
					validation,
					isFormSubmitted,
					A2(
						$author$project$Pyxis$Components$Field$DateInput$withTestId,
						$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
						$author$project$Pyxis$Components$Field$DateInput$config(id)))));
	});
var $author$project$Quotation$Common$Fields$View$birthdayId = 'birthday';
var $author$project$Quotation$Common$View$FormRows$birthdayRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$birthday,
			$author$project$Quotation$Common$Fields$View$birthdayId,
			$author$project$Quotation$Common$Fields$Model$birthdayFieldValidation(
				$author$project$Quotation$Common$StepModel$pickCurrentDate(stepState)),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickBirthdayField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$birthdaySimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$birthdayRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$Model$CommercialPurposesFieldChanged = function (a) {
	return {$: 25, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$CheckboxGroup$option = function (_v0) {
	var label = _v0.n6;
	var value = _v0.p8;
	return {ff: false, n6: label, p8: value};
};
var $author$project$Quotation$Common$Fields$Model$toCheckboxGroupOptionValue = function (_v0) {
	var value = _v0.a;
	var label = _v0.b;
	return $author$project$Pyxis$Components$Field$CheckboxGroup$option(
		{n6: label, p8: value});
};
var $author$project$Quotation$Common$Fields$View$commercialPurposesOption = function () {
	var value = 1;
	var label = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'commercial-purposes-checkbox')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Acconsento all\'invio di comunicazioni commerciali da parte di Prima attraverso modalità automatizzate (email, sms, ecc.).')
			]));
	return $author$project$Quotation$Common$Fields$Model$toCheckboxGroupOptionValue(
		_Utils_Tuple2(value, label));
}();
var $author$project$Pyxis$Components$Field$CheckboxGroup$withOptions = F2(
	function (options, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				ku: $author$project$Pyxis$Components$Field$CheckboxGroup$OptionList(options)
			});
	});
var $author$project$Quotation$Common$Fields$View$commercialPurposes = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$CheckboxGroup$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$CommercialPurposesFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$CheckboxGroup$withOptions,
				_List_fromArray(
					[$author$project$Quotation$Common$Fields$View$commercialPurposesOption]),
				A2(
					$author$project$Pyxis$Components$Field$CheckboxGroup$withLayout,
					$author$project$Pyxis$Components$Field$CheckboxGroup$layoutVertical,
					A3(
						$author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						$author$project$Pyxis$Components$Field$CheckboxGroup$config(id)))));
	});
var $author$project$Quotation$Common$Fields$View$commercialPurposesId = 'commercial-purposes';
var $author$project$Quotation$Common$View$FormRows$commercialPurposesRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$commercialPurposes,
			$author$project$Quotation$Common$Fields$View$commercialPurposesId,
			$author$project$Quotation$Common$Fields$Model$commercialPurposesFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickCommercialPurposesField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Family$CustomerStep$Model$NoOp = {$: 17};
var $author$project$Quotation$Common$StepModel$pickModalState = function (_v0) {
	var modalState = _v0.kc;
	return modalState;
};
var $author$project$Quotation$Family$CustomerStep$Model$isFieldEditable = function (model) {
	return _Utils_eq(
		$author$project$Quotation$Common$StepModel$pickModalState(model),
		$author$project$Quotation$Family$CustomerStep$Model$ClosedModal);
};
var $author$project$Quotation$Family$CustomerStep$Model$conditionalFieldEditableMsg = F2(
	function (model, msg) {
		return $author$project$Quotation$Family$CustomerStep$Model$isFieldEditable(model) ? msg : $author$project$Quotation$Family$CustomerStep$Model$NoOp;
	});
var $author$project$Pyxis$Components$Message$Default = 0;
var $author$project$Pyxis$Components$Message$Neutral = 0;
var $author$project$Pyxis$Components$Message$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$IconSet$CheckCircle = 21;
var $author$project$Pyxis$Components$IconSet$checkCircle = 21;
var $author$project$Pyxis$Components$IconSet$ExclamationTriangle = 48;
var $author$project$Pyxis$Components$IconSet$exclamationTriangle = 48;
var $author$project$Pyxis$Components$IconSet$InfoCircle = 79;
var $author$project$Pyxis$Components$IconSet$infoCircle = 79;
var $author$project$Pyxis$Components$IconSet$ThumbUp = 131;
var $author$project$Pyxis$Components$IconSet$thumbUp = 131;
var $author$project$Pyxis$Components$Message$defaultIcon = function (variant) {
	switch (variant) {
		case 1:
			return $author$project$Pyxis$Components$IconSet$thumbUp;
		case 3:
			return $author$project$Pyxis$Components$IconSet$checkCircle;
		case 2:
			return $author$project$Pyxis$Components$IconSet$exclamationTriangle;
		case 4:
			return $author$project$Pyxis$Components$IconSet$exclamationCircle;
		case 5:
			return $author$project$Pyxis$Components$IconSet$infoCircle;
		default:
			return $author$project$Pyxis$Components$IconSet$infoCircle;
	}
};
var $author$project$Pyxis$Components$Message$config = F2(
	function (variant, style) {
		return {
			c5: _List_Nil,
			mQ: _List_Nil,
			dE: $author$project$Pyxis$Components$Message$defaultIcon(variant),
			nM: $elm$core$Maybe$Nothing,
			aW: $elm$core$Maybe$Nothing,
			hP: style,
			eA: $elm$core$Maybe$Nothing,
			eE: $elm$core$Maybe$Nothing,
			h_: variant
		};
	});
var $author$project$Pyxis$Components$Message$neutral = A2($author$project$Pyxis$Components$Message$config, 0, 0);
var $author$project$Pyxis$Components$Message$getMessageStyleClass = function (style) {
	if (style === 1) {
		return 'message--with-background-color';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Message$getMessageVariantClass = function (variant) {
	switch (variant) {
		case 1:
			return 'message--brand';
		case 3:
			return 'message--success';
		case 2:
			return 'message--alert';
		case 4:
			return 'message--error';
		case 5:
			return 'message--ghost';
		default:
			return '';
	}
};
var $author$project$Pyxis$Components$Message$getRole = function (variant) {
	if (variant === 4) {
		return 'alert';
	} else {
		return 'status';
	}
};
var $author$project$Pyxis$Components$Message$renderClose = function (_v0) {
	var onDismiss = _v0.aW;
	var ariaLabel = _v0.eV;
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('message__close'),
				$elm$html$Html$Attributes$type_('button'),
				$elm$html$Html$Events$onClick(onDismiss),
				$author$project$Pyxis$Commons$Attributes$ariaLabel(ariaLabel)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$cross))
			]));
};
var $author$project$Pyxis$Components$Message$renderTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('message__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$Message$render = function (_v0) {
	var classList = _v0.c5;
	var icon = _v0.dE;
	var id = _v0.nM;
	var testId = _v0.eA;
	var content = _v0.mQ;
	var title = _v0.eE;
	var onDismiss = _v0.aW;
	var style = _v0.hP;
	var variant = _v0.h_;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('message', true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Message$getMessageStyleClass(style),
						true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Message$getMessageVariantClass(variant),
						true)
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				$author$project$Pyxis$Commons$Attributes$role(
				$author$project$Pyxis$Components$Message$getRole(variant))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__icon')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Icon$render(
						$author$project$Pyxis$Components$Icon$config(icon))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__content-wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Message$renderTitle, title)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('message__text')
							]),
						content)
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Message$renderClose, onDismiss))
			]));
};
var $author$project$Pyxis$Components$Message$withContent = F2(
	function (htmlText, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{mQ: htmlText});
	});
var $author$project$Quotation$Common$View$FormRows$contactMessageView = function () {
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-s-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('I tuoi dati sono al sicuro: ')
					])),
				$elm$html$Html$text('usiamo i tuoi contatti solo per comunicazioni sulla polizza.')
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			$author$project$Pyxis$Components$Message$neutral));
}();
var $author$project$Quotation$Common$View$FormRows$contactMessageRow = $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
	A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Quotation$Common$View$FormRows$contactMessageView,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('quotation__form-divider')
					]),
				_List_Nil)
			])));
var $author$project$Quotation$Common$Fields$Model$EmailFieldChanged = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pyxis$Components$Field$Input$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var value = _v0.p8;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Field$Input$addonTypeToString = function (addonType) {
	if (!addonType.$) {
		return 'icon';
	} else {
		return 'text';
	}
};
var $author$project$Pyxis$Components$Field$Input$placementToString = function (placement) {
	if (!placement) {
		return 'prepend';
	} else {
		return 'append';
	}
};
var $author$project$Pyxis$Components$Field$Input$addonToAttribute = function (_v0) {
	var type_ = _v0.ao;
	var placement = _v0.aF;
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'form-field--with',
					$author$project$Pyxis$Components$Field$Input$placementToString(placement),
					$author$project$Pyxis$Components$Field$Input$addonTypeToString(type_)
				])));
};
var $author$project$Pyxis$Components$Field$Input$isAppend = function (placement) {
	if (placement === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$Input$isPrepend = function (placement) {
	if (!placement) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$Input$iconSizeByInputSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Input$renderAddonByType = F2(
	function (size, type_) {
		if (!type_.$) {
			var icon = type_.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__addon')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Icon$render(
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Field$Input$iconSizeByInputSize(size),
							$author$project$Pyxis$Components$Icon$config(icon)))
					]));
		} else {
			var str = type_.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__addon')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(str)
					]));
		}
	});
var $author$project$Pyxis$Components$Field$Input$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$Input$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$Input$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$Input$getFormFieldTextSizeClass = function (size) {
	if (!size) {
		return 'form-field__text--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Input$typeToAttribute = function (a) {
	switch (a) {
		case 0:
			return $elm$html$Html$Attributes$type_('email');
		case 1:
			return $elm$html$Html$Attributes$type_('number');
		case 2:
			return $elm$html$Html$Attributes$type_('password');
		default:
			return $elm$html$Html$Attributes$type_('text');
	}
};
var $author$project$Pyxis$Components$Field$Input$renderInput = F3(
	function (error, _v0, _v1) {
		var modelData = _v0;
		var configData = _v1;
		return A2(
			$elm$html$Html$input,
			A2(
				$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
				configData.e9,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(configData.nM),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field__text', true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$Input$getFormFieldTextSizeClass(configData.aH),
								true)
							])),
						$elm$html$Html$Attributes$disabled(configData.ff),
						$elm$html$Html$Attributes$value(modelData.p8),
						$author$project$Pyxis$Components$Field$Input$typeToAttribute(configData.ao),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
						$elm$html$Html$Attributes$name(configData.oq),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$placeholder, configData.oU),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$min, configData.f_),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$max, configData.fW),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$step, configData.hL),
						A2(
						$author$project$Pyxis$Commons$Attributes$maybe,
						$elm$html$Html$Attributes$attribute('autocomplete'),
						configData.L),
						$author$project$Pyxis$Commons$Attributes$ariaInvalid(
						$author$project$Pyxis$Components$Field$Error$isVisible(error)),
						A2(
						$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
						A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(
								$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
							configData.fy)),
						$elm$html$Html$Events$onInput(
						A2($elm$core$Basics$composeR, configData.hY, $author$project$Pyxis$Components$Field$Input$OnInput)),
						$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$Input$OnFocus),
						$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$Input$OnBlur)
					])),
			_List_Nil);
	});
var $author$project$Pyxis$Components$Field$Input$renderAddon = F4(
	function (error, model, configuration, addon) {
		var size = configuration.aH;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-field__wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$author$project$Pyxis$Components$Field$Input$isPrepend(addon.aF),
					A2($author$project$Pyxis$Components$Field$Input$renderAddonByType, size, addon.ao)),
					A3($author$project$Pyxis$Components$Field$Input$renderInput, error, model, configuration),
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$author$project$Pyxis$Components$Field$Input$isAppend(addon.aF),
					A2($author$project$Pyxis$Components$Field$Input$renderAddonByType, size, addon.ao))
				]));
	});
var $author$project$Pyxis$Components$Field$Input$renderField = F3(
	function (error, model, configuration) {
		var disabled = configuration.ff;
		var addon = configuration.h6;
		var classList = configuration.c5;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field', true),
								_Utils_Tuple2(
								'form-field--error',
								$author$project$Pyxis$Components$Field$Error$isVisible(error)),
								_Utils_Tuple2('form-field--disabled', disabled)
							]),
						classList)),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Field$Input$addonToAttribute, addon)
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					A3($author$project$Pyxis$Components$Field$Input$renderInput, error, model, configuration),
					A2(
						$elm$core$Maybe$map,
						A3($author$project$Pyxis$Components$Field$Input$renderAddon, error, model, configuration),
						addon))
				]));
	});
var $author$project$Pyxis$Components$Field$Input$render = F3(
	function (tagger, model, config_) {
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Input$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.eQ,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.n6,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$map,
							tagger,
							A3($author$project$Pyxis$Components$Field$Input$renderField, error, model, config_))))));
	});
var $author$project$Pyxis$Components$Field$Input$Text = 3;
var $author$project$Pyxis$Components$Field$Input$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Input$M = 1;
var $author$project$Pyxis$Components$Field$Input$config = F2(
	function (inputType, name) {
		return {eQ: $elm$core$Maybe$Nothing, h6: $elm$core$Maybe$Nothing, L: $elm$core$Maybe$Nothing, c5: _List_Nil, e9: _List_Nil, ff: false, b1: $elm$core$Maybe$Nothing, fy: $elm$core$Maybe$Nothing, nM: 'id-' + name, b8: false, n6: $elm$core$Maybe$Nothing, fW: $elm$core$Maybe$Nothing, f_: $elm$core$Maybe$Nothing, oq: name, oU: $elm$core$Maybe$Nothing, aH: 1, hL: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, ao: inputType, cQ: $elm$core$Maybe$Nothing, hY: $elm$core$Basics$identity};
	});
var $author$project$Pyxis$Components$Field$Input$text = $author$project$Pyxis$Components$Field$Input$config(3);
var $author$project$Pyxis$Components$Field$Input$withLabel = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				n6: $elm$core$Maybe$Just(a)
			});
	});
var $author$project$Pyxis$Components$Field$Input$withPlaceholder = F2(
	function (placeholder, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				oU: $elm$core$Maybe$Just(placeholder)
			});
	});
var $author$project$Pyxis$Components$Field$Input$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eA: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Field$Input$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Quotation$Common$Fields$View$email = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Input$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$EmailFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Input$withPlaceholder,
				'Inserisci indirizzo email',
				A2(
					$author$project$Pyxis$Components$Field$Input$withLabel,
					A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo indirizzo email?', $author$project$Pyxis$Components$Field$Label$config),
					A3(
						$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Input$withTestId,
							$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
							$author$project$Pyxis$Components$Field$Input$text(id))))));
	});
var $author$project$Quotation$Common$Fields$View$emailId = 'email';
var $author$project$Quotation$Common$View$FormRows$emailRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$email,
			$author$project$Quotation$Common$Fields$View$emailId,
			$author$project$Quotation$Common$Fields$Model$emailFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickEmailField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$emailSimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$emailRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$Model$GenderFieldChanged = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pyxis$Components$Field$RadioGroup$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$RadioGroup$Default = 2;
var $author$project$Pyxis$Components$Field$RadioGroup$OptionList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$RadioGroup$config = function (name) {
	return {
		eQ: $elm$core$Maybe$Nothing,
		c5: _List_Nil,
		e9: _List_Nil,
		ff: false,
		b1: $elm$core$Maybe$Nothing,
		fy: $elm$core$Maybe$Nothing,
		nM: 'id-' + name,
		b8: false,
		n6: $elm$core$Maybe$Nothing,
		ce: 2,
		oq: name,
		ku: $author$project$Pyxis$Components$Field$RadioGroup$OptionList(_List_Nil),
		eA: $elm$core$Maybe$Nothing,
		cQ: $elm$core$Maybe$Nothing
	};
};
var $author$project$Pyxis$Components$Field$RadioGroup$Vertical = 1;
var $author$project$Pyxis$Components$Field$RadioGroup$layoutVertical = 1;
var $author$project$Pyxis$Components$Field$RadioGroup$OptionCard = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$OptionCard$AddonSizeMType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$OptionCard$addonSizeMType = $author$project$Pyxis$Components$Field$OptionCard$AddonSizeMType;
var $author$project$Pyxis$Components$Field$OptionCard$M = 0;
var $author$project$Pyxis$Components$Field$OptionCard$sizeM = 0;
var $author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM = function (_v0) {
	var addon = _v0.h6;
	var text = _v0.lG;
	var title = _v0.eE;
	var value = _v0.p8;
	return {
		h6: A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$addonSizeMType, addon),
		ff: false,
		aH: $author$project$Pyxis$Components$Field$OptionCard$sizeM,
		lG: text,
		eE: title,
		p8: value
	};
};
var $author$project$Pyxis$Components$Field$RadioGroup$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var selectedValue = _v0.a2;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(selectedValue),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Field$RadioGroup$labelId = function (id) {
	return id + '-label';
};
var $author$project$Pyxis$Components$Field$RadioGroup$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$RadioGroup$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$RadioGroup$radioId = F2(
	function (id, label) {
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					id,
					$author$project$Pyxis$Commons$String$toKebabCase(label),
					'option'
				]));
	});
var $author$project$Pyxis$Components$Field$RadioGroup$renderRadio = F4(
	function (error, selectedValue, configData, _v0) {
		var value = _v0.p8;
		var label = _v0.n6;
		var disabled = _v0.ff;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-control', true),
							_Utils_Tuple2(
							'form-control-option--error',
							$author$project$Pyxis$Components$Field$Error$isVisible(error)),
							_Utils_Tuple2('form-control--disabled', disabled || configData.ff)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						configData.e9,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('radio'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-control__radio', true)
									])),
								$elm$html$Html$Attributes$checked(
								_Utils_eq(
									selectedValue,
									$elm$core$Maybe$Just(value))),
								$elm$html$Html$Attributes$disabled(disabled || configData.ff),
								$elm$html$Html$Attributes$id(
								A2($author$project$Pyxis$Components$Field$RadioGroup$radioId, configData.nM, label)),
								$elm$html$Html$Attributes$name(configData.oq),
								$elm$html$Html$Events$onCheck(
								$elm$core$Basics$always(
									$author$project$Pyxis$Components$Field$RadioGroup$OnCheck(value))),
								$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$RadioGroup$OnFocus),
								$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$RadioGroup$OnBlur)
							])),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control__text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						]))
				]));
	});
var $author$project$Pyxis$Components$Field$RadioGroup$renderField = F5(
	function (tagger, configData, selectedValue, error, options) {
		return A2(
			$elm$html$Html$map,
			tagger,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-control-group', true),
								_Utils_Tuple2('form-control-group--column', configData.ce === 1)
							])),
						$elm$html$Html$Attributes$classList(configData.c5),
						$elm$html$Html$Attributes$id(configData.nM),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
						$author$project$Pyxis$Commons$Attributes$role('radiogroup'),
						$author$project$Pyxis$Commons$Attributes$ariaDisabled(configData.ff),
						$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
						$author$project$Pyxis$Components$Field$RadioGroup$labelId(configData.nM)),
						$author$project$Pyxis$Commons$Attributes$ariaInvalid(
						$author$project$Pyxis$Components$Field$Error$isVisible(error)),
						A2(
						$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
						A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(
								$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
							configData.fy))
					]),
				A2(
					$elm$core$List$map,
					A3($author$project$Pyxis$Components$Field$RadioGroup$renderRadio, error, selectedValue, configData),
					options)));
	});
var $author$project$Pyxis$Components$Field$RadioGroup$getFormCardGroupLayoutClass = function (layout) {
	switch (layout) {
		case 2:
			return '';
		case 1:
			return 'form-card-group--column';
		default:
			return 'form-card-group--row';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$Radio = 0;
var $author$project$Pyxis$Components$Field$OptionCard$renderRadio = $author$project$Pyxis$Components$Field$OptionCard$render(0);
var $author$project$Pyxis$Components$Field$RadioGroup$renderOptionsCard = F3(
	function (_v0, errorId, options) {
		var name = _v0.oq;
		var layout = _v0.ce;
		var classList = _v0.c5;
		var id = _v0.nM;
		var disabled = _v0.ff;
		var hint = _v0.fy;
		var testId = _v0.eA;
		var dataAttrList = _v0.e9;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-card-group', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$RadioGroup$getFormCardGroupLayoutClass(layout),
							true)
						])),
					$elm$html$Html$Attributes$classList(classList),
					$elm$html$Html$Attributes$id(id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					$author$project$Pyxis$Commons$Attributes$role('radiogroup'),
					$author$project$Pyxis$Commons$Attributes$ariaDisabled(disabled),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					$author$project$Pyxis$Components$Field$RadioGroup$labelId(id)),
					$author$project$Pyxis$Commons$Attributes$ariaInvalid(
					$elm_community$maybe_extra$Maybe$Extra$isJust(errorId)),
					A2(
					$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
					errorId,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always(
							$author$project$Pyxis$Components$Field$Hint$toId(id)),
						hint))
				]),
			A2(
				$elm$core$List$indexedMap,
				A3(
					$author$project$Pyxis$Components$Field$OptionCard$renderRadio,
					$elm_community$maybe_extra$Maybe$Extra$isJust(errorId),
					dataAttrList,
					A4($author$project$Pyxis$Components$Field$OptionCard$config, name, id, $elm$core$Maybe$Nothing, disabled)),
				options));
	});
var $author$project$Pyxis$Components$Field$RadioGroup$toInternal = F3(
	function (tagger, selectedValues, _v0) {
		var addon = _v0.h6;
		var text = _v0.lG;
		var title = _v0.eE;
		var value = _v0.p8;
		var size = _v0.aH;
		var disabled = _v0.ff;
		return {
			h6: addon,
			ff: disabled,
			dL: _Utils_eq(
				$elm$core$Maybe$Just(value),
				selectedValues),
			f6: tagger($author$project$Pyxis$Components$Field$RadioGroup$OnBlur),
			d4: $elm$core$Basics$always(
				tagger(
					$author$project$Pyxis$Components$Field$RadioGroup$OnCheck(value))),
			gj: tagger($author$project$Pyxis$Components$Field$RadioGroup$OnFocus),
			aH: size,
			lG: text,
			eE: title,
			p8: value
		};
	});
var $author$project$Pyxis$Components$Field$RadioGroup$renderControlGroup = F5(
	function (tagger, configData, error, selectedValue, options) {
		if (!options.$) {
			var options_ = options.a;
			return A5($author$project$Pyxis$Components$Field$RadioGroup$renderField, tagger, configData, selectedValue, error, options_);
		} else {
			var optionsCard = options.a;
			return A3(
				$author$project$Pyxis$Components$Field$RadioGroup$renderOptionsCard,
				configData,
				A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
				A2(
					$elm$core$List$map,
					A2($author$project$Pyxis$Components$Field$RadioGroup$toInternal, tagger, selectedValue),
					optionsCard));
		}
	});
var $author$project$Pyxis$Components$Field$RadioGroup$render = F3(
	function (tagger, model, config_) {
		var selectedValue = model.a2;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$RadioGroup$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.eQ,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.n6,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A5($author$project$Pyxis$Components$Field$RadioGroup$renderControlGroup, tagger, configData, error, selectedValue, configData.ku)))));
	});
var $author$project$Pyxis$Components$Field$RadioGroup$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{c5: classList});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$withLabel = F2(
	function (label, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				n6: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$withLayout = F2(
	function (layout, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ce: layout});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$OptionCardList = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard = F2(
	function (options, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				ku: $author$project$Pyxis$Components$Field$RadioGroup$OptionCardList(options)
			});
	});
var $author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Quotation$Common$Fields$View$gender = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$RadioGroup$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$GenderFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
						{
							h6: $elm$core$Maybe$Nothing,
							lG: $elm$core$Maybe$Just('Maschile'),
							eE: $elm$core$Maybe$Nothing,
							p8: 0
						}),
						$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
						{
							h6: $elm$core$Maybe$Nothing,
							lG: $elm$core$Maybe$Just('Femminile'),
							eE: $elm$core$Maybe$Nothing,
							p8: 1
						})
					]),
				A2(
					$author$project$Pyxis$Components$Field$RadioGroup$withLabel,
					A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo sesso?', $author$project$Pyxis$Components$Field$Label$config),
					A3(
						$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$RadioGroup$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('quotation__fixed-two-column-layout', true)
								]),
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
								$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
								$author$project$Pyxis$Components$Field$RadioGroup$config(id)))))));
	});
var $author$project$Quotation$Common$Fields$View$genderId = 'gender';
var $author$project$Quotation$Common$View$FormRows$genderRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$gender,
			$author$project$Quotation$Common$Fields$View$genderId,
			$author$project$Quotation$Common$Fields$Model$genderFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickGenderField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$genderSimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$genderRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$StepModel$isAgent = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$StepModel$pickTokenType,
	$elm$core$Basics$eq(1));
var $author$project$Quotation$Common$Fields$Model$NameFieldChanged = function (a) {
	return {$: 15, a: a};
};
var $author$project$Quotation$Common$Fields$View$name = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Input$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$NameFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Input$withPlaceholder,
				'Inserisci nome',
				A2(
					$author$project$Pyxis$Components$Field$Input$withLabel,
					A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo nome?', $author$project$Pyxis$Components$Field$Label$config),
					A3(
						$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Input$withTestId,
							$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
							$author$project$Pyxis$Components$Field$Input$text(id))))));
	});
var $author$project$Quotation$Common$Fields$View$nameId = 'name';
var $author$project$Quotation$Common$View$FormRows$nameRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$name,
			$author$project$Quotation$Common$Fields$View$nameId,
			$author$project$Quotation$Common$Fields$Model$nameFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickNameField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$nameSimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$nameRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$Fields$Model$PhoneFieldChanged = function (a) {
	return {$: 22, a: a};
};
var $author$project$Quotation$Common$Fields$View$phone = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Input$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PhoneFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Input$withPlaceholder,
				'Inserisci numero di cellulare',
				A2(
					$author$project$Pyxis$Components$Field$Input$withLabel,
					A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo numero di cellulare?', $author$project$Pyxis$Components$Field$Label$config),
					A3(
						$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Input$withTestId,
							$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
							$author$project$Pyxis$Components$Field$Input$text(id))))));
	});
var $author$project$Quotation$Common$Fields$View$phoneId = 'phone';
var $author$project$Quotation$Common$View$FormRows$phoneRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$phone,
			$author$project$Quotation$Common$Fields$View$phoneId,
			$author$project$Quotation$Common$Fields$Model$phoneFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPhoneField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$phoneSimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$phoneRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual = function (_v0) {
	var isResidentialAddressManual = _v0.jT;
	return isResidentialAddressManual;
};
var $author$project$Quotation$Common$Fields$View$privacyId = 'privacy';
var $author$project$Quotation$Common$Fields$Model$PrivacyPolicyFieldChanged = function (a) {
	return {$: 24, a: a};
};
var $author$project$Quotation$Common$Fields$View$privacyPolicyModalLink = function (toggleMsg) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('link'),
				$elm$html$Html$Events$onClick(toggleMsg),
				A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-modal-link')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('l\'Informativa Privacy')
			]));
};
var $author$project$Quotation$Common$Fields$View$readPrivacyPolicyOption = function (toggleMsg) {
	var value = 0;
	var label = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-checkbox')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Dichiaro di aver letto '),
				$author$project$Quotation$Common$Fields$View$privacyPolicyModalLink(toggleMsg),
				$elm$html$Html$text(', disposta ai sensi degli articoli 13 e 14 del Regolamento UE 2016/679.')
			]));
	return $author$project$Quotation$Common$Fields$Model$toCheckboxGroupOptionValue(
		_Utils_Tuple2(value, label));
};
var $author$project$Pyxis$Components$Field$Label$S = 0;
var $author$project$Pyxis$Components$Field$Label$sizeS = 0;
var $author$project$Pyxis$Components$Field$CheckboxGroup$withLabel = F2(
	function (label, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				n6: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$Label$withSize = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{aH: a});
	});
var $author$project$Quotation$Common$Fields$View$privacyPolicy = F6(
	function (id, validation, isFormSubmitted, toggleMsg, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$CheckboxGroup$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PrivacyPolicyFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$CheckboxGroup$withOptions,
				_List_fromArray(
					[
						$author$project$Quotation$Common$Fields$View$readPrivacyPolicyOption(toggleMsg)
					]),
				A2(
					$author$project$Pyxis$Components$Field$CheckboxGroup$withLayout,
					$author$project$Pyxis$Components$Field$CheckboxGroup$layoutVertical,
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withSize,
							$author$project$Pyxis$Components$Field$Label$sizeS,
							A2($author$project$Pyxis$Components$Field$Label$withText, 'Informativa privacy', $author$project$Pyxis$Components$Field$Label$config)),
						A3(
							$author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							$author$project$Pyxis$Components$Field$CheckboxGroup$config(id))))));
	});
var $author$project$Quotation$Common$View$FormRows$privacyPolicyRow = F3(
	function (stepState, fieldChangedMsgMapper, toggleMsg) {
		return A6(
			$author$project$Quotation$Common$Fields$View$privacyPolicy,
			$author$project$Quotation$Common$Fields$View$privacyId,
			$author$project$Quotation$Common$Fields$Model$privacyPolicyFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			toggleMsg,
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPrivacyPolicyField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$privacySimpleRow = F2(
	function (privacyPolicyView, commercialPurposesView) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						privacyPolicyView,
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('margin-v-xs')
							]),
						_List_fromArray(
							[commercialPurposesView]))
					])));
	});
var $author$project$Quotation$Family$CustomerStep$Model$FieldInfoModalUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Family$CustomerStep$Model$OpenFieldInfoModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Fields$Model$ResidentialAddressInfoModal = 8;
var $author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalLinkText = function (fieldInfoModal) {
	if (fieldInfoModal === 8) {
		return 'Perché ci serve saperlo';
	} else {
		return '';
	}
};
var $author$project$Quotation$Common$Fields$View$residentialAddressId = 'residential-address';
var $author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalLinkText(8),
		nM: $author$project$Quotation$Common$Fields$View$residentialAddressId,
		gc: $author$project$Quotation$Family$CustomerStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Family$CustomerStep$Model$OpenFieldInfoModal(8))
	});
var $author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoMessage = function (stepState) {
	var onClickMsg = A2(
		$author$project$Quotation$Family$CustomerStep$Model$conditionalFieldEditableMsg,
		stepState,
		A2(
			$author$project$Quotation$Family$CustomerStep$Model$ToggleManualResidentialAddress,
			1,
			!$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState)));
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se la ricerca automatica non trova l’indirizzo esatto della tua residenza, puoi inserirlo manualmente.')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-s-bold link link--no-underline'),
						$elm$html$Html$Events$onClick(onClickMsg),
						$author$project$Pyxis$Commons$Attributes$ariaLabel('manual-residential-address')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Inserisci manualmente')
					]))
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			$author$project$Pyxis$Components$Message$neutral));
};
var $author$project$Quotation$Common$Fields$View$FieldLabel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Common$Fields$View$manualResidentialAddressBtnId = 'manual-residential-address-btn';
var $author$project$Quotation$Common$Fields$View$renderInfoLink = function (infoLink) {
	if (!infoLink.$) {
		var htmlContent = infoLink.a;
		return htmlContent;
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$Common$Fields$View$renderInfoMessage = function (infoMessage) {
	if (!infoMessage.$) {
		var htmlContent = infoMessage.a;
		return htmlContent;
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$Common$Fields$View$residentialAddress = F9(
	function (id, validation, isFormSubmitted, msgMapper, manualBtnMsg, field, optionalLabel, optionalFieldInfo, optionalMessageView) {
		var withOptionalLabel = function () {
			if (!optionalLabel.$) {
				var labelValue = optionalLabel.a;
				return $author$project$Pyxis$Components$Field$Autocomplete$withLabel(
					A2($author$project$Pyxis$Components$Field$Label$withText, labelValue, $author$project$Pyxis$Components$Field$Label$config));
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Components$DebouncedAutocomplete$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ResidentialAddressFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withNoResultFoundAction,
						$author$project$Pyxis$Components$Button$render(
							A2(
								$author$project$Pyxis$Components$Button$withOnMouseDown,
								manualBtnMsg,
								A2(
									$author$project$Pyxis$Components$Button$withType,
									$author$project$Pyxis$Components$Button$button,
									A2(
										$author$project$Pyxis$Components$Button$withText,
										'Inserisci manualmente',
										A2(
											$author$project$Pyxis$Components$Button$withTestId,
											$author$project$Quotation$Common$Fields$View$manualResidentialAddressBtnId,
											A2($author$project$Pyxis$Components$Button$withId, $author$project$Quotation$Common$Fields$View$manualResidentialAddressBtnId, $author$project$Pyxis$Components$Button$ghost)))))),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
							$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
								'Inserisci via, numero civico e comune',
								withOptionalLabel(
									A3(
										$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
										validation,
										isFormSubmitted,
										A2(
											$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
											$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
											$author$project$Pyxis$Components$Field$Autocomplete$config(id)))))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$View$FormRows$residentialAddressRow = F7(
	function (stepState, fieldChangedMsgMapper, manualBtnMsg, isValidationVisible, optionalLabel, optionalFieldInfo, optionalMessage) {
		return A9(
			$author$project$Quotation$Common$Fields$View$residentialAddress,
			$author$project$Quotation$Common$Fields$View$residentialAddressId,
			$author$project$Quotation$Common$Fields$Model$optionalResidentialAddressFieldValidation(true),
			isValidationVisible,
			fieldChangedMsgMapper,
			manualBtnMsg,
			$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalLabel,
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$residentialAddressSimpleRow = F7(
	function (stepState, fieldChangedMsgMapper, manualBtnMsg, isValidationVisible, label, optionalFieldInfo, optionalMessage) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A7(
				$author$project$Quotation$Common$View$FormRows$residentialAddressRow,
				stepState,
				fieldChangedMsgMapper,
				manualBtnMsg,
				isValidationVisible,
				$author$project$Quotation$Common$Fields$View$FieldLabel(label),
				optionalFieldInfo,
				optionalMessage));
	});
var $author$project$Quotation$Common$Fields$Model$SurnameFieldChanged = function (a) {
	return {$: 16, a: a};
};
var $author$project$Quotation$Common$Fields$View$surname = F5(
	function (id, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Input$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$SurnameFieldChanged, msgMapper),
			field,
			A2(
				$author$project$Pyxis$Components$Field$Input$withPlaceholder,
				'Inserisci cognome',
				A2(
					$author$project$Pyxis$Components$Field$Input$withLabel,
					A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il tuo cognome?', $author$project$Pyxis$Components$Field$Label$config),
					A3(
						$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Input$withTestId,
							$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
							$author$project$Pyxis$Components$Field$Input$text(id))))));
	});
var $author$project$Quotation$Common$Fields$View$surnameId = 'surname';
var $author$project$Quotation$Common$View$FormRows$surnameRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A5(
			$author$project$Quotation$Common$Fields$View$surname,
			$author$project$Quotation$Common$Fields$View$surnameId,
			$author$project$Quotation$Common$Fields$Model$surnameFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickSurnameField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$View$FormRows$surnameSimpleRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A2($author$project$Quotation$Common$View$FormRows$surnameRow, stepState, fieldChangedMsgMapper));
	});
var $author$project$Quotation$Family$CustomerStep$View$fieldsetContent = function (stepState) {
	var residentialAddressRowList = _List_fromArray(
		[
			A7(
			$author$project$Quotation$Common$View$FormRows$residentialAddressSimpleRow,
			stepState,
			$author$project$Quotation$Family$CustomerStep$Model$FieldChanged,
			A2(
				$author$project$Quotation$Family$CustomerStep$Model$conditionalFieldEditableMsg,
				stepState,
				A2(
					$author$project$Quotation$Family$CustomerStep$Model$ToggleManualResidentialAddress,
					0,
					!$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState))),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) || $elm_community$maybe_extra$Maybe$Extra$isJust(
				$author$project$Components$DebouncedAutocomplete$getValue(
					$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(
						$author$project$Quotation$Common$StepModel$pickFormData(stepState)))),
			'Qual è il tuo indirizzo di residenza?',
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoLink),
			$author$project$Quotation$Common$Fields$View$InfoMessage(
				$author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoMessage(stepState)))
		]);
	var privacyPolicyRow = A3(
		$author$project$Quotation$Common$View$FormRows$privacyPolicyRow,
		stepState,
		$author$project$Quotation$Family$CustomerStep$Model$FieldChanged,
		$author$project$Quotation$Family$CustomerStep$Model$TogglePrivacyPolicy(true));
	var emailRowList = $author$project$Quotation$Common$StepModel$isAgent(stepState) ? _List_Nil : _List_fromArray(
		[
			A2($author$project$Quotation$Common$View$FormRows$emailSimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged)
		]);
	var countryRowList = $author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
		$author$project$Quotation$Common$StepModel$pickFormData(stepState)) ? _List_fromArray(
		[
			A2($author$project$Quotation$Common$View$FormRows$birthCountrySimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged)
		]) : _List_Nil;
	var commercialPurposesRow = A2($author$project$Quotation$Common$View$FormRows$commercialPurposesRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged);
	return _Utils_ap(
		_List_fromArray(
			[
				A2($author$project$Quotation$Common$View$FormRows$nameSimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$surnameSimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$genderSimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$birthdaySimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$birthCitySimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged)
			]),
		_Utils_ap(
			countryRowList,
			_Utils_ap(
				residentialAddressRowList,
				_Utils_ap(
					emailRowList,
					_List_fromArray(
						[
							A2($author$project$Quotation$Common$View$FormRows$phoneSimpleRow, stepState, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
							$author$project$Quotation$Common$View$FormRows$contactMessageRow,
							A2($author$project$Quotation$Common$View$FormRows$privacySimpleRow, privacyPolicyRow, commercialPurposesRow)
						])))));
};
var $author$project$Quotation$Family$CustomerStep$View$header = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Con dati reali e corretti puoi visualizzare il tuo preventivo e gestire l\'offerta per te',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Dati su di te e contatti', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Quotation$Family$CustomerStep$View$fieldsetConfig = function (stepState) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		_List_fromArray(
			[
				$author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
				$author$project$Quotation$Family$CustomerStep$View$buttonsRow(stepState))
			]),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Quotation$Family$CustomerStep$View$fieldsetContent(stepState),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				_List_fromArray(
					[
						$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Family$CustomerStep$View$header)
					]),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalMessage = function (fieldInfoModal) {
	if (fieldInfoModal === 8) {
		return _List_fromArray(
			['Questa informazione è utile per il calcolo del preventivo, perché il prezzo della polizza' + ' potrebbe variare a seconda del tuo indirizzo di residenza.']);
	} else {
		return _List_Nil;
	}
};
var $author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalTitle = function (fieldInfoModal) {
	if (fieldInfoModal === 8) {
		return 'Perché ci serve sapere qual è il tuo indirizzo di residenza';
	} else {
		return '';
	}
};
var $author$project$Quotation$Common$View$Modals$FieldInfo$content = function (textRows) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-m-book margin-b-s')
				]),
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$html$Html$text,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$elm$html$Html$div(_List_Nil))),
				textRows))
		]);
};
var $author$project$Pyxis$Components$Modal$Footer$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$Footer$config = {c3: _List_Nil, c5: _List_Nil, df: $elm$core$Maybe$Nothing, dx: false, dR: false, lG: $elm$core$Maybe$Nothing, eB: $author$project$Pyxis$Commons$Properties$Theme$default};
var $author$project$Pyxis$Components$Modal$Footer$withButtons = F2(
	function (buttons, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{c3: buttons});
	});
var $author$project$Pyxis$Components$Modal$Footer$withFullWidthButton = F2(
	function (fullWidthButton, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{dx: fullWidthButton});
	});
var $author$project$Quotation$Common$View$Modals$FieldInfo$footer = F2(
	function (id, closeMsg) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						closeMsg,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								'Ho capito',
								A2($author$project$Pyxis$Components$Button$withId, id + '-confirm-btn', $author$project$Pyxis$Components$Button$primary)))))
				]),
			A2($author$project$Pyxis$Components$Modal$Footer$withFullWidthButton, true, $author$project$Pyxis$Components$Modal$Footer$config));
	});
var $author$project$Quotation$Common$View$Modals$FieldInfo$header = function (title) {
	return A2($author$project$Pyxis$Components$Modal$Header$withTitle, title, $author$project$Pyxis$Components$Modal$Header$config);
};
var $author$project$Pyxis$Components$Modal$withFooter = F2(
	function (footer, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				ds: $elm$core$Maybe$Just(footer)
			});
	});
var $author$project$Quotation$Common$View$Modals$FieldInfo$view = F5(
	function (id, isOpen, closeMsg, title, message) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			A2($author$project$Utils$initSimpleModal, id, isOpen),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A2($author$project$Quotation$Common$View$Modals$FieldInfo$footer, id, closeMsg),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					$author$project$Quotation$Common$View$Modals$FieldInfo$content(message),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Quotation$Common$View$Modals$FieldInfo$header(title),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							closeMsg,
							'Chiudi',
							A2(
								$author$project$Pyxis$Components$Modal$withIsCentered,
								true,
								A2(
									$author$project$Pyxis$Components$Modal$withSize,
									$author$project$Pyxis$Components$Modal$sizeS,
									A2($author$project$Pyxis$Components$Modal$withTestId, id, $author$project$Pyxis$Components$Modal$config))))))));
	});
var $author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'residential-address-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Family$CustomerStep$Model$OpenFieldInfoModal(8)),
		$author$project$Quotation$Family$CustomerStep$Model$FieldInfoModalUpdated($author$project$Quotation$Family$CustomerStep$Model$ClosedModal),
		$author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalTitle(8),
		$author$project$Quotation$Family$CustomerStep$View$getOpenFieldInfoModalMessage(8));
};
var $author$project$Quotation$Family$CustomerStep$Model$CloseResidentialAddressFallbackModal = {$: 10};
var $author$project$Quotation$Common$View$Modals$AddressFallback$cityIdSuffix = '-city';
var $author$project$Quotation$Common$Components$AddressFallback$pickIsSubmitted = function (_v0) {
	var isSubmitted = _v0.b8;
	return isSubmitted;
};
var $author$project$Quotation$Common$View$Modals$AddressFallback$toFieldDataTestId = F2(
	function (id, suffix) {
		return 'id-' + (id + suffix);
	});
var $author$project$Quotation$Common$View$Modals$AddressFallback$cityRow = F3(
	function (id, stepState, msgTagger) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Components$DebouncedAutocomplete$render,
				A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$CityChanged, msgTagger),
				$author$project$Quotation$Common$Components$AddressFallback$pickCityField(
					$author$project$Quotation$Common$Components$AddressFallback$pickFormData(stepState)),
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
					$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
						'Cerca comune',
						A3(
							$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
							$author$project$Quotation$Common$Components$AddressFallback$cityFieldValidation,
							$author$project$Quotation$Common$Components$AddressFallback$pickIsSubmitted(stepState),
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
								A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il comune?', $author$project$Pyxis$Components$Field$Label$config),
								A2(
									$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
									A2($author$project$Quotation$Common$View$Modals$AddressFallback$toFieldDataTestId, id, $author$project$Quotation$Common$View$Modals$AddressFallback$cityIdSuffix),
									$author$project$Pyxis$Components$Field$Autocomplete$config(
										_Utils_ap(id, $author$project$Quotation$Common$View$Modals$AddressFallback$cityIdSuffix)))))))));
	});
var $author$project$Quotation$Common$Components$AddressFallback$pickIsZipCodeFieldVisible = function (_v0) {
	var isZipCodeFieldVisible = _v0.fO;
	return isZipCodeFieldVisible;
};
var $author$project$Quotation$Common$Components$AddressFallback$StreetNameChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Common$View$Modals$AddressFallback$streetNameIdSuffix = '-street-name';
var $author$project$Quotation$Common$View$Modals$AddressFallback$streetNameRow = F3(
	function (id, stepState, msgTagger) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Pyxis$Components$Field$Input$render,
				A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$StreetNameChanged, msgTagger),
				$author$project$Quotation$Common$Components$AddressFallback$pickStreetNameField(
					$author$project$Quotation$Common$Components$AddressFallback$pickFormData(stepState)),
				A2(
					$author$project$Pyxis$Components$Field$Input$withPlaceholder,
					'Inserisci via',
					A2(
						$author$project$Pyxis$Components$Field$Input$withLabel,
						A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è la via?', $author$project$Pyxis$Components$Field$Label$config),
						A3(
							$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
							$author$project$Quotation$Common$Components$AddressFallback$streetNameFieldValidation,
							$author$project$Quotation$Common$Components$AddressFallback$pickIsSubmitted(stepState),
							A2(
								$author$project$Pyxis$Components$Field$Input$withTestId,
								A2($author$project$Quotation$Common$View$Modals$AddressFallback$toFieldDataTestId, id, $author$project$Quotation$Common$View$Modals$AddressFallback$streetNameIdSuffix),
								$author$project$Pyxis$Components$Field$Input$text(
									_Utils_ap(id, $author$project$Quotation$Common$View$Modals$AddressFallback$streetNameIdSuffix))))))));
	});
var $author$project$Quotation$Common$Components$AddressFallback$StreetNumberChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Common$View$Modals$AddressFallback$streetNumberIdSuffix = '-street-number';
var $author$project$Quotation$Common$View$Modals$AddressFallback$streetNumberRow = F3(
	function (id, stepState, msgTagger) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Pyxis$Components$Field$Input$render,
				A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$StreetNumberChanged, msgTagger),
				$author$project$Quotation$Common$Components$AddressFallback$pickStreetNumberField(
					$author$project$Quotation$Common$Components$AddressFallback$pickFormData(stepState)),
				A2(
					$author$project$Pyxis$Components$Field$Input$withPlaceholder,
					'Inserisci numero civico',
					A2(
						$author$project$Pyxis$Components$Field$Input$withLabel,
						A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il numero civico?', $author$project$Pyxis$Components$Field$Label$config),
						A3(
							$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
							$author$project$Quotation$Common$Components$AddressFallback$streetNumberFieldValidation,
							$author$project$Quotation$Common$Components$AddressFallback$pickIsSubmitted(stepState),
							A2(
								$author$project$Pyxis$Components$Field$Input$withTestId,
								A2($author$project$Quotation$Common$View$Modals$AddressFallback$toFieldDataTestId, id, $author$project$Quotation$Common$View$Modals$AddressFallback$streetNumberIdSuffix),
								$author$project$Pyxis$Components$Field$Input$text(
									_Utils_ap(id, $author$project$Quotation$Common$View$Modals$AddressFallback$streetNumberIdSuffix))))))));
	});
var $author$project$Quotation$Common$Components$AddressFallback$ZipCodeChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Common$View$Modals$AddressFallback$zipIdSuffix = '-zipcode';
var $author$project$Quotation$Common$View$Modals$AddressFallback$zipCodeRow = F3(
	function (id, stepState, msgTagger) {
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Pyxis$Components$Field$Autocomplete$render,
				A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Components$AddressFallback$ZipCodeChanged, msgTagger),
				$author$project$Quotation$Common$Components$AddressFallback$pickZipCodeField(
					$author$project$Quotation$Common$Components$AddressFallback$pickFormData(stepState)),
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
					$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
						'Cerca CAP',
						A3(
							$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
							$author$project$Quotation$Common$Components$AddressFallback$zipCodeFieldValidation,
							$author$project$Quotation$Common$Components$AddressFallback$pickIsSubmitted(stepState),
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
								A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è il CAP?', $author$project$Pyxis$Components$Field$Label$config),
								A2(
									$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
									A2($author$project$Quotation$Common$View$Modals$AddressFallback$toFieldDataTestId, id, $author$project$Quotation$Common$View$Modals$AddressFallback$zipIdSuffix),
									$author$project$Pyxis$Components$Field$Autocomplete$config(
										_Utils_ap(id, $author$project$Quotation$Common$View$Modals$AddressFallback$zipIdSuffix)))))))));
	});
var $author$project$Quotation$Common$View$Modals$AddressFallback$fieldSetContent = F3(
	function (id, stepState, msgTagger) {
		var conditionalZipCodeRow = A3(
			$author$project$Utils$pipedTernaryOperator,
			_List_fromArray(
				[
					A3($author$project$Quotation$Common$View$Modals$AddressFallback$zipCodeRow, id, stepState, msgTagger)
				]),
			_List_Nil,
			$author$project$Quotation$Common$Components$AddressFallback$pickIsZipCodeFieldVisible(stepState));
		return A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A3($author$project$Quotation$Common$View$Modals$AddressFallback$streetNameRow, id, stepState, msgTagger),
					A3($author$project$Quotation$Common$View$Modals$AddressFallback$streetNumberRow, id, stepState, msgTagger),
					A3($author$project$Quotation$Common$View$Modals$AddressFallback$cityRow, id, stepState, msgTagger)
				]),
			conditionalZipCodeRow);
	});
var $author$project$Quotation$Common$View$Modals$AddressFallback$fieldSetConfig = F3(
	function (id, stepState, msgTagger) {
		return A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			A3($author$project$Quotation$Common$View$Modals$AddressFallback$fieldSetContent, id, stepState, msgTagger),
			$author$project$Pyxis$Components$Form$Fieldset$config);
	});
var $author$project$Quotation$Common$View$Modals$AddressFallback$content = F3(
	function (id, stepState, msgTagger) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-v-m')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Fieldset$render(
						A3($author$project$Quotation$Common$View$Modals$AddressFallback$fieldSetConfig, id, stepState, msgTagger))
					]))
			]);
	});
var $author$project$Quotation$Common$Components$AddressFallback$Submit = {$: 9};
var $author$project$Quotation$Common$View$Modals$AddressFallback$footer = F2(
	function (id, msgTagger) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						msgTagger($author$project$Quotation$Common$Components$AddressFallback$Submit),
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								'Conferma',
								A2(
									$author$project$Pyxis$Components$Button$withTestId,
									id + '-confirm-btn',
									A2($author$project$Pyxis$Components$Button$withId, id + '-confirm-btn', $author$project$Pyxis$Components$Button$primary))))))
				]),
			A2($author$project$Pyxis$Components$Modal$Footer$withFullWidthButton, true, $author$project$Pyxis$Components$Modal$Footer$config));
	});
var $author$project$Quotation$Common$View$Modals$AddressFallback$header = function (title) {
	return A2($author$project$Pyxis$Components$Modal$Header$withTitle, title, $author$project$Pyxis$Components$Modal$Header$config);
};
var $author$project$Quotation$Common$View$Modals$AddressFallback$view = F6(
	function (id, title, isOpen, msgTagger, closeMsg, stepState) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			A2($author$project$Utils$initSimpleModal, id, isOpen),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A2($author$project$Quotation$Common$View$Modals$AddressFallback$footer, id, msgTagger),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A3($author$project$Quotation$Common$View$Modals$AddressFallback$content, id, stepState, msgTagger),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Quotation$Common$View$Modals$AddressFallback$header(title),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							closeMsg,
							'Chiudi',
							A2(
								$author$project$Pyxis$Components$Modal$withIsCentered,
								true,
								A2(
									$author$project$Pyxis$Components$Modal$withSize,
									$author$project$Pyxis$Components$Modal$sizeS,
									A2($author$project$Pyxis$Components$Modal$withTestId, id, $author$project$Pyxis$Components$Modal$config))))))));
	});
var $author$project$Quotation$Family$CustomerStep$View$residentialFallbackAddressModal = function (stepState) {
	return A6(
		$author$project$Quotation$Common$View$Modals$AddressFallback$view,
		'residential-fallback-address-modal',
		'Qual è il tuo indirizzo di residenza?',
		$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState),
		A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ResidentialFallbackAddressFieldsChanged, $author$project$Quotation$Family$CustomerStep$Model$FieldChanged),
		$author$project$Quotation$Family$CustomerStep$Model$CloseResidentialAddressFallbackModal,
		$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $author$project$Types$pickCookiePageUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.iE;
	});
var $author$project$Types$pickLoginPageUrl = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $.j6;
	});
var $author$project$Quotation$Common$PrivacyData$privacyText = function (flags) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Il presente testo, reso ai sensi degli articoli 13 e 14 del Regolamento UE 2016/679 (\"GDPR\"), è stato redatto con lo scopo di informare, con riguardo al trattamento dei loro dati personali, tutti i soggetti coinvolti a qualsiasi titolo (richiedenti una quotazione, contraenti, assicurati, conducenti abituali, qui indicati singolarmente il “'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Cliente')
							])),
						$elm$html$Html$text('” o collettivamente i “'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Clienti')
							])),
						$elm$html$Html$text('”) nel rapporto assicurativo con Prima Assicurazioni S.p.A.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Titolare del trattamento e Responsabile della Protezione dei Dati')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Prima Assicurazioni S.p.A. (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Prima')
							])),
						$elm$html$Html$text('”, “'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Società')
							])),
						$elm$html$Html$text('” o “'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Titolare')
							])),
						$elm$html$Html$text('” del trattamento), con sede legale in Piazzale Loreto 17, 20131 Milano (MI), e-mail: '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-privacy-email'),
								$elm$html$Html$Attributes$class('link'),
								$elm$html$Html$Attributes$href('mailto:privacy@prima.it')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('privacy@prima.it')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Il Responsabile della Protezione dei Dati Personali (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('DPO')
							])),
						$elm$html$Html$text('”) è raggiungibile all’indirizzo: '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-dpo-email'),
								$elm$html$Html$Attributes$class('link'),
								$elm$html$Html$Attributes$href('mailto:dpo@prima.it')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('dpo@prima.it')
							]))
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Categorie e tipologie di Dati Personali e relative fonti')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('In occasione della gestione del rapporto assicurativo, ivi incluso l’utilizzo del sito web (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Sito')
							])),
						$elm$html$Html$text('”) e dell\'applicazione (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('App')
							])),
						$elm$html$Html$text('”) della Società, i dati personali (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Dati')
							])),
						$elm$html$Html$text('”) - da Lei comunicati oppure ottenuti presso terzi - oggetto di trattamento da parte del Titolare sono:')
					])),
				A2(
				$elm$html$Html$ol,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'list-style', 'decimal'),
						$elm$html$Html$Attributes$class('padding-l-xl')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati identificativi e anagrafici (ad es. nome, cognome, codice fiscale, indirizzo di residenza/domicilio, stato civile, professione, documento di identità).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati di contatto (ad es. indirizzo e-mail e recapito telefonico).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati bancari e fiscali (ad es. metodo di pagamento, codice IBAN ed estremi del conto corrente, P.IVA, pagamenti effettuati).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('In relazione al prodotto “Motor” (RC Auto/Moto/Ciclomotori/Furgoni), i dati relativi al veicolo e alla posizione assicurativa (ad es. tipologia di veicolo e numero di targa, numero di preventivo/polizza, classe di merito, informazioni contenute nell\'Attestato di Rischio).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('In relazione ai prodotti “Casa e famiglia”, i dati relativi all\'immobile e alla posizione assicurativa (ad es. indirizzo, tipologia, uso, sinistri denunciati).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('La voce in occasione delle eventuali registrazioni delle chiamate audio effettuate verso il nostro Servizio Clienti;')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('L\'immagine in occasione dell\'eventuale raccolta di documenti di identità.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati relativi allo stato di salute in relazione a eventuali lesioni subite in occasione di un sinistro.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('La posizione GPS da Lei volontariamente condivisa in occasione di un\'eventuale richiesta di assistenza stradale mediante l\'App.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati relativi alle sessioni di navigazione sul sito internet della Società (ad es. indirizzo IP, cookies, log access, username e credenziali di accesso all\''),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-login-page-link'),
										$elm$html$Html$Attributes$class('link'),
										$elm$html$Html$Attributes$target('_blank'),
										$elm$html$Html$Attributes$href(
										$author$project$Types$pickLoginPageUrl(flags))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('area riservata')
									])),
								$elm$html$Html$text(').')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Esclusivamente in presenza di specifici presupposti normativi e/o richieste provenienti delle Autorità competenti, i dati relativi alle condanne penali e ai reati o a connesse misure di sicurezza, nonché a indagini e accertamenti giudiziari in corso.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('L’appartenenza a liste di sanzioni internazionali.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('I dati e le informazioni contenuti all\'interno di eventuali comunicazioni, richieste e segnalazioni da Lei effettuati verso Prima e mediante qualsiasi canale ufficiale della Società (ad es. posta elettronica ordinaria/PEC).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Le valutazioni, le recensioni e i feedback resi nell’ambito del rapporto business.')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('I dati personali sono raccolti dal Titolare direttamente presso l’interessato oppure possono essere raccolti da altre fonti quali, a titolo esemplificativo, Associazione Nazionale fra le Imprese Assicuratrici (ANIA), Istituto per la Vigilanza sulle Assicurazioni (IVASS), Pubblico Registro Automobilistico (PRA).')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Il Cliente è tenuto a comunicare esclusivamente i dati personali a sé riferiti.')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Laddove vi sia la necessità di comunicare Dati afferenti a terzi (ad es. relativi al contraente della polizza e/o al conducente abituale del veicolo e/o a soggetti coinvolti in un sinistro, qualora diversi dall\'assicurato), sarà responsabilità del Cliente fornire tali Dati esatti, nonché mettere a disposizione dei rispettivi soggetti la presente informativa sul trattamento dei Dati Personali.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Finalità e base giuridica del trattamento')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Il Titolare effettua il trattamento dei Dati per le seguenti finalità:')
					])),
				A2(
				$elm$html$Html$ol,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'list-style', 'decimal'),
						$elm$html$Html$Attributes$class('padding-l-xl')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Consentire l’erogazione dei servizi richiesti, nonché instaurare e gestire il rapporto di assicurazione, ivi incluso ogni adempimento di natura precontrattuale e contrattuale da esso derivante (ai sensi dell’art. 6, par. 1, lett. b) GDPR), tra cui a titolo esemplificativo:'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('i. ')
									])),
								$elm$html$Html$text('la vendita e la distribuzione di polizze (calcolo della tariffa, quotazione, emissione preventivo, emissione della polizza, invio documentazione contrattuale, supporto nel collocamento a distanza);'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('ii. ')
									])),
								$elm$html$Html$text('la creazione/gestione/cancellazione dell’area riservata;'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('iii. ')
									])),
								$elm$html$Html$text('la gestione e la modifica delle polizze attive;'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('iv. ')
									])),
								$elm$html$Html$text('la tutela legale e la gestione dei contenziosi;'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('v. ')
									])),
								$elm$html$Html$text('l’assistenza stradale;'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('vi. ')
									])),
								$elm$html$Html$text('la gestione dei sinistri (denuncia, apertura, accertamento, chiusura e liquidazione, indagine), occasione in cui potrebbero essere trattati anche Dati relativi allo stato di salute ex art. 9 GDPR (ai sensi dell’art. 9, par. 2, lett. a) GDPR);'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('vii. ')
									])),
								$elm$html$Html$text('la gestione dei rimborsi;'),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$strong,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fw-heavy')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('viii. ')
									])),
								$elm$html$Html$text('la gestione di ogni richiesta pervenuta a qualsiasi canale aziendale.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Registrare le chiamate audio effettuate verso il nostro Servizio Clienti, ai fini del controllo qualitativo e del miglioramento dei servizi offerti, esigenze produttive e organizzative, nonché finalità difensive (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Gestire ogni genere di comunicazione il cui riscontro è disposto dalla normativa applicabile e vigente, a titolo esemplificativo le segnalazioni, l’esercizio dei diritti in materia di protezione dei dati personali, le recensioni negative effettuati dal Cliente verso Prima e mediante qualsiasi canale ufficiale della Società, ad esempio posta elettronica ordinaria/PEC (ai sensi dell’art. 6, par. 1, lett. c) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('In relazione al punto precedente, talvolta potrebbe essere necessario richiedere un documento di riconoscimento al fine di identificare correttamente il soggetto (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Gestire i rischi, nonché prevenire e individuare le frodi e il furto d’identità, tramite attività di revisione, ispezione, controllo interno e audit (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Effettuare attività e analisi in ambito IT-Security e Cybersecurity, al fine di tutelare il patrimonio aziendale (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Adempiere gli obblighi legali cui è sottoposta la Società, ivi inclusi quelli di natura fiscale, tributaria, amministrativo-contabile, nonché quanto necessario ad assolvere specifiche normative di settore, come ad esempio in conformità al D.lgs. 231/2001 e s.m.i., al Regolamento IVASS n. 40/2018, al Codice delle Assicurazioni Private (ai sensi dell’art. 6, par. 1, lett. c) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Nei limiti di quanto previsto dalle leggi o dai regolamenti in materia - laddove strettamente necessario e solo in presenza di puntuali e rigorosi indizi di frode, nonché in relazione alla tipologia di sinistro, alle relative dinamiche di svolgimento, alla rilevanza del ruolo rivestito e/o alle peculiari circostanze del caso concreto - adempiere gli obblighi legali cui è sottoposta la Società, ivi inclusi quelli finalizzati all\'accertamento di responsabilità in relazione a sinistri o eventi attinenti alla vita umana, nonché alla prevenzione, all\'accertamento e al contrasto di frodi o situazioni di concreto rischio per il corretto esercizio dell’attività assicurativa (ai sensi dell\'art. 6, par. 1, lett. c) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Qualora necessario, accertare, esercitare o difendere i diritti del Titolare in sede giudiziaria ed extragiudiziaria (ai sensi dell’art. 6, par. 1, lett. f) GDPR);')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Contribuire al continuo miglioramento dei processi relativi alle attività svolte dalla Società, ad esempio effettuando analisi statistiche, ivi inclusi lo sviluppo e il test di nuove funzionalità (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Finalità di marketing effettuate dal Titolare, con modalità tradizionali e con modalità automatizzate di contatto (e-mail, SMS, notifiche push in App), consistenti, ad esempio, nell’invio di comunicazioni commerciali e di materiale pubblicitario, Direct Email Marketing, compimento di ricerche di mercato e indagini sulla qualità del servizio erogato e la soddisfazione del Cliente, concorsi a premio, surveys e interviste, campagne social (ai sensi dell\'art. 6, par.1, lett. a) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Inviare comunicazioni (newsletter), mediante posta elettronica, in merito a servizi analoghi a quelli acquistati (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Per quanto concerne il sito internet e l’App, consentire il corretto funzionamento e la manutenzione degli stessi, la gestione degli accessi e delle credenziali, l’assistenza tecnica, nonché il miglioramento dell’esperienza di navigazione (per maggiori dettagli, è sempre consultabile online la rispettiva '),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-cookie-page-link'),
										$elm$html$Html$Attributes$class('link'),
										$elm$html$Html$Attributes$target('_blank'),
										$elm$html$Html$Attributes$href(
										$author$project$Types$pickCookiePageUrl(flags))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('cookie policy')
									])),
								$elm$html$Html$text(') (ai sensi dell’art. 6, par. 1, lett. f) GDPR).')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Il conferimento dei dati personali è necessario per il perseguimento delle finalità sopra riportate, in particolare per la gestione del rapporto assicurativo, nonché per adempiere gli obblighi di legge. In caso di mancato conferimento - parziale o totale - degli stessi, potrebbero essere pregiudicate – in tutto o in parte - le rispettive finalità.')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Il conferimento è facoltativo per le ulteriori finalità per cui è previsto il consenso espresso. L’eventuale diniego non pregiudica il trattamento dei Dati relativi alle finalità per cui il conferimento è necessario. In ogni caso, il consenso verrà richiesto tramite una distinta dichiarazione.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Destinatari')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('I Dati possono essere comunicati a soggetti operanti in qualità di autonomi titolari del trattamento, quali ad esempio autorità e/o enti pubblici nel caso in cui ciò sia previsto dalla legge e/o da regolamenti di settore, nonché possono essere trattati, per conto della Società, da soggetti ulteriori che forniscono servizi inerenti o connessi al rapporto assicurativo, designati responsabili del trattamento – mediante specifico accordo – ai sensi dell’art. 28 GDPR.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('In particolare, si fa riferimento a categorie di soggetti facenti parte della “catena assicurativa” (a titolo esemplificativo, le Compagnie Assicurative mandanti e le controparti in caso di sinistro, banche, istituti di credito, società di recupero crediti, attuari, avvocati e medici, consulenti tecnici, periti, officine e carrozzerie, società di assistenza stradale, società di liquidazione dei sinistri, etc.), società affiliate a Prima, nonché altre aziende alle quali vengono affidati servizi informatici e telematici, postali, finanziari, amministrativi, di archiviazione, di gestione della corrispondenza, di revisione contabile e certificazione di bilancio, nonché società specializzate in ricerche di mercato e indagini sulla qualità dei servizi.')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('I Dati possono essere trattati dai dipendenti delle funzioni aziendali deputate al perseguimento delle finalità sopra indicate, che sono stati espressamente autorizzati al trattamento e che hanno ricevuto adeguate istruzioni operative.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Modalità di Trattamento')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('I Dati sono trattati mediante modalità e strumenti, analogici e digitali, tali da garantire la sicurezza e la riservatezza degli stessi, oltre al rispetto degli specifici obblighi sanciti dalla legge.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Periodo di conservazione')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('I Dati sono conservati per il periodo di tempo strettamente necessario al perseguimento delle finalità sopra descritte e in considerazione degli obblighi legali e degli adempimenti normativi a cui è soggetto il Titolare, fino a un periodo massimo di 10 (dieci) anni, ai sensi dell’art. 2220 c.c.. In relazione alle registrazioni delle chiamate, esse verranno conservate per un periodo di 30 (trenta) giorni, eccetto quelle conservate per un periodo massimo di 5 (cinque) anni dalla cessazione del contratto nei casi previsti dalla normativa di settore.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Nel caso di contenzioso giudiziale, vengono conservati per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione.')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Con riguardo alle finalità che prevedono uno specifico consenso espresso, il trattamento viene effettuato fino alla revoca dello stesso.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Trasferimento verso Paesi terzi')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('I Dati non sono oggetto di trasferimento verso Paesi non appartenenti al territorio dell’Unione Europea e dello Spazio Economico Europeo (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('SEE')
							])),
						$elm$html$Html$text('”) oppure verso organizzazioni internazionali.')
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Qualora sia necessario comunicare tali Dati a destinatari situati fuori dallo SEE, il Titolare assicura che il trattamento avviene in conformità alla normativa vigente applicabile, ad esempio in virtù di una decisione di adeguatezza della Commissione Europea (ai sensi dell’art. 45 GDPR) oppure delle Clausole Contrattuali Standard approvate dalla Commissione europea (ai sensi dell’art. 46 GDPR), considerando la giurisprudenza della Corte di Giustizia dell’Unione Europea (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('CGUE')
							])),
						$elm$html$Html$text('”) e le decisioni n. 1/20 e 2/20 emesse dal Comitato Europeo per la Protezione dei Dati (“'),
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('EDPB')
							])),
						$elm$html$Html$text('”).')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Diritti dell\'interessato')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Laddove applicabile, in base a quanto previsto dagli artt. 15 e successivi del GDPR, l’interessato può contattare il Titolare del trattamento all’indirizzo e-mail '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-privacy-email'),
								$elm$html$Html$Attributes$class('link'),
								$elm$html$Html$Attributes$href('mailto:privacy@prima.it')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('privacy@prima.it')
							])),
						$elm$html$Html$text(' e/o il DPO all’indirizzo e-mail '),
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-dpo-email'),
								$elm$html$Html$Attributes$class('link'),
								$elm$html$Html$Attributes$href('mailto:dpo@prima.it')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('dpo@prima.it')
							])),
						$elm$html$Html$text(' in qualsiasi momento, al fine di:')
					])),
				A2(
				$elm$html$Html$ul,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('padding-l-xl')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Chiedere l’accesso ai Dati che lo riguardano, la loro cancellazione, la rettifica dei Dati errati, l’integrazione dei Dati incompleti, nonché la limitazione del trattamento.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Ricevere, ove previsto, in un formato strutturato, di uso comune e leggibile da dispositivo automatico i Dati, nonché, se tecnicamente fattibile, di trasmetterli a un altro titolare senza impedimenti (“diritto alla portabilità dei dati”).')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Opporsi al trattamento nelle ipotesi di legittimo interesse del Titolare per motivi connessi alla situazione particolare.')
							])),
						A2(
						$elm$html$Html$li,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Revocare in qualsiasi momento il consenso facoltativo e liberamente prestato.')
							]))
					])),
				A2(
				$elm$html$Html$p,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('margin-b-xl')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('L’interessato ha altresì il diritto di proporre reclamo all\'Autorità di controllo competente.')
					])),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2($elm$html$Html$p, _List_Nil, _List_Nil),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$strong,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fw-heavy')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ultimo aggiornamento ')
							])),
						$elm$html$Html$text('28/09/2023')
					]))
			]));
};
var $author$project$Quotation$Common$View$Modals$PrivacyPolicy$content = function (flags) {
	return _List_fromArray(
		[
			$author$project$Quotation$Common$PrivacyData$privacyText(flags)
		]);
};
var $author$project$Quotation$Common$View$Modals$PrivacyPolicy$modalId = 'privacy-modal';
var $author$project$Quotation$Common$View$Modals$PrivacyPolicy$footer = function (togglePrivacyPolicyMsg) {
	return A2(
		$author$project$Pyxis$Components$Modal$Footer$withButtons,
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Button$render(
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					togglePrivacyPolicyMsg,
					A2(
						$author$project$Pyxis$Components$Button$withType,
						$author$project$Pyxis$Components$Button$button,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							'Chiudi',
							A2($author$project$Pyxis$Components$Button$withId, $author$project$Quotation$Common$View$Modals$PrivacyPolicy$modalId + '-close-btn', $author$project$Pyxis$Components$Button$primary)))))
			]),
		A2($author$project$Pyxis$Components$Modal$Footer$withFullWidthButton, true, $author$project$Pyxis$Components$Modal$Footer$config));
};
var $author$project$Quotation$Common$View$Modals$PrivacyPolicy$header = A2($author$project$Pyxis$Components$Modal$Header$withTitle, 'Informativa Privacy Clienti', $author$project$Pyxis$Components$Modal$Header$config);
var $author$project$Quotation$Common$View$Modals$PrivacyPolicy$view = F3(
	function (isOpen, flags, togglePrivacyPolicyMsg) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			A2($author$project$Utils$initSimpleModal, $author$project$Quotation$Common$View$Modals$PrivacyPolicy$modalId, isOpen),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				$author$project$Quotation$Common$View$Modals$PrivacyPolicy$footer(togglePrivacyPolicyMsg),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					$author$project$Quotation$Common$View$Modals$PrivacyPolicy$content(flags),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Quotation$Common$View$Modals$PrivacyPolicy$header,
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							togglePrivacyPolicyMsg,
							'Chiudi',
							A2($author$project$Pyxis$Components$Modal$withTestId, $author$project$Quotation$Common$View$Modals$PrivacyPolicy$modalId, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Quotation$Family$CustomerStep$View$view = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-responsive')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Fieldset$render(
				$author$project$Quotation$Family$CustomerStep$View$fieldsetConfig(stepState)),
				$author$project$Quotation$Family$CustomerStep$View$residentialAddressInfoModal(stepState),
				$author$project$Quotation$Family$CustomerStep$View$residentialFallbackAddressModal(stepState),
				A3(
				$author$project$Quotation$Common$View$Modals$PrivacyPolicy$view,
				_Utils_eq(
					$author$project$Quotation$Common$StepModel$pickModalState(stepState),
					$author$project$Quotation$Family$CustomerStep$Model$OpenPrivacyPolicyModal),
				$author$project$Quotation$Common$StepModel$pickFlags(stepState),
				$author$project$Quotation$Family$CustomerStep$Model$TogglePrivacyPolicy(false))
			]));
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$TogglePrivacyPolicy = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Common$Fields$Model$FamilyMembersInfoModal = 10;
var $author$project$Quotation$Family$FamilyInfoStep$Model$FieldInfoModalUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$OpenFieldInfoModal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalMessage = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 9:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché ci aiuta a valutare' + ' meglio di che copertura hai bisogno.']);
		case 10:
			return _List_fromArray(
				['Questa informazione è utile per sapere il numero di persone che risulteranno coperte dalla polizza,' + ' cioè tutte quelle che fanno parte della tua famiglia e hanno la tua stessa residenza.']);
		default:
			return _List_Nil;
	}
};
var $author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalTitle = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 9:
			return 'Perché chiediamo se hai già denunciato altri sinistri';
		case 10:
			return 'Perché ci serve sapere quante persone fanno parte della tua famiglia';
		default:
			return '';
	}
};
var $author$project$Quotation$Family$FamilyInfoStep$View$familyMembersInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'family-members-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Family$FamilyInfoStep$Model$OpenFieldInfoModal(10)),
		$author$project$Quotation$Family$FamilyInfoStep$Model$FieldInfoModalUpdated($author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal),
		$author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalTitle(10),
		$author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalMessage(10));
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$FieldChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Common$Fields$View$WithoutInfoMessage = {$: 1};
var $author$project$Quotation$Common$Fields$Model$AdultsFieldChanged = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pyxis$Components$Field$Select$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Select$M = 1;
var $author$project$Pyxis$Components$Field$Select$config = F2(
	function (name, isMobile) {
		return {eQ: $elm$core$Maybe$Nothing, L: $elm$core$Maybe$Nothing, c5: _List_Nil, e9: _List_Nil, b1: $elm$core$Maybe$Nothing, fy: $elm$core$Maybe$Nothing, nM: 'id-' + name, aB: false, _: isMobile, b8: false, n6: $elm$core$Maybe$Nothing, oq: name, oU: $elm$core$Maybe$Nothing, aH: 1, eA: $elm$core$Maybe$Nothing, cQ: $elm$core$Maybe$Nothing};
	});
var $author$project$Pyxis$Components$Field$Select$OnBlur = {$: 1};
var $author$project$Pyxis$Components$Field$Select$OnClick = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$Select$OnFocus = {$: 2};
var $author$project$Pyxis$Components$Field$Select$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pyxis$Components$Field$Select$Select = {$: 0};
var $author$project$Pyxis$Commons$Attributes$ariaHasPopup = $elm$html$Html$Attributes$attribute('aria-haspopup');
var $author$project$Pyxis$Components$Field$Select$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dq;
		var selectedValue = _v0.a2;
		var id = _v1.nM;
		var isSubmitted = _v1.b8;
		var validation = _v1.cQ;
		var errorShowingStrategy = _v1.b1;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.n0),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.n$),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(selectedValue),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Field$Select$getDropdownId = function (id) {
	return id + '-dropdown';
};
var $author$project$Pyxis$Components$Field$Select$getDropdownWrapperSizeClass = function (size) {
	if (!size) {
		return 'form-dropdown-wrapper--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Select$getFormFieldSelectSizeClass = function (size) {
	if (!size) {
		return 'form-field__select--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Label$sizeM = 1;
var $author$project$Pyxis$Components$Field$Select$mapLabelSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Field$Label$sizeS;
	} else {
		return $author$project$Pyxis$Components$Field$Label$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Select$getLabelConfig = function (_v0) {
	var configData = _v0;
	return A2(
		$elm$core$Maybe$map,
		$author$project$Pyxis$Components$Field$Label$withFor(configData.nM),
		A2(
			$elm$core$Maybe$map,
			$author$project$Pyxis$Components$Field$Label$withSize(
				$author$project$Pyxis$Components$Field$Select$mapLabelSize(configData.aH)),
			configData.n6));
};
var $author$project$Pyxis$Components$Field$Select$OnKeyDown = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$Field$Select$handleKeydown = F2(
	function (tagger, key) {
		return _Utils_Tuple2(
			tagger(
				$author$project$Pyxis$Components$Field$Select$OnKeyDown(key)),
			A2(
				$elm$core$List$any,
				function (check) {
					return check(key);
				},
				_List_fromArray(
					[$author$project$Pyxis$Commons$Events$Keyboard$isArrowUp, $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown, $author$project$Pyxis$Commons$Events$Keyboard$isEnter, $author$project$Pyxis$Commons$Events$Keyboard$isSpace, $author$project$Pyxis$Commons$Events$Keyboard$isEsc])));
	});
var $author$project$Pyxis$Components$IconSet$ChevronDown = 28;
var $author$project$Pyxis$Components$IconSet$chevronDown = 28;
var $author$project$Pyxis$Components$Field$Select$iconSizeBySelectSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Select$renderAddon = function (size) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-field__addon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Field$Select$iconSizeBySelectSize(size),
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$chevronDown)))
			]));
};
var $author$project$Pyxis$Components$Field$Select$renderNativeOptions = F2(
	function (_v0, _v1) {
		var placeholder = _v0.oU;
		var selectedValue = _v1.a2;
		var options = _v1.ku;
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$hidden(true),
						$elm$html$Html$Attributes$disabled(true),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(selectedValue, $elm$core$Maybe$Nothing))
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $elm$html$Html$text, placeholder))
					])),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var value = _v2.p8;
					var label = _v2.n6;
					return A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(value),
								$elm$html$Html$Attributes$selected(
								_Utils_eq(
									$elm$core$Maybe$Just(value),
									selectedValue))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(label)
							]));
				},
				options));
	});
var $author$project$Pyxis$Components$Field$Select$DropdownOption = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$Select$OnMouseEnter = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pyxis$Commons$Attributes$ariaPosInset = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	$elm$html$Html$Attributes$attribute('aria-posinset'));
var $author$project$Pyxis$Commons$Attributes$ariaSetSize = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	$elm$html$Html$Attributes$attribute('aria-setsize'));
var $author$project$Pyxis$Components$Field$Select$renderOptions = F2(
	function (tagger, _v0) {
		var selectedValue = _v0.a2;
		var activeOption = _v0.G;
		var options = _v0.ku;
		var isActive = function (value) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Select$pickOptionValue,
						$elm$core$Basics$eq(value)),
					activeOption));
		};
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, option_) {
					var id = option_.nM;
					var value = option_.p8;
					var label = option_.n6;
					return A2(
						$elm$html$Html$map,
						tagger,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-dropdown__item', true),
											_Utils_Tuple2(
											'form-dropdown__item--hover',
											isActive(value)),
											_Utils_Tuple2(
											'form-dropdown__item--active',
											_Utils_eq(
												selectedValue,
												$elm$core$Maybe$Just(value)))
										])),
									$elm$html$Html$Attributes$id(id),
									$author$project$Pyxis$Commons$Attributes$role('option'),
									$author$project$Pyxis$Commons$Attributes$ariaSelected(
									isActive(value)),
									$author$project$Pyxis$Commons$Attributes$ariaPosInset(index + 1),
									$author$project$Pyxis$Commons$Attributes$ariaSetSize(
									$elm$core$List$length(options)),
									A2(
									$author$project$Pyxis$Commons$Events$preventDefaultOn,
									'mousedown',
									$author$project$Pyxis$Components$Field$Select$OnClick(
										$author$project$Pyxis$Components$Field$Select$DropdownOption(option_))),
									$elm$html$Html$Events$onMouseEnter(
									$author$project$Pyxis$Components$Field$Select$OnMouseEnter(option_)),
									$elm$html$Html$Attributes$tabindex(-1)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								])));
				}),
			options);
	});
var $author$project$Pyxis$Commons$Events$stopPropagationOn = F2(
	function (event, msg) {
		return A2(
			$elm$html$Html$Events$stopPropagationOn,
			event,
			$elm$json$Json$Decode$succeed(
				_Utils_Tuple2(msg, true)));
	});
var $author$project$Pyxis$Components$Field$Select$render = F3(
	function (tagger, model, config_) {
		var modelData = model;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Select$generateErrorConfig, model, config_);
		var activeOptionId = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Select$getOptionData,
					function ($) {
						return $.nM;
					}),
				modelData.G));
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withLabel,
				$author$project$Pyxis$Components$Field$Select$getLabelConfig(config_),
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
					configData.eQ,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						{
							fy: A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Field$Hint$withFieldId(configData.nM),
								configData.fy),
							nM: configData.nM
						},
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_Utils_ap(
										_List_fromArray(
											[
												_Utils_Tuple2('form-field', true),
												_Utils_Tuple2('form-field--with-custom-dropdown', !configData._),
												_Utils_Tuple2('form-field--with-opened-dropdown', (!configData._) && modelData.jP),
												_Utils_Tuple2(
												'form-field--error',
												$author$project$Pyxis$Components$Field$Error$isVisible(error)),
												_Utils_Tuple2('form-field--disabled', configData.aB)
											]),
										configData.c5))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('form-field__wrapper')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$select,
											A2(
												$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
												configData.e9,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2('form-field__select', true),
																_Utils_Tuple2('form-field__select--with-no-pointer-events', !configData._),
																_Utils_Tuple2(
																'form-field__select--filled',
																!_Utils_eq(modelData.a2, $elm$core$Maybe$Nothing)),
																_Utils_Tuple2('form-field__select--native', configData._),
																_Utils_Tuple2(
																$author$project$Pyxis$Components$Field$Select$getFormFieldSelectSizeClass(configData.aH),
																true)
															])),
														$elm$html$Html$Attributes$name(configData.oq),
														$elm$html$Html$Attributes$id(configData.nM),
														$elm$html$Html$Attributes$disabled(configData.aB),
														A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eA),
														A2(
														$author$project$Pyxis$Commons$Attributes$maybe,
														$elm$html$Html$Attributes$attribute('autocomplete'),
														configData.L),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData.aB,
														$elm$html$Html$Events$onInput(
															A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Field$Select$OnSelect, tagger))),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData.aB,
														$elm$html$Html$Events$onFocus(
															tagger($author$project$Pyxis$Components$Field$Select$OnFocus))),
														$elm$html$Html$Events$onBlur(
														tagger($author$project$Pyxis$Components$Field$Select$OnBlur)),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData.aB,
														A2(
															$author$project$Pyxis$Commons$Events$stopPropagationOn,
															'click',
															tagger(
																$author$project$Pyxis$Components$Field$Select$OnClick($author$project$Pyxis$Components$Field$Select$Select)))),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData._,
														$author$project$Pyxis$Commons$Attributes$role('combobox')),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData._,
														$author$project$Pyxis$Commons$Attributes$ariaControls(
															$author$project$Pyxis$Components$Field$Select$getDropdownId(configData.nM))),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData._,
														$author$project$Pyxis$Commons$Attributes$ariaHasPopup('listbox')),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData._,
														$author$project$Pyxis$Commons$Attributes$ariaExpanded(modelData.jP)),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														!configData._,
														$author$project$Pyxis$Commons$Attributes$ariaActiveDescendant(activeOptionId)),
														A2(
														$author$project$Pyxis$Commons$Attributes$renderIf,
														(!configData._) && (!configData.aB),
														A2(
															$author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn,
															'keydown',
															$author$project$Pyxis$Components$Field$Select$handleKeydown(tagger))),
														$author$project$Pyxis$Commons$Attributes$ariaInvalid(
														$author$project$Pyxis$Components$Field$Error$isVisible(error)),
														A2(
														$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
														A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.nM),
														A2(
															$elm$core$Maybe$map,
															$elm$core$Basics$always(
																$author$project$Pyxis$Components$Field$Hint$toId(configData.nM)),
															configData.fy))
													])),
											A2($author$project$Pyxis$Components$Field$Select$renderNativeOptions, config_, model)),
											$author$project$Pyxis$Components$Field$Select$renderAddon(configData.aH)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('form-dropdown-wrapper', true),
													_Utils_Tuple2(
													$author$project$Pyxis$Components$Field$Select$getDropdownWrapperSizeClass(configData.aH),
													true)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('form-dropdown'),
													$elm$html$Html$Attributes$id(
													$author$project$Pyxis$Components$Field$Select$getDropdownId(configData.nM)),
													$author$project$Pyxis$Commons$Attributes$role('listbox'),
													$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(configData.nM),
													$elm$html$Html$Attributes$tabindex(-1)
												]),
											A2($author$project$Pyxis$Components$Field$Select$renderOptions, tagger, model))
										]))
								]))))));
	});
var $author$project$Pyxis$Components$Field$Select$withLabel = F2(
	function (label, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				n6: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$Select$withPlaceholder = F2(
	function (placeholder, _v0) {
		var select = _v0;
		return _Utils_update(
			select,
			{
				oU: $elm$core$Maybe$Just(placeholder)
			});
	});
var $author$project$Pyxis$Components$Field$Select$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				b1: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				b8: isSubmitted,
				cQ: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Quotation$Common$Fields$View$adults = F6(
	function (id, isMobile, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Select$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$AdultsFieldChanged, msgMapper),
			field,
			A3(
				$author$project$Pyxis$Components$Field$Select$withValidationOnSubmit,
				validation,
				isFormSubmitted,
				A2(
					$author$project$Pyxis$Components$Field$Select$withPlaceholder,
					'Seleziona',
					A2(
						$author$project$Pyxis$Components$Field$Select$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withSize,
							$author$project$Pyxis$Components$Field$Label$sizeS,
							A2($author$project$Pyxis$Components$Field$Label$withText, 'Maggiorenni', $author$project$Pyxis$Components$Field$Label$config)),
						A2($author$project$Pyxis$Components$Field$Select$config, id, isMobile)))));
	});
var $author$project$Quotation$Common$Fields$View$adultsId = 'adults';
var $author$project$Types$pickIsMobile = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.z;
	},
	function ($) {
		return $._;
	});
var $author$project$Quotation$Common$View$FormRows$adultsRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A6(
			$author$project$Quotation$Common$Fields$View$adults,
			$author$project$Quotation$Common$Fields$View$adultsId,
			$author$project$Types$pickIsMobile(
				$author$project$Quotation$Common$StepModel$pickFlags(stepState)),
			$author$project$Quotation$Common$Fields$Model$adultsFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickAdultsField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Common$Fields$Model$ChildrenFieldChanged = function (a) {
	return {$: 14, a: a};
};
var $author$project$Quotation$Common$Fields$View$children = F6(
	function (id, isMobile, validation, isFormSubmitted, msgMapper, field) {
		return A3(
			$author$project$Pyxis$Components$Field$Select$render,
			A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ChildrenFieldChanged, msgMapper),
			field,
			A3(
				$author$project$Pyxis$Components$Field$Select$withValidationOnSubmit,
				validation,
				isFormSubmitted,
				A2(
					$author$project$Pyxis$Components$Field$Select$withPlaceholder,
					'Seleziona',
					A2(
						$author$project$Pyxis$Components$Field$Select$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withSize,
							$author$project$Pyxis$Components$Field$Label$sizeS,
							A2($author$project$Pyxis$Components$Field$Label$withText, 'Minorenni', $author$project$Pyxis$Components$Field$Label$config)),
						A2($author$project$Pyxis$Components$Field$Select$config, id, isMobile)))));
	});
var $author$project$Quotation$Common$Fields$View$childrenId = 'children';
var $author$project$Quotation$Common$View$FormRows$childrenRow = F2(
	function (stepState, fieldChangedMsgMapper) {
		return A6(
			$author$project$Quotation$Common$Fields$View$children,
			$author$project$Quotation$Common$Fields$View$childrenId,
			$author$project$Types$pickIsMobile(
				$author$project$Quotation$Common$StepModel$pickFlags(stepState)),
			$author$project$Quotation$Common$Fields$Model$childrenFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickChildrenField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$isFieldEditable = F2(
	function (model, expandableFieldId) {
		return A2(
			$elm$core$Set$member,
			expandableFieldId,
			$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model)) && _Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(model),
			$author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal);
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$conditionalFieldEditableMsg = F3(
	function (model, expandableFieldId, msg) {
		return A2($author$project$Quotation$Family$FamilyInfoStep$Model$isFieldEditable, model, expandableFieldId) ? msg : $author$project$Quotation$Family$FamilyInfoStep$Model$NoOp;
	});
var $author$project$Quotation$Common$Fields$Model$getAdultsTypeValue = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickAdultsField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Select$getValue,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Quotation$Common$Fields$Model$stringToAdultsTypeMapper),
			$elm_community$maybe_extra$Maybe$Extra$join)));
var $author$project$Quotation$Common$Fields$Model$getChildrenTypeValue = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$pickChildrenField,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Select$getValue,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Quotation$Common$Fields$Model$stringToChildrenTypeMapper),
			$elm_community$maybe_extra$Maybe$Extra$join)));
var $author$project$Quotation$Common$View$FormRows$invalidFamilyMembersFieldTitle = 'Seleziona numero persone';
var $author$project$Quotation$Common$View$FormRows$adultsTitleText = function (adultsValue) {
	return (!adultsValue) ? 'Un maggiorenne' : ($author$project$Quotation$Common$Fields$Model$adultsTypeToStringMapper(adultsValue) + ' maggiorenni');
};
var $author$project$Quotation$Common$View$FormRows$childrenTitleText = function (childrenValue) {
	return (!childrenValue) ? 'nessun minorenne' : ((childrenValue === 1) ? 'un minorenne' : ($elm$core$String$toLower(
		$author$project$Quotation$Common$Fields$Model$childrenTypeToStringMapper(childrenValue)) + ' minorenni'));
};
var $author$project$Quotation$Common$View$FormRows$toFamilyMembersTitle = F2(
	function (adultsValue, childrenValue) {
		return $author$project$Quotation$Common$View$FormRows$adultsTitleText(adultsValue) + (' e ' + $author$project$Quotation$Common$View$FormRows$childrenTitleText(childrenValue));
	});
var $author$project$Quotation$Common$View$FormRows$familyMembersTitle = function (stepData) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Quotation$Common$View$FormRows$invalidFamilyMembersFieldTitle,
		A3(
			$elm$core$Maybe$map2,
			$author$project$Quotation$Common$View$FormRows$toFamilyMembersTitle,
			$author$project$Quotation$Common$Fields$Model$getAdultsTypeValue(stepData),
			$author$project$Quotation$Common$Fields$Model$getChildrenTypeValue(stepData)));
};
var $author$project$Quotation$Common$StepModel$isFieldDisabled = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickEnabledFieldsIds,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Set$member(id),
			$elm$core$Basics$not));
};
var $author$project$Quotation$Common$StepModel$isFieldExpanded = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds,
		$elm$core$Set$member(id));
};
var $author$project$Quotation$Common$View$FormRows$familyMembersExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId, stepState),
		jY: !_Utils_eq(
			$author$project$Quotation$Common$View$FormRows$familyMembersTitle(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			$author$project$Quotation$Common$View$FormRows$invalidFamilyMembersFieldTitle),
		n6: 'Quante persone vivono in casa?',
		eE: $author$project$Quotation$Common$View$FormRows$familyMembersTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$View$ExpandableField$contentWrapperView = F2(
	function (isExpanded, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('quotation__expandable-field__content-wrapper', true),
							_Utils_Tuple2('quotation__expandable-field__content-wrapper--expanded', isExpanded)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('quotation__expandable-field__content')
						]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Quotation$Common$View$ExpandableField$filterAttributesTuples = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$filter($elm$core$Tuple$first),
	$elm$core$List$map($elm$core$Tuple$second));
var $author$project$Pyxis$Components$IconSet$Pen = 112;
var $author$project$Pyxis$Components$IconSet$pen = 112;
var $author$project$Quotation$Common$View$ExpandableField$titleRowConditionalAttributes = F4(
	function (msg, isExpanded, isDisabled, isValid) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				true,
				$elm$html$Html$Attributes$class('quotation__expandable-field__title-row')),
				_Utils_Tuple2(
				isExpanded,
				$elm$html$Html$Attributes$class('quotation__expandable-field__title-row--expanded')),
				_Utils_Tuple2(
				isDisabled,
				$elm$html$Html$Attributes$class('quotation__expandable-field__title-row--disabled')),
				_Utils_Tuple2(
				isValid,
				$elm$html$Html$Attributes$class('quotation__expandable-field__title-row--valid')),
				_Utils_Tuple2(
				!isDisabled,
				$elm$html$Html$Events$onClick(msg))
			]);
	});
var $author$project$Quotation$Common$View$ExpandableField$titleRowView = F5(
	function (msg, isExpanded, isDisabled, isValid, title) {
		return A2(
			$elm$html$Html$div,
			$author$project$Quotation$Common$View$ExpandableField$filterAttributesTuples(
				A4($author$project$Quotation$Common$View$ExpandableField$titleRowConditionalAttributes, msg, isExpanded, isDisabled, isValid)),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('quotation__expandable-field__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeS,
						$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$pen)))
				]));
	});
var $author$project$Quotation$Common$View$ExpandableField$expandableFieldView = F3(
	function (msg, _v0, content) {
		var id = _v0.nM;
		var isExpanded = _v0.n2;
		var isDisabled = _v0.aB;
		var isValid = _v0.jY;
		var title = _v0.eE;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field'),
					$elm$html$Html$Attributes$id(id)
				]),
			_List_fromArray(
				[
					A5($author$project$Quotation$Common$View$ExpandableField$titleRowView, msg, isExpanded, isDisabled, isValid, title),
					A2($author$project$Quotation$Common$View$ExpandableField$contentWrapperView, isExpanded, content)
				]));
	});
var $author$project$Quotation$Common$View$ExpandableField$labelSuffix = function (id) {
	return id + '-label';
};
var $author$project$Quotation$Common$View$ExpandableField$labelConditionalAttributes = F4(
	function (msg, id, isExpanded, isDisabled) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				true,
				$elm$html$Html$Attributes$class('quotation__expandable-field__label')),
				_Utils_Tuple2(
				isDisabled,
				$elm$html$Html$Attributes$class('quotation__expandable-field__label--disabled')),
				_Utils_Tuple2(
				true,
				$elm$html$Html$Attributes$id(
					$author$project$Quotation$Common$View$ExpandableField$labelSuffix(id))),
				_Utils_Tuple2(
				true,
				A2(
					$elm$html$Html$Attributes$attribute,
					'aria-controls',
					$author$project$Quotation$Common$View$ExpandableField$labelSuffix(id))),
				_Utils_Tuple2(
				true,
				A2(
					$elm$html$Html$Attributes$attribute,
					'aria-expanded',
					$author$project$Pyxis$Commons$String$fromBool(isExpanded))),
				_Utils_Tuple2(
				!isDisabled,
				$elm$html$Html$Events$onClick(msg))
			]);
	});
var $author$project$Quotation$Common$View$ExpandableField$labelView = F2(
	function (msg, _v0) {
		var id = _v0.nM;
		var isExpanded = _v0.n2;
		var isDisabled = _v0.aB;
		var label = _v0.n6;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					$author$project$Quotation$Common$View$ExpandableField$filterAttributesTuples(
						A4($author$project$Quotation$Common$View$ExpandableField$labelConditionalAttributes, msg, id, isExpanded, isDisabled)),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-l-book')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								]))
						]))
				]));
	});
var $author$project$Quotation$Common$View$ExpandableField$separatorView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('quotation__expandable-field__separator')
		]),
	_List_Nil);
var $author$project$Quotation$Common$View$ExpandableField$view = F3(
	function (expandableField, toggleMsg, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-wrapper')
				]),
			_List_fromArray(
				[
					A2($author$project$Quotation$Common$View$ExpandableField$labelView, toggleMsg, expandableField),
					A3($author$project$Quotation$Common$View$ExpandableField$expandableFieldView, toggleMsg, expandableField, content),
					$author$project$Quotation$Common$View$ExpandableField$separatorView
				]));
	});
var $author$project$Quotation$Common$View$FormRows$familyMembersExpandableRow = F5(
	function (stepState, toToggleMsg, adultsRowView, childrenRowView, optionalFieldInfo) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$familyMembersExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
						]),
					_List_fromArray(
						[
							adultsRowView,
							childrenRowView,
							$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo)
						]))));
	});
var $author$project$Quotation$Common$Fields$View$familyMembersId = 'family-members';
var $author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalLinkText = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 9:
			return 'Perché ci serve saperlo';
		case 10:
			return 'Perché ci serve saperlo';
		default:
			return '';
	}
};
var $author$project$Quotation$Family$FamilyInfoStep$View$familyMembersInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalLinkText(10),
		nM: $author$project$Quotation$Common$Fields$View$familyMembersId,
		gc: $author$project$Quotation$Family$FamilyInfoStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Family$FamilyInfoStep$Model$OpenFieldInfoModal(10))
	});
var $author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToStringMapper = function (reportedClaimsType) {
	switch (reportedClaimsType) {
		case 0:
			return 'Nessuno';
		case 1:
			return 'Uno';
		default:
			return 'Due o più';
	}
};
var $author$project$Quotation$Common$View$FormRows$reportedClaimsTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Seleziona numero sinistri',
		A2(
			$elm$core$Result$map,
			$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToStringMapper,
			$author$project$Quotation$Common$StepModel$validatedReportedClaimsField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$reportedClaimsExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isReportedClaimsFieldValid(stepState),
		n6: 'Quanti sinistri hai denunciato negli ultimi tre anni?',
		eE: $author$project$Quotation$Common$View$FormRows$reportedClaimsTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$ReportedClaimsFieldChanged = function (a) {
	return {$: 12, a: a};
};
var $author$project$Quotation$Common$Fields$View$reportedClaims = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ReportedClaimsFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToStringMapper(0)),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToStringMapper(1)),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$reportedClaimsTypeToStringMapper(2)),
									eE: $elm$core$Maybe$Nothing,
									p8: 2
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
								$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
								$author$project$Pyxis$Components$Field$RadioGroup$config(id))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$reportedClaimsId = 'reported-claims';
var $author$project$Quotation$Common$View$FormRows$reportedClaimsRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$reportedClaims,
			$author$project$Quotation$Common$Fields$View$reportedClaimsId,
			$author$project$Quotation$Common$Fields$Model$reportedClaimsFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickReportedClaimsField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$reportedClaimsExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$reportedClaimsExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$reportedClaimsRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$ReportedClaimsInfoModal = 9;
var $author$project$Quotation$Family$FamilyInfoStep$View$reportedClaimsInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalLinkText(9),
		nM: $author$project$Quotation$Common$Fields$View$reportedClaimsId,
		gc: $author$project$Quotation$Family$FamilyInfoStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Family$FamilyInfoStep$Model$OpenFieldInfoModal(9))
	});
var $author$project$Quotation$Family$FamilyInfoStep$View$toToggleMsg = F2(
	function (expandedFieldsIds, _v0) {
		var id = _v0.nM;
		var isExpanded = _v0.n2;
		return $author$project$Quotation$Family$FamilyInfoStep$Model$ExpandableFieldAnimation(
			A3($author$project$Quotation$Common$View$ExpandableField$toStateUpdateList, id, !isExpanded, expandedFieldsIds));
	});
var $author$project$Quotation$Family$FamilyInfoStep$View$fieldsetContent = function (stepState) {
	var childrenRow = A2(
		$author$project$Quotation$Common$View$FormRows$childrenRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Family$FamilyInfoStep$Model$FieldChanged,
			A2($author$project$Quotation$Family$FamilyInfoStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId)));
	var adultsRow = A2(
		$author$project$Quotation$Common$View$FormRows$adultsRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Family$FamilyInfoStep$Model$FieldChanged,
			A2($author$project$Quotation$Family$FamilyInfoStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId)));
	return _List_fromArray(
		[
			A5(
			$author$project$Quotation$Common$View$FormRows$familyMembersExpandableRow,
			stepState,
			$author$project$Quotation$Family$FamilyInfoStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			adultsRow,
			childrenRow,
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Family$FamilyInfoStep$View$familyMembersInfoLink)),
			A5(
			$author$project$Quotation$Common$View$FormRows$reportedClaimsExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Family$FamilyInfoStep$Model$FieldChanged,
				A2($author$project$Quotation$Family$FamilyInfoStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId)),
			$author$project$Quotation$Family$FamilyInfoStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Family$FamilyInfoStep$View$reportedClaimsInfoLink),
			$author$project$Quotation$Common$Fields$View$WithoutInfoMessage)
		]);
};
var $author$project$Types$pickIsPrivacyDisclaimerVisible = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.iY;
	},
	function ($) {
		return $.fM;
	});
var $author$project$Quotation$Family$FamilyInfoStep$Model$Proceed = {$: 11};
var $author$project$Quotation$Family$FamilyInfoStep$View$proceedButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('button-row justify-content-end')
		]),
	_List_fromArray(
		[
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withText,
				'Procedi',
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					$author$project$Quotation$Family$FamilyInfoStep$Model$Proceed,
					A2(
						$author$project$Pyxis$Components$Button$withIconAppend,
						$author$project$Pyxis$Components$IconSet$arrowRight,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeXL,
								A2(
									$author$project$Pyxis$Components$Button$withTestId,
									'proceed-btn',
									A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary))))))))
		]));
var $author$project$Quotation$Common$PrivacyData$privacyDisclaimer = function (toggleMsg) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book c-neutral-25')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('I dati sono trattati in conformità con quanto stabilito dal Regolamento U.E. 2016/679 (GDPR)' + ', come indicato nell\''),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('link'),
						$elm$html$Html$Events$onClick(toggleMsg),
						A2($elm$html$Html$Attributes$attribute, 'data-test-id', 'privacy-policy-modal-link')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Informativa Privacy')
					])),
				$elm$html$Html$text('.')
			]));
};
var $author$project$Quotation$Common$View$FieldsetFooterWithDisclaimer$contentView = function (_v0) {
	var isMobile = _v0._;
	var toggleMsg = _v0.p0;
	var proceedButton = _v0.o$;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-column')
			]),
		_List_fromArray(
			[
				proceedButton,
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(
						A3($author$project$Utils$ternaryOperator, isMobile, 'margin-t-xl', 'margin-t-2xl'))
					]),
				_List_fromArray(
					[
						$author$project$Quotation$Common$PrivacyData$privacyDisclaimer(toggleMsg)
					]))
			]));
};
var $author$project$Quotation$Common$View$FieldsetFooterWithDisclaimer$view = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$View$FieldsetFooterWithDisclaimer$contentView,
	A2($elm$core$Basics$composeR, $author$project$Quotation$Common$View$FormRows$toSimpleColRowS, $elm$core$List$singleton));
var $author$project$Quotation$Family$FamilyInfoStep$View$fieldsetFooter = function (stepState) {
	return $author$project$Types$pickIsPrivacyDisclaimerVisible(
		$author$project$Quotation$Common$StepModel$pickFlags(stepState)) ? $author$project$Quotation$Common$View$FieldsetFooterWithDisclaimer$view(
		{
			_: $author$project$Types$pickIsMobile(
				$author$project$Quotation$Common$StepModel$pickFlags(stepState)),
			o$: $author$project$Quotation$Family$FamilyInfoStep$View$proceedButton,
			p0: $author$project$Quotation$Family$FamilyInfoStep$Model$TogglePrivacyPolicy(true)
		}) : _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Family$FamilyInfoStep$View$proceedButton)
		]);
};
var $author$project$Quotation$Family$FamilyInfoStep$Model$DismissDiscountMessage = {$: 3};
var $author$project$Quotation$Family$FamilyInfoStep$View$header = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Per cominciare, inserisci alcune informazioni sulle persone che fanno parte della tua famiglia',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Informazioni sulla tua famiglia', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Quotation$Common$View$FormRows$RowSizeM = 1;
var $author$project$Quotation$Common$View$FormRows$toSimpleColRowM = $author$project$Quotation$Common$View$FormRows$toSimpleColRow(1);
var $author$project$Pyxis$Components$Message$Brand = 1;
var $author$project$Pyxis$Components$Message$brand = function (style) {
	return A2($author$project$Pyxis$Components$Message$config, 1, style);
};
var $author$project$Pyxis$Components$Message$ColoredBackground = 1;
var $author$project$Pyxis$Components$Message$coloredBackground = 1;
var $author$project$Pyxis$Components$IconSet$Gift = 58;
var $author$project$Pyxis$Components$IconSet$gift = 58;
var $author$project$Quotation$Common$View$DiscountMessage$messageContentView = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('Hai già acquistato online una polizza veicoli con Prima? '),
			A2(
			$elm$html$Html$strong,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Per te uno sconto del 10%.')
				]))
		]));
var $author$project$Pyxis$Components$Message$withIcon = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{dE: icon});
	});
var $author$project$Pyxis$Components$Message$withOnDismiss = F3(
	function (onDismiss, ariaLabel, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				aW: $elm$core$Maybe$Just(
					{eV: ariaLabel, aW: onDismiss})
			});
	});
var $author$project$Quotation$Common$View$DiscountMessage$view = function (msg) {
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[$author$project$Quotation$Common$View$DiscountMessage$messageContentView]),
			A3(
				$author$project$Pyxis$Components$Message$withOnDismiss,
				msg,
				'Chiudi il messaggio di sconto',
				A2(
					$author$project$Pyxis$Components$Message$withIcon,
					$author$project$Pyxis$Components$IconSet$gift,
					$author$project$Pyxis$Components$Message$brand($author$project$Pyxis$Components$Message$coloredBackground)))));
};
var $author$project$Quotation$Family$FamilyInfoStep$View$fieldsetHeader = function (stepState) {
	return $author$project$Quotation$Common$StepModel$pickIsDiscountMessageVisible(stepState) ? _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowM(
			$author$project$Quotation$Common$View$DiscountMessage$view($author$project$Quotation$Family$FamilyInfoStep$Model$DismissDiscountMessage)),
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Family$FamilyInfoStep$View$header)
		]) : _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Family$FamilyInfoStep$View$header)
		]);
};
var $author$project$Quotation$Family$FamilyInfoStep$View$fieldsetConfig = function (stepState) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		$author$project$Quotation$Family$FamilyInfoStep$View$fieldsetFooter(stepState),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Quotation$Family$FamilyInfoStep$View$fieldsetContent(stepState),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				$author$project$Quotation$Family$FamilyInfoStep$View$fieldsetHeader(stepState),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Quotation$Family$FamilyInfoStep$View$reportedClaimsInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'reported-claims-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Family$FamilyInfoStep$Model$OpenFieldInfoModal(9)),
		$author$project$Quotation$Family$FamilyInfoStep$Model$FieldInfoModalUpdated($author$project$Quotation$Family$FamilyInfoStep$Model$ClosedModal),
		$author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalTitle(9),
		$author$project$Quotation$Family$FamilyInfoStep$View$getOpenFieldInfoModalMessage(9));
};
var $author$project$Quotation$Family$FamilyInfoStep$View$view = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-responsive')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Fieldset$render(
				$author$project$Quotation$Family$FamilyInfoStep$View$fieldsetConfig(stepState)),
				$author$project$Quotation$Family$FamilyInfoStep$View$familyMembersInfoModal(stepState),
				$author$project$Quotation$Family$FamilyInfoStep$View$reportedClaimsInfoModal(stepState),
				A3(
				$author$project$Quotation$Common$View$Modals$PrivacyPolicy$view,
				_Utils_eq(
					$author$project$Quotation$Common$StepModel$pickModalState(stepState),
					$author$project$Quotation$Family$FamilyInfoStep$Model$OpenPrivacyPolicyModal),
				$author$project$Quotation$Common$StepModel$pickFlags(stepState),
				$author$project$Quotation$Family$FamilyInfoStep$Model$TogglePrivacyPolicy(false))
			]));
};
var $author$project$Quotation$Family$View$stepFieldsView = function (_v0) {
	var stepState = _v0.cH;
	if (!stepState.$) {
		var state = stepState.a;
		return A2(
			$elm$html$Html$map,
			$author$project$Quotation$Family$Model$FamilyInfoStepMsg,
			$author$project$Quotation$Family$FamilyInfoStep$View$view(state));
	} else {
		var state = stepState.a;
		return A2(
			$elm$html$Html$map,
			$author$project$Quotation$Family$Model$CustomerStepMsg,
			$author$project$Quotation$Family$CustomerStep$View$view(state));
	}
};
var $author$project$Quotation$Family$View$modelInitializedView = function (modelState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quotation__main')
			]),
		_List_fromArray(
			[
				$author$project$Quotation$Family$View$stepFieldsView(modelState)
			]));
};
var $author$project$Quotation$Family$View$view = function (model) {
	switch (model.$) {
		case 2:
			var modelInitializedState = model.a;
			return $author$project$Quotation$Family$View$modelInitializedView(modelInitializedState);
		case 1:
			return $elm$html$Html$text('');
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 6:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché il prezzo della polizza' + ' potrebbe variare a seconda di dove si trova la tua casa.']);
		case 7:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché potrebbe influire sul' + ' valore dell’immobile che stai assicurando.']);
		case 8:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché il prezzo della polizza' + ' potrebbe variare a seconda del tuo indirizzo di residenza.']);
		case 9:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché ci aiuta a valutare' + ' meglio di che copertura hai bisogno.']);
		case 10:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché ci permette di conoscere' + ' il numero di persone per cui la copertura è attiva.']);
		default:
			return _List_Nil;
	}
};
var $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 6:
			return 'Perché ci serve sapere qual è l’indirizzo della tua casa';
		case 7:
			return 'Perché ci serve sapere a che piano si trova la tua casa';
		case 8:
			return 'Perché ci serve sapere qual è il tuo indirizzo di residenza';
		case 9:
			return 'Perché chiediamo se hai già denunciato altri sinistri';
		case 10:
			return 'Perché chiediamo quante persone vivono nella tua casa';
		default:
			return '';
	}
};
var $author$project$Quotation$Home$AddressStep$View$familyMembersInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'family-members-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(10)),
		$author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$AddressStep$Model$ClosedModal),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle(10),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage(10));
};
var $author$project$Quotation$Home$AddressStep$Model$GoBack = {$: 15};
var $author$project$Quotation$Home$AddressStep$Model$Proceed = {$: 13};
var $author$project$Quotation$Home$AddressStep$View$buttons = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('button-row')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withText,
						'Indietro',
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							$author$project$Quotation$Home$AddressStep$Model$GoBack,
							A2(
								$author$project$Pyxis$Components$Button$withIconPrepend,
								$author$project$Pyxis$Components$IconSet$chevronLeft,
								A2(
									$author$project$Pyxis$Components$Button$withType,
									$author$project$Pyxis$Components$Button$button,
									A2(
										$author$project$Pyxis$Components$Button$withTestId,
										'go-back-btn',
										A2($author$project$Pyxis$Components$Button$withId, 'go-back-btn', $author$project$Pyxis$Components$Button$ghost))))))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withText,
						'Procedi',
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							$author$project$Quotation$Home$AddressStep$Model$Proceed,
							A2(
								$author$project$Pyxis$Components$Button$withIconAppend,
								$author$project$Pyxis$Components$IconSet$arrowRight,
								A2(
									$author$project$Pyxis$Components$Button$withType,
									$author$project$Pyxis$Components$Button$button,
									A2(
										$author$project$Pyxis$Components$Button$withSize,
										$author$project$Pyxis$Components$Button$sizeXL,
										A2(
											$author$project$Pyxis$Components$Button$withTestId,
											'proceed-btn',
											A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary))))))))
				]))
		]));
var $author$project$Quotation$Home$AddressStep$Model$ToggleManualPropertyAddress = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Quotation$Home$AddressStep$Model$ToggleManualResidentialAddress = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Quotation$Home$AddressStep$Model$isFieldEditable = F2(
	function (model, expandableFieldId) {
		return A2(
			$elm$core$Set$member,
			expandableFieldId,
			$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model)) && _Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(model),
			$author$project$Quotation$Home$AddressStep$Model$ClosedModal);
	});
var $author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg = F3(
	function (model, expandableFieldId, msg) {
		return A2($author$project$Quotation$Home$AddressStep$Model$isFieldEditable, model, expandableFieldId) ? msg : $author$project$Quotation$Home$AddressStep$Model$NoOp;
	});
var $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 6:
			return 'Perché ci serve saperlo';
		case 7:
			return 'Perché ci serve saperlo';
		case 8:
			return 'Perché ci serve saperlo';
		case 9:
			return 'Perché ci serve saperlo';
		case 10:
			return 'Perché ci serve saperlo';
		default:
			return '';
	}
};
var $author$project$Quotation$Home$AddressStep$View$familyMembersInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText(10),
		nM: $author$project$Quotation$Common$Fields$View$familyMembersId,
		gc: $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(10))
	});
var $author$project$Quotation$Common$StepModel$pickIsPropertyAddressManual = function (_v0) {
	var isPropertyAddressManual = _v0.jS;
	return isPropertyAddressManual;
};
var $author$project$Quotation$Common$View$FormRows$propertyAddressTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Inserisci via, numero civico e comune',
		A2(
			$elm$core$Result$map,
			function ($) {
				return $.jf;
			},
			$author$project$Quotation$Common$StepModel$validatedPropertyAddressField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$propertyAddressExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isPropertyAddressFieldValid(stepState),
		n6: 'Qual è l\'indirizzo della tua casa?',
		eE: $author$project$Quotation$Common$View$FormRows$propertyAddressTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$View$manualPropertyAddressBtnId = 'manual-property-address-btn';
var $author$project$Quotation$Common$Fields$View$propertyAddress = F8(
	function (id, validation, isFormSubmitted, msgMapper, manualBtnMsg, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Components$DebouncedAutocomplete$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyAddressFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withNoResultFoundAction,
						$author$project$Pyxis$Components$Button$render(
							A2(
								$author$project$Pyxis$Components$Button$withOnMouseDown,
								manualBtnMsg,
								A2(
									$author$project$Pyxis$Components$Button$withType,
									$author$project$Pyxis$Components$Button$button,
									A2(
										$author$project$Pyxis$Components$Button$withText,
										'Inserisci manualmente',
										A2(
											$author$project$Pyxis$Components$Button$withTestId,
											$author$project$Quotation$Common$Fields$View$manualPropertyAddressBtnId,
											A2($author$project$Pyxis$Components$Button$withId, $author$project$Quotation$Common$Fields$View$manualPropertyAddressBtnId, $author$project$Pyxis$Components$Button$ghost)))))),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$withNoResultsFoundMessage,
							$author$project$Components$DebouncedAutocomplete$noResultsFoundMessage,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
								'Inserisci via, numero civico e comune',
								A3(
									$author$project$Pyxis$Components$Field$Autocomplete$withValidationOnSubmit,
									validation,
									isFormSubmitted,
									A2(
										$author$project$Pyxis$Components$Field$Autocomplete$withTestId,
										$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
										$author$project$Pyxis$Components$Field$Autocomplete$config(id))))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$propertyAddressId = 'property-address';
var $author$project$Quotation$Common$View$FormRows$propertyAddressRow = F5(
	function (stepState, fieldChangedMsgMapper, manualBtnMsg, optionalFieldInfo, optionalMessage) {
		return A8(
			$author$project$Quotation$Common$Fields$View$propertyAddress,
			$author$project$Quotation$Common$Fields$View$propertyAddressId,
			$author$project$Quotation$Common$Fields$Model$propertyAddressFieldValidation,
			($author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) || $elm_community$maybe_extra$Maybe$Extra$isJust(
				$author$project$Components$DebouncedAutocomplete$getValue(
					$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(
						$author$project$Quotation$Common$StepModel$pickFormData(stepState))))) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			manualBtnMsg,
			$author$project$Quotation$Common$Fields$Model$pickPropertyAddressField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$propertyAddressExpandableRow = F6(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, manualBtnMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$propertyAddressExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A5($author$project$Quotation$Common$View$FormRows$propertyAddressRow, stepState, fieldChangedMsgMapper, manualBtnMsg, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$PropertyAddressInfoModal = 6;
var $author$project$Quotation$Home$AddressStep$View$propertyAddressInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText(6),
		nM: $author$project$Quotation$Common$Fields$View$propertyAddressId,
		gc: $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(6))
	});
var $author$project$Quotation$Home$AddressStep$View$propertyAddressInfoMessage = function (stepState) {
	var onClickMsg = A3(
		$author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg,
		stepState,
		$author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
		A2(
			$author$project$Quotation$Home$AddressStep$Model$ToggleManualPropertyAddress,
			1,
			!$author$project$Quotation$Common$StepModel$pickIsPropertyAddressManual(stepState)));
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se la ricerca automatica non trova l’indirizzo esatto della tua casa, puoi inserirlo manualmente.')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-s-bold link link--no-underline'),
						$elm$html$Html$Events$onClick(onClickMsg),
						$author$project$Pyxis$Commons$Attributes$ariaLabel('manual-property-address')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Inserisci manualmente')
					]))
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			$author$project$Pyxis$Components$Message$neutral));
};
var $author$project$Quotation$Common$Fields$Model$propertyFloorTypeToStringMapper = function (propertyFloorType) {
	switch (propertyFloorType) {
		case 0:
			return 'Piano terra o rialzato';
		case 1:
			return 'Ultimo piano';
		case 2:
			return 'Tutti gli altri piani';
		default:
			return 'Non richiesto';
	}
};
var $author$project$Quotation$Common$View$FormRows$propertyFloorTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Seleziona piano',
		A2(
			$elm$core$Result$map,
			$author$project$Quotation$Common$Fields$Model$propertyFloorTypeToStringMapper,
			A2($author$project$Quotation$Common$StepModel$validatedPropertyFloorField, true, stepData)));
};
var $author$project$Quotation$Common$View$FormRows$propertyFloorExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId, stepState),
		jY: A2($author$project$Quotation$Common$StepModel$isPropertyFloorFieldValid, true, stepState),
		n6: 'A che piano si trova?',
		eE: $author$project$Quotation$Common$View$FormRows$propertyFloorTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$PropertyFloorFieldChanged = function (a) {
	return {$: 9, a: a};
};
var $author$project$Quotation$Common$Fields$View$propertyFloor = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyFloorFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyFloorTypeToStringMapper(0)),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyFloorTypeToStringMapper(1)),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyFloorTypeToStringMapper(2)),
									eE: $elm$core$Maybe$Nothing,
									p8: 2
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
								$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
								$author$project$Pyxis$Components$Field$RadioGroup$config(id))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$propertyFloorId = 'property-floor';
var $author$project$Quotation$Common$View$FormRows$propertyFloorRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$propertyFloor,
			$author$project$Quotation$Common$Fields$View$propertyFloorId,
			$author$project$Quotation$Common$Fields$Model$propertyFloorFieldValidation(true),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPropertyFloorField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$propertyFloorExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$propertyFloorExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$propertyFloorRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$PropertyFloorInfoModal = 7;
var $author$project$Quotation$Home$AddressStep$View$propertyFloorInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText(7),
		nM: $author$project$Quotation$Common$Fields$View$propertyFloorId,
		gc: $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(7))
	});
var $author$project$Quotation$Home$AddressStep$View$reportedClaimsInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText(9),
		nM: $author$project$Quotation$Common$Fields$View$reportedClaimsId,
		gc: $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(9))
	});
var $author$project$Quotation$Common$Fields$View$WithoutFieldLabel = {$: 1};
var $author$project$Quotation$Common$View$FormRows$residentialAddressTitle = function (stepData) {
	var _v0 = A2($author$project$Quotation$Common$StepModel$validatedOptionalResidentialAddressField, true, stepData);
	if (!_v0.$) {
		if (!_v0.a.$) {
			var place = _v0.a.a;
			return place.jf;
		} else {
			var _v1 = _v0.a;
			return '';
		}
	} else {
		return 'Inserisci via, numero civico e comune';
	}
};
var $author$project$Quotation$Common$View$FormRows$residentialAddressExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId, stepState),
		jY: A2($author$project$Quotation$Common$StepModel$isResidentialAddressFieldValid, true, stepState),
		n6: 'Qual è il tuo indirizzo di residenza?',
		eE: $author$project$Quotation$Common$View$FormRows$residentialAddressTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$View$FormRows$residentialAddressExpandableRow = F7(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, isValidationVisible, manualBtnMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$residentialAddressExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A7($author$project$Quotation$Common$View$FormRows$residentialAddressRow, stepState, fieldChangedMsgMapper, manualBtnMsg, isValidationVisible, $author$project$Quotation$Common$Fields$View$WithoutFieldLabel, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Home$AddressStep$View$residentialAddressInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalLinkText(8),
		nM: $author$project$Quotation$Common$Fields$View$residentialAddressId,
		gc: $author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(8))
	});
var $author$project$Quotation$Home$AddressStep$View$residentialAddressInfoMessage = function (stepState) {
	var onClickMsg = A3(
		$author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg,
		stepState,
		$author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
		A2(
			$author$project$Quotation$Home$AddressStep$Model$ToggleManualResidentialAddress,
			1,
			!$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState)));
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Se la ricerca automatica non trova l’indirizzo esatto della tua residenza, puoi inserirlo manualmente.')
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-s-bold link link--no-underline'),
						$elm$html$Html$Events$onClick(onClickMsg),
						$author$project$Pyxis$Commons$Attributes$ariaLabel('manual-residential-address')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Inserisci manualmente')
					]))
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			$author$project$Pyxis$Components$Message$neutral));
};
var $author$project$Quotation$Home$AddressStep$View$toToggleMsg = F2(
	function (expandedFieldsIds, _v0) {
		var id = _v0.nM;
		var isExpanded = _v0.n2;
		return $author$project$Quotation$Home$AddressStep$Model$ExpandableFieldAnimation(
			A3($author$project$Quotation$Common$View$ExpandableField$toStateUpdateList, id, !isExpanded, expandedFieldsIds));
	});
var $author$project$Quotation$Home$AddressStep$View$fieldsetContent = function (stepState) {
	var conditionalResidentialAddressRow = $author$project$Quotation$Home$AddressStep$Model$isResidentialAddressRequired(
		$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState)) ? $elm$core$Maybe$Just(
		A7(
			$author$project$Quotation$Common$View$FormRows$residentialAddressExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId)),
			$author$project$Quotation$Home$AddressStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			($author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) || $elm_community$maybe_extra$Maybe$Extra$isJust(
				$author$project$Components$DebouncedAutocomplete$getValue(
					$author$project$Quotation$Common$Fields$Model$pickResidentialAddressField(
						$author$project$Quotation$Common$StepModel$pickFormData(stepState))))) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			A3(
				$author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg,
				stepState,
				$author$project$Quotation$Common$Fields$Model$residentialAddressExpandableFieldId,
				A2(
					$author$project$Quotation$Home$AddressStep$Model$ToggleManualResidentialAddress,
					0,
					!$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState))),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$AddressStep$View$residentialAddressInfoLink),
			$author$project$Quotation$Common$Fields$View$InfoMessage(
				$author$project$Quotation$Home$AddressStep$View$residentialAddressInfoMessage(stepState)))) : $elm$core$Maybe$Nothing;
	var conditionalPropertyFloorRow = $author$project$Quotation$Home$AddressStep$Model$isPropertyFloorRequired(
		$author$project$Quotation$Common$StepModel$pickPropertyStepResult(stepState)) ? $elm$core$Maybe$Just(
		A5(
			$author$project$Quotation$Common$View$FormRows$propertyFloorExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyFloorExpandableFieldId)),
			$author$project$Quotation$Home$AddressStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$AddressStep$View$propertyFloorInfoLink),
			$author$project$Quotation$Common$Fields$View$WithoutInfoMessage)) : $elm$core$Maybe$Nothing;
	var childrenRow = A2(
		$author$project$Quotation$Common$View$FormRows$childrenRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
			A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId)));
	var adultsRow = A2(
		$author$project$Quotation$Common$View$FormRows$adultsRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
			A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$familyMembersExpandableFieldId)));
	var contentList = _List_fromArray(
		[
			$elm$core$Maybe$Just(
			A6(
				$author$project$Quotation$Common$View$FormRows$propertyAddressExpandableRow,
				stepState,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
					A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId)),
				$author$project$Quotation$Home$AddressStep$View$toToggleMsg(
					$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
				A3(
					$author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg,
					stepState,
					$author$project$Quotation$Common$Fields$Model$propertyAddressExpandableFieldId,
					A2(
						$author$project$Quotation$Home$AddressStep$Model$ToggleManualPropertyAddress,
						0,
						!$author$project$Quotation$Common$StepModel$pickIsPropertyAddressManual(stepState))),
				$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$AddressStep$View$propertyAddressInfoLink),
				$author$project$Quotation$Common$Fields$View$InfoMessage(
					$author$project$Quotation$Home$AddressStep$View$propertyAddressInfoMessage(stepState)))),
			conditionalPropertyFloorRow,
			conditionalResidentialAddressRow,
			$elm$core$Maybe$Just(
			A5(
				$author$project$Quotation$Common$View$FormRows$reportedClaimsExpandableRow,
				stepState,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Quotation$Home$AddressStep$Model$FieldChanged,
					A2($author$project$Quotation$Home$AddressStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$reportedClaimsExpandableFieldId)),
				$author$project$Quotation$Home$AddressStep$View$toToggleMsg(
					$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
				$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$AddressStep$View$reportedClaimsInfoLink),
				$author$project$Quotation$Common$Fields$View$WithoutInfoMessage)),
			$elm$core$Maybe$Just(
			A5(
				$author$project$Quotation$Common$View$FormRows$familyMembersExpandableRow,
				stepState,
				$author$project$Quotation$Home$AddressStep$View$toToggleMsg(
					$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
				adultsRow,
				childrenRow,
				$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$AddressStep$View$familyMembersInfoLink)))
		]);
	return A2($elm$core$List$filterMap, $elm$core$Basics$identity, contentList);
};
var $author$project$Quotation$Home$AddressStep$View$header = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Con l\'indirizzo e altre informazioni sulla tua casa possiamo calcolare un preventivo più accurato',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Indirizzo e altri dettagli', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Quotation$Home$AddressStep$View$fieldsetConfig = function (stepState) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		_List_fromArray(
			[
				$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$AddressStep$View$buttons)
			]),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Quotation$Home$AddressStep$View$fieldsetContent(stepState),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				_List_fromArray(
					[
						$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$AddressStep$View$header)
					]),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Quotation$Home$AddressStep$View$propertyAddressInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'property-address-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(6)),
		$author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$AddressStep$Model$ClosedModal),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle(6),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage(6));
};
var $author$project$Quotation$Home$AddressStep$Model$ClosePropertyAddressFallbackModal = {$: 11};
var $author$project$Quotation$Home$AddressStep$View$propertyFallbackAddressModal = function (stepState) {
	return A6(
		$author$project$Quotation$Common$View$Modals$AddressFallback$view,
		'property-fallback-address-modal',
		'Qual è l\'indirizzo della tua casa?',
		$author$project$Quotation$Common$StepModel$pickIsPropertyAddressManual(stepState),
		A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyFallbackAddressFieldsChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged),
		$author$project$Quotation$Home$AddressStep$Model$ClosePropertyAddressFallbackModal,
		$author$project$Quotation$Common$Fields$Model$pickPropertyAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Home$AddressStep$View$propertyFloorInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'property-floor-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(7)),
		$author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$AddressStep$Model$ClosedModal),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle(7),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage(7));
};
var $author$project$Quotation$Home$AddressStep$View$reportedClaimsInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'reported-claims-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(9)),
		$author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$AddressStep$Model$ClosedModal),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle(9),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage(9));
};
var $author$project$Quotation$Home$AddressStep$View$residentialAddressInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'residential-address-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$AddressStep$Model$OpenFieldInfoModal(8)),
		$author$project$Quotation$Home$AddressStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$AddressStep$Model$ClosedModal),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalTitle(8),
		$author$project$Quotation$Home$AddressStep$View$getOpenFieldInfoModalMessage(8));
};
var $author$project$Quotation$Home$AddressStep$Model$CloseResidentialAddressFallbackModal = {$: 12};
var $author$project$Quotation$Home$AddressStep$View$residentialFallbackAddressModal = function (stepState) {
	return A6(
		$author$project$Quotation$Common$View$Modals$AddressFallback$view,
		'residential-fallback-address-modal',
		'Qual è il tuo indirizzo di residenza?',
		$author$project$Quotation$Common$StepModel$pickIsResidentialAddressManual(stepState),
		A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ResidentialFallbackAddressFieldsChanged, $author$project$Quotation$Home$AddressStep$Model$FieldChanged),
		$author$project$Quotation$Home$AddressStep$Model$CloseResidentialAddressFallbackModal,
		$author$project$Quotation$Common$Fields$Model$pickResidentialAddressFallbackField(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)));
};
var $author$project$Quotation$Home$AddressStep$View$view = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-responsive')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Fieldset$render(
				$author$project$Quotation$Home$AddressStep$View$fieldsetConfig(stepState)),
				$author$project$Quotation$Home$AddressStep$View$propertyFallbackAddressModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$residentialFallbackAddressModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$propertyAddressInfoModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$propertyFloorInfoModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$residentialAddressInfoModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$reportedClaimsInfoModal(stepState),
				$author$project$Quotation$Home$AddressStep$View$familyMembersInfoModal(stepState)
			]));
};
var $author$project$Quotation$Home$CustomerStep$Model$TogglePrivacyPolicy = function (a) {
	return {$: 7, a: a};
};
var $author$project$Quotation$Home$CustomerStep$Model$GoBack = {$: 10};
var $author$project$Quotation$Home$CustomerStep$Model$Proceed = {$: 9};
var $author$project$Quotation$Home$CustomerStep$View$buttonsRow = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button-row')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withText,
							'Indietro',
							A2(
								$author$project$Pyxis$Components$Button$withDisabled,
								$author$project$Quotation$Common$StepModel$isSubmittingFormData(stepState),
								A2(
									$author$project$Pyxis$Components$Button$withOnClick,
									$author$project$Quotation$Home$CustomerStep$Model$GoBack,
									A2(
										$author$project$Pyxis$Components$Button$withIconPrepend,
										$author$project$Pyxis$Components$IconSet$chevronLeft,
										A2(
											$author$project$Pyxis$Components$Button$withType,
											$author$project$Pyxis$Components$Button$button,
											A2(
												$author$project$Pyxis$Components$Button$withTestId,
												'go-back-btn',
												A2($author$project$Pyxis$Components$Button$withId, 'go-back-btn', $author$project$Pyxis$Components$Button$ghost)))))))),
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withText,
							'Mostra prezzo',
							A2(
								$author$project$Pyxis$Components$Button$withOnClick,
								$author$project$Quotation$Home$CustomerStep$Model$Proceed,
								A2(
									$author$project$Pyxis$Components$Button$withLoading,
									$author$project$Quotation$Common$StepModel$isSubmittingFormData(stepState),
									A2(
										$author$project$Pyxis$Components$Button$withIconAppend,
										$author$project$Pyxis$Components$IconSet$arrowRight,
										A2(
											$author$project$Pyxis$Components$Button$withType,
											$author$project$Pyxis$Components$Button$button,
											A2(
												$author$project$Pyxis$Components$Button$withSize,
												$author$project$Pyxis$Components$Button$sizeXL,
												A2(
													$author$project$Pyxis$Components$Button$withTestId,
													'proceed-btn',
													A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary)))))))))
					]))
			]));
};
var $author$project$Quotation$Home$CustomerStep$View$fieldsetContent = function (stepState) {
	var privacyPolicyRow = A3(
		$author$project$Quotation$Common$View$FormRows$privacyPolicyRow,
		stepState,
		$author$project$Quotation$Home$CustomerStep$Model$FieldChanged,
		$author$project$Quotation$Home$CustomerStep$Model$TogglePrivacyPolicy(true));
	var emailRowList = $author$project$Quotation$Common$StepModel$isAgent(stepState) ? _List_Nil : _List_fromArray(
		[
			A2($author$project$Quotation$Common$View$FormRows$emailSimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged)
		]);
	var countryRowList = $author$project$Quotation$Common$Fields$Model$checkIsForeignCity(
		$author$project$Quotation$Common$StepModel$pickFormData(stepState)) ? _List_fromArray(
		[
			A2($author$project$Quotation$Common$View$FormRows$birthCountrySimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged)
		]) : _List_Nil;
	var commercialPurposesRow = A2($author$project$Quotation$Common$View$FormRows$commercialPurposesRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged);
	return _Utils_ap(
		_List_fromArray(
			[
				A2($author$project$Quotation$Common$View$FormRows$nameSimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$surnameSimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$genderSimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$birthdaySimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
				A2($author$project$Quotation$Common$View$FormRows$birthCitySimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged)
			]),
		_Utils_ap(
			countryRowList,
			_Utils_ap(
				emailRowList,
				_List_fromArray(
					[
						A2($author$project$Quotation$Common$View$FormRows$phoneSimpleRow, stepState, $author$project$Quotation$Home$CustomerStep$Model$FieldChanged),
						$author$project$Quotation$Common$View$FormRows$contactMessageRow,
						A2($author$project$Quotation$Common$View$FormRows$privacySimpleRow, privacyPolicyRow, commercialPurposesRow)
					]))));
};
var $author$project$Quotation$Home$CustomerStep$View$header = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Con dati reali e corretti puoi visualizzare il tuo preventivo e gestire l\'offerta per te',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Dati su di te e contatti', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Quotation$Home$CustomerStep$View$fieldsetConfig = function (stepState) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		_List_fromArray(
			[
				$author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
				$author$project$Quotation$Home$CustomerStep$View$buttonsRow(stepState))
			]),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Quotation$Home$CustomerStep$View$fieldsetContent(stepState),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				_List_fromArray(
					[
						$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$CustomerStep$View$header)
					]),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Quotation$Home$CustomerStep$View$view = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-responsive')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Fieldset$render(
				$author$project$Quotation$Home$CustomerStep$View$fieldsetConfig(stepState)),
				A3(
				$author$project$Quotation$Common$View$Modals$PrivacyPolicy$view,
				!$author$project$Quotation$Common$StepModel$pickModalState(stepState),
				$author$project$Quotation$Common$StepModel$pickFlags(stepState),
				$author$project$Quotation$Home$CustomerStep$Model$TogglePrivacyPolicy(false))
			]));
};
var $author$project$Quotation$Home$PropertyStep$Model$TogglePrivacyPolicy = function (a) {
	return {$: 8, a: a};
};
var $author$project$Quotation$Common$Fields$Model$BuildingAdministratorInfoModal = 3;
var $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated = function (a) {
	return {$: 10, a: a};
};
var $author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 0:
			return _List_fromArray(
				['Puoi acquistare una polizza Casa e Famiglia solo se la casa che vuoi assicurare è di tua proprietà.']);
		case 1:
			return _List_fromArray(
				['Non è ancora possibile acquistare una polizza Casa e Famiglia per una casa non abitata,' + ' cioè rimasta disabitata per più di 270 giorni consecutivi.', 'È considerata abitata solo la casa in cui vivi per la maggior parte dell’anno (uso abituale),' + ' la seconda casa o la casa per le vacanze (uso saltuario) o una casa data in affitto (uso locato).']);
		case 2:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché il prezzo della polizza potrebbe' + ' variare a seconda della categoria della tua casa e del numero di famiglie da cui è abitata.']);
		case 3:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché le aree comuni di un condominio' + ' con un amministratore sono già coperte da una polizza condominiale.']);
		case 4:
			return _List_fromArray(
				['Questa informazione è utile per il calcolo del preventivo, perché il prezzo della polizza potrebbe' + (' variare a seconda dell’età dell’edificio o dell’ultima ristrutturazione significativa, che' + ' ha riguardato il rifacimento degli impianti idrici e degli impianti elettrici.')]);
		case 5:
			return _List_fromArray(
				['I metri quadri commerciali sono riportati nella visura catastale o nell’atto di compravendita:' + (' includono superfici calpestabili, pareti divisorie interne, muri esterni, superfici scoperte' + (' (come balconi e terrazzi) ed eventuali pertinenze legate all\'abitazione principale' + (' (come box, cantine e solai). Se non è possibile ricavare i metri quadri commerciali,' + ' consigliamo di inserire la metratura dell’immobile riportata per i pagamenti della TARI.')))]);
		default:
			return _List_Nil;
	}
};
var $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 0:
			return 'Puoi assicurare solo case di proprietà';
		case 1:
			return 'Puoi assicurare solo case abitate';
		case 2:
			return 'Perché ci serve sapere qual è la categoria della tua casa';
		case 3:
			return 'Perché ci serve sapere se il condominio ha un amministratore';
		case 4:
			return 'Perché ci serve sapere quando la casa è stata costruita o ristrutturata';
		case 5:
			return 'Dove trovare i metri quadri commerciali della casa';
		default:
			return '';
	}
};
var $author$project$Quotation$Home$PropertyStep$View$buildingAdministratorInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'building-administrator-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(3)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(3),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(3));
};
var $author$project$Quotation$Common$Fields$Model$ConstructionPeriodInfoModal = 4;
var $author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'construction-period-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(4)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(4),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(4));
};
var $author$project$Quotation$Home$PropertyStep$Model$FieldChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Quotation$Common$Fields$View$WithoutInfoLink = {$: 1};
var $author$project$Quotation$Common$Fields$View$buildingAdministratorId = 'building-administrator';
var $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText = function (fieldInfoModal) {
	switch (fieldInfoModal) {
		case 0:
			return 'Se la casa non è di tua proprietà';
		case 1:
			return 'Se la casa non è abitata';
		case 2:
			return 'Perché ci serve saperlo';
		case 3:
			return 'Perché ci serve saperlo';
		case 4:
			return 'Perché ci serve saperlo';
		case 5:
			return 'Dove trovare questo dato';
		default:
			return '';
	}
};
var $author$project$Quotation$Home$PropertyStep$View$buildingAdministratorInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(3),
		nM: $author$project$Quotation$Common$Fields$View$buildingAdministratorId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(3))
	});
var $author$project$Quotation$Common$Fields$Model$BuildingAdministratorFieldChanged = function (a) {
	return {$: 5, a: a};
};
var $author$project$Quotation$Common$Fields$View$buildingAdministrator = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$BuildingAdministratorFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('Sì'),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('No'),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								})
							]),
						A2(
							$author$project$Pyxis$Components$Field$RadioGroup$withLabel,
							A2(
								$author$project$Pyxis$Components$Field$Label$withSize,
								$author$project$Pyxis$Components$Field$Label$sizeS,
								A2($author$project$Pyxis$Components$Field$Label$withText, 'Il condominio ha un amministratore?', $author$project$Pyxis$Components$Field$Label$config)),
							A3(
								$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
								validation,
								isFormSubmitted,
								A2(
									$author$project$Pyxis$Components$Field$RadioGroup$withClassList,
									_List_fromArray(
										[
											_Utils_Tuple2('quotation__fixed-two-column-layout', true)
										]),
									A2(
										$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
										$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
										$author$project$Pyxis$Components$Field$RadioGroup$config(id))))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$View$FormRows$buildingAdministratorRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$buildingAdministrator,
			$author$project$Quotation$Common$Fields$View$buildingAdministratorId,
			$author$project$Quotation$Common$StepModel$buildingAdministratorFieldValidation(stepState),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$Fields$Model$getPropertyTypeValue = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$pickPropertyTypeField, $author$project$Pyxis$Components$Field$RadioGroup$getValue);
var $author$project$Quotation$Common$Fields$Model$isPropertyTypeApartmentSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$getPropertyTypeValue,
	$elm$core$Basics$eq(
		$elm$core$Maybe$Just(1)));
var $author$project$Quotation$Common$View$FormRows$conditionalBuildingAdministratorRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return $author$project$Quotation$Common$Fields$Model$isPropertyTypeApartmentSelected(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)) ? $elm$core$Maybe$Just(
			A4($author$project$Quotation$Common$View$FormRows$buildingAdministratorRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Quotation$Home$PropertyStep$Model$isFieldEditable = F2(
	function (model, expandableFieldId) {
		return A2(
			$elm$core$Set$member,
			expandableFieldId,
			$author$project$Quotation$Common$StepModel$pickExpandedFieldsIds(model)) && _Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(model),
			$author$project$Quotation$Home$PropertyStep$Model$ClosedModal);
	});
var $author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg = F3(
	function (model, expandableFieldId, msg) {
		return A2($author$project$Quotation$Home$PropertyStep$Model$isFieldEditable, model, expandableFieldId) ? msg : $author$project$Quotation$Home$PropertyStep$Model$NoOp;
	});
var $author$project$Quotation$Common$Fields$Model$isPropertyTypeVillaSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Quotation$Common$Fields$Model$getPropertyTypeValue,
	$elm$core$Basics$eq(
		$elm$core$Maybe$Just(0)));
var $author$project$Quotation$Common$Fields$Model$VillaTypeFieldChanged = function (a) {
	return {$: 4, a: a};
};
var $author$project$Quotation$Common$Fields$View$villaType = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$VillaTypeFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('Singola monofamiliare'),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('A schiera o plurifamiliare'),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								})
							]),
						A2(
							$author$project$Pyxis$Components$Field$RadioGroup$withLabel,
							A2(
								$author$project$Pyxis$Components$Field$Label$withSize,
								$author$project$Pyxis$Components$Field$Label$sizeS,
								A2($author$project$Pyxis$Components$Field$Label$withText, 'Qual è la sua categoria?', $author$project$Pyxis$Components$Field$Label$config)),
							A3(
								$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
								validation,
								isFormSubmitted,
								A2(
									$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
									$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
									$author$project$Pyxis$Components$Field$RadioGroup$config(id)))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$villaTypeId = 'villa-type';
var $author$project$Quotation$Common$View$FormRows$villaTypeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$villaType,
			$author$project$Quotation$Common$Fields$View$villaTypeId,
			$author$project$Quotation$Common$StepModel$villaFieldValidation(stepState),
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickVillaTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$conditionalVillaTypeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return $author$project$Quotation$Common$Fields$Model$isPropertyTypeVillaSelected(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState)) ? $elm$core$Maybe$Just(
			A4($author$project$Quotation$Common$View$FormRows$villaTypeRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper = function (constructionPeriodType) {
	switch (constructionPeriodType) {
		case 0:
			return 'Prima del 1970';
		case 1:
			return '1970 - 1979';
		case 2:
			return '1980 - 1989';
		case 3:
			return '1990 - 1999';
		case 4:
			return '2000 - 2009';
		case 5:
			return '2010 - 2019';
		case 6:
			return 'Dopo il 2019';
		default:
			return 'Non ricordo';
	}
};
var $author$project$Quotation$Common$View$FormRows$constructionPeriodTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Seleziona periodo',
		A2(
			$elm$core$Result$map,
			$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper,
			$author$project$Quotation$Common$StepModel$validatedConstructionPeriodField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$constructionPeriodExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isConstructionPeriodFieldValid(stepState),
		n6: 'Quando è stata costruita o ristrutturata?',
		eE: $author$project$Quotation$Common$View$FormRows$constructionPeriodTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$ConstructionPeriodFieldChanged = function (a) {
	return {$: 2, a: a};
};
var $author$project$Quotation$Common$Fields$View$constructionPeriod = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$ConstructionPeriodFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(0)),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(1)),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(2)),
									eE: $elm$core$Maybe$Nothing,
									p8: 2
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(3)),
									eE: $elm$core$Maybe$Nothing,
									p8: 3
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(4)),
									eE: $elm$core$Maybe$Nothing,
									p8: 4
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(5)),
									eE: $elm$core$Maybe$Nothing,
									p8: 5
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(6)),
									eE: $elm$core$Maybe$Nothing,
									p8: 6
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$constructionPeriodTypeToStringMapper(7)),
									eE: $elm$core$Maybe$Nothing,
									p8: 7
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('quotation__fixed-two-column-layout', true)
									]),
								A2(
									$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
									$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
									$author$project$Pyxis$Components$Field$RadioGroup$config(id)))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$constructionPeriodId = 'construction-period';
var $author$project$Quotation$Common$View$FormRows$constructionPeriodRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$constructionPeriod,
			$author$project$Quotation$Common$Fields$View$constructionPeriodId,
			$author$project$Quotation$Common$Fields$Model$constructionPeriodFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickConstructionPeriodField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$constructionPeriodExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$constructionPeriodExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$constructionPeriodRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(4),
		nM: $author$project$Quotation$Common$Fields$View$constructionPeriodId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(4))
	});
var $author$project$Pyxis$Components$Message$withTitle = F2(
	function (title, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoMessage = function () {
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Per lavori più lunghi di dieci anni, seleziona il periodo in cui sono stati completati.' + ' Per lavori ancora in corso, invece, seleziona \"Dopo il 2019\".')
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			A2($author$project$Pyxis$Components$Message$withTitle, 'Periodo di costruzione o ristrutturazione', $author$project$Pyxis$Components$Message$neutral)));
}();
var $author$project$Quotation$Common$Fields$Model$ownershipTypeToStringMapper = function (fieldValue) {
	if (!fieldValue) {
		return 'Casa di proprietà';
	} else {
		return 'Casa in affitto';
	}
};
var $author$project$Quotation$Common$View$FormRows$ownershipTypeTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Seleziona proprietà',
		A2(
			$elm$core$Result$map,
			$author$project$Quotation$Common$Fields$Model$ownershipTypeToStringMapper,
			$author$project$Quotation$Common$StepModel$validatedOwnershipTypeField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$ownershipTypeExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isOwnershipTypeFieldValid(stepState),
		n6: 'La casa è di tua proprietà?',
		eE: $author$project$Quotation$Common$View$FormRows$ownershipTypeTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$OwnershipTypeFieldChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Quotation$Common$Fields$View$ownershipType = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$OwnershipTypeFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('Sì'),
									eE: $elm$core$Maybe$Nothing,
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('No'),
									eE: $elm$core$Maybe$Nothing,
									p8: 1
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('quotation__fixed-two-column-layout', true)
									]),
								A2(
									$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
									$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
									$author$project$Pyxis$Components$Field$RadioGroup$config(id)))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$ownershipTypeId = 'ownership-type';
var $author$project$Quotation$Common$View$FormRows$ownershipTypeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$ownershipType,
			$author$project$Quotation$Common$Fields$View$ownershipTypeId,
			$author$project$Quotation$Common$Fields$Model$ownershipTypeFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickOwnershipTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$ownershipTypeExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$ownershipTypeExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$ownershipTypeRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$OwnershipTypeInfoModal = 0;
var $author$project$Quotation$Home$PropertyStep$View$ownershipTypeInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(0),
		nM: $author$project$Quotation$Common$Fields$View$ownershipTypeId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(0))
	});
var $author$project$Quotation$Common$Fields$Model$propertyPurposeTypeToStringMapper = function (propertyPurposeType) {
	switch (propertyPurposeType) {
		case 0:
			return 'Uso abituale';
		case 1:
			return 'Uso saltuario';
		default:
			return 'Uso locato';
	}
};
var $author$project$Quotation$Common$View$FormRows$propertyPurposeTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Seleziona uso',
		A2(
			$elm$core$Result$map,
			$author$project$Quotation$Common$Fields$Model$propertyPurposeTypeToStringMapper,
			$author$project$Quotation$Common$StepModel$validatedPropertyPurposeField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$propertyPurposeExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isPropertyPurposeFieldValid(stepState),
		n6: 'Come la utilizzi?',
		eE: $author$project$Quotation$Common$View$FormRows$propertyPurposeTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$PropertyPurposeFieldChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Quotation$Common$Fields$View$propertyPurpose = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyPurposeFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('È la casa in cui vivo'),
									eE: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyPurposeTypeToStringMapper(0)),
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('È la seconda casa'),
									eE: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyPurposeTypeToStringMapper(1)),
									p8: 1
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeM(
								{
									h6: $elm$core$Maybe$Nothing,
									lG: $elm$core$Maybe$Just('È una casa data in affitto'),
									eE: $elm$core$Maybe$Just(
										$author$project$Quotation$Common$Fields$Model$propertyPurposeTypeToStringMapper(2)),
									p8: 2
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
								$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
								$author$project$Pyxis$Components$Field$RadioGroup$config(id))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$propertyPurposeId = 'property-purpose';
var $author$project$Quotation$Common$View$FormRows$propertyPurposeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$propertyPurpose,
			$author$project$Quotation$Common$Fields$View$propertyPurposeId,
			$author$project$Quotation$Common$Fields$Model$propertyPurposeFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPropertyPurposeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$propertyPurposeExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$propertyPurposeExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$propertyPurposeRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$PropertyPurposeInfoModal = 1;
var $author$project$Quotation$Home$PropertyStep$View$propertyPurposeInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(1),
		nM: $author$project$Quotation$Common$Fields$View$propertyPurposeId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(1))
	});
var $author$project$Quotation$Common$View$FormRows$propertySizeTitle = function (stepData) {
	return A2(
		$elm$core$Result$withDefault,
		'Inserisci metri quadri',
		A2(
			$elm$core$Result$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$fromInt,
				A2($elm_community$basics_extra$Basics$Extra$flip, $elm$core$Basics$append, ' mq')),
			$author$project$Quotation$Common$StepModel$validatedPropertySizeField(stepData)));
};
var $author$project$Quotation$Common$View$FormRows$propertySizeExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId, stepState),
		jY: $author$project$Quotation$Common$StepModel$isPropertySizeFieldValid(stepState),
		n6: 'Che dimensioni ha?',
		eE: $author$project$Quotation$Common$View$FormRows$propertySizeTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$Fields$Model$PropertySizeFieldChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pyxis$Components$Field$Input$Append = 1;
var $author$project$Pyxis$Components$Field$Input$TextAddon = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$Input$withTextAppend = F2(
	function (text_, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				h6: $elm$core$Maybe$Just(
					{
						aF: 1,
						ao: $author$project$Pyxis$Components$Field$Input$TextAddon(text_)
					})
			});
	});
var $author$project$Quotation$Common$Fields$View$propertySize = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$Input$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertySizeFieldChanged, msgMapper),
					field,
					A3(
						$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
						validation,
						isFormSubmitted,
						A2(
							$author$project$Pyxis$Components$Field$Input$withTextAppend,
							'mq',
							A2(
								$author$project$Pyxis$Components$Field$Input$withPlaceholder,
								'Inserisci metri quadri',
								A2(
									$author$project$Pyxis$Components$Field$Input$withTestId,
									$author$project$Quotation$Common$Fields$View$toFieldDataTestId(id),
									$author$project$Pyxis$Components$Field$Input$text(id)))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$propertySizeId = 'property-size';
var $author$project$Quotation$Common$View$FormRows$propertySizeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$propertySize,
			$author$project$Quotation$Common$Fields$View$propertySizeId,
			$author$project$Quotation$Common$Fields$Model$propertySizeFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPropertySizeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Common$View$FormRows$propertySizeExpandableRow = F5(
	function (stepState, fieldChangedMsgMapper, toToggleMsg, optionalFieldInfo, optionalMessage) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$propertySizeExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A4($author$project$Quotation$Common$View$FormRows$propertySizeRow, stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage)));
	});
var $author$project$Quotation$Common$Fields$Model$PropertySizeInfoModal = 5;
var $author$project$Quotation$Home$PropertyStep$View$propertySizeInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(5),
		nM: $author$project$Quotation$Common$Fields$View$propertySizeId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(5))
	});
var $author$project$Quotation$Home$PropertyStep$View$propertySizeInfoMessage = function () {
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Per un preventivo più preciso, indica i metri quadri commerciali della casa, comprese le pertinenze' + ' (balconi, cantine, box e garage, escludendo il giardino).'),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-s-bold')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(' In assenza della metratura esatta, inserisci un valore approssimativo' + ' il più vicino possibile a quello reale.')
					]))
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			$author$project$Pyxis$Components$Message$neutral));
}();
var $author$project$Quotation$Common$View$FormRows$invalidPropertyTypeFieldTitle = 'Seleziona tipo';
var $author$project$Quotation$Common$Fields$Model$getBuildingAdministratorValue = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$pickBuildingAdministratorField, $author$project$Pyxis$Components$Field$RadioGroup$getValue);
var $author$project$Quotation$Common$View$FormRows$apartmentTypeTitle = function (stepData) {
	return _Utils_eq(
		$author$project$Quotation$Common$Fields$Model$getBuildingAdministratorValue(stepData),
		$elm$core$Maybe$Just(0)) ? 'Appartamento con amministratore' : (_Utils_eq(
		$author$project$Quotation$Common$Fields$Model$getBuildingAdministratorValue(stepData),
		$elm$core$Maybe$Just(1)) ? 'Appartamento senza amministratore' : $author$project$Quotation$Common$View$FormRows$invalidPropertyTypeFieldTitle);
};
var $author$project$Quotation$Common$Fields$Model$getVillaTypeValue = A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$pickVillaTypeField, $author$project$Pyxis$Components$Field$RadioGroup$getValue);
var $author$project$Quotation$Common$View$FormRows$villaTypeTitle = function (stepData) {
	return _Utils_eq(
		$author$project$Quotation$Common$Fields$Model$getVillaTypeValue(stepData),
		$elm$core$Maybe$Just(0)) ? 'Casa indipendente monofamiliare' : (_Utils_eq(
		$author$project$Quotation$Common$Fields$Model$getVillaTypeValue(stepData),
		$elm$core$Maybe$Just(1)) ? 'Casa a schiera' : $author$project$Quotation$Common$View$FormRows$invalidPropertyTypeFieldTitle);
};
var $author$project$Quotation$Common$View$FormRows$propertyTypeTitle = function (stepData) {
	return _Utils_eq(
		$author$project$Quotation$Common$Fields$Model$getPropertyTypeValue(stepData),
		$elm$core$Maybe$Just(0)) ? $author$project$Quotation$Common$View$FormRows$villaTypeTitle(stepData) : $author$project$Quotation$Common$View$FormRows$apartmentTypeTitle(stepData);
};
var $author$project$Quotation$Common$View$FormRows$propertyTypeExpandableField = function (stepState) {
	return {
		nM: $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId,
		aB: A2($author$project$Quotation$Common$StepModel$isFieldDisabled, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId, stepState),
		n2: A2($author$project$Quotation$Common$StepModel$isFieldExpanded, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId, stepState),
		jY: !_Utils_eq(
			$author$project$Quotation$Common$View$FormRows$propertyTypeTitle(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			$author$project$Quotation$Common$View$FormRows$invalidPropertyTypeFieldTitle),
		n6: 'Di che tipo è?',
		eE: $author$project$Quotation$Common$View$FormRows$propertyTypeTitle(
			$author$project$Quotation$Common$StepModel$pickFormData(stepState))
	};
};
var $author$project$Quotation$Common$View$FormRows$propertyTypeExpandableRow = F5(
	function (stepState, toToggleMsg, propertyType, conditionalVillaType, conditionalBuildingAdministratorType) {
		var expandableField = $author$project$Quotation$Common$View$FormRows$propertyTypeExpandableField(stepState);
		return $author$project$Quotation$Common$View$FormRows$toSimpleColRowS(
			A3(
				$author$project$Quotation$Common$View$ExpandableField$view,
				expandableField,
				toToggleMsg(expandableField),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
						]),
					A2(
						$elm$core$List$filterMap,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$core$Maybe$Just(propertyType),
								conditionalVillaType,
								conditionalBuildingAdministratorType
							])))));
	});
var $author$project$Quotation$Home$PropertyStep$View$propertyTypeInfoMessage = function () {
	var messageContent = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-s-book')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Seleziona questa risposta se hai una casa con accesso indipendente dall\'esterno,' + ' come una villa, una villetta a schiera o una villetta bifamiliare.')
			]));
	return $author$project$Pyxis$Components$Message$render(
		A2(
			$author$project$Pyxis$Components$Message$withContent,
			_List_fromArray(
				[messageContent]),
			A2($author$project$Pyxis$Components$Message$withTitle, 'Casa indipendente', $author$project$Pyxis$Components$Message$neutral)));
}();
var $author$project$Quotation$Common$Fields$Model$PropertyTypeFieldChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$Field$RadioGroup$addonImage = $author$project$Pyxis$Components$Field$OptionCard$addonImage;
var $author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeL = function (_v0) {
	var addon = _v0.h6;
	var text = _v0.lG;
	var title = _v0.eE;
	var value = _v0.p8;
	return {
		h6: $elm$core$Maybe$Just(
			$author$project$Pyxis$Components$Field$OptionCard$addonSizeLType(addon)),
		ff: false,
		aH: $author$project$Pyxis$Components$Field$OptionCard$sizeL,
		lG: text,
		eE: title,
		p8: value
	};
};
var $author$project$Quotation$Common$Fields$View$propertyType = F7(
	function (id, validation, isFormSubmitted, msgMapper, field, optionalFieldInfo, optionalMessageView) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation__expandable-field-content-grid')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$RadioGroup$render,
					A2($elm$core$Basics$composeR, $author$project$Quotation$Common$Fields$Model$PropertyTypeFieldChanged, msgMapper),
					field,
					A2(
						$author$project$Pyxis$Components$Field$RadioGroup$withOptionsCard,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeL(
								{
									h6: $author$project$Pyxis$Components$Field$RadioGroup$addonImage('/svg/form/v2/villa.svg'),
									lG: $elm$core$Maybe$Nothing,
									eE: $elm$core$Maybe$Just('Casa indipendente'),
									p8: 0
								}),
								$author$project$Pyxis$Components$Field$RadioGroup$optionCardSizeL(
								{
									h6: $author$project$Pyxis$Components$Field$RadioGroup$addonImage('/svg/form/v2/apartment.svg'),
									lG: $elm$core$Maybe$Nothing,
									eE: $elm$core$Maybe$Just('Appartamento'),
									p8: 1
								})
							]),
						A3(
							$author$project$Pyxis$Components$Field$RadioGroup$withValidationOnSubmit,
							validation,
							isFormSubmitted,
							A2(
								$author$project$Pyxis$Components$Field$RadioGroup$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('quotation__fixed-two-column-layout', true)
									]),
								A2(
									$author$project$Pyxis$Components$Field$RadioGroup$withLayout,
									$author$project$Pyxis$Components$Field$RadioGroup$layoutVertical,
									$author$project$Pyxis$Components$Field$RadioGroup$config(id)))))),
					$author$project$Quotation$Common$Fields$View$renderInfoLink(optionalFieldInfo),
					$author$project$Quotation$Common$Fields$View$renderInfoMessage(optionalMessageView)
				]));
	});
var $author$project$Quotation$Common$Fields$View$propertyTypeId = 'property-type';
var $author$project$Quotation$Common$View$FormRows$propertyTypeRow = F4(
	function (stepState, fieldChangedMsgMapper, optionalFieldInfo, optionalMessage) {
		return A7(
			$author$project$Quotation$Common$Fields$View$propertyType,
			$author$project$Quotation$Common$Fields$View$propertyTypeId,
			$author$project$Quotation$Common$Fields$Model$propertyTypeFieldValidation,
			$author$project$Quotation$Common$StepModel$pickIsFormSubmitted(stepState) && A2(
				$elm$core$Set$member,
				$author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId,
				$author$project$Quotation$Common$StepModel$pickEnabledValidationFieldsIds(stepState)),
			fieldChangedMsgMapper,
			$author$project$Quotation$Common$Fields$Model$pickPropertyTypeField(
				$author$project$Quotation$Common$StepModel$pickFormData(stepState)),
			optionalFieldInfo,
			optionalMessage);
	});
var $author$project$Quotation$Home$PropertyStep$View$toToggleMsg = F2(
	function (expandedFieldsIds, _v0) {
		var id = _v0.nM;
		var isExpanded = _v0.n2;
		return $author$project$Quotation$Home$PropertyStep$Model$ExpandableFieldAnimation(
			A3($author$project$Quotation$Common$View$ExpandableField$toStateUpdateList, id, !isExpanded, expandedFieldsIds));
	});
var $author$project$Quotation$Common$Fields$Model$VillaTypeInfoModal = 2;
var $author$project$Quotation$Home$PropertyStep$View$villaTypeInfoLink = $author$project$View$InfoModalButton$view(
	{
		mQ: $author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalLinkText(2),
		nM: $author$project$Quotation$Common$Fields$View$villaTypeId,
		gc: $author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated(
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(2))
	});
var $author$project$Quotation$Home$PropertyStep$View$fieldsetContent = function (stepState) {
	var propertyType = A4(
		$author$project$Quotation$Common$View$FormRows$propertyTypeRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
			A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId)),
		$author$project$Quotation$Common$Fields$View$WithoutInfoLink,
		$author$project$Quotation$Common$Fields$View$InfoMessage($author$project$Quotation$Home$PropertyStep$View$propertyTypeInfoMessage));
	var conditionalVillaType = A4(
		$author$project$Quotation$Common$View$FormRows$conditionalVillaTypeRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
			A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId)),
		$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$villaTypeInfoLink),
		$author$project$Quotation$Common$Fields$View$WithoutInfoMessage);
	var conditionalBuildingAdministratorType = A4(
		$author$project$Quotation$Common$View$FormRows$conditionalBuildingAdministratorRow,
		stepState,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
			A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyTypeExpandableFieldId)),
		$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$buildingAdministratorInfoLink),
		$author$project$Quotation$Common$Fields$View$WithoutInfoMessage);
	return _List_fromArray(
		[
			A5(
			$author$project$Quotation$Common$View$FormRows$ownershipTypeExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$ownershipTypeExpandableFieldId)),
			$author$project$Quotation$Home$PropertyStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$ownershipTypeInfoLink),
			$author$project$Quotation$Common$Fields$View$WithoutInfoMessage),
			A5(
			$author$project$Quotation$Common$View$FormRows$propertyPurposeExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertyPurposeExpandableFieldId)),
			$author$project$Quotation$Home$PropertyStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$propertyPurposeInfoLink),
			$author$project$Quotation$Common$Fields$View$WithoutInfoMessage),
			A5(
			$author$project$Quotation$Common$View$FormRows$propertyTypeExpandableRow,
			stepState,
			$author$project$Quotation$Home$PropertyStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			propertyType,
			conditionalVillaType,
			conditionalBuildingAdministratorType),
			A5(
			$author$project$Quotation$Common$View$FormRows$constructionPeriodExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$constructionPeriodExpandableFieldId)),
			$author$project$Quotation$Home$PropertyStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoLink),
			$author$project$Quotation$Common$Fields$View$InfoMessage($author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoMessage)),
			A5(
			$author$project$Quotation$Common$View$FormRows$propertySizeExpandableRow,
			stepState,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Quotation$Home$PropertyStep$Model$FieldChanged,
				A2($author$project$Quotation$Home$PropertyStep$Model$conditionalFieldEditableMsg, stepState, $author$project$Quotation$Common$Fields$Model$propertySizeExpandableFieldId)),
			$author$project$Quotation$Home$PropertyStep$View$toToggleMsg(
				$author$project$Quotation$Common$StepModel$getExpandedFieldsIdsList(stepState)),
			$author$project$Quotation$Common$Fields$View$InfoLink($author$project$Quotation$Home$PropertyStep$View$propertySizeInfoLink),
			$author$project$Quotation$Common$Fields$View$InfoMessage($author$project$Quotation$Home$PropertyStep$View$propertySizeInfoMessage))
		]);
};
var $author$project$Quotation$Home$PropertyStep$Model$Proceed = {$: 12};
var $author$project$Quotation$Home$PropertyStep$View$proceedButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('button-row justify-content-end')
		]),
	_List_fromArray(
		[
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withText,
				'Procedi',
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					$author$project$Quotation$Home$PropertyStep$Model$Proceed,
					A2(
						$author$project$Pyxis$Components$Button$withIconAppend,
						$author$project$Pyxis$Components$IconSet$arrowRight,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeXL,
								A2(
									$author$project$Pyxis$Components$Button$withTestId,
									'proceed-btn',
									A2($author$project$Pyxis$Components$Button$withId, 'proceed-btn', $author$project$Pyxis$Components$Button$primary))))))))
		]));
var $author$project$Quotation$Home$PropertyStep$View$fieldsetFooter = function (stepState) {
	return $author$project$Types$pickIsPrivacyDisclaimerVisible(
		$author$project$Quotation$Common$StepModel$pickFlags(stepState)) ? $author$project$Quotation$Common$View$FieldsetFooterWithDisclaimer$view(
		{
			_: $author$project$Types$pickIsMobile(
				$author$project$Quotation$Common$StepModel$pickFlags(stepState)),
			o$: $author$project$Quotation$Home$PropertyStep$View$proceedButton,
			p0: $author$project$Quotation$Home$PropertyStep$Model$TogglePrivacyPolicy(true)
		}) : _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$PropertyStep$View$proceedButton)
		]);
};
var $author$project$Quotation$Home$PropertyStep$Model$DismissDiscountMessage = {$: 3};
var $author$project$Quotation$Home$PropertyStep$View$header = $author$project$Pyxis$Components$Form$Legend$render(
	$author$project$Pyxis$Components$Form$Legend$withAlignmentLeft(
		A2(
			$author$project$Pyxis$Components$Form$Legend$withDescription,
			'Per cominciare, inserisci alcune informazioni di base sull\'abitazione da assicurare',
			A2($author$project$Pyxis$Components$Form$Legend$withTitle, 'Informazioni sulla tua casa', $author$project$Pyxis$Components$Form$Legend$config))));
var $author$project$Quotation$Home$PropertyStep$View$fieldsetHeader = function (stepState) {
	return $author$project$Quotation$Common$StepModel$pickIsDiscountMessageVisible(stepState) ? _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowM(
			$author$project$Quotation$Common$View$DiscountMessage$view($author$project$Quotation$Home$PropertyStep$Model$DismissDiscountMessage)),
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$PropertyStep$View$header)
		]) : _List_fromArray(
		[
			$author$project$Quotation$Common$View$FormRows$toSimpleColRowS($author$project$Quotation$Home$PropertyStep$View$header)
		]);
};
var $author$project$Quotation$Home$PropertyStep$View$fieldsetConfig = function (stepState) {
	return A2(
		$author$project$Pyxis$Components$Form$Fieldset$withFooter,
		$author$project$Quotation$Home$PropertyStep$View$fieldsetFooter(stepState),
		A2(
			$author$project$Pyxis$Components$Form$Fieldset$withContent,
			$author$project$Quotation$Home$PropertyStep$View$fieldsetContent(stepState),
			A2(
				$author$project$Pyxis$Components$Form$Fieldset$withHeader,
				$author$project$Quotation$Home$PropertyStep$View$fieldsetHeader(stepState),
				$author$project$Pyxis$Components$Form$Fieldset$config)));
};
var $author$project$Quotation$Home$PropertyStep$View$ownershipTypeInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'ownership-type-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(0)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(0),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(0));
};
var $author$project$Quotation$Home$PropertyStep$Model$ResetPropertyStepState = {$: 4};
var $author$project$Quotation$Common$View$Modals$OwnershipType$content = _List_fromArray(
	[
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-m-book margin-b-s')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Non è ancora possibile acquistare una polizza Casa e Famiglia se sei in affitto, usufrutto o comodato d\'uso.' + ' Prova a calcolare un nuovo preventivo per una casa di tua proprietà.')
			]))
	]);
var $author$project$Quotation$Home$PropertyStep$Model$ValidOwnershipTypeFieldChanged = {$: 5};
var $author$project$Quotation$Common$View$Modals$OwnershipType$modalId = 'ownership-type-modal';
var $author$project$Pyxis$Components$Button$Secondary = 1;
var $author$project$Pyxis$Components$Button$secondary = $author$project$Pyxis$Components$Button$config(1);
var $author$project$Quotation$Common$View$Modals$OwnershipType$footer = A2(
	$author$project$Pyxis$Components$Modal$Footer$withButtons,
	_List_fromArray(
		[
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				$author$project$Quotation$Home$PropertyStep$Model$ResetPropertyStepState,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withText,
						'Chiudi',
						A2($author$project$Pyxis$Components$Button$withId, $author$project$Quotation$Common$View$Modals$OwnershipType$modalId + '-close-btn', $author$project$Pyxis$Components$Button$secondary))))),
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				$author$project$Quotation$Home$PropertyStep$Model$ValidOwnershipTypeFieldChanged,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withText,
						'Assicura casa di proprietà',
						A2($author$project$Pyxis$Components$Button$withId, $author$project$Quotation$Common$View$Modals$OwnershipType$modalId + '-confirm-btn', $author$project$Pyxis$Components$Button$primary)))))
		]),
	A2($author$project$Pyxis$Components$Modal$Footer$withFullWidthButton, true, $author$project$Pyxis$Components$Modal$Footer$config));
var $author$project$Quotation$Common$View$Modals$OwnershipType$header = A2($author$project$Pyxis$Components$Modal$Header$withTitle, 'Puoi assicurare una casa solo se è di tua proprietà', $author$project$Pyxis$Components$Modal$Header$config);
var $author$project$Quotation$Common$View$Modals$OwnershipType$view = function (isOpen) {
	return A2(
		$author$project$Pyxis$Components$Modal$render,
		A2($author$project$Utils$initSimpleModal, $author$project$Quotation$Common$View$Modals$OwnershipType$modalId, isOpen),
		A2(
			$author$project$Pyxis$Components$Modal$withFooter,
			$author$project$Quotation$Common$View$Modals$OwnershipType$footer,
			A2(
				$author$project$Pyxis$Components$Modal$withContent,
				$author$project$Quotation$Common$View$Modals$OwnershipType$content,
				A2(
					$author$project$Pyxis$Components$Modal$withHeader,
					$author$project$Quotation$Common$View$Modals$OwnershipType$header,
					A3(
						$author$project$Pyxis$Components$Modal$withCloseMsg,
						$author$project$Quotation$Home$PropertyStep$Model$ResetPropertyStepState,
						'Chiudi',
						A2(
							$author$project$Pyxis$Components$Modal$withIsCentered,
							true,
							A2($author$project$Pyxis$Components$Modal$withTestId, $author$project$Quotation$Common$View$Modals$OwnershipType$modalId, $author$project$Pyxis$Components$Modal$config)))))));
};
var $author$project$Quotation$Home$PropertyStep$View$ownershipTypeModal = function (stepState) {
	return $author$project$Quotation$Common$View$Modals$OwnershipType$view(
		_Utils_eq(
			$author$project$Quotation$Home$PropertyStep$Model$OpenOwnershipTypeModal,
			$author$project$Quotation$Common$StepModel$pickModalState(stepState)));
};
var $author$project$Quotation$Home$PropertyStep$View$propertyPurposeInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'property-purpose-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(1)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(1),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(1));
};
var $author$project$Quotation$Home$PropertyStep$View$propertySizeInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'property-size-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(5)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(5),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(5));
};
var $author$project$Quotation$Home$PropertyStep$View$villaTypeInfoModal = function (stepState) {
	return A5(
		$author$project$Quotation$Common$View$Modals$FieldInfo$view,
		'villa-type-info-modal',
		_Utils_eq(
			$author$project$Quotation$Common$StepModel$pickModalState(stepState),
			$author$project$Quotation$Home$PropertyStep$Model$OpenFieldInfoModal(2)),
		$author$project$Quotation$Home$PropertyStep$Model$FieldInfoModalUpdated($author$project$Quotation$Home$PropertyStep$Model$ClosedModal),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalTitle(2),
		$author$project$Quotation$Home$PropertyStep$View$getOpenFieldInfoModalMessage(2));
};
var $author$project$Quotation$Home$PropertyStep$View$view = function (stepState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('container-responsive')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Fieldset$render(
				$author$project$Quotation$Home$PropertyStep$View$fieldsetConfig(stepState)),
				$author$project$Quotation$Home$PropertyStep$View$ownershipTypeModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$ownershipTypeInfoModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$propertyPurposeInfoModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$villaTypeInfoModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$buildingAdministratorInfoModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$constructionPeriodInfoModal(stepState),
				$author$project$Quotation$Home$PropertyStep$View$propertySizeInfoModal(stepState),
				A3(
				$author$project$Quotation$Common$View$Modals$PrivacyPolicy$view,
				_Utils_eq(
					$author$project$Quotation$Common$StepModel$pickModalState(stepState),
					$author$project$Quotation$Home$PropertyStep$Model$OpenPrivacyPolicyModal),
				$author$project$Quotation$Common$StepModel$pickFlags(stepState),
				$author$project$Quotation$Home$PropertyStep$Model$TogglePrivacyPolicy(false))
			]));
};
var $author$project$Quotation$Home$View$stepFieldsView = function (_v0) {
	var stepState = _v0.cH;
	switch (stepState.$) {
		case 0:
			var state = stepState.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Quotation$Home$Model$PropertyStepMsg,
				$author$project$Quotation$Home$PropertyStep$View$view(state));
		case 1:
			var state = stepState.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Quotation$Home$Model$AddressStepMsg,
				$author$project$Quotation$Home$AddressStep$View$view(state));
		default:
			var state = stepState.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Quotation$Home$Model$CustomerStepMsg,
				$author$project$Quotation$Home$CustomerStep$View$view(state));
	}
};
var $author$project$Quotation$Home$View$modelInitializedView = function (modelState) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quotation__main')
			]),
		_List_fromArray(
			[
				$author$project$Quotation$Home$View$stepFieldsView(modelState)
			]));
};
var $author$project$Quotation$Home$View$view = function (model) {
	switch (model.$) {
		case 2:
			var modelInitializedState = model.a;
			return $author$project$Quotation$Home$View$modelInitializedView(modelInitializedState);
		case 1:
			return $elm$html$Html$text('');
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$View$mainContentView = function (model) {
	switch (model.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			return $elm$html$Html$text('');
		case 2:
			var homeModel = model.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Quotation$Model$HomeMsg,
				$author$project$Quotation$Home$View$view(homeModel));
		default:
			var familyModel = model.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Quotation$Model$FamilyMsg,
				$author$project$Quotation$Family$View$view(familyModel));
	}
};
var $author$project$Quotation$View$quotationFadeInView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('quotation-fade-in')
			]),
		_List_fromArray(
			[
				$author$project$Quotation$View$mainContentView(model)
			]));
};
var $author$project$View$TopBar$MobileHiddenLogo = 1;
var $author$project$View$TopBar$RightSideContent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$ProgressIndicator$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$ProgressIndicator$Right = 1;
var $author$project$Pyxis$Components$ProgressIndicator$config = F2(
	function (currentStep, totalSteps) {
		return {c_: 1, c5: _List_Nil, dd: 'Step $current of $total', de: currentStep, nM: $elm$core$Maybe$Nothing, eA: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing, hV: totalSteps};
	});
var $author$project$Pyxis$Components$ProgressIndicator$calculatePercentage = function (current) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$toFloat,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$fdiv(current),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Basics$mul(100),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$String$fromFloat,
					$elm$core$Basics$append('--percentage: ')))));
};
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $author$project$Pyxis$Components$ProgressIndicator$getProgressIndicatorAlignmentClass = function (alignment) {
	if (!alignment) {
		return 'progress-indicator--left-aligned';
	} else {
		return '';
	}
};
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $author$project$Pyxis$Components$ProgressIndicator$renderCounterText = F2(
	function (current, total) {
		return A2(
			$elm$core$Basics$composeR,
			A2(
				$elm$core$String$replace,
				'$current',
				$elm$core$String$fromInt(current)),
			A2(
				$elm$core$String$replace,
				'$total',
				$elm$core$String$fromInt(total)));
	});
var $author$project$Pyxis$Components$ProgressIndicator$renderTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('progress-indicator__step-title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$ProgressIndicator$render = function (_v0) {
	var alignment = _v0.c_;
	var classList = _v0.c5;
	var id = _v0.nM;
	var testId = _v0.eA;
	var title = _v0.eE;
	var counterTextTemplate = _v0.dd;
	var currentStep = _v0.de;
	var totalSteps = _v0.hV;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('progress-indicator', true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$ProgressIndicator$getProgressIndicatorAlignmentClass(alignment),
						true)
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('progress-indicator__content-wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('progress-indicator__step-number')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								A3($author$project$Pyxis$Components$ProgressIndicator$renderCounterText, currentStep, totalSteps, counterTextTemplate))
							])),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$ProgressIndicator$renderTitle, title))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('progress-indicator__chart')
					]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$svg,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
								$elm$svg$Svg$Attributes$viewBox('0 0 32 32')
							]),
						_List_fromArray(
							[
								A2(
								$elm$svg$Svg$circle,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$class('progress-indicator__chart__background'),
										$elm$svg$Svg$Attributes$cx('16'),
										$elm$svg$Svg$Attributes$cy('16'),
										$elm$svg$Svg$Attributes$r('14')
									]),
								_List_Nil),
								A2(
								$elm$svg$Svg$circle,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$class('progress-indicator__chart__progress'),
										A2(
										$elm$html$Html$Attributes$attribute,
										'style',
										A2($author$project$Pyxis$Components$ProgressIndicator$calculatePercentage, currentStep, totalSteps)),
										$elm$svg$Svg$Attributes$cx('16'),
										$elm$svg$Svg$Attributes$cy('16'),
										$elm$svg$Svg$Attributes$r('14')
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Pyxis$Components$ProgressIndicator$Left = 0;
var $author$project$Pyxis$Components$ProgressIndicator$withAlignmentLeft = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{c_: 0});
};
var $author$project$Pyxis$Components$ProgressIndicator$withCounterText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{dd: text});
	});
var $author$project$Pyxis$Components$ProgressIndicator$withTitle = F2(
	function (title, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Quotation$Common$View$TopBar$progressView = F4(
	function (step, numSteps, title, isMobile) {
		var conditionalLeftAlignment = isMobile ? $author$project$Pyxis$Components$ProgressIndicator$withAlignmentLeft : $elm$core$Basics$identity;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex-row')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$ProgressIndicator$render(
					A2(
						$author$project$Pyxis$Components$ProgressIndicator$withCounterText,
						'Step $current di $total',
						A2(
							$author$project$Pyxis$Components$ProgressIndicator$withTitle,
							title,
							conditionalLeftAlignment(
								A2($author$project$Pyxis$Components$ProgressIndicator$config, step, numSteps)))))
				]));
	});
var $author$project$Quotation$Common$View$TopBar$familyCustomerStepProgressView = A3($author$project$Quotation$Common$View$TopBar$progressView, 2, 2, 'Dati e contatti');
var $author$project$Quotation$Common$View$TopBar$familyInfoStepProgressView = A3($author$project$Quotation$Common$View$TopBar$progressView, 1, 2, 'Informazioni famiglia');
var $author$project$Quotation$Common$View$TopBar$familyTopBarView = F2(
	function (familyModel, flags) {
		var _v0 = $author$project$Quotation$Family$Model$pickStepState(familyModel);
		if (!_v0.$) {
			if (!_v0.a.$) {
				var stepState = _v0.a.a;
				return A4(
					$author$project$View$TopBar$view,
					flags,
					$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
					1,
					$author$project$View$TopBar$RightSideContent(
						$author$project$Quotation$Common$View$TopBar$familyInfoStepProgressView(
							$author$project$Types$pickIsMobile(flags))));
			} else {
				var stepState = _v0.a.a;
				return A4(
					$author$project$View$TopBar$view,
					flags,
					$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
					1,
					$author$project$View$TopBar$RightSideContent(
						$author$project$Quotation$Common$View$TopBar$familyCustomerStepProgressView(
							$author$project$Types$pickIsMobile(flags))));
			}
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Quotation$Common$View$TopBar$homeAddressStepProgressView = A3($author$project$Quotation$Common$View$TopBar$progressView, 2, 3, 'Indirizzo e dettagli');
var $author$project$Quotation$Common$View$TopBar$homeCustomerStepProgressView = A3($author$project$Quotation$Common$View$TopBar$progressView, 3, 3, 'Dati e contatti');
var $author$project$Quotation$Common$View$TopBar$homePropertyStepProgressView = A3($author$project$Quotation$Common$View$TopBar$progressView, 1, 3, 'La tua casa');
var $author$project$Quotation$Common$View$TopBar$homeTopBarView = F2(
	function (homeModel, flags) {
		var _v0 = $author$project$Quotation$Home$Model$pickStepState(homeModel);
		if (!_v0.$) {
			switch (_v0.a.$) {
				case 0:
					var stepState = _v0.a.a;
					return A4(
						$author$project$View$TopBar$view,
						flags,
						$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
						1,
						$author$project$View$TopBar$RightSideContent(
							$author$project$Quotation$Common$View$TopBar$homePropertyStepProgressView(
								$author$project$Types$pickIsMobile(flags))));
				case 1:
					var stepState = _v0.a.a;
					return A4(
						$author$project$View$TopBar$view,
						flags,
						$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
						1,
						$author$project$View$TopBar$RightSideContent(
							$author$project$Quotation$Common$View$TopBar$homeAddressStepProgressView(
								$author$project$Types$pickIsMobile(flags))));
				default:
					var stepState = _v0.a.a;
					return A4(
						$author$project$View$TopBar$view,
						flags,
						$author$project$Quotation$Common$StepModel$pickTokenType(stepState),
						1,
						$author$project$View$TopBar$RightSideContent(
							$author$project$Quotation$Common$View$TopBar$homeCustomerStepProgressView(
								$author$project$Types$pickIsMobile(flags))));
			}
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Quotation$Common$View$TopBar$view = function (model) {
	switch (model.$) {
		case 2:
			var homeModel = model.a;
			return A2(
				$author$project$Quotation$Common$View$TopBar$homeTopBarView,
				homeModel,
				$author$project$Quotation$Home$Model$pickFlags(homeModel));
		case 3:
			var familyModel = model.a;
			return A2(
				$author$project$Quotation$Common$View$TopBar$familyTopBarView,
				familyModel,
				$author$project$Quotation$Family$Model$pickFlags(familyModel));
		case 0:
			return $elm$html$Html$text('');
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Quotation$View$view = function (model) {
	return A2(
		$author$project$PrimaElm$Lib$Utils$Render$renderIf,
		$author$project$Quotation$Model$pickIsViewInitialized(model),
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('quotation')
				]),
			_List_fromArray(
				[
					$author$project$Quotation$Common$View$TopBar$view(model),
					$author$project$Quotation$View$quotationFadeInView(model),
					$author$project$Quotation$View$footerView(model)
				])));
};
var $author$project$ThankYou$View$Components$figureView = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('hero__figure thank-you-image')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hero__figure__picture')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$View$Svg$svg,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hero__figure__picture__image thank-you-image')
						]),
					'',
					'thankyou-casa')
				]))
		]));
var $author$project$ThankYou$View$Components$confirmMessage = function (insuranceId) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		'Stiamo preparando i tuoi documenti di polizza: li riceverai con l\'email di conferma che invieremo all\'indirizzo ',
		function (_v0) {
			return 'Abbiamo inviato una email di conferma con tutti i documenti di polizza all\'indirizzo ';
		},
		insuranceId);
};
var $author$project$ThankYou$View$Components$insuranceCodeView = function (code) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('c-brand-light badge bg-brand-light-deopaque')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Codice polizza: ' + code)
					]))
			]));
};
var $author$project$ThankYou$View$Components$maybeInsuranceCodeView = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$ThankYou$View$Components$insuranceCodeView),
	$author$project$PrimaElm$Lib$Utils$Render$renderMaybe);
var $author$project$ThankYou$View$Components$heroTitleView = function (_v0) {
	var email = _v0.fi;
	var insuranceCode = _v0.nV;
	var insuranceId = _v0.jA;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$ThankYou$View$Components$maybeInsuranceCodeView(insuranceCode),
				A2(
				$elm$html$Html$h1,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__intro__title c-text-dark thank-you-hero-title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Pagamento avvenuto con successo!')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__intro__paragraph')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$ThankYou$View$Components$confirmMessage(insuranceId))
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__intro__paragraph fw-heavy c-text-dark')
					]),
				_List_fromArray(
					[
						$author$project$PrimaElm$Lib$Utils$Render$renderMaybe(
						A2($elm$core$Maybe$map, $elm$html$Html$text, email))
					]))
			]));
};
var $author$project$ThankYou$Model$DownloadInsuranceContract = function (a) {
	return {$: 6, a: a};
};
var $author$project$ThankYou$View$Components$insuranceCertificateLinkView = F2(
	function (linkHref, downloadStatus) {
		var withClickEvent = function () {
			if (downloadStatus === 1) {
				return $primait$pyxis_components$Prima$Pyxis$Link$withOnClick(
					$author$project$ThankYou$Model$DownloadInsuranceContract(linkHref));
			} else {
				return $elm$core$Basics$identity;
			}
		}();
		return $primait$pyxis_components$Prima$Pyxis$Link$render(
			$primait$pyxis_components$Prima$Pyxis$Link$withTargetBlank(
				withClickEvent(
					A2(
						$primait$pyxis_components$Prima$Pyxis$Link$withClass,
						'fw-light fs-small cursor-pointer',
						$primait$pyxis_components$Prima$Pyxis$Link$simple('Scarica il pdf')))));
	});
var $author$project$ThankYou$View$Components$downloadView = F2(
	function (downloadStatus, linkHref) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hero__intro hero__intro--with-figure')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('box-shadow--medium thank-you-certificate border')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('display-flex flex-direction-row align-items-center')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('display-flex align-items-center')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon fs-xxlarge thank-you-download-icon c-action-dark'),
													$elm$html$Html$Attributes$classList(
													_List_fromArray(
														[
															_Utils_Tuple2('icon-download', downloadStatus === 1),
															_Utils_Tuple2('thank-you-download-icon-loading', !downloadStatus)
														]))
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('c-text-dark fw-base')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Contratto di polizza')
												])),
											A2($author$project$ThankYou$View$Components$insuranceCertificateLinkView, linkHref, downloadStatus)
										]))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('c-text-base fs-xsmall')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Stampa e conserva il tuo contratto di polizza')
						]))
				]));
	});
var $author$project$ThankYou$View$Components$maybeDownloadView = function (downloadStatus) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(
			$author$project$ThankYou$View$Components$downloadView(downloadStatus)),
		$author$project$PrimaElm$Lib$Utils$Render$renderMaybe);
};
var $author$project$ThankYou$View$hero = function (model) {
	var linkHref = model.j4;
	var downloadStatus = model.iW;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero__container container display-flex flex-direction-column align-items-flex-start')
					]),
				_List_fromArray(
					[
						$author$project$ThankYou$View$Components$heroTitleView(model),
						A2($author$project$ThankYou$View$Components$maybeDownloadView, downloadStatus, linkHref)
					])),
				$author$project$ThankYou$View$Components$figureView
			]));
};
var $author$project$ThankYou$Model$CreatePassword = {$: 1};
var $author$project$ThankYou$Model$FindOutMore = {$: 2};
var $author$project$ThankYou$Model$SeeFAQ = {$: 3};
var $author$project$ThankYou$View$Components$suggestedAreaView = F5(
	function (titleCaption, descriptionCaption, buttonCaption, onClick, icon) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('suggestion')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex flex-direction-column')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(icon + ' icon fs-xlarge thank-you-icon c-brand-base')
								]),
							_List_Nil)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('display-flex flex-direction-column')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('c-text-dark fw-heavy')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(titleCaption)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('suggestion-content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('c-text-base fs-small suggestion-description')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(descriptionCaption)
										])),
									$primait$pyxis_components$Prima$Pyxis$Button$render(
									$primait$pyxis_components$Prima$Pyxis$Button$withSmallSize(
										A2(
											$primait$pyxis_components$Prima$Pyxis$Button$withOnClick,
											onClick,
											$primait$pyxis_components$Prima$Pyxis$Button$secondary(buttonCaption))))
								]))
						]))
				]));
	});
var $author$project$ThankYou$View$suggestedAreas = function (_v0) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-row align-content-space-around bg-shape-light thank-you-suggestions')
			]),
		_List_fromArray(
			[
				A5($author$project$ThankYou$View$Components$suggestedAreaView, 'Accedi alla tua Area riservata', 'Crea una password ed effettua l\'accesso per visualizzare i documenti e gestire la polizza.', 'Crea password', $author$project$ThankYou$Model$CreatePassword, 'icon-people'),
				A5($author$project$ThankYou$View$Components$suggestedAreaView, 'Scopri le polizze auto, moto e furgoni', 'Su prima.it bastano pochi secondi per calcolare un preventivo e assicurare il tuo veicolo.', 'Scopri di più', $author$project$ThankYou$Model$FindOutMore, 'icon-car'),
				A5($author$project$ThankYou$View$Components$suggestedAreaView, 'Trova altre informazioni', 'Vai alle FAQ e consulta le risposte alle domande frequenti.', 'Vai alle FAQ', $author$project$ThankYou$Model$SeeFAQ, 'icon-help')
			]));
};
var $author$project$ThankYou$View$view = function (model) {
	var flags = model.nv;
	var mainContent = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$View$Helper$wrapInContainerColumn(
				_List_fromArray(
					[
						$author$project$ThankYou$View$hero(model),
						$author$project$ThankYou$View$suggestedAreas(model)
					]))
			]));
	return $author$project$View$Template$render(
		A2(
			$author$project$View$Template$withLogoUrl,
			$author$project$Types$pickPrimaUrl(flags),
			A2(
				$author$project$View$Template$withMainContent,
				mainContent,
				A2($author$project$View$Template$withClasses, 'thank-you', $author$project$View$Template$template))));
};
var $author$project$View$routeToViewAndTitle = function (model) {
	var route = model.pw;
	switch (route.$) {
		case 0:
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$PreselectionMsg,
					$author$project$Preselection$Preselection$view(model.oW)),
				'Preventivo polizza casa online: scegli cosa vuoi assicurare');
		case 7:
			return _Utils_Tuple2(
				$elm$html$Html$text('not found'),
				'');
		case 1:
			var formType = route.a;
			var step = route.b;
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$QuotationMsg,
					$author$project$Quotation$View$view(model.pa)),
				A2($author$project$View$quotationStepToTitle, formType, step));
		case 2:
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$GuaranteesMsg,
					$author$project$Guarantees$View$view(model.nG)),
				'Preventivo polizza casa online: seleziona garanzie');
		case 3:
			var step = route.c;
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$CheckoutMsg,
					$author$project$Checkout$View$view(model.mw)),
				(step === 1) ? 'Preventivo polizza casa online: scegli data di decorrenza' : 'Preventivo polizza casa online: scegli metodo di pagamento');
		case 4:
			var maybeInsuranceId = route.c;
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$ThankYouMsg,
					$author$project$ThankYou$View$view(model.pU)),
				function () {
					if (!maybeInsuranceId.$) {
						return 'Pagamento completato - Emissione immediata';
					} else {
						return 'Pagamento completato';
					}
				}());
		case 6:
			var reason = route.a;
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$NotAvailableMsg,
					$author$project$NotAvailable$View$view(model.oA)),
				$author$project$View$notAvailableReasonToTitle(reason));
		case 5:
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$QuotationMsg,
					$author$project$Quotation$View$view(model.pa)),
				'Modifica dati');
		default:
			return _Utils_Tuple2(
				A2(
					$elm$html$Html$map,
					$author$project$Model$OneClickMsg,
					$author$project$OneClick$View$view(model.oH)),
				'Offerta');
	}
};
var $author$project$View$innerView = function (model) {
	var appStatus = model.c0;
	var _v0 = $author$project$View$routeToViewAndTitle(model);
	var content = _v0.a;
	var title = _v0.b;
	return {
		mo: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$html$Html$node,
						'link',
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('https://cdn.icomoon.io/98538/PyxisIconset30/style-cf.css?gfdteh'),
								$elm$html$Html$Attributes$rel('stylesheet')
							]),
						_List_Nil),
						A2($author$project$PrimaElm$Lib$Utils$Render$renderIf, appStatus === 1, content),
						A2(
						$author$project$PrimaElm$Lib$Utils$Render$renderIf,
						!appStatus,
						A2($author$project$Components$Loader$view, 0, 'Caricamento in corso...'))
					]))
			]),
		eE: title + ' | Prima'
	};
};
var $author$project$View$view = function (appState) {
	if (!appState.$) {
		var model = appState.a;
		return $author$project$View$innerView(model);
	} else {
		return {
			mo: _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Some init error occurred:')
								])),
							$elm$html$Html$text('An init error occurred during init decoding process. Check console for more info')
						]))
				]),
			eE: ''
		};
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{nU: $author$project$Init$init, oF: $author$project$Model$UrlChanged, oG: $author$project$Model$UrlRequested, pP: $author$project$Main$subscriptions, p5: $author$project$Update$update, p9: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main($elm$json$Json$Decode$value)(0)}});}(this));